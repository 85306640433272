import React, { useEffect, useRef, useState } from "react";
import { LIST_VIEW_LIMIT } from "../../../constants";
import { asec, desc } from "../../../utils";
import { ListView } from "../../secondary";
import styles from "./createFormulary.module.scss";
import { groupByList, deepCopyObject } from "../../../utils";
import { Accordion } from '../../secondary/Accordion/accordion';
import topArrow from '../../../static/images/up-dataTable.png'
import downArrow from '../../../static/images/down-dataTable.png'

export default function NewFormulary({
  addedItems,
  getRightSelectedItems,
  formularyName,
  addedMeta,
  setSeletedMaterial,
  seletedMaterial,
  changeMaterial
}) {
  const metaConfig = { total: 0, limit: 0, offset: 0, prev: "", next: "" };

  Object.assign(metaConfig, addedMeta);

  // Disable pagination
  const [noPage, setNoPage] = useState(false);
  const [items, setItems] = useState(
    addedItems && Array.isArray(addedItems) ? [...addedItems] : []
  );
  const [itemsMeta, setItemsMeta] = useState({ ...metaConfig });
  const [displayItems, setDisplayItems] = useState(
    Array.isArray(items) ? [...items] : []
  );

  useEffect(() => {
    setItems(addedItems && Array.isArray(addedItems) ? addedItems : []);
    Object.assign(metaConfig, addedMeta);
    setItemsMeta(metaConfig);
  }, [addedItems, addedMeta]);

  useEffect(() => {
    if (items && Array.isArray(items)) {
      setDisplayItems(items);
    }
  }, [items]);

  // Displaying limited items, rest will go in another page
  useEffect(() => {
    if (addedItems && Array.isArray(addedItems) && addedItems?.length > 0) {
      // Adding first X items to 'items' state
      const temp = [];
      for (let i = 0; i < addedItems?.length; i++) {
        if (items[i]) {
          temp.push(items[i]);
        } else {
          break;
        }
      }
      setDisplayItems([...temp]);

      // Updating meta based on added items
      const tempMeta = { ...itemsMeta };
      tempMeta["total"] = addedItems.length;
      tempMeta["limit"] = LIST_VIEW_LIMIT;
      setItemsMeta({ ...tempMeta });
    }
  }, [addedItems, items]);

  // Hiding and Showing pagination based on items
  useEffect(() => {
    if (items && Array.isArray(items) && items?.length < 1) {
      setNoPage(true);
    }
    if (items && Array.isArray(items) && items?.length >= 1) {
      setNoPage(false);
    }
  }, [items]);

  // Custom pagination function
  const [activePage, setActivePage] = useState(1);
  const getActivePage = (data) => {
    const offset =
      data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT < LIST_VIEW_LIMIT
        ? 0
        : data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT;
    const temp = [];
    if (items && Array.isArray(items)) {
      for (let i = offset; i < LIST_VIEW_LIMIT + offset; i++) {
        if (items[i]) {
          temp.push(items[i]);
        } else {
          break;
        }
      }
      setDisplayItems([...temp]);
      setActivePage(data);
    }
  };

  // SORTING

  // We are adding the 'addedItems' array to useRef because of state update issue
  const ref = useRef(Array.isArray(addedItems) ? addedItems : []);

  useEffect(() => {
    if (Array.isArray(addedItems) && addedItems.length > 0) {
      ref.current = [...addedItems];
    }
  }, [addedItems]);

  // This function is called everytime we click the sort buttons. We receive, sort type as argument
  const sortingItems = (data) => {
    setActivePage(1);
    if (ref && Array.isArray(ref.current)) {
      const temp = [...ref.current];
      if (data?.desc === false) {
        temp.sort(asec);
        setItems([...temp]);
      }
      if (data?.desc === true) {
        temp.sort(desc);
        setItems([...temp]);
      }
      if (data === undefined) {
        temp.sort(asec);
        setItems([...temp]);
      }
    }
  };

  const [sortingPayload, setSortingPayload] = useState({
    queryParam: { limit: LIST_VIEW_LIMIT, offSet: 0 },
    asyncMethod: sortingItems,
  });

  // Data table column details

  const columns = [
    {
      Header: formularyName ? formularyName : "",
      accessor: "title",
      fixedColumn: false,
    },
  ];

  const [
    groubedByProductCategoryDesc,
    setGroubedByProductCategoryDesc,
  ] = useState([]);

  useEffect(() => {
    
    setTimeout(() => {
        if (Array.isArray(displayItems)) {
            const changeProd = groupByList(displayItems, "productCategoryDesc");
            setGroubedByProductCategoryDesc(changeProd);
            if(document.querySelectorAll('.remove_check')){
                document.querySelectorAll('.remove_check').forEach(el => el.checked = false);
            }
   
          }
      }, 100)
  }, [displayItems]);
  const [
    sortType,
    setSortType,
  ] = useState('both');
  const sortMaterail = (sortDir) => {
    if(document.querySelectorAll('.remove_check')){
      document.querySelectorAll('.remove_check').forEach(el => el.checked = false);
    }
    setSeletedMaterial([])
    let cpyCat= [...groubedByProductCategoryDesc]
    
    if(sortDir==='up'){
      Array.isArray(cpyCat) &&
      cpyCat.length > 0 &&
      cpyCat.sort((a, b) => (a.key > b.key ? 1 : -1));
      cpyCat.map((listItems) => {//NOSONAR
        return (
              listItems?.value &&
          Array.isArray(listItems?.value) &&listItems?.value.length > 0 &&
          listItems?.value.sort((a, b) => (a.title > b.title ? 1 : -1))
        )})//NOSONAR

      setGroubedByProductCategoryDesc(cpyCat)
      if(sortType==='up'){  setSortType('both')}else{
        setSortType('down')
      }
    
    }
    else if(sortDir==='down'){
      Array.isArray(cpyCat) &&
      cpyCat.length > 0 &&
      cpyCat.sort((a, b) => (a.key > b.key ? -1 : 1));
      cpyCat.map((listItems) => {//NOSONAR
        return (
              listItems?.value &&
          Array.isArray(listItems?.value) &&listItems?.value.length > 0 &&
          listItems?.value.sort((a, b) => (a.title > b.title ? -1 : 1))
        )})//NOSONAR
      setGroubedByProductCategoryDesc(cpyCat)
     
      if(sortType==='down'){  setSortType('both')}else{
        setSortType('up')
      }
    }
  }

  return (
    <>
       {displayItems&&groubedByProductCategoryDesc &&<div className={`${styles.accordion_group} mt-5`}>
        <div className={`${styles.thead}`}>{columns&&columns[0]&&columns[0].Header}
        <div className={`ml-1  flex-column  justify-content-center ${styles.arrow_sec}`}>
       
            {sortType&&(sortType==='both'||sortType==='up') &&<span className={`${styles.fetch_uparrow} mb-0`} onClick={() => sortMaterail('up')}  >
              <img src={topArrow} alt="Top arrow" title="Up" onClick={() => sortMaterail('up')} />
            </span>}
            {sortType&&(sortType==='both'||sortType==='down')&&<span className={`${styles.fetch_downarrow} `} >
              <img src={downArrow} alt="doen arrow" title="Down" onClick={() => sortMaterail('down')}/>
            </span>}
            
         
        </div>
       
        </div>
        <div className={`${styles.tbody}`}>
        {
          displayItems&&groubedByProductCategoryDesc &&
            Array.isArray(groubedByProductCategoryDesc)&&groubedByProductCategoryDesc.length>0 &&
            groubedByProductCategoryDesc.map((listItems, index) => {
            return (<div key={index} >
              <Accordion
                accordType={'formulary'}
                idControl={index}
                heading={
                  <div > {listItems.key}
                  </div>
                  
                }
                content={<div>
                  {listItems?.value &&
              Array.isArray(listItems?.value) &&
              listItems?.value.map((listItemsVal, indexVal) => {
                return (
                    <div
                    key={index}
                      id={`collapseOne${indexVal}`}
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent={`#accordion${indexVal}`}
                      
                    >
                      <div className={`card-body ${styles.card_body}`}>
                        <div
                          className={`d-flex align-items-center ${styles.trow}`}
                        >
                          <span className={`${styles.cost_input}`}>
                            <div
                              className={`custom-control custom-checkbox ${styles.custom_checkbox}`}
                            >
                              <input
                                type="checkbox"
                                className={`custom-control-input ${styles.custom_control_input} remove_check`}
                               // onClick={()=>getlistItemsValChck(listItemsVal,indexVal)}
                                onChange={() => {
                                    changeMaterial(listItemsVal);
                                   /* setSeletedMaterial(
                                    {listItemsVal}
                                    );*/
                                  }}
                                  //value={seletedMaterial}
                              ></input>
                              <label
                                className={`custom-control-label ${styles.custom_control_label}`}
                              ></label>
                            </div>
                          </span>
                          <span>{listItemsVal.title}</span>
                        </div>
                      </div>
                    </div>
                     );
                    })}
                </div>
                }
              />
            </div>
            )
          })
        }          
          {Array.isArray(groubedByProductCategoryDesc)&&groubedByProductCategoryDesc.length===0&& <div className="row mx-auto d-flex justify-content-center align-items-center mt-5"><h5 className={` d-flex justify-content-center text-center`}>There are no materials selected for this formulary</h5></div>}
        </div>
      </div>}
    </>
  );
}
