import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-vis/dist/style.css";
import logo from "../../../static/images/skin-health-logo.png";
import {
  handleClickGenerateQualityReportPDF,
  handleClickGenerateWoundReportPDF,
} from "../../../utils/woundReportHelper";
import { Button } from "../../primary";
import { Header } from "../../secondary/Header";
import styles from "./qualityReportPdf.module.scss";

export function QualityReportPdf({ history }) {//NOSONAR
  // For scrolling the page to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Getting loader
  const loading = useSelector((state) => state.app.loading);

  // States
  const [canvases, setCanvases] = useState([]);
  const [header, setHeader] = useState({});
  const [started, setStarted] = useState(false); // To avoid multiple html2canvas promises
  const [pdfGeneration, setPdfGeneration] = useState(false); // State for end of html2canvas process
  const [message, setMessage] = useState(false);

  const overViewData = useSelector(
    (state) => state.woundreport.qualityAssuranceOverViewData
  );
  const customerName = useSelector((state) => state.user.customerName);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setMessage(true);
      }, 10000);
    }
  }, [loading]);

  // Getting header, finding the size, converting the code and pushing it into a state
  useEffect(() => {
    async function run() {
      const header = document.getElementById("header");
      const imageWidth = 210;
      await html2canvas(header).then(function(canvas) {
        setHeader({
          height: (canvas.height * imageWidth) / canvas.width,
          imgData: canvas.toDataURL("image/png"),
        });
      });
    }
    // We are starting above canvasing process after loading the html file with a delay
    if (!loading) {
      if (!started) {
        setTimeout(() => {
          run();
        }, 5000);
        setStarted(true);
      } else {
        return;
      }
    }
  }, [loading]);

  // Getting all the elements with the tag name 'article'. Capturing screenshot, finding height and converting it into base code.
  // Pushing all those into an array of object
  useEffect(() => {
    async function run() {//NOSONAR
      const allDivs = document.getElementsByTagName("article");
      const divsArray = Array.from(allDivs);
      const can = [];
      const imageWidth = 210;
      // Creating screenshot for each section
      for (let i = 0; i < divsArray.length; i++) {
        await html2canvas(divsArray[i], { allowTaint: true, useCORS: true })
          .then(function(canvas) {
            can.push({
              id: i,
              height: (canvas.height * imageWidth) / canvas.width,
              imgData: canvas.toDataURL("image/png"),
            });
            setCanvases([...can]);
          })
          .finally(() => {
            if (i === divsArray.length - 1) {
              setPdfGeneration(true);
            }
          });
      }
    }//NOSONAR
    // We are starting above canvasing process after loading the html file with a delay
    if (!loading) {
      if (!started) {
        setTimeout(() => {
          run();
        }, 5000);
        setStarted(true);
      } else {
        return;
      }
    }
  }, [loading]);

  // On click, generating pdf by putting all the above images into a document. We are using jsPDF to generate pdf.
  // If we can't fit image into same page, we are moving the image into next page. It is done automatically.
  const handleClick = () => {
    handleClickGenerateQualityReportPDF(
      html2canvas,
      jsPDF,
      canvases,
      header,
      customerName
    );
  };

  return (
    <>
      <div className={`${styles.back_btn}`}>
        <Header backLink={"patients"} handleBack={() => history.goBack()} />
      </div>

      {!pdfGeneration && (
        <div className={styles.info_message}>
          {
            <>
              {
                <h3>
                  Creating your PDF report... <span>This won't take long</span>
                </h3>
              }
            </>
          }
        </div>
      )}

      {!loading && (
        <div className={styles.info_message}>
          {pdfGeneration && (
            <>
              <Button
                classes={{
                  className: `btn btn-small ${styles.btn_small} ${styles.btn_custom}`,
                }}
                type="submit"
                id="next"
                onClick={handleClick}
              >
                Download PDF
              </Button>
            </>
          )}
        </div>
      )}

      {!loading && (
        <div id="pdf" className={styles.content}>
          <header id="header" className={`min ${styles.header}`}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-8 p-0">
                  <h1>Quality Overview</h1>
                </div>
                <div className="col-4 p-0 text-right">
                  <img src={logo} alt="test" />
                </div>
              </div>
            </div>
          </header>

          <article>
            <div className={styles.overview_btm}>
              <p>
                <label className={styles.pend_photos}>Pending Photos</label>
                <span>{overViewData?.pendingPhotos}</span>
              </p>
              <p>
                <label className={styles.pend_assessments}>
                  Pending Assessments
                </label>
                <span>{overViewData?.pendingAssessments}</span>
              </p>
              <p>
                <label className={styles.pend_review}>Pending Reviews</label>
                <span>{overViewData?.pendingReviews}</span>
              </p>
              <p>
                <label className={styles.comp_assess}>
                  Completed Assessments
                </label>
                <span>{overViewData?.completedAssessments}</span>
              </p>
            </div>
          </article>
        </div>
      )}
    </>
  );
}//NOSONAR
QualityReportPdf = connect()(withRouter(QualityReportPdf));
