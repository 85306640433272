import React, { useState, useRef, useEffect } from 'react';
import style from './modalities.module.scss';
import { Button, Form, MultiSelect } from '../../primary';
import { CANCEL_BTN, SAVE_BTN } from '../../../constants';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { useForm } from 'react-hook-form';
import { checkSalesOffice, signedLockedAccess } from '../../../utils';
import { getModalitiesAsync, optionTypeAsync, patchModalitiesAsync } from '../../../app/patientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleListViewMenuChangeMove } from '../../../utils/deeHelper';
import { assessmentRbacChecker } from '../../../utils/rbac';

export const Modalities = ({ interventionId, assessmentDetails }) => {

  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const modalitiesResp = useSelector(state => state.patient.modalities);
  const listResp = useSelector(state => state.patient.modalitiesListoptions);
  const patientData = useSelector(state => state.patient.detailViewData)
  const userDetails = useSelector(state => state.user);

  const listViewRef = useRef();
  const [editable, setEditable] = useState(false);
  const [listViewMenuData, setListViewMenuDatta] = useState([]);

  const hideButtons = assessmentRbacChecker(userDetails?.role, patientData?.isOwnCustomer);

  const editMode = () => {
    setEditable(true);
  }
  const viewMode = () => {
    if (modalitiesResp?.modalities === null || modalitiesResp?.modalities?.length <= 0) {
      return false
    } else {
      setEditable(false);
      reset();
    }
  }


  const handleListViewMenuChange = (e) => {
    handleListViewMenuChangeMove(e, setListViewMenuDatta)
  }
  const doSubmit = () => {
    const payloadData = {
      "modalities": listViewMenuData,
      'patientId': patientId,
      'assessmentId': assessmentId,
      'interventionId': interventionId
    }
    dispatch(patchModalitiesAsync(payloadData))
    setEditable(false);
  }
  useEffect(() => {
    if (modalitiesResp?.modalities === null || modalitiesResp?.modalities?.length <= 0) {
      setEditable(true)
     
    }
    let modalityData = [];
    modalitiesResp&&Array.isArray(modalitiesResp?.modalities)&&modalitiesResp?.modalities.map((data) => {//NOSONAR
      if (data&&data.key !== '') {
        modalityData.push(data)
      }
    })//NOSONAR
    setListViewMenuDatta(modalityData)
  }, [modalitiesResp])
  useEffect(() => {
    dispatch(optionTypeAsync('modalities'));
  }, [interventionId])
  useEffect(() => {
    dispatch(getModalitiesAsync({ patientId, assessmentId, interventionId }));
  }, [dispatch, interventionId])



  return <div>
    {
      !editable ? <div className={`${style.viewMode_container} mt-3 pt-1`}>
        <div className="d-flex justify-content-between">
          <div className={`${style.lab_container} d-flex col-11 p-0`}>
            <label className="col-5 p-0">Modalities: </label> <div className="col-6 p-0">
              {modalitiesResp?.modalities && modalitiesResp?.modalities.length > 0 && modalitiesResp?.modalities?.map(e => {
                let optionValue = "";
                listResp && listResp.length > 0 && listResp?.map((l) => {//NOSONAR
                  if (e == l.key) {
                    optionValue = l.value
                  }
                })//NOSONAR
                return <div className="font-weight-bold">{optionValue}</div>
              })}</div>
          </div>
          <div>
            {
              !editable && signedLockedAccess(assessmentDetails?.assessmentStage) && hideButtons && <div className={style.tab_edit} onClick={() => editMode()}><img src={editIcon} alt="icon" className={style.e_icon_space} />Edit</div>
            }
          </div>
        </div>
      </div> :
        <div className="mt-3 pt-1">
          {signedLockedAccess(assessmentDetails?.assessmentStage) ?
            <Form formChanges={true} onSubmit={handleSubmit(doSubmit)}>
              <MultiSelect
                id={"modalities"}
                name={"modalities"}
                label={"Modalities:"}
                classes={{
                  column: ` row ${style.botton_spac}`,
                  inputClassName: `${style.basic_select}`,
                  labelClassName: `col-4 col-form-label ${style.label_spec}`,
                  inputColumn: `col-6 p-0 ${style.select_wrapper}`,
                }}
                optionValue={Array.isArray(listResp) && listResp}
                showCheckbox={true}
                displayValue="value"
                validator={(e) => {
                  register({ required: true })
                  listViewRef.current = e
                }}
                onSelect={handleListViewMenuChange}
                onRemove={handleListViewMenuChange}
                defaultValue={modalitiesResp?.modalities && modalitiesResp.modalities}
                labelSubText={'Select all that apply'}
              />
              {hideButtons &&
                <div className="row">
                  <div className="mt-4 pt-2 col-sm-12 p-0">
                    <div className="offset-4">
                      <Button
                        type={"submit"}
                        classes={{ className: `btn ${style.btn_save}` }}
                        id={'updateBtn'}
                        value={SAVE_BTN}
                      />
                      <Button
                        classes={{ className: `btn ${style.btn_cancel}` }}
                        type="button"
                        id="cancel"
                        value={CANCEL_BTN}
                        onClick={() => viewMode()}
                      />

                    </div>
                  </div>
                </div>
              }
            </Form>
            :
            'This wound assessment is signed and locked, cannot be updated.'
          }
        </div>
    }
  </div>
}
