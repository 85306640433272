import React, { useEffect } from "react";
import NE1Logo from "./../../../static/images/ne-logo.png";
import screenShot from "../../../static/images/scree-shots.png";
import style from "./learnMorePage.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setAccessError } from "../../../app/appSlice";
import { Header } from "../../secondary/Header";

export const LearnMorePage = () => {
  const dispatch = useDispatch();
  const accessError = useSelector((state) => state.app.accessError);

  useEffect(() => {
    dispatch(setAccessError(null));
  }, []);
  return (
    <>
      <Header />
      <div className="col-md-11">
        <h2 class="ne_hdg text-center">You discovered a Premium feature!</h2>
        <h3 class="ne_hdg text-center">
          Upgrading to NE1 Wound Management System will unlock it.
        </h3>
        <div className={style.ne1_block}>
          <div className="col-6 p-0 text-center">
            <div className={style.ne_lft}>
              <p className="text-center">
                <img src={NE1Logo} alt="logo" />{" "}
              </p>
              <h2 className={style.ne_hdg}>NE1® Wound Management System</h2>
              <h3 className={style.ne_sub_title}>
                Mobile wound management made easy
              </h3>
              <p className={style.ne_cont}>
                The NE1 Wound Management System combines the latest imaging
                technology with the patented NE1 assessment methodology to
                empower front-line nurses to measure and assess wounds as well
                as select appropriate treatments. Additionally, the solution
                enables care coordination with wound healing experts.
              </p>
              <ul className={style.ne_list}>
                <li>Assess Accurately</li>
                <li>|</li>
                <li>Enhance Outcomes</li>
                <li>|</li>
                <li>Secure and Seamless</li>
              </ul>
              <button className={`btn ${style.ne_btn}`}>
                <a href="https://www.medlinene1.com">Learn more</a>
              </button>
            </div>
          </div>
          <div className={`col-6 p-0 text-right ${style.ne_right}`}>
            <img src={screenShot} alt="" className="img-fluid " />
          </div>
        </div>
      </div>
    </>
  );
};
