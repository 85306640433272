import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getMediaList, updateMediaList } from '../../../app/productSlice';
import { SUBMIT_ID } from '../../../constants';
import { Button } from '../../primary';
import { MultiFileUpload } from './multiFileUpload'
import styles from './mediaView.module.scss'
import editIcon from '../../../static/images/edit-icon-blue.png'
import PlusIcon from '../../../static/images/plus-icon_white.svg';
import ImageIcon from '../../../static/images/products/image_icon.svg';
import VideoIcon from '../../../static/images/products/video_icon.svg';
import CloseIcon from '../../../static/images/products/close_icon.svg';
import { handleDeleteMove } from '../../../utils/productImageHelper';
import Loader from '../../../static/images/spinner.svg';	
export function MediaView({ productData, productType }) {

    const { productId } = useParams()
    const dispatch = useDispatch()

    function getMedia() {
        dispatch(getMediaList(productId, productType))
    }

    // Initial GET call to get existing media
    useEffect(() => {
        getMedia();
    }, [dispatch])

    const mediaList = useSelector(state => state.product.mediaList)

    const [editable, setEditable] = useState(false)
    const [addMedia, setAddMedia] = useState(false)

    const handleEdit = () => {
        setEditable(true)
        setAddMedia(false);
    }

    const handleCancel = () => {
        setEditable(false)
        setAddMedia(false);
    }

    const handleDelete = (type, id) => {
        handleDeleteMove(type, id, mediaList, dispatch, updateMediaList, productId, productType, getMedia)
    }

    const handleAddMedia = () => {
        setAddMedia(true)
    }

    const handleAddMediaCancel = () => {
        setAddMedia(false)
    }

    /* Trigger ad media overlay close*/
    const dropRef = useRef();

    // Click event listener and calling handleClickOutside function if we click outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    const handleClickOutside = (e) => {
        if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
            setAddMedia(false);
        }
    };
    const [tinyDelay, setTinyDelay] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    }, [])
    return (
        <div className={styles.outer_media_view}>

            <div className={styles.head}>
                <h2>{(productData?.productFamilyDesc) ? productData?.productFamilyDesc : productData?.materialDescription}</h2>

                {tinyDelay&&!editable &&<span onClick={handleEdit}><img src={editIcon} alt="edit" />Edit</span>}

                {editable && <>

                    <Button
                        classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small} ${styles.btn_position}` }}
                        type="button"
                        id={SUBMIT_ID}
                        onClick={handleAddMedia}
                    >
                        <img src={PlusIcon} alt="edit" /> Add Media
                    </Button>

                </>}
                {addMedia && <div className={`dropdown-menu show ${styles.media_align}`} aria-labelledby="dropdownMenu3" >
                    <MultiFileUpload
                        handleAddMediaCancel={handleAddMediaCancel}
                        productType={productType}
                        mediaList={mediaList}
                        getMedia={getMedia}
                        productId={productId}
                    />
                </div>}
            </div>

            {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
            <div className={styles.media_view}>
                <h4>Images</h4>
                <ul>
                    {mediaList?.images === null && <h3 className="text-center">There are no images available for this product</h3>}
                    {mediaList?.images && mediaList?.images.length < 1 && <h3 className="text-center">There are no images available for this product</h3>}

                    {mediaList?.images && mediaList?.images?.length > 0 && mediaList.images.map((data, i) => {
                        return <li key={i}>
                            <img src={ImageIcon} alt="image_icon"></img>
                            <a href={data.image} target="_blank" rel="noopener noreferrer">{data.fileName}</a>
                            {editable && <span onClick={() => handleDelete('images', i)}><img src={CloseIcon} alt="close" /></span>}
                        </li>
                    })}
                </ul>
                <h4>Videos</h4>
                <ul>
                    {mediaList?.embedVideo === null && <h3 className="text-center">There are no videos available for this product</h3>}
                    {mediaList?.embedVideo && mediaList?.embedVideo.length < 1 && <h3 className="text-center">There are no videos available for this product</h3>}

                    {mediaList?.embedVideo && mediaList?.embedVideo?.length > 0 && mediaList.embedVideo.map((data, i) => {
                        return <li key={i}>
                            <img src={VideoIcon} alt="image_icon"></img>
                            <a href={data.videoUrl} target="_blank" rel="noopener noreferrer">{data.fileName}</a>
                            {editable && <span onClick={() => handleDelete('embedVideo', i)}><img src={CloseIcon} alt="close" /></span>}
                        </li>
                    })}
                </ul>
            </div>}

            <br />

            {editable && <div className="text-center pb-4">
                <br />
                {/* Save Button */}
                <Button
                    classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                    type="submit"
                    id={SUBMIT_ID}
                    onClick={handleCancel}
                >
                    Ok
                </Button>
                {/* Cancel button  */}
                {/* <Button
                        classes={{ className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                        id={CANCEL_ID}
                    >
                        {CANCEL_BTN}
                    </Button>                        */}
            </div>}

        </div>
    )
}