import React, { useEffect, useState } from 'react';
import style from './woundAssessment.module.scss';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect, useSelector, useDispatch } from 'react-redux';
import LogoImage from '../../../static/images/measurmenttest.jpg';
import { Modal } from '../../secondary/Modal/modal';
import {  patientAssessmentHistoryAsync } from '../../../app/patientSlice';
import { format } from 'date-fns'
import { setLoading } from '../../../app/appSlice';
import Loader from '../../../static/images/spinner.svg';
import { assessmentStageGetterMove, fullMonthFormatDate, pdfPageMove, allNullCheck} from '../../../utils';
import { Button } from '../../primary/Button/button';


export let AssessmentHistory = ({ modalOpen, modalClose, assessmentTitle, modalTitlteId, history, setmodalShow }) => {

  const { patientId } = useParams();
  const assessmentId = modalTitlteId;

  const dispatch = useDispatch();
  const assessmnetHistoryData = useSelector(state => state.patient.assessmentHistory);

  useEffect(() => {
    dispatch(patientAssessmentHistoryAsync(patientId, assessmentId));
  }, [patientId, assessmentId])

  //getting History/cause value
  const historyCause = useSelector(state => state.patient.historyCause);

  // Get wound progress
  const woundProgress = useSelector(state => state.patient.woundProgress);

  
  // Role
  const userRole = useSelector(state => state.user);

  const modalRefresh = () => {
    setmodalShow(false);
  }

  // redirect to assessment details screen fn
  const detaileView = (data) => {
    if (userRole.role !== 'ExternalStandardUser') {
      history.push('/patients/' + patientId + '/assessment/' + data);
      modalRefresh();
    }
  }

  const [pdfModal, setPdfModal] = useState(false)
  const [showImage, setShowImage] = useState(false)

  const pdfmodalopen = () => { //added for Pdf with/without Image
    setPdfModal(true);
  }
  const pdfmodalclose = () => {
    setPdfModal(false)
  }

  //added for Pdf with/without Image
  const pdfPageTrue = (data) => {  
    let showImage = true
    pdfPageMove(data, dispatch, setLoading, history, patientId, modalRefresh, showImage)
  }
  const pdfPageFalse = (data) => { 
    let showImage = false 
    pdfPageMove(data, dispatch, setLoading, history, patientId, modalRefresh, showImage)
  }
  //added for Pdf with/without Image

  // redirect to assessment details screen fn

  const pdfPage = (data) => {
    pdfPageMove(data, dispatch, setLoading, history, patientId, modalRefresh, showImage)
  }


  const assessmentStageGetter = (data) => {
    return assessmentStageGetterMove(data)
  }

  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])

  const [historyId, setHistoryId] = useState(); //PDf Popup Getting ID from assessment history list

  return (
    <>
      <Modal
        modalOpen={modalOpen}
        modalClose={modalRefresh}
        modalSize={style.custom_modal_size}
        pdfModal={pdfModal}
      >
        {
          <div className={style.parent}>
            <div>
              <div className={`${style.modal_heading} pl-4 pt-3 mt-3`}>Assessment History</div>
              {/* <div className={`pl-4 ${style.sub_heading}`}>

                {
                  `${assessmentTitle}`
                }
              </div> */}
            </div>
            <button type="button" className={!pdfModal && `close ${style.close_icon}`} onClick={() => modalRefresh()}>&times;</button>
            {/* <div className={`pl-4 pr-3 text-right`}>
              <Button
                value={"Compare"}
                id={'Compare'}
                classes={{ className: `btn pb-2 pt-2 mb-4 mr-2 ${style.no_btn}` }}
              />
            </div> */}
            <br />
            {!tinyDelay ? <div className={style.loader_fix}><img src={Loader} alt="loading" /></div>

              :
              <>
                {assessmnetHistoryData?.followuphistory?.length !== 0 ? <>
                  {
                    assessmnetHistoryData && assessmnetHistoryData?.followuphistory?.map((item, index) => { //NOSONAR
                      return <div className={style.admission_status_space}>

                        <div className={`pl-4 ${style.sub_heading}`}>

                          {
                            `${item.woundTitle}`
                          }
                        </div>
                        <div className={`pl-4 pr-4 mb-2 mt-1 pt-1 ${style.admission_status}`}>History/Cause: {' '} 
                          {item.causeCategory !== "unknown" ? ((item.causeCategory && item.cause) ?
                            <span className=''>                                  
                              {historyCause && historyCause?.length > 0 && historyCause?.map((data)=>{
                                if(data.key===item.causeCategory){
                                  return data?.options?.map((subdata)=>{
                                    if(subdata.key===item.cause || subdata.value===item.cause){
                                      return data.value + '-' + subdata.value
                                    }
                                  })
                                }
                              })}
                            </span>
                            : <span className=''>{`-`}</span>)
                            : <span className=''>{`Unknown`}</span>
                          }
                        </div>
                        <div className={`pl-4 pr-4 mb-2 pb-1 mt-1 ${style.admission_status}`}>Admission Date: {fullMonthFormatDate(item.admissionDate)}</div>
                        <table className="w-100" cellPadding='0' cellSpacing='0'>
                          <thead>
                            <tr className={style.complete_th}>
                              <th>Wound</th>
                              <th>Date Photo is Taken</th>
                              <th>Wound Progress</th>
                              <th>% Change</th>
                              <th>Workflow Status</th>
                              <th>View/Download PDF</th>
                            </tr>
                          </thead>
                          {
                            item && item?.assessments?.map((history, index) => { //NOSONAR
                              return <tbody>
                                <tr className={`${style.complete_td} mb-2`}>
                                  <td onClick={() => detaileView(history.id)}>
                                    {
                                      history.picture ? <img
                                        src={history.picture}
                                        alt="wound"
                                        className={style.modal_image}
                                      /> :
                                        <img src={LogoImage} alt="wound image" className={style.modal_image} />
                                    }
                                  </td>
                                  <td onClick={() => detaileView(history.id)}>{format(new Date(history.identifiedDate), 'LLLL d, yyyy hh:mmaa')}</td>
                                  <td onClick={() => detaileView(history.id)}>
                                    {
                                      history?.woundStage ?
                                        woundProgress && woundProgress.length > 0 && woundProgress?.map((data) => {
                                          if (data.key === history?.woundStage?.toLowerCase()) {
                                            return data.value
                                          }
                                        })
                                        :
                                        ''
                                    }
                                  </td>

                                  {/* <td onClick={() => detaileView(history.id)}>{`${history.closurePercentage}${history.closurePercentage !== 'Unknown' ? history.closurePercentage && `${history.closurePercentage !== '' ? `%` : ''}` : ''}`}</td> */}
                                  <td onClick={() => detaileView(history.id)}>                                    
                                      {history.closurePercentage ? 
                                        (history.closurePercentage !== "Unknown" ?  <span className={history.closurePercentage !== '0' ? (history.closurePercentage < '0' ? `${style.closureColor_green}` : `${style.closureColor_red}`) : ('')}>
                                          <span style={{paddingRight: "4px"}}>                                             
                                              <i className={history.closurePercentage !== "0" && (history.closurePercentage < "0" ? `fa-solid fa-down-long ${style.closureColor_green}` : `fa-solid fa-up-long ${style.closureColor_red}`)}></i>
                                          </span>
                                          {Number(history.closurePercentage?.replace( /^\D+/g, ''))} {`%`}
                                        </span> 
                                        :<span className={`pl-2`}>{"-"}</span>) 
                                      : <span className={`pl-2`}>{"-"}</span>}
                                  
                                    {/* {`${history.closurePercentage}${history.closurePercentage !== 'Unknown' ? (history.closurePercentage !== '0' ?  (history.closurePercentage < '0' ? <span style={{color:"#15b471"}}>{history.closurePercentage}</span> :  <span className={`${style.closureColor_green}`}>{history.closurePercentage}</span>) : <span>{history.closurePercentage}</span>): ''}`} */}
                                      
                                  </td>

                                  <td onClick={() => detaileView(history.id)}>
                                    {assessmentStageGetter(history.assessmentStage)}
                                  </td>
                                  <td key={index}>
                                    {(history.assessmentStage === 'pendingreview' || history.assessmentStage === 'signedlocked') ?
                                      <span className={style.pdf_link} 
                                          // onClick={() => pdfPage(history.id)}
                                          onClick={() => {pdfmodalopen();setHistoryId(history.id)}}
                                      >
                                          View/Download
                                        </span>
                                      :
                                      '-'
                                    }

                                    {/* <CheckBox
                                    classes={{
                                      column: "col form-check p-0",
                                      inputClassName: `${style.custom_control_input}`
                                    }}
                                    type={"checkbox"}
                                    id={`check_${index}`}
                                    name={`check_${index}`}
                                    onClick={e => e.stopPropagation()}
                                  /> */}
                                  </td>
                                </tr>
                                <tr><td className={style.tr_space}></td></tr>
                              </tbody>
                            })
                          }
                        </table>
                      </div>
                    })
                  }
                </>
                  :

                  <h4 className="text-center p-4">No data available.</h4>

                }
              </>
            }

          </div>
        }
      </Modal>

      {/* added for Pdf with/without Image */}
        {pdfModal && <Modal
          modalOpen={pdfmodalopen}
          modalClose={pdfmodalclose}
          modalSize={style.custom_modal_size_pdf}
        >
          {
            <div className={style.modalparent}>
              <div className={`${style.modal_heading_pdf} pb-2`}>
                <div className={`${style.modal_header_pdf} pl-3 pt-3`}>
                  <h3>PDF Download</h3>
                </div>
                <button type="button" className={`close ${style.close_icon_pdf} pr-3`} onClick={() => pdfmodalclose()}>&times;</button>
              </div>
            
              <div className={`${style.modal_footer}`}>
                <div className={'px-4 mt-5'}>
                  <h2> Do you want to include <br /> wound images? </h2>
                </div>
                <div className={'d-flex justify-content-center px-3 pt-5 pb-2'}>
                <Button
                      type="submit"
                      value="Yes"
                      classes={{ className: `btn btn-small ml-0 mr-5 ${style.btn_small} ${style.btn_custom}` }}
                      id="save"
                      onClick={()=>pdfPageTrue(historyId)}
                  />
                  
                  <Button
                      type="button"
                      value="No"
                      classes={{ className: `btn btn-small ${style.btn_small_cancel} ${style.btn_custom} ml-4` }}
                      id="cancel"
                      onClick={()=>pdfPageFalse(historyId)}                                                                          
                  />
                </div>
              </div>
            
            <br />
            

          </div>
          }        
        </Modal>
      }
      {/* added for Pdf with/without Image */}
      
    </>
  )
}

AssessmentHistory = connect()(withRouter(AssessmentHistory));
