import React, { useState, useRef, useEffect } from "react";
import styles from "./organizational.module.scss";
import closeIcon from "../../../static/images/Close_Normal.svg";
import {getUniqueListBy} from "../../../utils";
import { getSearchValueData } from "../../../utils";
export const Organizational = ({
  id,
  name,
  label,
  optionValue,   
  groupBy,
  displayValue,
  onSelect,
  defaultValue,
  classes,
  required,
  selectAll = false,
  resetDefault,
  reportView = false,
  customError = false,
  assignPage=false,
  dropdownOpen, 
  disabled //added for TravelNurse feature
}) => {

  const loadDefault = () => {
   
    if (
      Array.isArray(optionValue)&&(optionValue?.length > 0) &&(Array.isArray(defaultValue)&&defaultValue?.length==optionValue?.length)&&
      (selectAll)
    ) {
     
      let obj = {};
      obj["cat"] = "all";
      obj[displayValue] = "All";
      obj["id"] = "orgall";
      obj["group"] = "orgall";
      setSelectVal([obj]);
      let uniqueListShowSelectVal = getUniqueListBy(
        [obj],
        "key"
      );
      setSelectVal(uniqueListShowSelectVal);
     
      const checkboxes = document.querySelectorAll('input[checkAll="all"]');
      if(document.getElementById("orgall")){
        document.getElementById("orgall").checked=true
      }

      for (let i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = true;
      }
      //setSelectVal([obj]);
      setSelectVal(uniqueListShowSelectVal);
      onSelectValue(optionValue);
      onSelect(optionValue)
      if(document.getElementById("orgall")){
        document.getElementById("orgall").checked=true
      }

    } else {
      let defaultData = [];
      Array.isArray(defaultValue) &&
        defaultValue.map((item) => {//NOSONAR
          const parentInd = soldTo?.findIndex((x) => x === item[groupBy]);
          const childInd = groupedOrg[item[groupBy]]?.findIndex(
            (x) => x[displayValue] === item[displayValue]
          );
         // let obj = item;
          let obj = Object.assign({selected: false}, item);
          obj["id"] = `org${parentInd}${childInd}`;
          obj["group"] = `org${parentInd}`;
          defaultData = [...defaultData, obj];
          let checkboxes = document.getElementById(
            `org${parentInd}${childInd}`
          );
          if (checkboxes) {
            checkboxes.checked = true;
          }
          let parentCheckboxes = document.getElementById(`org${parentInd}`);
          if (parentCheckboxes) {
            parentCheckboxes.checked = true;
          }
        })//NOSONAR
       
     // setSelectVal(defaultData);
      let uniqueListShowSelectVal = getUniqueListBy(
        defaultData,
        "key"
      );
      setSelectVal(uniqueListShowSelectVal);
      onSelectValue(defaultValue);
    }
   
  };
  useEffect(() => {
    loadDefault()
  }, []);
  const [dropdownOption, setDropdownOption] = useState(false);
  const [optValue, setOptValue] = useState(optionValue);
  const [searchDo, setSearchDo] = useState(false);
  useEffect(()=>{
   
    if(assignPage){
      setOptValue(optionValue)
      if(optionValue.length === 0) { setSelectVal([])}
    }
  },[optionValue])
  useEffect(()=>{ 
    if((document.getElementById(id)?.value&&searchDo)){
      const checkboxesClr = document?.querySelectorAll('input[checkAll="all"]');
      for (let i = 0, n = checkboxesClr.length; i < n; i++) {
        checkboxesClr[i].checked = false;
      }
      setOptValue(optionValue)
   setTimeout(() => {
    Array.isArray(optionValue)&&optionValue.map((text, ind) => {//NOSONAR
      Array.isArray(showSelectVal)&&showSelectVal.map((text1, ind1) => {//NOSONAR
        if (text.key === text1.key) {
          if( document.getElementsByClassName('checkcom')[ind]){
            let checkboxes =  document.getElementsByClassName('checkcom')[ind];
            if (checkboxes) {
              checkboxes.checked = true;
              return
            }
          }
          else if( document.getElementsByClassName(`checkcom_child_${text.facilityNumber}`)[0]){
            let checkboxes =  document.getElementsByClassName(`checkcom_child_${text.facilityNumber}`)[0];
            if (checkboxes) {
              checkboxes.checked = true;
              return
            }
          }
                   
        }
      })//NOSONAR
    });//NOSONAR
   setSearchDo(false)
      document.getElementById(id).value=''
    }, 200)
    }
    // if((document.getElementById(id)?.value&&searchDo)){
    //   setTimeout(() => {
    // const checkboxesClr = document?.querySelectorAll('input[checkAll="all"]');
    // for (let i = 0, n = checkboxesClr.length; i < n; i++) {
    //   checkboxesClr[i].checked = false;
    // }
    //   setOptValue(optionValue)
    //   let defaultData = [];
    //   Array.isArray(defaultValue) &&
    //     defaultValue.map((item) => {//NOSONAR
    //       const parentInd = soldTo?.findIndex((x) => x === item[groupBy]);
    //       const childInd = groupedOrg[item[groupBy]]?.findIndex(
    //         (x) => x[displayValue] === item[displayValue]
    //       );
    //      // let obj = item;
    //       let obj = Object.assign({selected: false}, item);
    //       obj["id"] = `org${parentInd}${childInd}`;
    //       obj["group"] = `org${parentInd}`;
    //       defaultData = [...defaultData, obj];
    //       let checkboxes = document.getElementById(
    //         `org${parentInd}${childInd}`
    //       );
    //       if (checkboxes) {
    //         checkboxes.checked = true;
    //       }
    //       let parentCheckboxes = document.getElementById(`org${parentInd}`);
    //       if (parentCheckboxes) {
    //         parentCheckboxes.checked = true;
    //       }
    //     })//NOSONAR
    // //  setSelectVal(defaultData);
    //   let uniqueListShowSelectVal = getUniqueListBy(
    //     defaultData,
    //     "key"
    //   );
    //   setSelectVal(uniqueListShowSelectVal);
    //   onSelectValue(defaultValue);
    //   setSearchDo(false)
    //   document.getElementById(id).value=''
    // }, 100)
    //   }
   
  },[defaultValue,searchDo])
  const dropdownShown = (e) => {
    setDropdownOption(true);
    e.stopPropagation();
  };
  const dropRef = useRef();
  const onSelectValue = (val) => {
    onSelect(val);
  //  setOptValue([])   
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  
// added for TravelNurse
useEffect(()=>{
  setDropdownOption(dropdownOpen);
},[dropdownOpen])


  const handleClickOutside = (e) => {
    if (
      dropRef !== null &&
      dropRef.current &&
      !dropRef.current.contains(e.target)
    ) {
      setDropdownOption(false);
    }
  };
  const groupByOption = (objectArray, groupByCat) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[groupByCat];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };
  const [showSelectVal, setSelectVal] = useState([]);
  useEffect(() => {
    if (resetDefault && !reportView) {
      setSelectVal([]);
    } else if (resetDefault && reportView) {
      loadDefault()
     /* if (
        optionValue?.length > 0 &&
        optionValue?.length === defaultValue?.length &&
       selectAll
      ) {
        let obj = {};
        obj["cat"] = "all";
        obj[displayValue] = "All";
        obj["id"] = "orgall";
        obj["group"] = "orgall";
        //setSelectVal([obj]);
        let uniqueListShowSelectVal = getUniqueListBy(
          [obj],
          "key"
        );
        setSelectVal(uniqueListShowSelectVal);
        const checkboxes = document.querySelectorAll('input[checkAll="all"]');
        for (let i = 0, n = checkboxes.length; i < n; i++) {
          checkboxes[i].checked = true;
        }
        setSelectVal(uniqueListShowSelectVal);
        onSelectValue(defaultValue);
      }*/
    }
  }, [resetDefault]);

  const getSelectedVal = (e, index, val, childInd) => {
  
    if (e.target.checked) {
     // let obj = val;
       let obj = Object.assign({selected: false}, val);
      obj["id"] = `org${index}${childInd}`;
      obj["group"] = `org${index}`;
      let valu = [...showSelectVal, obj];
     // setSelectVal(valu);
      let uniqueListShowSelectVal = getUniqueListBy(
        valu,
        "key"
      );

      uniqueListShowSelectVal = uniqueListShowSelectVal.filter(function(item) {
            return item.key !== 'All'
        })
        valu = valu.filter(function(item) {
          return item.key !== 'All'
      })
      setSelectVal(uniqueListShowSelectVal);
      onSelectValue(valu);
      //setOptValue(optionValue)
    } else {
      if (
        document.getElementById("orgall") &&
        document.getElementById("orgall").checked
      ) {
        document.getElementById("orgall").checked = false;
        let arr = [];
        Array.isArray(soldTo)&&soldTo.map((text, ind) => {//NOSONAR
          Array.isArray(groupedOrg[text])&&groupedOrg[text].map((text1, ind1) => {//NOSONAR
            if (val && text1.facilityNumber !== val.facilityNumber) {
              let addId = text1;
              addId["id"] = `org${ind}${ind1}`;
              addId["group"] = `org${ind}`;
              arr = [...arr, addId];
              return
            }
          })//NOSONAR
        });//NOSONAR
        /* let unique = [...new Set(arr)];
                setSelectVal(unique);
                onSelectValue(unique);
                remove([val], unique)*/
        let obj = val;
        obj["id"] = `org${index}${childInd}`;
        obj["group"] = `org${index}`;
        let unique = [...new Set(arr)];
        remove([obj], unique);
       // setSelectVal(unique);
       let uniqueListShowSelectVal = getUniqueListBy(
        unique,
        "key"
      );
      setSelectVal(uniqueListShowSelectVal);
        onSelectValue(unique);
      } else {
        let arr = [];
        soldTo.map((text, ind) => {//NOSONAR
          Array.isArray(groupedOrg[text])&&groupedOrg[text].map((text1, ind1) => {//NOSONAR
            //let addId = text1;
            let addId = Object.assign({selected: false}, text1);
            addId["id"] = `org${ind}${ind1}`;
            addId["group"] = `org${ind}`;
            arr = [...arr, addId];
            return
          });//NOSONAR
        })//NOSONAR
        // let unique = [...new Set(arr)];
        // setSelectVal(unique);
        // onSelectValue(unique);
        // remove([val], unique)
       // let obj = val;
        let obj = Object.assign({selected: false}, val);
        obj["id"] = `org${index}${childInd}`;
        obj["group"] = `org${index}`;
        let unique = [...new Set(arr)];
        //setSelectVal(unique);
        let uniqueListShowSelectVal = getUniqueListBy(
          unique,
          "key"
        );
        setSelectVal(uniqueListShowSelectVal);
        onSelectValue(unique);
        remove([obj]);

      }
    }
    if(document.getElementById(id).value){
      setSearchDo(true)
    }
    if(document.getElementById('orgall')){
      let orgallCls = document.getElementById('orgall');
      if(!orgallCls.classList.contains('orgallCls')){
        document.getElementById("orgall").checked = false;
      }
    }
    //searchValue();
  };
  const selectShitTo = (e, item, index) => {
    let checkboxes = document.getElementsByName(`group${index}`);
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = e.target.checked;
    }
    if (e.target.checked) {
      let group = Array.isArray(groupedOrg[item])&&groupedOrg[item].map((text, ind) => {
        //let addId = text;
        const addId = Object.assign({selected: false}, text);
        addId["id"] = `org${index}${ind}`;
        addId["group"] = `org${index}`;
        return addId;
      });
      let selectVal = [...showSelectVal];
      const valu = [...selectVal, ...group];
      let unique = [...new Set(valu)];
      //setSelectVal(unique);
      let uniqueListShowSelectVal = getUniqueListBy(
        unique,
        "key"
      );
      uniqueListShowSelectVal = uniqueListShowSelectVal.filter(function(item) {
        return item.key !== 'All'
    })
      unique = unique.filter(function(item) {
      return item.key !== 'All'
      })
      setSelectVal(uniqueListShowSelectVal);
      onSelectValue(unique);
    } else {
      if (
        document.getElementById("orgall") &&
        document.getElementById("orgall").checked
      ) {
        document.getElementById("orgall").checked = false;
        let arr = [];
        Array.isArray(soldTo)&&soldTo.map((text, ind) => {//NOSONAR
          Array.isArray(groupedOrg[text])&&groupedOrg[text].map((text1, ind1) => {//NOSONAR
            if (item !== text1.cat) {
             // let addId = text1;
              const addId = Object.assign({selected: false}, text1);
              addId["id"] = `org${ind}${ind1}`;
              addId["group"] = `org${ind}`;
              arr = [...arr, addId];
              return
            }
          })//NOSONAR
        });//NOSONAR
        let unique = [...new Set(arr)];

        remove(groupedOrg[item], unique);
        //setSelectVal(unique);
        let uniqueListShowSelectVal = getUniqueListBy(
          unique,
          "key"
        );
        setSelectVal(uniqueListShowSelectVal);
        onSelectValue(unique);
      } else {
      
          
        let arr = [];
        Array.isArray(soldTo)&&soldTo.map((text, ind) => {//NOSONAR
          Array.isArray(groupedOrg[text])&&groupedOrg[text].map((text1, ind1) => {//NOSONAR
            let addId = {...text1};
           //let addId = Object.assign({selected: false}, text1);
            addId["id"] = `org${ind}${ind1}`;
            addId["group"] = `org${ind}`;
            arr = [...arr, addId];
            return
          });//NOSONAR
        })//NOSONAR
        let unique = [...new Set(arr)];
       // setSelectVal(unique);
       let uniqueListShowSelectVal = getUniqueListBy(
        unique,
        "key"
      );

      setSelectVal(uniqueListShowSelectVal);
        onSelectValue(unique);
        remove(groupedOrg[item], unique);
        //remove(groupedOrg[item])
      }
    }

    if(document.getElementById('orgall')){
      let orgallCls = document.getElementById('orgall');
      if(!orgallCls.classList.contains('orgallCls')){
        document.getElementById("orgall").checked = false;
      }
    }
    if(document.getElementById(id)?.value){
      setSearchDo(true)
    }
    
  };
  const remove = (toRemove, unique) => {
    if (selectAll) {
      const removecheckboxes = document.getElementById("orgall");
      if (removecheckboxes) {
        removecheckboxes.checked = false;
      }
    }
    // let selectVal = unique !== undefined ? unique : [...showSelectVal]
    let selectVal = [...showSelectVal];
    toRemove &&
    Array.isArray(toRemove) &&
    toRemove.forEach((removeItem, i) => {
    selectVal &&
      Array.isArray(selectVal) &&
      selectVal.forEach((selectItems, j) => {//NOSONAR
            let checkboxes = document.getElementById(`${removeItem.group}`);
            if (checkboxes) {
              checkboxes.checked = false;
            }
            if (removeItem.id === selectItems.id) {
              selectVal.splice(j, 1);
            }
            else if (removeItem.key === selectItems.key) {
              selectVal.splice(j, 1);
            }
            let index = selectVal.indexOf(removeItem);
            if (index != -1) {
              selectVal.splice(index, 1);
            }
          })//NOSONAR
      });
    onSelectValue(selectVal);
   // setSelectVal(selectVal);
   let uniqueListShowSelectVal = getUniqueListBy(
    selectVal,
    "key"
  );
  setSelectVal(uniqueListShowSelectVal);
    if(document.getElementById(id)?.value){
      setSearchDo(true)
    }
  };
  const removeSelectList = (val) => {
    remove([val]);
    setDropdownOption(true);
    let checkboxes = document.getElementById(val.id);
    if (checkboxes) {
      checkboxes.checked = false;
    }
    if(document.getElementById(val.group)){
      let group = document.getElementById(val.group);
      group.checked = false;
    }
    
    if (val.id === "orgall") {
      const checkboxes = document.querySelectorAll('input[checkAll="all"]');
      for (let i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
      }
    }
  };
  const searchValue = (e) => {
    if (e?.target?.value !== "") {
      let data = []; 
      Array.isArray(optionValue)&&optionValue.map((item) => {
        /*const tlc = item[displayValue].toLowerCase();
        const cat = item['cat'].toLowerCase();*/
        const tlc = item[displayValue].split("|")[0].trim();
        const cat = item['cat'].split("|")[0].trim();
        const facilityNumberKey = item['facilityNumber'].toLowerCase();
        const searchVal = e.target.value.toLowerCase();
        if (tlc.indexOf(searchVal)=== 0) {
          return (data = [...data, item]);
        }
        if (cat.indexOf(searchVal)=== 0) {
          return (data = [...data, item]);
        }
        
        /*if (facilityNumberKey.indexOf(searchVal)!= -1) {
          return (data = [...data, item]);
        }*/
      });
      
      setOptValue(data)
      const checkboxesClr = document?.querySelectorAll('input[checkAll="all"]');
      for (let i = 0, n = checkboxesClr.length; i < n; i++) {
        checkboxesClr[i].checked = false;
      }
    } else {
      if(document.getElementById(id)){
        document.getElementById(id).value='Select'
        setSearchDo(true)
      }
    }
  };
  const checkAll = (e) => {
    const checkboxes = document.querySelectorAll('input[checkAll="all"]');
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = e.target.checked;
    }
    if (e.target.checked) {
      onSelectValue(optionValue);
      let obj = {};
      obj["cat"] = "all";
      obj[displayValue] = "All";
      obj["id"] = "orgall";
      obj["group"] = "orgall";
      //setSelectVal([obj]);
      let uniqueListShowSelectVal = getUniqueListBy(
        [obj],
        "key"
      );
      setSelectVal(uniqueListShowSelectVal);
     
    } else {
      setSelectVal([]);
      onSelectValue([]);
      onSelect([])
    }
    if(e.target.checked===false){
      setSelectVal([]);
      onSelectValue([]);
      onSelect([])
     // setOptValue([]) 
    }
  };
  const groupedOrg = groupByOption(optValue, groupBy);
  const soldTo = Object.keys(groupedOrg);
  return (
    <div className={classes ? classes.column : ""}>
      {label && (
        <label
          
          className={`${classes.labelClassName} ${styles.text_field_label}`}
        >
          {label}{" "}
          {required ? <span className={styles.astrick}>&#42;</span> : ""}
        </label>
      )}
      <div className={`${classes ? classes.inputColumn : ""}`}>
        <div className={`${styles.multi_wrapper} ${customError ? `${styles.error_border}` : ''}`}>
          {Array.isArray(showSelectVal)&&showSelectVal?.map((item, index) => {
            return (
              <span
                className={`${styles.chip}`}
                title={item[displayValue]}
                key={index}
                id={`orgSpan${item.facilityNumber}`}
              >
                {item[displayValue]}
                <img
                  className={`${styles.close_icon} check_remove`}
                  src={closeIcon}
                  alt=""
                  onClick={() => removeSelectList(item)}
                />
              </span>
            );
          })}
          
          <input
            type={'text'}
            className={!disabled ? `${styles.select_wrapper} form-control` : `${styles.disableOrg} form-control`} //added for disable when customernumberField Disabled
            placeholder="Select"
            onClick={(e) => dropdownShown(e)}
            ref={dropRef}
            id={id}
            name={name}
            onKeyUp={(e) => searchValue(e)}
            onChange={(e) => searchValue(e)}
           // defaultValue={''}
          />
        </div>
        <div        
          className={`dropdown-menu ${styles.dropdown_check_align} pl-0 pr-0 ${
            dropdownOption ? "show" : ""
          }`}
          ref={dropRef}
        >
          {optValue.length === 0 ? (
            <span className={`${styles.notFound}`}>No Options Available</span>
          ) : (
            ""
          )}
          {
            <>
              {selectAll && optValue.length !== 0 &&  !document.getElementById(id)?.value? (
                <div
                  className={`custom-control custom-checkbox ${styles.custom_checkbox}`}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input check_all orgallCls"
                    name={`group`}
                    checkAll="all"
                    id={`orgall`}
                    onChange={(e) => checkAll(e)}
                  />
                  <label className="custom-control-label" htmlFor={`orgall`}>
                    All
                  </label>
                </div>
              ) : (
                ""
              )}
              {Array.isArray(soldTo)&&soldTo.map((item, index) => {
                return (
                  <>
                    <div className={`${styles.sold_blk}`}>
                      <div
                        className={`${styles.heading_bold} ${styles.sold_to_left}`}
                      >
                        Sold To:
                      </div>
                      <div
                        className={`custom-control custom-checkbox ${styles.custom_checkbox}`}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input check_parent checkcom"
                          name={`group${index}`}
                          checkAll="all"
                          id={`org${index}`}
                          onChange={(e) => selectShitTo(e, item, index)}
                          orgspanid={`orgSpan${item.facilityNumber}`}
                          customkey={`${item}`}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`org${index}`}
                        >
                          {item}
                        </label>
                      </div>
                    </div>
                    {groupedOrg &&
                      groupedOrg[item] &&
                      groupedOrg[item].length > 0 &&
                      groupedOrg[item][0] &&
                      groupedOrg[item][0].shipToFacilities ===
                        "shipToFacilities" && (
                        <div className={`${styles.shift_blk}`}>
                          <div className={`${styles.heading_bold}`}>
                            Ship To:
                          </div>
                          { Array.isArray(groupedOrg[item])&&groupedOrg[item].map((shitToItem, ind) => {
                            return (
                              <div
                                className={`custom-control custom-checkbox ${styles.custom_checkbox}`}
                              >
                                <input
                                  type="checkbox"
                                  className={`custom-control-input check_child checkcom_child_${shitToItem.facilityNumber}`}
                                  name={`group${index}`}
                                  checkAll="all"
                                  id={`org${index}${ind}`}
                                  onChange={(e) =>
                                    getSelectedVal(e, index, shitToItem, ind)
                                  }
                                  orgspanid={`orgSpan${shitToItem.facilityNumber}`}
                                  customkey={`${item}`}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`org${index}${ind}`}
                                >
                                  {shitToItem[displayValue]}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </>
                );
              })}
            </>
          }
        </div>
        {customError && (
          <span className={styles.error_message}>Select facility</span>
        )}
      </div>
    </div>
  );
};