import React from "react";
import styles from "./csvImport.module.scss";
import ImportIcon from "../../../static/images/import-icon.png";

export function CsvImport({ uploading, handleImport}) {
  return (
    /* If someone is uploading disable the import functionality */
    !uploading ? (
      <button className={styles.import_block}>
        {/* Import button */}
        <img src={ImportIcon} alt="Import" />
        <label htmlFor="csv">Import</label>
        <input
          type="file"
          id="csv"
          className={styles.file_upload}
          accept=".csv, .xls, .xlsx, text/csv, application/csv,
                text/comma-separated-values, application/csv, application/excel,
                application/vnd.msexcel, text/anytext, application/vnd. ms-excel,
                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={handleImport}
        />
      </button>
    ) : (
      <button className={`${styles.import_block_disabled} ${styles.tooltip}`} disabled={uploading && true}>
        {/* Import button */}
        <img src={ImportIcon} alt="Import" />
        <label htmlFor="csv">Import</label>
          <span class={`${styles.tooltiptext}`}>
            {`Another import is in progress`}
          </span> 
        {/* <input
          type="file"
          id="csv"
          className={styles.file_upload}
          accept=".csv, .xls, .xlsx, text/csv, application/csv,
                text/comma-separated-values, application/csv, application/excel,
                application/vnd.msexcel, text/anytext, application/vnd. ms-excel,
                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={handleImport}
        /> */}
      </button>
    )
  );
}
