import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { withRouter, useParams } from 'react-router-dom'
import { ListView } from '../components/secondary/ListView'
import { DetailView } from '../components';
import { getCallBackListAsync, deleteFormListAsync, formDetailsAsync, upDateformDetailsAsync, setFormDetailsData } from '../app/customFormSlice';
import { setDeleteStatus } from '../app/appSlice';
import { Assign, FormBuilder as FormBuilderTab } from '../components/core/FormBuilder';
import { CUSTOM_FORM_LIST, LIST_VIEW_LIMIT } from '../constants'
import { List, Search, Sorting, Pagination, Delete, SelectedRow } from '../utils'

export let FormBuilder = ({ dispatch, history, location }) => {
    const [offSet, setOffSet] = useState(0)
    const [searchKey, setSearchKey] = useState('')
    const [sortType, setSortType] = useState('')
    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);
    const { formId } = useParams();

    useEffect(() => {
        dispatch(setFormDetailsData({}))
        if (formId) {
            dispatch(formDetailsAsync(formId,history))
        }
    }, [formId, dispatch])

    const formList = useSelector(state => state.forms.formList)
    const formData = useSelector(state => state.forms.formDetailsData)

    const deleteData = useSelector(state => state.app.deleteStatus)

    useEffect(() => {
        dispatch(setDeleteStatus({}))
    }, [dispatch])

    useEffect(()=>{
        if(deleteData.status==="success") {
            getFormList();
        }
    },[deleteData])

    const getFormList = () => {
        List(CUSTOM_FORM_LIST, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync)
    }

    useEffect(() => {
        setSortType('')
        setSearchKey('')
        getFormList();
    }, [dispatch])

    // search list
    const getSearchValue = (event) => {
        if (event && event.keyCode === 13) {
            if (event && event.target.value !== '') {
                setOffSet(0)
                setActivePage(1)
                setSearchKey(event.target.value)
                Search(event, CUSTOM_FORM_LIST, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType)
            }else{
                setOffSet(0)
                setActivePage(1)
                getFormList();
                setSearchKey('')
                setSortType('')
            }
        }
    }

    // sorting
    const sortingList = Sorting(CUSTOM_FORM_LIST, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackListAsync,searchKey,sortType,setSortType)


    // pagination
    const [activePage, setActivePage] = useState(1);
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        Pagination(data, setActivePage, CUSTOM_FORM_LIST, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackListAsync,searchKey,'',sortType)
    }

    // delete
    const deleteList = (data) => {
        Delete(data, dispatch, deleteFormListAsync, getFormList)
    }

    let showFixedColumn = !!formList?.forms && !!formList?.forms?.length
    let columns = [
        {
            Header: 'Form name',
            accessor: 'name',
            fixedColumn: showFixedColumn,
            // customWidth: "100px"
        },        
        {
            Header: 'Feature',
            accessor: 'feature',
            customWidth: "200px"
        },
        {
            Header: 'Sub feature',
            accessor: 'subFeature',
            customWidth: "635px"
        },
        {                                 //added for Add columns for create/update date
            Header: 'Created on',
            accessor: 'dateCreated',
            customWidth: "635px"
        },
        {
            Header: 'Updated on',
            accessor: 'dateUpdated',
            customWidth: "635px"
        }
    ]

    // details view
    const [detailsRowData, setDetailsRowData] = useState([])
    const getSelectedRowData = (data) => {
        SelectedRow(data, setDetailsRowData, 'formBuilder', history, isAdminView)
    }

    const upDateForm = (res) => {
        dispatch(upDateformDetailsAsync(res))
    }

    const handleCancel = () => {
        history.push('/admin/formBuilder')
    }

    const tabList = [
        {
            name: 'Form Build',
            content: <FormBuilderTab formData={formData} upDateForm={upDateForm} handleCancel={handleCancel} />
        },
        {
            name: 'Assign',
            content: <Assign formData={formData} upDateForm={upDateForm} />
        }
    ]

    const handleBack = () => {
    }

    return (
        <>
            {!formId && <ListView
                columns={columns}
                data={formList?.forms}
                metaData={formList?.metadata}
                sorting={true}
                rowSelect={isAdminView}
                getSelectedRowData={getSelectedRowData}
                detailView={true}
                pageRangeDisplayed={5}
                searchPlaceholder={"Form Name, Feature, Sub Feature"}
                getSearchValue={(e) => getSearchValue(e)}
                detailsRow={!formId}
                activePage={activePage}
                getActivePage={getActivePage}
                listSorting={sortingList}
                listType={"FormBuilder"}
                subHeading={'Form Builder'}
                showFixedColumn={showFixedColumn}
                fixedFullWidthSize={35}
                deleteListView={deleteList}
                searchKey={(searchKey)?searchKey:''}
                offset={offSet}
                sortType={sortType?sortType:''}
            />}
            {formId &&
                <DetailView
                    detailsRowData={detailsRowData}
                    tabList={tabList}
                    title="Form Builder"
                    dispatch={dispatch}
                    defaultIndex={0}
                    detailsRow={formId}
                    handleBack={handleBack}
                    subHeading={'Form Builder'}
                    backLink='/admin/formBuilder'
                />
            }
        </>
    )
}
FormBuilder.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}
FormBuilder = connect()(withRouter(FormBuilder));