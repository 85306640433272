import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import style from './activityLog.module.scss';
import { getActivityLogAsync } from '../../../app/patientSlice';
import { hrsAgo } from '../../../utils';

export const ActivityLog = ({ patientData }) => {
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const activityLogGetData = useSelector(state => state.patient.activityLogGetData)
  useEffect(() => {
    if(patientId){
     dispatch(getActivityLogAsync({ patientId }));
    }
  }, [dispatch])

  return (
    patientData?.moreInfo?.ne1WMSStatus === 1 ? 
      <div >
        <div className="col-sm-12 single-product-block p-0">
          <div className="row">
            {activityLogGetData?.length === 0 ? <div className='p-4'>There is no activity Log</div> :
              <div className="col-12">
                {activityLogGetData && activityLogGetData.map((item, index) => {
                  return (
                    <>
                      <p className={style.activity_log}>
                        <strong className = "pr-2">{`${item?.activity}`}</strong>
                        {item?.woundRecordName !== null ? <>Wound id: <strong className = "pr-2">{item?.woundRecordName}</strong></> : ''}
                        {item?.reEvalId !== null? <>Re-Eval id : <strong className = "pr-2">{item?.reEvalId}</strong></> : ''}
                        {item?.createdBy !== null ? <> User: <strong className = "pr-2"> {item?.createdBy}</strong></> : ''}
                        {item?.createdDate !== null ? <> Date: <strong>{hrsAgo(item?.createdDate)}</strong></> : ''}
                      </p>
                    </>
                  )
                })}
              </div>
            }
          </div>
        </div>
      </div>
    :
    <div className="m-3 p-3"><h3>Patient is Inactive</h3></div>
  )
}
