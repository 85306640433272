/* Wound assessment */

import { tissueInput } from "./userValidator";

export const handleDeleteData = (historyAssessmentId, deleteHistoryAssessment, assessmentId, deleteAssessment, setAssessmentId, setHistoryAssessmentId, setShowDeleteConfirmation) => {
    if (historyAssessmentId) {
      deleteHistoryAssessment(assessmentId, historyAssessmentId);
    } else {
      deleteAssessment(assessmentId);
      setAssessmentId(undefined);
    }
    setHistoryAssessmentId(undefined);
    setShowDeleteConfirmation(false)
}

/* Sign and Lock */

export const lockSuccessAsyncData = (setSigned, setLocked, assessmentDetail, patientId, assessmentId, getCharDetails, dispatch, woundAssessmentUpdateAsync) => {  
  setSigned(true)
  setLocked(true)
  // Updating 'Signed', 'Locked' and changing 'assessmentStage' to 'signedlocked'
  const assessment = { ...assessmentDetail } // Copying assessment details
  assessment['assessmentStage'] = 'signedlocked';
  assessment['signed'] = 1;
  assessment['locked'] = 1;
  const payload = {
      patientId,
      assessmentId,
      assessment,
      getCharDetails, 
  }
  dispatch(woundAssessmentUpdateAsync(payload))
}

/* Handle input from Characteristic Tissue */

export const handleInputData = (e, i, id, inputFlag, setInputFlag, resultOne, setResultOne, setValidPercent, setStartedEditing) => {
  setStartedEditing(true)
  if(!tissueInput(e.target.value)){
    e.target.value = null;
    setValidPercent(id)
    return
  }else{
    setValidPercent(false)
  }
  const temp = [...inputFlag]; // Copy the object to temp object
  temp[i] = false;  // For hiding input field 
  if(e.target.value){
    setInputFlag(temp)
  }
 
  // Finding if the id already exists in results array. If it exists,
  // remove that item, add new item (object) which has same id (the one we removed) and percentage
  resultOne && resultOne?.length > 0 && resultOne.map((data, index) => {
      if (data.id === id) {
          const temp = [...resultOne]
          temp.splice(index, 1)
          // Below we are pushing an object which has both 'id' and 'percentage'
          temp.push({
              id: id,
              woundFillingPercentage: e.target.value
          })
          setResultOne([...temp])
      }
  })
}


export const handleInputDataOverride = (e, i, id, inputFlagOverride, setInputFlagOverride, resultTwo, setResultTwo, setValidPercent, setStartedEditing) => {
  setStartedEditing(true)
  if(!tissueInput(e.target.value)){
    e.target.value = null;
    setValidPercent(id)
    return
  }else{
    setValidPercent(false)
  }
  const temp = [...inputFlagOverride]; // Copy the object to temp object
  temp[i] = false;  // For hiding input field 
  if(e.target.value){
    setInputFlagOverride(temp)
  }
 
  // Finding if the id already exists in results array. If it exists,
  // remove that item, add new item (object) which has same id (the one we removed) and percentage
  resultTwo && resultTwo?.length > 0 && resultTwo.map((data, index) => {
      if (data.id === id) {
          const temp = [...resultTwo]
          temp.splice(index, 1)
          // Below we are pushing an object which has both 'id' and 'percentage'
          temp.push({
              id: id,
              woundFillingPercentage: e.target.value
          })
          setResultTwo([...temp])
      }
  })
}

/* Patients.js container */

export const getSearchValueData = (event, PATIENT, customerNumber, offSet, LIST_VIEW_LIMIT, setPatients, setSearchKey, dispatch, getCallBackListAsync, isAdminView,selectedFacility,sortType) => {
  if ((event.keyCode === 13)||(event.target.value)) {
    const searchPayLoad = {};
    searchPayLoad['url'] = PATIENT
    searchPayLoad['method'] = 'Get'
    searchPayLoad['queryParam'] = { customerNumber: customerNumber, offSet: isNaN(offSet)===true?0:offSet, limit: LIST_VIEW_LIMIT, search: event.target?.value?event.target.value.trim():'', pagetype : isAdminView ? "patientadmin": "patient",sort:sortType?sortType:''}
   if(selectedFacility){
    searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{facilityNumber:selectedFacility}} ;
   }   
    searchPayLoad['setMethod'] = setPatients
    setSearchKey(event.target?.value?event.target.value.trim():'');
    dispatch(getCallBackListAsync(searchPayLoad));
  }
}

export const getActivePageData = (data, setActivePage, PATIENT, customerNumber, LIST_VIEW_LIMIT, setPatients, dispatch, getCallBackListAsync,search,isAdminView,selectedFacility,sortType) => {  
  setActivePage(data);
  const paginationPayLoad = {};
  paginationPayLoad['url'] = PATIENT
  paginationPayLoad['method'] = 'Get'
  paginationPayLoad['queryParam'] = {
    customerNumber: customerNumber,
    offSet: (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 :
      (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT, 
      limit: LIST_VIEW_LIMIT,
       pagetype : isAdminView ? "patientadmin": "patient",
       sort:sortType?sortType:''
  }
  if(search !=""){
    paginationPayLoad['queryParam']={...paginationPayLoad['queryParam'],...{search:search}} ;
  }
  if(selectedFacility){
    paginationPayLoad['queryParam']={...paginationPayLoad['queryParam'],...{facilityNumber:selectedFacility}} ;
}
  paginationPayLoad['setMethod'] = setPatients
  dispatch(getCallBackListAsync(paginationPayLoad));
}

export const facilityListViewData = (value, setSelectedFacility, PATIENT, customerNumber, LIST_VIEW_LIMIT, setPatients, dispatch, getCallBackListAsync,isAdminView) => {
    setSelectedFacility(value)
    const facilityPayLoad = {};
    facilityPayLoad['url'] = PATIENT
    facilityPayLoad['method'] = 'Get'
    facilityPayLoad['queryParam'] = { customerNumber: customerNumber, facilityNumber: value, offSet: 0, limit: LIST_VIEW_LIMIT,pagetype : isAdminView ? "patientadmin": "patient"}
    facilityPayLoad['setMethod'] = setPatients
    dispatch(getCallBackListAsync(facilityPayLoad))
}

/* Status */

export const saveStatus = (e, setConfirmationModal, patientData, dispatch, patientUpdateAsync, setEditable, editable) => {
  e.preventDefault();
  let patientStatus = (e.target.patientStatus && e.target.patientStatus.value && e.target.patientStatus.value !== null) ? e.target.patientStatus.value : '';
  const statusDate = (e.target.statusDate && e.target.statusDate.value && e.target.statusDate.value !== null) ? e.target.statusDate.value : '';
  const statusTime = (e.target.statusTime && e.target.statusTime.value && e.target.statusTime.value !== null) ? e.target.statusTime.value : '';
  const statusDateTime = (statusDate && statusTime) ? new Date(statusDate + ' ' + statusTime).toISOString() : '';
  const tempData = {
    statusInfo: {
      status: patientStatus,
    }
  }
  if (patientStatus === "discharged") {
    setConfirmationModal(true)
    tempData.statusInfo['dischargeDate'] = statusDateTime;
  } else {
    tempData.statusInfo['admissionDate'] = statusDateTime;
    const result = { ...patientData, ...tempData }
    dispatch(patientUpdateAsync(result,'ADTSTATUS'))
    //setEditable(!editable)
  }
}

export const yesConfirmStatus = (e, PATIENT_STATUS_ID, patientData, dispatch, patientUpdateAsync, setEditable, editable, setConfirmationModal) => {
  let patientStatus = (document.getElementById(PATIENT_STATUS_ID).value) ? document.getElementById(PATIENT_STATUS_ID).value : '';
  const statusDate = (document.getElementById("statusDate").value) ? document.getElementById("statusDate").value : '';
  const statusTime = (document.getElementById("statusTime").value) ? document.getElementById("statusTime").value : '';
  const statusDateTime = (statusDate && statusTime) ? new Date(statusDate + ' ' + statusTime).toISOString() : '';
  const tempData = {
    statusInfo: {
      status: patientStatus,
    }
  }
  tempData.statusInfo['dischargeDate'] = statusDateTime;
  const result = { ...patientData, ...tempData }
  dispatch(patientUpdateAsync(result,'ADTSTATUS'))
 // setEditable(!editable)
  setConfirmationModal(false)
}

export const handleStatusChangeStatus = (e, ADMITTED_DATE_LBL, RE_ADMITTED_DATE_LBL, DISCHARGED_DATE_LBL, setDyntLbl) => {
  let value = e.target.value;
  let label = '';
  if (value === "admitted") {
    label = ADMITTED_DATE_LBL;
  } else if (value === "readmitted") {
    label = RE_ADMITTED_DATE_LBL;
  }
  else if (value === "discharged") {
    label = DISCHARGED_DATE_LBL;
  }
  if (label !== '') {
    setDyntLbl(label);
  }
}

// History cause edit
export const getHSDataHelper = (cat, value, setPi, setSelectedCause, setCategory) => {
  if (cat === 'pi') {
      setPi(true)
  }
  if (cat !== 'pi') {
      setPi(false)
  }
  setSelectedCause(value)
  setCategory(cat)
}

export const selectedCheckHelper = (tid, tissueIds) => {
  if (tissueIds?.indexOf(tid) !== -1) {
      return true;
  } else {
      return false;
  }
}

export const selectedCheckHelperOverride = (tid, tissueIds) => {
  if (tissueIds?.indexOf(tid) !== -1) {
      return true;
  } else {
      return false;
  }
}

export const openDeleteConfirmationData = (_assessmentId, _historyAssessmentId, setAssessmentId, setHistoryAssessmentId, setShowDeleteConfirmation )=>{
   setAssessmentId(_assessmentId);
    setHistoryAssessmentId(_historyAssessmentId);
    setShowDeleteConfirmation(true)
}

export const modalOpenData = (id, title, setAssessmentTitle, setModalTitlteId, setmodalShow) =>{
  setAssessmentTitle(title);
    setModalTitlteId(id);
    setmodalShow(true);
}

export const allowEnterData = (e) =>{
  if (e.key === 'Enter') {
    e.stopPropagation();
  }
}


export const handleSelectHelper = (e, i, id, ai, inputFlag, checkFlag, setInputFlag, setCheckFlag, resultOne, setResultOne, setCheckDefault, setStartedEditing, setNoPercentError) => {
  setStartedEditing(true)
  setNoPercentError(false)
  const tempInput = [...inputFlag]; // Copy our input field array to temp
  const tempCheck = [...checkFlag]; // Copy our input field array to temp
  if(ai === 1){
    setCheckDefault(null)
  }
  if (e.target.checked) { // If checkbox checked 
      // Below if condition is for 'tissue types' which don't have percent and input field
      if (tempInput[i] === false) {
          tempInput[i] = true; // Display input field
      }
      if (tempCheck[i] === false) {
          tempCheck[i] = true; // Enable checkbox
      }
      setInputFlag(tempInput) // Update state 
      setCheckFlag(tempCheck) // Update state 
      // If there are some tissues already selected, then map through those tissue, 
      // else add the selected item 
      if (resultOne && resultOne?.length > 0) {
        resultOne.map((data) => {
              if (data.id !== id) {
                  const temp = [...resultOne]
                  temp.push({ // This is what we are going to pass to API
                      id: id,
                  })
                  setResultOne([...temp]) // We are copying the above selectedId to an 'result' array
              }
          })
      } else {
          const temp = [];
          temp.push({ id: id })
          setResultOne([...temp])
      }
  }
  if (!e.target.checked) {
      if (tempInput[i] === true) {
          tempInput[i] = false; // Hide input field
      }
      if (tempCheck[i] === true) {
          tempCheck[i] = false; // Hide input field
      }
      setInputFlag(tempInput) // Update state 
      setCheckFlag(tempCheck) // Update state 
      resultOne && resultOne?.length > 0 && resultOne.map((data, index) => {
          if (data.id === id) {
              const temp = [...resultOne]
              temp.splice(index, 1)
              setResultOne([...temp]) // we are remvoing the item which got unselected
          }
      })
  }
}


export const handleSelectHelperOverride = (toggleOn, e, i, id, ai, inputFlagOverride, checkFlagOverride, setInputFlagOverride, setCheckFlagOverride, resultTwo, setResultTwo, setCheckDefault, setStartedEditing, setNoPercentError) => {
  setStartedEditing(true)
  setNoPercentError(false)
  const tempInput = [...inputFlagOverride]; // Copy our input field array to temp
  const tempCheck = [...checkFlagOverride]; // Copy our input field array to temp
  if(ai === 1){
    setCheckDefault(null)
  }
  if (e) { // If checkbox checked 
      // Below if condition is for 'tissue types' which don't have percent and input field
      if (tempInput[i] === false) {
          tempInput[i] = true; // Display input field
      }
      if (tempCheck[i] === false) {
          tempCheck[i] = true; // Enable checkbox
      }
      if(toggleOn){
        setInputFlagOverride(tempInput) // Update state 
        setCheckFlagOverride(tempCheck) // Update state 
        }
        else if(!toggleOn){
          let tempArrayOverrideInput = new Array(inputFlagOverride?.length).fill(false);
          let tempArrayOverrideCheck = new Array(checkFlagOverride?.length).fill(false);
          setInputFlagOverride(tempArrayOverrideInput) // Update state 
          setCheckFlagOverride(tempArrayOverrideCheck) // Update state 
        } 
      // If there are some tissues already selected, then map through those tissue, 
      // else add the selected item 
      if (resultTwo && resultTwo?.length > 0) {
        resultTwo.map((data) => {
              if (data.id !== id) {
                  const temp = [...resultTwo]
                  temp.push({ // This is what we are going to pass to API
                      id: id,
                  })
                  if(toggleOn){
                    setResultTwo([...temp]) // We are copying the above selectedId to an 'result' array
                  }
                  else if(!toggleOn){  // If Toggle OFF 'result' is Empty array
                    setResultTwo([])
                  }
              }
          })
      } else {
          const temp = [];
          temp.push({ id: id })
          if(toggleOn){
            setResultTwo([...temp])
          }
          else if(!toggleOn){ // Intially If Toggle OFF 'result' is Empty array
            setResultTwo([])
          }
      }
  }
  if (!e) {
      if (tempInput[i] === true) {
          tempInput[i] = false; // Hide input field
      }
      if (tempCheck[i] === true) {
          tempCheck[i] = false; // Hide input field
      }
      setInputFlagOverride(tempInput) // Update state 
      setCheckFlagOverride(tempCheck) // Update state 
      resultTwo && resultTwo?.length > 0 && resultTwo.map((data, index) => {
          if (data.id === id) {
              const temp = [...resultTwo]
              temp.splice(index, 1)
              setResultTwo([...temp]) // we are remvoing the item which got unselected
          }
      })
  }
}



export const optionsMapperMove = (optionArray, key) => {
  const res = optionArray && optionArray.length > 0 && optionArray?.map((data) => {
      if (data?.key === key) {
          return data?.value
      }
  })
  return res
}

export const mapperMove = (source, name, bool) => {
  if (source) {
      if (Array.isArray(source[name]) && source[name].length > 0) {
          return source[name]
      }
      else if (!Array.isArray(source[name]) && source[name] !== '' && source[name] !== null) {
          if (!bool) {
              return source[name]
          }
          else if (bool) {
              return source[name] ? 'Yes' : 'No'
          }
      }
      else {
          return false
      }
  }
}


export const mainCheckerMove = (source, name) => {
  if (source) {
      if (Array.isArray(source[name]) && source[name].length > 0) {
          return true
      }
      else if (!Array.isArray(source[name]) && source[name] !== '' && source[name] !== null && source[name] !== undefined) {
          return true
      }
      else {
          return false
      }
  }
}

export const handleClickMove = (html2canvas, jsPDF, canvases, header, patientId, assessmentId, patientData) => {
  html2canvas(document.getElementById('pdf'), { scale: 2 }).then(function (canvas) {

      var imgWidth = 210;
      var pageHeight = 294;

      var doc = new jsPDF('p', 'mm');

      let size = 0

      // Below is the moving the content to next page based on fitting
      canvases.forEach((data, i) => {
          if (size + data.height > pageHeight) {
              doc.addPage();
              doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
              size = header.height
          }
          if (size === 0) {
              doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
              size = header.height
          }
          doc.addImage(data.imgData, 'PNG', 0, size, imgWidth, data.height);
          size += data.height
      })

      var pageCount = doc.internal.getNumberOfPages(); //Total Page Number

      // Below function is for setting page number and footer
      for (let i = 0; i < pageCount; i++) {
          doc.setPage(i);
          const pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
          doc.setFontSize(7.5);
          doc.setTextColor('#999999')
          doc.text('Page: ' + pageCurrent + '/' + pageCount, 8, 5);
          doc.setDrawColor('#eee')
          doc.line(0, 291, 210, 291)
          doc.text('Wound Evaluation | Confidential', 5, 295);
      }

      doc.save('Wound_Evaluation_'+patientData?.firstName+'_'+patientData?.lastName+'_'+assessmentId);
  });

}

export const assessmentStageGetterMove = (data) => {
  if(data === 'pendingreview'){
    return 'Pending Review'
  }else if(data === 'pendingassessment'){
    return 'Pending Assessment'

  }else if(data === 'signedlocked'){
    return 'Signed & Locked'
  }
}

// export const pdfPageMove = (data, dispatch, setLoading, history, patientId, modalRefresh) => {
//   dispatch(setLoading(true))
//   history.push('/patients/' + patientId + '/assessment/' + data + '/pdfdownload')
//   modalRefresh();
// }

export const pdfPageMove = (data, dispatch, setLoading, history, patientId, modalRefresh, showImage) => {
  dispatch(setLoading(true))
  history.push({
    pathname : '/patients/' + patientId + '/assessment/' + data + '/pdfdownload',
    state : showImage
});
  modalRefresh();
}