import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Header } from '../../secondary/Header';
import style from './surveyResults.module.scss';
import { Form, Button, TextField, DateTimePicker, NewMultiSelect } from '../../primary';
import rightArrow from '../../../static/images/right_arrow.svg';
import Spreadsheet from '../../../static/images/ExportSpredsheet.svg';
import { format, subDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { checkCustomer, getSurveyCsv, getSurveyListOptions, getSurveyResults, getSurveyResultsAlt, setCustomerAvailable, setSurveryListOptions, setSurveyResults,setIsSuveyDownloadReady } from '../../../app/woundReportSlice';
import { DataTable, Pagination } from '../../secondary';
import { LIST_VIEW_LIMIT } from '../../../constants';
import Loader from '../../../static/images/spinner.svg';
import { CSVLink } from 'react-csv';
import { customFromDatePickerChangeMove, customToDatePickerChangeMove, disableChangeMove, getActivePageMove, handleCustomerNumberMove, resetDataMove } from '../../../utils/surveyHelper';

export const SurveyResults = ({history}) => {

  /* Use hook form */
  const { register, handleSubmit, control, errors, reset, setError, watch, getValues, clearErrors } = useForm();
  const customerName = useSelector(state => state.user.customerName);

  //restrict when directly open the page
  const userDetails = useSelector(state => state.user);
  const adminView = userDetails?.role     
  useEffect(()=>{        
      window.onload = function() { 
          if (adminView === "ExternalStandardUser") {  
              history.push('/unAuthorized'); 
          }       
      }    
  },[]) //restrict when directly open the page

    // Use dispatch

  const dispatch = useDispatch()

  /* Handle multiselect dropdown */
  const customerRef = useRef();
  const surveyRef = useRef();

  // Refs for datepicker
  const dateRangeRef1 = useRef();
  const dateRangeRef2 = useRef();

  const [showApplyButton, setShowApplyButton] = useState(false); // For displaying and hiding apply button
  const [disableCustomerGroup, setDisableCustomerGroup] = useState(false); // Flag to disable/enable customer input field
  const [offSet, setOffSet] = useState(0)
  const [sortType, setSortType] = useState(null) // For mixing sorting with pagination

  const [fromDate, setFromDate] = useState(subDays(new Date(), 30))
  const [toDate, setToDate] = useState(new Date())

  const [fromDateError, setFromDateError] = useState(null)
  const [toDateError, setToDateError] = useState(null)

  const [customerOptions, setCustomerOptions] = useState([]) // Customer options value
  const [surveyOptions, setSurveyOptions] = useState([]) // Survey options from API
  //const [customerGroupData, setCustomerGroupData] = useState(['all']); // Default value of customer 
  const [surveyNameData, setSurveyNameData] = useState([]); // We are sending values stored in this state to API for getting grid view results

  // We are getting sleected customer and survey from multi select
  const [selectedSurvey, setSelectedSurvey] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState([])

  // Setting default value for customer and survey
  const [customerDefault, setCustomerDefault] = useState(['all']) // default value for survey
  const [surveryDefault, setSurveyDefault] = useState(['all']) // default value for customer

  const [resetStart, setResetStart] = useState(false)
  const [surveyResultsCSV, setSurveyResultsCSV] = useState('')

  // Initially we are clearing all data
  useEffect(() => {
    dispatch(setSurveryListOptions({})) // Survey list options
    dispatch(setSurveyResults({})) // Results of grid view
    dispatch(setCustomerAvailable(null)) // To check wheater customer exists or not
  }, [])

  // Setting values for customer options, multi select issue, so we using set timeout
  useEffect(() => {
    setTimeout(() => {
      setCustomerOptions([
       // { key: 'acrossallcustomers', value: 'Across all customers'},
        // { key: 'all', value: 'All' },
        { key: 'surveyassignedtospecificcustomerno', value: 'Customer Number'},
        { key: 'postacute', value: 'Post acute' },
        { key: 'acute', value: 'Acute'},
        { key: 'users', value: 'User(s)'},
      ])
    }, 100)
  }, [])


  // DATE SECTION

  // onSelect from date checker
  const customFromDatePickerChange = (data) => {
    if(data){
      customFromDatePickerChangeMove(data, setFromDate, setFromDateError, setToDateError, setShowApplyButton)
    }
  
  }

  // onSelect to calendar date checker
  const customToDatePickerChange = (data) => {
    if(data){
    customToDatePickerChangeMove(data, setToDate, setFromDateError, setToDateError, setShowApplyButton)
  }
  }


  // CUSTOMER SECTION

  // Disable Customer Group Selection field funtion
  const customerNumber = watch('customerNumber');

  // const disableChange = () => {
  //   disableChangeMove(setShowApplyButton, customerNumber, setDisableCustomerGroup, setCustomerDefault)
  // }

  // Function to handle custmer number

  const handleCustomerNumber = () => {
    handleCustomerNumberMove(setSurveyNameData, setError, dispatch, checkCustomer, setSurveyDefault, setCustomerDefault, setShowApplyButton, setDisableCustomerGroup)
  }

  const customerAvailable = useSelector(state => state.woundreport.customerAvailable)

  // If customer doesn't exists show error
  useEffect(() => {
    if (customerAvailable === true) {
      clearErrors(['customerNumber'])
      // If customer exists calling API to get survey based on the customer number
      getSurveyList(document.getElementById('customerNumber').value, [])
      dispatch(setCustomerAvailable(null))
    }
    if (customerAvailable === false) {
      setError('customerNumber', {
        type: "manual",
        message: "Customer doesn't exists"
      })
      dispatch(setCustomerAvailable(null))
    }
  }, [customerAvailable])

  // useEffect(() => {
  //   if(surveyListOptions?.survey && surveyListOptions?.survey?.length > 0){
  //     setSurveyDefault(['all'])
  //   }else{
  //     setSurveyDefault([])
  //   }
  // }, [surveyListOptions])

  // CUSTOMER SECTION

  // Function to get survey list options

  const getSurveyList = (number, group) => {
    const payload = {
      customerNumber: number,
      customerGroup: [...group]
    }
    dispatch(getSurveyListOptions(payload))
  }

  const surveyListOptions = useSelector(state => state.woundreport.surveryListOptions)

  // All the below three use effects is for first time calling survey
  // Get initial survery list with default value of 'all' for customer, then 
  // call another API to get list view data

  const [firstTime, setFirstTime] = useState(true)

  // First make API call to get 'survey list options'
  useEffect(() => {
    const payload = {
      customerNumber: null,
      customerGroup: ['all', 'surveyassignedtospecificcustomerno', 'acute', 'postacute', 'users']
    }
   // dispatch(getSurveyListOptions(payload))
  }, [])

  // Once we get, we have 'all' as default for survey list options, for 'all', we need to load all 'surveys'.
  // Below condition will load all survey numbers when 'all' is selected.
  // useEffect(() => {
  //   if (surveyNameData[0] === 'all') {
  //     const temp = []
  //     if (surveyListOptions?.survey && surveyListOptions?.survey?.length > 0) {
  //       surveyListOptions.survey.map((data) => {
  //         temp.push(data.id)
  //       })
  //     }
  //     setAllSurvey([...temp])
  //   }
  // }, [surveyListOptions])

  // Now we are calling survey grid list API with 'all'
  useEffect(() => {
    if (firstTime) {
      if (surveyNameData && surveyNameData.length > 0) {
        const firstCall = {
          dateFrom: format(subDays(new Date(), 30), "yyyy/LL/dd"),
          dateTo: format(new Date(), "yyyy/LL/dd"),
          surveyId: surveyNameData
        }
        dispatch(getSurveyResults(firstCall)).then(() => setFirstTime(false))
        // The below call is for csv download
       // dispatch(getSurveyCsv(firstCall, sortType))
       setSurveyResultsCSV(firstCall)
      }
    }
  }, [firstTime, surveyNameData])

  // Reset API call
  useEffect(() => {
    if (resetStart) {
      if (surveyNameData && surveyNameData.length > 0) {
        const firstCall = {
          dateFrom: format(subDays(new Date(), 30), "yyyy/LL/dd"),
          dateTo: format(new Date(), "yyyy/LL/dd"),
          surveyId: surveyNameData
        }
        dispatch(getSurveyResults(firstCall))
        // The below call is for csv download
        setSurveyResultsCSV(firstCall)
       // dispatch(getSurveyCsv(firstCall, sortType))
        setTimeout(() => setResetStart(false), 3000)
      }
    }
  }, [resetStart, surveyNameData])


  // Process survery list options and make it into key value pair for feeding into multi select
  useEffect(() => {
    if (surveyListOptions?.survey && surveyListOptions?.survey?.length > 0) {
      const arr = []
      surveyListOptions.survey.map((data) => {
        arr.push({ key: data.id, value: data.surveyName })
      })
      setSurveyOptions([...arr])
      setSurveyDefault(['all'])
    } else if (surveyListOptions?.survey && surveyListOptions?.survey?.length === 0) {
      setSurveyOptions([])
      setSurveyDefault([])
    } else {
      setSurveyOptions([])
      setSurveyDefault([])
    }
  }, [surveyListOptions])

  const surveyResults = useSelector(state => state.woundreport.surveyResults)
  const surveyResultsCsv = useSelector(state => state.woundreport.surveyResultsCsv)

  // On change value
  const onChange = () => {
    setShowApplyButton(true)
  }

  // Update customer data
  useEffect(() => {
    let temp = []
    selectedCustomer && selectedCustomer?.length !== 0 && selectedCustomer?.forEach((data) => {//NOSONAR
      temp.push(data.key)
    }) //NOSONAR
    let itsAll = [];
    if (temp.indexOf('acute') !== -1 && temp.indexOf('postacute') !== -1 && temp.indexOf('all') !== -1 && temp.indexOf('surveyassignedtospecificcustomerno') !== -1 && temp.indexOf('users') !== -1) {
      itsAll.push('surveyassignedtospecificcustomerno', 'all', 'acute', 'postacute','users');
    } else {
      itsAll = [...temp]
    }
    if (temp.length !== 0) {
      getSurveyList(null, [...itsAll])
    }
  }, [selectedCustomer])

  // Update survey data
  useEffect(() => {
    let temp = []
    selectedSurvey && selectedSurvey?.length !== 0 && selectedSurvey?.forEach((data) => { //NOSONAR
      temp.push(data.key)
    }) //NOSONAR
    setSurveyNameData([...temp])
  }, [selectedSurvey])

  // List view
  const columns = [
    {
      Header: 'First name',
      accessor: 'firstName',
      restWidth: '150px'
    },
    {
      Header: 'Last name',
      accessor: 'lastName',
      restWidth: '150px'
    },
    {
      Header: 'Role',
      accessor: 'role',
      restWidth: '150px'
    },
    {
      Header: 'Phone',
      accessor: 'phoneNo',
      restWidth: '150px'
    },
    {
      Header: 'Email',
      accessor: 'emailId',
      restWidth: '200px'
    },
    {
      Header: 'Survey name',
      accessor: 'surveyName',
      restWidth: '150px'
    },
    {
      Header: 'Survey published',
      accessor: 'surveypublishedDate',
      restWidth: '230px'
    },
    {
      Header: 'Submission date',
      accessor: 'submittedOn',
      restWidth: '230px'
    },
    {
      Header: 'Customer number',
      accessor: 'customerNumber',
      restWidth: '170px'
    },
    {
      Header: 'Customer name',
      accessor: 'customerName',
      restWidth: '250px'
    }
  ]

  // SORTING SECTION

  // We are storing data in ref since useCallback will take old value
  // const ref = useRef([...surveyNameData])

  // useEffect(() => {
  //   ref.current = [...surveyNameData]
  // }, [surveyNameData])

  // Payload
  const sortingPayLoad = {};
  sortingPayLoad['url'] = '/reports/survey'
  sortingPayLoad['method'] = 'POST'
  sortingPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: isNaN(offSet)===true?0:offSet }
  sortingPayLoad['setMethod'] = setSurveyResults
  sortingPayLoad['setSortType'] = setSortType
  sortingPayLoad['asyncMethod'] = getSurveyResultsAlt
  sortingPayLoad['toBeSent'] = {
    dateFrom: format(fromDate, "yyyy/LL/dd"),
    dateTo: format(toDate, "yyyy/LL/dd"),
    surveyId: surveyNameData
  }
  // Function which handles sort
  const handleSort = (data) => {
    if (sortingPayLoad) {
      const sortingColumn = data.sortBy[0]
      const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
      sortingPayLoad['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
      sortingPayLoad['queryParam']['offSet'] = offSet
      // The below if condition is for 'Sorting' + 'Pagination'
      if (sortingPayLoad.setSortType) {
        sortingPayLoad.setSortType(sortingPayLoad['queryParam']['sort'])
      }
      dispatch(sortingPayLoad.asyncMethod(sortingPayLoad));
    }
  }



  // SORTING SECTION

  // PAGINATION SECTION
  const [activePage, setActivePage] = useState(1);

  const getActivePage = (data) => {
    setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
    getActivePageMove(data, setActivePage, setSurveyResults, sortType, format, fromDate, toDate, surveyNameData, dispatch, getSurveyResultsAlt)
  }
  // PAGINATION SECTION

  // Below three functions are just dummy for listview
  const getSelectedRowData = (data) => {
  }

  const deleteDataList = () => {
  }

  const selectRowCount = () => {
  }


  // CSV DOWNLOAD SECTION 

  const [csvData, setCsvData] = useState(null)
  const [numberOfQA, setNumberOfQA] = useState(0) // State for mainaining number of questions each survey has

  // Setting CSV headers
  const [csvHeader, setCsvHeader] = useState([
    { label: 'First name', key: 'firstName' },
    { label: 'Last name', key: 'lastName' },
    { label: 'Role', key: 'role' },
    { label: 'Phone', key: 'phoneNo' },
    { label: 'Email', key: 'emailId' },
    { label: 'Survey name', key: 'surveyName' },
    { label: 'Survey published', key: 'surveypublishedDate' },
    { label: 'Submission date', key: 'submittedOn' },
    { label: 'Customer number', key: 'customerNumber' },
    { label: 'Customer name', key: 'customerName' },
  ])

  // Adding question and answer to the spreadsheet. We are looking over each survey and finding question and answer
  useEffect(() => {
    if (surveyResultsCsv && surveyResultsCsv?.survey && surveyResultsCsv?.survey?.length > 0) {
      const temp = [...surveyResultsCsv.survey]
      const res = []
      let max = 0;
      surveyResultsCsv?.survey?.forEach((data, i) => { //NOSONAR
        const test = { ...temp[i] }
        // We need to pass header dynamically finding maximum number of question/answer each survey has
        // If there are 4 questions, then we are displaying 4 question and answer fields in spreadsheet
        if (data.questionAnswer.length > max) {
          max = data.questionAnswer.length
        }
        data.questionAnswer && data.questionAnswer.length > 0 && data.questionAnswer.forEach((question, qi) => { //NOSONAR
          test['question' + (qi + 1)] = question.question;
          test['answer' + (qi + 1)] = question.answer
        }) //NOSONAR
        res.push(test)
      }) //NOSONAR
      setNumberOfQA(max)
      setCsvData([...res])
    }
  }, [surveyResultsCsv])

  // Below we are adding extra headers 'question' and 'answer' based on how many question and answer survey has
  useEffect(() => {
    if (numberOfQA > 0) {
      const temp = []
      for (let i = 1; i <= numberOfQA; i++) {
        temp.push({ label: 'Question ' + i, key: 'question' + i })
        temp.push({ label: 'Answer ' + i, key: 'answer' + i })
      }
      setCsvHeader([
        { label: 'First name', key: 'firstName' },
        { label: 'Last name', key: 'lastName' },
        { label: 'Role', key: 'role' },
        { label: 'Phone', key: 'phoneNo' },
        { label: 'Email', key: 'emailId' },
        { label: 'Survey name', key: 'surveyName' },
        { label: 'Survey published', key: 'surveypublishedDate' },
        { label: 'Submission date', key: 'submittedOn' },
        { label: 'Customer number', key: 'customerNumber' },
        { label: 'Customer name', key: 'customerName' },
        , ...temp])
    }
  }, [numberOfQA])

  const csvLink = useRef();
  const getFileName = () => {
      const csvDownloadDate = format(new Date(), "MM.dd.yyyy-hh.mm.aaa");
      return `${customerName}.Survey_Results.${csvDownloadDate}.csv`;
  }

  // CSV DOWNLOAD SECTION

  useEffect(() => {
    if (surveyNameData.length > 0) {
      clearErrors(['surveyName'])
    }
  }, [surveyNameData])

  useEffect(() => {
    if (surveyOptions.length > 0) {
      clearErrors(['surveyName'])
    }
  }, [surveyOptions])



  // Apply function (or On Submit)
  const doSubmit = (data) => {

    if (fromDateError !== null || toDateError !== null) {
      return;
    }

    // Check survey list options. If there is no survey list options throw error on submit
    if (surveyOptions?.length === 0) {
      setError('surveyName', {
        type: "manual",
        message: "There are no surveys. Please change the filters."
      })
      return;
    } else {
      clearErrors(['surveyName'])
    }

    // Checking wheater survey is selected
    if (surveyNameData?.length === 0) {
      setError('surveyName', {
        type: "manual",
        message: "Please select atleast one survey"
      })
      return;
    } else {
      clearErrors(['surveyName'])
    }
    // If the user selects 'all' in survey field, then we are using below loop to get all IDs of survey and calling API
    // const allSurvey = []
    // if (surveyNameData[0] === 'all') {
    //   if (surveyListOptions?.survey && surveyListOptions?.survey?.length > 0) {
    //     surveyListOptions.survey.map((data) => {
    //       allSurvey.push(data.id)
    //     })
    //   }
    // }
   // dispatch(setSurveyResults({}))
    // Making actual API call
    const payload = {
      dateFrom: format(data.fromDate, "yyyy/LL/dd"),
      dateTo: format(data.toDate, "yyyy/LL/dd"),
      // surveyId: allSurvey && allSurvey.length > 0 ? allSurvey : surveyNameData
      surveyId: surveyNameData
    }
    dispatch(getSurveyResults(payload))
    // The below call is for csv download
    //dispatch(getSurveyCsv(payload, sortType))
    setSurveyResultsCSV(payload)
  }
  // Reset function.
  const resetData = () => {
    resetDataMove(reset, subDays, setSurveyNameData, setDisableCustomerGroup, setShowApplyButton, setFirstTime, getSurveyList, setSurveyDefault, setCustomerDefault, setFromDateError, setToDateError, clearErrors, setCsvData, setNumberOfQA, setCsvHeader, setResetStart, setFromDate, setToDate)
  }

  let loading = useSelector(state => state.woundreport.surveyResultsLoader)
  let surveyListLoader = useSelector(state => state.woundreport.surveyListLoader)
  const isSurveyDownloadReady = useSelector(state => state.woundreport.isSurveyDownloadReady)
  const [downloadCheck, setDownloadCheck] = useState(false);
  const clickAllSurvey = () => {
      dispatch(setIsSuveyDownloadReady(''))
      setDownloadCheck(true)
      const limit=(surveyResults?.metadata?.total)?surveyResults?.metadata?.total:50
      dispatch(getSurveyCsv(surveyResultsCSV, sortType,limit))
    }

    useEffect(() => {
      if (csvLink&&csvLink.current&&isSurveyDownloadReady&&isSurveyDownloadReady==='downloadAllSurvey'&&downloadCheck) {
      
       dispatch(setIsSuveyDownloadReady(''))
       setDownloadCheck(false);
       csvLink.current.link.click()
      }else{
        dispatch(setIsSuveyDownloadReady(''))
        setDownloadCheck(false);
      }
  }, [isSurveyDownloadReady]);
  return (
    <>
      <div className="right_block">
        <Header reportHeading={"Survey Results"} />
        <div className={`col ${style.wound_dashboard} mt-0`}>
          <Form className={style.wound_form} onSubmit={handleSubmit(doSubmit)} >
            <div className={`col-8 ${style.col_spec}`}>
              <label className={`col-5 ${style.label_spec}`}>Date Range:</label>

              <DateTimePicker
                id={"fromDate"}
                name={"fromDate"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 ${style.col_spec}`,
                }}
                customOnChange={customFromDatePickerChange}
                defaultValue={subDays(new Date(), 30)}
                displayIcon={true}
                validator={register}
                showMonthDropdown={true}
                showYearDropdown={true}
                dateRef={dateRangeRef1}
                maxDate={new Date()}
                errors={errors}
              />

              <DateTimePicker
                id={"toDate"}
                name={"toDate"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 pr-0 ${style.col_spec}`,
                }}
                defaultValue={new Date()}
                customOnChange={customToDatePickerChange}
                displayIcon={true}
                validator={register}
                showMonthDropdown={true}
                showYearDropdown={true}
                dateRef={dateRangeRef2}
                maxDate={new Date()}
                errors={errors}
              />

            </div>

            <div className={`col-8 ${style.col_spec}`}>
              <label className={`col-5 ${style.label_spec}`}></label>

              <div className="col text-danger m-0">{fromDateError}</div>
              <div className="col text-danger m-0">{toDateError}</div>

            </div>

            <TextField
              id={"customerNumber"}
              name={"customerNumber"}
              label={"Primary Group Code:"}
              classes={{
                column: `col-8 ${style.col_spec}`,
                labelClass: `col-5 col-form-label ${style.label_spec}`,
                inputColumn: `col-7 p-0`,
                inputClassName: `${style.wound_input}  customerNum`
              }}
              type="text"
              validator={register}
              errors={errors}
     //         onBlur={disableChange}
            />
            <div className="mt-2">
              <span className={`mt-1 ${style.form_span}`} onClick={handleCustomerNumber}>
                Search <img src={rightArrow} alt="" className={`${style.img_left}`} />
              </span>
            </div>
            <div className="col-8 pb-3 mb-1">
              <span className="offset-8 "><strong>OR</strong></span>
            </div>

            {/* <MultiSelect
              id={"customerGroupSelection"}
              name={"customerGroupSelection"}
              label={"Customer Group Selection:"}
              classes={{
                column: `col-8 ${style.col_spec}`,
                inputClassName: style.wound_select,
                labelClassName: `col-5 ${style.label_spec}`,
                inputColumn: `col-7 p-0`,
              }}
              optionValue={[
                { key: 'all', value: 'All Customer' },
                { key: 'postacute', value: 'Post-Acute' },
                { key: 'acute', value: 'Acute' },
              ]}
              validator={(e) => {
                register(e)
                customerRef.current = e
              }}
              selectAll={true}
              allValue={'All Customer'}
              defaultValue={['all']}
              displayValue="value"
              selectedValue={selectedSurvey}
              disabled={disableCustomerGroup}
              labelSubText={'Select all that apply'}
            /> */}

            <NewMultiSelect
              id={"customerGroupSelection"}
              name={"customerGroupSelection"}
              label={"Customer Group Selection:"}
              classes={{
                column: `col-8 ${style.col_spec}`,
                inputClassName: style.wound_select,
                labelClassName: `col-5 ${style.label_spec}`,
                inputColumn: `col-7 p-0`,
              }}
              optionValue={customerOptions}
              validator={(e) => {
                register(e)
                customerRef.current = e
              }}
              selectAll={true}
              allValue = {"All"}
              defaultValue={customerDefault}
              displayType="value"
              selectedValue={selectedCustomer}
              setSelectedValue={setSelectedCustomer}
              labelSubText={'Select all that apply'}
              disabled={disableCustomerGroup}
              onChange={onChange}
              setSurveyDefault={setSurveyDefault}
            />

            {/* <MultiSelect
              id={"surveyName"}
              name={"surveyName"}
              label={"Survey Name:"}
              classes={{
                column: `col-8 ${style.col_spec}`,
                inputClassName: style.wound_select,
                labelClassName: `col-5 ${style.label_spec}`,
                inputColumn: `col-7 p-0`,
              }}
              optionValue={surveyOptions}
              validator={(e) => {
                register(e)
                surveyRef.current = e
              }}
              defaultValue={surveyReset}
              showCheckbox={true}
              displayValue="value"
              onSelect={surveyNameSelect}
              onRemove={surveyNameSelect}
              labelSubText={'Select all that apply'}
            /> */}

            {/* Loader */}
            {
              surveyListLoader && <div className={`${style.loader_position} text-center col-11`}>
                <img src={Loader} alt="loading" className={style.loader_img} />
              </div>
            }

            <NewMultiSelect
              id={"surveyName"}
              name={"surveyName"}
              label={"Survey Name:"}
              classes={{
                column: `col-8 ${style.col_spec}`,
                inputClassName: style.wound_select,
                labelClassName: `col-5 ${style.label_spec}`,
                inputColumn: `col-7 p-0`,
              }}
              optionValue={surveyOptions}
              validator={(e) => {
                register(e)
                surveyRef.current = e
              }}
              selectAll={true}
              allValue={'All'}
              defaultValue={surveryDefault}
              displayType="value"
              selectedValue={selectedSurvey}
              setSelectedValue={setSelectedSurvey}
              labelSubText={'Select all that apply'}
              onChange={onChange}
              setSurveyDefault={setSurveyDefault}
            />

            {errors?.surveyName?.message && <div className="text-danger col-8">
              <div className="col-7 offset-5 p-0 mb-3">{errors?.surveyName?.message}</div>
            </div>}
            {
              showApplyButton &&
              <div className={`col-8 ${style.col_spec}`}>
                <div className="offset-5">
                  <Button
                    classes={{ className: `btn ${style.btn_custom} ${style.btn_small} mr-4` }}
                    type="submit"
                    id="submit_btn"
                  >
                    Apply
                  </Button>
                  <Button
                    classes={{ className: `btn ${style.btn_custom} ${style.btn_small_cancel} ` }}
                    onClick={resetData}
                  >
                    Reset
                  </Button>
                </div>
              </div>
            }
          </Form>

          {/* Loader */}
          {
            loading && <div className={`${style.loader_position} text-center`}>
              <img src={Loader} alt="loading" className={style.loader_img} />
            </div>
          }

          {Array.isArray(surveyResults?.survey) && surveyResults?.survey?.length > 0  && <div className="col-sm-12 mb-2 justify-content-end d-flex pl-4 pr-4">
            <span className={style.down_spread}  onClick={() =>clickAllSurvey()}>
              {/* <CSVLink
                onClick={() => csvLink.current.link.download = getFileName()}
                headers={csvHeader}
                data={csvData}
                filename={getFileName}
                ref={csvLink}
                target="_blank"
              >
                <img src={Spreadsheet} alt="" />  Download as CSV
              </CSVLink> */}
              <img src={Spreadsheet} alt="" />  
              <a className=""> Download as CSV</a>
            </span>
            <CSVLink
               headers={csvHeader}
              data={Array.isArray(csvData)?csvData:[]}
              filename={`${customerName}.Survey_Results.${format(new Date(), "MM.dd.yyyy-hh.mm.aaa")}.csv`}
              target="_blank"
              className="hidden"
              ref={csvLink}
            >
            </CSVLink>
          </div>}

          <div>

            {surveyResults?.survey && surveyResults?.survey?.length > 0 ?
              <div className={style.list_outer}>
                <DataTable
                  columns={columns}
                  data={surveyResults?.survey}
                  sorting={true}
                  getSelectedRowData={getSelectedRowData}
                  setSelectedRows={selectRowCount}
                  onSort={handleSort}
                  detailView={false}
                  deleteDataList={deleteDataList}
                  showFixedColumn={false}
                  workListView={true}
                  removeCursor={true}
                />

                {surveyResults?.survey?.length > 0 && <Pagination
                  activePage={activePage}
                  itemsCountPerPage={surveyResults?.metadata ? surveyResults?.metadata?.limit : 50}
                  totalItemsCount={surveyResults?.metadata ? surveyResults?.metadata?.total : 0}
                  getActivePage={getActivePage}
                />}
                <div className="clearfix"></div>
              </div>
              :
              <>
                {/* When there is no data */}
                <div className={style.empty}>
                  <div className={style.empty_heading}>
                    <span>First name</span>
                    <span>Last name</span>
                    <span>Role</span>
                    <span>Phone</span>
                    <span>Email</span>
                    <span>Survey name</span>
                    <span>Survey published</span>
                    <span>Submission date</span>
                    <span>Customer number</span>
                    <span>Customer name</span>
                  </div>
                  <div className={style.empty_body}>
                    {/* <h5>Oops there are no matching results, please update your filters</h5> */}
                    <h5>There are no records matching your report criteria.</h5>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}
