import React from 'react'
import { FormBuilderImage } from './formBuilderImage'
import { Tab } from '../../secondary'
import styles from './multiFileUploadFormBuilder.module.scss'

export function MultiFileUploadFormBuilder({ handleAddMediaCancel,questionId,formId, productType, mediaList, questionIndex, customFormBuilder,setcustomFormBuilder }) {

    const tabList = [
        {
            name: 'Images',
            content: <FormBuilderImage
                handleAddMediaCancel={handleAddMediaCancel}
                questionId={questionId}
                formId={formId}
                productType={productType}
                mediaList={mediaList}
                questionIndex={questionIndex}
                customFormBuilder={customFormBuilder}
                setcustomFormBuilder={setcustomFormBuilder}
            />,
            className: styles.no_shadow
        }
    ]

    return (
        <div>
            <div className={styles.media_hdg}>
                Upload Media
            </div>
            <div className={styles.media_btm}>
                <Tab tabList={tabList} />
            </div>

        </div>
    )
}
