import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import style from './notification.module.scss';
import NotificationIcon from '../../../static/images/notifications-icon.png'
import { userNotificationAsync, deleteNotificationAsync, readNotificationAsync } from '../../../app/userSlice';
import closeIcon from '../../../static/images/close-icon-gray.png';
import { getNotificationDate } from '../../../utils';
import { withRouter } from 'react-router';
export let Notification = ({ dispatch, user }) => {
    let notificationData = user.notificationData;

    const handlNotificationDelete = (e, id) => {
        e.stopPropagation();
        dispatch(deleteNotificationAsync(id))
    }
    const handlNotificationRead = (e, values) => {
        e.preventDefault();
        dispatch(readNotificationAsync(values))
    }

    /* State to check drop down assw */
    const [viewDrop, setDrop] = useState(false)

    /* Function to toggle drop assessment*/
    const handleDrop = (e) => {
        setDrop(!viewDrop)
        setClickedOutside(false)
    }

    /* Trigger Drop Down Items close */
    const dropRef = useRef();
    const [clickedOutside, setClickedOutside] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    const handleClickOutside = (e) => {
        if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
            setDrop(false);
            setClickedOutside(true);
            /* Read all notification
            let notificationsIDS=[]
            notificationData && notificationData.notifications.map((value)=> {
                if(value['unread']===1){
                  notificationsIDS.push(value['id'])
                 }
              })
            if(notificationsIDS.length!==0){
                let postData = { notifications:notificationsIDS}
               dispatch(readNotificationAsync(postData))
            }
            */
        }
    };


    const getNotificationList = () => {
        dispatch(userNotificationAsync())
    }

    useEffect(() => {
        getNotificationList();
    }, [dispatch]);


    return <button className={` d-inline mr-4 ${style.noty_blk}`}>
        {notificationData && notificationData.metadata && notificationData.notifications.length && notificationData.notifications.length != 0 ?
            <>
                <img  className={`${style.noty_icon}`} src={NotificationIcon} onClick={handleDrop} ref={dropRef} alt="Notification" />
                <span className={`${style.alert_note} ${style.noty_icon}`} onClick={handleDrop} ref={dropRef} title={notificationData.metadata.unreadcount} >{notificationData.metadata.unreadcount}</span>
            </>
            :
            <img className={`${style.noty_icon}`} src={NotificationIcon} ref={dropRef} alt="Notification" />
        }

        {viewDrop && notificationData && notificationData.metadata && notificationData.notifications.length != 0 &&
            <div className={`dropdown-menu dropdown-menu-right show ${style.medline_menu_not} ${style.notification_block}`} ref={dropRef}>
                {/* <p className={`mb-0 ${style.medline_user}`}>Notifications</p>
                <div className="dropdown-divider"></div> */}
                <div className={`${style.notification_items}`}>
                    {notificationData && notificationData.notifications.map((key, index) => (
                        (key) &&
                        <p key={index+'tes3'} onClick={(e) => handlNotificationRead(e, key) }
                            className={`dropdown-item ${style.p_link} ${style.notification_block_dropdown_item} ${(key.unread === 1)?style.notification_unread:''} readNotification`} >
                            <span className='font-weight-bold'> {key.title}</span>
                            <span className={`d-flex text-truncate`} title={key.description}> {key.description}</span>
                            <span className={`${style.mins_ago}`}>
                                {getNotificationDate(key.createdDate)}
                                {(key.unread === 1) ?
                                    <>
                                        <button className={`${style.btn_circle}`}></button>
                                    </>
                                    :
                                    <button className={`${style.btn_circle_normal}`}></button>
                                }
                                <a
                                    href="#/"
                                    className={`${style.dropdown_item_a} deleteNotification`}
                                    onClick={(e) => handlNotificationDelete(e, key.id)}
                                    title="Remove">
                                    <img src={closeIcon} alt="close icon" />
                                </a>
                            </span>
                        </p>
                    ))
                    }
                </div>
            </div>
        }
    </button>

}

/*Notification.propTypes = {
    dispatch: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        user: state.user
    }
}*/

//Notification = connect(mapStateToProps)(Notification);
Notification = connect()(withRouter(Notification));