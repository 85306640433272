import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, Form, TextField } from "../../primary";
import { Confirmation } from '../../secondary/Confirmation/confirmation';
import styles from "./formularyAssign.module.scss";
import {
  getFormularyFilterByNameId,
  setFormularySearchData,
  setResponseAssignState,
  assignFormularyAsyn,
} from "../../../app/customerSlice";
import {
  ASSIGNED_FORMULARY_SUCCESS,
  UPDATE_FAILURE,
  SUCCESS_STATUS,
  ERROR_STATUS,
  ASSIGNED_FORMULARY_REQUIRED,
  VALID_FORMULARY_ERROR_MSG1,
  VALID_FORMULARY_ERROR_MSG2,
} from "../../../constants";
import { useDebounce } from "../../../utils";
import { useForm } from "react-hook-form";
import { CustomerFormularyAssignedList } from "./customerFormularyAssignedList";
import { AccessErrorModal } from "../../primary";
import Loader from '../../../static/images/spinner.svg';
export function FormularyAssign({ customerData, dispatch }) {
  const customerId = customerData ? customerData.id : "";
  const formularyId =
    customerData && customerData.formularyId ? customerData.formularyId : "";
  const { register, handleSubmit, watch, errors, reset } = useForm({
    mode: "all",
  });
  const [formularyIdInput, setFormularyIdInput] = useState("");
  const [isSelect, setIsSelect] = useState(false);
  const searchTerm = useDebounce(formularyIdInput, 500);
  const [showConfirm, setShowConfirm] = useState(false);
  const formularySearchDatas = useSelector(
    (state) => state.customer.formularySearchData
  );
  useEffect(() => {
    if (!isSelect && searchTerm && searchTerm.length >= 1) {
      dispatch(getFormularyFilterByNameId(searchTerm));
    } else {
      dispatch(setFormularySearchData({}));
    }
  }, [searchTerm]);

  const handleFormularyChange = (event) => {
    const input = event.target.value;
    setFormularyIdInput(input);
    setIsSelect(false);
    document.getElementById("formularyIdName").value = "";
    dispatch(setResponseAssignState(false));
  };
  const clearData = () => {
    if (document.getElementById("formularyId"))
      document.getElementById("formularyIds").value = "";
    if (document.getElementById("formularyIdName"))
      document.getElementById("formularyIdName").value = "";
    setFormularyIdInput("");
    reset();
  };
  const formularySelect = (e, keys) => {
    e.stopPropagation();
    setIsSelect(true);
    dispatch(setFormularySearchData({}));
    if (keys) {
      const formularyname = keys.value;
      setFormularyIdInput(formularyname);
      document.getElementById("formularyIds").value = formularyname;
      document.getElementById("formularyIdName").value = keys.key;
    }
  };
  const doFormularySubmit = (data) => {
    dispatch(setResponseAssignState(false));
    const payload = {
      id: data.formularyIdName,
    };
    if (data.formularyIdName)
      dispatch(assignFormularyAsyn(payload, customerId));
  };
  /* Edit success */
  const editState = useSelector((state) => state.customer.responseAssignState);
  const accessError = useSelector((state) => state.app.accessError);
  useEffect(() => {
 
    const chkpopErr=editState?.finalErrorRes?JSON.parse(editState?.finalErrorRes):''
    if (editState === "success") {
      clearData();
      setTimeout(() => {
        dispatch(setResponseAssignState(false));
      }, 5000);
    } 
    else if (
      editState?.errorMessage &&  editState?.finalErrorRes&&chkpopErr?.data&&chkpopErr.data==='showconfirmation'
    ) {
      setShowConfirm(true)
    }
    else {
      //clearData();
    }
  }, [editState]);

  useEffect(() => {
    dispatch(setResponseAssignState(false));
  }, [dispatch]);

  const userDetails = useSelector((state) => state.user);
  const adminView = userDetails?.role;
  const [externalAdmin, setExternalAdmin] = useState(false);
  
  useEffect(() => {
    if (adminView === "ExternalAdministrator") {
      setExternalAdmin(true);
      setExternalAdmin(true) // added External Formulary
    }
  }, [adminView]);
    const handleAssignYes = () => {
      setShowConfirm(false)
      if(document.getElementById("formularyIdName")&&document.getElementById("formularyIdName").value){
        const payload = {
          id:document.getElementById("formularyIdName").value,
          isConfirmed:1
        };
        dispatch(assignFormularyAsyn(payload, customerId));
      }       
      dispatch(setResponseAssignState(false)); 
    }

    const handleAssignNo = () => {
        setShowConfirm(false)
        dispatch(setResponseAssignState(false));
    }
    let errCk= editState?.finalErrorRes?JSON.parse(editState?.finalErrorRes):''
    const [tinyDelay, setTinyDelay] = useState(false)
    useEffect(() => {
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    }, [])
  return (
    <>
      {/* ExternalAdministrator user view */}
      {externalAdmin && (
        <div className={`${styles.single_product_block}`}>
          <div className={`col-12`}>
            {formularyId === null ||
              (formularyId === "" && (
                <div className={`${styles.content_center}`}>
                  Currently, there is no Medline formulary assigned to your
                  Organization.<br></br>
                  Please contact your Medline Sales Rep.
                </div>
              ))}
            {formularyId && formularyId !== "undefined" && (
              <CustomerFormularyAssignedList
                customerId={customerId}
                externalAdmin={externalAdmin}
                formularyId={formularyId}
              />
            )}
          </div>
        </div>
      )}

      {/* interternalAdministrator user view */}
      {adminView && !externalAdmin && (
        <div className={`${styles.single_product_block}`}>
          {/* Edit success */}
          {editState === SUCCESS_STATUS && (
            <div
              className={`alert alert-success mt-4 ml-3 ${styles.form_alert}`}
            >
              {ASSIGNED_FORMULARY_SUCCESS}
            </div>
          )}
          {/* Edit failure */}
          {editState === ERROR_STATUS && (
            <div
              className={`alert alert-danger mt-4 ml-3 ${styles.form_alert}`}
            >
              {UPDATE_FAILURE}
            </div>
          )}
{!tinyDelay ?<div className={`${styles.loader} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
          <>
          <div className={`col-12  ${styles.col_spec}`}>
            <Form onSubmit={handleSubmit(doFormularySubmit)}>
              <div className={`${styles.formulary_content}`}>
                <div className={`col_spec mt-4  ${styles.col_spec}`}>
                  <input
                    type="hidden"
                    name="formularyIdName"
                    id="formularyIdName"
                    ref={register}
                  />
                  <TextField
                    id={"formularyIds"}
                    name={"formularyId"}
                    label={"Formulary ID/Name:"}
                    placeholder={"Formulary Name"}
                    classes={{
                      column: `${styles.col_spec}`,
                      labelClass: `col col-form-label  pl-0 ${styles.label_spec}`,
                      inputColumn: `${styles.autocomplete}`,
                      inputClassName: `col ${styles.basic_input}`,
                      search_btn: `${styles.search_btn}`,
                    }}
                    type="text"
                    defaultValue={
                      formularyIdInput
                        ? formularyIdInput
                        : formularyId
                        ? formularyId + " - " + customerData?.formularyName
                        : ""
                    }
                    search={true}
                    onChange={handleFormularyChange}
                    validator={register({
                      required: ASSIGNED_FORMULARY_REQUIRED,
                    })}
                    required={true}
                    errors={errors}
                    customError={
                      editState?.errorMessage && errCk?.data===null
                        ? editState.errorMessage
                        : formularySearchDatas &&
                          Array.isArray(formularySearchDatas) &&
                          formularySearchDatas.length === 0
                        ? "Formulary id is not found"
                        : ""
                    }
                  />
                  <div className="ml-4 d-inline">
                    <Button
                      id={"assignId"}
                      type={"submit"}
                      value={"Assign"}
                      classes={{
                        className: `btn ${styles.btn_small} ${styles.btn_custom}`,
                      }}
                    />
                  </div>
                </div>
                {formularySearchDatas &&
                  Array.isArray(formularySearchDatas) &&
                  formularySearchDatas.length > 0 && (
                    <div className="col-12 col_spec row">
                      <div className={`col_spec mr-auto ${styles.search_drop}`}>
                        <div className={`col-spec ${styles.search_close_box}`}>
                          {formularySearchDatas.map(
                            (keys, index) =>
                              keys.value && (
                                <p
                                  className={`dropdown-item`}
                                  title={keys.value}
                                  onClick={(e) => formularySelect(e, keys)}
                                >
                                  {keys.value}
                                </p>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </Form>
          </div>
          {formularyId && formularyId !== "undefined" && (
            <div className={`col-12`}>
              <CustomerFormularyAssignedList
                customerId={customerId}
                externalAdmin={externalAdmin}
                formularyId={formularyId}
                adminView = {adminView}
              />
            </div>
          )}
          </>}
        </div>
      )}

      {accessError && <AccessErrorModal />}
     
      {/* Assign confirmation */}
      {
          showConfirm && <Confirmation 
            onClose={handleAssignNo}
            title={" "}
          >
            <div className="text-center pr-4 pl-5">
              <p className="pb-3">{editState?.errorMessage}</p>
              <div className="mb-2">
                <Button
                  value="Yes"
                  classes={{ className: `btn mr-3 pb-2 pt-2 ${styles.yes_btn}` }}
                  onClick={handleAssignYes}
                />

                <Button
                  value="No"
                  classes={{ className: `btn mr-3 ml-2 pb-2 pt-2 ${styles.no_btn}` }}
                  onClick={handleAssignNo}
                />
              </div>
            </div>
          </Confirmation>
        }

    </>
  );
}
FormularyAssign.propTypes = {
  dispatch: PropTypes.func,
};

FormularyAssign = connect()(FormularyAssign);
