
export const clearFileInputMove = (radioOption, setDisplayName) => {
    if (radioOption === 'Browse') {
        if(document.getElementById('image')){
            document.getElementById('image').value = ''                
            setDisplayName(null)
        }
    }
}

export function getBase64Move(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function imageValidatorMove(file, setImageError, setDisplayName) {
    // Logic to check image dimension
    // const img = new Image()
    // img.src = window.URL.createObjectURL(file)
    // img.onload = () => {
    //     if (img.width > 1600 || img.height > 900) {
    //         setImageError(true)
    //         document.getElementById('image').value = null;
    //         setDisplayName(null)
    //         setTimeout(() => {
    //             setImageError(false)
    //         }, 5000)
    //     }
    // }
    // Logic to check image size
    if (parseInt(file.size / 1024) > 1024) {
        setImageError(true)
        setDisplayName(false)
        document.getElementById('image').value = null;
        setTimeout(() => {
            setImageError(false)
        }, 5000)
        return false;
    }
    return true;
}

export const fixBaseCodeMove = (data) => {
    let fixed;
    if (data.search('data:image/png;base64') !== -1) {
        fixed = data.replace('data:image/png;base64,', '')
    }
    if (data.search('data:image/jpeg;base64') !== -1) {
        fixed = data.replace('data:image/jpeg;base64,', '')
    }
    if (data.search('data:image/jpg;base64') !== -1) {
        fixed = data.replace('data:image/jpg;base64,', '')
    }
    return fixed;
}

export const fileTypeCheckerMove = (e) => {
    if(e.type){
    if(e.type === 'image/png' || e.type === 'image/jpg' || e.type === 'image/jpeg'){
        return true
    }else{
        return false
    }}else{
        return false
    }
}

export const handleImageChangeMove = (e, fileTypeChecker, setServerError, clearFileInput, addedList, setAddedList, imageValidator, imageError, setDisplayName, getBase64, fixBaseCode) => {
    if(!fileTypeChecker(e.target.files[0])){
        setServerError("Please upload only JPEG or JPG or PNG files")
        setTimeout(() => {
            setServerError(null)
        }, [5000])
        clearFileInput()
    }
    // Clear if anyother image is already added
    if (addedList && addedList?.length > 0) {
        setAddedList([])
    }
    e.preventDefault();
    const files = e?.target?.files
    let datas = []
    // Going trough each file and calling above getBase64 function.
    // Return promise will be handled here and stored in a variable, 
    // then that variable will be set in state
    // files.forEach((file, i) => {
    //     imageValidator(file) // If the image passes required condition, store the image else throw error    
    //     // If image passess, push the image to our list.
    //     if (!imageError) {
    //         setDisplayName(file.name)
    //         getBase64(file).then((data) => {
    //             const temp = [...datas]
    //             temp[i] = {
    //                 image: fixBaseCode(data),
    //                 fileName: file.name
    //             }
    //             datas = [...temp]
    //             setAddedList([...datas])
    //         });
    //     }

    // })
    if(files && files[0]){
         // If the image passes required condition, store the image else throw error    
        // If image passess, push the image to our list.
        if (imageValidator(files[0])) {
            setDisplayName(files[0]?.name)
            getBase64(files[0]).then((data) => {
                const temp = [...datas]
                temp[0] = {
                    image: fixBaseCode(data),
                    fileName: files[0]?.name
                }
                datas = [...temp]
                setAddedList([...datas])
            });
        }

    }
};

export const getBase64FromUrlMove = async (url, setUrlLoading, setServerError) => {
    setUrlLoading(true)
    setServerError(null)
    try {
        const data = await fetch(url);
    } catch (e) {
        // If the image is private display error message.
        setServerError("Content in this link doesn't have access to download")
        setUrlLoading(false)
      //  document.getElementById('urlField').value = null
        // setTimeout(() => {
        //     setServerError(null)
        // }, [5000])
    }
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        }
    });
}

export const handleUrlMove = (e, addedList, setAddedList, getBase64FromUrl, setUrlLoading, fixBaseCode, getFileName) => {
    // Clear if anyother image is already added
    if (addedList && addedList?.length > 0) {
        setAddedList([])
    }
    setAddedList([{ image: e.target.value, fileName: null }])
    // getBase64FromUrl(e.target.value).then((res) => {
    //     setUrlLoading(false)
    //     setAddedList([{ image: fixBaseCode(res), fileName: getFileName(e.target.value) }])
    // })
}

export const handleDeleteMove = (type, id, mediaList, dispatch, updateMediaList, productId, productType, getMedia) => {
    // Using filter to filter the deleteted item and send rest to API
    const filtered = mediaList[type].filter((data, i) => i !== id)
    const list = { ...mediaList }
    if (filtered && filtered.length === 0) {
        list[type] = []
    } else if (filtered && filtered.length > 0) {
        list[type] = [...filtered]
    }
    dispatch(updateMediaList({ productId, productType, list, getMedia }))
}