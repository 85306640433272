import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkListRecordsCount } from "../../../app/patientSlice";
import { VerticalTabWorkList } from "../../secondary/VerticalTabWorkList";
import { ReviewLockedAssessment } from "./reviewLockedAssessment";
import style from './workListCommon.module.scss';
export function CompletedAssessment({facilityNumber, history }) {
    useEffect(() => {
            dispatch(getWorkListRecordsCount(facilityNumber,'completeAssessmentsValues'));
       }, [facilityNumber])
    const workListRecordsCount = useSelector(state => state.patient.workListRecordsCount);
    const reviewLockedAssessments=workListRecordsCount?.completeAssessmentsValues?.reviewLockedAssessments?`(${workListRecordsCount?.completeAssessmentsValues?.reviewLockedAssessments})`:''

    /* Tab list */
    const tabList = [
        {
            name: `Reviewed & Locked, Assessment can't be changed ${reviewLockedAssessments}`,
            content: <ReviewLockedAssessment facilityNumber={facilityNumber} />,
            bottomName: 'Reviewed & Locked',
            bgcolor: style.completed_bgcolor
        },
    ];
    const dispatch = useDispatch();
    return (
        <>
            <VerticalTabWorkList tabList={tabList} dispatch={dispatch} />
        </>
    )
}
