import React, { useEffect, useState } from 'react'
import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";
import styles from "./pdfDownload.module.scss";
import logo from '../../../static/images/skin-health-logo.png'
import { FlexibleWidthXYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, LineMarkSeries } from 'react-vis';
import 'react-vis/dist/style.css'
import { useParams, withRouter } from 'react-router-dom';
import { Header } from '../../secondary/Header';
import { connect, useDispatch, useSelector } from 'react-redux';
import { characteristicsImagesAsync, detailAssessmentAsync, getHealingTrajectoryAsync, optionTypeAsync, pdfDataAsync, setPdfData, woundClassificationAsync, getPdfDataAsyn, setIsPdfGeneration} from '../../../app/patientSlice';
import { allNullCheck, dateOfBirthDate, fullMonthFormatDate, handleClickMove, mainCheckerMove, mapperMove, optionsMapperMove } from '../../../utils';
import {updateUserDetailsAsync} from '../../../app/userSlice';
import Loader from '../../../static/images/spinner.svg';
import { Button } from '../../primary';
import { format } from 'date-fns';
import { assessmentRbacChecker } from '../../../utils/rbac';
import pdfnoimage from '../../../static/images/Pdf_no_image.png'

export function PdfDownload({ history}) {
    const pdfFile = useSelector(state => state.patient.pdfFile);//api pdf load
    //pdfFile
    const pdfbase64link=pdfFile?.pdfData;
    const { patientId } = useParams();
    const { assessmentId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPdfData(false))
    }, [])
       
    // For scrolling the page to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const notes = useSelector(state => state.patient.assessmentNotes); //added for Notes Section
    const withImage = history.location.state; //added for Pdf with/without Image      
    // Getting loader 
    const loading = useSelector(state => state.app.loading);

    // States
    const [canvases, setCanvases] = useState([])
    const [header, setHeader] = useState({})
    const [facilityData, setFacilityData] = useState('') // Facility details
    const [started, setStarted] = useState(false) // To avoid multiple html2canvas promises
    const [pdfGeneration, setPdfGeneration] = useState(false) // State for end of html2canvas process
    const [message, setMessage] = useState(false)
    const [tissueTypedata, setTissueTypeData] = useState([]) // Storing mapped tissue type data
    const [primaryDressFreq, setPrimaryDressFreq] = useState([]) // State for enabling primary dressing frequency
    const [secDressFreq, setSecDressFreq] = useState([]) // State for enabling secondary dressing frequency
    const isPdfGeneration = useSelector(state => state.patient.isPdfGeneration);//api pdf load
    const [isGraph, setIsGraph] = useState(false)
    // API calls
    useEffect(() => {
        
  
		 
        const payload = {
            patientId,
            assessmentId
        }
        const classificationPayload = {
            patientId,
            assessmentId,
            data: {
                woundBedIdentifiable: null,
                configurationRequest: 1
            }
        }
        const tissueTypesPayload = {
            patientId,
            assessmentId,
            type: 'tissuetypes'
        }
       dispatch(getHealingTrajectoryAsync(payload))
       dispatch(setIsPdfGeneration(true))
        dispatch(pdfDataAsync({ ne1Id: patientId, assessmentId: assessmentId }, history));
        dispatch(woundClassificationAsync(classificationPayload))
        dispatch(characteristicsImagesAsync(tissueTypesPayload))
        dispatch(detailAssessmentAsync(payload))
        dispatch(optionTypeAsync('woundprogress'))
        dispatch(optionTypeAsync('woundage'));
        dispatch(optionTypeAsync('phototype'));
        dispatch(optionTypeAsync('documentationtype'));
        dispatch(optionTypeAsync('pihistory'));
        dispatch(optionTypeAsync('humananatomy'));
        dispatch(optionTypeAsync('historycause'));
    }, [])

    // Getting API datas from redux
    const user = useSelector(state => state.user)
    const detailAssessment = useSelector(state => state.patient.detailAssessment);
    const patientData = useSelector(state => state.patient.detailViewData)
    const healingTrajectory = useSelector(state => state.patient.healingTrajectoryData)
    const woundClassification = useSelector(state => state.patient.woundClassificationOptions);
    const woundProgress = useSelector(state => state.patient.woundProgress);
    const woundAge = useSelector(state => state.patient.woundAge);
    const photoType = useSelector(state => state.patient.photoType);
    const documentationType = useSelector(state => state.patient.documentationType);
    const piHistory = useSelector(state => state.patient.piHistory);
    const historyCause = useSelector(state => state.patient.historyCause);
    const humanAnatomy = useSelector(state => state.patient.humanAnatomy);
    const tissueTypes = useSelector(state => state.patient.tissueTypesData);
    const otherData = useSelector(state => state.patient.pdfData);
    const woundAssessments = useSelector(state => state.patient.woundAssessments);
    const userDetailsAlt = useSelector(state => state.user.userDetails);
    
    const hideIt = assessmentRbacChecker(user?.role, patientData?.isOwnCustomer)
    
    const [scoreType, setScoreType] = useState('')
  
    // Redirecting user to unauthorized page for external customer
    // if(!hideIt){
    //    // window.location='/unAuthorized';
    // }
    
    // Tissue types mapping
    useEffect(() => {
        const temp = [];
        detailAssessment?.tissueTypes?.length > 0 && detailAssessment?.tissueTypes?.map((first, i) => {        //NOSONAR
            tissueTypes?.tissueTypes && tissueTypes?.tissueTypes?.length > 0 && tissueTypes.tissueTypes.map((second, i) => { //NOSONAR
                if (first.id === second.id) {
                    temp.push({ name: second.name, percent: first.woundFillingPercentage })
                }
            })//NOSONAR
            tissueTypes?.overrideTissueType && tissueTypes?.overrideTissueType?.length > 0 && tissueTypes.overrideTissueType.map((second, i) => { //NOSONAR
                if (first.id === second.id) {
                    temp.push({ name: second.name, percent: first.woundFillingPercentage })
                }
            })//NOSONAR

        }) //NOSONAR
        setTissueTypeData([...temp])
    }, [tissueTypes])

    // Healing trajectory data getting
    const healingTrajectoryData = useSelector(state => {
        let res = {}
        state.patient.healingTrajectoryData.map(data => {
            if (data.id == assessmentId) {
                res = { ...data }
            }
        })
        return res
    })


    useEffect(() => {
        setFacilityData(patientData?.facilityName?.split('|'))
        if(patientData?.facilityNumber){
            dispatch(updateUserDetailsAsync(patientData?.facilityNumber,setScoreType));
        }
    }, [patientData])


    // CHART GENERATION 
    const [healTraj, setHealTraj] = useState([])

    useEffect(() => {
        let healTrajTemp=[]
        if (healingTrajectory && healingTrajectory.length > 0) {

            let tempArray = []
            // We are removing the item which don't have 'surface area'
            tempArray = healingTrajectory && healingTrajectory.length > 0 && healingTrajectory.filter((data, i) => {
                if (!isNaN(data.dimensions.surfaceArea.value)) {
                    return data;
                }
            })

            // We are reversing the array since we need to display improved wound image first 
             healTrajTemp = [...tempArray]
            //healTrajTemp.reverse();
            setHealTraj(healTrajTemp)
        }
            if(isGraph===false){
                async function run() {
                   if(document.getElementById('pdf_chart')){
                    const header = document.getElementById('pdf_chart')
                    await html2canvas(header).then(function (canvas) {
                    //canvas.toDataURL('image/png')
                    let pdf_chartImage = canvas.toDataURL("image/png");
                   pdf_chartImage=pdf_chartImage.replace('data:image/png;base64,', '')
                   
                    const payloadPdf ={
                        "ne1Id":patientId,
                        "graph":healTrajTemp.length === 0?null:pdf_chartImage,
                        "assessmentid":assessmentId,
                        "photo": withImage?1:0
                    }
                     dispatch(getPdfDataAsyn(payloadPdf));
                    //window.open(pdf_chartImage);
                    })
                }
                }
                // We are starting above canvasing process after loading the html file with a delay
                        setTimeout(() => {
                            run();
                        }, 4000)
                        setIsGraph(true)
            }

    }, [healingTrajectory,isGraph])
 
    // Below we are storing surface areas in an array and feeding that to the chart
    const [surfaceArray, setSurfaceArray] = useState([]) // State for storing surface area
    useEffect(() => {
        const tempArray = []
        healTraj && healTraj.length > 0 && healTraj.map((data) => {//NOSONAR
            if(!isNaN(data.dimensions.surfaceArea.value)){
            return tempArray.push({ x: format(new Date(data?.measurementDate), 'LL/dd')+' '+format(new Date(data?.measurementDate), 'h:m aa'), y: data?.dimensions?.surfaceArea?.value,date:format(new Date(data?.measurementDate), 'LL/dd') })
             }
             /* data?.dimensions?.surfaceArea?.value &&
                tempArray.push({ x: format(new Date(data?.identifiedDate), 'dd - MMM'), y: data?.dimensions?.surfaceArea?.value })
                return */
            })//NOSONAR
        setSurfaceArray(tempArray)
  
    }, [healTraj])

    // Set maximum value for y axis 
    const [yAxis, setYAxis] = useState(25)
    // We are maping through the surface area array and finding maximum surface area and adding '3' to that.
    useEffect(() => {
        const values = []
        surfaceArray && surfaceArray.length > 0 && surfaceArray.forEach((data) => {
            values.push(data.y)
        })
        setYAxis(Math.max(...values) + 3)
    }, [surfaceArray])

    /** CHART GENERATION */

    // Options mapper (list options)
    const optionsMapper = (optionArray, key) => {
        return optionsMapperMove(optionArray, key)
    }

    // Function which maps through object and see if value exists, if it exists it will return the value, else return false
    const mapper = (source, name, bool = false) => {
        return mapperMove(source, name, bool)
    }

    // Main item in the object
    const mainChecker = (source, name) => {
        return mainCheckerMove(source, name)
    }

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                setMessage(true)
            }, 10000)
        }
    }, [loading])

    // // Getting header, finding the size, converting the code and pushing it into a state
    // useEffect(() => {
    //     async function run() {
    //         const header = document.getElementById('header')
    //         const imageWidth = 210;
    //         await html2canvas(header).then(function (canvas) {
    //             setHeader({ height: canvas.height * imageWidth / canvas.width, imgData: canvas.toDataURL('image/png') })
    //         })
    //     }
    //     // We are starting above canvasing process after loading the html file with a delay
    //     if (!loading) {
    //         if (!started) {
    //             setTimeout(() => {
    //                 run();
    //             }, 5000)
    //             setStarted(true)
    //         }
    //         else {
    //             return
    //         }
    //     }
    // }, [loading])


    // Getting all the elements with the tag name 'article'. Capturing screenshot, finding height and converting it into base code.
    // Pushing all those into an array of object
    useEffect(() => {
        async function run() {
            const allDivs = document.getElementsByTagName('article')
            const divsArray = Array.from(allDivs)
            const can = []
            const imageWidth = 210;
            // Creating screenshot for each section
            // for (let i = 0; i < divsArray.length; i++) {
            //     await html2canvas(divsArray[i], { allowTaint: true, useCORS: true }).then(function (canvas) {
            //         can.push({ id: i, height: canvas.height * imageWidth / canvas.width, imgData: canvas.toDataURL('image/png') })
            //         setCanvases([...can])
            //     }).finally(() => {
            //         if (i === divsArray.length - 1) {
            //             setPdfGeneration(true)
            //         }
            //     })
            // }
        }
        // We are starting above canvasing process after loading the html file with a delay
        if (!loading) {
            if (!started) {
                setTimeout(() => {
                    run();
                }, 3500)
                setStarted(true)
            }
            else {
                return
            }
        }
    }, [loading])

 
    // Dressing frequency map issue fix

    useEffect(() => {
        if (otherData?.treatment?.length > 0) {
            setPrimaryDressFreq(Array(otherData?.treatment?.length).fill(false))
            setSecDressFreq(Array(otherData?.treatment?.length).fill(false))
        }
    }, [otherData])

    // On click, generating pdf by putting all the above images into a document. We are using jsPDF to generate pdf.
    // If we can't fit image into same page, we are moving the image into next page. It is done automatically.
    const handleClick = () => {
        handleClickMove(html2canvas, jsPDF, canvases, header, patientId, assessmentId, patientData)
    }

    return (
        <>
            {otherData !== false && <>
                <div className={`${styles.back_btn}`}>
                    <Header backLink={'patients'} handleBack={() => history.goBack()} />
                </div>
                {isPdfGeneration && <div className={styles.info_message}>
                    {<>
                        {<h3>Creating your PDF report... <span>This won't take long</span></h3>}
                        {/* {!message && <h3>Creating your PDF report... <span>This won't take long</span></h3>} */}
                        {/* {message && <h3></h3>} */}
                    </>}
                </div>}
				
				
				    {!isPdfGeneration&& <div className={styles.info_message}>                    
                   
                        <a className={`btn btn-small ${styles.btn_small} ${styles.btn_custom}`} download={'Wound_Evaluation_'+patientData?.firstName+'_'+patientData?.lastName+'_'+assessmentId} href={pdfbase64link}>Download PDF</a>
                        
                </div>}
                {/* {!pdfGeneration && hideIt && <div className={styles.info_message}>
                    {<>
                        {<h3>Creating your PDF report... <span>This won't take long</span></h3>}
                    </>}
                </div>}

                {!loading && hideIt && <div className={styles.info_message}>                    
                    {pdfGeneration && <>
                        <Button
                            classes={{ className: `btn btn-small ${styles.btn_small} ${styles.btn_custom}` }}
                            type="submit"
                            id="next"
                            onClick={handleClick}
                        >
                            Download PDF
                        </Button>
                    </>}
                </div>} */}

                {/* {loading && <div className={styles.loader_fix}>
                    <h3>We are gathering data. Please wait.</h3>
                    <img src={Loader} alt="loading" />
                </div>} */}

                {!loading && <div id="pdf" className={styles.content}>

                    <header id="header" className={`min ${styles.header}`}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-9">
                                    <h1>Wound Assessment</h1>
                                    {/* <p>Evaluated on : <strong>{fullMonthFormatDate(allNullCheck(detailAssessment?.lastAssessedDate) ? detailAssessment?.lastAssessedDate : detailAssessment?.identifiedDate)}</strong></p> */}
                                </div>
                                <div className="col-3 text-right">
                                    <img src={logo} alt="test" />
                                </div>
                            </div>
                        </div>
                    </header>

                    {/* Subdetails added PDF */}
                    {/* <div className={`row align-items-center ${styles.assess_content_subDetails}`}> */}
                    <article className={`min`}>
                    <div className='sub_details pb-4'>
                        <div className={`col-12 pl-3`}>
                        <div className={`${styles.assess_content} mb-2 pt-1`}>
                            <span className={styles.assess_label_subDetail_label}>Photo taken by:</span> <span className={styles.assess_label_subDetail_labelout}>{detailAssessment?.photoTakenBy ? detailAssessment?.photoTakenBy : 'NA'}</span>
                            {` | `}
                            <span className={styles.assess_label_subDetail_label}>Photo taken on:</span> <span className={styles.assess_label_subDetail_labelout}>{detailAssessment?.identifiedDate ? fullMonthFormatDate(detailAssessment?.identifiedDate) : 'NA'}</span>
                        </div>

                        <div className={`${styles.assess_content} mb-2`}>
                            <span className={styles.assess_label_subDetail_label}>Assessed by:</span> <span className={styles.assess_label_subDetail_labelout}>{detailAssessment?.assessedBy ? detailAssessment?.assessedBy : 'NA'}</span> 
                            {` | `}
                            <span className={styles.assess_label_subDetail_label}>Last assessed:</span> <span className={styles.assess_label_subDetail_labelout}>{detailAssessment?.lastAssessedDate ? fullMonthFormatDate(detailAssessment?.lastAssessedDate) : 'NA'}</span>
                        </div>

                        <div className={`${styles.assess_content} mb-1`}>
                            <span className={styles.assess_label_subDetail_label}>Signed:</span> <span className={styles.assess_label_subDetail_labelout}>{detailAssessment?.signedBy ? detailAssessment?.signedBy : "NA"}</span>                                     
                            {` | `}
                            <span className={styles.assess_label_subDetail_label}>Locked:</span> <span className={styles.assess_label_subDetail_labelout}>{detailAssessment?.lockedBy ? detailAssessment?.lockedBy : "NA"}</span> 
                        </div>

                        </div>
                        {/* </div> */}
                        {/* <div className={`col-3`}>                
                            {
                            detailAssessment && detailAssessment?.reEvalSequence > 0 && userDetails?.role !== 'ExternalStandardUser' ? 
                                <div className={`row align-items-center`}>
                                <div className={`${styles.assement_all_link}`}>
                                    {
                                    detailAssessment && !isAdminView && 
                                    <a
                                        className={styles.assess_link}
                                        onClick={() => modalOpen()}
                                    >
                                        All assessments                                              
                                        <span 
                                            className={`${styles.right_Arrow}`}
                                            onClick={() => modalOpen()}                         
                                            >                                                    
                                            <i class="fa-solid fa-circle-right" aria-hidden="true"></i>
                                        </span> 
                                    </a>
                                    }
                                </div>                                          
                            </div>

                            : ''
                            }                  
                        </div>                             */}
                    </div>
                    </article>

                    <article className={`min`}>
                        <div className={`${styles.border_head}`}>
                            <div className="col-12 d-flex gery_box">
                                <div className="col-6">
                                    <h6>{patientData?.firstName + ' ' + patientData?.lastName}</h6>
                                    <p>{patientData?.age + ' '+'old'+', ' + patientData?.gender?.toLowerCase()}</p>
                                    {/* <p>DOB: {dateOfBirthDate(patientData?.dob)}</p> */}
                                    <p>NE1 Code: {patientData?.ne1Id}</p>
                                </div>
                                <div className="col-6">
                                    <div className={styles.customer_block}>
                                        {otherData?.customerLogo ? <img src={otherData?.customerLogo} alt="customer logo" className={styles.logo} /> : <span className={styles.no_logo}>No Logo</span>}
                                        {facilityData && facilityData.length > 0 && <div>
                                            <h6>{facilityData[1]}</h6>
                                            <p className={`mb-0 pb-1`}>{facilityData[2]}</p>
                                            <p>{facilityData[3]}</p>
                                        </div>}
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>

                    <article className={`min`}>
                        <div className="container-fluid">

                            <div className="row mt-3">
                                <div className="col-6 pl-4">
                                    <h6 style={{fontSize:'18px'}}>{detailAssessment?.title}</h6>
                                    <div className={`${styles.assess_content_label} ${styles.assess_content} my-4`}>  
                                        <span className={styles.assess_label}>History/Cause: </span>
                                        {(detailAssessment?.historyCause?.causeCategory !== "unknown" && detailAssessment?.historyCause?.causeCategory !== "unabletodetermine") ? (allNullCheck(detailAssessment?.historyCause?.causeCategory) && allNullCheck(detailAssessment?.historyCause?.cause) ?
                                            <span className=''>                                  
                                                {historyCause && historyCause.length > 0 && historyCause?.map((data)=>{
                                                if(data.key===detailAssessment?.historyCause?.causeCategory){
                                                    return data?.options?.map((item)=>{
                                                    if(item.key===detailAssessment?.historyCause?.cause || item.value===detailAssessment?.historyCause?.cause){
                                                        return detailAssessment?.historyCause?.causeCategory==='others'?`${'Other'}-${item.value}`:data.value + '-' + item.value
                                                    }
                                                    })
                                                }
                                                })}
                                                 {detailAssessment?.historyCause?.causeCategory==='others'&&`${'Other'}-${detailAssessment?.historyCause?.cause}`}
                                            </span>
                                            : <span className=''>{`-`}</span>)
                                            : (detailAssessment?.historyCause?.causeCategory === "unabletodetermine" ? <span className=''>{`Unable to determine`}</span> : <span className=''>{`Unknown`}</span>)
                                        }
                                    </div>
                                    {/* <p>{fullMonthFormatDate(allNullCheck(detailAssessment?.lastAssessedDate) ? detailAssessment?.lastAssessedDate : detailAssessment?.identifiedDate)}</p> */}
                                    
                                    {/* added for Labels */}
                                    {/* <div className={`${styles.assess_content_label} ${styles.assess_content} mb-4 mt-4`}>  
                                        <span className={styles.assess_label}>Labels: </span>
                                        {detailAssessment?.injuryType === null && detailAssessment?.bodyType === null && <span className={`${styles.sub_label_content} mr-3`}>None</span>}
                                        {detailAssessment?.injuryType !== "null" && <span className={detailAssessment?.injuryType !== "null" ? `${styles.sub_label_content} mr-3`: `d-none`}>{detailAssessment?.injuryType}</span>}
                                        {detailAssessment?.bodyType !== "null" && <span className={detailAssessment?.bodyType !== "null" ? `${styles.sub_label_content} mr-3` : `d-none`}>{detailAssessment?.bodyType}</span>}
                                    </div> */}

                                <div className={`${styles.assess_content_label} ${styles.assess_content} mb-4`}>  
                                    <span className={styles.assess_label}>Labels: </span>
                                    
                                    {(detailAssessment?.bodyType === "null" && detailAssessment.injuryType === 'None') &&  <span className={`${styles.sub_label_content_None} mr-3`}>
                                    <span> None </span>
                                    </span>}
                                    {(detailAssessment?.injuryType === "null" && detailAssessment?.bodyType === "null") &&  <span className={`${styles.sub_label_content_None} mr-3`}>
                                    <span> None </span>
                                    </span>} 
                                    {(detailAssessment?.injuryType === null && detailAssessment?.bodyType === null) &&  <span className={`${styles.sub_label_content_None} mr-3`}>
                                    <span> None </span>
                                    </span>}
                                    {(detailAssessment?.injuryType === "None" && detailAssessment?.bodyType === "None")  &&  <span className={`${styles.sub_label_content_None} mr-3`}>
                                    <span> None </span>
                                    </span>}
                                    {(detailAssessment?.bodyType === "" && detailAssessment?.injuryType === "") &&  <span className={`${styles.sub_label_content_None} mr-3`}>
                                    <span> None </span>
                                    </span>}
                                    {((detailAssessment?.bodyType === "null"||detailAssessment?.bodyType === null) && detailAssessment?.injuryType === "") &&  <span className={`${styles.sub_label_content_None} mr-3`}>
                                    <span> None </span>
                                    </span>}
                                    {((detailAssessment?.injuryType === "null"||detailAssessment?.injuryType === null) && detailAssessment?.bodyType === "") &&  <span className={`${styles.sub_label_content_None} mr-3`}>
                                    <span> None </span>
                                    </span>}                                                                
                                    
                                    
                                {(detailAssessment?.injuryType !== "null" && detailAssessment?.bodyType !== "null") || (detailAssessment?.injuryType !== "None") ? <>
                                <span className={detailAssessment?.injuryType !== "null" && detailAssessment?.injuryType !== null && detailAssessment?.injuryType !== "None" && detailAssessment?.injuryType !== "" && `${styles.sub_label_content} mr-3`}>
                                    {detailAssessment?.injuryType !== "null" && detailAssessment?.injuryType !== null && detailAssessment?.injuryType !== "None" ? <span>{detailAssessment?.injuryType}</span> : <span className='d-none'>{"None"}</span>}
                                    </span>
                                
                                    <span className={detailAssessment?.bodyType !== "null" && detailAssessment?.bodyType !== null && detailAssessment?.bodyType !== "None" && detailAssessment?.bodyType !== "" && `${styles.sub_label_content} mr-3`}>
                                    {detailAssessment?.bodyType !== "null" && detailAssessment?.bodyType !== null && detailAssessment?.bodyType !== "None" ? <span>{detailAssessment?.bodyType}</span> : <span className='d-none'>{"None"}</span>}
                                   
                                    </span>
                                </> : <span className={(detailAssessment?.bodyType === "null" && detailAssessment.injuryType === 'None') ? 'd-none' : `${styles.sub_label_content} mr-3`} 
                                        // styles={(assessment?.injuryType === "None" && assessment?.bodyType === "null") && {display:'none'}}
                                        >
                                    <span className={(detailAssessment?.bodyType === "None" || detailAssessment?.injuryType === "None") ? 'd-none': (detailAssessment?.injuryType === "None" && detailAssessment?.bodyType === "null") && 'd-inline'}> None </span>
                                    </span>}
                                        
                                </div>
                                    
                                    {/* {healingTrajectoryData?.dimensions?.surfaceArea?.value !== 'Unknown' &&
                                        <h3>{healingTrajectoryData?.dimensions?.surfaceArea?.value} cm<sup>2</sup></h3>
                                    } */}
                                    
                                    <div className={`${styles.assess_content} mb-4 WoundAcquired`}>
                                        <span className={styles.assess_label}>Wound Acquired: &#173;</span>                
                                        {detailAssessment?.woundAcquired ? ((detailAssessment?.woundAcquired ==="POA") ? <span className={`${styles.POAColor}`}>{detailAssessment?.woundAcquired}</span> : <span className={`${styles.NONPOAColor}`}>{detailAssessment?.woundAcquired}</span>) : 'NA'}
                                    </div>

                                    <div className={`${styles.assess_content} pb-3`}>
                                        <span className={`${styles.assess_label}`}>Classification: </span>

                                        {detailAssessment?.classification?.woundClassification !== "unknown" ? (allNullCheck(detailAssessment?.classification?.woundClassification) ?                                  
                                        <span className={`${styles.classifi_content}`}>
                                            {woundClassification && woundClassification?.result?.length > 0 && woundClassification?.result?.map((data) => {
                                                if (data.key === detailAssessment?.classification?.woundClassification) {
                                                    return data.value
                                                }
                                            })
                                            }
                                        </span> : <span className={`${styles.classifi_content}`}>{`Unknown`}</span>):
                                        <span className={`${styles.classifi_content}`}>{`Unknown`}</span>
                                        }
                                    </div>

                                    <div className={styles.chart_width} id={'pdf_chart'}>
                                        {healTraj && healTraj.length === 0 ?
                                            <div className="text-left mt-5">
                                                <h5 style={{fontSize : "25px"}}><i>Chart is not available</i></h5>
                                            </div>
                                            :
                                            <>
                                                {healingTrajectoryData?.dimensions?.surfaceArea?.value !== 'Unknown' ?
                                                <div className={`${styles.chart_box}`}>
                                                    <FlexibleWidthXYPlot height={400} xType='ordinal' yDomain={[0, yAxis]} >
                                                        <VerticalGridLines />
                                                        <HorizontalGridLines />
                                                        <XAxis title="Date" />
                                                        <YAxis title="Surface Area" />
                                                        <LineMarkSeries
                                                            data={surfaceArray}
                                                            size={3}
                                                            stroke={'#387ac4'}
                                                            fill={'#387ac4'}
                                                        />
                                                    </FlexibleWidthXYPlot>
                                                    </div>
                                                    :
                                                    <div className="text-left mt-5">
                                                        <h5 style={{fontSize : "25px"}}><i>Chart is not available</i></h5>
                                                    </div>
                                                }
                                            </>}
                                    </div>

                                </div>
                                <div className="col-6">
                                    
                                    <div className={`${styles.wound_image} text-left pl-3 pt-2`}>
                                        {healingTrajectoryData?.woundPicture ? (withImage ? <img src={healingTrajectoryData?.woundPicture} alt="test" /> : <div className={`text-left pb-1 pl-1`}>
                                                    {/* <h5><i>No image chosen</i></h5> */}
                                                    <img src={pdfnoimage} style={{width:'275px'}} alt="test" />
                                                </div>) : 
                                                <div className="text-left pt-5">
                                                    <h5><i>Image is not available</i></h5>
                                                </div>}
                                    </div>
                                    
                                    <div className={`text-left pt-3 pb-2 pl-5`}>
                                    {mainChecker(detailAssessment, 'woundStage') &&
                                        <p style={{paddingLeft : '40px'}}>Progress: <strong>{optionsMapper(woundProgress, detailAssessment?.woundStage)}</strong></p>
                                    }
                                    </div>
                                    <div className={`${styles.score} pl-5`}>
                                        <div className={`${styles.score_part}`}>
                                            <span>{healingTrajectoryData?.score ? healingTrajectoryData?.score : 0}</span>
                                            <span>{(scoreType&&scoreType === 'push') ? 'PUSH':(scoreType&&scoreType==='bwat')?'BWAT':'-'}</span> 
                                            <span></span>
                                        </div>
                                        <div className={`${styles.percentage_part}`}>
                                            <span>% Change</span>
                                            <span>
                                            {/* {healingTrajectoryData?.closurePercentage} */}
                                            {healingTrajectoryData?.changeInCm ? 
                                                (healingTrajectoryData?.changeInCm !== "Unknown" ? 
                                                <span className={healingTrajectoryData?.changeInCm !== "0" ? (healingTrajectoryData?.changeInCm < "0" ? `${styles.closureColor} ${styles.closureColor_green} mt-2` : `${styles.closureColor} ${styles.closureColor_red} mt-2`) : `${styles.closureColor} ${styles.closureColor_grey} mt-2`}>
                                                    <span style={{paddingRight: "4px"}}>                                             
                                                        <i className={healingTrajectoryData?.changeInCm !== "0" && (healingTrajectoryData?.changeInCm < "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                    </span>
                                                    {Number(healingTrajectoryData?.changeInCm.replace( /^\D+/g, ''))} cm{detailAssessment?.measurement?.isStickerAvailable ===1 &&detailAssessment?.isWoundWithoutPhoto === 0&&<sup>2</sup>}
                                                    
                                                </span> : <span className={`${styles.closureColor} ${styles.closureColor_grey} mt-2`}>0 cm{detailAssessment?.measurement?.isStickerAvailable ===1 &&detailAssessment?.isWoundWithoutPhoto === 0&&<sup>2</sup>}</span>                                                
                                                ) : "-"
                                            }
                                            </span>
                                            <span>
                                            {healingTrajectoryData?.closurePercentage ? 
                                                (healingTrajectoryData?.closurePercentage !== "Unknown" ? 
                                                <span className={healingTrajectoryData?.closurePercentage !== "0" ? (healingTrajectoryData?.closurePercentage < "0" ? `${styles.closureColor} ${styles.closurePercentage_green} ${healingTrajectoryData?.changeInCm !== "0" && `pl-2`}`: `${styles.closureColor} ${styles.closurePercentage_red} ${healingTrajectoryData?.changeInCm !== "0" && `pl-2`}`) : `${styles.closureColor} ${styles.closureColor_grey} ${healingTrajectoryData?.changeInCm !== "0" && `pl-2`}`}>
                                               
                                                    {`(`}{healingTrajectoryData?.closurePercentage.replace( /^\D+/g, '')} %{`)`}
                                                </span> : <span className={`${styles.closureColor} ${styles.closureColor_grey}`}>Unknown</span>
                                                ) : "-"  
                                            }
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className={styles.traj_bwat}>
                                        <span className="d-block">{healTraj[currentAssess]?.score ? healTraj[currentAssess]?.score : 0}</span>
                                        <label>{(scoreType&&scoreType === BWAT) ? 'BWAT':(scoreType&&scoreType === 'push')? 'PUSH' : '-'}</label>
                                    </div> */}

                                    {/* <div className={`${styles.traj_bwat_clouser}`}>
                                        <div className={`text-center`}>% Change <br/>
                                            {healTraj[currentAssess]?.changeInCm ? 
                                                (healTraj[currentAssess]?.changeInCm !== "Unknown" ? 
                                                <span className={healTraj[currentAssess]?.changeInCm !== "0" ? (healTraj[currentAssess]?.changeInCm > "0" ? `${styles.closureColor} ${styles.closureColor_green} mt-2` : `${styles.closureColor} ${styles.closureColor_red} mt-2`) : `${styles.closureColor} ${styles.closureColor_grey} mt-2`}>
                                                    <span style={{paddingRight: "4px"}}>                                             
                                                        <i className={healTraj[currentAssess]?.changeInCm !== "0" && (healTraj[currentAssess]?.changeInCm > "0" ? `fa-solid fa-down-long ${styles.closureColor_green}` : `fa-solid fa-up-long ${styles.closureColor_red}`)}></i>
                                                    </span>
                                                    {Number(healTraj[currentAssess]?.changeInCm.replace( /^\D+/g, ''))} cm<sup>2</sup>
                                                    
                                                </span> : <span className={`${styles.closureColor} ${styles.closureColor_grey} mt-2`}>0 cm<sup>2</sup></span>                                                
                                                ) : "-"
                                            }
                                            {healTraj[currentAssess]?.closurePercentage ? 
                                                (healTraj[currentAssess]?.closurePercentage !== "Unknown" ? 
                                                <span className={healTraj[currentAssess]?.closurePercentage !== "0" ? (healTraj[currentAssess]?.closurePercentage > "0" ? `${styles.closureColor} ${styles.closureColor_green} ${healTraj[currentAssess]?.changeInCm !== "0" && `pl-2`}`: `${styles.closureColor} ${styles.closureColor_red} ${healTraj[currentAssess]?.changeInCm !== "0" && `pl-2`}`) : `${styles.closureColor} ${styles.closureColor_grey} ${healTraj[currentAssess]?.changeInCm !== "0" && `pl-2`}`}>
                                               
                                                    {`(`}{healTraj[currentAssess]?.closurePercentage.replace( /^\D+/g, '')} %{`)`}
                                                </span> : <span className={`${styles.closureColor} ${styles.closureColor_grey}`}>Unknown</span>
                                                ) : "-"  
                                            }                                       
                                        </div>  
                                    </div> */}
                                    {/* {mainChecker(detailAssessment, 'classification') &&
                                        <p>Wound Classification: <strong>{optionsMapper(woundClassification, detailAssessment?.classification?.woundClassification)}</strong></p>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </article>


                    <article className={`min ${styles.chart}`}>
                        <div className="row">
                            {/* <div className="col-6">

                                <div className={styles.chart_width}>
                                    {healTraj && healTraj.length === 0 ?
                                        <div className="text-center">
                                            <h5>Chart is not available.</h5>
                                        </div>
                                        :
                                        <>
                                            {healingTrajectoryData?.dimensions?.surfaceArea?.value !== 'Unknown' ?
                                                <FlexibleWidthXYPlot height={400} xType='ordinal' yDomain={[0, yAxis]} >
                                                    <VerticalGridLines />
                                                    <HorizontalGridLines />
                                                    <XAxis title="Date" />
                                                    <YAxis title="Surface Area" />
                                                    <LineMarkSeries
                                                        data={surfaceArray}
                                                        size={3}
                                                        stroke={'#387ac4'}
                                                        fill={'#387ac4'}
                                                    />
                                                </FlexibleWidthXYPlot>
                                                :
                                                <div className="text-center">
                                                    <h5>Chart is not available.</h5>
                                                </div>
                                            }
                                        </>}
                                </div>
                            </div> */}
                            <div className="col-6">
                                {/* <div className={styles.wound_image}>
                                    {healingTrajectoryData?.woundPicture ? <img src={healingTrajectoryData?.woundPicture} alt="test" /> : <div className="text-center">
                                                <h5>Image is not available.</h5>
                                            </div>}
                                </div> */}
                            </div>
                        </div>
                    </article>

                    {/* {allNullCheck(healingTrajectoryData?.dimensions?.surfaceArea?.value) &&  */}
                        <article className={`min ${styles.tabley}`}>
                            <table>
                                <thead>
                                    <th>Healing Progression Rate (HPR)</th>
                                    <th>Value</th>
                                    <th>% Change</th>
                                </thead>
                                <tr>
                                    <td>Worst Tissue Type (WTT)</td>
                                    <td>{healingTrajectoryData?.healingProgressionRate?.worstTissueType?.value}</td>
                                    <td>
                                        
                                        {/* {healingTrajectoryData?.healingProgressionRate?.worstTissueType?.differencePercentage !== 'Unknown'
                                        ?
                                        healingTrajectoryData?.healingProgressionRate?.worstTissueType?.differencePercentage + '%'
                                        :
                                        'Unknown'} */}


                                        {healingTrajectoryData?.healingProgressionRate?.worstTissueType?.differencePercentage ? 
                                            (healingTrajectoryData?.healingProgressionRate?.worstTissueType?.differencePercentage !== "Unknown" ? 
                                            <span className={healingTrajectoryData?.healingProgressionRate?.worstTissueType?.differencePercentage !== "0" ? (healingTrajectoryData?.healingProgressionRate?.worstTissueType?.differencePercentage < "0" ? `${styles.closureColor} ${styles.chart_box_green} mt-2` : `${styles.closureColor} ${styles.chart_box_red} mt-2`) : `${styles.closureColor} mt-2`}>
                                                <span>                                             
                                                    <i className={healingTrajectoryData?.healingProgressionRate?.worstTissueType?.differencePercentage !== "0" && (healingTrajectoryData?.healingProgressionRate?.worstTissueType?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.chart_box_green}` : `fa-solid fa-up-long ${styles.chart_box_red}`)}></i>
                                                </span>
                                                {Number(healingTrajectoryData?.healingProgressionRate?.worstTissueType?.differencePercentage.replace( /^\D+/g, ''))} %                        
                                            </span> : <span className={`mt-2`}>Unknown</span>                                                
                                            ) : "-"
                                        }


                                    </td>
                                </tr>
                                <tr>
                                    <td>Surface Area Value (SAV)</td>
                                    <td>{healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.value}</td>
                                    <td>
                                        {/* {healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.differencePercentage !== 'Unknown'
                                        ?
                                        healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.differencePercentage + '%'
                                        :
                                        'Unknown'} */}

                                        {healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.differencePercentage ? 
                                            (healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.differencePercentage !== "Unknown" ? 
                                            <span className={healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.differencePercentage !== "0" ? (healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.differencePercentage < "0" ? `${styles.closureColor} ${styles.chart_box_green} mt-2` : `${styles.closureColor} ${styles.chart_box_red} mt-2`) : `${styles.closureColor} mt-2`}>
                                                <span>                                             
                                                    <i className={healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.differencePercentage !== "0" && (healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.chart_box_green}` : `fa-solid fa-up-long ${styles.chart_box_red}`)}></i>
                                                </span>
                                                {Number(healingTrajectoryData?.healingProgressionRate?.surfaceAreaValue?.differencePercentage.replace( /^\D+/g, ''))} %                        
                                            </span> : <span className={`mt-2`}>Unknown</span>                                                
                                            ) : "-"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Intact Value (IV)</td>
                                    <td>{healingTrajectoryData?.healingProgressionRate?.intactValue?.value}</td>
                                    <td>
                                        {/* {healingTrajectoryData?.healingProgressionRate?.intactValue?.differencePercentage !== 'Unknown'
                                        ?
                                        healingTrajectoryData?.healingProgressionRate?.intactValue?.differencePercentage + '%'
                                        :
                                        'Unknown'} */}
                                        {healingTrajectoryData?.healingProgressionRate?.intactValue?.differencePercentage ? 
                                            (healingTrajectoryData?.healingProgressionRate?.intactValue?.differencePercentage !== "Unknown" ? 
                                            <span className={healingTrajectoryData?.healingProgressionRate?.intactValue?.differencePercentage !== "0" ? (healingTrajectoryData?.healingProgressionRate?.intactValue?.differencePercentage < "0" ? `${styles.closureColor} ${styles.chart_box_green} mt-2` : `${styles.closureColor} ${styles.chart_box_red} mt-2`) : `${styles.closureColor} mt-2`}>
                                                <span>                                             
                                                    <i className={healingTrajectoryData?.healingProgressionRate?.intactValue?.differencePercentage !== "0" && (healingTrajectoryData?.healingProgressionRate?.intactValue?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.chart_box_green}` : `fa-solid fa-up-long ${styles.chart_box_red}`)}></i>
                                                </span>
                                                {Number(healingTrajectoryData?.healingProgressionRate?.intactValue?.differencePercentage.replace( /^\D+/g, ''))} %                        
                                            </span> : <span className={`mt-2`}>Unknown</span>                                                
                                            ) : "-"
                                        }

                                    </td>
                                </tr>
                            </table>
                        </article>
                        {/* } */}
                    
                    {/* {allNullCheck(healingTrajectoryData?.dimensions?.surfaceArea?.value) &&  */}
                        <article className={`min ${styles.tabley}`}>
                            <table>
                                <thead>
                                    <th>Dimensions</th>
                                    <th>Value</th>
                                    <th>% Change</th>
                                </thead>
                                <tr>
                                    <td>Surface Area</td>
                                    <td>{allNullCheck(healingTrajectoryData?.dimensions?.surfaceArea?.value) ? <>{healingTrajectoryData?.dimensions?.surfaceArea?.value}<span> cm{detailAssessment?.measurement?.isStickerAvailable ===1 &&detailAssessment?.isWoundWithoutPhoto === 0&&<sup>2</sup>}</span></> : 'Unknown' }</td>
                                    <td>
                                        {/* {healingTrajectoryData?.dimensions?.surfaceArea?.differencePercentage !== 'Unknown'
                                        ?
                                        healingTrajectoryData?.dimensions?.surfaceArea?.differencePercentage + '%'
                                        :
                                        'Unknown'} */}
                                        {healingTrajectoryData?.dimensions?.surfaceArea?.differencePercentage ? 
                                            (healingTrajectoryData?.dimensions?.surfaceArea?.differencePercentage !== "Unknown" ? 
                                            <span className={healingTrajectoryData?.dimensions?.surfaceArea?.differencePercentage !== "0" ? (healingTrajectoryData?.dimensions?.surfaceArea?.differencePercentage < "0" ? `${styles.closureColor} ${styles.chart_box_green} mt-2` : `${styles.closureColor} ${styles.chart_box_red} mt-2`) : `${styles.closureColor} mt-2`}>
                                                <span>                                             
                                                    <i className={healingTrajectoryData?.dimensions?.surfaceArea?.differencePercentage !== "0" && (healingTrajectoryData?.dimensions?.surfaceArea?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.chart_box_green}` : `fa-solid fa-up-long ${styles.chart_box_red}`)}></i>
                                                </span>
                                                {Number(healingTrajectoryData?.dimensions?.surfaceArea?.differencePercentage.replace( /^\D+/g, ''))} %                        
                                            </span> : <span className={`mt-2`}>Unknown</span>                                                
                                            ) : "-"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Length</td>
                                    <td>{allNullCheck(healingTrajectoryData?.dimensions?.length?.value) ? <>{healingTrajectoryData?.dimensions?.length?.value}<span> cm</span></> : 'Unknown'}</td>
                                    <td>
                                        {/* {healingTrajectoryData?.dimensions?.length?.differencePercentage !== 'Unknown'
                                        ?
                                        healingTrajectoryData?.dimensions?.length?.differencePercentage + '%'
                                        :
                                        'Unknown'} */}
                                        {healingTrajectoryData?.dimensions?.length?.differencePercentage ? 
                                            (healingTrajectoryData?.dimensions?.length?.differencePercentage !== "Unknown" ? 
                                            <span className={healingTrajectoryData?.dimensions?.length?.differencePercentage !== "0" ? (healingTrajectoryData?.dimensions?.length?.differencePercentage < "0" ? `${styles.closureColor} ${styles.chart_box_green} mt-2` : `${styles.closureColor} ${styles.chart_box_red} mt-2`) : `${styles.closureColor} mt-2`}>
                                                <span>                                             
                                                    <i className={healingTrajectoryData?.dimensions?.length?.differencePercentage !== "0" && (healingTrajectoryData?.dimensions?.length?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.chart_box_green}` : `fa-solid fa-up-long ${styles.chart_box_red}`)}></i>
                                                </span>
                                                {Number(healingTrajectoryData?.dimensions?.length?.differencePercentage.replace( /^\D+/g, ''))} %                        
                                            </span> : <span className={`mt-2`}>Unknown</span>                                                
                                            ) : "-"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Width</td>
                                    <td>{allNullCheck(healingTrajectoryData?.dimensions?.width?.value) ? <>{healingTrajectoryData?.dimensions?.width?.value}<span> cm</span></> : 'Unknown'}</td>
                                    <td>
                                        {/* {healingTrajectoryData?.dimensions?.width?.differencePercentage !== 'Unknown'
                                        ?
                                        healingTrajectoryData?.dimensions?.width?.differencePercentage + '%'
                                        :
                                        'Unknown'} */}
                                        {healingTrajectoryData?.dimensions?.width?.differencePercentage ? 
                                            (healingTrajectoryData?.dimensions?.width?.differencePercentage !== "Unknown" ? 
                                            <span className={healingTrajectoryData?.dimensions?.width?.differencePercentage !== "0" ? (healingTrajectoryData?.dimensions?.width?.differencePercentage < "0" ? `${styles.closureColor} ${styles.chart_box_green} mt-2` : `${styles.closureColor} ${styles.chart_box_red} mt-2`) : `${styles.closureColor} mt-2`}>
                                                <span>                                             
                                                    <i className={healingTrajectoryData?.dimensions?.width?.differencePercentage !== "0" && (healingTrajectoryData?.dimensions?.width?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.chart_box_green}` : `fa-solid fa-up-long ${styles.chart_box_red}`)}></i>
                                                </span>
                                                {Number(healingTrajectoryData?.dimensions?.width?.differencePercentage.replace( /^\D+/g, ''))} %                        
                                            </span> : <span className={`mt-2`}>Unknown</span>                                                
                                            ) : "-"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Depth</td>
                                    <td>{allNullCheck(healingTrajectoryData?.dimensions?.depth?.value) ? <>{healingTrajectoryData?.dimensions?.depth?.value}<span> cm</span></> : 'Unknown'}</td>
                                    <td>
                                        {/* {healingTrajectoryData?.dimensions?.depth?.differencePercentage !== 'Unknown'
                                        ?
                                        healingTrajectoryData?.dimensions?.depth?.differencePercentage + '%'
                                        :
                                        'Unknown'} */}
                                        {healingTrajectoryData?.dimensions?.depth?.differencePercentage ? 
                                            (healingTrajectoryData?.dimensions?.depth?.differencePercentage !== "Unknown" ? 
                                            <span className={healingTrajectoryData?.dimensions?.depth?.differencePercentage !== "0" ? (healingTrajectoryData?.dimensions?.depth?.differencePercentage < "0" ? `${styles.closureColor} ${styles.chart_box_green} mt-2` : `${styles.closureColor} ${styles.chart_box_red} mt-2`) : `${styles.closureColor} mt-2`}>
                                                <span>                                             
                                                    <i className={healingTrajectoryData?.dimensions?.depth?.differencePercentage !== "0" && (healingTrajectoryData?.dimensions?.depth?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.chart_box_green}` : `fa-solid fa-up-long ${styles.chart_box_red}`)}></i>
                                                </span>
                                                {Number(healingTrajectoryData?.dimensions?.depth?.differencePercentage.replace( /^\D+/g, ''))} %                        
                                            </span> : <span className={`mt-2`}>Unknown</span>                                                
                                            ) : "-"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Undermining </td>
                                    <td>{allNullCheck(healingTrajectoryData?.dimensions?.undermining?.value) ? <>{healingTrajectoryData?.dimensions?.undermining?.value}<span> cm</span></> : 'Unknown'}</td>
                                    <td>
                                        {/* {healingTrajectoryData?.dimensions?.undermining?.differencePercentage !== 'Unknown'
                                        ?
                                        healingTrajectoryData?.dimensions?.undermining?.differencePercentage + '%'
                                        :
                                        'Unknown'} */}
                                        {healingTrajectoryData?.dimensions?.undermining?.differencePercentage ? 
                                            (healingTrajectoryData?.dimensions?.undermining?.differencePercentage !== "Unknown" ? 
                                            <span className={healingTrajectoryData?.dimensions?.undermining?.differencePercentage !== "0" ? (healingTrajectoryData?.dimensions?.undermining?.differencePercentage < "0" ? `${styles.closureColor} ${styles.chart_box_green} mt-2` : `${styles.closureColor} ${styles.chart_box_red} mt-2`) : `${styles.closureColor} mt-2`}>
                                                <span>                                             
                                                    <i className={healingTrajectoryData?.dimensions?.undermining?.differencePercentage !== "0" && (healingTrajectoryData?.dimensions?.undermining?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.chart_box_green}` : `fa-solid fa-up-long ${styles.chart_box_red}`)}></i>
                                                </span>
                                                {Number(healingTrajectoryData?.dimensions?.undermining?.differencePercentage.replace( /^\D+/g, ''))} %                        
                                            </span> : <span className={`mt-2`}>Unknown</span>                                                
                                            ) : "-"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tunneling </td>
                                    <td>{allNullCheck(healingTrajectoryData?.dimensions?.tunneling?.value) ? <>{healingTrajectoryData?.dimensions?.tunneling?.value}<span> cm</span></> : 'Unknown'}</td>
                                    <td>
                                        {/* {healingTrajectoryData?.dimensions?.tunneling?.differencePercentage !== 'Unknown'
                                        ?
                                        healingTrajectoryData?.dimensions?.tunneling?.differencePercentage + '%'
                                        :
                                        'Unknown'} */}
                                        {healingTrajectoryData?.dimensions?.tunneling?.differencePercentage ? 
                                            (healingTrajectoryData?.dimensions?.tunneling?.differencePercentage !== "Unknown" ? 
                                            <span className={healingTrajectoryData?.dimensions?.tunneling?.differencePercentage !== "0" ? (healingTrajectoryData?.dimensions?.tunneling?.differencePercentage < "0" ? `${styles.closureColor} ${styles.chart_box_green} mt-2` : `${styles.closureColor} ${styles.chart_box_red} mt-2`) : `${styles.closureColor} mt-2`}>
                                                <span>                                             
                                                    <i className={healingTrajectoryData?.dimensions?.tunneling?.differencePercentage !== "0" && (healingTrajectoryData?.dimensions?.tunneling?.differencePercentage < "0" ? `fa-solid fa-down-long ${styles.chart_box_green}` : `fa-solid fa-up-long ${styles.chart_box_red}`)}></i>
                                                </span>
                                                {Number(healingTrajectoryData?.dimensions?.tunneling?.differencePercentage.replace( /^\D+/g, ''))} %                        
                                            </span> : <span className={`mt-2`}>Unknown</span>                                                
                                            ) : "-"
                                        }
                                    </td>
                                </tr>
                            </table>
                        </article>
                        {/* } */}



                
                    {/* Re- opened  */}
                    <article className="min">
                        <h2>Characteristics</h2>

                        <div className={styles.tabley}>
                            <table>
                                <thead>
                                    <th>History/Cause</th>
                                    <th></th>
                                </thead>

                                {mapper(detailAssessment?.historyCause, 'cause') && <tr>
                                    <td>History/Cause</td>
                                    <td>
                                        {historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                            let value;
                                            if (data?.options?.length > 0) {
                                                data.options.map((sub) => {
                                                    if (sub.key === mapper(detailAssessment?.historyCause, 'cause')) {
                                                        // value = `${data.value}-${sub.value}`
                                                        value = `${sub.value}`
                                                    }
                                                })
                                            }
                                            else {
                                                if (data.key === mapper(detailAssessment?.historyCause, 'cause')) {
                                                    value = data.value
                                                }
                                            }
                                            return value;
                                        })}
                                    </td>
                                </tr>}

                                {mapper(detailAssessment?.historyCause, 'conditionPOA', true) && <tr>
                                    <td>Condition POA</td>
                                    <td>{mapper(detailAssessment?.historyCause, 'conditionPOA', true)}</td>
                                </tr>}

                                

                                {mapper(detailAssessment?.historyCause, 'documentProvidedBy') && <tr>
                                    <td>Document Provided By</td>
                                    <td>{optionsMapper(documentationType, mapper(detailAssessment?.historyCause, 'documentProvidedBy'))}</td>
                                </tr>}
    

                                {mapper(detailAssessment?.historyCause, 'photoType') && <tr>
                                    <td>Photo Type</td>
                                    <td>{optionsMapper(photoType, mapper(detailAssessment?.historyCause, 'photoType'))}</td>
                                </tr>}

                                {mapper(detailAssessment?.historyCause, 'piHistory') && <tr>
                                    <td>Does the Patient has a history of Pressure Injury/Ulcer for the same wound</td>
                                    <td>{optionsMapper(piHistory, mapper(detailAssessment?.historyCause, 'piHistory'))}</td>
                                </tr>}

                                {mapper(detailAssessment?.historyCause, 'woundAge') && <tr>
                                    <td>Wound Age</td>
                                    <td>{optionsMapper(woundAge, mapper(detailAssessment?.historyCause, 'woundAge'))}</td>
                                </tr>}

                            </table>
                        </div>

                    </article>

                    <article className={`min ${styles.tabley}`}>
                        <table>
                            <thead>
                                <th>Anatomy</th>
                                <th></th>
                            </thead>
                            <tr>
                                <td>Anatomy</td>
                                <td>{optionsMapper(humanAnatomy, detailAssessment?.anatomy)}</td>
                            </tr>
                        </table>
                    </article>

                    {mainChecker(detailAssessment, 'tissueTypes') && <article className={`min ${styles.tabley}`}>
                        <table>
                            {detailAssessment?.tissueTypes !== null && detailAssessment?.tissueTypes?.length !== 0 &&
                            <thead>
                                <th>Tissue Types</th>
                                <th></th>
                            </thead>}
                            {tissueTypedata && tissueTypedata.length > 0 && tissueTypedata.map((data, i) => {
                                return <tr>
                                    <td>Tissue Types {i + 1}</td>
                                    <td>{data.name} {allNullCheck(data.percent) && ' | Covered ' + data.percent + '%'}</td>
                                </tr>
                            })}
                        </table>
                    </article>}

                    {mainChecker(otherData, 'touchView') && <article className={`min ${styles.tabley}`}>
                        <table>
                            {detailAssessment?.touchView !== null &&
                            <thead>
                                <th>Touch &amp; View Details</th>
                                <th></th>
                            </thead>}

                            {mapper(otherData?.touchView, 'openAreas', true) && <tr>
                                <td>Are There Any Open Areas?</td>
                                <td>{mapper(otherData?.touchView, 'openAreas', true)}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'yellowFibrin', true) && <tr>
                                <td>Is Yellow Fibrin present in the wound?</td>
                                <td>{mapper(otherData?.touchView, 'yellowFibrin', true)}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'bioFilm', true) && <tr>
                                <td>Is Biofilm present in the wound?</td>
                                <td>{mapper(otherData?.touchView, 'bioFilm', true)}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'tissueTemperature') && <tr>
                                <td>Tissue Temperature</td>
                                <td>{mapper(otherData?.touchView, 'tissueTemperature')}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'tissueFirmness') && <tr>
                                <td>Tissue Firmness</td>
                                <td>{mapper(otherData?.touchView, 'tissueFirmness')}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'painLevel') && <tr>
                                <td>Pain Level</td>
                                <td>{mapper(otherData?.touchView, 'painLevel')}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'periwoundCondition') && <tr>
                                    <td>Periwound Skin Condition</td>
                                    <td>{mapper(otherData?.touchView, 'periwoundCondition')}</td>
                                </tr>}

                            {mapper(otherData?.touchView, 'blanchTest') && <tr>
                                <td>Blanch Test: Capillary Refill of Intact Skin</td>
                                <td>{mapper(otherData?.touchView, 'blanchTest')}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'evidenceOfInfection', true) && <tr>
                                <td>Evidence of Infection</td>
                                <td>{mapper(otherData?.touchView, 'evidenceOfInfection', true)}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'exudateType') && <tr>
                                <td>Exudate Type</td>
                                <td>{mapper(otherData?.touchView, 'exudateType').join(', ')}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'exudateAmount') && <tr>
                                <td>Exudate Amount</td>
                                <td>{mapper(otherData?.touchView, 'exudateAmount')}</td>
                            </tr>}

                            {mapper(otherData?.touchView, 'woundOdor', true) && <tr>
                                <td>Wound Odor</td>
                                <td>{mapper(otherData?.touchView, 'woundOdor', true)}</td>
                            </tr>}
                            
                            {scoreType&&scoreType === 'bwat' && <> 
                                {mapper(otherData?.touchView, 'woundEdges') && <tr>
                                    <td>Wound Edges</td>
                                    <td>{mapper(otherData?.touchView, 'woundEdges')}</td>
                                </tr>}

                                {mapper(otherData?.touchView, 'skinColorAroundWound') && <tr>
                                    <td>Skin Color Surrounding Wound</td>
                                    <td>{mapper(otherData?.touchView, 'skinColorAroundWound')}</td>
                                </tr>}

                                {mapper(otherData?.touchView, 'induration') && <tr>
                                    <td>Induration</td>
                                    <td>{mapper(otherData?.touchView, 'induration')}</td>
                                </tr>}

                                {mapper(otherData?.touchView, 'edema') && <tr>
                                    <td>Edema</td>
                                    <td>{mapper(otherData?.touchView, 'edema')}</td>
                                </tr>}

                                {mapper(otherData?.touchView, 'granulationTissue') && <tr>
                                    <td>Granulation Tissue</td>
                                    <td>{mapper(otherData?.touchView, 'granulationTissue')}</td>
                                </tr>}

                                {mapper(otherData?.touchView, 'epithelialization') && <tr>
                                    <td>Epithelialization</td>
                                    <td>{mapper(otherData?.touchView, 'epithelialization')}</td>
                                </tr>}
                            </>}

                        </table>
                    </article>}


                    {mainChecker(otherData, 'customForm') && <>
                        {otherData?.customForm?.length > 0 && otherData?.customForm?.map((data, i) => {
                            return <article className={`min ${styles.tabley}`}>
                                <table>
                                    <thead>
                                        <th>{data.formName}</th>
                                        <th></th>
                                    </thead>
                                    {data?.questions && data?.questions?.length && data?.questions.map((item, i) => {
                                        return <tr>
                                            <td>{item.question}</td>
                                            <td>{item.value ? item.value : item.answer}</td>
                                        </tr>
                                    })}
                                </table>
                            </article>
                        })

                        }

                    </>
                    }

                    {mainChecker(otherData, 'treatment') && <>

                        <article className="min">
                            <h2>Treatment</h2>
                        </article>

                        {otherData?.treatment?.length > 0 && otherData?.treatment?.map((data, mIndex) => {
                            return <>
                                <article className="min">
                                    <h4>{data.interventionName}</h4>
                                </article>



                                {/* Dressing Appearance */}
                                {mapper(data, 'dressingAppearance') && <article className={`min ${styles.tabley}`}>
                                    <table>
                                        <thead><th>Dressing Appearance</th></thead>
                                        {<tr>
                                            <td>Dressing Appearance</td>
                                            <td>{mapper(data, 'dressingAppearance')}</td>
                                        </tr>}
                                    </table>
                                </article>}

                                {/* Debridement */}
                                {mainChecker(data, 'debridement') && <article className={`min ${styles.tabley}`}>
                                    <table>
                                        <thead><th>Debridement</th></thead>

                                        {mapper(data?.debridement, 'isDebridementPerformed') && <tr>
                                            <td>Was There Sharp/Surgical Debridement Performed</td>
                                            <td>{mapper(data?.debridement, 'isDebridementPerformed')}</td>
                                        </tr>}

                                        {mapper(data?.debridement, 'debridementPerformedBy') && <tr>
                                            <td>Sharp/Surgical Debridement Performed By</td>
                                            <td>{mapper(data?.debridement, 'debridementPerformedBy')}</td>
                                        </tr>}

                                        {mapper(data?.debridement, 'surgicalDebridementProcedure') && <tr>
                                            <td>Type of Sharp/Surgical Debridement Procedure</td>
                                            <td>{mapper(data?.debridement, 'surgicalDebridementProcedure')}</td>
                                        </tr>}

                                        {mapper(data?.debridement, 'nonExcisionalApproach') && <tr>
                                            <td>Non-excisional approach</td>
                                            <td>{mapper(data?.debridement, 'nonExcisionalApproach')}</td>
                                        </tr>}

                                        {mapper(data?.debridement, 'depthOfDebridement') && <tr>
                                            <td>Depth of Debridement - Deepest Level</td>
                                            <td>{mapper(data?.debridement, 'depthOfDebridement')}</td>
                                        </tr>}

                                        
                                        {mapper(data?.debridement, 'necroticSubcutaneousTissue') && <tr>
                                            <td>Necrotic Subcutaneous Tissue</td>
                                            <td>{mapper(data?.debridement, 'necroticSubcutaneousTissue').join(', ')}</td>
                                        </tr>}

                                        
                                        {mapper(data?.debridement, 'necroticTissue') && <tr>
                                            <td>Necrotic Tissue</td>
                                            <td>{mapper(data?.debridement, 'necroticTissue').join(', ')}</td>
                                        </tr>}

                                        
                                        {mapper(data?.debridement, 'notApplicable') && <tr>
                                            <td>N/A</td>
                                            <td>{mapper(data?.debridement, 'notApplicable').join(', ')}</td>
                                        </tr>}

                                        
                                        {mapper(data?.debridement, 'instrumentsUsed') && <tr>
                                            <td>Instruments Used</td>
                                            <td>{mapper(data?.debridement, 'instrumentsUsed').join(', ')}</td>
                                        </tr>}

                                        {mapper(data?.debridement, 'surfaceAreaOfNecroticSkinTissue') && <tr>
                                            <td>Surface Area Debrided of Necrotic Skin (cm²)</td>
                                            <td>{mapper(data?.debridement, 'surfaceAreaOfNecroticSkinTissue')}</td>
                                        </tr>}

                                        {mapper(data?.debridement, 'surfaceAreaOfNecroticSubqTissue') && <tr>
                                            <td>Surface Area Debrided of Subq Tissue (cm²)</td>
                                            <td>{mapper(data?.debridement, 'surfaceAreaOfNecroticSubqTissue')}</td>
                                        </tr>}

                                        {mapper(data?.debridement, 'postDebridementBleedingAmount') && <tr>
                                            <td>Post Debridement Bleeding Amount</td>
                                            <td>{mapper(data?.debridement, 'postDebridementBleedingAmount')}</td>
                                        </tr>}

                                        {mapper(data?.debridement, 'techniqueUsed') && <tr>
                                            <td>Technique Used</td>
                                            <td>{mapper(data?.debridement, 'techniqueUsed')}</td>
                                        </tr>}

                                        {mapper(data?.debridement, 'stoppedBleedingContactPressure', true) && <tr>
                                            <td>Stopped Bleeding with Contact Pressure</td>
                                            <td>{mapper(data?.debridement, 'stoppedBleedingContactPressure', true)}</td>
                                        </tr>}


                                    </table>
                                </article>}

                                {/* Wound cleanser */}
                                {mapper(data, 'woundCleanser') && <article className={`min ${styles.tabley}`}>
                                    <table>
                                        <thead><th>Wound Cleanser</th></thead>
                                        {mapper(data, 'woundCleanser').length > 0 && mapper(data, 'woundCleanser').map((item) => {
                                            return <tr>
                                                <td>{item?.name}</td>
                                                <td className={styles.treatment_field}>
                                                    <h5>
                                                        {item?.value?.productFamilyDesc}
                                                    </h5>
                                                    <h6 className={styles.treatment_sub}>
                                                        {item?.value?.materialDescription}
                                                    </h6>
                                                    {item?.isRecommended && <strong>Recommended</strong>}
                                                </td>
                                            </tr>
                                        })}
                                    </table>
                                </article>}

                                {/* Skin Protectant */}
                                {mapper(data, 'skinProtectant') && <article className={`min ${styles.tabley}`}>
                                    <table>
                                        <thead><th>Skin Protectant</th></thead>
                                        {mapper(data, 'skinProtectant').length > 0 && mapper(data, 'skinProtectant').map((item) => {
                                            return <tr>
                                                <td>{item?.name}</td>
                                                <td className={styles.treatment_field}>
                                                    <h5>
                                                        {item?.value?.productFamilyDesc}
                                                    </h5>
                                                    <h6 className={styles.treatment_sub}>
                                                        {item?.value?.materialDescription}
                                                    </h6>
                                                    {item?.isRecommended && <strong>Recommended</strong>}
                                                </td>
                                            </tr>
                                        })}
                                    </table>
                                </article>}


                                {/* Primary Dressing */}
                                {mapper(data, 'primaryDressing') && <article className={`min ${styles.tabley}`}>
                                    <table>
                                        <thead><th>Primary Dressing</th></thead>
                                        {mapper(data, 'primaryDressing').length > 0 && mapper(data, 'primaryDressing').map((item) => {
                                            // We are checking wheather the dressing frequency is there or it, if there is 
                                            // Dressing frequency, then we are specifically setting that 'Array index' as true.
                                            // We are using array because we have multiple interventions and single state for 
                                            // showing and hiding dressing frequency section
                                            if (item?.value?.dressingChangeFrequecy && item?.value?.dressingChangeFrequecy !== " ") {
                                                if (!primaryDressFreq[mIndex]) {
                                                    const temp = [...primaryDressFreq]
                                                    temp[mIndex] = true
                                                    setPrimaryDressFreq([...temp])
                                                }
                                            }
                                            if (item?.name) {
                                                return <tr>
                                                    <td>{item?.name}</td>
                                                    <td className={styles.treatment_field}>
                                                        <h5>
                                                            {item?.value?.productFamilyDesc}
                                                        </h5>
                                                        <h6 className={styles.treatment_sub}>
                                                            {item?.value?.materialDescription}
                                                        </h6>
                                                        {item?.isRecommended && <strong>Recommended</strong>}
                                                    </td>
                                                </tr>
                                            }
                                            else {
                                                return null
                                            }
                                        })}
                                    </table>
                                </article>}

                                {/* Secondary Dressing */}
                                {mapper(data, 'secondaryDressing') && <article className={`min ${styles.tabley}`}>
                                    <table>
                                        <thead><th>Secondary Dressing</th></thead>
                                        {mapper(data, 'secondaryDressing').length > 0 && mapper(data, 'secondaryDressing').map((item) => {
                                            if (item?.value?.dressingChangeFrequecy && item?.value?.dressingChangeFrequecy !== " ") {
                                                if (!secDressFreq[mIndex]) {
                                                    const temp = [...secDressFreq]
                                                    temp[mIndex] = true
                                                    setSecDressFreq([...temp])
                                                }
                                            }
                                            if (item?.name) {
                                                return <tr>
                                                    <td>{item?.name}</td>
                                                    <td className={styles.treatment_field}>
                                                        <h5>
                                                            {item?.value?.productFamilyDesc}
                                                        </h5>
                                                        <h6 className={styles.treatment_sub}>
                                                            {item?.value?.materialDescription}
                                                        </h6>
                                                        {item?.isRecommended && <strong>Recommended</strong>}
                                                    </td>
                                                </tr>
                                            }
                                            else {
                                                return null
                                            }
                                        })}
                                    </table>
                                </article>}

                                {/* Compression */}
                                {mapper(data, 'compression') && <article className={`min ${styles.tabley}`}>
                                    <table>
                                        <thead><th>Compression</th></thead>
                                        {mapper(data, 'compression').length > 0 && mapper(data, 'compression').map((item) => {
                                            return <tr>
                                                <td>{item?.name}</td>
                                                <td className={styles.treatment_field}>
                                                    <h5>
                                                        {item?.value?.productFamilyDesc}
                                                    </h5>
                                                    <h6 className={styles.treatment_sub}>
                                                        {item?.value?.materialDescription}
                                                    </h6>
                                                    {item?.isRecommended && <strong>Recommended</strong>}
                                                </td>
                                            </tr>
                                        })}
                                    </table>
                                </article>}

                                {/* Modalities */}
                                {mapper(data, 'modalities') && <article className={`min ${styles.tabley}`}>
                                    <table>
                                        <thead><th>Modalities</th></thead>
                                        {<tr>
                                            <td>Modalities</td>
                                            <td>{mapper(data, 'modalities').join(', ')}</td>
                                        </tr>}
                                    </table>
                                </article>}

                                {/* Additional care */}
                                {mapper(data, 'additionalCare') && <article className={`min ${styles.tabley}`}>
                                    <table>
                                        <thead><th>Additional Care</th></thead>
                                        {<tr>
                                            <td>Additional Care</td>
                                            <td>{mapper(data, 'additionalCare').join(', ')}</td>
                                        </tr>}
                                    </table>
                                </article>}

                                {/* Primary dressing change frequency */}
                                {primaryDressFreq[mIndex] && <>
                                    {mapper(data, 'primaryDressing') && <article className={`min ${styles.tabley}`}>
                                        <table>
                                            <thead><th>Primary Dressing Change Frequency</th></thead>
                                            {mapper(data, 'primaryDressing').length > 0 && mapper(data, 'primaryDressing').map((item) => {
                                                if (item?.value?.dressingChangeFrequecy && item?.value?.dressingChangeFrequecy !== ' ') {
                                                    return <tr>
                                                        <td>{item?.name}</td>
                                                        <td className={styles.treatment_field}>
                                                            <h5>
                                                                {item?.value?.materialDescription}
                                                            </h5>
                                                            <h6 className={styles.treatment_sub}>
                                                                {item?.value?.productFamilyDesc}
                                                            </h6>
                                                        </td>
                                                        <td>{item?.value?.dressingChangeFrequecy}</td>
                                                    </tr>
                                                }
                                                else {
                                                    return null
                                                }
                                            })}
                                        </table>
                                    </article>}
                                </>}

                                {/* Secondary dressing change frequency */}
                                {secDressFreq[mIndex] && <>
                                    {mapper(data, 'secondaryDressing') && <article className={`min ${styles.tabley}`}>
                                        <table>
                                            <thead><th>Secondary Dressing Change Frequency</th></thead>
                                            {mapper(data, 'secondaryDressing').length > 0 && mapper(data, 'secondaryDressing').map((item) => {
                                                if (item?.value?.dressingChangeFrequecy && item?.value?.dressingChangeFrequecy !== ' ') {
                                                    return <tr>
                                                        <td>{item?.name}</td>
                                                        <td className={styles.treatment_field}>
                                                            <h5>
                                                                {item?.value?.materialDescription}
                                                            </h5>
                                                            <h6 className={styles.treatment_sub}>
                                                                {item?.value?.productFamilyDesc}
                                                            </h6>
                                                        </td>
                                                        <td>{item?.value?.dressingChangeFrequecy}</td>
                                                    </tr>
                                                }
                                                else {
                                                    return null
                                                }
                                            })}
                                        </table>
                                    </article>}
                                </>}

                            </>
                        })

                        }

                        {/* {mainChecker(getNotes, 'notes') && <>
                        <article className="min pb-0">
                            <h2>Notes</h2>
                        </article>

                        {getNotes.length>0 && getNotes.map((data, index) => {
                            return <article className={styles.entered_notes} key={index}>
                                <div className={styles.post_details}>
                                    <span className={styles.creater_name}>{data.createdBy}</span> | <span>{fullMonthFormatDate(data.createdDate)}</span> | <span>{data.createdByRole}</span>
                                </div> 
                                <div className={styles.notes_text}>{data?.notes === '' ? 'Unknown' : data?.notes?.split('\n').map(str => <div>{str}</div>)}</div>                               
                                </article> 
                            })}

                        </>} */}   
                    </>
                    }

                {/* Notes Heading */}
                {notes && Array.isArray(notes) &&
                    <article className="min pb-0">
                        <h2>Notes</h2>
                    </article>
                }  
                <article className={`min ${styles.tabley}`}>
                {notes && Array.isArray(notes) && notes.map((data, index) => {
                    return <div className={styles.entered_notes} key={index}>
                        <div className={styles.post_details}>
                            <span className={styles.creater_name}>{data.createdBy}</span> | <span>{fullMonthFormatDate(data.createdDate)}</span> | <span>{data.createdByRole}</span>
                        </div> 
                        <div className={styles.notes_text}>{data?.notes === '' ? 'Unknown' : data?.notes?.split('\n').map(str => <div>{str}</div>)}</div>                               
                        </div> 
                    })}
                </article>
                
                {/* Signature line/ Data */}
                <article className='min'>
                    <div className='d-flex px-0 pt-2 pb-2 mt-5'>
                        <div className={`${styles.signatuehead} col-6 pr-5 pl-0`}>
                            <hr className={`${styles.signatureLine}`}/>
                            <div className={`${styles.signatureTitle}`}>
                                <p>Physician/Provider Signature</p>
                            </div>
                        </div>
                        <div className={`${styles.signatuehead} col-5 offset-1 pl-5 pr-0`}>
                            <hr className={`${styles.signatureLine}`}/>
                            <div className={`${styles.signatureTitle}`}>
                                <p>Date</p>
                            </div>
                        </div>
                    </div>
                </article>
                {/* Signature line/ Data */}  

                </div>
                
                }               
            </>}
        </>
    )
}

PdfDownload = connect()(withRouter(PdfDownload));