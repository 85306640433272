import React, { useRef, useEffect, useState } from 'react'
import {
    SAVE_BTN, SUBMIT_ID, CANCEL_BTN, CANCEL_ID,
    ROLE_LBL,
    ROLE_ID,
    ROLE_OPTIONS,
    CUSTOMER_NUMBER_ID,
} from '../../../constants'
import { Form, TextField, Button, SelectBox, Organizational, RadioButton } from '../../primary'
import { checkFirstName, checkLastName, checkEmail, phoneMasker, checkPrimaryGroup, checkDominaName, checkTenantID, checkDepartmentName, checkTitleName, getFacilityLabel, checkMaterialDivison, useDebounce,  customerNumberValid, getFacilityLabelUrl, checkTokenizedFacility, checkIncExcType, checkAssignToAllEmails } from '../../../utils'
import styles from './createEditSso.module.scss';
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import{ setResMsgState, getFaciltiestUserAsyn, setUserFacilities, getCustomerNumberSuggestions, setCustomerNumberSearch, setCustomerFacilities, getEmailSuggestions, setEmailSearch } from '../../../app/userSlice'// added Checkbox feature
import closeNormal from '../../../static/images/Close_Normal.svg'


export function CreateEditSso({ register, handleSubmit, control, handleCancel, onSubmit, errors,
  externalAdmin = false, isModal = false, setSelectedFacilities, selectAll = false, editState, role, setGetCheckStatus, setGetmulIncEmail, errorFacilities, showEmailError, setShowEmailError, makeVisble, setError, typeVis, setTypeVis, counterChange, setCounterChange}) {
  
      window.onload = function() { 
          load_test();                  
      }
     
  /*************************
   * Phone number field
   *************************/
  /* Get value from phone field using useREf */
  let phoneField = useRef();
  /* If phone number is typed call the above phoneHandle function */
  if (phoneField.current) {
      if (phoneField.current.value.length > 9) {
          phoneField.current.value = phoneMasker(phoneField.current.value)
      }
  }

  //get user role data from state
  const userRoleData = useSelector(state => state.user.userRole);
  // convert key value pair instead of name and label. 
  const convertedUserRoleData = (Object.keys(userRoleData).length !== 0) ? userRoleData.map(data => ({ key: data.name, value: data.label })) : '';;
  const userData = useSelector(state => state.user.detailSsoViewData)
  const facilitiesData = useSelector(state => state.user.userFacilities);

  /* based on role, display role option to edit privilege on admin */
  let externalAdminRoleData = [];
  let RolesDataOption = ROLE_OPTIONS;
  if (externalAdmin) {
      externalAdminRoleData = RolesDataOption?.filter((key) => key.key.startsWith("eXT"));
  }

  /* Fetching reaosn for visit data  */
  const dispatch = useDispatch()      
  
  useEffect(() => {
    dispatch(setCustomerFacilities({}));
  }, [dispatch])   


    /* facilities,primarygroup updation */
  const [loadState, setLoadState] = useState(true)
  const [toDo, setToDo] = useState(userData?.primaryGroup);
  const [todoList, setTodoList] = useState([]);   
  const [isValidSaleCust, setIsValidSaleCust] = useState(false);

  useEffect(()=>{
    setIsValidSaleCust(errorFacilities)
  },[errorFacilities])

   /* CustomerNumber search Suggestions  */   
  const [custNumInput, setCustNumInput] = useState("");
  const [isSelect, setIsSelect] = useState(false);
  const searchTerm = useDebounce(custNumInput, 500);
  const [errorMsg, setErrorMsg] = useState("");

  const customerNumberSearchDatas = useSelector(
      (state) => state.user.customerNumberSearch
    );

  const emailSearchData = useSelector((state) => state.user.emailSearch)

  const [errorMesg, setErrorMesg] = useState(false);
useEffect(()=>{   
 if(emailSearchData !== undefined || emailSearchData !== null){
    if(emailSearchData?.errorMessage === "Enter your email address in format: yourname@example.com"){
      setErrorMesg('Enter a valid domain name')
    }  
    else{
      setErrorMesg('')
    }      
  }       
},[emailSearchData])


  useEffect(() => {
      if (!isSelect && searchTerm && searchTerm.length >= 1) {
        dispatch(getCustomerNumberSuggestions(searchTerm));
      } else {
        dispatch(setCustomerNumberSearch({}));
      }
    }, [searchTerm]);    

    const load_test =() => {
      userData && todoList.push(
          {
              customerNumber : userData?.primaryGroup,           
              facilities : userData?.facilities?.map(list => list),
              customerId : userData?.ssoTenantMappingId
          }              
      )
      setLoadState(false)
    }      

  useEffect(()=>{
      userData && todoList.push(
          {
              customerNumber : userData?.primaryGroup,           
              facilities : userData?.facilities?.map(list => list),
              customerId : userData?.ssoTenantMappingId
          }              
      )
  },[editState])

  // Selected Facilities mapping
  const [onselectOrgz, setonselectOrg] = useState([]);
  const [checkOrgDrop, setCheckOrgDrop] = useState(false);
  const onSelectOrg = (selectedList) => {
      setonselectOrg([...selectedList])
      if (setSelectedFacilities) {
          let facilitykey = [];            
          selectedList && selectedList?.map((item) => { //NOSONAR
              let facility = item.key.split("|")[0].trim();
              facilitykey = [...facilitykey, facility];                
          })
          setToDo(facilitiesData?.data?.facilities?.find(f=>f?.accountGroup === "Z002")?.facilityNumber)
          const currentIndex = todoList && todoList?.findIndex(t => t?.customerNumber === toDo)
          if(currentIndex > -1) {
              todoList[currentIndex].facilities = facilitiesData?.data?.facilities?.filter((f)=> facilitykey.indexOf(f.facilityNumber) > -1)
              setIsValidSaleCust(false)
          }            
          else {   
              if(toDo !== "" && toDo !== null){                   
                  todoList && todoList.push({                
                      customerNumber : toDo,
                      customerId : facilitiesData?.data?.facilities?.find((f)=> f.facilityNumber === toDo)?.id,
                      facilities : facilitiesData?.data?.facilities?.filter((f)=> facilitykey.indexOf(f.facilityNumber) > -1)
                      
                  })
              }            
             
          }
          // if(facilitykey?.length === 0){
          //   setIsValidSaleCust(true)
          // }
          setSelectedFacilities(facilitykey)
      }
      
      setCheckOrgDrop(true)
  }
  

  let faciOptionData = [];
  const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
  soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
      const shipToFacilities = facilitiesData.data.facilities?.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
     if(shipToFacilities.length===0){
      faciOptionData.push({
          cat: getFacilityLabel(soldToFacility),
          key: getFacilityLabel(soldToFacility),
          facilityNumber: soldToFacility.facilityNumber,
          soldToFacility: 'soldToFacility'
      })
     }
      
      shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
          faciOptionData.push({
              cat: getFacilityLabel(soldToFacility),
              key: getFacilityLabel(shipToFacility),
              facilityNumber: shipToFacility.facilityNumber,
              shipToFacilities: 'shipToFacilities'
          })
      })
  })

  
  // By Default coming facilities mapping
  useEffect(() => {
      const res = todoList?.filter((item)=> item.customerNumber === userData?.primaryGroup)        
      const defaultFac = todoList?.find(f=>f.customerNumber===toDo)?.facilities?.filter(item=>item?.facilityNumber !== null)  
      let defaultFacArr = [];
      const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
      soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
          const shipToFacilities = facilitiesData.data.facilities?.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
          if(shipToFacilities.length===0){
              defaultFac && defaultFac.forEach((shipTofacilityNumber) => {
                  if (shipTofacilityNumber.facilityNumber === soldToFacility.facilityNumber) {
              defaultFacArr.push({
                  cat: getFacilityLabel(soldToFacility),
                  key: getFacilityLabel(soldToFacility),
                  facilityNumber: soldToFacility.facilityNumber,
                  soldToFacility: 'soldToFacility'
              })
               }
           })
             }
          shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
              defaultFac && defaultFac.forEach((shipTofacilityNumber) => {
                  if (shipTofacilityNumber.facilityNumber === shipToFacility.facilityNumber) {
                      defaultFacArr.push({
                          cat: getFacilityLabel(soldToFacility),
                          key: getFacilityLabel(shipToFacility),
                          facilityNumber: shipToFacility.facilityNumber,
                          shipToFacilities: 'shipToFacilities'
                      })
                  }
              })

          })
      })
      if(!makeTrue){
        setonselectOrg(defaultFacArr)
      }
      if(makeTrue){
        setonselectOrg([])
      }        
      if (setSelectedFacilities) {
          let facilitykey = [];
          defaultFacArr && defaultFacArr?.map((item) => { //NOSONAR
              let facility = item.key.split("|")[0].trim();
              facilitykey = [...facilitykey, facility];
          })            
          setSelectedFacilities(facilitykey)
      }
      setCheckOrgDrop(true)
  }, [userData, checkOrgDrop])     

  const getCustomerNumber = (val) => {         
      dispatch(setCustomerFacilities({}));
      dispatch(setUserFacilities([])) 
      setonselectOrg([]);  
      setCheckOrgDrop(true) 
      dispatch(setCustomerNumberSearch({}));
      if(val === ''){
          setErrorMsg("Enter primary group number")
          setIsValidSaleCust(false)
      }
      else{  
          setIsValidSaleCust(false)
          setErrorMsg('') 
          setonselectOrg([]);
          setToDo(val)   
          if(departmentMake&&orOptionState){
            setOrValidateTitle(true)
          } 
          if(titleMake&&orOptionState){
            setOrValidateDepart(true)
          }     
          const payload = { customer: val }
          dispatch(getFaciltiestUserAsyn(payload,setCheckOrgDrop,setErrorMsg));                  
      } 
      
  }

  const getCustomerNumberSearch = (event) => {  
      if(event === ''){
          setErrorMsg("Enter primary group number")
          setCustNumInput("")
          dispatch(setCustomerNumberSearch({}));
      }else {    
          setIsValidSaleCust(false)       
          setCustNumInput(event)
          setIsSelect(false);
          setErrorMsg('')                        
      }  
    };
  
  const ClearData = (value) => { 
      document.getElementById(CUSTOMER_NUMBER_ID).value = null ;  
      setCheckOrgDrop(false) 
      setToDo('')
      dispatch(setCustomerNumberSearch({}));
      dispatch(setUserFacilities([])) 
      dispatch(setCustomerFacilities({}));
      setonselectOrg([]); 
      setCustNumInput("");
      setErrorMsg("Enter primary group number")
      setIsValidSaleCust(false) 
      let customerNumberVal = toDo?.toUpperCase();
      const payload = { customer: customerNumberVal }
      dispatch(getFaciltiestUserAsyn(payload));       
  }


  const customerNumberSelect = (e, items) => {
      e.stopPropagation();       
      setIsSelect(true);
      dispatch(setCustomerFacilities({}));
      dispatch(setUserFacilities([])) 
      setonselectOrg([]);   
      dispatch(setCustomerNumberSearch({}));              
      if (items) {
        setErrorMsg('')          
        setCheckOrgDrop(true)
        setIsValidSaleCust(false)          
        const customerNumber = items;
        setCustNumInput(customerNumber);
        document.getElementById(CUSTOMER_NUMBER_ID).value = customerNumber;
        document.getElementById(CUSTOMER_NUMBER_ID).value = items; 
        setToDo(items)                    
        const payload = { customer: items }
        dispatch(getFaciltiestUserAsyn(payload, setCheckOrgDrop));                           
      }      
    }
 
  const resMsgState = useSelector(state => state.user.resMsgState);
  let errCk= editState?.finalErrorRes?JSON.parse(editState?.finalErrorRes):'';

useEffect(() => {
   if (!isSelect && searchTerm && searchTerm.length >= 1) {
     dispatch(getCustomerNumberSuggestions(searchTerm));
   } else {
     dispatch(setCustomerNumberSearch({}));
   }
 }, [searchTerm]);
 
 useEffect(()=>{
  if(makeVisble){
    setErrorMsg("Enter primary group number")
  }
 },[makeVisble])

// Making default Include/Exclude changing and updating
const [disState, setDisState] = useState(false)
const [showEmailCheckbox, setShowEmailCheckbox] = useState(true)
const [makeDisable, setMakeDisable] = useState(false)
const [errorTenant, setErrorTenant] = useState("")

const handleTenant = (value) =>{
if(value === ""){
  setErrorTenant("Enter teanant Id")
}
if(value !== ""){
  setErrorTenant("")
}

}

const [makeTrue, setMakeTrue] = useState(false)
const [remainVis, setRemainVis] = useState(false)
const [radioNone, setRadioNone] = useState(false)

const getTokenState = (value) => {
setShowEmailCheckbox(true)
document.getElementById("tableTBD0").checked= false
document.getElementById("tableTBD1").checked= false
document.getElementById(CUSTOMER_NUMBER_ID).value = null ;
document.getElementById("tenant").value = null ; 
setMakeDisable(true) 
setSearchKeyWord([]) 
dispatch(setCustomerFacilities({}));
dispatch(setCustomerNumberSearch({}));
dispatch(setUserFacilities([])) 
setonselectOrg([]); 
setRadioNone(true)  
setCheckOrgDrop(false)   
setCustNumInput("");
setToDo('') 

setMakeTitleAlone(false)
  document.getElementById("department").value= null
  document.getElementById("title").value= null  
  setTenantMake(false)
  setDepartmentMake(false)
  setTitleMake(false)
  setAndOptionState(false)  
  setGetAndOpt(false)
  setOrValidateDepart(false)  
  setOrValidateTitle(false)
  setCounterChange(0)

if(value==='Yes'){
  setTypeVis(true)
  setRemainVis(false)  
  setOrOptionState(false)
  if(showEmailCheckbox){
    document.getElementById("assigntoallemails0").checked= false
    document.getElementById("assigntoallemails1").checked= false
  }    
  setMakeIncExcError(false)    
}

if(value==='No'){  
  setTypeVis(false)
  setRemainVis(false) 
  setOrOptionState(true)  
}
}


const getState=(value) =>{
setDisState(true) 
setSearchKeyWord([]) 
setShowEmailError(false)
setRadioNone(false)
if(value==='Include'){
  setShowEmailCheckbox(true)
  document.getElementById(CUSTOMER_NUMBER_ID).value = null ;     
  document.getElementById("tenant").value = null ; 
  setCheckOrgDrop(false) 
  setCustNumInput("");
  setToDo('')
  dispatch(setCustomerFacilities({}));
  dispatch(setCustomerNumberSearch({}));
  dispatch(setUserFacilities([])) 
  setonselectOrg([]);
  setMakeTrue(true)
  setRemainVis(true)
  setMakeDisable(true)

  setMakeTitleAlone(false)
  document.getElementById("department").value= null
  document.getElementById("title").value= null
  document.getElementById("yes_no1").checked= false
  document.getElementById("yes_no2").checked= true
  setTenantMake(false)
  setDepartmentMake(false)
  setTitleMake(false)
  setAndOptionState(false)
  setOrOptionState(true)
  setGetAndOpt(false)
  setOrValidateDepart(false)
  setOrValidateTitle(false)
  setCounterChange(0)
}
if(value==='Exclude'){
  setShowEmailCheckbox(false)
  setMakeDisable(false)
  document.getElementById(CUSTOMER_NUMBER_ID).value = null ;
  document.getElementById("tenant").value = null ;        
  dispatch(setCustomerFacilities({}));
  dispatch(setCustomerNumberSearch({}));
  dispatch(setUserFacilities([])) 
  setonselectOrg([]);
  setCheckOrgDrop(false) 
  setCustNumInput("");
  setToDo('')
  setMakeTrue(true)
  setRemainVis(true)

  setMakeTitleAlone(false)
  document.getElementById("department").value= null
  document.getElementById("title").value= null
  document.getElementById("yes_no1").checked= false
  document.getElementById("yes_no2").checked= true
  setTenantMake(false)
  setDepartmentMake(false)
  setTitleMake(false)
  setAndOptionState(false)
  setOrOptionState(true)
  setGetAndOpt(false)
  setOrValidateDepart(false)
  setOrValidateTitle(false)
  setCounterChange(0)
}
if(value === "Yes"){
  setMakeDisable(true)
  setMakeIncExcError(false) 
  // setSearchKeyWord([])  
}
if(value === "No"){
  setMakeDisable(false) 
  setMakeIncExcError(true) 
  // setSearchKeyWord([])
}
}

useEffect(()=>{
if(showEmailCheckbox && document.getElementById("assigntoallemails1")?.checked===true){
  setMakeDisable(false)
  setMakeIncExcError(true)
}  
},[showEmailCheckbox])

useEffect(()=>{
if(userData?.isTokenizedFacility === 1){
  setTypeVis(true)
  setCustNumInput("");
  setToDo('')
  dispatch(getFaciltiestUserAsyn({}));
  dispatch(setCustomerFacilities({}));
  dispatch(setCustomerNumberSearch({}));
  dispatch(setUserFacilities([])) 
  setonselectOrg([]);
  document.getElementById("assigntoallemails0").checked=false
  document.getElementById("assigntoallemails1").checked=false
}
if(userData?.isTokenizedFacility === 0){
  setTypeVis(false)
  setRemainVis(true)
  // setToDo("")
}
if(userData?.assignToAllEmails === 1){
  setMakeDisable(true)
}
if(userData?.assignToAllEmails === 0){
  setMakeDisable(false)
}
},[userData])

const [checkStatus, setCheckStatus] = useState(0)
const handleClickStatus = () => {
if(checkStatus===0){
  setCheckStatus(1)
}
if(checkStatus===1){
  setCheckStatus(0)
}
}

useEffect(()=>{
  setGetCheckStatus(checkStatus)
},[checkStatus])


// By default set Include/Exclude using assign to all email response Yes/No
const [makeIncExcError, setMakeIncExcError] = useState(false)

useEffect(()=>{    
  if(userData?.isSSOEnable === 1){
      setCheckStatus(1)
  }
  if(userData?.isSSOEnable === 0){
      setCheckStatus(0)
  }
  if(userData?.assignToAllEmails === 1 || userData?.assignToAllEmails === 0){
      setDisState(true)
  }
  if(userData?.assignToAllEmails===0){
    if(Array.isArray(userData?.assignToSpecificEmail)&&userData?.assignToSpecificEmail?.length >= 1){
      setShowEmailCheckbox(true)
    }  
    else{
      setShowEmailCheckbox(false)
    }  
  }
//   if(userData?.assignToAllEmails === 0 && userData?.excludeToSpecificEmail !== null){
//     setShowEmailCheckbox(false)
// }
  if(userData?.assignToAllEmails === 0 && userData?.assignToSpecificEmail !== null && userData?.excludeToSpecificEmail === null){
      setMakeIncExcError(true)
  }
},[userData])



/* Email search Suggestions  */   
const [emailInput, setEmailInput] = useState("");
const [isEmailSelect, setIsEmailSelect] = useState(false);
const searchEmailTerm = useDebounce(emailInput, 500);
const [isValidEmail, setIsValidEmail] = useState(false);
const [errorEmail, setErrorEmail] = useState(false)

const getEmailSearch = (event) => {  
if(event === ''){
    setErrorEmail("Enter domain name")
    setEmailInput("")
}else {    
    setIsValidEmail(false)       
    setEmailInput(event)
    setIsEmailSelect(false);
    setErrorEmail("")                       
}  
};

const getEmail = (val) => {         
// dispatch(setUserFacilities([]))  
setCheckOrgDrop(true) 

if(val === ''){
  setErrorEmail("Enter domain name")
}
else{  
    setIsValidEmail(false)
    setErrorEmail("")                    
} 

}

const emailSelect = (e, items) => {
e.stopPropagation();       
setIsEmailSelect(true);
dispatch(setEmailSearch({}));
setCheckOrgDrop(true)                 
if (items) {
  setErrorEmail("")
  setIsValidEmail(false)          
  const customerNumber = items;
  setEmailInput(customerNumber);
  document.getElementById("ssodomainname").value = customerNumber;
  document.getElementById("ssodomainname").value = items;                   
}      
}

useEffect(() => {
if (!isEmailSelect && searchEmailTerm && searchEmailTerm.length >= 1) {
  dispatch(getEmailSuggestions(searchEmailTerm));
} else {
  dispatch(setEmailSearch({}));
}
}, [searchEmailTerm]);


//Multiple Email 
const [searchKeyWord, setSearchKeyWord] = useState([]);
const [newKeySearchKey, setNewSearchKey] = useState(true);

const addNewSearchKeyWord = () => {
  setNewSearchKey(true)
  document.getElementById("keyword")?.focus();
};

useEffect(() => {

  if(userData?.assignToAllEmails===0){
    if(Array.isArray(userData?.assignToSpecificEmail)&&userData?.assignToSpecificEmail?.length >= 1){
      setSearchKeyWord(userData?.assignToSpecificEmail);
    }  
    else{        
      setSearchKeyWord(userData?.excludeToSpecificEmail);
    }
  }
  // if(userData?.assignToAllEmails===0 && userData?.excludeToSpecificEmail !== null){
  //   setSearchKeyWord(userData?.excludeToSpecificEmail);
  // }
  // if((userData?.assignToAllEmails === 1) || (userData?.assignToAllEmails === 0 && userData?.assignToSpecificEmail !== null)){
  //   setSearchKeyWord(userData?.assignToSpecificEmail);
  // }    
}, [userData, dispatch]);

const removeKeyword = (index) => {
      const deleteData = [...searchKeyWord]
      deleteData.splice(index, 1);
      setSearchKeyWord(deleteData);
}

const onBlurChangeSearchKeyWord=(value)=>{
    const convertSearchValue= value.split(',');
    let splitedNewArray =[]
    if(convertSearchValue){
      Array.isArray(convertSearchValue)&&convertSearchValue?.map(arrayValue=>{//NOSONAR
        splitedNewArray.push(arrayValue)
    })//NOSONAR
    let newSearch=[...searchKeyWord,...splitedNewArray]
    //duplicate remove
    const duplicateRemovedData=newSearch?.filter((item,index)=>newSearch.indexOf(item)===index)
    setSearchKeyWord(duplicateRemovedData);
    // setNewSearchKey(false);
    document.getElementById("keyword").value = ""
  }    
}


const onKeyChangeSearchKeyWord=(value)=>{
  const convertSearchValue= value.split(',');
  let splitedNewArray =[]
  if(convertSearchValue){
    Array.isArray(convertSearchValue)&&convertSearchValue?.map(arrayValue=>{//NOSONAR
      splitedNewArray.push(arrayValue)
  })//NOSONAR
  let newSearch=[...searchKeyWord,...splitedNewArray]
  //duplicate remove
  const duplicateRemovedData=newSearch?.filter((item,index)=>newSearch.indexOf(item)===index)
  setSearchKeyWord(duplicateRemovedData);
  // setNewSearchKey(false);
  document.getElementById("keyword").value = ""
}    
}

const contentChange =(e, index)=>{
    let tmpArray=[...searchKeyWord];
    tmpArray[index]=e.target.textContent;
    setSearchKeyWord(tmpArray)
}

const removeTextBox = () =>{
  setNewSearchKey(true)
}

// sending email to payload
useEffect(()=>{    
  setGetmulIncEmail(searchKeyWord?.filter(item=>item !== ""))
},[searchKeyWord])

useEffect(()=>{
  let assigntospecificemailValue = searchKeyWord?.filter(item=>item !== "")
  if(assigntospecificemailValue?.length > 0){    
    setShowEmailError(false)
  }
},[searchKeyWord])  

const falseErrorChange = (val) => {
  if(val !== ""){
    setShowEmailError(false)
  }
  else{
    if(searchKeyWord.length > 0){
      setShowEmailError(false)
    }
    else {
      setShowEmailError(true)
    }
  }
}


// -----Department and Title Fields------
const [tenantMake, setTenantMake] = useState(false)
const [departmentMake, setDepartmentMake] = useState(false)
const [titleMake, setTitleMake] = useState(false)
const [andOptionState, setAndOptionState] = useState(false)
const [orOptionState, setOrOptionState] = useState(false)
const [makeTitleAlone, setMakeTitleAlone] = useState(false)
const [getAndOpt, setGetAndOpt] = useState(false)
const [orValidateDepart, setOrValidateDepart] = useState(false)
const [orValidateTitle, setOrValidateTitle] = useState(false)

useEffect(()=>{
  if(userData?.tenant !== ""){
    setTenantMake(true)
  }
  if(userData?.tenant === ""){
    setTenantMake(false)
  }
},[userData])

useEffect(()=>{
  // if(userData?.department !== null){
  //   setDepartmentMake(true)
  // }
  // if(userData?.department === null){
  //   setDepartmentMake(false)
  // }
  if((userData?.department !== null && userData?.title === null)){
    setDepartmentMake(true)
  }
  else{
    setDepartmentMake(false)
  }
},[userData])

useEffect(()=>{
  if(userData?.department === null && userData?.title !== null){
    setTitleMake(true)
  }
  else{
    setTitleMake(false)
  }
  // if(userData?.title !== null){
  //   setTitleMake(true)
  // }
  // if(userData?.title === null){
  //   setTitleMake(false)
  // }
},[userData])

useEffect(()=>{
  if(userData?.title !== null && userData?.department !== null){
    setAndOptionState(true)
  }
  if((userData?.department !== null && userData?.title === null)||(userData?.department === null && userData?.title !== null)){
    setOrOptionState(true)
  }
  // if(userData?.title === null && userData?.department !== null){
  //   setOrOptionState(true)
  // }
  // if(userData?.title === null && userData?.department === null){
  //   setAndOptionState(false)
  // }
  // if(userData?.title === null || userData?.department === null){
  //       setOrOptionState(false)
  //     }
},[userData])

useEffect(()=>{
  if(userData?.title !== null && userData?.department !== null){
    setGetAndOpt(true)
  }
},[userData])


const onChangeMethodTenant = (value) =>{
  setRadioNone(true)
  if(value !== ""){
    setTenantMake(true)
    setMakeTitleAlone(true)
    // setRadioYesNo(true)
    document.getElementById("yes_no1").checked= false
    document.getElementById("yes_no2").checked= false
  }
  else{
    setTenantMake(false)
    // setRadioYesNo(false)
    setMakeTitleAlone(false)
    setOrOptionState(true)
    document.getElementById("yes_no2").checked= true
    
    // if(!andOptionState&&!orOptionState){
    //   setMakeTitleAlone(true)
    // }
    // else{
    //   setMakeTitleAlone(false)
    // }    
  }
}

const onChangeMethodDepartment = (value) => {
  setRadioNone(true)
  if(andOptionState){
    if(value !== "" || document.getElementById('title')?.value !==""){
      setGetAndOpt(true)
    }
    else{
      setGetAndOpt(false)
    }
  }
  else if(orOptionState){
    if(counterChange===0){
      if(value !== ""|| document.getElementById('department')?.value !==""){      
        setDepartmentMake(true)
        setOrValidateTitle(true)
      }
      else{
        setDepartmentMake(false) 
        setOrValidateTitle(false)   
        setGetAndOpt(false)
      }
    }
    if(counterChange >= 1){
      if(value !== ""|| document.getElementById('department')?.value !==""){   
        if(document.getElementById('title')?.value !=="") {
          setDepartmentMake(false)
          setOrValidateTitle(false)
        } 
        else {
          setDepartmentMake(true)
          setOrValidateTitle(true)
        }         
      }
      else{
        if(document.getElementById('title')?.value !=="") {
          setTitleMake(true)
          // setDepartmentMake(false) 
          // setOrValidateTitle(false)   
          // setGetAndOpt(false)
        }
        else{
          // setTitleMake(false)
          setDepartmentMake(false) 
          setOrValidateTitle(false)   
          setGetAndOpt(false)
        }        
      }
    }   
  }  
  else{
    if(value !== ""){
      setDepartmentMake(true)
    }
    else{
      setDepartmentMake(false)
    }
  }
}

const onChangeMethodTitle = (value) => {
  setRadioNone(true)
  if(andOptionState){
    if(value !== "" || document.getElementById('department')?.value !==""){
      setGetAndOpt(true)
    }
    else{
      setGetAndOpt(false)
    }
  }
  else if(orOptionState){
    if(counterChange === 0){
      if(value !== ""|| document.getElementById('title')?.value !==""){
        setTitleMake(true)
        setOrValidateDepart(true)
      }
      else{
        setTitleMake(false)
        setOrValidateDepart(false)
        setGetAndOpt(false)
      }
    }
   if(counterChange >= 1){
    if(value !== ""|| document.getElementById('title')?.value !==""){
      if(document.getElementById('department')?.value !==""){
        setTitleMake(false)
        setOrValidateDepart(false)
      }
      else{
        setTitleMake(true)
        setOrValidateDepart(true)
      }      
    }
    else{
      if(document.getElementById('department')?.value !==""){
        setDepartmentMake(true)
      }
      else{
        setTitleMake(false)
        setOrValidateDepart(false)
        setGetAndOpt(false)
      }      
    }
   }
  }
  else{
    if(value !== ""){
      setTitleMake(true)
    }
    else{
      setTitleMake(false)
    }
  }
}

const makeAndOption = () => {
  setAndOptionState(true)
  setOrOptionState(false)
  setMakeTitleAlone(false)
  setCounterChange(counterChange + 1)
  // document.getElementById("department").value = null ;
  // document.getElementById("title").value = null ;
}

useEffect(()=>{
  if(andOptionState&&departmentMake){
    setDepartmentMake(false)
  }
  if(andOptionState&&titleMake){
    setTitleMake(false)
    setOrValidateDepart(false)
  }
},[andOptionState])

const makeOROption = () =>{
  setOrOptionState(true)
  setAndOptionState(false)
  setMakeTitleAlone(false)
  setCounterChange(counterChange + 1)
    // setGetAndOpt(false)
  // document.getElementById("department").value = null ;
  // document.getElementById("title").value = null ;
}

useEffect(()=>{
if(document.getElementById('department')?.value !== "" && document.getElementById('title')?.value === "" && orOptionState){
  setDepartmentMake(true)
}
if(document.getElementById('department')?.value === "" && document.getElementById('title')?.value !== "" && orOptionState){
  setTitleMake(true)
}
},[orOptionState])

  
  return (
      <>


<Form formChanges={true} className={`pl-2 ${styles.patient_content}`} onSubmit={handleSubmit(onSubmit)}>
        
        {role !== 'ExternalAdminstrator' && 
          <>

          <div className={`${styles.heading_Domain} mr-3 pt-4 pb-4`}>
            <div className={`col-12 px-0 pb-2 pt-1 d-flex align-items-start justify-content-start`}>
              <div className={`col-4 px-0`}>                  
                <TextField
                    name={'ssodomainname'}
                    id={'ssodomainname'}
                    data-testid={"ssodomainname"}
                    label={`Domain Name: `}
                    classes={{
                      column: `col-12 ${styles.col_spec} pr-0`,
                      labelClass: `col-5 col-form-label ${styles.label_spec}`,
                      inputColumn: 'col-7 py-0 pr-0',
                      inputClassName: `${styles.basic_input_domain} form-control`
                  }}
                    // optionValue={convertedUserRoleData}
                    defaultValue={userData && userData?.domainName}
                    validator={register(checkDominaName)}
                    required={true}
                    errors={errors}
                    customError={errorMesg}
                    onKeyPress={()=>{
                      setErrorMsg('')
                    }}
                    onChange={(e)=>getEmailSearch(e.target.value)}      
                    onKeyUp={(e) => getEmail(e?.target?.value)} 
                />
                {emailSearchData &&
                  Array.isArray(emailSearchData?.emails) &&
                  emailSearchData?.emails?.length > 0 && (
                      <div className="col-12 col-spec row">
                      <div className={`col_spec mr-auto ${styles.search_drop_domain}`}>
                          <div className={`col-spec ${styles.search_close_box}`}>
                          {emailSearchData?.emails?.map(
                              (items, index) =>
                              items && (
                                  <p
                                  className={`dropdown-item`}
                                  title={items.value}
                                  onClick={(e) => emailSelect(e, items)}
                                  >
                                  {items}
                                  </p>
                              )
                          )}
                          </div>
                      </div>
                      </div>
                  )} 
              </div>

              {/* <div className={`verticalCode  ${styles.vertical_Code}`}></div> */}

              <div className={`col-4 px-0`}>
                <SelectBox
                    id={ROLE_ID}
                    name={ROLE_ID}
                    label={`${ROLE_LBL}:`}
                  classes={{
                      column: `col-12 d-flex ${styles.col_spec} pr-0`,
                      inputClassName: styles.basic_select,
                      labelClassName: `col-3 pr-0 col-form-label ${styles.label_spec}`,
                      inputColumn: `col-7 pl-0 p-0 ${styles.select_wrapper_role}`
                  }}
                    optionValue={convertedUserRoleData}
                    defaultValue={userData && userData?.role}
                    validator={register}
                    required={true}
                />
              </div>

              {/* <div className={`verticalCode  ${styles.vertical_Code_role}`}></div> */}

              <div className={`col-3 px-0 pt-2 ${styles.toggle_switch_place}`}>
                {/* Toggle switch */}
                <div className={`col-9 custom-control custom-switch pl-2 d-flex`}>                      
                    <b>{` Enable SSO:  `}</b>{<span className={`${styles.astrick} ${styles.label_spec_Sso} pl-1 pr-2`}>&#42;</span>}
                    <div className={`${styles.toggle_shells}`}>                                    
                    {/* <div className={`${styles.toggle_button_status}`}>{toggle ? "Verified" : "Not verified"}</div>  */}                                    
                    <input 
                        type="checkbox"
                        name="isStatus" 
                        className="custom-control-input"
                        id={"isStatus"}
                        data-testid={"toggleSwitch"}
                        defaultValue = {checkStatus}
                        checked = {checkStatus === 1}                                                     
                        onChange={handleClickStatus}                          
                    />
                    <label className={`custom-control-label ${styles.toggle_switch}`} for="isStatus"></label> 
                    </div>
                </div>              
              </div>
            </div>
            
            <div className={`col-sm-12 px-0 pb-1`}>
              <label className={`col-6 col-form-label mr-0 pt-1 mt-2 ${styles.label_spec_Sso}`}>Assign facility to each user using the tenant parameter in the token: {<span className={styles.astrick}>&#42;</span>}</label>
              <RadioButton
                  id={`tokenized_facility`}
                  name={`tokenized_facility`}
                  classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                      inputColumn: "custom-control custom-radio custom-control-inline"
                  }}
                  validator={register(checkTokenizedFacility)}
                  radioOption={['Yes', 'No']}
                  required={true}
                  defaultValue={
                    userData?.isTokenizedFacility===1
                      ? "Yes"
                      : "No"
                  }                 
                  onChange={(e) => getTokenState(e.target.value)}
                  onClick={(e) => getTokenState(e.target.value)}
                  lableSso="sso_token_facility"
              />
            </div>

          </div>

          {typeVis === false && <div className={`col-sm-12 px-0 pt-5 pb-4`}>
            <label className={`col-3 col-form-label mr-1 ${styles.label_spec_Sso}`}>Type: {<span className={styles.astrick}>&#42;</span>}</label>
            <RadioButton
                id={`tableTBD`}
                name={`tableTBD`}
                classes={{
                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                    inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                    inputColumn: "custom-control custom-radio custom-control-inline"
                }}
                errors={errors}
                validator={register(checkIncExcType)}
                radioOption={['Include', 'Exclude']}
                required={true}
                defaultValue={
                  radioNone === false ? (userData?.assignToAllEmails===0 && userData?.excludeToSpecificEmail !== null)
                    ? "Exclude"
                    : "Include"   
                  : null           
                }                 
                onChange={(e) => getState(e.target.value)}
                onClick={(e) => getState(e.target.value)}
                disabled={typeVis}
                lableSso="sso"
            />
          </div>} 
          {typeVis === true && <div className={`col-sm-12 px-0 pt-5 pb-4`}>
          <label className={`col-3 col-form-label mr-1 ${styles.label_spec_Sso}`}>Type: {<span className={styles.astrick}>&#42;</span>}</label>
          <RadioButton
              id={`tableTBD`}
              name={`tableTBD`}
              classes={{
                  labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                  inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                  inputColumn: "custom-control custom-radio custom-control-inline"
              }}
              errors={errors}
              // validator={register(checkIncExcType)}
              radioOption={['Include', 'Exclude']}
              required={true}
              // defaultValue={
              //   typeVis === false ? (userData?.assignToAllEmails===0 && userData?.excludeToSpecificEmail !== null)
              //     ? "Exclude"
              //     : "Include"    
              //   : null             
              // }                 
              onChange={(e) => getState(e.target.value)}
              onClick={(e) => getState(e.target.value)}
              disabled={typeVis}
              lableSso="sso"
          />
        </div>}
        

        {typeVis === false && <div className={`col-sm-12 px-0`}>
          {(!departmentMake && !getAndOpt && !titleMake) && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec} mb-2`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(departmentMake&&styles.disabled_Column)||(titleMake&&styles.disabled_Column)||(getAndOpt&&styles.disabled_Column)}`
              }}
              label={`Tenant:`}
              type="text"
              name={'tenant'}
              id={'tenant'}
              validator={register(!typeVis && remainVis && checkTenantID)}
              errors={errors}
              // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.tenant : null}
              // disabled={!disState}
              disabled={!remainVis ? true : departmentMake ? true : titleMake ? true : getAndOpt ? true : false}
              onChange={(e)=>{onChangeMethodTenant(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTenant(e.target.value)}}
          />}
          {(!departmentMake && !getAndOpt && titleMake) && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec} mb-2`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(departmentMake&&styles.disabled_Column)||(titleMake&&styles.disabled_Column)||(getAndOpt&&styles.disabled_Column)}`
              }}
              label={`Tenant:`}
              type="text"
              name={'tenant'}
              id={'tenant'}
              validator={register}
              // errors={errors}
              // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.tenant : null}
              // disabled={!disState}
              disabled={!remainVis ? true : departmentMake ? true : titleMake ? true : getAndOpt ? true : false}
              onChange={(e)=>{onChangeMethodTenant(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTenant(e.target.value)}}
          />}
         {(!departmentMake && getAndOpt) && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec} mb-2`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(departmentMake&&styles.disabled_Column)||(titleMake&&styles.disabled_Column)||(getAndOpt&&styles.disabled_Column)}`
              }}
              label={`Tenant:`}
              type="text"
              name={'tenant'}
              id={'tenant'}
              validator={register}
              // errors={errors}
              // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.tenant : null}
              // disabled={!disState}
              disabled={!remainVis ? true : departmentMake ? true : titleMake ? true : getAndOpt ? true : false}
              onChange={(e)=>{onChangeMethodTenant(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTenant(e.target.value)}}
          />}
          {departmentMake && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec} mb-2`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(departmentMake&&styles.disabled_Column)||(titleMake&&styles.disabled_Column)||(getAndOpt&&styles.disabled_Column)}`
              }}
              label={`Tenant:`}
              type="text"
              name={'tenant'}
              id={'tenant'}
              validator={register}
              // errors={errors}
                 // customError={errorTenant}
              required={true}
              // defaultValue={radioNone === false && userData ? userData?.tenant : null}
                  // disabled={!remainVis}
              disabled={!remainVis ? true : departmentMake ? true : titleMake ? true : getAndOpt ? true : false}
              onChange={(e)=>{onChangeMethodTenant(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTenant(e.target.value)}}
          />}
        </div>}       

        {typeVis === true && <div className={`col-sm-12 px-0`}>
          <TextField
              classes={{
                  column: `col-7 ${styles.col_spec} mb-2`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${!remainVis&&styles.disabled_Column}`
              }}
              label={`Tenant:`}
              type="text"
              name={'tenant'}
              id={'tenant'}
              // validator={register(typeVis === false && checkTenantID)}
              errors={errors}
              // customError={errorTenant}
              required={true}
              // defaultValue={userData && userData?.tenant}
              // disabled={!disState}
              disabled={!remainVis}
              onChange={(e)=>{onChangeMethodTenant(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTenant(e.target.value)}}
          />
        </div>}   


        {/* Changes here from      */}

        <div className={`col-sm-12 px-0 pb-2 d-flex align-items-center`}>
          <label className={`col-2 text-left col-form-label pl-5 ${styles.label_spec_Sso}`} style={{fontSize:'20px',fontWeight:'bold'}}> OR </label>
          <label className={`col-6 col-form-label pl-0 mr-0 ${styles.label_spec_Sso}`}> </label>
        </div>

        {typeVis === false && <div className={`col-sm-12 px-0`}>
          {(!tenantMake && !orValidateDepart&&!titleMake) && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec} mb-2`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(titleMake&&styles.disabled_Column)}`
              }}
              label={`Department:`}
              type="text"
              name={'department'}
              id={'department'}
              validator={register(!typeVis && remainVis && checkDepartmentName)}
              errors={errors}
              // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.department : null}
              // disabled={!disState}
              disabled={!remainVis ? true : tenantMake ? true : titleMake ? true : false}
              onChange={(e)=>{onChangeMethodDepartment(e.target.value)}}
              onBlur={(e)=>{onChangeMethodDepartment(e.target.value)}}
            />}
            {(!tenantMake && !orValidateDepart&&titleMake) && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec} mb-2`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(titleMake&&styles.disabled_Column)}`
              }}
              label={`Department:`}
              type="text"
              name={'department'}
              id={'department'}
              validator={register}
              // errors={errors}
              // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.department : null}
              // disabled={!disState}
              disabled={!remainVis ? true : tenantMake ? true : titleMake ? true : false}
              onChange={(e)=>{onChangeMethodDepartment(e.target.value)}}
              onBlur={(e)=>{onChangeMethodDepartment(e.target.value)}}
            />}
            {(!tenantMake && orValidateDepart) && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec} mb-2`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(titleMake&&styles.disabled_Column)}`
              }}
              label={`Department:`}
              type="text"
              name={'department'}
              id={'department'}
              validator={register}
              // errors={errors}
              // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.department : null}
              // disabled={!disState}
              disabled={!remainVis ? true : tenantMake ? true : titleMake ? true : false}
              onChange={(e)=>{onChangeMethodDepartment(e.target.value)}}
              onBlur={(e)=>{onChangeMethodDepartment(e.target.value)}}
            />}           
            {tenantMake && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec} mb-2`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(titleMake&&styles.disabled_Column)}`
              }}
              label={`Department:`}
              type="text"
              name={'department'}
              id={'department'}
              validator={register}
              // errors={errors}
                  // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.department : null}
                  // disabled={!disState}
              disabled={!remainVis ? true : tenantMake ? true : titleMake ? true : false}
              onChange={(e)=>{onChangeMethodDepartment(e.target.value)}}
              onBlur={(e)=>{onChangeMethodDepartment(e.target.value)}}
            />}
          </div>
        }  

        {typeVis === true && <div className={`col-sm-12 px-0`}>
          <TextField
            classes={{
                column: `col-7 ${styles.col_spec} mb-2`,
                labelClass: `col-5 col-form-label ${styles.label_spec}`,
                inputColumn: 'col-7 py-0 pl-3 pr-4',
                inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(titleMake&&styles.disabled_Column)}`
            }}
            label={`Department:`}
            type="text"
            name={'department'}
            id={'department'}
            validator={register}
            // errors={errors}
                // customError={errorTenant}
            required={true}
            defaultValue={radioNone === false && userData ? userData?.department : null}
                // disabled={!disState}
            disabled={!remainVis}
            onChange={(e)=>{onChangeMethodDepartment(e.target.value)}}
            onBlur={(e)=>{onChangeMethodDepartment(e.target.value)}}
          />
        </div>
        }

        {/* AND / OR label tokenzState-No */}
          {
            <div className={`${styles.and_or_block} d-flex pr-0 py-3 pl-4 align-items-center`}>
              {/* ---------AND--------- */}
              <label for="html" className={`${styles.and_or_buttons}`}>AND</label>
              <input 
                type="radio" 
                className={`${styles.and_or_radio}`} 
                id="yes_no1"
                name="yes_no" 
                disabled={!remainVis ? true : tenantMake ? true : false}
                checked={andOptionState}
                onClick={makeAndOption}
              ></input>
              {/* ---------OR--------- */}
              <label for="html" className={`${styles.and_or_buttons}`}>OR</label>
              <input 
                type="radio" 
                className={`${styles.and_or_radio}`} 
                id="yes_no2"
                name="yes_no"
                disabled={!remainVis ? true : tenantMake ? true : false}
                checked={orOptionState}
                onClick={makeOROption}
                ></input>
            </div>
          }     

        {typeVis === false && <div className={`col-sm-12 px-0 pb-2`}>
          {(!tenantMake && !orValidateTitle && !departmentMake) && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec}`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(makeTitleAlone&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(departmentMake&&styles.disabled_Column)}`
              }}
              label={`Title:`}
              type="text"
              name={'title'}
              id={'title'}
              validator={register(!typeVis && remainVis && !makeTitleAlone && checkTitleName)}
              errors={!makeTitleAlone && errors}
              // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.title : null}
              // disabled={!disState}
              disabled={!remainVis ? true : makeTitleAlone ? true : tenantMake ? true : departmentMake ? true : false}
              onChange={(e)=>{onChangeMethodTitle(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTitle(e.target.value)}}
            />}
            {(!tenantMake && !orValidateTitle && departmentMake) && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec}`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(makeTitleAlone&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(departmentMake&&styles.disabled_Column)}`
              }}
              label={`Title:`}
              type="text"
              name={'title'}
              id={'title'}
              validator={register}
              // errors={!makeTitleAlone && errors}
              // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.title : null}
              // disabled={!disState}
              disabled={!remainVis ? true : makeTitleAlone ? true : tenantMake ? true : departmentMake ? true : false}
              onChange={(e)=>{onChangeMethodTitle(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTitle(e.target.value)}}
            />}
           {(!tenantMake && orValidateTitle) && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec}`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(makeTitleAlone&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(departmentMake&&styles.disabled_Column)}`
              }}
              label={`Title:`}
              type="text"
              name={'title'}
              id={'title'}
              validator={register}
              // errors={!makeTitleAlone && errors}
              // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.title : null}
              // disabled={!disState}
              disabled={!remainVis ? true : makeTitleAlone ? true : tenantMake ? true : departmentMake ? true : false}
              onChange={(e)=>{onChangeMethodTitle(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTitle(e.target.value)}}
            />}
            {tenantMake && <TextField
              classes={{
                  column: `col-7 ${styles.col_spec}`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(makeTitleAlone&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(departmentMake&&styles.disabled_Column)}`
              }}
              label={`Title:`}
              type="text"
              name={'title'}
              id={'title'}
              validator={register}
              // errors={errors}
                // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.title : null}
                // disabled={!disState}
              disabled={!remainVis ? true : makeTitleAlone ? true : tenantMake ? true : departmentMake ? true : false}
              onChange={(e)=>{onChangeMethodTitle(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTitle(e.target.value)}}
            />}
          </div>
        } 

        {typeVis === true && <div className={`col-sm-12 px-0 pb-2`}>
          <TextField
              classes={{
                  column: `col-7 ${styles.col_spec}`,
                  labelClass: `col-5 col-form-label ${styles.label_spec}`,
                  inputColumn: 'col-7 py-0 pl-3 pr-4',
                  inputClassName: `${styles.basic_input} form-control ${(!remainVis&&styles.disabled_Column)||(makeTitleAlone&&styles.disabled_Column)||(tenantMake&&styles.disabled_Column)||(departmentMake&&styles.disabled_Column)}`
              }}
              label={`Title:`}
              type="text"
              name={'title'}
              id={'title'}
              validator={register}
              // errors={errors}
                // customError={errorTenant}
              required={true}
              defaultValue={radioNone === false && userData ? userData?.title : null}
                // disabled={!disState}
              disabled={!remainVis}
              onChange={(e)=>{onChangeMethodTitle(e.target.value)}}
              onBlur={(e)=>{onChangeMethodTitle(e.target.value)}}
            />
          </div>
        }

        {/* changes end here */}

          {typeVis === false && <div className={`col-12 px-0 pb-2 ${styles.input_icons}`}>                                        
              <TextField 
                  id={CUSTOMER_NUMBER_ID}
                  name={CUSTOMER_NUMBER_ID}
                  label={"Primary Group:"}
                  classes = {
                      {
                          column: `col-8 ${styles.col_spec}`,
                          labelClass: `col-4 col-form-label  ${styles.label_spec}`,
                          inputColumn: `col-6 px-0 pl-4 pr-2 ml-3`,
                          inputClassName: `${styles.basic_input} ${!remainVis&&styles.disabled_Column}`                                  
                      }                            
                  }
                  // disabled={!disState}
                  disabled={!remainVis}
                  type="text"                            
                  errors={errors}
                  // customError={errorMsg ? errorMsg : ""}
                  // customError={
                  //     editState?.errorMessage && errCk?.data===null
                  //       ? editState.errorMessage
                  //       : customerNumberSearchDatas &&
                  //         Array.isArray(customerNumberSearchDatas) &&
                  //         customerNumberSearchDatas.length === 0
                  //       ? "Customer Number is not found"
                  //       : ""
                  //   }
                  required={true}
                  validator={register(!typeVis && remainVis && checkPrimaryGroup)} 
                  // validator={register({
                  //     validate: (value,e) => {
                  //       if ((value === ""|| value === null)&&(traveNurse === 0)) {
                  //         return "Customer Number Required"
                  //       } 
                  //       return true                             
                  //     }  
                  //   })}                       
                  defaultValue={radioNone === false && toDo !== undefined ? toDo?.charAt(0)?.toUpperCase() + toDo?.slice(1)?.toLowerCase() : ""}                                                   
                  onKeyPress={()=>{
                      setErrorMsg('')
                  }}
                  onChange={(e)=>getCustomerNumberSearch(e.target.value)}                        
                  // onKeyUp={(e) => {
                  //     if (e.key === 'Enter') {
                  //       getCustomerNumber(e.target.value);
                  //     }
                  // }}   
                  onKeyUp={(e) => getCustomerNumber(e?.target?.value)}                       
              />  
              {customerNumberSearchDatas &&
                  Array.isArray(customerNumberSearchDatas) &&
                  customerNumberSearchDatas.length > 0 && (
                      <div className="col-12 col-spec row">
                      <div className={`col_spec mr-auto ${styles.search_drop}`}>
                          <div className={`col-spec ${styles.search_close_box}`}>
                          {customerNumberSearchDatas.map(
                              (items, index) =>
                              items && (
                                  <p
                                  className={`dropdown-item`}
                                  title={items.value}
                                  onClick={(e) => customerNumberSelect(e, items)}
                                  >
                                  {items}
                                  </p>
                              )
                          )}
                          </div>
                      </div>
                      </div>
                  )}                  
              <span 
                  id="clear_icon"
                  className={remainVis ? `${styles.x_icon}` : `d-none`}
                  onClick={(e)=>ClearData(
                    document.getElementById(CUSTOMER_NUMBER_ID).value
                  )}                          
                  >
                  <i className="fa fa-times" style={remainVis ? {color : "#000"}: {color : "rgb(191 187 187)",cursor:'default'}} disabled={!remainVis} aria-hidden="true"></i>
              </span> 

          </div>}


          {typeVis === true && <div className={`col-12 px-0 pb-2 ${styles.input_icons}`}>                                        
              <TextField 
                  id={CUSTOMER_NUMBER_ID}
                  name={CUSTOMER_NUMBER_ID}
                  label={"Primary Group:"}
                  classes = {
                      {
                          column: `col-8 ${styles.col_spec}`,
                          labelClass: `col-4 col-form-label  ${styles.label_spec}`,
                          inputColumn: `col-6 px-0 pl-4 pr-2 ml-3`,
                          inputClassName: `${styles.basic_input} ${!remainVis&&styles.disabled_Column}`                                  
                      }                            
                  }
                  // disabled={!disState}
                  disabled={!remainVis}
                  type="text"                            
                  errors={errors}
                  // customError={errorMsg ? errorMsg : ""}
                  // customError={
                  //     editState?.errorMessage && errCk?.data===null
                  //       ? editState.errorMessage
                  //       : customerNumberSearchDatas &&
                  //         Array.isArray(customerNumberSearchDatas) &&
                  //         customerNumberSearchDatas.length === 0
                  //       ? "Customer Number is not found"
                  //       : ""
                  //   }
                  required={true}
                  // validator={register(typeVis === false && checkPrimaryGroup)} 
                  // validator={register({
                  //     validate: (value,e) => {
                  //       if ((value === ""|| value === null)&&(traveNurse === 0)) {
                  //         return "Customer Number Required"
                  //       } 
                  //       return true                             
                  //     }  
                  //   })}                       
                  // defaultValue={toDo !== undefined ? toDo?.charAt(0)?.toUpperCase() + toDo?.slice(1)?.toLowerCase() : ""}                                                   
                  onKeyPress={()=>{
                      setErrorMsg('')
                  }}
                  onChange={(e)=>getCustomerNumberSearch(e.target.value)}                        
                  // onKeyUp={(e) => {
                  //     if (e.key === 'Enter') {
                  //       getCustomerNumber(e.target.value);
                  //     }
                  // }}   
                  onKeyUp={(e) => getCustomerNumber(e?.target?.value)}                       
              />  
              {customerNumberSearchDatas &&
                  Array.isArray(customerNumberSearchDatas) &&
                  customerNumberSearchDatas.length > 0 && (
                      <div className="col-12 col-spec row">
                      <div className={`col_spec mr-auto ${styles.search_drop}`}>
                          <div className={`col-spec ${styles.search_close_box}`}>
                          {customerNumberSearchDatas.map(
                              (items, index) =>
                              items && (
                                  <p
                                  className={`dropdown-item`}
                                  title={items.value}
                                  onClick={(e) => customerNumberSelect(e, items)}
                                  >
                                  {items}
                                  </p>
                              )
                          )}
                          </div>
                      </div>
                      </div>
                  )}                  
              <span 
                  id="clear_icon"
                  className={remainVis ? `${styles.x_icon}` : `d-none`}
                  onClick={(e)=>ClearData(
                    document.getElementById(CUSTOMER_NUMBER_ID).value
                  )}                          
                  >
                  <i className="fa fa-times" style={remainVis ? {color : "#000"}: {color : "rgb(191 187 187)",cursor:'default'}} disabled={!remainVis} aria-hidden="true"></i>
              </span> 

          </div>}
          
          
          {/* Organizational View*/}
          <div className={`col-12 px-0 ${styles.input_icons}`}>
              {(checkOrgDrop === false) || (checkOrgDrop === true) && <Organizational
                      id={"organizationalView"}
                      name={"organizationalView"}
                      label={`Facility/Locations:`}
                      classes={{
                          column: `col-9 ${styles.col_spec}`,
                          labelClassName: `col-4 mr-1 col-form-label ${styles.label_spec}`,
                          inputColumn: `col-5 px-0 pr-1 ${styles.organizationalView}`,
                          inputClassName: `${styles.basic_input} form-control`
                      }}                        
                      optionValue={faciOptionData}
                      groupBy="cat"
                      displayValue="key"
                      defaultValue={onselectOrgz}
                      onSelect={onSelectOrg}
                      // customError={errorMsg}
                      selectAll={true}  
                      dropdownOpen={checkOrgDrop}
                      required={true}
                      errors={errors}   
                      disabled={!remainVis}                              
                  /> 
                  } 
          </div>
          { !typeVis && remainVis && isValidSaleCust && <span className={`col-8 ${styles.error_message} text-center`}>{
                      'Please select facility' }
                  </span> }
        {/* .... */}

        {typeVis === false && showEmailCheckbox &&
            <div className={`col-sm-12 px-0 py-4 align-items-center`}>
            <label className={`col-3 col-form-label mr-0 ${styles.label_spec_Sso}`}>Applies to all email addresses: {<span className={styles.astrick}>&#42;</span>}</label>
            <RadioButton
                id={`assigntoallemails`}
                name={`assigntoallemails`}
                classes={{
                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                    inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                    inputColumn: "custom-control custom-radio custom-control-inline"
                }}
                // validator={register}
                validator={register(!typeVis && remainVis && checkAssignToAllEmails)}
                radioOption={['Yes', 'No']}
                defaultValue={
                  radioNone === false ? userData?.assignToAllEmails === 1
                    ? "Yes"
                    : "No"
                  : null
                }
                onChange={(e) => getState(e.target.value)}
                onClick={(e) => getState(e.target.value)}
                // disabled={!disState}
                disabled={!remainVis}
                required={true}
                errors={errors}
                lableSso="sso"
            />
        </div>         
        }

        {typeVis === true && showEmailCheckbox &&
            <div className={`col-sm-12 px-0 py-4 align-items-center`}>
            <label className={`col-3 col-form-label mr-0 ${styles.label_spec_Sso}`}>Applies to all email addresses: {<span className={styles.astrick}>&#42;</span>}</label>
            <RadioButton
                id={`assigntoallemails`}
                name={`assigntoallemails`}
                classes={{
                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                    inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                    inputColumn: "custom-control custom-radio custom-control-inline"
                }}
                // validator={register}
                // validator={register(!typeVis && remainVis && checkAssignToAllEmails)}
                radioOption={['Yes', 'No']}
                // defaultValue={
                //   radioNone === false ? userData?.assignToAllEmails === 1
                //     ? "Yes"
                //     : "No"
                //   : null
                // }
                onChange={(e) => getState(e.target.value)}
                onClick={(e) => getState(e.target.value)}
                // disabled={!disState}
                disabled={!remainVis}
                required={true}
                errors={errors}
                lableSso="sso"
            />
        </div>         
        }

        {showEmailCheckbox && <div className={`col-sm-12 px-0 pb-2 d-flex align-items-center`}>
          <label className={`col-2 text-center col-form-label mr-0 ${styles.label_spec_Sso}`} style={{fontSize:'20px',fontWeight:'bold'}}> OR </label>
          <label className={`col-6 col-form-label pl-0 mr-0 ${styles.label_spec_Sso}`}> </label>
        </div>}
        
        {/* Include Mulit Email text box */}
        {showEmailCheckbox &&   
          <div className={`col-sm-12 ${styles.search_terms} mt-0 d-flex`}>
                <label className={`${styles.label_spec} col-3 p-0`}>Specific email addresses only: {makeIncExcError && <span className={styles.astrick}>&#42;</span>}</label>                 
                <div className={makeDisable ? `col-4 ${styles.main_list_disabled} pl-0 pr-1` : `col-4 ${styles.main_list} pl-0 pr-1`} contentEditable={makeDisable===true ? false : true}>                  
                  <div className={`col-12 ${styles.search_list} px-0`}>
                    <ul className="d-flex flex-wrap w-100 pl-3">
                      {searchKeyWord && searchKeyWord.length > 0 && searchKeyWord.map((data, i) => {
                        return (
                          <>
                            {data&& <li key={i} contentEditable={false}>{data}<img src={closeNormal} alt="close" onClick={() => removeKeyword(i)} className={`deleteSearchKeyWord`}/></li>}                      
                          </>
                        )
                      })

                      }
                    </ul>
                  </div>

                    {
                        newKeySearchKey && <><div className={`col-12 ${styles.input_li} d-flex align-items-center p-0`}>
                          <TextField
                              id={'keyword'}
                              name={"keyword"}
                              classes={{
                                  column: `col-12 ${styles.col_spec} p-0 m-0 pl-1}`,
                                  labelClass: `col-12 col-form-label ${styles.label_spec}`,
                                  inputColumn: 'col-12 p-0 pt-3 w-100',
                                  inputClassName: `${styles.basic_input_sepcific} onblurKeyword ${styles.col_spec_unBorder} ${makeDisable && styles.disabled_Column}`
                              }}
                              type="text"
                              onBlur={(e)=>onBlurChangeSearchKeyWord(e.target.value)}
                              // onKeyPress={(e) => onKeyChangeSearchKeyWord(e.target.value)}
                              onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  onKeyChangeSearchKeyWord(e.target.value);
                                }
                            }}
                              autofocus={true}
                              disabled={makeDisable? true : false}
                              onChange={(e) => falseErrorChange(e.target.value)}
                          />                              
                          <img src={closeNormal} alt="close" onClick={() => removeTextBox()} className={`deleteSearchKeyWord`} />
                        </div></>
                      }            
                </div>                  
              </div>
        }
        {showEmailCheckbox && !isValidSaleCust ? showEmailError && typeVis === false && <span className={`col-8 ${styles.error_message_IncExc} text-center`}>{
            'Enter specific email addresses'}
          </span> : ""
        }
        

        {/* Exclude Mulit Email text box */}
        {!showEmailCheckbox &&             
          <div className={`col-sm-12 ${styles.search_terms_excl} d-flex`}>
              <label className={`${styles.label_spec} col-3 p-0`}>Specific email addresses only: {<span className={styles.astrick}>&#42;</span>}</label>                 
              <div className={makeDisable ? `col-4 ${styles.main_list_disabled} pl-0 pr-1` : `col-4 ${styles.main_list} pl-0 pr-1`} contentEditable={makeDisable===true ? false : true}>                  
                <div className={`col-12 ${styles.search_list} px-0`}>
                  <ul className="d-flex flex-wrap w-100 pl-3">
                    {searchKeyWord && searchKeyWord.length > 0 && searchKeyWord.map((data, i) => {
                      return (
                        <>
                          {data&& <li key={i} contentEditable={false}>{data}<img src={closeNormal} alt="close" onClick={() => removeKeyword(i)} className={`deleteSearchKeyWord`}/></li>}                      
                        </>
                      )
                    })

                    }
                  </ul>
                </div>

                  {
                      newKeySearchKey && <><div className={`col-12 ${styles.input_li} d-flex align-items-center p-0`}>
                        <TextField
                            id={'keyword'}
                            name={"keyword"}
                            classes={{
                                column: `col-12 ${styles.col_spec} p-0 m-0 pl-1}`,
                                labelClass: `col-12 col-form-label ${styles.label_spec}`,
                                inputColumn: `col-12 p-0 pt-3 w-100`,
                                inputClassName: `${styles.basic_input_sepcific} onblurKeyword ${styles.col_spec_unBorder} ${makeDisable && styles.disabled_Column}`
                            }}
                            type="text"
                            onBlur={(e)=>onBlurChangeSearchKeyWord(e.target.value)}
                            // onKeyPress={(e) => onKeyChangeSearchKeyWord(e.target.value)}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter') {
                                onKeyChangeSearchKeyWord(e.target.value);
                              }
                          }}
                            autofocus={true}
                            disabled={makeDisable? true : false}
                            onChange={(e) => falseErrorChange(e.target.value)}
                        />                              
                        <img src={closeNormal} alt="close" onClick={() => removeTextBox()} className={`deleteSearchKeyWord`} />
                      </div></>
                    }            
              </div>
            </div>
        }
        {!showEmailCheckbox && !isValidSaleCust ? showEmailError && typeVis === false && <span className={`col-8 ${styles.error_message_IncExc} text-center`}>{
            'Enter specific email addresses'}
          </span> : ""
        }

        <div className="col-sm-12 d-flex pt-5 pl-0">
          <div className='col-3 mr-4 ml-3'></div>
          <div className="text-center pb-4 mr-4">
            <Button
              classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
              type="submit"
              id={SUBMIT_ID}
              >
              {SAVE_BTN}
            </Button>
          </div>
          <div className="text-center pb-4">
            <Button
              classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small} ${styles.btn_cancel}` }}
              id={CANCEL_ID}
              onClick={handleCancel}
              >
              {CANCEL_BTN}
          </Button>
          </div>
        </div>

        </>
        }          
      
      </Form>            
      </>
  )
}

CreateEditSso.propTypes = {
  register: PropTypes.func,
  handleSubmit: PropTypes.func,
  control: PropTypes.object,
  handleCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  userData: PropTypes.object
}
