import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  BROWSE_LBL,
  CANCEL_BTN,
  CANCEL_ID,
  EDIT,
  SAVE_BTN,
  SUBMIT_ID,
  AI_TISSUE_TYPE_STATUS,
  WOUND_EDGE_AUTO_TRACING_STATUS,
  NE1_WOUND_MEASUREMENT_OPT,
  // UPDATE_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
  UPDATE_FAILURE,
  SUCCESS_STATUS,
  ERROR_STATUS,
  SESSION_TIMEOUT_OPTIONS,
  POA_TYPE_OPTIONS,
  CUSTOMER_GROUP_NAME
} from "../../../constants";
import editIcon from "../../../static/images/edit-icon-blue.png";
import DefaultImg from "../../../static/images/picture-icon-default.png";
import {
  checkDomain,
  poaRevalValidator,
  checkSessionType,
  checkSessionHours,
  checkSessionMinutes,
  checkPoaType,
} from "../../../utils";
import { Button, Form, RadioButton, TextField, SelectBox } from "../../primary";
import { PictureIcon, Modal } from "../../secondary";
import styles from "./integration.module.scss";
import PropTypes from "prop-types";
import { setEditState } from "../../../app/customerSlice";
import { AccessErrorModal } from "../../primary";
import { Confirmation } from "../../secondary/Confirmation/confirmation";
import warning from "../../../static/images/icon-check-danger.png";
import closeIcon from "../../../static/images/close-icon-gray.png";
import {listOptionSalesCustomer} from '../../../app/customerSlice';
import infoIcon from '../../../static/images/info.svg';
import Loader from '../../../static/images/spinner.svg';

export function Integration({ customerData, updateCustomerData, history  }) {
  /* Use hook form */
  const { register, handleSubmit, watch, clearErrors, errors,reset,getValues} = useForm();

  /* State to check editable forms */
  const [editable, setEditable] = useState(false);
  const [disable, setDisable] = useState(false);
  const accessError = useSelector((state) => state.app.accessError);

  const resMsgState = useSelector(state => state.user.resMsgState);

  /* Set reEval or set default value */
  const reEvalHours = customerData?.settings
    ? customerData?.settings?.reEvalWithinHrs
    : "";

    useEffect(() => {
      dispatch(listOptionSalesCustomer('repotype')) //listoption for saleoffice
  }, [])
  const listOptionSales = useSelector(state=> state.customer.listOption)

  /* Function to toggle edit */
  const handleEdit = () => {
    reset()
    setEditable(!editable);
    setDisable(!disable);   
    setInboundState(customerData?.integretiontab?.inboundIntegration)
    setOutboundState(customerData?.integretiontab?.outboundIntegration)
    setManualMakeTrue(false)
    setShowConfirmPopupManual(false)
    setSapMakeTrue(false)
    setShowConfirmPopupSap(false)
    setMakeAllDiscrete(false)
    setMakeWoundImage(false)
    setMakeMeasurement(false)
    setMakeCharacterstics(false)
    setMakeTreatment(false)
  };


  // submit data
  const onSubmit = (data) => {
    const tempData = {
      integretiontab:  {
        adtFeed: data.ADT_Feed !== "" || data.ADT_Feed !== undefined ? data.ADT_Feed : null,
        inboundIntegration: inboundState,
        manualPatientCreation: data.manualpatientcreation === "Enable" ? 1 : 0,
        mpCallSoldToShipTo: popupSaveMutual === true ? 1 : 0,
        sapCustomerMaster: data.sapcustomermaster === "Enable" ? 1 : 0,
        scMallSoldToShipTo: popupSaveSap === true ? 1 : 0,
        outboundIntegration: outboundState,
        pdfReport: data.PDF_report !== "" || data.PDF_report !== undefined ? data.PDF_report : null,
        allDiscreateData: data.all_discrete_data !== "" || data.all_discrete_data !== undefined ? data.all_discrete_data : null,
        woundImages: data.wound_images !== "" || data.wound_images !== undefined ? data.wound_images : null,
        measurements: data.measurements !== "" || data.measurements !== undefined ? data.measurements : null,
        charateristics: data.characteristics !== "" || data.characteristics !== undefined ? data.characteristics : null,
        treatmentPlan: data.treatment_plan !== "" || data.treatment_plan !== undefined ? data.treatment_plan : null
      },      
    };
    /* Merging default object with new data */
    const result = { ...customerData, ...tempData };
    /* Dispatching */
     updateCustomerData(result);
  };

  /* Edit success */
  const editState = useSelector((state) => state.customer.editState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (editState === "success") {
      setEditable(!editable);
      reset()
    }
    setTimeout(() => {
      dispatch(setEditState(false));
    }, 5000);
  }, [editState, dispatch]);
  
  //Inbound & Outbound
  const [inboundState, setInboundState] = useState(0)
  const [outboundState, setOutboundState] = useState(0)

  const handleClickInbound = () => {
    setManualMakeTrue(false)
    setShowConfirmPopupManual(false)
    setPopupSaveMutual(false)
    setSapMakeTrue(false)
    setShowConfirmPopupSap(false)
    setPopupSaveSap(false)
    if(inboundState===0){
        setInboundState(1)
    }
    if(inboundState===1){
        setInboundState(0)
        // setAbendValue(false)
    }
  }

  useEffect(()=>{
    if(customerData?.integretiontab?.inboundIntegration === 1){
      setInboundState(1)
    }
    else{
      setInboundState(0)   
    }
  },[customerData])
  

  const handleClickOutbound = () => {
    if(outboundState===0){
        setOutboundState(1)
    }
    if(outboundState===1){
        setOutboundState(0)
    }
  }
  
  useEffect(()=>{
    if(customerData?.integretiontab?.outboundIntegration === 1){
      setOutboundState(1)
    }
    else{
      setOutboundState(0)
    }
  },[customerData])


  // Mutual Popup
  const [manualMakeTrue, setManualMakeTrue] = useState(false)
  const [showConfirmPopupManual, setShowConfirmPopupManual] = useState(false)
  const [popupSaveMutual, setPopupSaveMutual] = useState(false)
  const [sapMakeTrue, setSapMakeTrue] = useState(false)
  const [showConfirmPopupSap, setShowConfirmPopupSap] = useState(false)
  const [popupSaveSap, setPopupSaveSap] = useState(false)
  
  const [enableStatusManual, setEnableStatusManual] = useState(false)
  const getConFirmMunual = (value) => {
    setManualMakeTrue(true)
    if(value==="Enable"){
      setEnableStatusManual(true)
    }
    else{
      setEnableStatusManual(false)
    }
    // if(value==="Enable"){
    //   if(customerData?.integretiontab?.manualPatientCreation===0 && !manualMakeTrue && customerData?.accountGroup==="Z002"){
    //     setManualMakeTrue(true)
    //   }
    //   if(customerData?.integretiontab?.manualPatientCreation===1 && manualMakeTrue && customerData?.accountGroup==="Z002"){
    //     setManualMakeTrue(false)
    //   }
    //   if(customerData?.integretiontab?.manualPatientCreation===1 && !manualMakeTrue && customerData?.accountGroup==="Z002"){
    //     setManualMakeTrue(true)
    //   }
    //   setEnableStatusManual(true)
    // }
    // else{
    //   if(customerData?.integretiontab?.manualPatientCreation===1 && !manualMakeTrue && customerData?.accountGroup==="Z002"){
    //     setManualMakeTrue(true)
    //   }
    //   if(customerData?.integretiontab?.manualPatientCreation===0 && manualMakeTrue && customerData?.accountGroup==="Z002"){
    //     setManualMakeTrue(false)
    //   }
    //   if(customerData?.integretiontab?.manualPatientCreation===0 && !manualMakeTrue && customerData?.accountGroup==="Z002"){
    //     setManualMakeTrue(true)
    //   }
    //   setEnableStatusManual(false)      
    // }
  }

  const modalOpenMunual = () => {
    setShowConfirmPopupManual(true)
  }
  const modalCloseManual = () => {
    setShowConfirmPopupManual(false)
    setManualMakeTrue(false)
  }

  const handleSavePopupManual = () => {
    setPopupSaveMutual(true)
    modalCloseManual();
  }

  const handleCancelPopupManual = () => {
    setPopupSaveMutual(false)
    modalCloseManual();
  }

  const [enableStatusSap, setEnableStatusSap] = useState(false)
  const getConFirmSap = (value) => {
    setSapMakeTrue(true)
    if(value==="Enable"){
      setEnableStatusSap(true)
    }
    else{
      setEnableStatusSap(false)
    }
    // if(value==="Enable"){
    //   if(customerData?.integretiontab?.sapCustomerMaster===0 && !sapMakeTrue && customerData?.accountGroup==="Z002"){
    //     setSapMakeTrue(true)
    //   }
    //   if(customerData?.integretiontab?.sapCustomerMaster===1 && sapMakeTrue && customerData?.accountGroup==="Z002"){
    //     setSapMakeTrue(false)
    //   }
    //   if(customerData?.integretiontab?.sapCustomerMaster===1 && !sapMakeTrue && customerData?.accountGroup==="Z002"){
    //     setSapMakeTrue(true)
    //   }
    //   setEnableStatusSap(true)
    // }
    // else{
    //   if(customerData?.integretiontab?.sapCustomerMaster===1 && !sapMakeTrue && customerData?.accountGroup==="Z002"){
    //     setSapMakeTrue(true)
    //   }
    //   if(customerData?.integretiontab?.sapCustomerMaster===0 && sapMakeTrue && customerData?.accountGroup==="Z002"){
    //     setSapMakeTrue(false)
    //   }
    //   if(customerData?.integretiontab?.sapCustomerMaster===0 && !sapMakeTrue && customerData?.accountGroup==="Z002"){
    //     setSapMakeTrue(true)
    //   }
    //   setEnableStatusSap(false)
    // }
  }

  const modalOpenSap = () => {
    setShowConfirmPopupSap(true)
  }
  const modalCloseSap = () => {
    setShowConfirmPopupSap(false)
    setSapMakeTrue(false)
  }

  const handleSavePopupSap = () => {
    setPopupSaveSap(true)
    modalCloseSap();    
  }

  const handleCancelPopupSap = () => {
    setPopupSaveSap(false)
    modalCloseSap();
  }

  const [makeAllDiscrete, setMakeAllDiscrete] = useState(false)
  const [makeWoundImage, setMakeWoundImage] = useState(false)
  const [makeMeasurement, setMakeMeasurement] = useState(false)
  const [makeCharacterstics, setMakeCharacterstics] = useState(false)
  const [makeTreatment, setMakeTreatment] = useState(false)

  const checkAllDiscreteData = (value) => {
    if(value){
      setMakeAllDiscrete(true)
    }
    else{
      setMakeAllDiscrete(false)
    }    
  }

  const checkWoundImage = (value)=> {
    if(value){
      setMakeWoundImage(true)
    }
    else{
      setMakeWoundImage(false)
    } 
  }

  const checkMeasurements = (value) => {
    if(value){
      setMakeMeasurement(true)
    }
    else{
      setMakeMeasurement(false)
    } 
  }

  const checkCharacteristics = (value) => {
    if(value){
      setMakeCharacterstics(true)
    }
    else{
      setMakeCharacterstics(false)
    } 
  }

  const checkTreatmentPlan = (value) => {
    if(value){
      setMakeTreatment(true)
    }
    else{
      setMakeTreatment(false)
    } 
  }


     /*loader*/
 const [tinyDelay, setTinyDelay] = useState(false)

 useEffect(() => {
   setTimeout(() => {
     setTinyDelay(true)
   }, 2500)
 }, [])
  return (
    <>
      
      {/* Modal Pop Screen for Manual */}
      {showConfirmPopupManual && customerData?.accountGroup==="Z002" && <Modal
        modalOpen={modalOpenMunual}
        modalClose={modalCloseManual}
        heading={"NE1 Settings"}
        className={"modal_box"}
        modalSize={styles.custom_modal_size}
        modalScroll={false}
        reset={!resMsgState?.errorMessage && reset}
        custom_content={styles.custom_content}
        custom_heading={styles.custom_heading_ne1}
        modal_title={styles.modal_title_modal}
        close={styles.close_button_modal}
        changeNe1={true}
      >
        <div className="">
            <div className={`${styles.headerSetting} pt-3`}>
              <div className="mr-4">
                <i class="fa fa-cog" style={{color:'rgb(137 137 137)', fontSize:'34px'}} aria-hidden="true"></i>
              </div>
              <div className="pt-2">
                <p style={{fontSize:'21px', color:'rgb(137 137 137)', fontWeight:'500'}}>Apply to all Sold To’s & Ship To’s</p>
              </div>              
            </div>
            <div className={`${styles.content_modal} text-center pt-3`}>
              {!enableStatusManual ? <h1 style={{fontSize:'60px', color:'rgb(164 163 163)', fontWeight:'500', lineHeight:'0.99em'}}>Disable Manual Patient Creation</h1> : <h1 style={{fontSize:'60px', color:'rgb(164 163 163)', fontWeight:'500', lineHeight:'0.99em'}}>Enable Manual Patient Creation</h1>}
              <p style={{fontSize:'20px', color:'rgb(137 137 137)', fontWeight:'500',marginTop:'32px', padding:'0 60px'}}>Please click “Yes” if you wish to apply these parameters to the entire org hierarchy or “No” to cancel</p>
            </div>

            <div className={`row ${styles.form_btn} ml-0`}>
              <div className="col-sm-12">
                <div className="text-center pb-4">
                  <br />
                  <Button
                    classes={{
                      className: `btn ${styles.btn_custom} ${styles.btn_small} settingsSave rounded`,
                    }}
                    type="submit"
                    id={SUBMIT_ID}
                    onClick={handleSavePopupManual}
                  >
                    {'Yes'}
                  </Button>
                  <Button
                    classes={{
                      className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} ${styles.btn_small_cancel_modal} rounded`,
                    }}
                    id={'no_popup'}
                    onClick={handleCancelPopupManual}
                  >
                    {'No'}
                  </Button>
                </div>
              </div>
            </div>
            
        </div>
      </Modal>
      }

      {/* Modal Pop Screen for SAP */}
      {showConfirmPopupSap && customerData?.accountGroup==="Z002" && <Modal
        modalOpen={modalOpenSap}
        modalClose={modalCloseSap}
        heading={"NE1 Settings"}
        className={"modal_box"}
        modalSize={styles.custom_modal_size}
        modalScroll={false}
        reset={!resMsgState?.errorMessage && reset}
        custom_content={styles.custom_content}
        custom_heading={styles.custom_heading_ne1}
        modal_title={styles.modal_title_modal}
        close={styles.close_button_modal}
        changeNe1={true}
      >
        <div className="">
            <div className={`${styles.headerSetting} pt-3`}>
              <div className="mr-4">
                <i class="fa fa-cog" style={{color:'rgb(137 137 137)', fontSize:'34px'}} aria-hidden="true"></i>
              </div>
              <div className="pt-2">
                <p style={{fontSize:'21px', color:'rgb(137 137 137)', fontWeight:'500'}}>Apply to all Sold To’s & Ship To’s</p>
              </div>              
            </div>
            <div className={`${styles.content_modal} text-center pt-3`}>
              {!enableStatusSap ? <h1 style={{fontSize:'46px', color:'rgb(164 163 163)', fontWeight:'500', lineHeight:'0.99em'}}>Disable SAP Customer Master Update</h1> : <h1 style={{fontSize:'46px', color:'rgb(164 163 163)', fontWeight:'500', lineHeight:'0.99em'}}>Enable SAP Customer Master Update</h1>}
              <p style={{fontSize:'20px', color:'rgb(137 137 137)', fontWeight:'500',marginTop:'32px', padding:'0 60px'}}>Please click “Yes” if you wish to apply these parameters to the entire org hierarchy or “No” to cancel</p>
            </div>

            <div className={`row ${styles.form_btn} ml-0`}>
              <div className="col-sm-12">
                <div className="text-center pb-4">
                  <br />
                  <Button
                    classes={{
                      className: `btn ${styles.btn_custom} ${styles.btn_small} settingsSave rounded`,
                    }}
                    type="submit"
                    id={SUBMIT_ID}
                    onClick={handleSavePopupSap}
                  >
                    {'Yes'}
                  </Button>
                  <Button
                    classes={{
                      className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} ${styles.btn_small_cancel_modal} rounded`,
                    }}
                    id={'no_popup'}
                    onClick={handleCancelPopupSap}
                  >
                    {'No'}
                  </Button>
                </div>
              </div>
            </div>
            
        </div>
      </Modal>
      }
      

      {!tinyDelay ?<div className={`loader col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:<div className={`col-md-12 ${styles.single_product_block}`}>
        <div className="row">
          {/* Edit success */}
          {editState === SUCCESS_STATUS && (
            <div className={`alert alert-success ${styles.form_alert}`}>
              {CUSTOMER_UPDATE_SUCCESS}
            </div>
          )}

          {/* Edit failure */}
          {editState === ERROR_STATUS && (
            <div className={`alert alert-danger ${styles.form_alert}`}>
              {UPDATE_FAILURE}
            </div>
          )}
          
          
          <div className={` ${styles.patient_content}`}>
            {!editable ? (
              <>
                {/* Headers */}
                <div className={`${styles.tab_content_top} col-12`}>
                  <h2 className={`${styles.tab_content_hdg} pl-0`}>
                    <div className={`${styles.tab_content_ne1Status}`}>
                        {/* {customerData?.customerName} */}
                    </div>
                    {!editable && (
                      <span
                        className={`float-sm-right ${styles.tab_edit}`}
                        onClick={handleEdit}
                      >
                        <img src={editIcon} alt="edit" />
                        {EDIT}
                      </span>
                    )}
                  </h2>
                </div>

                {/* Inbound Integration​  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                    style={{fontSize:'18px', fontWeight:'normal', color:'#737373'}}
                  >
                    Inbound Integration​:
                  </label>
                  <div className="col-6 p-0 pt-1">
                    <span className={`col-form-label ${styles.content_spec}`} style={{fontSize:'18px', fontWeight:'normal', color:'#737373'}}>
                      {customerData?.integretiontab?.inboundIntegration === 1 ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* ADT Feed  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    ADT Feed:
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.integretiontab?.adtFeed !== null && customerData?.integretiontab?.adtFeed !== ""
                        ? customerData?.integretiontab?.adtFeed[0]?.toUpperCase() +
                          customerData?.integretiontab?.adtFeed?.slice(1)
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>  
                

                {/* Manual patient creation  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Manual patient creation:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {
                        AI_TISSUE_TYPE_STATUS[
                          customerData?.integretiontab?.manualPatientCreation
                        ]
                      }
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>  
                

                 {/* SAP Customer Master  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    SAP customer master:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {
                        AI_TISSUE_TYPE_STATUS[
                          customerData?.integretiontab?.sapCustomerMaster
                        ]
                      }
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>  
                

                {/* Outbound Integration​  */}
                <div className={`col-6 ${styles.col_spec} pt-3`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                    style={{fontSize:'18px', fontWeight:'normal', color:'#737373'}}
                  >
                    Outbound Integration​:
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`} style={{fontSize:'18px', fontWeight:'normal', color:'#737373'}}>
                      {customerData?.integretiontab?.outboundIntegration === 1 ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div> 


                {/* PDF Report  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    PDF report:
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.integretiontab?.pdfReport !== null && customerData?.integretiontab?.pdfReport !== ""
                        ? customerData?.integretiontab?.pdfReport[0]?.toUpperCase() +
                        customerData?.integretiontab?.pdfReport?.slice(1)
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* All discrete data  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    All discrete data:
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                        {customerData?.integretiontab?.allDiscreateData !== null && customerData?.integretiontab?.allDiscreateData !== ""
                        ? customerData?.integretiontab?.allDiscreateData[0]?.toUpperCase() +
                        customerData?.integretiontab?.allDiscreateData?.slice(1)
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Wound images:  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Wound images:​
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                        {customerData?.integretiontab?.woundImages !== null && customerData?.integretiontab?.woundImages !== ""
                        ? customerData?.integretiontab?.woundImages[0]?.toUpperCase()+
                        customerData?.integretiontab?.woundImages?.slice(1)
                        : "-"} 
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Measurements  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Measurements:
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                        {customerData?.integretiontab?.measurements !== null && customerData?.integretiontab?.measurements !== ""
                        ? customerData?.integretiontab?.measurements[0]?.toUpperCase() +
                        customerData?.integretiontab?.measurements?.slice(1)
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/*Wound Measurements Heading */}
                {/* <div className={`col-sm-12 ${styles.col_spec} pt-2`}>
                  <p className={`col-12 ${styles.subheading_2}`}>
                    Wound Measurements
                  </p>
                </div> */}

                {/* Characteristics  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Characteristics:
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                        {customerData?.integretiontab?.charateristics !== null && customerData?.integretiontab?.charateristics !== ""
                        ? customerData?.integretiontab?.charateristics[0]?.toUpperCase() +
                        customerData?.integretiontab?.charateristics?.slice(1)
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Treatment Plan  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Treatment plan:
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                        {customerData?.integretiontab?.treatmentPlan !== null && customerData?.integretiontab?.treatmentPlan !== ""
                        ? customerData?.integretiontab?.treatmentPlan[0]?.toUpperCase() +
                        customerData?.integretiontab?.treatmentPlan?.slice(1)
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>    
                
              </>
            ) : 
            
            (
              <Form
                formChanges={true}
                className={`p-0 ${styles.patient_content}`}
                onSubmit={handleSubmit(onSubmit)}
              >

                {/* Inbound section */}
                {/* Toggle for Inbound Integration */}                
                <div className={`col-6 ${styles.ne1_toggleSwitch} custom-control custom-switch pl-0 pt-2 pb-4`}>                      
                    <h2 className={`col-6`} style={{fontSize:'24px', fontWeight:'normal', color:'#737373'}}>
                      Inbound Integration: 
                    </h2>
                    <div className={`${styles.toggle_shells}`}>                                      
                    <input 
                        type="checkbox"
                        name="inbound_toggle" 
                        className="custom-control-input"
                        id={"inbound_toggle"}
                        data-testid={"inbound_toggle"}
                        // defaultValue = {ne1State}
                        checked = {inboundState === 1}                                                    
                        onChange={handleClickInbound}                          
                    />
                    <label className={`custom-control-label ${styles.toggle_switch}`} for="inbound_toggle"></label> 
                    </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}> </div> 


                {/* Customer Group Name */}
                <SelectBox
                    id={"ADT_Feed"}
                    name={"ADT_Feed"}
                    // label={`${CUSTOMER_GROUP_NAME_LBL}:`}
                    label={`ADT Feed:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled} `
                    }}
                    optionValue={listOptionSales}
                    validator={register}
                    defaultValue={customerData && customerData?.integretiontab?.adtFeed}
                    placeholder={"Select"}
                    errors = {errors}
                    disabled={inboundState===0}
                    // onChange={(e)=>adtFeedHandleChange(e.target.value)}
                />
                <div className={inboundState===1 ? `col-4` : 'd-none'}>
                    <span className={`${styles.tooltip}`}>
                      <img src={infoIcon} alt="info" />
                      <span class={`${styles.tooltiptext}`}>
                        {`Please refer to Azure Logic Apps for how often the patient inbound data is updated`}
                      </span> 
                    </span>
                </div>          
                <div className={inboundState===1 ? `col-2 ${styles.col_spec}`: `col-6 ${styles.col_spec}`}></div>
              

                {/* Manual Patient Creation */}                
                { inboundState===1 && <>
                <div className={`col-5 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec} mr-2`}
                  >
                    Manual patient creation:
                  </label>
                  <RadioButton
                    id={"manualpatientcreation"}
                    name={"manualpatientcreation"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn:
                        "custom-radio custom-control custom-control-inline",
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    defaultValue={
                      customerData?.integretiontab?.manualPatientCreation===1 ? "Enable" : "Disable"
                    }
                    onChange={(e) => getConFirmMunual(e.target.value)}
                    disabled={inboundState===0}
                  />
                </div>

                <div className={`col-6 ${styles.col_spec}`}>    
                  {/* Vertical code */}
                  {manualMakeTrue && <div className={`verticalCode  ${styles.vertical_Code_role}`}></div>} 
                  {/* Shift and Sold button */}
                  {manualMakeTrue &&
                  <div className="btnClass">
                    <Button
                        classes={{
                          className: `btn ${styles.shif_sold_button}`,
                        }}
                        id="ShiftSold"
                        type="button"
                        // id={CANCEL_ID}
                        onClick={modalOpenMunual}
                      >
                        {"Apply to all Sold To’s and Ship To’s​"}
                      </Button>
                  </div>}
                </div>
                </>}

                {inboundState===0 && <>
                <div className={`col-5 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec} mr-2`}
                  >
                    Manual patient creation:
                  </label>
                  <RadioButton
                    id={"manualpatientcreation"}
                    name={"manualpatientcreation"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn:
                        "custom-radio custom-control custom-control-inline",
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    // defaultValue={inboundState===0 
                    // }
                    onChange={(e) => getConFirmMunual(e.target.value)}
                    disabled={inboundState===0}
                  />
                </div>
                <div className={`col-6 ${styles.col_spec}`}> </div>
                </>}


                {/* SAP Customer master */}                
                {inboundState === 1 && <>
                <div className={`col-5 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec} mr-2`}
                  >
                    SAP customer master:
                  </label>
                  <RadioButton
                    id={"sapcustomermaster"}
                    name={"sapcustomermaster"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn:
                        "custom-radio custom-control custom-control-inline",
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    defaultValue={
                      customerData?.integretiontab?.sapCustomerMaster===1 ? "Enable" : "Disable"
                    }
                    onChange={(e) => getConFirmSap(e.target.value)}
                    disabled={inboundState===0}
                  />
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                   {/* Vertical code */}
                  {sapMakeTrue && <div className={`verticalCode  ${styles.vertical_Code_role}`}></div>} 
                  {/* Shift and Sold button */}
                  {sapMakeTrue &&
                  <div className="btnClass">
                    <Button
                        classes={{
                          className: `btn ${styles.shif_sold_button}`,
                        }}
                        id="ShiftSold"
                        type="button"
                        // id={CANCEL_ID}
                        onClick={modalOpenSap}
                      >
                        {"Apply to all Sold To’s and Ship To’s​"}
                      </Button>
                  </div>}
                </div>
                </>}

                {inboundState === 0 && <>
                <div className={`col-5 ${styles.col_spec}`}>
                  <label
                    className={`col-7 col-form-label ${styles.label_spec} mr-2`}
                  >
                    SAP customer master:
                  </label>
                  <RadioButton
                    id={"sapcustomermaster"}
                    name={"sapcustomermaster"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn:
                        "custom-radio custom-control custom-control-inline",
                    }}
                    validator={register()}
                    radioOption={["Enable", "Disable"]}
                    // defaultValue={
                    //   customerData?.integretiontab?.sapCustomerMaster===1 ? "Enable" : "Disable"
                    // }
                    onChange={(e) => getConFirmSap(e.target.value)}
                    disabled={inboundState===0}
                  />
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>
                </>}


                {/* Outbound section */}
                <div className="col-12 py-3"></div>
                {/* Toggle for Outbound Integration */}              
                <div className={`col-6 ${styles.ne1_toggleSwitch} custom-control custom-switch pl-0 pt-2 pb-4`}>                      
                    <h2 className={`col-6`} style={{fontSize:'24px', fontWeight:'normal', color:'#737373'}}>
                      Outbound Integration: 
                    </h2>
                    <div className={`${styles.toggle_shells}`}>                                      
                    <input 
                        type="checkbox"
                        name="outbound_toggle" 
                        className="custom-control-input"
                        id={"outbound_toggle"}
                        data-testid={"outbound_toggle"}
                        // defaultValue = {ne1State}
                        checked = {outboundState === 1 ? true : false}                                                    
                        onChange={handleClickOutbound}                          
                    />
                    <label className={`custom-control-label ${styles.toggle_switch}`} for="outbound_toggle"></label> 
                    </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}> </div>
             
                {/* PDF report Dropdown */}
                <SelectBox
                    id={"PDF_report"}
                    name={"PDF_report"}
                    label={`PDF report:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                    }}
                    optionValue={listOptionSales}
                    validator={register}
                    defaultValue={customerData && customerData?.integretiontab?.pdfReport}
                    disabled={outboundState === 0}
                    placeholder={"Select"}
                />          
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* All discrete data*/}
                <SelectBox
                    id={"all_discrete_data"}
                    name={"all_discrete_data"}
                    label={`All discrete data:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                    }}
                    optionValue={listOptionSales}
                    validator={register}
                    defaultValue={customerData && customerData?.integretiontab?.allDiscreateData}
                    disabled={outboundState === 0 ? true : makeWoundImage ? true : makeCharacterstics ? true : makeMeasurement ? true : makeTreatment ? true : false}
                    placeholder={"Select"}
                    onChange={(e) => checkAllDiscreteData(e.target.value)}
                />          
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Wound images */}
                <SelectBox
                    id={"wound_images"}
                    name={"wound_images"}
                    label={`Wound images:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                    }}
                    optionValue={listOptionSales}
                    validator={register}
                    defaultValue={customerData && customerData?.integretiontab?.woundImages}
                    disabled={outboundState === 0 ? true : makeAllDiscrete ? true : false}
                    placeholder={"Select"}
                    onChange={(e) => checkWoundImage(e.target.value)}
                />          
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Measurements */}
                <SelectBox
                    id={"measurements"}
                    name={"measurements"}
                    label={`Measurements:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                    }}
                    optionValue={listOptionSales}
                    validator={register}
                    defaultValue={customerData && customerData?.integretiontab?.measurements}
                    disabled={outboundState === 0 ? true : makeAllDiscrete ? true : false}
                    placeholder={"Select"}
                    onChange={(e) => checkMeasurements(e.target.value)}
                />          
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Characteristics */}
                <SelectBox
                    id={"characteristics"}
                    name={"characteristics"}
                    label={`Characteristics:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                    }}
                    optionValue={listOptionSales}
                    validator={register}
                    defaultValue={customerData && customerData?.integretiontab?.charateristics}
                    disabled={outboundState === 0 ? true : makeAllDiscrete ? true : false}
                    placeholder={"Select"}
                    onChange={(e) => checkCharacteristics(e.target.value)}
                />          
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Treatment Plan */}
                <SelectBox
                    id={"treatment_plan"}
                    name={"treatment_plan"}
                    label={`Treatment plan:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                    }}
                    optionValue={listOptionSales}
                    validator={register}
                    defaultValue={customerData && customerData?.integretiontab?.treatmentPlan}
                    disabled={outboundState === 0 ? true : makeAllDiscrete ? true : false}
                    placeholder={"Select"}
                    onChange={(e) => checkTreatmentPlan(e.target.value)}
                />          
                <div className={`col-6 ${styles.col_spec}`}></div>

                

                <div className={`row ${styles.form_btn}`}>
                  <div className="col-sm-12">
                    <div className="text-center pb-4">
                      <br />
                      {/* Save Button */}
                      <Button
                        classes={{
                          className: `btn ${styles.btn_custom} ${styles.btn_small} settingsSave`,
                        }}
                        type="submit"
                        id={SUBMIT_ID}
                      >
                        {SAVE_BTN}
                      </Button>

                      {/* Cancel button  */}
                      <Button
                        classes={{
                          className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}`,
                        }}
                        id={CANCEL_ID}
                        onClick={handleEdit}
                      >
                        {CANCEL_BTN}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>}

      {accessError && <AccessErrorModal />}
    </>
  );
}

Integration.propTypes = {
  customerData: PropTypes.object,
};
