import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import style from './menu.module.scss';
import LogoImage from '../../../static/images/medline-logo.svg';
import WorkList from '../../../static/images/worklist-icon.svg';
import Patients from '../../../static/images/patients-icon.svg';
import Wound from '../../../static/images/wounds-icon.svg';
import Admin from '../../../static/images/admin-icon.svg';
import BIReports from '../../../static/images/BIReports.svg';
import TreatmentOutcomes from '../../../static/images/TreatmentOutcomes.svg';
import QualityAssurance from '../../../static/images/QualityAssurance.svg';
import { Link, withRouter } from 'react-router-dom'
import { navigationChecker, primaryNavigationChecker, rbacMasterSecNavChecker } from '../../../utils/rbac'
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { INTERNAL_ADMINISTRATOR } from '../../../constants';
import CloseIcon from '../../../static/images/closeIcon.svg';
 
export let Menu = ({ location, logout, user }) => {
  
  const [path, setPath] = useState(window.location.href);
  const pathIn = window.location.href;
  const origin = window.location.origin;
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [isInternalAdmin, setInternalAdmin] = useState(false);
  const userRole = useSelector(state => state.user);
  const [count, setCount] = useState(true);
  const [getPath, setGetPath] = useState(path) 
  const isAdminView = pathIn.includes(`${origin}/admin`);
  // const usageAnalytics = pathIn.includes(`${origin}/admin/usageAnalytics/`)
  // const surveyResult = pathIn.includes(`${origin}/admin/surveyResult/`)
  const formBuilder = pathIn.includes(`${origin}/admin/formBuilder/`)
  const support = pathIn.includes(`${origin}/admin/support/`)
  const announcement = pathIn.includes(`${origin}/admin/announcement/`)
  const products = pathIn.includes(`${origin}/admin/products/`)
  const formulary = pathIn.includes(`${origin}/admin/formulary/`)
  const customers = pathIn.includes(`${origin}/admin/customers/`)
  const users = pathIn.includes(`${origin}/admin/users/`)
  const sso = pathIn.includes(`${origin}/admin/sso/`)
  const layouts = pathIn.includes(`${origin}/admin/layouts/`)
  const patients = pathIn.includes(`${origin}/admin/patients/`)  
  const platformanalytics = pathIn.includes(`${origin}/platformanalytics`)
  const patientPath = pathIn.includes(`${origin}/patients`)
  const worklistPath = pathIn.includes(`${origin}/worklist`)

  
  window.onload = function() {  
    setFadeRight(false)
    setMenuClick(false)
    setPath(window.location.href);
    setGetPath(path)
  }

  const onClickLinkNavigation = (val) => {  
    setGetPath(`${origin}/${val}`)
    setShowAdminMenu(false)    
  }

  useEffect(() => {
    setPath(window.location.href);
    setFadeRight(false)
    setMenuClick(false)
    // setGetPath(`${origin}/worklist`)
    if(path===`${origin}`){
      setGetPath(`${origin}/worklist`)
    }
    else if(path===(`${origin}/worklist`||`${origin}/patients`||`${origin}/woundReport`||`${origin}/treatmentReport`||`${origin}/qualityReport`||`${origin}/biReports`)){
      setGetPath(pathIn)
    }
    else{
      setGetPath(pathIn)
    } 
  }, [pathIn])

  useEffect(()=>{
    setFadeRight(false)
    setMenuClick(false)
    if(path===`${origin}`){
      setGetPath(`${origin}/worklist`)
    }
    else if(path===(`${origin}/worklist`||`${origin}/patients`||`${origin}/woundReport`||`${origin}/treatmentReport`||`${origin}/qualityReport`||`${origin}/biReports`)){
      setGetPath(`${origin}/worklist`)
    }
    else{
      setGetPath(pathIn)
    } 
  },[])
  
  useEffect(() => {
    setInternalAdmin(user && (user.role || '').toLowerCase() === INTERNAL_ADMINISTRATOR);
  }, [user])
  
    useEffect(() => {
      if(count||!count){
        const timeout = setTimeout(() => {
          if((!document?.querySelector('form'))|| (document?.querySelector('#modal_id')&&document?.querySelector('#search'))){
            toast.dismiss('success1')
          }
          if(document?.querySelector('.fullscreen')&&document?.querySelector('.fullscreen svg > polygon')){
            if(document?.querySelector('.main_next_clear')){
              document.querySelector('.main_next_clear').style.display = "flex";
            }
            if(document?.querySelector('.sub_next_clear')){
              document.querySelector('.sub_next_clear').style.display = "none";
            }
            let fullscreen = document.querySelector('.fullscreen');
             let btnElements = fullscreen?.querySelectorAll('button');
             btnElements?.forEach( ( item,index) => {
              if(index===0){
                item.classList.add("clear_draw")
              }else if(index===1){
                item.classList.add("next_draw")
              }
                
              } );
          }else if(document?.querySelector('.main_next_clear')){
            document.querySelector('.main_next_clear').style.display = "none";
            if(document?.querySelector('.sub_next_clear')){
              document.querySelector('.sub_next_clear').style.display = "block";
            }
          }
           if(document.getElementById('check_left_block')){
             //modalWindow
            if(document.getElementById('modal_id')&&document.getElementById('modal_id').classList.contains('opencheck')){
              document.getElementById('check_left_block').classList.add(`check_left_block_menu`)
            }else{
                document.getElementById('check_left_block').classList.remove(`check_left_block_menu`)
                if(document.getElementById('modalWindow')){
                  document.getElementById('check_left_block').classList.add(`check_left_block_menu`)
                }
            }
          }
           setCount(false);
           setCount(true);
         }, 50);
         return () => clearTimeout(timeout);
      }
     },[count]);

     //set menu div when click on menu Icon display_block
    const [menuClick, setMenuClick] = useState(false)
    useEffect(()=>{
        if(menuClick && document.getElementById('check_left_block').style.display ==='block'){
            document.getElementById('check_left_block').style.display='none';
            document.getElementById('check_left_block').style.transition = 'all 0.1s';
        }       
    },[menuClick])

  //   useEffect(() => {
  //     let menuValue = document.getElementById('menuButton')
  //         menuValue?.addEventListener('click', function () {  
  //             setMenuClick(false)
  //       });
  // });
  useEffect(() => {
    let menuValue = document.getElementById('login-btn')
        menuValue?.addEventListener('click', function () {  
            setMenuClick(false)
            document.getElementById('check_left_block').style.display='none';
    });
});

  //set FadeInleft animation
  const [fadeRight, setFadeRight] = useState(false)
 useEffect(() => {
  let menuValue = document.getElementById('menuButton')
          menuValue?.addEventListener('click', function () {  
            setFadeRight(false)
         });
  let menuCloseValue = document.getElementById('closeMenuButton')
    menuCloseValue?.addEventListener('click', function () {  
        setFadeRight(false)
     });
});   
     
  return <div>
    <div className={style.left_menu} >
      <div className={`container-fluid m-0`}>
        <div className="row">
          <div className={`${style.left_bg} ${style.left_bg}`}>          
                
                  <div 
                    className={`animate ${style.first_left_menu} ${!fadeRight ? `${style.fade_in_left_move}` : ""}`}
                  >                    
                    <div className={`d-flex justify-content-between align-items-center`}>
                      <div className={style.LogoImage_Second_slide}>
                        <img src={LogoImage} alt="Logo" className="img-fluid" />
                      </div>
                      <div 
                        className={style.CloseIcon_Second_slide}
                        id={'closeMenuButton'}
                        onClick={() => {setMenuClick(true); setShowAdminMenu(false)}}
                      >
                        <img src={CloseIcon} alt="Logo" className="img-fluid" />
                      </div>
                    </div>
                    <div className={`${style.main_list_icon}`}>
                      <ul>
                        <li className={style.admin_icon}>
                          <Link  id={"work_list"} to={(userRole?.role!=="MedlineAdministrator" || userRole?.role==="ExternalStandardUser" || userRole?.role==="ExternalAdministrator" || userRole.role === "ExternalNE1WoundExpert" || userRole.role === "ExternalNE1Review" || userRole.role === "ExternalNE1Assesment") && userRole?.customerSettings?.ne1Wms === 0 ?"/learnMore":"/worklist"} className={(getPath === `${origin}/worklist` || worklistPath) ? style.active : ''} onClick={()=>onClickLinkNavigation("worklist")}><img src={WorkList} alt="icon" />
                            <span className={style.admin_menu_text}>Work List</span></Link>
                        </li>
                        <li className={style.admin_icon}>
                          <Link id={"patients"} to={(userRole?.role!=="MedlineAdministrator" || userRole?.role==="ExternalStandardUser" || userRole?.role==="ExternalAdministrator" || userRole.role === "ExternalNE1WoundExpert" || userRole.role === "ExternalNE1Review" || userRole.role === "ExternalNE1Assesment") && userRole?.customerSettings?.ne1Wms === 0 ? "/learnMore":"/patients"}  className={(getPath === `${origin}/patients` || patientPath) ? style.active : ''} onClick={()=>onClickLinkNavigation("patients")}><img src={Patients} alt="icon" />
                            <span className={style.admin_menu_text}>Patients</span></Link>
                        </li>
                        <li className={style.admin_icon}>
                          <Link id={"wounds"} to={(userRole?.role!=="MedlineAdministrator" || userRole?.role==="ExternalStandardUser" || userRole?.role==="ExternalAdministrator" || userRole.role === "ExternalNE1WoundExpert" || userRole.role === "ExternalNE1Review" || userRole.role === "ExternalNE1Assesment") && userRole?.customerSettings?.ne1Wms === 0 ? "/learnMore":"/woundReport"}  className={getPath === `${origin}/woundReport` ? style.active : ''} onClick={()=>onClickLinkNavigation("woundReport")}><img src={Wound} alt="icon" />
                            <span className={style.admin_menu_text}>Wounds</span></Link>
                        </li>
                        <li className={style.admin_icon}>
                          <Link id={"treatment_outcomes"}to={(userRole?.role!=="MedlineAdministrator" || userRole?.role==="ExternalStandardUser" || userRole?.role==="ExternalAdministrator"  || userRole.role === "ExternalNE1WoundExpert" || userRole.role === "ExternalNE1Review" || userRole.role === "ExternalNE1Assesment") && userRole?.customerSettings?.ne1Wms === 0 ? "/learnMore":"/treatmentReport"}  className={getPath === `${origin}/treatmentReport` ? style.active : ''} onClick={()=>onClickLinkNavigation("treatmentReport")}><img src={TreatmentOutcomes} alt="icon" />
                            <span className={style.admin_menu_text}>Treatment Outcomes</span></Link>
                        </li>
                        <li className={style.admin_icon}>
                          <Link id={"quality_assurance"} to={(userRole?.role!=="MedlineAdministrator" ||userRole?.role==="ExternalStandardUser" || userRole?.role==="ExternalAdministrator" || userRole.role === "ExternalNE1WoundExpert" || userRole.role === "ExternalNE1Review" || userRole.role === "ExternalNE1Assesment") && userRole?.customerSettings?.ne1Wms === 0 ? "/learnMore":"/qualityReport"} className={getPath === `${origin}/qualityReport` ? style.active : ''} onClick={()=>onClickLinkNavigation("qualityReport")}><img src={QualityAssurance} alt="icon" />
                            <span className={style.admin_menu_text}>Quality Assurance</span></Link>
                        </li>
                        <li className={style.admin_icon}>
                          {/* <Link to="#"><img src={BIReports} alt="icon" /> */}
                          <Link id={"ad_hoc_reports"}to={(userRole?.role!=="MedlineAdministrator" || userRole?.role==="ExternalStandardUser" || userRole?.role==="ExternalAdministrator" || userRole.role === "ExternalNE1WoundExpert" || userRole.role === "ExternalNE1Review" || userRole.role === "ExternalNE1Assesment") && userRole?.customerSettings?.ne1Wms === 0 ? "/learnMore":"/biReports"} className={getPath === `${origin}/biReports` ? style.active : ''} onClick={()=>onClickLinkNavigation("biReports")}><img src={BIReports} alt="icon" />
                            <span className={style.admin_menu_text}>Ad-hoc Reports</span></Link>
                        </li>
                      </ul>
                    </div>
                    {primaryNavigationChecker('primaryNavAdmin', userRole.role) ?
                      <div className={`${style.admin_icon} ${style.main_list_icon_admin}`} 
                      onMouseEnter={() => setShowAdminMenu(true)} 
                      onMouseLeave={() => setShowAdminMenu(false)}
                      >
                        <Link to="/worklist" className={(isAdminView || platformanalytics) ? style.active : ''} onClick={()=>onClickLinkNavigation("worklist")}><img src={Admin} alt="icon" />
                          <span className={style.admin_menu_text}>Admin</span>
                          <span className='ml-auto pr-3'><i className="fa fa-chevron-right" style={{color:'#002d72', fontSize:'12px'}} aria-hidden="true"></i></span></Link>
                        <div
                          data-testid="admin-menu"
                          className={`${style.left_admin_bg} ${showAdminMenu ? style.left_admin_bg_active : ''}`}
                          onMouseEnter={() => setShowAdminMenu(true)}
                          onMouseLeave={() => setShowAdminMenu(false)}
                        >
                          <div className={style.admin_submenu}>
                            <div className={style.admin_menu}>
                              <p className={style.admin_menu_hdg}>Dashboard</p>
                              <hr className={`${style.horizontalRow} text-left`} />
                              <ul className={style.admin_menu_list}>
                                {navigationChecker('navPlatformAnalytics', userRole.role) && <li><Link to="/platformanalytics" className={getPath === `${origin}/platformanalytics` ? style.active : ''} onClick={()=>onClickLinkNavigation("platformanalytics")}>Platform Analytics</Link></li>}   

                                {userRole.role === 'ExternalAdministrator' && <li><Link to="/admin/customers" className={(getPath === `${origin}/admin/customers`|| customers) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/customers")}>Company Profile</Link></li>}                   
                                
                                {navigationChecker('navUsageAnalytics', userRole.role) && <li><Link to="/admin/usageAnalytics" className={getPath === `${origin}/admin/usageAnalytics` ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/usageAnalytics")}>Usage Analytics</Link></li>}
                                
                                {navigationChecker('navSurveyResult', userRole.role) && <li><Link to="/admin/surveyResult" className={getPath === `${origin}/admin/surveyResult` ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/surveyResult")}>Survey Results</Link></li>}

                                {navigationChecker('navFormBuilder', userRole.role) && <li><Link to="/admin/formBuilder" className={(getPath === `${origin}/admin/formBuilder` || formBuilder) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/formBuilder")}>Form Builder</Link></li>}
                              
                                {navigationChecker('navContextualSupport', userRole.role) && <li><Link to="/admin/support" className={(getPath === `${origin}/admin/support`|| support) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/support")}>Contextual support</Link></li>}

                                {navigationChecker('navInAppMessaging', userRole.role) && <li><Link to="/admin/announcement" className={(getPath === `${origin}/admin/announcement`|| announcement) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/announcement")}>In-App Messaging</Link></li>}
                              </ul>
                              <ul className={style.admin_menu_list}>
                                {/* {navigationChecker('navCompanyProfile', userRole.role) && <li><Link to="/admin/customers" className={(path === origin + '/admin/customers') ? style.active : ''}>Company Profile</Link></li>} */}
                                {/* {navigationChecker('navAnalytics', userRole.role) && <li><Link to="#">Analytics</Link></li>} */}
                              </ul>

                            </div>
                            
                            <div className={style.admin_menu}>
                            
                              {rbacMasterSecNavChecker('navMaster', userRole.role) && <>
                              <p className={style.admin_menu_hdg}>Master Data</p>  
                              <hr className={`${style.horizontalRow} text-left`} />       
                                <ul className={style.admin_menu_list}>
                                  {navigationChecker('navProducts', userRole.role) && <li><Link to="/admin/products" className={(getPath === `${origin}/admin/products`|| products) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/products")}>Products</Link></li>}
                                  {navigationChecker('navCustomers', userRole.role) && <li><Link to="/admin/customers" className={(getPath === `${origin}/admin/customers`|| customers) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/customers")}>Customers</Link></li>}
                                  {navigationChecker('navFormulary', userRole.role) && <li><Link to="/admin/formulary" className={(getPath === `${origin}/admin/formulary`|| formulary) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/formulary")}>Formulary</Link></li>}
                                </ul>
                                </>
                                }

                              {navigationChecker('navUsers', userRole.role) && <div className={style.admin_menu}>
                              <p className={style.admin_menu_hdg}>Users</p>
                              <hr className={`${style.horizontalRow} text-left`} />
                              <ul className={style.admin_menu_list}>
                                <li><Link to="/admin/users" className={(getPath === `${origin}/admin/users`|| users) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/users")}>Manage Users</Link></li>
                                {/* {isInternalAdmin && <li><Link to="#">{'Roles & Permissions'}</Link></li>} */}
                              {userRole.role === 'MedlineAdministrator' && <li><Link to="/admin/sso" className={(getPath === `${origin}/admin/sso`|| sso) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/sso")}>SSO</Link></li>}
                                
                              </ul>
                            </div>}

                            </div>                     

                            <div className={style.admin_menu}>
                              {navigationChecker('navMobolileAppMngmnt', userRole.role) && <div className={style.admin_menu}>
                                <p className={style.admin_menu_hdg}>Mobile App Mngmt</p>
                                <hr className={`${style.horizontalRow} text-left`} />
                                <ul className={style.admin_menu_list}>
                                  <li><Link to="/admin/layouts" className={(getPath === `${origin}/admin/layouts`|| layouts) ? style.active : ''} onClick={()=>onClickLinkNavigation("admin/layouts")}>Layout</Link></li>
                                </ul>
                              </div>}
                              { }
                              {navigationChecker('navNE1WMS', userRole.role) && <div className={style.admin_menu}>
                                <p className={style.admin_menu_hdg}>NE1 WMS</p>
                                <hr className={`${style.horizontalRow} text-left`} />
                                <ul className={style.admin_menu_list}>
                                  <li><Link id={"patient_management"}to={userRole?.role!=="MedlineAdministrator" && userRole?.customerSettings?.ne1Wms === 0 ? "/learnMore":"/admin/patients"}  className={(getPath === `${origin}/admin/patients` || patients) ? style.active : ''}
                                    onClick={() => { onClickLinkNavigation('admin/patients'); window.scrollTo(0, 0) }}>Patient Management</Link></li>
                                </ul>
                              </div>}
                            </div>

                          </div>
                        </div>
                      </div>
                      : ''}
                  </div>
                  




          </div>

        </div>
      </div>
    </div>

  </div>
}

Menu.propTypes = {
  logout: PropTypes.func,
};

Menu = withRouter(Menu);
