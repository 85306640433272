import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { ListView } from '../components/secondary/ListView';
import { DetailView } from '../components';
import PropTypes from 'prop-types';
import { layoutListAsync, deleteLayoutListAsync, listViewMenuAsync } from '../app/layoutsSlice';
import { setDeleteStatus } from '../app/appSlice';
import { useSelector } from 'react-redux';
import { LAYOUT, LIST_VIEW_LIMIT } from '../constants';
import { List, Search, Sorting, Pagination, Delete, SelectedRow } from '../utils'

export let Layouts = ({ dispatch, history, location }) => {
  const [offSet, setOffSet] = useState(0)
  const [searchKey, setSearchKey] = useState('')
  const [sortType, setSortType] = useState('')
  const path = window.location.href;
  const origin = window.location.origin;
  const isAdminView = path.includes(`${origin}/admin`);
  const { layoutId } = useParams();

  useEffect(() => {
    dispatch(setDeleteStatus({}))
  }, [dispatch])

  const layoutListAll = useSelector(state => state.layouts.layoutsList);
  const deleteData = useSelector(state => state.app.deleteStatus)


  useEffect(() => {
    if (deleteData.status === "success") {
      getLayoutsList();
    }
  }, [deleteData])

  /* get Layouts list */
  const getLayoutsList = () => {
    List(LAYOUT, 'Get', LIST_VIEW_LIMIT, 0, dispatch, layoutListAsync)
    dispatch(listViewMenuAsync())
  }

  useEffect(() => {
    setSortType('')
    setSearchKey('')
    getLayoutsList();
    getSelectedRowData();
  }, [dispatch]);

  /* List view table */
  let data = layoutListAll?.layouts
  let showFixedColumn = !!data && !!data.length;

  let columns = [
    {                               //added for web Layout add ID
      Header: 'ID',
      accessor: 'id',
      fixedColumn: showFixedColumn,
      fixedWidth: '130px'
    },
    {
      Header: 'Name',
      accessor: 'name',
      fixedColumn: showFixedColumn,
      fixedWidth: '300px' //360
    },
    {
      Header: 'Type',
      accessor: 'type',
      fixedColumn: showFixedColumn,
      fixedWidth: '250px' //225
    },
    {
      Header: 'Preset location',
      accessor: 'presetLocation',
      customWidth: "250px"
    },
    {
      Header: 'Assign', //Sale Office
      accessor: 'salesOffice',
      customWidth: "180px"
    }
  ]


  const handleBack = () => {
    
  }

  // details view
  const [detailsRowData, setDetailsRowData] = useState()
  const getSelectedRowData = (data) => {
    SelectedRow(data, setDetailsRowData, 'layouts', history, isAdminView)
  }

  // sorting
  const sortingList = Sorting(LAYOUT, 'Get', LIST_VIEW_LIMIT, offSet, layoutListAsync, searchKey,sortType,setSortType)


  /* search list */
  const getSearchValue = (event) => {
    
    if (event && event.keyCode === 13) {
      if (event && event.target.value !== '') {
        setOffSet(0)
        setActivePage(1)
        setSearchKey(event.target.value)
        Search(event, LAYOUT, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, layoutListAsync,sortType)
      } else {
        setOffSet(0)
        setActivePage(1)
        getLayoutsList();
        setSearchKey('')
        setSortType('')
      }
    }

  }

  // pagination
  const [activePage, setActivePage] = useState(1);
  const getActivePage = (data) => {
    setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
    Pagination(data, setActivePage, LAYOUT, 'Get', LIST_VIEW_LIMIT, dispatch, layoutListAsync, searchKey,'',sortType)
  }

  // Delete layout
  const deleteList = (data) => {
    Delete(data, dispatch, deleteLayoutListAsync, getLayoutsList)
  }

  return <div>
    {/* List view table */}
    {
      !layoutId && <ListView
        columns={columns}
        data={data}
        metaData={layoutListAll?.metadata}
        sorting={true}
        getSelectedRowData={getSelectedRowData}
        detailView={true}
        pageRangeDisplayed={5}
        rowSelect={isAdminView}
        searchPlaceholder={"Name, Type, Preset Location, Assign"} //sale office - assign 
        getSearchValue={(e) => getSearchValue(e)}
        detailsRow={!layoutId}
        activePage={activePage}
        getActivePage={getActivePage}
        listType={"Layouts"}
        subHeading={'Layout'}
        showFixedColumn={showFixedColumn}
        deleteListView={deleteList}
        listSorting={sortingList}
        searchKey={(searchKey) ? searchKey : ''}
        offset={offSet}
        sortType={sortType?sortType:''}
      />
    }

    {layoutId &&
      <DetailView
        layoutRowData={detailsRowData}
        layouts={true}
        title="Layout"
        // dispatch={dispatch}
        // defaultIndex={0}
        // detailsRow={detailsRow}
        handleBack={handleBack}
        subHeading={'Layout'}
        backLink='/admin/layouts'
        history={history}
      />
    }

  </div>
}

Layouts.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
}

Layouts = connect()(withRouter(Layouts));
