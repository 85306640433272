import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './assign.module.scss'

export const ProductInputs = ({classes, label, id, required, listData, getValue, error, defaultValue}) => {
    const [showDropDown, setShowDropDown] = useState(false)
    const [defaultValueadd, setDefaultValueadd] = useState('')
    const [listDataOptions, setListDataOptions] = useState(listData)
    const showDropDownList = () => {
        setShowDropDown(true)
    }
    useEffect(()=>{
        const defaultSelect = listData?.find((x)=>x.key === defaultValue);
        if(defaultSelect?.value !==undefined) {
            document.getElementById(id).value = defaultSelect?.value
        } else {
            document.getElementById(id).value = ''
        }
        setDefaultValueadd(defaultSelect?.value)
    },[listData, defaultValue])

    useEffect(()=>{
        setListDataOptions(listData)
    },[listData])

    const dropRef = useRef();
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const handleClickOutside = (e) => {
        if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
            setShowDropDown(false);
        }
    };

    const addValue = (key, value, id) => {
        document.getElementById(id).value = value
        setDefaultValueadd(value);
        setShowDropDown(false);
        getValue(key, value)
        setListDataOptions(listData)
    }
    
    const searchList = (e) => {
        let data = [];
        listData?.map((item) => {//NOSONAR
            const tlc = item.value.toLowerCase()
            const searchVal = e.target.value.toLowerCase()
            if(tlc.indexOf(searchVal) > -1) {
                return data = [...data, item]
            }
        })//NOSONAR
        setListDataOptions(data)
        setDefaultValueadd('')
        if(e.target.value ==='') {
            getValue('', '')
        }
    }

    return (
        <div className={`${classes?.column}`}>
            <label className={`${classes?.labelClassName}`}>
                {label}: {required ? <span>*</span> :''}
            </label>
            <div className={`${classes?.inputColumn}`}>
                <input type="text" className={`${classes?.inputClassName}`} onClick={()=>showDropDownList()} ref={dropRef} placeholder="Select" defaultValue={defaultValueadd} onKeyUp={(e)=>searchList(e)} id={id} autoComplete={'off'}/>
                <div className={`dropdown-menu ${styles.product_selector_items} ${showDropDown ? 'show':'hide'}`} ref={dropRef}>
                    <ul>
                        {
                            listDataOptions?.length > 0 ? listDataOptions?.map((item, index)=>{
                                return <li key={index} onClick={()=>addValue(item.key, item.value, id)}>{item.value}</li>
                            }) : <li>No data</li>
                        } 
                    </ul>
                </div>
                {error ? <span className={`${styles.error_message} mb-2 err-msg-product`}>{error}</span> : ''}
            </div>
        </div>
    )
}
