import React, { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { getWorkListRecordsCount,setWorkListRecordsCount } from '../../../app/patientSlice';
import { VerticalTabWorkList } from '../../secondary/VerticalTabWorkList';
import style from './workListCommon.module.scss';
import { WoundPhotosData } from './woundPhotosData';
export function WoundPhotos({facilityNumber, history }) {
  useEffect(() => {
        dispatch(setWorkListRecordsCount({}))
        dispatch(getWorkListRecordsCount(facilityNumber,'patientSubmitedValues'));
   }, [facilityNumber])
  const workListRecordsCount = useSelector(state => state.patient.workListRecordsCount);
  /* Tab list */
  const woundPhotosCount=workListRecordsCount?.patientSubmitedValues?.woundPhotosCount?`(${workListRecordsCount?.patientSubmitedValues?.woundPhotosCount})`:''
  const tabList = [
    {
      name: `Wound Photos ${woundPhotosCount}`,
     // name: <> Wound Photos <span className={style.woundListCountSpan}>{woundPhotosCount}</span></>,
      content: <WoundPhotosData facilityNumber={facilityNumber}/>,
      bottomName: 'Pending Assessment',
      bgcolor: style.wound_photo_color
    },
  ];
  const dispatch = useDispatch();
  return (
    <>
      <VerticalTabWorkList  defaultIndex={0} tabList={tabList} dispatch={dispatch} />
    </>
  )
}
