import React, { useRef, useEffect, useState } from 'react'
import {
    CUSTOMER_NAME_LBL,
    CUSTOMER_NAME_ID,
    STREET_ADD_LBL,
    STREET_ADD_ID,
    CITY_LBL,
    CITY_ID,
    PHONE_ID,
    POSTAL_CODE_ID,
    POSTAL_CODE_LBL,
    REGION_LBL,
    REGION_ID,
    ACC_GROUP_CODE_ID,
    ACC_GROUP_CODE_LBL,
    CUSTOMER_GROUP_NAME_LBL,
    CUSTOMER_GROUP_NAME_ID,
    SALES_OFFICE_DESC_LBL,
    SALES_OFFICE_DESC_ID,
    DIVISION_MANAGER_NAME_LBL,
    DIVISION_MANAGER_NAME_ID,
    WOUND_CARE_SALES_REP_NAME_LBL,
    WOUND_CARE_SALES_REP_NAME_ID,
    ABOUT_CUSTOMER_HEAD,
    ACCOUNT_GROUP_CODE,
    CUSTOMER_GROUP_NAME,
    SAVE_BTN, SUBMIT_ID, CANCEL_BTN, CANCEL_ID,
    CONTACT_INFORMATION_HEAD,
    DISTRICT_NAME_LBL,
    DISTRICT_NAME_ID,
    COUNTRY_CODE_LBL,
    COUNTRY_CODE_ID,
    COUNTRY_CODE_OPTIONS,
    SALES_OFFICE_OPTIONS_CUST,
    SESSION_TIMEOUT_OPTIONS,
    BROWSE_LBL,
    STATE_LBL, STATE_ID, US_STATES, CA_STATES
} from '../../../constants'
import { Form, TextField, Button, SelectBox } from '../../primary'
import {
    checkCustomerName,
    checkStreetName,
    checkRegion,
    checkCityName,
    checkGroup,
    selectBoxConverter,
    phoneMasker,
    checkUSPostalNumber,
    checkPostalNumber,
    checkPhoneNumberWithoutValidation,
    checkSalesOffice,
    checkSessionTime,
    checkSessionType,
    checkCustomerGroup
} from '../../../utils'
import styles from './createEditCustomer.module.scss'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import {
    listOptionSalesCustomer, listOptionCustomerGroup
} from '../../../app/customerSlice'
import { PictureIcon } from "../../secondary";
import closeIcon from "../../../static/images/close-icon-gray.png";


export function CreateEditCustomer({ handleCancel, onSubmit, customerData, externalAdmin = false, isModal = false, previewImg, setPreviewImg, buttonState, setButtonState, defaultTimeOut, defaultTimeOutType, setDefaultTimeOut, setDefaultTimeOutType, showStatus, setShowStatus }) {

        useEffect(() => {
            // dispatch(listOptionCustomerGroup(customerData?.salesOfficeDescription?.toLowerCase())) //listoption for customer group
            dispatch(listOptionCustomerGroup('customergroup')) 
            dispatch(listOptionSalesCustomer('salesoffice')) //listoption for saleoffice  
                     
        }, [])
        /* Use hook form */
        const { register, handleSubmit,reset, errors, watch, clearErrors, getValues } = useForm({
            mode: 'onBlur'
        });
        const dispatch = useDispatch()

        const listOptionSales = useSelector(state=> state.customer.listOption) //sales office dropdown value getting from API
        const customerGroupType = useSelector(state=>state.customer.customerGroupType)//customer group dropdown value getting from API

    /*************************
     * Phone number field
     *************************/
    /* Get value from phone field using useREf */
    const phoneField = useRef();
    /* If phone number is typed call the above phoneHandle function */
    if (phoneField.current) {
        if (phoneField.current.value.length > 9) {
            phoneField.current.value = phoneMasker(phoneField.current.value)
        }
    }
     
/* Set default image to a state if there is any */
  useEffect(() => {
    if (customerData?.settings?.companyLogo) {
      setButtonState(false);
      setPreviewImg(customerData?.settings?.companyLogo);
      setDefaultTimeOut(
        customerData && customerData?.settings?.sessionTimeout
          ? customerData?.settings?.sessionTimeout
          : 24
      );
      setDefaultTimeOutType(
        customerData && customerData?.settings?.sessionTimeoutType
          ? customerData?.settings?.sessionTimeoutType
          : "hours"
      );    
    }    
  }, [customerData]);


  const getSessionType = (value) => {
    if (value !== "") {
      setDefaultTimeOutType(value);
      if (document.getElementById("sessionTimeOut")&&document.getElementById("sessionTimeOut")?.value) {
      setDefaultTimeOut(document.getElementById("sessionTimeOut").value);
       // document.getElementById("sessionTimeOut").value = "";
      }
    }
    watch("sessionTimeOut");
    clearErrors(["sessionTimeOut"]);
  };


  const [emailDomains, setEmailDomains] = useState(Array.isArray(customerData?.settings?.domain)&&customerData?.settings?.domain.length>0?customerData?.settings?.domain :['']);
    //JSON.stringify(customerData?.settings?.domain)
    const handleDomainValueClick = (e) => {
      setEmailDomains([...emailDomains, [""]]);
    //!watch("emailDomains");
    clearErrors(['emailDomains']);
    };
    
    const handleRemoveEmailDomain = (index) => { //NOSONAR
    let list = [...emailDomains].slice();
    list.splice(index, 1);
    setEmailDomains(list);
    reset({...getValues(), emailDomains: list})
    //!watch("emailDomains");
    clearErrors(['emailDomains']);
    };
    const chkOldDomain = (index, e) => {
      const ele_val = e && e.target && e.target.value ? e.target.value : "";
  
  
        if (ele_val) {
         //let inputListObject = [...emailDomains];
          let againAdd = [...emailDomains];
           againAdd[index]  = ele_val !== "" ? ele_val : ``;
        //  inputListObject.splice(index, 1, againAdd);
          setEmailDomains(againAdd);
        }
  
    };

    const handleClickStatus = () => {
        if(!showStatus){
            setShowStatus(1)
        }
        else{
            setShowStatus(0)
        }
    }

    //county based states showing in Update page
    const [countryValue, setCountryValue] = useState(customerData?.address?.country)
    const [stateValue, setStateValue] = useState(customerData?.address?.state)
    const [stateChangeTrue, setStateChangeTrue] = useState(false)
    
    const onChangeCounty= (value) => {
        setStateValue([])
        setStateChangeTrue(true)
        setCountryValue(value)
    }   
  
    const onChangeState = (value) => {
        setStateValue(value)
        setStateChangeTrue(false)
    }   

    //county based states showing in creating page
    const [countryCreateValue, setCountryCreateValue] = useState()
    const [stateRef, setStateRef] = useState([])
    const [stateCreateOnchange, setStateCreateOnchange] = useState(false)
    const onChangeCountyCreate= (value) => {
        setCountryCreateValue(value)
        setStateRef([])
        setStateCreateOnchange(true)
    }
        
    const onChangeStateCreate = (value) => {
        setStateRef(value)
        setStateCreateOnchange(false)
    }
    useEffect(()=>{
        setCountryCreateValue("US")
    },[isModal])

    const resetFormVal = () => {
        setStateRef([])
        setStateCreateOnchange(true)
    }

    //Sale office based Customer Group list
    const [saleOfficeChange, setSaleOfficeChange] = useState(false)
    const [customStateRef, setCustomStateRef] = useState(customerData?.customerGroup)

    // const handleChangeSalesOffice = (value) => {
    //     dispatch(listOptionCustomerGroup(value))
    //     setSaleOfficeChange(true);
    // }
    // const handleChangeCustomerGroup = (value) => {
    //     let GetValue = value;
    //     setCustomStateRef(GetValue)
    //     setSaleOfficeChange(false);
    // }

    return (
        <>
        <Form formChanges={true} className={`${styles.patient_content}`} onSubmit={handleSubmit(onSubmit)}>
            {/* <Form className={`${styles.patient_content}`} onSubmit={handleSubmit(onSubmit)}> */}
                {!isModal &&
                <div className={`${styles.tab_content_top} col-12 py-3 d-flex align-items-center justify-content-between`}> 
                 <div className={`col-3 d-flex pl-0 ${styles.cusTogg_content}`}>
                    {/* customer number */}
                    <div className={`${styles.customer_heading} pl-0`}><h2 >{customerData?.customerNumber}</h2></div>
                    {/* vertical code */}
                    <div className={`verticalCode  ${styles.vertical_Code_role}`}></div>

                    {/* Ne1 Status */}
                    <div className={`${styles.ne1_toggleSwitch} custom-control custom-switch pl-0`}>                      
                        <h2 className={`col-10 px-0 col-form-label ${styles.label_spec}`}>
                        Status: 
                        </h2>
                        <div className={`${styles.toggle_shells}`}>                                      
                        <input 
                            type="checkbox"
                            name="isStatus" 
                            className="custom-control-input"
                            id={"isStatus"}
                            data-testid={"toggleSwitch"}
                            defaultValue = {showStatus}
                            checked = {showStatus === 1 ? true : false}                                                    
                            onChange={handleClickStatus}                          
                        />
                        <label className={`custom-control-label ${styles.toggle_switch}`} for="isStatus"></label> 
                        </div>                    
                    </div>
                  </div>
                
                  <div className='col-5 d-flex px-0 ml-0'>
                        {/* Session Timeout */}
                        <div className={`session_time col-12 d-flex px-0`} style={{float:'right'}}>                            
                            <TextField
                            id={"sessionTimeOut"}
                            name={"sessionTimeOut"}
                            label={"Session Timeout: "}
                            classes={{
                                column: `col-8 ${styles.col_spec} pr-0`,
                                labelClass: `col-7 col-form-label ${styles.label_spec} px-0`,
                                inputColumn: "col-5 p-0",
                                inputClassName: styles.basic_input,
                            }}
                            type="text"
                            validator={register(checkSessionTime)}
                            defaultValue={defaultTimeOut}
                            required={true}
                            errors={errors}
                            />
                            <SelectBox
                            id={"sessionTimeoutType"}
                            name={"sessionTimeoutType"}
                            classes={{
                                column: `col-3 ${styles.col_spec} ml-3`,
                                inputClassName: styles.basic_select,
                                inputColumn: `col-12 p-0 ${styles.select_wrapper}`,
                            }}
                            optionValue={SESSION_TIMEOUT_OPTIONS}
                            validator={register(checkSessionType)}
                            defaultValue={defaultTimeOutType}
                            errors={errors}
                            required={true}
                            onChange={(e) => getSessionType(e.target.value)}
                            />
                        </div>                        

                    </div>


                    <div className={`col-2 px-0`}>
                        {/* Logo */}
                        <div className={`col-12 px-0 d-flex align-items-center ${styles.comp_log}`}>
                            {/* Label */}
                                <label
                                className={`col-4 col-form-label ${styles.label_spec} ${styles.label_spec_loglable} pl-0`}
                                >
                                    Logo:
                                </label>
                                {/* Picture icon component  */}
                                <PictureIcon
                                previewImg={previewImg} // Default preview image if there is any
                                setPreviewImg={setPreviewImg} // Function to set above state
                                buttonState={buttonState} // State for hinding and showing "browse" button
                                setButtonState={setButtonState} // Function to set above state
                                validator={register}
                                id={"companyLogo"} // ID and name
                                name={"companyLogo"}
                                browseLbl={BROWSE_LBL} // Label for browse button
                                />
                        </div>
                    </div>

                </div>}

                {/* Heading */}
                <div className={`col-sm-12 ${styles.col_spec}`}>
                    <p className={`col-12 ${styles.subheading_2}`}>{"General Data"}</p>
                </div>
                
                {isModal && 
                    <>
                        <TextField
                            id={CUSTOMER_NAME_ID}
                            name={CUSTOMER_NAME_ID}
                            label={`${CUSTOMER_NAME_LBL}:`}
                            classes={{
                                column: `col-8 ${styles.col_spec}`,
                                labelClass: `col-4 col-form-label ${styles.label_spec} ${styles.label_spec_modal}`,
                                inputColumn: `col-8 p-0 ${styles.input_column_csName}`,
                                inputClassName: styles.basic_input
                            }}
                            type="text"
                            validator={register(checkCustomerName)}
                            // defaultValue={customerData && customerData?.customerName}
                            errors={errors}
                            required={true}
                        />

                        {/* Phone */}
                        <TextField
                            id={PHONE_ID}
                            name={PHONE_ID}
                            label={`Phone:`}
                            classes={{
                                column: `col-4 ${styles.col_spec}`,
                                labelClass: `col-4 col-form-label ${styles.label_spec} ${styles.label_spec_modal}`,
                                inputColumn: 'col-8 p-0', inputClassName: styles.basic_input
                            }}
                            type="text"
                            validator={(e) => {
                                register(e, checkPhoneNumberWithoutValidation)
                                phoneField.current = e
                            }}
                            // defaultValue={customerData && customerData?.phone}
                            errors={errors}
                        />

                        <SelectBox
                            id={ACC_GROUP_CODE_ID}
                            name={ACC_GROUP_CODE_ID}
                            label={`${ACC_GROUP_CODE_LBL}:`}
                            classes={{
                                column: `col-6 ${styles.col_spec}`,
                                inputClassName: styles.basic_select,
                                labelClassName: `col-5 col-form-label mr-1 ${styles.label_spec} ${styles.label_spec_modal}`,
                                inputColumn: `col-6 p-0 ml-2 ${styles.select_wrapper}`
                            }}
                            optionValue={ACCOUNT_GROUP_CODE}
                            validator={register(checkGroup)}
                            // defaultValue={customerData && customerData.accountGroup}
                            errors={errors}
                            disabled={!isModal}
                            required={true}
                        />
                    

                    {/* Sales Office*/}
                    <SelectBox
                        id={SALES_OFFICE_DESC_ID}
                        name={SALES_OFFICE_DESC_ID}
                        // label={`${SALES_OFFICE_DESC_LBL}:`}
                        label={`Sales Office:`}
                        classes={{
                            column: `col-6 ${styles.col_spec}`,
                            inputClassName: styles.basic_select,
                            labelClassName: `col-5 col-form-label ${styles.label_spec} ${styles.label_spec_modal}`,
                            inputColumn: `col-7 p-0 ${styles.select_wrapper}`
                        }}
                        optionValue={listOptionSales}
                        validator={register(checkSalesOffice)}
                        // defaultValue={customerData && customerData?.salesOfficeDescription}
                        placeholder={"Select"}
                        required = {true}
                        errors = {errors}
                    />            

                    

                    <div className={`col-12 verticalCode px-0 ${styles.vertical_Code}`}></div>

                    {/* Country Code */}
                    <SelectBox
                        id={COUNTRY_CODE_ID}
                        name={COUNTRY_CODE_ID}
                        // label={`${COUNTRY_CODE_LBL} :`}
                        label={`Country:`}
                        classes={{
                            column: `col-12 ${styles.col_spec}`,
                            inputClassName: styles.basic_select,
                            labelClassName: `col-2 col-form-label ${styles.label_spec} ${styles.label_spec_modal}`,
                            inputColumn: `col-4 p-0 ml-5 ${styles.select_wrapper}`
                        }}
                        optionValue={COUNTRY_CODE_OPTIONS}
                        validator={register}
                        defaultValue='US'
                        onChange={(e)=>onChangeCountyCreate(e.target.value)}
                    />

                    {/* Street Add */}
                    <TextField
                        id={STREET_ADD_ID}
                        name={STREET_ADD_ID}
                        label={`Address:`}
                        classes={{
                            column: `col-12 ${styles.col_spec}`,
                            labelClass: `col-2 col-form-label ${styles.label_spec} ${styles.label_spec_modal}`,
                            inputColumn: 'col-10 p-0 pl-5',
                            inputClassName: styles.basic_input
                        }}
                        type="text"
                        // validator={register(checkStreetName)}
                        validator={register}
                        // defaultValue={customerData && customerData?.address?.addressLine1}
                        errors={errors}
                        required={false}
                    />

                    {/* Postal code */}
                    <TextField
                        id={POSTAL_CODE_ID}
                        name={POSTAL_CODE_ID}
                        label={`${POSTAL_CODE_LBL}:`}
                        classes={{
                            column: `col-4 ${styles.col_spec}`,
                            labelClass: `col-8 col-form-label ${styles.label_spec} ${styles.label_spec_modal}`,
                            inputColumn: 'col-4 p-0', inputClassName: styles.basic_input
                        }}
                        type="text"
                        // validator={register(checkPostalNumber)}
                        validator={register}
                        // defaultValue={customerData && customerData.address?.zipCode}
                        errors={errors}
                        required={false}
                    /> 

                    {/* City */}
                    <TextField
                        id={CITY_ID}
                        name={CITY_ID}
                        label={`${CITY_LBL}:`}
                        classes={{
                            column: `col-4 ${styles.col_spec}`,
                            labelClass: `col-3 col-form-label ${styles.label_spec} ${styles.label_spec_modal}`,
                            inputColumn: 'col-9 p-0', inputClassName: styles.basic_input
                        }}
                        type="text"
                        // validator={register(checkCityName)}
                        validator={register}
                        errors={errors}
                        // defaultValue={customerData && customerData?.address?.city}
                        required={false}
                    />

                    {stateCreateOnchange && <SelectBox
                        id={STATE_ID}
                        name={STATE_ID}
                        label={`${STATE_LBL}:`}
                        classes={{
                            column: `col-4 ${styles.col_spec}`,
                            inputClassName: styles.basic_select,
                            labelClassName: `col-3 col-form-label ${styles.label_spec} ${styles.label_spec_modal}`,
                            inputColumn: `col-9 p-0 ${styles.select_wrapper}`
                        }}
                        // optionValue={selectBoxConverter(countryCreateValue==="US" ? US_STATES : CA_STATES)}
                        optionValue={countryCreateValue==="US" ? US_STATES : CA_STATES}
                        validator={register}
                        onChange={(e)=>onChangeStateCreate(e.target.value)}
                        // defaultValue={stateRef}
                    />}
                    {!stateCreateOnchange && <SelectBox
                        id={STATE_ID}
                        name={STATE_ID}
                        label={`${STATE_LBL}:`}
                        classes={{
                            column: `col-4 ${styles.col_spec}`,
                            inputClassName: styles.basic_select,
                            labelClassName: `col-3 col-form-label ${styles.label_spec} ${styles.label_spec_modal}`,
                            inputColumn: `col-9 p-0 ${styles.select_wrapper}`
                        }}
                        // optionValue={selectBoxConverter(countryCreateValue==="US" ? US_STATES : CA_STATES)}
                        optionValue={countryCreateValue==="US" ? US_STATES : CA_STATES}
                        validator={register}
                        onChange={(e)=>onChangeStateCreate(e.target.value)}
                        defaultValue={stateRef}
                    />}
                </>
                }

                {/* Customer Number */}
                {!isModal && (!externalAdmin || externalAdmin) ?
                    <TextField
                        id={"customerNumber"}
                        name={"customerNumber"}
                        label={"Customer Number:"}
                        classes={{
                            column: `col-6 ${styles.col_spec}`,
                            labelClass: `col-6 col-form-label ${styles.label_spec}`,
                            inputColumn: 'col-6 p-0',
                            inputClassName: `${styles.basic_input} ${(!isModal) ? styles.disabled : ''}`
                        }}
                        type="text"
                        validator={register}
                        defaultValue={customerData && customerData?.customerNumber}
                        errors={errors}
                        disabled={!isModal}
                        required={true}
                    />
                    :
                    null
                }

                {!isModal && 
                
                <TextField
                    id={CUSTOMER_NAME_ID}
                    name={CUSTOMER_NAME_ID}
                    label={`${CUSTOMER_NAME_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(checkCustomerName)}
                    defaultValue={customerData && customerData?.customerName}
                    errors={errors}
                    required={true}

                />}                

                {!isModal && <TextField
                    id={STREET_ADD_ID}
                    name={STREET_ADD_ID}
                    label={`Address:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    // validator={register(checkStreetName)}
                    validator={register}
                    defaultValue={customerData && customerData?.address?.addressLine1}
                    errors={errors}
                    required={false}
                />}                

                {!isModal && <TextField
                    id={CITY_ID}
                    name={CITY_ID}
                    label={`${CITY_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0', inputClassName: styles.basic_input
                    }}
                    type="text"
                    // validator={register(checkCityName)}
                    validator={register}
                    errors={errors}
                    defaultValue={customerData && customerData?.address?.city}
                    required={false}
                />}

                {/* State */}
                {!isModal && !stateChangeTrue && <SelectBox
                    id={STATE_ID}
                    name={STATE_ID}
                    label={`${STATE_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    // optionValue={selectBoxConverter(countryValue==="US" ? US_STATES : CA_STATES)}
                    optionValue={countryValue==="US" ? US_STATES : CA_STATES}
                    validator={register}
                    onChange={(e)=>onChangeState(e.target.value)}
                    // defaultValue={Array.isArray(stateValue) && stateValue.length === 0  ? 'se' : stateValue}
                    defaultValue={stateValue}
                />}
                {!isModal && stateChangeTrue && <SelectBox
                    id={STATE_ID}
                    name={STATE_ID}
                    label={`${STATE_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    // optionValue={selectBoxConverter(countryValue==="US" ? US_STATES : CA_STATES)}
                    optionValue={countryValue==="US" ? US_STATES : CA_STATES}
                    validator={register}
                    onChange={(e)=>onChangeState(e.target.value)}
                    // defaultValue={Array.isArray(stateValue) && stateValue.length === 0  ? 'se' : stateValue}
                    // defaultValue={stateValue}
                />}
                            
                {/* Postal code */}
                {!isModal && <TextField
                    id={POSTAL_CODE_ID}
                    name={POSTAL_CODE_ID}
                    label={`${POSTAL_CODE_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0', inputClassName: styles.basic_input
                    }}
                    type="text"
                    // validator={register(checkPostalNumber)}
                    validator={register}
                    defaultValue={customerData && customerData.address?.zipCode}
                    errors={errors}
                    required={false}
                />}

                 {/* Phone */}
                 {!isModal && <TextField
                    id={PHONE_ID}
                    name={PHONE_ID}
                    label={`Phone:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0', inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={(e) => {
                        register(e, checkPhoneNumberWithoutValidation)
                        phoneField.current = e
                    }}
                    defaultValue={customerData && customerData?.phone}
                    errors={errors}
                />}
                
                {/* District */}
                {/* <TextField
                    id={DISTRICT_NAME_ID}
                    name={DISTRICT_NAME_ID}
                    label={`${DISTRICT_NAME_LBL} :`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0', inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register}
                    defaultValue={customerData && customerData?.address?.district}
                /> */}

                {/* Country Code */}
                {!isModal && <SelectBox
                    id={COUNTRY_CODE_ID}
                    name={COUNTRY_CODE_ID}
                    // label={`${COUNTRY_CODE_LBL} :`}
                    label={`Country:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={COUNTRY_CODE_OPTIONS}
                    validator={register}
                    // defaultValue='US'
                    onChange={(e)=>onChangeCounty(e.target.value)}
                    defaultValue ={customerData && customerData?.address?.country}
                />}

                {/* Email Domain */}
                {!isModal && 
                <div className={`row col-12`}>
                    <div className={`row col-9`}>
                    <div className={`col-4`}>
                    <label
                        className={`col-form-label ${styles.label_spec}`}
                        >
                        Domain:<span> *</span>
                        </label>
                        </div>
                        {
                         Array.isArray(
                                    emailDomains
                                ) &&
                                emailDomains?.map(
                                    (email, i) => {
                                    return (
                                        <>
                                {i != 0 && <div className="col-4"></div>}         
                    <div className={`col-7  ml-n4 mr-n4 mb-2`}>
    
                    <TextField
                    id={`emailDomains${i}`}
                    name={`emailDomains[${i}]`}
                    label={""}
                    classes={{
                        column: `col-9 ml-n2`,
                        labelClass: ``,
                        inputColumn: "col-12 ml-n1",
                        inputClassName: styles.basic_input,
                    }}
                    type="text"
                    //validator={register(checkDomain)}
                    onChange={(e) => chkOldDomain(i, e)}
                    onBlur={(e) => chkOldDomain(i, e)}
                    //onClick={(e) => chkOldDomain(i, e)}
                    validator={register({
                    //  disabled: false,
                    // setValueAs: (value) => (value),
                        validate: (value) => {
                        if (!value) {
                            return "Domain is required";
                        } 
                        else if (                   
                    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/.test(value)===false
                        ) {
                            return 'Enter a valid domain address';
                        }
                        else if (
                            emailDomains &&
                            emailDomains.length > 0
                        ) {
                            let chckSeq = 0;
                            emailDomains.forEach((item, keys) => {
                    
                            if (
                                i !== keys &&
                                item === value
                            ) {
                                chckSeq = 1;
                            }
                            });
                            if (chckSeq === 1) {
                            return 'Domain already exist';
                            }
                        }
                        },
                    },i)}
                    errors={errors}
                    defaultValue={
                        email &&
                        email !== null &&
                        email !== "null"
                        ? email
                        : ""
                    }
                    //readOnly={( errors["emailDomains"] &&
                    //errors["emailDomains"][i] &&
                    //  errors["emailDomains"][i].message&&i===0)?false:(Array.isArray(customerData?.settings?.domain)&&customerData?.settings?.domain.length>0&&i===0)?true:false}
                    
                    required={true}
                    />


    {errors &&
                                            errors["emailDomains"] &&
                                            errors["emailDomains"][i] &&
                                            errors["emailDomains"][i].message && (
                                                <span
                                                className={`${styles.error_message} ml-3`}
                                                >
                                                {
                                                errors["emailDomains"][i].message 
                                                }
                                                </span>
                                            )}
            
                    </div>
                    {i!=0&&<div className={`col-1 d-flex ml-n3`}>
                                        <img
                                            onClick={() => handleRemoveEmailDomain(i)}
                                            className={`${styles.deleteDomain} delete_specification align-content-center flex-wrap `}
                                            src={closeIcon}
                                            alt=""
                                        />
                    </div>}
                                        </>
                                    );
                                    }
                                )}
    

                    <div className={`col-8 d-table mt-1 mb-3 ml-4`}>
                    <button
                                    id={"addDomain"}
                                    type={'button'}
                                    className={` ${styles.addValBtn}`}
                                    onClick={(e) =>
                                    handleDomainValueClick(e)
                                    }
                                >
                                    + Add domain
                                </button>
                    </div>                
                
                    </div>
                    <div className={`col-3`}></div>
                </div>
                }
                 
                {!isModal &&

                    <>
                        {/* *************************** */}
                        {/* About  Customer section */}
                        {/* *************************** */}

                        {/* Heading */}
                        <div className={`col-sm-12 ${styles.col_spec}`}>
                            {/* <p className={`col-12 ${styles.subheading_2}`}>{ABOUT_CUSTOMER_HEAD}</p> */}
                            <p className={`col-12 ${styles.subheading_2}`}>{"Sales Area"}</p>
                        </div>

                        {externalAdmin ?
                            <>
                               
                               {/* Account Group code */}
                               <SelectBox
                                    id={ACC_GROUP_CODE_ID}
                                    name={ACC_GROUP_CODE_ID}
                                    label={`${ACC_GROUP_CODE_LBL}:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                                    }}
                                    optionValue={ACCOUNT_GROUP_CODE}
                                    validator={register(checkGroup)}
                                    errors={errors}
                                    disabled={true}
                                    defaultValue={customerData && customerData?.accountGroup}
                                    required={true}
                                    
                                />
                                <div className='col-6'></div>

                                {/* Sales Office */}
                                <TextField
                                    id={SALES_OFFICE_DESC_ID}
                                    name={SALES_OFFICE_DESC_ID}
                                    // label={`${SALES_OFFICE_DESC_LBL}:`}
                                    label={`Sales Office:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: 'col-6 p-0',
                                        inputClassName: `${styles.basic_input} ${styles.disabled}`
                                    }}
                                    type="text"
                                    validator={register}
                                    defaultValue={customerData && customerData?.salesOfficeDescription}
                                    disabled={true}
                                    // onChange={(e)=>handleChangeSalesOffice(e.target.value)}
                                />
                               
                               {/* Customer Group Name */}
                               <SelectBox
                                    id={CUSTOMER_GROUP_NAME_ID}
                                    name={CUSTOMER_GROUP_NAME_ID}
                                    // label={`${CUSTOMER_GROUP_NAME_LBL}:`}
                                    label={`Customer Group:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                                    }}
                                    optionValue={customerGroupType}
                                    validator={register}
                                    defaultValue={customerData && customerData?.customerGroup}
                                    disabled={true}
                                    placeholder={"Select"}
                                    required={true}
                                    // onChange={(e)=>handleChangeCustomerGroup(e.target.value)}
                                />


                                {/* {saleOfficeChange && 
                                <SelectBox
                                    id={CUSTOMER_GROUP_NAME_ID}
                                    name={CUSTOMER_GROUP_NAME_ID}
                                    // label={`${CUSTOMER_GROUP_NAME_LBL}:`}
                                    label={`Customer Group:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                                    }}
                                    // optionValue={CUSTOMER_GROUP_NAME}
                                    optionValue={customerGroupType}
                                    validator={register}
                                    // defaultValue={customerData && customerData?.customerGroup}
                                    disabled={true}
                                    placeholder={"Select"}
                                    required={true}
                                    onChange={(e)=>handleChangeCustomerGroup(e.target.value)}
                                />}
                                {!saleOfficeChange && 
                                <SelectBox
                                    id={CUSTOMER_GROUP_NAME_ID}
                                    name={CUSTOMER_GROUP_NAME_ID}
                                    // label={`${CUSTOMER_GROUP_NAME_LBL}:`}
                                    label={`Customer Group:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-6 p-0 ${styles.select_wrapper} ${styles.disabled}`
                                    }}
                                    // optionValue={CUSTOMER_GROUP_NAME}
                                    optionValue={customerGroupType}
                                    validator={register}
                                    defaultValue={customStateRef}
                                    disabled={true}
                                    placeholder={"Select"}
                                    required={true}
                                    onChange={(e)=>handleChangeCustomerGroup(e.target.value)}
                                />}                                 */}

                                
                                {/* Wound Care Sales*/}
                                <TextField
                                    id={WOUND_CARE_SALES_REP_NAME_ID}
                                    name={WOUND_CARE_SALES_REP_NAME_ID}
                                    // label={`${WOUND_CARE_SALES_REP_NAME_LBL}:`}
                                    label={`Wound Care Sales Rep:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: 'col-6 p-0', inputClassName: `${styles.basic_input} ${styles.disabled}`
                                    }}
                                    type="text"
                                    validator={register}
                                    defaultValue={customerData && customerData?.woundCareSalesRepName}
                                    disabled={true}
                                />

                                {/* Division Manager*/}
                                <TextField
                                    id={DIVISION_MANAGER_NAME_ID}
                                    name={DIVISION_MANAGER_NAME_ID}
                                    // label={`${DIVISION_MANAGER_NAME_LBL}:`}
                                    label={`Division Manager:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: 'col-6 p-0',
                                        inputClassName: `${styles.basic_input} ${styles.disabled}`
                                    }}

                                    type="text"
                                    validator={register}
                                    defaultValue={customerData && customerData?.divisionManagerName}
                                    disabled={true}
                                />
                                
                            </>
                            :
                            <>
                                {/* Account Group code */}
                                <SelectBox
                                    id={ACC_GROUP_CODE_ID}
                                    name={ACC_GROUP_CODE_ID}
                                    label={`${ACC_GROUP_CODE_LBL}:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec} pb-2`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                                    }}
                                    optionValue={ACCOUNT_GROUP_CODE}
                                    validator={register(checkGroup)}
                                    defaultValue={customerData && customerData?.accountGroup}
                                    errors={errors}
                                    disabled={!isModal}
                                    required={true}
                                />
                                <div className='col-6'></div>

                                {/* Sales Office */}
                                <SelectBox
                                    id={SALES_OFFICE_DESC_ID}
                                    name={SALES_OFFICE_DESC_ID}
                                    // label={`${SALES_OFFICE_DESC_LBL}:`}
                                    label={`Sales Office:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec} mb-4`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-6 p-0 mb-2 ${styles.select_wrapper}`
                                    }}
                                    optionValue={listOptionSales}
                                    validator={register(checkSalesOffice)}
                                    defaultValue={customerData && customerData?.salesOfficeDescription}
                                    placeholder={"Select"}
                                    required = {true}
                                    errors = {errors}
                                    // onChange={(e)=>handleChangeSalesOffice(e.target.value)}
                                />

                                {/* Customer Group Name */}
                                <SelectBox
                                    id={CUSTOMER_GROUP_NAME_ID}
                                    name={CUSTOMER_GROUP_NAME_ID}
                                    // label={`${CUSTOMER_GROUP_NAME_LBL}:`}
                                    label={`Customer Group:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                                    }}
                                    // optionValue={CUSTOMER_GROUP_NAME}
                                    optionValue={customerGroupType}
                                    validator={register(checkCustomerGroup)}
                                    defaultValue={customerData && customerData?.customerGroup}
                                    placeholder={"Select"}
                                    required={true}
                                    errors = {errors}  
                                    // onChange={(e)=>handleChangeCustomerGroup(e.target.value)}                                  
                                />

                                {/* {saleOfficeChange && 
                                <SelectBox
                                    id={CUSTOMER_GROUP_NAME_ID}
                                    name={CUSTOMER_GROUP_NAME_ID}
                                    // label={`${CUSTOMER_GROUP_NAME_LBL}:`}
                                    label={`Customer Group:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                                    }}
                                    // optionValue={CUSTOMER_GROUP_NAME}
                                    optionValue={customerGroupType}
                                    validator={register(checkCustomerGroup)}
                                    // defaultValue={customerData && customerData?.customerGroup}
                                    placeholder={"Select"}
                                    required={true}
                                    errors = {errors}   
                                    onChange={(e)=>handleChangeCustomerGroup(e.target.value)}                                 
                                />}
                                {!saleOfficeChange && 
                                <SelectBox
                                    id={CUSTOMER_GROUP_NAME_ID}
                                    name={CUSTOMER_GROUP_NAME_ID}
                                    // label={`${CUSTOMER_GROUP_NAME_LBL}:`}
                                    label={`Customer Group:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                                    }}
                                    // optionValue={CUSTOMER_GROUP_NAME}
                                    optionValue={customerGroupType}
                                    validator={register(checkCustomerGroup)}
                                    defaultValue={customStateRef}
                                    placeholder={"Select"}
                                    required={true}
                                    errors = {errors}  
                                    onChange={(e)=>handleChangeCustomerGroup(e.target.value)}                                  
                                />} */}
                                

                                {/* Wound Care Sales*/}
                                <TextField
                                    id={WOUND_CARE_SALES_REP_NAME_ID}
                                    name={WOUND_CARE_SALES_REP_NAME_ID}
                                    // label={`${WOUND_CARE_SALES_REP_NAME_LBL}:`}
                                    label={`Wound Care Sales Rep:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: 'col-6 p-0', inputClassName: styles.basic_input
                                    }}
                                    type="text"
                                    validator={register}
                                    defaultValue={customerData && customerData?.woundCareSalesRepName}
                                />
                                

                                {/* Division Manager*/}
                                <TextField
                                    id={DIVISION_MANAGER_NAME_ID}
                                    name={DIVISION_MANAGER_NAME_ID}
                                    // label={`${DIVISION_MANAGER_NAME_LBL}:`}
                                    label={`Division Manager:`}
                                    classes={{
                                        column: `col-6 ${styles.col_spec}`,
                                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                                        inputColumn: 'col-6 p-0', inputClassName: styles.basic_input
                                    }}
                                    type="text"
                                    validator={register}
                                    defaultValue={customerData && customerData?.divisionManagerName}
                                />                               
                                
                            </>
                        }

                    </>
                }


                <div className="col-sm-12 pt-4">
                    <div className="text-center pb-4">
                        {/* Save Button */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                            type="submit"
                            id={SUBMIT_ID}
                        >
                            {SAVE_BTN}
                        </Button>
                        {/* Cancel button  */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={(e)=>{handleCancel(e,reset); resetFormVal()}}
                        >
                            {CANCEL_BTN}
                        </Button>


                    </div>
                </div>

            </Form>
        </>
    )
}

CreateEditCustomer.propTypes = {
    register: PropTypes.func,
    handleSubmit: PropTypes.func,
    control: PropTypes.object,
    handleCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    customerData: PropTypes.object,
    externalAdmin: PropTypes.bool
}
