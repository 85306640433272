import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
//import { format, differenceInSeconds, addMinutes } from "date-fns";
import {
  logout,
  continueSessionTimeoutAsyn,
  setSessionTimeOutData,
  setSessionTempTime,
  getSessionTimeOutAsyn
} from "./app/userSlice";
import { Button } from "./components/primary";
import { Modal } from "./components/secondary";
import style from "./components/core/Menu/menu.module.scss";
import continueSignout from "./static/images/continue-signout.png";

export let RenderSession = ({
  user,
  dispatch,
  resetSession,
  setResetSession,
}) => {
  const [sessionMsgModal, setSessionMsgModal] = useState(false);

  const modalOpenSession = () => {
    setSessionMsgModal(true);
  };

  const modalCloseSession = () => {
    setSessionMsgModal(false);
  };

  const [sessionMsgOutModal, setSessionMsgOutModal] = useState(false);

  const modalOpenSessionOut = () => {
    setSessionMsgOutModal(true);
  };

  const modalCloseSessionOut = () => {
    setSessionMsgOutModal(false);
    dispatch(setSessionTempTime(""));
    dispatch(logout());
  };
  const sessionTimeOutData = useSelector(
    (state) => state.user.sessionTimeOutData
  );
  const sessionTempTime = useSelector((state) => state.user.sessionTempTime);
  /*let sessionTimeOutData = {
    lastLogOn: "2023-08-04T07:03:42.6229953Z",
    sessionTimeout: 3,
    sessionTimeoutType: "minutes",
    dynTime: 15,
  };*/
  //   console.log('sessionTimeOutData',sessionTimeOutData)

  let chckFinalMinutes =
    sessionTimeOutData?.sessionTimeoutType === "hours" &&
    sessionTimeOutData?.sessionTimeout
      ? sessionTimeOutData?.sessionTimeout * 60
      : sessionTimeOutData?.sessionTimeoutType === "minutes" &&
        sessionTimeOutData?.sessionTimeout
      ? sessionTimeOutData?.sessionTimeout
      : 15 * 60; //15 minutes

  /* const diffTime = differenceInSeconds(new Date(), new Date(sessionTimeOutData?.lastLogOn));
   console.log('differenceInSeconds pos', differenceInSeconds(new Date(),new Date(sessionTimeOutData?.lastLogOn)))
   console.log('lastLogOn',format(new Date(sessionTimeOutData?.lastLogOn), "MMMM dd, yyyy hh:mm:ss"))
   let diffTimeNum =  Math.abs(diffTime);
   console.log('diffTime',diffTimeNum)
   const expireDateFormat=format(new Date('2023-08-09T21:19:51.6229953Z'), "MMMM dd, yyyy hh:mm:ss")
console.log('expireDateFormat',expireDateFormat)
let expireDate = new Date(addMinutes(new Date(expireDateFormat), 30)).getTime();
console.log('expireDate getTimezoneOffset',expireDate)
//console.log('new Date()',new Date().getTimezoneOffset())
console.log('new expireDate()',expireDate)
//console.log('countdownTimer',countdownTimer({expireDate}))
   */
  //showNav
  const [finalMinutes, setFinalMinutes] = useState(
    sessionTempTime ? sessionTempTime : sessionTimeOutData?.dynTime
  );
  const Ref = useRef(null);
  // The state for our timer
  let initTimer = {
    total: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    finalForm: "00:00:00",
  };
  const [timer, setTimer] = useState(initTimer);
  
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      let initTimer = {
        total: total,
        hours: hours > 9 ? hours : 0 + hours,
        minutes: minutes > 9 ? minutes : 0 + minutes,
        seconds: seconds > 9 ? seconds : "0" + seconds,
        finalForm:
          (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds),
      };
      setTimer(initTimer);
      /*setTimer(
				(hours > 9 ? hours : '0' + hours) + ':' +
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)*/
    }
  };

  const clearTimer = (e) => {
    //setTimer('00:00:10');
    let initTimer = {
      total: 0,
      hours: 0,
      minutes: finalMinutes,
      seconds: 0,
      finalForm: "00:" + finalMinutes + ":00",
    };
    setTimer(initTimer);
    if (Ref.current) 
    clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = (time) => {
    let deadline = new Date();
    //deadline.setSeconds(deadline.getSeconds() + 10);
    let convertSec = time * 60;
    deadline.setSeconds(deadline.getSeconds() + convertSec);
    return deadline;
  };
  useEffect(() => {
    if(user?.userDetails?.email){
      dispatch(getSessionTimeOutAsyn(user?.userDetails?.email));
    }
  }, []);
  useEffect(() => {
    if (sessionTimeOutData?.dynTime) {
      //
      setFinalMinutes(
        sessionTempTime ? sessionTempTime : sessionTimeOutData?.dynTime
      );
      clearTimer(
        getDeadTime(
          sessionTempTime ? sessionTempTime : sessionTimeOutData?.dynTime
        )
      );
    }
  }, [sessionTimeOutData]);

  const onClickReset = () => {
    clearTimer(
      getDeadTime(
        sessionTimeOutData?.dynTime ? sessionTimeOutData?.dynTime : 15* 60
      )
    );
  };

  useEffect(() => {
    if (timer?.minutes > 0) {
      dispatch(setSessionTempTime(timer?.minutes > 0 ? timer?.minutes : 2));
    }

    if (
      (timer?.minutes > 0 && timer?.minutes < 2) ||
      (timer?.total < 119000 && timer?.total !== 0)
    ) {
    //  console.log("countDown less 2", timer);
      setSessionMsgOutModal(false);
      setSessionMsgModal(true);
    } else if (
      timer &&
      timer?.minutes <= 0 &&
      timer?.seconds !== 0 &&
      timer?.seconds <= 1
    ) {
      setSessionMsgModal(false);
      setSessionMsgOutModal(true);
    //  console.log("countDown expire", timer);

      /*setInterval(() => {
        setSessionMsgModal(false);
        setSessionMsgOutModal(false);
        dispatch(setSessionTempTime(""));
        clearTimer(0)
        setTimer(initTimer)
        clearInterval(0);
        dispatch(logout());
      }, 10000);*/
    } else {
      setSessionMsgOutModal(false);
      setSessionMsgModal(false);
    }
  }, [timer]);
  const doSessionContinue = () => {
   /* let chckFinalMinutes =
    sessionTimeOutData?.sessionTimeoutType === "hours" 
      ? sessionTimeOutData?.sessionTimeout * 60
      : sessionTimeOutData?.sessionTimeoutType === "minutes"
      ? sessionTimeOutData?.sessionTimeout
      : sessionTimeOutData?.sessionTimeoutType === "days"
      ?sessionTimeOutData?.sessionTimeout *1000 * 60 * 60 * 24
      : sessionTimeOutData?.sessionTimeoutType === "weeks"
      ?sessionTimeOutData?.sessionTimeout *1000 * 60 * 60 * 24
      : sessionTimeOutData?.sessionTimeoutType === "months"
      ?sessionTimeOutData?.sessionTimeout *1000 * 60 * 60 * 24
      : 15 * 60; //15 minutes */
    let chckFinalMinutes =sessionTimeOutData?.sessionTimeout?sessionTimeOutData?.sessionTimeout:15 * 60; //15 minutes*/
    let sessData = {
      lastLogOn: sessionTimeOutData?.lastLogOn,
      sessionTimeout: sessionTimeOutData?.sessionTimeout,
      sessionTimeoutType: sessionTimeOutData?.sessionTimeoutType,
      dynTime: chckFinalMinutes,
    };
    setSessionMsgModal(false);
    setSessionMsgOutModal(false);
    if(user?.userDetails?.email){
      dispatch(continueSessionTimeoutAsyn(user?.userDetails?.email));
    }
    onClickReset();
  };
  const doLogout = () => {
    setSessionMsgModal(false);
    setSessionMsgOutModal(false);
    dispatch(logout());
  };
  return (
    <>
      <div>
     <p className={'d-none'}>{timer? JSON.stringify(timer):''}</p>
       <input type="hidden" className={'finaltime'} value={timer? JSON.stringify(timer):''}/>
        {sessionMsgModal && timer?.total < 119000 && timer?.total !== 0 && (
          <Modal
            modalOpen={modalOpenSession}
            modalClose={modalCloseSession}
            heading={"Session Timeout"}
            className={"modal_box"}
            modalSize={style.custom_session_modal_size}
            custom_heading={style.custom_heading}
            custom_body={style.custom_body}
            custom_content={style.custom_content}
          >
            <div className="col-sm-12 text-center">
              <div className={`pt-2 pb-2 ${style.cont_sign_main}`}>
                <p className={style.con_sign_content}>
                  {" "}
                  <img
                    src={continueSignout}
                    alt="icon"
                    className={style.con_sign_img}
                  />
                  <b>Your session will expire in</b>
                </p>
                <p className={style.con_sign_time}>
                  {timer?.minutes} min {timer?.seconds} secs
                </p>
                <p className={style.con_sign_content}>
                  <b>
                    Please click “Continue” to keep working <br />
                    or “Sign Out” to end your session now.
                  </b>
                </p>
              </div>
              <div className="d-flex justify-content-center pb-4 mt-4">
                {/* Save Button */}
                <Button
                  id={"back_continue"}
                  type={"button"}
                  value={"Continue"}
                  classes={{
                    className: `btn ${style.btn_cont} mr-2`,
                  }}
                  onClick={doSessionContinue}
                >
                  {/* <img src={arrowLeft} className={`mr-1`}/>   */}
                  Continue
                </Button>
                {/* Cancel button  */}
                <Button
                  id={"next_sigout"}
                  type={"submit"}
                  value={"Sign Out"}
                  classes={{
                    className: `btn ${style.btn_signout} float-right p-1 ml-4`,
                  }}
                  onClick={doLogout}
                >
                  Sign Out
                  {/* <img src={arrowRight} className={`mb-1`} /> */}
                </Button>
              </div>
            </div>
          </Modal>
        )}

        {sessionMsgOutModal && (
          <Modal
            modalOpen={modalOpenSessionOut}
            modalClose={modalCloseSessionOut}
            heading={"Session Timed Out"}
            className={"modal_box"}
            modalSize={style.custom_modal_size}
            custom_heading={style.custom_heading}
            custom_body={style.custom_body}
            custom_content={style.custom_content}
          >
            <div className="col-sm-12 text-center">
              <div className={`pt-2 pb-2 ${style.cont_sign_main} text-center`}>
                <p className={style.con_sign_content}>
                  {" "}
                  <img
                    src={continueSignout}
                    alt="icon"
                    className={style.con_sign_img}
                  />
                  <b className="ml-3">Your session expired</b>
                </p>
                <p className={`${style.con_sign_time} text-center`}>
                  You’ve been gone for <br />a while
                </p>
                <p className={`${style.con_sign_content} text-center`}>
                  <b>
                    We closed down what you were working on <br />
                    to keep your information safe.
                  </b>
                </p>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
//dispatch
