import React, { useState } from 'react';
import { Tab as ReactTabs, Tabs, TabList, TabPanel } from 'react-tabs';
import { PropTypes } from 'prop-types';
import 'react-tabs/style/react-tabs.css';
import {
    react_tabs,
    react_tabs_list,
    selected,
    react_tab_pane,
    fixed_width,
    tissueview_fix,
    tissueview_outer,
    wound_report_view,
    hist_cause_list,
    hr_Line
} from './tab.module.scss';



export const Tab = ({ tabList, onSelect, defaultIndex, selectedIndex, workListView, tissueView,woundReportView,histCause, tabRemove }) => {
    return (
        <Tabs onSelect={onSelect} defaultIndex={defaultIndex}  selectedIndex={selectedIndex} className={tissueView && tissueview_outer}>
            {!tabRemove && <TabList className={`${react_tabs} ${tissueView && tissueview_fix}`}>
                {/* Map through tabs  */}
                {tabList.map(tab => (
                    <ReactTabs className={(woundReportView)? wound_report_view:(histCause)?hist_cause_list:react_tabs_list} selectedClassName={selected} key={tab.name}>{tab.name}</ReactTabs>
                ))}
            </TabList>}
            <div className={!tabRemove && hr_Line}/>
            {/* Map throgh tab panel */}
            {tabList.map((tabC, index) => (
                <TabPanel
                    className={`${react_tab_pane} ${(tabC.className) ? tabC.className : ''} ${workListView ? fixed_width : ''} `}
                    selectedClassName={selected}
                    key={index}>{tabC.content}
                </TabPanel>
            ))}
        </Tabs>
    );
};

Tab.propTypes = {
    tabList: PropTypes.array,
    tanPanelClass : PropTypes.object,
    onSelect: PropTypes.func,
    selectedIndex: PropTypes.number,
    tabShadowNone:PropTypes.any,
    workListView:PropTypes.bool
};
