import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { Button, TextField, Form } from '../../primary';
import { Link } from 'react-router-dom';
import { checkResetNewPassword, commonHintCheck, ServiceError } from '../../../utils';
import { Banner } from '../../secondary/Banner';
import { Logo } from '../../secondary/Logo';
import { link_style, subheading, form_shadow, title, medline_block, form_group, service_error,close_btn } from '../../../styles/common.module.scss';
import { PASSWORD_PHDR, CONFIRM_PASSWORD_REQUIRED } from '../../../constants';
import { custom_btn } from './resetPasswordForm.module.scss'
import { clearError } from '../../../app/appSlice'
import closeErrorIcon from "./../../../static/images/Close_Normal.svg";

export function ResetPasswordForm({ onSubmit, dynParam }) {

  const { register, handleSubmit, watch, errors } = useForm({ mode: "onBlur" });

  const errorMessage = ServiceError();

  // Password hint funtion
  const passwordCheck = watch('newPassword');

  const [minLength, setMinLength] = useState(false);
  const [oneSpecialChar, setOneSpecialChar] = useState(false);
  const [oneNum, setOneNum] = useState(false);
  const [oneUCase, setOneUCase] = useState(false);

  const passwordCondition = () => {
    commonHintCheck(passwordCheck, setMinLength, setOneUCase, setOneSpecialChar, setOneNum)
  }
  const doSubmit = (data) => {
    onSubmit(data)
  }
  const dispatch = useDispatch();
  return <div className={`container-fluid ${medline_block}`}>
    <div className="row vh-100">
      <div className="col-sm-12 col-lg-6">
        <Logo />
        <div className="container pt-3">
          <div className="row">
            <div className={` col-12 col-sm-12  mx-auto pt-5 pb-5 ${form_shadow}`}>
              <div className="col">
                <p>
                  <span className={subheading}>Return to&#160;
                    <Link to="/login" className={link_style}>Login</Link>
                  </span>
                </p>
              </div>
              <div className="col-12">
                <h1 className={title}>{dynParam&&dynParam?.acct_status==='isPasswordConfigured'?'Create':'Reset'}  Password</h1>
                <p className={subheading}>Enter a new password</p>
              </div>
              {/* Service error messages  */}
              {/* {errorMessage && <div className={service_error}>{errorMessage}</div>} */}
              {errorMessage && (
                    <div className="row">
                    <div
                      className={service_error}
                      dangerouslySetInnerHTML={{
                        __html:
                          errorMessage
                      }}
                    ></div>
                    <>
                      {
                         <button className={`${close_btn}`} onClick={()=>{dispatch(clearError())}}>
                          <img src={closeErrorIcon} alt="Close" />
                        </button>
                      }
                    </>
                  </div>
                  )}
              <Form className={"col-12 mx-auto"} onSubmit={handleSubmit(doSubmit)}>
                <TextField
                  classes={{ column: 'form-group', inputClassName: `form-control mb-1`, inputColumn: 'col-12 p-0' }}
                  label={"New Password *"}
                  type={"password"}
                  name={"newPassword"}
                  id={"newPassword"}
                  placeholder={PASSWORD_PHDR}
                  validator={register(checkResetNewPassword)}
                  onKeyUp={() => passwordCondition()}
                  errors={errors}
                  hints={true}
                  minLength={minLength}
                  oneSpecialChar={oneSpecialChar}
                  oneNum={oneNum}
                  oneUCase={oneUCase}
                />
                <TextField
                  classes={{ column: `form-group ${form_group}`, inputClassName: `form-control mb-1`, inputColumn: 'col-12 p-0' }}
                  label={"Confirm Password *"}
                  type={"password"}
                  name={"confirmPassword"}
                  id={"confirmPassword"}
                  placeholder={PASSWORD_PHDR}
                  validator={register({
                    required: CONFIRM_PASSWORD_REQUIRED,
                    validate: (value) => value === watch('newPassword') || "Password does not match"
                  })}
                  errors={errors}
                />
                <div className="form-group pt-3">
                  <Button
                    id={"reset-btn"}
                    type={"submit"}
                    value={"Submit"}
                    classes={{ className: `btn btn-block ${custom_btn}` }}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Banner />
    </div>
  </div>
}