import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export let Home = ({ dispatch, history }) => {

  const userRole = useSelector(state => state.user);
  const [redirect, setRedirect] = useState(null);

  const userloading = useSelector(state =>state.user.userLoading)

  
  useEffect(() => {
    if(userloading === false&&userRole){
    if(userRole?.customerSettings?.ne1Wms === 0){
      if (( userRole?.role !== "MedlineAdministrator" ||userRole?.role === "ExternalStandardUser"  || userRole.role === "ExternalNE1Review" || userRole.role === "ExternalNE1Assesment")) {
        setRedirect(2);
      }
      else if ((userRole?.role === "ExternalAdministrator"|| userRole.role === "ExternalNE1WoundExpert"  || userRole?.role !== "ExternalStandardUser" || userRole.role !== "ExternalNE1Review" || userRole.role !== "ExternalNE1Assesment")) {
        setRedirect(0);
      }
    }
    else if(userRole?.customerSettings?.ne1Wms === 1){
      if ((userRole?.role !== "ExternalAdministrator" || userRole?.role !== "ExternalStandardUser" || userRole.role !== "ExternalNE1WoundExpert" || userRole.role !== "ExternalNE1Review" || userRole.role !== "ExternalNE1Assesment")) {
        setRedirect(1);
      }
      else if ((userRole?.role === "ExternalAdministrator" || userRole?.role === "ExternalStandardUser" || userRole.role === "ExternalNE1WoundExpert" || userRole.role === "ExternalNE1Review" || userRole.role === "ExternalNE1Assesment" )) {
        setRedirect(1);
      }
    }
  }
  }, [userRole, userloading])
  
  return (<div>
    {redirect !== null && redirect === 1 &&
      <Redirect
        to={{ pathname: '/worklist', state: { from: history.location } }}

      />}
    {redirect !== null && redirect === 0 &&
      <Redirect
        to={{ pathname: '/admin/usageAnalytics', state: { from: history.location } }}

      />
    }
    {redirect !== null && redirect === 2 &&
      <Redirect
        to={{ pathname: '/learnMore', state: { from: history.location } }}

      />
    }
  </div>)
}

Home.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object

};

Home = connect()(withRouter(Home));
