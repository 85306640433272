import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UserProfileDetails } from '../components/core/UserProfile';

export let UserProfile = () => {
    return <UserProfileDetails />;
}

UserProfile = connect()(withRouter(UserProfile));
