import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SAVE_BTN, SUBMIT_ID, EDIT, SUCCESS_STATUS, UPDATE_SUCCESS, UPDATE_FAILURE, ERROR_STATUS } from '../../../constants'
import editIcon from '../../../static/images/edit-icon-blue.png'
import styles from './competitorsView.module.scss'
import PlusIcon from '../../../static/images/plus-icon_white.svg';
import closeIcon from '../../../static/images/Close_Normal.svg';
import { Button, Form, TextField,AccessErrorModal } from '../../primary';
import { getProductCompetitorAsync, updateProductCompetitorAsync, setProductEditStatus } from '../../../app/productSlice';
import Loader from '../../../static/images/spinner.svg';
export function CompetitorsView({ productData }) {
  const competitorsData = useSelector(state => state.product.competitors);
  const updateStatus = useSelector(state => state.product.productEditStatus);
  const dispatch = useDispatch();
  const { productId } = useParams()
  useEffect(() => {
    if (productId) {
      dispatch(getProductCompetitorAsync(productId))
    }
  }, [productId, dispatch]);
  const { register, handleSubmit, reset } = useForm({
    mode: 'all'
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2
  } = useForm({
    mode: "all",
  });
  const imagePlusIcon = <img src={PlusIcon} className={`${styles.plusimg}`} alt="plus" />
  const [editable, setEditable] = useState(false)
  const [openFileForm, setOpenFileForm] = useState(false)
  const [tempFileName, setTempFileName] = useState('')
  const [tempFileUrl, setTempFileUrl] = useState('')
  const [loadingImg, setloadingImg] = useState(false)
  /* Function to toggle edit */
  const handleEdit = () => {
    setEditable(!editable)
    if (editable) {
      setInputList(competitorsData);
      resetFileForm();
    }
  }
  const [inputList, setInputList] = useState();
  // handle click event of the Add button
  const resetFileForm = () => {
    setOpenFileForm(false)
    reset2();
    setTempFileName('')
    setTempFileUrl('')
  }
  const handleAddCompetitorClick = () => {
    setOpenFileForm(!openFileForm)
  };

  const onSubmitFile = (data) => {
    setloadingImg(true)
    let finalImage;
    if (data.tempFileUrl) {
      finalImage = data.tempFileUrl.replace('data:image/png;base64,', '')
      finalImage = finalImage.replace('data:image/jpeg;base64,', '')
      finalImage = finalImage.replace('data:image/jpg;base64,', '')
      finalImage = finalImage.replace('data:image/svg+xml;base64,', '')
    }
    const tempFilePayload = { "competitorName": data.competitorName, "image": finalImage, "fileName": tempFileName, }
    if (inputList && Array.isArray(inputList)) {
      setInputList([...inputList, tempFilePayload]);
    }
    setTimeout(() => {
      resetFileForm();
      setloadingImg(false)
    }, 1000)

  }
  const doCompetitorsAdd = (data) => {
    const payload = {
      "competitors": (data.competitors === undefined) ? [] : data.competitors,
    }
    dispatch(updateProductCompetitorAsync(payload, productId))
  }
  // handle click event of the Remove button
  const handleRemoveAttributeClick = (index) => {
    let list = [...inputList].slice();
    list.splice(index, 1);
    setInputList(list);
    reset()
  };
  const handleFileUpload = () => {
    if (document.getElementById("selectFile"))
      document.getElementById("selectFile").click();
  }

  const handleFileChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = (e && e.target && e.target.files[0]) ? e.target.files[0] : '';
    reader.onloadend = () => {
      setTempFileUrl(reader.result)
    }
    if (e && e.target && e.target.files[0]) {
      setTempFileName(file.name)
      reader.readAsDataURL(file)
    }
  };
  const handleListItems = (index) => {
    let elementShow = document.getElementById('competitorName' + index);
    let elementHide = document.getElementById('dataListHide' + index);
    if (ReactDOM.findDOMNode(elementShow)) {
      ReactDOM.findDOMNode(elementShow).classList.remove("d-none")
      ReactDOM.findDOMNode(elementShow).classList.add("d-show")
      ReactDOM.findDOMNode(elementHide).classList.add("d-none")
    }

  };
  useEffect(() => {
    setInputList(competitorsData);
  }, [competitorsData, dispatch]);

  useEffect(() => {
    if (updateStatus === "success" || updateStatus === "error") {
      handleEdit();
      setTimeout(() => {
        dispatch(setProductEditStatus({}))
      }, 5000)
    }

  }, [updateStatus]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setProductEditStatus({}))
    }, 5000)
  }, [dispatch])
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
  return (
    <>
    <AccessErrorModal />
      <div className={`${styles.tab_content_top}`}>
        <h2 className={`${styles.tab_content_hdg}`}>
          {(productData?.productFamilyDesc)? productData?.productFamilyDesc:productData?.materialDescription}
          {tinyDelay&&!editable &&
            <span className={`float-sm-right edit_btn ${styles.tab_edit}`} onClick={handleEdit} >
              <img src={editIcon} alt="edit" />{EDIT}
            </span>

          }
          {editable &&inputList&& Array.isArray(inputList) && inputList.length === 3 ? <Button
            id={"addCompetitor"}
            classes={{ className: `float-sm-right btn  ${styles.btn_custom_disable}` }}

          >
            {imagePlusIcon}

            {'Add Competitors'}
          </Button> : editable && <Button
            id={"addCompetitor"}
            classes={{ className: `float-sm-right btn  ${styles.btn_save}` }}
            onClick={handleAddCompetitorClick}
          >
            {imagePlusIcon}

            {'Add Competitors'}
          </Button>
          }
          {openFileForm && editable && <div className={`dropdown-menu show ${styles.media_align}`} aria-labelledby="dropdownMenu3">
            <div className={`${styles.media_hdg}`}>
              Add Competitors
            </div>
            <div className={`${styles.media_btm}`}>
              <Form key={2} onSubmit={handleSubmit2(onSubmitFile)}>
                <div className="form-group col-12 p-0">
                  <input
                    id="tempFileUrl"
                    type="hidden"
                    ref={register2}
                    className={`invisible`}
                    name={`tempFileUrl`}
                    defaultValue={tempFileUrl ? tempFileUrl : ''}
                  />
                  <input
                    type="hidden"
                    ref={register2}
                    className={`invisible`}
                    name={`tempFileName`}
                    defaultValue={tempFileName ? tempFileName : ''}
                  />
                  <TextField
                    label={'Competitor Name:'}
                    classes={{
                      column: ``,
                      labelClass: '',
                      inputColumn: '',
                      inputClassName: `${styles.input_field}`
                    }}
                    placeholder={'Competitor Name'}
                    id={`competitorName`}
                    type={"text"}
                    name={`competitorName`}
                    validator={register2}
                  />
                </div>
                <div className="form-group col-12 p-0">
                  <label for="URL">Upload Image:</label>
                  <span>
                    Max file size : 20MB <br/>
                    Supported image format ( JPG, JPEG, PNG )
                  </span>
                </div>
                <div className={`${styles.media_upload}`}>
                  {loadingImg ? <div className={styles.loader}>
                    <img src={Loader} alt="loading" />
                  </div>
                    :
                    <> <Button
                      id={'buttonFile'}
                      type={"button"}
                      value={'Choose File'}
                      onClick={() => handleFileUpload()}
                      classes={{ className: `${styles.choose_file_btn}` }}
                    />
                      {tempFileName && <span>{tempFileName}</span>}
                      <TextField
                        classes={{
                          column: ``,
                          labelClass: ``,
                          inputColumn: '',
                          inputClassName: `invisible hidden_file`
                        }}
                        id={'selectFile'}
                        type={"file"}
                        name={`competitorFile`}
                        // validator={register2}
                        required={true}
                        errors={errors2}
                        onChange={handleFileChange}
                        validator={register2({
                          validate: (value,e) => {
                            if (value && value[0]) {
                             if (value[0].size > 20971520) {
                                return 'Max file size : 20MB';
                              }
                              else if((value[0].type === 'image/png') || (value[0].type === 'image/jpg') || (value[0].type === 'image/jpeg')){
                                return true
                             }
                             else{
                              return 'Please upload only JPEG or JPG or PNG files';
                             }
                            }
                            else if (value && !value[0]) {
                              return 'Please upload image';
                            }
                            else{
                              return true
                            }
                          }
                        })}
                      /></>
                  }
                </div>
                <div className="col-sm-12 p-0">
                  <div className="text-right">
                    <Button
                      id={`fileSubmit`}
                      type={SUBMIT_ID}
                      value={'Upload'}
                      classes={{ className: `mr-3 btn mb-1 ${styles.btn_small}` }}
                    />
                    <Button
                      id={`fileCancel`}
                      type={"button"}
                      value={'Cancel'}
                      classes={{ className: `mr-3 btn mb-1 ${styles.btn_cancel}` }}
                      onClick={() => resetFileForm()}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          }
        </h2>
      </div>
      {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
      <div className={`col-md-12 ${styles.single_product_block}`}>
        <div className="row">
          <div className={`p-0 ${styles.patient_content}`}>
            {updateStatus === SUCCESS_STATUS && <div className={`alert alert-success d-inline ${styles.form_alert}`}>{UPDATE_SUCCESS}</div>}
            {/* Edit failure */}
            {updateStatus === ERROR_STATUS && <div className={`alert alert-danger ${styles.form_alert}`}>{UPDATE_FAILURE}</div>}
            <div className={`col-12`}>
              {inputList&&Array.isArray(inputList) && inputList.length === 0 &&
                <div className={`${styles.content_center}`}>
                 There is no competitors added to this product family
                </div>
              }
            </div>
            {!editable ?
              <>
                {/* Material Number */}
                {competitorsData && competitorsData.length > 0 && Array.isArray(competitorsData) && competitorsData.map((items, i) => {
                  return (
                    <>
                      <div className={`${styles.compet_blk}`}>
                        <div className={`${styles.compet_top}`}>
                          <div className={`${styles.compet_top_title}`}>
                            {items.competitorName}
                          </div>
                        </div>
                        <div className={`${styles.compet_btm}`}>
                          <ul>
                            <li className={`${styles.compet_list}`}>
                              <a href={(items && items.image) ? items.image : ''}> {items.fileName}</a>
                            </li>
                          </ul>

                        </div>
                      </div>
                    </>
                  )
                }
                )}
              </>
              :
              <div className="col-12 p-4">
                <Form formChanges={true} onSubmit={handleSubmit(doCompetitorsAdd)}>
                  {inputList && inputList.length > 0 && inputList.map((items, i) => {
                    return (
                      <>
                        <input type="hidden" name={`competitors[${i}][image]`} id={`image${i}`} ref={register} style={{ visibility: "hidden" }} defaultValue={(items && items.image) ? items.image : ''} />
                        <input type="hidden" name={`competitors[${i}][fileName]`} id={`fileName${i}`} ref={register} style={{ visibility: "hidden" }} defaultValue={(items && items.fileName) ? items.fileName : ''} />
                        <div className={`${styles.compet_blk}`}>
                          <div className={`${styles.compet_top}`}>
                            <div className={`${styles.compet_top_title}`}>
                              <span
                                onDoubleClick={() => handleListItems(i)}
                                className={`dataListHide`}
                                id={`dataListHide${i}`}
                              >{items.competitorName} </span>

                              <input type="text" className={`d-none`} name={`competitors[${i}][competitorName]`} id={`competitorName${i}`} ref={register} defaultValue={(items && items.competitorName) ? items.competitorName : ''} />

                              {/* {items.competitorName} */}
                              <div className={`${styles.close}`}>
                                <img className={`remove_competitor`} onClick={() => handleRemoveAttributeClick(i)} src={closeIcon} alt="remove" />
                              </div>
                            </div>
                          </div>
                          <div className={`${styles.compet_btm}`}>
                            <ul>
                              <li className={`${styles.compet_list}`}>
                                <a> {items.fileName}</a>
                              </li>
                            </ul>

                          </div>
                        </div>
                      </>
                    )

                  })
                  }
                  <div className={styles.btn_container}>
                    <Button
                      id={"creat_list_view_menu"}
                      type={SUBMIT_ID}
                      value={SAVE_BTN}
                      classes={{ className: `btn mb-1 mr-3 ${styles.btn_small}` }}
                    />
                    <Button
                      id={'cancel'}
                      type={"button"}
                      value={'Cancel'}
                      classes={{ className: `btn mb-1 ${styles.btn_cancel}` }}
                      onClick={() => handleEdit()}
                    />
                  </div>
                </Form>
              </div>
            }
          </div>
        </div>
      </div>}
    </>
  )
}

