import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { getSupportListDataAsync, getSupportQuestionsAsync, optionTypeAsync, setSucessStatus } from '../app/supportSlice';
import { DetailView, ListView } from '../components';
import { LIST_VIEW_LIMIT, SUPPORT_LIST } from '../constants';
import { List, Pagination, Search, SelectedRow, Sorting } from '../utils';

export let ContextualSupport = ({ dispatch, history, location }) => {
    const [offSet, setOffSet] = useState(0)
    const [searchKey, setSearchKey] = useState('')
    const [sortType, setSortType] = useState('')
    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);
    const { supportDataId } = useParams();

    //restrict when directly open the page
    const userDetails = useSelector(state => state.user);
    const adminView = userDetails?.role     
    useEffect(()=>{        
        window.onload = function() { 
            if (adminView === "ExternalStandardUser") {  
                history.push('/unAuthorized'); 
            }       
        }    
    },[]) //restrict when directly open the page

    useEffect(()=>{
        dispatch(optionTypeAsync("applicationpage"))
        if(supportDataId) {
            dispatch(setSucessStatus({}))
            dispatch(getSupportQuestionsAsync(supportDataId,history))
        }
    },[supportDataId, dispatch])
    const supportList = useSelector(state => state.support.supportListData) 
    const getSupportList = () => {
        List(SUPPORT_LIST, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getSupportListDataAsync)
    }
    useEffect(()=> {
        setSortType('')
        setSearchKey('')
        getSupportList();
        getSelectedRowData();
    },[dispatch])
    // search list
    const getSearchValue = (event) => {
        if (event && event.keyCode === 13) {
            if (event && event.target.value !== '') {
                setOffSet(0)
                setActivePage(1)
                setSearchKey(event.target.value)
                Search(event, SUPPORT_LIST, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getSupportListDataAsync,sortType)
            }else{
                setOffSet(0)
                setActivePage(1)
                getSupportList();
                setSearchKey('')
                setSortType('')
            }
        }
    }
    //sorting
    const sortingPayLoad = Sorting(SUPPORT_LIST, 'Get', LIST_VIEW_LIMIT, offSet, getSupportListDataAsync,searchKey,sortType,setSortType)

    // paginations
    const [activePage, setActivePage] = useState(1);
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        Pagination(data, setActivePage, SUPPORT_LIST, 'Get', LIST_VIEW_LIMIT, dispatch, getSupportListDataAsync,searchKey,'',sortType)
    }
    const showFixedColumn = !!supportList.support && !!supportList.support.length
    const columns = [
        {
          Header: 'Topics',
          accessor: 'topic',
          fixedColumn: showFixedColumn,
          customWidth:"200px"
        },
        {
          Header: 'Pages',
          accessor: 'page',
          customWidth:"200px"
        },
        {
          Header: 'Application',
          accessor: 'application',
          customWidth:"635px"
        }
    ]
    // details view
    const [detailsRowData, setDetailsRowData] = useState([])
    const getSelectedRowData = (data) => {
        SelectedRow(data, setDetailsRowData, 'support', history, isAdminView)
    }
    const handleBack = () => {
    }
    return (
        <>
            { !supportDataId && <ListView 
                columns={columns}
                data={supportList && supportList?.support}
                metaData={supportList.metadata && supportList?.metadata}
                sorting={true}
                rowSelect={false}
                getSelectedRowData={getSelectedRowData}
                detailView={true}
                pageRangeDisplayed={5}
                searchPlaceholder={"Search by Topics,Pages and Application"}
                getSearchValue={(e) => getSearchValue(e)}
                detailsRow={!supportDataId}
                activePage={activePage}
                getActivePage={getActivePage}
                listSorting={sortingPayLoad}
                listType={"ContextualSupport"}
                subHeading={'Contextual Support'}
                showFixedColumn={showFixedColumn}
                contextualSupportView={true}
                offset={offSet}
                searchKey={(searchKey)?searchKey:''}
                sortType={sortType?sortType:''}
            /> }
            { supportDataId && 
                <DetailView 
                    supportViewRowData={detailsRowData}
                    title="Contextual Support" 
                    dispatch={dispatch}
                    supportView={true}
                    handleBack={handleBack} 
                    subHeading={'Contextual Support'} 
                    backLink='/admin/support' 
                />
            }
        </>
    )
}
ContextualSupport.propTypes = {
    dispatch: PropTypes.func,
    history:PropTypes.object,
};
ContextualSupport = connect()(withRouter(ContextualSupport));
