import React, { useEffect } from 'react';
import { ViewTreatMent } from '../../secondary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDressingAsync, getlistOptionsAsync, saveDressingAsync } from '../../../app/patientSlice';
export const WoundCleanser = ({ interventionId, formularyId, assessmentDetails }) => {

  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const woundCleanserGetData = useSelector(state => state.patient.woundCleanserGetData);
  const woundCleanserListOptions = useSelector(state => state.patient.woundCleanserListOptions);

  let flag = false;
  const doSubmit = (data) => {
    const payloadData = {
      "productCategory": data,
      'patientId': patientId,
      'assessmentId': assessmentId,
      'interventionId': interventionId,
      'payType': 'woundcleanser'
    }
    let woundCleanserData = [];
    const woundCleanserDatas = Array.isArray(data) && data?.map((item, index) => {//NOSONAR
      woundCleanserData.push(item.value)//NOSONAR
      return
    })//NOSONAR

    for (let i = 0; i < woundCleanserData.length; i++) {
      if (woundCleanserData[i] === undefined) {
        flag = false
      }
      if (woundCleanserData[i] !== undefined) {
        flag = true;
        break;
      }
    }
         dispatch(saveDressingAsync(payloadData))
    
  }

  useEffect(() => {
    if (formularyId !== null) {
      const payload = {
        'formularyid': formularyId,
        'option': 'woundcleanser',
        payType: 'woundcleanser'
      }
      dispatch(getlistOptionsAsync(payload));
      dispatch(getDressingAsync({ patientId, assessmentId, interventionId, payType: 'woundcleanser' }));     
    }

  }, [interventionId])


  return (
    <ViewTreatMent
      editData={woundCleanserListOptions}
      viewData={woundCleanserGetData}
      saveData={doSubmit}
      noDataMessage={"Your formulary doesnt have any wound cleansers, please contact your Medline Sales Rep"}
      assessmentDetails={assessmentDetails}
    />
  )
}
