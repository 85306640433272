import React, { useEffect } from 'react';
import { ViewTreatMent } from '../../secondary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDressingAsync, getlistOptionsAsync, saveDressingAsync } from '../../../app/patientSlice';
export const PrimaryDressing = ({ interventionId, formularyId, assessmentDetails }) => {

  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const primaryDressingGetData = useSelector(state => state.patient.primaryDressingGetData);
  const primaryDressingListOptions = useSelector(state => state.patient.primaryDressingListOptions);

  let flag = false;

  const doSumit = (data) => {
    const payloadData = {
      "productCategory": data,
      'patientId': patientId,
      'assessmentId': assessmentId,
      'interventionId': interventionId,
      'payType': 'primarydressing'
    }
    let primaryData = [];
    const primaryDatas = Array.isArray(data) && data?.map((item, index) => {//NOSONAR
      primaryData.push(item.value)
      return
    })//NOSONAR

    for (let i = 0; i < primaryData.length; i++) {
      if (primaryData[i] === undefined) {
        flag = false
      }
      if (primaryData[i] !== undefined) {
        flag = true;
        break;
      }
    }
      dispatch(saveDressingAsync(payloadData))
    
  }
  useEffect(() => {
    if (formularyId !== null) {
      const payload = {
        'formularyid': formularyId,
        'option': 'primarydressing?ne1Id=' + patientId + '&assessmentId=' + assessmentId,
        payType: 'primarydressing'
      }
      dispatch(getlistOptionsAsync(payload));
      dispatch(getDressingAsync({ patientId, assessmentId, interventionId, payType: 'primarydressing' }));
    }

  }, [interventionId])
  return (
    <ViewTreatMent
      editData={primaryDressingListOptions}
      viewData={primaryDressingGetData}
      saveData={doSumit}
      dressing={'primary'}
      noDataMessage={'Your formulary doesnt have any primary dressings, please contact your Medline Sales Rep'}
      assessmentDetails={assessmentDetails}
    />
  )
}
