import React, { useState, useEffect, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button, MultiSelect } from "../../primary";
import { CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from "../../../constants";
import styles from "./assign.module.scss";
import { Modal } from "../../secondary";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ProductInputs } from "./productInputs";
import ReactTags from "react-tag-autocomplete";
import { getUniqueListBy } from "../../../utils";

export let AddFamily = ({
  modalOpen,
  modalClose,
  getFamilyCode,
  index,
  productCategoryList,
  pIndex,
  defaultValue,
  productFamily,
}) => {
  const { register, errors, reset } = useForm();
  const productDiv = useRef();
  const productCate = useRef();
  const productFam = useRef();
  const [productCat, setProductCat] = useState([]);
  const [productFamilyCode, setProductFamilyCode] = useState([]);
  const [productFilter, setProdcutFilter] = useState({});
  const [familyCode, setFamilyCode] = useState([]);
  const [defaultFamily, setDefaultFamily] = useState([]);
  const [defaultData, setDefaultData] = useState(true);
  const [defaultDivision, setDefaultDivision] = useState([]);
  const [productDivision, setProductDivision] = useState([]);
  const [familyCodeTag, setFamilyCodeTag] = useState([]);
  const [familyCodeSuggestions, setFamilyCodeSuggestions] = useState([]);
  const [defaultProductCategoryDesc, setDefaultProductCategoryDesc] = useState(
    []
  );
  const familyCodeTagsRef = useRef();

  const onDelete = useCallback(
    (tagIndex) => {
      
      const getFamilyIds = familyCodeTag.filter((_, i) => i === tagIndex);
      /*if (getFamilyIds) {
        const defaultFam = Array.isArray(productFam.current.state.selectedValues)&&productFam.current.state.selectedValues.filter((e) =>
          getFamilyIds.some((val) => val.id !== e.familyCode)
        );if(defaultFam){
        const familyCode = defaultFam.map(({ familyCode }) => familyCode);
        //setDefaultFamily(familyCode);
        const finalFamilySelection = productFam.current.state.selectedValues.filter((e) =>
           defaultFam.some((val) => val.familyCode === e.familyCode)
        );
        productFam.current.state.selectedValues = Array.isArray(finalFamilySelection)?finalFamilySelection:[];
        selectFamilyCode(Array.isArray(finalFamilySelection)?finalFamilySelection:[]);
      }
      }*/
      const finalTag=familyCodeTag.filter((_, i) => i !== tagIndex);
      setFamilyCodeTag(finalTag);
      const getCat=Array.isArray(productCate.current.state.selectedValues)?productCate.current.state.selectedValues:[];
      let prodcutCat = [];
      let familyCode = [];
      getCat?.map((item) => {//NOSONAR
      prodcutCat = [...prodcutCat, item.key];
      const productFamilCode = productCategoryList?.data?.productFamilyDesc?.find(
          (x) => x?.key === item.key
      );
      let codeDesc = [];
      productFamilCode?.value?.map((item, index) => {//NOSONAR
            let familyDes = { ...item };
            familyDes["valueCode"] = `${item.familyCode} - ${item.value}`;
            codeDesc = [...codeDesc, familyDes];
      //familyCodeTag
      });//NOSONAR
          familyCode = [...familyCode, ...codeDesc];
      });//NOSONAR
      /*if(finalTag&&Array.isArray(finalTag)&&finalTag.length>0){
        let familyCodef=familyCode.filter((e) =>{
            finalTag.filter((val) => 
                {if(val.id !== e.familyCode){
                  return e;
                }}  
           )
          })
      }*/
      const finalArrayFam  =  Array.isArray(familyCode) &&familyCode.filter(function (o1) {
        return !finalTag.some(function (o2) {
            return o1.familyCode === o2.id; // return the ones with equal id
       });
      });
        familyCode=finalArrayFam;
        Array.isArray(familyCode) &&
        familyCode.length > 0 &&
        familyCode.sort((a, b) => (a.familyCode > b.familyCode ? 1 : -1));
        const uniqueFinalFamilyCode = getUniqueListBy(familyCode, "familyCode");
        setProductFamilyCode(uniqueFinalFamilyCode);  
   
    },
    [familyCodeTag]
  );

  const onAddition = useCallback(
    (newTag) => {
      const ids = familyCodeTag.map(({ id }) => id);
      if (!ids.includes(newTag.id)) {
        const finalTag=[...familyCodeTag, newTag];
        setFamilyCodeTag(finalTag);
        /*const filterDefaultFamilyUpdate = productFam.current.state.options.find((x) => x.familyCode === newTag.id);        
        if(filterDefaultFamilyUpdate){
          const defaultFamilyUpdate=[...productFam.current.state.selectedValues, filterDefaultFamilyUpdate]
          productFam.current.state.selectedValues = defaultFamilyUpdate;
          setDefaultFamily(defaultFamilyUpdate);
          selectFamilyCode(defaultFamilyUpdate,filterDefaultFamilyUpdate);
        }*/
       /* setTimeout(() => {
          getProductCat(Array.isArray(productCate.current.state.selectedValues)?productCate.current.state.selectedValues:[])
       }, 1000)*/
        const getCat=Array.isArray(productCate.current.state.selectedValues)?productCate.current.state.selectedValues:[];
        let prodcutCat = [];
        let familyCode = [];
        getCat?.map((item) => {//NOSONAR
        prodcutCat = [...prodcutCat, item.key];
        const productFamilCode = productCategoryList?.data?.productFamilyDesc?.find(
            (x) => x?.key === item.key
        );
        let codeDesc = [];
        productFamilCode?.value?.map((item, index) => {//NOSONAR
      
              let familyDes = { ...item };
              familyDes["valueCode"] = `${item.familyCode} - ${item.value}`;
              codeDesc = [...codeDesc, familyDes];
  
        //familyCodeTag
        });//NOSONAR
            familyCode = [...familyCode, ...codeDesc];
        });//NOSONAR

        const finalArrayFam  =  Array.isArray(familyCode) &&familyCode.filter(function (o1) {
          return !finalTag.some(function (o2) {
              return o1.familyCode === o2.id; // return the ones with equal id
         });
        });
     
      familyCode=finalArrayFam;
          Array.isArray(familyCode) &&
          familyCode.length > 0 &&
          familyCode.sort((a, b) => (a.familyCode > b.familyCode ? 1 : -1));
          const uniqueFinalFamilyCode = getUniqueListBy(familyCode, "familyCode");
          setProductFamilyCode(uniqueFinalFamilyCode); 
        }
    },
    [familyCodeTag]
  );

  useEffect(() => {
    setProductDivision(productCategoryList?.data?.division);

    const getAllfamilycode=[]
    const collAllFam=Array.isArray(productCategoryList?.data?.productFamilyDesc)&&productCategoryList?.data?.productFamilyDesc.map((items,index) =>{
      Array.isArray(items.value)&&items.value.map((fam,ind) =>{
       return getAllfamilycode.push({ id: fam.familyCode, name: fam.familyCode })
      })
    })
    setFamilyCodeSuggestions(getAllfamilycode)
    let category = [];
    defaultValue?.division?.map((item) => {//NOSONAR
      const categoryDesc = productCategoryList?.data?.productCategoryDesc?.find(
        (x) => x?.key === item
      );
      if (categoryDesc?.value?.length > 0) {
        category = [...category, ...categoryDesc?.value];//NOSONAR
      }
    });//NOSONAR
    if (category?.length > 0) {
      Array.isArray(category) &&
        category.length > 0 &&
        category.sort((a, b) => (a.value > b.value ? 1 : -1));
      setProductCat(category);
    }
    let familyCode = [];
    defaultValue?.productCategoryDesc?.map((item) => {//NOSONAR
      const productFamilCode = productCategoryList?.data?.productFamilyDesc?.find(
        (x) => x?.key === item
      );
      let codeDesc = [];
      productFamilCode?.value?.map((item, index) => {//NOSONAR
        let familyDes = { ...item };
        familyDes["valueCode"] = `${item.familyCode} - ${item.value}`;
        codeDesc = [...codeDesc, familyDes];
      });//NOSONAR
      familyCode = [...familyCode, ...codeDesc];
    });//NOSONAR
    if (familyCode?.length > 0) {
      Array.isArray(familyCode) &&
        familyCode.length > 0 &&
        familyCode.sort((a, b) => (a.familyCode > b.familyCode ? 1 : -1));
      setProductFamilyCode(familyCode);
     /* let finalFamilyCodeSugestion = [];
      familyCode?.map((item) => {
        finalFamilyCodeSugestion.push({ id: item.familyCode, name: item.familyCode });
      });
      //setFamilyCodeSuggestions(finalFamilyCodeSugestion)*/
    }
    let defaultFam = [];
    defaultValue?.producFamilyCode?.length > 0 &&
    defaultValue?.producFamilyCode?.map((item) => {//NOSONAR
        let familyDefault = familyCode.find((x) => x.key === item);
        if (familyDefault !== undefined) {
          defaultFam = [...defaultFam, familyDefault.key];
        }
      });//NOSONAR
    if (defaultFam?.length > 0) {
      const getFamilDefault =
        Array.isArray(familyCode) &&
        familyCode.filter(
          (e) =>
            Array.isArray(defaultFam) && defaultFam.some((val) => val === e.key)
        );
      //setDefaultFamily(Array.isArray(getFamilDefault) ? getFamilDefault : []);
      //selectFamilyCode(getFamilDefault);
     /* productFam.current.state.selectedValues = Array.isArray(getFamilDefault)
        ? getFamilDefault
        : [];*/
    }

    if (productFamily?.length > 0) {
      let finalFamilyCodeDef = [];
      productFamily?.map((item) => {
        finalFamilyCodeDef.push({ id: item, name: item });
      });
    Array.isArray(finalFamilyCodeDef) &&
    finalFamilyCodeDef.length > 0 &&
    finalFamilyCodeDef.sort((a, b) => (a.value > b.value ? 1 : -1));
      setFamilyCodeTag(finalFamilyCodeDef)
    }
    setProdcutFilter(defaultValue);
    const getDivisionDefault =
      Array.isArray(productCategoryList?.data?.division) &&
      productCategoryList?.data?.division.filter(
        (e) =>
          Array.isArray(defaultValue?.division) &&
          defaultValue?.division.some((val) => val === e.key)
      );
    setDefaultDivision(
      Array.isArray(getDivisionDefault) ? getDivisionDefault : []
    );
    productDiv.current.state.selectedValues = Array.isArray(getDivisionDefault)
      ? getDivisionDefault
      : [];
    const getDecsDefault =
      Array.isArray(category) &&
      category.filter(
        (e) =>
          Array.isArray(defaultValue?.productCategoryDesc) &&
          defaultValue?.productCategoryDesc.some((val) => val === e.key)
      );
    setDefaultProductCategoryDesc(
      Array.isArray(getDecsDefault) ? getDecsDefault : []
    );
    productCate.current.state.selectedValues = Array.isArray(getDecsDefault)
      ? getDecsDefault
      : [];
  }, [productCategoryList, defaultValue, modalOpen]);

  const addFamilyCode = () => {
    const defaultFamilyCodeBuket = familyCodeTag.map((e) => {
      return e.id;
    });
    const defaultDivisionKey = defaultDivision.map((e) => {
      return e.key;
    });
    const defaultProductCategoryDescKey = defaultProductCategoryDesc.map(
      (e) => {
        return e.key;
      }
    );
    const defaultProductFamilyKey = defaultFamily.map(
      (e) => {
        return e.key;
      }
    );
    const productFilters = {
      division: defaultDivisionKey,
      productCategoryDesc: defaultProductCategoryDescKey,
      producFamilyCode:defaultProductFamilyKey
    };
    getFamilyCode(defaultFamilyCodeBuket, index, pIndex, productFilters);
    modalClose();
    setProductDivision([]);
    setProductCat([]);
    setProductFamilyCode([]);
  };

  const modalRefresh = () => {
    reset();
    modalClose();
    setProductDivision([]);
    setProductCat([]);
    setProductFamilyCode([]);
  };

  const handleCancel = (e) => {
    reset();
    setProductDivision([]);
    setProductCat([]);
    setProductFamilyCode([]);
    e.preventDefault();
    modalClose();
  };

  const getProductDivision = (list) => {
    let division = [];
    let category = [];
    list?.map((item) => {//NOSONAR
      division = [...division, item.key];
      const categoryDesc = productCategoryList?.data?.productCategoryDesc?.find(
        (x) => x?.key === item.key
      );
      category = [...category, ...categoryDesc?.value];//NOSONAR
    });//NOSONAR
    const divi = { division: division };
    const filter = { ...productFilter, ...divi };
    setProdcutFilter(filter);
    Array.isArray(category) &&
      category.length > 0 &&
      category.sort((a, b) => (a.value > b.value ? 1 : -1));

    //setProductFamilyCode([])
    productDiv.current.state.selectedValues = list;
    setDefaultDivision(list);
    const removeDefaultCatDec = category.filter(
      (e) =>
        Array.isArray(defaultProductCategoryDesc) &&
        defaultProductCategoryDesc.some(
          (val) => val && e && val.key && e.key && val.key === e.key
        )
    );
    productCate.current.state.selectedValues = removeDefaultCatDec;
    setProductCat(category);
    setDefaultProductCategoryDesc(removeDefaultCatDec);
    getProductCat(removeDefaultCatDec);
    //setDefaultData(false)
  };

  const getProductCat = (list,delId) => {
    let prodcutCat = [];
    let familyCode = [];
    list?.map((item) => { //NOSONAR
      prodcutCat = [...prodcutCat, item.key];
      const productFamilCode = productCategoryList?.data?.productFamilyDesc?.find(
        (x) => x?.key === item.key
      );
      let codeDesc = [];
      productFamilCode?.value?.map((item, index) => {//NOSONAR
        let familyDes = { ...item };
        familyDes["valueCode"] = `${item.familyCode} - ${item.value}`;
        codeDesc = [...codeDesc, familyDes];
        //familyCodeTag
      });//NOSONAR
      familyCode = [...familyCode, ...codeDesc];
    });//NOSONAR
    const cat = { productCategoryDesc: prodcutCat };
    const filter = { ...productFilter, ...cat };
    setProdcutFilter(filter);
    /*let finalFamilyCodeSugestion = [];
    familyCode?.map((item) => {
      finalFamilyCodeSugestion.push({ id: item.familyCode, name: item.familyCode });
    });
   // setFamilyCodeSuggestions(finalFamilyCodeSugestion)*/
    productCate.current.state.selectedValues = list;
    setDefaultProductCategoryDesc(list);
   /* const removeDefaultFamCode = familyCode.filter(
      (e) =>
        Array.isArray(familyCodeTag) &&
        familyCodeTag.some(
          (val) =>
            val &&
            e &&
            val.id &&
            e.familyCode &&
            val.id === e.familyCode
        )
    );
    //productFam.current.state.selectedValues = removeDefaultFamCode;
    //setDefaultFamily(removeDefaultFamCode);*/
      const finalArrayFam  =  Array.isArray(familyCode) &&familyCode.filter(function (o1) {
        return !familyCodeTag.some(function (o2) {
            return o1.familyCode === o2.id; // return the ones with equal id
       });
      });
      familyCode=finalArrayFam;
      Array.isArray(familyCode) &&
      familyCode.length > 0 &&
      familyCode.sort((a, b) => (a.familyCode > b.familyCode ? 1 : -1));
      const uniqueFinalFamilyCode = getUniqueListBy(familyCode, "familyCode");
      setProductFamilyCode(uniqueFinalFamilyCode);   
  };

  const selectFamilyCode = (list, chk_act) => {
    let productFamilyCode = [];
    Array.isArray(list)&&list.length>0 &&
      list?.map((item) => {//NOSONAR
        if(item&&item.familyCode){
          productFamilyCode = [...productFamilyCode, item.familyCode];
        }
      });//NOSONAR
     // productFam.current.state.selectedValues = [];  
      //productFam.current.state.options = [];  
      //setDefaultFamily([]);
      const finalFamilySelectionRemove = productFam.current.state.options.filter((e) =>
           list.some((val) => val.familyCode !== e.familyCode)
        );
        setTimeout(() => {
           setDefaultFamily([]);
           setProductFamilyCode(finalFamilySelectionRemove);
           productFam.current.state.selectedValues = [];  
        }, 10)
   // setDefaultFamily(list);
    if(Array.isArray(familyCodeTag)&&chk_act&&chk_act.familyCode){
     const finalFamilyCode = [...familyCodeTag,{ id: chk_act.familyCode, name: chk_act.familyCode}];
     if(finalFamilyCode){
      const uniqueFinalFamilyCode = getUniqueListBy(finalFamilyCode, "id");
      setFamilyCodeTag(uniqueFinalFamilyCode);
     }
    }
  };
  const removeFamilyCode = (list, chk_act) => {
    let productFamilyCode = [];
    Array.isArray(list) &&
      list?.map((item) => {//NOSONAR
        productFamilyCode = [...productFamilyCode, item.familyCode];
      });//NOSONAR
    //setDefaultFamily(list);
    if(Array.isArray(familyCodeTag)&&chk_act&&chk_act.familyCode){
     // const finalFamilyCode = familyCodeTag.find((x) => x.id !== chk_act.familyCode);
      const finalFamilyCode = familyCodeTag.filter((e) =>
         // getFamilyIds.some((val) => val.id !== e.familyCode)
         e.id !== chk_act.familyCode
        );
      if(finalFamilyCode){
        const uniqueFinalFamilyCode = getUniqueListBy(finalFamilyCode, "id");
        setFamilyCodeTag(uniqueFinalFamilyCode);
      }
     }
  };
  return (
    <Modal
      modalOpen={modalOpen}
      modalClose={modalRefresh}
      heading={
        productFamily?.length > 0
          ? "Edit Product Family Code"
          : "Select Product Family Code"
      }
      className={"modal_box"}
      modalSize={styles.custom_modal_size_family}
      reset={reset}
    >
      <div className={`pl-2 ${styles.patient_content} addFamily`}>
        <MultiSelect
          id={"productDivision"}
          name={"productDivision"}
          label={`Product division:`}
          classes={{
            column: `col-12 ${styles.col_spec}`,
            inputClassName: styles.basic_select,
            labelClassName: `col-3 col-form-label ${styles.label_spec} onselect`,
            inputColumn: `col-8 p-0 ${styles.select_wrapper}`,
          }}
          optionValue={productDivision}
          displayValue="value"
          showCheckbox={true}
          onSelect={(list) => getProductDivision(list)}
          onRemove={(list) => getProductDivision(list)}
          defaultValue={Array.isArray(defaultDivision) ? defaultDivision : []}
          validator={(e) => {
            productDiv.current = e;
          }}
        />
        <MultiSelect
          id={"productCategoryDesc"}
          name={"productCategoryDesc"}
          label={`Product category description:`}
          classes={{
            column: `col-12 ${styles.col_spec}`,
            inputClassName: styles.basic_select,
            labelClassName: `col-3 col-form-label ${styles.label_spec} onselect`,
            inputColumn: `col-8 p-0 ${styles.select_wrapper}`,
          }}
          optionValue={productCat}
          displayValue="value"
          showCheckbox={true}
          onSelect={(list) => getProductCat(list)}
          onRemove={(list) => getProductCat(list)}
          defaultValue={
            Array.isArray(defaultProductCategoryDesc)
              ? defaultProductCategoryDesc
              : []
          }
          validator={(e) => {
            productCate.current = e;
          }}
        />
        <MultiSelect
            id={"producyFamily"}
            name={"producyFamily"}
            label={`Product family:`}
            classes={{
              column: `col-12 ${styles.col_spec}`,
              inputClassName: styles.basic_select,
              labelClassName: `col-3 col-form-label ${styles.label_spec} onselect`,
              inputColumn: `col-8 p-0 ${styles.select_wrapper}`,
            }}
            optionValue={productFamilyCode}
            displayValue="valueCode"
            showCheckbox={true}
            onSelect={selectFamilyCode}
            onRemove={removeFamilyCode}
            defaultValue={defaultFamily}
            validator={(e) => {
              productFam.current = e;
            }}
            closeOnSelect={false}
          />
        <div className={`col-12 ${styles.col_spec}`}>
        <label className={`col-3 col-form-label ${styles.label_spec}`}>Selected product family code: </label>
        <div className="col-9 p-0">
        <ReactTags
        ref={familyCodeTagsRef}
        tags={familyCodeTag}
        suggestions={familyCodeSuggestions}
        onDelete={onDelete}
        onAddition={onAddition}
        classNames={{ root: `${styles.root_tag}` }}
        placeholderText={"Select product family code"}
        allowBackspace={false}
        readOnly={false}
        maxSuggestionsLength ={10}
        minQueryLength ={1}
        />
        </div>
        </div>
       
        <div className="col-sm-12">
          <div className="text-center pb-4">
            <Button
              classes={{
                className: `btn ${styles.btn_custom} ${styles.btn_small}`,
              }}
              type="button"
              id={SUBMIT_ID}
              onClick={() => addFamilyCode()}
            >
              {SAVE_BTN}
            </Button>
            <Button
              classes={{
                className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} handleCancel`,
              }}
              id={CANCEL_ID}
              onClick={handleCancel}
            >
              {CANCEL_BTN}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
AddFamily = connect()(withRouter(AddFamily));
