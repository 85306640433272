import React, { useEffect, useState, useRef } from 'react';
import style from './debridement.module.scss';
import { Button, Form, SelectBox, RadioButton, MultiSelect, TextField } from '../../primary';
import { TreatmentDropDownItem } from '../../secondary';
import { CANCEL_BTN, SAVE_BTN, MULTISELECT_SUB_LABLE } from '../../../constants';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  surgicalDebridementPerformed, debridementPerformedBy, nonExcisionalApproach, typeProcedure, DepthofDebridement,
  debridedNecroticSkin,
  signedLockedAccess,
  getUniqueListBy
} from '../../../utils';
import { assessmentRbacChecker } from '../../../utils/rbac';
import { useParams } from 'react-router-dom';
import { getdebridementAsync, getlistOptionsAsync, optionTypeAsync, patchDebridementAsync,setdebridementListoptions} from '../../../app/patientSlice';

export const Debridement = ({ interventionId, assessmentDetails,formularyId}) => {
  useEffect(() => {
    dispatch(setdebridementListoptions({}));
    if(formularyId!==null){
      const payload = {
        'formularyid':formularyId,
        'option': 'debridement'
      }
      dispatch(getlistOptionsAsync(payload));
    }
  }, [formularyId])
  const { register, handleSubmit, reset, errors, getValues } = useForm();
  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const patientData = useSelector(state => state.patient.detailViewData)
  const userDetails = useSelector(state => state.user);
  const hideButtons = assessmentRbacChecker(userDetails?.role, patientData?.isOwnCustomer);
  const listViewRef = useRef();
  const instrumentsUsedRef = useRef();
  const necroticTissueRef = useRef();
  const notapplicableRef = useRef()

  const [listViewMenuData, setListViewMenuDatta] = useState([]);
  const [necroticTissueTmp, setnecroticTissueTmp] = useState([]);
  const [instrumentsUsedTemp, setInstrumentsUsedTemp] = useState([]);

  // const userDetails = useSelector(state => state.user);
  const resp = useSelector(state => state.patient.debridement);
  const listOptionsresp = useSelector(state => state.patient.debridementListoptions);
  const debridementperformedbyOptions = useSelector(state => state.patient.debridementperformedby);
  const nonexcisionalapproachOptions = useSelector(state => state.patient.nonexcisionalapproach);
  const surgicaldebridementprocedureOptions = useSelector(state => state.patient.surgicaldebridementprocedure);
  const depthofdebridementOptions = useSelector(state => state.patient.depthofdebridement);
  const instrumentsusedOptions = useSelector(state => state.patient.instrumentsused);
  const necroticsubcutaneoustissueOptions = useSelector(state => state.patient.necroticsubcutaneoustissue);
  const necrotictissueOptions = useSelector(state => state.patient.necrotictissue);
  const postdebridementbleedingamountOptions = useSelector(state => state.patient.postdebridementbleedingamount);
  const techniqueusedOptions = useSelector(state => state.patient.techniqueusedOptions);
  const notapplicableOptions = useSelector(state => state.patient.notapplicableOptions);

  const [editable, setEditable] = useState(false);
  const [noDataMsg, setnoDataMsg] = useState(false);
  const [additionalQuestions, setAdditionalQuestions] = useState(false);
  const [excisionalDisable, setexcisionalDisable] = useState(false);
  const [instrumentsUsed, setInstrumentsUsed] = useState(false);
  const [ftNecroticSubcutaneousTissue, setFtNecroticSubcutaneousTissue] = useState(false);
  const [necroticTissueView, setNecroticTissueView] = useState(false);
  const [na, setNa] = useState(false);
  const [techniqueUsed, setTechniqueUsed] = useState(false);
  const [stoppedBleeding, setStoppedBleeding] = useState(false);
  const [customErrors, setCustomErrors] = useState(false);
  const [customErrorsMsg, setCustomErrorsMsg] = useState(false);
  const [customErrcolor, setCustomErrcolor] = useState(false);
  const [performedByValue, setperformedByValue] = useState();
  const [debridementProcedureValue, setdebridementProcedureValue] = useState();
  const [nonExcisionalShow, setNonExcisionalShow] = useState();
  const [deepestLevelCom, setDeepestLevelCom] = useState();
  const [getValue, setGetValue] = useState([]);
  const [instrumentsUsedDefault, setInstrumentsUsedDefault] = useState(resp?.instrumentsUsed)
  const [subcutaneousTissuerespDefault, setSubcutaneousTissuerespDefault] = useState(resp?.necroticSubcutaneousTissue)
  const [defaultValNecroticTissue, setdefaultValNecroticTissue] = useState(resp?.necroticTissue)
  const [defaultValNecroticSubTissue, setDefaultValNecroticSubTissue] = useState(resp?.necroticSubcutaneousTissue)
  const [defaultValnotapplicable, setDefaultValNotapplicable] = useState(resp?.notApplicable)
  const [notapplicableTmp, setNotapplicableTmp] = useState()
  const [surfaceAreaCheck, setSurfaceAreaCheck] = useState(false);
  const [prodCatCheck, setProdCatCheck] = useState(false);
  const [nonExcisionalDisabledValues, setNonExcisionalDisabledValues] = useState('');
  const editMode = () => {
    setEditable(true);
    setInstrumentsUsedDefault(resp?.instrumentsUsed)
    setInstrumentsUsedTemp(resp?.instrumentsUsed)
    setListViewMenuDatta(resp?.necroticSubcutaneousTissue)
    setnecroticTissueTmp(resp?.necroticTissue)
    setNotapplicableTmp(resp?.notApplicable)
    setDefaultValNecroticSubTissue(resp?.necroticSubcutaneousTissue)
    setdefaultValNecroticTissue(resp?.necroticTissue)
    setDefaultValNotapplicable(resp?.notApplicable)
    resp?.isDebridementPerformed === 'yes' ? setAdditionalQuestions(true) : setAdditionalQuestions(false);
    if (performedByValue === 'ptorrn-certified(cwswccwocn)' && debridementProcedureValue === 'excisional(cutaway-greaterthan1.0cm²)') {
      //setexcisionalDisable(true)
      setDeepestLevelCom(true)
      /*setTimeout(() => {
        setNonExcisionalShow(true)
      }, 10);*/
    }
    if (performedByValue === 'ptorrn-certified(cwswccwocn)' && debridementProcedureValue === 'non-excisional(abradescrapesnippick)') {
      setexcisionalDisable(false)
      setNonExcisionalShow(true)
    }
    if (performedByValue !== 'ptorrn-certified(cwswccwocn)' && debridementProcedureValue === 'excisional(cutaway-greaterthan1.0cm²)') {
      setNonExcisionalShow(false)
      setDeepestLevelCom(true)
    }
    if (performedByValue !== 'ptorrn-certified(cwswccwocn)' && debridementProcedureValue === 'non-excisional(abradescrapesnippick)') {
      setNonExcisionalShow(true)
      setDeepestLevelCom(false)
      setexcisionalDisable(false);
    }
    if (resp?.depthOfDebridement !== 'na') {
      //setDefaultValNotapplicable(true)
      setNotapplicableTmp([])
    }
    resp?.postDebridementBleedingAmount === 'small' || resp?.postDebridementBleedingAmount === 'moderate' || resp?.postDebridementBleedingAmount === 'large' ? setStoppedBleeding(true) : setStoppedBleeding(false);
    resp?.postDebridementBleedingAmount === 'none' ? setTechniqueUsed(true) : setTechniqueUsed(false);
    resp?.depthOfDebridement === 'na' || resp?.depthOfDebridement === 'fullthickness-necrotictissue' || resp?.depthOfDebridement === 'fullthickness-necroticsubcutaneoustissue' || resp?.depthOfDebridement === 'superficial-skin' || resp?.depthOfDebridement === 'partialthickness-necroticskin' ? setInstrumentsUsed(true) : setInstrumentsUsed(false);
    //resp?.notApplicable?.length !== 0 && setDefaultValNotapplicable(false)

    resp?.depthOfDebridement === 'fullthickness-necroticsubcutaneoustissue' ? setFtNecroticSubcutaneousTissue(true) : setFtNecroticSubcutaneousTissue(false);
    resp?.depthOfDebridement === 'fullthickness-necrotictissue' ? setNecroticTissueView(true) : setNecroticTissueView(false);
    resp?.depthOfDebridement === 'na' ? setNa(true) : setNa(false);
    resp?.surgicalDebridementProcedure === 'excisional(cutaway-greaterthan1.0cm²)' ? setDeepestLevelCom(true) : setDeepestLevelCom(false);
    resp?.surgicalDebridementProcedure === 'non-excisional(abradescrapesnippick)' ? setNonExcisionalShow(true) : setNonExcisionalShow(false);
    setTimeout(() => {
      if(document.getElementById('deepestLevel')&&resp?.depthOfDebridement){
        document.getElementById('deepestLevel').value=resp?.depthOfDebridement
      }
    }, 10);
   
    if(resp?.depthOfDebridement&&resp?.depthOfDebridement!==''){
      setSurfaceAreaCheck(true)
      setInstrumentsUsed(true)
    }else{
      setInstrumentsUsed(false)
    }
    if(resp?.isDebridementPerformed){
      setProdCatCheck(true)
    }
    if(resp?.isDebridementPerformed === 'yes'){
      setNonExcisionalDisabledValues('')
      setTimeout(() => {
        if(document.getElementById('surgicalDebridementPerformed')){
          document.getElementById('surgicalDebridementPerformed').value='yes'
        }else{
          document.getElementById('surgicalDebridementPerformed').value='no'
        }
        //debridementPerformedBy
        if(resp?.debridementPerformedBy){
          if(document.getElementById('performed')){
            document.getElementById('performed').value=resp?.debridementPerformedBy
          }
          if(resp?.debridementPerformedBy==='ptorrn-certified(cwswccwocn)'){
            setNonExcisionalShow(true)
            if(document.getElementById('nonExcisionalId')){
              document.getElementById('nonExcisionalId').value=resp?.nonExcisionalApproach
            }
            if(resp?.surgicalDebridementProcedure==='non-excisional(abradescrapesnippick)'){
              setNonExcisionalDisabledValues('percutaneous')
            }else{
              setNonExcisionalDisabledValues('open')
            }
          }
        }
        //surgicalDebridementProcedure
        if(resp?.surgicalDebridementProcedure){
          if(document.getElementById('excisional')){
            document.getElementById('excisional').value=resp?.surgicalDebridementProcedure
          }
          if(resp?.surgicalDebridementProcedure==='non-excisional(abradescrapesnippick)'&&resp?.nonExcisionalApproach){
            if(document.getElementById('nonExcisionalId')){
              document.getElementById('nonExcisionalId').value=resp?.nonExcisionalApproach
            }
          }else{
            if(document.getElementById('text')&&resp?.surfaceAreaOfNecroticSkinTissue){
              document.getElementById('text').value=resp?.surfaceAreaOfNecroticSkinTissue
            }
            if(document.getElementById('subqTissue')&&resp?.surfaceAreaOfNecroticSubqTissue){
              document.getElementById('subqTissue').value=resp?.surfaceAreaOfNecroticSubqTissue
            }
            if(document.getElementById('bleedingAmount')&&resp?.postDebridementBleedingAmount){
              document.getElementById('bleedingAmount').value=resp?.postDebridementBleedingAmount
            }
            if(resp?.postDebridementBleedingAmount&&resp?.postDebridementBleedingAmount==='none'){
              if(document.getElementById('techniqueUsed')&&resp?.techniqueUsed){
                document.getElementById('techniqueUsed').value=resp?.techniqueUsed
              }
            }
            if(resp?.postDebridementBleedingAmount&&resp?.postDebridementBleedingAmount!=='none'){
              if(document.getElementById('SPWConactPressure0')&&resp?.stoppedBleedingContactPressure){
                document.getElementById('SPWConactPressure0').value=resp?.stoppedBleedingContactPressure
                document.getElementById('SPWConactPressure0').click()
              }
            }
          }
        }
      }, 10);
    }
    if (resp?.productCategory?.length > 0) {
      setGetValue(resp?.productCategory)
    }
  }
  const viewMode = () => {
    
    if (resp === null) {
      return false
    } else {
      reset();
      setTimeout(() => {
        setAdditionalQuestions(false)
        setInstrumentsUsed(false);
        setFtNecroticSubcutaneousTissue(false);
        setNecroticTissueView(false);
        setNa(false);
        setTechniqueUsed(false);
        setStoppedBleeding(false);
        setdefaultValNecroticTissue(false)
        setEditable(false);
      }, 10);
    }

  }
  const getPrimaryGroup = (e) => {
    e.target.value === 'yes' ? setAdditionalQuestions(true) : setAdditionalQuestions(false);
    if (e?.target?.value === 'no') {
      setSubcutaneousTissuerespDefault([])
      setInstrumentsUsed(false)
      reset({ ...getValues(), DebridementPerformed: '', performedExcisional: '', deepestLevel: '', DebridedOfNecroticSkin: '' });
    }
    setProdCatCheck(true)
  }

  const surgicalPerformedBy = (e) => {
    const changedVal=e.target.value
    reset({ 
      ...getValues(), 
      performedExcisional: '', 
      nonExcisional: '', 
      deepestLevel: '', 
    //  FTNecroticSubcutaneousTissue: '' ,
    //  necroticTissue: '',
    //  na: '',
    // instrumentsUsed: '',
      DebridedOfNecroticSkin: '',
      subqTissue: '',
      bleedingAmount: '',
      techniqueUsed: '',
      SPWConactPressure: ''
    });
  //  listViewRef.current.resetSelectedValues()
  //  necroticTissueRef.current.resetSelectedValues()
  //  notapplicableRef.current.resetSelectedValues()
    instrumentsUsedRef?.current?.resetSelectedValues([])//NOSONAR
    setInstrumentsUsedTemp([])
    setInstrumentsUsedDefault(resp?.instrumentsUsed)
    setexcisionalDisable(false)
    setperformedByValue(changedVal)
    if (changedVal !== 'ptorrn-certified(cwswccwocn)' && debridementProcedureValue === 'excisional(cutaway-greaterthan1.0cm²)') {
      setNonExcisionalShow(false)
      setDeepestLevelCom(true)
    }
    if (changedVal !== 'ptorrn-certified(cwswccwocn)' && debridementProcedureValue === 'non-excisional(abradescrapesnippick)') {
      setNonExcisionalShow(true)
      setexcisionalDisable(false);
      setDeepestLevelCom(false)
    }
    if (changedVal === 'ptorrn-certified(cwswccwocn)' && debridementProcedureValue === 'excisional(cutaway-greaterthan1.0cm²)') {
     // setexcisionalDisable(true);
      setNonExcisionalShow(true);
      setDeepestLevelCom(true)
      setTimeout(() => {
        if (document.getElementById("nonExcisionalId")) {
          document.getElementById('nonExcisionalId').value = 'percutaneous'
        }
      }, 10)
    }
    if (changedVal === 'ptorrn-certified(cwswccwocn)' && debridementProcedureValue === 'non-excisional(abradescrapesnippick)') {
      setexcisionalDisable(false);
      setNonExcisionalShow(true);
      setDeepestLevelCom(false)
      if (document.getElementById("nonExcisionalId")) {
        document.getElementById('nonExcisionalId').value = resp?.nonExcisionalApproach
      }
    }
  }

  const debrideProcedureChange = (e) => {
    setNonExcisionalDisabledValues('')
    setdebridementProcedureValue(e.target.value)
    setInstrumentsUsedDefault(resp?.instrumentsUsed)
    setexcisionalDisable(false)
    if (performedByValue !== 'ptorrn-certified(cwswccwocn)' && e.target.value === 'excisional(cutaway-greaterthan1.0cm²)') {
      setNonExcisionalShow(false)
      setDeepestLevelCom(true)
      if (document.getElementById("nonExcisionalId")) {
        document.getElementById('nonExcisionalId').value = ''
      }
      // nonExcisional
      // reset({ ...getValues(), nonExcisional: {} });
    }
    if (performedByValue !== 'ptorrn-certified(cwswccwocn)' && e.target.value === 'non-excisional(abradescrapesnippick)') {
      //setDefaultValNecroticSubTissue(true)
      //setDefaultValNotapplicable(true)
      setInstrumentsUsedDefault(resp?.instrumentsUsed)
      setInstrumentsUsedTemp([])
      reset({ ...getValues(), deepestLevel: '' });

      setNonExcisionalShow(true)
      setDeepestLevelCom(false)
     // setexcisionalDisable(true)
      setTimeout(() => {
        if (document.getElementById("nonExcisionalId")) {
          document.getElementById('nonExcisionalId').value = resp?.nonExcisionalApproach ? resp?.nonExcisionalApproach : 'percutaneous'
        }
      }, 10)
    }
    if (performedByValue === 'ptorrn-certified(cwswccwocn)' && e.target.value === 'excisional(cutaway-greaterthan1.0cm²)') {
     // setexcisionalDisable(true)
     // setNonExcisionalShow(true)
     setNonExcisionalShow(true)
      setDeepestLevelCom(true)
      setTimeout(() => {
        if (document.getElementById("nonExcisionalId")) {
          document.getElementById('nonExcisionalId').value = 'percutaneous'
          setNonExcisionalDisabledValues('open')
        }
      }, 10)
    }
    if (performedByValue === 'ptorrn-certified(cwswccwocn)' && e.target.value === 'non-excisional(abradescrapesnippick)') {
     // setDefaultValNecroticSubTissue(true)
      //setDefaultValNotapplicable(true)
      setInstrumentsUsedDefault(resp?.instrumentsUsed)
      setInstrumentsUsedTemp([])
      setListViewMenuDatta([])

      
      setDeepestLevelCom(false)
      setNonExcisionalShow(true)
      if (document.getElementById("nonExcisionalId")) {
        document.getElementById('nonExcisionalId').value ='open'
        setNonExcisionalDisabledValues('percutaneous')
      }
    }
    
  }

  const performedExcisionalChange = (e) => {
    const getVal = e.target.value
    if (getVal !== '') {
      setFtNecroticSubcutaneousTissue(false)
      setNecroticTissueView(false);
      setNa(false);
      if(getVal!==''){
        setInstrumentsUsed(true)
      }else{
        setInstrumentsUsed(false)
      }
    }
    if (getVal == '') {
      setFtNecroticSubcutaneousTissue(false)
      setNecroticTissueView(false);
      setNa(false);
      setInstrumentsUsed(false)
      setnecroticTissueTmp([])
      setNotapplicableTmp([])
    }
    if (getVal === 'fullthickness-necroticsubcutaneoustissue') {
      setFtNecroticSubcutaneousTissue(true)
      setNecroticTissueView(false);
      setNa(false);
      // defaulte val reset
      setdefaultValNecroticTissue(true)
      //setDefaultValNotapplicable(true)
      setnecroticTissueTmp([])
      setNotapplicableTmp([])
    }
    if (getVal === 'fullthickness-necrotictissue') {
      setNecroticTissueView(true)
      setFtNecroticSubcutaneousTissue(false)
      setNa(false);
      // defaulte val reset
      //setDefaultValNecroticSubTissue(true)
     // setDefaultValNotapplicable(true)
      setListViewMenuDatta([])
      setNotapplicableTmp([])
    }
    if (getVal === 'na') {
      setNa(true)
      setNecroticTissueView(false);
      setFtNecroticSubcutaneousTissue(false)
      // defaulte val reset
      setdefaultValNecroticTissue(true)
     // setDefaultValNecroticSubTissue(true)
      setListViewMenuDatta([])
      setnecroticTissueTmp([])
    }
    
    setSurfaceAreaCheck(true)
  }

  const postBleedingSelect = (e) => {
    const selectVal = e.target.value
    if (selectVal === 'small' || selectVal === 'moderate' || selectVal === 'large') {
      setStoppedBleeding(true)
      setTechniqueUsed(false)
      // reset data
      reset({ ...getValues(), techniqueUsed: '' });
    }
    if (selectVal === 'none') {
      setTechniqueUsed(true)
      setStoppedBleeding(false);
      // reset data
      reset({ ...getValues(), SPWConactPressure: '' });
    }
  }

  const [multiVal, setMultiVal] = useState([]);

  // const multiItems = (allItems, lastItem) => {
  //   if (allItems.length !== 0) {
  //     setCustomErrcolor(false)
  //     setCustomErrors(false)
  //   } 
  //   setMultiVal(allItems)
  // }

  const handleListViewMenuChange = (e) => {

    if (e && e?.length > 0) {
      let arr = [];
      e && Array.isArray(e) && e?.map((data) => {//NOSONAR
        if (data.key !== 'none') {
          arr.push(data.key)
        }
        if (data.key === 'none') {
          listViewRef.current.resetSelectedValues()
          arr = [];
          arr.push(data.key)
        }
      })//NOSONAR
      setListViewMenuDatta(arr)
    }
    else {
      setListViewMenuDatta([])
    }
  }
  // necroticTissue select function
  const necroticTissueChange = (e) => {
    if (e && e?.length > 0) {
      let arr = [];
      e && Array.isArray(e) && e?.map((data) => {//NOSONAR
        if (data.key !== 'none') {
          arr.push(data.key)
        }
        if (data.key === 'none') {
          necroticTissueRef.current.resetSelectedValues()
          arr = [];
          arr.push(data.key)
        }
      })//NOSONAR
      setnecroticTissueTmp(arr)
    }
    else {
      setnecroticTissueTmp([])
    }
  }
  // onSelect instrumentsUsed
  const instrumentsUsedChange = (e) => {
    if (e && e?.length > 0) {
      let arr = [];
      e && Array.isArray(e) && e?.map((data) => {//NOSONAR
        if (data.key !== 'none') {
          arr.push(data.key)
        }
        if (data.key === 'none') {
          instrumentsUsedRef.current.resetSelectedValues()
          arr = [];
          arr.push(data.key)
        }
      })//NOSONAR
      setInstrumentsUsedTemp(arr)
    }
    else {
      setInstrumentsUsedTemp([])
    }
  }
  // na select function
  const notapplicableSelect = (e) => {
    if (e && e?.length > 0) {
      let arr = [];
      e && Array.isArray(e) && e?.map((data) => {//NOSONAR
        if (data.key !== 'none') {
          arr.push(data.key)
        }
        if (data.key === 'none') {
          notapplicableRef.current.resetSelectedValues()
          arr = [];
          arr.push(data.key)
        }
      })//NOSONAR
      setNotapplicableTmp(arr)
    }
    else {
      setNotapplicableTmp([])
    }
  }



  const doSubmit = (data) => {
    const payloadData = {
      "isDebridementPerformed": data.surgicalDebridementPerformed ? data?.surgicalDebridementPerformed : '',
      "debridementPerformedBy": data.DebridementPerformed ? data?.DebridementPerformed : '',
      "surgicalDebridementProcedure": data.performedExcisional ? data?.performedExcisional : '',
      "nonExcisionalApproach": nonExcisionalShow ? data?.nonExcisional ? data.nonExcisional : 'percutaneous' : '',
      "necroticSubcutaneousTissue": listViewMenuData ? listViewMenuData : '',
      "necroticTissue": necroticTissueTmp ? necroticTissueTmp : '',
      "notApplicable": notapplicableTmp ? notapplicableTmp : [],
      "depthOfDebridement": data.deepestLevel ? data?.deepestLevel : '',
      "instrumentsUsed": instrumentsUsedTemp ? instrumentsUsedTemp : '',
      "surfaceAreaOfNecroticSkinTissue": data.DebridedOfNecroticSkin ? data?.DebridedOfNecroticSkin : '',
      "surfaceAreaOfNecroticSubqTissue": data.subqTissue ? data.subqTissue : '',
      "postDebridementBleedingAmount": data.bleedingAmount ? data?.bleedingAmount : '',
      "stoppedBleedingContactPressure": data.SPWConactPressure ? data?.SPWConactPressure : '',
      "techniqueUsed": data.techniqueUsed ? data?.techniqueUsed : '',
      "honeyDressing": null,
      "productCategory": getValue,
      'patientId': patientId,
      'assessmentId': assessmentId,
      'interventionId': interventionId
    }
    dispatch(patchDebridementAsync(payloadData))
    // if (multiVal.length == 0) {
    //   setCustomErrcolor(true)
    //   setCustomErrors(true)
    //   setCustomErrorsMsg(INSTRUMENTS_USED)
    //   return false;
    // } else {
    //   setCustomErrcolor(false)
    //   setCustomErrors(false)
    // }
    reset();
    setEditable(false);
    setAdditionalQuestions(false)
    setInstrumentsUsed(false);
    setFtNecroticSubcutaneousTissue(false);
    setNecroticTissueView(false);
    setNa(false);
    setTechniqueUsed(false);
    setStoppedBleeding(false);
    setdefaultValNecroticTissue(false)
  }

  useEffect(() => {
    //userDetails.formularyId == null || resp?.data == null ? setnoDataMsg(true) : setnoDataMsg(false);
    formularyId == null || resp?.data == null ? setnoDataMsg(true) : setnoDataMsg(false);
    setAdditionalQuestions(false)
    setInstrumentsUsed(false);
    setFtNecroticSubcutaneousTissue(false);
    setNecroticTissueView(false);
    setNa(false);
    setTechniqueUsed(false);
    setStoppedBleeding(false);
    reset();
    setCustomErrcolor(false)
    setCustomErrors(false)
  }, [])

  useEffect(() => {
    viewMode()
    dispatch(getdebridementAsync({ patientId, assessmentId, interventionId }));
  }, [interventionId])

  useEffect(() => {
    dispatch(optionTypeAsync('debridementperformedby'));
    dispatch(optionTypeAsync('nonexcisionalapproach'));
    dispatch(optionTypeAsync('surgicaldebridementprocedure'));
    dispatch(optionTypeAsync('depthofdebridement'));
    dispatch(optionTypeAsync('instrumentsused'));
    dispatch(optionTypeAsync('necroticsubcutaneoustissue'));
    dispatch(optionTypeAsync('necrotictissue'));
    dispatch(optionTypeAsync('postdebridementbleedingamount'));
    dispatch(optionTypeAsync('techniqueused'));
    dispatch(optionTypeAsync('notapplicable'));
  }, [interventionId])

  useEffect(() => {
    setperformedByValue(resp?.debridementPerformedBy);
    setdebridementProcedureValue(resp?.surgicalDebridementProcedure);
    if (resp?.productCategory?.length > 0) {
      setGetValue(resp?.productCategory)
    }
  }, [resp])

  useEffect(() => {
    resp == null ? setEditable(true) : setEditable(false)
  }, [resp])



  /*const getOptValue = (index, optionsVal) => {
    let getOpt = { ...getValue[index] }
    getOpt['value'] = optionsVal
    const getVal = [...getValue]
    getVal.splice(index, 1, getOpt)
    setGetValue(getVal)
  }*/
  const getOptValue = (index,name, optionsVal, isRecommended) => {
  let getOpt ={}
  getOpt['key'] = name
  if(optionsVal){
    getOpt['value'] = optionsVal
  }else{
    getOpt['value'] = ''
  }
  if(isRecommended){
    getOpt['isRecommended'] = isRecommended
  }else{
    getOpt['isRecommended'] = 0
  }


  const getVal = [...getValue,...[getOpt]]
  let finalVal = [];
  getVal.forEach((val,i)=>{
      if(val.key===getOpt.key){
        finalVal.push({...getOpt})
      }else{
        finalVal.push({...val})
      }
  })
  const uniqueList = getUniqueListBy(finalVal, 'key')
  setGetValue(uniqueList)
  }

  return <div>
    {
      !editable ?
        <div className={style.viewMode_container}>
          <div className="d-flex justify-content-between w-100">
            <div className={`${style.lab_container} w-100`}>
              {resp?.isDebridementPerformed&&<div className="pb-3">
                <label className="col-5 m-0 ">Was There Sharp/Surgical Debridement Performed: </label> <span className="col-7 p-0 font-weight-bold ">{resp?.isDebridementPerformed === 'no' ? 'No' : 'Yes'}</span>
              </div>}
              {
                resp?.isDebridementPerformed === 'yes' && <div>
                  <div className="pb-3 row pl-3">
                    <label className="col-5 m-0 ">Sharp/Surgical Debridement Performed By: </label> <span className="col-7 p-0 font-weight-bold ">
                      {/* {resp?.debridementPerformedBy} */}
                      {/* debridementperformedbyOptions */}
                      {
                        debridementperformedbyOptions && debridementperformedbyOptions?.length > 0 && debridementperformedbyOptions?.map((e) => {
                          let optionsValue = "";
                          if (resp?.debridementPerformedBy === e.key) {
                            optionsValue = e.value
                          } return <div className="font-weight-bold">
                            {optionsValue}
                          </div>
                        })
                      }
                    </span>
                  </div>
                  <div className="pb-3 row pl-3">
                    <label className="col-5 m-0 ">Type of Sharp/Surgical Debridement Procedure: </label> <span className="col-7 p-0 font-weight-bold ">
                      {
                        surgicaldebridementprocedureOptions?.length > 0 && surgicaldebridementprocedureOptions?.map((e) => {
                          let optionsValue = "";
                          if (resp?.surgicalDebridementProcedure === e.key) {
                            optionsValue = e.value
                          } return <div className="font-weight-bold">
                            {optionsValue}
                          </div>
                        })
                      }
                    </span>
                  </div>
                  {/* ============================================ */}

                  {
                    resp?.nonExcisionalApproach !== '' && <div className="pb-3 row pl-3">
                      <label className="col-5 m-0 ">Non-Excisional Approach: </label> <div className="col-7 p-0 font-weight-bold ">
                        {
                          nonexcisionalapproachOptions && nonexcisionalapproachOptions.length > 0 && nonexcisionalapproachOptions?.map((e) => {
                            let optionsValue = "";
                            if (resp?.nonExcisionalApproach === e.key) {
                              optionsValue = e.value
                            } return <div className="font-weight-bold">
                              {optionsValue}
                            </div>
                          })
                        }
                      </div>
                    </div>
                  }
                  {
                    resp?.depthOfDebridement !== '' && <div className="pb-3 row pl-3">
                      <label className="col-5 m-0 ">Depth of Debridement - Deepest Level: </label> <span className="col-7 p-0 font-weight-bold ">
                        {
                          depthofdebridementOptions?.length > 0 && depthofdebridementOptions?.map((e) => {
                            let optionsValue = "";
                            if (resp?.depthOfDebridement === e.key) {
                              optionsValue = e.value
                            } return <div className="font-weight-bold">
                              {optionsValue}
                            </div>
                          })
                        }
                      </span>
                    </div>
                  }

                  {/* ================== Necrotic Subcutaneous Tissue view ============ */}
                  {
                    resp?.necroticSubcutaneousTissue && resp?.necroticSubcutaneousTissue?.length !== 0 && <div className="pb-3 row pl-3">
                      <label className="col-5 m-0 ">Necrotic Subcutaneous Tissue: </label> <div className="col-7 p-0 font-weight-bold ">
                        {
                          resp?.necroticSubcutaneousTissue?.map(k => {
                            let optionValue = "";
                            necroticsubcutaneoustissueOptions && necroticsubcutaneoustissueOptions?.length > 0 && necroticsubcutaneoustissueOptions?.map((l) => { //NOSONAR
                              if (k == l.key) {
                                optionValue = l.value
                              }
                            })//NOSONAR
                            return <div className="font-weight-bold">{optionValue}</div>
                          })
                        }
                      </div>
                    </div>
                  }
                  {/* ============ Necrotic Tissue ==================*/}
                  {
                   Array.isArray(resp?.necroticTissue)&& resp?.necroticTissue?.length !== 0 && <div className="pb-3 row pl-3">
                      <label className="col-5 m-0 ">Necrotic Tissue: </label> <div className="col-7 p-0 font-weight-bold ">
                        {
                          // resp?.necroticTissue.map(e => <div>{e}</div>)
                          resp?.necroticTissue?.map(k => {//NOSONAR
                            let optionValue = "";
                            necrotictissueOptions?.length > 0 && necrotictissueOptions?.map((l) => {//NOSONAR
                              if (k == l.key) {
                                optionValue = l.value
                              }
                            })//NOSONAR
                            return <div className="font-weight-bold">{optionValue}</div>
                          })//NOSONAR
                        }
                      </div>
                    </div>
                  }

                  {/* ================ Not abplicable view =====================  */}

                  {
                    resp?.notApplicable?.length !== 0 && <div className="pb-3 row pl-3">
                      <label className="col-5 m-0 ">N/A: </label> <div className="col-7 p-0 font-weight-bold ">
                        {
                          // resp?.necroticTissue.map(e => <div>{e}</div>)
                          resp?.notApplicable?.map(k => {//NOSONAR
                            let optionValue = "";
                            notapplicableOptions?.length > 0 && notapplicableOptions?.map((l) => {//NOSONAR
                              if (k == l.key) {
                                optionValue = l.value
                              }
                            })//NOSONAR
                            return <div className="font-weight-bold">{optionValue}</div>
                          })//NOSONAR
                        }
                      </div>
                    </div>
                  }

                  {/* ================ Instruments Used view =====================  */}
                  {
                    resp?.instrumentsUsed?.length > 0 && <div className="pb-3 row pl-3">
                      <label className="col-5 m-0 ">Instruments Used: </label> <span className="col-7 p-0 font-weight-bold ">
                        {
                          resp?.instrumentsUsed?.map(k => {
                            let optionValue = "";
                            instrumentsusedOptions && instrumentsusedOptions?.length > 0 && instrumentsusedOptions?.map((l) => { //NOSONAR
                              if (k == l.key) {
                                optionValue = l.value
                              }
                            })//NOSONAR
                            return <div className="font-weight-bold">{optionValue}</div>
                          })
                        }

                      </span>
                    </div>
                  }


                  {
                    resp?.surfaceAreaOfNecroticSkinTissue !== "" && <div className="pb-3">
                      <label className="col-5 m-0 ">Surface Area Debrided of Necrotic Skin (cm²): </label> <span className="col-7 p-0 font-weight-bold ">
                        {resp?.surfaceAreaOfNecroticSkinTissue}
                      </span>
                    </div>
                  }
                  {/* Surface Area Debrided of Subq Tissue (cm²): */}
                  {
                    resp?.surfaceAreaOfNecroticSubqTissue !== "" && <div className="pb-3">
                      <label className="col-5 m-0 ">Surface Area Debrided of Subq Tissue (cm²): </label> <span className="col-7 p-0 font-weight-bold ">
                        {resp?.surfaceAreaOfNecroticSubqTissue}
                      </span>
                    </div>
                  }

                  {/* {
                    resp?.surgicalDebridementProcedure !== '' && <div className="pb-3">
                      <label className="col-5 m-0 ">Debridement performed excisional: </label> <span className="col-7 p-0 font-weight-bold ">{resp?.surgicalDebridementProcedure}</span>
                    </div>
                  } */}

                  {
                    resp?.postDebridementBleedingAmount !== '' && <div className="pb-3 row pl-3">
                      <label className="col-5 m-0 ">Post Debridement Bleeding Amount: </label> <span className="col-7 p-0 font-weight-bold ">
                        {
                          postdebridementbleedingamountOptions?.length > 0 && postdebridementbleedingamountOptions?.map((e) => {
                            let optionsValue = "";
                            if (resp?.postDebridementBleedingAmount === e.key) {
                              optionsValue = e.value
                            } return <div className="font-weight-bold">
                              {optionsValue}
                            </div>
                          })
                        }


                      </span>
                    </div>
                  }
                  {
                    resp?.techniqueUsed !== '' && <div className="pb-3 row pl-3">
                      <label className="col-5 m-0 ">Technique Used: </label> <span className="col-7 p-0 font-weight-bold ">
                        {
                          techniqueusedOptions?.length > 0 && techniqueusedOptions?.map((e) => {
                            let optionsValue = "";
                            if (resp?.techniqueUsed === e.key) {
                              optionsValue = e.value
                            } return <div className="font-weight-bold">
                              {optionsValue}
                            </div>
                          })
                        }
                      </span>
                    </div>
                  }
                  {
                    resp?.stoppedBleedingContactPressure !== "" && <div className="pb-3">
                      <label className="col-5 m-0 ">Stopped Bleeding with Contact Pressure: </label> <span className="col-7 p-0 font-weight-bold ">
                        {resp?.stoppedBleedingContactPressure}
                      </span>
                    </div>
                  }


                </div>
              }

              {
                !noDataMsg&&resp && Array.isArray(resp.productCategory) &&resp.productCategory===0? <div className={style.nodata_msg}>Your Formularys doesn't have any Debridement, please contact your Medline Sales Rep</div> :
                  <div>
                    {
                      resp && Array.isArray(resp.productCategory) && resp?.productCategory?.map(e => {
                        const getVal = listOptionsresp?.productCategory?.find((x) => x.key === e.key)
                        const getValOption = getVal?.options?.find((x) => x.materialNumber === e.value)
                        return <div>
                          <div className="d-flex">
                            <div className='col-5 '>{getVal?.name}: </div>
                            <div className="col-6 p-0 font-weight-bold">
                              <div>{getValOption?.productFamilyDesc}</div>
                              <div>{getValOption?.materialDescription}</div>
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </div>
              }
            </div>
            <div>
              {
                !editable && signedLockedAccess(assessmentDetails?.assessmentStage) && hideButtons && <div className={style.tab_edit} onClick={() => editMode()}><img src={editIcon} alt="icon" className={style.e_icon_space} />Edit</div>
              }
            </div>
          </div>
        </div> :
        <div className={style.cont_spac} >
          {signedLockedAccess(assessmentDetails?.assessmentStage) ?
            <Form formChanges={true} onSubmit={handleSubmit(doSubmit)}>
              {/* Was There Surgical Debridement Performed? */}
              <SelectBox
                id={"surgicalDebridementPerformed"}
                name={"surgicalDebridementPerformed"}
                label={"Was There Sharp/Surgical Debridement Performed?"}
                classes={{
                  column: `row ${style.botton_spac} mb-0`,
                  inputClassName: `${style.custom_basic_select} ${style.select_wrapper}`,
                  labelClassName: `col-5 ${style.label_spec}`,
                  inputColumn: `col-6 p-0`
                }}
                optionValue={[
                  { key: 'yes', value: 'Yes' },
                  { key: 'no', value: 'No' }
                ]}
                defaultValue={resp && resp?.isDebridementPerformed}
                required={true}
                errors={errors}
                validator={register(surgicalDebridementPerformed)}
                placeholder={'Select'}
                onChange={(e) => getPrimaryGroup(e)}
              />
              {
                additionalQuestions ? <div>
                  {/* Sharp/Surgical Debridement Performed By */}
                  <SelectBox
                    id={"performed"}
                    name={"DebridementPerformed"}
                    label={"Sharp/Surgical Debridement Performed By:"}
                    classes={{
                      column: `row ${style.botton_spac}`,
                      inputClassName: `${style.basic_select} ${style.select_wrapper}`,
                      labelClassName: `col-5 ${style.label_spec}`,
                      inputColumn: `col-6 p-0`
                    }}
                    optionValue={debridementperformedbyOptions}
                    defaultValue={resp && resp?.debridementPerformedBy}
                    required={true}
                    errors={errors}
                    validator={register(debridementPerformedBy)}
                    placeholder={'Select'}
                    onChange={(e) => surgicalPerformedBy(e)}
                  />
                  {/* Type of Sharp/Surgical Debridement Procedure */}
                  <SelectBox
                    id={"excisional"}
                    name={"performedExcisional"}
                    label={"Type of Sharp/Surgical Debridement Procedure:"}
                    classes={{
                      column: `row ${style.botton_spac}`,
                      inputClassName: `${style.basic_select} ${style.select_wrapper}`,
                      labelClassName: `col-5 ${style.label_spec}`,
                      inputColumn: `col-6 p-0`
                    }}
                    optionValue={surgicaldebridementprocedureOptions}
                    defaultValue={resp && resp?.surgicalDebridementProcedure}
                    validator={register(typeProcedure)}
                    errors={errors}
                    placeholder={'Select'}
                    onChange={(e) => debrideProcedureChange(e)}
                    required={true}
                  />

                  {
                    nonExcisionalShow && <SelectBox
                      id={"nonExcisionalId"}
                      name={"nonExcisional"}
                      label={"Non-Excisional Approach:"}
                      classes={{
                        column: `row ${style.botton_spac}`,
                        inputClassName: `${style.custom_basic_select} ${style.select_wrapper}`,
                        labelClassName: `col-5 ${style.label_spec}`,
                        inputColumn: `col-7 p-0`
                      }}
                      optionValue={nonexcisionalapproachOptions}
                      defaultValue={resp?.nonExcisionalApproach}
                      required={true}
                      errors={errors}
                      validator={register(nonExcisionalApproach)}
                      placeholder={'Select'}
                      disabled={excisionalDisable}
                      disabledValues={nonExcisionalDisabledValues}
                    />
                  }

                  {
                    deepestLevelCom && <SelectBox
                      id={"deepestLevel"}
                      name={"deepestLevel"}
                      label={"Depth of Debridement - Deepest Level:"}
                      classes={{
                        column: `row ${style.botton_spac}`,
                        inputClassName: `${style.basic_select} ${style.select_wrapper}`,
                        labelClassName: `col-5 ${style.label_spec}`,
                        inputColumn: `col-6 p-0 `
                      }}
                      optionValue={depthofdebridementOptions}
                      required={true}
                      errors={errors}
                      validator={register(DepthofDebridement)}
                      placeholder={'Select'}
                      onChange={performedExcisionalChange}
                      defaultValue={resp?.depthOfDebridement && resp.depthOfDebridement}
                    />
                  }

                  {
                    deepestLevelCom && ftNecroticSubcutaneousTissue && <MultiSelect
                      id={"FTNecroticSubcutaneousTissue"}
                      name={"FTNecroticSubcutaneousTissue"}
                      label={"Necrotic Subcutaneous Tissue:"}
                      classes={{
                        column: ` row ${style.botton_spac}`,
                        inputClassName: `${style.basic_select}`,
                        labelClassName: `col-5 col-form-label ${style.label_spec}`,
                        inputColumn: `col-6 p-0 ${style.select_wrapper}`,
                      }}
                      optionValue={necroticsubcutaneoustissueOptions}
                      showCheckbox={true}
                      displayValue="value"
                      validator={(e) => {
                        register({ required: true })
                        listViewRef.current = e
                      }}
                      onSelect={handleListViewMenuChange}
                      onRemove={handleListViewMenuChange}
                      //defaultValue={defaultValNecroticSubTissue ? [] : resp?.necroticSubcutaneousTissue}
                      defaultValue={Array.isArray(defaultValNecroticSubTissue)?defaultValNecroticSubTissue:[]}
                      labelSubText={MULTISELECT_SUB_LABLE}
                    />
                  }
                  {
                    deepestLevelCom && necroticTissueView && <MultiSelect
                      id={"necroticTissue"}
                      name={"necroticTissue"}
                      label={"Necrotic Tissue:"}
                      classes={{
                        column: `row ${style.botton_spac}`,
                        inputClassName: `${style.basic_select}`,
                        labelClassName: `col-5 col-form-label ${style.label_spec}`,
                        inputColumn: `col-6 p-0 ${style.select_wrapper}`,
                      }}
                      optionValue={necrotictissueOptions}
                      showCheckbox={true}
                      displayValue="value"
                      validator={(e) => {
                        register({ required: true })
                        necroticTissueRef.current = e
                      }}
                      onSelect={necroticTissueChange}
                      onRemove={necroticTissueChange}
                     // defaultValue={defaultValNecroticTissue ? [] :resp&&resp.necroticTissue&& Array.isArray(resp.necroticTissue)?resp.necroticTissue:[]}
                     
                      defaultValue={Array.isArray(defaultValNecroticTissue) ? defaultValNecroticTissue :[]}
                      labelSubText={MULTISELECT_SUB_LABLE}
                    />
                  }
                  {/* na */}
                  {
                    deepestLevelCom && na && <MultiSelect
                      id={"na"}
                      name={"na"}
                      label={"N/A:"}
                      classes={{
                        column: ` row ${style.botton_spac}`,
                        inputClassName: `${style.basic_select}`,
                        labelClassName: `col-5 col-form-label ${style.label_spec}`,
                        inputColumn: `col-6 p-0 ${style.select_wrapper}`,
                      }}
                      optionValue={notapplicableOptions}
                      showCheckbox={true}
                      displayValue="value"
                      defaultValue={Array.isArray(defaultValnotapplicable) ? defaultValnotapplicable : []}
                      labelSubText={MULTISELECT_SUB_LABLE}
                      validator={(e) => {
                        register({ required: true })
                        notapplicableRef.current = e
                      }}
                      onSelect={notapplicableSelect}
                      onRemove={notapplicableSelect}
                    />
                  }
                  {/* instrumentsUsed */}
                  {
                    deepestLevelCom && instrumentsUsed && <MultiSelect
                      id={"instrumentsUsed"}
                      name={"instrumentsUsed"}
                      label={"Instruments Used:"}
                      classes={{
                        column: ` row ${style.botton_spac}`,
                        inputClassName: `${style.basic_select}`,
                        labelClassName: `col-5 col-form-label ${style.label_spec}`,
                        inputColumn: `col-6 p-0 ${!customErrcolor ? style.select_wrapper : style.select_wrappers}`,
                        labelSubClass: `{'bir_subline'}`
                      }}
                      optionValue={instrumentsusedOptions}
                      required={true}
                      showCheckbox={true}
                      displayValue="value"
                      customErrors={customErrors}
                      customErrorsMsg={customErrorsMsg}
                      validator={(e) => {
                        register()
                        instrumentsUsedRef.current = e
                      }}
                      onSelect={instrumentsUsedChange}
                      onRemove={instrumentsUsedChange}
                      //defaultValue={instrumentsUsedDefault ? [] : resp?.instrumentsUsed}
                      defaultValue={Array.isArray(instrumentsUsedDefault)?instrumentsUsedDefault:[]}
                      labelSubText={MULTISELECT_SUB_LABLE}
                    />
                  }
                  {deepestLevelCom&&surfaceAreaCheck&&<>
                  {/* Surface Area Debrided of Necrotic Skin */}
                  <TextField
                    id={`text`}
                    name={`DebridedOfNecroticSkin`}
                    label={<div className={style.inline_block}>Surface Area Debrided of Necrotic Skin (cm&#178;):</div>}
                    classes={{
                      column: `row col-form-label pt-0 ${style.botton_spac}`,
                      labelClass: `col-5 col-form-label ${style.label_spec}`,
                      inputColumn: `col-6 p-0`,
                      inputClassName: `${style.basic_input} ${style.border_color}`
                    }}
                    type="text"
                    defaultValue={resp?.surfaceAreaOfNecroticSkinTissue}
                    required={true}
                    validator={register(debridedNecroticSkin)}
                    errors={errors}
                  />
                  {/* Surface Area Debrided of Subq Tissue */}
                  <TextField
                    id={`subqTissue`}
                    name={`subqTissue`}
                    label={<div className={style.inline_block}>Surface Area Debrided of Subq Tissue (cm&#178;):</div>}
                    classes={{
                      column: `row col-form-label pt-0 ${style.botton_spac}`,
                      labelClass: `col-5 col-form-label ${style.label_spec}`,
                      inputColumn: `col-6 p-0`,
                      inputClassName: `${style.basic_input}`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={resp?.surfaceAreaOfNecroticSubqTissue}
                  />
                   {/* Post Debridement Bleeding Amount */}
                   <SelectBox
                    id={"bleedingAmount"}
                    name={"bleedingAmount"}
                    label={"Post Debridement Bleeding Amount:"}
                    classes={{
                      column: `row ${style.botton_spac}`,
                      inputClassName: `${style.custom_basic_select} ${style.select_wrapper}`,
                      labelClassName: `col-5 m-0 ${style.label_spec}`,
                      inputColumn: `col-6 p-0 `
                    }}
                    optionValue={postdebridementbleedingamountOptions}
                    validator={register()}
                    placeholder={'Select'}
                    onChange={postBleedingSelect}
                    defaultValue={resp?.postDebridementBleedingAmount}
                  />
                   {/* techniqueUsed */}
                   {
                    techniqueUsed && <SelectBox
                      id={"techniqueUsed"}
                      name={"techniqueUsed"}
                      label={"Technique Used:"}
                      classes={{
                        column: `row ${style.botton_spac}`,
                        inputClassName: `${style.custom_basic_select} ${style.select_wrapper}`,
                        labelClassName: `col-5 m-0 ${style.label_spec}`,
                        inputColumn: `col-6 p-0 `
                      }}
                      optionValue={techniqueusedOptions}
                      defaultValue={resp?.techniqueUsed}
                      validator={register()}
                      placeholder={'Select'}
                    />
                  }
                  {
                    stoppedBleeding && <div className={`d-flex ${style.botton_spac}`}>
                      <label className={`col-5 col-form-label pr-3 ${style.label_spec}`}>Stopped Bleeding with Contact Pressure:</label>
                      <div>
                        <RadioButton
                          id={"SPWConactPressure"}
                          name={"SPWConactPressure"}
                          classes={{
                            labelClass: `custom-control-label col-form-label ${style.custom_control_label}`,
                            inputClassName: `custom-control-input ${style.custom_control_input} col-6`,
                            inputColumn: "custom-control custom-radio custom-control-inline"
                          }}
                          validator={register()}
                          radioOption={['Yes']}
                          defaultValue={resp?.stoppedBleedingContactPressure ? resp.stoppedBleedingContactPressure : ''}
                        />
                      </div>
                    </div>
                  }
                </>}                 
                </div> : ''
              }
              {
                !noDataMsg ? `Your Formulary doesn't have any Debridement, please contact your Medline Sales Rep` :
                prodCatCheck&&listOptionsresp && Array.isArray(listOptionsresp.productCategory)&&listOptionsresp.productCategory.length>0 && listOptionsresp?.productCategory?.map((items, index) => {
                    let defaulVal = ''
                    items && Array.isArray(items.options) && items.options.map((chckOpt, k) => {
                    getValue && Array.isArray(getValue) && getValue.map((itemDef, j) => {//NOSONAR
                      if(itemDef.value===chckOpt.materialNumber){
                        defaulVal=itemDef.value
                        return
                      }
                     })//NOSONAR
                 })
                    return <TreatmentDropDownItem
                      index={index}
                      key={index}
                      id={index + 1}
                      //name={index + 1}
                      name={items.key}
                      label={items.name}
                      classes={{
                        column: `row  ${style.botton_spac}`,
                        inputClassName: style.basic_select,
                        inputColumn: `col-6 p-0`,
                        labelClassName: `col-5 mb-0`
                      }}
                      listOptions={items.options}
                      //defaultValue={getValue && getValue[index] && getValue[index].value ? getValue[index].value : ''}
                      defaultValue={(defaulVal)?defaulVal:''}
                      getData={getOptValue}
                    />
                  })

              }

              {prodCatCheck&&!listOptionsresp?.productCategory
              
              &&<><b className="ml-3">Your Formulary doesn't have any Debridement Products, please contact your Medline Sales Rep</b></>
              }
              {
                hideButtons && <div className="row">
                  <div className="mt-4 pt-2 col-sm-12 p-0">
                    <div className="offset-5">
                      <Button
                        type={"submit"}
                        classes={{ className: `btn ${style.btn_save}` }}
                        id={'updateBtn'}
                        value={SAVE_BTN}
                      />
                      <Button
                        classes={{ className: `btn ${style.btn_cancel}` }}
                        type="button"
                        id="cancel"
                        value={CANCEL_BTN}
                        onClick={() => viewMode()}
                      />
                    </div>
                  </div>
                </div>
              }
            </Form>
            :
            'This wound assessment is signed and locked, cannot be updated.'
          }
        </div>
    }
  </div>
}
