import React from 'react'
import pictureIcon from '../../../static/images/picture-icon-default.png'
import styles from './pictureIcon.module.scss'
import PropTypes from 'prop-types';
import Icon from '../../../static/images/empty_icon.svg';

export function PictureIcon({ previewImg, setPreviewImg, buttonState, setButtonState, validator, id, name, browseLbl, classes, removeImages, listViewMenu }) {
    /* Image preview function */
    function handleImageChange(e) {
        e.preventDefault();
        /* Setup file reader */
        const reader = new FileReader();
        /* Select image */
        const file = e.target.files[0];
        /* Once image is loaded update state */
        reader.onloadend = () => {
            /* Image code into a state */
            setPreviewImg(reader.result)
            setButtonState(false)
        }
        reader.readAsDataURL(file)
    }

    /* Remove image */
    function removeImage() {
        setPreviewImg(null)
        setButtonState(true)
    }
    /*reset image*/
    if (removeImages) {
        removeImage();
    }
    return (
        <>
            {/* Upload block */}
            {<div className={`${styles.picture_icon_block} ${(classes) ? classes.contr_wrapper : ''}`}>
                {/* Default image */}
                {buttonState && <img src={!listViewMenu ? pictureIcon : Icon} alt="default-icon" className={`${(classes) ? classes.change_label : ''}`} />}
                {/* Uploaded image */}
                {previewImg && <img src={previewImg} alt="preview-img" className={`${(classes) ? classes.pic_wrapper : ''}`} />}
                {/* Default upload link*/}
                {buttonState && <div className={styles.link}>
                    <label htmlFor={id} className={`${(classes) ? classes.browse_btn : ''} mb-0`}>
                        {
                            !browseLbl ? "Upload Picture" : browseLbl
                        }
                    </label>
                </div>}
                {/* Link after selecting image */}
                {previewImg && <div className={styles.link}>
                    {/* Change image                 */}
                    <label htmlFor={id} className={`${(classes) ? classes.change_label : ''}`}>
                        Update
                    </label>
                    {/* Remove image */}
                    <label onClick={removeImage} className={`${(classes) ? classes.change_label : ''} mb-0`}>
                        Delete
                    </label>
                </div>}
                {/* Hidden file input field */}
                <input type="file" name={name} id={id} ref={validator} onChange={handleImageChange} />
            </div>
            }
        </>
    )
}
PictureIcon.propTypes = {
    classes: PropTypes.object,
    listViewMenu: PropTypes.bool
}
