import React, { useState, useEffect } from "react";
//import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import { pdfjs, Document, Page } from "react-pdf";
import { useDispatch } from "react-redux";
import { setLoading } from "../app/appSlice";
import { UrlParams } from "../utils/commonHelper";
import "./externalPdfViewPage.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ExternalPdfViewPage = () => {
  const params = new UrlParams(window.location.search);
  const dispatch = useDispatch();

  const [file, setFile] = useState(params.get("file"));
  useEffect(() => {
    if (document.getElementById("check_left_block")) {
      document.getElementById("check_left_block").classList.add("d-none");
    }
    dispatch(setLoading(false));
    setFile(params.get("file"));
  }, [dispatch, params]);
  const [numPages, setNumPages] = useState(null);
  //const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    //setPageNumber(1);
  }

  /*function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  function previousPage() {
    changePage(-1);
  }
  function nextPage() {
    changePage(1);sss
  }*/

  return (
    <>
      <div className="extpdf">
        <div className="extpdf__container">
          <div className="extpdf__container__document">
            <>
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                {/* <Page pageNumber={pageNumber} /> */}
                {[...Array(numPages).keys()].map((p) => (
                  <>
                    <Page pageNumber={p + 1} />
                    <div className="row col-12 d-flex flex-row-reverse">
                      <div className="pagesec">
                        {" "}
                        Page {p + 1 || (numPages ? 1 : "--")} of{" "}
                        {numPages || "--"}
                      </div>
                    </div>
                  </>
                ))}
              </Document>
              {/* {file&&numPages&&<div className="row">
                <div className="d-flex justify-content-between  mb-3 col-12">
                  <div className="p-2">
                    <button
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                    >
                      Previous
                    </button>
                  </div>
                  <div className="p-2">
                    {" "}
                    <p>
                      Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                      {numPages || "--"}
                    </p>
                  </div>
                  <div className="p-2">
                    {" "}
                    <button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>} */}
            </>
          </div>
        </div>
      </div>
    </>
  );
};
