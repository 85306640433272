import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { familyCodeOption, productCategoryCodeAndMaterialGroupCodeOption } from '../../../app/productSlice'
import {
    CANCEL_BTN, CANCEL_ID, DIVISION_DATA, GTIN_LBL, HCPSC_CODE_LBL, MANUFACTURER_LBL, MATERIAL_DESCRIPTION_LBL,
    MATERIAL_GROUP_CODE_LBL,
    MATERIAL_GROUP_DESCRIPTION_LBL, MATERIAL_NUMBER_LBL, MATERIAL_STATUS, MATERIAL_STATUS_LBL, PRODUCT_CATEGORY_CODE_LBL, PRODUCT_CATEGORY_DESCRIPTION_LBL,
    PRODUCT_FAMILY_CODE_LBL, PRODUCT_FAMILY_DESCRIPTION_LBL, PRODUCT_MANAGER_LBL, QUANTITY_LBL, SAVE_BTN, SUBMIT_ID, UNIT_MEASURE, UNIT_OF_MEASURE_LBL, UPC_LBL
} from '../../../constants'
import {
    checkMaterialGroupCode, checkProductCategoryCode,
     checkProductFamilyCode, checkMaterialDescription
} from '../../../utils'
import { Button, Form, RadioButton, SelectBox, TextField } from '../../primary'
import styles from './createEditProductMaterial.module.scss'

export function CreateEditMaterial({ handleCancel, onSubmit, productData, externalAdmin = false, isModal = false,productType,productManager }) {

    /* Use hook form */
    const { register, handleSubmit, errors } = useForm({
        mode: 'onBlur'
    });
    
    const dispatch = useDispatch()

    /* Handle multiselect dropdown */
    const productMangerRef = useRef();

    const [productManagerData, setProductManagerData] = useState([]);
    const [materialGroupDescription, setMaterialGroupDescription] = useState("");
    const [productFamilyDescription, setProductFamilyDescription] = useState(""); 
    const [productFamilyManager,setProductFamilyManager] =useState([])
    const [divisionCodeId, setDivisionCodeId] = useState();
    const [selectedProductCodeId, setSelectedProductCodeId] = useState();
    const [productCategoryDescription, setProductCategoryDescription] = useState("");  
    const [defaultProductCatCode, setDefaultProductCatCode] = useState(productData?.productCategoryCodeId);
    
    const handleSelect = (e) => {
        if (e && e?.length > 0) {
            let arr = [];
            e.map((data) => {
              if (data.key !=='') {
                arr.push(data.key)
              }
              
            })
            setProductManagerData(arr)
          }
    }
    productManager(productManagerData);

    
    
    const handleRadioChange=(data)=>{
        const productDivisionCodeId=(data.target.value==="Advanced Skin Care (80)")?80:(data.target.value==="Advanced Wound Care (71)")?71 : (data.target.value==="Nutrition and Pharmaceuticals (14)")?14 :(data.target.value==="Repositioning and Offloading (75)")?75:'';
        setDivisionCodeId(productDivisionCodeId)
        setMaterialGroupDescription("");
        
        if(document.getElementById('materialGroupCode')){
            document.getElementById('materialGroupCode').value="";	
		}
        setDefaultProductCatCode("");
		if(document.getElementById('productCategoryCode')){
            document.getElementById('productCategoryCode').value="";	
		}
        setProductCategoryDescription("");
        setProductFamilyDescription("");
        setProductFamilyManager([]);
    }

  useEffect(() => {
      if(divisionCodeId!=='undefined'){
        const payload={id:divisionCodeId,code:"materialGroupCode"}
        dispatch(productCategoryCodeAndMaterialGroupCodeOption(payload))
      }
  }, [divisionCodeId])

  useEffect(() => {
    if(divisionCodeId!=='undefined'){
        const payload={id:divisionCodeId,code:"productCategoryCode"}
        dispatch(productCategoryCodeAndMaterialGroupCodeOption(payload))
    }
  }, [divisionCodeId])
  const productManagerOption = useSelector(state => state.product.productManager);
  const materialStatusOption = useSelector(state => state.product.materialStatus);
  const productDivision = useSelector(state => state.product.productDivision);
  const productCategoryCodeOption = useSelector(state => state.product.productCategoryCode);
  const materialGroupCodeOption = useSelector(state => state.product.materialGroupCode);
  const manufacturerOption = useSelector(state => state.product.manufacturer);
  
  
  const convertedMaterialCodeData = materialGroupCodeOption && materialGroupCodeOption.length > 0 
  && materialGroupCodeOption.map(data => ({ key: data.key, value: data.value }));

useEffect(() => {
    if(productData&& productData?.materialGroupCodeId){
        materialCodeOnChange(productData?.materialGroupCodeId);
    }
}, [materialGroupCodeOption,productData])

const materialCodeOnChange=(value)=>{
  Array.isArray(materialGroupCodeOption)&&materialGroupCodeOption.map((data)=>{//NOSONAR
      if(data.key == value){
          setMaterialGroupDescription(data.desc);//NOSONAR
          return
      }
      else if(value=='') {
        setMaterialGroupDescription();
      return 
    }
    else {
        return
    }
  })//NOSONAR
}

const convertedProductCategoryCodeData = productCategoryCodeOption && productCategoryCodeOption.length > 0 
    && productCategoryCodeOption.map(data => ({ key: data.key, value: data.value }));
   
useEffect(() => {
    if(productData&&productData.productCategoryCodeId){
        productCategoryCodeOnChange(productData?.productCategoryCodeId);
    }
}, [productCategoryCodeOption,productData])

const productCategoryCodeOnChange=(value)=>{
    setSelectedProductCodeId(value);
    Array.isArray(productCategoryCodeOption)&&productCategoryCodeOption.map((data)=>{//NOSONAR
        if(data.key == value){
            setProductCategoryDescription(data.desc);//NOSONAR
            return
        }
        else if(value=='') {
            setProductCategoryDescription();
          return 
        }
        else {
            return
        }
    })//NOSONAR
}

useEffect(() => {
    if(divisionCodeId!=='undefined' && selectedProductCodeId!=='undefined'){
        const payload={id:divisionCodeId,productCategoryCode:selectedProductCodeId}
        dispatch(familyCodeOption(payload));
    }
}, [divisionCodeId,selectedProductCodeId])

useEffect(() => {
    if(productData){
        const payload={id:productData.productDivisionCodeId,productCategoryCode:productData.productCategoryCodeId}
        dispatch(familyCodeOption(payload));
    }
}, [productData])


const familyCodeOptionData = useSelector(state => state.product.familyCode);
const convertedFamilyCodeData = familyCodeOptionData && familyCodeOptionData.length > 0 
    && Array.isArray(familyCodeOptionData) && familyCodeOptionData.map(data => ({ key: data.key, value: data.value }));

const productFamilyCodeOnChange=(value)=>{
    Array.isArray(familyCodeOptionData)&&familyCodeOptionData.map((data)=>{//NOSONAR
        if(data.key == value){
            setProductFamilyDescription(data.description);//NOSONAR
            setProductFamilyManager(data.productManager);//NOSONAR
            return
        }
        else if(value=='') {
            setProductFamilyDescription();
          return 
        }
        else {
            return
        }
    })//NOSONAR
}
useEffect(() => {
    if(productData&&productData?.productFamilyCodeId){
        productFamilyCodeOnChange(productData?.productFamilyCodeId);
    }
}, [familyCodeOptionData,productData])

    return (
        <>
            <Form formChanges={true} className={`${styles.patient_content}`} onSubmit={handleSubmit(onSubmit)}>
                <div className={`col-10 ${styles.col_spec}`}>
                <label className={`col-5 col-form-label ${styles.label_spec}`}>Division: <span className={styles.astrick}>&#42;</span></label>
                {/*Division */}
                    <RadioButton
                        id={"division"}
                        name={"division"}
                        classes={{
                            column: `w-100 pl-3`,
                            labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input}`,
                            inputColumn: `col-5 custom-control custom-radio`
                        }}
                        validator={register()}
                        radioOption={DIVISION_DATA}
                        defaultValue={(productData?.productDivisionCodeId==80)?"Advanced Skin Care (80)":(productData?.productDivisionCodeId==71)?"Advanced Wound Care (71)":(productData?.productDivisionCodeId==14)?"Nutrition and Pharmaceuticals (14)":(productData?.productDivisionCodeId==75)?"Repositioning and Offloading (75)":''}
                        onChange={handleRadioChange}
                        disabled={(productData?.isEditable===0)?true:false}
                    />
                </div>


                {/* Material Number */}
                <TextField
                    id={"materialNumber"}
                    name={"materialNumber"}
                    label={`${MATERIAL_NUMBER_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input} ${(productData?.isEditable===0)?styles.disabled:''}`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={productData && productData?.materialNumber}
                    errors={errors}
                    disabled={(productData?.isEditable===0)?true:false}
                    required={true}
                />
                {/* material Description */}
                <TextField
                    id={"materialDescription"}
                    name={"materialDescription"}
                    label={`${MATERIAL_DESCRIPTION_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input}`
                    }}
                    type="text"
                    validator={register(checkMaterialDescription)}
                    defaultValue={productData && productData?.materialDescription}
                    //disabled={(productData?.isEditable===0)?false:true}
                    required={true}
                    errors={errors}           
                />

                <SelectBox
                    id={"materialGroupCode"}
                    name={"materialGroupCode"}
                    label={`${MATERIAL_GROUP_CODE_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        inputClassName: `${styles.basic_select}`,
                        labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-5 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={convertedMaterialCodeData}
                    validator={register(checkMaterialGroupCode)}
                    defaultValue={productData && productData.materialGroupCodeId}
                    errors={errors}
                    required={true}
                    //disabled={(productData?.isEditable===0)?true:false}
                    placeholder={"Select"}
                    onChange={e=>materialCodeOnChange(e.currentTarget.value)}
                />
            
                {/* material Group Description */}
                <TextField
                    id={"materialGroupDescription"}
                    name={"materialGroupDescription"}
                    label={`${MATERIAL_GROUP_DESCRIPTION_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input} ${(productData?.isEditable===0)?styles.disabled:''}`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={materialGroupDescription}
                    errors={errors}
                    //disabled={(productData?.isEditable===0)?true:false}
                    disabled={true}
                />

                <SelectBox
                    id={"productCategoryCode"}
                    name={"productCategoryCode"}
                    label={`${PRODUCT_CATEGORY_CODE_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        inputClassName: `${styles.basic_select} }`,
                        labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-5 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={convertedProductCategoryCodeData}
                    defaultValue={defaultProductCatCode}
                    validator={register(checkProductCategoryCode)}
                    errors={errors}
                    required={true}
                    //disabled={(productData?.isEditable===0)?true:false}
                    onChange={e => productCategoryCodeOnChange(e.currentTarget.value)}
                    placeholder={"Select"}
                />
            
            
                {/* Product Category Description */}
                <TextField
                    id={"productCategoryDescription"}
                    name={"productCategoryDescription"}
                    label={`${PRODUCT_CATEGORY_DESCRIPTION_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input} ${(productData?.isEditable===0)?styles.disabled:''}`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={productCategoryDescription}
                    errors={errors}
                    disabled={(productData?.isEditable===0)?true:false}
                />

                <SelectBox
                    id={"productFamilyCode"}
                    name={"productFamilyCode"}
                    label={`${PRODUCT_FAMILY_CODE_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        inputClassName: `${styles.basic_select} }`,
                        labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-5 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={convertedFamilyCodeData}
                    defaultValue={productData&&productData?.productFamilyCodeId}
                    validator={register(checkProductFamilyCode)}
                    errors={errors}
                    required={true}
                    //disabled={(productData?.isEditable===0)?true:false}
                    onChange={e => productFamilyCodeOnChange(e.currentTarget.value)}
                    placeholder={"Select"}
                />

                {/* Product Family Description */}
                <TextField
                    id={"productFamilyDescription"}
                    name={"productFamilyDescription"}
                    label={`${PRODUCT_FAMILY_DESCRIPTION_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input} ${(productData?.isEditable==0)?styles.disabled:''}`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={productFamilyDescription}
                    errors={errors}
                    disabled={(productData?.isEditable===0)?true:false}
                />
                {/* Product Family Description */}
                <TextField
                    id={"productManager"}
                    name={"productManager"}
                    label={`${PRODUCT_MANAGER_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input} ${(productData?.isEditable===0)?styles.disabled:''}`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={productFamilyManager.map((data)=>{
                        return data;
                    })}
                    errors={errors}
                    //disabled={(productData?.isEditable===0)?false:true}
                    disabled={true}
                />
                <SelectBox
                    id={"manufacturer"}
                    name={"manufacturer"}
                    label={`${MANUFACTURER_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        inputClassName: `${styles.basic_select} }`,
                        labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-5 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={manufacturerOption}
                    validator={register()}
                    defaultValue={productData && productData?.manufacturerNameId}
                    errors={errors}
                    //disabled={(productData?.isEditable===0)?true:false}
                    placeholder={"Select"}
                    
                />
                <SelectBox
                    id={"unit"}
                    name={"unit"}
                    label={`${UNIT_OF_MEASURE_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        inputClassName: `${styles.basic_select} }`,
                        labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-5 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={UNIT_MEASURE} 
                    validator={register()}
                    defaultValue={productData && productData.salesUOMCodeId}
                    errors={errors}
                    //disabled={(productData?.isEditable===0)?true:false}
                    placeholder={"Select"}
                    
                />
    

                <TextField
                    id={"quantity"}
                    name={"quantity"}
                    label={`${QUANTITY_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input} }`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={productData && productData?.salesUOMNumeratorQuantity}
                    errors={errors}
                    //disabled={(productData?.isEditable===0)?true:false}
                />
                <TextField
                    id={"gtin"}
                    name={"gtin"}
                    label={`${GTIN_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input} }`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={productData && productData?.gtinNumber}
                    errors={errors}
                    //disabled={(productData?.isEditable===0)?true:false}
                />
                <TextField
                    id={"upc"}
                    name={"upc"}
                    label={`${UPC_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input} }`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={productData && productData?.upcCode}
                    errors={errors}
                    //disabled={(productData?.isEditable===0)?true:false}
                />
                <TextField
                    id={"hcpsc"}
                    name={"hcpsc"}
                    label={`${HCPSC_CODE_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-5 p-0',
                        inputClassName: `${styles.basic_input} }`
                    }}
                    type="text"
                    validator={register()}
                    defaultValue={productData && productData?.hcpcScode}
                    errors={errors}
                    //disabled={(productData?.isEditable===0)?true:false}
                />
                <SelectBox
                    id={"materialStatus"}
                    name={"materialStatus"}
                    label={`${MATERIAL_STATUS_LBL}:`}
                    classes={{
                        column: `col-10 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-5 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={MATERIAL_STATUS}
                    validator={register()}
                    defaultValue={productData && productData.materialStatusCodeId}
                    errors={errors}
                    placeholder={"Select"}
                    
                />
                <div className="col-sm-12">
                    <div className="text-center pb-4">
                        {/* Save Button */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                            type="submit"
                            id={SUBMIT_ID}
                        >
                            {SAVE_BTN}
                        </Button>
                        {/* Cancel button  */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={handleCancel}
                        >
                            {CANCEL_BTN}
                        </Button>


                    </div>
                </div>

            </Form>
        </>
    )
}

CreateEditMaterial.propTypes = {
    register: PropTypes.func,
    handleSubmit: PropTypes.func,
    control: PropTypes.object,
    handleCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    productData: PropTypes.object,
    externalAdmin: PropTypes.bool
}
