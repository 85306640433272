import React from 'react'

export function CharAiTissueLists({
    styles,
    aiAvailable,
    tissueTypesData,
    handleSelect,
    checkFlag,
    selectedCheck,
    inputFlag,
    result,
    handleInput,
    ai,
    validPercent,
    checkDefault }) {

    return (
        <div className={styles.tissue_list}>

            {/* Displaying message if there is no AI suggessted tissue types available */}
            {ai === 1 && !aiAvailable && <div>Sorry. No AI Suggested tissue types available for this wound.</div>}

            {tissueTypesData && tissueTypesData.length > 0 && tissueTypesData.map((data, i) => {
                if (data.aiSelected === ai) {
                    return <>
                        {/* Individual item */}
                        <div className={styles.tissue_list_item} key={i}>
                            {/* Checkbox */}
                            <div className={`custom-control-inline ${styles.custom_checkbox}`}> 
                                <input
                                    type="checkbox"
                                    className={` ${styles.custom_control_input}`}
                                    id={data.code + data.id}
                                    onClick={(e) => handleSelect(e, i, data.id, ai)} // Pass event and index
                                    defaultChecked={checkFlag[i] || selectedCheck(data.id)}
                                    disabled={data.enable === 1 ? false : true}
                                    data-testid="tissueCheckBox"
                                />
                                <label
                                    className={`custom-control-label ${styles.custom_control_label_check} ${data.enable === 0 && styles.disable_check}`}
                                    htmlFor={data.code + data.id} data-testid="labelsamp">&nbsp;</label>
                            </div>
                            <h4 className={data.enable === 0 && styles.disable_head}>{data.name}</h4>
                            {/* Percent, textbox and checkbox */}
                            <div className={styles.list_fields} data-testid="inputfield">
                                {/* Percentage */}
                                {!inputFlag[i] // Find the specifix index is true or false (for displaying input box)
                                    && <span className={styles.display_percent}>
                                        {/* Get percentage from accessment details by passing the code  */}
                                        {result && result.length > 0 && result.map((item) => {
                                            if (item.id === data.id) {
                                                // If there is wound percentage return it, else return null
                                                if (item.woundFillingPercentage) {
                                                    return item.woundFillingPercentage + '%'
                                                } else {
                                                    return null
                                                }
                                            }
                                        })}
                                    </span>}

                                {/* Input field */}
                                {inputFlag[i] && data.id <= 5
                                    && <input data-testid="inputtype" type="text" name={data.code} placeholder="% Covered" onBlur={(e) => handleInput(e, i, data.id)} />}

                                {/* Error message */}
                                {inputFlag[i] && data.id <= 5 && data.code !== 'N/A' && validPercent === data.id && <em data-testid="errormsg">Please enter valid percentage!</em>}

                            </div>
                        </div>
                    </>
                }
            })}
        </div>
    )
}
