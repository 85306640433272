import React from 'react'
import { Modal } from '../../secondary/Modal';
import styles from './createRiskAssessment.module.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { CreateRiskAssessmentFormView } from './createRiskAssessmentFormView';

export let CreateRiskAssessment = ({ checkRiskAssessmentScale, modalClose, dispatch }) => {

    return (
        <>
            {/* Modal */}
            <Modal
                modalOpen={true}
                modalClose={modalClose}
                heading={"Risk Assessment"}
                className={styles.assessment_modal}
                modalSize={styles.custom_modal_size}
            >
                <div className={styles.spacing}>
                    {
                        checkRiskAssessmentScale &&
                        <CreateRiskAssessmentFormView
                            styles={styles}
                            dispatch={dispatch}
                            checkRiskAssessmentScale={checkRiskAssessmentScale}
                            handleCancel={modalClose}
                        />

                    }

                </div>
            </Modal>
        </>
    )
}

CreateRiskAssessment = connect()(withRouter(CreateRiskAssessment));
