import React, { useEffect, useState } from 'react';
import { Accordion } from '../../secondary/Accordion/accordion';
import LogoImage from '../../../static/images/measurmenttest.jpg';
import deleteIcon from '../../../static/images/delete-icon.png';
import warning from '../../../static/images/icon-check-danger.png';
import { useSelector } from 'react-redux';
import style from './woundAssessment.module.scss';
import { Button, AccessErrorModal, TextField } from '../../primary';
import { Confirmation } from '../../secondary/Confirmation/confirmation';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns'
import { daysAgo, fullMonthFormatDate, getLabel, getSubLabel, allNullCheck } from '../../../utils';
import { CreateAssessment } from './createAssessment';
import { AssessmentHistory } from '../../core/Patients/assessmentHistory';
import { WoundPhotoAssessment } from '../../core/Patients/woundPhotoAssessment';
import { optionTypeAsync, setWoundAssessment, updatepatientassessmentcustomlabels } from '../../../app/patientSlice'; //Chnaged for Label
import { handleDeleteData, openDeleteConfirmationData, modalOpenData } from '../../../utils/patientsHelper';
import Loader from '../../../static/images/spinner.svg';
import RightArrow from "../../../static/images/right_arrow.svg";
import { scryRenderedDOMComponentsWithTag } from 'react-dom/test-utils';

export const WoundAssessment = ({ patientData, dispatch, getAssessments, getAssessmentHistory, deleteAssessment, deleteHistoryAssessment }) => {
  useEffect(() => {
    dispatch(setWoundAssessment('reset'))
  }, [])


  const path = window.location.href;
  const origin = window.location.origin;
  const isAdminView = path.includes(`${origin}/admin`);
  const { patientId } = useParams();

  // Role
  const userRole = useSelector(state => state.user);

  useEffect(() => {
    dispatch(optionTypeAsync('woundprogress'));
    getAssessments(patientId);
  }, [])

  const woundAssessments = useSelector(state => state.patient.woundAssessments);
  let currentTitle = '';
  const [assessmentId, setAssessmentId] = useState();
  const [historyAssessmentId, setHistoryAssessmentId] = useState();
  // options API
  const humanAnatomy = useSelector(state => state.patient.humanAnatomy);
  const historyCause = useSelector(state => state.patient.historyCause);
  // const customLabels = useSelector(state.patient.detailAssessment)
  const woundClassificationOptions = useSelector(state => state.patient.woundClassificationOptions) // Get wound classification select box options
  
  // *******************
  // confirmation modal
  // *******************
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const openDeleteConfirmation = (_assessmentId, _historyAssessmentId) => {
    openDeleteConfirmationData(_assessmentId, _historyAssessmentId, setAssessmentId, setHistoryAssessmentId, setShowDeleteConfirmation)
    // setAssessmentId(_assessmentId);
    // setHistoryAssessmentId(_historyAssessmentId);
    // setShowDeleteConfirmation(true)

  }

  const clousreControl = '100';
  const statusBadg = {
    'pendingassessment': <span className={`pt-2 pb-2 ${style.assess_status} ${style.pending_assess}`}>Pending Assessment</span>,
    'reviewed': <span className={`pt-2 pb-2  ${style.assess_status} ${style.reviewed}`}>Reviewed</span>,
    'pendingreview': <span className={`pt-2 pb-2 ${style.assess_status} ${style.pending_review}`}>Pending Review</span>,
    'signedlocked': <span className={`pt-2 pb-2 ${style.assess_status} ${style.signed_locked}`}>Signed &#38; Locked</span>
  }


  /************* */
  /* DO NOT DELETE */
  /*************** */
  /// IMPORTANT -- below we only need to do condition check for 'pending review' and 'signed and locked'.
  /// if we are checking 'pending assessment', it is temp only
  // Checking wheather the assessment stage is 'pending review' or 'signed or locke'. Only then display 'Re-Eval' button.
  // Else don't display the 're-eval' button. 
  // const checkAssessmentState = (data) => {
  //   if (data?.toLowerCase().split(' ').join('') === PENDING_REVIEW || data?.toLowerCase().split(' ').join('') === SIGNED_LOCKED) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }


  // State for modal open / close
  const [assessmentModal, setAssessmentModal] = useState(false)
  const [assessId, setAssessId] = useState()

  {/****************************************** */
    /*  DO NOT REMOVE THE BELOW COMMENTED CODE  */
    /******************************************* */
  }

  /* re-eval assessment modal */
  /* Trigger opening the modal */
  // const reEvalOpen = (id) => {
  //   setAssessmentModal(true);
  //   setAssessId(id)
  // }
  // /* For closing the modal */
  // const reEvalClose = () => {
  //   setAssessmentModal(false);
  // }

  {/****************************************** */
    /*  DO NOT REMOVE THE ABOVE COMMENTED CODE  */
    /******************************************* */
  }

  const handleDelete = () => {
    handleDeleteData(historyAssessmentId, deleteHistoryAssessment, assessmentId, deleteAssessment, setAssessmentId, setHistoryAssessmentId, setShowDeleteConfirmation)
  }

  const [modalShow, setmodalShow] = useState(false);

  const [assessmentTitle, setAssessmentTitle] = useState('');
  const [modalTitlteId, setModalTitlteId] = useState('');

  const modalOpen = (id, title) => {
    modalOpenData(id, title, setAssessmentTitle, setModalTitlteId, setmodalShow)
    // setAssessmentTitle(title);
    // setModalTitlteId(id);
    // setmodalShow(true);
  }

  // const modalClose = () => {
  //   setmodalShow(false);
  // }

  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
 

  const [statecheck, setStateCheck] = useState("Passing")

  return (
  <>
  {!tinyDelay ? <div  className={`${'loader'} col-12`}><img src={Loader} alt="loading" /></div>:
  <div>

    {patientData?.moreInfo?.ne1WMSStatus === 1 && 
      <WoundPhotoAssessment
        patientData={patientData}
        dispatch={dispatch}
        getAssessments={getAssessments}
      />}

    {/* <AccessErrorModal /> */}

    

    {patientData.ne1Id === patientId &&  <>

      {patientData?.moreInfo?.ne1WMSStatus === 1 ? <>

        {/* Re-eval modal  */}
        {assessmentModal && <CreateAssessment modalClose={setAssessmentModal} reEval={true} assessmentId={assessId} />}

        {/* heading && <PatientHeading heading={heading} /> */}
        {
          woundAssessments && woundAssessments.assessments && Array.isArray(woundAssessments.assessments) && woundAssessments.assessments.map((data, index) => {
            return (<div key={index}>
              <Accordion
                idControl={index}
                heading={
                  <div>Admission Date: {format(new Date(data.admissionDate.replace('Z', '')), 'MM/dd/yyyy hh:mm aa')}
                    {
                      data.dischargeDate ? <span> - Discharge Date: {format(new Date(data.dischargeDate), 'MM/dd/yyyy hh:mm aa')} </span> : null
                    }
                  </div>
                }
                content={<div>
                  {
                    data.assessments && data.assessments.length > 0 ? data.assessments.map((assessment, index) => {
                      currentTitle = assessment.title;
                      return <div key={index} className={`row m-0 ${style.accordion_panel}`}>
                        <div className="col-lg-2 col-md-3 col-sm-3 pl-0">
                          <div className={style.img_parent}>
                            <img src={assessment.picture || LogoImage} alt="wound" className={style.wound_img} />
                            {
                              !isAdminView && assessment?.woundStage && <span className={`${style.img_stage} pt-1 pb-1`}>
                                {assessment && assessment.woundStage}
                              </span>
                            }
                          </div>
                        </div>

                        <div className={`col-10`}>
                          <div className={`row align-items-start`}>

                            <div className="col-lg-9 col-md-7 col-sm-6 pl-2">                          
                            <div className={`${style.panel_heading} ${style.body_heading} mb-4`}>
                              {userRole.role !== 'ExternalStandardUser' ? <Link params={{ testvalue: "hello" }} to={
                                {
                                  pathname: ('/patients/' + patientId + '/assessment/' + assessment.id),
                                  state: { 'assessment': assessment, 'humanAnatomy': humanAnatomy, 'customlabels': statecheck },
                                  assessmentsIndex: index
                                }

                              }
                              >
                                <div className="mr-3">
                                  {
                                    assessment && assessment?.title
                                  }
                                </div>
                              </Link> 
                              :
                              <div className="mr-3">
                                  {
                                    assessment && assessment?.title
                                  }
                                </div>
                              }
                              {/* <div className={style.assess_stage}>
                                {
                                  !isAdminView && statusBadg[assessment.assessmentStage]
                                }
                              </div> */}
                              </div>
                              <div className={`${style.detaile}`}>

                              <div className={`${style.assess_content_label} ${style.assess_content} mb-4`}>  
                                <span className={style.assess_label}>History/Cause: </span>                                

                                {assessment?.causeCategory !== "unknown" ? (allNullCheck(assessment?.causeCategory) && allNullCheck(assessment?.cause) ?
                                  (assessment?.causeCategory !== "others"||assessment?.causeCategory.toLowerCase() !== "others") ?
                                  <span>                                  
                                    {historyCause && historyCause.length > 0 && historyCause?.map((data)=>{
                                      if(data.key===assessment?.causeCategory.toLowerCase()){
                                        return data?.options?.map((item)=>{
                                          if(item.key!=='unabletodetermine'){
                                            if(item.key===assessment?.cause || item.value===assessment?.cause){
                                              return data.value + '-' + item.value
                                            }
                                          }
                                          else{
                                            return data.value
                                          }
                                        })
                                      }
                                    })}
                                  </span> : <span>{"Other" + `-` + assessment?.cause}</span>
                                  : <span>{`-`}</span>)
                                  : <span>{`Unknown`}</span>
                                }
                                  
                              </div>

                              {/* added for Labels */} 
                              <div className={`${style.assess_content_label} ${style.assess_content} mb-4`}>  
                                <span className={style.assess_label}>Labels: </span>
                                
                                {(assessment?.bodyType === "null" && assessment.injuryType === 'None') &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {(assessment?.injuryType === "null" && assessment?.bodyType === "null") &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>} 
                                {(assessment?.injuryType === null && assessment?.bodyType === null) &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {(assessment?.injuryType === "None" && assessment?.bodyType === "None")  &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {(assessment?.bodyType === "" && assessment?.injuryType === "") &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {((assessment?.bodyType === "null"||assessment?.bodyType === null) && assessment?.injuryType === "") &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}
                                {((assessment?.injuryType === "null"||assessment?.injuryType === null) && assessment?.bodyType === "") &&  <span className={`${style.sub_label_content_None} mr-3`}>
                                <span> None </span>
                                </span>}                                                                
                                
                                
                               {(assessment?.injuryType !== "null" && assessment?.bodyType !== "null") || (assessment?.injuryType !== "None") ? <>
                               <span className={assessment?.injuryType !== "null" && assessment?.injuryType !== null && assessment?.injuryType !== "None" && assessment?.injuryType !== "" && `${style.sub_label_content} mr-3`}>
                                  {assessment?.injuryType !== "null" && assessment?.injuryType !== null && assessment?.injuryType !== "None" ? <span>{assessment?.injuryType}</span> : <span className='d-none'>{"None"}</span>}
                                  {/* {assessment?.injuryType !== ""  ? (assessment?.injuryType !== "null" && assessment?.injuryType !== null && assessment?.injuryType !== "None" ? <span>{assessment?.injuryType}</span> : <span className='d-none'>{"None"}</span>) : <span className={assessment?.injuryType === "None" ? 'd-none': 'd-block'}> None </span>} */}
                                  {/* {assessment?.injuryType !== "" ? (assessment?.injuryType !== "null" && assessment?.injuryType !== null  ? <span>{assessment?.injuryType}</span> : <span className='d-none'>{"None"}</span>) : <span> None </span>} */}
                                </span>
                               
                                <span className={assessment?.bodyType !== "null" && assessment?.bodyType !== null && assessment?.bodyType !== "None" && assessment?.bodyType !== "" && `${style.sub_label_content} mr-3`}>
                                  {assessment?.bodyType !== "null" && assessment?.bodyType !== null && assessment?.bodyType !== "None" ? <span>{assessment?.bodyType}</span> : <span className='d-none'>{"None"}</span>}
                                  {/* {assessment?.bodyType !== ""  ? (assessment?.bodyType !== "null" && assessment?.bodyType !== null && assessment?.bodyType !== "None" ? <span>{assessment?.bodyType}</span> : <span className='d-none'>{"None"}</span>) : <span className={assessment?.bodyType === "None" ? 'd-none': 'd-block'}> None </span>} */}
                                </span>
                               </> : <span className={(assessment?.bodyType === "null" && assessment.injuryType === 'None') ? 'd-none' : `${style.sub_label_content} mr-3`} 
                                      // style={(assessment?.injuryType === "None" && assessment?.bodyType === "null") && {display:'none'}}
                                    >
                                  <span className={(assessment?.bodyType === "None" || assessment?.injuryType === "None") ? 'd-none': (assessment?.injuryType === "None" && assessment?.bodyType === "null") && 'd-block'}> None </span>
                                </span>}
                                
                                
                              </div>


                         {/* <Form className={`${style.LabelMain}`} onBlur={handleSubmit(doSubmit)} ref={formRef}>  */}                

                              <div className={`${style.assess_content_woundAcq} ${style.assess_content} mb-4`}>  
                                <span className={style.assess_label}>Wound Acquired: </span> {assessment.woundAcquired ? ((assessment.woundAcquired==="POA") ? <span className={`${style.POAColor}`}>{assessment.woundAcquired}</span>: <span className={`${style.NONPOAColor}`}>{assessment.woundAcquired}</span>) : 'NA'}
                              </div>

                              {/* <div className={`${style.assess_content} mb-4`}>
                                <span className={style.assess_label}>Classification: </span>
                                {
                                  getSubLabel(assessment?.cause, historyCause)
                                }
                              </div> */}

                              <div className={`${style.assess_content} mb-4`}>
                                <span className={`${style.assess_label}`}>Classification: </span>
                                {assessment?.classification !== "unknown" ? (allNullCheck(assessment?.classification) ?                                  
                                <span className={`${style.classifi_content}`}>
                                    {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.map((data) => {
                                        if (data.key === assessment?.classification) {
                                            return data.value
                                        }
                                    })
                                    }
                                </span> : <span className={`${style.classifi_content}`}>{`Unknown`}</span>):
                                <span className={`${style.classifi_content}`}>{`Unknown`}</span>}
                              </div>                              

                            </div>                           

                          </div>
                          

                          <div className="col-lg-3 col-md-2">
                            <div className={`row align-items-center ${style.full_height}`}>
                              <div className={`col-lg-10 col-md-12 ${style.closure_ctr}`}>
                                <div className={style.assess_stage}>
                                  {
                                    !isAdminView && statusBadg[assessment.assessmentStage]
                                  }
                                </div>
                                {
                                  !isAdminView && 
                                    <div className={style.closure_container}>
                                      <div className={`${style.clousre_text} text-center`}>% Change <br/>                                        
                                        {assessment?.changeInCm ? 
                                          (assessment?.changeInCm !== "Unknown" ?
                                              <span className={assessment?.changeInCm !== "0" ? (assessment?.changeInCm < "0" ? `${style.closureColor} ${style.closureColor_green} mt-2` : `${style.closureColor} ${style.closureColor_red} mt-2`) : `${style.closureColor} ${style.closureColor_grey} mt-2`}>
                                                <span style={{paddingRight: "4px"}}>                                             
                                                    <i className={assessment?.changeInCm !== "0" && (assessment?.changeInCm < "0" ? `fa-solid fa-down-long ${style.closureColor_green}` : `fa-solid fa-up-long ${style.closureColor_red}`)}></i>
                                                </span>
                                                {Number(assessment?.changeInCm?.replace( /^\D+/g, ''))} cm<sup>2</sup>
                                                {/* {assessment?.changeInCm} cm<sup>2</sup> */}
                                              </span> : <span className={`${style.closureColor} ${style.closureColor_grey} mt-2`}>
                                                0 cm<sup>2</sup>
                                            </span>
                                          ) : ("-")
                                        } 

                                        {assessment?.closurePercentage ? 
                                          (assessment?.closurePercentage !== "Unknown" ? 
                                            <span className={assessment?.changeInCm !== "0" ? (assessment?.changeInCm < "0" ? `${style.closureColor} ${style.closureColor_green} ${assessment?.changeInCm !== '0' && `pl-2`}`: `${style.closureColor} ${style.closureColor_red} ${assessment?.changeInCm !== '0' && `pl-2`}`) : `${style.closureColor} ${style.closureColor_grey} ${assessment?.changeInCm !== '0' && `pl-2`}`}>
                                              {`(`}{Number(assessment.closurePercentage?.replace( /^\D+/g, ''))} %{`)`}
                                            </span> : <span className={`${style.closureColor} ${style.closureColor_grey}`}>
                                              {`(Unknown)`}
                                            </span>
                                          ) : ("-") 
                                        } 

                                      </div>                                      
                                     
                                    </div>                                  
                                }
                              </div>
                              <div className={`col-lg-2 col-md-12 pr-0 ${isAdminView && style.full_height}`}>
                                {
                                 // !isAdminView ?

                                    /****************************************** */
                                    /*  DO NOT REMOVE THE BELOW COMMENTED CODE  */
                                    /******************************************* */

                                    // checkAssessmentState(assessment?.assessmentStage) && patientData.statusInfo.status !== DISCHARGED &&

                                    // <div className={style.full_height}>
                                    //   <div className={`${style.closure_container}`}>
                                    //     <Button
                                    //       type="button"
                                    //       name={"Btn"}
                                    //       value="Re-Eval"
                                    //       classes={{ className: `btn  ${style.btn_small_cancel} ${style.btn_custom}` }}
                                    //       onClick={() => reEvalOpen(assessment?.id)}
                                    //     />
                                    //   </div>
                                    // </div>
                                    /****************************************** */
                                    /*  DO NOT REMOVE THE ABOVE COMMENTED CODE  */
                                    /******************************************* */

                                    //<>
                                   // </>
                                    //:
                                    // <div>
                                    //   <div className={`text-right`}>
                                    //     <img src={deleteIcon} alt="delete icon" id="asdf" className={style.del_icon} onClick={() => openDeleteConfirmation(assessment.id)} />
                                    //   </div>
                                    // </div>
                                }
                                {/* {((isAdminView && userRole.role === 'MedlineAdministrator') || (userRole.role === 'ExternalAdministrator')) && */}
                                {isAdminView &&
                                 <div>
                                 <div className={`text-right`}>
                                   <img src={deleteIcon} alt="delete icon" id="asdf" className={style.del_icon} onClick={() => openDeleteConfirmation(assessment.id)} />
                                 </div>
                               </div>
                                }
                              </div>
                            </div>
                            </div>
                          </div>



                          <div className={`row mt-3`}>
                          <div className={`col-12`}>
                              <div className={`row align-items-center ${style.assess_content_subDetails}`}>

                                <div className={`col-9 pr-0`}>
                                  <div className={`${style.assess_content} mb-2 pt-1`}>
                                    <span className={style.assess_label_subDetail_label}>Photo taken by:</span> <span className={style.assess_label_subDetail_labelout}>{assessment?.photoTakenBy ? assessment?.photoTakenBy : 'NA'}</span>  
                                    {` | `}
                                    <span className={style.assess_label_subDetail_label}>Photo taken on:</span> <span className={style.assess_label_subDetail_labelout}>{assessment?.identifiedDate ? fullMonthFormatDate(assessment?.identifiedDate) : 'NA'}</span>
                                  </div>

                                  <div className={`${style.assess_content} mb-2`}>
                                    <span className={style.assess_label_subDetail_label}>Assessed by:</span> <span className={style.assess_label_subDetail_labelout}>{assessment?.assessedBy ? assessment.assessedBy : 'NA'}</span>
                                    {` | `}
                                    <span className={style.assess_label_subDetail_label}>Last assessed: </span>
                                    <span className={style.assess_label_subDetail_labelout}>
                                      {index !== 0 ?
                                          assessment.lastAssessedDate ? fullMonthFormatDate(assessment?.lastAssessedDate) : 'NA'
                                          :
                                          assessment.lastAssessedDate ? daysAgo(assessment?.lastAssessedDate) : 'NA'
                                      }
                                    </span>                                    
                                  </div>

                                  <div className={`${style.assess_content} mb-1`}>
                                    <span className={style.assess_label_subDetail_label}>Signed:</span> <span className={style.assess_label_subDetail_labelout}>{assessment.signedBy ? assessment.signedBy : 'NA'}</span>
                                      {` | `}
                                    <span className={style.assess_label_subDetail_label}>Reviewed & Locked:</span> <span className={style.assess_label_subDetail_labelout}>{assessment?.lockedBy ? assessment?.lockedBy : 'NA'}</span>
                                  </div>

                                </div>

                                <div className={`col-3`}>                                
                                    {
                                      assessment && assessment?.reEvalSequence > 0 && userRole?.role !== 'ExternalStandardUser' ? 
                                        <div className={`row align-items-center`}>
                                          <div className={`${style.assement_all_link}`}>
                                            {
                                              assessment && !isAdminView && 
                                              <a
                                                className={style.assess_link}
                                                onClick={() => modalOpen(assessment.id, assessment.title)}
                                              >
                                                All assessments                                              
                                                <span 
                                                    className={`${style.right_Arrow}`}
                                                    onClick={() => modalOpen(assessment.id, assessment.title)}                         
                                                    >                                                    
                                                    <i class="fa-solid fa-circle-right" aria-hidden="true"></i>
                                                </span> 
                                              </a>
                                            }
                                          </div>                                          
                                      </div>

                                      : ''
                                    }
                                  
                                </div>  

                              </div>
                          </div>
                          </div>
                        </div>
                        

                        

                      </div>

                    }) : <div className={`pt-4 pb-4 text-center ${style.no_data_content}`}>There are no assessments</div>
                  }
                </div>
                }
              />

              {/* confirmation */}
              {
                showDeleteConfirmation && <Confirmation
                  onClose={() => setShowDeleteConfirmation(false)}
                  title={" "}
                >
                  <div className="text-center pr-5 pl-5">
                    <p>
                      <img src={warning} alt="warnning-icon" />
                    </p>
                    <p className="pb-3">Are you sure you’d like to delete Items?</p>
                    <div className="mb-2">
                      <Button
                        value="No"
                        classes={{ className: `btn mr-3 ml-2 pb-2 pt-2 ${style.no_btn}` }}
                        onClick={() => setShowDeleteConfirmation(false)}
                      />
                      <Button
                        value="Yes"
                        classes={{ className: `btn mr-2 pb-2 pt-2 ${style.yes_btn}` }}
                        onClick={handleDelete}
                      />
                    </div>
                  </div>
                </Confirmation>
              }
            </div>
            )
          })
        }

        {
          modalShow && <AssessmentHistory
            modalOpen={modalShow}
            modalClose={'modalClose'}
            setmodalShow={setmodalShow}
            assessmentTitle={assessmentTitle}
            modalTitlteId={modalTitlteId}
            getAssessmentHistory={getAssessmentHistory}
          />
        }
      </>
      :
      <div className="m-3" style={{background: "#fff", boxShadow: '0px 10px 20px #e4e4e4', padding: '1rem', paddingLeft: '32px', transform:'translateX(-15px'}}><h3>Patient is Inactive</h3></div>
      }
    </>}
  </div>
  }
  </>
  )
}
// WoundAssessment.propTypes = {
//   detailAssessment: PropTypes.object  
// }

// WoundAssessment = connect()(withRouter(WoundAssessment));
