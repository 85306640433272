import React, { useState } from 'react';
import { Tab as ReactTabs, Tabs, TabList, TabPanel } from 'react-tabs';
import { PropTypes } from 'prop-types';
import 'react-tabs/style/react-tabs.css';
import styles from './verticalTabWorkList.module.scss';

export const VerticalTabWorkList = ({ tabList, onSelectInt, defaultIndex, dispatch }) => {

    /* State for selected index */
    const [selectedIndex, setSelectedIndex] = useState(defaultIndex?defaultIndex:0);

    /* on Select updated selected index */
    const onSelect = (i) => {setSelectedIndex(i);if(onSelectInt)onSelectInt(i)}

    return (
        <div className={styles.vertical_tab}>
            <Tabs onSelect={onSelect} defaultIndex={defaultIndex} selectedIndex={selectedIndex}>
                <TabList className={styles.react_tabs}>
                    {/* Map through tabs  */}
                    {tabList.map((tab, i) => (
                        <ReactTabs className={styles.react_tabs_list} selectedClassName={styles.selected} key={tab.name}>
                            {tab.name}
                            <div className="clearfix"></div>
                            <span className={`${styles.indication_btn} ${tab.bgcolor}`}>{tab.bottomName}</span>
                        </ReactTabs>
                    ))}
                </TabList>
                {/* Map throgh tab panel */}
                {tabList.map((tabC, index) => (
                    <TabPanel className={styles.react_tab_pane} selectedClassName={styles.selected} key={index}>
                        {/* Close our component and pass index as props */}
                        {React.cloneElement(
                            tabC.content,
                            {
                                index: index, // Passing index
                                dispatch: dispatch,
                                selectedIndex: setSelectedIndex // Navigation to next
                            }
                        )}
                    </TabPanel>
                ))}
            </Tabs>
        </div>
    );
};

VerticalTabWorkList.propTypes = {
    tabList: PropTypes.array,
    onSelect: PropTypes.func,
    selectedIndex: PropTypes.number
};
