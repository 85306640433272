import React from "react";
import style from './selectBox.module.scss';
import PropTypes from 'prop-types';

export function SelectBox({ classes, id='', label, name='', placeholder='', optionValue, defaultValue, disabled=false, validator, required, errors, onChange, customForm=false,customError,disabledValues}) {

    // Option value should be in the following format
    // [{key:'a', value: 'India'}, {key: 'b', value : 'Canada'}]

    const optionData = optionValue && optionValue.length > 0 && Array.isArray(optionValue) && optionValue.map((item,index)=>
            
            defaultValue && (defaultValue===item.value) ?
            <option key={index} value={item.key} selected disabled={item.manualPatientCreation==="false" ? true : false} >{item.value}</option>
            :
            <option key={index} className={item.manualPatientCreation==="false" ? `${style.ManualIndimationtooltip}` : ""} value={item.key} disabled={item.manualPatientCreation==="false" ? true : false}>{item.value}</option>
       
    )
    const errorMessage = errors && errors[name] && errors[name].message;
    
    return (
            <div className={(classes) ? classes.column : ''}>
                {/* Label  */}
                {!customForm ? label && <label htmlFor={id} className={(classes) ? classes.labelClassName : ''}>{label} {required ? <span className={style.astrick}>&#42;</span> : ''}</label>:''}
                {/* Inner div */}
                <div className={(classes) ? classes.inputColumn : ''}>
                    {/* Input */}
                    {customForm ? label && <label htmlFor={id} className={(classes) ? classes.labelClassName : ''}>{label} {required ? <span className={style.astrick}>&#42;</span> : ''}</label>:''}
                    {!customForm ? <select
                        name={name} 
                        id={id} 
                        className={`${(classes) ? classes.inputClassName : ''}  ${errorMessage ? `${style.error_border}` : ''}`}
                        placeholder={placeholder}
                        disabled={disabled}
                        ref={validator} 
                        autoComplete="off"
                        onChange={onChange}
                        defaultValue={defaultValue}
                        data-testid={'select_'+id}
                    >
                    {
                        placeholder !=='' && <option value="">{placeholder}</option>
                    }
                    { optionData }               
                    </select>
                    :<select
                        name={name}
                        id={id}
                        className={`${(classes) ? classes.inputClassName : ''}  ${errorMessage ? `${style.error_border}` : ''} ${customError ? `${style.error_border}` : ''}`}
                        placeholder={placeholder}
                        disabled={disabled}
                        ref={validator}
                        autoComplete="off"
                        onChange={onChange}
                        value={defaultValue}
                        data-testid={'select_'+id}
                    >
                        {
                            placeholder !=='' && <option value="">{placeholder}</option>
                        }
                        { optionData }                        
                    </select>
                    }
                    {/* Error */}
                    {errorMessage && <span className={style.error_message}>{errorMessage}</span>}
                    {customError && <span className={style.error_message}>{customError}</span>}
                </div>
            </div>
    );
}

SelectBox.propTypes = {
    classes:PropTypes.object,
    label:PropTypes.string,
    name:PropTypes.string,
    placeholder:PropTypes.string,
    optionValue:PropTypes.array,
    disabled:PropTypes.bool,
    validator:PropTypes.func,
    errors:PropTypes.object,
    onChange: PropTypes.func
}
