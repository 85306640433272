import React from 'react'
import {
    ANNOUNCEMENT_NAME_LBL,
    SALES_OFFICE_NAME_LBL,
    ANNOUNCEMENT_DESCRIPTION_LBL,
    ANNOUNCEMENT_ID,
    SALES_OFFICE_ID,
    ANNOUNCEMENT_DESCRIPTION_ID,
    SAVE_BTN, SUBMIT_ID, CANCEL_BTN, CANCEL_ID,
    SALES_OFFICE_OPTIONS,
    ANNOUNCEMENT_DESCRIPTION_LIMIT,
    ANNOUNCEMENT_NAME_LIMIT
} from '../../../constants'
import { Form, TextField, Button, SelectBox, TextArea } from '../../primary'
import { checkAnnouncementName, checkSalesOffice, checkAnnouncementDescription } from '../../../utils'
import styles from './createEditAnnouncement.module.scss'
import PropTypes from 'prop-types'

export function CreateEditAnnouncement({ register, handleSubmit, handleCancel, onSubmit, errors }) {
    const desc_limit = ANNOUNCEMENT_DESCRIPTION_LIMIT;
    const name_limit = ANNOUNCEMENT_NAME_LIMIT;
    const [textAreaContent, setTextAreaContent] = React.useState('');
    const [textNameContent, setTextNameContent] = React.useState('');
    const setDescriptionContent = React.useCallback(
        text => {
            setTextAreaContent(text.slice(0, desc_limit));
        },
        [desc_limit, setTextAreaContent]
    );

    const setNameContent = React.useCallback(
        text => {
            setTextNameContent(text.slice(0, name_limit));
        },
        [name_limit, setTextNameContent]
    );
    const handleClear = (e) => {
        e.preventDefault();
        setTextAreaContent('');
        setTextNameContent('');
        handleCancel(e)
    }
    return (
        <>
            <Form formChanges={true} className={`pl-2 ${styles.patient_content}`} onSubmit={handleSubmit(onSubmit)} >
                {/* Error message  */}
                <TextField
                    id={ANNOUNCEMENT_ID}
                    name={ANNOUNCEMENT_ID}
                    label={`${ANNOUNCEMENT_NAME_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-8 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-9 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(checkAnnouncementName)}
                    defaultValue={textNameContent}
                    errors={errors}
                    required={true}
                    charLimit={name_limit}
                    onChange={event => setNameContent(event.target.value)}
                />

                {/* Country Code */}
                <SelectBox
                    id={SALES_OFFICE_ID}
                    name={SALES_OFFICE_ID}
                    label={`${SALES_OFFICE_NAME_LBL}:`}
                    classes={{
                        column: `col-8 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0`
                    }}
                    optionValue={SALES_OFFICE_OPTIONS}
                    validator={register(checkSalesOffice)}
                    placeholder={'Select'}
                    errors={errors}
                    required={true}
                />

                <TextArea
                    classes={{
                        column: `col-8 ${styles.col_spec}`,
                        inputClassName: `${styles.textarea_box} ${styles.text_content} w-100`,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-12 p-0`
                    }}
                    rows={3}
                    id={ANNOUNCEMENT_DESCRIPTION_ID}
                    name={ANNOUNCEMENT_DESCRIPTION_ID}
                    label={`${ANNOUNCEMENT_DESCRIPTION_LBL}:`}
                    validator={register(checkAnnouncementDescription)}
                    errors={errors}
                    required={true}
                    value={textAreaContent}
                    charLimit={desc_limit}
                    onChange={event => setDescriptionContent(event.target.value)}
                />


                <div className="col-sm-12 pb-4">
                    <div className="text-center">
                        {/* Save Button */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                            type="submit"
                            id={SUBMIT_ID}
                        >
                            {SAVE_BTN}
                        </Button>
                        {/* Cancel button  */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={handleClear}
                        >
                            {CANCEL_BTN}
                        </Button>
                    </div>
                </div>

            </Form>
        </>
    )
}

CreateEditAnnouncement.propTypes = {
    register: PropTypes.func,
    handleSubmit: PropTypes.func,
    control: PropTypes.object,
    handleCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    customerData: PropTypes.object,
    externalAdmin: PropTypes.bool
}
