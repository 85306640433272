import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  ALREADY_REGISTERED_LBL,
  LOGIN_LBL,
  REGISTER_HEAD_LBL,
  FIRST_NAME_LBL,
  LAST_NAME_LBL,
  CUSTOMER_NUMBER_LBL,
  PHONE_LBL,
  EMAIL_LBL,
  PASSWORD_LBL,
  CONFIRM_PASSWORD_LBL,
  REGISTER_BTN,
  PRIVACY_POLICY_LBL,
  TERMS_LBL,
  EMAIL_PHDR,
  PASSWORD_PHDR,
  PHONE_PHDR,
  CUSTOMER_NUMBER_PHDR,
  LAST_NAME_PHDR,
  FIRST_NAME_PHDR,
  FIRST_NAME_ID,
  LAST_NAME_ID,
  CUSTOMER_NUMBER_ID,
  PHONE_ID,
  EMAIL_ID,
  PASSWORD_ID,
  CONFIRM_PASSWORD_ID,
  CHECK_TERMS_ID,
  REGISTER_BTN_ID,
  CONFIRM_PASSWORD_REQUIRED,
  PASSWORD_NOMATCH,
  TERMS_URL,
  PRIVACY_POLICY_URL,
  US_STATES,
} from "../../../constants";
import { Button, TextField, CheckBox, Form, SelectBox } from "../../primary";
import { Link } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  setRegistrationFormData,
  getPrimaryCustomerFacility,
  setPrimaryCustomerFacility,
  getZipByFacility,
  setZipFacilitySearchDatas,
  getLocationByCountry,
  setLocationByCountry
} from "../../../app/userSlice";
import { clearError } from "../../../app/appSlice";
import {
  ServiceError,
  phoneMasker,
  commonHintCheck,
  checkResetNewPassword,
  checkEmail,
  checkConfirmPassword,
  checkFirstName,
  checkLastName,
  checkCustomerNumber,
  checkPhoneNumber,
  checkTos,
  checkCompanyEmail,
  selectBoxConverter,
  getRegFacilityLabel,
  useDebounce
} from "../../../utils";
import { Banner } from "../../secondary/Banner";
import { Logo } from "../../secondary/Logo";
import {
  link_style,
  subheading,
  form_shadow,
  title,
  medline_block,
  service_error,
  close_btn,
  not_list_error
} from "../../../styles/common.module.scss";
import style from "./registration.module.scss";
import closeErrorIcon from "./../../../static/images/Close_Normal.svg";
import questionIcon from "../../../static/images/questionIcon.svg";
import arrowRight from '../../../static/images/ArrowRight.svg';
import arrowLeft from '../../../static/images/ArrowLeft.svg';
export function RegistrationForm({ onSubmit, regType, setRregType, logEmail,history }) {
  const registrationFormData = useSelector(
    (state) => state.user.registrationFormData,
    shallowEqual
  );
  const primaryCustomerFacilityData = useSelector(
    (state) => state.user.primaryCustomerFacility
  );

  //RegResCheckAllFlag
  const regResCheckAllFlag = useSelector(
    (state) => state.user.regResCheckAllFlag
  );

  const organizationTypeOptions = useSelector(
    (state) => state.user.organizationType
  );
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, reset, errors, setValue,setError, getValues } = useForm({
    mode: "submit",
  //  defaultValues: registrationFormData,
  });
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
    setValue:setLogEmail
  } = useForm({ mode: "submit" });

  const {
    register: registerZip,
    formState: { errors: errorsZip },
    handleSubmit: handleSubmitZip,
    reset: resetZip,
    
  } = useForm({ mode: "submit" });

  let phoneField = useRef();
  const errorMessage = ServiceError();
  const passwordCheck = watch(PASSWORD_ID);
  const fields = watch([
    "firstName",
    "lastName",
    "customerNumber",
    "phone",
    "email",
  ]);

  const [minLength, setMinLength] = useState(false);
  const [oneSpecialChar, setOneSpecialChar] = useState(false);
  const [oneNum, setOneNum] = useState(false);
  const [oneUCase, setOneUCase] = useState(false);
  const [checkCustomerFacilityData, setCheckCustomerFacilityData] = useState(
    ""
  );
  const [formType, setFormType] = useState('');
  useEffect(() => {
    let optionData = [];
    primaryCustomerFacilityData &&
      Array.isArray(primaryCustomerFacilityData) &&
      primaryCustomerFacilityData.map((value, index) => {//NOSONARoptionData
        return optionData.push({
          value: getRegFacilityLabel(value),
          key: value.facilityNumber,
          item: formType?.selectForm&&formType?.selectForm==='custFacilityEmail'?`${
            value?.facilityName ? ` ${value.facilityName}`:'' }`:`${value?.facilityNumber ? value.facilityNumber : ""}${
              value?.facilityName ? ` | ${value.facilityName}`:'' }`,
          isMultiplePrimaryCustomerExists:value?.isMultiplePrimaryCustomerExists
        });
      }); //NOSONAR
    setCheckCustomerFacilityData(optionData);
  }, [primaryCustomerFacilityData]);
  useEffect(() => {
    if (!shallowEqual(fields, registrationFormData)) {
      dispatch(setRegistrationFormData(fields));
    }
  }, [fields, registrationFormData, dispatch]);

  if (phoneField.current) {
    if (phoneField.current.value.length > 9) {
      phoneField.current.value = phoneMasker(phoneField.current.value);
    }
  }

  const validators = {
    checkEmail,
    checkResetNewPassword,
    checkConfirmPassword,
    checkFirstName,
    checkLastName,
    checkCustomerNumber,
    checkPhoneNumber,
    checkTos,
    checkCompanyEmail,
  };

  const passwordCondition = () => {
    commonHintCheck(
      passwordCheck,
      setMinLength,
      setOneUCase,
      setOneSpecialChar,
      setOneNum
    );
  };

  const doSubmit = (data) => {
      if(document.getElementById('email')&&data.email===undefined){
        data.email=document.getElementById('email').value
      }
      if(document.getElementById('facilityCity')&&data.facilityCity===undefined){
        data.facilityCity=document.getElementById('facilityCity').value
      }
      if(document.getElementById('facilityState')&&data.facilityState===undefined){
        data.facilityState=document.getElementById('facilityState').value
      }
       if(document.getElementById('country')&&data.country===undefined){
        data.country=document.getElementById('country').value
      }
      onSubmit(data, reset);
  };
  const doSubmitCustNumEmail = (data) => {
    dispatch(setLocationByCountry({}))
    setCustomerNumberCheck('')
    setInitCustomerNumberCheck('')
    setEmailCheck('')
    reset({});
    dispatch(clearError());
    dispatch(setZipFacilitySearchDatas([]))
    let queryString = "";
    if (regType === "custFacilityEmail") {
      setEmailCheck(data.emailCheck)
      queryString = "?Email=" + data.emailCheck.trim();
    } else if (regType === "custFacilityNumber"||regType==="custFacilityNumberCheck") {
      setRregType('custFacilityNumber')
      setInitCustomerNumberCheck(data.customerNumberCheck.trim())
      setCustomerNumberCheck(data.customerNumberCheck.trim())
      queryString = "?CustomerNumber=" + data.customerNumberCheck.trim();
    }
    // dispatch(setPrimaryCustomerFacility({}));
   
    dispatch(getPrimaryCustomerFacility(queryString, setRregType, regType,setCustomerNumberCheck));
    setFormType({selectForm:regType==="custFacilityNumberCheck"?'custFacilityNumber':regType,email:data?.emailCheck?data?.emailCheck:''})
    setValue("email",logEmail);
    resetZip({})
    reset2({})
    /*setError("email", {
      type: "manual",
      message: "Domain name issues!"
    });*/
    if(logEmail){
      setLogEmail('emailCheck',logEmail)
      setEmailCheck(logEmail)
      setValue("email", logEmail);
    }
  };
  const custFacilityEmailReg = (e) => {
    dispatch(setLocationByCountry({}))
    reset2({});
    dispatch(clearError());
   // setRregType("custFacilityEmail");
    resetZip({})
    if(logEmail){
      setLogEmail('emailCheck',logEmail)
      setEmailCheck(logEmail)
      let queryString ="?Email=" + logEmail;
      dispatch(getPrimaryCustomerFacility(queryString, setRregType, 'custFacilityEmail',setCustomerNumberCheck));
      setFormType({selectForm:'custFacilityEmail',email:logEmail})
      setValue("email", logEmail);
    }
      };

  useEffect(() => {
    if ((errorMessage==="Enter a work email provided by your Employer"||errorMessage==="Enter a work email provided by your employer")&&(regType==='')) {
       setError("email", {
          type: "manual",
          message: 'Enter a work email provided by your employer'
    });
    dispatch(clearError());
    }
  }, [errorMessage,dispatch]);
  const regResMatchCustomerNumZip = useSelector(
    (state) => state.user.regResMatchCustomerNumZip
  );
  const doSubmitZip = (data) => {
    setZipSearchInput('');
    if(data?.zipCode){
      const zipCode=data?.zipCode;
      let finalCustNum=''
      if(regResMatchCustomerNumZip&&Array.isArray(regResMatchCustomerNumZip?.facilitiesInfo)&&regResMatchCustomerNumZip?.facilitiesInfo.length>0){
        regResMatchCustomerNumZip?.facilitiesInfo.map((key, index) => {
          if(key?.address?.zipCode===data?.zipCode){
           finalCustNum=key?.facilityNumber
          }
          return key;
        })
      }
      //setZipSearchInput(data?.zipCode);
      dispatch(setZipFacilitySearchDatas([]));
      dispatch(clearError());
      //dispatch(getZipByFacility(data?.zipCode,customerNumberCheck));
      if(regResMatchCustomerNumZip?.customerNumber){
        dispatch(getZipByFacility(data?.zipCode,regResMatchCustomerNumZip?.customerNumber,setRregType));
      }
      else if(customerNumberCheck){
        dispatch(getZipByFacility(data?.zipCode,customerNumberCheck,setRregType));
      }else if(finalCustNum){
        dispatch(getZipByFacility(data?.zipCode,finalCustNum,setRregType));
      }
      
    
    }
    
    if(data?.zipfacility&&document.getElementById('zipfacility')){
      let zipFacility = JSON.parse(data?.zipfacility)
      zipFacility=zipFacility.item
      setCheckCustomerFacilityData([]);
       setCheckCustomerFacilityData([{
        value:getRegFacilityLabel(zipFacility),
        key: zipFacility.facilityNumber,
        item: `${zipFacility.facilityNumber ? zipFacility.facilityNumber : ""}${
          zipFacility.facilityName ? ` | ${zipFacility.facilityName}`:'' }`,
        zipSelectOnly:1  
      }]);
       setRregType('')
    }
};
const [customerNumberCheck, setCustomerNumberCheck] = useState("");
const [initCustomerNumberCheck, setInitCustomerNumberCheck] = useState("");
const [emailCheck, setEmailCheck] = useState('');
const [zipSearchInput, setZipSearchInput] = useState("");
const searchZip = useDebounce(zipSearchInput, 500);

 useEffect(() => {
  if (searchZip && searchZip.length >= 1) {
    dispatch(clearError());
    dispatch(getZipByFacility(searchZip,customerNumberCheck));
  } else {
    dispatch(setZipFacilitySearchDatas([]));
  }
}, [searchZip]);
const handleZipcodeChange = (event) => {
  const input = event.target.value;
  setZipSearchInput(input);

};
const zipFacilitySelect = (e, keys) => {
  e.stopPropagation();
  setDropDown(false);

  if (keys) {
    const zipFacility = keys.item;
    setitemValueText(
      <>
          <div className={`${style.cat}`}>{zipFacility.facilityName ?zipFacility.facilityName : 'Select your facility/location'}</div>
          <div className={`${style.material_description}`}>
            {zipFacility?.address?.addressLine1 ? `${zipFacility?.address?.addressLine1}` : ''} 
            {zipFacility?.address?.city ? `, ${zipFacility?.address?.city}` : ''}
            {zipFacility?.address?.state ?`, ${zipFacility?.address?.state}`:''}
            </div>
      </>
  )
  setSelectedFacilityLoc(JSON.stringify(keys))
      setCheckCustomerFacilityData([]);
       setCheckCustomerFacilityData([{
        value:`${zipFacility.facilityNumber ? zipFacility.facilityNumber : ""}${
          zipFacility.facilityName ? ` | ${zipFacility.facilityName}`:'' }`,
        key: zipFacility.facilityNumber,
        item: `${zipFacility.facilityNumber ? zipFacility.facilityNumber : ""}${
          zipFacility.facilityName ? ` | ${zipFacility.facilityName}`:'' }`
      }]);
      // setRregType('')
  }else{
    setSelectedFacilityLoc('')
    setitemValueText(
      <>
          <div className={`${style.cat}`}>{'Select your facility/location'}</div>
      </>
  )
  }
  
};
const zipFacilitySearchDatas = useSelector(
  (state) => state.user.zipFacilitySearchDatas
);
//locationByCountry
const locationByCountryDatas = useSelector(
  (state) => state.user.locationByCountry
);
useEffect(() => {
  if (locationByCountryDatas) {
    if(locationByCountryDatas?.city){
      setValue("facilityCity", locationByCountryDatas?.city);
    }
    if(locationByCountryDatas?.state){
      setValue("facilityState", locationByCountryDatas?.state);
    }
    if(locationByCountryDatas?.country){
      setValue("country", locationByCountryDatas?.country?locationByCountryDatas?.country:'');
    }
 
  }
}, [locationByCountryDatas,dispatch]);
//onCountryZipSelect
const onCountryZipSelect = (event) => {
  dispatch(clearError());
  event.stopPropagation();
  const postalcode = event.target.value;
  const country=getValues('country')?getValues('country'):'ca';
  if (postalcode&&postalcode.length>=3) {
    dispatch(getLocationByCountry(postalcode,''));
  }
};
const backZipFaciClick = () => {
  if(Array.isArray(zipFacilitySearchDatas)&&zipFacilitySearchDatas.length > 0){
    dispatch(setZipFacilitySearchDatas([]));
   // setRregType('isPrimaryGroup')
  }else if(regType){
     setRregType('custFacilityNumber')
  }
  reset2({})
  resetZip({})
  dispatch(clearError());
  setitemValueText('Select your facility/location')
  setSelectedFacilityLoc('')
};
//zipFacilitySelect
const [itemValueText, setitemValueText] = useState('Select your facility/location');
//selectedFacilityLoc
const [selectedFacilityLoc, setSelectedFacilityLoc] = useState('');
const [viewDropDown, setDropDown] = useState(false)
   /* Function to toggle drop assessment*/
   const handleDropDown = (e) => {
    setDropDown(!viewDropDown)
}

/* Trigger Drop Down Items close */
const dropDownRef = useRef();
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);
  return () => document.removeEventListener('mousedown', handleClickOutside);
});

const handleClickOutside = (e) => {
  if (dropDownRef !== null && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
      setDropDown(false);
  }
};

  return (
    <div className={`container-fluid ${medline_block}`}>
      <div className="row vh-100">
        <div className="col-sm-12 col-lg-6">
          <Logo />
          <div className="container pt-3">
            <div className="row">
              <div
                className={`col-12 col-sm-12 mx-auto pt-5 pb-5 ${form_shadow}`}
              >
                <div className="col-12">
                <>
                  {regType===''&&
                         <button className={`${style.close_password}`} onClick={()=>{dispatch(clearError()); history.push('/')}} >
                          {/* <img src={close_icon_gray} alt="Close Password" /> */}
                        </button>
                      }
                    </>
                  <h1 className={title}>{REGISTER_HEAD_LBL}</h1>

                   {regType === "custFacilityNumber"||regType==="custFacilityNumberCheck" &&<div className={'mt-3 mb-3'}>
                     {logEmail?`${logEmail}`:''} 
                         <button onClick={()=>{dispatch(clearError()); history.push('/')}} className={link_style}>
                          {'Change'}
                        </button>
                    </div>
                  }
                  
                  {/* <p>
                   {
                      regType===''&&(
                        <span className={subheading}>
                      {ALREADY_REGISTERED_LBL}&#160;
                      <Link to="/login" className={link_style}>
                        {LOGIN_LBL}
                      </Link>
                    </span>
                      )
                   } 
                  </p> */}
                </div>
                {/* {errorMessage && <div data-testid="service_error" className={service_error}>{errorMessage}</div>} */}
                {errorMessage && (
                  <div className="row">
                    <div
                      data-testid="service_error"
                      className={service_error}
                      dangerouslySetInnerHTML={{
                        __html: errorMessage,
                      }}
                    ></div>
                    <>
                      {
                        <button
                          className={`${close_btn}`}
                          onClick={() => {
                            dispatch(clearError());
                          }}
                        >
                          <img src={closeErrorIcon} alt="Close" />
                        </button>
                      }
                    </>
                  </div>
                )}

                {/*regType custFacilityNumber custFacilityEmail*/}
                {regType === "custFacilityNumber" ? (
                  <Form
                    className={"col-12  mx-auto submitData"}
                    onSubmit={handleSubmit2(doSubmitCustNumEmail)}
                  >
                    <TextField
                      classes={{
                        column: "form-group",
                        inputClassName: "form-control mb-1",
                        inputColumn: "col-12 p-0",
                      }}
                      label={"Medline account number"}
                      type={"text"}
                      name={"customerNumberCheck"}
                      id={"customerNumberCheck"}
                    // placeholder={"Medline account number"}
                      validator={register2(checkCustomerNumber)}
                      errors={errors2}
                      required={false}
                      autoComplete={'off'}
                    />
                     <div className="form-group mt-4 d-flex justify-content-between">
                    <Button
                      id={"back_act_num_id"}
                      type={"button"}
                      value={"<- Back"} 
                      classes={{
                        className: `btn ${style.btn_nxt_back} ${style.btn_back}`,
                      }}
                     onClick={() => {
                          history.push('/')
                        }}
                    >
                      {/* <img src={arrowLeft} className={`mr-1`}/>   */}
                      Back
                      </Button>
                    <Button
                      id={"next_cust_fac_num_id"}
                      type={"submit"}
                      value={"Next ->"}
                      classes={{
                        className: `btn ${style.btn_nxt_back} ${style.custom_btn} float-right p-1`,
                      }}
                    >Next 
                    {/* <img src={arrowRight} className={`mb-1`} /> */}
                    </Button>
                    </div>
                    {/* <div className="form-group mt-4">
                      <Button
                        id={"next_cust_fac_num_id"}
                        type={"submit"}
                        value={"Next"}
                        classes={{
                          className: `btn btn-block ${style.custom_btn}`,
                        }}
                      />
                    </div> */}

                    <div className={`${style.hr_sect} mt-4`}> or </div>

                    <div className="form-group mt-4">
                      <Button
                        id={"next_cust_fac_email_id"}
                        type={"button"}
                        classes={{
                          className: `btn btn-block  ${style.custom_btn_cancel}`,
                        }}
                       // onClick={(e) => custFacilityEmailReg(e)}
                       onClick={() => {
                        setRregType('custFacilityNumberCheck')
                    }}
                      >
                        <img className={`mr-3`} src={questionIcon} alt="info" />

                        {"I don't have the Medline account number"}
                      </Button>
                    </div>
                  </Form>
                ):regType === "custFacilityNumberCheck" ? (
                  <Form
                    className={"col-12  mx-auto submitData"}
                    onSubmit={handleSubmit2(doSubmitCustNumEmail)}
                  >
                    <p><span className={'text-danger font-weight-bold'}>!</span> We strongly advise registering with a Medline account number, as doing so will grant you immediate access to the app. Registering without it may necessitate approval, which could result in a delay.
</p>
                    <TextField
                      classes={{
                        column: "form-group",
                        inputClassName: "form-control mb-1",
                        inputColumn: "col-12 p-0",
                      }}
                      label={"Medline account number"}
                      type={"text"}
                      name={"customerNumberCheck"}
                      id={"customerNumberCheck"}
                    // placeholder={"Medline account number"}
                      validator={register2(checkCustomerNumber)}
                      errors={errors2}
                      required={false}
                      autoComplete={'off'}
                    />
                     <div className="form-group mt-4 d-flex justify-content-between">
                    <Button
                      id={"back_act_num_id"}
                      type={"button"}
                      value={"<- Back"} 
                      classes={{
                        className: `btn ${style.btn_nxt_back} ${style.btn_back}`,
                      }}
                     onClick={() => {
                            setRregType('custFacilityNumber')
                        }}
                    >
                      {/* <img src={arrowLeft} className={`mr-1`}/>   */}
                      Back
                      </Button>
                    <Button
                      id={"next_cust_fac_num_id"}
                      type={"submit"}
                      value={"Next ->"}
                      classes={{
                        className: `btn ${style.btn_nxt_back} ${style.custom_btn} float-right p-1`,
                      }}
                    >Next 
                    {/* <img src={arrowRight} className={`mb-1`} /> */}
                    </Button>
                    </div>
                    {/* <div className="form-group mt-4">
                      <Button
                        id={"next_cust_fac_num_id"}
                        type={"submit"}
                        value={"Next"}
                        classes={{
                          className: `btn btn-block ${style.custom_btn}`,
                        }}
                      />
                    </div> */}

                    <div className={`${style.hr_sect} mt-4`}> or </div>

                    <div className="form-group mt-4">
                      <Button
                        id={"next_cust_fac_email_id"}
                        type={"button"}
                        classes={{
                          className: `btn btn-block  ${style.custom_btn_cancel}`,
                        }}
                        onClick={(e) => custFacilityEmailReg(e)}
                      >
                        {"Continue without the Medline account number"}
                      </Button>
                    </div>
                  </Form>
                )  : regType === "custFacilityEmail" ? (
                  <Form
                    className={"col-12  mx-auto submitData"}
                    onSubmit={handleSubmit2(doSubmitCustNumEmail)}
                  >
                    <TextField
                      classes={{
                        column: "form-group",
                        inputClassName: "form-control mb-1",
                        inputColumn: "col-12 p-0",
                      }}
                      label={"Work email"}
                      type={"text"}
                      name={"emailCheck"}  
                      id={"emailCheck"}
                     // placeholder={"Work email"}
                      validator={register2(checkCompanyEmail)}
                      errors={errors2}
                      required={true}
                      defaultValue={logEmail?logEmail:''}
                    />
                    <div className="form-group mt-4">
                      <Button
                        id={"next_cust_fac_email_id"}
                        type={"submit"}
                        value={"Next"}
                        classes={{
                          className: `btn ${style.btn_nxt_back} ${style.custom_btn} float-right p-1`,
                        }}
                        >Next 
                        {/* <img src={arrowRight} className={`mb-1`} /> */}
                        </Button>
                    </div>
                  </Form>
                ) : regType === "isPrimaryGroup" ?(
                  <Form
                    className={"col-12  mx-auto submitData"}
                    onSubmit={handleSubmitZip(doSubmitZip)}
                  >
                     {zipFacilitySearchDatas &&
                  Array.isArray(zipFacilitySearchDatas) &&
                  zipFacilitySearchDatas.length === 0 && (
                     <TextField
                            classes={{
                              column: "form-group",
                              inputClassName: "form-control mb-1",
                              inputColumn: "col-12 p-0",
                            }}
                            label={"Facility/Location zip code"}
                            type={"text"}
                            name={"zipCode"}
                            id={"zipCode"}
                            placeholder={"Enter facility/location zip code"}
                            validator={registerZip({
                              required: "Enter your facility/location zip code",
                              pattern: {
                                value: /^[a-zA-Z0-9-]+$/,
                                message: "Enter valid facility/location zip code"
                            }
                            })}
                            errors={errorsZip}
                            required={true}
                            maxLength={9}
                          />
                          )}

{zipFacilitySearchDatas &&
                  Array.isArray(zipFacilitySearchDatas) &&
                  zipFacilitySearchDatas.length > 0 && (<div className={`form-group`}>
                  {/* <label className={``}>Select facility:</label> */}
                  <label for="organizationType" className={`${style.label_spec} mb-1`}>Facility/Location <span>*</span></label>
                  <div className={`col-12 p-0`}>
                  <div className={`dropdown ${style.risk_select_box}`}>
                    <div className={`btn btn-secondary dropdown-toggle testdropdown ${style.drop_arrow} ${style.dropdown_toggle} `} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={handleDropDown} ref={dropDownRef} id={'dyn'}>
                      <input type='hidden' 
                       id={"zipfacility"}
                       name={"zipfacility"}
                       ref={registerZip({
                       required: "Select your facility/location from dropdown",
                       })} 
                       placeholder={'Select facility/location'}
                       errors={errorsZip}
                       defaultValue={selectedFacilityLoc}
                      />
                        {
                             itemValueText
                        }

                    </div>
                    {viewDropDown &&
                        <div className={`dropdown-menu show ${style.risk_drop_down}`} aria-labelledby={''} ref={dropDownRef}>
                         <>
                                            <div className={`dropdown-item  ${style.risk_item_wrap}`} id = {`handle_change`}  onClick={(e) => zipFacilitySelect(e, '')}>
                                                <div className={`${style.cat}`}>Select your facility/location</div>
                                               
                                            </div>
                                        </>
                            {
                               zipFacilitySearchDatas  && zipFacilitySearchDatas.length > 0 && zipFacilitySearchDatas.map((data, i) => {
                                    return (
                                        <>
                                        <div className={`dropdown-item  ${style.risk_item_wrap} `}  value={JSON.stringify(data)} key={i}  items={JSON.stringify(data)}  onClick={(e) => zipFacilitySelect(e, data)}>
                                            <div className={`${style.cat}`}>{data?.item?.facilityName}</div>
                                            <div className={`${style.material_description}`}>
                                              <div>
            {data?.item?.address?.addressLine1 !==null ? `${data?.item?.address?.addressLine1}` : ''} 
            {data?.item?.address?.city ? `, ${data?.item?.address?.city}` : ''}
            {data?.item?.address?.state ?`, ${data?.item?.address?.state}`:''}	
                                              </div>
                                            </div>
                                        </div>

                                    </>              )
                                })
                            }
                        </div>
                    }

                </div>
                  {errorsZip?.zipfacility?.message&&<><span className={`${style.error_message}`}>{errorsZip?.zipfacility?.message}</span></>}
                  </div>
                  </div>
                  )}

                  {/* {zipFacilitySearchDatas &&
                  Array.isArray(zipFacilitySearchDatas) &&
                  zipFacilitySearchDatas.length > 0 && (<div className={`form-group`}>
                  <label for="organizationType" className={`${style.label_spec} mb-1`}>Facility/Location <span>*</span></label>
                  <div className={`col-12 p-0 ${style.select_wrapper}`}>
                  <select className={`col-12 form-control ${style.basic_select}`}  
                  id={"zipfacility"}
                  name={"zipfacility"}
                  ref={registerZip({
                  required: "Select your facility/location from dropdown",
                  })} 
                  placeholder={'Select facility/location'}
                  errors={errorsZip}
                  >
                    <option value={''} >{'Select your facility/location'}</option>
                  {zipFacilitySearchDatas && zipFacilitySearchDatas.length > 0 && zipFacilitySearchDatas.map((data, i) => {
                  return <option value={JSON.stringify(data)} key={i}  items={JSON.stringify(data)}>{data.value}</option>
                  })
                  }
                  </select>
                  {errorsZip?.zipfacility?.message&&<><span className={`${style.error_message}`}>{errorsZip?.zipfacility?.message}</span></>}
                  </div>
                  </div>
                  )} */}

                    <div className="form-group mt-4 d-flex justify-content-between">
                    <Button
                      id={"back_zip_txt_id"}
                      type={"button"}
                      value={"<- Back"} 
                      classes={{
                        className: `btn ${style.btn_nxt_back} ${style.btn_back}`,
                      }}
                      onClick={backZipFaciClick}
                      >
                        {/* <img src={arrowLeft} className={`mr-1`}/>   */}
                        Back</Button>
                    <Button
                      id={"next_zip_txt_id"}
                      type={"submit"}
                      value={"Next ->"}
                      classes={{
                        className: `btn ${style.btn_nxt_back} ${style.custom_btn} float-right p-1`,
                      }}
                      >Next 
                      {/* <img src={arrowRight} className={`mb-1`} /> */}
                      </Button>
                    </div>

                    {zipFacilitySearchDatas &&
                  Array.isArray(zipFacilitySearchDatas) &&
                  zipFacilitySearchDatas.length > 0 && (
                    <><div className={`${style.hr_sect} mt-4`}> or </div>
                    <div className="form-group mt-4">
                      <Button
                        id={'facilityNotList'}
                        type={"submit"}
                        value={'My Facility/Location is not listed'}
                        classes={{
                          className: `btn btn-block  ${style.custom_btn_cancel}`,
                        }}
                        onClick={() => {
                          setRregType('')
                        }}
                      />
                    </div></>)}


                  </Form>
                ):(
                  <Form
                    className={"col-12  mx-auto submitData"}
                    onSubmit={handleSubmit(doSubmit)}
                  >
                     {regResCheckAllFlag?.isDomainNameMatchWithMultipleCustomerNumbers &&regResCheckAllFlag?.isDomainNameMatchWithMultipleCustomerNumbers===1 && (
                            <input
                              type="hidden"
                              id={'isDomainNameMatchWithMultipleCustomerNumbers'}
                              name={'isDomainNameMatchWithMultipleCustomerNumbers'}
                              defaultValue={1}
                              ref={register}
                            />
                          )}
                      {formType?.selectForm==='custFacilityEmail' && (
                            <input
                              type="hidden"
                              id={'iDonotHaveMedlineAccountNumber'}
                              name={'iDonotHaveMedlineAccountNumber'}
                              defaultValue={1}
                              ref={register}
                            />
                          )}
                      {regResCheckAllFlag?.isCustomerNumberMatchWithExcludedCustomerNumbers &&regResCheckAllFlag?.isCustomerNumberMatchWithExcludedCustomerNumbers===1 && (
                          <><input
                              type="hidden"
                              id={'isCustomerNumberMatchWithExcludedCustomerNumbers'}
                              name={'isCustomerNumberMatchWithExcludedCustomerNumbers'}
                              defaultValue={1}
                              ref={register}
                            />

{initCustomerNumberCheck&&regResCheckAllFlag?.customerFacilitiesInfo?.customerNumber &&regResCheckAllFlag?.customerFacilitiesInfo?.customerNumber==='Z002_Generic_PG' && (
                        <> <input
                              type="hidden"
                              id={'primaryCustomerNumber'}
                              name={'primaryCustomerNumber'}
                              defaultValue={initCustomerNumberCheck}
                              ref={register}
                            />
                            
                            </>
)}

                          </>
                            
                          )}
                          {regResCheckAllFlag?.isDomainMatchWithExcludedCustomerNumbers &&regResCheckAllFlag?.isDomainMatchWithExcludedCustomerNumbers===1 && (
                            <input
                              type="hidden"
                              id={'isDomainMatchWithExcludedCustomerNumbers'}
                              name={'isDomainMatchWithExcludedCustomerNumbers'}
                              defaultValue={1}
                              ref={register}
                            />
                          )}

{regResCheckAllFlag?.isValidZipCodeWithNoCustomerNumber &&regResCheckAllFlag?.isValidZipCodeWithNoCustomerNumber===1 && (
                            <input
                              type="hidden"
                              id={'isValidZipCodeWithNoCustomerNumber'}
                              name={'isValidZipCodeWithNoCustomerNumber'}
                              defaultValue={1}
                              ref={register}
                            />
                          )}
                          {regResCheckAllFlag?.isAllPrimaryGroupNumberFacilitiesAssign &&regResCheckAllFlag?.isAllPrimaryGroupNumberFacilitiesAssign===1 && (
                            <input
                              type="hidden"
                              id={'isAllPrimaryGroupNumberFacilitiesAssign'}
                              name={'isAllPrimaryGroupNumberFacilitiesAssign'}
                              defaultValue={1}
                              ref={register}
                            />
                          )}


                          

                    {
                       Array.isArray(checkCustomerFacilityData) && 
                      checkCustomerFacilityData.length === 1 ? (
                      <div className="col-12 p-0 mb-3 text-center">
                        <span
                          className={`col-form-label ${style.select_single}`}
                          title={checkCustomerFacilityData[0].item}
                        >
                          {checkCustomerFacilityData[0]?.key && (
                            <input
                              type="hidden"
                              id={CUSTOMER_NUMBER_ID}
                              name={CUSTOMER_NUMBER_ID}
                              defaultValue={
                                checkCustomerFacilityData[0]?.key
                                  ? checkCustomerFacilityData[0].key
                                  : ""
                              }
                              ref={register(validators.checkCustomerNumber)}
                            />
                          )}
                          {checkCustomerFacilityData[0]?.isMultiplePrimaryCustomerExists &&checkCustomerFacilityData[0]?.isMultiplePrimaryCustomerExists===1 && (
                            <input
                              type="hidden"
                              id={'isMultiplePrimaryCustomerExists'}
                              name={'isMultiplePrimaryCustomerExists'}
                              defaultValue={1}
                              ref={register}
                            />
                          )}
                          {checkCustomerFacilityData[0]?.zipSelectOnly===1&&(checkCustomerFacilityData[0].item)}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-row">
                      <TextField
                        classes={{
                          column: "form-group col-md-6",
                          inputClassName: "form-control mb-1",
                          inputColumn: "col-12 p-0",
                        }}
                        label={FIRST_NAME_LBL}
                        type={"text"}
                        name={FIRST_NAME_ID}
                        id={FIRST_NAME_ID}
                        //placeholder={FIRST_NAME_PHDR}
                        validator={register(validators.checkFirstName)}
                        errors={errors}
                        required={true}
                      />
                      <TextField
                        classes={{
                          column: "form-group col-md-6",
                          inputClassName: "form-control mb-1",
                          inputColumn: "col-12 p-0",
                        }}
                        label={LAST_NAME_LBL}
                        type={"text"}
                        name={LAST_NAME_ID}
                        id={LAST_NAME_ID}
                        //placeholder={LAST_NAME_PHDR}
                        validator={register(validators.checkLastName)}
                        errors={errors}
                        required={true}
                      />
                    </div>

                    <TextField
                      classes={{
                        column: "form-group",
                        inputClassName: "form-control mb-1",
                        inputColumn: "col-12 p-0",
                      }}
                      label={PHONE_LBL}
                      type={"text"}
                      name={PHONE_ID}
                      id={PHONE_ID}
                     // placeholder={PHONE_PHDR}
                      validator={(e) => {
                        register(e, validators.checkPhoneNumber);
                        phoneField.current = e;
                      }}
                      errors={errors}
                      required={true}
                    />

                    <TextField
                      classes={{
                        column: "form-group",
                        inputClassName: "form-control mb-1",
                        inputColumn: "col-12 p-0",
                      }}
                      label={"Work email"}
                      type={"text"}
                      name={EMAIL_ID}
                      id={EMAIL_ID}
                     // placeholder={"Work email"}
                      validator={register(validators.checkCompanyEmail)}
                      errors={errors}
                      required={true}
                      defaultValue={logEmail?logEmail:''}
                      disabled={logEmail?true:false}
                    />
                    <TextField
                      classes={{
                        inputClassName: "form-control mb-1 keyuptest",
                        inputColumn: "col-12 p-0",
                      }}
                      label={PASSWORD_LBL}
                      type={"password"}
                      name={PASSWORD_ID}
                      id={PASSWORD_ID}
                     // placeholder={PASSWORD_PHDR}
                      onKeyUp={() => passwordCondition()}
                      validator={register(checkResetNewPassword)}
                      errors={errors}
                      required={true}
                      hints={true}
                      minLength={minLength}
                      oneSpecialChar={oneSpecialChar}
                      oneNum={oneNum}
                      oneUCase={oneUCase}
                      autoComplete={'new-password'}
                    />
                    <TextField
                      classes={{
                        column: "form-group",
                        inputClassName: "form-control mb-1",
                        inputColumn: "col-12 p-0",
                      }}
                      label={CONFIRM_PASSWORD_LBL}
                      type={"password"}
                      name={CONFIRM_PASSWORD_ID}
                      id={CONFIRM_PASSWORD_ID}
                      //placeholder={PASSWORD_PHDR}
                      validator={register({
                        validate: (value) => {
                          if (!value) {
                            return CONFIRM_PASSWORD_REQUIRED;
                          }
                          if (value && value !== watch("password")) {
                            return PASSWORD_NOMATCH;
                          }
                        },
                      })}
                      errors={errors}
                      required={true}
                      autoComplete={'new-password'}
                    />
                    {Array.isArray(checkCustomerFacilityData) &&
                      checkCustomerFacilityData.length === 0 && (
                        <>
                           {/* facilityName */}
                           <TextField
                            classes={{
                              column: "form-group",
                              inputClassName: "form-control mb-1",
                              inputColumn: "col-12 p-0",
                            }}
                            label={"Facility/location name"}
                            type={"text"}
                            name={"facilityName"}
                            id={"facilityName"}
                           // placeholder={"Facility Name"}
                            validator={register({
                              required: "Enter your facility/location name",
                            })}
                            errors={errors}
                            required={true}
                          />
                        
                            {/* facilityAddress */}
                            <TextField
                            classes={{
                              column: "form-group",
                              inputClassName: "form-control mb-1",
                              inputColumn: "col-12 p-0",
                            }}
                            label={"Facility/location address"}
                            type={"text"}
                            name={"facilityAddress"}
                            id={"facilityAddress"}
                            //placeholder={"Facility Address"}
                            validator={register({
                              required: "Enter your facility/location address",
                            })}
                            errors={errors}
                            required={true}
                          />
                            {/* facilityZipCode */}
                            <TextField
                            classes={{
                              column: "form-group",
                              inputClassName: "form-control mb-1",
                              inputColumn: "col-12 p-0",
                            }}
                            label={"Facility/location zip code"}
                            type={"text"}
                            name={"facilityZipCode"}
                            id={"facilityZipCode"}
                            //placeholder={"Facility Zip Code"}
                            validator={register({
                              required: "Enter your facility/location zip code",
                              /*pattern: {
                                value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                                message:  "Enter valid zip code",
                            }*/
                            pattern: {
                              value: /^[a-zA-Z0-9-]+$/,
                              message: "Enter valid facility/location zip code"
                          }
                            })}
                            errors={errors}
                            required={true}
                            maxLength={9}
                            //onKeyPress={onCountryZipSelect}
                            onKeyPress={event => {
                              if (event.key === 'Enter') {
                                onCountryZipSelect(event)
                              }
                            }}
                            onBlur={onCountryZipSelect}
                          />
                              {/* facilityCity */}
                              <TextField
                            classes={{
                              column: "form-group",
                              inputClassName: "form-control mb-1",
                              inputColumn: "col-12 p-0",
                            }}
                            label={"Facility/location city"}
                            type={"text"}
                            name={"facilityCity"}
                            id={"facilityCity"}
                           // placeholder={"Facility City"}
                            validator={register({
                              required: "Enter your facility/location city",
                            })}
                            errors={errors}
                            required={true}
                            defaultValue={locationByCountryDatas?.city?locationByCountryDatas?.city:''}
                            disabled={locationByCountryDatas?.city?true:false}
                          />
                          {/* State */}
                          {/* <SelectBox
                            id={"facilityState"}
                            name={"facilityState"}
                            label={`${"Facility state"}`}
                            placeholder={"Select"}
                            classes={{
                              column: `form-group`,
                              inputClassName: `col-12 form-control ${style.basic_select}`,
                              labelClassName: `${style.label_spec} mb-1`,
                              inputColumn: `col-12 p-0 form-control mb-2 ${style.select_wrapper}`,
                            }}
                            optionValue={selectBoxConverter(US_STATES)}
                            validator={register({
                              required: "Select your facility state",
                            })}
                            errors={errors}
                            required={true}
                            //defaultValue={patientData && patientData?.address?.state}
                          /> */}
                           <TextField
                            classes={{
                              column: "form-group",
                              inputClassName: "form-control mb-1",
                              inputColumn: "col-12 p-0",
                            }}
                            label={`${"Facility/location state"}`}
                            type={"text"}
                            name={"facilityState"}
                            id={"facilityState"}
                            //placeholder={"Facility Address"}
                            validator={register({
                              required: "Enter your facility/location state",
                            })}
                            errors={errors}
                            required={true}
                            disabled={locationByCountryDatas?.state?true:false}
                            defaultValue={locationByCountryDatas?.state?locationByCountryDatas?.state:''}
                          />

                          <TextField
                            classes={{
                              column: "form-group",
                              inputClassName: "form-control mb-1",
                              inputColumn: "col-12 p-0",
                            }}
                             label={`${"Facility/location country"}`}
                            type={"text"}
                            name={"country"}
                            id={"country"}
                            //placeholder={"Facility Address"}
                            validator={register({
                              required: "Enter your facility/location country",
                            })}
                            errors={errors}
                            required={true}
                            disabled={locationByCountryDatas?.country?true:false}
                            defaultValue={locationByCountryDatas?.country?locationByCountryDatas?.country:''}
                          />
                           {/* <SelectBox
                            id={"country"}
                            name={"country"}
                            label={`${"Facility/location country"}`}
                            classes={{
                              column: `form-group`,
                              inputClassName: `col-12 form-control ${style.basic_select}`,
                              labelClassName: `${style.label_spec} mb-1`,
                              inputColumn: `col-12 p-0 form-control mb-1  ${style.select_wrapper}`,
                            }}
                            optionValue={locationByCountryDatas?.country?[{key:locationByCountryDatas?.country, value: locationByCountryDatas?.country}]:[]}
                            validator={register({
                              required: "Select your facility/location country",
                            })}
                            placeholder={"Select facility/location country"}
                            errors={errors}
                            required={true}
                            //disabled={locationByCountryDatas?.country?true:false}
                           // onChange={onCountryZipSelect}
                          /> */}
                          {/*organizationTypeOptions*/}
                          <SelectBox
                            id={"organizationType"}
                            name={"organizationType"}
                            label={`${"Healthcare organization type"}`}
                            classes={{
                              column: `form-group`,
                              inputClassName: `col-12 form-control ${style.basic_select}`,
                              labelClassName: `${style.label_spec} mb-1`,
                              inputColumn: `col-12 p-0 form-control mb-4  ${style.select_wrapper}`,
                            }}
                            optionValue={organizationTypeOptions}
                            validator={register({
                              required: "Select healthcare organization type",
                            })}
                            placeholder={"Select"}
                            errors={errors}
                            required={true}
                          />
                        </>
                      )}
                    <div className="form-group mb-3 no-gutters">
                      <CheckBox
                        classes={{
                          column: "col form-check",
                          inputClassName: "form-check-input",
                          labelClassName: "form-check-label",
                        }}
                        label={
                          <>
                            <span className={style.check_policy}>
                              By signing up or creating an account you agree{" "}
                              <br />
                              to the{" "}
                              <a
                                href={PRIVACY_POLICY_URL}
                                className={style.policy_link}
                                target="_blank"
                              >
                                {PRIVACY_POLICY_LBL}
                              </a>{" "}
                              and{" "}
                              <a
                                href={TERMS_URL}
                                className={style.policy_link}
                                target="_blank"
                              >
                                {TERMS_LBL}
                              </a>
                            </span>
                          </>
                        }
                        type={"checkbox"}
                        name={CHECK_TERMS_ID}
                        id={CHECK_TERMS_ID}
                        validator={register(checkTos)}
                        errors={errors}
                      />
                    </div>
                    <div className="form-group">
                      <Button
                        id={REGISTER_BTN_ID}
                        type={"submit"}
                        value={REGISTER_BTN}
                        classes={{
                          className: `btn btn-block ${style.custom_btn}`,
                        }}
                      />
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
        <Banner />
      </div>
    </div>
  );
}
