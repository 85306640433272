import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProductMarkettingAsync, setProductEditStatus, updateProductMarkettingAsync } from '../../../app/productSlice'
import { CANCEL_BTN, CANCEL_ID, EDIT, ERROR_STATUS, SAVE_BTN, SUBMIT_ID, SUCCESS_STATUS, UPDATE_FAILURE, UPDATE_SUCCESS } from '../../../constants'
import addNewSvg from '../../../static/images/Add_New.svg'
import closeNormal from '../../../static/images/Close_Normal.svg'
import editIcon from '../../../static/images/edit-icon-blue.png'
import imageIconSvg from '../../../static/images/Image_Icons.svg'
import DeleteIcon from '../../../static/images/delete-icon.png';
import { checkDescription } from '../../../utils'
import { Button, TextArea, TextField, Form } from '../../primary'
import styles from './marketingView.module.scss'
import Loader from '../../../static/images/spinner.svg';	
export function MarketingView({ productData }) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'all'
  });
  const [editable, setEditable] = useState(false)
  const handleEdit = () => {
    setEditable(!editable)
  }
  const dispatch = useDispatch();
  const { productId } = useParams()
  useEffect(() => {
    if (productId) {
      dispatch(getProductMarkettingAsync(productId))
    }
  }, [productId]);

  const markettingData = useSelector(state => state.product.markettingData);
  const editState = useSelector(state => state.product.productEditStatus);

  const howToApplyData = markettingData?.howToApply;

  const searchKeyWordData = markettingData?.keyword;

  const dummyObj = { "description": "", "image": "", "fileName": "" }
  const [inputList, setInputList] = useState();

  // handle click event of the Add button
  const addNewApply = () => {
    if (inputList && Array.isArray(inputList)) {
      setInputList([...inputList, dummyObj]);
    }

  };

  useEffect(() => {
    setInputList(howToApplyData);
  }, [howToApplyData, dispatch]);

  const [searchKeyWord, setSearchKeyWord] = useState(searchKeyWordData);
  const [newKeySearchKey, setNewSearchKey] = useState(false);
  const addNewSearchKeyWord = () => {
    setNewSearchKey(true)
  };

  useEffect(() => {
    setSearchKeyWord(searchKeyWordData);
  }, [searchKeyWordData, dispatch]);

  const removeKeyword = (index) => {
        const deleteData = [...searchKeyWord]
        deleteData.splice(index, 1);
        setSearchKeyWord(deleteData);
  }
  // Function to convert file to base 64. This function will return promise.
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const acceptedFileTypes = "image/jpg, image/jpeg, image/png";

  const handleImageChange = (e, index) => {
    e.preventDefault();
    let imageData="";
    let fileData="";
    if(e?.target?.files.length>0){
      const files = e?.target?.files
        getBase64(files).then((data) => {
          imageData= document.getElementById('imageUrl' + index).value=data
          fileData=document.getElementById('fileName' + index).value=files[0].name
        
        const copyInputList=[...inputList]
        copyInputList[index].image=imageData;
        copyInputList[index].fileName=fileData;
        setInputList(copyInputList);
      });
    }
  };

  const onBlurChangeSearchKeyWord=(value)=>{
    const convertSearchValue= value.split(',');
    let splitedNewArray =[]
    if(convertSearchValue){
      Array.isArray(convertSearchValue)&&convertSearchValue?.map(arrayValue=>{//NOSONAR
        splitedNewArray.push(arrayValue)
    })//NOSONAR
    let newSearch=[...searchKeyWord,...splitedNewArray]
    //duplicate remove
    const duplicateRemovedData=newSearch.filter((item,index)=>newSearch.indexOf(item)===index)
    setSearchKeyWord(duplicateRemovedData);
    setNewSearchKey(false);
  }
    
  }

  useEffect(() => {
    if (editState === "success" || editState === "error") {
      setEditable(!editable)
      //dispatch(getProductMarkettingAsync(productId))
      setTimeout(() => {
        dispatch(setProductEditStatus({}))
      }, 5000)
    }
  }, [editState]);

  const [imageDescError, setImageDescError] = useState(false)

  const onSubmitMarketing=(data)=>{


    let breaker = false;
    data && data['howToApply'] && data['howToApply'].length > 0 && data['howToApply'].map((value, key) => {
      if(value.image !== '' && value.description === ''){
        breaker = true;
      }else if(value.image === '' && value.description !== ''){
        breaker = true;
      }else if(value.filedata){
        breaker = true;
      }
    });

    if(breaker){
      setImageDescError(true)
      return;
    }else{
      setImageDescError(false)
    }
    
    const howToApplydatas = []
    data && data['howToApply'] && data['howToApply'].length > 0 && data['howToApply'].map((value, key) => {
      let finalImageData;
      if (value.image.search('data:image/png;base64') !== -1) {
          finalImageData = value.image.replace('data:image/png;base64,', '')
      }
      else if (value.image.search('data:image/jpeg;base64') !== -1) {
        finalImageData = value.image.replace('data:image/jpeg;base64,', '')
      }
      else if (value.image.search('data:image/jpg;base64') !== -1) {
        finalImageData = value.image.replace('data:image/jpg;base64,', '')
      }
      else{
        finalImageData = value.image;
      }
      if(value.description&&value.fileName){
        howToApplydatas.push({
          description: value.description,
          image:finalImageData,
          fileName:value.fileName
        });
      }else{
        //last item delete
        inputList&&inputList.splice(-1);//NOSONAR
      // inputList.pop();
      }
    });

    let keyword = [];
    if(searchKeyWord.length === 1){
      if(searchKeyWord[0] === ""){
        keyword = []
      }else{
        keyword = [...searchKeyWord]
      }
    }else if(searchKeyWord.length === 0){
      keyword = []
    }else{
      keyword = [...searchKeyWord]
    }

    const payload ={
      productFamilyCoreDesc:data.productFamilyCoreDesc,
      keyBenefits:data.keyBenefits,
      howToApply:howToApplydatas,
      keyword: keyword
    }
    dispatch(updateProductMarkettingAsync(payload,productId));
    setNewSearchKey(false);
  }

  const removeImageFile=(e,index)=>{
    const deleteImageData=[];
    const copyData=inputList
    Array.isArray(copyData)&&copyData.map((data,i)=>{
      if(index===i){
        deleteImageData.push({image:'',fileName:'',description:data.description});
      }else{
        deleteImageData.push({image:data.image,fileName:data.fileName,description:data.description});
      }
    })
    setInputList(deleteImageData);
  }

  const handleEditCallBack=()=>{
    setEditable(!editable)
    dispatch(getProductMarkettingAsync(productId))
    setNewSearchKey(false);
    setImageDescError(false)
  }
 
  const contentChange =(e, index)=>{
      let tmpArray=[...searchKeyWord];
      tmpArray[index]=e.target.textContent;
      setSearchKeyWord(tmpArray)
  }

  const removeTextBox = () =>{
    setNewSearchKey(false)
  }

  const deleteHowtoApply=(index)=>{
    setImageDescError(false)
    const deleteImageData=[...inputList]
    deleteImageData.splice(index,1);
    setInputList(deleteImageData);
  }
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
  return (
    <>
      <div className={`${styles.tab_content_top}`}>
        <h2 className={`${styles.tab_content_hdg}`}>
          {productData?.productFamilyDesc}
          
          {tinyDelay&&!editable &&
            <span className={`float-sm-right ${styles.tab_edit}`} onClick={handleEdit} >
              <img src={editIcon} alt="edit" />{EDIT}
            </span>

          }
        </h2>
      </div>
      {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
      <div className={`col-sm-12 ${styles.single_product_block}`}>
        <div className="row">
          {!editable ?
            <>
            {/* Edit success */}
            {editState === SUCCESS_STATUS && <div className={`alert alert-success ${styles.form_alert}`}>{UPDATE_SUCCESS}</div>}

            {/* Edit failure */}
            {editState === ERROR_STATUS && <div className={`alert alert-danger ${styles.form_alert}`}>{UPDATE_FAILURE}</div>}

              <label className={`${styles.label_spec} col-12 p-0`}>Marketing Description: </label>
              <div className="col-12 col_spec p-0">
                <div className={`${styles.prod_content_view} mt-2 mb-4`} >
                  {markettingData && markettingData?.productFamilyCoreDesc &&
                  
                  <p>{markettingData?.productFamilyCoreDesc}</p>
                  }

                </div>
              </div>

              <label className={`${styles.label_spec} col-12 p-0 `}>Key Benefits: </label>
              <div className="col-12 col_spec p-0">
                <div className={`${styles.prod_content_view} mt-2 mb-4`} >
                {markettingData && markettingData?.keyBenefits &&
                  <p>{markettingData?.keyBenefits}</p>
                }
                </div>
              </div>
              <label className={`${styles.label_spec} col-6 p-0 `}>How to Apply: </label>


              <div className={`${styles.prod_upload_blk} ${styles.prod_upload_blk_alt}`}>
                <ul>
                  {markettingData && markettingData?.howToApply?.map((data, i) => {
                    return (

                      <li className={`${styles.prod_list}`}>
                        <img src={imageIconSvg} />   <a href={data.image} target="_blank" rel="noopener noreferrer">{data.fileName}</a>

                        <p>{i + 1}. {data.description}.</p>
                      </li>
                    )
                  })}
                </ul>

              </div>

              <div className={`${styles.search_terms}`}>
                <label className={`${styles.label_spec} col-6 p-0 `}>Customer facing search terms (Mobile App): </label>

                <div className={`${styles.search_list}`}>
                  <ul>
                    {/* edit mode reference <li>Search Term 01 <img src={closeNormal} alt="close" /></li> */}
                    {markettingData && markettingData?.keyword?.map((data) => {
                      return (
                        <>
                        {data&&<li>{data} </li>}
                        </>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </>
            :
              <Form formChanges={true} className={`col-12 ${styles.formSubmit}`} onSubmit={handleSubmit(onSubmitMarketing)} >
                <TextArea
                  id={`productFamilyCoreDesc`}
                  name={`productFamilyCoreDesc`}
                  label={"Marketing Description:"}
                  classes={{
                    column: `col-12 ${styles.col_spec} p-0`,
                    inputClassName: `${styles.product_textarea}`,
                    labelClassName: `${styles.label_spec} col-12 p-0`,
                    inputColumn: `col-12 pl-0 mt-2 mb-2`
                  }}
                  validator={register(checkDescription)}
                  errors={errors}
                  required={true}
                  value={markettingData && markettingData?.productFamilyCoreDesc}
                />
                <label className={`${styles.label_spec} col-12 p-0`}>Key Benefits:</label>
                <div className="col-12 col_spec p-0">
                  <TextArea
                    id={`keyBenefits`}
                    name={`keyBenefits`}
                    classes={{
                      inputClassName: `${styles.product_textarea}`,
                      inputColumn: `col-12 pl-0 mt-2 mb-2`
                    }}
                    validator={register()}
                    errors={errors}
                    value={markettingData && markettingData?.keyBenefits}
                  />
                </div>
                <label className={`${styles.label_spec} col-6 p-0`}>How to Apply:</label>
                <span className={`col-auto text-right ${styles.add_new_link} addNewApply`} onClick={() => addNewApply()}><img src={addNewSvg} alt="" />Add New</span>

                {inputList && inputList.length > 0 && inputList.map((data, i) => {
                  return (
                    <>
                      <div className={`${styles.prod_upload_blk}`}>
                        <div className="d-flex">
                          <label className={`${styles.label_spec} col-11 p-0`}>Upload Image</label>
                          <span className={`deleteQues  p-0 text-right ml-5`} onClick={() => deleteHowtoApply(i)}><img src={DeleteIcon} alt="" /></span>
                        </div>
                        <div className="mt-2 mb-2">
                        <input 
                          type="text" 
                          className="d-none" 
                          ref={register()}  
                          name={`howToApply[${i}][image]`} 
                          defaultValue={data.image} 
                          id={`imageUrl${i}`} 
                          accept={acceptedFileTypes}  
                        />
                        <input 
                          type="text" 
                          className="d-none" 
                          ref={register()}  
                          name={`howToApply[${i}][fileName]`} 
                          defaultValue={data.fileName}  
                          id={`fileName${i}`} 
                          accept={acceptedFileTypes}  
                        />
                        
                          {/* <input  type="file" name="" value="" onChange={onImageChange}/> */}
                          
                          {(data?.fileName!=="") ?
                           
                           <span>
                             <img src={imageIconSvg} /> 
                              <a href="#">{data?.fileName}</a> 
                              <img src={closeNormal} alt="close" onClick={(e)=>removeImageFile(e,i)} className={`deleteHowToApply`}/>
                            </span>
                            
                           : 
                            <input type="file" ref={register()}  name={`howToApply[${i}][filedata]`}  accept={acceptedFileTypes} className={'imageChange'} onChange={(e) => handleImageChange(e, i)} />
                          }
                        </div>
                        {/* <img src={imageState} width="20px" height="20px" /> */}

                        <TextArea
                          id={`imageDescription${i}`}
                      
                          name={`howToApply[${i}][description]`}
                          classes={{
                            column: `col-12 ${styles.col_spec} p-0`,
                            inputClassName: `${styles.product_textarea}`,
                            inputColumn: `col-12 pl-0 mt-2 mb-2`
                          }}
                          validator={register()}
                          errors={errors}
                          placeholder={"Enter image Description"}
                          value={data.description}
                        />
                      </div>
                    </>
                  )
                })
                }

                {imageDescError && <div className="text-danger pt-2 pb-2">Please add both image and description.</div>}

                <div className={`${styles.search_terms} mt-3`}>
                  <label className={`${styles.label_spec} col-6 p-0`}>Customer facing search terms (Mobile App):</label>
                  <span className={`col-auto text-right ${styles.add_new_link} addSearchKeyWord`} onClick={() => addNewSearchKeyWord()} ><img src={addNewSvg} alt="" />Add New</span>
                  <div className={`${styles.search_list}`}>
                    <ul className="d-flex flex-wrap">

                      {searchKeyWord && searchKeyWord.length > 0 && searchKeyWord.map((data, i) => {
                        return (
                          <>
                            {data&&<li key={i} contentEditable={true}   onBlur={(e)=> contentChange(e,i)} suppressContentEditableWarning={true}>{data}<img src={closeNormal} alt="close" onClick={() => removeKeyword(i)} className={`deleteSearchKeyWord`}/></li>
                             }
                          
                            </>
                        )
                      })

                      }

                        {
                             newKeySearchKey && <><li className={`${styles.input_li} p-0`}>

                              <TextField
                                  id={"keyword"}
                                  name={"keyword"}
                                  classes={{
                                      column: `col-10 ${styles.col_spec} p-0`,
                                      labelClass: `col-5 col-form-label ${styles.label_spec}`,
                                      inputColumn: 'col-5 p-0',
                                      inputClassName: `${styles.basic_input} onblurKeyword`
                                  }}
                                  type="text"
                                  onBlur={(e)=>onBlurChangeSearchKeyWord(e.target.value)}
                              />
                              <img src={closeNormal} alt="close" onClick={() => removeTextBox()} className={`deleteSearchKeyWord`}/>
                            </li></>
                          }
                      
                      {/* <li className={styles.input_li}>{newKeySearchKey &&
                      <TextField
                          id={"keyword"}
                          name={"keyword"}
                          classes={{
                              column: `col-10 ${styles.col_spec}`,
                              labelClass: `col-5 col-form-label ${styles.label_spec}`,
                              inputColumn: 'col-5 p-0',
                              inputClassName: styles.basic_input
                          }}
                          type="text"
                          //validator={register(checkProductFamilyCode)}
                          //errors={errors}
                          //required={true}
                      />
                    }</li> */}

                    </ul>
                    
                  </div>


                </div>
                <div className={`col-sm-12 ${styles.clear}`}>
                  <div className="text-center pb-4">
                     {/* Save Button */}
                     <Button
                      classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                      type="submit"
                      id={SUBMIT_ID}
                    >
                      {SAVE_BTN}
                    </Button>
                    
                    {/* Cancel button  */}
                    <Button
                      classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                      id={CANCEL_ID}
                      onClick={handleEditCallBack}
                    >
                      {CANCEL_BTN}
                    </Button>

                   
                  </div>
                </div>
              </Form>
            //</div>
          }
        </div>


      </div>
}

    </>
  )
}

