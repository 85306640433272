import React, { useRef, useEffect, useState } from 'react'
import {
    SAVE_BTN, SUBMIT_ID, CANCEL_BTN, CANCEL_ID,
    FIRST_NAME_LBL,
    FIRST_NAME_ID,
    LAST_NAME_LBL,
    LAST_NAME_ID,
    EMAIL_LBL,
    EMAIL_ID,
    EMAIL_PHDR,
    PHONE_ID,
    ROLE_LBL,
    ROLE_ID,
    USER_INFORMATION_HEAD,
    ROLE_OPTIONS,
    CUSTOMER_NUMBER_ID,
    CUSTOMER_NUMBER_LBL,
    USER_TRAVELNURSE_STATUS,   
} from '../../../constants'
import { Form, TextField, Button, SelectBox, Organizational } from '../../primary'
import { checkFirstName, checkLastName, checkEmail, phoneMasker, checkPhoneNumber, getFacilityLabel, checkMaterialDivison, useDebounce,  customerNumberValid, getFacilityLabelUrl } from '../../../utils'
import styles from './createEditUser.module.scss';
import closeIcon from "../../../static/images/Close_Normal.svg";
// import CreateEditCloseIcon from './createEditCloseIcon'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import{ setResMsgState, getFaciltiestUserAsyn, setUserFacilities, getCustomerNumberSuggestions, setCustomerNumberSearch } from '../../../app/userSlice'// added Checkbox feature
import { clear } from 'fetch-intercept';
import { setdressChangeFrequency } from '../../../app/patientSlice';



export function CreateEditUser({ register, handleSubmit,
    control, handleCancel, onSubmit, errors, userData,
    externalAdmin = false, isModal = false, facilityOptions, errormsg, setSelectedFacilities, getSearchValue, searchKey, selectAll = false, assignedFacilities, setGetTodoList, setGetTraveNurse, editState, setGetOrgError, facilitiesData}) {
    /*************************
     * Phone number field
     *************************/
    /* Get value from phone field using useREf */
    let phoneField = useRef();
    /* If phone number is typed call the above phoneHandle function */
    if (phoneField.current) {
        if (phoneField.current.value.length > 9) {
            phoneField.current.value = phoneMasker(phoneField.current.value)
        }
    }

    //get user role data from state
    const userRoleData = useSelector(state => state.user.userRole);
    // convert key value pair instead of name and label. 
    const convertedUserRoleData = (Object.keys(userRoleData).length !== 0) ? userRoleData.map(data => ({ key: data.name, value: data.label })) : '';;
    

    /* based on role, display role option to edit privilege on admin */
    let externalAdminRoleData = [];
    let RolesDataOption = ROLE_OPTIONS;
    if (externalAdmin) {
        externalAdminRoleData = RolesDataOption.filter((key) => key.key.startsWith("eXT"));

    }

    /* Fetching reaosn for visit data  */
    const dispatch = useDispatch()

    
      /* Traveling Nurse\ */
    const [intialState, setIntialState] = useState(true);
    const [loadState, setLoadState] = useState(true)
    const [toDo, setToDo] = useState(userData?.customerNumber);
    const [todoList, setTodoList] = useState([]);   
    const [makeDefault, setMakeDefault] = useState(userData?.customerNumber? 1 : 0);
    const [traveNurse, setTraveNurse] = useState(userData?.isTravelNurse ===1 ? 1 : 0 )
    const [isValidSaleCust, setIsValidSaleCust] = useState(false);
    const [isMakeDefault, setIsMakeDefault] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)

     /* CustomerNumber search Suggestions  */   
    const [custNumInput, setCustNumInput] = useState("");
    const [isSelect, setIsSelect] = useState(false);
    const searchTerm = useDebounce(custNumInput, 500);
    const [errorMsg, setErrorMsg] = useState("");
    const [orgError, setOrgError] = useState(false)

    const customerNumberSearchDatas = useSelector(
        (state) => state.user.customerNumberSearch
      );

  
    useEffect(() => {
        if (!isSelect && searchTerm && searchTerm.length >= 1) {
          dispatch(getCustomerNumberSuggestions(searchTerm));
        } else {
          dispatch(setCustomerNumberSearch({}));
        }
      }, [searchTerm]);

    

              
      loadState && userData?.customer?.map((item)=>{
        todoList && todoList.push(
                 {
                     customerNumber : item?.customerNumber,
                     value : item?.customerNumber,            
                     facilities : item?.facilities?.map(list => list),
                     isDefault : item?.customerNumber === userData?.defaultCustomerNumber,
                     customerId : item?.customerId, 
                     isNe1WmsEnable : item?.isNe1WmsEnable
                 }                 
             )
             setLoadState(false)
        })

     

    const newArr = [];

    const [onselectOrgz, setonselectOrg] = useState([]);
    const [checkOrgDrop, setCheckOrgDrop] = useState(false);
    const onSelectOrg = (selectedList) => {
        setonselectOrg([...selectedList])
        if (setSelectedFacilities) {
            let facilitykey = [];            
            selectedList && selectedList?.map((item) => { //NOSONAR
                let facility = item.key.split("|")[0].trim();
                facilitykey = [...facilitykey, facility];                
            })
            setToDo(facilitiesData?.data?.facilities?.find(f=>f.accountGroup === "Z002")?.facilityNumber)
            const currentIndex = todoList && todoList.findIndex(t => t.customerNumber === toDo)
            if(currentIndex > -1) {
                todoList[currentIndex].facilities = facilitiesData?.data?.facilities?.filter((f)=> facilitykey.indexOf(f.facilityNumber) > -1)
                setIsValidSaleCust(false)
            }            
            else {   
                if(toDo !== "" && toDo !== null){                   
                    todoList && todoList.push({                
                        customerNumber : toDo,
                        value : toDo,
                        isDefault : 0,
                        customerId : facilitiesData?.data?.facilities?.find((f)=> f.facilityNumber === toDo)?.id,
                        facilities : facilitiesData?.data?.facilities?.filter((f)=> facilitykey.indexOf(f.facilityNumber) > -1)
                        
                    })
                }             
               
            }
            
            setSelectedFacilities(facilitykey)
        }
        
        setCheckOrgDrop(true)
    }
    
    // const facilitiesData = useSelector(state => state.user.userFacilities);

    let faciOptionData = [];
    const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
    soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
        const shipToFacilities = facilitiesData.data.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
       if(shipToFacilities.length===0){
        faciOptionData.push({
            cat: getFacilityLabel(soldToFacility),
            key: getFacilityLabel(soldToFacility),
            facilityNumber: soldToFacility.facilityNumber,
            soldToFacility: 'soldToFacility'
        })
       }
        
        shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
            faciOptionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(shipToFacility),
                facilityNumber: shipToFacility.facilityNumber,
                shipToFacilities: 'shipToFacilities'
            })
        })
    })

    

    useEffect(() => {
        const res = userData?.customer?.filter((item)=> item.customerNumber === userData?.customerNumber)             
        const defaultFac = todoList?.find(f=>f.customerNumber.toLowerCase()===toDo.toLowerCase())?.facilities
        let defaultFacArr = [];
        const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
        soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
            const shipToFacilities = facilitiesData.data.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
            if(shipToFacilities.length===0){
                defaultFac && defaultFac.forEach((shipTofacilityNumber) => {
                    if (shipTofacilityNumber.facilityNumber === soldToFacility.facilityNumber) {
                defaultFacArr.push({
                    cat: getFacilityLabel(soldToFacility),
                    key: getFacilityLabel(soldToFacility),
                    facilityNumber: soldToFacility.facilityNumber,
                    soldToFacility: 'soldToFacility'
                })
                 }
             })
               }
            shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
                defaultFac && defaultFac.forEach((shipTofacilityNumber) => {
                    if (shipTofacilityNumber.facilityNumber === shipToFacility.facilityNumber) {
                        defaultFacArr.push({
                            cat: getFacilityLabel(soldToFacility),
                            key: getFacilityLabel(shipToFacility),
                            facilityNumber: shipToFacility.facilityNumber,
                            shipToFacilities: 'shipToFacilities'
                        })
                    }
                })

            })
        })
        setonselectOrg(defaultFacArr)
        if (setSelectedFacilities) {
            let facilitykey = [];
            defaultFacArr && defaultFacArr?.map((item) => { //NOSONAR
                let facility = item.key.split("|")[0].trim();
                facilitykey = [...facilitykey, facility];
            })
            setSelectedFacilities(facilitykey)
        }
        setCheckOrgDrop(true)
        if(traveNurse === 1 && intialState){
            document.getElementById(CUSTOMER_NUMBER_ID).value = null ;        
            setonselectOrg([]);
            setToDo('')
            dispatch(setUserFacilities([])) 
            setCustNumInput("");
            setIntialState(false)
        }   
        // if(dropdownOpen){            
        //     setTimeout(document.getElementsByClassName("dropdown-menu")[0]?.classList?.add('show'), 5000)
        //     setDropdownOpen(false)           
        // }
    }, [userData, checkOrgDrop])   

    
    

    const [customValue, setCustomValue] = useState('');
    const [checkChange, setCheckChange] = useState(0); 
   
    // const [inputList, setInputList] = useState([]);
    // const convertedDefaultCustomerNumber = (Object.keys(userData?.customer).length !== 0) ? userData?.customer?.map((item, index)=> ({key: item.index, value: item.customerNumber})): "";
    
        
    /*Remove a whole customer Number list from the Organizational list  */
    const removeCustomerNumberList = (cusNum) => {     
        let removeList = todoList?.findIndex(f=>f.customerNumber === cusNum);
        todoList.splice(removeList, 1) 
        setTodoList([...todoList])  
    }

    useEffect(()=>{       
        if(todoList?.length > 0){
            return todoList
        } 
        // return todoList       
    }, [todoList])

    
   /*Remove a shift & sold Facilities from the Organizational list */
    const removeSelectedFacilitiesList = (cusNum, facNum) => { 
        let removeList = todoList?.findIndex(f=>f.customerNumber === cusNum);
        todoList[removeList]?.facilities?.splice(todoList[removeList]?.facilities?.findIndex(f=>f.facilityNumber === facNum), 1)       
        setTodoList([...todoList])       
    }


    /*TravelNurse ToggleChange */
    const checkHandleClick = (travDefault) => { 
        if(traveNurse === 0) {
            setTraveNurse(1) 
            setGetTraveNurse(1) 
            ClearData();  
            // setIsValidSaleCust(true)               
        }
        else {            
            setTraveNurse(0)  
            setGetTraveNurse(0) 
            if(todoList?.find(f=>f.isDefault) === undefined){
                document.getElementById(CUSTOMER_NUMBER_ID).value = ""
                setToDo("")
                // getCustomerNumber("")
                ClearData();
                setTodoList([])
            } 
            else{
                document.getElementById(CUSTOMER_NUMBER_ID).value = todoList?.find(f=>f.isDefault)?.customerNumber
                setToDo(todoList?.find(f=>f.isDefault)?.customerNumber)
                getCustomerNumber(todoList?.find(f=>f.isDefault)?.customerNumber)
                setTodoList([todoList?.find(f=>f.isDefault)])
            }
            setDropdownOpen(false)
        }
        
    }

   /*Make Default CustomerNumber ToggleChange */
    const makeDefaultClick = (cusDefault, checkTogg) => { 
        // if(!cusDefault){
        //     // setIsMakeDefault(true)
        // }
        // else    
        // setIsMakeDefault(false)   
        todoList.map((item)=>{//NOSONAR
            return(
                item.isDefault = (item?.customerNumber === cusDefault && (!checkTogg))
            )  
        }) //NOSONAR 
        setTodoList([...todoList])              
    }
  
 
    useEffect(() => {
        let fac = ""
        if (facilitiesData?.data?.errorMessage === "Invalid Customer Number.") {
            fac = "customer number doesn’t match our record"
        } else if (facilitiesData?.data !== undefined) {
            if (faciOptionData.length === 0) {
                fac = "please enter a valid customer number"
            }
        }
        setErrorMsg(fac)        
    }, [facilitiesData])

    

    const getCustomerNumber = (val) => {  
       
        dispatch(setUserFacilities([]))  
        setCheckOrgDrop(true) 

        if(val === ''){
            setErrorMsg("please enter any customer number")
            // setIsValidSaleCust(true)
        }
        else{  
            setIsValidSaleCust(false)
            setErrorMsg('') 
            setonselectOrg([]);
            if(traveNurse === 0){
                setTodoList([])
            }
            setToDo(val)         
            const payload = { customer: val }
            dispatch(getFaciltiestUserAsyn(payload,setCheckOrgDrop,setErrorMsg));  
            setDropdownOpen(true)                    
        } 
        
    }

    const getCustomerNumberSearch = (event) => {  
        if(event === ''){
            setErrorMsg("please enter any customer number")
            setCustNumInput("")
        }else {    
            setIsValidSaleCust(false)       
            setCustNumInput(event)
            setIsSelect(false);
            setErrorMsg('')                        
        }  
      };

    const ClearData = (value) => { 
        document.getElementById(CUSTOMER_NUMBER_ID).value = null ;        
        setonselectOrg([]);
        setCheckOrgDrop(false) 
        setToDo('')
        dispatch(setUserFacilities([])) 
        setCustNumInput("");
        setDropdownOpen(false)
    }


    const customerNumberSelect = (e, items) => {
        e.stopPropagation();       
        setIsSelect(true);
        dispatch(setCustomerNumberSearch({}));
        setCheckOrgDrop(true)                 
        if (items) {
          setErrorMsg('')
          setIsValidSaleCust(false)          
          const customerNumber = items;
          setCustNumInput(customerNumber);
          document.getElementById(CUSTOMER_NUMBER_ID).value = customerNumber;
          document.getElementById(CUSTOMER_NUMBER_ID).value = items; 
          setToDo(items)                    
          const payload = { customer: items }
          dispatch(getFaciltiestUserAsyn(payload, setCheckOrgDrop)); 
          setDropdownOpen(true)
          if(traveNurse === 0){
            setTodoList([])
            }                  
        }      
      }
   
    const resMsgState = useSelector(state => state.user.resMsgState);


    useEffect(()=>{
        if(userData !== undefined){
            setGetTodoList(todoList)
        }
    },[todoList])

    useEffect(()=>{
        if(userData !== undefined){
            setGetOrgError(orgError)
        }
    },[orgError])


    let errCk= editState?.finalErrorRes?JSON.parse(editState?.finalErrorRes):'';


   /*----Before Submitting validation------*/
    const canSubmit = () =>{        
         if(traveNurse === 0){
            if(toDo === undefined || toDo === null || toDo === ""){
                setErrorMsg('please enter any customer number')
                return false
            }
            if ((todoList?.find(f=>f.customerNumber===toDo)?.facilities?.length > 0)===false){
                setIsValidSaleCust(true)            
                return false
            }
        }
        else if(traveNurse === 1 ){  
            if(todoList?.find(f=>f.facilities?.length>0)===undefined){
                if(toDo === undefined || toDo === null || toDo === ""){
                    setErrorMsg('Must assign the user to at least one customer #')
                    return false
                }
                setIsValidSaleCust(true)            
                return false
            }
            if((todoList?.find(f=>f.customerNumber===toDo)?.facilities?.length === 0 || todoList?.find(f=>f.customerNumber===toDo)?.facilities?.length === null)){
                setIsValidSaleCust(true)            
                return false
            }          
            if(todoList?.find(f=>f.isDefault && f.customerNumber !== null && f.customerNumber !== "") === undefined){                
                setIsMakeDefault(true)
                return false
            } 
                        
        }
        else{             
            setIsValidSaleCust(false)
            setIsMakeDefault(false) 
        }
        // setGetTodoList(todoList)
        // setGetOrgError(orgError)         
        return true        
        
    }

    
    return (
        <>
          
            <Form formChanges={true} className={`pl-2 ${styles.patient_content}`}  
                onSubmit={(e) => {
                    if(canSubmit() || userData === undefined){                        
                        handleSubmit(onSubmit)(e).catch(() => {});
                        }
                    else{
                        e.preventDefault();
                    }
                    }
                }               
             >
                {/* Title Name * Role */}
                <div className={`col-12 px-0 ${styles.tab_content_top}`}>
                    {!isModal && <div className={`col-12`}>
                            <h2 className={`px-0 ${styles.tab_content_hdg}`}>
                                {userData?.firstName + ' ' + userData?.lastName}
                            </h2>
                        </div>}                        

                       
                        <div className={`col-6 d-flex px-0`}>
                        {
                            userData?.role === 'MedlineAdministrator' ? <div className={`col-8 ${styles.col_spec}`}>
                                <div className="w-100 d-flex align-items-center">
                                    <label className={`col-2 pr-1 ${styles.label_spec}`}>{`${ROLE_LBL}:`}</label>
                                    <div className={`col-9 p-0 ${styles.int_str}`}>Medline Administrator</div>
                                </div>
                                </div> : !isModal && !externalAdmin && <SelectBox
                                    id={ROLE_ID}
                                    name={ROLE_ID}
                                    label={`${ROLE_LBL}:`}
                                    classes={{
                                        column: `col-8 d-flex ${styles.col_spec}`,
                                        inputClassName: styles.basic_select,
                                        labelClassName: `col-2 mr-1 col-form-label ${styles.label_spec}`,
                                        inputColumn: `col-9 p-0 ${styles.select_wrapper}`
                                    }}
                                    optionValue={convertedUserRoleData}
                                    defaultValue={userData && userData?.role}
                                    validator={register}
                                />
                            }
            
                            {!isModal && externalAdmin && <SelectBox
                                id={ROLE_ID}
                                name={ROLE_ID}
                                label={`${ROLE_LBL} :`}
                                classes={{
                                    column: `col-8 d-flex ${styles.col_spec}`,
                                    inputClassName: styles.basic_select,
                                    labelClassName: `col-2 mr-1 col-form-label ${styles.label_spec}`,
                                    inputColumn: `col-9 p-0 ${styles.select_wrapper}`
                                }}
                                optionValue={convertedUserRoleData}
                                defaultValue={userData && userData?.role}
                                validator={register}
                            />}
                            
                            {isModal && <SelectBox
                                id={ROLE_ID}
                                name={ROLE_ID}
                                label={`${ROLE_LBL}:`}
                                classes={{
                                    column: `col-8 d-flex ${styles.col_spec}`,
                                    inputClassName: styles.basic_select,
                                    labelClassName: `col-2 mr-1 col-form-label ${styles.label_spec}`,
                                    inputColumn: `col-9 p-0 ${styles.select_wrapper}`
                                }}
                                optionValue={convertedUserRoleData}
                                validator={register}
                                defaultValue={'ExternalStandardUser'}
                            />}
                            
                            {!isModal && <>
                            <div className={`verticalCode  ${styles.vertical_Code}`}>

                            </div>                           
                            {/* Toggle switch */}
                            <div className={`col-6 custom-control custom-switch pl-2 py-2 d-flex`}>
                                    {`Traveling Nurse :  `}
                                    <div className={`${styles.toggle_shells}`}>                                    
                                    {/* <div className={`${styles.toggle_button_status}`}>{toggle ? "Verified" : "Not verified"}</div>  */}                                    
                                    <input 
                                        type="checkbox"
                                        name="isTravelNurse" 
                                        className="custom-control-input"
                                        id={"isTravelNurse"}
                                        data-testid={"toggleSwitch"}
                                        defaultValue = {traveNurse}
                                        checked = {traveNurse === 1}                                                     
                                        onChange={checkHandleClick}
                                    />
                                    <label className={`custom-control-label ${styles.toggle_switch}`} for="isTravelNurse"></label> 
                                    </div>
                                </div>
                            </>
                                }
                                                            
                        </div>                                 
                </div>


                {/* Heading */}
                <div className={`col-sm-12 ${styles.col_spec}`}>
                    <p className={`col-12 ${styles.subheading_2}`}>Contact Information</p>
                </div>


                
                {/* First Name */}
                <TextField
                    id={FIRST_NAME_ID}
                    name={FIRST_NAME_ID}
                    label={`${FIRST_NAME_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(checkFirstName)}
                    defaultValue={userData && userData?.firstName}
                    errors={errors}
                    required={true}
                />
              

                {/* Last Name */}
                <TextField
                    id={LAST_NAME_ID}
                    name={LAST_NAME_ID}
                    label={`${LAST_NAME_LBL}:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={register(checkLastName)}
                    errors={errors}
                    defaultValue={userData && userData?.lastName}
                    required={true}

                />
                {/* Email */}
                {externalAdmin | !externalAdmin ?
                    <TextField
                        classes={{
                            column: `col-6 ${styles.col_spec}`,
                            labelClass: `col-6 col-form-label ${styles.label_spec}`,
                            inputColumn: 'col-6 p-0',
                            inputClassName: `${styles.basic_input} ${!isModal && styles.disabled}`
                        }}
                        label={`${EMAIL_LBL}:`}
                        type={"text"}
                        name={EMAIL_ID}
                        id={EMAIL_ID}
                        placeholder={EMAIL_PHDR}
                        validator={register(checkEmail)}
                        errors={errors}
                        required={true}
                        defaultValue={!isModal ? userData && userData?.email : ''}
                        disabled={!isModal}
                        customError={resMsgState?.errorMessage}
                        onChange={()=>{
                            dispatch(setResMsgState(''))
                          }}
                          onKeyPress={()=>{
                            dispatch(setResMsgState(''))
                          }}
                    />
                    :
                    null
                }

                {/* Phone */}
                <TextField
                    id={PHONE_ID}
                    name={PHONE_ID}
                    label={`Phone Number:`}
                    classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: styles.basic_input
                    }}
                    type="text"
                    validator={(e) => {
                        register(e, checkPhoneNumber)
                        phoneField.current = e
                    }}

                    required={true}
                    defaultValue={userData && userData?.phone}
                    errors={errors}
                />
                

                {/* Heading */}
                {!isModal && <div className={`col-sm-12 pt-4 ${styles.col_spec}`}>
                    <p className={`col-12 ${styles.subheading_2}`}>Customer Information</p>
                </div>
                }
                


                {/* Customer Number */}
                {externalAdmin | !externalAdmin ? ( 
                    <div className={`col-9 ${styles.input_icons}`}> 
                                       
                    <TextField 
                        data-testid="customerNumber"
                        id={CUSTOMER_NUMBER_ID}
                        name={CUSTOMER_NUMBER_ID}
                        label={`${CUSTOMER_NUMBER_LBL}:`}
                        classes = {
                            {
                                column: `col-12 ${styles.col_spec} ${isModal && styles.hidden}`,
                                labelClass: `col-4 col-form-label  ${styles.label_spec}`,
                                inputColumn: 'col-6 pl-0',
                                inputClassName: `${styles.basic_input} ${!isModal}`                                  
                            }                            
                        }
                        type="text"                            
                        errors={errors}
                        customError={errorMsg ? errorMsg : ""}
                        // customError={
                        //     editState?.errorMessage && errCk?.data===null
                        //       ? editState.errorMessage
                        //       : customerNumberSearchDatas &&
                        //         Array.isArray(customerNumberSearchDatas) &&
                        //         customerNumberSearchDatas.length === 0
                        //       ? "Customer Number is not found"
                        //       : ""
                        //   }
                        required={true}
                        validator={register()} 
                        // validator={register({
                        //     validate: (value,e) => {
                        //       if ((value === ""|| value === null)&&(traveNurse === 0)) {
                        //         return "Customer Number Required"
                        //       } 
                        //       return true                             
                        //     }
                        //   })}                       
                        defaultValue={toDo}                                                   
                        onKeyPress={()=>{
                            setErrorMsg('')
                        }}
                        onChange={(e)=>getCustomerNumberSearch(e.target.value)}                        
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              getCustomerNumber(e.target.value);
                            }
                        }}                           
                    />  
                    {customerNumberSearchDatas &&
                        Array.isArray(customerNumberSearchDatas) &&
                        customerNumberSearchDatas.length > 0 && (
                            <div className="col-12 col-spec row">
                             <div className={`col_spec mr-auto ${styles.search_drop}`}>
                                <div className={`col-spec ${styles.search_close_box}`}>
                                {customerNumberSearchDatas.map(
                                    (items, index) =>
                                    items && (
                                        <p
                                        className={`dropdown-item`}
                                        title={items.value}
                                        onClick={(e) => customerNumberSelect(e, items)}
                                        >
                                        {items}
                                        </p>
                                    )
                                )}
                                </div>
                            </div>
                            </div>
                        )}                  
                    <span 
                        id="clear_icon"
                        className={`${styles.x_icon}`}
                        onClick={(e)=>ClearData(
                            document.getElementById(CUSTOMER_NUMBER_ID).value
                          )}                          
                        >
                        <i className="fa fa-times" style={{color : "#000"}} aria-hidden="true"></i>
                    </span>                          
                                              
                    
                </div>
                ): null}

                {!isModal && <div className={`col-12 ${styles.col_spec}`}>
                    {/* Organizational View*/}
                    {(checkOrgDrop === false) || (checkOrgDrop === true) && <Organizational
                        id={"organizationalView"}
                        name={"organizationalView"}
                        label={`Facility/Locations:`}
                        classes={{
                            column: `col-12 mb-5 ${styles.col_spec} ${isModal && styles.hidden}`,
                            labelClassName: `col-3 col-form-label ${styles.label_spec}`,
                            inputColumn: `col-6 px-0 ${styles.organizationalView} `,
                            inputClassName: `${styles.basic_input} form-control`
                        }}                        
                        optionValue={faciOptionData}
                        groupBy="cat"
                        displayValue="key"
                        defaultValue={onselectOrgz}                       
                        onSelect={onSelectOrg}
                        selectAll={true}  
                        required={true}
                        errors={errors}
                        dropdownOpen = {dropdownOpen}    
                                                      
                    />
                    }                
                    { isValidSaleCust && <span className={`${styles.error_message} ${styles.error_CustNum} text-center`}>{
                        'Please select facility' }
                    </span> }
                </div>}
                

                
                {/* Organizational List*/}
                {traveNurse === 1 && (
                    <div className={`col-12 px-0 mb-5`} id={"displayTravelNurse"}>                    
                    {todoList && todoList?.map((item, id, index)=>{  
                        if(item?.customerNumber === "" || item?.facilities === null || item?.facilities?.length === 0 || item?.facilities === ""|| item?.facilities?.length === "" || item?.facilities?.length === null) {
                            return null;
                        }                 
                        return(                        
                            <div className={`col-12 d-flex mb-5 ${styles.col_spec}`}>
                                <label className={`col-3 col-form-label ${styles.label_spec}`}>Customer Number: </label>
                                <div className={`col-9 pt-2 ${styles.col_spec}`} key={id}>  
                                    {item?.isNe1WmsEnable === "0" ? (
                                        <span className={`${styles.excla_icon} ${styles.tooltip}`}>                                        
                                            <i class="fa-solid fa-exclamation"style={{color : "#ff0000"}} aria-hidden="true">
                                                <span class={`${styles.tooltiptext}`}>NE1 is disabled</span>
                                            </i>                                       
                                        </span>
                                    ):null}                                                            
                                    <a href={`/admin/customers/${item?.customerId}`} target="_blank">{item.value}</a>
                                    <img
                                        className={`${styles.close_icon} check_remove`}
                                        src={closeIcon}
                                        alt=""
                                        onClick={()=>removeCustomerNumberList(item?.customerNumber)}
                                    />
                                    {`|`}
                                
                                    <div className={`custom-control custom-switch pl-2 pt-0 d-flex`}>
                                        {`Default :  `}
                                        <div className={`${styles.toggle_shells}`}>                       
                                        <input 
                                            type="checkbox"
                                            name="makeDefault" 
                                            className="custom-control-input" 
                                            checked = {item?.isDefault}
                                            id={item?.customerNumber}                                               
                                            onChange={()=>makeDefaultClick(item?.customerNumber, item?.isDefault)} 
                                        />
                                        <label className={`custom-control-label ${styles.toggle_switch}`} for={item?.customerNumber}></label> 
                                        </div>                                        
                                    </div>
                                </div>
                                <label className={`col-3 col-form-label ${styles.label_spec}`}>Facility/Locations: </label> 
                                <div className={`col-9 pl-0 pt-0 col-form-label ${styles.content_spec}`}>                                    
                                    {item?.facilities?.map((fac, id)=>{
                                        return(
                                            <div className={`col-12 p-0 col-form-label ${styles.content_spec}`} key={id}> 
                                                {fac?.isNe1WmsEnable === "0" ? (
                                                    <span className={`${styles.excla_icon} ${styles.tooltip}`}>                                        
                                                        <i class="fa-solid fa-exclamation"style={{color : "#ff0000"}} aria-hidden="true">
                                                            <span class={`${styles.tooltiptext}`}>NE1 is disabled</span>
                                                        </i>                                       
                                                    </span>
                                                ):null}   
                                                <div className={`col-form-label pr-1 ${styles.content_spec}`}>               
                                                    {getFacilityLabelUrl(fac)}                                                     
                                                </div>
                                                <img
                                                    className={`${styles.close_icon_list} check_remove`}
                                                    src={closeIcon}
                                                    alt=""
                                                    onClick={()=>removeSelectedFacilitiesList(item?.customerNumber, fac?.facilityNumber)}                                                    
                                                />                                                
                                            </div>
                                        )
                                    })}
                                </div>                         
                        </div>
                        )                        
                    })} 
                    { isMakeDefault && <span className={`${styles.error_message} ${styles.error_CustDefu} text-center`}>
                        {`Must select a 'Default' customer number`}
                    </span> } 
                </div>
                )}
                   
                

                <div className="col-sm-12">
                    <div className="text-center pb-4">
                        {/* Save Button */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                            type="submit"
                            id={SUBMIT_ID}
                        >
                            {SAVE_BTN}
                        </Button>

                        {/* Cancel button  */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={handleCancel}
                        >
                            {CANCEL_BTN}
                        </Button>

                    </div>
                </div>

            </Form>
        </>
    )
}

CreateEditUser.propTypes = {
    register: PropTypes.func,
    handleSubmit: PropTypes.func,
    control: PropTypes.object,
    handleCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    userData: PropTypes.object
}
