import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateFormBuilderMediaList } from '../../../app/customFormSlice';
import { CANCEL_BTN, CANCEL_ID, SUBMIT_ID } from '../../../constants';
import { getFileName } from '../../../utils';
import { Button, RadioButton, TextArea } from '../../primary'
import Loader from '../../../static/images/spinner.svg';
import styles from './formBuilderImage.module.scss'
import { clearFileInputMove, fileTypeCheckerMove, fixBaseCodeMove, getBase64FromUrlMove, getBase64Move, handleImageChangeMove, handleUrlMove, imageValidatorMove } from '../../../utils/productImageHelper';

export function FormBuilderImage({ handleAddMediaCancel,questionId,formId, mediaList, questionIndex,customFormBuilder,setcustomFormBuilder, getMedia }) {

    const [radioOption, setRadioOption] = useState('Browse') // Radio option url or browse
    const [inputList, setInputList] = useState([]) // List we get from API
    const [addedList, setAddedList] = useState([]) // The image we add 
    const [displayName, setDisplayName] = useState(null) // To display selected file name
    const [imageError, setImageError] = useState(false) // To dispaly image size and dimension error
    const [serverError, setServerError] = useState(null) // sserver error
    const [uploadSuccess, setUploadSuccess] = useState(false) // on Success]

    const acceptedFileTypes = "image/jpg, image/jpeg, image/png";

    // Loading media list from API to a state
    useEffect(() => {
        if (mediaList?.images && mediaList?.images?.length > 0) {
            setInputList([...mediaList?.images])//NOSONAR
        }
    }, [mediaList])


    const dispatch = useDispatch()

    const handleRadio = (e) => {
        setRadioOption(e.target.value)
    }

    // Function to clear file input
    const clearFileInput = () => {
        clearFileInputMove(radioOption, setDisplayName)
    }

    useEffect(() => {
        clearFileInput()
    }, [radioOption])

    // Function to convert file to base 64. This function will return promise.
    function getBase64(file) {
        return getBase64Move(file)
    }

    // Function to check file size and dimension. If it is bigger than the limit,
    // I am displaying an error message and resetting the input field.
    function imageValidator(file) {
        return imageValidatorMove(file, setImageError, setDisplayName)
    }

    // Function to remove base64 code prefix
    const fixBaseCode = (data) => {
        return fixBaseCodeMove(data)
    }

    const fileTypeChecker = (e) => {
        return fileTypeCheckerMove(e)
    }

    const handleImageChange = (e) => {
        handleImageChangeMove(e, fileTypeChecker, setServerError, clearFileInput, addedList, setAddedList, imageValidator, imageError, setDisplayName, getBase64, fixBaseCode)
    };

    useEffect(() => {
        if (imageError) {
            setAddedList([])
        }
    }, [imageError])

    // State to show loading image until url fetches image
    const [urlLoading, setUrlLoading] = useState(false)

    // Image from URL
    const getBase64FromUrl = async (url) => {
        return getBase64FromUrlMove(url, setUrlLoading, setServerError)
    }


    // Handle URL 
    const handleUrl = (e) => {
        handleUrlMove(e, addedList, setAddedList, getBase64FromUrl, setUrlLoading, fixBaseCode, getFileName)
    }


    // On submit 

    const onSubmit = () => {
        
        if(addedList && addedList.length === 0){
            setServerError("Please select image to upload")
            setTimeout(() => {
                setServerError(null)
            }, [5000])
            return;
        }
        const list = { ...mediaList }
        if (list?.images === null || list?.images?.length === 0) {
            list['images'] = [...addedList]
        }
        else if (list?.images && list?.images?.length > 0) {
            list['images'] = [...list['images'], ...addedList]
        }
        dispatch(updateFormBuilderMediaList({formId, questionId, list, getMedia, setUploadSuccess, setServerError,questionIndex,customFormBuilder,setcustomFormBuilder })).then(() => {
            clearFileInput();
            setAddedList([]);
        }
        ) // Dispatch goes here
    }

    const loading = useSelector(state => state.app.loading)

    return (
        <div className={styles.product_image}>

            {inputList && inputList?.length >= 5 ?

                <>
                    <h3>Maximum media size reached</h3>

                    <div className="text-center pb-4">
                        <Button
                            classes={{ className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={handleAddMediaCancel}
                        >
                            {CANCEL_BTN}
                        </Button>
                    </div>

                </>

                :

                <>

                    <div className={styles.specs}>
                        Max file size: 1MB <br /> Supported image format (JPG, JPEG, PNG)
                        {/* Max file size: 1MB <br /> Recommended image size 1600px X 900px <br /> Supported image format (JPG, JPEG, PNG) */}
                    </div>
                    <h4>Upload Options</h4>
                    {imageError && <div className={`alert alert-danger ${styles.alert_fix}`}>Image is too large and exceeds the 1 MB size limit.</div>}
                    {serverError && <div className={`alert alert-danger ${styles.alert_fix}`}>{serverError}</div>}
                    {uploadSuccess && <div className={`alert alert-success ${styles.alert_fix}`}>Image uploaded successfully.</div>}
                    <RadioButton
                        id={"imageFileType"}
                        name={"imageFileType"}
                        classes={{
                            labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input} imageFileType`,
                            inputColumn: `custom-control custom-radio custom-control-inline`
                        }}
                        radioOption={['Browse', 'URL']}
                        defaultValue={'Browse'}
                        onChange={handleRadio}
                    />

                    <div className={styles.upload_block}>
                        {loading ? <div className={styles.loader}>
                            <img src={Loader} alt="loading loader" />
                        </div>
                            :
                            <>
                                {radioOption === 'Browse' && <div className={styles.file_block}>
                                    <label htmlFor={"image"} className={styles.file_upload}>
                                        Choose File
                                    </label>
                                    <input className={"d-none"} type="file" id={"image"} name="image" accept={acceptedFileTypes} onChange={handleImageChange} />
                                    <h6 title={displayName?displayName:''}>{displayName ? displayName : 'No File Chosen'}</h6>
                                </div>}
                                {radioOption === 'URL' && <div className={styles.url_block}>
                                    <TextArea
                                        id={"urlField"}
                                        name={"urlField"}
                                        classes={{ inputClassName: styles.text_area }}
                                        onChange={handleUrl}
                                        placeholder={"https://www.medline.com/pages/1496.jpg"}
                                    />
                                </div>}
                            </>
                        }
                    </div>

                    <div className="text-center pt-3">
                        {urlLoading && <div className={styles.loader}>
                            <img src={Loader} alt="loading" />
                            <br />
                        </div>}
                        {/* Save Button */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small} ${loading && styles.uploading_btn}` }}
                            type="button"
                            id={SUBMIT_ID}
                            onClick={onSubmit}
                        >
                            {loading ? 'Uploading...' : 'Upload'}
                        </Button>
                        {/* Cancel button  */}
                        <Button
                            classes={{ className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={handleAddMediaCancel}
                        >
                            {CANCEL_BTN}
                        </Button>
                    </div>
                </>}

        </div>
    )
}
