import React from 'react';
import styles from './pictureIconMultiple.module.scss'
import PropTypes from 'prop-types';
import { deepCopyArray } from '../../../utils/commonHelper';

export function PictureIconMultiple({ previewId, validateName, errors, imageURL, imagevalidator,
    setInputList, inputList, refKeys = '', subName = '', validator, id, name, browseLbl,
    classes, marketingBanner }) {

    let errorMessage = errors && errors[name] && errors[name].message;
    if ((errors && refKeys && validateName && errors['banners'] && errors['banners'][refKeys] && errors['banners'][refKeys][validateName])) {
        errorMessage = errors['banners'][refKeys][validateName].message;
    }

    const handleImageChange = (e, index, subName) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = (e && e.target && e.target.files[0]) ? e.target.files[0] : '';
        reader.onloadend = () => {
            let tempInputList = deepCopyArray(inputList);
            tempInputList[index][subName] = reader.result;
            setInputList(tempInputList)

        }
        if (e && e.target && e.target.files[0]) {
            reader.readAsDataURL(file)
        }
    };

    /* Remove image */
    const removeImage = (e, index, subName, id) => {
        let tempInputList = deepCopyArray(inputList);
        tempInputList[index][subName] = '';
        setInputList(tempInputList);
        document.getElementById(id).value = [];
        //document.getElementById(id).value = '';
    }
    return (
        <>
            {/* Upload block */}
            {

                <div className={`${marketingBanner ? '' : styles.picture_icon_block} ${(classes) ? classes.contr_wrapper : ''}`}>

                    {/* Uploaded image */}
                    {inputList && refKeys && subName && inputList[refKeys] && inputList[refKeys][subName] &&
                        <img
                            src={inputList[refKeys][subName]}
                            id={previewId + '_preView'}
                            alt="preview-img"
                            className={`${(classes) ? classes.pic_wrapper : ''}`}
                        />}
                    {/* Default upload link*/}
                    {inputList && refKeys && subName && inputList[refKeys] && inputList[refKeys][subName] === "" && <div className={styles.link} >
                        <label
                            htmlFor={id}
                            className={`${(classes) ? classes.browse_btn + ' ' + (marketingBanner && classes && classes.change_label) ? classes.change_label : '' : ''}`}>
                            {
                                !browseLbl ? "Upload Picture" : browseLbl
                            }
                        </label>
                    </div>}
                    {/* Link after selecting image */}
                    {inputList && refKeys && subName && inputList[refKeys] && inputList[refKeys][subName] && <div className={styles.link}>
                        {/* Change image                 */}
                        <label htmlFor={id} className={`${(classes && classes.change_label) ? classes.change_label : ''}`}>
                            Change  {marketingBanner ? 'Banner' : 'Picture'}
                        </label>
                        &nbsp;
                        {/* Remove image */}
                        <label
                            id={'remove_' + id}
                            data-testid={'remove_' + id}
                            onClick={e => removeImage(e, refKeys, subName, id)}
                            className={`${(classes && classes.change_label) ? classes.change_label : ''}`}>
                            Remove
                        </label>
                    </div>}
                    {errorMessage && <span className={styles.error_message}>{errorMessage}</span>}

                    {/* Hidden file input field */}
                    <input type="file" name={name} id={id} ref={validator} style={{ visibility: "hidden" }} onChange={e => handleImageChange(e, refKeys, subName)} />
                    <input
                        type="hidden"
                        name={imageURL}
                        ref={imagevalidator}
                        style={{ visibility: "hidden" }}
                        defaultValue={(inputList && refKeys && subName && inputList[refKeys] && inputList[refKeys][subName]) ? inputList[refKeys][subName] : ''}
                    />
                </div>
            }
        </>
    )
}
PictureIconMultiple.propTypes = {
    classes: PropTypes.object,
    errors: PropTypes.object,
}

