import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './platformAnalytics.module.scss'
import { Header } from '../../secondary/Header'
import { Button, DateTimePicker, Form } from '../../primary'
import Spreadsheet from '../../../static/images/ExportSpredsheet.svg';
import { format, startOfYear } from 'date-fns'
import { useForm } from 'react-hook-form'
import { allNullCheck } from '../../../utils'
import { getPlatformOverview, getPlatformResults, getPlatformResultsAlt, getPlatformResultsCsv, setPlatformResults } from '../../../app/woundReportSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DataTable, Pagination } from '../../secondary'
import { LIST_VIEW_LIMIT } from '../../../constants'
import { CSVLink } from 'react-csv';
import { setLoading } from '../../../app/appSlice';
import { getActivePageMove, handleToDateMove } from '../../../utils/platformHelper';


export function PlatformAnalytics({ history }) {
    /* Use hook form */
    const { register, handleSubmit, control, errors, reset, setError, clearErrors } = useForm();
    const customerName = useSelector(state => state.user.customerName);

    const dateRangeRef1 = useRef();
    const dateRangeRef2 = useRef();
    const [fromDate, setFromDate] = useState(startOfYear(new Date()))
    const [toDate, setToDate] = useState(new Date())
    const [selectedBlock, setSelectedBlock] = useState('acutecustomers')
    const [offSet, setOffSet] = useState(0)
    const [sortType, setSortType] = useState(null) // For mixing sorting with pagination
    // PAGINATION SECTION
    const [activePage, setActivePage] = useState(1);
    // We are storing data in ref since useCallback will take old value
    const customerRef = useRef(selectedBlock)

    const dispatch = useDispatch();

    //restrict when directly open the page
    const userDetails = useSelector(state => state.user);
    const adminView = userDetails?.role     
    useEffect(()=>{        
        window.onload = function() { 
            if (adminView === "ExternalStandardUser") {  
                history.push('/unAuthorized'); 
            }       
        }    
    },[]) //restrict when directly open the page



    const handleFromDate = (data) => {
        if(data){
            setFromDate(data)
        }
       
    }

    // Handling to date and displaying errors when selected date is less or greater 
    const handleToDate = (data) => {
        if(data){
        handleToDateMove(data, setToDate, startOfYear, setError, clearErrors)
        }
    }

    // Make first API call with default values for overview
    useEffect(() => {
        const payload = {
            fromDate: format(startOfYear(new Date()), "yyyy/LL/dd"),
            toDate: format(new Date(), "yyyy/LL/dd")
        }
        dispatch(getPlatformOverview(payload))
    }, [])

    const getResults = (fromDate, toDate, type) => {
        const payload = {
            fromDate: format(fromDate, "yyyy/LL/dd"),
            toDate: format(toDate, "yyyy/LL/dd"),
            customerType: type
        }
        dispatch(getPlatformResults(payload))
        dispatch(getPlatformResultsCsv(payload))
    }
    // Make first API call with default values for platform results
    useEffect(() => {
        getResults(startOfYear(new Date()), new Date(), 'acutecustomers')
    }, [])

    // Getting platform overview details
    const platformOverview = useSelector(state => state.woundreport.platformOverview)

    const [sectionName, setSectionName] = useState('Acute Customers')

    // Handle block click
    const handleBlockClick = (type, fullName) => {
        setSelectedBlock(type)
        setSectionName(fullName)
        customerRef.current = type
        getResults(fromDate, toDate, type)
    }


    // Getting platform results 
    const platformResults = useSelector(state => state.woundreport.platformResults)

    // List view
    const columns = [
        {
            Header: 'Customer Number',
            accessor: 'customerNumber',
            restWidth: '180px'
        },
        {
            Header: 'Customer Name',
            accessor: 'customerName',
            restWidth: '150px'
        },
        {
            Header: 'Location',
            accessor: 'location',
            restWidth: '150px'
        },
        {
            Header: 'Phone',
            accessor: 'phone',
            restWidth: '150px'
        },
        {
            Header: 'Sales Rep Number',
            accessor: 'salesRepNumber',
            restWidth: '180px'
        },
        {
            Header: 'Sales Rep Name',
            accessor: 'salesRepName',
            restWidth: '150px'
        },
        {
            Header: 'Formulary ID',
            accessor: 'formularyId',
            restWidth: '150px'
        },
        {
            Header: 'Formulary Name',
            accessor: 'formularyName',
            restWidth: '150px'
        },
        {
            Header: 'Regional Manager Number',
            accessor: 'regionalManagerNumber',
            restWidth: '230px'
        },
        {
            Header: 'Regional Manager Name',
            accessor: 'regionalManagerName',
            restWidth: '230px'
        },
        {
            Header: 'Division Manager Number',
            accessor: 'divisionManagerNumber',
            restWidth: '230px'
        },
        {
            Header: 'Division Manager Name',
            accessor: 'divisionManagerName',
            restWidth: '210px'
        },
        {
            Header: 'WoundCare Sales Rep Number',
            accessor: 'woundCareSalesRepNumber',
            restWidth: '250px'
        },
        {
            Header: 'WoundCare Sales Rep Name',
            accessor: 'woundCareSalesRepName',
            restWidth: '230px'
        },
        {
            Header: 'Primary Sales Rep Number',
            accessor: 'primarySalesRepNumber',
            restWidth: '230px'
        },
        {
            Header: 'Primary Sales Rep Name',
            accessor: 'primarySalesRepName',
            restWidth: '230px'
        }
    ]

    // SORTING SECTION

    // Payload
    const sortingPayLoad = {};
    sortingPayLoad['url'] = '/reports/platformanalytics/customerdata'
    sortingPayLoad['method'] = 'POST'
    sortingPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: isNaN(offSet)===true?0:offSet }
    sortingPayLoad['setMethod'] = setPlatformResults
    sortingPayLoad['setSortType'] = setSortType
    sortingPayLoad['asyncMethod'] = getPlatformResultsAlt
    sortingPayLoad['toBeSent'] = {
        fromDate: format(fromDate, "yyyy/LL/dd"),
        toDate: format(toDate, "yyyy/LL/dd"),
        customerType: customerRef.current
    }

    // Function which handles sort
    const handleSort = useCallback(data => {
        if (sortingPayLoad) {
            const sortingColumn = data.sortBy[0]
            const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
            sortingPayLoad['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
            // The below if condition is for 'Sorting' + 'Pagination'
            if (sortingPayLoad.setSortType) {
                sortingPayLoad.setSortType(sortingPayLoad['queryParam']['sort'])
            }
            dispatch(sortingPayLoad.asyncMethod(sortingPayLoad));
        }
    }, [dispatch, selectedBlock,activePage,offSet])

    // SORTING SECTION

    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        getActivePageMove(data, setActivePage, setPlatformResults, sortType, format, fromDate, toDate, selectedBlock, dispatch, getPlatformResultsAlt)
    }
    // PAGINATION SECTION

    // Below three functions are just dummy for listview
    const getSelectedRowData = (data) => {
    }

    const deleteDataList = () => {
    }

    const selectRowCount = () => {
    }

    // CSV DOWNLOAD SECTION 

    const [csvData, setCsvData] = useState([])

    // Getting platform results for CSV
    const platformResultsCsv = useSelector(state => state.woundreport.platformResultsCsv)

    // const platformResultsCsv = {
    // 	"customerData":[{
    // 	"customerNumber":"",
    // 	"customer Name":23,
    // 	"location":"Arun",
    // 	"phone":"poormobilitypressure",
    // 	"salesRepName":"stage 3",
    // 	"salesRepNumber":"left posterior",
    // 	"formularyID":"",   
    // 	"formularyName":"2021-12-31",
    // 	"regionalManagerNumber":"2021-12-31",
    // 	"regionalManagerName":"",
    // 	"divisionManagerNumber":"",
    // 	"divisionManagerName ":"",
    // 	"woundCareSalesRepName":"",
    // 	"woundCareSalesRepNumber":"",
    // 	"primarySalesRepNumber":"",
    // 	"primarySalesRepName":""
    // }
    // ],
    // "metadata": {
    // 	"total": 135,
    // 	"limit": 50,
    // 	"offset": 0,
    // 	"prev": "",
    // 	"next": "/reports/platformanalytics/customerdata?limit=50&offset=50&sort=&search="
    // }
    // }

    useEffect(() => {
        if (platformResultsCsv?.customerData && platformResultsCsv?.customerData.length > 0) {
            setCsvData([...platformResultsCsv?.customerData])//NOSONAR
        }
        else {
            setCsvData([])
        }
    }, [platformResultsCsv])


    // Setting CSV headers
    const [csvHeader, setCsvHeader] = useState([
        {
            label: 'Customer Number',
            key: 'customerNumber',
        },
        {
            label: 'Customer Name',
            key: 'customerName',
        },
        {
            label: 'Location',
            key: 'location',
        },
        {
            label: 'Phone',
            key: 'phone',
        },
        {
            label: 'Sales Rep Number',
            key: 'salesRepNumber',
        },
        {
            label: 'Sales Rep Name',
            key: 'salesRepName',
        },
        {
            label: 'Formulary ID',
            key: 'formularyId',
        },
        {
            label: 'Formulary Name',
            key: 'formularyName',
        },
        {
            label: 'Regional Manager Number',
            key: 'regionalManagerNumber',
        },
        {
            label: 'Regional Manager Name',
            key: 'regionalManagerName',
        },
        {
            label: 'Division Manager Number',
            key: 'divisionManagerNumber',
        },
        {
            label: 'Division Manager Name',
            key: 'divisionManagerName',
        },
        {
            label: 'WoundCare Sales Rep Number',
            key: 'woundCareSalesRepNumber',
        },
        {
            label: 'WoundCare Sales Rep Name',
            key: 'woundCareSalesRepName',
        },
        {
            label: 'Primary Sales Rep Number',
            key: 'primarySalesRepNumber',
        },
        {
            label: 'Primary Sales Rep Name',
            key: 'primarySalesRepName',
        }
    ])

    const csvLink = useRef();
    const getFileName = () => {
        const csvDownloadDate = format(new Date(), "MM.dd.yyyy-hh.mm.aaa");
        return `${customerName}.${sectionName}.Platform_Analytics.${csvDownloadDate}.csv`;
    }

    const pdfPage = () => {
        dispatch(setLoading(true))
        history.push({
            pathname: '/platformanalytics/pdfdownload',
            state: platformOverview
        });
    }

    // Reset form 
    const resetData = () => {
        reset({ datePicker1: startOfYear(new Date()), datePicker2: new Date() });
    }

    // Handle submit
    const doSubmit = (data) => {
        const payload = {
            fromDate: format(data.datePicker1, "yyyy/LL/dd"),
            toDate: format(data.datePicker2, "yyyy/LL/dd")
        }
        dispatch(getPlatformOverview(payload))
        const otherPayload = {
            fromDate: format(data.datePicker1, "yyyy/LL/dd"),
            toDate: format(data.datePicker2, "yyyy/LL/dd"),
            customerType: selectedBlock
        }
        dispatch(getPlatformResults(otherPayload))
        dispatch(getPlatformResultsCsv(otherPayload))
    }

    return (
        <div>
            <Header reportHeading={"Platform Analytics"} />

            <div className={`col ${styles.wound_dashboard} mt-0`}>
                <Form onSubmit={handleSubmit(doSubmit)} >
                    <div className={styles.platform_form}>
                        <div className={`col-8 ${styles.col_spec}`}>
                            <label className={`col-5 ${styles.label_spec}`}>Date Range:</label>
                            <DateTimePicker
                                id={"datePicker1"}
                                name={"datePicker1"}
                                control={control}
                                classes={{
                                    date_picker: styles.wound_input,
                                    dateColumn: "col-12 p-0",
                                    column: `col m-0 ${styles.col_spec}`,
                                }}
                                defaultValue={startOfYear(new Date())}
                                customOnChange={handleFromDate}
                                validator={register}
                                displayIcon={true}
                                showMonthDropdown={true}
                                dateRef={dateRangeRef1}
                                showYearDropdown={true}
                                errors={errors}
                            />
                            <DateTimePicker
                                id={"datePicker2"}
                                name={"datePicker2"}
                                control={control}
                                classes={{
                                    date_picker: styles.wound_input,
                                    dateColumn: "col-12 p-0",
                                    column: `col m-0 pr-0 ${styles.col_spec}`,
                                }}
                                defaultValue={new Date()}
                                customOnChange={handleToDate}
                                validator={register}
                                displayIcon={true}
                                showMonthDropdown={true}
                                dateRef={dateRangeRef2}
                                showYearDropdown={true}
                                errors={errors}
                                maxDate={new Date()}
                            />
                        </div>
                        <div className={`col-4 ${styles.col_spec}`}></div>


                        <div className={`col-8 ${styles.col_spec}`}>
                            <label className={`col-5 ${styles.label_spec}`}></label>
                            <Button
                                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small} mr-4` }}
                                type="submit"
                            >
                                Apply
                            </Button>
                            <Button
                                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} ` }}
                                onClick={resetData}
                            >
                                Reset
                            </Button>
                        </div>

                    </div>
                </Form>
                <div className={styles.pa_blk}>
                    <div className={styles.title_top}>
                        <p className={styles.bi_title}>Support Info</p>
                        <span className={styles.down_pdf} onClick={pdfPage}><img src={Spreadsheet} alt="" />
                            <label>&nbsp; Download as PDF </label>
                        </span>
                    </div>

                    <div className={styles.platform_content}>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Clinical Support
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.supportInfo?.clinicalSupportInfo?.clinicalSupport?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.supportInfo?.clinicalSupportInfo?.clinicalSupport?.percent) ? platformOverview?.supportInfo?.clinicalSupportInfo?.clinicalSupport?.percent + '%' : '-'}
                                </div>
                            </div>
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    # of Tickets
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.supportInfo?.clinicalSupportInfo?.numberOfTickets?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.supportInfo?.clinicalSupportInfo?.numberOfTickets?.percent) ? platformOverview?.supportInfo?.clinicalSupportInfo?.numberOfTickets?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Application Support
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.supportInfo?.applicationSupportInfo?.applicationSupport?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.supportInfo?.applicationSupportInfo?.applicationSupport?.percent) ? platformOverview?.supportInfo?.applicationSupportInfo?.applicationSupport?.percent + '%' : '-'}
                                </div>
                            </div>
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    # of Tickets
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.supportInfo?.applicationSupportInfo?.numberOfTickets?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.supportInfo?.applicationSupportInfo?.numberOfTickets?.percent) ? platformOverview?.supportInfo?.applicationSupportInfo?.numberOfTickets?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={styles.title_top}>
                        <p className={styles.bi_title}>NE1 WMS (Paid customers)</p>
                    </div>

                    <div className={styles.platform_content}>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Acute
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.ne1wmsPaidCustomer?.acute?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.ne1wmsPaidCustomer?.acute?.percent) ? platformOverview?.ne1wmsPaidCustomer?.acute?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Post Acute
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.ne1wmsPaidCustomer?.postAcute?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.ne1wmsPaidCustomer?.postAcute?.percent) ? platformOverview?.ne1wmsPaidCustomer?.postAcute?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={styles.title_top}>
                        <p className={styles.bi_title}>Product Master</p>
                    </div>

                    <div className={styles.platform_content}>
                        <div className="col-12">
                            <h3 className={styles.pa_subtitle}>Advanced Skin Care</h3>
                        </div>
                        <div className="col-5">

                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Active
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.advancedSkinCare?.active?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.advancedSkinCare?.active?.percent) ? platformOverview?.productMaster?.advancedSkinCare?.active?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Inactive
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.advancedSkinCare?.inActive?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.advancedSkinCare?.inActive?.percent) ? platformOverview?.productMaster?.advancedSkinCare?.inActive?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={`${styles.platform_content} pt-2`}>
                        <div className="col-12">
                            <h3 className={styles.pa_subtitle}>Advanced Wound Care</h3>
                        </div>
                        <div className="col-5">

                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Active
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.advancedWoundCare?.active?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.advancedWoundCare?.active?.percent) ? platformOverview?.productMaster?.advancedWoundCare?.active?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Inactive
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.advancedWoundCare?.inActive?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.advancedWoundCare?.inActive?.percent) ? platformOverview?.productMaster?.advancedWoundCare?.inActive?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`${styles.platform_content} pt-2`}>
                        <div className="col-12">
                            <h3 className={styles.pa_subtitle}>Nutrition and Pharmaceuticals</h3>
                        </div>
                        <div className="col-5">

                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Active
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.nutritionandPharmaceuticals?.active?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.nutritionandPharmaceuticals?.active?.percent) ? platformOverview?.productMaster?.nutritionandPharmaceuticals?.active?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Inactive
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.nutritionandPharmaceuticals?.inActive?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.nutritionandPharmaceuticals?.inActive?.percent) ? platformOverview?.productMaster?.nutritionandPharmaceuticals?.inActive?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={`${styles.platform_content} pt-2`}>
                        <div className="col-12">
                            <h3 className={styles.pa_subtitle}>Repositioning and Offloading</h3>
                        </div>
                        <div className="col-5">

                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Active
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.repositioningandOffloading?.active?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.repositioningandOffloading?.active?.percent) ? platformOverview?.productMaster?.repositioningandOffloading?.active?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Inactive
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.repositioningandOffloading?.inActive?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.repositioningandOffloading?.inActive?.percent) ? platformOverview?.productMaster?.repositioningandOffloading?.inActive?.percent + '%' : '-'}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={styles.overview_blk}>
                        <div className={styles.pa_score}>
                            <div className={`${styles.pa_percentage} ${selectedBlock === 'acutecustomers' ? styles.active : ''}`} onClick={() => handleBlockClick('acutecustomers', 'Acute Customers')}>
                                <label className={styles.dash_top}>Acute Customers</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.acuteCustomers?.count}</span>
                                    <span>{allNullCheck(platformOverview?.acuteCustomers?.percent) ? platformOverview?.acuteCustomers?.percent + '%' : '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage} ${selectedBlock === 'postacutecustomers' ? styles.active : ''}`} onClick={() => handleBlockClick('postacutecustomers', 'Post Acute Customers')}>
                                <label className={styles.dash_top}>Post Acute Customers</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.postAcuteCustomers?.count}</span>
                                    <span>{allNullCheck(platformOverview?.postAcuteCustomers?.percent) ? platformOverview?.postAcuteCustomers?.percent + '%' : '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage} ${selectedBlock === 'mostactivecustomers' ? styles.active : ''}`} onClick={() => handleBlockClick('mostactivecustomers', 'Most Active Customers')}>
                                <label className={styles.dash_top}>Most Active Customers</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.mostActiveCustomers?.count}</span>
                                    <span>{allNullCheck(platformOverview?.mostActiveCustomers?.percent) ? platformOverview?.mostActiveCustomers?.percent + '%' : '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage} ${selectedBlock === 'ne1wmspaidcustomers' ? styles.active : ''}`} onClick={() => handleBlockClick('ne1wmspaidcustomers', 'NE1 WMS Paid Customers')}>
                                <label className={styles.dash_top}>NE1 WMS Paid Customers</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.ne1wmsPaidCustomers?.count}</span>
                                    <span>{allNullCheck(platformOverview?.ne1wmsPaidCustomers?.percent) ? platformOverview?.ne1wmsPaidCustomers?.percent + '%' : '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage} ${selectedBlock === 'customerswithformulary' ? styles.active : ''}`} onClick={() => handleBlockClick('customerswithformulary', 'Customers with Formulary')}>
                                <label className={styles.dash_top}>Customers with Formulary</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.customersWithFormulary?.count}</span>
                                    <span>{allNullCheck(platformOverview?.customersWithFormulary?.percent) ? platformOverview?.customersWithFormulary?.percent + '%' : '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage} ${selectedBlock === 'customerswithoutformulary' ? styles.active : ''}`} onClick={() => handleBlockClick('customerswithoutformulary', 'Customers without Formulary')}>
                                <label className={styles.dash_top}>Customers without Formulary</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.customersWithoutFormulary?.count}</span>
                                    <span>{allNullCheck(platformOverview?.customersWithoutFormulary?.percent) ? platformOverview?.customersWithoutFormulary?.percent + '%' : '-'}</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div>

                        {csvData && csvData.length > 0 && <div className="col-sm-12 mb-1 mt-4 justify-content-end d-flex">
                            <span className={styles.down_spread}>
                                <CSVLink
                                    onClick={() => csvLink.current.link.download = getFileName()}
                                    headers={csvHeader}
                                    data={csvData}
                                    filename={getFileName}
                                    ref={csvLink}
                                    target="_blank"
                                >
                                    <img src={Spreadsheet} alt="" />  Download as CSV
                                </CSVLink>
                            </span>
                        </div>}

                        {platformResults?.customerData && platformResults?.customerData?.length > 0 ?
                            <div className={styles.list_outer}>
                                <DataTable
                                    columns={columns}
                                    data={platformResults?.customerData}
                                    sorting={true}
                                    getSelectedRowData={getSelectedRowData}
                                    setSelectedRows={selectRowCount}
                                    onSort={handleSort}
                                    detailView={false}
                                    deleteDataList={deleteDataList}
                                    showFixedColumn={false}
                                    workListView={true}
                                    sortType={(sortType)?sortType:''}
                                    reportView={true}
                                />

                                {platformResults?.customerData?.length > 0 && <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={platformResults?.metadata ? platformResults?.metadata?.limit : 50}
                                    totalItemsCount={platformResults?.metadata ? platformResults?.metadata?.total : 0}
                                    getActivePage={getActivePage}
                                />}
                            </div>
                            :
                            <>
                                {/* When there is no data */}
                                <div className={styles.empty}>
                                    <div className={styles.empty_heading}>
                                        <span>Customer Number</span>
                                        <span>Customer Name</span>
                                        <span>Location</span>
                                        <span>Phone</span>
                                        <span>Sales Rep Number</span>
                                        <span>Sales Rep Name</span>
                                        <span>Formulary ID</span>
                                        <span>Formulary Name</span>
                                        <span>Regional Manager Number</span>
                                        <span>Regional Manager Name</span>
                                        <span>Division Manager Number</span>
                                        <span>Division Manager Name</span>
                                        <span>WoundCare Sales Rep Number</span>
                                        <span>WoundCare Sales Rep Name</span>
                                        <span>Primary Sales Rep Number</span>
                                        <span>Primary Sales Rep Name</span>
                                    </div>
                                    <div className={styles.empty_body}>
                                        <h5>There are no records matching your report criteria.</h5>
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                </div>

            </div>

        </div>
    )
}
