import React from "react";
import { default as ReactPagination } from "react-js-pagination";
import 'bootstrap/dist/css/bootstrap.min.css';
import style from './pagination.module.scss';
import { LIST_VIEW_LIMIT } from '../../../constants';

export let Pagination = ({ activePage, getActivePage, itemsCountPerPage, totalItemsCount, pageRangeDisplayed, formularyView ,reportView }) => {
    const limit = (((activePage*LIST_VIEW_LIMIT)-LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 1:((activePage*LIST_VIEW_LIMIT)-LIST_VIEW_LIMIT) + 1;
    const showCount = activePage * itemsCountPerPage <= totalItemsCount ? activePage * itemsCountPerPage : totalItemsCount;
   
    return <div className={`p-2 float-sm-right ${style.clear_both} pt-4`}>
        
        <label className={style.page_show}>{`Showing ${limit} - ${showCount} of ${totalItemsCount}`}</label>
        
        <ReactPagination
            innerClass={`pagination float-sm-right ${totalItemsCount < LIST_VIEW_LIMIT ? style.nopagination : " "} `}
            itemClass={`page-item ${style.page_item}`}
            linkClass={`page-link ${style.page_link}`}
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={pageRangeDisplayed}
            onChange={(number) => getActivePage(number)}
        />
    </div>
    
}