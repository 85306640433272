/**
 * Persistence layer with expiration based on localStorage/asyncStorage.
 */

class NamespacedStorage {
    constructor(storage, key) {
        this.storage = storage;
        this.key = key;
    }
    _makeKey(key) {
        return `${this.key}__${key}`;
    }
    getItem(name) {
        return this.storage.getItem(this._makeKey(name));
    }
    setItem(name, value) {
        return this.storage.setItem(this._makeKey(name), value);
    }
    removeItem(name) {
        return this.storage.removeItem(this._makeKey(name));
    }
}

const KEY = 'DSHP_PERSISTENCE';

export class SimplePersistence {
    
    constructor() {
        this.storage = new NamespacedStorage(
            window && window.localStorage, //TODO:add logic to use asynstorage
            // window &&window.sessionStorage,
            this.constructor.KEY || KEY
        );
    }
    getItem(name, full=false) {
        const now = Date.now();
        const item = this.storage.getItem(name);
        if (full) { return item }
        if (!item) {
            return undefined;
        }
        const { value, ttl, timeStored } = JSON.parse(item);
        if (ttl && now - timeStored > ttl * 1000) {
            this.storage.removeItem(name);
            return undefined;
        }
        return JSON.parse(value);
    }
    setItem(name, value, ttl, full = undefined) {
        if (full !== undefined) {
            this.storage.setItem(name, full);
            return;
        }
        const timeStored = Date.now();
        this.storage.setItem(
            name,
            JSON.stringify({
                value: JSON.stringify(value),
                timeStored,
                ttl
            })
        );
    }
    removeItem(name) {
        this.storage.removeItem(name);
    }
}
