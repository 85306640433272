import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { getDownloadTreatmentReportCSVDetails, getTreatmentWorklist, setTreatmentReportCsv } from '../../../app/woundReportSlice';
import { DASHBOARD_TREAMENTOUTCOME_ASSESSMENT, LIST_VIEW_LIMIT } from '../../../constants';
import Spreadsheet from '../../../static/images/ExportSpredsheet.svg';
import { firstLetterCaps } from '../../../utils';
import { DataTable, Pagination } from '../../secondary';
import style from './treatmentReportData.module.scss';
// import ReactExport from 'react-export-excel'
// const ExcelFile=ReactExport.ExcelFile;
// const ExcelSheet=ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn=ReactExport.ExcelFile.ExcelColumn;

export let TreatmentReportData = ({ dispatch, history, tempPayload, selectedDivOption }) => {
    useEffect(() => {
        setSortType('')
        setOffSet(0)
        setActivePage(1)
        const woundAssessmentPayload = { ...tempPayload, selectedOption: selectedDivOption }
        const listPayLoad = {};
        listPayLoad['url'] = DASHBOARD_TREAMENTOUTCOME_ASSESSMENT
        listPayLoad['method'] = 'Post'
        listPayLoad['payload'] = woundAssessmentPayload
        listPayLoad['queryParam'] = { offSet: 0, limit: LIST_VIEW_LIMIT }
        if(woundAssessmentPayload&&woundAssessmentPayload.facility&&woundAssessmentPayload.facility.length>0){
            dispatch(getTreatmentWorklist(listPayLoad))
        }
        
    }, [tempPayload, selectedDivOption])
    const [activePage, setActivePage] = useState(1);
    const [offSet, setOffSet] = useState(0)
    const [sortType, setSortType] = useState('')
    const treatmentWorklistData = useSelector(state => state.woundreport.treatmentWorklistData);

    useEffect(() => {
        dispatch(setTreatmentReportCsv({}));
        setTimeout(() => {
            const total = (treatmentWorklistData?.treatmentOutcome && treatmentWorklistData.metadata) ? treatmentWorklistData.metadata.total : 50
            const queryParam = `limit=` + total + `&offSet=0`;
            const userDetailPayload = { ...tempPayload, selectedOption: selectedDivOption }
            if(userDetailPayload&&userDetailPayload.facility&&userDetailPayload.facility.length>0&&total!==0){
            dispatch(getDownloadTreatmentReportCSVDetails(userDetailPayload, queryParam))
        }
        }, 100)
    }, [treatmentWorklistData, tempPayload, selectedDivOption])

    const treatmentReportCsv = useSelector(state => state.woundreport.treatmentReportCsv);
    const customerName = useSelector(state => state.user.customerName);
    const csvLink = useRef();

    const getFileName = () => {
        const csvDownloadDate = format(new Date(), "MM.dd.yyyy-hh.mm.aaa");
        return `${customerName}.${selectedDivOption}.Treatment_Outcomes.${csvDownloadDate}.csv`;
    }
    let columns =  [
        {
            Header: ' Product photo',
            accessor: 'productFamilyImage',
            imageData: 'picture',
            restWidth: '150px',
            disableSortBy: true,

        },
        {
            Header: 'Category',
            accessor: 'category',
            restWidth: '150px'
        },
        {
            Header: 'Product family',
            accessor: 'productFamily',
            restWidth: '320px'
        },
        {
            Header: 'Material #',
            accessor: 'material',

        },
        {
            Header: 'Material description',
            accessor: 'materialDescription',
            restWidth: '350px'
        },
        {
            Header: '# Applied',
            accessor: 'noOfApplied',
            restWidth: '120px'
        },
        {
            Header: '% Utilization',
            accessor: 'utilization',

        },
        {
            Header: 'Avg. Rate of Healing',
            accessor: 'avgRateOfHealing',
            restWidth: '165px'

        },
    ];
if(selectedDivOption==='Primary Dressings'||selectedDivOption==='Secondary Dressings'){
    columns = [
        {
            Header: ' Product photo',
            accessor: 'productFamilyImage',
            imageData: 'picture',
            restWidth: '150px',
            disableSortBy: true,

        },
        {
            Header: 'Category',
            accessor: 'category',
            restWidth: '150px'
        },
        {
            Header: 'Product family',
            accessor: 'productFamily',
            restWidth: '320px'
        },
        {
            Header: 'Material #',
            accessor: 'material',

        },
        {
            Header: 'Material description',
            accessor: 'materialDescription',
            restWidth: '350px'
        },
        {
            Header: '# Applied',
            accessor: 'noOfApplied',
            restWidth: '120px'
        },
        {
            Header: '% Utilization',
            accessor: 'utilization',

        },
        {
            Header: 'Avg. Change Frequency',
            accessor: 'avgChangeFrequency',
            restWidth: '200px'

        },
        {
            Header: 'Avg. Rate of Healing',
            accessor: 'avgRateOfHealing',
            restWidth: '165px'

        },
    ];
}
 
    //redirect
    const getSelectedRowData = (data) => {
        // if (data) {
        //     history.push(`/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`);
        // }
    }

    const deleteDataList = () => {
    }

    const selectRowCount = () => {
    }

    const handleSort = useCallback(data => {
        if (data && data.sortBy[0] && data.sortBy[0].id) {
            const woundAssessmentPayload = { ...tempPayload, selectedOption: selectedDivOption }
            const sortingColumn = data.sortBy[0]
            const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
            let sortParam = sortingColumn.id + sortingType
            const listPayLoad = {};
            listPayLoad['url'] = DASHBOARD_TREAMENTOUTCOME_ASSESSMENT
            listPayLoad['method'] = 'Post'
            listPayLoad['payload'] = woundAssessmentPayload
            listPayLoad['queryParam'] = { offSet: isNaN(offSet)===true?0:offSet, limit: LIST_VIEW_LIMIT, sort: sortParam }
            setSortType(sortParam)
            if(woundAssessmentPayload&&woundAssessmentPayload.facility&&woundAssessmentPayload.facility.length>0){
            dispatch(getTreatmentWorklist(listPayLoad))}
        }

    }, [dispatch, tempPayload, selectedDivOption,offSet])
    const getActivePage = (data) => {
        setActivePage(data);
        const woundAssessmentPayload = { ...tempPayload, selectedOption: selectedDivOption }
        const listPayLoad = {};
        listPayLoad['url'] = DASHBOARD_TREAMENTOUTCOME_ASSESSMENT
        listPayLoad['method'] = 'Post'
        listPayLoad['payload'] = woundAssessmentPayload
        listPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT,sort: (sortType)?sortType:'' }
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        if(woundAssessmentPayload&&woundAssessmentPayload.facility&&woundAssessmentPayload.facility.length>0){
             dispatch(getTreatmentWorklist(listPayLoad))
        }
    }
    const fileHeaders = [
        { label: 'Category', key: 'category' },
        { label: 'Product Family', key: 'productFamily' },
        { label: 'Material #', key: 'material' },
        { label: 'Material Description', key: 'materialDescription' },
        { label: '# Applied', key: 'noOfApplied' },
        { label: '% Utilization', key: 'utilization' },
        { label: 'Avg. Change Frequency', key: 'avgChangeFrequency' },
        { label: 'Avg. Rate of Healing', key: 'avgRateOfHealing' },
    ];

    return (
        <>
            {treatmentReportCsv?.treatmentOutcome && treatmentReportCsv?.treatmentOutcome.length > 0 &&
                <>
                    <div className="col-sm-12 mb-2 justify-content-end d-flex">
                        <span className={style.down_spread}><img src={Spreadsheet} alt="" />
                            {/* <ExcelFile filename={getFileName()} element={<label className={style.excel}> Download as CSV</label>} >
                    <ExcelSheet data={treatmentReportCsv?.treatmentOutcome} name={getFileName()}>
                        <ExcelColumn label="Category" value="category" />
                        <ExcelColumn label="Product Family" value="productFamily" />
                        <ExcelColumn label="Material #" value="material" />
                        <ExcelColumn label="Material Description" value="materialDescription" />

                        <ExcelColumn label="# Applied" value="noOfApplied" />
                        <ExcelColumn label="% Utilization" value="utilization" />
                        <ExcelColumn label="Avg. Change Frequency" value="avgChangeFrequency" />
                        <ExcelColumn label="Avg. Rate of Healing" value="avgRateOfHealing" />
                        
                    </ExcelSheet>
                </ExcelFile> */}
                            <label>
                                <CSVLink
                                    onClick={() => csvLink.current.link.download = getFileName()}
                                    headers={fileHeaders}
                                    data={treatmentReportCsv?.treatmentOutcome}
                                    fileName={getFileName}
                                    target="_blank"
                                    ref={csvLink}
                                >
                                    Download as CSV
                                </CSVLink>
                            </label>
                        </span>
                    </div>
                </>
            }
            {treatmentWorklistData && treatmentWorklistData?.treatmentOutcome &&

                <div className="col-12">
                    <DataTable
                        columns={columns}
                        data={treatmentWorklistData && treatmentWorklistData?.treatmentOutcome}
                        sorting={true}
                        getSelectedRowData={getSelectedRowData}
                        setSelectedRows={selectRowCount}
                        onSort={handleSort}
                        detailView={true}
                        deleteDataList={deleteDataList}
                        showFixedColumn={false}
                        workListView={true}
                        reportView={true}
                        removeCursor={true}
                        sortType={(sortType)?sortType:''}
                    />
                </div>
            }
            {(Array.isArray(treatmentWorklistData.treatmentOutcome)) && (treatmentWorklistData.treatmentOutcome.length > 1) && <Pagination
                activePage={activePage}
                itemsCountPerPage={treatmentWorklistData?.metadata ? treatmentWorklistData?.metadata?.limit : 50}
                totalItemsCount={treatmentWorklistData?.metadata ? treatmentWorklistData?.metadata?.total : 0}
                getActivePage={getActivePage}
                reportView={true}
            />
            }

        </>
    )

}
TreatmentReportData.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}
TreatmentReportData = connect()(withRouter(TreatmentReportData));
