import { Service } from '../service';
import { createSlice } from '@reduxjs/toolkit';
import {
 SUCCESS_STATUS,
  ANNOUNCEMENT,
  ERROR_STATUS,
 DELETE_ANNOUNCEMENTS,
 ANNOUNCEMENT_SALES_OFFICE_CHECK
} from '../constants';
import { SimplePersistence } from '../utils';
import { setError, setLoading, deleteAsync } from './appSlice';
import { constructEndpoint } from '../utils/commonHelper'
import { userNotificationAsync } from'./userSlice';
import { format } from 'date-fns';
const { request } = Service;

const storage = new SimplePersistence();

export const announcementSlice = createSlice({
  name: 'announcement',
  initialState: {
    announcements: {},
    userFacilities: {},
    detailViewData: {}, // individual user details
    appdownloadinvite: {},
    uploadStatus: null, // Upload success or failed?
    userRole: {},
    resMsgState: '',
    editState: false,
  },
  reducers: {
    setUserDetail: (state, action) => {
      return {
        ...state, ...action.payload
      }
    },
    setLists: (state, action) => {
      state.announcements = action.payload;
    },
    resetLists: (state, action) => {
      state.announcements = {}
    },
    /* Data for detailed user view */
    setDetailViewData: (state, action) => {
      state.detailViewData = action.payload
    },
    setResMsgState: (state, action) => {
      state.resMsgState = action.payload;
    },
    setEditState: (state, action) => {
      state.editState = action.payload;
    },
     
  }
});

export const {
  setUserDetail,
  setLists,
  resetLists,
  setDetailViewData,
  setResMsgState,
  setEditState
} = announcementSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(loginAsync(data))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// create Announcement
export const createAnnouncementAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(ANNOUNCEMENT, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setResMsgState(data.status))
      const ann_payload={
        url:'/announcements',
        queryParam:{ limit: '50', offSet: '0'},
        method:'GET'
      }
      await dispatch(userNotificationAsync())
      await dispatch(setLoading(true));
      await dispatch(getCallBackListAsync(ann_payload))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setResMsgState('error'))
    }
  }
  catch (error) {
    dispatch(setResMsgState('error'))
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// get All List Details
export const getCallBackListAsync = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.announcements.map((value)=> {
        value['createdDate'] = (value['createdDate'])?format(new Date( value['createdDate']), 'MMMM dd,yyyy'):'';
        value['salesOffice']=(value['salesOffice'] && value['salesOffice']!=='')? ANNOUNCEMENT_SALES_OFFICE_CHECK[value?.salesOffice?.toLowerCase()]:'';
      })
      dispatch(setLists(data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setLists(obj))
    if(obj?.response?.status === 405 || obj?.response?.status === 404){
      window.location='/unAuthorized';
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Announcement details by Id  
export const announcementDetailsAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${ANNOUNCEMENT}/${payload}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setDetailViewData(data.data))
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))

  }
  finally {
    dispatch(setLoading(false));
  }
}

export const announcementUpdateAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${ANNOUNCEMENT}/${payload.id}`, {
      method: 'PUT',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(announcementDetailsAsync(payload.id))
      dispatch(setEditState(data.status))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    dispatch(setEditState('error'))
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// delete announcement list
export const deleteUserListAsync = (payload) => async dispatch => {
  try {
   
    dispatch(setLoading(true));
    payload.map(async (value) => {
     await dispatch(deleteAsync(DELETE_ANNOUNCEMENTS, value.id))
    })
    await dispatch(setLoading(true));
    await dispatch(userNotificationAsync())
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
export default announcementSlice.reducer;
