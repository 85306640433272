import React, { useState, useEffect, useRef } from 'react'
import styles from './assign.module.scss'

export const AddOptionDropDown = ({ addNewQuestionOption, type, parentIndex, addOption, clsName }) => {
    const dropRef = useRef();
    const [addMoreOption, setAddMoreOption] = useState(false)
    const addNewQuestionOptionSelect = (value, type, parentIndex) => {
        setAddMoreOption(false)
        addNewQuestionOption(value, type, parentIndex)
    }
    const showAddOption = () => {
        setAddMoreOption(true)
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOut);
        return () => document.removeEventListener('mousedown', handleClickOut);
    });

    const handleClickOut = (e) => {
        if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
            setAddMoreOption(false);
        }
    };
    return (
        <div className={"col-4 p-0"}>
            <button type="button" className={`btn ml-5 w-auto ${styles.btn_custom_branch}`} onClick={() => showAddOption()} ref={dropRef}>
                Add New
            </button>
            {addMoreOption && <div className={`dropdown-menu show ${styles.dropdown_align_add}`} ref={dropRef}>
                {
                    addOption?.map(
                        (value, index) => value.visible !== 0 && <p

                            className={`${styles.dropdown_heading_add} ${clsName} addAnsw`}
                            key={index}
                            onClick={() => addNewQuestionOptionSelect(value, type, parentIndex)}>
                            <span>{value.value}</span>

                        </p>
                    )
                }
            </div>}
        </div>
    )
}
