import { Service } from '../service';
import { createSlice } from '@reduxjs/toolkit';
import { SUCCESS_STATUS, DELETE_FORMULARIES, OPTION_TYPES, MATERIALS, FORMULARY, ASSIGNED_FORMULARY, LIST_VIEW_LIMIT } from '../constants';
import { setError, setLoading, deleteAsync, setAccessError } from './appSlice';
import { constructEndpoint } from '../utils/commonHelper'
import { getCallBackAssignedFormularyListAsync } from './customerSlice';
import { List } from '../utils';
import { format } from "date-fns";

const { request } = Service;

export const formularySlice = createSlice({
  name: 'formulary',
  initialState: {
    formularies: {}, // Formulary list,
    division: [], // Dropdown
    materials: [], // Dropdown
    newPayload: {}, // For storing request payload 
    duplicateData: {}, // For storing duplicate data
    addedApiMaterials: {},
    searchKey: '', // For handing search and page navigation
    sortType: null
  },
  reducers: {
    /* FORMULARY SECTION  */
    setFormularies: (state, action) => {
      state.formularies = action.payload;
    },
    setDivision: (state, action) => {
      state.division = action.payload
    },
    setMaterials: (state, action) => {
      state.materials = action.payload
    },
    setNewPayload: (state, action) => {
      state.newPayload = action.payload
    },
    setDuplicateData: (state, action) => {
      state.duplicateData = action.payload
    },
    setAddedApiMaterials: (state, action) => {
      state.addedApiMaterials = action.payload
    },
    setSearchKey: (state, action) => {
      state.searchKey = action.payload
    },
    setSortType: (state, action) => {
      state.sortType = action.payload
    }
  }
});

export const {
  setFormularies,
  setDivision,
  setMaterials,
  setNewPayload,
  setDuplicateData,
  setAddedApiMaterials,
  setSearchKey,
  setSortType
} = formularySlice.actions;

/* Formulary section */
export const getFormularyListAsync = (payload, history) => async dispatch => {
  const url = payload.url;
  let queryParam = payload.queryParam && payload?.queryParam
  if (payload.sortType) {
    const temp = { ...queryParam }
    temp['sort'] = payload.sortType
    queryParam = { ...temp }
  }
  if (!queryParam['search'] && payload.search !== '') {
    const temp = { ...queryParam }
    temp['search'] = payload.search
    queryParam = { ...temp }
  }
  const method = payload.method
  
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method
    });
    if (data && data.status === SUCCESS_STATUS) {

      data.data.formulary.map((value)=>{ // added for datecreate & update
        value['dateCreated'] = value.dateCreated
          ? format(new Date(value.dateCreated), "MMMM dd, yyyy")
          : "";
        value['dateUpdated'] = value.dateUpdated
          ? format(new Date(value.dateUpdated), "MMMM dd, yyyy")
          : "";
      })// added for datecreate & update

      dispatch(payload.setMethod(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setFormularies(obj))
    if(obj?.response?.status === 405 || obj?.response?.status === 404 ){
      history.push('/unAuthorized')
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

// delete formulary(s)
export const deleteFormulariesAsync = (data, getFormularyList) => async dispatch => {
  try {
    dispatch(setLoading(true));
    data.map((value) => {
      dispatch(deleteAsync(DELETE_FORMULARIES, value.id))
    })
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    setTimeout(() => {
      getFormularyList();
    }, 500)
    dispatch(setLoading(false));
  }
}

export const optionTypeAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(OPTION_TYPES + '/' + payload, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      if (payload === 'materialdivision') {
        dispatch(setDivision(data.data.options))
      }
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Formulary options

export const formularyOptionsAsync = (payload, queryParam) => async dispatch => {
  
  try {
    if(payload.division){
     let chkdivision = parseInt(payload.division);
      if(chkdivision){
        dispatch(setLoading(true));
        const data = await request(MATERIALS + '?limit=' + queryParam.limit, {
        method: 'POST',
        body: JSON.stringify(payload)
        });
        if (data && data.status === SUCCESS_STATUS) {
          dispatch(setMaterials(data.data))
        }
      }
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

/* Formulary section */
export const getMaterialsListAsync = (payload, history) => async (dispatch, getState) => {
  const url = payload.url;
  let queryParam = payload.queryParam && payload?.queryParam
  if (payload.sortType) {
    const temp = { ...queryParam }
    temp['sort'] = payload.sortType
    queryParam = { ...temp }
  }
  const method = payload.method
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url, queryParam), {
      method: method,
      body: JSON.stringify(payload.body)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(payload.setMethod(data.data))
    }
  }
  
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setFormularies(obj))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Create formulary

export const createFormularyAsync = (payload, history, setCreateFormulary) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(FORMULARY, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      if (setCreateFormulary) {
        setCreateFormulary(false)
      }
      if (payload.setEditMode) {
        payload.setEditMode(false)
      }
      if (payload.setDuplicate) {
        payload.setDuplicate(false)
      }
      List(ASSIGNED_FORMULARY + '/' + data.data, 'Get', LIST_VIEW_LIMIT, 0, dispatch, getCallBackAssignedFormularyListAsync);
      history.push('/admin' + FORMULARY + '/' + data.data)
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Update formulary

export const updateFormularyAsync = (payload, history, setEditMode) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(FORMULARY + '/' + payload.id, {
      method: 'PUT',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      setEditMode(false)
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }    
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Duplicate

export const duplicateAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(FORMULARY + '/' + payload.id + '/duplicate', {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setDuplicateData(data.data))
      payload.setDuplicate(true)
      payload.setEditMode(true)
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Get formulary list

export const getFormularyListByIdAsync = (payload,history) => async dispatch => {
  dispatch(setAddedApiMaterials({}))
  try {
    dispatch(setLoading(true));
    const data = await request(FORMULARY + '/' + payload + '?limit=80000', {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setAddedApiMaterials(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
    if(obj?.response?.status === 405 || obj?.response?.status === 404 ){
      history.push('/unAuthorized')
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}


export default formularySlice.reducer;