import React, { useState, useLayoutEffect, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Header } from '../../secondary/Header';
import editIcon from '../../../static/images/edit-icon-blue.png';
import userLogo from '../../../static/images/picture-icon-default.png';
import { Button, Form, TextArea, TextField } from '../../primary';
import { PictureIcon, Modal } from '../../secondary';
import style from './userProfileDetails.module.scss';
import { UPLOAD_USER_PICTURE, USER_PROFILE_PHDR, USER_PROFILE_PHDR_LIMIT, PASSWORD_PHDR, CONFIRM_PASSWORD_REQUIRED,TERMS_URL,PRIVACY_POLICY_URL, CURRENT_PASSWORD_REQUIRED, NEW_PASSWORD_REQUIRED } from '../../../constants';
import { checkCaringTextarea, checkResetNewPassword, commonHintCheck,ServiceError } from '../../../utils';
import { logout, updatePasswordAsync, userProfileUpdateAsync, updateUserDetailsAsync, setResMsgState, userRole, getUserRoleAsync, optionRoleAsync,setEditState,setPassUpdateState } from '../../../app/userSlice';

import { setOtherPageFacilityCheck, setLastVal, setFacilityAndCustomerNumLastVal} from '../../../app/patientSlice';
//setWoundReportPayload
import { useDispatch, useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setQualityReportPayload, setTreatmentReportPayload, setWoundReportPayload } from '../../../app/woundReportSlice';
import { setAccessError, setError } from '../../../app/appSlice';

export let UserProfileDetails = ({ history }) => {

  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.user.userDetails);
  const listUserRoleData = useSelector(state => state.user.userRole);
  const roleListoption = useSelector(state => state.user.roleListoption)
  
  const { register, handleSubmit, errors, reset, watch } = useForm();
  const [editable, setEditable] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [previewImg, setPreviewImg] = useState(null);
  const [buttonState, setButtonState] = useState(true);
  let [textAreaContent, setTextAreaContent] = useState(userDetails?.about ? userDetails.about:'');
  const [updateStatus, setUpdateStatus] = useState(false);
  const [minLength, setMinLength] = useState(false);
  const [oneSpecialChar, setOneSpecialChar] = useState(false);
  const [oneNum, setOneNum] = useState(false);
  const [oneUCase, setOneUCase] = useState(false);
  const [atleastOne, setAtleastOne] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(false);
  const passUpdateState = useSelector(state => state.user.passUpdateState)
  const modalBoxClose = () => {
    setUpdatePassword(false);
    setMinLength(false);
    setOneUCase(false);
    setOneSpecialChar(false);
    setOneNum(false);
    setAtleastOne(false);
    reset();
  }
  const updatePasswordModal = () => {
    setUpdatePassword(true);
  }
  const editMode = () => {
    setEditable(true);
    setTextAreaContent(userDetails?.about?userDetails?.about:'');
    reset();
  }
  const viewMode = () => {
    setEditable(false);
    setButtonState(true);
    setPreviewImg(null);
    setTextAreaContent(userDetails?.about?userDetails?.about:'');
    reset();
  }
  const desc_limit = USER_PROFILE_PHDR_LIMIT;
  const setDescriptionContent = React.useCallback(
    text => {
      setTextAreaContent(text.slice(0, desc_limit));
    },
    [desc_limit, setTextAreaContent]
  );
  const doLogout = () => {
    dispatch(setResMsgState({}));
    dispatch(setOtherPageFacilityCheck(''));
    dispatch(setWoundReportPayload({}))
    dispatch(setTreatmentReportPayload({}))
    dispatch(setQualityReportPayload({}))    
    dispatch(setLastVal({}))
    dispatch(setFacilityAndCustomerNumLastVal({}))
    dispatch(setError(''))
    dispatch(setAccessError(null))    
    dispatch(logout());
  }
  useLayoutEffect(() => {
    dispatch(updateUserDetailsAsync());

  }, [editable])
  
  const errorMessage = ServiceError();
  const doSubmit = (data,passUpdateState) => {
  
    const payload = {
      "id": userDetails.id,
      "currentpassword": data.currentPassword,
      "newpassword": data.newPassword
    } 
     if(minLength && oneSpecialChar && oneNum  && oneUCase){
            dispatch(updatePasswordAsync(payload, history)); 
    }
    
      if (userDetails.status === 'success') {
        reset();
        setUpdateStatus(true);
      }
  }

  useEffect(() => {
   if(passUpdateState&&passUpdateState==='success'){
       reset();
       dispatch(setPassUpdateState(''))
       setUpdateStatus(true);
       setUpdatePassword(false);
       modalBoxClose();
       setCheckUpdate(true);
      
   }
  }, [passUpdateState])
//editState
   const updateProfile = (updates) => {
    let finalPic;
    if (previewImg) {
      finalPic = previewImg.replace('data:image/png;base64,', '')
      finalPic = finalPic.replace('data:image/jpeg;base64,', '')
      finalPic = finalPic.replace('data:image/jpg;base64,', '')
      finalPic = finalPic.replace('data:image/svg+xml;base64,', '')
    }
    const payload = {
      "id": userDetails.id,
      "profilePicture": finalPic ? finalPic : updates.userPicture || updates.userPicture === "" ? null : finalPic,
      "about": updates.aboutUs
    }
    dispatch(userProfileUpdateAsync(payload))
    viewMode();
    reset()

  }
  useEffect(() => {
    if (userDetails?.profilePicture) {
      setButtonState(false)
      setPreviewImg(userDetails?.profilePicture)
    }
  }, [userDetails])
  useEffect(() => {
    dispatch(optionRoleAsync());
    dispatch(getUserRoleAsync());
    dispatch(setPassUpdateState(''))
    setCheckUpdate(false);
  }, [dispatch])

  const passwordCheck = watch("newPassword");
  const passwordCondition = () => {
    commonHintCheck(passwordCheck, setMinLength, setOneUCase, setOneSpecialChar, setOneNum)
    if (checkUpdate) {
      if (!minLength || !oneSpecialChar || !oneNum || !oneUCase) {
        setAtleastOne(true)
      } else {
        setAtleastOne(false)
      }
    }
  }
  const upDateButton = () => {
    if (!minLength || !oneSpecialChar || !oneNum || !oneUCase) {
      setAtleastOne(true)
    } else {
      setAtleastOne(false)
    }
    // setCheckUpdate(true);
  }

  return <div>
    <div className={`${style.back_btn} ${style.reponse_top}`}>
      <Header heading={'User Profile'} />
    </div>
    <div className={`${style.tab_pane} mt-3`}>
      <div className={`d-flex justify-content-between ${style.titlte_wrapper}`}>
        <div className={style.heading}>Hi {userDetails.firstName}, <span className={style.sub_heading}>hope you are having a great day!</span> </div>
        <div>
          {
            !editable && <div id="editBtn" className={style.edit_icon} onClick={() => editMode()}><img src={editIcon} alt="icon" className={`pr-2`} />Edit</div>
          }
        </div>
      </div>
      <div className="p-4 row">
        <div className={`col-9`}>
          {
            !editable ? <div>
              <div className="row mb-3 pt-1">
                <label className={`col-3 ${style.label_cutom}`}>Profile Picture:</label>
                <div className={`col-9`}>
                  <img src={userDetails.profilePicture ? userDetails.profilePicture : userLogo} alt="image" className={`mb-3 ${style.user_picture}`} />
                  <div className={`${style.about_container} ${style.data_color}`}>{userDetails.about ? userDetails.about : USER_PROFILE_PHDR}</div>
                </div>
              </div>
              <div className="row mb-3">
                <label className={`col-3 mb-0 ${style.label_cutom}`}>Customer Number:</label>
                <div className={`col-9 ${style.data_color}`}>{userDetails.customerNumber}</div>
              </div>
              <div className="row mb-2">
                <label className={`col-3 ${style.label_cutom}`}>Role:</label>
                {
                  roleListoption && roleListoption?.map((e) => { // NOSONAR
                    let optionsValue = "";
                    if (e.key === userDetails?.role) { // NOSONAR
                      optionsValue = e.value
                      return <div className={`col-9 ${style.data_color}`}>
                        {optionsValue}
                      </div>
                    }
                  })// NOSONAR
                }
              </div>
            </div> :
              <Modal
                modalOpen={'modalOpen'}
                modalClose={viewMode}
                modalSize={style.modalSize}
                custom_heading={style.custom_heading}
              >
                <div className={style.parent}>
                  <button type="button" className={`close ${style.close_icon}`} onClick={() => viewMode()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className={style.edit_container}>
                    <div className={`mt-3 pt-4 ${style.title}`}>Edit</div>
                    <Form className={`pt-3`} onSubmit={handleSubmit(updateProfile)}>
                      <div className="row align-items-center mb-4">
                        <label className={`col-5 mb-0 ${style.label_cutom}`}>Profile Picture:</label>
                        <div className={`col-7 pl-0 ${style.data_color}`}>
                          <PictureIcon
                            classes={{
                              pic_wrapper: '',
                              contr_wrapper: `mt-0 ${style.icon_align}`
                            }}
                            previewImg={previewImg}
                            setPreviewImg={setPreviewImg}
                            buttonState={buttonState}
                            setButtonState={setButtonState}
                            id={'userPicture'}
                            name={"userPicture"}
                            validator={register}
                            browseLbl={UPLOAD_USER_PICTURE}
                          />
                        </div>
                      </div>
                      <div className={`${style.about_container} pt-1 mb-4`}>
                        <TextArea
                          classes={{
                            column: ` ${style.col_spec}`,
                            inputClassName: `${style.textarea_box} w-100 p-1`,
                            inputColumn: `col-12 p-0`
                          }}
                          rows={5}
                          placeholder={USER_PROFILE_PHDR}
                          id={'aboutUs'}
                          name={'aboutUs'}
                          validator={register(checkCaringTextarea)}
                          errors={errors}
                          value={textAreaContent}
                          charLimit={desc_limit}
                          onChange={event => setDescriptionContent(event.target.value)}
                          maxlength={5}
                        />
                      </div>
                      <div className={`w-100 text-center pt-3`}>
                        <div className="pt-4">
                          <Button
                            id={"updateSumit"}
                            type={"submit"}
                            value={"Save"}
                            classes={{ className: `btn ${style.btn_save}` }}
                          />
                          <Button
                            id={"reset-btn"}
                            type={"button"}
                            value={"Cancel"}
                            classes={{ className: `btn ${style.btn_cancel}` }}
                            onClick={() => viewMode()}
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Modal>
          }
        {
         updateStatus && checkUpdate &&
            <Modal
              modalOpen={'modalOpen'}
              modalClose={viewMode}
              modalSize={style.success_modal}
            >
              <div className={`${style.parent} ${style.modal_contr}`}>
                <div className={style.status_heading}>Success!</div>
                <div className={style.msg_content}>Your password has been changed</div>
                <div className={`text-center mt-2`}>
                  <Button
                    id={"loginBtn"}
                    type={"button"}
                    value={"Continue to Login"}
                    classes={{ className: `btn mt-4 pl-4 pr-4 ${style.continue_btn}` }}
                    onClick={() => doLogout()}
                  />
                </div>
              </div>
            </Modal>
            } 
     </div>
        <div className={`col-3`}>
          <div className={style.option_title}>Your Account</div>
          <ul className={`pl-0 pr-0 ${style.option_container}`}>
           {userDetails?.isSSOUser!==1&&<>
            <li>
              <i>Manage 4 PIN</i>
            </li>
            <li>
              <i id="updatePassword" onClick={() => updatePasswordModal()}>Password Update</i>
            </li></>
            }
            <li>
              <a href={TERMS_URL} id="Terms" target="_blank">Terms of Services</a>
            </li>
            <li>
              <a href={PRIVACY_POLICY_URL} id="privacyPolicy" target="_blank" >Privacy Policy</a>
            </li>
            
          </ul>
          <div className={`pl-0 pr-0 ${style.btn_container}`}>
            <Button
              id={"signOut"}
              type={'button'}
              value={'Sign Out'}
              classes={{ className: `btn w-100 ${style.btn_save}` }}
              onClick={() => doLogout()}
            />
          </div>
        </div>
      </div>
    </div>
    {
      updatePassword && <Modal
        modalOpen={'modalOpen'}
        modalClose={modalBoxClose}
        modalSize={style.modalSize}
        custom_heading={style.custom_heading}
      >
        <div className={style.parent}>
          <button type="button" className={`close ${style.close_icon}`} onClick={() => modalBoxClose()}>&times;</button>
          <div className={style.password_container}>
            <div className={`mt-3 pt-4 ${style.title}`}>Update Password</div>
            <Form className={`pt-3`} onSubmit={handleSubmit(doSubmit)}>
              {/* Current Password  */}
            <TextField
                classes={{
                  column: 'form-group',
                  inputClassName: `form-control mb-1 ${style.radius}`,
                  inputColumn: 'col-12 p-0'
                }}
                label={"Current Password *"}
                type={"password"}
                name={"currentPassword"}
                id={"currentPassword"}
                placeholder={PASSWORD_PHDR}
                validator={register({
                  required: CURRENT_PASSWORD_REQUIRED,
                    })}
                errors={errors}
                customError={passUpdateState&&passUpdateState.errorMessage?passUpdateState.errorMessage : ""}
                onKeyUp={() => dispatch(setPassUpdateState(''))}
              />
              <TextField
                classes={{
                  column: 'form-group',
                  inputClassName: `form-control mb-1 ${style.radius}`,
                  inputColumn: 'col-12 p-0'
                }}
                label={"New Password *"}
                type={"password"}
                name={"newPassword"}
                id={"userNewPassword"}
                placeholder={PASSWORD_PHDR}
                //validator={register(checkResetNewPassword)}
                validator={register({checkResetNewPassword,
                  required: NEW_PASSWORD_REQUIRED,
                  validate: (value) => value !== watch('currentPassword') || "Your new password must be different from the current password"
                })}
                errors={errors}
                hints={true}
                onKeyUp={() => passwordCondition()}
                minLength={minLength}
                oneSpecialChar={oneSpecialChar}
                oneNum={oneNum}
                oneUCase={oneUCase}
                atleastOne={atleastOne}
              />
              <TextField
                classes={{
                  column: `form-group `,
                  inputClassName: `form-control mb-1 ${style.radius}`,
                  inputColumn: 'col-12 p-0'
                }}
                label={"Re-type new password *"}
                type={"password"}
                name={"confirmPassword"}
                id={"confirmPassword"}
                placeholder={PASSWORD_PHDR}
                validator={register({
                  required: CONFIRM_PASSWORD_REQUIRED,
                  validate: (value) => value === watch('newPassword') || "Password does not match"
                })}
                errors={errors}
              />
              <div className="form-group pt-3">
                <Button
                  id={"reset-btn"}
                  type={"submit"}
                  value={"Update Password"}
                  classes={{ className: `btn btn-block` }}
                  onClick={upDateButton}
                />
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    }
  </div>
}

UserProfileDetails.propTypes = {
  history: PropTypes.object
}

UserProfileDetails = connect()(withRouter(UserProfileDetails))
