import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  SAVE_BTN,
  SUBMIT_ID,
  EDIT,
  SUCCESS_STATUS,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  ERROR_STATUS,
} from "../../../constants";
import editIcon from "../../../static/images/edit-icon-blue.png";
import styles from "./specificationsView.module.scss";
import PlusIcon from "../../../static/images/plus-icon_white.svg";
import closeIcon from "../../../static/images/close-icon-gray.png";
import { Button, Form, TextField, AccessErrorModal } from "../../primary";
import {
  getProductSpecificationAsync,
  productOptionTypeAsync,
  updateProductSpecificationAsync,
  setProductEditStatus,
} from "../../../app/productSlice";
import Loader from '../../../static/images/spinner.svg';	
export function SpecificationView({ productData }) {
  const specificationData = useSelector((state) => state.product.specification);
  const updateStatus = useSelector((state) => state.product.productEditStatus);
  const dispatch = useDispatch();
  const { productId } = useParams();
  useEffect(() => {
    if (productId) {
      dispatch(getProductSpecificationAsync(productId));
    }
  }, [productId, dispatch]);
  const { register, handleSubmit, watch, errors, reset } = useForm({
    mode: "Submit",
    shouldFocusError: true,
  });
  const imagePlusIcon = (
    <img src={PlusIcon} className={`${styles.plusimg}`} alt="plus" />
  );
  const [editable, setEditable] = useState(false);
  /* Function to toggle edit */
  const handleEdit = () => {
    setEditable(!editable);
    if (editable) {
      setInputList(specificationData);
    }
    reset({});
  };
  const attributeOptions = useSelector(
    (state) => state.product.materialAttribute
  );
  const clone_json = {
    sKUSpecAttributeId: "0",
    sKUSpecAttribute: "",
    sKUSpecAttributeValue: ["null"],
  };
  const [inputList, setInputList] = useState([]);
  // handle click event of the Add button
  const handleAddAttributeClick = () => {
    if (inputList && Array.isArray(inputList)) {
      setInputList([...inputList, clone_json]);
    }
  };
  const handleAddValueClick = (e, i, items) => {
    e.preventDefault();
    let inputListObject = [...inputList];
    let selectSpeciRow = { ...inputListObject[i] };
    let addTxt = [...selectSpeciRow.sKUSpecAttributeValue, ...["null"]];
    selectSpeciRow.sKUSpecAttributeValue = addTxt;
    inputListObject.splice(i, 1, selectSpeciRow);
    setInputList(inputListObject);
  };
  const doAttributeAdd = (data) => {
    const payload = {
      specification: data.specification === undefined ? [] : data.specification,
    };
    dispatch(updateProductSpecificationAsync(payload, productId));
  };
  // handle click event of the Remove button
  const handleRemoveAttributeClick = (index, j, items) => {
    let list = [...inputList].slice();
    if (
      items &&
      Array.isArray(items?.sKUSpecAttributeValue) &&
      items?.sKUSpecAttributeValue.length === 1
    ) {
      list.splice(index, 1);
      setInputList(list);
    } else if (list[index]?.sKUSpecAttributeValue) {
      let inputListObject = [...inputList];
      let selectRemAttr = [...inputList[index].sKUSpecAttributeValue];
      let againAdd = { ...inputList[index] };
      selectRemAttr.splice(j, 1);
      againAdd.sKUSpecAttributeValue = selectRemAttr;
      inputListObject.splice(index, 1, againAdd);
      setInputList(inputListObject);
    }
    reset();
  };
  const getAttrVals = (value, index, j, items) => {
    let inputListObject = [...inputList];
    let selectAddAttr = [...inputList[index].sKUSpecAttributeValue];
    //let inputListObjectAttr = [...inputList[index].sKUSpecAttributeValue]
    let againAdd = { ...inputList[index] };
    selectAddAttr[j] = value !== "" ? value : ``;
    againAdd.sKUSpecAttributeValue = selectAddAttr;
    inputListObject.splice(index, 1, againAdd);
    setInputList(inputListObject);
  };
  // double click
  const handleListItems = (index) => {
    let elementShow = document.getElementById("dataListShow" + index);
    let elementHide = document.getElementById("dataListHide" + index);
    if (ReactDOM.findDOMNode(elementShow)) {
      ReactDOM.findDOMNode(elementShow).classList.remove("d-none");
      ReactDOM.findDOMNode(elementShow).classList.add("d-show");
      ReactDOM.findDOMNode(elementHide).classList.add("d-none");
    }
  };
  //chkOldAttributes
  const chkOldAttribute = (index, e) => {
    const ele_val = e && e.target && e.target.value ? e.target.value : "";
    const sKUSpecAttributeId = document.getElementById(
      "sKUSpecAttributeId" + index
    );
    if (ReactDOM.findDOMNode(sKUSpecAttributeId)) {
      if (ele_val) {
        let old_val = sKUSpecAttributeId.getAttribute("old_value");
        if (old_val) {
          old_val = JSON.parse(old_val);
          if (old_val.sKUSpecAttribute !== ele_val) {
            ReactDOM.findDOMNode(sKUSpecAttributeId).value = 0;
          } else {
            ReactDOM.findDOMNode(sKUSpecAttributeId).value =
              old_val.sKUSpecAttributeId;
          }
        }
        let inputListObject = [...inputList];
        let againAdd = { ...inputList[index] };
        againAdd.sKUSpecAttribute = ele_val !== "" ? ele_val : ``;
        inputListObject.splice(index, 1, againAdd);
        setInputList(inputListObject);
      }
    }
  };
  useEffect(() => {
    setInputList(specificationData);
    dispatch(productOptionTypeAsync("materialattribute"));
  }, [specificationData, dispatch]);
  useEffect(() => {
    if (updateStatus === "success" || updateStatus === "error") {
      reset();
      setEditable(!editable);
      setTimeout(() => {
        dispatch(setProductEditStatus({}));
      }, 5000);
    }
  }, [updateStatus]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(setProductEditStatus({}));
    }, 5000);
  }, [dispatch]);
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
  return (
    <>
      <AccessErrorModal />
      <div className={`${styles.tab_content_top}`}>
        <h2 className={`${styles.tab_content_hdg}`}>
          {productData?.productFamilyDesc
            ? productData?.productFamilyDesc
            : productData?.materialDescription}
         {tinyDelay&&!editable && (
            <span
              className={`float-sm-right ${styles.tab_edit}`}
              onClick={handleEdit}
            >
              <img src={editIcon} alt="edit" />
              {EDIT}
            </span>
          )}
          {editable && (
            <Button
              id={"addAttri"}
              classes={{ className: `float-sm-right btn  ${styles.btn_save}` }}
              onClick={handleAddAttributeClick}
            >
              {imagePlusIcon}
              {" New Attribute"}
            </Button>
          )}
        </h2>
      </div>
      {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
      <div className={`col-md-12 ${styles.single_product_block}`}>
        <div className="row">
          <div className={`p-0 ${styles.patient_content}`}>
            {updateStatus === SUCCESS_STATUS && (
              <div
                className={`alert alert-success d-inline ${styles.form_alert}`}
              >
                {UPDATE_SUCCESS}
              </div>
            )}
            {/* Edit failure */}
            {updateStatus === ERROR_STATUS && (
              <div className={`alert alert-danger ${styles.form_alert}`}>
                {UPDATE_FAILURE}
              </div>
            )}
            <div className={`col-12`}>
              {Array.isArray(inputList) && inputList.length === 0 && (
                <div className={`${styles.content_center}`}>
                  There is no attributes available for this product.
                </div>
              )}
            </div>
            {!editable ? (
              <>
                {/* Material Number */}
                {specificationData &&
                  specificationData.length > 0 &&
                  Array.isArray(specificationData) &&
                  specificationData.map((items, i) => {
                    const specificationList = items.sKUSpecAttributeValue.map(
                      (item) => <h6>{item}</h6>
                    );
                    return (
                      <>
                        <div className={`col-10 ${styles.col_spec}`}>
                          <label
                            className={`col-5 col-form-label ${styles.label_spec}`}
                          >
                            {items.sKUSpecAttribute}:{" "}
                          </label>

                          <div className="col-5 p-0" key={i}>
                            <span
                              className={`col-form-label ${styles.content_spec}`}
                            >
                              {specificationList}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>
            ) : (
              <div className="col-12 pl-3 pr-0 pt-0 pb-4">
                <Form formChanges={true} onSubmit={handleSubmit(doAttributeAdd)}>
                  {inputList &&
                    inputList.length > 0 &&
                    Array.isArray(inputList) &&
                    inputList.map((items, i) => {
                      return (
                        <>
                          <div className={`row mb-3`}>
                            <input
                              type="hidden"
                              name={`specification[${i}][sKUSpecAttributeId]`}
                              id={`sKUSpecAttributeId${i}`}
                              ref={register}
                              style={{ visibility: "hidden" }}
                              old_value={JSON.stringify(items)}
                              defaultValue={
                                items && items.sKUSpecAttributeId
                                  ? items.sKUSpecAttributeId
                                  : ""
                              }
                            />
                            {/* <div className={`col-12 d-flex`}> */}
                            <div className={`col-4`}>
                              {items && items.sKUSpecAttributeId !== "0" ? (
                                <>
                                  <label
                                    className={`col-form-label ${styles.label_spec} dataListhide`}
                                    id={`dataListHide${i}`}
                                    onDoubleClick={(e) => handleListItems(i)}
                                  >
                                    {items.sKUSpecAttribute}
                                  </label>
                                  <input
                                    type="text"
                                    name={`specification[${i}][sKUSpecAttribute]`}
                                    id={`dataListShow${i}`}
                                    className={`col-11  ${styles.dataListInput} ${styles.input_field} ${styles.input_data_list} d-none`}
                                    list={`sKUSpecAttribute${i}`}
                                    defaultValue={
                                      items && items.sKUSpecAttribute
                                        ? items.sKUSpecAttribute
                                        : ""
                                    }
                                    placeholder={"Attribute Name"}
                                    autoComplete={"off"}
                                    ref={register({
                                      validate: (value) => {
                                        if (!value) {
                                          return "Enter attribute name";
                                        } else if (
                                          inputList &&
                                          inputList.length > 0
                                        ) {
                                          let chckSeq = 0;
                                          inputList.forEach((item, keys) => {
                                            if (
                                              i !== keys &&
                                              watch(
                                                `specification[${keys}][sKUSpecAttribute]`
                                              ) === value
                                            ) {
                                              chckSeq = 1;
                                            }
                                          });
                                          if (chckSeq === 1) {
                                            return 'This attribute name has been taken already';
                                          }
                                        }
                                      },
                                    })}
                                    onChange={(e) => chkOldAttribute(i, e)}
                                    onBlur={(e) => chkOldAttribute(i, e)}
                                    onClick={(e) => chkOldAttribute(i, e)}
                                  />
                                  <label
                                    className={`col-form-label ${styles.label_spec}`}
                                  >
                                    :{" "}
                                  </label>
                                  <datalist
                                    id={`sKUSpecAttribute${i}`}
                                    className={`dataList`}
                                  >
                                    {/* <select className={`${styles.dataListSelect}`}> */}
                                    {attributeOptions &&
                                      Array.isArray(attributeOptions) &&
                                      attributeOptions.map((item, j) => {
                                        return (
                                          <option
                                            className={`${styles.dataListOption}`}
                                            value={item.value}
                                            onChange={(e) =>
                                              chkOldAttribute(i, e)
                                            }
                                            onClick={(e) =>
                                              chkOldAttribute(i, e)
                                            }
                                          >
                                            {item.value}
                                          </option>
                                        );
                                      })}
                                    {/* </select> */}
                                  </datalist>
                                  {errors &&
                                    errors["specification"] &&
                                    errors["specification"][i] &&
                                    errors["specification"][i][
                                      "sKUSpecAttribute"
                                    ] &&
                                    errors["specification"][i][
                                      "sKUSpecAttribute"
                                    ].message && (
                                      <span className={styles.error_message}>
                                        {
                                          errors["specification"][i][
                                            "sKUSpecAttribute"
                                          ].message
                                        }
                                      </span>
                                    )}
                                </>
                              ) : (
                                <>
                                  <input
                                    type="text"
                                    className={`col-11  ${
                                      styles.dataListInput
                                    } ${styles.input_field} ${
                                      styles.input_data_list
                                    } 
                                                        ${
                                                          errors &&
                                                          errors[
                                                            "specification"
                                                          ] &&
                                                          errors[
                                                            "specification"
                                                          ][i] &&
                                                          errors[
                                                            "specification"
                                                          ][i][
                                                            "sKUSpecAttribute"
                                                          ] &&
                                                          errors[
                                                            "specification"
                                                          ][i][
                                                            "sKUSpecAttribute"
                                                          ].message
                                                            ? `${styles.error_border}`
                                                            : ""
                                                        }
                                                        `}
                                    list={`sKUSpecAttribute${i}`}
                                    // ref={register}
                                    name={`specification[${i}][sKUSpecAttribute]`}
                                    id={`dataListShow${i}`}
                                    defaultValue={
                                      items && items.sKUSpecAttribute
                                        ? items.sKUSpecAttribute
                                        : ""
                                    }
                                    placeholder={"Attribute Name"}
                                    autoComplete={"off"}
                                    ref={register({
                                      validate: (value) => {
                                        if (!value) {
                                          return "Enter attribute name";
                                        } else if (
                                          inputList &&
                                          inputList.length > 0
                                        ) {
                                          let chckSeq = 0;
                                          inputList.forEach((item, keys) => {
                                            if (
                                              i !== keys &&
                                              watch(
                                                `specification[${keys}][sKUSpecAttribute]`
                                              ) === value
                                            ) {
                                              chckSeq = 1;
                                            }
                                          });
                                          if (chckSeq === 1) {
                                            return 'This attribute name has been taken already';
                                          }
                                        }
                                      },
                                    })}
                                    onChange={(e) => chkOldAttribute(i, e)}
                                    onBlur={(e) => chkOldAttribute(i, e)}
                                    onClick={(e) => chkOldAttribute(i, e)}
                                  />
                                  <datalist
                                    id={`sKUSpecAttribute${i}`}
                                    className={`dataList`}
                                  >
                                    {attributeOptions &&
                                      Array.isArray(attributeOptions) &&
                                      attributeOptions.map((item, j) => {
                                        return (
                                          <option
                                            className={`${styles.dataListOption}`}
                                            value={item.value}
                                            onChange={(e) =>
                                              chkOldAttribute(i, e)
                                            }
                                            onClick={(e) =>
                                              chkOldAttribute(i, e)
                                            }
                                          >
                                            {item.value}
                                          </option>
                                        );
                                      })}
                                  </datalist>
                                  {errors &&
                                    errors["specification"] &&
                                    errors["specification"][i] &&
                                    errors["specification"][i][
                                      "sKUSpecAttribute"
                                    ] &&
                                    errors["specification"][i][
                                      "sKUSpecAttribute"
                                    ].message && (
                                      <span className={styles.error_message}>
                                        {
                                          errors["specification"][i][
                                            "sKUSpecAttribute"
                                          ].message
                                        }
                                      </span>
                                    )}
                                </>
                              )}
                            </div>
                            {items &&
                              items.sKUSpecAttributeValue.length > 0 &&
                              Array.isArray(
                                items && items.sKUSpecAttributeValue
                              ) &&
                              items.sKUSpecAttributeValue.map(
                                (skyAttrIndi, j) => {
                                  return (
                                    <>
                                      {j != 0 && <div className="col-4"></div>}
                                      {/* <div className={`col-10 d-flex`}> */}
                                      <div
                                        className={`col-6 ${
                                          j != 0 ? "mt-2" : ""
                                        }`}
                                      >
                                        <TextField
                                          classes={{
                                            column: ``,
                                            labelClass: "",
                                            inputColumn: "",
                                            inputClassName: `${styles.input_field}`,
                                          }}
                                          placeholder={"Attribute Field"}
                                          id={`sKUSpecAttributeValue${i}${j}`}
                                          type={"text"}
                                          name={`specification[${i}][sKUSpecAttributeValue][${j}]`}
                                          subName={`specification`}
                                          validator={register({
                                            validate: (value) => {
                                              if (!value) {
                                                return "Enter attribute field";
                                              }
                                            },
                                          })}
                                          defaultValue={
                                            skyAttrIndi &&
                                            skyAttrIndi !== null &&
                                            skyAttrIndi !== "null"
                                              ? skyAttrIndi
                                              : ""
                                          }
                                          onChange={(e) =>
                                            getAttrVals(
                                              e.target.value,
                                              i,
                                              j,
                                              items
                                            )
                                          }
                                          // defaultValue={(items && items.sKUSpecAttributeValue) ? items.sKUSpecAttributeValue : ''}
                                        />
                                        {errors &&
                                          errors["specification"] &&
                                          errors["specification"][i] &&
                                          errors["specification"][i][
                                            "sKUSpecAttributeValue"
                                          ] &&
                                          errors["specification"][i][
                                            "sKUSpecAttributeValue"
                                          ][j] &&
                                          errors["specification"][i][
                                            "sKUSpecAttributeValue"
                                          ][j].message && (
                                            <span
                                              className={styles.error_message}
                                            >
                                              {
                                                errors["specification"][i][
                                                  "sKUSpecAttributeValue"
                                                ][j].message
                                              }
                                            </span>
                                          )}
                                      </div>
                                      <div className={`col-1 d-flex `}>
                                      <img
                                        onClick={() =>
                                          handleRemoveAttributeClick(
                                            i,
                                            j,
                                            items
                                          )
                                        }
                                        className={`${styles.deleteAttr} delete_specification align-content-center flex-wrap ${
                                          j === 0 ? "mt-2" : ""
                                        }`}
                                        src={closeIcon}
                                        alt=""
                                      />
                                    </div>
                                      
                                      {/* </div>            */}
                                    </>
                                  );
                                }
                              )}
                            <div className={`mt-1 col-10`}>
                              <button
                                id={"addAttri"}
                                className={` ${styles.addValBtn}`}
                                onClick={(e) =>
                                  handleAddValueClick(e, i, items)
                                }
                              >
                                + Add Value
                              </button>
                            </div>
                          </div>
                          {/* </div> */}
                        </>
                      );
                    })}

                  <div className={styles.btn_container}>
                    <Button
                      id={"creat_list_view_menu"}
                      type={SUBMIT_ID}
                      value={SAVE_BTN}
                      classes={{
                        className: `btn mb-1 mr-3 ${styles.btn_small}`,
                      }}
                    />
                    <Button
                      id={"cancel"}
                      type={"button"}
                      value={"Cancel"}
                      classes={{ className: `btn mb-1 ${styles.btn_cancel}` }}
                      onClick={() => handleEdit()}
                    />
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>}
    </>
  );
}
