import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../../secondary/Header';
import style from './usageAnalyticsReport.module.scss';
import { Form, Button, TextField, DateTimePicker,SelectBox } from '../../primary';
import rightArrow from '../../../static/images/right_arrow.svg';
import { subDays } from 'date-fns';
import { getAnalyticsOverview, getFaciltiestCustomerAsyn, setCustomerFacilities,  } from '../../../app/usageAnalyticsReportSlice'
import { Organizational } from '../../primary'
import { convertnewDateToDate, getFacilityLabel } from '../../../utils'
import { DataTable } from '../../secondary';
import { AnalyticsUserDetailTable } from './analyticsUserDetailTable';
import Carousel, {consts} from "react-elastic-carousel";
import {SALES_OFFICE_OPTIONS} from '../../../constants';

export const UsageAnalyticsReport = ({history}) => {
  const dispatch = useDispatch();

  //restrict when directly open the page
  const userDetails = useSelector(state => state.user);
  const adminView = userDetails?.role     
  useEffect(()=>{        
      window.onload = function() { 
        if (adminView !== "MedlineAdministrator"&&userDetails?.customerSettings?.ne1Wms === 0) {  
            history.push('/learnMore'); 
          }  
          if (adminView === "ExternalStandardUser") {  
              history.push('/unAuthorized'); 
          }       
      }    
  },[]) //restrict when directly open the page

  const [tempPayload, setTempPayload] = useState("")
  useEffect(() => {
         dispatch(getFaciltiestCustomerAsyn(userDetails?.customerNumber,'firstLoadUsageAnalytics',setTempPayload));
  }, [])
  /* Use hook form */
  const { register, handleSubmit, control, errors, reset, setError, watch } = useForm();
  let fromDate = useRef();
  let toDate = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [toDateRes, setToDateRes] = useState('');

  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [onselectOrg, setonselectOrg] = useState([]);
  const [errorMsg, setErrorMsg] = useState("")
  
  const [selectedDivOption, setSelectedDivOption] = useState("Product Selector")
  const [showApplyState, setShowApplyState] = useState(false);
  const [customerGroup, setCustomerGroup] = useState([]);
  const [createGroup, setCreateGroup] = useState(false);
  const [isValidSaleCust, setIsValidSaleCust] = useState(false);
  
  const getCustomerGroup = (e) => {
  if (e.target.value) {
      setShowApplyState(true);
      setCustomerGroup(e.target.value);
      setCreateGroup(true);
      //assignForm.current.resetSelectedValues();
      if(document.getElementById('customerNumber'))
      document.getElementById('customerNumber').value = ""
      setonselectOrg(null)
      setIsValidSaleCust(false);
      dispatch(setCustomerFacilities([]))

  } else {
      setCustomerGroup([]);
      setCreateGroup(false);
  }
}

  const onSelectOrg = (selectedList) => {
    setIsValidSaleCust(false);
    setShowApplyState(true);
    if (optionData.length !== selectedList.length) {
      setShowApplyState(true);
    }

    if (setSelectedFacilities) {
      let facilitykey = [];
      selectedList && selectedList?.map((item) => { //NOSONAR
        let facility = item.key.split("|")[0].trim();
        facilitykey = [...facilitykey, facility];
      })
      setSelectedFacilities(facilitykey)
    }
    if(selectedList.length===0){
      setonselectOrg([])
  }else{
    setonselectOrg([...selectedList])
  }
  }
  const facilitiesData = useSelector(state => state.usageAnalyticsReport.customerFacilities);
  const overViewData = useSelector(state => state.usageAnalyticsReport.analyticsOverViewData);

  let optionData = [];
  const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')

  soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
    const shipToFacilities = facilitiesData.data.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
     if(shipToFacilities.length===0){
        optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(soldToFacility),
                facilityNumber: soldToFacility.facilityNumber,
                soldToFacility: 'soldToFacility'
            })
           }
    shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
        optionData.push({
            cat: getFacilityLabel(soldToFacility),
            key: getFacilityLabel(shipToFacility),
            facilityNumber: shipToFacility.facilityNumber,
            shipToFacilities: 'shipToFacilities'
        })
    })
})
  useEffect(() => {
    let fac = ""
    if (facilitiesData?.errorMessage) {
      fac = "Primary group code doesn’t match our record"
       setShowApplyState(false)
    }
    setErrorMsg(fac)
  }, [facilitiesData])
  const enterCustomerNumber = (value) => {
    if (value) {
      setonselectOrg([])
      dispatch(getFaciltiestCustomerAsyn(value));
      setShowApplyState(true);
      setCreateGroup(false);
      setIsValidSaleCust(false);
        
    }
    if (value === "") {
      setonselectOrg(null)
      setCreateGroup(false);
      setCustomerGroup([])
    }
  }
  const getCustomerNumber = (value) => {
    if (value) {
      setonselectOrg([])
      dispatch(getFaciltiestCustomerAsyn(value));
      setShowApplyState(true);
      setCreateGroup(false);
      setIsValidSaleCust(false);
    }
    if (value === "") {
      setonselectOrg(null)
      setCreateGroup(false);
      setCustomerGroup([])
    }
  }
  const onSubmit = (data) => {
    if(userDetails && (userDetails.role === "MedlineAdministrator" || userDetails.role === "MedlineSupport")){
      if(selectedFacilities.length===0&&document?.getElementById('customerNumber')?.value == '' &&Array.isArray(customerGroup)&& customerGroup.length=== 0){
        setIsValidSaleCust(true);
        return false;
      }
      else if(selectedFacilities.length===0&&document?.getElementById('customerNumber')?.value !== '' &&Array.isArray(customerGroup)&& customerGroup.length=== 0){
        setIsValidSaleCust(true);
        return false;
      }
    }
    
    const tempData = {  
      fromDate: convertnewDateToDate(data.fromDate),
      toDate: convertnewDateToDate(data.toDate),
      facility: selectedFacilities,
      customerType: Array.isArray(customerGroup)&&customerGroup.length === 0 ? null : [customerGroup]
    }
    const fromDates = new Date(document.getElementById('fromDate').value).getTime()
    const toDates = new Date(document.getElementById('toDate').value).getTime()
    setToDateRes(new Date(document.getElementById('fromDate').value))
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById('toDate').value = ''
      } else {
        if((fromDates&&toDates)||(selectedFacilities.length>0) ||(Array.isArray(customerGroup)&& customerGroup.length>0)){
          dispatch(getAnalyticsOverview(tempData))
          setTempPayload(tempData)
        }
       
      }
    }, 10)

  }

  const resetData = () => {
    setCreateGroup(false)
    setToDateRes('')
    setShowApplyState(false);
    setonselectOrg([])
    setSelectedDivOption("Product Selector")
    dispatch(setCustomerFacilities([]))
    reset()
    setCustomerGroup([])
    dispatch(getFaciltiestCustomerAsyn(userDetails?.customerNumber));
    setTimeout(() => {
      getDefaultLoadData()
    }, 500)
  }

  const columns = [
    {
      Header: 'Features',
      accessor: 'feature',
      restWidth: '250px'
    },
    {
      Header: 'Usage',
      accessor: 'usage',
      restWidth: '200px'

    },
    {
      Header: 'Usage %',
      accessor: 'usagePercent',
      restWidth: '180px'
    },
    {
      Header: 'Avg usage per user',
      accessor: 'avgUsagePerUser',
      restWidth: '250px'
    }, {
      Header: 'Usage % compare to other features',
      accessor: 'usagePercentToOthers',
      restWidth: '360px'
    }
  ];
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const selectRowCount = (length) => {
    setSelectedRowCount(length)
  }
  const handleSort = useCallback(data => {
    if (data && data.sortBy[0] && data.sortBy[0].id) {
      const sortingColumn = data.sortBy[0]
      const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
      const queryParam = `sort=` + sortingColumn.id + sortingType
      dispatch(getAnalyticsOverview(tempPayload, queryParam))
    }

  }, [dispatch, tempPayload])
  const deleteDataList = (data) => {
    //electedData = data
  }
  const optionSelected = (e) => {
    setSelectedDivOption(e);
  }
  const customFromDatePickerChange = (e) => {
    const fromDates = new Date(e).getTime()
    const toDates = new Date(document.getElementById('toDate').value).getTime()
    setToDateRes(new Date(e))
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById('toDate').value = ''
      }
    }, 500)
    setShowApplyState(true);

  }
  const customToDatePickerChange = (e) => {
    setShowApplyState(true);
    const fromDates = new Date(document.getElementById('fromDate').value).getTime()
    const toDates = new Date(e).getTime()
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById('fromDate').value = ''
        setToDateRes('')
      }
    }, 500)
  }
  const handleOnBlurFrom = ({ target: { value } }) => {
    const fromDates = new Date(value).getTime()
    const toDates = new Date(document.getElementById('toDate').value).getTime()
    setToDateRes(new Date(value))
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById('toDate').value = ''
      }
    }, 500)
  };
  const handleOnBlurTO = ({ target: { value } }) => {
    const fromDates = new Date(document.getElementById('fromDate').value).getTime()
    const toDates = new Date(value).getTime()
    setTimeout(() => {
      if (fromDates > toDates) {
        document.getElementById('toDate').value = ''
        setToDateRes('')
      }
    }, 500)
  };
  const getDefaultLoadData = () => {
    let facilitykey = [];
    optionData &&Array.isArray(optionData)&& optionData?.map((item) => { //NOSONAR
      let facility = item.key.split("|")[0].trim();
      facilitykey = [...facilitykey, facility];
    })
    if (facilitykey.length > 0 && document.getElementById('fromDate') && document.getElementById('toDate')) {
      const loadData = {
        fromDate: convertnewDateToDate(new Date(document.getElementById('fromDate').value)),
        toDate: convertnewDateToDate(new Date(document.getElementById('toDate').value)),
        facility: facilitykey,
        customerType: Array.isArray(customerGroup)&&customerGroup.length === 0 ? null : [customerGroup]
      }
      //dispatch(getAnalyticsOverview(loadData))

      dispatch(getFaciltiestCustomerAsyn(userDetails?.customerNumber,'firstLoadUsageAnalytics',setTempPayload));
      setTempPayload(loadData)
      setToDateRes(new Date(document.getElementById('fromDate').value))
    }
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 300, itemsToShow: 2, itemsToScroll: 6 },
    { width: 500, itemsToShow: 3 },
    { width: 700, itemsToShow: 4 },
    { width: 900, itemsToShow: 5 },
    { width: 1000, itemsToShow: 6 }
  ];
  const myArrow = ({ type, onClick, isEdge }) =>  {
    const pointer = type === consts.PREV ? 
    <span className={`carousel-control-prev-icon ${style.left_icon}`}></span>
    : 
    <span className={`carousel-control-next-icon ${style.right_icon}`}></span>
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    )
  }
  return (
    <>
      <div className="right_block">
        <Header reportHeading={"Usage Analytics"} />
        <div className={`col ${style.wound_dashboard} mt-0`}>
          <Form className={style.wound_form} onSubmit={handleSubmit(onSubmit)}>
            <div className={`col-8 ${style.col_spec}`}>
              <label className={`col-5 ${style.label_spec}`}>Date Range:</label>
              <DateTimePicker
                id={"fromDate"}
                name={"fromDate"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 ${style.col_spec}`,
                }}
                // selected={startDate}
                customOnChange={customFromDatePickerChange}
                defaultValue={subDays(new Date(startDate), 30)}
                displayIcon={true}
                validator={register()}
                showMonthDropdown={true}
                showYearDropdown={true}
                
                errors={errors}
                maxDate={new Date()}
                onBlur={handleOnBlurFrom}
                dateRef={fromDate}
              />
  
              <DateTimePicker
                id={"toDate"}
                name={"toDate"}
                control={control}
                classes={{
                  date_picker: style.wound_input,
                  dateColumn: "col-12 p-0",
                  column: `col m-0 pr-0 ${style.col_spec}`,
                }}
                defaultValue={new Date()}
                customOnChange={customToDatePickerChange}
                displayIcon={true}
                validator={register()}
                showMonthDropdown={true}
                showYearDropdown={true}
                minDate={toDateRes ? toDateRes : ''}
                maxDate={new Date()}
                errors={errors}
                onBlur={handleOnBlurTO}
                dateRef={toDate}
              />
            </div>
            {userDetails && (userDetails.role === "MedlineAdministrator" || userDetails.role === "MedlineSupport") &&
            <>
            {/* Customer group added */}
            <div className={`col-8 ${style.col_spec}`}>
                
                <SelectBox
                  id={'customerGroup'}
                  name={'customerGroup'}
                  label={'Customer Group Selection:'}
                  classes={{
                  column: `col-12 ${style.col_spec}`,
                  labelClassName: `col-4 col-form-label ${style.label_spec}`,
                  inputColumn: `col-7 p-0 `,
                  inputClassName: `${style.basic_input} ${style.custGroupAlign} form-control`
                  }}
                  validator={register()}
                  placeholder="Select"
                  optionValue={SALES_OFFICE_OPTIONS}
                  errors={errors}
                  onChange={(e) => getCustomerGroup(e)}
                  // defaultValue={layoutRowData && layoutRowData.salesOffice}
                  disabled={document.getElementById("customerNumber")?.value ? true : false}
                  // required={true}
                />
              </div>
              <div className={` ${style.label_OR}`} ><strong>OR</strong></div>
            {/* <TextField
              id={"customerNumber"}
              name={"customerNumber"}
              label={"Primary Group Code:"}
              classes={{
                column: `col-8 mt-3 ${style.col_spec}`,
                labelClass: `col-5 col-form-label ${style.label_spec}`,
                inputColumn: `col-7 p-0`,
                inputClassName: `${style.wound_input}  customerNum`
              }}
              onKeyUp={(e) => enterCustomerNumber(e?.target?.value)}
              type="text"
              validator={register()}
              errors={errors}
              disabled={createGroup ? true : false}
              customError={errorMsg ? errorMsg : ''}
            />
            <div className="mt-4">
              <span className={`mt-1 ${style.form_span}`} onClick={(e) => getCustomerNumber(document.getElementById('customerNumber').value)}>
                Search <img src={rightArrow} alt="" className={`${style.img_left}`} />
              </span>
            </div> */}
            <br />
            </>
            }
            <div className={`col-10 ${style.col_spec}`}>
              {optionData && Array.isArray(optionData) && optionData.length === 0 && <Organizational
                id={"organizationalView"}
                name={"organizationalView"}
                label={`Facility/Location:`}
                classes={{
                  column: `col ${style.col_spec}`,
                  labelClassName: `col-4 col-form-label ${style.label_spec}`,
                  inputColumn: `${style.organizationalView} ${createGroup ? style.disabled : ''} col-7 p-0 ml-1`,
                  inputClassName: `${style.basic_input} form-control`
                }}
                optionValue={optionData}
                groupBy="cat"
                displayValue="key"
                defaultValue={optionData}
                onSelect={onSelectOrg}
                onRemove={onSelectOrg}
                // selectAll={true}
                disabled={createGroup ? true : false}
                assignPage={true}
                
              />}
              {optionData && Array.isArray(optionData) && optionData.length !== 0 && <Organizational
                id={"organizationalView"}
                name={"organizationalView"}
                label={`Facility/Location:`}
                classes={{
                  column: `col ${style.col_spec}`,
                  labelClassName: `col-4 col-form-label ${style.label_spec}`,
                  inputColumn: `${style.organizationalView} ${createGroup ? style.disabled : ''} col-7 p-0 ml-1`,
                  inputClassName: `${style.basic_input} form-control`
                }}
                optionValue={optionData}
                groupBy="cat"
                displayValue="key"
                defaultValue={Array.isArray(onselectOrg)&&onselectOrg.length>0?onselectOrg:optionData}
                onSelect={onSelectOrg}
                onRemove={onSelectOrg}
                selectAll={true}
                disabled={createGroup ? true : false}
                assignPage={true}
              />}
            </div>
            { isValidSaleCust&&<span className={`${style.error_message} text-center`}>{
             'Please select customer group selection or customer number and organization' }
               </span> }
            {/* {
              showApplyButton && */}
            {showApplyState && <div className={`col-8 ${style.col_spec}`}>
              <div className="offset-5">
                <Button
                  classes={{ className: `btn ${style.btn_custom} ${style.btn_small} mr-4` }}
                  type="submit"
                >
                  Apply
                </Button>
                <Button
                  classes={{ className: `btn ${style.btn_custom} ${style.btn_small_cancel} ` }}
                  onClick={resetData}
                  type="button"
                >
                  Reset
                </Button>
              </div>
            </div>}
            {/* } */}
          </Form>

        </div>

        <div className={style.overview_blk}>
          <div className={style.overview_top}>
            <p className={style.overview_title}>Overview</p>
          </div>
          {/* data table start */}

          <div className={`w-100 mt-n2 ${style.wound_dashboard}`}>
            <DataTable
              columns={columns}
              data={overViewData && Array.isArray(overViewData) ? overViewData : []}
              sorting={true}
              getSelectedRowData={''}
              setSelectedRows={selectRowCount}
              onSort={handleSort}
              detailView={false}
              fixedFullWidthSize={'0'}
              workListView={true}
              deleteDataList={deleteDataList}
              reportView={true}
            />
          </div>
          {/* data table end */}

          
      <hr className="seperator" />
      <div className={`carousel-wrapper ${style.carousel_wrap}`}>
        <Carousel breakPoints={breakPoints} renderArrow={myArrow} pagination={false}
       >

        <div className={`crd_opt_1 ${style.ua_percent} ${(selectedDivOption === "Product Selector") ? style.active : ''}`} onClick={() => optionSelected("Product Selector")}>
                <label className={style.ua_top}>
                  Product Selector
                </label>
              </div>
              <div className={`crd_opt_2 ${style.ua_percent} ${(selectedDivOption === "Wound Management") ? style.active : ''}`} onClick={() => optionSelected("Wound Management")}>
                <label className={style.ua_top}>
                  Wound Management
                </label>
              </div>
              <div className={`crd_opt_3 ${style.ua_percent} ${(selectedDivOption === "Product Search") ? style.active : ''}`} onClick={() => optionSelected("Product Search")}>
                <label className={style.ua_top}>
                  Product Search
                </label>
              </div>
              <div className={`crd_opt_8 ${style.ua_percent} ${(selectedDivOption === "Risk Assessment") ? style.active : ''}`} onClick={() => optionSelected("Risk Assessment")}>
              <label className={style.ua_top}>
                Risk Assessment
              </label>
            </div>
              <div className={`crd_opt_4 ${style.ua_percent} ${(selectedDivOption === "OR Pads") ? style.active : ''}`} onClick={() => optionSelected("OR Pads")}>
                <label className={style.ua_top}>
                  OR Pads
                </label>
            </div>
            <div className={`crd_opt_5 ${style.ua_percent} ${(selectedDivOption === "Protein needs calculator") ? style.active : ''}`} onClick={() => optionSelected("Protein needs calculator")}>
              <label className={style.ua_top}>
                Protien needs calculator
              </label>
            </div>
            <div className={`crd_opt_6 ${style.ua_percent} ${(selectedDivOption === "NE1 WMS(Paid customers)") ? style.active : ''}`} onClick={() => optionSelected("NE1 WMS(Paid customers)")}>
              <label className={style.ua_top}>
                NE1 WMS <span>(Paid customers)</span>
              </label>
            </div>
            <div className={`crd_opt_7 ${style.ua_percent} ${(selectedDivOption === "NE1 WMS(Offline Patients)") ? style.active : ''}`} onClick={() => optionSelected("NE1 WMS(Offline Patients)")}>
              <label className={style.ua_top}>
                NE1 WMS <span>(Offline Patients)</span>
              </label>
            </div>
            
        </Carousel>
      </div>
    </div>
        <AnalyticsUserDetailTable tempPayload={tempPayload} selectedDivOption={selectedDivOption} />
      </div>
    </>
  )
}
