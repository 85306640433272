import React from 'react';
import { connect } from 'react-redux';
import HackTimer from 'hacktimer'
import renderRoutes from './renderRoutes';
import { Menu } from './components/core/Menu/menu';
import PropTypes from 'prop-types';
import {
  logout
} from './app/userSlice';
import { Mask } from './components';
import {setAccessError, setError } from './app/appSlice';

export let App = ({dispatch, user, app}) => {
  
  const doLogout = (e) => {
    e.preventDefault();
    dispatch(setError(''))
    dispatch(setAccessError(null))  
    dispatch(logout());
  }

  return (
    <div className="App" data-testid="app" style={{backgroundColor: '#dadee0'}}>
    {/* <div className="App" data-testid="app" style={{backgroundColor: '#dadee0', overflowY: 'hidden', overflowY: '-moz-hidden-unscrollable'}}> */}
      {renderRoutes(() => <Menu logout={doLogout} user={user}/>, user, dispatch)}
      {app.loading && <Mask />}
    </div>
  );
}

App.propTypes = {
  dispatch: PropTypes.func,
};

function mapStateToProps(state) {
  return {
     user: state.user,
     app: state.app
  }
}

App = connect(mapStateToProps)(App);
