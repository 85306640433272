import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { getWoundPhotoAsync } from '../../../app/workListSlice';
import { LIST_VIEW_LIMIT, WOUND_PHOTO_WORKLIST } from '../../../constants';
import { DataTable, Pagination } from '../../secondary';

export let WoundPhotosData = ({ dispatch, history,facilityNumber }) => {
    const [activePage, setActivePage] = useState(1);
    const [offSet, setOffSet] = useState(0)
    const [sortType, setSortType] = useState('')
    const getWorkListWoundPhoto = () => {
        const listPayLoad = {};
        listPayLoad['url'] = WOUND_PHOTO_WORKLIST
        listPayLoad['method'] = 'Get'
        listPayLoad['queryParam'] = { offSet: 0, limit: LIST_VIEW_LIMIT, facilityNumber: facilityNumber }
        dispatch(getWoundPhotoAsync(listPayLoad))
    }

    useEffect(() => {
        getWorkListWoundPhoto();
    }, [dispatch, facilityNumber]);


    const patientList = useSelector(state => state.worklists.woundPhotoData)

    const columns = [
        // {
        //     Header: 'Patient Profile Photo',
        //     accessor: 'profilePicture',
        //     imageData: 'picture',
        //     restWidth: '165px',
        //     disableSortBy: true,
        // },
        {
            Header: 'MRN #',
            accessor: 'mrn',

        },
        {
            Header: 'First name',
            accessor: 'firstName',

        },
        {
            Header: 'Last name',
            accessor: 'lastName',

        },
        {
            Header: 'Gender',
            accessor: 'gender',

        },
        {
            Header: 'Age',
            accessor: 'age',
            restWidth: '150px'

        },
        {
            Header: `DOB`,
            accessor: 'dob',
            restWidth: '180px'
        },

    ];

    //redirect
    const getSelectedRowData = (data) => {
        if (data) {
            history.push(`/${"patients"}/${data.ne1Id}`);
        }
    }

    const deleteDataList = () => {
    }

    const selectRowCount = () => {
    }

    const handleSort = useCallback(data => {
        if (data && data.sortBy[0] && data.sortBy[0].id) {
          const sortingColumn = data.sortBy[0]
          const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
          let sortParam = sortingColumn.id + sortingType
          setSortType(sortParam)
          const listPayLoad = {};
            listPayLoad['url'] = WOUND_PHOTO_WORKLIST
            listPayLoad['method'] = 'Get'
            listPayLoad['queryParam'] = { offSet: isNaN(offSet)===true?0:offSet, limit: LIST_VIEW_LIMIT, facilityNumber: facilityNumber, sort: sortParam }
            dispatch(getWoundPhotoAsync(listPayLoad))
        }
    
    }, [dispatch, facilityNumber,offSet])
      const getActivePage = (data) => {
        setActivePage(data);
        const listPayLoad = {};
        listPayLoad['url'] = WOUND_PHOTO_WORKLIST
        listPayLoad['method'] = 'Get'
        listPayLoad['queryParam'] = { offSet: (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT, limit: LIST_VIEW_LIMIT, facilityNumber:facilityNumber,sort: (sortType)?sortType:'' }
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        dispatch(getWoundPhotoAsync(listPayLoad))
      }  


    return (
        <>
            {patientList?.woundPhoto &&
                <DataTable
                    columns={columns}
                    data={patientList && patientList?.woundPhoto}
                    sorting={true}
                    getSelectedRowData={getSelectedRowData}
                    setSelectedRows={selectRowCount}
                    onSort={handleSort}
                    detailView={true}
                    deleteDataList={deleteDataList}
                    showFixedColumn={false}
                    workListView={true}
                    sortType={(sortType)?sortType:''}
                />
            }
            {(Array.isArray(patientList?.woundPhoto)) && (patientList?.woundPhoto.length > 1) &&<Pagination
                activePage={activePage}
                itemsCountPerPage={patientList?.metadata ? patientList?.metadata?.limit : 50}
                totalItemsCount={patientList?.metadata ? patientList?.metadata?.total : 0}
                getActivePage={getActivePage}
                reportView={true}
            />
            }

        </>
    )

}
WoundPhotosData.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}
WoundPhotosData = connect()(withRouter(WoundPhotosData));
