import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkListRecordsCount,setLastVal } from '../../../app/patientSlice';
import { VerticalTabWorkList } from '../../secondary/VerticalTabWorkList';
import { NewAdmissionNONPOA } from './newAdmissionNONPOA';
import { NewAdmissionPOA } from './newAdmissionPOA';
import style from './workListCommon.module.scss';
export function NewAdmission({facilityNumber,history }) {
  useEffect(() => {
    if(facilityNumber){
        dispatch(getWorkListRecordsCount(facilityNumber,'newAdmissionValues'));
    }
   }, [facilityNumber])
  const workListRecordsCount = useSelector(state => state.patient.workListRecordsCount);
  const poaCount=workListRecordsCount?.newAdmissionValues?.poaCount?`(${workListRecordsCount?.newAdmissionValues?.poaCount})`:''
  const nonpoaCount=workListRecordsCount?.newAdmissionValues?.nonpoaCount?`(${workListRecordsCount?.newAdmissionValues?.nonpoaCount})`:''
  /* Tab list */
  const tabList = [
    {
      name: `POA ${poaCount}`,
      content: <NewAdmissionPOA facilityNumber={facilityNumber}/>,
      bottomName: 'Pending Wound Photo',
      bgcolor: style.new_admission_bgcolor

    },
    {
      name: `Non-POA ${nonpoaCount}`,
      content: <NewAdmissionNONPOA facilityNumber={facilityNumber}/>,
      bottomName: 'Pending Wound Photo',
      bgcolor: style.new_admission_bgcolor
    },
  ];
  const dispatch = useDispatch();
  const lastVal = useSelector(state => state.patient.lastVal)
  const [defaultVerTab, setDefaultVerTab] = useState(lastVal?.selectVerTab?lastVal?.selectVerTab:0)
  const onSelectVerTab = (e) => {
    setDefaultVerTab(e)
     const setLast={...lastVal,selectVerTab: e }
     dispatch(setLastVal(setLast))
  
    }
  return (
    <>
      <VerticalTabWorkList  onSelectInt={onSelectVerTab} defaultIndex={defaultVerTab<=1?defaultVerTab:0}  tabList={tabList} dispatch={dispatch} />
    </>
  )
}
