import React from 'react'
import PropTypes from 'prop-types';
import { custom_btn_primary } from './button.module.scss';

export function Button({ ariaLabel, children, id, type, value, classes, onClick }) {

  return (
    <>
      <button
        type={type || ''}
        id={id || ''}
        className={(classes) ? `${classes.className} ${custom_btn_primary}` :''}
        onClick={onClick}
        aria-label={ariaLabel}
      >
        {children || value}
      </button>
    </>
  )
}
Button.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.object,
  onClick: PropTypes.func
}
