import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, TextField, Button, SelectBox } from '../../primary';
import { Modal, ActionModal, CloseWarning } from '../../secondary';
import styles from './createPatient.module.scss';
import { checkCustomerNumber, ServiceError } from '../../../utils';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CreateEditPatient } from './createEditPatient';
import { checkCustomerAsync, createPatientAsync, resetFacilitiesData, setResMsgState } from '../../../app/patientSlice';
import { format } from 'date-fns';
import iconSuccess from '../../../static/images/icons-check-success.png';
import iconDanger from '../../../static/images/icon-check-danger.png';
import { SUCCESS_STATUS, ERROR_STATUS, PATIENT_CREATED_MSG } from '../../../constants';
import { setAccessError, clearError} from '../../../app/appSlice';
import infoIcon from '../../../static/images/info.svg';

export let CreatePatients = ({ dispatch, history, modalOpen, modalClose,facilityListView }) => {
    const errorMessage = ServiceError();
    const [facility, setFacility] = useState(false);
    const [showFacilityList, setFacilityList] = useState(false);
    const [facilityNumber, setFacilityNumber] = useState('');
    const [msgModal, setMsgModal] = useState(false);
    const resMsgState = useSelector(state => state.patient.resMsgState);
    const accessError = useSelector(state => state.app.accessError)
    /* Use hook form */
    const { register, handleSubmit, control, errors, reset, setError } = useForm();
    /* On Submit */
    useEffect(() => {
        dispatch(resetFacilitiesData({}))
        dispatch(setResMsgState({}));
        modalRefresh()
        setMsgModal(false)
    }, [dispatch])
    const getFacilities = useSelector(state => state.patient.facilitiesData);
    const facilityList = (data) => {
        if (data.status === "success") {
            setFacility(true);
        }
    }

    useEffect(() => {
    if (errorMessage==="MRN already registered with another patient") {
        setError("mrn", {
        type: "manual",
        message: errorMessage
        });
        document.getElementById("mrn").focus();
        dispatch(clearError());
    } 
    }, [errorMessage,dispatch]);

    const modalRefresh = () => {
        modalClose();
        setFacility(false);
        setFacilityList(false);
        setMsgModal(false)
        if (userDetails.role === "ExternalAdministrator") {
            const facilitiesLength = getFacilities.data && getFacilities.data.facilities && getFacilities.data.facilities.length
            facilitiesLength > 0 && facilitiesLength === 1 && setFacilityList(true)
            getFacilities.data && setFacilityNumber(getFacilities.data.facilities[0].facilityNumber)
        } else {
            dispatch(resetFacilitiesData({}))
        }
    }

    /* No fn */
    const handleNo = () => {
        dispatch(setAccessError(null))
        modalClose()
    }

    const onSubmit = (data) => {
        if (!data.facility) {
            let facilityNumber = {}
            facilityNumber['customerNumber'] = data.customer;
            facilityNumber['setMethod'] = facilityList
            dispatch(checkCustomerAsync(facilityNumber, history))
        }
        data.facility && setFacilityList(true)

        if (getFacilities?.data?.facilities?.length === 1) {
            setFacilityList(true);    
            setFacilityNumber(optionData[0].key);
        } else {
            setFacilityNumber(data.facility);
        }
    }

    const onSubmitPatient = (patientData) => {
       // let admissionDate = format(new Date(patientData.admissionDate), 'MM/dd/yyyy h:mm aa');
        const admissionDate = (document.getElementById("admissionDate")?.value) ? document.getElementById("admissionDate").value : '';
        const admissionTime = (document.getElementById("admissionTime")?.value) ? document.getElementById("admissionTime").value : '';

        const data = {
            facilityNumber: facilityNumber,
            firstName: patientData.firstName,
            lastName: patientData.lastName,
            gender: patientData.gender,
            dob: format(new Date(patientData.dateOfBirth), 'MM/dd/yyyy'),
            phone: patientData.phone,
            phoneType: patientData.phoneType,
            address: {
                addressLine1: patientData.address,
                addressLine2: "",
                city: patientData.city,
                state: patientData.state,
                zipCode: patientData.zipCode
            },
            careInfo: {
                admissionDate: new Date(admissionDate + ' ' + admissionTime).toLocaleString(),//.toISOString() //.toUTCString()
                wristBandId: patientData.wristband,
                unit: patientData.unit,
                floorNumber: patientData.floor,
                roomNumber: patientData.room,
                reasonForVisit: patientData.reasonForVisit
            },
            customerNumber: customerNumber,
            mrn:patientData.mrn
        }
        dispatch(createPatientAsync(data, history, setMsgModal))
        // dispatch(createPatientAsync(data, history, setMsgModal)).then(
        //     () => {
        //         setFacilityList(false);
        //         setFacility(false);
        //     }
        // )
    }

    useEffect(() => {
    if (resMsgState === "success") {
        setMsgModal(true)
        setTimeout(() => {
            reset();
            modalRefresh()
            setMsgModal(false)
            dispatch(setResMsgState({}));
        }, 4000)
    }
    }, [resMsgState]);

    const userDetails = useSelector(state => state.user);
    const customerNumber = (userDetails.customerNumber) ? userDetails.customerNumber : 0;
    useEffect(() => {
        if (userDetails.role === "ExternalAdministrator") {
            let facilityNumber = {};
            facilityNumber['customerNumber'] = customerNumber;
            dispatch(checkCustomerAsync(facilityNumber, history))
            setFacility(true)
        } else {
            resetFacilitiesData();
        }
    }, [dispatch])

    useEffect(() => {
        if (userDetails.role === "ExternalAdministrator") {
            const facilitiesLength = getFacilities.data && getFacilities.data.facilities && getFacilities.data.facilities.length
            facilitiesLength > 0 && facilitiesLength === 1 && setFacilityList(true)
            getFacilities.data && setFacilityNumber(getFacilities.data.facilities[0].facilityNumber)
        }
    }, [getFacilities])

    let optionData = [];

    (getFacilities?.data) && getFacilities?.data?.facilities?.map((value, index) => {//NOSONAR
        optionData.push({
            value: `
        ${value?.facilityNumber !== null ? value.facilityNumber : ''}  
        ${value?.facilityName !== null ? ` | ` + value.facilityName : ''} 
        ${value?.address?.addressLine1 !== null ? ` | ` + value.address.addressLine1 : ''}  
        ${value?.address?.addressLine2 !== null ? ` | ` + value.address.addressLine2 : ''} 
        ${value?.address?.city !== null ? ` | ` + value.address.city : ''}  
        ${value?.address?.state !== null ? ` | ` + value.address.state : ''} 
        ${value?.address?.zipCode !== null ? ` | ` + value.address.zipCode : ''}`,
            key: `${value?.facilityNumber !== null ? ` | ` + value.facilityNumber : ''}`,
            manualPatientCreation: `${value?.manualPatientCreation===0 ? false : true}`
        })
    })//NOSONAR
    let customerText = <TextField
        classes={{
            column: `col ${styles.col_spec}`, labelClass: `col-form-label ${styles.label_spec} ${styles.custom_label_spec}`,
            inputColumn: 'col-4 p-0', inputClassName: `${styles.basic_input} form-control`
        }}
        label={"Customer #:"}
        type={"text"}
        name={"customer"}
        id={"customer"}
        placeholder={""}
        title={'Toggle All Rows Selected'}
        validator={register(checkCustomerNumber)}
        errors={errors}
        customError={errorMessage ? 'We didn’t recognize this customer number' : ""}
        required={true}
    />
    let facilityDropdown = <><SelectBox
        id={"facility"}
        name={"facility"}
        label={`Facility:`}
        classes={{
            column: `col ${styles.col_spec}`, labelClassName: `col-form-label ${styles.label_spec} ${styles.custom_label_spec}`,
            inputColumn: 'col-8 p-0', inputClassName: `${styles.basic_input} selectFacility form-control`
        }}
        optionValue={optionData}
        validator={register({ required: true })}
        defaultValue={1072719}
    />
    <span className={`${styles.tooltip} ${styles.tooltip_list}`}>                   
        <img src={infoIcon} alt="info"  style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc"}}/>                                                            
        <span class={userDetails.role !== "ExternalAdministrator" ? `${styles.tooltiptext}` : `${styles.tooltiptextExternal}`}>{'Manual patient creation is disabled for some facilities by your organization'}</span>                                                            
    </span>
    </>

    let button = <div className="col-sm-12">
        <div className="text-center pb-4">
            <Button id={""} type={"submit"} value={"Next"} classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }} />
        </div>
    </div>

    let internalForm = <>
        {!showFacilityList &&
            <Form className={` ${styles.patient_content} formSubmit`} onSubmit={handleSubmit(onSubmit)}>
                <div className={`col-sm-12 ${styles.col_spec}`}>
                    <p className={`col-12 ${styles.subheading_2} pl-3`}>Contact Information:</p>
                </div>
                {/* Service error messages  */}
                {/* {errorMessage && <div className={`col-sm-6`}>
                    <div data-testid="service_error col-sm-6" className={service_error}>
                        {"Customer number does not match with our records"}
                    </div>
                </div>} */}
                {customerText}
                {facility && !errorMessage && getFacilities?.data?.facilities?.length > 1 ?
                    facilityDropdown
                    : facility && getFacilities?.data?.facilities?.length === 1 ?
                        <div className={`col ${styles.col_spec}`}>
                            <label className={`col-3 col-form-label ${styles.label_spec}`}>Facility:</label>
                            <div className="col-8 p-0">
                                <span className={`col-form-label ${styles.content_spec}`}>
                                    {optionData[0].value}
                                </span>
                            </div>
                        </div>

                        : ''}
                {button}
            </Form>
        }
        {showFacilityList &&
            <CreateEditPatient
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmitPatient}
                control={control}
                errors={errors}
                handleCancel={modalRefresh}
            />
        }
    </>

    let createPatient = {
        'MedlineAdministrator': internalForm,
        'MedlineStandardUser': internalForm,
        'MedlineSupport': internalForm,
        'ExternalAdministrator': <>
            {
                !showFacilityList ?
                    <Form className={` ${styles.patient_content} formSubmit`} onSubmit={handleSubmit(onSubmit)}>
                        <div className={`col-sm-12 ${styles.col_spec}`}>
                            <p className={`col-12 ${styles.subheading_2} pl-3`}>Contact Information:</p>
                        </div>
                        {facilityDropdown}
                        {button}
                    </Form>
                    :
                    showFacilityList && <CreateEditPatient
                        register={register}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmitPatient}
                        control={control}
                        errors={errors}
                        handleCancel={modalRefresh}
                    />
            }
        </>
    }
    useEffect(() => {
        if(resMsgState === SUCCESS_STATUS&&msgModal){
          if (document.getElementById("facility")) {
            document.getElementById('facility').value = ''
          }
          if(facilityListView){
            facilityListView('')
          }
        }
      }, [resMsgState,msgModal])
    useEffect(async () => {
        reset(); // asynchronously reset your form values
        setFacility(false);
    }, [reset])
    return (
        <>
            {!msgModal && <Modal
                modalOpen={modalOpen}
                modalClose={modalRefresh}
                heading={"Add Patient"}
                className={"modal_box"}
                modalSize={styles.custom_modal_size}
                reset={reset}
                accessError={accessError && <CloseWarning
                    message={accessError ? accessError : 'Access Denied'}
                    noFn={handleNo}
                    accessError={accessError}
                />
                }
            >
                {createPatient[userDetails.role]}
            </Modal>
            }

            {msgModal && <Modal
                modalOpen={modalOpen}
                modalClose={modalRefresh}
            >
                <div className="text-center p-4">

                    {resMsgState === SUCCESS_STATUS && <ActionModal
                        iconImg={iconSuccess}
                        heading={PATIENT_CREATED_MSG}
                        buttonText="Close"
                        buttonAction={modalRefresh}
                    />
                    }

                    {resMsgState === ERROR_STATUS && <ActionModal
                        iconImg={iconDanger}
                        heading="Patient not yet created!"
                        buttonText="Close"
                        buttonAction={modalRefresh}
                    />
                    }

                </div>
            </Modal>
            }
        </>

    )
}
CreatePatients.propTypes = {
    dispatch: PropTypes.func
};
CreatePatients = connect()(withRouter(CreatePatients));
