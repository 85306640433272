import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import styles from './fancyDropDown.module.scss';
import { Accordion } from '../../secondary/Accordion/accordion';
import {RadioButton, TextField} from '../../primary'
import piCauseIcon from "../../../static/images/pi-cause.png";
import otherCauseIcon from "../../../static/images/other-cause.png";
export const FancyDropDown = ({ index, id = '', name = '', label = '', validator, listOptions, required = false, classes, getData, defaultValue, viewScreen,errors, showCom }) => {
    const [histCauseData, setHistCauseData] = useState([]);

    const [itemValueText, setitemValueText] = useState('Select');
    const [isToggleSwitch, setIsToggleSwitch] = useState(0);
    // ONLY FOR DROPDOWN HIDING AND SHOWING, NO DATA WORKS

    const [viewFancyDropDown, setFancyDropDown] = useState(false)

    /* Function to toggle drop assessment*/
    const handleDropDown = (e) => {
        setFancyDropDown(!viewFancyDropDown)
    }

    /* Trigger Drop Down Items close */
    const dropDownRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', checkHandleClickOutside);
        return () => document.removeEventListener('mousedown', checkHandleClickOutside);
    });

    const checkHandleClickOutside = (e) => {
        if (dropDownRef !== null && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
            setFancyDropDown(false);
        }
    };

    // ONLY FOR DROPDOWN HIDING AND SHOWING, NO DATA WORKS

    const handleDropChangeTreatMent = (index, i) => {
        setitemValueText(
            <>
                <div><span className={`${styles.cat}`}>{listOptions[index].value ? listOptions[index].value : ''}</span> : {listOptions[index].options[i].value ? listOptions[index].options[i].value : ''}</div>
            </>
        )
        setFancyDropDown(false);
        getData(listOptions[index].key, listOptions[index].options[i].key)
    }

    const [defaultItem, setDefaultItem] = useState({ category: defaultValue?.causeCategory, desc: defaultValue?.cause })

    useEffect(() => {
        if ((defaultValue?.causeCategory)&&(defaultValue?.causeCategory === 'others')) {
            setDefaultItem({ category: defaultValue?.causeCategory, desc: defaultValue?.cause })
            getData('others', defaultValue?.cause)
        }
        defaultValue?.causeCategory !== 'others'&&listOptions?.forEach((x) => { //NOSONAR
            x?.options?.forEach((y) => { //NOSONAR
                if ((defaultValue?.cause)&&(y.key === defaultValue?.cause.toLowerCase())) {
                    setDefaultItem({ category: x.value, desc: y.value })
                }
            }) //NOSONAR
        }) //NOSONAR
        setHistCauseData(listOptions)
        if(defaultValue?.cause==='unabletodetermine'){
            getData('unabletodetermine', 'unabletodetermine')
            setIsToggleSwitch(1) 
        }
        if(defaultValue?.cause===1){
            //getData('unabletodetermine', 'unabletodetermine')
            setIsToggleSwitch(1) 
        }
        if(defaultValue?.cause&&document.getElementById(defaultValue?.cause+'0')&&defaultValue?.cause!=='unabletodetermine'){
            document.getElementById(defaultValue?.cause+'0').click()
         } 

    }, [])
    useEffect(() => {
            if(defaultItem?.desc&&document.getElementById(defaultItem?.desc+'0')&&defaultItem?.desc!=='Unable to determine'){
                document.getElementById(defaultItem?.desc+'0').click()
             }
            if(defaultItem?.desc==='Unable to determine'){
                setIsToggleSwitch(1) 
                if(document.getElementById('unabletodetermine')){
                    document.getElementById("unabletodetermine").checked = true;
                }
            }

    }, [defaultItem])



    const checkToggleSwitch = () => { 
        if(document.getElementById('otherName')){
            document.getElementById('otherName').value = '';
        }
        if(document.querySelector('#hist_caus_par input[type="radio"]:checked')){
            document.querySelector('#hist_caus_par input[type="radio"]:checked').checked = false;
        }
       
        if(isToggleSwitch === 0) {
          
            setIsToggleSwitch(1) 
            getData('unabletodetermine', 'unabletodetermine')
        }
        else {            
            setIsToggleSwitch(0)  
            getData('', '')
        }
    }
    const onChangeHistoryCause = (index,optionIndex) => { 
        setIsToggleSwitch(0) 
       getData(listOptions[index].key, listOptions[index].options[optionIndex].key)
       setDefaultItem({ category: listOptions[index].key, desc:listOptions[index].options[optionIndex].key })
       if(document.getElementById('otherName')){
        document.getElementById('otherName').value = '';
    }
   
    }

    const onChangeHistoryCauseTxt = (e) => { 
        getData('others', e.target.value)
        if(document.querySelector('#hist_caus_par input[type="radio"]:checked')){
            document.querySelector('#hist_caus_par input[type="radio"]:checked').checked = false;
        }
     }

    //let histCauseData=[{"key":"pi","value":"PI","options":[{"key":"poormobilitypressure","value":"Poor Mobility Pressure Injury/Ulcer"},{"key":"poormobilitywithshear","value":"Poor Mobility with Shear Pressure Injury/Ulcer "},{"key":"devicerelatedpressureinjuryulcer","value":"Device Related Pressure Injury/Ulcer"},{"key":"mucosalmembranepressureinjuryulcer","value":"Mucosal Membrane Pressure Injury/Ulcer"}]},{"key":"mmpi","value":"MMPI","options":[{"key":"mucosalmembranepressureinjuryulcer","value":"Mucosal Membrane Pressure Injury/Ulcer"}]},{"key":"other","value":"Other","options":[{"key":"abrasion","value":"Abrasion"},{"key":"bruise","value":"Bruise"},{"key":"burn","value":"Burn"},{"key":"cancerrelated","value":"Cancer related"},{"key":"circulationproblems","value":"Circulation Problems"},{"key":"cutaneousmanifestationofcovid19","value":"Cutaneous manifestation of COVID-19"},{"key":"diabetes","value":"Diabetes"},{"key":"fallrelated","value":"Fall Related"},{"key":"frictionrelated","value":"Friction related"},{"key":"incision","value":"Incision"},{"key":"incontinentofbowelorbladder","value":"Incontinent of bowel or bladder"},{"key":"laceration","value":"Laceration"},{"key":"medicalAdhesiverelatedskininjury","value":"Medical Adhesive related skin injury"},{"key":"moisturerelatedskindamage","value":"Moisture related skin damage"},{"key":"mucosalmembrane","value":"Mucosal Membrane"},{"key":"nailsfingertoes","value":"Nail(s) (Finger/Toes)"},{"key":"neuropathic","value":"Neuropathic"},{"key":"ostomyrelated","value":"Ostomy related"},{"key":"rash","value":"Rash"},{"key":"skintear","value":"Skin Tear"},{"key":"traumarelated","value":"Trauma"},{"key":"tubesanddrains","value":"Tubes and Drains"}]},{"key":"unabletodetermine","value":"Unable to determine","options":[{"key":"unabletodetermine","value":"Unable to determine"}]}]
    return (
        <>
        {/* Accordion start */}
        
{showCom&&showCom===true?
<div className={``} id={'hist_caus_par'}>
        {
          histCauseData && histCauseData.length > 0 && histCauseData.map((data, index) => {
            return (
			data.key==='pi'||data.key==='other'?
            <>
            <div key={index}>
              <Accordion
              histCause={true}
              defaultShow={defaultItem?.category&&defaultItem?.category.replace(/\s/g, '').toLowerCase()===data.key?1:0}
              classes={{ accBodyCust: `${styles.acc_body_cust}`,accHeadCust: `${styles.acc_head_cust}`,accBtnCust:`${styles.acc_btn_cust}`, accPanelCust:data?.key==='other'?`${styles.acc_panel_cust}`:''}}
                idControl={index}
                heading={
                  <div>
                    {
                      data.key==='pi'?<><div className={`${styles.cause_icon}`} ><img src={piCauseIcon} alt="Pressure Injury (PI)"   /><span> <b>Pressure Injury (PI)</b></span></div> </>  : <> <div className={`${styles.cause_icon}`}><img src={otherCauseIcon} alt="Other"    /><span> <b>Other</b></span></div></> 
                    }
                  </div>
                }
                content={<div>
                  {
                    data.options && data.options.length > 0 ? data.options.map((optionVal, optionIndex) => {

                      return <div key={optionIndex} className={`row m-0 ${styles.accordion_panel}`}>

                        <div className={`${styles.risk_block}`}>
                         {/* {optionVal.value} */}
                         <RadioButton
                                    id={optionVal?.value}
                                    name={name}
                                    classes={{
                                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                        inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                        inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[optionVal?.value]}
                                    validator={validator}
                                   // validator={register({ required: "Please select Yes or No" })}
                                    onChange={ e => onChangeHistoryCause(index, optionIndex)}
                                    defaultValue={defaultItem?.desc?defaultItem?.desc:''}
                                    disabled={isToggleSwitch===1?true:false}
                                />
                        </div>

                      </div>
                    }) : ''
                  }
                </div>
                }
              />
            </div>
            
            
            
            </>
			:
			''
            )
          })
        }
        <TextField
                                id='otherName'
                                name={`otherName${name}`}
                                label={``}
                                classes={{
                                    column:`${(classes) ? classes.column : ''}` ,
                                    labelClass: ``,
                                    inputColumn: `${(classes) ? classes.inputColumn+' col-12' : ''}`,
                                    inputClassName: `${(isToggleSwitch===1)?styles.other_name_dis:styles.basic_input}`
                                }}
                                type="search"
                                validator={validator}
                               onChange={ e => onChangeHistoryCauseTxt(e)}
                               disabled={isToggleSwitch===1?true:false}
                               placeholder={'Name it something else'}
                               defaultValue={defaultItem?.category==='others'&&defaultItem?.desc?defaultItem?.desc:''}
                            />

<div className={`col-6 custom-control custom-switch pl-0 py-2 d-flex mt-1 mb-2`}>
                                    <b>{`Unable to determine   `}</b>

                                    <div className={`${styles.toggle_shells}`}>                                    
                                    {/* <div className={`${styles.toggle_button_status}`}>{toggle ? "Verified" : "Not verified"}</div>  */}                                    
                                    <input 
                                        type="checkbox"
                                        name={name} 
                                        className="custom-control-input"
                                        id={"unabletodetermine"}
                                        data-testid={"toggleSwitch"}
                                        defaultValue = {isToggleSwitch}
                                        checked={isToggleSwitch===1?true:false}                                               
                                        onChange={checkToggleSwitch}
                                       
                                        ref={validator}
                                    />
                                    <label className={`custom-control-label ${styles.toggle_switch} ml-2 mb-1`} for="unabletodetermine"></label> 
                                    </div>
                                </div>
                               </div>

       : <div className={`${(classes) ? classes.column : ''}`} key={index}>

            <div className={`${(classes) ? classes.inputColumn : ''}`}>
                <div className={`dropdown ${styles.risk_select_box}`}>
                    <div className={`btn btn-secondary dropdown-toggle ${styles.drop_arrow} ${styles.dropdown_toggle} `} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={handleDropDown} ref={dropDownRef} id={id + 'dyn'}>
                        {
                            defaultValue?.cause ?
                                itemValueText === 'Select' ?
                                    <>
                                        <div><span className={`${styles.cat}`}>{defaultItem?.category ? defaultItem?.category : ''}</span> : {defaultItem?.desc ? defaultItem?.desc : ''}</div>
                                    </>
                                    :
                                    itemValueText
                                :
                                itemValueText
                        }

                    </div>
                    {viewFancyDropDown &&
                        <div className={`dropdown-menu show ${styles.risk_drop_down}`} aria-labelledby={id} ref={dropDownRef}>
                            {
                                listOptions && listOptions.length > 0 && listOptions.map((optionValues, ind) => {
                                    return (
                                        <>

                                            {optionValues?.options && optionValues?.options.length > 0 && optionValues?.options.map((sub, i) => {
                                                return <div className={`dropdown-item  ${styles.risk_item_wrap}`} onClick={e => handleDropChangeTreatMent(ind, i)} key={ind}>
                                                    <div><span className={`${styles.cat}`}>{optionValues.value ? optionValues.value : ''}</span> : {sub.value ? sub.value : ''}</div>
                                                </div>
                                            })}
                                        </>
                                    )
                                })
                            }
                        </div>
                    }

                </div>
            </div>
        </div>
        }
        </>
    );
}

FancyDropDown.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.any,
    type: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    validator: PropTypes.func,
    errors: PropTypes.object,
    onChange: PropTypes.func
}