import React, { useEffect } from 'react';
import cancel from './../../../static/icons/cancel.svg';
import warning from '../../../static/images/rbac_auth.svg';
import {
  popup,
  close_btn,
  ws_modal_xl,
  delete_conf,
  ws_modal_content,
  ws_modal_header,
  custom_title,
  main_content,
  opps_txt,
  opps_msg,
  opps_btn
} from './accessErrorModal.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setAccessError } from '../../../app/appSlice';

export const AccessErrorModal = () => {

  const dispatch = useDispatch();

  const accessError = useSelector(state => state.app.accessError)

  const handleAccessError = () => {
    dispatch(setAccessError(null))
  }

  useEffect(() => {
    if (accessError !== null) {
      document.body.classList.add('modal-open');
      return () => {
        document.body.classList.remove('modal-open');
      };
    }
  }, [accessError]);


  return (
    <>
      {accessError && <div
        className={popup}
        id="modalWindow"
      >
        {/* <div className="popup_inner"> */}
        <div className={`${ws_modal_xl} ${delete_conf}`}>
          <div className={`modal-dialog modal-dialog-scrollable modal-dialog-centered`} style={{ width: '800px' }}>
            <div className={`modal-content rounded-0 ${ws_modal_content}`}>
              {/* <div className={`modal-header ${ws_modal_header}`}>
                <h5 className={`modal-title ${custom_title}`}></h5>
                <button to="#" data-testid="close" onClick={handleAccessError} className={`${close_btn}`}>
                  <img src={cancel} alt="Close" />
                </button>
              </div> */}
              
              <div className={main_content}>
                <img src={warning} alt="Close" />
                
                <div className={`${opps_txt}`}>Oops…</div>
                <div className={`${opps_msg}`}>{accessError.toString()}</div>
                <button  data-testid="close" onClick={handleAccessError} className={`${opps_btn}`}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>}
    </>
  );
};
