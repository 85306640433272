import React from "react";
import PropTypes from 'prop-types';
import style from './radioButton.module.scss';

export function RadioButton({ classes, id='', name='', radioOption, defaultValue, validator, dataTestid, errors, disabled=false, onChange, onClick, lableSso }) {
    const optionData = radioOption && radioOption.map((value,index) =>
        <div className={classes && classes.inputColumn} key={index}>
            <input
                type="radio"
                id={id + index}
                data-testid={dataTestid}
                name={name}
                className={`${(classes) ? classes.inputClassName : ''} ${errors && errors[name] ? `${style.error_border}` : ''}`}
                value={value}
                ref={validator}
                onChange={onChange}
                onClick={onClick}
                defaultChecked={(value===defaultValue) ? true : false} disabled={disabled}/>
            <label htmlFor={id + index} key={index} className={classes && classes.labelClass}>
                {value}
            </label>
        </div>
    );  
    
    return (
        <div className={classes && classes.column}>
            {optionData}
            {/* Error */}
            {errors && <span className={!lableSso ? style.error_message : lableSso==="sso_token_facility" ? style.error_message_sso_token : style.error_message_sso}>{(errors[name]) ? errors[name].message:''}</span>}
        </div>
    );
}

RadioButton.propTypes = { 
    classes:PropTypes.object,
    name:PropTypes.string,
    radioOption:PropTypes.array,
    defaultValue:PropTypes.string,
    disabled:PropTypes.bool,
    validator:PropTypes.func,
    errors:PropTypes.object,
}
