import { password_hints, pass_condition, fail_condition } from '../../secondary/PasswordHint/passwordHint.module.scss'

export const PasswordHint = ({ minLength, oneNum, oneSpecialChar, oneUCase }) => {

    return <div className='pass_condition' >
        <ul className={password_hints}>
            <li className={minLength ? pass_condition : fail_condition} ><span /> Minimum of 8 characters </li>
            <li className={oneSpecialChar ? pass_condition : fail_condition} ><span />One special character </li>
            <li className={oneNum ? pass_condition : fail_condition} ><span /> One number </li>
            <li className={oneUCase ? pass_condition : fail_condition} ><span /> One upper case </li>
        </ul>
    </div>
} 