import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useLocation } from "react-router-dom";
import { withRouter } from 'react-router-dom'
import { RegistrationForm } from '../components'
import { registerAsync } from '../app/userSlice'
import PropTypes from 'prop-types'

export let Registration = ({dispatch, history}) => {  
    const paramLocationData = useLocation();
    const [regType, setRregType] = useState('custFacilityNumber');//custFacilityEmail
    const handleRegistration = (data, reset) => {
        dispatch(registerAsync(data, history, reset));
    }
    return <RegistrationForm onSubmit={handleRegistration} regType={regType} setRregType={setRregType} logEmail={paramLocationData?.state?.dynParam?.email.trim()} history={history}/>
}

Registration.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

Registration = connect()(withRouter(Registration))