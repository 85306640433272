import React, { useEffect } from 'react';
import { ViewTreatMent } from '../../secondary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDressingAsync, getlistOptionsAsync, saveDressingAsync } from '../../../app/patientSlice';
export const Compression = ({ interventionId, formularyId, assessmentDetails }) => {
  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const compressionGetData = useSelector(state => state.patient.compressionGetData);
  const compressionListOptions = useSelector(state => state.patient.compressionListOptions);
  let flag = false;
  const doSumit = (data) => {
    const payloadData = {
      "productCategory": data,
      'patientId': patientId,
      'assessmentId': assessmentId,
      'interventionId': interventionId,
      'payType': 'compression'
    }
    let compressionData = [];
    const compressionDatas = Array.isArray(data) && data?.map((item, index) => {//NOSONAR
      compressionData.push(item.value)
      return
    })//NOSONAR

    for (let i = 0; i < compressionData.length; i++) {
      if (compressionData[i] === undefined) {
        flag = false
      }
      if (compressionData[i] !== undefined) {
        flag = true;
        break;
      }
    }
      dispatch(saveDressingAsync(payloadData))
  }
  
  useEffect(() => {
    if (formularyId !== null) {
      const payload = {
        'formularyid': formularyId,
        'option': 'compression',
        payType: 'compression'
      }
      dispatch(getlistOptionsAsync(payload));
      dispatch(getDressingAsync({ patientId, assessmentId, interventionId, payType: 'compression' }));
    }
  }, [interventionId])
  return (
    <ViewTreatMent
      editData={compressionListOptions === null ? [] : compressionListOptions}
      viewData={compressionGetData === null ? [] : compressionGetData}
      saveData={doSumit}
      noDataMessage={"Your formulary doesn't have any Compression, please contact your Medline Sales Rep"}
      assessmentDetails={assessmentDetails}
    />
  )
}
