import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { familyCodeOption, productCategoryCodeAndMaterialGroupCodeOption, setProductEditStatus, updateMaterialAsync, updateProductFamilyAsync } from '../../../app/productSlice'
import {
  DIVISION_LBL, EDIT, ERROR_STATUS, GTIN_LBL, HCPSC_CODE_LBL, MANUFACTURER_LBL, MATERIAL_DESCRIPTION_LBL,
  MATERIAL_GROUP_CODE_LBL,
  MATERIAL_GROUP_DESCRIPTION_LBL, MATERIAL_NUMBER_LBL, MATERIAL_STATUS_LBL, PRODUCT_CATEGORY_CODE_LBL,
  PRODUCT_CATEGORY_DESCRIPTION_LBL,
  PRODUCT_FAMILY_CODE_LBL,
  PRODUCT_FAMILY_DESCRIPTION_LBL,
  PRODUCT_MANAGER_LBL, QUANTITY_LBL, SUCCESS_STATUS, UNIT_MEASURE, UNIT_OF_MEASURE_LBL, UPC_LBL, UPDATE_FAILURE, UPDATE_SUCCESS
} from '../../../constants'
import editIcon from '../../../static/images/edit-icon-blue.png'
import { onSubmitMaterialData, onSubmitProductData } from '../../../utils/productsHelper'
import styles from './basicInfoView.module.scss'
import { CreateEditMaterial } from './createEditMaterial'
import { CreateEditProductMaterial } from './createEditProductMaterial'
import { AccessErrorModal  } from '../../primary';
import Loader from '../../../static/images/spinner.svg';	

export function BasicInfoView({ updateCustomerData, productType }) {
  const productData = useSelector(state => state.product.basicViewDetailData);
  const { productId } = useParams()

  const [editable, setEditable] = useState(false)
  const handleEdit = () => {
    setEditable(!editable)
  }

  const productManagerOption = useSelector(state => state.product.productManager);

  const divisionCodeID=productData?.productDivisionCodeId;

  useEffect(() => {
    const payload={id:divisionCodeID,code:"materialGroupCode"}
    dispatch(productCategoryCodeAndMaterialGroupCodeOption(payload))
  }, [divisionCodeID])

  useEffect(() => {
    const payload={id:divisionCodeID,code:"productCategoryCode"}
    dispatch(productCategoryCodeAndMaterialGroupCodeOption(payload))
  }, [divisionCodeID])

  useEffect(() => {
    if(divisionCodeID!=='undefined' && productData?.productCategoryCodeId!=='undefined'){
        const payload={id:divisionCodeID,productCategoryCode:productData?.productCategoryCodeId}
        dispatch(familyCodeOption(payload));
    }
}, [divisionCodeID,productData?.productCategoryCodeId])

  
  const materialStatusOption = useSelector(state => state.product.materialStatus);
  const productDivision = useSelector(state => state.product.productDivision);
  const productCategoryCodeOption = useSelector(state => state.product.productCategoryCode);
  const materialGroupCodeOption = useSelector(state => state.product.materialGroupCode);
  const manufacturerOption = useSelector(state => state.product.manufacturer);
  const familyCodeOptionData = useSelector(state => state.product.familyCode);

  /* State for displaying product manager detials */
  let productManagerItems = [];
  /* Edit success */
  const editState = useSelector(state => state.product.productEditStatus)
  const dispatch = useDispatch()

  const [productManagerVal, setproductManagerVal] = useState([])
  const productManagerData = (data) => {
    setproductManagerVal(data);
  };

  /* On Submit */
  const onSubmit = (data) => {
    onSubmitProductData(data, productData, productManagerVal, productType, productId, dispatch, updateProductFamilyAsync)
    
  }

  useEffect(() => {
    if (editState === "success" || editState === "error") {
      setEditable(!editable)
      setTimeout(() => {
        dispatch(setProductEditStatus({}))
      }, 5000)
    }
  }, [editState]);

  const onSubmitUpdateMaterial=(data)=>{
    onSubmitMaterialData(data,productData, productType, productId, dispatch, updateMaterialAsync)
  }
  useEffect(() => {
    if(productId&&Number(productId)== 'NAN'){
     window.location.href = "/unAuthorized"
    }
  }, [productId])
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
  return (
    <>
    <AccessErrorModal />
      <div className={`${styles.tab_content_top}`}>
        <h2 className={`${styles.tab_content_hdg}`}>
          {(productData?.productFamilyDesc)? productData?.productFamilyDesc:productData?.materialDescription}
          {tinyDelay&&!editable &&
            <span className={`float-sm-right ${styles.tab_edit}`} onClick={handleEdit} >
              <img src={editIcon} alt="edit" />{EDIT}
            </span>
          }
        </h2>
      </div>
      {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
      <div className={`col-md-12 ${styles.single_product_block}`}>
        <div className="row">
          <div className={`p-0 ${styles.patient_content}`}>

            {!editable ?

              <>
                {/* Edit success */}
                {editState === SUCCESS_STATUS && <div className={`alert alert-success ${styles.form_alert}`}>{UPDATE_SUCCESS}</div>}

                {/* Edit failure */}
                {editState === ERROR_STATUS && <div className={`alert alert-danger ${styles.form_alert}`}>{UPDATE_FAILURE}</div>}


                {(productType === "productfamily") ?
                  <>
                    {/* Division */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{DIVISION_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {productDivision&&Array.isArray(productDivision)&&productDivision.map((item) => {
                                let value;
                                if(item.key === productData?.productDivisionCodeId){
                                    value = item.value;
                                }
                                return value;
                            })}
                        </span>
                      </div>
                    </div>


                    {/* Product Category Code */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_CATEGORY_CODE_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {Array.isArray(productCategoryCodeOption)&&productCategoryCodeOption?.map((item) => {
                                let value;
                                if(item.key === productData?.productCategoryCodeId){
                                    value = item.value;
                                }
                                return value;
                            })}
                        </span>
                      </div>
                    </div>
                    {/* Product Category Description */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_CATEGORY_DESCRIPTION_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {Array.isArray(productCategoryCodeOption)&& productCategoryCodeOption.map((item) => {
                                let value;
                                if(item.key === productData?.productCategoryCodeId){
                                    value = item.desc;
                                }
                                return value;
                            })}
                        </span>
                      </div>
                    </div>
                    {/* Product Family Code */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_FAMILY_CODE_LBL}: </label>
                      <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{productData?.productFamilyCode}</span></div>
                    </div>
                    {/* Product Family Description */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_FAMILY_DESCRIPTION_LBL}: </label>
                      <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{productData?.productFamilyDesc}</span></div>
                    </div>
                    {/* Product Manager */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_MANAGER_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                           {productData?.productManager?.forEach((data) => { //NOSONAR
                                productManagerOption?.forEach((item) => { //NOSONAR
                                    if(item.key === data) {             
                                        productManagerItems.push(item.value);  
                                    }
                                }); 
                            })}
                            {/* Display device type items array */}
                            {productManagerItems.join(', ')}
                        
                        </span>
                      </div>
                    </div>

                    <table className="table table-striped">
                      <thead>
                        <tr className={`${styles.table_head}`}>
                          <th scope="col">Material #</th>
                          <th scope="col">Description</th>
                          <th scope="col">Packaging</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productData && Array.isArray(productData?.materials) && productData?.materials.map((item) => {
                          return <>
                            <tr>
                              <td className={`${styles.anchor_tag}`}><a href={`/admin/products/material/${item.materialId}`}>{item.materialNumber}</a></td>
                              <td className={`${styles.material_content}`}>{item.materialDesc}</td>
                              <td className={`${styles.material_content}`}>{item.packageQuantity}</td>
                            </tr>
                          </>
                        })}
                      </tbody>
                    </table>
                  </>
                  :
                  <>
                    {/* Division */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{DIVISION_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {Array.isArray(productDivision) && productDivision.map((item) => {
                                let value;
                                if(item.key === productData?.productDivisionCodeId){
                                    value = item.value;
                                }
                                return value;
                            })}
                        </span>
                      </div>
                    </div>

                    {/* Material Number */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{MATERIAL_NUMBER_LBL}: </label>
                      <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{productData?.materialNumber}</span></div>
                    </div>

                    {/* Material Description */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{MATERIAL_DESCRIPTION_LBL}: </label>
                      <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{productData?.materialDescription}</span></div>
                    </div>

                    {/* Material Group Code */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{MATERIAL_GROUP_CODE_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {Array.isArray(materialGroupCodeOption)&& materialGroupCodeOption.map((item) => {
                                let value;
                                if(item.key === productData?.materialGroupCodeId){
                                    value = item.value;
                                }
                                return value;
                            })}
                        </span>
                      </div>
                    </div>

                    {/* Material Group Description */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{MATERIAL_GROUP_DESCRIPTION_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {Array.isArray(materialGroupCodeOption)&&materialGroupCodeOption.map((item) => {
                                let value;
                                if(item.key === productData?.materialGroupCodeId){
                                    value = item.desc;
                                }
                                return value;
                            })}
                        </span>
                      </div>
                    </div>

                      
                    {/* Product Category Code */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_CATEGORY_CODE_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {Array.isArray(productCategoryCodeOption)&&productCategoryCodeOption?.map((item) => {
                                let value;
                                if(item.key === productData?.productCategoryCodeId){
                                    value = item.value;
                                }
                                return value;
                            })}
                        </span>
                      </div>
                    </div>
                    {/* Product Category Description */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_CATEGORY_DESCRIPTION_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {Array.isArray(productCategoryCodeOption)&& productCategoryCodeOption.map((item) => {
                                let value;
                                if(item.key === productData?.productCategoryCodeId){
                                    value = item.desc;
                                }
                                return value;
                            })}
                        </span>
                      </div>
                    </div>
                    {/* Product Family Code */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_FAMILY_CODE_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                        {Array.isArray(familyCodeOptionData)&&familyCodeOptionData.map((data)=>{
                            let value;
                            if(data.key == productData.productFamilyCodeId){
                              value=data.value;
                            }
                          return <a href={`/admin/products/productfamily/${data.key}`}>{value}</a>
                        })
                        }
                        </span>
                      </div>
                    </div>
                    {/* Product Family Description */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_FAMILY_DESCRIPTION_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                        {Array.isArray(familyCodeOptionData)&&familyCodeOptionData.map((data)=>{
                            let value;
                            if(data.key == productData.productFamilyCodeId){
                              value=data.description;
                          }
                          return value
                        })
                        }
                        </span>
                      </div>
                    </div>
                    {/* Product Manager */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{PRODUCT_MANAGER_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                            {Array.isArray(familyCodeOptionData)&&familyCodeOptionData.map((data)=>{
                            let value;
                            if(data.key == productData.productFamilyCodeId){
                              value=data.productManager;
                            }
                            return value
                          })
                        }
                        </span>
                      </div>
                    </div>


                    {/* Manufactures */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{MANUFACTURER_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {Array.isArray(manufacturerOption)&& manufacturerOption.map((item)=>{
                            let value;
                            if(item.key === productData?.manufacturerNameId){
                                value = item.value;
                            }
                            return value;
                          })}
                        </span>
                      </div>
                    </div>
                    {/* Unit of measure */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{UNIT_OF_MEASURE_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                        {Array.isArray(UNIT_MEASURE)&& UNIT_MEASURE.map((item)=>{
                            let value;
                            if(item.key == productData?.salesUOMCodeId){
                                value = item.value;
                            }
                            return value;
                          })}
                        </span>
                      </div>
                    </div>
                    {/* Quantity*/}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{QUANTITY_LBL}: </label>
                      <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{productData?.salesUOMNumeratorQuantity}</span></div>
                    </div>
                    {/* GTIN */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{GTIN_LBL}: </label>
                      <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{productData?.gtinNumber}</span></div>
                    </div>
                    {/* UPC */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{UPC_LBL}: </label>
                      <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{productData?.upcCode}</span></div>
                    </div>
                    {/* HCPSC Code */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{HCPSC_CODE_LBL}: </label>
                      <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{productData?.hcpcScode}</span></div>
                    </div>
                    {/* Material Status */}
                    <div className={`col-10 ${styles.col_spec}`}>
                      <label className={`col-5 col-form-label ${styles.label_spec}`}>{MATERIAL_STATUS_LBL}: </label>
                      <div className="col-5 p-0">
                        <span className={`col-form-label ${styles.content_spec}`}>
                          {Array.isArray(materialStatusOption) && materialStatusOption.map((item) => {
                                let value;
                                if(item.key === productData?.materialStatusCodeId){
                                    value = item.value;
                                }
                                return value;
                            })}
                        </span>
                      </div>
                    </div>
                  </>
                }

              </>
              :
              <>
              {(productType === "productfamily") ?
              
              <CreateEditProductMaterial
                onSubmit={onSubmit}
                productData={productData}
                handleCancel={handleEdit}
                productType={productType}
                productManager={productManagerData}
                editable={editable}
              />
              :
              <CreateEditMaterial
              onSubmit={onSubmitUpdateMaterial}
              productData={productData}
              handleCancel={handleEdit}
              productType={productType}
              productManager={productManagerData}
              editable={editable}
            />
              
              }
              </>
            }

          </div>
        </div>
      </div>}
    </>
  )

}
