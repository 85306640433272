import React from 'react'
import styles from './closeWarning.module.scss'

export function CloseWarning({ message, yesFn, noFn,accessError }) {
    return (
        <div className={`${styles.warning} ${accessError?styles.acces_err_warning:''}`}>
            {/* Warning message */}
            <h6>{message}
                {accessError?<button onClick={noFn}>OK</button>:<><button onClick={yesFn}>Yes</button> <button onClick={noFn}>No</button></>}
            </h6>
        </div>
    )
}
