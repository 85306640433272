
import { Modal } from '../../secondary/Modal';
import style from './riskAssessmentScale.module.scss'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router';

export let RiskAssessmentScale = ({ modalClose, scaleType, score }) => {
    /* Dispatch "norton""braden" */
    const dispatch = useDispatch()
    return (
        <>
            {/* Modal */}
            <Modal
                modalOpen={true}
                modalClose={modalClose}
                heading={"Risk Assessment"}
                subHeading={((scaleType === 'norton') ? 'Norton' : 'Braden') + ` Score:`}
                subNumber={score}
                className={style.assessment_modal}
                modalSize={style.custom_modal_size}
            >
                <div className={style.spacing}>
                    <div className="col-sm-12 single-product-block p-0">
                        {scaleType && scaleType === 'norton' && <div className={`${style.popup_broden}`}>

                            <div className="col p-0">
                                <p className={`${style.risk_label}`}>Norton Scale</p>
                                <p className={`${style.risk_label} ${style.mt70}`}>Treatment</p>
                                <p className={`${style.risk_label} ${style.mt70}`}>Practice</p>
                            </div>
                            <div className={`col p-0 ${style.none_risk}`}>
                                <ul className={` ${style.wound_none}`}>

                                    <li className={` ${score && score === 20 ? style.active : ''}`}>20</li>
                                    <li className={` ${score && score === 19 ? style.active : ''}`}>19</li>
                                    <li className={` ${score && score === 18 ? style.active : ''}`}>18</li>
                                </ul>
                                <p className={`${style.risk_label}`}>Low Risk</p>
                                <p className={`${style.risk_text}`}>No additional pressure support required</p>
                                <ul className={`${style.risk_text_listing}`}>
                                    <li>- Educate - weight shifting, skin inspection</li>
                                    <li>- Evaluate on change of condition</li>
                                </ul>
                            </div>
                            <div className={`col p-0 ${style.mild_risk}`}>
                                <div className="d-flex">
                                    <div className="d-inline">
                                        <ul className={`${style.wound_mild}`}>

                                            <li className={` ${score && score === 17 ? style.active : ''}`}>17</li>
                                            <li className={` ${score && score === 16 ? style.active : ''}`}>16</li>
                                            <li className={` ${score && score === 15 ? style.active : ''}`}>15</li>
                                            <li className={` ${score && score === 14 ? style.active : ''}`}>14</li>
                                        </ul>
                                        <p className={`${style.risk_label}`}>Medium Risk</p>
                                    </div>

                                </div>
                                <p className={`${style.risk_text}`}>High specification foam mattress or static air overlay. Consider cushion for chair becicradle/gooseneck</p>
                                <ul className={`${style.risk_text_listing}`}>
                                    <li>- Reposition - weight-shifting, skin inspection</li>
                                    <li>- Promote activity</li>
                                    <li>- Manage individual risk factors nutrition; shear; friction; continence</li>
                                    <li>- Educate</li>
                                    <li>- Evaluate on change of condition</li>
                                </ul>
                            </div>
                            <div className={`col p-0 ${style.high_risk}`}>
                                <div className="d-flex">
                                    <div className="d-inline">
                                        <ul className={` ${style.wound_high}`}>
                                            <li className={` ${score && score === 13 ? style.active : ''}`}>13</li>
                                            <li className={` ${score && score === 12 ? style.active : ''}`}>12</li>
                                            <li className={` ${score && score === 11 ? style.active : ''}`}>11</li>
                                            <li className={` ${score && score === 10 ? style.active : ''}`}>10</li>
                                        </ul>
                                        <p className={` ${style.risk_label}`}>High risk</p>
                                    </div>
                                    <div className="d-inline">
                                        <ul className={`${style.wound_veryhigh}`}>
                                            <li className={` ${score && score === 9 ? style.active : ''}`}>09</li>
                                            <li className={` ${score && score === 8 ? style.active : ''}`}>08</li>
                                            <li className={` ${score && score === 7 ? style.active : ''}`}>07</li>
                                            <li className={` ${score && score === 6 ? style.active : ''}`}>06</li>
                                            <li className={` ${score && score === 5 ? style.active : ''}`}>05</li>
                                        </ul>
                                        <p className={`${style.risk_label}`}>Very High Risk</p>
                                    </div>
                                </div>

                                <p className={`${style.risk_text}`}>Dynamic air overlay, dynamic air cushion, dynamic mattress replacement or low air loss</p>
                                <ul className={`${style.risk_text_listing}`}>
                                    <li>All Plus</li>
                                    <li>- Supplement with small positional shifts</li>
                                    <li>- Seating posture assessment</li>
                                    <li>- Nutrition assessmenmt</li>
                                    <li>- Educate</li>
                                    <li>- Evaluate on change of condition</li>
                                </ul>
                            </div>

                        </div>
                        }
                        {scaleType && scaleType === 'braden' && <div className={`${style.popup_broden}`}>

                            <div className="col p-0">
                                <p className={`${style.risk_label}`}>Braden Score</p>
                                <p className={`${style.risk_label} ${style.mt70}`}>Treatment</p>
                                <p className={`${style.risk_label} ${style.mt70}`}>Practice</p>
                            </div>
                            <div className={`col p-0 ${style.none_risk}`}>
                                <ul className={` ${style.wound_none}`}>
                                    <li className={` ${score && score === 23 ? style.active : ''}`}>23</li>
                                    <li className={` ${score && score === 22 ? style.active : ''}`}>22</li>
                                    <li className={` ${score && score === 21 ? style.active : ''}`}>21</li>
                                    <li className={` ${score && score === 20 ? style.active : ''}`}>20</li>
                                    <li className={` ${score && score === 19 ? style.active : ''}`}>19</li>
                                </ul>
                                <p className={`${style.risk_label}`}>None</p>
                                <p className={`${style.risk_text}`}>No additional pressure support required</p>
                                <ul className={`${style.risk_text_listing}`}>
                                    <li>- Educate - weight shifting, skin inspection</li>
                                    <li>- Evaluate on change of condition</li>
                                </ul>
                            </div>
                            <div className={`col p-0 ${style.mild_risk}`}>
                                <div className="d-flex">
                                    <div className="d-inline">
                                        <ul className={`${style.wound_mild}`}>
                                            <li className={` ${score && score === 18 ? style.active : ''}`}>18</li>
                                            <li className={` ${score && score === 17 ? style.active : ''}`}>17</li>
                                            <li className={` ${score && score === 16 ? style.active : ''}`}>16</li>
                                            <li className={` ${score && score === 15 ? style.active : ''}`}>15</li>
                                        </ul>
                                        <p className={`${style.risk_label}`}>Mild</p>
                                    </div>
                                    <div className="d-inline">
                                        <ul className={`${style.wound_moderate}`}>
                                            <li className={` ${score && score === 14 ? style.active : ''}`}>14</li>
                                            <li className={` ${score && score === 13 ? style.active : ''}`}>13</li>
                                        </ul>
                                        <p className={`${style.risk_label}`}>Moderate</p>
                                    </div>
                                </div>
                                <p className={`${style.risk_text}`}>High specification foam mattress or static air overlay. Consider cushion for chair becicradle/gooseneck</p>
                                <ul className={`${style.risk_text_listing}`}>
                                    <li>- Reposition - weight-shifting, skin inspection</li>
                                    <li>- Promote activity</li>
                                    <li>- Manage individual risk factors nutrition; shear; friction; continence</li>
                                    <li>- Educate</li>
                                    <li>- Evaluate on change of condition</li>
                                </ul>
                            </div>
                            <div className={`col p-0 ${style.high_risk}`}>
                                <div className="d-flex">
                                    <div className="d-inline">
                                        <ul className={` ${style.wound_high}`}>
                                            <li className={` ${score && score === 12 ? style.active : ''}`}>12</li>
                                            <li className={` ${score && score === 11 ? style.active : ''}`}>11</li>
                                            <li className={` ${score && score === 10 ? style.active : ''}`}>10</li>
                                        </ul>
                                        <p className={` ${style.risk_label}`}>High</p>
                                    </div>
                                    <div className="d-inline">
                                        <ul className={`${style.wound_veryhigh}`}>
                                            <li className={` ${score && score === 9 ? style.active : ''}`}>09</li>
                                            <li className={` ${score && score === 8 ? style.active : ''}`}>08</li>
                                            <li className={` ${score && score === 7 ? style.active : ''}`}>07</li>
                                            <li className={` ${score && score === 6 ? style.active : ''}`}>06</li>
                                        </ul>
                                        <p className={`${style.risk_label}`}>Severe</p>
                                    </div>
                                </div>

                                <p className={`${style.risk_text}`}>Dynamic air overlay, dynamic air cushion, dynamic mattress replacement or low air loss</p>
                                <ul className={`${style.risk_text_listing}`}>
                                    <li>All Plus</li>
                                    <li>- Supplement with small positional shifts</li>
                                    <li>- Seating posture assessment</li>
                                    <li>- Nutrition assessmenmt</li>
                                    <li>- Educate</li>
                                    <li>- Evaluate on change of condition</li>
                                </ul>
                            </div>

                        </div>
                        }
                    </div>

                </div>
            </Modal>
        </>
    )
}

RiskAssessmentScale = connect()(withRouter(RiskAssessmentScale));

