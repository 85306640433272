import fetchIntercept from 'fetch-intercept';
import { toast } from 'react-toastify';

export const unregister = fetchIntercept.register({
    response: function (response) { 
        if (response.status === 405) {
            localStorage.removeItem('badReqMsg')
            return response;
        }      
        if (response.status === 400) {
            const data =  response.json();
            var promise = new Promise(function(resolve, reject) {
                resolve(data);
              })
              promise.then((data) =>{
                if(data&&data.message){
                toast.error(` ${data.message}`, {
                    toastId: 'warning1',
                    position: "top-right",
                    autoClose: true,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                   // pauseOnFocusLoss: false
                    });  

                    return response;
                }
                /*localStorage.removeItem('badReqMsg')
                  if(data&&data.message){
                    localStorage.setItem('badReqMsg',data.message)
                  }
                  window.location.href = "/unAuthorized"*/
              } )
             
        }
        if (response.status === 200 || response.status === 201) {
            return response;
        } else if(response.status === 401 || response.status === 403){
            localStorage.removeItem('badReqMsg')
            localStorage.removeItem('persist:DSHP_ROOT')
            localStorage.removeItem('DSHP_PERSISTENCE__signin_token');
            window.location.href = "/login"
        } else {
            return response;
        }
    }
});
