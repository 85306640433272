import { createSlice } from '@reduxjs/toolkit';
import { SUCCESS_STATUS } from '../constants';
import { Service } from '../service';
import { constructEndpoint } from '../utils/commonHelper';
import { setError, setLoading } from './appSlice';
import { format } from 'date-fns';
const { request } = Service;

export const workListSlice = createSlice({
    name: 'worklists',
    initialState: {
      newAdmissionPOAData:{},
      newAdmissionNONPOAData:{},
      newWoundsPOAData:{},
      newWoundsNONPOAData:{},
      piAssessmentPOAData:{},
      piAssessmentNONPOAData:{},
      otherAssessmentPOAData:{},
      otherAssessmentNONPOAData:{},
      woundExpertAssessmetPOAData:{},
      woundExpertAssessmetNONPOAData:{},
      woundExpertAssessmentDebridementData:{},
      completedAssessmentData:{},
      woundPhotoData:{}
      
    },
    reducers: {
      setNewAdmissionPOAData:(state,action)=>{
        state.newAdmissionPOAData=action.payload
      },
      setNewAdmissionNONPOAData:(state,action)=>{
        state.newAdmissionNONPOAData=action.payload
      },
      setNewWoundsPOAData:(state,action)=>{
        state.newWoundsPOAData=action.payload
      },
      setNewWoundsNONPOAData:(state,action)=>{
        state.newWoundsNONPOAData=action.payload
      },
      setPIAssessmentPOAData:(state,action)=>{
        state.piAssessmentPOAData=action.payload
      },
      setPIAssessmentNONPOAData:(state,action)=>{
        state.piAssessmentNONPOAData=action.payload
      },
      setOtherAssessmentPOAData:(state,action)=>{
        state.otherAssessmentPOAData=action.payload
      },
      setOtherAssessmentNONPOAData:(state,action)=>{
        state.otherAssessmentNONPOAData=action.payload
      },
      setWoundExpertAssementPOAData:(state,action)=>{
        state.woundExpertAssessmetPOAData=action.payload
      },
      setWoundExpertAssementNONPOAData:(state,action)=>{
        state.woundExpertAssessmetNONPOAData=action.payload
      },
      setWoundExpertAssementDebridementData:(state,action)=>{
        state.woundExpertAssessmentDebridementData=action.payload
      },

      setCompletedAssessmentData:(state,action)=>{
        state.completedAssessmentData=action.payload
      },
      setWoundPhotoData:(state,action)=>{
        state.woundPhotoData=action.payload
      },
    }
})
export const {
  setNewAdmissionPOAData,
  setNewAdmissionNONPOAData,
  setNewWoundsPOAData,
  setNewWoundsNONPOAData,
  setPIAssessmentPOAData,
  setPIAssessmentNONPOAData,
  setOtherAssessmentPOAData,
  setOtherAssessmentNONPOAData,
  setWoundExpertAssementPOAData,
  setWoundExpertAssementNONPOAData,
  setWoundExpertAssementDebridementData,
  setCompletedAssessmentData,
  setWoundPhotoData
} = workListSlice.actions;

// New Admission Pending Wound POA
export const getNewAdmissionsPOAAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.newAdmissions.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setNewAdmissionPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }
// New Admission Pending Wound NonPOA
export const getNewAdmissionsNONPOAAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.newAdmissions.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setNewAdmissionNONPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }
// Newwounds Pending Assessment POA
export const getNewWoundsPOAAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.newWounds.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setNewWoundsPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }
// New Wound Pending Assessment NonPOA
export const getNewWoundsNONPOAAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.newWounds.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setNewWoundsNONPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

// PI assessments Pending Review POA
export const getPIAssessmentsPOAAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.piAssessments.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setPIAssessmentPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }
// PI assessments Pending Review Non POA
export const getPIAssessmentsNONPOAAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.piAssessments.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setPIAssessmentNONPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

// Otherassessments Pending Review POA
export const getOtherAssessmentPOAAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.otherAssessments.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setOtherAssessmentPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }
 // Otherassessments Pending Review POA
export const getOtherAssessmentNONPOAAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.otherAssessments.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setOtherAssessmentNONPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

// Get wound expert assessments worklist //Wound Expert - PI-POA
export const getWoundExpertAssessmentPOA = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.expertAssessments.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setWoundExpertAssementPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }
  
// Get wound expert assessments worklist //Wound Expert - PI-NON-POA
export const getWoundExpertAssessmentNONPOA = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.expertAssessments.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setWoundExpertAssementNONPOAData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

// Get wound expert assessments worklist //Wound Expert - Debridement
export const getWoundExpertDebridement = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.expertAssessments.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setWoundExpertAssementDebridementData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }


// Get completed assessments worklist //Signed & Locked
export const getCompletedAssessment = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.completedAssessments.map((value)=>{
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
          value['admissionDate'] = (value.admissionDate) ? format(new Date(value.admissionDate.replace('Z', '')), 'MMMM dd, yyyy h:mm aa') : '';
          value['dischargeDate'] = (value.dischargeDate) ? format(new Date(value.dischargeDate), 'MMMM dd, yyyy h:mm aa') : '';
          value['lastAssessedDate'] = (value.lastAssessedDate) ? format(new Date(value.lastAssessedDate), 'MMMM dd, yyyy h:mm aa') : '';
        })
        dispatch(setCompletedAssessmentData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }
// Get Wound Photo worklist
export const getWoundPhotoAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        data.data.woundPhoto.map((value) => {
          value['dob'] = (value.dob) ? format(new Date(value.dob), 'MMMM dd, yyyy') : '';
        })
        dispatch(setWoundPhotoData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

  export default workListSlice.reducer;
