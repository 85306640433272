import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CANCEL_BTN, CANCEL_ID, SUBMIT_ID } from '../../../constants'
import { Button, RadioButton, TextArea } from '../../primary'
import Loader from '../../../static/images/spinner.svg';
import styles from './productDocument.module.scss'
import { updateDocumentList } from '../../../app/productSlice';
import { getFileName } from '../../../utils';
import { clearFileInputMove, fileTypeCheckerMove, fixBaseCodeMove, getBase64FromUrlMove, getBase64Move, handleDocChangeMove, handleUrlMove, pdfValidatorMove } from '../../../utils/productsHelper';

export function ProductDocument({ handleAddFileCancel, productType, documentList, getDocs, productId }) {

    const [radioOption, setRadioOption] = useState('Browse') // Radio option url or browse
    // const [inputList, setInputList] = useState([]) // List we get from API
    const [addedList, setAddedList] = useState([]) // The docuemnts we add 
    const [displayName, setDisplayName] = useState(null) // To display selected file name
    const [documentError, setDocumentError] = useState(false) // To dispaly document size
    const [serverError, setServerError] = useState(null) // Server error
    const [uploadSuccess, setUploadSuccess] = useState(false) // on Success

    const acceptedFileTypes = "application/pdf";

    const dispatch = useDispatch()

    const handleRadio = (e) => {
        setRadioOption(e.target.value)
    }

    // Function to clear file input
    const clearFileInput = () => {     
        clearFileInputMove(radioOption, setDisplayName)
    }

    useEffect(() => {
        clearFileInput()
    }, [radioOption])

    // Function to convert file to base 64. This function will return promise.
    function getBase64(file) {
        return getBase64Move(file)
    }

    // Function to check file size
    function pdfValidator(file) {
        return pdfValidatorMove(file)
    }

    // Function to remove base64 code prefix
    const fixBaseCode = (data) => {
        return fixBaseCodeMove(data)
    }

    const fileTypeChecker = (e) => {
        return fileTypeCheckerMove(e)
    }    

    const handleDocChange = (e) => {
        handleDocChangeMove(e, fileTypeChecker, setServerError, clearFileInput, addedList, setAddedList, pdfValidator, setDisplayName, getBase64, fixBaseCode, setDocumentError)
    };

    // State to show loading image until url fetches image
    const [urlLoading, setUrlLoading] = useState(false)

    // get base 64 from URL
    const getBase64FromUrl = async (url) => {
        return getBase64FromUrlMove(url, setUrlLoading, setServerError)
    }

    // Handle URL 
    const handleUrl = (e) => {
        handleUrlMove(e, addedList, setAddedList, getBase64FromUrl, setUrlLoading, fixBaseCode, getFileName)
    }

    // On submit 

    const onSubmit = () => {
        if(addedList && addedList.length === 0){
            setServerError("Please select PDF document to upload")
            setTimeout(() => {
                setServerError(null)
            }, [5000])
            return;
        }        
        const list = { ...documentList }
        if (list?.documents === null || list?.documents?.length === 0) {
            list['documents'] = [...addedList]
        }
        else if (list?.documents && list?.documents?.length > 0) {
            list['documents'] = [...list['documents'], ...addedList]
        }
        dispatch(updateDocumentList({ productId, productType, list, getDocs, setUploadSuccess, setServerError })).then(() => {
            clearFileInput()
            setAddedList([])
        }) // Dispatch goes here
    }

    const loading = useSelector(state => state.app.loading)

    return (
        <div className={styles.product_document}>
            <div className={styles.media_hdg}>
                Upload Document
            </div>
            <div className={styles.media_btm}>

                <div className={styles.specs}>
                    Max file size: 20MB <br /> Supported document format (PDF)
                </div>
                <h4>Upload Options</h4>
                {documentError && <div className={`alert alert-danger ${styles.alert_fix}`}>Document should be max 20MB.</div>}
                {serverError && <div className={`alert alert-danger ${styles.alert_fix}`}>{serverError}</div>}
                {uploadSuccess && <div className={`alert alert-success ${styles.alert_fix}`}>File has been uploaded successfully.</div>}
                <RadioButton
                    id={"productType"}
                    name={"productType"}
                    classes={{
                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                        inputClassName: `custom-control-input ${styles.custom_control_input} productType`,
                        inputColumn: `custom-control custom-radio custom-control-inline`
                    }}
                    radioOption={['Browse', 'URL']}
                    defaultValue={'Browse'}
                    onChange={handleRadio}
                />

                <div className={styles.upload_block}>
                    {loading ? <div className={styles.loader}>
                        <img src={Loader} alt="loading loader" />
                    </div>
                        :
                        <>
                            {radioOption === 'Browse' && <div className={styles.file_block}>
                                <label htmlFor={"pdf"} className={styles.file_upload}>
                                    Choose File
                                </label>
                                <input className={"d-none"} type="file" id={"pdf"} name="pdf" accept={acceptedFileTypes} onChange={handleDocChange} />
                                <h6>{displayName ? displayName : 'No File Chosen'}</h6>
                            </div>}
                            {radioOption === 'URL' && <div className={styles.url_block}>
                                <TextArea
                                    id={"urlField"}
                                    name={"urlField"}
                                    classes={{ inputClassName: styles.text_area }}
                                    onChange={handleUrl}
                                    placeholder={"https://www.medline.com/pages/1496.pdf"}
                                />
                            </div>}
                        </>
                    }
                </div>

                <div className="text-center pt-3">
                    {urlLoading && <div className={styles.loader}>
                            <img src={Loader} alt="loading" />
                            <br />
                    </div>}
                    {/* Save Button */}
                    <Button
                        classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small} ${loading && styles.uploading_btn}` }}
                        type="submit"
                        id={SUBMIT_ID}
                        onClick={onSubmit}
                    >
                        {loading ? 'Uploading...' : 'Upload'}
                    </Button>
                    {/* Cancel button  */}
                    <Button
                        classes={{ className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                        id={CANCEL_ID}
                        onClick={handleAddFileCancel}
                    >
                        {CANCEL_BTN}
                    </Button>
                </div>

            </div>

        </div>
    )
}
