import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import styles from './treatmentDropDownItem.module.scss';
import tick from '../../../static/images/check-mark.png'
export const TreatmentDropDownItem = ({ dressing, index, id = '', name = '', label = '', validator, listOptions, required = false, classes, getData, viewScreen, defaultValue, dropValue, setGetValueDrop}) => {
    const [itemValueText, setitemValueText] = useState('Select');
    const [viewDropDown, setDropDown] = useState(false)
    const [chckDefaultValue, setChckDefaultValue] = useState(defaultValue)

    useEffect(()=>{
        if(dressing === 'secondary' && dropValue === true){
            setChckDefaultValue('')
        }
        else{
            setChckDefaultValue(defaultValue)
        }
    },[dropValue, dressing])

    //defaultValue
    /* Function to toggle drop assessment*/
    const handleDropDown = (e) => {
        setDropDown(!viewDropDown)
    }

    /* Trigger Drop Down Items close */
    const dropDownRef = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const handleClickOutside = (e) => {
        if (dropDownRef !== null && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
            setDropDown(false);
        }
    };

    const handleDropChangeTreatMent = (index, optionValues) => {
        if(dressing === 'secondary' && dropValue === true){
            setGetValueDrop(false)
        }
        setChckDefaultValue('')
        setitemValueText(
            <>
                <div className={`${styles.cat}`}>{optionValues.productFamilyDesc ? optionValues.productFamilyDesc : 'Select'}</div>
                <div className={`${styles.material_description}`}><div>{optionValues?.materialDescription ? optionValues?.materialDescription : ''}</div>{optionValues?.materialDescription&&<div className={`${styles.tick_div} ml-2`}><img src={tick} alt="tick"/></div>}</div>
                {dressing !== "secondary" && optionValues?.isRecommended === 1 ? <div className={`${styles.recommended}`}>
                    Recommended
                </div> : ''}
                <div className={`${styles.material_code}`}>{optionValues?.materialCode}</div>
            </>
        )
        setDropDown(false);
        getData(index,name, optionValues.materialNumber, optionValues.isRecommended)
    }
    
    const getVal = listOptions?.find((x) => x.materialNumber === defaultValue)

    return ( 
        <div className={`${(classes) ? classes.column : ''} mouse-Down`} key={index}>
            {label &&
                <label
                    htmlFor={id}
                    className={`${classes.labelClassName} 
                    ${styles.text_field_label}`}>{`${label}:`}
                    {required ? <span className={styles.astrick}>&#42;</span> : ''}
                </label>
            }
            <div className={`${(classes) ? classes.inputColumn : ''}`}>
                <div className={`dropdown ${styles.risk_select_box}`}>
                    <div className={`btn btn-secondary dropdown-toggle testdropdown ${styles.drop_arrow} ${styles.dropdown_toggle} `} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={handleDropDown} ref={dropDownRef} id={id + 'dyn'}>
                        {
                            chckDefaultValue !== "" ?
                                <>
                                    <div className={`${styles.cat}`}>{getVal?.productFamilyDesc ? getVal?.productFamilyDesc : 'Select'}</div>
                                    <div className={`${styles.material_description}`}><div>{getVal?.materialDescription ? getVal?.materialDescription : ''}</div>{getVal?.materialDescription&&<div className={`${styles.tick_div} ml-2`}><img src={tick} alt="tick"/></div>}</div>
                                    {dressing !== "secondary" && getVal?.isRecommended === 1 ? <div className={`${styles.recommended}`}>
                                        Recommended
                                    </div> : ''}
                                    <div className={`${styles.material_code}`}>{getVal?.materialCode}</div>
                                </>
                                : itemValueText
                        }

                    </div>
                    {viewDropDown &&
                        <div className={`dropdown-menu show ${styles.risk_drop_down}`} aria-labelledby={id} ref={dropDownRef}>
                         <>
                                            <div className={`dropdown-item  ${styles.risk_item_wrap}`} id = {`handle_change`} onClick={e => handleDropChangeTreatMent(index, '')}>
                                                <div className={`${styles.cat}`}>Select</div>
                                               
                                            </div>
                                        </>
                            {
                               listOptions  && listOptions.length > 0 && listOptions.map((optionValues, ind) => {
                                    return (
                                        <>
                                        <div className={`dropdown-item  ${styles.risk_item_wrap} ${getVal?.materialNumber===optionValues.materialNumber ? styles.risk_item_wrap_selected : ''}`} id = {`handle_change`} onClick={e => handleDropChangeTreatMent(index, optionValues)} key={ind}>
                                            <div className={`${styles.cat}`}>{optionValues.productFamilyDesc ? optionValues.productFamilyDesc : ''}</div>
                                            <div className={`${styles.material_description}`}><div>{optionValues.materialDescription ? optionValues.materialDescription : ''}</div>{getVal?.materialNumber===optionValues.materialNumber&&<div className={`${styles.tick_div} ml-2`}><img src={tick} alt="tick"/></div>}</div>
                                            {dressing !== "secondary" && optionValues?.isRecommended === 1 ? <div className={`${styles.recommended}`}>
                                                Recommended
                                            </div> : ''}
                                            <div className={`${styles.material_code}`}>{optionValues?.materialCode}</div>
                                        </div>

                                    </>              )
                                })
                            }
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

TreatmentDropDownItem.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.any,
    type: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    validator: PropTypes.func,
    errors: PropTypes.object,
    onChange: PropTypes.func
}