import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ActionModal } from '../../secondary'
import { Organizational, RadioButton, CheckBox, SelectBox, TextField, Form, Button, MultiSelect } from '../../primary'
import styles from './createSso.module.scss'
import { connect,useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CreateEditSso } from './createEditSso'
import { getCustomerNumberSuggestions, getEmailSuggestions, setEmailSearch, setCustomerNumberSearch, setCustomerFacilities, setResMsgState, createSsoAsync, getFaciltiestUserAsyn, getFaciltiestCustomerAsyn, setUserFacilities} from '../../../app/userSlice'
import iconSuccess from '../../../static/images/icons-check-success.png'
import { SAVE_BTN, SUBMIT_ID, CANCEL_BTN, CANCEL_ID, SUCCESS_STATUS, ERROR_STATUS, SSO_CREATED_MSG, ROLE_ID, ROLE_LBL, CUSTOMER_NUMBER_ID } from '../../../constants';
import { getFacilityLabel, onSubmitSsoData,checkPrimaryGroup, checkDominaName, checkTenantID, checkDepartmentName, checkTitleName, checkIncExcType, checkAssignToAllEmails, checkFacilities, checkSpecificEmail, useDebounce, getFacilityLabelUrl, ServiceError, checkTokenizedFacility } from '../../../utils';
import closeNormal from '../../../static/images/Close_Normal.svg';
import { clearError } from "../../../app/appSlice";

export let CreateSso = ({ dispatch, modalOpen, modalClose }) => {

  const { reset, register, handleSubmit, control, errors, setError } = useForm({
    mode: 'onSubmit'
  });

  useEffect(() => {
    dispatch(setCustomerFacilities({}));
  }, [dispatch])

  useEffect(() => {
    dispatch(setResMsgState({}));
  }, [dispatch])  

  const ErrorMessage = ServiceError();
  useEffect(() => {
    if (ErrorMessage==="Tenant and Primary Group mapping already exists") {
      setError("tenant", {
        type: "manual",
        message: "Tenant and Primary group mapping already exists"
      });
      dispatch(clearError());
    }    
  }, [ErrorMessage,dispatch]);

  const [createUser, setCreateUser] = useState(false)
  const [onselectOrgz, setonselectOrg] = useState([]);

  const [msgModal, setMsgModal] = useState(false);
  const resMsgState = useSelector(state => state.user.resMsgState);

  const role = useSelector(state => state.user.role);
  const customerNumber = useSelector(state => state.user.customerNumber);  

  const [errMsg, seterrMsg] = useState(false);
   
  useEffect(() => {
    if(role === 'ExternalAdministrator'){
      dispatch(getFaciltiestCustomerAsyn({customer: customerNumber}));
    }
  }, [role, customerNumber, createUser])


  const modalRefresh = () => {
    modalClose();
    setCreateUser(false);
    setMsgModal(false);
    setErrorMsg("");
    setonselectOrg([]);
    if(role !== 'ExternalAdministrator'){ dispatch(setCustomerFacilities({})); }
    dispatch(setResMsgState({}));
    seterrMsg(false);
    setCheckStatus(1)
    setDisState(false) //added for SSO
    setTypeVis(false) // added for SSO
    setGetTypeVal("")
    setSearchKeyWord([])
    setMakeDisable(true)
    setErrorMesg('')
    dispatch(setCustomerNumberSearch({}));

    setTenantMake(false)
    setDepartmentMake(false)
    setTitleMake(false)
    setAndOptionState(false)
    setOrOptionState(false)
    setMakeTitleAlone(false)
    setGetAndOpt(false)
    setCounterChange(0)
  }

  const handleCancel = (e, resets) => {
    reset();
    e.preventDefault();
    if (resets) {
      resets();
    }
    setMsgModal(false)
    modalClose();
    setCreateUser(false);
    setMsgModal(false);
    setErrorMsg("");
    setonselectOrg([]);
    if(role !== 'ExternalAdministrator'){ dispatch(setCustomerFacilities({})); }
    dispatch(setResMsgState({}));
    seterrMsg(false);
    setCheckStatus(1)
    setDisState(false) //added for SSO
    setTypeVis(false) // added for SSO
    setGetTypeVal("")
    setSearchKeyWord([])
    setMakeDisable(true)
    setErrorMesg('')
    dispatch(setCustomerNumberSearch({}));

    setTenantMake(false)
    setDepartmentMake(false)
    setTitleMake(false)
    setAndOptionState(false)
    setOrOptionState(false)
    setMakeTitleAlone(false)
    setGetAndOpt(false)
    setCounterChange(0)
  }
  
  const [isValidSaleCust, setIsValidSaleCust] = useState(false)
  const [domainPrimary, setDomainPrimary] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)

  const [checkStatus, setCheckStatus] = useState(1)
  const [tokenzState, setTokenzState] = useState(0)  

  // const [checkValidation, setCheckValidation] = useState(false)
  // useEffect(()=>{
  //   let assigntospecificemailValue = searchKeyWord.filter(item=>item !== "")
  //   if(checkValidation){
  //     if(onselectOrgz.length===0){
  //       setIsValidSaleCust(true)
  //       return false
  //     }
  //     if(assigntospecificemailValue.length===0){
  //       setShowEmailError(true)
  //       return false
  //     }
  //     return false
  //   }
  // },[checkValidation])

  const onSubmitUser = (data) => {
    if(tokenzState){
      let finalData = {
        "ssodomainname": data.ssodomainname,
        "role" : data.roleID,
        "isActiveStatus": checkStatus,
        "isSSOEnable":checkStatus,
        "isTokenizedFacility":tokenzState,
        "tenant": null,
        "department" : null,
        "title" : null,
        "primarygroup": null,
        "facilities":[],
        "assigntoallemails": 1,
        "assigntospecificemails": [],
        "excludetospecificemails": null,
      }
      onSubmitSsoData(finalData, dispatch, createSsoAsync, setMsgModal, modalRefresh, reset, resMsgState, role)
      setCreateUser(false);
    }
    if(!tokenzState){
      let assigntospecificemailValue = searchKeyWord?.filter(item=>item !== "")
      //for Validation
      if(data.ssodomainname === ""){
        setDomainPrimary(true)
        return false
      }   
      if(onselectOrgz.length === 0){
        setIsValidSaleCust(true)
        return false
      }
      if(data.tableTBD === "Include" && data.assigntoallemails==='No'){
        if(assigntospecificemailValue.length === 0){
          setShowEmailError(true)
          return false
        }
      }
      if(data.tableTBD === "Exclude"){
        if(assigntospecificemailValue.length === 0){
          setShowEmailError(true)
          return false
        }
      } //for Validation  
      let finalData = {
        "ssodomainname": data.ssodomainname,
        "tenant": data.tenant !== undefined ? data.tenant : null,
        "department" : data.department !== undefined ? data.department : null,
        "title" : data.title !== undefined ? data.title : null,
        "role" : data.roleID,
        "primarygroup": data.customerNumber,
        "facilities":onselectOrgz.length > 0 ? onselectOrgz.map(val=>val?.facilityNumber) : "",
        "assigntoallemails": data.tableTBD === "Include" ? data.assigntoallemails==='Yes' ? 1 : 0 : 0,
        "assigntospecificemails": data.tableTBD === "Include" && data.assigntoallemails==='No' ? assigntospecificemailValue : [],
        "excludetospecificemails": data.tableTBD === "Exclude" ? assigntospecificemailValue : "",
        "isActiveStatus": checkStatus,
        "isSSOEnable":checkStatus,
        "isTokenizedFacility":tokenzState
    }   
      onSubmitSsoData(finalData, dispatch, createSsoAsync, setMsgModal, modalRefresh, reset, resMsgState, role)
      setCreateUser(false);
    }
  }
  
  const facilitiesData = useSelector(state => state.user.customerFacilities);
  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {
    if(role !== 'ExternalAdministrator'){
      let fac = ""
      if (facilitiesData?.errorMessage) {
        fac = "Customer number doesn’t match our record"
      } else if (facilitiesData?.data !== undefined) {
        if (optionData.length === 0) {
          fac = "Organization mapping is missing for the customer"
        }
      }
      setErrorMsg(fac)
    }
  }, [facilitiesData])

  const onSelectOrg = (selectedList) => {
    setonselectOrg([...selectedList])
    seterrMsg(false)
  }

  useEffect(()=>{
    if(onselectOrgz.length > 0 || onselectOrgz.length !== 0){
      setIsValidSaleCust(false)
    }
  },[onselectOrgz])

  // useEffect(() => {
  //   // if(role === 'ExternalAdministrator'){
  //   //   // optionData.length > 0 ? setOrg(true) : setOrg(false)
  //   //   //onSelectOrg(optionData)
  //   // }
  // },[modalOpen,optionData])


  useEffect(() => {
    if (resMsgState === "success") {
      setMsgModal(true)
      setTimeout(() => {
        reset();
        modalRefresh()
        setMsgModal(false)
        dispatch(setResMsgState({}))
      }, 4000)
    }
  }, [resMsgState]);

// for SSO  

 //get user role data from state
 const userRoleData = useSelector(state => state.user.userRole); 
 // convert key value pair instead of name and label. 
 const convertedUserRoleData = (Object.keys(userRoleData).length !== 0) ? userRoleData.map(data => ({ key: data.name, value: data.label })) : '';;

const customerNumberSearchDatas = useSelector((state) => state.user.customerNumberSearch);

const emailSearchData = useSelector((state) => state.user.emailSearch)

  const [errorMesg, setErrorMesg] = useState(false);
  useEffect(()=>{   
   if(emailSearchData !== undefined || emailSearchData !== null){
      if(emailSearchData?.errorMessage === "Enter your email address in format: yourname@example.com"){
        setErrorMesg('Enter a valid domain name')
      }  
      else{
        setErrorMesg('')
      }      
    }       
  },[emailSearchData])


 /* CustomerNumber search Suggestions  */   
 const [custNumInput, setCustNumInput] = useState("");
 const [isSelect, setIsSelect] = useState(false);
 const searchTerm = useDebounce(custNumInput, 500); 

 useEffect(() => {
     if (!isSelect && searchTerm && searchTerm.length >= 1) {
       dispatch(getCustomerNumberSuggestions(searchTerm));
     } else {
       dispatch(setCustomerNumberSearch({}));
     }
   }, [searchTerm]);  


//Facilities dropdown
const [checkOrgDrop, setCheckOrgDrop] = useState(false);
const [makeFacErro, setMakeFacError] = useState(false)
  const getCustomerNumber = (val) => {         
    setonselectOrg([]);
    setCheckOrgDrop(false) 
    dispatch(setCustomerFacilities({}));    
    if(val === ''){
        // setErrorMsg("Enter your primary group number")
    }
    else{ 
        setErrorMsg('') 
        setonselectOrg([]);        
        const payload = { customer: val }
        dispatch(getFaciltiestUserAsyn(payload,setCheckOrgDrop,setErrorMsg)); 
        setMakeFacError(true)     
        setCheckOrgDrop(true)             
    }
}

  const getCustomerNumberSearch = (event) => {  
    if(event === ''){
        // setErrorMsg("Enter your primary group number")
        setCustNumInput("")
    }else {         
        setCustNumInput(event)
        setIsSelect(false);
        setErrorMsg('')  
        setMakeFacError(true)                      
    }  
  };   
  
  const customerNumberSelect = (e, items) => {
    e.stopPropagation();  
    dispatch(setCustomerNumberSearch({}));     
    setIsSelect(true);
    setonselectOrg([]);
    setCheckOrgDrop(false) 
    dispatch(setCustomerFacilities({}));                  
    if (items) {
      setErrorMsg('') 
      setCheckOrgDrop(true)  
      setMakeFacError(true)      
      const customerNumber = items;
      setCustNumInput(customerNumber);
      document.getElementById(CUSTOMER_NUMBER_ID).value = customerNumber;
      document.getElementById(CUSTOMER_NUMBER_ID).value = items;                   
      const payload = { customer: items }
      dispatch(getFaciltiestCustomerAsyn(payload, setCheckOrgDrop));                  
    }      
  }

  const ClearData = (value) => { 
    document.getElementById(CUSTOMER_NUMBER_ID).value = null ;        
    setonselectOrg([]);
    setCheckOrgDrop(false) 
    dispatch(setCustomerFacilities({}));  
    setCustNumInput("");
}


  let optionData = [];
  const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
  soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
    const shipToFacilities = facilitiesData.data.facilities?.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
    if(shipToFacilities.length===0){
      optionData.push({
          cat: getFacilityLabel(soldToFacility),
          key: getFacilityLabel(soldToFacility),
          facilityNumber: soldToFacility.facilityNumber,
          soldToFacility: 'soldToFacility'
      })
     }
    shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
      optionData.push({
        cat: getFacilityLabel(soldToFacility),
        key: getFacilityLabel(shipToFacility),
        facilityNumber: shipToFacility.facilityNumber,
        shipToFacilities: 'shipToFacilities'
      })
    })
  })

//Disable Senario for Include/Exclude
const [disState, setDisState] = useState(false)
const [showEmailCheckbox, setShowEmailCheckbox] = useState(true)
const [makeDisable, setMakeDisable] = useState(true)
const [typeVis, setTypeVis] = useState(false)
const [showMand, setShowMand] = useState(false)

const [getTypeVal, setGetTypeVal] = useState("")

const getTokenState = (value) => {
  setShowEmailCheckbox(true)
  document.getElementById("tableTBD0").checked= false
  document.getElementById("tableTBD1").checked= false
  document.getElementById(CUSTOMER_NUMBER_ID).value = null ;
  document.getElementById("tenant").value = null ;   
  setMakeDisable(true) 
  setSearchKeyWord([]) 
  dispatch(setCustomerFacilities({}));
  dispatch(setCustomerNumberSearch({}));
  dispatch(setUserFacilities([])) 
  setonselectOrg([]); 
  setCheckOrgDrop(false)   
  setCustNumInput(""); 
  document.getElementById("department").value= null
  document.getElementById("title").value= null
  document.getElementById("yes_no1").checked= false
  document.getElementById("yes_no2").checked= false
  setTenantMake(false)
  setDepartmentMake(false)
  setTitleMake(false)
  setAndOptionState(false)
  setOrOptionState(false)
  setMakeTitleAlone(false)
  setGetAndOpt(false)
  setCounterChange(0)
  // setToDo('')
  if(value==='Yes'){
    setTokenzState(1)
    setDisState(false)
    setTypeVis(false)   
  }
  if(value==='No'){
    setTokenzState(0)
    setTypeVis(true)
    setGetTypeVal("")     
  }
}


const getState=(value) =>{
  setDisState(true)
  setSearchKeyWord([])  
  if(value==='Include'){  
    setGetTypeVal(value)
    setShowEmailCheckbox(true)
    document.getElementById(CUSTOMER_NUMBER_ID).value = null ;
    document.getElementById("tenant").value = null ;
    document.getElementById("department").value = null ;
    document.getElementById("title").value = null ; 
    setMakeDisable(true)        
    setonselectOrg([]);
    setCheckOrgDrop(false) 
    dispatch(setCustomerFacilities({}));
    dispatch(setCustomerNumberSearch({}));
    setShowMand(false)
    setMakeTitleAlone(false)
    document.getElementById("department").value= null
    document.getElementById("title").value= null
    document.getElementById("yes_no1").checked= false
    document.getElementById("yes_no2").checked= true
    setTenantMake(false)
    setDepartmentMake(false)
    setTitleMake(false)
    setAndOptionState(false)
    setOrOptionState(true)
    setGetAndOpt(false)
    setCounterChange(0)
  }
  if(value==='Exclude'){
    setGetTypeVal(value)
    setShowEmailCheckbox(false)
    setMakeDisable(false)
    document.getElementById(CUSTOMER_NUMBER_ID).value = null ;
    document.getElementById("tenant").value = null ;    
    document.getElementById("department").value = null ;
    document.getElementById("title").value = null ;      
    setonselectOrg([]);
    setCheckOrgDrop(false) 
    dispatch(setCustomerFacilities({}));
    dispatch(setCustomerNumberSearch({})); 
    setShowMand(false)
    setMakeTitleAlone(false) 
    document.getElementById("department").value= null
    document.getElementById("title").value= null
    document.getElementById("yes_no1").checked= false
    document.getElementById("yes_no2").checked= true
    setTenantMake(false)
    setDepartmentMake(false)
    setTitleMake(false)
    setAndOptionState(false)
    setOrOptionState(true)
    setGetAndOpt(false)
    setCounterChange(0)
  }
  if(value==='Yes'){
    setMakeDisable(true)
    setShowMand(false)
    setHiddenInputEmail(false)
  }
  if(value==='No'){
    setMakeDisable(false)
    setShowMand(true)
    setHiddenInputEmail(true)
  }
}

useEffect(()=>{
  if(getTypeVal ===""){
    setDisState(false)
  }
  else{
    setDisState(true)
  }
},[getTypeVal])

const handleClickStatus = () => {
  if(checkStatus===0){
    setCheckStatus(1)
  }
  if(checkStatus===1){
    setCheckStatus(0)
  }
}


/* Email search Suggestions  */   
const [emailInput, setEmailInput] = useState("");
const [isEmailSelect, setIsEmailSelect] = useState(false);
const searchEmailTerm = useDebounce(emailInput, 500);
const [checkOrgDropEmail, setCheckOrgDropEmail] = useState(false)

const getEmailSearch = (event) => {  
  if(event === ''){
      // setErrorMsg("please enter any email")
      setEmailInput("")
      setDomainPrimary(true)
  }else {          
      setEmailInput(event)
      setIsEmailSelect(false);
      // setErrorMsg('')   
      setDomainPrimary(false)                                        
  }  
};

const getEmail = (val) => {   
  // setCheckOrgDropEmail(true) 
  if(val === ''){
      // setErrorMsg("please enter any email")
      setDomainPrimary(true)
  }
  else{  
      // setErrorMsg('')     
      setDomainPrimary(false)            
  }   
}

const emailSelect = (e, items) => {
  e.stopPropagation();       
  setIsEmailSelect(true);
  dispatch(setEmailSearch({}));
  setCheckOrgDropEmail(true) 
  if (items) {
    setDomainPrimary(false)   
    setErrorMsg('')         
    const customerNumber = items;
    setEmailInput(customerNumber);
    document.getElementById("ssodomainname").value = customerNumber;
    document.getElementById("ssodomainname").value = items;                     
  }      
}

useEffect(() => {
  if (!isEmailSelect && searchEmailTerm && searchEmailTerm.length >= 1) {
    dispatch(getEmailSuggestions(searchEmailTerm));
  } else {
    dispatch(setEmailSearch({}));
  }
}, [searchEmailTerm]);



//Multiple Email 
const [searchKeyWord, setSearchKeyWord] = useState([]);
  const [newKeySearchKey, setNewSearchKey] = useState(true);

  // const addNewSearchKeyWord = () => {
  //   setNewSearchKey(true)
  //   document.getElementById("keyword")?.focus();
  // };
  // useEffect(() => {
  //   setSearchKeyWord(userData?.assignToSpecificEmail);
  // }, [userData, dispatch]);

  const removeKeyword = (index) => {
        const deleteData = [...searchKeyWord]
        deleteData.splice(index, 1);
        setSearchKeyWord(deleteData);
  }

  const onBlurChangeSearchKeyWord=(value)=>{
      const convertSearchValue= value.split(',');
      let splitedNewArray =[]
      if(convertSearchValue){
        Array.isArray(convertSearchValue)&&convertSearchValue?.map(arrayValue=>{//NOSONAR
          splitedNewArray.push(arrayValue)
      })//NOSONAR
      let newSearch=[...searchKeyWord,...splitedNewArray]
      //duplicate remove
      const duplicateRemovedData=newSearch?.filter((item,index)=>newSearch.indexOf(item)===index)
      setSearchKeyWord(duplicateRemovedData);
      document.getElementById("keyword").value = ""
    }    
  }
  

  const onKeyChangeSearchKeyWord=(value)=>{
    const convertSearchValue= value.split(',');
    let splitedNewArray =[]
    if(convertSearchValue){
      Array.isArray(convertSearchValue)&&convertSearchValue?.map(arrayValue=>{//NOSONAR
        splitedNewArray.push(arrayValue)
    })//NOSONAR
    let newSearch=[...searchKeyWord,...splitedNewArray]
    //duplicate remove
    const duplicateRemovedData=newSearch?.filter((item,index)=>newSearch.indexOf(item)===index)
    setSearchKeyWord(duplicateRemovedData);
    document.getElementById("keyword").value = ""
  }    
}
 
const contentChange =(e, index)=>{ //removed
    let tmpArray=[...searchKeyWord];
    tmpArray[index]=e.target.textContent;
    setSearchKeyWord(tmpArray)
}

const removeTextBox = () =>{
  setNewSearchKey(true)
}

useEffect(()=>{
  let assigntospecificemailValue = searchKeyWord?.filter(item=>item !== "")
  if(assigntospecificemailValue.length > 0){    
    setShowEmailError(false)    
  }
},[searchKeyWord])

const falseErrorChange = (val) => {
  if(val !== ""){
    setShowEmailError(false)
    setHiddenInputEmail(false)
  }
  else{
    let assigntospecificemailValue = searchKeyWord?.filter(item=>item !== "")
    if(assigntospecificemailValue.length > 0){    
      setShowEmailError(false)    
    }
    else{
      setShowEmailError(true)
      setHiddenInputEmail(false)
    }
    // setHiddenInputEmail(true)
  }
}

const[hiddenInputFac, setHiddentInputFac] = useState(true)
const [hiddenInputEmail, setHiddenInputEmail] = useState(true)
useEffect(()=>{
  if(onselectOrgz.length > 0){
    setHiddentInputFac(false)
  }
},[onselectOrgz])

// -----Department and Title Fields------
const [tenantMake, setTenantMake] = useState(false)
const [departmentMake, setDepartmentMake] = useState(false)
const [titleMake, setTitleMake] = useState(false)
const [andOptionState, setAndOptionState] = useState(false)
const [orOptionState, setOrOptionState] = useState(false)
const [makeTitleAlone, setMakeTitleAlone] = useState(false)
const [getAndOpt, setGetAndOpt] = useState(false)
const [radioYesNo, setRadioYesNo] = useState(false)
const[counterChange, setCounterChange] = useState(0)

const onChangeMethodTenant = (value) =>{
  if(value !== ""){
    setTenantMake(true)
    setMakeTitleAlone(true)
    setRadioYesNo(true)
    setOrOptionState(false)
    document.getElementById("yes_no1").checked= false
    document.getElementById("yes_no2").checked= false
  }
  else{
    setTenantMake(false)
    setRadioYesNo(false)
    setMakeTitleAlone(false)
    setOrOptionState(true)
    document.getElementById("yes_no2").checked= true
  }
}

const onChangeMethodDepartment = (value) => {
  if(andOptionState){
    if(value !== "" || document.getElementById('title')?.value !==""){
      setGetAndOpt(true)
    }
    else{
      setGetAndOpt(false)
    }
  }
  else if(orOptionState){
    if(counterChange===0){
      if(value !== ""|| document.getElementById('department')?.value !==""){
        setDepartmentMake(true)
      }
      else{
        setDepartmentMake(false)      
      }
    }
    if(counterChange >= 1){      
      if(value !== ""|| document.getElementById('department')?.value !==""){
        if(document.getElementById('title')?.value !==""){
          setDepartmentMake(false)
          setGetAndOpt(true)
        }
        else{
          setDepartmentMake(true)
          setGetAndOpt(true)
        }
      }
      else{
        if(document.getElementById('title')?.value !==""){
          setGetAndOpt(true)
          setTitleMake(true)           
        }
        else{
          setDepartmentMake(false) 
          setGetAndOpt(false)
        }  
      }
    }
  }  
  else{
    if(value !== ""){
      setDepartmentMake(true)
    }
    else{
      setDepartmentMake(false)
    }
  }
}

const onChangeMethodTitle = (value) => {  
  if(andOptionState){
    if(value !== "" || document.getElementById('department')?.value !==""){
      setGetAndOpt(true)
    }
    else{
      setGetAndOpt(false)
    }
  }
  else if(orOptionState){
    if(counterChange === 0 ){
      if(value !== ""|| document.getElementById('title')?.value !==""){
        setTitleMake(true)
      }
      else{
        setTitleMake(false)
      }
    }
    if(counterChange >= 1 ){
      if(value !== ""|| document.getElementById('title')?.value !==""){
        if(document.getElementById('department')?.value !==""){
          setTitleMake(false)
          setGetAndOpt(true)
        }
        else{
          setTitleMake(true)
          setGetAndOpt(true)
        }
        
      }
      else{
        if(document.getElementById('department')?.value !==""){
          setDepartmentMake(true)
          setGetAndOpt(true)
        }
        else{
          setTitleMake(false)
          setGetAndOpt(false)
        }
      }
    }
  }
  else{
    if(value !== ""){
      setTitleMake(true)
    }
    else{
      setTitleMake(false)
    }
  }
}

const makeAndOption = () => {
  setAndOptionState(true)
  setOrOptionState(false)
  setMakeTitleAlone(false)
  setCounterChange(counterChange+1)
}

useEffect(()=>{
  if(andOptionState&&departmentMake){
    setDepartmentMake(false)
  }
  if(andOptionState&&titleMake){
    setTitleMake(false)
  }
},[andOptionState])

const makeOROption = () =>{
  setOrOptionState(true)
  setAndOptionState(false)
  setMakeTitleAlone(false)
  setGetAndOpt(false)
  setCounterChange(counterChange+1)
}

  return (
    <>
      {!msgModal && <Modal
        modalOpen={modalOpen}
        modalClose={modalRefresh}
        heading={"SSO Mapping"}
        className={"modal_box"}
        modalSize={styles.custom_modal_size}
        modalScroll={false}
        reset={!resMsgState?.errorMessage && reset}
        custom_content={styles.custom_content}
      >
        {(!createUser) && <Form className={`pl-2 ${styles.patient_content}`} onSubmit={handleSubmit(onSubmitUser)}>
          
          {role !== 'ExternalAdminstrator' && 
            <>
            {/* Heading DomainName, Role, Status rows */}
            <div className={`${styles.heading_Domain} mr-3`}>              
              <div className={`col-12 px-0 pb-0 pt-1 d-flex align-items-start justify-content-start`}>
                {/* Domain Name Box */}
                <div className={`col-5 px-0`}>                  
                  <TextField
                      name={'ssodomainname'}
                      id={'ssodomainname'}
                      label={`Domain name: `}
                      classes={{
                        column: `col-12 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-8 py-0 pl-1',
                        inputClassName: `${styles.basic_input_domain} form-control`
                      }}
                      type="text"
                      validator={register(checkDominaName)}
                      errors={errors}
                      customError={errorMesg}
                      required={true}
                      selectAll={true}
                      onKeyPress={()=>{
                          setErrorMsg('')
                      }}
                      onChange={(e)=>getEmailSearch(e.target.value)}      
                      onKeyUp={(e) => getEmail(e?.target?.value)} 
                  />

                  {emailSearchData &&
                    Array.isArray(emailSearchData?.emails) &&
                    emailSearchData?.emails?.length > 0 && (
                        <div className="col-12 col-spec row">
                        <div className={`col_spec mr-auto ${styles.search_drop_domain}`}>
                            <div className={`col-spec ${styles.search_close_box}`}>
                            {emailSearchData?.emails?.map(
                                (items, index) =>
                                items && (
                                    <p
                                    className={`dropdown-item`}
                                    title={items.value}
                                    onClick={(e) => emailSelect(e, items)}
                                    >
                                    {items}
                                    </p>
                                )
                            )}
                            </div>
                        </div>
                        </div>
                    )} 
                </div>

                {/* <div className={`verticalCode  ${styles.vertical_Code}`}></div> */}

                <div className={`col-4 px-0`}>
                  <SelectBox
                      id={ROLE_ID}
                      name={ROLE_ID}
                      label={`${ROLE_LBL}:`}
                      classes={{
                          column: `col-12 d-flex ${styles.col_spec} pr-0`,
                          inputClassName: styles.basic_select_role,
                          labelClassName: `col-4 col-form-label ${styles.label_spec}`,
                          inputColumn: `col-8 p-0 ${styles.select_wrapper}`
                      }}
                      optionValue={convertedUserRoleData}
                      defaultValue={'ExternalStandardUser'}
                      validator={register}
                      required={true}
                  />
                </div>

                {/* <div className={`verticalCode  ${styles.vertical_Code_role}`}></div> */}

                {/* Toggle switch */}      
                <div className={`col-3 px-0 pt-2`}>                  
                  <div className={`col-11 custom-control custom-switch pl-2 d-flex`}>                      
                      <b>{`Enable SSO:  `}</b>{<span className={`${styles.astrick} ${styles.label_spec_Sso} pl-1 pr-2`}>&#42;</span>}
                      <div className={`${styles.toggle_shells}`}>                                      
                      <input 
                          type="checkbox"
                          name="isStatus" 
                          className="custom-control-input"
                          id={"isStatus"}
                          data-testid={"toggleSwitch"}
                          // defaultValue = {checkStatus}
                          checked = {checkStatus === 1 ? true : false}                                                     
                          onChange={handleClickStatus}                          
                      />
                      <label className={`custom-control-label ${styles.toggle_switch}`} for="isStatus"></label> 
                      </div>
                  </div>              
                </div>
              </div>
               {/* Assign facility to each user using the tenant parameter in the token*/}           
              <div className={`col-sm-12 px-0 pt-1 pb-2`}>
                <label className={`col-8 col-form-label mr-0 pt-1 mt-2 ${styles.label_spec_Sso}`}>Assign facility to each user using the tenant parameter in the token: {<span className={styles.astrick}>&#42;</span>}</label>
                <RadioButton
                    id={`tokenized_facility`}
                    name={`tokenized_facility`}
                    classes={{
                        labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                        inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                        inputColumn: "custom-control custom-radio custom-control-inline"
                    }}
                    errors={errors}
                    validator={register(checkTokenizedFacility)}
                    required={true}
                    radioOption={['Yes', 'No']}
                    onChange={(e) => getTokenState(e.target.value)}
                    onClick={(e) => getTokenState(e.target.value)}
                    lableSso="sso_token_facility"
                  />
              </div>  
          </div>

           {/* Include/Exclude Option tokenzState-No*/}           
          {tokenzState===0 && <div className={`col-sm-12 px-0 py-4`}>
            <label className={`col-3 col-form-label mr-0 ${styles.label_spec_Sso}`}>Type: {<span className={styles.astrick}>&#42;</span>}</label>
            <RadioButton
                id={`tableTBD`}
                name={`tableTBD`}
                classes={{
                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                    inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                    inputColumn: "custom-control custom-radio custom-control-inline"
                }}
                errors={errors}
                // validator={register(checkIncExcType)}
                validator={register(typeVis ? checkIncExcType : "")}
                required={true}
                radioOption={['Include', 'Exclude']}
                onChange={(e) => getState(e.target.value)}
                onClick={(e) => getState(e.target.value)}
                lableSso="sso"
                disabled={!typeVis}
              />
          </div>}  

          {/* Include/Exclude Option tokenzState-Yes */} 
          {tokenzState===1 && <div className={`col-sm-12 px-0 py-4`}>
            <label className={`col-3 col-form-label mr-0 ${styles.label_spec_Sso}`}>Type: {<span className={styles.astrick}>&#42;</span>}</label>
            <RadioButton
                id={`tableTBD`}
                name={`tableTBD`}
                classes={{
                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                    inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                    inputColumn: "custom-control custom-radio custom-control-inline"
                }}
                // errors={errors}
                // validator={register(typeVis ? checkIncExcType : "")}
                required={true}
                radioOption={['Include', 'Exclude']}
                onChange={(e) => getState(e.target.value)}
                onClick={(e) => getState(e.target.value)}
                lableSso="sso"
                disabled={!typeVis}
              />
          </div>}        




          
          {/* Tenant tokenzState-No */}
          {tokenzState===0 && <div className={`col-sm-12 px-0`}>           
            {(!departmentMake && !getAndOpt && !titleMake) && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec} mb-2`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState&&styles.disabled_Column) || (departmentMake&&styles.disabled_Column) || (titleMake&&styles.disabled_Column)|| (getAndOpt&&styles.disabled_Column)}`
                }}
                label={`Tenant:`}
                type={"text"}
                name={'tenant'}
                id={'tenant'}
                validator={register(disState ? checkTenantID : "")}
                errors={errors}
                required={true}
                // defaultValue={!isModal ? userData && userData?.email : ''}
                // disabled={!disState}
                disabled={!disState ? true : departmentMake ? true : titleMake ? true : getAndOpt ? true : false}
                onChange={(e)=>{onChangeMethodTenant(e.target.value)}}
                onBlur={(e)=>{onChangeMethodTenant(e.target.value)}}
            />}
            {(!departmentMake && !getAndOpt && titleMake) && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec} mb-2`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState&&styles.disabled_Column) || (departmentMake&&styles.disabled_Column) || (titleMake&&styles.disabled_Column)|| (getAndOpt&&styles.disabled_Column)}`
                }}
                label={`Tenant:`}
                type={"text"}
                name={'tenant'}
                id={'tenant'}
                validator={register}
                // errors={errors}
                required={true}
                // defaultValue={!isModal ? userData && userData?.email : ''}
                // disabled={!disState}
                disabled={!disState ? true : departmentMake ? true : titleMake ? true : getAndOpt ? true : false}
                onChange={(e)=>{onChangeMethodTenant(e.target.value)}}
                onBlur={(e)=>{onChangeMethodTenant(e.target.value)}}
            />}
            {(!departmentMake && getAndOpt) && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec} mb-2`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState&&styles.disabled_Column) || (departmentMake&&styles.disabled_Column) || (titleMake&&styles.disabled_Column)|| (getAndOpt&&styles.disabled_Column)}`
                }}
                label={`Tenant:`}
                type={"text"}
                name={'tenant'}
                id={'tenant'}
                validator={register}
                // errors={errors}
                required={true}
                // defaultValue={!isModal ? userData && userData?.email : ''}
                // disabled={!disState}
                disabled={!disState ? true : departmentMake ? true : titleMake ? true : getAndOpt ? true : false}
                onChange={(e)=>{onChangeMethodTenant(e.target.value)}}
                onBlur={(e)=>{onChangeMethodTenant(e.target.value)}}
            />}
            {departmentMake && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec} mb-2`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState&&styles.disabled_Column) || (departmentMake&&styles.disabled_Column) || (titleMake&&styles.disabled_Column)|| (getAndOpt&&styles.disabled_Column)}`
                }}
                label={`Tenant:`}
                type={"text"}
                name={'tenant'}
                id={'tenant'}
                validator={register}
                // errors={errors}
                required={true}
                // defaultValue={!isModal ? userData && userData?.email : ''}
                // disabled={!disState}
                disabled={!disState ? true : departmentMake ? true : titleMake ? true : getAndOpt ? true : false}
                onChange={(e)=>{onChangeMethodTenant(e.target.value)}}
                onBlur={(e)=>{onChangeMethodTenant(e.target.value)}}
            />}
          </div>}

          {/* OR label tokenzState-No */}
          {tokenzState===0 && <div className={`col-sm-12 px-0 pb-2 d-flex align-items-center`}>
            <label className={`col-2 text-center col-form-label mr-0 ${styles.label_spec_Sso}`} style={{fontSize:'20px',fontWeight:'bold'}}> OR </label>
            <label className={`col-8 col-form-label pl-0 mr-0 ${styles.label_spec_Sso}`}> </label>
          </div>}

          {/* Department tokenzState-No */}
          {tokenzState===0 && <div className={`col-sm-12 px-0`}>            
            {(!tenantMake && !titleMake) && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec} mb-2`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState &&styles.disabled_Column) || (tenantMake &&styles.disabled_Column)|| (titleMake &&styles.disabled_Column)}`
                }}
                label={`Department:`}
                type={"text"}
                name={'department'}
                id={'department'}
                validator={register(disState ? checkDepartmentName : "")}
                errors={errors}
                required={true}
                // disabled={!disState}
                disabled={!disState ? true : tenantMake ? true : titleMake ? true : false}
                onChange={(e)=>{onChangeMethodDepartment(e.target.value)}}
                onBlur={(e)=>{onChangeMethodDepartment(e.target.value)}}
            />}
            {(!tenantMake && titleMake) && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec} mb-2`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState &&styles.disabled_Column) || (tenantMake &&styles.disabled_Column)|| (titleMake &&styles.disabled_Column)}`
                }}
                label={`Department:`}
                type={"text"}
                name={'department'}
                id={'department'}
                validator={register}
                // errors={errors}
                required={true}
                // disabled={!disState}
                disabled={!disState ? true : tenantMake ? true : titleMake ? true : false}
                onChange={(e)=>{onChangeMethodDepartment(e.target.value)}}
                onBlur={(e)=>{onChangeMethodDepartment(e.target.value)}}
            />}
            {tenantMake && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec} mb-2`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState &&styles.disabled_Column) || (tenantMake &&styles.disabled_Column)|| (titleMake &&styles.disabled_Column)}`
                }}
                label={`Department:`}
                type={"text"}
                name={'department'}
                id={'department'}
                validator={register}
                // errors={errors}
                required={true}
                // disabled={!disState}
                disabled={!disState ? true : tenantMake ? true : titleMake ? true : false}
                onChange={(e)=>{onChangeMethodDepartment(e.target.value)}}
                onBlur={(e)=>{onChangeMethodDepartment(e.target.value)}}
            />}
          </div>}

          {/* AND / OR label tokenzState-No */}
          {tokenzState===0 && 
            <div className={`${styles.and_or_block} d-flex pr-0 py-3 pl-4 align-items-center`}>
              {/* ---------AND--------- */}
              <label for="html" className={`${styles.and_or_buttons}`}>AND</label>
              <input 
                type="radio" 
                className={`${styles.and_or_radio}`} 
                id="yes_no1"
                name="yes_no" 
                disabled={!disState ? true : radioYesNo ? true : false}
                onClick={makeAndOption}
              ></input>
              {/* ---------OR--------- */}
              <label for="html" className={`${styles.and_or_buttons}`}>OR</label>
              <input 
                type="radio" 
                className={`${styles.and_or_radio}`} 
                id="yes_no2"
                name="yes_no"
                disabled={!disState ? true : radioYesNo ? true : false}
                // checked={!disState}
                // defaultChecked={!disState}
                onClick={makeOROption}
                ></input>
            </div>
          } 

          {/* title tokenzState-No */}
          {tokenzState===0 && <div className={`col-sm-12 px-0 pb-2`}>            
            {(!tenantMake && !departmentMake) && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState &&styles.disabled_Column)||(makeTitleAlone &&styles.disabled_Column)||(departmentMake &&styles.disabled_Column)}`
                }}
                label={`Title:`}
                type={"text"}
                name={'title'}
                id={'title'}
                validator={register((disState && !makeTitleAlone) ? checkTitleName : "")}
                errors={errors}
                required={true}
                disabled={!disState ? true : makeTitleAlone ? true : departmentMake ? true : false}
                // disabled={!andOrMake ? true : tenantMake ? true : departmentMake ? true : false}
                onChange={(e)=>{onChangeMethodTitle(e.target.value)}}
                onBlur={(e)=>{onChangeMethodTitle(e.target.value)}}
            />}
            {(!tenantMake && departmentMake) && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState &&styles.disabled_Column)||(makeTitleAlone &&styles.disabled_Column)||(departmentMake &&styles.disabled_Column)}`
                }}
                label={`Title:`}
                type={"text"}
                name={'title'}
                id={'title'}
                validator={register}
                // errors={errors}
                required={true}
                disabled={!disState ? true : makeTitleAlone ? true : departmentMake ? true : false}
                // disabled={!andOrMake ? true : tenantMake ? true : departmentMake ? true : false}
                onChange={(e)=>{onChangeMethodTitle(e.target.value)}}
                onBlur={(e)=>{onChangeMethodTitle(e.target.value)}}
            />}            
            {tenantMake && <TextField
                classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${(!disState &&styles.disabled_Column)||(makeTitleAlone &&styles.disabled_Column)||(departmentMake &&styles.disabled_Column)}`
                }}
                label={`Title:`}
                type={"text"}
                name={'title'}
                id={'title'}
                validator={register}
                // errors={errors}
                required={true}
                disabled={!disState ? true : makeTitleAlone ? true : departmentMake ? true : false}
                // disabled={!andOrMake ? true : tenantMake ? true : departmentMake ? true : false}
                onChange={(e)=>{onChangeMethodTitle(e.target.value)}}
                onBlur={(e)=>{onChangeMethodTitle(e.target.value)}}
            />}
          </div>}

          
          {/* Tenant tokenzState-Yes */}
          {tokenzState===1 && <div className={`col-sm-12 px-0`}>
            <TextField
                classes={{
                    column: `col-6 ${styles.col_spec} mb-2`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${!disState&&styles.disabled_Column}`
                }}
                label={`Tenant:`}
                type={"text"}
                name={'tenant'}
                id={'tenant'}
                // validator={register(disState ? checkTenantID : "")}
                // errors={errors}
                required={true}
                // defaultValue={!isModal ? userData && userData?.email : ''}
                disabled={!disState}
            />
          </div>}



        {/* OR label tokenzState-Yes */}
        {tokenzState===1 && <div className={`col-sm-12 px-0 pb-2 d-flex align-items-center`}>
            <label className={`col-2 text-center col-form-label mr-0 ${styles.label_spec_Sso}`} style={{fontSize:'20px',fontWeight:'bold'}}> OR </label>
            <label className={`col-8 col-form-label pl-0 mr-0 ${styles.label_spec_Sso}`}> </label>
          </div>}

          {/* Department tokenzState-Yes */}
          {tokenzState===1 && <div className={`col-sm-12 px-0`}>
            <TextField
                classes={{
                    column: `col-6 ${styles.col_spec} mb-2`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${!disState&&styles.disabled_Column}`
                }}
                label={`Department:`}
                type={"text"}
                name={'department'}
                id={'department'}
                // validator={register(disState ? checkTenantID : "")}
                // errors={errors}
                required={true}
                disabled={!disState}
            />
          </div>}

          {/* AND / OR label tokenzState-Yes */}
          {tokenzState===1 &&         
            <div className={`${styles.and_or_block} d-flex pr-0 py-3 pl-4 align-items-center`}>
              <label for="html" className={`${styles.and_or_buttons}`}>AND</label>
              <input 
                type="radio" 
                className={`${styles.and_or_radio}`} 
                id="yes_no1"
                name="yes_no" 
                disabled={!disState ? true : false}                
              ></input>
              <label for="html" className={`${styles.and_or_buttons}`}>OR</label>
              <input 
                type="radio" 
                className={`${styles.and_or_radio}`} 
                id="yes_no2"
                name="yes_no"
                disabled={!disState ? true : false}
                >                
                </input>
            </div>
          }

          {/* title tokenzState-Yes */}
          {tokenzState===1 && <div className={`col-sm-12 px-0 pb-2`}>
            <TextField
                classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-12 py-0 pr-0 pl-0',
                    inputClassName: `${styles.basic_input} form-control ${!disState&&styles.disabled_Column}`
                }}
                label={`Title:`}
                type={"text"}
                name={'title'}
                id={'title'}
                // validator={register(disState ? checkTenantID : "")}
                // errors={errors}
                required={true}
                disabled={!disState}
            />
          </div>}

          {/* Primary Group */}
          {tokenzState===0 && <div className={`col-12 px-0 pb-2 ${styles.input_icons}`}>                                        
              <TextField 
                  id={CUSTOMER_NUMBER_ID}
                  name={CUSTOMER_NUMBER_ID}
                  label={"Primary group:"}
                  classes = {
                      {
                          column: `col-6 ${styles.col_spec}`,
                          labelClass: `col-6 col-form-label  ${styles.label_spec}`,
                          inputColumn: `col-12 pl-0 pr-0`,
                          inputClassName: `${styles.basic_input} ${!disState&&styles.disabled_Column}`                                  
                      }                            
                  }
                  disabled={!disState}
                  type="text"
                  validator={register(disState ? checkPrimaryGroup : "")}
                  errors={errors}
                  customError={errorMsg}
                  required={true}
                  selectAll={true}                                                  
                  onKeyPress={()=>{
                      setErrorMsg('')
                  }}
                  onChange={(e)=>getCustomerNumberSearch(e.target.value)}  
                  onKeyUp={(e) => getCustomerNumber(e?.target?.value)}                       
              />  
              {customerNumberSearchDatas &&
                  Array.isArray(customerNumberSearchDatas) &&
                  customerNumberSearchDatas.length > 0 && (
                      <div className="col-12 col-spec row" id="custNumSuggestion">
                      <div className={`col_spec mr-auto ${styles.search_drop}`}>
                          <div className={`col-spec ${styles.search_close_box}`}>
                          {customerNumberSearchDatas.map(
                              (items, index) =>
                              items && (
                                  <p
                                  className={`dropdown-item`}
                                  title={items.value}
                                  onClick={(e) => customerNumberSelect(e, items)}
                                  >
                                  {items}
                                  </p>
                              )
                          )}
                          </div>
                      </div>
                      </div>
                  )}                  
              <span 
                  id="clear_icon"
                  className={`${styles.x_icon}`}
                  onClick={(e)=>ClearData(
                    document.getElementById(CUSTOMER_NUMBER_ID).value
                  )}                          
                  >
                  <i className="fa fa-times" style={disState ? {color : "#000"}: {color : "rgb(191 187 187)",cursor:'default'}} aria-hidden="true"></i>
              </span>  
          </div>}
          {tokenzState===1 && <div className={`col-12 px-0 pb-2 ${styles.input_icons}`}>                                        
              <TextField 
                  id={CUSTOMER_NUMBER_ID}
                  name={CUSTOMER_NUMBER_ID}
                  label={"Primary group:"}
                  classes = {
                      {
                          column: `col-6 ${styles.col_spec}`,
                          labelClass: `col-6 col-form-label  ${styles.label_spec}`,
                          inputColumn: `col-12 pl-0 pr-0`,
                          inputClassName: `${styles.basic_input} ${!disState&&styles.disabled_Column}`                                  
                      }                            
                  }
                  disabled={!disState}
                  type="text"
                  // validator={register(disState ? checkPrimaryGroup : "")}
                  // errors={errors}
                  // customError={errorMsg}
                  required={true}
                  selectAll={true}                                                  
                  onKeyPress={()=>{
                      setErrorMsg('')
                  }}
                  onChange={(e)=>getCustomerNumberSearch(e.target.value)}  
                  onKeyUp={(e) => getCustomerNumber(e?.target?.value)}                       
              />  
              {customerNumberSearchDatas &&
                  Array.isArray(customerNumberSearchDatas) &&
                  customerNumberSearchDatas.length > 0 && (
                      <div className="col-12 col-spec row" id="custNumSuggestion">
                      <div className={`col_spec mr-auto ${styles.search_drop}`}>
                          <div className={`col-spec ${styles.search_close_box}`}>
                          {customerNumberSearchDatas.map(
                              (items, index) =>
                              items && (
                                  <p
                                  className={`dropdown-item`}
                                  title={items.value}
                                  onClick={(e) => customerNumberSelect(e, items)}
                                  >
                                  {items}
                                  </p>
                              )
                          )}
                          </div>
                      </div>
                      </div>
                  )}                  
              <span 
                  id="clear_icon"
                  className={`${styles.x_icon}`}
                  onClick={(e)=>ClearData(
                    document.getElementById(CUSTOMER_NUMBER_ID).value
                  )}                          
                  >
                  <i className="fa fa-times" style={disState ? {color : "#000"}: {color : "rgb(191 187 187)",cursor:'default'}} aria-hidden="true"></i>
              </span>  
          </div>}
            
            
          {/* Organizational View*/}
          <div className={`col-12 px-0 ${styles.input_icons}`}>           
            {<Organizational
                id={"organizationalView"}
                name={"organizationalView"}
                label={`Facility/Location:`}
                classes={{
                  column: `col-6 ${styles.col_spec}`,
                  labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                  inputColumn: `col-12 p-0 ${styles.organizationalView}`,
                  inputClassName: `${styles.basic_input} form-control`
                }}
                optionValue={optionData}
                groupBy="cat"
                displayValue="key"
                defaultValue={onselectOrgz}
                onSelect={onSelectOrg}
                customError={errMsg}
                selectAll={true}  
                dropdownOpen={checkOrgDrop}
                required={true}
                // errors={errors}
                // onRemove={onSelectOrg}
                disabled={!disState}
                assignPage={true}
              />}         
          </div>
          {hiddenInputFac && tokenzState===0 &&          
              <TextField 
                id={"hiddenInputFacility"}
                name={"hiddenInputFacility"}
                label={""}
                classes = {
                    {
                        column: `col-6 ${styles.col_spec} p-0 m-0`,
                        labelClass: `col-6 col-form-label  ${styles.label_spec}`,
                        inputColumn: `col-12 pl-0 pr-0 pb-2 ${styles.hidden_input_column}`,
                        inputClassName: `${styles.hidden_input}`                                  
                    }                            
                }
                type="text"
                validator={register(disState ? checkFacilities : "")}
                errors={errors}
              />
            }
          {isValidSaleCust && tokenzState===0 && <span className={`col-8 ${styles.error_message} text-center`}>{
            'Please select facility'}
              </span>}
          
          {/* assign to allemails Radio Button*/}
          {showEmailCheckbox && tokenzState===0 &&
              <div className={`col-sm-12 px-0 pb-3 ${hiddenInputFac ? 'pt-0': "pt-4"}`}>
              <label className={`col-3 col-form-label pt-0 mr-0 ${styles.label_spec_Sso}`}>Applies to all email addresses: {<span className={styles.astrick}>&#42;</span>}</label>
              <RadioButton
                  id={`assigntoallemails`}
                  name={`assigntoallemails`}
                  classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                      inputColumn: "custom-control custom-radio custom-control-inline"
                  }}
                  errors={errors}
                  validator={register(disState ? checkAssignToAllEmails : "")}
                  radioOption={['Yes', 'No']}
                  required={true}
                  onChange={(e) => getState(e.target.value)}
                  onClick={(e) => getState(e.target.value)}
                  disabled={!disState}
                  lableSso="sso"
              />
          </div>
          }
          {showEmailCheckbox && tokenzState===1 &&
              <div className={`col-sm-12 px-0 pb-3 ${hiddenInputFac ? 'pt-0': "pt-4"}`}>
              <label className={`col-3 col-form-label pt-0 mr-0 ${styles.label_spec_Sso}`}>Applies to all email addresses: {<span className={styles.astrick}>&#42;</span>}</label>
              <RadioButton
                  id={`assigntoallemails`}
                  name={`assigntoallemails`}
                  classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label_Sso}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input_Sso}`,
                      inputColumn: "custom-control custom-radio custom-control-inline"
                  }}
                  // errors={errors}
                  // validator={register(disState ? checkAssignToAllEmails : "")}
                  radioOption={['Yes', 'No']}
                  required={true}
                  onChange={(e) => getState(e.target.value)}
                  onClick={(e) => getState(e.target.value)}
                  disabled={!disState}
                  lableSso="sso"
              />
          </div>
          }

          {/* OR label */}
          {showEmailCheckbox && <div className={`col-sm-12 px-0 pb-2 d-flex align-items-center`}>
            <label className={`col-2 text-center col-form-label mr-0 ${styles.label_spec_Sso}`} style={{fontSize:'20px',fontWeight:'bold'}}> OR </label>
            <label className={`col-8 col-form-label pl-0 mr-0 ${styles.label_spec_Sso}`}> </label>
          </div>}

          {/* Include Mulit Email text box */}
          {showEmailCheckbox && 
          <>
          <div className={`col-sm-12 ${styles.search_terms} mt-0 d-flex`} id={"specificemailaddress"}>
            <label className={`${styles.label_spec} col-3 p-0`}>Specific email addresses only: {showMand && <span className={styles.astrick}>&#42;</span>}</label>                 
            {/* <div className={!disState ? `col-6 ${styles.main_list_disabled} pl-0 pr-1` : makeDisable ? `col-6 ${styles.main_list_disabled} pl-0 pr-1` : `col-6 ${styles.main_list} pl-0 pr-1`} contentEditable={makeDisable===true ? false : true}>                   */}
            <div className={makeDisable ? `col-6 ${styles.main_list_disabled} pl-0 pr-1` : `col-6 ${styles.main_list} pl-0 pr-1`}>                  
              <div className={`${styles.search_list} px-0`}>
                <ul className="d-flex flex-wrap w-100 pl-3">
                  {searchKeyWord && searchKeyWord.length > 0 && searchKeyWord.map((data, i) => {
                    return (
                      <>
                        {/* {data&& <li key={i} contentEditable={true}   onBlur={(e)=> contentChange(e,i)} suppressContentEditableWarning={true}>{data}<img src={closeNormal} alt="close" onClick={() => removeKeyword(i)} className={`deleteSearchKeyWord`}/></li>}                       */}
                        {data&& <li key={i} contentEditable={false}>{data}<img src={closeNormal} alt="close" onClick={() => removeKeyword(i)} className={`deleteSearchKeyWord`}/></li>}
                      </>
                    )
                  })

                  }
                </ul>
              </div>

                {
                  newKeySearchKey && <><div className={`col-12 ${styles.input_li} d-flex align-items-center p-0`}>
                      <TextField
                          id={'keyword'}
                          name={"keyword"}
                          classes={{
                              column: `col-12 ${styles.col_spec} p-0 m-0 pl-1}`,
                              labelClass: `col-12 col-form-label ${styles.label_spec}`,
                              inputColumn: 'col-12 p-0 pt-3 w-100',
                              inputClassName: `${styles.basic_input_sepcific} onblurKeyword ${styles.col_spec_unBorder} ${makeDisable && styles.disabled_Column}`
                          }}
                          type="text"
                          onBlur={(e)=>onBlurChangeSearchKeyWord(e.target.value)}
                          // onKeyPress={(e) => onKeyChangeSearchKeyWord(e.target.value)}
                          onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              onKeyChangeSearchKeyWord(e.target.value);
                            }
                        }}
                          autofocus={true}
                          disabled={makeDisable ? true : false}
                          onChange={(e) => falseErrorChange(e.target.value)}
                      />                              
                      <img src={closeNormal} alt="close" onClick={() => removeTextBox()} className={`deleteSearchKeyWord`} />
                    </div>
                    </>
                  }            
            </div>
          </div>
          {hiddenInputEmail && tokenzState===0 &&        
              <TextField 
                id={"hiddenInputEmail"}
                name={"hiddenInputEmail"}
                label={""}
                classes = {
                    {
                        column: `col-6 ${styles.col_spec} p-0 m-0`,
                        labelClass: `col-6 col-form-label  ${styles.label_spec}`,
                        inputColumn: `col-12 pl-0 pr-0 pb-2 ${styles.hidden_email_column}`,
                        inputClassName: `${styles.hidden_input}`                                  
                    }                            
                }
                type="text"
                validator={register(showMand ? checkSpecificEmail : "")}
                errors={errors}
              />
            }
          {makeFacErro ? showEmailError && tokenzState===0 && <span className={`col-8 ${styles.error_message_IncExc} text-center`}>{
            'Enter specific email addresses'}
              </span> : ""}
          </>          
          }

          {/* Exclude Mulit Email text box */}
          {!showEmailCheckbox && tokenzState===0 &&
          <>
          <div className={`col-sm-12 ${styles.search_terms_excl} d-flex`}>
            <label className={`${styles.label_spec} col-3 p-0`}>Specific email addresses only: {<span className={styles.astrick}>&#42;</span>}</label>                 
            {/* <div className={!disState ? `col-6 ${styles.main_list_disabled} pl-0 pr-1` : makeDisable ? `col-6 ${styles.main_list_disabled} pl-0 pr-1` : `col-6 ${styles.main_list} pl-0 pr-1`} contentEditable={makeDisable===true ? false : true}>                   */}
            <div className={makeDisable ? `col-6 ${styles.main_list_disabled} pl-0 pr-1` : `col-6 ${styles.main_list_excl} pl-0 pr-1`} contentEditable={makeDisable===true ? false : true}>                  
              <div className={`${styles.search_list} px-0`}>
                <ul className="d-flex flex-wrap w-100 pl-3">
                  {searchKeyWord && searchKeyWord.length > 0 && searchKeyWord.map((data, i) => {
                    return (
                      <>
                        {/* {data&& <li key={i} contentEditable={true}   onBlur={(e)=> contentChange(e,i)} suppressContentEditableWarning={true}>{data}<img src={closeNormal} alt="close" onClick={() => removeKeyword(i)} className={`deleteSearchKeyWord`}/></li>}                       */}
                        {data&& <li key={i} contentEditable={false}>{data}<img src={closeNormal} alt="close" onClick={() => removeKeyword(i)} className={`deleteSearchKeyWord`}/></li>}
                      </>
                    )
                  })

                  }
                </ul>
              </div>

                {
                  newKeySearchKey && <><div className={`col-12 ${styles.input_li} d-flex align-items-center p-0`}>
                      <TextField
                          id={'keyword'}
                          name={"keyword"}
                          classes={{
                              column: `col-12 ${styles.col_spec} p-0 m-0 pl-1}`,
                              labelClass: `col-12 col-form-label ${styles.label_spec}`,
                              inputColumn: 'col-12 p-0 pt-3 w-100',
                              inputClassName: `${styles.basic_input_sepcific} onblurKeyword ${styles.col_spec_unBorder} ${makeDisable && styles.disabled_Column}`
                          }}
                          type="text"
                          onBlur={(e)=>onBlurChangeSearchKeyWord(e.target.value)}
                          // onKeyPress={(e) => onKeyChangeSearchKeyWord(e.target.value)}
                          onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              onKeyChangeSearchKeyWord(e.target.value);
                            }
                        }}
                          autofocus={true}
                          disabled={makeDisable ? true : false}
                          onChange={(e) => falseErrorChange(e.target.value)}
                      />                              
                      <img src={closeNormal} alt="close" onClick={() => removeTextBox()} className={`deleteSearchKeyWord`} />
                    </div></>
                  }            
            </div>
          </div>
          {hiddenInputEmail && tokenzState===0 &&          
              <TextField 
                id={"hiddenInputEmail"}
                name={"hiddenInputEmail"}
                label={""}
                classes = {
                    {
                        column: `col-6 ${styles.col_spec} p-0 m-0`,
                        labelClass: `col-6 col-form-label  ${styles.label_spec}`,
                        inputColumn: `col-12 pl-0 pr-0 pb-2 ${styles.hidden_email_column}`,
                        inputClassName: `${styles.hidden_input}`                                  
                    }                            
                }
                type="text"
                validator={register(showMand ? checkSpecificEmail : "")}
                errors={errors}
              />
            }
          { makeFacErro ? showEmailError && tokenzState===0 && <span className={`col-8 ${styles.error_message_IncExc} text-center`}>{
            'Enter specific email addresses'}
              </span> : ""}
          </>
          }

        {/* Save/Cancel Button */}
        <div className="col-sm-12 d-flex pt-4 pl-0">
          <div className='col-4'></div>
            <div className="text-center pb-4 mr-4">
              <Button
                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                type="submit"
                id={SUBMIT_ID}
                >
                {SAVE_BTN}
              </Button>
            </div>
            <div className="text-center pb-4">
              <Button
                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small} ${styles.btn_cancel}` }}
                id={CANCEL_ID}
                onClick={(e)=>handleCancel(e,reset)}

                >
                {CANCEL_BTN}
            </Button>
            </div>
          </div>

          </>
        }          
        
        </Form>
        }

        {(createUser) && <CreateEditSso
          register={register}
          handleSubmit={handleSubmit}
          onSubmit={onSubmitUser}
          control={control}
          errors={errors}
          handleCancel={modalRefresh}
          facilityOptions={optionData}
          isModal={true}
          errormsg={resMsgState?.errorMessage}
          role={role}
        />}
      </Modal>
      }
      {msgModal && <Modal
        modalOpen={modalOpen}
        modalClose={modalRefresh}
      >
        <div className="text-center p-4">
          {resMsgState === SUCCESS_STATUS && <ActionModal
            iconImg={iconSuccess}
            heading={SSO_CREATED_MSG}
            buttonText="Close"
            buttonAction={modalRefresh}
          />}
        </div>
      </Modal>
      }

    </>
  )
}
CreateSso.propTypes = {
  dispatch: PropTypes.func
};
CreateSso = connect()(withRouter(CreateSso));
