import React, { useState } from "react";
import PropTypes from 'prop-types';
import style from './textField.module.scss';
import { PasswordHint } from "../../secondary";
import EyeOpen from '../../../static/images/eye-icon-off.png';
import EyeClose from '../../../static/images/eye-icon.png';
import searchIcon from '../../../static/images/search-icon.png';
export const TextField = ({
  refKeys = '',
  subName = '',
  classes,
  id = '',
  label,
  type = '',
  name = '',
  defaultValue = '',
  placeholder = '',
  disabled = false,
  validator,
  errors,
  required,
  onChange,
  onKeyUp,
  note,
  customError = '',
  charLimit = false,
  onBlur,
  hints,
  onFocus,
  minLength,
  oneSpecialChar,
  oneNum,
  oneUCase,
  onKeyPress,
  search = false,
  step = 'any',
  atleastOne = false,
  passwordCheck,
  listViewError,
  title=false,
  readOnly=false,
  autoComplete='new-password',
  searchClear=true,
  getSearchValue,
  showClearX,
  setShowClearX,
  labelMrn,
  clearId=false
}) => {
  let errorMessage = errors && errors[name] && errors[name].message;
  if ((errors && refKeys && subName && errors['banners'] && errors['banners'][refKeys] && errors['banners'][refKeys][subName])) {
    errorMessage = errors['banners'][refKeys][subName].message;
  }
  else if ((errors && refKeys && subName && errors['sapMaterialZCEP'] && errors['sapMaterialZCEP'][refKeys] && errors['sapMaterialZCEP'][refKeys][subName])) {
    errorMessage = errors['sapMaterialZCEP'][refKeys][subName].message;
  }
  //sapmateriazcep
  // Password mask 
  const [visible, setVisible] = useState(false);
  let eventMan = new KeyboardEvent('keypress');
  Object.defineProperties(eventMan, {
      charCode: {value: 13},
      keyCode: {value: 13},
      keyIdentifier: {value: 'click'},
      which: {value: 13},
      target:{value: ''}
      
  });

  return (
    <div className={(classes) ? classes.column : ''}>
      {/* Label  */}
      {label && <label htmlFor={id} className={`${classes.labelClass} ${style.text_field_label}`}>{label} {required ? <span className={style.astrick}>&#42;</span> : ''}</label>}
      {/* Inner div */}
      <div className={`${(classes) ? classes.inputColumn : ''}`} contentEditable={false}>
        {/* Input */}
        <input
          type={visible ? 'text' : type}
          name={name}
          id={id}
          className={`${(classes) ? `${classes.inputClassName}` : ''} ${errorMessage ? `${style.error_border}` : ''}  ${customError ? `${style.error_border}` : ''}`}
          placeholder={placeholder}
          disabled={disabled}
          ref={validator}
          autoComplete={autoComplete}
          defaultValue={defaultValue}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={charLimit ? charLimit : ''}
          onKeyPress={onKeyPress}
          step={step}
          title={title}
          readOnly={readOnly}
          
        />
        {(id==='search'||showClearX===true)&&(showClearX||defaultValue)&&<span 
                    id="clear_icon"
                    className={`${style.x_icon}`}
       
                    onClick={() => { document.getElementById(id).value = null ;  if(getSearchValue)getSearchValue(eventMan);setShowClearX(false)}}             
                    >
                       <i className="fa fa-times" style={{color : "#000"}} aria-hidden="true"></i>
                    </span>}
        {search &&<a className={`${(classes) && (classes.search_btn) ? `${classes.search_btn}` : ''}`}>
          <img src={searchIcon} alt="search icon" />
        </a>
        
        }
        {note}
        {type === 'password' &&
          <span className={style.eyeclose}>
            <span id="eyeOC" onClick={() => setVisible(masck => !masck)}>
              <img src={visible ? EyeOpen : EyeClose} alt="" />
            </span>
          </span>
        }
        {/* Char Count  */}
        {
          charLimit && <span className={`${style.char_count}`}>
            <font className={`${labelMrn !== "mrn" ? style.char_count_font : 'd-none'}`}>
              <font className={`${style.char_count_font}`}>
                {defaultValue != 0 ? defaultValue?.length : 0}/{charLimit}
              </font>
            </font>
          </span>
        }
        {/* Error */}
        {customError && <span className={(listViewError)?style.error_message2 :style.error_message}>{customError}</span>}
        {
          errorMessage && <span className={style.error_message}>
            {
              (errors[name] && errors[name].type && errors[name].type === 'passwordHint') ? <PasswordHint {...JSON.parse(errorMessage)} /> : errorMessage
            }
          </span>
        }
        {
          hints && <span className={style.error_message}>
            {
              atleastOne && !errorMessage && <>Looks like you missed one of the password requirements. Please check your password.</>
            }
            <PasswordHint
              minLength={minLength}
              oneSpecialChar={oneSpecialChar}
              oneNum={oneNum}
              oneUCase={oneUCase}
            />
          </span>
        }
      </div>
    </div>
  );
}

TextField.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.any,
  type: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  validator: PropTypes.func,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  hints: PropTypes.bool,
  onFocus: PropTypes.func,
  minLength: PropTypes.bool,
  oneSpecialChar: PropTypes.bool,
  oneNum: PropTypes.bool,
  oneUCase: PropTypes.bool
}
