import React, { useState, useEffect, useRef } from 'react';
import style from './modalities.module.scss';
import { Button, Form, MultiSelect } from '../../primary';
import { CANCEL_BTN, SAVE_BTN } from '../../../constants';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAdditionalCareAsync, patchAdditionalCareAsync, optionTypeAsync } from '../../../app/patientSlice';
import { useParams } from 'react-router-dom';
import { signedLockedAccess } from '../../../utils';
import { assessmentRbacChecker } from '../../../utils/rbac';

export const AdditionalCare = ({ interventionId, formularyId, assessmentDetails }) => {

  const { register, handleSubmit, reset } = useForm();
  const { patientId, assessmentId } = useParams();
  const listViewRef = useRef();
  const dispatch = useDispatch();
  const resp = useSelector(state => state.patient.additionalCare);
  const listResp = useSelector(state => state.patient.additionalCareListoptions);
  const patientData = useSelector(state => state.patient.detailViewData)
  const userDetails = useSelector(state => state.user);
  const hideButtons = assessmentRbacChecker(userDetails?.role, patientData?.isOwnCustomer);

  const [listViewMenuData, setListViewMenuDatta] = useState([]);

  const [editable, setEditable] = useState(false);

  const editMode = () => {
    setEditable(true);
  }

  const viewMode = () => {
    if (resp?.additionalCare === null || resp?.additionalCare?.length <= 0) {
      return false
    } else {
      setEditable(false);
      reset();
    }

  }

  const handleListViewMenuChange = (e) => {
    if (e && e?.length > 0) {
      let tempData = [];
      e.map((data) => {
        if (data.key !== '') {
          tempData.push(data.key)
        }
      })
      setListViewMenuDatta(tempData)
    }
  }

  useEffect(() => {
    if (resp?.additionalCare == null || resp?.additionalCare?.length <= 0) {
      setEditable(true)
    }
    let additionalCareData = [];
    resp&&Array.isArray(resp?.additionalCare)&&resp?.additionalCare.map((data) => {//NOSONAR
      if (data&&data.key !== '') {
        additionalCareData.push(data)
      }
    })//NOSONAR
    setListViewMenuDatta(additionalCareData)
  }, [resp])
  const doSubmit = () => {
    const payloadData = {
      "additionalCare": listViewMenuData,
      'patientId': patientId,
      'assessmentId': assessmentId,
      'interventionId': interventionId
    }
    dispatch(patchAdditionalCareAsync(payloadData))
    setEditable(false);
  }

  useEffect(() => {
    viewMode()
    dispatch(optionTypeAsync('additionalcare'));
    dispatch(getAdditionalCareAsync({ patientId, assessmentId, interventionId }));
  }, [interventionId, formularyId])

  return <div>
    {
      !editable ? <div className={style.viewMode_container}>
        <div className="d-flex justify-content-between">
          <div className={`${style.lab_container} d-flex col-11 p-0`}>
            <label className="col-5 p-0">Additional Care: </label> <div className="col-6 p-0">
              {resp?.additionalCare && resp?.additionalCare.length > 0 && resp?.additionalCare?.map(e => {
                let optionValue = "";
                listResp && listResp.length > 0 && listResp?.map((l) => {//NOSONAR
                  if (e == l.key) {
                    optionValue = l.value
                  }
                })//NOSONAR
                return <div className="font-weight-bold">{optionValue}</div>
              })}</div>
          </div>
          <div>
            {
              !editable && signedLockedAccess(assessmentDetails?.assessmentStage) && hideButtons && <div className={style.tab_edit} onClick={() => editMode()}><img src={editIcon} alt="icon" className={style.e_icon_space} />Edit</div>
            }
          </div>
        </div>
      </div> :
        <div className="mt-3 pt-1">
          {signedLockedAccess(assessmentDetails?.assessmentStage) ?
            <Form formChanges={true} onSubmit={handleSubmit(doSubmit)}>
              <MultiSelect
                id={"additionalCare"}
                name={"additionalCare"}
                label={"Additional Care:"}
                classes={{
                  column: ` row ${style.botton_spac}`,
                  inputClassName: `${style.basic_select}`,
                  labelClassName: `col-4 col-form-label ${style.label_spec}`,
                  inputColumn: `col-6 p-0 ${style.select_wrapper}`,
                }}
                optionValue={Array.isArray(listResp) && listResp}
                showCheckbox={true}
                displayValue="value"
                validator={(e) => {
                  register({ required: true })
                  listViewRef.current = e
                }}
                onSelect={handleListViewMenuChange}
                onRemove={handleListViewMenuChange}
                defaultValue={resp?.additionalCare && resp.additionalCare}
                labelSubText={'Select all that apply'}
              />
              {
                hideButtons && <div className="row">
                  <div className="mt-4 pt-2 col-sm-12 p-0">
                    <div className="offset-4">
                      <Button
                        type={"submit"}
                        classes={{ className: `btn ${style.btn_save}` }}
                        id={'updateBtn'}
                        value={SAVE_BTN}
                      />
                      <Button
                        classes={{ className: `btn ${style.btn_cancel}` }}
                        type="button"
                        id="cancel"
                        value={CANCEL_BTN}
                        onClick={() => viewMode()}
                      />
                    </div>
                  </div>
                </div>
              }

            </Form>
            :
            'This wound assessment is signed and locked, cannot be updated.'
          }
        </div>
    }
  </div>
}
