import React, { useState, useEffect, useRef, useCallback } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form, TextField, Button, SelectBox, MultiSelect, Organizational } from '../../primary';
import styles from './assign.module.scss';
import { EDIT, CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants';
import editIcon from '../../../static/images/edit-icon-blue.png';
import rightArrow from '../../../static/images/right_arrow.svg';
import { getFacilitiesAsync, setUpdateStatus, reSetFacilitiesData,getAssignEmailAsyn,setAssignEmail } from '../../../app/customFormSlice';
import { getFacilityLabel, customerNumberValid, OrganizationValid, SequenceValid } from '../../../utils';
import ReactTags from "react-tag-autocomplete";
import Loader from '../../../static/images/spinner.svg';
export const Assign = ({ upDateForm }) => {
    const emailTagsRef = useRef();
    const [ emailTags, setEmailTags] = useState([]);
    const [emailTagsSuggestions, setEmailTagsSuggestions] = useState([]);
    const [isBusy, setIsBusy] = useState(false)
   
    let dispatch = useDispatch();       
    const assignForm = useRef();
    let formData = useSelector(state => state.forms.formDetailsData)
    
    useEffect(() => {
        dispatch(setUpdateStatus({}))
        if (formData?.assignInfo !== null && formData?.assignInfo !== undefined && formData?.assignInfo?.customerNumber !== null || formData?.assignInfo?.customerGroup !== null) {
            if (formData?.assignInfo?.customerNumber) {
                dispatch(getFacilitiesAsync(formData?.assignInfo?.customerNumber))
            }
            setEditable(false)
        } else {
            dispatch(reSetFacilitiesData({}))
           // setEditable(true)
        }
        
        if (formData?.assignInfo?.feature === "Survey"&&formData?.assignInfo?.userEmail&&Array.isArray(formData?.assignInfo?.userEmail)) {
            let finalEmailSelected= [];
            formData?.assignInfo?.userEmail?.map((item) => {
                     finalEmailSelected.push({ id: item.id, name: item.email });
                });
            setEmailTags(finalEmailSelected)
        }
       
    }, [formData?.assignInfo])
    let upDateStatus = useSelector(state => state.forms.upDateStatus)
    let facilitiesData = useSelector(state => state.forms.orgData);
    let optionData = [];
    const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')

    soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
        const shipToFacilities = facilitiesData.data.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
        if (shipToFacilities.length === 0) {
            optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(soldToFacility),
                facilityNumber: soldToFacility.facilityNumber,
                soldToFacility: 'soldToFacility'
            })
        }
        shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
            optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(shipToFacility),
                facilityNumber: shipToFacility.facilityNumber,
                shipToFacilities: 'shipToFacilities'
            })
        })
    })
    const [editable, setEditable] = useState(false)

    /* Function to toggle edit */
    const handleEdit = () => {
        setEmailTagsSuggestions([])
        setIsBusy(false)
        setErrorMsg('')
        setCustomerNumber(formData?.assignInfo?.customerNumber?formData?.assignInfo?.customerNumber:'')
        setRemoveDefaultOrg(true)
        setOrg(defaultFacilities)
        setEditable(true)
        if (formData?.assignInfo?.customerGroup !== null) {
            setCreateGroup(true)
            setCustomerNumberInp(false)
        } else {
            setCustomerNumberInp(true)
            setCreateGroup(false)
        }
        if ((formData?.assignInfo?.customerGroup === null && formData?.assignInfo?.customerNumber === null)||(formData?.assignInfo?.customerGroup === null && formData?.assignInfo?.customerNumber === '')) {
            setCustomerNumberInp(false)
            setCreateGroup(false)
        }
        if (formData?.assignInfo?.feature === "Survey"&&formData?.assignInfo?.userEmail&&Array.isArray(formData?.assignInfo?.userEmail)) {
            let finalEmailSelected= [];
            formData?.assignInfo?.userEmail?.map((item) => {
                     finalEmailSelected.push({ id: item, name: item });
                });
            setEmailTags(finalEmailSelected)
        }else{
            setEmailTags([]);
        }
    }

    const handleCancel = () => {
      
        setEditable(false)
        setErrorAssign(false)
        if (formData?.assignInfo?.customerNumber) {
            dispatch(getFacilitiesAsync(formData?.assignInfo?.customerNumber))
        }
    }

    /* Use hook form */
    const { register, handleSubmit, control, errors, reset, getValues } = useForm({
        mode: 'onBlur'
    });
    const [errorAssign, setErrorAssign] = useState(false)
    const onSubmit = (data) => {
        const emailIDs = Array.isArray(emailTags)&&emailTags.map(({ name }) => name);
        setUpdateStatus({})
        const soldToNumbers = [];
        org.forEach((soldTo) => {
            const facilityNumber = soldTo?.cat?.split('|')[0]?.trim();
            if (!soldToNumbers.find(st => st.soldToNumber === facilityNumber)) {
                soldToNumbers.push({
                    "soldToNumber": facilityNumber,
                    "shipToNumbers": org.filter(k => k.cat === soldTo.cat).map(shipTo => shipTo?.key?.split('|')[0]?.trim())
                });
            }
        })
        const tempUserData = {
            customerGroup: customerGroup,
            customerNumber: customerGroup === null ? customerNumber : null,
            organization: customerGroup === null ? {
                primaryGroupNumber: facilitiesData?.data?.facilities?.find((f) => f.accountGroup === 'Z002')?.facilityNumber,
                soldToNumbers
            } : null,
            feature: formData?.assignInfo?.feature,
            subFeature: formData?.assignInfo?.feature === "NE1 WMS" ? formData?.assignInfo?.subFeature : null,
            sequence: sequence
        }
        if(formData?.assignInfo?.feature === "Survey"){
            tempUserData.userEmail=emailIDs
        }
        let result = { ...formData }
        result.assignInfo = { ...result.assignInfo, ...tempUserData }
        /*if (customerGroup === null && customerNumber === '' && emailIDs?.length>0&&formData?.assignInfo?.feature === "Survey") {
            upDateForm(result);
            setEditable(false)
            setEmailTags([]);
        }
        else if (customerGroup !== null || customerNumber !== "" && customerNumber !== null && org.length > 0) {
            upDateForm(result);
            setEditable(false)
            setEmailTags([]);
        } else {
            setErrorAssign(true)
            setEditable(true)
        }*/
        upDateForm(result);
        setEditable(false)
        setEmailTags([]);
    }

    const onChangeOrg = (selectedList, selectedItem) => {
        setErrorAssign(false);
        setOrg([...selectedList]);
        setRemoveDefaultOrg(false)
    }

    const getOrganization = () => {
        if(emailTagsRef&& emailTagsRef.current){
            emailTagsRef.current.clearInput()
        }
        setEmailTags([]);
        const customerNum = document.getElementById("customerNumber").value
        if (customerNum) {
            setOrg([])
            //assignForm.current.resetSelectedValues()
            setRemoveDefaultOrg(false)
            dispatch(reSetFacilitiesData({}))
            setCustomerNumber(customerNum)
            dispatch(getFacilitiesAsync(customerNum))
            setCreateGroup(false);
            setCustomerNumberInp(true)       
       }
    }

    let [customerNumber, setCustomerNumber] = useState(formData?.assignInfo?.customerNumber)
    let [removeDefaultOrg, setRemoveDefaultOrg] = useState(true)
    /*const getCustomerNumber = (e) => {
        setOrg([])
        //assignForm.current.resetSelectedValues()
        setRemoveDefaultOrg(false)
        dispatch(reSetFacilitiesData({}))
        setCustomerNumber(e.target.value)
        dispatch(getFacilitiesAsync(e.target.value))
        setCreateGroup(false);
        setCustomerNumberInp(true)
    }*/
    const enterCustomerNumber = (e) => {
        if(emailTagsRef&& emailTagsRef.current){
            emailTagsRef.current.clearInput()
        }
        setEmailTags([]);
        if (e.keyCode === 13) {
            setOrg([])
            //assignForm.current.resetSelectedValues()
            setRemoveDefaultOrg(false)
            dispatch(reSetFacilitiesData({}))
            setCustomerNumber(e.target.value)
            dispatch(getFacilitiesAsync(e.target.value))
            setCreateGroup(false);
            setCustomerNumberInp(true)
        }
        if (e.target.value === "") {
            setOrg([])
            setRemoveDefaultOrg(false)
            setCustomerNumberInp(false)
            setCreateGroup(false);
            setCustomerNumber(null)
            dispatch(reSetFacilitiesData({}))
            //assignForm.current.resetSelectedValues();
        }
    }

    const assignedFacilities = () => {
        const facilities = [];
        formData?.assignInfo?.organization?.soldToNumbers?.forEach((soldTo) => { //NOSONAR
            /*const soldToFacility = facilitiesData?.data?.facilities?.find((f) => f.facilityNumber === soldTo.soldToNumber) //NOSONAR
            soldToFacility && facilities.push(<div className={`col-form-label ${styles.content_spec}`}>
                {getFacilityLabel(soldToFacility)}
            </div>);*/

            const shipToFacilities = facilitiesData?.data?.facilities?.filter((f) => soldTo.shipToNumbers.includes(f.facilityNumber)) //NOSONAR
            shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
                facilities.push(<div className={`col-form-label ${styles.content_spec}`}>
                    {getFacilityLabel(shipToFacility)}
                </div>);
            });
        }) //NOSONAR
        return facilities;
    }

    const defaultFacilities = () => {
        const facilities = [];
        formData?.assignInfo?.organization?.soldToNumbers?.forEach((soldTo) => { //NOSONAR
            const soldToFacility = facilitiesData?.data?.facilities?.find((f) => f.facilityNumber === soldTo.soldToNumber) //NOSONAR
            let soldToFacilityLabel, shipToFacilityLabel;
            if (soldToFacility) {
                soldToFacilityLabel = getFacilityLabel(soldToFacility);
            }
            const shipToFacilities = facilitiesData?.data?.facilities?.filter((f) => soldTo.shipToNumbers.includes(f.facilityNumber)) //NOSONAR
            if (shipToFacilities && shipToFacilities.length > 0) {
                shipToFacilities.forEach((shipToFacility) => {
                    shipToFacilityLabel = getFacilityLabel(shipToFacility);
                    facilities.push({ cat: soldToFacilityLabel, key: shipToFacilityLabel });
                });
            }
        }) //NOSONAR
        return facilities;
    }

    const [org, setOrg] = useState(defaultFacilities());

    const [errorMsg, setErrorMsg] = useState("")
    useEffect(() => {
        let fac = ""
        if (facilitiesData?.errorMessage === "Invalid Customer Number.") {
            fac = "Customer number doesn’t match our record"
        } else if (facilitiesData?.data !== undefined) {
            if (optionData.length === 0) {
                fac = "Organization mapping is missing for the customer"
            }
        }
        setErrorMsg(fac)
    }, [facilitiesData])

    const [createGroup, setCreateGroup] = useState(false);
    const [customerNumberInp, setCustomerNumberInp] = useState(false);
    const [customerGroup, setCustomerGroup] = useState(formData?.assignInfo?.customerGroup)
    const getCustomerGroup = (e) => {
        if(emailTagsRef&& emailTagsRef.current){
            emailTagsRef.current.clearInput()
        }
        
        setEmailTags([]);
        if (e.target.value !== "") {
            setCustomerGroup(e.target.value)
            setCreateGroup(true);
            //assignForm.current.resetSelectedValues();
            document.getElementById('customerNumber').value = ""
            setErrorAssign(false)
            setRemoveDefaultOrg(false)
        } else {
            setCustomerGroup(null)
            setCreateGroup(false);
        }
    }

    const customerGroupText = {
        all: 'All Customer',
        postacute: 'Post-Acute',
        acute: 'Acute'
    }
    const [sequence, setSequence] = useState(0)
    const getSequenceNumber = (e) => {
        setSequence(e.target.value)
    }


    const onDelete = useCallback(
        (tagIndex) => {
            const finalTag=emailTags.filter((_, i) => i !== tagIndex);
            setEmailTags(finalTag);

            if(emailTags.length===0||emailTags.length===1){
                setCreateGroup(true);
                setCustomerNumberInp(false)   
            }

        },
        [emailTags]
        );

        const onAddition = useCallback(
        (newTag) => {
            const ids = emailTags.map(({ name }) => name);
            if (!ids.includes(newTag.name)) {
            const finalTag=[...emailTags, newTag];
            setEmailTags(finalTag);
            }
            
        },
        [emailTags]
        );

//onInput
const onInput = useCallback(
    (query) => {
        setOrg([])
        setRemoveDefaultOrg(false)
        dispatch(reSetFacilitiesData({}))
        setCustomerNumber('')
        setCustomerNumberInp(true)   
        document.getElementById('customergroup').value = ""
        setCustomerGroup(null)
        setCreateGroup(false);
        document.getElementById('customerNumber').value = ""
        setIsBusy(true)
        if(!isBusy&&query) {
            dispatch(getAssignEmailAsyn({query,setEmailTagsSuggestions,setIsBusy}))
           
        }
        if( Array.isArray(emailTags)&&emailTags?.length===0&&!query){
          
            setCreateGroup(false);
            setCustomerNumberInp(false)   
            
        }
        if(!query){
            setIsBusy(true)
            setEmailTagsSuggestions([])
           emailTagsRef.current.clearInput()
            emailTagsRef.current.state.query=''
        }
        
    },
    [emailTags]
    );
    const onFocus  = useCallback(
        (query) => {
            if(document.querySelector('.react-tags__search-input')){
                document.querySelector('.react-tags__search-input').setAttribute('autoComplete', 'nope');
                }
        },
        [emailTags]
        );
        const [tinyDelay, setTinyDelay] = useState(false)

        useEffect(() => {
          setTimeout(() => {
            setTinyDelay(true)
          }, 2500)
        }, [])
    return (
        <>
        {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
              <>
            <div className={`${styles.tab_content_top}`}>
                <h2 className={`${styles.tab_content_hdg}`}>
                    {formData.assignInfo && formData.name}
                    {!editable ?
                        <span className={`float-sm-right ${styles.tab_edit} handleEdit`} onClick={handleEdit} >
                            <img src={editIcon} alt="edit" />{EDIT}
                        </span>
                        : ''
                    }
                </h2>
            </div>
            {editable && errorAssign ? <span className={`${styles.error_message} text-center`}>{
               formData?.assignInfo?.feature === "NE1 WMS" ? 'Please select customer number and organization':formData?.assignInfo?.feature === "Survey"?'Please select customer group selection or customer number and organization or email' : 'Please select customer group selection or customer number and organization'}</span> : ''}
            <div className={`col-md-12 ${styles.single_product_block}`}>
                <div className="row">
                    <div className={`p-0 ${styles.patient_content}`}>
                        {editable && <Form formChanges={true} className={`${styles.patient_content} onsubmit`} onSubmit={handleSubmit(onSubmit)} >
                            {formData?.assignInfo?.feature !== "NE1 WMS" ? <><SelectBox
                                id={"customergroup"}
                                name={"customergroup"}
                                label={`Customer Group Selection:`}
                                classes={{
                                    column: `col-12 ${styles.col_spec}`,
                                    inputClassName: styles.basic_select,
                                    labelClassName: `col-3 col-form-label ${styles.label_spec}`,
                                    inputColumn: `col-3 p-0 ${styles.select_wrapper}`
                                }}
                                optionValue={[
                                    { key: 'all', value: 'All Customers' },
                                    { key: 'postacute', value: 'Post-Acute' },
                                    { key: 'acute', value: 'Acute' }
                                ]}
                                defaultValue={formData.assignInfo && formData.assignInfo.customerGroup}
                                errors={errors}
                                placeholder="Select"
                                onChange={(e) => getCustomerGroup(e)}
                                disabled={customerNumberInp ? true : false}
                            />
                                <div className="offset-4 mb-3"><strong>OR</strong></div></> : ''}

                                {formData?.assignInfo?.feature === "Survey" ? <>
                                    <div className={`col-12 ${styles.col_spec}`}>
                                    <label className={`col-3 col-form-label ${styles.label_spec}`}>Email: </label>
                                    <div className="col-8 p-0">
                                        <ReactTags
                                        ref={emailTagsRef}
                                        tags={emailTags}
                                        id={'emailDomain'}
                                        suggestions={emailTagsSuggestions}
                                        onDelete={onDelete}
                                        onAddition={onAddition}
                                        onInput={onInput}
                                         onFocus ={onFocus}
                                        classNames={{ root: `${styles.root_tag}` }}
                                        placeholderText={"Select Email"}
                                        allowBackspace={false}
                                        readOnly={false}
                                        maxSuggestionsLength ={1000}
                                        minQueryLength ={1}
                                        autoComplete="nope"
                                        />
                                    </div>
                                    
                                    </div>
                                   {emailTagsRef?.current?.state?.query&&Array.isArray(emailTagsSuggestions)&&emailTagsSuggestions.length===0&&<><div className={`col-3`}></div><span className={`${styles.error_message}  col-6 ml-n3 mt-n3`}>{'Entered email is not available'}</span></>} 
                                   
                                <div className="offset-4 mb-3"><strong>OR</strong></div></> : ''}
                            <TextField
                                id={"customerNumber"}
                                name={"customerNumber"}
                                label={"Customer Number:"}
                                classes={{
                                    column: `col-12 ${styles.col_spec}`,
                                    labelClass: `col-3 col-form-label ${styles.label_spec}`,
                                    inputColumn: `col-3 p-0 text-nowrap`,
                                    inputClassName: `${styles.basic_input} col-12 customerNum`
                                }}
                                type="text"
                                note={
                                    <span className={`col-3 col-form-label ${styles.form_span} getorg ${createGroup ? styles.disabled : ''}`} onClick={() => getOrganization()}>
                                        Search <img src={rightArrow} alt="" className={`${styles.img_left}`} />
                                    </span>
                                }
                                //onBlur={(e) => getCustomerNumber(e)}
                                onKeyUp={(e) => enterCustomerNumber(e)}
                                defaultValue={formData?.assignInfo?.customerNumber}
                                customError={createGroup ? '' : errorMsg}
                                disabled={createGroup ? true : false}
                            />
                            {<Organizational 
                                id={"organization"}
                                name={"organization"}
                                label={`Organization:`}
                                classes={{
                                    column: `col-12 ${styles.col_spec}`,
                                    inputClassName: styles.basic_select,
                                    labelClassName: `col-3 col-form-label ${styles.label_spec} onselect`,
                                    inputColumn: `col-7 p-0 ${styles.select_wrapper} ${createGroup ? styles.disabled : ''}`
                                }}
                                optionValue={optionData}
                                groupBy="cat"
                                displayValue="key"
                                //defaultValue={removeDefaultOrg ? defaultFacilities() : ''}
                                defaultValue={removeDefaultOrg ? defaultFacilities() :Array.isArray(org) ? org : []}
                                onSelect={onChangeOrg}
                                onRemove={onChangeOrg}
                                disabled={false}
                                assignPage={true}
                                selectAll={true}  
                            />}

                            {/*<MultiSelect
                                id={"organization"}
                                name={"organization"}
                                label={`Organization:`}
                                classes={{
                                    column: `col-12 ${styles.col_spec}`,
                                    inputClassName: styles.basic_select,
                                    labelClassName: `col-3 col-form-label ${styles.label_spec} onselect`,
                                    inputColumn: `col-7 p-0 ${styles.select_wrapper} ${createGroup ? styles.disabled : ''}`
                                }}
                                optionValue={optionData}
                                groupBy="cat"
                                displayValue="key"
                                showCheckbox={true}
                                onSelect={onChangeOrg}
                                onRemove={onChangeOrg}
                                validator={(e) => {
                                    register({ required: true })
                                    assignForm.current = e
                                }}
                                defaultValue={removeDefaultOrg ? defaultFacilities() : ''}
                                disabled={false}
                            /> */}
                            <SelectBox
                                id={"feature"}
                                name={"feature"}
                                label={`Feature:`}
                                classes={{
                                    column: `col-12 ${styles.col_spec}`,
                                    inputClassName: styles.basic_select,
                                    labelClassName: `col-3 col-form-label ${styles.label_spec}`,
                                    inputColumn: `col-3 p-0 ${styles.select_wrapper}`
                                }}
                                optionValue={[
                                    { key: 'NE1 WMS', value: 'NE1 WMS' },
                                    { key: 'Product Selector', value: 'Product Selector' },
                                    { key: 'Survey', value: 'Survey' }
                                ]}
                                defaultValue={formData?.assignInfo?.feature}
                                disabled={true}
                            />
                            <SelectBox
                                id={"subFeature"}
                                name={"subFeature"}
                                label={`Sub-Feature:`}
                                classes={{
                                    column: `col-12 ${styles.col_spec}`,
                                    inputClassName: styles.basic_select,
                                    labelClassName: `col-3 col-form-label ${styles.label_spec}`,
                                    inputColumn: `col-3 p-0 ${styles.select_wrapper}`
                                }}
                                optionValue={[
                                    { key: 'null', value: 'Select' },
                                    { key: 'Characteristics', value: 'Characteristics' },
                                    { key: 'Treatment', value: 'Treatment' }
                                ]}
                                defaultValue={formData.assignInfo && formData.assignInfo.subFeature}
                                disabled={true}
                            />
                            <TextField
                                id={"sequence"}
                                name={"sequence"}
                                label={"Sequence:"}
                                classes={{
                                    column: `col-12 ${styles.col_spec}`,
                                    labelClass: `col-3 col-form-label ${styles.label_spec}`,
                                    inputColumn: 'col-3 p-0',
                                    inputClassName: `${styles.basic_input} `
                                }}
                                type="text"
                                defaultValue={formData.assignInfo && formData.assignInfo.sequence}
                                disabled={formData?.assignInfo?.feature !== "NE1 WMS" ? true : false}
                                onKeyUp={(e) => getSequenceNumber(e)}
                            />
                            <div className="col-sm-12">
                                <div className="text-center pb-4">
                                    <br />
                                    {/* Cancel button  */}
                                    <Button
                                        classes={{ className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small}` }}
                                        type="submit"
                                        id={SUBMIT_ID}
                                    >
                                        {SAVE_BTN}
                                    </Button>
                                    <Button
                                        classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} handleCancel` }}
                                        id={CANCEL_ID}
                                        onClick={handleCancel}
                                    >
                                        {CANCEL_BTN}
                                    </Button>

                                    {/* Save Button */}

                                </div>
                            </div>
                        </Form>
                        }
                        {!editable &&
                            <>
                                {(upDateStatus) && upDateStatus?.status === "success" && <div className={`alert alert-success ${styles.form_alert}`}>
                                    Successfully updated
                                </div>}
                                {(upDateStatus) && upDateStatus?.status === "error" && <div className={`alert alert-danger ${styles.form_alert}`}>
                                    Assign Form not update
                                </div>}
                                {formData?.assignInfo?.feature !== "NE1 WMS" ? <div className={`col-12 ${styles.col_spec}`}>
                                    <label className={`col-3 col-form-label ${styles.label_spec}`}>Customer Group Selection:</label>
                                    <div className="col-3 p-0">
                                        <span className={`col-form-label d-inline-block ${styles.content_spec}`}>
                                            {formData?.assignInfo && customerGroupText[formData?.assignInfo?.customerGroup] ? customerGroupText[formData?.assignInfo?.customerGroup] : '-'}
                                        </span>
                                    </div>
                                </div> : ''}
                                {formData?.assignInfo?.feature === "Survey" && <div className={`col-12 ${styles.col_spec}`}>
                                    <label className={`col-3 col-form-label ${styles.label_spec}`}>Email:</label>
                                    <div className="col-9 p-0">
                                        {formData?.assignInfo?.userEmail &&Array.isArray(formData?.assignInfo?.userEmail)&& formData?.assignInfo?.userEmail.length > 0 ? formData?.assignInfo?.userEmail.map((data, i) => {
                                        return  <span className={`col-form-label d-inline-block ${styles.content_spec}`}>
                                         {i!=0?',':''} {data}
                                        </span>
                                        }):'-'}
                                      
                                    </div>
                                </div>}
                                <div className={`col-12 ${styles.col_spec}`}>
                                    <label className={`col-3 col-form-label ${styles.label_spec}`}>Customer Number:</label>
                                    <div className="col-3 p-0">
                                        <span className={`col-form-label d-inline-block ${styles.content_spec}`}>
                                            {formData?.assignInfo && formData?.assignInfo?.customerNumber ? formData?.assignInfo?.customerNumber : '-'}
                                        </span>
                                    </div>
                                </div>
                                <div className={`col-12 ${styles.col_spec}`}>
                                    <label className={`col-3 col-form-label ${styles.label_spec}`}>Organization:</label>
                                    <div className="col-7 p-0">
                                        {assignedFacilities().length > 0 ? assignedFacilities() : '-'}
                                    </div>
                                </div>
                                <div className={`col-12 ${styles.col_spec}`}>
                                    <label className={`col-3 col-form-label ${styles.label_spec}`}>Feature:</label>
                                    <div className="col-3 p-0">
                                        <span className={`col-form-label d-inline-block ${styles.content_spec}`}>
                                            {formData.assignInfo && formData.assignInfo.feature}
                                        </span>
                                    </div>
                                </div>
                                <div className={`col-12 ${styles.col_spec}`}>
                                    <label className={`col-3 col-form-label ${styles.label_spec}`}>Sub-Feature:</label>
                                    <div className="col-3 p-0">
                                        <span className={`col-form-label d-inline-block ${styles.content_spec}`}>
                                            {formData.assignInfo && formData.assignInfo.subFeature ? formData.assignInfo.subFeature : '-'}
                                        </span>
                                    </div>
                                </div>
                                <div className={`col-12 ${styles.col_spec}`}>
                                    <label className={`col-3 col-form-label ${styles.label_spec}`}>Sequence:</label>
                                    <div className="col-3 p-0">
                                        <span className={`col-form-label d-inline-block ${styles.content_spec}`}>
                                            {formData.assignInfo && formData.assignInfo.sequence}
                                        </span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            </>}
        </>
    )
}
