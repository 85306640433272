import { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkListRecordsCount, setLastVal } from "../../../app/patientSlice";
import { VerticalTabWorkList } from "../../secondary/VerticalTabWorkList";
import { WoundExpertDebridement } from "./woundExpertDebridement";
import { WoundExpertPOAStage } from "./woundExpertPOAStage";
import { WoundExpertPOAStageUnstage } from "./woundExpertPOAStageUnstage";

export function WoundExpert({ facilityNumber, history }) {
    useEffect(() => {
        if(facilityNumber){
            dispatch(getWorkListRecordsCount(facilityNumber,'woundExpertValues'));
        }
       }, [facilityNumber])
    const workListRecordsCount = useSelector(state => state.patient.workListRecordsCount);
    const piPoaCount=workListRecordsCount?.woundExpertValues?.piPoaCount?`(${workListRecordsCount?.woundExpertValues?.piPoaCount})`:''
    const piNonPoaCount=workListRecordsCount?.woundExpertValues?.piNonPoaCount?`(${workListRecordsCount?.woundExpertValues?.piNonPoaCount})`:''
    const sharpsurgicalDebridement=workListRecordsCount?.woundExpertValues?.sharpsurgicalDebridement?`(${workListRecordsCount?.woundExpertValues?.sharpsurgicalDebridement})`:''

    /* Tab list */
    const tabList = [
        {
            name: `POA ${piPoaCount}`,
            content: <WoundExpertPOAStage facilityNumber={facilityNumber} />,
            bottomName: 'Pending Review'
        },
        {
            name: `Non-POA ${piNonPoaCount}`,
            content: <WoundExpertPOAStageUnstage facilityNumber={facilityNumber} />,
            bottomName: 'Pending Review'
        },
        {
            name: `Sharp/Surgical Debridement : Excisional Full Thickness ${sharpsurgicalDebridement}`,
            content: <WoundExpertDebridement facilityNumber={facilityNumber} />,
            bottomName: 'Pending Review'
        },
    ];
    const dispatch = useDispatch();
    const lastVal = useSelector(state => state.patient.lastVal)
    const [defaultVerTab, setDefaultVerTab] = useState(lastVal?.selectVerTab?lastVal?.selectVerTab:0)
    const onSelectVerTab = (e) => {
      setDefaultVerTab(e)
       const setLast={...lastVal,selectVerTab: e }
       dispatch(setLastVal(setLast))
    
      }
    return (
        <>
            <VerticalTabWorkList onSelectInt={onSelectVerTab} defaultIndex={defaultVerTab<=2?defaultVerTab:0} tabList={tabList} dispatch={dispatch} />

        </>
    )
}
