import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, TextField, Button, SelectBox, DateTimePicker } from '../../primary';
import { Modal, ActionModal } from '../../secondary';
import styles from './simpleCreatePatient.module.scss';
import { checkFirstNamePatient, checkLastNamePatient, checkAdmissionDate, checkNoFuturedatePatient, checkGenderPatient, checkMRNNumber, ServiceError } from '../../../utils';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ADMISSION_DATE_ID, ADMISSION_TIME_ID, ADMISSTION_DATE_LBL, BROWSE_LBL, CANCEL_BTN, CANCEL_ID, DATE_OF_BIRTH_ID, DATE_OF_BIRTH_LBL, FIRST_NAME_ID, FIRST_NAME_LBL, LAST_NAME_ID,
  LAST_NAME_LBL, PATIENT_PICTURE_ID, PATIENT_PICTURE_LBL, SAVE_BTN, SUBMIT_ID, SUCCESS_STATUS, ERROR_STATUS, GENDER_ID, GENDER_LBL, PATIENT_CREATED_MSG, MRN_Number
} from '../../../constants';
import { PictureIcon } from '../../secondary';
import { format } from 'date-fns';
import { createPatientAsync, resetFacilitiesData, getNewFacilityDataAsync, simpleCreatePatientAsync, getSimpleNewFacilityDataAsync, setResMsgState } from '../../../app/patientSlice';
import iconSuccess from '../../../static/images/icons-check-success.png';
import iconDanger from '../../../static/images/icon-check-danger.png';
import { clearError } from "../../../app/appSlice";
import infoIcon from '../../../static/images/info.svg';

export let SimpleCreatePatient = ({ dispatch, history, modalOpen, modalClose, selectedFacility, getPatientList,facilityListView }) => {
  const { register, handleSubmit, control, errors, reset, setError } = useForm({
    mode: 'onSubmit'
  });
  const path = window.location.href;
  const origin = window.location.origin;
  const isAdminView = path.includes(`${origin}/admin`);
  const [msgModal, setMsgModal] = useState(false);
  const userDetails = useSelector(state => state.user);
  const customerNumber = (userDetails.customerNumber) ? userDetails.customerNumber : 0;
  const resMsgState = useSelector(state => state.patient.resMsgState);

  const ErrorMessage = ServiceError();
  useEffect(() => {
    if (ErrorMessage==="MRN already registered with another patient") {
      setError("mrn_number", {
        type: "manual",
        message: ErrorMessage
      });
      document.getElementById("mrn_number").focus();
      dispatch(clearError());
    }    
  }, [ErrorMessage,dispatch]);

  const onSubmit = (data) => {
    const admissionDate = (document.getElementById("admissionDate").value) ? document.getElementById("admissionDate").value : '';
    const admissionTime = (document.getElementById("admissionTime").value) ? document.getElementById("admissionTime").value : '';
    if (admissionTime === '') {
      setError('admissionDate', { type: 'Manual', message: 'Enter Patient’s Admission Date & Time' });
      return
    }
    let finalPic;
    let dataFacility = data?.facility; 
    dataFacility = dataFacility.replace(/[\|\s]/g,'');
    if (previewImg) {
      finalPic = previewImg.replace('data:image/png;base64,', '')
      finalPic = finalPic.replace('data:image/jpeg;base64,', '')
      finalPic = finalPic.replace('data:image/jpg;base64,', '')
      finalPic = finalPic.replace('data:image/svg+xml;base64,', '')
    }
    const payLoadData = {
      //facilityNumber: selectedFacility ? selectedFacility : dataFacility,
      facilityNumber: dataFacility ? dataFacility : selectedFacility,
      admissionDate: new Date(admissionDate + ' ' + admissionTime).toLocaleString(),//.toISOString() //.toUTCString()
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      dob: format(new Date(data.dateOfBirth), 'MM/dd/yyyy'),
      profilePicture: finalPic ? finalPic : data.profilePicture,
      mrn: data.mrn_number
    }
    dispatch(simpleCreatePatientAsync({payload: payLoadData, history, getPatientList}))

    // dispatch(simpleCreatePatientAsync({payload: payLoadData, history, getPatientList})).then(
    //   () => {
    //     setMsgModal(true)
    //     reset();
    //     setPreviewImg(null)
    //     setButtonState(true)
    //     setTimeout(() => {
    //       modalClose();
    //       setMsgModal(false)
    //     }, 4000);
    //   })
  }


  useEffect(() => {
    if (resMsgState === "success") {
      setMsgModal(true)
      // setButtonState(true)
      reset();
      setTimeout(() => {        
        modalClose();
        setMsgModal(false)
        // dispatch(setResMsgState(false))
      }, 4000)
    }
  }, [resMsgState]);

  useEffect(() => {
    dispatch(setResMsgState({}))
    modalClose()
    setMsgModal(false)
    if (!isAdminView) {
      let facilityNumber = {}
      facilityNumber['customerNumber'] = customerNumber;
      // dispatch(checkCustomerAsync(facilityNumber, history))
  //    dispatch(getNewFacilityDataAsync(facilityNumber, history))
      dispatch(getSimpleNewFacilityDataAsync(facilityNumber, history))
      dispatch(setResMsgState({}))
    modalClose()
    setMsgModal(false)
      //getNewFacilityDataAsync
    } else {
      resetFacilitiesData();
      dispatch(setResMsgState({}))
    modalClose()
    setMsgModal(false)
    }
  }, [dispatch])  

  // const getFacilities = useSelector(state => state.patient.newFacilitiesData);
  const getFacilities = useSelector(state => state.patient.simpleNewFacilitiesData);
  let optionData = [];

  (getFacilities?.data) && getFacilities?.data?.facilities?.map((value, index) => {//NOSONAR
        optionData.push({ value: `
        ${value?.facilityNumber !== null ? value.facilityNumber  : ''}  
        ${value?.facilityName !== null ? ` | ` + value.facilityName  : ''} 
        ${value?.address?.addressLine1 !== null ? ` | ` + value.address.addressLine1  : '' }  
        ${value?.address?.addressLine2 !== null ? ` | ` + value.address.addressLine2  : '' } 
        ${value?.address?.city !== null ?  ` | ` + value.address.city :''}  
        ${value?.address?.state !== null ? ` | ` + value.address.state :''} 
        ${value?.address?.zipCode !== null ? ` | ` + value.address.zipCode :''}`, 
        key: `${value?.facilityNumber !== null? value.facilityNumber :''}`, manualPatientCreation: `${value?.manualPatientCreation===0 ? false : true}`})
    })//NOSONAR

  let dobRef = useRef();
  let admissionRef = useRef();

  const [previewImg, setPreviewImg] = useState(null)
  const [buttonState, setButtonState] = useState(true)

  const onCancel = (e) => {
    e.preventDefault();
    reset();
    modalClose();
    setRemImg(true)
  }

  useEffect(async () => {
    reset();
  }, [reset])

  useEffect(() => {
    if(resMsgState === SUCCESS_STATUS&&msgModal){
      if (document.getElementById("facility")) {
        document.getElementById('facility').value = ''
      }
      if(facilityListView){
        facilityListView('')
      }
    }
  }, [resMsgState,msgModal])

  const [remImg, setRemImg] = useState(false)
  useEffect(() => {
    setRemImg(false)
  }, [remImg])

  if (document.getElementById("patientFacility") && selectedFacility) {
    document.getElementById('patientFacility').value = selectedFacility
  }

  return (
    <>
      {!msgModal && <Modal
        modalOpen={modalOpen}
        modalClose={modalClose}
        heading={"Add Patient"}
        className={"modal_box"}
        modalSize={styles.custom_modal_size}
        reset={reset}
      >
        {/* <div className={`col-sm-12 ${styles.col_spec}`}>
          <p className={`col-12 ${styles.subheading_2} pl-4`}>Contact Information:</p>
        </div> */}
        <Form className={`pl-2 ${styles.patient_content} create_patient`} onSubmit={handleSubmit(onSubmit)}>
            <div className={`col-12 ${styles.patient_content_mrn_field} ml-3`}>
              <TextField
                id={'mrn_number'}
                name={'mrn_number'}
                label={`Enter ${MRN_Number}:`}
                classes={{ column: `col ${styles.col_spec} justify-content-center mr-5 pr-5`, labelClass: `col-form-label ${styles.label_spec} ${styles.custom_label_spec} pl-4`, inputColumn: 'col-4 p-0', inputClassName: styles.basic_input }}
                type="text"
                validator={register(checkMRNNumber)}
                errors={errors}
                // defaultValue={''}
                required={true}
                charLimit={20}
                labelMrn={"mrn"}
              />
            </div>
            {/* <div className={`col-sm-12 ${styles.col_spec}`}>
              <p className={`col-12 ${styles.subheading_2} pl-3`}>Contact Information:</p>
            </div> */}
          {
            optionData && Array.isArray(optionData) && optionData.length === 1 ?
              <input type="hidden" name={"facility"} id={"patientFacility"} ref={register} style={{ visibility: "hidden" }} defaultValue={(optionData && optionData[0]) ? optionData[0].key : ''} /> : 
              <>
              <SelectBox
                id={"patientFacility"}
                name={"facility"}
                label={`Facility:`}
                classes={{
                  column: `col ${styles.col_spec}`, labelClassName: `col-form-label  ${styles.label_spec} ${styles.custom_label_spec}`,
                  inputColumn: `col-8 p-0  ${styles.select_wrapper}`, inputClassName: `${styles.basic_select} ${styles.basic_select_pro} form-control`
                }}
                optionValue={optionData}
                // optionValue={Array.isArray(optionData)?optionData:[]}
                validator={register({ required: true })}
                defaultValue={selectedFacility}
                required={true}
              />
              <span className={`${styles.tooltip} ${styles.tooltip_list}`}>                   
                  <img src={infoIcon} alt="info"  style={{fontSize:"16px", width: "26px", height : "auto", paddingLeft:"3px", paddingRight:"5px", color:"#0052cc"}}/>                                                            
                  <span class={`${styles.tooltiptext}`}>{'Manual patient creation is disabled for some facilities by your organization'}</span>                                                            
              </span>
              </>
          }
          <DateTimePicker
            id={ADMISSION_DATE_ID}
            name={ADMISSION_DATE_ID}
            label={`${ADMISSTION_DATE_LBL}:`}
            timeName={ADMISSION_TIME_ID}
            control={control}
            classes={{
              date_picker: styles.basic_input,
              time_picker: `${styles.admission_time_fix} ${styles.basic_input}`,
              dateColumn: `col-3 p-0 ${styles.admission_left}`,
              timeColumn: `col-2 pr-0`,
              column: `col ${styles.col_spec}`,
              labelClass: `col-form-label ${styles.label_spec} ${styles.custom_label_spec}`
            }}
            defaultValue={new Date()}
            defaultTime={new Date()}
            displayIcon={true}
            timeRequired={true}
            showTimeInput={true}
            dateRef={admissionRef}
            timeFormat="h:mm aa"
            errors={errors}
            timeID={ADMISSION_TIME_ID}
            validator={checkAdmissionDate}
            required={true}
            createPatient={true}
          />
          <TextField
            id={FIRST_NAME_ID}
            name={FIRST_NAME_ID}
            label={`${FIRST_NAME_LBL}:`}
            classes={{ column: `col ${styles.col_spec}`, labelClass: `col-form-label ${styles.label_spec} ${styles.custom_label_spec}`, inputColumn: 'col-4 p-0', inputClassName: styles.basic_input }}
            type="text"
            validator={register(checkFirstNamePatient)}
            errors={errors}
            defaultValue={''}
            required={true}
          />
          <TextField
            id={LAST_NAME_ID}
            name={LAST_NAME_ID}
            label={`${LAST_NAME_LBL}:`}
            classes={{ column: `col ${styles.col_spec}`, labelClass: `col-form-label ${styles.label_spec} ${styles.custom_label_spec}`, inputColumn: 'col-4 p-0', inputClassName: styles.basic_input }}
            type="text"
            validator={register(checkLastNamePatient)}
            errors={errors}
            defaultValue={''}
            required={true}
          />
          <SelectBox
            id={GENDER_ID}
            name={GENDER_ID}
            label={`${GENDER_LBL}:`}
            classes={{
              column: `col ${styles.col_spec}`, labelClassName: `col-form-label  ${styles.label_spec} ${styles.custom_label_spec}`,
              inputColumn:  `col-4 p-0 ${styles.select_wrapper}`, inputClassName: `form-control ${styles.basic_select} `
            }}
            placeholder="Select"
            optionValue={[{ value: 'Male', key: 'male' }, { value: 'Female', key: 'female' }, { value: 'Other', key: 'other' }, { value: 'Unknown', key: 'unknown' }]}
            errors={errors}
            validator={register(checkGenderPatient)}
            required={true}
          />
          <DateTimePicker
            id={DATE_OF_BIRTH_ID}
            name={DATE_OF_BIRTH_ID}
            label={`${DATE_OF_BIRTH_LBL}:`}
            control={control}
            classes={{
              date_picker: styles.basic_input, dateColumn: "col-4 p-0", column: `col ${styles.col_spec}`, labelClass: `col-form-label ${styles.label_spec} ${styles.custom_label_spec}`
            }}
            defaultValue={new Date()}
            displayIcon={true}
            validator={checkNoFuturedatePatient}
            showMonthDropdown={true}
            showYearDropdown={true}
            dateRef={dobRef}
            errors={errors}
            required={true}
          />
          <div className={`col ${styles.col_spec}`}>
            <label className={`col-form-label ${styles.label_spec} ${styles.custom_label_spec}`}>{PATIENT_PICTURE_LBL}:</label>
            <PictureIcon
              previewImg={previewImg}
              setPreviewImg={setPreviewImg}
              buttonState={buttonState}
              setButtonState={setButtonState}
              validator={register}
              id={PATIENT_PICTURE_ID}
              name={PATIENT_PICTURE_ID}
              browseLbl={BROWSE_LBL}
              removeImages={remImg}
            />
          </div>
          <div className="col-sm-12">
            <div className="text-center pb-4 mb-3 mt-4 p-4">
              <Button classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }} type="submit" id={SUBMIT_ID} >
                {SAVE_BTN}
              </Button>
              <Button classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} onCancel` }} id={CANCEL_ID} onClick={onCancel} >
                {CANCEL_BTN}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
      }
      {msgModal && <Modal
        modalOpen={modalOpen}
        modalClose={modalClose}
      >
        <div className="text-center p-4">
          {resMsgState === SUCCESS_STATUS && <ActionModal
            iconImg={iconSuccess}
            heading={PATIENT_CREATED_MSG}
            buttonText="Close"
            buttonAction={modalClose}
          />
          }
          {resMsgState === ERROR_STATUS && <ActionModal
            iconImg={iconDanger}
            heading="Patient not created!"
            buttonText="Close"
            buttonAction={modalClose}
          />
          }
        </div>
      </Modal>
      }
    </>
  )
}
SimpleCreatePatient.propTypes = {
  dispatch: PropTypes.func
};
SimpleCreatePatient = connect()(withRouter(SimpleCreatePatient));
