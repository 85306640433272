import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTable, useBlockLayout, useRowSelect, useSortBy } from 'react-table'
import style from './dataTable.module.scss';
import DefaultImg from '../../../static/images/picture-icon-default.png';
export const DataTable = ({ columns, data, sorting, rowSelect, getSelectedRowData,
  detailView, setSelectedRows, saveData, onSort, deleteDataList,
  showFixedColumn, searchKey, workListView, formularyView, formularyLeft, productType, fixedFullWidthSize,
  activePage,reportView, removeCursor,sortType, listType }) => {
      
  const userRole = useSelector(state => state.user);
  
  let fixedColumnCount = [];
  columns.map((value) => {
    value.fixedColumn && fixedColumnCount.push(value)
  })
  let scrollColumnWidth = 0;
  scrollColumnWidth = fixedColumnCount.length * 120;
  let custom_width_fixed = 100 / fixedColumnCount.length - 1 + "%";

  if (fixedColumnCount.length === 1 && !rowSelect) {
    custom_width_fixed = 100 / fixedColumnCount.length + "%";
  }
  
  let chkboxClmn = 8 + "%";
  if (fixedColumnCount.length > 1 && rowSelect) {

    chkboxClmn = 8 + "%";

  }
  let restColumnCount = columns.length - fixedColumnCount.length;
  let max_col = 3;
  let total_width = 100
  if (restColumnCount === 1) {
    max_col = 1;
  } 
  else if (restColumnCount === 2) {
    total_width = 50;
    max_col = 2;
  }
  else if (restColumnCount === 3) {
    total_width = 50;

  }
  else if (restColumnCount <=4) {
    total_width = 45;
  }

  let custom_width_rest = "";
  //check condition on workList data.
  if (!workListView) {
    custom_width_rest = total_width / restColumnCount * max_col + "%";
  }

  if (rowSelect) scrollColumnWidth = scrollColumnWidth + 50;
  const {
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { sortBy, selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      saveData,
      manualSortBy: true
    },
    (sorting) ? useSortBy : '',
    useBlockLayout,
    useRowSelect,
    hooks => {
      (rowSelect) &&
        hooks.visibleColumns.push(columns => [

          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>             
                <div className={`custom-control custom-checkbox ${style.custom_checkbox}`}>
                <input
                  type="checkbox" {...getToggleAllRowsSelectedProps()}
                  className={`custom-control-input ${style.custom_control_input} ${style.custom_checkbox_header}`}
                  id="selectAllCheck"
                />
                <label
                  className={userRole?.role!=='ExternalAdministrator' ? `custom-control-label ${style.custom_control_label} ${style.custom_checkbox_header}` : `d-none`}
                  htmlFor="selectAllCheck"></label>
              </div>              
              </>
            ),
            Cell: ({ row }) => (
              <>  
                {(row.values.type === "marketingbanner") || (row.values.type === "card")||(row.original.type==="postacute")||(row.original.type==="acute")||(row.original.type==="alternatesite")||(row.original.type==="international")||(row.original.type==="miscellaneous") ||(row.original.type==="isListDisable")||(row.original.isDisabled===1) ?
                  <div className={`custom-control custom-checkbox ${style.custom_checkbox}`}>
                    <input type="checkbox" disabled className={`custom-control-input ${style.custom_control_input} `} />
                    <label className={`custom-control-label ${style.custom_control_label}`} ></label>
                  </div>
                  :
                  <div className={`custom-control custom-checkbox ${style.custom_checkbox}`}>
                    <input type="checkbox" onClick={e => e.stopPropagation()} {...row.getToggleRowSelectedProps()} className={`custom-control-input ${style.custom_control_input}`} id={row.id} />
                    <label className={userRole?.role!=='ExternalAdministrator' ? `custom-control-label ${style.custom_control_label}` : `d-none`} htmlFor={row.id}></label>
                  </div>
                }
              </>

            ),
            disableSortBy: true,
            fixedColumn: showFixedColumn,
          },
          ...columns
        ])

    }


  )

  // useEffect(() => {
  //   onSort({ sortBy });
  // }, [onSort, sortBy]);

  useEffect(() => {
    onSort({ sortBy });
  }, [sortBy]);

  useEffect(() => {
    const enabledRows = selectedFlatRows.filter(row => row.original && !['card', 'marketingbanner','acute','postacute','isListDisable'].includes(row.original.type));
    if (enabledRows) {
      setSelectedRows(enabledRows.length);
      deleteDataList(enabledRows.map(row => row.original));
    }
  }, [deleteDataList, setSelectedRows, selectedFlatRows]);


  useEffect(() => {
    let fcBody = document.querySelector(".fix_column > .tbody"),
      rcBody = document.querySelector(".rest_columns > .tbody"),
      rcHead = document.querySelector(".rest_columns > .thead");

    if ((fcBody) || (rcBody)) {
      rcBody.scrollTop = 0
      rcBody.addEventListener("scroll", function () {
        fcBody.scrollTop = this.scrollTop;
        rcHead.scrollLeft = this.scrollLeft;

      }, { passive: true });

      return () => {
        rcBody.removeEventListener("scroll", function () {
          fcBody.scrollTop = this.scrollTop;
          rcHead.scrollLeft = this.scrollLeft;
        }, { passive: true });
      }
    }
  }, [data, activePage]);

  if (rows.length === 0 && columns.length < 5) {
    custom_width_rest = 75 / columns.length - 1 + "%";
  } else if (rows.length === 0 && columns.length > 5) {
    custom_width_rest = 100 / columns.length + 1 * max_col + "%";
  }

  return (
    <>
    <div className={`${(rows.length === 0) ? 'clearfix mt-3' : 'clearfix'}`}>
      {(rows.length !== 0) && <><div className={style.total_wrapper}>

        {(rows.length !== 0) &&
          <div className={`fix_column ${style.fix_column} ${rowSelect ? style.row_select : ''} ${workListView ? style.fixed_none : ''}  ${formularyView ? style.fixed_none : ''}`}
            style={{
              width: (fixedFullWidthSize ? fixedFullWidthSize + '%' : '50%')
            }}
          >
            <div className={`thead ${style.thead}`}>
              {headerGroups.map(headerGroup => (
                headerGroup.headers.map((column, index) => (

                  (column.fixedColumn) && <span key={index} className={`${style.cost_input}`} {...(sorting) && column.getSortByToggleProps()} style={{
                    width:
                      (index === 0 && !rowSelect && !column.fixedWidth) ? custom_width_fixed :
                        (index === 0 && rowSelect && fixedColumnCount.length > 1 && !column.fixedWidth) ? chkboxClmn :
                          (index === 0 && rowSelect && fixedColumnCount.length === 1 && !column.fixedWidth) ? chkboxClmn :
                            (column.fixedWidth) ? column.fixedWidth : custom_width_fixed

                  }} title={column.render('Header')}>
                    {column.render('Header')}

                    <span className={`${!column.disableSortBy ? column.isSorted ? column.isSortedDesc ? style.sort_by_desc : style.sort_by_asc : style.sort_by_default : ''}`}>
                    </span>
                  </span>
                ))
              ))}
            </div>
            <div className={`tbody ${rows.length <= 12 ? style.no_height : style.tbody} `}>
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  (detailView) ? <div className={`${style.trow} ${(row?.original?.strike_through===1) ?style.trow_strike:""} ${style.cursor_pointer}`} key={i} onClick={() => getSelectedRowData(row.original)}>
                    {row.cells.map((cell, index) => {

                      return (
                        (cell.column.fixedColumn) && <span className={`${style.cost_input} ${(row?.original?.strike_through===1) ?style.span_strike:""} ${listType==="Formulary"?'pr-4':""} `} key={index} style={{
                          width:

                            (index === 0 && !rowSelect && !cell.column.fixedWidt) ? custom_width_fixed :
                              (index === 0 && rowSelect && fixedColumnCount.length > 1 && !cell.column.fixedWidth) ? chkboxClmn :
                                (index === 0 && rowSelect && fixedColumnCount.length === 1 && !cell.column.fixedWidth) ? chkboxClmn :
                                  (cell.column.fixedWidth) ? cell.column.fixedWidth : custom_width_fixed

                        }} title={cell.render('Cell').props.cell.value}>
                          {cell.render('Cell')}
                        </span>
                      )
                    })}

                  </div>
                    : <div className={`${style.trow} ${(row?.original?.strike_through===1) ?style.trow_strike:""}`} key={i}>
                      {row.cells.map((cell, index) => {
                        return (
                          (cell.column.fixedColumn) && <span className={`${(row?.original?.strike_through===1) ?style.span_strike:""}`} key={index} style={{
                            width:
                              (index === 0 && !rowSelect && !cell.column.fixedWidt) ? custom_width_fixed :
                                (index === 0 && rowSelect && fixedColumnCount.length > 1 && !cell.column.fixedWidth) ? chkboxClmn :
                                  (index === 0 && rowSelect && fixedColumnCount.length === 1 && !cell.column.fixedWidth) ? chkboxClmn :
                                    (cell.column.fixedWidth) ? cell.column.fixedWidth : custom_width_fixed

                          }} title={cell.render('Cell').props.cell.value}>
                            {cell.render('Cell')}
                          </span>
                        )
                      })}
                    </div>
                )
              })}
            </div>
          </div>
        }

        <div className={`rest_columns ${style.rest_columns} ${(rows.length === 0) ? style.no_data : ''} ${workListView ? style.rest_fullwidth : ''} ${formularyView ? style.rest_fullwidth : ''}`}
          style={{
            width: (fixedFullWidthSize ? 100 - fixedFullWidthSize + '%' : '50%')
          }}
        >
          <div className={`thead ${style.thead} ${(rows.length === 0) ? style.thead_scroll : ''} ${formularyView && style.formulary_head}`}>
            {headerGroups.map(headerGroup => (
              headerGroup.headers.map((column, index) => (
                (!column.fixedColumn) && <span
                  key={index}
                  className={`${style.cost_input}`} {...(sorting) && column.getSortByToggleProps()}
                  style={{ width: (column.restWidth) ? column.restWidth : custom_width_rest }}
                  title={column.render('Header')}
                >
                  {column.render('Header')}
                  <span className={`${!column.disableSortBy ? column.isSorted ? column.isSortedDesc ? style.sort_by_desc : style.sort_by_asc : style.sort_by_default : ''}`}>
                  </span>
                </span>
              ))
            ))}
            <span className={style.last_span}></span>
          </div>
          <div className={`tbody ${rows.length <= 12 ? style.no_height : style.tbody} ${formularyView && style.formulary}`}>

            {rows.map((row, i) => {
              prepareRow(row)
              return (

                (detailView) ? <div className={`${style.trow} ${(row?.original?.strike_through===1) ?style.trow_strike:""} ${!removeCursor ? style.cursor_pointer : style.cursor_default}`} key={i} onClick={() => getSelectedRowData(row.original)}>
                  {row.cells.map((cell, index) => {
                    return (
                      (cell.column.imageData) ? <span className={`${style.cost_input} ${(row?.original?.strike_through===1) ?style.span_strike:""}`} key={index} style={{ width: (cell.column.restWidth) ? cell.column.restWidth : custom_width_rest }} >

                        <img src={cell.value ? cell.value : DefaultImg} width={30} />
                      </span>
                        :
                        (!cell.column.fixedColumn) && <span
                          className={`${style.cost_input} ${(row?.original?.strike_through===1) ?style.span_strike:""}`}
                          key={index}
                          style={{ width: (cell.column.restWidth) ? cell.column.restWidth : custom_width_rest }}
                          title={cell.render('Cell').props.cell.value}
                        >
                          {cell.render('Cell')}
                        </span>
                    )
                  })}
                </div>
                  :
                  <div className={`${style.trow} ${(row?.original?.strike_through===1) ?style.trow_strike:""}`} key={i}>
                    {row.cells.map((cell, index) => {
                      return (
                        (!cell.column.fixedColumn) && <span
                          className={`${style.cost_input} ${(row?.original?.strike_through===1) ?style.span_strike:""}`}
                          key={index}
                          style={{ width: (cell.column.restWidth) ? cell.column.restWidth : '95%' }}
                          title={cell.render('Cell').props.cell.value}
                        >
                          {cell.render('Cell')}
                          
                        </span>
                      )
                    })}
                  </div>
              )
            })}
          </div>
        </div>
      </div>
      </>
      }
      {
        (rows.length === 0) &&
        <><div className={style.total_wrapper}>
          <div className={`thead ${style.thead} ${columns&&Array.isArray(columns)&&columns.length>4?style.no_thead:''} ${(rows.length === 0) ? style.thead_scroll : ''} ${formularyView && style.formulary_head}`}>
            {headerGroups.map(headerGroup => (
              headerGroup.headers.map((column, index) => (
                <span
                  key={index}
                  className={`${style.cost_input}`} {...(sorting) && column.getSortByToggleProps()}
                  style={{ width: (column.restWidth) ? column.restWidth : custom_width_rest }}
                  title={column.render('Header')}
                >
                  {column.render('Header')}
                  <span className={`${!column.disableSortBy ? column.isSorted ? column.isSortedDesc ? style.sort_by_desc : style.sort_by_asc : style.sort_by_default : ''}`}>
                  </span>
                </span>
              ))
            ))}
          </div>
          <div className={`tbody ${rows.length <= 12 ? style.no_height : style.tbody} ${formularyView && style.formulary}`}>
            {
              (rows.length === 0) && (searchKey === '') ? <h4
                className={`${style.no_data_table_center}`}>There are no items to show in this view</h4> :
                (rows.length === 0)
                  &&
                  !reportView ?
                  <h4 className={`text-center ${style.no_data_table_center}`}>{formularyView ? formularyLeft ? "There are no materials here. " : 'There are no materials selected for this formulary' : 'There are no records in this list'}</h4>
                  :
                  <div className={style.no_matching_result}>There are no records matching your report criteria.</div>
            }
          </div>
        </div>
        </>
      }
    </div>
    </>
  )
}

DataTable.propTypes = {
  jsonData: PropTypes.object,
  sorting: PropTypes.bool,
  rowSelect: PropTypes.bool,
  setSelectedRows: PropTypes.func,
  saveData: PropTypes.func,
  onSort: PropTypes.func,
  workListView: PropTypes.bool,
}
