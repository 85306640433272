import React, { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  BROWSE_LBL,
  CANCEL_BTN,
  CANCEL_ID,
  EDIT,
  SAVE_BTN,
  SUBMIT_ID,
  // UPDATE_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
  UPDATE_FAILURE,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from "../../../constants";
import {
  integerWithTwoDecimal, intergerQuantityValueOnly, numericValueOnlyBill
} from "../../../utils";
import editIcon from "../../../static/images/edit-icon-blue.png";
import DefaultImg from "../../../static/images/picture-icon-default.png";
import { Button, Form, RadioButton, TextField, SelectBox, MultiSelect } from "../../primary";
import { PictureIcon, Modal } from "../../secondary";
import styles from "./integration.module.scss";
import PropTypes from "prop-types";
import { setEditState, getPriceListOption, facilitiesCheckAsyn, SetQuantityAcrossFacilitiesStatus } from "../../../app/customerSlice";
// import {setError } from '../../../app/appSlice';
import { AccessErrorModal } from "../../primary";
import { Confirmation } from "../../secondary/Confirmation/confirmation";
import warning from "../../../static/images/icon-check-danger.png";
import closeIcon from "../../../static/images/close-icon-gray.png";
import { listOptionSalesCustomer } from '../../../app/customerSlice';
import infoIcon from '../../../static/images/info.svg';
import Loader from '../../../static/images/spinner.svg';

export function Billing({ customerData, updateCustomerData, history }) {
  /* Use hook form */
  const { register, handleSubmit, watch, clearErrors, errors, reset, getValues } = useForm();

  //let billingTabDatas={"interfaceintosap":1,"sapcontractnumber":"contractnumber","sapmaterialnumber":["NE1ONETIME","NE1MONTHLY"],"sapMaterialZCEP":[{materialNumber:"NE1MONTHLY",materialType:"Standard",materialAmount:200,materialPriceType:"usd",materialQuantity:40},{materialNumber:"NE1ONETIME",materialType:"Custom",materialAmount:600,materialPriceType:"usd",materialQuantity:80}],"woundstatus":"all","aggregatepricing":1,"aggregatequantity":1,"includeDischarePatient":1}
  let [billingTabData, setbillingTabData] = useState(customerData?.billingTab);
  const materialNumber = [{ value: 'NE1MONTHLY', key: 'NE1MONTHLY' }, { value: 'NE1ANNUAL', key: 'NE1ANNUAL' }, { value: 'NE1ONETIME', key: 'NE1ONETIME' }, { value: 'NE1MARKER', key: 'NE1MARKER' }, { value: 'NE1WMSHH1', key: 'NE1WMSHH1' }]
  const [materialNumberTypeData, setMaterialNumberTypeData] = useState([])
  const materialNumberTypeRef = useRef();
  const [onSelMat, setOnSelMat] = useState(false)
  const [materialNumberlist, setMaterialNumberlist] = useState(false)

  useEffect(()=>{
    setMaterialNumberlist(false)
  },[])

  const OnChangeMaterialNumberType = (e) => {
    setOnSelMat(true)
    if (e && e?.length > 0) {
      setMaterialNumberlist(false)
      let arr = [];
      e.map((data) => {
        if (data.key !== "") {
          arr.push(data.key);
        }
      });
      setMaterialNumberTypeData(arr);
    }
    else{
      setMaterialNumberlist(true)
    }
  };


  const OnRemoveMaterialNumberType = (selectedList, selectedItem) => {
    setOnSelMat(true)
    let arr = [];
    if (selectedList && selectedList?.length > 0) {

      Array.isArray(selectedList) && selectedList.map((data) => {//NOSONAR
        if (data && data.key !== "") {
          arr.push(data.key);
        }
      })//NOSONAR
      setMaterialNumberTypeData(arr);

    } else {
      setMaterialNumberTypeData(arr);
      materialNumberTypeRef.current.resetSelectedValues();
      setMaterialNumberlist(true)
    }
  };



  /* State to check editable forms */
  const [editable, setEditable] = useState(false);
  const [disable, setDisable] = useState(false);
  const accessError = useSelector((state) => state.app.accessError);
  const resMsgState = useSelector(state => state.user.resMsgState);  

  const quantityAcrossFacilities = useSelector(state=>state?.customer?.quantityAcrossFacilitiesStatus) 

  /* Function to toggle edit */
  const handleEdit = () => {
    setbillingTabData(customerData?.billingTab)
    if(customerData?.billingTab?.sapMaterialNumber &&Array.isArray(customerData?.billingTab?.sapMaterialNumber)){
      setMaterialNumberTypeData(customerData?.billingTab?.sapMaterialNumber);
    }
    reset()
    setEditable(!editable);
    setInterfaceTosap(customerData?.billingTab?.interfaceIntoSAP === 1 ? 1 : 0)
    setbillingTabData(customerData?.billingTab)
    dispatch(SetQuantityAcrossFacilitiesStatus(null))
    setMaterialNumberlist(false)
  };

  // submit data
  const onSubmit = (data) => {
    if (materialNumberTypeData.length === 0){ 
      setMaterialNumberlist(true)    
      return false;      
    }
    else if(!materialNumberlist && quantityAcrossFacilities!==null){
      return false;
    }
    else {   
    const tempData = {      
      billingTab: {
        "interfaceIntoSAP": interfaceTosape,
        "sapContractNumber":interfaceTosape===0?null: data?.sapContractNumber,
        "priceList": interfaceTosape===0?null:data?.priceList,
        "sapMaterialNumber": interfaceTosape===0?null:materialNumberTypeData,
        "sapMaterialZCEP": interfaceTosape===0?null:data?.sapMaterialZCEP,
        "woundStatus": interfaceTosape===0?'all':data?.woundStatus === "All" ? "all" : "active",
        "aggregatePricing": interfaceTosape===0?null:data?.aggregatePricing === "Yes" ? 1 : 0,
        // "aggregateQuantity": interfaceTosape===0?null:data?.aggregateQuantity === "Yes" ? 1 : 0,
        "billtonumber" : interfaceTosape===0 ?  null : customerData?.accountGroup==='Z002' ? data?.billToNumber : null,
        "aggregateQuantity": null,
        "includeDischarePatient":interfaceTosape===0?1:data?.includeDischarePatient === "Yes" ? 1 : 0,
      },
    };    
    /* Merging default object with new data */
    const result = { ...customerData, ...tempData };
    /* Dispatching */
    updateCustomerData(result);
    setEditable(false);
  }
  };

  /* Edit success */
  const editState = useSelector((state) => state.customer.editState);
  //priceListOption
  const priceListOption = useSelector((state) => state.customer.priceListOption);
  const dispatch = useDispatch();

  useEffect(() => {
    if (editState === "success") {
      setEditable(false);
      reset()
    }
    setTimeout(() => {
      dispatch(setEditState(false));
    }, 5000);
  }, [editState, dispatch]);

  const [interfaceTosape, setInterfaceTosap] = useState(0)

  useEffect(() => {
    dispatch(getPriceListOption())
  }, [dispatch])


  const handleClickInterfaceTosape = () => {
    if (interfaceTosape === 0) {
      setInterfaceTosap(1)
      dispatch(facilitiesCheckAsyn(customerData?.customerNumber))
    }
    if (interfaceTosape === 1) {
      setInterfaceTosap(0)
      dispatch(SetQuantityAcrossFacilitiesStatus(null)) 
    }
  }

  useEffect(() => {
    setbillingTabData(customerData?.billingTab)
    dispatch(SetQuantityAcrossFacilitiesStatus(null)) 
    setMaterialNumberlist(false)   
    if(customerData?.billingTab?.sapMaterialNumber &&Array.isArray(customerData?.billingTab?.sapMaterialNumber)){
      setMaterialNumberTypeData(customerData?.billingTab?.sapMaterialNumber);
    }
    if (customerData?.billingTab?.interfaceIntoSAP === 1) {
      setInterfaceTosap(1)
    }
    else {
      setInterfaceTosap(0)
    }
  }, [customerData])

  const getMaterialType = (materialType, index, materialNumber) => {
    let billingTabObject = { ...billingTabData }    
    let newData = []
    Array.isArray(billingTabData?.sapMaterialZCEP) &&
      billingTabData?.sapMaterialZCEP.map((data, i) => {
        if (i === index) {
          let val = { ...data }
          val['materialType'] = materialType
          newData.push(val)
        } else {
          newData.push(data)
        }

      })
    billingTabObject['sapMaterialZCEP'] = newData
    setbillingTabData(billingTabObject)
  }

  /*loader*/
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setEditable(false);
      setTinyDelay(true)
    }, 2500)
  }, [])

  useEffect(() => {
    if (onSelMat === true) {

      let sapmateriazcepListExits = []
      Array.isArray(materialNumberTypeData) &&
        materialNumberTypeData.map((materialNumber, i) => {

          Array.isArray(billingTabData?.sapMaterialZCEP) &&
            billingTabData?.sapMaterialZCEP.map((data, i) => {
              if (data?.materialNumber.toUpperCase() === materialNumber.toUpperCase()) {
                sapmateriazcepListExits.push(data)
              }
            })
          if (billingTabData?.sapMaterialZCEP === null) {
            sapmateriazcepListExits.push({ materialNumber: materialNumber.toUpperCase(), materialType: "Standard", materialAmount: '', materialPriceType: "usd", materialQuantity: '' })
          }

        })
      const newMat = Array.isArray(billingTabData?.sapMaterialZCEP) && Array.isArray(materialNumberTypeData) && materialNumberTypeData.filter(function (o1) {
        return !billingTabData?.sapMaterialZCEP.some(function (o2) {
          return o1.toUpperCase() === o2.materialNumber.toUpperCase(); // return the ones with equal id
        });
      });

      let cloneNewMat = Array.isArray(newMat) && newMat.map((el1) => ({
        materialNumber: el1.toUpperCase(), materialType: "Standard", materialAmount: '', materialPriceType: "usd", materialQuantity: ''
      }))
      let billingTabObject = { ...billingTabData }
      if (billingTabData?.sapMaterialZCEP !== null && cloneNewMat !== false) {
        let finalComMat = [...sapmateriazcepListExits, ...cloneNewMat]

        billingTabObject.sapMaterialZCEP = finalComMat

        let finalObj = { ...billingTabObject }
        setbillingTabData(finalObj)
      } else {
        billingTabObject.sapMaterialZCEP = sapmateriazcepListExits
        let finalObj = { ...billingTabObject }
        setbillingTabData(finalObj)
      }

      setOnSelMat(false)
    }
  }, [materialNumberTypeData, onSelMat])
  
  const QuantityAcrossFacilities = (val) => {
    if(val==="Yes"){
      dispatch(facilitiesCheckAsyn(customerData?.customerNumber))
    }
    if(val==="No"){
      // dispatch(SetQuantityAcrossFacilitiesStatus(false)) 
    }
  }  

  const closeErrorMesge = () => {       
    if(interfaceTosape === 1 && quantityAcrossFacilities !== null){
      setInterfaceTosap(0)
      dispatch(SetQuantityAcrossFacilitiesStatus(null)) 
    } 
  }  
  
  const makeDecimal = (num,id,getInteger) => {   
    if(!getInteger){
      if(document.getElementById(id)){      
        // document.getElementById(id).value=Number(num.toString()).toFixed(2)
        document.getElementById(id).value=num?.toString()?.match(/^-?\d+(?:\.\d{0,2})?/)
      }
    }
    if(getInteger){
      if(document.getElementById(id)){
        document.getElementById(id).value= +num +'.00'
      }
    }
    // if(document.getElementById(id)){
    //   document.getElementById(id).value=Number(num).toFixed(2)
    // }    
  }

  return (
    <>


      {!tinyDelay ? <div className={`loader col-12`}>
        <img src={Loader} alt="loading" />
      </div> : <div className={`col-md-12 ${styles.single_product_block}`}>
        <div className="row">
          {/* Edit success */}
          {editState === SUCCESS_STATUS && (
            <div className={`alert alert-success ${styles.form_alert}`}>
              {CUSTOMER_UPDATE_SUCCESS}
            </div>
          )}

          {/* Edit failure */}
          {editState === ERROR_STATUS && (
            <div className={`alert alert-danger ${styles.form_alert}`}>
              {UPDATE_FAILURE}
            </div>
          )}


          <div className={` ${styles.patient_content}`}>
            {!editable ? (
              <>
                {/* Headers */}
                <div className={`${styles.tab_content_top_billing} col-12`}>
                  <h2 className={`${styles.tab_content_hdg} pl-0`}>
                    <div className={`${styles.tab_content_ne1Status} py-3`}>
                      {`Interface into SAP: `}<span className="" style={{fontSize:'22px', color:'#737373'}}>{billingTabData?.interfaceIntoSAP === 1 ? "Enable" : "Disable"}</span>
                    </div>
                    {!editable && (
                      <span
                        className={`float-sm-right ${styles.tab_edit}`}
                        onClick={handleEdit}
                      >
                        <img src={editIcon} alt="edit" />
                        {EDIT}
                      </span>
                    )}
                  </h2>
                </div>

                {/*Interface into SAP */}
                {/* <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                    style={{ fontSize: '18px', fontWeight: 'normal', color: '#737373' }}
                  >
                    Interface into SAP:

                  </label>
                  <div className="col-6 p-0 pt-1">
                    <span className={`col-form-label ${styles.content_spec}`} style={{ fontSize: '18px', fontWeight: 'normal', color: '#737373' }}>
                      {billingTabData?.interfaceIntoSAP === 1 ? "Enable" : "Disable"}
                    </span>



                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div> */}
                

                {/* SAP contract number  */}
                {interfaceTosape===1&&
                <>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    SAP contract number :
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {billingTabData?.sapContractNumber}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Price list :
                  </label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {billingTabData?.priceList && billingTabData?.priceList.toUpperCase()}
                    </span>
                  </div>
                </div>
                
                {/* Billing to Number:  */}
                {customerData?.accountGroup==='Z002' &&
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Bill to number:
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {billingTabData?.billtonumber}
                    </span>
                  </div>
                </div>}
                {customerData?.accountGroup==='Z002' && <div className={`col-6 ${styles.col_spec}`}></div>}  

                {/* SAP material number(s):  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    SAP material number(s):
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {billingTabData?.sapMaterialNumber && Array.isArray(billingTabData?.sapMaterialNumber) &&
                        billingTabData?.sapMaterialNumber.join(', ')
                      }
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>


                {/*  SAP material ZCEP :  */}
                <div className={`col-12  ${styles.col_spec}`}>
                  <label
                    className={`col-3 col-form-label ${styles.label_spec}`}
                  >
                    SAP material ZCEP :
                  </label>
                  <div className="ml-n4 p-0">
                    {
                      billingTabData?.sapMaterialZCEP &&
                      Array.isArray(billingTabData?.sapMaterialZCEP) &&
                      billingTabData?.sapMaterialZCEP.map((value, i) => {

                        return (
                          <>
                            <div className={`mb-2`} style={{ fontSize: '16px', fontWeight: 'normal', color: '#737373' }}>
                              <span
                                className={`ml-2 ${styles.mat_type_span}`}

                              >
                                {value &&value?.materialNumber&& value?.materialNumber.toUpperCase()}
                              </span>
                              <span

                              >
                                <label
                                  className={`col-form-label${styles.label_spec}`}
                                >
                                  <b>Type: </b>  &nbsp;
                                </label>
                                {value && value?.materialType}  &nbsp;
                              </span>
                              {value?.materialType === "Custom" && <>
                                <span
                                >
                                  <label
                                    className={`col-form-label${styles.label_spec}`}
                                  >
                                    <b>Amount: </b>  &nbsp;
                                  </label>
                                  $ {value && value?.materialAmount}   &nbsp;
                                </span>

                                <span
                                >
                                  <label
                                    className={`col-form-label${styles.label_spec}`}
                                  >
                                    <b>Quantity: </b>   &nbsp;
                                  </label>
                                  {value && value?.materialQuantity}   &nbsp;
                                </span>
                              </>
                              }
                            </div>
                          </>
                        )
                      })
                    }
                  </div>
                </div>



                {/*  Wound Status:  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Wound Status :
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {billingTabData?.woundStatus === 'all' ? "All" : "Active"}
                    </span>
                  </div>
                </div>

                {/* Aggregate pricing across facilities  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Aggregate pricing across facilities :
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {billingTabData?.aggregatePricing === 1 ? "Yes" : "No"}
                    </span>
                  </div>
                </div>


                {/* Aggregate quantity across facilities  */}
                {/* <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Aggregate quantity across facilities :
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {billingTabData?.aggregateQuantity === 1 ? "Yes" : "No"}
                    </span>
                  </div>
                </div> */}

                {/* Include discharged patients  */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Include discharged patients :
                  </label>
                  <div className="col-5 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {billingTabData?.includeDischarePatient === 1 ? "Yes" : "No"}
                    </span>
                  </div>
                </div>


                <div className={`col-6 ${styles.col_spec}`}></div>
                </> }
              </>
            ) :

              (
                <Form
                  formChanges={true}
                  className={`p-0 ${styles.patient_content}`}
                  onSubmit={handleSubmit(onSubmit)}
                >

                  {/* Inbound section */}
                  {/* Toggle for Inbound Integration */}
                  <div className={`col-4 ${styles.ne1_toggleSwitch} custom-control custom-switch pl-0 pt-2 pb-4`}>
                    <h2 className={`col-9`} style={{ fontSize: '24px', fontWeight: 'normal', color: '#737373' }}>
                      Interface into SAP:
                    </h2>
                    <div className={`${styles.toggle_shells}`}>
                      <input
                        type="checkbox"
                        name="inbound_toggle"
                        className="custom-control-input"
                        id={"inbound_toggle"}
                        data-testid={"inbound_toggle"}
                        checked={interfaceTosape === 1}
                        onChange={handleClickInterfaceTosape}
                      />
                      <label className={`custom-control-label ${styles.toggle_switch}`} for="inbound_toggle"></label>
                    </div>
                  </div>
                  <div className={`col-6 ${styles.col_spec}`}>
                    {quantityAcrossFacilities !== null && <div className={`${styles.facilityErrorMessage}`}>
                      <div className={`${styles.messageContent}`}>
                        <p>{quantityAcrossFacilities}</p>
                      </div>
                      <div className={`${styles.closeErrorMessage} ml-auto`} onClick={closeErrorMesge}>
                        <i class="fa fa-times-circle" style={{color:'#000',fontSize:'24px'}} aria-hidden="true" onClick={closeErrorMesge}></i>
                      </div>
                    </div>}
                  </div>
                  {interfaceTosape === 1 && <>
                    <TextField
                      id={"sapContractNumber"}
                      name={"sapContractNumber"}
                      label={"SAP contract number:"}
                      classes={{
                        column: `col-6 ${styles.col_spec}`,
                        labelClass: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: 'col-6 p-0',
                        inputClassName: `${styles.basic_input}`
                      }}
                      type="text"
                      validator={register({
                        required: 'Enter SAP contract number',
                      })}
                      // validator={register}
                      defaultValue={billingTabData?.sapContractNumber}
                      errors={errors}
                      required={true}
                    />


                    <SelectBox
                      id={`priceList`}
                      name={`priceList`}
                      label={"Price list:"}
                      classes={{
                        column: `row col-6 `,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-4 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper} `
                      }}
                      optionValue={priceListOption}
                      validator={register({
                        required: 'Select a price list',
                      })}
                      defaultValue={billingTabData?.priceList}
                      placeholder={"Select"}
                      errors={errors}
                      required={true}
                    />

                    {customerData?.accountGroup==='Z002' && 
                      <TextField
                        id={"billToNumber"}
                        name={"billToNumber"}
                        label={"Bill to number:"}
                        classes={{
                          column: `col-6 ${styles.col_spec}`,
                          labelClass: `col-6 col-form-label ${styles.label_spec}`,
                          inputColumn: 'col-6 p-0',
                          inputClassName: `${styles.basic_input}`
                        }}
                        type="text"
                        validator={register(numericValueOnlyBill)}
                        // validator={register}
                        defaultValue={billingTabData?.billtonumber}
                        errors={errors}
                        required={true}
                    />}
                    {customerData?.accountGroup==='Z002' && <div className="col-6"></div>}

                    <MultiSelect
                      id={"materialNumber"}
                      name={"materialNumber[]"}
                      label={"SAP material number(s)"}
                      classes={{
                        column: `col-6 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0`,
                      }}
                      optionValue={materialNumber}
                      validator={(e) => {
                        register({ required: true });
                        materialNumberTypeRef.current = e;
                      }}
                      defaultValue={billingTabData?.sapMaterialNumber}
                      showCheckbox={true}
                      displayValue="value"
                      onSelect={OnChangeMaterialNumberType}

                      onRemove={OnRemoveMaterialNumberType}
                    // labelSubText={'Select all that apply'}
                      required={true}
                    />
                    <div className={`col-6 ${styles.col_spec}`}></div>
                    {materialNumberlist && <div className={`col-6 ${styles.error_message_bill}`}>{'Select material number'}</div>}
                    
                    {/* Customer Group Name */}


                    <div className={`row col-12`}>
                      {
                        Array.isArray(billingTabData?.sapMaterialZCEP) && billingTabData?.sapMaterialZCEP.length > 0 &&
                        <>
                          <div className={`col-12 ${styles.col_spec}`}>
                            <label
                              className={`col-7 col-form-label ${styles.label_spec} mr-2`}
                            >
                              SAP material ZCEP

                            </label>
                          </div>
                        </>
                      }
                      {
                        billingTabData?.sapMaterialZCEP &&
                        Array.isArray(billingTabData?.sapMaterialZCEP) &&
                        billingTabData?.sapMaterialZCEP.map((value, i) => {
                          const materialType = value?.materialType
                          if(value?.materialNumber&&value?.materialNumber!==null){
                          return (
                            <>
                              <div key={value.materialNumber} className={`col-10 d-flex mt-n4 ml-5 mb-2`}>
                                <span
                                  className={`ml-2 ${styles.mat_type_span}`}
                                >
                                  {value && value?.materialNumber&&value?.materialNumber.toUpperCase()} :
                                </span>

                                <input type='hidden' name={`sapMaterialZCEP[${i}][materialNumber]`} ref={register()} value={value && value?.materialNumber&& value?.materialNumber.toUpperCase()} />
                                <RadioButton
                                  id={`materialType${value && value?.materialNumber&& value?.materialNumber.toUpperCase()}`}
                                  name={`sapMaterialZCEP[${i}][materialType]`}
                                  classes={{
                                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                    inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                    inputColumn:
                                      "custom-radio custom-control custom-control-inline",
                                  }}
                                  validator={register()}
                                  radioOption={["Standard", "Custom"]}
                                  defaultValue={
                                    materialType === "Custom" ? "Custom" : "Standard"
                                  }

                                  onChange={(e) => getMaterialType(e.target.value, i, value?.materialNumber)}
                                //disabled={inboundState===0}
                                />

                                {materialType === "Custom" ? <>
                                  <TextField
                                    id={`materialAmount${value && value?.materialNumber&& value?.materialNumber.toUpperCase()}`}
                                    name={`sapMaterialZCEP[${i}][materialAmount]`}
                                    // label={"SAP contract number:"}
                                    classes={{
                                      column: `m-0 p-0 d-flex`,
                                      // labelClass: `col-6 col-form-label ${styles.label_spec}`,
                                      inputColumn: 'col-8 p-0',
                                      inputClassName: `${styles.basic_input}`
                                    }}
                                    type="number"
                                    // validator={register({
                                    //   required: 'Enter quantity',
                                    // })}
                                    validator={register(integerWithTwoDecimal)}                                   
                                    placeholder={"Enter an amount"}
                                    defaultValue={
                                      value?.materialAmount
                                    }
                                    errors={errors}
                                    required={true}
                                    subName={`materialAmount`}
                                    refKeys={`${i}`}
                                    onBlur={(event)=>makeDecimal(event.target.value,`materialAmount${value && value?.materialNumber&& value?.materialNumber.toUpperCase()}`, Number.isInteger(+event.target.value))}
                                  />
                                  {/* { errors['sapMaterialZCEP'][i]['materialAmount'].message} */}

                                  <SelectBox
                                    id={`materialPriceType${value && value?.materialNumber&& value?.materialNumber.toUpperCase()}`}
                                    name={`sapMaterialZCEP[${i}][materialPriceType]`}
                                    classes={{
                                      column: `col-1 ml-n5 m-0 p-0`,
                                      inputClassName: styles.basic_select,
                                      // labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                                      inputColumn: `col-9 p-0 ${styles.select_wrapper} `
                                    }}
                                    optionValue={[{ key: 'usd', value: 'USD' }]}
                                    validator={register}
                                    defaultValue={
                                      value?.materialPriceType
                                    }
                                    //  placeholder={"Select"}
                                    errors={errors}
                                  />
                                  <div className={`${styles.billing_vertical_line}`}>
                                    {/* /billing_vertical_line */}
                                  </div>
                                  
                                  <TextField
                                    id={`quantity${value && value?.materialNumber&& value?.materialNumber.toUpperCase()}`}
                                    name={`sapMaterialZCEP[${i}][materialQuantity]`}
                                    label={"Quantity"}
                                    classes={{
                                      column: `row ml-1`,
                                      labelClass: `col-4 col-form-label`,
                                      inputColumn: 'col-6 p-0',
                                      inputClassName: `${styles.basic_input}`
                                    }}
                                    type="number"
                                    validator={register(intergerQuantityValueOnly)}
                                    // validator={register({
                                    //   required: 'Enter a quantity',
                                    // })}
                                    placeholder={"Enter a quantity"}
                                    defaultValue={
                                      value?.materialQuantity
                                    }
                                    errors={errors}
                                    subName={`materialQuantity`}
                                    refKeys={`${i}`}
                                  />
                                </> : ''}
                              </div>
                              <div className={`col-6 ${styles.col_spec}`}> </div>
                            </>
                          )}
                        })
                      
                      }

                    </div>
                    

                    <>
                      <div className={`col-5 ${styles.col_spec}`}>
                        <label
                          className={`col-7 col-form-label ${styles.label_spec} mr-2`}
                        >
                          Wound Status :
                        </label>
                        <RadioButton
                          id={"woundStatus"}
                          name={"woundStatus"}
                          classes={{
                            labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input}`,
                            inputColumn:
                              "custom-radio custom-control custom-control-inline",
                          }}
                          validator={register()}
                          radioOption={["All", "Active"]}
                          defaultValue={
                            billingTabData?.woundStatus === "active" ? "Active" : "All"
                          }
                        // onChange={(e) => getConFirmMunual(e.target.value)}
                        //disabled={inboundState===0}
                        />
                      </div>

                      <div className={`col-6 ${styles.col_spec}`}> </div>
                    </>

                    <>
                      <div className={`col-5 ${styles.col_spec}`}>
                        <label
                          className={`col-7 col-form-label ${styles.label_spec} mr-2`}
                        >
                          Aggregate pricing across facilities:
                        </label>
                        <RadioButton
                          id={"aggregatePricing"}
                          name={"aggregatePricing"}
                          classes={{
                            labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input}`,
                            inputColumn:
                              "custom-radio custom-control custom-control-inline",
                          }}
                          validator={register()}
                          radioOption={["Yes", "No"]}
                          defaultValue={
                            billingTabData?.aggregatePricing === 1 ? "Yes" : "No"
                          }
                        />
                      </div>
                      <div className={`col-6 ${styles.col_spec}`}> </div>
                    </>


                    {/* <>
                      <div className={`col-5 ${styles.col_spec}`}>
                        <label
                          className={`col-7 col-form-label ${styles.label_spec} mr-2`}
                        >
                          Aggregate quantity across facilities :
                        </label>
                        <RadioButton
                          id={"aggregateQuantity"}
                          name={"aggregateQuantity"}
                          classes={{
                            labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input}`,
                            inputColumn:
                              "custom-radio custom-control custom-control-inline",
                          }}
                          validator={register()}
                          radioOption={["Yes", "No"]}
                          defaultValue={
                            billingTabData?.aggregateQuantity === 1 ? "Yes" : "No"
                          }
                          onClick={(e)=>QuantityAcrossFacilities(e.target.value)}
                        />
                      </div>

                      <div className={`col-6 ${styles.col_spec} mb-0`}>
                        {quantityAcrossFacilities && <div className={`${styles.facilityErrorMessage}`}>
                            <div className={`${styles.messageContent}`}>
                              <p>Quantity can't be aggregated due to one or more facilities having separate SAP contracts.</p>
                            </div>
                            <div className={`${styles.closeErrorMessage} ml-auto`} onClick={closeErrorMesge}>
                              <i class="fa fa-times-circle" style={{color:'#000',fontSize:'24px'}} aria-hidden="true" onClick={closeErrorMesge}></i>
                            </div>
                          </div>}
                      </div>
                    </> */}


                    <>
                      <div className={`col-5 ${styles.col_spec}`}>
                        <label
                          className={`col-7 col-form-label ${styles.label_spec} mr-2`}
                        >
                          Include discharged patients :
                        </label>
                        <RadioButton
                          id={"includeDischarePatient"}
                          name={"includeDischarePatient"}
                          classes={{
                            labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input}`,
                            inputColumn:
                              "custom-radio custom-control custom-control-inline",
                          }}
                          validator={register()}
                          radioOption={["Yes", "No"]}
                          defaultValue={
                            billingTabData?.includeDischarePatient === 1 ?  "Yes" : "No"
                          }
                        />
                      </div>
                      <div className={`col-6 ${styles.col_spec}`}> </div>
                    </>
                  </>}
                  <div className={`col-6 ${styles.col_spec}`}></div>

                  <div className={`row ${styles.form_btn}`}>
                    <div className="col-sm-12">
                      <div className="text-center pb-4">
                        <br />
                        {/* Save Button */}
                        <Button
                          classes={{
                            className: `btn ${styles.btn_custom} ${styles.btn_small} settingsSave`,
                          }}
                          type="submit"
                          id={SUBMIT_ID}
                        >
                          {SAVE_BTN}
                        </Button>

                        {/* Cancel button  */}
                        <Button
                          classes={{
                            className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}`,
                          }}
                          id={CANCEL_ID}
                          onClick={handleEdit}
                        >
                          {CANCEL_BTN}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
          </div>
        </div>
      </div>}

      {accessError && <AccessErrorModal />}
    </>
  );
}

Billing.propTypes = {
  customerData: PropTypes.object,
};
