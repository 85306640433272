import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from 'react-hook-form'
import "./react-datepicker.css";
import styles from './dateTimePicker.module.scss'
import CalendarIcon from '../../../static/images/calendar-icon.png';
import PropTypes from 'prop-types'

export function DateTimePicker({
    classes, name, id, label, validator, errors, required, control, defaultValue, customOnChange, 
    timeRequired, timeName, timeFormat, defaultTime,
    dateFormat, dateRef, showTimeInput, displayIcon, showMonthDropdown, showYearDropdown, disabled = false, timeID,maxDate,minDate,maxTime,minTime, createPatient,onBlur }) {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            /* Validation */
            rules={validator}
            /* Renders date picker */
            render={({ onChange, value }) => (
                <div className={(classes) ? classes.column : ''}>
                    {
                        label && <label htmlFor={id} className={`${classes ? classes.labelClass : ''}`}>
                            {label} {required ? <span className={styles.astrick}>&#42;</span> : ''}
                        </label>
                    }
                    {/* Inner div */}
                    <div className={`${(classes) ? classes.dateColumn : ''}`}>
                        <DatePicker
                            className={`${classes ? classes.date_picker : ''} ${(errors[name]) && styles.error_border}`}
                            name={name}
                            id={id}
                            selected={value}
                            onChange={(e) => {
                                if(customOnChange){
                                    customOnChange(e);
                                }
                                onChange(e)
                            }}
                            dateFormat={dateFormat}
                            ref={dateRef}
                            showMonthDropdown={showMonthDropdown}
                            showYearDropdown={showYearDropdown}
                            disabled={disabled}
                            maxDate={maxDate?maxDate:false}
                            minDate={minDate?minDate:false}
                            onBlur={onBlur}
                            dropdownMode="select"
                           // adjustDateOnChange={true}
                           // onFocus={e => e.target.blur()}
                        />
                        {/* Display error message  */}
                        {errors && !timeRequired && <span className={`${styles.error_message} ${(createPatient) ? 'offset-3' :''}`}>{(errors[name]) ? errors[name].message : ''}</span>}
                        {/* Display calendar icon */}
                        {displayIcon && <img src={CalendarIcon} alt="" className={styles.calendar_right} onClick={() => { dateRef.current.setOpen(true) }} />}
                    </div>
                    {/*show Time Selection only*/}
                    {timeRequired && <div className={`${(classes) ? classes.timeColumn : ''}`}>
                        {/* Controlled component for timepicker */}
                        <Controller
                            control={control}
                            name={timeName}
                            defaultValue={defaultTime}
                            render={({ onChange, value }) => (
                                <DatePicker
                                    className={`${classes ? classes.time_picker : ''} ${(errors[name]) && styles.error_border}`}
                                    selected={value}
                                    onChange={onChange}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat={timeFormat}
                                    name={timeName}
                                    disabled={disabled}
                                    id={timeID}
                                    maxTime={maxTime?maxTime:false}
                                    minTime={minTime?minTime:false}
                                   // onFocus={e => e.target.blur()}
                                />
                            )}
                        />
                    </div>}
                     {/* Display error message  */}
                    {
                        label && timeRequired && errors && <>
                            <label htmlFor={id} className={`${classes ? classes.labelClass : ''}`}></label>
                            <span className={`${styles.error_message} ${(createPatient) ? 'offset-3' :''}`}>{(errors[name]) ? errors[name].message : ''}</span>
                        </>
                    }

                </div>
            )}
        />
    )
}

DateTimePicker.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    validator: PropTypes.object,
    errors: PropTypes.object,
    required: PropTypes.bool,
    control: PropTypes.object,
    defaultValue: PropTypes.instanceOf(Date),
    timeRequired: PropTypes.bool,
    timeName: PropTypes.string,
    timeFormat: PropTypes.string,
    defaultTime: PropTypes.instanceOf(Date),
    dateFormat: PropTypes.string,
    dateRef: PropTypes.object,
    showTimeInput: PropTypes.bool,
    displayIcon: PropTypes.bool,
    showMonthDropdown: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    disabled: PropTypes.bool
}
