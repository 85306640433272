import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ActionModal } from '../../secondary'
import { Organizational } from '../../primary'
import styles from './createUser.module.scss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import { CreateEditUser } from './createEditUser'
import { Form, TextField, Button, MultiSelect } from '../../primary'
import { createUserAsync, getFaciltiestCustomerAsyn, setCustomerFacilities, setResMsgState } from '../../../app/userSlice'
import iconSuccess from '../../../static/images/icons-check-success.png'
import { SUCCESS_STATUS, ERROR_STATUS, USER_CREATED_MSG } from '../../../constants';
import { getFacilityLabel, onSubmitUserData,checkCustomerNumber } from '../../../utils';
export let CreateUser = ({ dispatch, modalOpen, modalClose }) => {

  const { reset, register, handleSubmit, control, errors } = useForm({
    mode: 'onSubmit'
  });

  useEffect(() => {
    dispatch(setCustomerFacilities({}));
  }, [dispatch])

  useEffect(() => {
    dispatch(setResMsgState({}));
  }, [dispatch])


  const [createUser, setCreateUser] = useState(false)
  const [onselectOrgz, setonselectOrg] = useState([]);
  const [typedCustomerNumber, setTypedCustomerNumber] = useState("");

  const [msgModal, setMsgModal] = useState(false);
  const resMsgState = useSelector(state => state.user.resMsgState);

  const role = useSelector(state => state.user.role);
  const customerNumber = useSelector(state => state.user.customerNumber);

  const [errMsg, seterrMsg] = useState(false);
  const onSubmit = (data) => {
    setTypedCustomerNumber(data.customer);
    dispatch(setCustomerFacilities({}));
    //dispatch(getFaciltiestCustomerAsyn(data));
    if(onselectOrgz?.length > 0) {
      setCreateUser(true)
      seterrMsg(false)
    } else { 
      dispatch(getFaciltiestCustomerAsyn(data));
      setCreateUser(false)
      seterrMsg(true)
    }
  }
 
  const handleNext = () => {
    if(onselectOrgz.length > 0) {
      setCreateUser(true)
      seterrMsg(false)
    } else {
      setCreateUser(false)
      seterrMsg(true)
    }
  }

  useEffect(() => {
    if(role === 'ExternalAdministrator'){
      dispatch(getFaciltiestCustomerAsyn({customer: customerNumber}));
    }
  }, [role, customerNumber, createUser])


  const onSelectOrg = (selectedList) => {
    setonselectOrg([...selectedList])
    seterrMsg(false)
  }
  const modalRefresh = () => {
    modalClose();
    setCreateUser(false);
    setMsgModal(false);
    setErrorMsg("");
    setOrg(false);
    setonselectOrg([]);
    if(role !== 'ExternalAdministrator'){ dispatch(setCustomerFacilities({})); }
    dispatch(setResMsgState({}));
    seterrMsg(false);
  }
  const onSubmitUser = (data) => {
    onSubmitUserData(data, onselectOrgz, typedCustomerNumber, dispatch, createUserAsync, setMsgModal, modalRefresh, reset, resMsgState, customerNumber, role)
  }
  // form reset while click cancel button
  const resetForm = () => { reset(); modalClose(); }

  const facilitiesData = useSelector(state => state.user.customerFacilities);
  let optionData = [];
  const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
  soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
    const shipToFacilities = facilitiesData.data.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
    if(shipToFacilities.length===0){
      optionData.push({
          cat: getFacilityLabel(soldToFacility),
          key: getFacilityLabel(soldToFacility),
          facilityNumber: soldToFacility.facilityNumber,
          soldToFacility: 'soldToFacility'
      })
     }
    shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
      optionData.push({
        cat: getFacilityLabel(soldToFacility),
        key: getFacilityLabel(shipToFacility),
        facilityNumber: shipToFacility.facilityNumber,
        shipToFacilities: 'shipToFacilities'
      })
    })
  })

  const [errorMsg, setErrorMsg] = useState("")
  const [org, setOrg] = useState(false)


  useEffect(() => {
    if(role !== 'ExternalAdministrator'){
      let fac = ""
      if (facilitiesData?.errorMessage) {
        fac = "Customer number doesn’t match our record"
      } else if (facilitiesData?.data !== undefined) {
        if (optionData.length === 0) {
          fac = "Organization mapping is missing for the customer"
        }
      }
      optionData.length > 0 ? setOrg(true) : setOrg(false)
      setErrorMsg(fac)
    }
  }, [facilitiesData])

  useEffect(() => {
    if(role === 'ExternalAdministrator'){
      optionData.length > 0 ? setOrg(true) : setOrg(false)
      //onSelectOrg(optionData)
    }
  },[modalOpen,optionData])


  useEffect(() => {
    if (resMsgState === "success") {
      setMsgModal(true)
      setTimeout(() => {
        reset();
        modalRefresh()
        dispatch(setResMsgState({}))
      }, 5000)
    }
  }, [resMsgState]);
  return (
    <>
      {!msgModal && <Modal
        modalOpen={modalOpen}
        modalClose={modalRefresh}
        heading={"Create User"}
        className={"modal_box"}
        modalSize={styles.custom_modal_size}
        modalScroll={false}
        reset={!resMsgState?.errorMessage && reset}
      >
        {(!createUser) && <Form className={`pl-2 ${styles.patient_content}`} onSubmit={handleSubmit(onSubmit)}>
          {role !== 'ExternalAdministrator' && <TextField
            classes={{
              column: `col ${styles.col_spec}`,
              labelClass: `col-3 col-form-label ${styles.label_spec}`,
              inputColumn: 'col-4 p-0',
              inputClassName: `${styles.basic_input} form-control`
            }}
            label={"Customer #:"}
            type={"text"}
            name={"customer"}
            id={"customer"}
            placeholder={""}
            title={'Toggle All Rows Selected'}
            // validator={register({
            //   required: true
            // })}
            validator={register(checkCustomerNumber)}
            errors={errors}
            customError={errorMsg}
            required={true}
            selectAll={true}  
          />}
          {org &&
            <>
              <Organizational
                id={"organizationalView"}
                name={"organizationalView"}
                label={`Facility/Location:`}
                classes={{
                  column: `col ${styles.col_spec}`,
                  labelClassName: `col-3 col-form-label ${styles.label_spec}`,
                  inputColumn: `${styles.organizationalView} col-8 p-0`,
                  inputClassName: `${styles.basic_input} form-control`
                }}
                optionValue={optionData}
                groupBy="cat"
                displayValue="key"
                defaultValue={onselectOrgz}
                onSelect={onSelectOrg}
                customError={errMsg}
                selectAll={true}  
              />
            </>
          }
          {role !== 'ExternalAdministrator' ? <div className="col-sm-12">
            <div className="text-center pb-4">
              <Button
                id={""}
                type={"submit"}
                value={"Next"}
                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
              />
            </div>
          </div>
          :
          <div className="col-sm-12">
            <div className="text-center pb-4">
              <Button
                onClick={handleNext}
                id={""}
                type={"button"}
                value={"Next"}
                classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
              />
            </div>
          </div>}
        </Form>
        }
        {(createUser) && <CreateEditUser
          register={register}
          handleSubmit={handleSubmit}
          onSubmit={onSubmitUser}
          control={control}
          errors={errors}
          handleCancel={modalRefresh}
          facilityOptions={optionData}
          isModal={true}
          errormsg={resMsgState?.errorMessage}
        />}
      </Modal>
      }
      {msgModal && <Modal
        modalOpen={modalOpen}
        modalClose={modalRefresh}
      >
        <div className="text-center p-4">
          {resMsgState === SUCCESS_STATUS && <ActionModal
            iconImg={iconSuccess}
            heading={USER_CREATED_MSG}
            buttonText="Close"
            buttonAction={modalRefresh}
          />}
        </div>
      </Modal>
      }

    </>
  )
}
CreateUser.propTypes = {
  dispatch: PropTypes.func
};
CreateUser = connect()(withRouter(CreateUser));
