import React, { useEffect, useState } from 'react';
import { Accordion } from '../../secondary/Accordion/accordion';
import { connect, useDispatch, useSelector } from 'react-redux'
import style from './riskAssessment.module.scss';
import { PatientHeading } from './patientHeading';
import { format } from 'date-fns'
import { fullMonthFormatDate } from '../../../utils';
import { Modal } from '../../secondary/Modal';
import { Button } from '../../primary'
import { RiskAssessmentScale } from './riskAssessmentScale';
import { getRiskAssessmentsByIdAsync, getRiskAssessmentOptionsAsync } from '../../../app/patientSlice'
import { useParams, withRouter } from 'react-router-dom';
import Loader from '../../../static/images/spinner.svg';
export let RiskAssessment = ({ heading, patientData, getRiskAssessments }) => {
  const { patientId } = useParams();
  const riskAssessmentData = useSelector(state => state.patient.riskAssessments);
  const riskAssessmentQuestions = useSelector(state => state.patient.riskAssessmentQuestions);
  const [checkRiskAssessmentScale, setCheckRiskAssessmentScale] = useState('');
  const [checkRiskAssessmentScore, setCheckRiskAssessmentScore] = useState('');
  useEffect(() => {
    getRiskAssessments();
  }, [patientData])


  const [modalShowRisk, setModalShowRisk] = useState(false);
  const [modalShowRiskScale, setModalShowRiskScale] = useState(false);

  const modalCloseRisk = () => {
    setModalShowRisk(false);

    setCheckRiskAssessmentScale('')
    setCheckRiskAssessmentScore('')

  }

  const modalCloseRiskNext = () => {
    setModalShowRisk(false);

    setModalShowRiskScale(true);
  }

  const modalCloseRiskScale = () => {
    setModalShowRiskScale(false);
  }
  const dispatch = useDispatch();
  const modalOpenRisk = (index, assessment) => {
    setCheckRiskAssessmentScale(assessment.type)
    setCheckRiskAssessmentScore(assessment.score)
    if(patientData?.ne1Id&&assessment?.id){
      dispatch(getRiskAssessmentsByIdAsync(patientData.ne1Id, assessment.id))
    }
    setModalShowRisk(true);
  }

  const checkDetails = useSelector(state => state.user.userDetails);
  const riskAssessmentScaleType = (checkDetails && checkDetails.customerSettings &&
    checkDetails.customerSettings.riskAssessmentScale ? checkDetails.customerSettings.riskAssessmentScale : 'norton')

  const bradenSensoryPerception = useSelector(state => state.patient.bradenSensoryPerception);
  const bradenMoisture = useSelector(state => state.patient.bradenMoisture);
  const bradenActivity = useSelector(state => state.patient.bradenActivity);
  const bradenMobility = useSelector(state => state.patient.bradenMobility);
  const bradenNutrition = useSelector(state => state.patient.bradenNutrition);
  const bradenFrictionShear = useSelector(state => state.patient.bradenFrictionShear);

  const nortonPhysicalCondition = useSelector(state => state.patient.nortonPhysicalCondition);
  const nortonMentalCondition = useSelector(state => state.patient.nortonMentalCondition);
  const nortonActivity = useSelector(state => state.patient.nortonActivity);
  const nortonMobility = useSelector(state => state.patient.nortonMobility);
  const nortonIncontinence = useSelector(state => state.patient.nortonIncontinence);
  useEffect(() => {

    if (patientId) {
      if (riskAssessmentScaleType && riskAssessmentScaleType === 'norton') {
        dispatch(getRiskAssessmentOptionsAsync('nortonphysicalcondition'));
        dispatch(getRiskAssessmentOptionsAsync('nortonmentalcondition'));
        dispatch(getRiskAssessmentOptionsAsync('nortonactivity'));
        dispatch(getRiskAssessmentOptionsAsync('nortonmobility'));
        dispatch(getRiskAssessmentOptionsAsync('nortonincontinence'));
      }
      else {
        dispatch(getRiskAssessmentOptionsAsync('bradensensoryperception'));
        dispatch(getRiskAssessmentOptionsAsync('bradenmoisture'));
        dispatch(getRiskAssessmentOptionsAsync('bradenactivity'));
        dispatch(getRiskAssessmentOptionsAsync('bradenmobility'));
        dispatch(getRiskAssessmentOptionsAsync('bradennutrition'));
        dispatch(getRiskAssessmentOptionsAsync('bradenfrictionshear'));
      }

    }
  }, [patientId, riskAssessmentScaleType, dispatch])

   const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])

  return (<>
    {!tinyDelay?<div className={`${style.loader} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:patientData?.moreInfo?.ne1WMSStatus === 1 ? 
    <div>
        {/* Generic heading  */}
        {/* {heading && <PatientHeading heading={heading ? heading : ''} />} */}
        {
          riskAssessmentData && riskAssessmentData.length > 0 && riskAssessmentData.map((data, index) => {
            return (<div key={index}>
              <Accordion
                idControl={index}
                heading={
                  <div>Admission Date: {data && data.admissionDate ? format(new Date(data.admissionDate.replace('Z', '')), 'MM/dd/yyyy hh:mm aa') : ''}
                    {
                      data.dischargeDate ? <span> - Discharge Date: {data && data.dischargeDate ? format(new Date(data.dischargeDate), 'MM/dd/yyyy hh:mm aa') : ''} </span> : null
                    }
                  </div>
                }
                content={<div>
                  {
                    data.assessments && data.assessments.length > 0 ? data.assessments.map((assessment, index) => {

                      return <div key={index} className={`row m-0 ${style.accordion_panel}`} onClick={() => modalOpenRisk(index, assessment)} style={{cursor:'pointer'}}>

                        <div className={`${style.risk_block}`}>
                          <div className={`${style.score_left}`}>
                            <p><label>{assessment.score}</label><span>{assessment.type && assessment.type[0].toUpperCase() + assessment.type.slice(1)} Score</span></p>
                          </div>
                          <div className={`${style.score_right}`}>

                            <p onClick={() => modalOpenRisk(index, assessment)}
                              className={`modalOpenRisk ${style.risk_label} ${assessment.scale && assessment.scale === 'Mild' || assessment.scale === 'Medium Risk' ? style.risk_mild : assessment.scale && assessment.scale === 'Severe' || assessment.scale === 'Very High' ?
                                style.risk_severe : assessment.scale && assessment.scale === 'Moderate' ? style.risk_moderate : assessment.scale && assessment.scale === 'High' || assessment.scale === 'Heavy'? style.risk_high : ''}`}>
                              <label> {assessment.scale ? assessment.name.replace(' - ' + assessment.scale, '') : assessment.name}</label></p>
                            <p onClick={() => modalOpenRisk(index, assessment)}
                              className={`modalOpenRisk ${style.risk_label} ${assessment.scale && assessment.scale === 'Mild' || assessment.scale === 'Medium Risk' ? style.risk_mild : assessment.scale && assessment.scale === 'Severe' || assessment.scale === 'Very High' || assessment.scale === 'Very High Risk' || assessment.scale === 'High Risk' ?
                                style.risk_severe : assessment.scale && assessment.scale === 'Moderate' ? style.risk_moderate : assessment.scale && assessment.scale === 'High' || assessment.scale === 'Heavy' ? style.risk_high : ''}`}>
                              <span> {assessment.scale}</span></p>
                            {/* <p className={`${style.risk_label}`}><label>#B1</label><span>- None</span></p> */}
                            <p><label>Created By: </label> <span>{assessment.createdBy}</span></p>
                            <p><label>Created On:</label> <span>{assessment.createdDate && fullMonthFormatDate(assessment.createdDate)}</span></p>
                          </div>
                        </div>

                      </div>
                    }) : <div className={`pt-4 pb-4 text-center ${style.no_data_content}`}>There are no risk assessments</div>
                  }
                </div>
                }
              />
            </div>
            )
          })
        }

        {/* Modal */}
        {modalShowRisk && <Modal
          modalOpen={true}
          modalClose={modalCloseRisk}
          heading={"Risk Assessment"}
          modalSize={style.custom_modal_size}
        >
          <>
            {checkRiskAssessmentScale && checkRiskAssessmentScale === 'norton' &&
              <div className={`ml-3 ${style.riskAssesmentContent}`}>
                <p><label>Physical Condition:</label><span> {riskAssessmentQuestions && riskAssessmentQuestions.norton && Array.isArray(nortonPhysicalCondition) ? nortonPhysicalCondition.map(e => e.key === riskAssessmentQuestions.norton.physicalCondition && e.value) : ''}</span></p>
                <p><label>Mental Condition:</label><span> {riskAssessmentQuestions && riskAssessmentQuestions.norton && Array.isArray(nortonMentalCondition) ? nortonMentalCondition.map(e => e.key === riskAssessmentQuestions.norton.mentalCondtion && e.value) : ''}</span></p>
                <p><label>Activity:</label><span> {riskAssessmentQuestions && riskAssessmentQuestions.norton && Array.isArray(nortonActivity) ? nortonActivity.map(e => e.key === riskAssessmentQuestions.norton.activity && e.value) : ''}</span></p>
                <p><label>Mobility:</label><span> {riskAssessmentQuestions && riskAssessmentQuestions.norton && Array.isArray(nortonMobility) ? nortonMobility.map(e => e.key === riskAssessmentQuestions.norton.mobility && e.value) : ''}</span></p>
                <p><label>Incontinence:</label><span> {riskAssessmentQuestions && riskAssessmentQuestions.norton && Array.isArray(nortonIncontinence) ? nortonIncontinence.map(e => e.key === riskAssessmentQuestions.norton.incontinence && e.value) : ''}</span></p>

              </div>
            }
            {checkRiskAssessmentScale && checkRiskAssessmentScale === 'braden' && <div className={`ml-3 ${style.riskAssesmentContent}`}>
              <p><label>Sensory Perception - Ability to respond meaningfully to pressure related discomfort:</label><span>{riskAssessmentQuestions && riskAssessmentQuestions.braden && Array.isArray(bradenSensoryPerception) ? bradenSensoryPerception.map(e => e.key === riskAssessmentQuestions.braden.sensoryPerception && e.value) : ''}</span></p>
              <p><label>Moisture - Degree to which skin is exposed to moisture:</label><span> {riskAssessmentQuestions && riskAssessmentQuestions.braden && Array.isArray(bradenMoisture) ? bradenMoisture.map(e => e.key === riskAssessmentQuestions.braden.moisture && e.value) : ''}</span></p>
              <p><label>Activity - Degree of physical Activity:</label><span> {riskAssessmentQuestions && riskAssessmentQuestions.braden && Array.isArray(bradenActivity) ? bradenActivity.map(e => e.key === riskAssessmentQuestions.braden.activity && e.value) : ''}</span></p>
              <p><label>Mobility - Ability change and control body position:</label><span> {riskAssessmentQuestions && riskAssessmentQuestions.braden && Array.isArray(bradenMobility) ? bradenMobility.map(e => e.key === riskAssessmentQuestions.braden.mobility && e.value) : ''}</span></p>
              <p><label>Nutrition - Usual food intake pattern
                (NPO-Nothing by mouth, IV-Intrvenously, TPN-Total Parental Nutrition):</label><span>{riskAssessmentQuestions && riskAssessmentQuestions.braden && Array.isArray(bradenNutrition) ? bradenNutrition.map(e => e.key === riskAssessmentQuestions.braden.nutrition && e.value) : ''}</span></p>
              <p><label>{'Friction & Shear:'}</label><span>{riskAssessmentQuestions && riskAssessmentQuestions.braden && Array.isArray(bradenFrictionShear) ? bradenFrictionShear.map(e => e.key === riskAssessmentQuestions.braden.frictionShear && e.value) : ''}</span></p>

            </div>
            }
          </>
          <div className="col-sm-12">
            <div className="text-center pb-4">
              <br />
            

              {/* Save Button */}
              <Button
                classes={{ className: `mr-4 btn ${style.btn_custom} ${style.btn_small}` }}
                onClick={modalCloseRiskNext}

              >
                {'Next'}
              </Button>

              {/* Cancel button  */}
              <Button
                classes={{ className: `btn ${style.btn_custom} ${style.btn_small_cancel}` }}
                onClick={modalCloseRisk}
              >
                {'Cancel'}
              </Button>
            </div>
          </div>
        </Modal>
        }

        {/*Modal Scale*/}
        {modalShowRiskScale && <RiskAssessmentScale
          modalOpen={true} scaleType={checkRiskAssessmentScale} score={checkRiskAssessmentScore} modalClose={modalCloseRiskScale} />
        }
    </div>
    :
    <div className="m-3 p-3"><h3>Patient is Inactive</h3></div>
    }
 </> )
}

RiskAssessment = connect()(withRouter(RiskAssessment));
