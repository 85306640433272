import { createSlice } from '@reduxjs/toolkit';
import { SUCCESS_STATUS } from '../constants';
import { request } from '../service/api';
import { constructEndpoint } from '../utils';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    errorMessage: '',
    deleteStatus:'',
    accessError: null
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.errorMessage = action.payload
    },
    clearError: (state) => {
      state.errorMessage = ''
    },
    setDeleteStatus: (state, action) => {
      state.deleteStatus = action.payload
    },
    setAccessError: (state, action) => {
      state.accessError = action.payload;
    }
  },
});

export const { setLoading, setError, clearError, setDeleteStatus, setAccessError } = appSlice.actions;

export const deleteAsync = (endpoint, ...params) => async dispatch => {
  dispatch(setDeleteStatus(''))
  try {
    dispatch(setLoading(true));
    const response = await request(constructEndpoint(endpoint, ...params), {
      method: 'DELETE'
    })
    dispatch(setDeleteStatus(response))
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      /* If there are error, dispatch setError action */
      dispatch(setDeleteStatus(obj))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

export default appSlice.reducer;
