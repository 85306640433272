import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  CANCEL_BTN,
  CANCEL_ID,
  EDIT,
  SAVE_BTN,
  SUBMIT_ID,
  WOC_ON_STAFF_STATUS,
  NE1_RULER_MEASUREMENTS,
  PHONE_REQUIRED,
  VALID_PHONE,
  CUSTOMER_UPDATE_SUCCESS,
  UPDATE_FAILURE,
  SUCCESS_STATUS,
  ERROR_STATUS
} from '../../../constants';

import editIcon from '../../../static/images/edit-icon-blue.png';
import { checkEmailKFI, checkFirstName, getKeyByValue, phoneMasker, checkName, checkBedsAvail, checkAvgDailyCensus, checkPatientPopulation  } from '../../../utils';
import { Button, Form, RadioButton, SelectBox, TextField, MultiSelect } from '../../primary';
import styles from './moreInfo.module.scss';
import { setEditState } from '../../../app/customerSlice';
import { AccessErrorModal } from '../../primary';
import Loader from '../../../static/images/spinner.svg';
export function MoreInfo({ customerData, updateCustomerData }) {

  const dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm();
  const [editable, setEditable] = useState(false);
  let phoneField = useRef();
  const typeOfDeviceRef = useRef();
  const emrTypeData = useSelector(state => state.patient.emrTypes);
  const deviceTypeData = useSelector(state => state.patient.deviceType);
  const woundFrequencyTypeData = useSelector(state => state.patient.woundFrequencyType);
  const editState = useSelector(state => state.customer.editState);
  const accessError = useSelector(state => state.app.accessError)
  const handleEdit = () => {
    setEditable(!editable)
  }
  if (phoneField.current) {
    if (phoneField.current.value.length > 9) {
      phoneField.current.value = phoneMasker(phoneField.current.value)
    }
  }
  const [typeOfDeviceData, setTypeOfDeviceData] = useState([]);
  let typeOfDeviceItems = [];
  const handleTypeOfDeviceSelect = () => {
    const selectedItems = typeOfDeviceRef.current.getSelectedItems()
    const tempData = selectedItems.map((data) => {
      return data.key
    })
    setTypeOfDeviceData(tempData)
  }

  const onSubmitData = (data) => {
    const tempData = {
      //temporary data. this is required for update.
      /*customerGroup:"test",
      salesOfficeDescription: "test",
      divisionManagerName: "test",
      woundCareSalesRepName: "test",*/
      //    
      moreInfo: {
        "typeOfDevice": typeOfDeviceData,
        "numberOfBeds": data.numberOfBeds,
        "avgDailyCensus": data.avgDailyCensus,
        "patientPopulationPercentage": data.patientPopulationPercentage,
        "woundEvaluationFrequency": data.woundEvaluationFrequency,
        "wocnOnStaff": getKeyByValue(WOC_ON_STAFF_STATUS, data.wocnOnStaff),
        "emr": data.emr,
        "ne1RulerInUse": getKeyByValue(NE1_RULER_MEASUREMENTS, data.ne1RulerInUse),
        "other": data.other,
        "keyContact": {
          "name": data.keyContactName,
          "title": data.keyContactTitle,
          "email": data.keyContactEmail,
          "phone": data.keyContactPhone,
          //"companyWebsite": "medline.com"
        },
        "financeContact": {
          "name": data.financeContactName,
          "email": data.financeContactEmail,
          "phone": data.financeContactPhone,
        },
        "itContact": {
          "name": data.itContactName,
          "email": data.itContactEmail,
          "phone": data.itContactPhone,
        }
      }
    }
    const result = { ...customerData, ...tempData }
    updateCustomerData(result);
  }
  useEffect(() => {
    if (editState === "success") {
      setEditable(!editable)
    }
    setTimeout(() => {
      dispatch(setEditState(false))
    }, 5000)
  }, [editState, dispatch])
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
  return (
    <>
      <div className={`${styles.tab_content_top}`}>
        <h2 className={`${styles.tab_content_hdg}`}>
          {customerData?.customerName}
          {tinyDelay&&!editable &&
            <span className={`float-sm-right ${styles.tab_edit}`} onClick={handleEdit} >
              <img src={editIcon} alt="edit" />{EDIT}
            </span>
          }
        </h2>
      </div>{!tinyDelay ?<div className={`${styles.loader} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
      <div className={`col-md-12 ${styles.single_product_block}`}>
        <div className="row">
          <div className={`p-0 ${styles.patient_content}`}>
            {!editable ?
              <>
                {editState === SUCCESS_STATUS && <div className={`alert alert-success ${styles.form_alert}`}>{CUSTOMER_UPDATE_SUCCESS}</div>}
                {editState === ERROR_STATUS && <div className={`alert alert-danger ${styles.form_alert}`}>{UPDATE_FAILURE}</div>}
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>Key Contact Info</p>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Name:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.keyContact?.name !== null ? customerData?.moreInfo?.keyContact?.name : '-'}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Title:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.keyContact?.title !== "" && customerData?.moreInfo?.keyContact?.title !== null ? customerData?.moreInfo?.keyContact?.title : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Email:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.keyContact?.email !== null ? customerData?.moreInfo?.keyContact?.email :'-'}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Phone:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.keyContact?.phone !== null ? customerData?.moreInfo?.keyContact?.phone : '-'}
                    </span>
                  </div>
                </div>
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>Finance Contact Info</p>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Name:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.financeContact?.name !== "" && customerData?.moreInfo?.financeContact?.name !== null ? customerData?.moreInfo?.financeContact?.name : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Email:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.financeContact?.email !== "" && customerData?.moreInfo?.financeContact?.email !==null ? customerData?.moreInfo?.financeContact?.email : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Phone:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.financeContact?.phone !== "" && customerData?.moreInfo?.financeContact?.phone !==null ? customerData?.moreInfo?.financeContact?.phone : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>IT Contact Info</p>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Name:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.itContact?.name !== "" && customerData?.moreInfo?.itContact?.name !==null ? customerData?.moreInfo?.itContact?.name : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Email:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.itContact?.email !== "" && customerData?.moreInfo?.itContact?.email !== null ? customerData?.moreInfo?.itContact?.email : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Phone:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.itContact?.phone !== "" && customerData?.moreInfo?.itContact?.phone !==null ? customerData?.moreInfo?.itContact?.phone : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>About Customer:</p>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Type of Device:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.typeOfDevice !== null ? customerData?.moreInfo?.typeOfDevice?.forEach((data) => { //NOSONAR
                        deviceTypeData?.forEach((item) => { //NOSONAR
                          if (item.key === data) {
                            typeOfDeviceItems.push(item.value);
                          }
                        });
                      }) : "-"}
                      {typeOfDeviceItems.join(', ')}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}># of Beds (if applicable):</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.numberOfBeds !== "" && customerData?.moreInfo?.numberOfBeds !== null ? customerData?.moreInfo?.numberOfBeds : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Avg. Daily Census(if applicable):</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.avgDailyCensus !== "" && customerData?.moreInfo?.avgDailyCensus !== null ? customerData?.moreInfo?.avgDailyCensus : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Patient Population w/ Wounds(%) :</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.patientPopulationPercentage !== "" && customerData?.moreInfo?.patientPopulationPercentage !== null ? `${customerData?.moreInfo?.patientPopulationPercentage}%` : '-'}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Wound Evaluation Frequency:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.woundEvaluationFrequency !== "" && customerData?.moreInfo?.woundEvaluationFrequency !== null ? woundFrequencyTypeData && Array.isArray(woundFrequencyTypeData) && woundFrequencyTypeData?.map((item) => {
                        let value;
                        if (item.key === customerData?.moreInfo?.woundEvaluationFrequency) {
                          value = item.value;
                        }
                        return value;
                      }) : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Wound Expert on Staff?</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.wocnOnStaff !== null ? WOC_ON_STAFF_STATUS[customerData?.moreInfo?.wocnOnStaff] : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>EMR:</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.emr !== "" && customerData?.moreInfo?.emr !== null ? emrTypeData && Array.isArray(emrTypeData) && emrTypeData?.map((item) => {
                        let value;
                        if (item.key === customerData?.moreInfo?.emr) {
                          value = item.value;
                        }                    
                        return value;
                      }) : "-"}
                    </span>
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Do you use the NE1 ruler for wound measurements?</label>
                  <div className="col-6 p-0">
                    <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.moreInfo?.ne1RulerInUse !== null ? NE1_RULER_MEASUREMENTS[customerData?.moreInfo?.ne1RulerInUse] : "-"}
                    </span>
                  </div>
                </div>
              </>
              :
              <Form formChanges={true} className={` ${styles.patient_content}`} onSubmit={handleSubmit(onSubmitData)} >
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>Key Contact Info</p>
                </div>
                <TextField
                  id={"keyContactName"}
                  name={"keyContactName"}
                  label={"Name:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  // validator={register(checkName)}
                  validator={register()}
                  defaultValue={customerData && customerData?.moreInfo?.keyContact?.name}
                  errors={errors}
                  // required={true}
                />                
                <TextField
                  id={"keyContactTitle"}
                  name={"keyContactTitle"}
                  label={"Title:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  validator={register()}
                  defaultValue={customerData && customerData?.moreInfo?.keyContact?.title}
                  errors={errors}
                />
                <TextField
                  id={"keyContactEmail"}
                  name={"keyContactEmail"}
                  label={"Email:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  validator={register(checkEmailKFI)}
                  defaultValue={customerData && customerData?.moreInfo?.keyContact?.email}
                  errors={errors}
                  // required={true}
                />
                <TextField
                  id={"keyContactPhone"}
                  name={"keyContactPhone"}
                  label={"Phone:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="phone"
                  validator={(e) => {
                    register(e, {
                      validate: {
                        required: value => {
                          if (value) {
                            if (value.length > 9) {
                              value = phoneMasker(value)
                              var isphone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
                              if (isphone) {
                                return true;
                              }
                              else {
                                return VALID_PHONE;
                              }
                            }
                            else {
                              return VALID_PHONE;
                            }
                          } 
                          // else {
                          //   return PHONE_REQUIRED;
                          // }
                        },
                      },
                    })
                    phoneField.current = e
                  }}
                  defaultValue={customerData && customerData?.moreInfo?.keyContact?.phone}
                  errors={errors}
                  // required={true}
                />
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>Finance Contact Info</p>
                </div>
                <TextField
                  id={"financeContactName"}
                  name={"financeContactName"}
                  label={"Name:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  // validator={register(checkName)}
                  validator={register()}
                  defaultValue={customerData && customerData?.moreInfo?.financeContact?.name}
                  errors={errors}
                  // required={true}
                />
                <TextField
                  id={"financeContactEmail"}
                  name={"financeContactEmail"}
                  label={"Email:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  validator={register(checkEmailKFI)}
                  // validator={register()}
                  defaultValue={customerData && customerData?.moreInfo?.financeContact?.email}
                  errors={errors}
                  // required={true}
                />
                <TextField
                  id={"financeContactPhone"}
                  name={"financeContactPhone"}
                  label={"Phone:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  validator={(e) => {
                    register(e, {
                      validate: {
                        required: value => {
                          if (value) {
                            if (value.length > 9) {
                              value = phoneMasker(value)
                              var isphone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
                              if (isphone) {
                                return true;
                              }
                              else {
                                return VALID_PHONE;
                              }
                            }
                            else {
                              return VALID_PHONE;
                            }
                          } 
                          // else {
                          //   return PHONE_REQUIRED;
                          // }
                        },
                      },
                    })
                    phoneField.current = e
                  }}
                  defaultValue={customerData && customerData?.moreInfo?.financeContact?.phone}
                  errors={errors}
                  // required={true}
                />
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>IT Contact Info</p>
                </div>
                <TextField
                  id={"itContactName"}
                  name={"itContactName"}
                  label={"Name:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  // validator={register(checkFirstName)}
                  validator={register()}
                  defaultValue={customerData && customerData?.moreInfo?.itContact?.name}
                  errors={errors}
                  // required={true}
                />
                <TextField
                  id={"itContactEmail"}
                  name={"itContactEmail"}
                  label={"Email:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  validator={register(checkEmailKFI)}
                  // validator={register()}
                  defaultValue={customerData && customerData?.moreInfo?.financeContact?.email}
                  errors={errors}
                  // required={true}
                />
                <TextField
                  id={"itContactPhone"}
                  name={"itContactPhone"}
                  label={"Phone:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  validator={(e) => {
                    register(e, {
                      validate: {
                        required: value => {
                          if (value) {
                            if (value.length > 9) {
                              value = phoneMasker(value)
                              var isphone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
                              if (isphone) {
                                return true;
                              }
                              else {
                                return VALID_PHONE;
                              }
                            }
                            else {
                              return VALID_PHONE;
                            }
                          } 
                          // else {
                          //   return PHONE_REQUIRED;
                          // }
                        },
                      },
                    })
                    phoneField.current = e
                  }}
                  defaultValue={customerData && customerData?.moreInfo?.itContact?.phone}
                  errors={errors}
                  // required={true}
                />
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>About Customer</p>
                </div>
                <MultiSelect
                  id={"typeOfDevice"}
                  name={"typeOfDevice"}
                  label={"Type of Device:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    inputClassName: styles.basic_select,
                    labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: `col-6 p-0`,
                  }}
                  optionValue={deviceTypeData}
                  validator={typeOfDeviceRef}
                  defaultValue={customerData && customerData?.moreInfo?.typeOfDevice}
                  showCheckbox={true}
                  displayValue="value"
                  onSelect={handleTypeOfDeviceSelect}
                  labelSubText={'Select all that apply'}
                />
                <TextField
                  id={"numberOfBeds"}
                  name={"numberOfBeds"}
                  label={"# of Beds (if applicable):"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="number"
                  validator={register(checkBedsAvail)}
                  defaultValue={customerData && customerData?.moreInfo?.numberOfBeds}
                  errors={errors}
                  // required={true}
                />
                <TextField
                  id={"avgDailyCensus"}
                  name={"avgDailyCensus"}
                  label={"Avg. Daily Census (if applicable):"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="number"
                  validator={register(checkAvgDailyCensus)}
                  defaultValue={customerData && customerData?.moreInfo?.avgDailyCensus}
                  errors={errors}
                  // required={true}
                />
                <TextField
                  id={"patientPopulationPercentage"}
                  name={"patientPopulationPercentage"}
                  label={"Patient Population w/ Wounds(%):"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="number"
                  validator={register(checkPatientPopulation)}
                  defaultValue={customerData && customerData?.moreInfo?.patientPopulationPercentage}
                  errors={errors}
                  // required={true}
                />
                <SelectBox
                  id={"woundEvaluationFrequency"}
                  name={"woundEvaluationFrequency"}
                  label={"Wound Evaluation Frequency:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    inputClassName: styles.basic_select,
                    labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                  }}
                  optionValue={woundFrequencyTypeData}
                  validator={register}
                  defaultValue={customerData && customerData?.moreInfo?.woundEvaluationFrequency}
                  placeholder = {"Select"}
                />
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Wound Expert on Staff?</label>
                  <RadioButton
                    id={"wocnOnStaff"}
                    name={"wocnOnStaff"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn: "custom-control custom-radio custom-control-inline"
                    }}
                    validator={register}
                    radioOption={['Yes', 'No']}
                    defaultValue={customerData && WOC_ON_STAFF_STATUS[customerData?.moreInfo?.wocnOnStaff]}
                  />
                </div>
                <SelectBox
                  id={"emr"}
                  name={"emr"}
                  label={"EMR:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    inputClassName: styles.basic_select,
                    labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                  }}
                  optionValue={emrTypeData}
                  validator={register}
                  defaultValue={customerData && customerData?.moreInfo?.emr}
                  placeholder = {"Select"}
                />
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>Do you use the NE1 ruler for wound measurements?</label>
                  <RadioButton
                    id={"ne1RulerInUse"}
                    name={"ne1RulerInUse"}
                    classes={{
                      labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                      inputClassName: `custom-control-input ${styles.custom_control_input}`,
                      inputColumn: "custom-control custom-radio custom-control-inline"
                    }}
                    validator={register}
                    radioOption={['Yes', 'No']}
                    defaultValue={customerData && NE1_RULER_MEASUREMENTS[customerData?.moreInfo?.ne1RulerInUse]}
                  />
                </div>
                <TextField
                  id={"other"}
                  name={"other"}
                  label={"Other:"}
                  classes={{
                    column: `col-6 ${styles.col_spec}`,
                    labelClass: `col-6 col-form-label ${styles.label_spec}`,
                    inputColumn: 'col-6 p-0',
                    inputClassName: `${styles.basic_input}`
                  }}
                  type="text"
                  validator={register()}
                  placeholder={"Enter Text"}
                  errors={errors}
                  defaultValue={customerData && customerData?.moreInfo?.other}
                />
                <div className="col-sm-12">
                  <div className="text-center pb-4">
                    <br />
                    <Button
                      classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                      type="submit"
                      id={SUBMIT_ID}
                    >
                      {SAVE_BTN}
                    </Button>
                    <Button
                      classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                      id={CANCEL_ID}
                      onClick={handleEdit}
                    >
                      {CANCEL_BTN}
                    </Button>
                  </div>
                </div>
              </Form>
            }
          </div>
        </div>
      </div>
}
      {
        accessError && <AccessErrorModal />
      }
    </>
  )
}
