import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, TextField, Form } from '../../primary'
import { Link } from 'react-router-dom';
import { checkEmail } from '../../../utils'
import {Banner} from '../../secondary/Banner';
import {Logo} from '../../secondary/Logo';
import { ServiceError } from '../../../utils'
import { 
  link_style, 
  subheading, 
  form_shadow, 
  title, 
  form_group, 
  medline_block,
  service_error,
  close_btn
} from '../../../styles/common.module.scss';
import { custom_btn } from './forgotPassword.module.scss'

import { useDispatch } from "react-redux";
import { clearError } from '../../../app/appSlice'
import closeErrorIcon from "./../../../static/images/Close_Normal.svg";

export function ForgotPasswordForm({ doSubmit }) {

  /* Hooks initilization */

  const { register, handleSubmit,setValue, errors } = useForm();

  /*************************
 * Back end error handling
 *************************/

   const errorMessage = ServiceError();

  /*************************
  * Form
  *************************/
   const dispatch = useDispatch();
  return <div className={`container-fluid ${medline_block}`}>
          <div className="row vh-100">
            <div className="col-sm-12 col-lg-6">
              <Logo />
              <div className="container pt-3">
                <div className="row">
                  <div className={`col-12 col-sm-12 mx-auto pt-5 pb-5 ${form_shadow}`}>

                        <div className="col">
                          <p>
                            <span className={subheading}>Return to&#160;
                              <Link to="/login" className={link_style}>Login</Link>
                            </span>
                          </p>
                        </div>
                        <div className="col-12">
                          <h1 className={title}>Forgot Your Password</h1>
                          <p className={subheading}>Please provide your email address</p>
                        </div>

                        {/* Service error messages  */}
                        {/* {errorMessage && <div className={service_error}>{errorMessage}</div>} */}
                        {errorMessage && (
                          <div className="row">
                            <div
                            className={service_error}
                            dangerouslySetInnerHTML={{
                            __html:
                            errorMessage
                            }}
                            >
                          </div>
                        <>
                        {
                        <button className={`${close_btn}`} onClick={()=>{dispatch(clearError())}}>
                        <img src={closeErrorIcon} alt="Close" />
                        </button>
                        }
                        </>
                        </div>
                        )}

                        <Form className={"col-12 mx-auto"} onSubmit={handleSubmit(doSubmit)}>
                          <TextField
                            classes={{ column: `form-group pb-4 m-1 ${form_group}`, inputClassName: 'form-control mb-1', inputColumn:'col-12 p-0' }}
                            label={"Email"}
                            type={"text"}
                            name={"email"}
                            id={"email"}
                            placeholder={"name@yourcompany.com"}
                            validator={register(checkEmail)}
                            errors={errors}
                            required={true}
                            onChange={(e)=>{
                              if(e.target.value !== ''){
                                if(document.getElementById('email')){
                                  document.getElementById('email').value=e.target.value.toLowerCase().trim();     
                                  setValue('email',e.target.value.toLowerCase().trim())
                                }
                              }
                            }}
                          />
                          <div className="form-group pt-1">
                            <Button
                              id={"forgot-btn"}
                              type={"submit"}
                              value={"Next"}
                              classes={{ className: `btn btn-block ${custom_btn}` }}
                            />
                          </div>
                        </Form>

                  </div>
                </div>
              </div>
            </div>
            <Banner />
          </div>
        </div>
}
