import { Service } from '../service';
import { createSlice } from '@reduxjs/toolkit';
import {
  SUCCESS_STATUS,
  LAYOUT_DETAIL,
  NEW_LAYOUT,
  DELETE_LAYOUT,
  ERROR_STATUS,
  LAYOUT,
  LIST_VIEW_LIMIT,
  LIST_VIEW_MENUS,
  MARKETING_BANNER_DETAIL,
  DELETE_MARKETING_BANNER,
  MARKETING_BANNER_CREATE,
  MARKETING_BANNER_UPDATE,
  SALES_OFFICE_OPTION,
  ANNOUNCEMENT_SALES_OFFICE_CHECK,
  CHECK_CUSTOMER_NUMBER
} from '../constants';
import { setError, setLoading, deleteAsync } from './appSlice';
import { constructEndpoint } from '../utils/commonHelper'

const { request } = Service;

export const layoutsSlice = createSlice({
  name: 'layouts',
  initialState: {
    layoutsList: {},
    deatilLayout: {},
    newLayout: {},
    updateLayout: {},
    deleteLayout: {},
    marketingBanner: {},
    salesOffice: {},
    layoutEditStatus: {},
    newLayoutStatus: {},
    updateBannerStatus:{},
    documentList:{},
    excludeNumberSearch : {},
    autoSeqNumber:{}
  },
  reducers: {
    setLayouts: (state, action) => {
      state.layoutsList = action.payload;
    },
    setDetailLayout: (state, action) => {
      state.deatilLayout = action.payload;
    },
    setNewLayout: (state, action) => {
      state.newLayout = action.payload;
    }, 
    setUpdateLayout: (state, action) => {
      state.updateLayout = action.payload;
    },
    setDeleteLayout: (state, action) => {
      state.deleteLayout = action.payload;
    },
    setListViewMenus: (state, action) => {
      state.listViewMenus = action.payload;
    },
    setMarketingBanner: (state, action) => {
      state.marketingBanner = action.payload;
    },
    setsalesOffice: (state, action) => {
      state.salesOffice = action.payload;
    },
    setLayoutEditStatus: (state, action) => {
      state.layoutEditStatus = action.payload;
    },
    setNewLayoutStatus: (state, action) => {
      state.newLayoutStatus = action.payload;
    },
    setUpdateBannerStatus: (state, action) => {
      state.updateBannerStatus = action.payload;
    },
    setDocumentList: (state, action) => {
      state.documentList = action.payload
    },
    resetLayoutDetailsData: (state, action) => {
      state.deatilLayout = {}
    },
    storeDocumentList:(state,action) => {
      state.doclist = action.payload
    },
    setFacilitiesData:(state, action) => {
      state.orgsData = action.payload
    },
    reSetFacilitiesData:(state, action) => {
      state.orgsData = {}
    },
    setExcludeNumberSearch : (state, action) => {
      state.excludeNumberSearch = action.payload
    },
    setAutoSeqNumber : (state, action) => {
      state.autoSeqNumber = action.payload
    },
  }
});

export const {
  setLayouts,
  setDetailLayout,
  setNewLayout,
  setUpdateLayout,
  setDeleteLayout,
  setListViewMenus,
  setMarketingBanner,
  setsalesOffice,
  setLayoutEditStatus,
  setNewLayoutStatus,
  setUpdateBannerStatus,
  setDocumentList,
  resetLayoutDetailsData,
  storeDocumentList,
  setFacilitiesData,
  reSetFacilitiesData,
  setExcludeNumberSearch,
  setAutoSeqNumber
} = layoutsSlice.actions;

/* Get list view menus */
export const listViewMenuAsync = () => async dispatch => {
  try {
    const resp = await request(LIST_VIEW_MENUS, {
      method: 'GET'
    })
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setListViewMenus(resp.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
}

/* Get layout details by layout id */
export const layoutListAsync = (payload) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  try {
    dispatch(setLoading(true));
    const resp = await request(constructEndpoint(url, queryParam), {
      method: method
    })
    if (resp && resp.status === SUCCESS_STATUS) {    
	    resp.data.layouts.map((value) => {
        // value['salesOffice'] = (value['salesOffice'] && value['salesOffice'] !== '') ? ANNOUNCEMENT_SALES_OFFICE_CHECK[value?.salesOffice?.toLowerCase()]: ''; 
        value['salesOffice'] = (value['salesOffice'] && value['salesOffice'] !== '') ? ANNOUNCEMENT_SALES_OFFICE_CHECK[value?.salesOffice] ? ANNOUNCEMENT_SALES_OFFICE_CHECK[value?.salesOffice?.toLowerCase()]: value?.salesOffice.charAt(0).toUpperCase() + value?.salesOffice.slice(1).toLowerCase() : ''; //changed for labels
      })
      dispatch(setLayouts(resp.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405 || obj?.response?.status === 404 ){
      window.location='/unAuthorized';
    }
    dispatch(setError(obj.errorMessage))
    
  }
  finally {
    dispatch(setLoading(false));
  }
}

// detailview
export const layoutDetailsAsync = (payload, history) => async dispatch => {
  dispatch(setDetailLayout({}))
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(LAYOUT_DETAIL, payload), {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setDetailLayout(data.data))
      if(data?.data && data?.data.customerInfo){
        dispatch(getLayoutAsync(data?.data && data?.data.customerInfo))
      }
     
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    if(obj?.response?.status === 405 || obj?.response?.status === 404 ){
      history.push('/unAuthorized')
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Create layout POST call
export const createLayoutAsync = (payload) => async (dispatch) => {
    try {
    dispatch(setLoading(true));
    const resp = await request(constructEndpoint(NEW_LAYOUT), {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setNewLayoutStatus(resp.status))
      dispatch(setNewLayout(resp.status))
      const listPayLoad = [];
      listPayLoad['url'] = LAYOUT
      listPayLoad['method'] = 'Get'
      listPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: 0 }
      dispatch(layoutListAsync(listPayLoad))
      dispatch(listViewMenuAsync())
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setNewLayoutStatus(obj));
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Layout PUT call
export const updateLayoutAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(LAYOUT_DETAIL, payload.id), {
      method: 'PUT',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(layoutDetailsAsync(payload.id)); // call-back layoutDetailsAsync
      dispatch(setLayoutEditStatus(data.status));
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setLayoutEditStatus('error'));
    }
    return data
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
     dispatch(setLayoutEditStatus(obj));
    return error
  }
  finally {
    dispatch(setLoading(false));
  }
}

// delete layout list
export const deleteLayoutListAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    payload.map((value) => {
      dispatch(deleteAsync(DELETE_LAYOUT, value.id))
    })
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}


// marketing banner
export const getMarketingBanners = (payload, history) => async dispatch => {
  let bannerDatas = [];
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(MARKETING_BANNER_DETAIL, payload), {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      if (data.data !== null) {
        bannerDatas = data.data;
      }
      dispatch(setMarketingBanner(bannerDatas))
    }
  }
  catch (error) {

    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// delete Marketing Banner
export const deleteMarketingBannerAsync = (layoutId, bannerId) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(DELETE_MARKETING_BANNER, layoutId, bannerId), {
      method: 'DELETE',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(getMarketingBanners(layoutId))
    }
  }
  catch (error) {
    // Sales Office list option get call
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const salesOfficeAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const resp = await request(`${SALES_OFFICE_OPTION}/salesoffice`, {
      method: 'GET',
    })
    if (resp && resp.status === SUCCESS_STATUS) {
      dispatch(setsalesOffice(resp.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

//  Marketing Banner POST call
export const createMarketingBannertAsync = (payload, layoutId) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(MARKETING_BANNER_CREATE, layoutId), {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(getMarketingBanners(layoutId)) // call-back layoutDetailsAsync 
    }
    
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

//  Marketing Banner PUT call
export const updateMarketingBannerAsync = (payload, layoutId, bannerId) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(MARKETING_BANNER_UPDATE, layoutId, bannerId), {
      method: 'PUT',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setUpdateBannerStatus(data.status))
      dispatch(getMarketingBanners(layoutId)) // call-back layoutDetailsAsync 
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setUpdateBannerStatus('error'))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
//get 

export const getLayoutAsync = (payload, setRemoveDefaultOrg) => async dispatch => {
  if(payload){
    try {
      //dispatch(reSetFacilitiesData({}))
      dispatch(setLoading(true));
      const data = await request(`${CHECK_CUSTOMER_NUMBER}?customerNumber=${payload}`, {
        method: 'GET',
      });
      if (data && data.status === SUCCESS_STATUS) {
       // dispatch(setDetailLayossut(data))
        dispatch(setFacilitiesData(data))
        if(setRemoveDefaultOrg){
          dispatch(setRemoveDefaultOrg(true))
        }
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
      //dispatch(setDetailLayout(obj))
      dispatch(setFacilitiesData(obj))
    }
    finally {
      dispatch(setLoading(false));
    }
  }
 
}
//Layout get document
export const getDocumentList = () => async dispatch => {
  console.log("Get Document List");
  try {
    dispatch(setLoading(true));
    const data = await request(`/layout/document`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setDocumentList(data.data)) // call-back detail
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Update documents
export const updateDocumentList = ({  getDocs, setUploadSuccess, setServerError,doclist,documentListItems}) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/layout/document`, {
      method: 'POST',
      body: JSON.stringify(doclist)
    });
        if (data && data.status === SUCCESS_STATUS) {
          let newDoc=data.data
          let exitDoc={...documentListItems}
          if(Array.isArray(exitDoc.documents)&&Array.isArray(newDoc.documents)){
            let mergeDoc=exitDoc.documents.concat(newDoc.documents);
               dispatch(setDocumentList({documents:mergeDoc}))
          }else{
            dispatch(setDocumentList(newDoc))
          }
          
      if(setUploadSuccess){
        dispatch(storeDocumentList(...data));
        setUploadSuccess(true);
      }
      if(getDocs){
        getDocs();
      }
    }
  }
  catch (error) {  
    const obj = { ...error }
    if(setServerError){
      setServerError(obj.errorMessage)
      setTimeout(() => {
        setServerError(null)
      }, 5000)
    }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
//Customer Number Search Suggestions
export const getCustomerNumberSuggestionsLayout = (payload,setSuggestionsBusy,salesOfficeType) => async (dispatch) => {

  try {
    dispatch(setLoading(true));     
    const data = await request(`/customerbykeyword`, {
      method: 'POST',
      body: JSON.stringify({customerNumber:payload,salesOfficeType:salesOfficeType})
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setExcludeNumberSearch(data.data))   
      if(setSuggestionsBusy){
        setSuggestionsBusy(false)
      }
    }
  }
  
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setExcludeNumberSearch(obj))
    if(setSuggestionsBusy){
      setSuggestionsBusy(false)
    }
  }
  finally {
    dispatch(setLoading(false));
  } 
}

//get AutoSeqNumber
export const getAutoSeqNumber = ({payload}) => async (dispatch) => {

  try {
    if(payload?.sequenceNumber===''){
      dispatch(setAutoSeqNumber({}))   
    }
    dispatch(setLoading(true));     
    const data = await request(`/nextsequencenumber`, {
      method: 'POST',
      body: JSON.stringify({sequenceNumber:payload?.sequenceNumber,layoutType:payload?.layoutType,groupType:payload?.groupType,customerNumber:payload?.customerNumber})
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setAutoSeqNumber(data))   
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))

  }
  finally {
    dispatch(setLoading(false));
  } 
}
export default layoutsSlice.reducer;
