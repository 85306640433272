import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { EDIT, UPDATE_SUCCESS, UPDATE_FAILURE, SUCCESS_STATUS, ERROR_STATUS, SUCCESS} from '../../../constants'
import editIcon from '../../../static/images/edit-icon-blue.png'
import { phoneMasker, getFacilityLabel, getFacilityLabelUrl,ServiceError } from '../../../utils'
import styles from './basicInfoSso.module.scss'
import { CreateEditSso } from './createEditSso'
import { setEditState, getFaciltiestUserAsyn, setCustomerNumberSearch } from '../../../app/userSlice';
import { AccessErrorModal, Button, SelectBox } from '../../primary';
import { clearError } from "../../../app/appSlice";
import Loader from '../../../static/images/spinner.svg';

export function BasicInfoSso({ userData, facilityOptions, updateSsoData, getSearchValue , searchKey, isModal = false}) {
    const { ssoId } = useParams();
    /* State to check editable forms */
    const [editable, setEditable] = useState(false)
    const [disable, setDisable] = useState(false)
    const [selectedFacilities, setSelectedFacilities] = useState([])
    const [counterChange, setCounterChange] = useState(0)    
    
    /* Function to toggle edit */
    const handleEdit = () => {
        setEditable(!editable)
        setDisable(!disable);
        setShowEmailError(false)
        setCounterChange(0)
        dispatch(setCustomerNumberSearch({}));  
        let customerNumberVal = userData?.primaryGroup?.toUpperCase();
        const payload = { customer: customerNumberVal }
        if(userData?.isTokenizedFacility === 0){
            dispatch(getFaciltiestUserAsyn(payload));  
        }
    }

    /* Use hook form */
    const { register, handleSubmit, control, errors, setError } = useForm({
        mode: 'onBlur'
    });

    /*************************
         * Phone number field
         *************************/
    /* Get value from phone field using useREf */
    let phoneField = useRef();

    /* If phone number is typed call the above phoneHandle function */
    if (phoneField.current) {
        if (phoneField.current.value.length > 9) {
            phoneField.current.value = phoneMasker(phoneField.current.value)
        }
    }

     /* Edit success */
     const editState = useSelector(state => state.user.editState)

     const dispatch = useDispatch()
 
     useEffect(() => {
         setTimeout(() => {
             dispatch(setEditState(false))
         }, 5000)
     }, [editState, dispatch])

    const userDetails = useSelector(state => state.user);
    const adminView = userDetails?.role
    const [externalAdmin, setExternalAdmin] = useState(false);  
    useEffect(() => {
        if (adminView === "ExternalAdministrator") {
            setExternalAdmin(true)
        }
    }, [adminView])

    const [showEmailCheckbox, setShowEmailCheckbox] = useState(false)
    useEffect(() => {
        if (userData?.primaryGroup) {
            const payload = { customer: userData?.primaryGroup,}
            dispatch(getFaciltiestUserAsyn(payload));
        }
        if(userData?.assignToAllEmails===0){
            if(Array.isArray(userData?.assignToSpecificEmail)&&userData?.assignToSpecificEmail?.length >= 1){
              setShowEmailCheckbox(true)
            }  
            else{
              setShowEmailCheckbox(false)
            }  
          }
    }, [userData])

    // facility data from state.
    const getFacilities = useSelector(state => state.user.userFacilities);

    let facilityData = "";
    (getFacilities.data) && getFacilities.data.facilities.map((value, index) => {
        facilityData = `${value.facilityNumber}| ${value.facilityName} | ${value.address?.addressLine1} | ${value.address?.addressLine2} | ${value.address?.city} | ${value.address?.state} | ${value.address?.zipCode}`;
    })

    const [getCheckStatus, setGetCheckStatus] = useState(userData?.isActive === 1 ? 1 : 0);
    const [getmulIncEmail, setGetmulIncEmail] = useState('')
    const [showEmailError, setShowEmailError] = useState(false)
    const[makeVisble, setMakeVisble] = useState(false)
    
    const ErrorMessage = ServiceError();      
    useEffect(() => {
        if (editState === "success"){
            handleEdit();
        }
        else if (editState === "error"){
            if (ErrorMessage==="Tenant and Primary Group mapping already exists") {
                setError("tenant", {
                type: "manual",
                message: "Tenant and Primary group mapping already exists"
                });
                dispatch(clearError());
            }
        }
            
    }, [ErrorMessage,dispatch,editState])

    const [typeVis, setTypeVis] = useState(userData?.isTokenizedFacility === 0 ? false : true)
    const [errorFacilities, setErrorFacilities] = useState(false)
    
    /* On Submit */
    const onSubmit = (data) => { 
        if(typeVis === false){
            /* validating */ 
            if(selectedFacilities?.length === 0){
                setErrorFacilities(true)
                return false
            }
            if(selectedFacilities?.length > 0){
                setErrorFacilities(false)
            }

            if(data.tableTBD === "Include" && data.assigntoallemails==='No'){
                if(getmulIncEmail.length === 0){
                setShowEmailError(true)
                return false
                }
            }
            if(data.tableTBD === "Exclude"){
                // if(data.PrimaryGroup === ""){
                //     setMakeVisble(true)
                // }
                if(getmulIncEmail.length === 0){
                setShowEmailError(true)
                return false
                }
            } /* validating */ 

            // handleEdit();
            /* Merging */
            let tempUserData = {           
                ssoTenantMappingId: parseInt(ssoId), // id
                ssoDomainName: data.ssodomainname,
                tenant: data.tenant !== undefined ? data.tenant : null,
                department: data.department !== undefined ? data.department : null,
                title: data.title !== undefined ? data.title : null,
                PrimaryGroup: data?.customerNumber?.toLowerCase(),
                role:data.roleID,
                AssignToAllEmails: data.assigntoallemails === "Yes" ? 1 : 0,
                AssignToSpecificEmails: data.tableTBD === "Include" && data.assigntoallemails === "No" ? getmulIncEmail : [] ,
                ExcludeToSpecificEmails: data.tableTBD === "Exclude" ? getmulIncEmail : "",
                facilities:selectedFacilities,
                IsActiveStatus: getCheckStatus=== 1 ? 1 : 0,
                isSSOEnable: getCheckStatus=== 1 ? 1 : 0,
                isTokenizedFacility: typeVis === true ? 1 : 0
                
            }
            // const result = { ...userData, ...tempUserData }
            let result = {...tempUserData }
            /* Dispatching */
            updateSsoData(result);
        }

        if(typeVis === true){
            {
                let tempUserData = {           
                    ssoTenantMappingId: parseInt(ssoId), // id
                    ssoDomainName: data.ssodomainname,
                    tenant: "",
                    department: null,
                    title: null,
                    PrimaryGroup: "",
                    role:data.roleID,
                    AssignToAllEmails: 1,
                    AssignToSpecificEmails: [] ,
                    ExcludeToSpecificEmails: "",
                    facilities:[],
                    IsActiveStatus: getCheckStatus=== 1 ? 1 : 0,
                    isSSOEnable: getCheckStatus=== 1 ? 1 : 0,
                    isTokenizedFacility: typeVis === true ? 1 : 0
                    
                }
                // const result = { ...userData, ...tempUserData }
                let result = {...tempUserData }
                /* Dispatching */
                updateSsoData(result);
            } 
        }
                   
    }
   
    
    // useEffect(()=>{
    //     if(selectedFacilities?.length === 0){
    //         setErrorFacilities(true)
    //     }
    //     if(selectedFacilities?.length > 0){
    //         setErrorFacilities(false)
    //     }
    // },[onsubmit])

    const facilitiesData = useSelector(state => state.user.userFacilities);    
    const assignedFacilities = () => {
        const getSelList = userData?.filter((item)=> item.primaryGroup === userData?.primaryGroup)        
        const defaultFac = getSelList?.facilities !== null ? getSelList?.facilities : null;
        let defaultFacArr = [];
        const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
        soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
            const shipToFacilities = facilitiesData.data.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
            if(shipToFacilities.length===0){
                defaultFac && defaultFac.forEach((shipTofacilityNumber) => {
                    if (shipTofacilityNumber.facilityNumber === soldToFacility.facilityNumber) {
                defaultFacArr.push(<div className={`col-form-label ${styles.content_spec}`}>               
                {getFacilityLabelUrl(soldToFacility)}                
            </div>            
            )
                    }
             })
               }
            shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
                defaultFac && defaultFac.forEach((shipTofacilityNumber) => {
                    if (shipTofacilityNumber.facilityNumber === shipToFacility.facilityNumber) {
                        defaultFacArr.push(<div className={`col-form-label ${styles.content_spec}`}>
                        {getFacilityLabelUrl(shipToFacility)}                       
                    </div>)
                    }
                })
            })
        })
        return defaultFacArr;
    }
    const [tinyDelay, setTinyDelay] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    }, [])
    return (
        <>
        {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
              <>
            <AccessErrorModal />
            {!editable && (
                <div className={`${styles.tab_content_top}`}>
                 
                <div className={`col-12 d-flex ${styles.col_spec} py-4`}> 
                    <div className="col-4 px-2">
                        <span className={`col-form-label ${styles.content_spec} align-items-center`}>
                            <b>Domain name: </b>                          
                            <a href={`https://www.${userData?.domainName}`} target="_blank" rel="noopener noreferrer external">{userData?.domainName}</a>
                        </span>
                    </div>                  
                    <div className="col-3 px-2">
                        <span className={`col-form-label ${styles.content_spec}`}>
                            <b>Role: </b>
                            {userData?.role}
                        </span>
                    </div>

                    <div className="col-2 pl-0 pr-2">
                        <span className={`col-form-label ${styles.content_spec}`}>
                            <b>Enable SSO: </b>
                            {userData?.isSSOEnable === 1 ? "Yes" : "No"}
                        </span>
                    </div>

                    <div className="col-3">
                        {!editable &&
                            <span className={`float-sm-right ${styles.tab_edit}`} onClick={handleEdit} >
                                <img src={editIcon} alt="edit" />{EDIT}
                            </span>
                        }
                    </div>                        
                </div> 
                <div className="col-12 pb-4">
                    <span className={`col-form-label ${styles.content_spec} pl-2 align-items-center`}>
                        <b style={{paddingRight:'1px'}}>Assign facility to each user using the tenant parameter in the token: </b>
                        {userData?.isTokenizedFacility === 1 ? "Yes" : "No"}
                    </span>
                </div>
            </div>
            )}
                        <div className={`col-md-12 single_product_block ${styles.single_product_block}`}>
                <div className="row">
                    <div className={`col-12 p-0 ${styles.patient_content} justify-content-start`}>
                        {!editable ?
                            <>
                                {/* Edit success */}
                                {editState === SUCCESS_STATUS && <div className={`alert alert-success ${styles.form_alert}`}>{UPDATE_SUCCESS}</div>}

                                {/* Edit failure */}
                                {editState === ERROR_STATUS && <div className={`alert alert-danger ${styles.form_alert}`}>{UPDATE_FAILURE}</div>}
                                
                                {/* Sso Information Heading */}
                                <div className={`col-sm-12 mt-3 ${styles.col_spec} pt-3`}>
                                    <p className={`col-12 ${styles.subheading_2}`}>SSO Information</p>
                                </div>                        

                                {/* Include/Exclude button */}
                                <div className={`col-5 ${styles.col_spec} pt-3`} data-testid="include_exclude">
                                    <label className={`col-7 col-form-label ${styles.label_spec}`}>Include/Exclude: </label>
                                    <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{userData?.isTokenizedFacility === 0 ? ((userData?.assignToAllEmails === 1 || showEmailCheckbox === true) ? "Include" : "Exclude") : "-" }</span></div>
                                </div>

                                {/* Teanant*/}
                                <div className={`col-6 ${styles.col_spec} pt-3`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>Tenant: </label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{userData?.isTokenizedFacility === 0 ? (userData?.tenant !==null && userData?.tenant !== "" ? userData?.tenant : "-") : "-"}</span></div>
                                </div>  

                                {/* Department*/}
                                <div className={`col-5 ${styles.col_spec} pt-3`} data-testid="include_exclude">
                                    <label className={`col-7 col-form-label ${styles.label_spec}`}>Department: </label>
                                    <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{userData?.isTokenizedFacility === 0 ? (userData?.department !==null && userData?.department !== "" ? userData?.department?.charAt(0)?.toUpperCase() + userData?.department?.slice(1)?.toLowerCase() : "-") : "-"}</span></div>
                                </div>

                                {/* Title*/}
                                <div className={`col-6 ${styles.col_spec} pt-3`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>Title: </label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{userData?.isTokenizedFacility === 0 ? (userData?.title !==null && userData?.title !== "" ? userData?.title?.charAt(0)?.toUpperCase() + userData?.title?.slice(1)?.toLowerCase() : "-") : "-"}</span></div>
                                </div>                               

                                {/* all email address Yes/No button */}
                                <div className={`col-5 ${styles.col_spec} pt-3`}>
                                    <label className={`col-7 col-form-label ${styles.label_spec}`}>All email addresses: </label>
                                    <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}>{userData?.isTokenizedFacility === 0 ? (userData?.assignToAllEmails === 1 ? "Yes" : "No") : "-"}</span></div>
                                </div>
                               
                                {/* Specific email addresses */}
                                <div className={`col-6 ${styles.col_spec} pt-3`}>
                                    <label className={`col-6 col-form-label ${styles.label_spec}`}>Specific email addresses: </label>
                                    <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{userData?.isTokenizedFacility === 0 ? (userData?.assignToAllEmails === 0 ? showEmailCheckbox === true ? userData?.assignToSpecificEmail?.map(value=><li>{value}</li>) : userData?.excludeToSpecificEmail?.map(value=><li>{value}</li>) : '-'):"-"}</span></div>
                                </div>                                

                                {/* Primary Group */}
                                <div className={`col-5 ${styles.col_spec} pt-3`}>
                                    <label className={`col-7 col-form-label ${styles.label_spec}`}>Primary group: </label>
                                    <div className="col-5 p-0"><span className={`col-form-label ${styles.content_spec}`}><a href={`/admin/customers/${userData?.primaryGroupId}`} target="_blank">{userData?.isTokenizedFacility === 0 ? (userData?.primaryGroup !== "" ? userData?.primaryGroup?.charAt(0)?.toUpperCase() + userData?.primaryGroup?.slice(1)?.toLowerCase() : "-") : "-"}</a></span></div>
                                </div>

                                {/* Facilities */}
                                <div className={`col-11 ${styles.col_spec} pt-3`}>
                                    <label className={`col-3 col-form-label ${styles.label_spec}`}>Facility/Locations: </label>
                                    <div className={`col-8 ${styles.col_spec} pl-3 ml-1 d-block`}>
                                    {/* <div className={`col-9 ${styles.col_spec} d-block`}>
                                        {userData?.facilitiesValue?.map((list)=><li>{list}</li>)} */}
                                        {userData?.facilities?.filter((fac)=>fac.facilityNumber!==null)?.map((fac)=>{
                                                return(
                                                    <div className={`row ${styles.content_spec} d-block`}>                            
                                                        <div className={`col-12 pl-1 col-form-label ${styles.content_spec}`}>               
                                                            {getFacilityLabelUrl(fac)}   
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        } 
                                        {userData?.facilities?.length===0 && userData?.isTokenizedFacility === 1 &&
                                            <div className={`col-12 px-0 col-form-label ${styles.content_spec} ${styles.content_spec_facilities}`}>               
                                                {"-"}   
                                            </div>  
                                        }                                   
                                    </div>
                                </div>                                    

                            </>
                            :
                            <CreateEditSso                                
                                register={register}
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                control={control}
                                errors={errors}
                                userData={userData}
                                handleCancel={handleEdit}
                                externalAdmin={externalAdmin}
                                facilityOptions={facilityOptions}
                                setSelectedFacilities={setSelectedFacilities}
                                getSearchValue={getSearchValue}
                                searchKey = {searchKey}
                                assignedFacilities = {()=>assignedFacilities()}
                                editState = {editState}
                                facilitiesData = {facilitiesData}  
                                setGetCheckStatus={setGetCheckStatus}  
                                setGetmulIncEmail={setGetmulIncEmail}
                                errorFacilities={errorFacilities}
                                showEmailError={showEmailError} 
                                setShowEmailError={setShowEmailError}
                                makeVisble={makeVisble}   
                                setError={setError}
                                typeVis={typeVis}
                                setTypeVis={setTypeVis}  
                                counterChange={counterChange}
                                setCounterChange={setCounterChange}                     
                            />
                        }
                    </div>
                </div>
            </div>
            </>
            }
        </>
    )
}
