import React from 'react';
import { SurveyResults } from '../components/core/SurveyResults/surveyResults';

export const Survey = ({history}) => {
  return (
    <>
      <SurveyResults history={history}/>
    </>
  )
}
