import React from 'react'
import styles from './characteristicCustomForm.module.scss'
import { TextField, SelectBox} from '../../primary'

export function CharacteristicCustomFormSingleSelect({ data, index, optionData, getForm, register, errors, singleSelectVal, showViewForm, optionLevelbranching, customFormBuilder, checkBranch}) {
    let viewForm = getForm?.formdata?.length > 0 && [...getForm.formdata].find(v => v.id === data.id)
    let forms = customFormBuilder && [...customFormBuilder]
    let findOthers = viewForm?.options?.find(v => v === "other")
    const singleSelectValue = (val, index, id) => {
        if(val !==""){
            let formobj = { ...forms[index] }
            let findGotoNum = formobj.options.find((o) => o.key === val)
            let branchNumber = checkBranch(findGotoNum, index)
            if (showViewForm?.formdata?.length > 0) {
                let viewData = { ...getForm?.formdata[index] }
                viewData.options = [val]
                let dataform = { ...getForm }
                let viewFrm = [...getForm.formdata]
                viewFrm.splice(index, 1, viewData)
                dataform.formdata = viewFrm
                singleSelectVal(dataform)
            } else {
                let viewForm = getForm?.formdata?.length > 0 && [...getForm.formdata].find(v => v.id === id)
                let selectedIndex = getForm?.formdata?.length > 0 && [...getForm.formdata].findIndex(x => x.id === id);
                if (viewForm) {
                    let viewData = { ...viewForm }
                    viewData.options = [val]
                    let dataform = { ...getForm }
                    let viewFrm = [...getForm.formdata]
                    viewFrm.splice(selectedIndex, 1, viewData)
                    dataform.formdata = viewFrm
                    singleSelectVal(dataform)
                } else {
                    let viewData = { ...getForm }
                    viewData.formdata = [...viewData.formdata, { id: id, options: [val], value: "" }]
                    singleSelectVal(viewData)
                }
            }
            branchNumber !== "" && optionLevelbranching(val, index, id, forms, branchNumber)
        }
    }

    const getOtherValue = (e, index, id) => {
        if (showViewForm?.formdata?.length > 0) {
            let viewData = { ...getForm.formdata[index] }
            viewData.value = e.target.value
            let dataform = { ...getForm }
            let viewFrm = [...getForm.formdata]
            viewFrm.splice(index, 1, viewData)
            dataform.formdata = viewFrm
            singleSelectVal(dataform)
        } else {
            let viewForm = getForm?.formdata?.length > 0 && [...getForm.formdata].find(v => v.id === id)
            let selectedIndex = getForm?.formdata?.length > 0 && [...getForm.formdata].findIndex(x => x.id === id);
            if (viewForm) {
                let viewData = { ...viewForm }
                viewData.value = e.target.value
                let dataform = { ...getForm }
                let viewFrm = [...getForm.formdata]
                viewFrm.splice(selectedIndex, 1, viewData)
                dataform.formdata = viewFrm
                singleSelectVal(dataform)
            }
        }
    }

    return <div key={index}>
        <SelectBox
            id={`${data.id}`}
            name={`${data.id}`}
            label={`${data.desc}`}
            classes={{
                column: `col-12 ${styles.col_spec}`,
                inputClassName: `${styles.basic_select} singleSelect`,
                labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                inputColumn: `col-5 p-0 ${styles.select_wrapper}`
            }}
            optionValue={optionData}
            validator={register}
            errors={errors}
            placeholder="Select"
            defaultValue={viewForm?.options}
            onChange={(e) => singleSelectValue(e.target.value, index, data.id)}
        />
        {findOthers && <TextField
            id={`${data.id}`}
            name={`${data.id}`}
            classes={{
                column: `col-12 ${styles.col_spec}`,
                inputColumn: `offset-6 col-5 p-0 ${styles.display_flex}`,
                inputClassName: `${styles.basic_input} getOtherval`
            }}
            type="text"
            validator={register()}
            errors={errors}
            defaultValue={viewForm?.value}
            onBlur={(e) => getOtherValue(e, index, data.id)}
        />}
    </div>
}
