import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { withRouter, useParams } from 'react-router-dom'
import { DetailView } from '../components';
import { ListView } from '../components/secondary/ListView';
import { LIST_VIEW_LIMIT, ANNOUNCEMENT } from '../constants';
import { getCallBackListAsync, setLists, resetLists, deleteUserListAsync } from '../app/announcementSlice'
import { setDeleteStatus } from '../app/appSlice';
import { List, Search, Sorting, Pagination, Delete, SelectedRow } from '../utils'

export let Announcement = ({ dispatch, history, location }) => {

    let userDetails = useSelector(state => state.user);
    let customerNumber = 0;
    if (userDetails) {
        customerNumber = userDetails.customerNumber
    }
  
    const [offSet, setOffSet] = useState(0)
    let announcementList = useSelector(state => state.announcement.announcements);
    const deleteData = useSelector(state => state.app.deleteStatus)

    useEffect(() => {
        dispatch(setDeleteStatus({}))
    }, [dispatch])

    useEffect(() => {
        if (deleteData.status === "success") {
            getAnnouncementList();
        }
    }, [deleteData])
    /* Get state with the Individual customer data */
    const listDataById = useSelector(state => state.announcement.detailViewData)
    /* Tabs object */
    const tabList = [
        {
            name: 'Basic Info',
            content: ''
        }
        , {
            name: 'Settings',
            content: ''
        }

    ];
    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);
    const [detailsRow, SelectedRow] = useState(false)
    const [searchKey, setSearchKey] = useState('')
    const [sortType, setSortType] = useState('')
    /* Get User Details */
    const getAnnouncementList = () => {
        List(ANNOUNCEMENT, 'Get', LIST_VIEW_LIMIT, 0, dispatch, getCallBackListAsync)
    }
    useEffect(() => {
        setSortType('')
        setSearchKey('')
        dispatch(resetLists())
        getAnnouncementList();
    }, [dispatch]);
    const showFixedColumn = !!announcementList.data && !!announcementList.data.announcements.length
    let columns = [
        {
            Header: 'Announcement',
            accessor: 'title',
            fixedColumn: showFixedColumn,

        },
        {
            Header: 'Created date',
            accessor: 'createdDate',

        },
        {
            Header: 'Sales office',
            accessor: 'salesOffice',
        }
    ];
    /* If url changes, hide the detail view */
    useEffect(() => {
        SelectedRow(false)
    }, [location])
    const getSelectedRowData = (data, listDataById) => {
        /* If user clicks the row redirect to detail view page by attaching MRN to the url */
        if (data) {
        }
    }
    const handleBack = () => {
        SelectedRow(false)
    }
    // search list
    const getSearchValue = (event) => {
        if (event && event.keyCode === 13) {
            if (event && event.target.value !== '') {
                setOffSet(0)
                setActivePage(1)
                setSearchKey(event.target.value)
                Search(event, ANNOUNCEMENT, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType)
            }else{
                setOffSet(0)
                setActivePage(1)
                getAnnouncementList();
                setSearchKey('')
                setSortType('')
            }
        }
    }
    // pagination
    const [activePage, setActivePage] = useState(1);
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        Pagination(data, setActivePage, ANNOUNCEMENT, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackListAsync,searchKey,'',sortType)
    }

    const sortingPayLoad = Sorting(ANNOUNCEMENT, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackListAsync,searchKey,sortType,setSortType)

    const deleteList = (data) => {
        Delete(data, dispatch, deleteUserListAsync, getAnnouncementList)
    }
    return (
        <div>
            {!detailsRow && <ListView
                columns={columns}
                data={announcementList.data && announcementList.data.announcements}
                metaData={announcementList.data && announcementList.data.metadata}
                sorting={true}
                rowSelect={isAdminView}
                getSelectedRowData={getSelectedRowData}
                detailView={true}
                pageRangeDisplayed={5}
                searchPlaceholder={"Announcement Name, Created date, Sales office"}
                getSearchValue={(e) => getSearchValue(e)}
                detailsRow={detailsRow}
                activePage={activePage}
                getActivePage={getActivePage}
                //handleSort={listSorting}
                listSorting={sortingPayLoad}
                listType={"Announcement"}
                subHeading={'Announcement'}
                showFixedColumn={showFixedColumn}
                deleteListView={deleteList}
                searchKey={(searchKey) ? searchKey : ''}
                offset={offSet}
                sortType={sortType?sortType:''}

            />}
            {
                detailsRow && <DetailView
                    title="Announcement"
                    tabList={tabList}
                    defaultIndex={0}
                    detailsRow={detailsRow}
                    handleBack={handleBack}
                    subHeading={'Announcement'}
                    backLink='/admin/announcement'
                />
            }
        </div>
    )
}
Announcement.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}
Announcement = connect()(withRouter(Announcement));
