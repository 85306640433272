import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import style from '../../core/Menu/menu.module.scss';
import DownArrowgi from '../../../static/images/down_arrowgi.gif';
export function Form({ children, className, onSubmit , formChanges=false,directChanges=false}) {
  const [show, setHandleShow] = useState(false);
    const handleChange = (data) => {
    if(formChanges&&!document.getElementById('modal_id')?.classList?.contains('opencheck')){
      setHandleShow(true)
    }
    }

    
    useEffect(() => {
      if(show||directChanges){
       toast.success(` ${'Scroll down and click save'}`, {
         //icon: `${DownArrowgi}`,
         icon: ({theme, type}) =>  <img className={`${style.down_arrowgif}`} src={`${DownArrowgi}`}/>,
         //icon: false,
         containerId: 'warning',
         toastId: 'success1',
         position: "bottom-right",
         autoClose: false,
         hideProgressBar: true,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: 0,
         className: `${style.toast_body}`,
         style: { background: '#36b37e',color:'#ffffff', opacity: '0 !important' },
         
        // pauseOnFocusLoss: false
         }); 
      }
     }, [show,directChanges])

  return (<form data-testid={"formId"} className={className} onSubmit={onSubmit} onChange={handleChange} onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}} autoComplete="nope">
          {children}
        </form>)
}