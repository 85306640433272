import React, { useState, useEffect, useRef } from 'react';
import styles from './assign.module.scss';
import deleteIcon from '../../../static/images/Close_Normal.svg';
import { SelectBox, CheckBox } from '../../primary';

export const MultiSelect = ({ id, index, placeholder, defaultValue, count, pIndex, getOptionValue,
    getGotoValue, goto, gotoOption, deleteAnswerOption }) => {
    const deleteOption = (index, pIndex) => {
        deleteAnswerOption(index, pIndex)
    }
    return (
        <>
            <div className={`col-7  ${styles.col_spec} ${styles.col_spec_img} fireEvent`} key={index}>
                <div className="col-1 col-form-label">
                    <span className={`${styles.pos_delete_form} delete`} onClick={() => deleteOption(index, pIndex)}>
                        <img src={deleteIcon} alt="" />
                    </span>
                </div>
                <div className={`col-5 ${styles.form_custom_check}`}>
                    <CheckBox
                        classes={{
                            column: `${styles.custom_check_bg} ${styles.custom_checkbox}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input}`,
                            labelClassName: `custom-control-label ${styles.custom_control_label} ${styles.custom_control_label_t0}`,
                            inputColumn: "custom-control custom-checkbox custom-control-inline mr-1"
                        }}
                        type={"checkbox"}
                        id={`check${index}`}
                        name={`check${index}`}
                    />
                    <input
                        className={`${styles.type_basic_input} ${styles.input_block} ${styles.hide_outline} singleSelectIOpt`}
                        type="text"
                        id={`check${index}`}
                        name={`check${index}`}
                        placeholder={placeholder}
                        autoComplete="off"
                        onBlur={(e) => getOptionValue(e.target.value, index, pIndex)}
                        defaultValue={defaultValue}
                    />
                </div> 
            </div>
            {goto ==="" || goto === undefined || goto === null ? <div className={`col-5 ${styles.col_spec}`}></div>:""}
            {goto !== "" && goto !== undefined && goto !== null && <SelectBox
                id={"Goto"}
                name={"Goto"}
                label={`Go to:`}
                classes={{
                    column: `col-3 ${styles.col_spec}`,
                    inputClassName: `${styles.type_basic_select} select_Goto`,
                    labelClassName: `col-4 col-form-label ${styles.type_label_spec}`,
                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`
                }}
                optionValue={gotoOption}
                defaultValue={goto}
                onChange={(e) => getGotoValue(e.target.value, pIndex, index)}
                customForm={true}
            />}
        </>
    )
}
