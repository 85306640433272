import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkListRecordsCount, setLastVal } from '../../../app/patientSlice';
import { VerticalTabWorkList } from '../../secondary/VerticalTabWorkList';
import { PIAssessmentsNONPOA } from './PIAssessmentsNONPOA';
import { PIAssessmentsPOA } from './PIAssessmentsPOA';
export function PIAssessment({facilityNumber, history }) {
  useEffect(() => {
    if(facilityNumber){
        dispatch(getWorkListRecordsCount(facilityNumber,'piAssessmentsValues'));
    }
   }, [facilityNumber])
  const workListRecordsCount = useSelector(state => state.patient.workListRecordsCount);
  const poaCount=workListRecordsCount?.piAssessmentsValues?.poaCount?`(${workListRecordsCount?.piAssessmentsValues?.poaCount})`:''
  const nonpoaCount=workListRecordsCount?.piAssessmentsValues?.nonpoaCount?`(${workListRecordsCount?.piAssessmentsValues?.nonpoaCount})`:''
 
  /* Tab list */
  const tabList = [
    {
      name: `POA ${poaCount}`,
      content: <PIAssessmentsPOA facilityNumber={facilityNumber}/>,
      bottomName: 'Pending Review'
    },
    {
      name: `Non-POA ${nonpoaCount}`,
      content: <PIAssessmentsNONPOA facilityNumber={facilityNumber} />,
      bottomName: 'Pending Review'
    },
  ];
  const dispatch = useDispatch();
  const lastVal = useSelector(state => state.patient.lastVal)
  const [defaultVerTab, setDefaultVerTab] = useState(lastVal?.selectVerTab?lastVal?.selectVerTab:0)
  const onSelectVerTab = (e) => {
    setDefaultVerTab(e)
     const setLast={...lastVal,selectVerTab: e }
     dispatch(setLastVal(setLast))
  
    }
  return (
    <>
      <VerticalTabWorkList onSelectInt={onSelectVerTab} defaultIndex={defaultVerTab<=1?defaultVerTab:0} tabList={tabList} dispatch={dispatch} />
    </>
  )
}
