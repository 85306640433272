import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  CONTENTURL, EXTRENAL_URL, GENERAL_ERROR, LIST_VIEW_ICON, LIST_VIEW_MENU_SEQUENCE_ID, SALES_OFFICE_OPTIONS, SAVE_BTN, SUBMIT_ID, UPLOAD_ICON,
  WEB_VIEW_URL, SEQUENCE_LBL, SUCCESS_STATUS, UPDATE_SUCCESS, UPDATE_FAILURE, ERROR_STATUS, VALID_EXISTING_SEQUENCE_NUM
} from '../../../constants';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { checkName, checkSalesOffice, contentUrl, sequenceNumber, getFacilityLabel, ServiceError, useDebounce} from '../../../utils';
import { Button, Form, RadioButton, SelectBox, TextField, Organizational } from '../../primary';
import rightArrow from '../../../static/images/right_arrow.svg';
import { PictureIcon } from '../../secondary';
import style from './editFilter.module.scss';
import { updateLayoutAsync, setLayoutEditStatus,getDocumentList, updateDocumentList,setDocumentList,getLayoutAsync,resetLayoutDetailsData,reSetFacilitiesData,setFacilitiesData,getCustomerNumberSuggestionsLayout,setExcludeNumberSearch,getAutoSeqNumber,setAutoSeqNumber } from '../../../app/layoutsSlice';
import{getCustomerNumberSuggestions, setCustomerNumberSearch } from '../../../app/userSlice'
import { useDispatch, useSelector } from 'react-redux';
import PlusIcon from '../../../static/images/plus-icon_white.svg';
import  {LayoutDocument}  from '../Layouts/layoutDocument';
import Avatar from '../../../static/images/empty_icon.svg';
import DocumentIcon from '../../../static/images/products/document_icon.svg';
import CloseIcon from '../../../static/images/products/close_icon.svg';
import { clearError } from "../../../app/appSlice";
import ReactTags from "react-tag-autocomplete";
import Loader from '../../../static/images/spinner.svg';
export const EditListViewMenu = ({ layoutRowData, layoutId }) => {
  const { register, handleSubmit, errors, reset, setValue, setError} = useForm({ mode: 'all' });
  const [previewImg, setPreviewImg] = useState(layoutRowData?.menuIcon);
  const [buttonState, setButtonState] = useState(true);
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  let [removeDefaultOrg, setRemoveDefaultOrg] = useState(true)
  const [createGroup, setCreateGroup] = useState(false);
  const [errorAssign, setErrorAssign] = useState(false);
  const [customerGroup, setCustomerGroup] = useState('');
  let [customerNumber, setCustomerNumber] = useState("");
  const [customerNumberInp, setCustomerNumberInp] = useState(false);
  const [textUrl,setUrlText] = useState('');
  const [isValidSaleCust, setIsValidSaleCust] = useState(false);
  const [isValidPdf,setIsValidPdf] = useState(false);
  const [updateSeq, setUpdateSeq] = useState('');
  const autoSeqNumber = useSelector(state => state.layouts.autoSeqNumber);
  useEffect(() => {
    setUpdateSeq(autoSeqNumber?.data?autoSeqNumber?.data:'')
    if(autoSeqNumber?.data&&document.getElementById('sequence')){
      document.getElementById('sequence').value=autoSeqNumber?.data
    }
  }, [autoSeqNumber])
  const editMode = () => {
    setUpdateSeq(layoutRowData && layoutRowData.sequence)
    setIsValidSaleCust(false);
    setIsValidPdf(false);
    setEditable(true);
    setAddFile(false);
    dispatch(setLayoutEditStatus({}))
    dispatch(setDocumentList({documents:layoutRowData &&Array.isArray(layoutRowData.pdfDocumentsInfo)?layoutRowData.pdfDocumentsInfo:[]}))
    setCustomerGroup(layoutRowData && layoutRowData.salesOffice)
    setUrlText(layoutRowData && layoutRowData.contentURL)
    setCustomerNumber(layoutRowData && layoutRowData.customerInfo)
    if(layoutRowData && layoutRowData.salesOffice){
      dispatch(setFacilitiesData({}))
      setCreateGroup(true);
      setShowExclude(true)
      setCustomerCodeSuggestions([])
      const getExcludeNumber=Array.isArray(layoutRowData.excludedCustomers)&&layoutRowData.excludedCustomers.length>0 ? layoutRowData.excludedCustomers:[];
      let editExcludeNumber=[]
      getExcludeNumber.map((excludeNumber,ind) =>{
        return editExcludeNumber.push({ id: excludeNumber, name: excludeNumber })
       })
      setCustomerCodeTag(editExcludeNumber)
    }
  
    if(customerNumber&&layoutRowData && layoutRowData.customerInfo!==customerNumber){
      setValue("filterSalesOffice", '');
      setRemoveDefaultOrg(true)
      dispatch(getLayoutAsync(layoutRowData && layoutRowData.customerInfo))
      //setRemoveDefaultOrg(true)
    } 
    else if(customerNumber===''&&layoutRowData && layoutRowData.customerInfo){
      setValue("filterSalesOffice", '');
      setRemoveDefaultOrg(true)
      dispatch(getLayoutAsync(layoutRowData && layoutRowData.customerInfo))
    } 
    
  }
  const updateStatus = useSelector(state => state.layouts.layoutEditStatus);
  let facilitiesDatas = useSelector(state => state.layouts.orgsData);
  let deatilLayout = useSelector(state => state.layouts.deatilLayout);
  const documentListItems = useSelector(state => state.layouts.documentList);
  let optionData = [];
    const soldToFacilities = facilitiesDatas?.data?.facilities?.filter((f) => f.accountGroup === '0001')
    soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
        const shipToFacilities = facilitiesDatas?.data?.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
        if (shipToFacilities.length === 0) {
            optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(soldToFacility),
                facilityNumber: soldToFacility.facilityNumber,
                soldToFacility: 'soldToFacility'
            })
        }
        shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
            optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(shipToFacility),
                facilityNumber: shipToFacility.facilityNumber,
                shipToFacilities: 'shipToFacilities'
            })
        })
    })
    const enterCustomerNumber = (customerNumber) => {
      const dynCustomerNumber=customerNumber
      if(document.getElementById('sequence')){
        document.getElementById('sequence').value = ""
      }
      dispatch(setAutoSeqNumber({}))
      if (dynCustomerNumber) {
        const payload = {sequenceNumber: "",layoutType:'listviewmenu',customerNumber:dynCustomerNumber}
        dispatch(getAutoSeqNumber({payload}))	
          setValue("filterSalesOffice", '');
          setOrg([])
          setRemoveDefaultOrg(false)
          dispatch(reSetFacilitiesData({})) 
          setCustomerNumber(dynCustomerNumber)
          setCustomerGroup('')
          dispatch(getLayoutAsync(dynCustomerNumber))
          setCreateGroup(false);
          setCustomerNumberInp(true)
        
          setIsValidSaleCust(false); 
          
      }
      if (dynCustomerNumber === "") {
          setOrg(null)
          setRemoveDefaultOrg(false)
          setCustomerNumberInp(false)
          setIsValidSaleCust(true);
          setCreateGroup(false);
          setCustomerNumber("")
          dispatch(reSetFacilitiesData({}))
          dispatch(setFacilitiesData({}))
          setCustomerGroup('')
      }
    }
    const validateSalesCust = () =>{

      let docLength = documentListItems?.documents && documentListItems?.documents?.length;

      if((customerNumber == '' && customerGroup=='') && (textUrl == '' && docLength==0)) {
        setIsValidSaleCust(true);
        setIsValidPdf(true);    
      }
      else  if(customerNumber == '' && customerGroup==''){
        setIsValidSaleCust(true);
      }
      else if(textUrl == '' && docLength==undefined){
        setIsValidPdf(true);   
      }
      else  {
        setIsValidSaleCust(false);
        setIsValidPdf(false);
    }
    }
    const doFilterEdit = (data) => {
      let soldToNumbers = [];
    org?.forEach((soldTo) => {
            const facilityNumber = soldTo?.cat?.split('|')[0]?.trim();
            if (!soldToNumbers.find(st => st.soldToNumber === facilityNumber)) {
                soldToNumbers.push({
                    "soldToNumber": facilityNumber,
                    "shipToNumbers": org?.filter(k => k.cat === soldTo.cat).map(shipTo => shipTo?.key?.split('|')[0]?.trim())
                });
            }
        })
    const urlTypeChk = (data.urlType === WEB_VIEW_URL ? 'internal' : 'external')
    let finalPic;
    if (previewImg) {
      finalPic = previewImg.replace('data:image/png;base64,', '')
      finalPic = finalPic.replace('data:image/jpeg;base64,', '')
      finalPic = finalPic.replace('data:image/jpg;base64,', '')
      finalPic = finalPic.replace('data:image/svg+xml;base64,', '')
    }
    const orgNumber= document.getElementById("customerNumber")?.value;

    /*const tempUserData = {
      customerGroup: customerGroup,
       organization:  {
         primaryGroupNumber: deatilLayout?.data?.facilities.find((f) => f.accountGroup === 'Z002').facilityNumber,
         soldToNumbers,
     } ,
     documents:documentListItems.documents
   }*/
   soldToNumbers=Array.isArray(soldToNumbers)&&soldToNumbers.length===0?null:soldToNumbers;
   const tempUserData = {
     customerNumber: orgNumber&&soldToNumbers? orgNumber : null,
     organization: orgNumber&&soldToNumbers? {
         primaryGroupNumber: facilitiesDatas?.data?.facilities.find((f) => f.accountGroup === 'Z002').facilityNumber,
         soldToNumbers
     } : null,

 }
 let finalCustomerCodeTag = [];
 finalCustomerCodeTag=Array.isArray(customerCodeTag)&&customerCodeTag?.map(
   (e) => {
     return e.id;
   }
 );
    const payload = {
  "id": layoutId,
  "name": data.name,
  "type": "listviewmenu",
  "presetLocation": "skinhealthhomepage",
  "salesOffice": customerGroup?customerGroup : "",
  "excludedCustomers":Array.isArray(finalCustomerCodeTag)&&finalCustomerCodeTag.length>0?finalCustomerCodeTag:null,
  "menuIcon":finalPic ? finalPic : data.picture || data.picture == "" ? "fruit.png" : finalPic,
  "contentURL": textUrl,
  "contentURLType": textUrl ? urlTypeChk : '' ,
  "sequence": parseInt(data.listSeque),
  "listViewMenus": [
    10
  ],
  "customerInfo": tempUserData,
  documents: 
  documentListItems.documents ? documentListItems.documents : ''
}
let docLength = documentListItems?.documents && documentListItems?.documents?.length;
if((customerNumber == '' && customerGroup=='') && (textUrl == '' && docLength==0)) {
  setIsValidSaleCust(true);
  setIsValidPdf(true);    
}
else  if(customerNumber == '' && customerGroup==''){
  setIsValidSaleCust(true);
}
else if(textUrl == '' && (docLength==undefined || docLength==0)){
  setIsValidPdf(true);   
}
else if(customerNumber !== ''&&tempUserData?.customerNumber==null&&customerGroup==''){
  setIsValidSaleCust(true);
}
else  {
  setIsValidSaleCust(false);
  setIsValidPdf(false);
  dispatch(updateLayoutAsync(payload))    
}
  
}
  const enterTextUrl = (e) =>{
    setUrlText(e.target.value)
    setIsValidPdf(false); 
   }
   const getOrganization = () => {
    const customerNum = document.getElementById("customerNumber").value;
    setOrg([])
    setRemoveDefaultOrg(false)
    dispatch(setFacilitiesData({}))
    dispatch(reSetFacilitiesData({}))
    setCustomerNumber(customerNum)
    if(customerNum){
      dispatch(getLayoutAsync(customerNum))
    } 
   
    setCreateGroup(false);
    setCustomerNumberInp(true)
    //setCustomerGroup(false) 
    setShowExclude(false)
  }
  const assignedFacilities = () => {
    const facilities = [];
    layoutRowData?.organizationDetailInfo?.soldToNumbers?.forEach((soldTo) => { //NOSONAR
        const shipToFacilities = facilitiesDatas?.data?.facilities?.filter((f) => soldTo.shipToNumbers?.includes(f.facilityNumber)) //NOSONAR
        shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
            facilities.push(<div className={`col-form-label ${style.content_spec}`}>
                {getFacilityLabel(shipToFacility)}
            </div>);
        });
    }) //NOSONAR
    return facilities;
}
const defaultFacilities = () => {
  const facilities = [];
  layoutRowData?.organizationDetailInfo?.soldToNumbers?.forEach((soldTo) => { //NOSONAR
      const soldToFacility = facilitiesDatas?.data?.facilities?.find((f) => f.facilityNumber === soldTo.soldToNumber) //NOSONAR
      let soldToFacilityLabel, shipToFacilityLabel;
      if (soldToFacility) {
          soldToFacilityLabel = getFacilityLabel(soldToFacility);
      }
      const shipToFacilities = facilitiesDatas?.data?.facilities?.filter((f) => soldTo.shipToNumbers.includes(f.facilityNumber)) //NOSONAR
      if (shipToFacilities && shipToFacilities.length > 0) {
          shipToFacilities.forEach((shipToFacility) => {
              shipToFacilityLabel = getFacilityLabel(shipToFacility);
              facilities.push({ cat: soldToFacilityLabel, key: shipToFacilityLabel });
          });
      }
  }) //NOSONAR
  return facilities;
}

  const viewMode = () => {
    setEditable(false);
    reset();
    dispatch(setDocumentList({}))
    setRemoveDefaultOrg(true)
    setOrg(defaultFacilities)
    if(customerNumber&&layoutRowData && layoutRowData.customerInfo!==customerNumber){
      dispatch(getLayoutAsync(layoutRowData && layoutRowData.customerInfo))
    } 
    else if(customerNumber===''&&layoutRowData && layoutRowData.customerInfo){
      dispatch(getLayoutAsync(layoutRowData && layoutRowData.customerInfo))
    } 
    
  }
  useEffect(() => {
    if (layoutRowData?.menuIcon) {
      setButtonState(false)
      setPreviewImg(layoutRowData?.menuIcon)
    }
    if(layoutRowData && layoutRowData.customerInfo){
      setCustomerNumber(layoutRowData && layoutRowData.customerInfo)
    }
  }, [layoutRowData])
  useEffect(() => {
    if (updateStatus === "success") {
      setEditable(false);
      reset();
      dispatch(setDocumentList({}))
      setTimeout(() => {
        dispatch(setLayoutEditStatus({}))
      }, 5000)
    } else if (updateStatus?.errorMessage === VALID_EXISTING_SEQUENCE_NUM) {
      setEditable(true);
    }
  }, [updateStatus]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(setLayoutEditStatus({}))
    }, 5000)
    dispatch(setExcludeNumberSearch({}))
    setCustomerCodeSuggestions([])
    setCustomerCodeTag([])
    setShowExclude(false)
  }, [dispatch])
  const [org, setOrg] = useState(defaultFacilities());
  /* CustomerNumber search Suggestions  */   
  const [custNumInput, setCustNumInput] = useState("");
  const [isSelect, setIsSelect] = useState(false);
  const searchTerm = useDebounce(custNumInput, 500);
 const [errorMsg, setErrorMsg] = useState("");
 const customerNumberSearchDatas = useSelector(
   (state) => state.user.customerNumberSearch
 );
 useEffect(() => {
   if (!isSelect && searchTerm && searchTerm.length >= 1) {
     dispatch(getCustomerNumberSuggestions(searchTerm));
   } else {
     dispatch(setCustomerNumberSearch({}));
   }
 }, [searchTerm]);
 
 const getCustomerNumberSearch = (event) => {  
   if(event === ''){
       setErrorMsg("please enter any customer number")
       setCustNumInput("")
   }else {    
      // setIsValidSaleCust(false)       
       setCustNumInput(event)
       setIsSelect(false);
       setErrorMsg('')                        
   }  
 };
 
 const ClearData = (value) => { 
   document.getElementById('customerNumber').value = null ;        
   setCustNumInput("");
   dispatch(reSetFacilitiesData({}))
 }
 
 const customerNumberSelect = (e, items) => {
  // e.stopPropagation();       
   setIsSelect(true);
   dispatch(setCustomerNumberSearch({}));                 
   if (items) {
     setErrorMsg('')          
     const customerNumber = items;
     setCustNumInput(customerNumber);
     document.getElementById('customerNumber').value = customerNumber;
     document.getElementById('customerNumber').value = items; 
     enterCustomerNumber(customerNumber)    
   }      
 }
  const onChangeOrg = (selectedList=[{}], selectedItem) => {
    setErrorAssign(false);
    setOrg([...selectedList]);
    setRemoveDefaultOrg(false)
  }
 // Initial GET call to get existing media
 function getDocs() {
  //dispatch(getDocumentList());
}
 useEffect(() => {
  getDocs();
  dispatch(setDocumentList({}))
}, [dispatch])

const errorMessage = ServiceError();
useEffect(() => {
  if (errorMessage==="This sequence number is assigned to another banner. Please use a different sequence number") {
    setError("listSeque", {
      type: "manual",
      message: errorMessage
    });
    dispatch(clearError());
  }
  if (errorMessage==="Layouts name already exist") { 
    setError("name", {
      type: "manual",
      message: errorMessage
    });
  
    dispatch(clearError());
  } 
}, [errorMessage,dispatch]);

const [addFile, setAddFile] = useState(false)

const handleAddFile = () => {

setAddFile(true)
setIsValidPdf(false);


}

const handleAddFileCancel = () => {
  setAddFile(false)
}
/* Trigger ad doc overlay close*/
const dropOverlay = useRef();

// Click event listener and calling handleClickOutside function if we click outside
useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
});
const handleClickOutside = (e) => {
    if (dropOverlay !== null && dropOverlay.current && !dropOverlay.current.contains(e.target)) {
        setAddFile(false);
    }
};
const handleDelete = (id) => {
  const filtered = documentListItems['documents'].filter((data, i) => i !== id)
  let list;
  if (filtered && filtered.length === 0) {
      list = {
          documents: []
      }
  } else if (filtered && filtered.length > 0) {
      list = {
          documents: [...filtered]
      }
  }
 dispatch(setDocumentList(list))
 
}
const getCustomerGroup = (e) => {
  if(document.getElementById('sequence')){
    document.getElementById('sequence').value = ""
  }
  dispatch(setAutoSeqNumber({}))
  if (e.target.value !== "") {
    const payload = {sequenceNumber: "",layoutType:'listviewmenu',groupType:e.target.value}
    dispatch(getAutoSeqNumber({payload}))	
      setCustomerGroup(e.target.value)
      setCreateGroup(true);
      //assignForm.current.resetSelectedValues();
      document.getElementById('customerNumber').value = ""
      setErrorAssign(false)
      setRemoveDefaultOrg(false)
      setIsValidSaleCust(false); 
      setShowExclude(true)
      setCustomerCodeSuggestions([])
      setCustomerCodeTag([])
  } else {
      setCustomerGroup("")
      setCreateGroup(false);
      // setIsValidSaleCust(true); 
      setShowExclude(false)
  }
}
useEffect(() => {
  if(documentListItems?.documents && documentListItems?.documents?.length > 0){
  setUrlText('')
  setValue("url",'');
  if(document?.getElementById('url')){
    document.getElementById('url').value=''
  }
  if(document?.querySelector('input[name="urlType"]')){ 
    document.querySelector('input[name="urlType"]').checked=false
  }

  setAddFile(false);  
  }
  if(documentListItems?.documents && documentListItems?.documents?.length === 0){
    if(document?.getElementById('contentUrl0')&&layoutRowData?.contentURLType === ""){
      document.getElementById('contentUrl0').checked=true
    }
  }
  }, [documentListItems])

    /* exclude options */
const excludeNumberSearch = useSelector(state => state.layouts.excludeNumberSearch);

const customerCodeTagsRef = useRef();   
const [customerCodeTag, setCustomerCodeTag] = useState([]);

const [customerCodeSuggestions, setCustomerCodeSuggestions] = useState([]);

const [suggestionsBusy, setSuggestionsBusy] = useState(false);

const [showExclude, setShowExclude] = useState(false);

const onDelete = useCallback(
(tagIndex) => {
 
const finalTag=customerCodeTag.filter((_, i) => i !== tagIndex);
setCustomerCodeTag(finalTag); 

},
[customerCodeTag]
);

const onAddition = useCallback(
(newTag) => {
 const ids = customerCodeTag.map(({ id }) => id);
 if (!ids.includes(newTag.id)) {
   const finalTag=[...customerCodeTag, newTag];
   setCustomerCodeTag(finalTag);
   }
},
[customerCodeTag]
);

//onInput
const onInputExclude  = useCallback(
  (query) => {
    if(!suggestionsBusy&&query&&customerGroup){
      setSuggestionsBusy(true)
      dispatch(getCustomerNumberSuggestionsLayout(query,setSuggestionsBusy,customerGroup))
    }
  },
  [customerCodeTag]
  );

  useEffect(() => {
    if(Array.isArray(excludeNumberSearch)){
      const getExcludeNumber=[]
      excludeNumberSearch.map((excludeNumber,ind) =>{//NOSONAR
        return getExcludeNumber.push({ id: excludeNumber, name: excludeNumber })
       })//NOSONAR
       setCustomerCodeSuggestions(getExcludeNumber)
    }else{
      setCustomerCodeSuggestions([])
    }

    
    }, [excludeNumberSearch])

    const [tinyDelay, setTinyDelay] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    }, [])

  return <div>
    <div className={style.tab_pane}>
      <div className={`d-flex justify-content-between ${style.container_wrapper}`}>
        <div className={style.heading}>List View Menu <span className={style.subheading}> | Skin Health Home Page</span></div> 
        {tinyDelay&&!editable &&<div id="LVMEdit" className={style.edit_icon} onClick={() => editMode()}><img src={editIcon} alt="icon" /> Edit</div>
        }
      </div>
      {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
      <>
      {
        !editable ? <div className="col-12 p-4">
          {updateStatus === SUCCESS_STATUS && <div className={`alert alert-success d-inline ${style.form_alert}`}>{UPDATE_SUCCESS}</div>}
          {updateStatus === ERROR_STATUS && <div className={`alert alert-danger ${style.form_alert}`}>{UPDATE_FAILURE}</div>}
          <div className={`row`}>
            <div className={`col-6`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Name:</label>
                <div className={`col-6 ${style.data_color}`}>
                  {
                    layoutRowData && layoutRowData.name
                  }
                </div>
              </div>
            </div>
            <div className={`col-6`}>
            
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Sales Office:</label>
                <div className={`col-6 ${style.data_color}`}>
                  {
                    layoutRowData?.salesOffice ? SALES_OFFICE_OPTIONS.map(e => e.key == layoutRowData.salesOffice && e.value)  : '-'
                  }
                  {/* {
                    layoutRowData && Array.isArray(SALES_OFFICE_OPTIONS) && SALES_OFFICE_OPTIONS.map(e => e.key == layoutRowData.salesOffice && e.value)
                  } */}
                </div>
              </div>
            </div>
            <div className={`col-6 mt-3`}>
              <div className={`row`}>
              <label className={`col-3 pt-4 ${style.label_cutom}`} >Icon:</label>
                <div className={`col-6 ${style.menu_icon}`}>
                  {
                     layoutRowData?.menuIcon ? <img src={layoutRowData?.menuIcon} alt="icon" className={style.avatar_icon} /> : <img src={Avatar} alt="icon" />
                  }
                </div>
              </div>
            </div>
              <div className={`col-6 mt-5`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Exclude:</label>
                <div className={`col-6 ${style.data_color}`}>
                {
                      layoutRowData && Array.isArray(layoutRowData.excludedCustomers)&&layoutRowData.excludedCustomers.length>0 ? layoutRowData.excludedCustomers.join(', '): '-'
                    }
                </div>
              </div>
            </div>
           
         <div className={`col-6 mt-5`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >PDF File:</label>
                <div className={`col-5 ${style.data_color} ${style.media_view} ml-2`} >
                <ul>
                   
                    {layoutRowData &&Array.isArray(layoutRowData.pdfDocumentsInfo)&& layoutRowData.pdfDocumentsInfo.length > 0 && layoutRowData.pdfDocumentsInfo.map((data, i) => {
                        return <li key={i} >
                          <img src={DocumentIcon} alt="image_icon" ></img>
                            <a href={data.documentUrl} target="_blank" rel="noopener noreferrer" title={`${data?.fileName?data.fileName:''}`}>{data.fileName}</a>
                        </li>
                    })}
                    {layoutRowData &&Array.isArray(layoutRowData.pdfDocumentsInfo)&& layoutRowData.pdfDocumentsInfo.length === 0&&
                         <li >-</li>
                    } 
                </ul>
                  
                </div>
              </div>
            </div>
          
            
            <div className={`col-6 mt-5`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Customer Number:</label>
                <div className={`col-6 ${style.data_color}`}>
                  {
                    layoutRowData && layoutRowData.customerInfo ? layoutRowData.customerInfo : '-'
                  }
                </div>
              </div>
            </div>
          </div>
         <div className={`row mt-4`}>
         <div className={`col-6 mt-5`}></div>
            <div className={`col-6 mt-3`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Organization:</label>
                 <div className={`${style.org_view}`}>
                    {assignedFacilities().length > 0 ? assignedFacilities() : '-'}
                 </div>
              </div>
            </div>
          </div>
         {/* added */}
       
         <div className={`row mt-3`}>
            <label className={`col-3 pt-2 ${style.label_cutom}`} >Content URL:</label>
            <div className={`col-5 pt-1`}>
              {
                layoutRowData && layoutRowData.contentURL ? layoutRowData.contentURL : '-'
              }
              </div>
              </div>
          
          <div className={`row mt-0`}>
            <div className={`col-6 `}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom} ${style.label_seq}`} >Sequence:</label>
                <div className={`col-6 ${style.data_color} ${style.label_seq}`}>
                  {
                    layoutRowData && layoutRowData.sequence
                  }
                </div>
              </div>
            </div>
          </div>
        </div> :
          <div className="col-12 p-4">
            <Form formChanges={true} onSubmit={handleSubmit(doFilterEdit)}>
              {/* {Object.keys(errors).length !== 0 && <div className="col-12 pl-0"><span className={style.general_error}>{GENERAL_ERROR}</span></div>} */}
              <div className={`row`}>
                <div className={`col-6`}>
                  <TextField
                    id={'menuName'}
                    classes={{
                      column: `row mb-4`,
                      labelClass: `col-6 ${style.label_cutom}`,
                      inputColumn: 'col-6',
                      inputClassName: `${style.input_field}`
                    }}
                    label={'Name:'}
                    type={"text"}
                    name={'name'}
                    defaultValue={layoutRowData && layoutRowData.name}
                    validator={register(checkName)}
                    required={true}
                    errors={errors}
                  />
                </div>
                <div className={`col-6`}>
                
                  <SelectBox
                    id={'salesOffice'}
                    name={'filterSalesOffice'}
                    label={'Sales Office:'}
                    classes={{
                      column: `row ${style.col_spec}`,
                      inputClassName: `${style.input_field} ${style.first_child}`,
                      labelClassName: `col-5 col-form-label mt-0 ${style.label_cutom}`,
                      inputColumn: `col-6 p-0 `
                    }}
                    validator={register()}
                    placeholder="Select"
                    optionValue={SALES_OFFICE_OPTIONS}
                    errors={errors}
                    onChange={(e) => getCustomerGroup(e)}
                    defaultValue={layoutRowData && layoutRowData.salesOffice}
                    disabled={document.getElementById("customerNumber")?.value ? true : false}
                    required={true}
                  />

            {showExclude&&<div className={`row mt-3`}>
              
                              <div className="col-5">
                                <label className={`mb-0 ${style.label_cutom}`} >Exclude :</label>
                              </div>
                              <div className={`col-5 p-0`}>
                              <ReactTags
                                    ref={customerCodeTagsRef}
                                    tags={customerCodeTag}
                                    suggestions={customerCodeSuggestions}
                                    onDelete={onDelete}
                                    onAddition={onAddition}
                                    onInput={onInputExclude}
                                    classNames={{ root: `${style.root_tag}` }}
                                    placeholderText={"Customer Number"}
                                    allowBackspace={false}
                                    readOnly={false}
                                    maxSuggestionsLength ={10}
                                    minQueryLength ={1}
                                    />
                                    {excludeNumberSearch===null&&Array.isArray(customerCodeSuggestions)&&<><p>No data found</p></>}
                                </div>
                              </div>}  
                              

                </div>
                <div className={`col-6 mt-4`}>
                  <div className={`row mt-1`}>
                    <label className={`col-6 ${style.label_cutom}`} >Icon:</label>
                    <div className={`col-6 ml-0 mt-0 `}>
                    <PictureIcon
                      classes={{ pic_wrapper: '', contr_wrapper: `mt-0 ${style.icon_align} ${style.pic_cust} ml-0` }}
                      previewImg={previewImg}
                      setPreviewImg={setPreviewImg}
                      buttonState={buttonState}
                      setButtonState={setButtonState}
                      id={LIST_VIEW_ICON}
                      name={"menuIcon"}
                      validator={register}
                      browseLbl={UPLOAD_ICON}
                      listViewMenu={true}
                    />
                    </div>
                  </div>
                </div>
                <div className={`col-6 mt-0`}>

                <div className={`col-3 col-form-label mt-0 ${style.label_cutom} ${style.label_OR}`} ><strong>OR</strong></div>
                <div className={`row col-12 pt-1 ml-n0 mb-4 ${style.input_icons}`}>  
                <TextField
                  id={"customerNumber"}
                  name={"customerNumber"}
                  label={"Customer Number:"}
                  classes={{
                    column: `row ${style.col_spec}`,
                      inputClassName: `${style.input_field} ${style.first_child} ${createGroup ? style.disable_field : ''}`,
                      labelClass: `col-5 col-form-label mt-0 ${style.label_cutom}`,
                      inputColumn: `col-6 p-0 ml-1`
                  }}
                  type="text"
                  // validator={register()}
                 // errors={errors}
                 customError={createGroup ? '' : errorMsg}
                 // onKeyUp={(e) => enterCustomerNumber(e)}
                 onChange={(e)=>getCustomerNumberSearch(e.target.value)} 
                  disabled={createGroup ? true : false}
                  defaultValue={layoutRowData && layoutRowData.customerInfo}
                  required={true}
                />
                {customerNumberSearchDatas &&
                        Array.isArray(customerNumberSearchDatas) &&
                        customerNumberSearchDatas.length > 0 && (
                            <div className="col-12 col-spec row">
                             <div className={`col_spec mr-auto ${style.search_drop}`}>
                                <div className={`col-spec ${style.search_close_box}`}>
                                {customerNumberSearchDatas.map(
                                    (items, index) =>
                                    items && (
                                        <p
                                        className={`dropdown-item`}
                                        title={items.value}
                                        onClick={(e) => customerNumberSelect(e, items)}
                                        >
                                        {items}
                                        </p>
                                    )
                                )}
                                </div>
                            </div>
                            </div>
                        )}                  
                    <span 
                        id="clear_icon"
                        className={`${style.x_icon}`}
                        onClick={(e)=>ClearData(
                            document.getElementById('customerNumber').value
                          )}                          
                        >
                        <i className="fa fa-times" style={{color : "#000"}} aria-hidden="true"></i>
                    </span>
                {/* <span className={`col-3 col-form-label ${style.form_span} ml-3`} onClick={() => getOrganization()}>
                                    Search <img src={rightArrow} alt="" className={`${style.img_left}`}  />
                </span> */}
                </div>
                <br /> 
              <div className={`col-12`}>
               
                {<Organizational 
                                id={"organization"}
                                name={"organization"}
                                label={`Organization:`}
                                classes={{
                                    column: `row ${style.col_spec}`,
                                    inputClassName: `${style.input_field} ${style.first_child}`,
                                    labelClassName: `col-5 col-form-label ${style.label_cutom} onselect  p-0`,
                                    inputColumn: `col-6 p-0  ${style.org_input} `
                                }}
                                optionValue={optionData}
                                groupBy="cat"
                                displayValue="key"
                               // defaultValue={removeDefaultOrg ? defaultFacilities() : ''}
                                defaultValue={removeDefaultOrg ? defaultFacilities() :Array.isArray(org) ? org : []}
                                onSelect={onChangeOrg}
                                onRemove={onChangeOrg}
                                 assignPage={true}
                                disabled={createGroup ? true : false}
                                required={true}
                                selectAll={true}  
                            />}
              
              { isValidSaleCust&&<span className={`${style.error_message} ${style.error_CustNum} text-center`}>{
             'Please select customer group selection or customer number and organization' }
               </span> }
              </div>
               </div>
              </div> 
              <div className={`row `}>
            <div className={`col-6 ${style.pdf_align}`}>
            <div className="row">
            
                <div className="col-6 ">
                  <label className={style.label_cutom}>PDF File: <span className={style.errStar}>&#42;</span></label>
                </div>
                <div className="col-5 ml-3">
                  
                    
               { documentListItems?.documents?.length > 0 ? '' : <Button
                            classes={{ className: ` ml-n3 btn ${style.btn_custom} ${style.btn_small} ${style.btn_position} ${textUrl ? style.disabled : ''}` }}
                            type="button"
                            id={SUBMIT_ID}
                            onClick={handleAddFile}
                        >
                            <img src={PlusIcon} alt="edit" /> Add File
                        </Button> }
                       
                    <div className={`${style.media_view}`}>
                    
                <ul >
                    {documentListItems?.documents && documentListItems?.documents?.length > 0 && documentListItems.documents.map((data, i) => {
                        return <li key={i} className={`${style.pdfList_view}`}>
                            <img src={DocumentIcon} alt="image_icon"></img>
                            <a href={data.documentUrl} target="_blank" rel="noopener noreferrer" title={`${data?.fileName?data.fileName:''}`}>{data.fileName}</a>
                            <span onClick={() => handleDelete(i)}><img src={CloseIcon} alt="close" /></span>
                        </li>
                    })}
                </ul>
                
            </div><br/>
            <div className={`${style.Or_Align}`} ><strong>OR</strong></div>
            </div>
            {addFile && 
                        <div className={`dropdown-menu show ${style.media_align}`} aria-labelledby="dropdownMenu3">
                        <LayoutDocument
                            handleAddFileCancel={handleAddFileCancel}
                         
                            documentListItems={documentListItems}
                            getDocs={getDocs}
                         
                         />
                   </div> 
                    } 
            </div>
            </div>
          </div>
                <div className={`row mt-0`}>
                
                <div className="col-3 mt-4 ">
                
                  <label className={style.label_cutom}>Content URL: <span className={style.errStar}>&#42;</span></label>
                </div>
                <div className="col-9 pr-0">
                  <div className="row">
                    <div className="col-12">
                      <RadioButton
                        id={CONTENTURL}
                        name={"urlType"}
                        classes={{
                          labelClass: `custom-control-label mb-2 ${style.custom_control_label}`,
                          inputClassName: `custom-control-input ${style.custom_control_input}`,
                          inputColumn: "custom-control custom-radio custom-control-inline"
                        }}
                        radioOption={[EXTRENAL_URL, WEB_VIEW_URL]}
                        defaultValue={documentListItems?.documents?.length > 0?'':layoutRowData?.contentURLType === "external" ? EXTRENAL_URL : WEB_VIEW_URL}
                         validator={register}
                         disabled={documentListItems?.documents?.length > 0?true:false}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <TextField
                        classes={{
                          column: `row mb-4`,
                          labelClass: `col-4 ${style.label_spec}`,
                          inputColumn: 'col-10',
                          inputClassName: `w-100 ${style.input_field} ${documentListItems?.documents?.length > 0 ? style.disable_field : ''}`
                        }}
                        id={'url'}
                        type={"text"}
                        name={'Url'}
                        errors={errors}
                        validator={register()}
                        defaultValue={textUrl}
                        onKeyUp={(e) => enterTextUrl(e)}
                        disabled={documentListItems?.documents?.length > 0 ? true: false}
                        required={true}
                      />
                    </div>
                    { isValidPdf && <span className={`${style.error_message} ${style.error_PDF} text-right`}>{
             'Please select PDF file or content url' }
               </span> }
                  </div>
                </div>
              </div>
            
              <div className={`col-6 mt-3`}>
                <TextField
                  classes={{
                    column: `row`,
                    labelClass: `col-6 p-0 ${style.label_cutom}`,
                    inputColumn: 'col-6 pl-2',
                    inputClassName: `${style.small_input} ${style.input_field}`
                  }}
                  id={LIST_VIEW_MENU_SEQUENCE_ID}
                  label={SEQUENCE_LBL}
                  type={"number"}
                  name={'listSeque'}
                  validator={register(sequenceNumber)}
                  required={true}
                  errors={errors}
                  defaultValue={updateSeq}
                  // customError={updateStatus && updateStatus.errorMessage?updateStatus.errorMessage: ""}
                />
              </div>
              <div className={`${style.btn_container} mt-4`}>
                <Button
                  id={"creat_list_view_menu"}
                  type={SUBMIT_ID}
                  value={SAVE_BTN}
                  classes={{ className: `btn mb-1 ${style.btn_save}` }}
                  onClick={() => validateSalesCust()}
                />
                <Button
                  id={'LVM_Cancel'}
                  type={"button"}
                  value={'Cancel'}
                  classes={{ className: `btn mb-1 ${style.btn_cancel}` }}
                  onClick={() => viewMode()}
                />
              </div>
            </Form>
          </div>
      }</>}
    </div>
  </div>
}

EditListViewMenu.propTypes = {
  layoutRowData: PropTypes.any,
}
