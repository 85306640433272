import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import 'react-vis/dist/style.css';
import logo from '../../../static/images/skin-health-logo.png';
import Loader from '../../../static/images/spinner.svg';
import { handleClickGenerateWoundReportPDF } from '../../../utils/woundReportHelper';
import { Button } from '../../primary';
import { Header } from '../../secondary/Header';
import styles from "./woundReportPdf.module.scss";

export function WoundReportPdf({ history }) {

    // For scrolling the page to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Getting loader 
    const loading = useSelector(state => state.app.loading);

    // States
    const [canvases, setCanvases] = useState([])
    const [header, setHeader] = useState({})
    const [started, setStarted] = useState(false) // To avoid multiple html2canvas promises
    const [pdfGeneration, setPdfGeneration] = useState(false) // State for end of html2canvas process
    const [message, setMessage] = useState(false)


    const overViewData = useSelector(state => state.woundreport.overViewData);
    const customerName = useSelector(state => state.user.customerName);


    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                setMessage(true)
            }, 10000)
        }
    }, [loading])

    // Getting header, finding the size, converting the code and pushing it into a state
    useEffect(() => {
        async function run() {
            const header = document.getElementById('header')
            const imageWidth = 210;
            await html2canvas(header).then(function (canvas) {
                setHeader({ height: canvas.height * imageWidth / canvas.width, imgData: canvas.toDataURL('image/png') })
            })
        }
        // We are starting above canvasing process after loading the html file with a delay
        if (!loading) {
            if (!started) {
                setTimeout(() => {
                    run();
                }, 5000)
                setStarted(true)
            }
            else {
                return
            }
        }
    }, [loading])


    // Getting all the elements with the tag name 'article'. Capturing screenshot, finding height and converting it into base code.
    // Pushing all those into an array of object
    useEffect(() => {
        async function run() {
            const allDivs = document.getElementsByTagName('article')
            const divsArray = Array.from(allDivs)
            const can = []
            const imageWidth = 210;
            // Creating screenshot for each section
            for (let i = 0; i < divsArray.length; i++) {
                await html2canvas(divsArray[i], { allowTaint: true, useCORS: true }).then(function (canvas) {
                    can.push({ id: i, height: canvas.height * imageWidth / canvas.width, imgData: canvas.toDataURL('image/png') })
                    setCanvases([...can])
                }).finally(() => {
                    if (i === divsArray.length - 1) {
                        setPdfGeneration(true)
                    }
                })
            }
        }
        // We are starting above canvasing process after loading the html file with a delay
        if (!loading) {
            if (!started) {
                setTimeout(() => {
                    run();
                }, 5000)
                setStarted(true)
            }
            else {
                return
            }
        }
    }, [loading])





    // On click, generating pdf by putting all the above images into a document. We are using jsPDF to generate pdf.
    // If we can't fit image into same page, we are moving the image into next page. It is done automatically.
    const handleClick = () => {
        handleClickGenerateWoundReportPDF(html2canvas, jsPDF, canvases, header, customerName);
    }

    return (
        <>
            <div className={`${styles.back_btn}`}>
                <Header backLink={'patients'} handleBack={() => history.goBack()} />
            </div>

            {!pdfGeneration && <div className={styles.info_message}>
                {<>
                    {<h3>Creating your PDF report... <span>This won't take long</span></h3>}

                </>}
            </div>}

            {!loading && <div className={styles.info_message}>
                {pdfGeneration && <>
                    <Button
                        classes={{ className: `btn btn-small ${styles.btn_small} ${styles.btn_custom}` }}
                        type="submit"
                        id="next"
                        onClick={handleClick}
                    >
                        Download PDF
                    </Button>
                </>}
            </div>}

            {!loading && <div id="pdf" className={styles.content}>

                <header id="header" className={`min ${styles.header}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-8 p-0">
                                <h1>Wounds Overview</h1>
                            </div>
                            <div className="col-4 p-0 text-right">
                                <img src={logo} alt="test" />
                            </div>
                        </div>
                    </div>
                </header>

                <article>
                    <div className={styles.overview_btm}>
                        {/* <p><label>Patients with wounds (active & resolved) / Patients with active wounds</label><span>{overViewData?.patientCount?overViewData?.patientCount+' / ':''}{overViewData?.patientWithWound?.count}</span></p> */}
                        <p><label>Patients with active wounds</label><span>{overViewData?.patientWithWound?.count}</span></p>
                        <p><label>Active wounds that are not resolved</label><span>{overViewData?.activeWound}</span></p>
                        <p><label>Average number of wounds per patient</label><span>{overViewData?.avgWoundPerPatient}</span></p>
                        <p><label>Average number of assessments per wound</label><span>{overViewData?.avgAssessmentPerWound}</span></p>
                        <p><label>Healing rate</label><span>{overViewData?.healingRate}%</span></p>
                    </div>
                </article>

                <article>
                    <div className={`${styles.overview_score} pr-0 pl-0`}>
                        <div className={`${styles.overview_percentage} `}>
                            <label className={styles.dash_top}>Patients with Wounds</label>
                            <p className={styles.dash_btm}>
                            <span>{overViewData?.patientWithWound?.count}</span>
                                <span>{overViewData?.patientWithWound?.percent}</span>
                            </p>
                        </div>
                        <div className={`${styles.overview_percentage} `}>
                            <label className={styles.dash_top}>Pressure Injury
                                <span className={styles.dash_poa}>POA</span>
                            </label>

                            <p className={styles.dash_btm}>
                                <span>{overViewData?.piPOA?.count}</span>
                                <span>{overViewData?.piPOA?.percent}</span>
                            </p>
                        </div>
                        <div className={`${styles.overview_percentage} `}>
                            <label className={styles.dash_top}>Pressure Injury
                                <span className={styles.dash_nonpoa}>Non-POA</span>
                                <span className={styles.dash_stage}>(Stage 1, 2, DTPI, MMPI)</span>
                            </label>

                            <p className={styles.dash_btm}>
                                <span>{overViewData?.piNonPOAStageMMPI?.count}</span>
                                <span>{overViewData?.piNonPOAStageMMPI?.percent}</span>
                            </p>
                        </div>
                        <div className={`${styles.overview_percentage} `} >
                            <label className={styles.dash_top}>Pressure Injury
                                <span className={`${styles.dash_nonpoa} ${styles.dash_color}`}>Non-POA</span>
                                <span className={`${styles.dash_stage} ${styles.dash_color}`}>(Stage 3, 4, Unstageable)</span>
                            </label>

                            <p className={styles.dash_btm}>
                                <span>{overViewData?.other?.count}</span>
                                <span>{overViewData?.other?.percent}</span>
                            </p>
                        </div>
                        <div className={`${styles.overview_percentage} `}>
                            <label class={styles.dash_top}>Others</label>

                            <p class={styles.dash_btm}>
                                <span>{overViewData?.patientWithWound?.count}</span>
                                <span>{overViewData?.patientWithWound?.percent}</span>
                            </p>
                        </div>
                    </div>
                </article>




            </div>}
        </>
    )
}

WoundReportPdf = connect()(withRouter(WoundReportPdf));