import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditFilter, EditListViewMenu, EditCrad, EditMarketingBannerView } from '../Layouts';
import { withRouter, useParams } from 'react-router-dom';
import { layoutDetailsAsync } from '../../../app/layoutsSlice';
import { connect, useSelector } from 'react-redux';

export let LayoutsDetail = ({ dispatch,history }) => {

  const { layoutId } = useParams();
  const layoutData = useSelector(state => state.layouts.deatilLayout);
  useEffect(() => {
    if (layoutId) {
      dispatch(layoutDetailsAsync(layoutId,history))
    }
  }, [layoutId, dispatch]);
  const detailView = {
    'listviewmenu': <EditListViewMenu layoutRowData={layoutData} layoutId={layoutId} />,
    'filter': <EditFilter layoutRowData={layoutData} layoutId={layoutId} />,
    'card': <EditCrad layoutRowData={layoutData} layoutId={layoutId} />,
    'marketingbanner': <EditMarketingBannerView layoutRowData={layoutData} layoutId={layoutId} />,
  }
  return <div>
    {detailView && detailView[layoutData.type]}
  </div>
}

LayoutsDetail.propTypes = {
  layoutRowData: PropTypes.any,
}

LayoutsDetail = connect()(withRouter(LayoutsDetail));
