import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { DataTable, Pagination } from '../../secondary';
import {
    getCallBackAssignedFormularyListAsync,
    resetCustomerAssignedFormulary,
} from '../../../app/customerSlice';
import { LIST_VIEW_LIMIT, ASSIGNED_FORMULARY } from '../../../constants';
import { dateOfBirthDate, List, SelectedRow, Sorting, Pagination as assignedPagination } from '../../../utils';
import styles from './formularyAssign.module.scss'
import { TextField, SelectBox } from '../../primary'
import editIcon from '../../../static/images/edit-icon.png'
import duplicateIcon from '../../../static/images/duplicate.svg'
import searchIcon from '../../../static/images/search-icon.png';
import CreateFormulary from '../Formulary/createFormulary';
import { duplicateAsync, getFormularyListByIdAsync, formularyOptionsAsync, setNewPayload, setMaterials } from '../../../app/formularySlice';

export let CustomerFormularyAssignedList = ({ dispatch, history, externalAdmin, formularyId, createView, customerId, adminView}) => {

    const formId = useParams()

    // Edit mode

    const [editMode, setEditMode] = useState(false)
     /* External Admin Formulary */
     const [searchText,setSearchText] = useState(""); 
    // const [editModeExternal, setEditModeExternal] = useState(false)// from added for external formulary
    const [searchKey, setSearchKey] = useState('')
    const [searchColumns,setSearchColumns] = useState(["All"]);//upto

    const [duplicate, setDuplicate] = useState(false)

    const handleEdit = () => {
        setEditMode(true)
        history.push('/admin/formulary/' + formularyId,{'dyList':{sortType:sortType,activePage:activePage,offSet: isNaN(offSet)===true?0:offSet},'refPath':`admin/customers/${customerId}`, 'cefPath':'editMode'})
        // setEditModeExternal(true)
    }

    const handleEditExternal = () => { //External Formulary
        setEditMode(true)
        // setEditModeExternal(true)
        // if(!editModeExternal){
        //     const payload = {
        //         id: formularyId,
        //         setEditModeExternal: setEditModeExternal
        //     }
        //     dispatch(duplicateAsync(payload))
        // }
        
    }

    const handleCancel = () => {
        setEditMode(false)
        // setEditModeExternal(false)
    }

    // const handleDuplicate = () => {
    //     const payload = {
    //         id: formularyId,
    //         setDuplicate: setDuplicate,
    //         setEditMode: setEditMode
    //     }
    //     dispatch(duplicateAsync(payload))
    // }

    const urlId = formularyId;

    const FORMULARYREQURL = ASSIGNED_FORMULARY + '/' + urlId
    const userDetails = useSelector(state => state.user);
    let customerNumber = 0;
    if (userDetails) {
        customerNumber = userDetails.customerNumber
    }

    const assignedFormularyList = useSelector(state => state.customer.customerAssignedFormulary);
    
    /* Tabs object */
    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);
    /* Get AssignedFormularyList Details */

    const getAssignedFormularyList = () => {
        List(FORMULARYREQURL, 'Get', LIST_VIEW_LIMIT, 0, dispatch, getCallBackAssignedFormularyListAsync);
    }



    useEffect(() => {  //External Formulary
        dispatch(getFormularyListByIdAsync(formularyId,history))
    }, [formularyId, editMode])


    useEffect(() => {
        // We are sending '71' as default value
        const payload = {
            division: "71",
            productCategoryDesc: null,
            productCategoryCode: null,
            productFamilyDesc: null,
            productFamilyCode: null
        }
        const queryParam = { limit: 80000 }
        // We are setting above payload as initial default data.
        dispatch(setNewPayload(payload))
        // We are getting materials and dropdowns based on above payload initially
        dispatch(formularyOptionsAsync(payload, queryParam))
    }, [dispatch])

    const handleSearchColumn = (event,data) => {
       
        let makeSearch= (event&&event.target&&event.target.value)?event.target.value:''
        setSearchColumns(makeSearch)
        if(makeSearch){
            makeSearch= makeSearch+':'+searchKey;
          }
                let searchPayLoad = {};
                searchPayLoad['url'] = FORMULARYREQURL
                searchPayLoad['method'] = 'get'
                searchPayLoad['queryParam'] = { limit: 50, offSet: isNaN(offSet)===true?0:offSet, search:makeSearch}
                if(sortType){
                  searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{sort:sortType}} ;
                }
               
                dispatch(getCallBackAssignedFormularyListAsync(searchPayLoad));
              
    }

    const getAssignedFormularyListSearch = (event) => {
        setSearchKey(event.target.value)
        let makeSearch= (event&&event.target&&event.target.value)?event.target.value:''
        if(searchColumns){
            makeSearch= searchColumns+':'+makeSearch;
        }
            let searchPayLoad = {};
            searchPayLoad['url'] = FORMULARYREQURL
            searchPayLoad['method'] = 'get'
            searchPayLoad['queryParam'] = { limit: 50, offSet: isNaN(offSet)===true?0:offSet, search:makeSearch}
            if(sortType){
                searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{sort:sortType}} ;
            }
        
        dispatch(getCallBackAssignedFormularyListAsync(searchPayLoad));
    }


    const addedApiMaterials = []

    useEffect(() => {
        dispatch(resetCustomerAssignedFormulary())
        getAssignedFormularyList();
    }, []);



    //  Setting fixed column
     const showFixedColumn = assignedFormularyList && !!assignedFormularyList.data && Array.isArray(assignedFormularyList.data.materials) && !!assignedFormularyList.data.materials.length

   
    const columns = [      
        {
            Header: 'Division ',
            accessor: 'division',
            restWidth: '200px',
        },
        {
            Header: 'Product Category Description',
            accessor: 'productCategoryDesc',
            restWidth: '300px',
          
        },
        {
            Header: 'Material #',
            accessor: 'exactMaterialNumber',
            restWidth: '150px',
        },
        {
            Header: 'Material Description',
            accessor: 'materialName',
            restWidth: '300px'
        }
    ];

   
    const columnsExternal = [
        {
            Header: 'Division',
            accessor: 'division',
            fixedColumn: showFixedColumn
        },
        {
            Header: 'Product Category Description',
            accessor: 'productCategoryDesc',
            fixedColumn: showFixedColumn,
            fixedWidth: '240px'
        },
        {
            Header: 'Material #',
            accessor: 'exactMaterialNumber',
            fixedColumn: showFixedColumn,
            restWidth:'200px'
        },
        {
            Header: 'Material Description',
            accessor: 'materialName',
            restWidth:'220px'
        },
        {
            Header: 'Product Family Code',
            accessor: 'productFamilyCode',
            restWidth:'170px'
        },
        {
            Header: 'Product Family Description',
            accessor: 'productFamilyDesc',
            restWidth:'300px'
        }
    ];



    const [detailsRowData, setDetailsRowData] = useState([])
    const getSelectedRowData = (data) => {
        // SelectedRow(data, setDetailsRowData, 'customerAssignedFormulary', history, isAdminView);
    }
    // pagination
    const [activePage, setActivePage] = useState(1);
    const [offSet, setOffSet] = useState(0)
    const [sortType, setSortType] = useState('')
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        assignedPagination(data, setActivePage, FORMULARYREQURL, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackAssignedFormularyListAsync,'','',sortType);
    }
    // sorting
    const listSorting = Sorting(ASSIGNED_FORMULARY + '/' + formularyId, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackAssignedFormularyListAsync,sortType,setSortType)

    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const selectRowCount = (length) => {
        setSelectedRowCount(length)
    }

    const handleSort = useCallback(data => {
        if (listSorting) {
            const sortingColumn = data.sortBy[0]
            const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
            listSorting['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
            listSorting['queryParam']['offSet'] = (offSet)?offSet:0
            listSorting['queryParam']['search'] = ''
            setSortType(listSorting['queryParam']['sort'])
            dispatch(listSorting.asyncMethod(listSorting));
        }
    }, [dispatch,offSet])

    let selectedData = "";
    const deleteDataList = (data) => {
        selectedData = data
    }


    useEffect(() => {
        const formularyName = (assignedFormularyList) && assignedFormularyList.data?.id + ' - ' + assignedFormularyList.data?.formularyName
        if (document.getElementById('formularyId')) {
            document.getElementById('formularyId').value = formularyName
        }
    })


    const searchProduct = (rows) => {  //External formulary
        const searchColumns = rows[0] && Object.keys(rows[0]);
     return rows.filter((row) =>
     searchColumns.some(
         (column) => row[column].toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1
     )        
     );
     } 

    const onlyFirstName = (data) => {
        const myArr = data.split(" ");
        return myArr[0]
    }

    return (
        <>
            {!editMode && <>
                {assignedFormularyList && assignedFormularyList.data && assignedFormularyList.data.materials &&
                    <>

                        {/* Meta */}
                        {createView && <>

                            <h3 className={styles.admin_meta}>
                                <span><b>Created Date:</b> {assignedFormularyList?.data?.createdDate && dateOfBirthDate(assignedFormularyList?.data?.createdDate)}</span>
                                <span><b>Created By:</b> {onlyFirstName(assignedFormularyList?.data?.createdBy)}</span>
                                {assignedFormularyList?.data?.updatedDate && <span><b>Last Edited:</b> {dateOfBirthDate(assignedFormularyList?.data?.updatedDate)}</span>}
                                {assignedFormularyList?.data?.updatedBy && <span><b>Last Edited By:</b> {onlyFirstName(assignedFormularyList?.data?.updatedBy)}</span>}
                            </h3>

                        </>}

                        {createView && <div className={`${styles.formulary_content}`}>
                            <div className={`col_spec mt-4  ${styles.col_spec}`}>
                                <TextField
                                    label={"Formulary ID/Name:"}
                                    placeholder={"Olivia Harper"}
                                    id="formularyId"
                                    classes={{
                                        column: `mt-4  ${styles.col_spec}`,
                                        labelClass: `col col-form-label  pl-0 ${styles.label_spec}`,
                                        inputColumn: `${styles.autocomplete} ${styles.autocomplete_disable}`,
                                        inputClassName: `col ${styles.basic_input}`
                                    }}
                                    type="text"
                                    disabled={true}
                                    required={true}
                                />
                            </div>

                            {/* Meta Edit and Duplicate icons*/}
                            {/* {createView && <>                                
                                <div className={styles.edit_duplicate}>
                                    <span onClick={handleEdit}><img src={editIcon} alt="edit" /> Edit</span>
                                    <span onClick={handleDuplicate}><img src={duplicateIcon} alt="duplicate" /> Duplicate</span>
                                </div>
                            </>
                            } */}

                        </div>
                        }

                        {externalAdmin && <div className={`${styles.formulary_content}`}>
                            <div className={`col_spec mt-4 pl-1 ${styles.col_spec}`}>
                                <TextField
                                    label={"Formulary ID/Name:"}
                                    placeholder={"Olivia Harper"}
                                    classes={{
                                        column: `mt-4  ${styles.col_spec}`,
                                        labelClass: `col col-form-label  pl-0 ${styles.label_spec}`,
                                        inputColumn: `${styles.autocomplete} ${styles.autocomplete_disable}`,
                                        inputClassName: `col ${styles.basic_input}`
                                    }}
                                    type="text"
                                    defaultValue={assignedFormularyList.data ? assignedFormularyList.data?.id + ' - ' + assignedFormularyList.data?.formularyName : ''}
                                    disabled={true}
                                    required={true}
                                />
                            </div>


                            <div className={`${styles.searchDropMove}`}>
                                <img src={searchIcon} className={`${styles.searchImage}`} alt="search icon" />
                                    <SelectBox
                                    id={"search"}
                                    name={"select"}
                                    classes={{
                                        column: `col-15 h-33 w-175 ${styles.col_spec}`,
                                        inputClassName: styles.searchBar,
                                        inputColumn: `col-15 p-0 ${styles.searchPos}`
                                    }}
                                    placeholder={'All'}    
                                 //  optionValue ={productOptions}
                                    optionValue = {userDetails?.role !== "ExternalAdministrator" ? (
                                        [
                                            { key: 'division', value: 'Division' },
                                            { key: 'productCategoryDesc', value: 'Product Category Description' },
                                            { key: 'exactMaterialNumber', value: 'Material #' },
                                            { key: 'materialName', value: 'Material Description' },                                                
                                            
                                        ]
                                    ): (
                                        [
                                            { key: 'division', value: 'Division' },
                                            { key: 'productCategoryDesc', value: 'Product Category Description' },
                                            { key: 'exactMaterialNumber', value: 'Material #' },
                                            { key: 'materialName', value: 'Material Description' },
                                            { key: 'productFamilyCode', value: 'Product Family Code' },
                                            { key: 'productFamilyDesc', value: 'Product Family Description' }
                                            
                                        ]
                                    )
                                    }                                   
                                
                                    onChange = {(e) => handleSearchColumn(e)}
                                />
                             
                               
                                <TextField
                                    id={"searchId"}
                                    name={"searchId"}
                                    //placeholder={}
                                    classes={{
                                        column: `col-15 ${styles.col_spec}`,
                                        inputClassName: `${styles.searchDrop}`,
                                        inputColumn: `col-15 p-0 ${styles.searchPosition}` ,
                                    }}
                                    type="text"
                                    onChange={(e) => getAssignedFormularyListSearch(e)}
                                    value={searchText}
                                    // errors={errors}
                                    // validator={register}
                                    required={false}
                                    // customError={errorMsg ? errorMsg : ''}
                                    // defaultValue={(woundReportPayload && woundReportPayload.tempWoundCustomer) ? woundReportPayload.tempWoundCustomer : ''}                            
                                />                        
                            </div>

                            {externalAdmin && assignedFormularyList?.data?.defaultType === null &&  <div className={styles.edit_duplicate_external}>
                                <span onClick={handleEdit}><img src={editIcon} alt="edit" /> Edit</span>                               
                            </div>}    
                            

                        </div>
                        }

                        <DataTable
                            columns={userDetails?.role !== "ExternalAdministrator" ? columns : columnsExternal}
                            data={searchProduct(assignedFormularyList.data.materials)}
                            sorting={true}
                            getSelectedRowData={getSelectedRowData}
                            setSelectedRows={selectRowCount}
                            onSort={handleSort}
                            detailView={true}
                            deleteDataList={deleteDataList}
                            // fixedFullWidthSize={'0'}
                            workListView={userDetails?.role !== "ExternalAdministrator" ? true : false}
                            sortType={sortType?sortType:''}
                        />

                        {(Array.isArray(assignedFormularyList.data.materials))&&(assignedFormularyList.data.materials.length>1)&&<Pagination
                            activePage={activePage}
                            itemsCountPerPage={assignedFormularyList.data && assignedFormularyList.data.metadata ? assignedFormularyList.data.metadata.limit : 50}
                            totalItemsCount={assignedFormularyList.data && assignedFormularyList.data.metadata ? assignedFormularyList.data && assignedFormularyList.data.metadata.total : 0}
                            getActivePage={getActivePage}
                        />
                        }
                    </>
                }
            </>}
            
            {/* {editMode && <CreateFormulary duplicate={false} setDuplicate={setDuplicate} editMode={true} handleCancel={handleCancel} setEditMode={setEditMode} formularyId={formularyId} formularyData={addedApiMaterials} externalAdmin={externalAdmin}/>} */}
        </>
    )
}

CustomerFormularyAssignedList.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

CustomerFormularyAssignedList = connect()(withRouter(CustomerFormularyAssignedList));
