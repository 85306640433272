import React, { useState, useEffect } from 'react';
import style from './accordion.module.scss';
import PropTypes from 'prop-types';
import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";
export function Accordion({ heading, content, idControl = '',accordType,classes,defaultShow,histCause=false}) {
  const [firstAccod, setFirstAccod] = useState(histCause?false:true);
  function handleShow() {
    setFirstAccod(!firstAccod)
  }
  useEffect(() => {
    if(histCause&&defaultShow===1){
      setFirstAccod(true)
     }
}, [defaultShow])

  return <>
  {histCause?
   <div>
   <ReactAccordion     className={`${(classes?.accBodyCust) ? classes.accBodyCust : ''} ${style.accod_body}`} >
     <AccordionItem className={`card mb-3 border-0 rounded-0 ${(classes?.accCardCust) ? classes.accCardCust : ''}`} dangerouslySetExpanded={firstAccod} >
       <AccordionItemButton className={`m-0 p-0 ${accordType&&accordType==='formulary'?style.accod_heading_formulary:style.accod_heading} ${(classes?.accHeadCust) ? classes.accHeadCust : ''}`} >
         <div onClick={handleShow} id={`cust${idControl}`} idcontrol={idControl} className={`${accordType&&accordType==='formulary'?style.accod_btn_formulary:style.accod_btns} ${(classes?.accBtnCust) ? classes.accBtnCust : ''}`}>
           {heading}
         </div>
       </AccordionItemButton>
       <AccordionItemPanel idcontrol={idControl} className={`${firstAccod ? style.show_bodys : style.hide} ${(classes?.accPanelCust) ? classes.accPanelCust : ''}`}>
         {content}
       </AccordionItemPanel>
     </AccordionItem>
   </ReactAccordion>
 </div>

: <div>
<ReactAccordion     className={`${(classes?.accBodyCust) ? classes.accBodyCust : ''} ${style.accod_body}`}  allowZeroExpanded >
  <AccordionItem className={`card mb-3 border-0 rounded-0 ${(classes?.accCardCust) ? classes.accCardCust : ''}`}  >
    <AccordionItemButton className={`m-0 p-0 ${accordType&&accordType==='formulary'?style.accod_heading_formulary:style.accod_heading} ${(classes?.accHeadCust) ? classes.accHeadCust : ''}`} >
      <div onClick={handleShow} id={`cust${idControl}`} idcontrol={idControl + 1} className={`${accordType&&accordType==='formulary'?style.accod_btn_formulary:style.accod_btn} ${(classes?.accBtnCust) ? classes.accBtnCust : ''}`}>
        {heading}
      </div>
    </AccordionItemButton>
    <AccordionItemPanel idcontrol={idControl + 1} className={`${firstAccod ? style.show_body : style.hide} ${(classes?.accPanelCust) ? classes.accPanelCust : ''}`}>
      {content}
    </AccordionItemPanel>
  </AccordionItem>
</ReactAccordion>
</div>}
   
  </>
}
Accordion.propTypes = {
  heading: PropTypes.object,
  content: PropTypes.object,
  allowed: PropTypes.string
}
