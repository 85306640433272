import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import 'react-vis/dist/style.css';
import logo from '../../../static/images/skin-health-logo.png';
import Loader from '../../../static/images/spinner.svg';
import { handleClickGenerateTreatmentPDF, handleClickGenerateWoundReportPDF } from '../../../utils/woundReportHelper';
import { Button } from '../../primary';
import { Header } from '../../secondary/Header';
import style from "./treatmentReportPdf.module.scss";

export function TreatmentReportPdf({ history }) {

    // For scrolling the page to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Getting loader 
    const loading = useSelector(state => state.app.loading);

    // States
    const [canvases, setCanvases] = useState([])
    const [header, setHeader] = useState({})
    const [started, setStarted] = useState(false) // To avoid multiple html2canvas promises
    const [pdfGeneration, setPdfGeneration] = useState(false) // State for end of html2canvas process
    const [message, setMessage] = useState(false)


    const overViewData = useSelector(state => state.woundreport.treatmentOverViewData);
    const customerName = useSelector(state => state.user.customerName);


    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                setMessage(true)
            }, 10000)
        }
    }, [loading])

    // Getting header, finding the size, converting the code and pushing it into a state
    useEffect(() => {
        async function run() {
            const header = document.getElementById('header')
            const imageWidth = 210;
            await html2canvas(header).then(function (canvas) {
                setHeader({ height: canvas.height * imageWidth / canvas.width, imgData: canvas.toDataURL('image/png') })
            })
        }
        // We are starting above canvasing process after loading the html file with a delay
        if (!loading) {
            if (!started) {
                setTimeout(() => {
                    run();
                }, 5000)
                setStarted(true)
            }
            else {
                return
            }
        }
    }, [loading])


    // Getting all the elements with the tag name 'article'. Capturing screenshot, finding height and converting it into base code.
    // Pushing all those into an array of object
    useEffect(() => {
        async function run() {
            const allDivs = document.getElementsByTagName('article')
            const divsArray = Array.from(allDivs)
            const can = []
            const imageWidth = 210;
            // Creating screenshot for each section
            for (let i = 0; i < divsArray.length; i++) {
                await html2canvas(divsArray[i], { allowTaint: true, useCORS: true }).then(function (canvas) {
                    can.push({ id: i, height: canvas.height * imageWidth / canvas.width, imgData: canvas.toDataURL('image/png') })
                    setCanvases([...can])
                }).finally(() => {
                    if (i === divsArray.length - 1) {
                        setPdfGeneration(true)
                    }
                })
            }
        }
        // We are starting above canvasing process after loading the html file with a delay
        if (!loading) {
            if (!started) {
                setTimeout(() => {
                    run();
                }, 5000)
                setStarted(true)
            }
            else {
                return
            }
        }
    }, [loading])


    


    // On click, generating pdf by putting all the above images into a document. We are using jsPDF to generate pdf.
    // If we can't fit image into same page, we are moving the image into next page. It is done automatically.
    const handleClick = () => {
        handleClickGenerateTreatmentPDF(html2canvas, jsPDF, canvases, header, customerName);
    }

    return (
        <>
            <div className={`${style.back_btn}`}>
                <Header backLink={'patients'} handleBack={() => history.goBack()} />
            </div>

            {!pdfGeneration && <div className={style.info_message}>
                {<>
                    {<h3>Creating your PDF report... <span>This won't take long</span></h3>}
                    
                </>}
            </div>}

            {!loading &&  <div className={style.info_message}>                    
                {pdfGeneration && <>
                    <Button
                        classes={{ className: `btn btn-small ${style.btn_small} ${style.btn_custom}` }}
                        type="submit"
                        id="next"
                        onClick={handleClick}
                    >
                        Download PDF
                    </Button>
                </>}
            </div>}

            {!loading && <div id="pdf" className={style.content}>

                <header id="header" className={`min ${style.header}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-8 p-0">
                                <h1>Treatment Overview</h1>
                            </div>
                            <div className="col-4 p-0 text-right">
                                <img src={logo} alt="test" />
                            </div>
                        </div>
                    </div>
                </header>

                <article>
                    <div className={style.overview_score}>
                            <div className={`crd_opt_1 ${style.overview_percentage} `}>
                                <label className={style.dash_top}>Wound Cleansers</label>
                                <p className={style.dash_btm}>
                                    <span>{overViewData?.woundCleansers?.count}</span>
                                    <span>{overViewData?.woundCleansers?.percent}</span>
                                </p>
                            </div>
                            <div className={`crd_opt_2 ${style.overview_percentage}`}>
                                <label className={style.dash_top}>Skin Protectants</label>
                                <p className={style.dash_btm}>
                                    <span>{overViewData?.skinProtectants?.count}</span>
                                    <span>{overViewData?.skinProtectants?.percent}</span>
                                </p>
                            </div>
                            <div className={`crd_opt_3 ${style.overview_percentage}`}>
                                <label className={style.dash_top}>Primary Dressings </label>
                                <p className={style.dash_btm}>
                                    <span>{overViewData?.primaryDressings?.count}</span>
                                    <span>{overViewData?.primaryDressings?.percent}</span>
                                </p>
                            </div>
                            <div className={`crd_opt_4 ${style.overview_percentage}`}>
                                <label className={style.dash_top}>Secondary Dressings </label>
                                <p className={style.dash_btm}>
                                    <span>{overViewData?.secondaryDressings?.count}</span>
                                    <span>{overViewData?.secondaryDressings?.percent}</span>
                                </p>
                            </div>
                            <div className={`crd_opt_5 ${style.overview_percentage}`}>
                                <label className={style.dash_top}>Compression </label>
                                <p className={style.dash_btm}>
                                    <span>{overViewData?.compression?.count}</span>
                                    <span>{overViewData?.compression?.percent}</span>
                                </p>
                            </div>
                        </div>
                </article>    
            </div>
            }
        </>
    )
}

TreatmentReportPdf = connect()(withRouter(TreatmentReportPdf));