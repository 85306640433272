import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DataTable, Pagination } from '..';
import { Confirmation } from '../Confirmation/confirmation'
import { TextField, Button, SelectBox, RadioButton, AccessErrorModal } from '../../primary';
import Loader from '../../../static/images/spinner.svg';
import { form_group } from '../../../styles/common.module.scss';
import DeleteIcon from '../../../static/images/delete-icon.svg';
import searchIcon from '../../../static/images/search-icon.svg';
import PlusIcon from '../../../static/images/plus-icon_white.svg';
import warning from '../../../static/images/icon-check-danger.png';
import { CreatePatients } from '../../core/Patients'
import { CreateCustomer } from '../../core/Customers'
import { CreateUser } from '../../core/Users'
import { CsvImportLoader } from '../../core/Patients/csvImportLoader'
import style from './listView.module.scss';
import { Header } from '../Header';
import { SimpleCreatePatient } from '../../core/Patients/simpleCreatePatient';
import { CsvImport } from '../CsvImport';
import { CreateForm } from '../../core/FormBuilder'
import { CreateAnnouncement } from '../../core/Announcement';
import { CreateListViewMenu } from '../../core/Layouts';
import { getFacilityLabel } from '../../../utils';
import { dateOfBirthDate, List, SelectedRow, Sorting, Pagination as assignedPagination } from '../../../utils';
import { CreateProductMaterial } from '../../core/Products';
import { resetFacilitiesData, getNewFacilityDataAsync, getFacilityDataAsyncForPatients, getCallBackListAsync,setFacilityAndCustomerNumLastVal, setAdminView } from '../../../app/patientSlice';
import { useForm } from 'react-hook-form';
import { LIST_VIEW_LIMIT, PATIENT, DELETE_ASSESSMENT, DELETE_HISTORY_ASSESSMENT, SUCCESS_STATUS, USER_INFORMATION_HEAD} from '../../../constants';
import rightArrow from '../../../static/images/right_arrow.svg';
import { getCallBackAssignedFormularyListAsync,  resetCustomerAssignedFormulary} from '../../../app/customerSlice';

export let CustListView = ({ location, dispatch, history, columns, data, metaData, sorting,
  rowSelect, getSelectedRowData, detailView, searchPlaceholder, getSearchValue, detailsRow,
   listSorting, listType, subHeading, facilityListView, fileUplodad,
  showFixedColumn, setUploadStatus, uploadingCheckAsync, deleteListView, searchKey, contextualSupportView,
  selectedFacility, getPatientList, setCreateFormulary, formularyView, getSelectedItems, noPage, formularyLeft,
  handleProductChange, productType, setProductType, rightFormulary, addedItems, sortingItems, fixedFullWidthSize, offset, getCustomerList, getUserList,
  accessError, otherHeading, setCustomerNumber, facilityNumberOption, setFacilityNumber, customerNumberId
}) => {
  /* Get url of the current page */
  const path = window.location.href;
  /* Below will return only domain - http://www.google.com */
  const origin = window.location.origin;
  
  const isAdminView = path.includes(`${origin}/admin`);

  if(path.includes(`${origin}/admin`)){
    dispatch(setAdminView('admin'))
  }else{
    dispatch(setAdminView('not'))
  }

  const [modalShow, setmodalShow] = useState(false)
  const [menuShow, setMenuShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")
  const [onselectOrgz, setonselectOrg] = useState([]);
  const userRole = useSelector(state => state.user);

  const facilityAndCustomerNumLastVal = useSelector(state => state.patient.facilityAndCustomerNumLastVal)



  const { register, handleSubmit, control, errors, reset, setError, watch } = useForm();
  const loading = useSelector(state => state.app.loading)
  const adminView = useSelector(state => state.patient.adminView)

  const dropRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = (e) => {
    if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
      setMenuShow(false);
    }
  };

  const menuList = () => {
    setMenuShow(true)
  }
  const modalOpen = () => {
    setmodalShow(true);
  }

  const modalClose = () => {
    setmodalShow(false);
    setConfirmationModal(false);
  }

  const getFacilities = useSelector(state => state.patient.newFacilitiesData);
  const [facOptionData, setFacOptionData] = useState('');

  useEffect(() => {
    let fac = ""
    if (getFacilities?.errorMessage || getFacilities?.length === 0 &&document.getElementById('customerNumberId')&&document.getElementById('customerNumberId').value) {
      fac = "We didn’t recognize this customer number. Enter a different customer number"
    }
    setErrorMsg(fac)
    setFacOptionData(facilityNumberOption)
    console.log('facilityNumberOption',facilityNumberOption)
  }, [getFacilities,facilityNumberOption])

  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const selectRowCount = (length) => {
    setSelectedRowCount(length)
  }

  let selectedData = "";
  const deleteDataList = (data) => {
    selectedData = data
    if (formularyView) {
      getSelectedItems(data)
    }

  }

  //added pagination for
  const urlId = customerNumberId;
  const USERINFORENQ = "/customer" + '/' + urlId + "/users"

// pagination
    const [activePage, setActivePage] = useState(1);
    const [offSet, setOffSet] = useState(0)
    const [sortType, setSortType] = useState('')
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        assignedPagination(data, setActivePage, USERINFORENQ, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackAssignedFormularyListAsync,'','',sortType);
    }



    const getAssignedFormularyList = () => {
      List(USERINFORENQ, 'Get', LIST_VIEW_LIMIT, 0, dispatch, getCallBackAssignedFormularyListAsync);
  }



    const deleteList = () => {
    deleteListView(selectedData);
    setConfirmationModal(false)
  }

  const [confirmationModal, setConfirmationModal] = useState(false)
  const deleteModal = () => {
    setConfirmationModal(true)
  }

  const handleSort = useCallback(data => {
    if (listSorting) {
      let sortingColumn = data.sortBy[0]
      let sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
      listSorting['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
      // The below if condition is for 'Sorting' + 'Pagination'  
      if (listSorting.setSortType) {
        //dispatch(listSorting.setSortType(listSorting['queryParam']['sort']))
        if(listSorting['queryParam']['sort']==undefined&&sortType){
          listSorting['queryParam']['sort']=sortType;
        }else{
          if(listSorting['queryParam']['sort']&&listSorting['queryParam']['sort']!==undefined){
            listSorting.setSortType(listSorting['queryParam']['sort'])
          }
        }
      }
      dispatch(listSorting.asyncMethod(listSorting));
    }
  }, [dispatch, searchKey, activePage, offset, sortType])

  const handleRightSort = useCallback(data => {
    if (listSorting) {
      let sortingColumn = data.sortBy[0]
      let sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
      listSorting['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
      if (!formularyView) {
        dispatch(listSorting.asyncMethod(listSorting));
      }
      if (formularyView) {
        listSorting.asyncMethod(sortingColumn)
      }
    }
  }, [dispatch, addedItems])

  const handleProductSort = useCallback(data => {
    if (listSorting) {
      let sortingColumn = data.sortBy[0]
      let sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
      listSorting['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
      if (listSorting.setSortType) {
        if(listSorting['queryParam']['sort']==undefined&&sortType){
          listSorting['queryParam']['sort']=sortType;
        }else{
          if(listSorting['queryParam']['sort']&&listSorting['queryParam']['sort']!==undefined){
            listSorting.setSortType(listSorting['queryParam']['sort'])
          }
        }
      }
      dispatch(listSorting.asyncMethod(listSorting));
    }
  }, [dispatch, productType, activePage, searchKey, offset,sortType])

  const upDateData = (data, id, value) => { }


  /* CSV file imoport block */

  /* Aborting upload */
  const controller = new AbortController()
  const signal = controller.signal

  /* Cancel upload function */
  const cancelUpload = () => {
    // Abort.
    controller.abort()
    setFileSelected(false)
    dispatch(setUploadStatus(null))
  }

  /* State to check weather the CSV file is selected or not */
  const [fileSelected, setFileSelected] = useState(false)

  /* Handle file import */
  const handleImport = (data) => {
    /* If file selected, set state as true */
    /* Check if the selected file is CSV or not */
    const fileName = data.target.files[0].name.split('.')
    if (fileName[fileName.length - 1] === 'csv'||fileName[fileName.length - 1] === 'xlsx'||fileName[fileName.length - 1] === 'xls') {
      setFileSelected(true)
      /* Initilize formdata */
      let payload = new FormData();
      // Add file to formdata
      payload.append(
        'csv',
        data.target.files[0]
      );
      /* Dispatch payload and abort signal */
      dispatch(fileUplodad({ payload, signal, getPatientList, getCustomerList, getUserList }))
      /* Reset the form */
      data.target.value = ''
    } else {
      /* If it is not a csv file error */
      alert('Wrong file type selected!!!')
    }
  }

  /* Loader close */
  const loaderClose = () => {
    setFileSelected(false)
    dispatch(setUploadStatus(null))
  }

  /* Check weather someone else is uploading */
  const uploading = useSelector((state) => state.patient.uploading)
  /* For set interval timer */
  const [intervalId, setIntervalId] = useState(0)

  useEffect(() => {
    /* If no file is selected keep on calling API to check if someone is uploading,
      if someone is uploaidng, we need to disable the "import" button */
    let interval;
    if (!fileSelected) {
      if (path === `${origin}/admin/patients` || path === `${origin}/admin/customers`) {
        interval = setInterval(() => {
          dispatch(uploadingCheckAsync())
        }, process.env.REACT_APP_UPLOADING_CHECK_INTERVAL)
        /* Assigning it to state */
        setIntervalId(interval)
      }
    }
    /* If not in list view clear interval */
    if (path !== `${origin}/admin/patients` || path !== `${origin}/admin/customers`) {
      clearInterval(intervalId)
    }
    /* If we are uploading, we should not call above API */
    if (fileSelected) {
      clearInterval(intervalId)
    }
    return () => {
      clearInterval(interval)
    }
  }, [fileSelected, dispatch])

  let headerProp = {
    'Patients': {
      heading: `${isAdminView ? otherHeading ? 'Patient Management' : 'Admin Portal' : 'Patients'}`,
      // subHeading: `${isAdminView ? 'Patient Management' : ''}`
    },
    'Customers': {
      heading: 'Admin Portal',
      subHeading: subHeading
    },
    'Users': {
      heading: 'Admin Portal',
      subHeading: 'Users Profile'
    },
    'Sso': {
      heading: 'Admin Portal',
      subHeading: 'SSO'
    },
    'FormBuilder': {
      heading: 'Admin Portal',
      subHeading: 'Form Builder'
    },
    'Announcement': {
      heading: 'Admin Portal',
      subHeading: 'Announcement'
    },
    'Layouts': {
      heading: 'Admin Portal',
      subHeading: 'Layout'
    },
    'ContextualSupport': {
      heading: 'Admin Portal',
      subHeading: 'Contextual Support'
    },
    'Formulary': {
      heading: 'Admin Portal',
      subHeading: 'Formulary'
    },
    'Products': {
      heading: 'Admin Portal',
      // subHeading: (productType === "material") ? 'Material' : (productType === "productfamily") ? 'Product Family' :''
      subHeading: 'Products'
    },
  }

  let listMenu = {
    'Layouts': <CreateListViewMenu menuList={menuShow} dropRef={dropRef} />
  }
  let productMaterialCreateMenu = {
    'Products': <CreateProductMaterial menuList={menuShow} dropRef={dropRef} setProductType={setProductType}/>
  }

  const getCustomerNumber = (value) => {
    if (value) {
       setErrorMsg('')
      // setonselectOrg([])
     // setCustomerNumber(value)
      dispatch(getFacilityDataAsyncForPatients({ customerNumber: value},setCustomerNumber));
      // dispatch(setFacilityAndCustomerNumLastVal({
      //   customerNum  : value,
      //   facilitylocation : facilityAndCustomerNumLastVal.facilitylocation ? facilityAndCustomerNumLastVal.facilitylocation  : ''
      // }))
    }
  }

  
  const imagePlusIcon = <img src={PlusIcon} className={`${style.plusimg}`} alt="Click to add" />

  return <>
    {/* {!formularyView && 
    <Header {...headerProp[listType]} detailsRow={detailsRow} />
    } */}
    <div className={`col pt-0 pr-0 pb-3`}>
      <div className={`row ${style.ipadm_l}`}>
        {!formularyView && <><br/>
        <TextField
          classes={{
            column: `form-group no-gutters ${form_group} position-relative ${listType === 'Patients' ? 'col-sm-4' : 'col-sm-7'} p-0`,
            inputClassName: `form-control mb-1 ${style.pl40}`,
            labelClass: `position-absolute ${style.search_icon_image}`
          }}
          label={<img src={searchIcon} alt="search icon" />}
          type={"text"}
          name={"search"}
          id={"search"}
          placeholder={searchPlaceholder}
          title={'Toggle All Rows Selected'}
          onKeyUp={getSearchValue}
          defaultValue={searchKey}
        />
          
        </>
        }
        <div className={`${((productType === 'productfamily')) ? 'col-sm-12 justify-content-end' : ((productType === 'material') && Array.isArray(data) && data.length === 0) ? 'col-sm-12' : (listType === 'Patients') ? 'col-sm-8' : 'col-sm-5 justify-content-end'} d-flex pr-0`}>
          {listType === "Patients" && <div className={`${style.customer_search_parent}`}>
            {(userRole.role === 'MedlineAdministrator' || userRole.role === 'MedlineSupport') && <div className={`${style.customer_Search_position} d-flex`}>
              <>
                
              </>
            </div>
            }
            <div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
    {
      data && <>
        <div className={`${style.loader_parent}`}>
          {/* {loading && <div className={`text-center mt-2 mb-3 ${style.loader_child}`}>
            <img src={Loader} alt="loader" style={{ maxWidth: '50px' }} />
          </div>} */}
          <DataTable
            columns={columns}
            data={data}
            sorting={sorting}
            rowSelect={rowSelect}
            getSelectedRowData={getSelectedRowData}
            setSelectedRows={selectRowCount}
            saveData={upDateData}
            onSort={(listType === 'Products') ? handleProductSort : rightFormulary ? handleRightSort : handleSort}
            detailView={detailView}
            deleteDataList={deleteDataList}
            showFixedColumn={showFixedColumn}
            searchKey={searchKey}
            listType={listType}
            formularyView={formularyView}
            formularyLeft={formularyLeft}
            productType={productType}
            fixedFullWidthSize={fixedFullWidthSize}
            activePage={activePage}
            sortType={sortType?sortType:''}
          />
          {(!noPage) && (Array.isArray(data)) && (data.length > 0) && <Pagination
            activePage={activePage}
            itemsCountPerPage={metaData ? metaData.limit : 50}
            totalItemsCount={metaData ? metaData.total : 0}
            getActivePage={getActivePage}
          />}
        </div>
      </>
    }
    {/* {
      listModalBox[listType]
    } */}
    {
      confirmationModal && <Confirmation
        onClose={() => modalClose()}
        title={" "}
      >
        <div className="text-center pr-5 pl-5">
          <p>
            <img src={warning} alt="warnning-icon" />
          </p>
          <p className="pb-3">Are you sure you’d like to delete Items?</p>
          <div className="mb-2">
            <Button
              value="Yes"
              classes={{ className: `btn mr-2 pb-2 pt-2 mr-3 ${style.yes_btn}` }}
              onClick={() => deleteList()}
            />
            <Button
              id="closeId"
              value="No"
              classes={{ className: `btn ml-2 pb-2 pt-2 ${style.no_btn}` }}
              onClick={() => modalClose()}
            />
          </div>
        </div>
      </Confirmation>
    }
    {
      accessError && !modalShow && <AccessErrorModal />
    }
  </>
}

CustListView.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
};

CustListView = connect()(withRouter(CustListView));
