import PropTypes from 'prop-types';
import React, { useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createMaterialAsync, createProductFamilyAsync, setFamilyCode, setMaterialGroupCode, setProductCategoryCode, setResMsgState } from '../../../app/productSlice';
import {
  ERROR_STATUS, SUCCESS_STATUS
} from '../../../constants';
import iconDanger from '../../../static/images/icon-check-danger.png';
import iconSuccess from '../../../static/images/icons-check-success.png';
import { onSubmitCreateMaterialData, onSubmitCreateProductData } from '../../../utils/productsHelper';
import { ActionModal, Modal , CloseWarning} from '../../secondary';
import { CreateEditProductMaterial } from './createEditProductMaterial';
import style from './createProductMaterial.module.scss';
import { setAccessError } from '../../../app/appSlice';
export function CreateProductMaterial ({ menuList, dropRef, setProductType }) {


  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'all'
  });

  const dispatch = useDispatch();

  const [msgModal, setMsgModal] = useState(false);

  const [createMsgModal, setCreateMsgModal] = useState(false);
  const accessError = useSelector(state => state.app.accessError)
  const modalClose = () => {
    setProductModalOpen(false);
    setMaterialModalOpen(false);
    setMsgModal(false);
    setCreateMsgModal(false);
    dispatch(setFamilyCode({}))
    dispatch(setMaterialGroupCode({}))
    dispatch(setProductCategoryCode({}))
    dispatch(setResMsgState(''))
    reset();
  }

  // modal state
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [materialModalOpen, setMaterialModalOpen] = useState(false);

  const productModal = () => {
    modalClose();
    setProductModalOpen(true);
  }
  const materialModal = () => {
    modalClose();
    setMaterialModalOpen(true);
  }

  const [productManagerVal, setproductManagerVal] = useState([])
  const productManagerData=(data)=>{
    setproductManagerVal(data);
  };
  
  const onSubmitMaterial=(data)=>{
    setProductType('material')
    onSubmitCreateMaterialData(data,dispatch,createMaterialAsync,setMaterialModalOpen,setCreateMsgModal)

  }

  const resMsgState = useSelector(state => state.product.resMsgState);
  const [multiError, setMultiError] = useState(false);


  const onSubmitProduct=(data)=>{
    setProductType('productfamily')
    if(productManagerVal===undefined) {
      setMultiError(true)
      return false
    }else{
      setMultiError(false)
      onSubmitCreateProductData(data,productManagerVal,dispatch,createProductFamilyAsync,setProductModalOpen,setMsgModal,resMsgState)
      return true
    } 
    
  }

  
  useEffect(() => {
    if(accessError){
      dispatch(setResMsgState(''))   
      setCreateMsgModal(false);
      setTimeout(() => {
        modalClose();
      }, 500)
    }

}, [dispatch,accessError]);
  return (
    <>
      {
        menuList == true ? <div className={style.list_menu_postion} ref={dropRef}>
          <div onClick={() => materialModal()}>Material</div>
          <div onClick={() => productModal()}>Product Family</div>
        </div> : null
      }
      {
        /* create material modal */
        materialModalOpen && <Modal
          modalOpen={'modalOpen'}
          modalClose={modalClose}
          heading={<div className={style.modal_heading}>{"Create Material"}</div>}
          modalSize={style.custom_modal_size}
         
        >
          <div className="pl-2 pr-4">
            <CreateEditProductMaterial
                    register={register}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmitMaterial}
                    isModal={true}
                    errors={errors}
                    handleCancel={modalClose}
                    productManager={productManagerData}
                />
          </div>
        </Modal>
      }
      
      {productModalOpen && <Modal
        modalOpen={'modalOpen'}
        modalClose={modalClose}
        heading={<div className={style.modal_heading}>{"Create Product Family"}</div>}
        modalSize={style.custom_modal_size}
        
      >
        <div className="pl-2 pr-4">

        <CreateEditProductMaterial
            register={register}
            handleSubmit={handleSubmit}
            onSubmit={onSubmitProduct}
            errors={errors}
            handleCancel={modalClose}
            productType={"productfamily"}
            productManager={productManagerData}
            isModal={true}
            multiError={multiError}
        />
          
        </div>
      </Modal>}

      {msgModal && <Modal
                modalOpen={"modalopen"}
                modalClose={modalClose}
            >
                <div className="text-center p-4">

                    {resMsgState === SUCCESS_STATUS && <ActionModal
                        iconImg={iconSuccess}
                        heading="Product family has been successfully created."
                        buttonText="Close"
                        buttonAction={modalClose}
                    />
                    }

                    {resMsgState === ERROR_STATUS && <ActionModal
                        iconImg={iconDanger}
                        heading="Product family not yet created!"
                        buttonText="Close"
                        buttonAction={modalClose}
                    />
                    }

                </div>
            </Modal>
            }

          {createMsgModal && <Modal
                modalOpen={"modalopen"}
                modalClose={modalClose}
            >
                <div className="text-center p-4">

                    {resMsgState === SUCCESS_STATUS && <ActionModal
                        iconImg={iconSuccess}
                        heading="Create material has been successfully created."
                        buttonText="Close"
                        buttonAction={modalClose}
                    />
                    }

                    {resMsgState === ERROR_STATUS && <ActionModal
                        iconImg={iconDanger}
                        heading="Product family not yet created!"
                        buttonText="Close"
                        buttonAction={modalClose}
                    />
                    }

                </div>
            </Modal>
            }
    </>
  )
}

CreateProductMaterial.propTypes = {
  menuList: PropTypes.func
}
