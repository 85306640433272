import React, { useEffect, useState } from 'react'
import { Button, Form, TextField } from '../../primary'
import styles from './mobileappregister.module.scss'
import { useForm } from 'react-hook-form'
import { ServiceError, checkEmail } from "../../../utils";
import { Modal } from '../../secondary'
import { useSelector, useDispatch } from 'react-redux'
import { registerForMobileAsync, setTemporaryPassword, setEditStateTemPass } from '../../../app/patientSlice'
import { clearError } from "../../../app/appSlice";
import Loader from '../../../static/images/spinner.svg';
import warning from '../../../static/images/icon-check-danger.png';


export const MobileAppRegisterForm = ({ modalOpen, modalClose, detailsRow, checkTempPass, setCheckTempPass, assessmentId }) => {


    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);

    /* Patient email state */
    const [patientEmail, setPatientEmail] = useState("");
    const [tempPassword, setTempPassword] = useState("");
    const [isEmail, setIsEmail] = useState(true);

    const dispatch = useDispatch();
    const errorMessage = ServiceError();
    /* Fetching patient data */
    const patientData = useSelector(state => state.patient.detailViewData);

    /* Set email or set null */
    const patientEmailAddress = patientEmail ? patientEmail : patientData?.moreInfo ? patientData.moreInfo.email : '';

    /* Temp password */
    const tempPasswordData = useSelector(state => state.patient.temporaryPassword)

    /* Use hook form */
    const { register, handleSubmit, errors,reset } = useForm();

    useEffect(() => {
        if (tempPasswordData) {
            setTempPassword(tempPasswordData);

        }
    }, [tempPasswordData])
    useEffect(() => {
        if (patientEmailAddress && window.location.pathname === `/patients/${patientData.ne1Id}`) {
            reset();
            setTempPassword('');
            dispatch(setTemporaryPassword(''))
            const payload = { ne1Id: patientData.ne1Id, email: patientEmailAddress }
            setPatientEmail(patientEmailAddress);
            dispatch(registerForMobileAsync(payload))

        }
        if (patientEmailAddress && window.location.pathname === `/patients/${patientData.ne1Id}/assessment/${assessmentId}`) {
            reset();
            setTempPassword('');
            dispatch(setTemporaryPassword(''))
            const payload = { ne1Id: patientData.ne1Id, email: patientEmailAddress }
            setPatientEmail(patientEmailAddress);
            dispatch(registerForMobileAsync(payload))
        }
        if(patientEmailAddress && window.location.pathname === `/admin/patients/${patientData.ne1Id}`) {
            reset();
            setTempPassword('');
            dispatch(setTemporaryPassword(''))
            const payload = { ne1Id: patientData.ne1Id, email: patientEmailAddress }
            setPatientEmail(patientEmailAddress);
            dispatch(registerForMobileAsync(payload))
        }
    }, [patientEmailAddress, checkTempPass])

    /*************************
       * Validation
       *************************/
    const validators = {
        checkEmail
    }
    /* Form submit */
    const doSubmit = data => {
        const submitedEmail = data.email;
        setTempPassword('');
        dispatch(setTemporaryPassword(''))
        const payload = { ne1Id: patientData.ne1Id, email: submitedEmail }
        dispatch(registerForMobileAsync(payload))


    }
    /*************************
  * Back end error handling
  *************************/

    /*************************
   * Form
   *************************/
    const editState = useSelector(state => state.patient.editStateTemPass)
    useEffect(() => {
        if (editState === "success") {
            setIsEmail(false);
            setTimeout(() => {
                dispatch(setEditStateTemPass(false))
            }, 5000)
        }
    }, [editState]);
    const resetErrorRes = (e) => {
       
        dispatch(setEditStateTemPass(false))
    }
    useEffect(() => {
        dispatch(setEditStateTemPass(false))
    }, []);

    
    return (
        <Modal
            modalOpen={modalOpen}
            modalClose={modalClose}
            heading={<div>
                <div className={styles.modal_heading}>{"Register Patient for Mobile"}</div>
            </div>}
            modalSize={styles.custom_modal_size}
        >
            <Form onSubmit={handleSubmit(doSubmit)}>
                <div className={`col-sm-12  ${styles.single_product_block}`}>
                    {
                        (patientEmailAddress) && (tempPassword !== '') ?
                            <div className="row">
                                <div className={`p-0 ${styles.patient_content}`} key={tempPassword}>
                                    <TextField
                                        classes={{
                                            column: `col-10 ${styles.col_spec}`,
                                            labelClass: `col-5 col-form-label ${styles.label_spec}`,
                                            inputColumn: 'col-5 p-0',
                                            inputClassName: `${styles.basic_input} ${styles.disabled}`
                                        }}
                                        label={'Registered Email:'}
                                        type={"text"}
                                        name={'email'}
                                        id={'registered_email'}
                                        defaultValue={patientEmail}
                                        disabled={true}
                                        validator={register(validators.checkEmail)}
                                        errors={errors}
                                       

                                    />

                                    <TextField
                                        classes={{
                                            column: `col-10 ${styles.col_spec}`,
                                            labelClass: `col-5 col-form-label ${styles.label_spec}`,
                                            inputColumn: 'col-5 p-0', inputClassName: `${styles.basic_input} ${styles.disabled}`
                                        }}
                                        label={'Temporary Password:'}
                                        type={"text"}
                                        name={'temporarypwd'}
                                        id={'temporary_pwd'}
                                        defaultValue={tempPassword}
                                        disabled={true}
                                        validator={register}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    <div className="text-center pb-4">
                                        <Button
                                            type={"reset"}
                                            value={"Close"}
                                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                                            onClick={modalClose}
                                        />
                                    </div>

                                </div>
                            </div>
                            :
                            (patientEmailAddress === '' || patientEmailAddress === null) ?
                                <div className="row">
                                    <div className={`col-12 p-0 ${styles.patient_content}`}>
                                        <TextField
                                            classes={{
                                                column: `col-10 ${styles.col_spec}`,
                                                labelClass: `col-5 col-form-label ${styles.label_spec}`,
                                                inputColumn: 'col-5 p-0',
                                                inputClassName: `${styles.basic_input}`
                                            }}
                                            label={'Enter Email:'}
                                            type={"text"}
                                            name={'email'}
                                            id={'email'}
                                            defaultValue={patientEmail}
                                            validator={register(validators.checkEmail)}
                                            errors={errors}
                                            customError={(document.getElementById('email')&&document.getElementById('email').value)&&editState?.errorMessage ? editState.errorMessage : ""}
                                            onChange={resetErrorRes}
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="text-center pb-4">
                                            <Button
                                                type={"submit"}
                                                value={"Next"}
                                                classes={{ className: `btn  ${styles.btn_custom} ${styles.btn_small}` }}
                                            />
                                        </div>
                                    </div>
                                </div> : <div className={'text-center'}>{editState&&editState?.errorMessage?<><img src={warning} alt="warnning-icon" className={`text-center ${styles.loader_img}`} />{editState?.errorMessage}</>:<img src={Loader} alt="loader" className={`text-center ${styles.loader_img}`} />}</div>
                    }
                </div>
            </Form>
        </Modal>
        /* New modal form*/
    )
}
