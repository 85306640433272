import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useParams, withRouter, useHistory } from 'react-router-dom'
import { DataTable, Pagination } from '../../secondary';
import {
    getCallBackAssignedFormularyListAsync,
    resetCustomerAssignedFormulary,setResponseAssignState,
} from '../../../app/customerSlice';
import { LIST_VIEW_LIMIT, ASSIGNED_FORMULARY } from '../../../constants';
import { dateOfBirthDate, List, SelectedRow, Sorting, Pagination as assignedPagination, onlyFirstName,Search } from '../../../utils';
import styles from './viewFormulary.module.scss'
import { TextField, SelectBox } from '../../primary'
import editIcon from '../../../static/images/edit-icon.png'
import duplicateIcon from '../../../static/images/duplicate.svg'
import CreateFormulary from '../Formulary/createFormulary';
import { duplicateAsync, getFormularyListByIdAsync } from '../../../app/formularySlice';
import searchIcon from '../../../static/images/search-icon.png';

export let ViewFormulary = ({ dispatch, history, externalAdmin, createView }) => {

    const { formularyId } = useParams()

    // Edit mode

    const backToHistory = useHistory()
    const [editMode, setEditMode] = useState(backToHistory?.location?.state?.cefPath === "editMode")

    const [duplicate, setDuplicate] = useState(false)
    const [searchText,setSearchText] = useState("");

    const [searchColumns,setSearchColumns] = useState(["All"]);

    const [gridData,setGridData] = useState([]);
    const [searchKey, setSearchKey] = useState('')

    const getAssignedFormularyListSearch = (event) => {
        setSearchKey(event.target.value)
      let makeSearch= (event&&event.target&&event.target.value)?event.target.value:''
      if(searchColumns){
        makeSearch= searchColumns+':'+makeSearch;
      }
              let searchPayLoad = {};
            searchPayLoad['url'] = FORMULARYREQURL
            searchPayLoad['method'] = 'get'
            searchPayLoad['queryParam'] = { limit: 50, offSet: isNaN(offSet)===true?0:offSet, search:makeSearch}
            if(sortType){
              searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{sort:sortType}} ;
            }
           
            dispatch(getCallBackAssignedFormularyListAsync(searchPayLoad));
             }
    

    const handleSearchColumn = (event,data) => {
       
        let makeSearch= (event&&event.target&&event.target.value)?event.target.value:''
        setSearchColumns(makeSearch)
        if(makeSearch){
            makeSearch= makeSearch+':'+searchKey;
          }
                let searchPayLoad = {};
                searchPayLoad['url'] = FORMULARYREQURL
                searchPayLoad['method'] = 'get'
                searchPayLoad['queryParam'] = { limit: 50, offSet: isNaN(offSet)===true?0:offSet, search:makeSearch}
                if(sortType){
                  searchPayLoad['queryParam']={...searchPayLoad['queryParam'],...{sort:sortType}} ;
                }
               
                dispatch(getCallBackAssignedFormularyListAsync(searchPayLoad));
              
    }


    let[filteredData] = useState(); 

    const handleEdit = () => {
        setEditMode(true)
    }

    const handleCancel = () => {
        setEditMode(false)
    }

    const handleDuplicate = () => {
        const payload = {
            id: formularyId,
            setDuplicate: setDuplicate,
            setEditMode: setEditMode
        }
        dispatch(duplicateAsync(payload))
    }
   
    /* Tabs object */
    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);

    const FORMULARYREQURL = ASSIGNED_FORMULARY + '/' + formularyId

    const userDetails = useSelector(state => state.user);

    let customerNumber = 0;
    if (userDetails) {
        customerNumber = userDetails.customerNumber
    }

    const assignedFormularyList = useSelector(state => state.customer.customerAssignedFormulary);
  

    /* Get AssignedFormularyList Details */

    const getAssignedFormularyList = () => {
        List(FORMULARYREQURL, 'Get', LIST_VIEW_LIMIT, 0, dispatch, getCallBackAssignedFormularyListAsync);
    }

    useEffect(() => {
        dispatch(getFormularyListByIdAsync(formularyId,history))
    }, [formularyId, editMode])

    const addedApiMaterials = useSelector(state => state.formulary.addedApiMaterials)

    useEffect(() => {
        dispatch(resetCustomerAssignedFormulary())
        getAssignedFormularyList();
    }, [dispatch]);

    useEffect(() => {
        if (!formularyId) {
            dispatch(resetCustomerAssignedFormulary())
            getAssignedFormularyList();
        }
    }, [dispatch]);

    // Setting fixed column
    const showFixedColumn = assignedFormularyList && !!assignedFormularyList.data && Array.isArray(assignedFormularyList.data.materials) && !!assignedFormularyList.data.materials.length

    // List view column
    const columns = [
        {
            Header: 'Division',
            accessor: 'division',
            fixedColumn: showFixedColumn
        },
        {
            Header: 'Product Category Description',
            accessor: 'productCategoryDesc',
            fixedColumn: showFixedColumn,
            fixedWidth: '240px'
        },
        {
            Header: 'Material #',
            accessor: 'exactMaterialNumber',
            fixedColumn: showFixedColumn,
            restWidth:'200px'
        },
        {
            Header: 'Material Description',
            accessor: 'materialName',
            restWidth:'220px'
        },
        {
            Header: 'Product Family Code',
            accessor: 'productFamilyCode',
            restWidth:'170px'
        },
        {
            Header: 'Product Family Description',
            accessor: 'productFamilyDesc',
            restWidth:'210px'
        }
    ];

    const productOptions = columns.Header;
    const [detailsRowData, setDetailsRowData] = useState([])
    const getSelectedRowData = (data) => {
        // SelectedRow(data, setDetailsRowData, 'customerAssignedFormulary', history, isAdminView);
    }

    // Pagination
    const [activePage, setActivePage] = useState(1);
    const [offSet, setOffSet] = useState(0)
    const [sortType, setSortType] = useState('')
    const getActivePage = (data) => {
        let makeSearch= searchKey
      if(searchColumns){
        makeSearch=makeSearch+':'+searchColumns;
      }
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        assignedPagination(data, setActivePage, FORMULARYREQURL, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackAssignedFormularyListAsync,makeSearch,'',sortType);
    }


    // Sorting
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const selectRowCount = (length) => {
        setSelectedRowCount(length)
    }

    const handleSort = useCallback(data => {
        const listSorting = Sorting(ASSIGNED_FORMULARY + '/' + formularyId, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackAssignedFormularyListAsync)
        if (listSorting) {
            const sortingColumn = data.sortBy[0]
            const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
            listSorting['queryParam']['sort'] = (sortingColumn) && sortingColumn.id + sortingType
            setSortType(listSorting['queryParam']['sort'])
            dispatch(listSorting.asyncMethod(listSorting));
        }
    }, [dispatch, formularyId,activePage, offSet])

    let selectedData = "";
    const deleteDataList = (data) => {
        selectedData = data
    }


    useEffect(() => {
        const formularyName = (assignedFormularyList) && assignedFormularyList.data?.id + ' - ' + assignedFormularyList.data?.formularyName
        if (document.getElementById('formularyId')) {
            document.getElementById('formularyId').value = formularyName
        }
    })

    const searchProduct = (rows) => {
        const searchColumns = rows[0] && Object.keys(rows[0]);
     return rows.filter((row) =>
     searchColumns.some(
         (column) => row[column].toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1
     )        
     );
     }  

    return (
        <>
            {!editMode && <>
                {assignedFormularyList && assignedFormularyList.data && assignedFormularyList.data.materials &&
                    <>

                        {/* Meta */}
                        {createView && <>

                            <h3 className={styles.admin_meta} data-testid="heading">
                                <span><b>Created Date:</b> {assignedFormularyList?.data?.createdDate && dateOfBirthDate(assignedFormularyList?.data?.createdDate)}</span>
                                <span><b>Created By:</b> {assignedFormularyList?.data?.createdBy}</span>
                                {assignedFormularyList?.data?.updatedDate && <span><b>Last Edited:</b> {dateOfBirthDate(assignedFormularyList?.data?.updatedDate)}</span>}
                                {assignedFormularyList?.data?.updatedBy && <span><b>Last Edited By:</b> {assignedFormularyList?.data?.updatedBy}</span>}
                            </h3>

                        </>}

                        {createView && <div className={`${styles.formulary_content}`} data-testid="field">
                            <div className={`col_spec mt-4  ${styles.col_spec}`}>
                                <TextField
                                    label={"Formulary ID/Name:"}
                                    placeholder={"Olivia Harper"}
                                    id="formularyId"
                                    classes={{
                                        column: `mt-4  ${styles.col_spec}`,
                                        labelClass: `col col-form-label  pl-0 ${styles.label_spec}`,
                                        inputColumn: `${styles.autocomplete} ${styles.autocomplete_disable}`,
                                        inputClassName: `col ${styles.basic_input}`
                                    }}
                                    type="text"
                                    disabled={true}
                                    required={true}
                                />
                            </div>

                            <div className={`${styles.searchDropMove}`}>
                            <img src={searchIcon} className={`${styles.searchImage}`} alt="search icon" />
                                    <SelectBox
                                    id={"search"}
                                    name={"select"}
                                    classes={{
                                        column: `col-15 h-33 w-175 ${styles.col_spec}`,
                                        inputClassName: styles.searchBar,
                                        inputColumn: `col-15 p-0 ${styles.searchPos}`
                                    }}
                                    placeholder={'All'}    
                                 //  optionValue ={productOptions}
                                   optionValue={[
                                    { key: 'division', value: 'Division' },
                                    { key: 'productCategoryDesc', value: 'Product Category Description' },
                                    { key: 'exactMaterialNumber', value: 'Material #' },
                                    { key: 'materialName', value: 'Material Description' },
                                    { key: 'productFamilyCode', value: 'Product Family Code' },
                                    { key: 'productFamilyDesc', value: 'Product Family Description' }
                                  
                                ]}
                                  //selectedData={searchColumns.includes(columnDatas)}
                            
                                  onChange = {(e) => handleSearchColumn(e)}
                             />

                             
                               
                            <TextField
                                    id={"searchId"}
                                    name={"searchId"}
                                    //placeholder={}
                                    classes={{
                                        column: `col-15 ${styles.col_spec}`,
                                        inputClassName: `${styles.searchDrop}`,
                                        inputColumn: `col-15 p-0 ${styles.searchPosition}` ,
                                    }}
                                    type="text"
                                    onChange={(e) => getAssignedFormularyListSearch(e)}
                                    value={searchText}
                                    // errors={errors}
                                    // validator={register}
                                    required={false}
                                    // customError={errorMsg ? errorMsg : ''}
                                    // defaultValue={(woundReportPayload && woundReportPayload.tempWoundCustomer) ? woundReportPayload.tempWoundCustomer : ''}                            
                                />                        
                        </div>
                        {createView && (userDetails?.role !== "ExternalAdministrator") ? 
                                ((assignedFormularyList?.data?.formularyName === "Best Practice Guidelines - PAC" || assignedFormularyList?.data?.formularyName === "Best Practice Guidelines - AC"|| assignedFormularyList?.data?.formularyName === "Best Practice Guidelines - ALTERNATESITE"|| assignedFormularyList?.data?.formularyName === "Best Practice Guidelines - INTERNATIONAL"|| assignedFormularyList?.data?.formularyName === "Best Practice Guidelines - MISC") ?
                                ((userDetails?.role === "MedlineAdministrator") ? <>                                   
                                    <div className={styles.duplicate_external} data-testid="buttons">
                                        <span onClick={handleEdit}><img src={editIcon} alt="edit" /> Edit</span>
                                        <span onClick={handleDuplicate}><img src={duplicateIcon} alt="duplicate" /> Duplicate</span>
                                    </div>
                                </> : <>
                                    <div className={styles.duplicate_external} data-testid="buttons">
                                        <span onClick={handleDuplicate}><img src={duplicateIcon} alt="duplicate" /> Duplicate</span>
                                    </div>
                                </>) : (
                                    <>
                                        <div className={styles.duplicate_external} data-testid="buttons">
                                            <span onClick={handleEdit}><img src={editIcon} alt="edit" /> Edit</span>
                                            <span onClick={handleDuplicate}><img src={duplicateIcon} alt="duplicate" /> Duplicate</span>
                                        </div>
                                    </>
                                )
                                ) : 
                                (<>
                                    <div className={styles.duplicate_external} data-testid="buttons">
                                        <span onClick={handleEdit}><img src={editIcon} alt="edit" /> Edit</span>
                                    </div>
                                </>)
                            }  
                            {/* Meta Edit and Duplicate icons*/}
                            
                            {/* {createView && (assignedFormularyList?.data?.defaultType === null) && (userDetails?.role !== "ExternalAdministrator") ? 
                            <>
                                <div className={styles.edit_duplicate} data-testid="buttons">
                                    <span onClick={handleEdit}><img src={editIcon} alt="edit" /> Edit</span>
                                    <span onClick={handleDuplicate}><img src={duplicateIcon} alt="duplicate" /> Duplicate</span>
                                </div>
                            </> : <>
                                <div className={styles.duplicate_external} data-testid="buttons">
                                    <span onClick={handleEdit}><img src={editIcon} alt="edit" /> Edit</span>
                                </div>
                            </>
                            } */} 
                        </div>
                        }
                        {externalAdmin && <div className={`${styles.formulary_content}`} data-testid="content">
                            <div className={`col_spec mt-4  ${styles.col_spec}`}>
                                <TextField
                                    label={"Formulary ID/Name:"}
                                    placeholder={"Olivia Harper"}
                                    classes={{
                                        column: `mt-4  ${styles.col_spec}`,
                                        labelClass: `col col-form-label  pl-0 ${styles.label_spec}`,
                                        inputColumn: `${styles.autocomplete} ${styles.autocomplete_disable}`,
                                        inputClassName: `col ${styles.basic_input}`
                                    }}
                                    type="text"
                                    defaultValue={assignedFormularyList.data ? assignedFormularyList.data?.id + ' - ' + assignedFormularyList.data?.formularyName : ''}
                                    disabled={true}
                                    required={true}
                                />
                            </div>
                        </div>
                        }

                        <DataTable
                            columns={columns}
                           // data={assignedFormularyList.data && assignedFormularyList.data.materials}
                           data={searchProduct(assignedFormularyList.data.materials)} 
                           sorting={true}
                            getSelectedRowData={getSelectedRowData}
                            setSelectedRows={selectRowCount}
                            onSort={handleSort}
                            detailView={true}
                            deleteDataList={deleteDataList}
                            // showFixedColumn={showFixedColumn}
                           
                        />

                        {assignedFormularyList.data.metadata.total !==0 && <Pagination
                            activePage={activePage}
                            itemsCountPerPage={assignedFormularyList.data && assignedFormularyList.data.metadata ? assignedFormularyList.data.metadata.limit : 50}
                            totalItemsCount={assignedFormularyList.data && assignedFormularyList.data.metadata ? assignedFormularyList.data && assignedFormularyList.data.metadata.total : 0}
                            getActivePage={getActivePage}
                        />}
                    </>
                }
            </>}

            {/* Open create formulary when edit button clicked  */}
            {/* {editMode && <CreateFormulary duplicate={duplicate} setDuplicate={setDuplicate} editMode={true} handleCancel={handleCancel} setEditMode={setEditMode} formularyId={formularyId} formularyData={addedApiMaterials} externalAdmin={externalAdmin}/>} */}
            {editMode && <CreateFormulary duplicate={duplicate} setDuplicate={setDuplicate} editMode={true} handleCancel={handleCancel} setEditMode={setEditMode} formularyId={formularyId} formularyData={addedApiMaterials} externalAdmin={externalAdmin}/>}
        </>
    )
}

ViewFormulary.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

ViewFormulary = connect()(withRouter(ViewFormulary));
