import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDocumentList, updateDocumentList } from '../../../app/productSlice';
import { SUBMIT_ID } from '../../../constants';
import { Button } from '../../primary';
import styles from './documentView.module.scss'
import editIcon from '../../../static/images/edit-icon-blue.png'
import PlusIcon from '../../../static/images/plus-icon_white.svg';
import DocumentIcon from '../../../static/images/products/document_icon.svg';
import CloseIcon from '../../../static/images/products/close_icon.svg';
import { ProductDocument } from './productDocument';
import { handleDeleteMove } from '../../../utils/productsHelper';
import Loader from '../../../static/images/spinner.svg';
export function DocumentsView({ productData, productType }) {

    const { productId } = useParams()
    const dispatch = useDispatch()

    function getDocs() {
        dispatch(getDocumentList(productId, productType))
    }

    // Initial GET call to get existing media
    useEffect(() => {
        getDocs();
    }, [dispatch])

    const documentList = useSelector(state => state.product.documentList)

    const [editable, setEditable] = useState(false)
    const [addFile, setAddFile] = useState(false)

    const handleEdit = () => {
        setEditable(true)
    }

    const handleCancel = () => {
        setEditable(false)
    }

    const handleDelete = (id) => {
        handleDeleteMove(id, documentList, dispatch, updateDocumentList, productId, productType, getDocs)
    }

    const handleAddFile = () => {
        setAddFile(true)
    }

    const handleAddFileCancel = () => {
        setAddFile(false)
    }

    /* Trigger ad doc overlay close*/
    const dropRef = useRef();

    // Click event listener and calling handleClickOutside function if we click outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    const handleClickOutside = (e) => {
        if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
            setAddFile(false);
        }
    };

    const [tinyDelay, setTinyDelay] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    }, [])

    return (
        <div className={styles.outer_document_view}>

            <div className={styles.head}>
                <h2>{(productData?.productFamilyDesc) ? productData?.productFamilyDesc : productData?.materialDescription}</h2>

                {tinyDelay&&!editable &&<span onClick={handleEdit}><img src={editIcon} alt="edit" />Edit</span>}

                {editable && <>

                    <Button
                        classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small} ${styles.btn_position}` }}
                        type="button"
                        id={SUBMIT_ID}
                        onClick={handleAddFile}
                    >
                        <img src={PlusIcon} alt="edit" /> Add File
                    </Button>

                </>}
                {addFile && <div className={`dropdown-menu show ${styles.media_align}`} aria-labelledby="dropdownMenu3">
                    <ProductDocument
                        handleAddFileCancel={handleAddFileCancel}
                        productType={productType}
                        documentList={documentList}
                        getDocs={getDocs}
                        productId={productId}
                    />
                </div>}
            </div>


            {/* Displaying documents list */}
            {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
            <div className={styles.media_view}>
                <h4>Documents</h4>
                <ul>
                    {documentList?.documents === null && <h3 className="text-center">There are no documents added to this product</h3>}
                    {documentList?.documents && documentList?.documents?.length === 0 && <h3 className="text-center">There is no documents added to this product</h3>}
                    {documentList?.documents && documentList?.documents?.length > 0 && documentList.documents.map((data, i) => {
                        return <li key={i}>
                            <img src={DocumentIcon} alt="image_icon"></img>
                            <a href={data.document} target="_blank" rel="noopener noreferrer">{data.fileName}</a>
                            {editable && <span onClick={() => handleDelete(i)}><img src={CloseIcon} alt="close" /></span>}
                        </li>
                    })}
                </ul>
            </div>}

            <br />

            {editable && <div className="text-center pb-4">
                <br />
                {/* Save Button */}
                <Button
                    classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                    type="submit"
                    id={SUBMIT_ID}
                    onClick={handleCancel}
                >
                    Ok
                </Button>
                {/* Cancel button  */}
                {/* <Button
                        classes={{ className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                        id={CANCEL_ID}
                    >
                        {CANCEL_BTN}
                    </Button>                        */}
            </div>}

        </div>
    )
}