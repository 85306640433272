import React, { useEffect } from 'react';
import cancel from './../../../static/icons/cancel.svg';
import PropTypes from 'prop-types';

import {
  popup,
  close_btn,
  ws_modal_xl,
  delete_conf,
  ws_modal_content,
  ws_modal_header,
  ws_modal_body,
  ws_modal_footer,
  success_align,
  confirmAdjustCls,
  scrollApply,
  custom_title,
} from './confirmation.module.scss';

export const Confirmation = ({
  children,
  message,
  onClose,
  actions,
  title,
  hideBody,
  className,
  confirmAdjust,
  customSize
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  const setActionType = (type) => {
    if (type === 'p') {
      return 'confirm';
    } else if (type === 's') {
      return 'cancel float-right';
    } else if (type === 'c') {
      return `confirm ${success_align}`;
    } else if (type === 'b') {
      return `back`;
    }
    return ''
  };

  return (
    <div
      className={className ? `${popup} ${className}` : popup}
      id="modalWindow"
    >
      {/* <div className="popup_inner"> */}
      <div className={`${ws_modal_xl} ${delete_conf} ${confirmAdjustCls} `}>
        <div className={`modal-dialog modal-dialog-scrollable modal-dialog-centered  ${(confirmAdjust === true) ? confirmAdjustCls : ''} ${(customSize)?customSize:''}`}>
          <div className={`modal-content rounded-0 ${ws_modal_content}`}>
            <div className={`modal-header ${ws_modal_header}`}>
              <h5 className={`modal-title ${custom_title}`}>{title || 'ALERT'}</h5>
              <button to="#" data-testid="close" onClick={onClose} className={`${close_btn}`}>
                <img src={cancel} alt="Close" />
              </button>
            </div>
            {(message || children) && (
              <div
                className={`modal-body ${ws_modal_body} ${(confirmAdjust === true) ? scrollApply : ''}`}
                style={{ display: hideBody ? 'none' : 'block' }}
              >
                {message ? <p>{message}</p> : children}
              </div>
            )}
            {actions && actions.length > 0 && (
              <div className={`modal-footer ${ws_modal_footer}`}>
                {actions.map((a) => (
                  <button
                    key={`action-${a.type}`}
                    to="#"
                    disabled={a.disabled ? a.disabled : false}
                    data-dismiss="modal"
                    onClick={a.func}
                    className={`btn btn-primary ${setActionType(a.type)}`}
                  >
                    {a.title}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

Confirmation.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  actions: PropTypes.array,
  children: PropTypes.any,
  hideBody: PropTypes.bool,
  className: PropTypes.string,
};
