import React, { useState, useEffect } from 'react';

// Order of parameters
// 

export const handleListViewMenuChangeMove = (e, setListViewMenuDatta) => {
    if (e && e?.length > 0) {
      let tempData = [];
      e.map((data) => {
        if (data.key !== '') {
          tempData.push(data.key)
        }
      })
      setListViewMenuDatta(tempData)
    }
  }