import React, { useState, useEffect, useRef } from 'react'
import styles from './newMultiSelect.module.scss'
import closeIcon from '../../../static/images/Close_Normal.svg';

export function NewMultiSelect({ id, name, label, required, classes, optionValue, displayType, selectedValue, setSelectedValue, defaultValue, selectAll = false, onChange, validator, allValue = 'All', customError=false, labelSubText, reset, disabled, setSurveyDefault }) {
    
    const [firstRun, setFirstRun] = useState(true)
    const [displayValue, setDisplayValue] = useState([])
    const [localOptions, setLocalOptions] = useState([...optionValue])
    const [searching, setSearching] = useState(false)
    
    useEffect(() =>  {
        setLocalOptions([...optionValue])
    }, [optionValue])
    
    // Function to remove item from list
    const remover = (selectedValue, item) => {        
        let res = []
        selectedValue && selectedValue.length > 0 && selectedValue.forEach((data, index) => {
            if(item.key !== data.key){
                res.push(data)
            }
        })
        return res;
    }

    // Check or uncheck
    const checkUncheckAll = (what) => {        
        const block = document.getElementById(`${name}_drop_check`);
        const allCheckBoxes = block.querySelectorAll('input[checkAll="all"]');
        for (let i = 0; i < allCheckBoxes.length; i++) {
            allCheckBoxes[i].checked = what
        }
    }

    // Default value
    useEffect(() => {
        if(localOptions?.length !== 0 && firstRun){
            if (defaultValue && defaultValue?.length > 0 && defaultValue?.indexOf('all') !== -1) {
                const block = document.getElementById(`${name}_drop_check`);
                const checkboxes = block.querySelectorAll('input[checkAll="all"]');
                for (let i = 0, n = checkboxes.length; i < n; i++) {
                    checkboxes[i].checked = true
                }
                if(id==='customerGroupSelection'){
                    setSelectedValue([...localOptions,{key: 'all', value: allValue}])
                }else{
                    setSelectedValue([...localOptions])
                }
              
                setDisplayValue([{key: 'all', value: allValue}])
            }else if(defaultValue && defaultValue?.length > 0){
                let temp = []
                localOptions && localOptions?.length > 0 && localOptions.forEach((data) => {
                    defaultValue && defaultValue?.length > 0 && defaultValue.forEach((item) => {
                        if(item === data.key){
                            temp.push(data)
                            if(document.getElementById(`${name} ${data.key}`)){
                                document.getElementById(`${name} ${data.key}`).checked = true;
                            }
                        }
                    })
                })
                setSelectedValue([...temp])
                setDisplayValue([...temp])
            }else if(defaultValue && defaultValue?.length === 0){
                const block = document.getElementById(`${name}_drop_check`);
                const checkboxes = block.querySelectorAll('input[checkAll="all"]');
                for (let i = 0, n = checkboxes.length; i < n; i++) {
                    checkboxes[i].checked = false
                }
                setSelectedValue([])
                setDisplayValue([])
            }
        }
    }, [localOptions, defaultValue])

    // Remove selected item 
    const removeSelectList = (item) => {
        onChange();
        document.getElementById(`${name}_all`).checked = false // First uncheck 'all'
        if(item.key === 'all'){         
            setSelectedValue([])   
            setDisplayValue([])  
            checkUncheckAll(false)
            if(setSurveyDefault){
                setSurveyDefault([])
            }
                       
        }else{
            let checkBox = document.getElementById(`${name} ${item.key}`);
            checkBox.checked = false
            const res = remover(selectedValue, item)     
            if(id==='customerGroupSelection'){
             const resCustSel = remover(res, {key: 'all', value: 'All'})
              //  setSelectedValue([...localOptions,{key: 'all', value: allValue}])
                setSelectedValue([...resCustSel])
                setDisplayValue([...resCustSel])
                if(resCustSel.length===0){
                    if(setSurveyDefault){
                        setSurveyDefault([])
                    }
                }
               
            }else{
                setSelectedValue([...res])
                setDisplayValue([...res])
                if(res.length===0){
                    if(setSurveyDefault){
                        setSurveyDefault([])
                    }
                }
            }
        }
    }

    // Search functionality
    const searchValue = (e) => {
        setFirstRun(false)
        const searchTerm = e.target.value.toLowerCase();
        let res = []
        let searchList = [...optionValue]
        if(searchTerm !== ''){
            searchList && searchList?.length > 0 && searchList.forEach((data) => {
                const text = data.value.toLowerCase();
                if(text.indexOf(searchTerm) > -1){
                    res.push(data)
                }
            });
            setSearching(true)
        }else{
            res = [...optionValue]
            setSearching(false)
        }
        setLocalOptions([...res])
    }


    // Check all
    const checkAll = (e) => {
        onChange();
        checkUncheckAll(e.target.checked)
        if (e.target.checked) {
            if(id==='customerGroupSelection'){
                setSelectedValue([...localOptions,{key: 'all', value: allValue}])
            }else{
                setSelectedValue([...localOptions])
            }
            setDisplayValue([{key: 'all', value: allValue}])
        } else {
            setSelectedValue([])
            setDisplayValue([])
        }
    }

    // Select individual item
    const selectItem = (e, item, index) => {
        onChange();
        if(e.target.checked === true){
            setSelectedValue([...selectedValue, item])
            setDisplayValue([...displayValue, item])
        }
        if(e.target.checked === false){
            const res = remover(selectedValue, item)
            if(id==='customerGroupSelection'&&item.key !== 'all'){
                const resCustSel = remover(res, {key: 'all', value: 'All'})
                 //  setSelectedValue([...localOptions,{key: 'all', value: allValue}])
                   setSelectedValue([...resCustSel])
                   setDisplayValue([...resCustSel])
               }else{
                   setSelectedValue([...res])
                   setDisplayValue([...res])
               }
        }
        document.getElementById(`${name}_all`).checked = false
    }

    // Handling dropdown
    const [dropdownOption, setDropdownOption] = useState(false);
    const dropdownShown = (e) => {
        setDropdownOption(true);
        e.stopPropagation();
    }
    const dropRef = useRef();
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    const handleClickOutside = (e) => {
        if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
            setDropdownOption(false);
        }
    };


    return (
        <div className={(classes) ? classes.column : ''}>
            {label && <label className={`${classes.labelClassName}`}>{label} {required ? <span className={styles.astrick}>&#42;</span> : ''} {labelSubText && <div className={styles.bir_subline}>{labelSubText}</div>}</label>}
            <div className={`${(classes) ? classes.inputColumn : ''}`}>
                <div className={`${styles.multi_wrapper}`}>
                    {displayValue?.map((item, index) => {
                        return <span className={`${styles.chip}`} title={item[displayType]} key={index}>
                            {item[displayType]}
                            <img className={`${styles.close_icon} check_remove ${'check_remove'+id}`} src={closeIcon} alt="close icon" onClick={() => removeSelectList(item)} />
                        </span>
                    })}
                    <input type="text" className={`${styles.select_wrapper} form-control`} placeholder="Select" onClick={(e) => dropdownShown(e)} ref={dropRef} id={id} name={name} onKeyUp={(e) => searchValue(e)} disabled={disabled}/>
                </div>
                <div className={`dropdown-menu ${styles.dropdown_check_align} pl-0 pr-0 ${dropdownOption ? 'show' : ''}`} ref={dropRef} id={`${name}_drop_check`}>
                    {localOptions.length === 0 ? <span className={`${styles.notFound}`}>No Options Available</span> : ''}
                    {
                        <>
                            {!searching && selectAll && localOptions.length !== 0 ? <div className={`custom-control custom-checkbox ${styles.custom_checkbox}`}>
                                <input type="checkbox" className="custom-control-input check_all" name={`group`} checkAll="all" id={`${name}_all`} onChange={(e) => checkAll(e)} />
                                <label className="custom-control-label" htmlFor={`${name}_all`}>
                                    {allValue}
                                </label>
                            </div> : ''}
                            {localOptions.map((item, index) => {
                                return <>
                                    <div className={`${styles.sold_blk}`}>
                                        <div className={`custom-control custom-checkbox ${styles.custom_checkbox}`}>
                                            <input type="checkbox" className="custom-control-input check_parent" name={`group${index}`} checkAll="all" id={`${name} ${item.key}`} onChange={(e) => selectItem(e, item, index)} />
                                            <label className="custom-control-label" htmlFor={`${name} ${item.key}`}>
                                                {item.value}
                                            </label>
                                        </div>
                                    </div>
                                </>
                            })}
                        </>
                    }
                </div>
                {customError && <span className={styles.error_message}>Select facility</span>}
            </div>
        </div>
        
    )
}
