import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useParams, withRouter,useLocation } from 'react-router-dom'
import { ListView } from '../components/secondary/ListView';
import { FORMULARY, LIST_VIEW_LIMIT } from '../constants';
import {
    deleteFormulariesAsync,
    formularyOptionsAsync,
    getFormularyListAsync,
    optionTypeAsync,
    setFormularies,
    setNewPayload,
    setSearchKey,
   // setSortType,
} from '../app/formularySlice'
import CreateFormulary from '../components/core/Formulary/createFormulary';

export let Formulary = ({ dispatch, history }) => {

    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);
    const [createFormulary, setCreateFormulary] = useState(false)
    const { formularyId } = useParams();
   
    const formularylocation = useLocation();
    const [checkFormularyList, setCheckFormularyList] = useState('')
    const [offSet, setOffSet] = useState(formularylocation&&formularylocation.state&&formularylocation.state.dyList&&formularylocation.state.dyList.offSet?formularylocation.state.dyList.offSet:0)
    const [activePage, setActivePage] = useState(formularylocation&&formularylocation.state&&formularylocation.state.dyList&&formularylocation.state.dyList.activePage?formularylocation.state.dyList.activePage:1);
    const [sortType, setSortType] = useState(formularylocation&&formularylocation.state&&formularylocation.state.dyList&&formularylocation.state.dyList.sortType?formularylocation.state.dyList.sortType:'') // For mixing sorting with pagination

    const searchKey = useSelector(state => state.formulary.searchKey)
    //const sortType = useSelector(state => state.formulary.sortType)
    const accessError = useSelector(state => state.app.accessError)
    // Below is for clearing create formulary data from redux state
    useEffect(() => {
        // We are sending '71' as default value
        const payload = {
            division: "71",
            productCategoryDesc: null,
            productCategoryCode: null,
            productFamilyDesc: null,
            productFamilyCode: null
        }
        const queryParam = { limit: 80000 }
        // We are setting above payload as initial default data.
        dispatch(setNewPayload(payload))
        // We are getting materials and dropdowns based on above payload initially
        dispatch(formularyOptionsAsync(payload, queryParam))
    }, [dispatch])

    // Get division dropdown
    useEffect(() => {
        dispatch(optionTypeAsync('materialdivision'))
    }, [dispatch])

    // List of formularies
    const formularyList = useSelector(state => state.formulary.formularies)

    const getFormularyList = () => {
        const listPayLoad = {};
        listPayLoad['url'] = FORMULARY
        listPayLoad['method'] = 'Get'
        listPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: isNaN(offSet)===true?0:offSet, search: (searchKey) ? searchKey : '',sort:sortType }
        listPayLoad['sortType'] = sortType
        listPayLoad['setMethod'] = setFormularies
        dispatch(getFormularyListAsync(listPayLoad,history))
    }

    // useEffect(() => {      
    //     dispatch(setSearchKey(''))
    // }, [])

    
    useEffect(() => {
        getFormularyList();
    }, [dispatch])

    // search list
    const getSearchValue = (event) => {
        if (event.keyCode === 13) {
            const searchPayLoad = {};
            searchPayLoad['url'] = FORMULARY
            searchPayLoad['method'] = 'Get'
            searchPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: 0, search: event.target.value,sort:sortType }
            searchPayLoad['sortType'] = sortType
            searchPayLoad['setMethod'] = setFormularies
            dispatch(setSearchKey(event.target.value))
            dispatch(getFormularyListAsync(searchPayLoad,history));
        }
    }


    // sorting
    const sortingPayLoad = {};
    sortingPayLoad['url'] = FORMULARY
    sortingPayLoad['method'] = 'Get'
    sortingPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: isNaN(offSet)===true?0:offSet, search: (searchKey) ? searchKey : '',sort:sortType }
    sortingPayLoad['setMethod'] = setFormularies
    sortingPayLoad['setSortType'] = setSortType
    sortingPayLoad['asyncMethod'] = getFormularyListAsync

    // pagination
   
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        setActivePage(data);
        const paginationPayLoad = {};
        paginationPayLoad['url'] = FORMULARY
        paginationPayLoad['method'] = 'Get'
        paginationPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT,sort:sortType }
        paginationPayLoad['setMethod'] = setFormularies
        paginationPayLoad['sortType'] = sortType
        paginationPayLoad['search'] = searchKey
        dispatch(getFormularyListAsync(paginationPayLoad,history));
    }

    // delete
    const deleteList = (data) => {
        dispatch(deleteFormulariesAsync(data, getFormularyList))
    }

    // Table fixed colum setting
    const showFixedColumn = !!formularyList?.formulary && !!formularyList?.formulary?.length
    const columns = [
        {
            Header: 'Formulary ID',
            accessor: 'id',
            fixedColumn: showFixedColumn,
        },
        {
            Header: 'Formulary name',
            accessor: 'formularyName',
            restWidth: '300px'
        },
        {
            Header: 'Customer #',
            accessor: 'customerNumber',
            restWidth: '300px'
        },
        {
            Header: 'Customer name',
            accessor: 'customerName',
            restWidth: '300px'
        },
        {                                 //added for Add columns for create/update date
            Header: 'Created on',
            accessor: 'dateCreated',
            restWidth: "250px"
        },
        {
            Header: 'Updated on',
            accessor: 'dateUpdated',
            restWidth: "250px"
        }
    ]

    // Handel cancel (in create formulary page)
    const handleCancel = () => {
        setCreateFormulary(false)
    }

    // Get selected row details 

    const getSelectedRowData = (data) => {
        history.push('/admin/formulary/' + data.id,{'dyList':{sortType:sortType,activePage:activePage,offSet: isNaN(offSet)===true?0:offSet},'refPath':'admin/formulary/'})
    }
   
    return (
        <>
            {!formularyId && !createFormulary && <ListView
                columns={columns}
                data={formularyList?.formulary}
                metaData={formularyList?.metadata}
                sorting={true}
                rowSelect={isAdminView}
                getSelectedRowData={getSelectedRowData}
                detailView={true}
                pageRangeDisplayed={5}
                searchPlaceholder={"Formulary ID, Formulary Name, Customer #, Customer Name"}
                getSearchValue={(e) => getSearchValue(e)}
                detailsRow={!formularyId}
                activePage={activePage}
                getActivePage={getActivePage}
                listSorting={sortingPayLoad}
                listType={"Formulary"}
                subHeading={'Formulary'}
                showFixedColumn={showFixedColumn}
                deleteListView={deleteList}
                searchKey={(searchKey) ? searchKey : ''}
                fixedFullWidthSize={15}
                setCreateFormulary={setCreateFormulary}                
                accessError={accessError}
                sortType={sortType?sortType:''}
            />}

            {createFormulary && <CreateFormulary handleCancel={handleCancel} dispatch={dispatch} history={history} setCreateFormulary={setCreateFormulary} />}
        </>
    )
}

Formulary.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

Formulary = connect()(withRouter(Formulary));
