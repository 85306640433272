import React from "react";
import PropTypes from 'prop-types';
import style from './checkBox.module.scss'

export function CheckBox({ classes, id = '', label, name = '', defaultChecked = false, validator, errors, disabled=false, onClick,woundReport }) {

    return (
        <div className={`${(classes) ? classes.column : ''}`}>
            <div className={`custom-control custom-checkbox ${style.custom_checkbox} ${(woundReport)? style.index_remove:'' }`}>
                <input
                    type="checkbox"
                    id={id}
                    className={`custom-control-input ${style.custom_control_input} ${(classes) ? classes.inputClassName : ''} ${errors && errors[name] ? 'error-border' : ''}`}
                    value={label}
                    name={name}
                    ref={validator}
                    defaultChecked={defaultChecked}
                    disabled={disabled}
                    onClick={onClick}
                />
                <label htmlFor={id} className={`custom-control-label ${style.custom_control_label} ${(classes) ? classes.labelClassName : ''}`}>
                    {label}
                </label>
            </div>
            {/* Error */}
            {errors && <span className={style.error_message}>{(errors[name]) ? errors[name].message : ''}</span>}
        </div>
    );
}

CheckBox.propTypes = {
    classes: PropTypes.object,
    label: PropTypes.any,
    name: PropTypes.string,
    defaultChecked: PropTypes.string,
    validator: PropTypes.func,
    errors: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
}