import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VerticalTab } from '../../secondary';
import { DressingAppearance } from './dressingAppearance';
import { PrimaryDressing } from './primaryDressing';
import { SecondaryDressing } from './secondaryDressing';
import { Modalities } from "./modalities";
import { AdditionalCare } from "./additionalCare";
import { DressingChangeFrequency } from './dressingChangeFrequency';
import { getInterventionList, addInterventionList, getTreatmentListByIntervention,
  setDressingAppearance,setDebridement,setWoundCleanserGetData,setSkinProtectantGetData,
  setPrimaryDressingGetData,
  setSecondaryDressingGetData,
  setCompressionGetData,
  setModalities,
  setAdditionalCare,
  setdressChangeFrequency,
  setInterventionLoader,
  patientDetailsAsync,
  setInterventionListOptions,
  setTreatmentListIntervention
} from '../../../app/patientSlice';

import { WoundCleanser } from './woundCleanser';
import { Compression } from './compression';
import { SkinProtectant } from './skinProtectant';
import { Debridement } from './debridement';
import styles from '../../secondary/VerticalTab/verticalTab.module.scss';
import { useParams } from 'react-router-dom'
import editIcon from '../../../static/images/plus-sign.svg';
import { updateUserDetailsAsync } from '../../../app/userSlice';
import { format } from 'date-fns';
import { assessmentRbacChecker } from '../../../utils/rbac';
import style from './treatment.module.scss';
import Loader from '../../../static/images/spinner.svg';
export const Treatment = () => {

  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const userDetails = useSelector(state => state.user);
  const patientData = useSelector(state => state.patient.detailViewData)
  const hideButtons = assessmentRbacChecker(userDetails?.role, patientData?.isOwnCustomer);
  // Get values of assessment from state 
  const assessmentDetails = useSelector(state => state.patient.detailAssessment)
  const interventionLoader = useSelector(state => state.patient.interventionLoader)
  useEffect(() => {
      dispatch(setInterventionListOptions([]));
      dispatch(setTreatmentListIntervention({}));
      dispatch(setDressingAppearance({}));
      dispatch(setDebridement({}));
      dispatch(setWoundCleanserGetData({}));
      dispatch(setSkinProtectantGetData({}));
      dispatch(setPrimaryDressingGetData({}));
      dispatch(setSecondaryDressingGetData({}));
      dispatch(setCompressionGetData({}));
      dispatch(setModalities({}));
      dispatch(setAdditionalCare({}));
      dispatch(setdressChangeFrequency({}));
      dispatch(getInterventionList({ patientId, assessmentId }));
  }, [])
  useEffect(() => {
    if (assessmentId) {
      dispatch(getInterventionList({ patientId, assessmentId }));
    }
  }, [dispatch, assessmentId])
  useEffect(() => {
    if (patientId) {
     dispatch(patientDetailsAsync(patientId));
    }
  }, [dispatch, patientId])
  const listOptions = useSelector(state => state.patient.interventionListOptions)
  const dynTab = useSelector(state => state.patient.treatmentListIntervention)

  const [interventionId, setInterventionId] = useState(listOptions && listOptions.length > 0 && listOptions[0] ? listOptions[listOptions.length - 1]['interventionId'] : 0);
  const [interventionName, setInterventionName] = useState(listOptions && listOptions.length > 0 && listOptions[0] ? listOptions[listOptions.length - 1]['interventionName'] : 'Intervention');
  const [viewDropDown, setDropDown] = useState(false)
  const dropDownRef = useRef();
  const [clickedOutside, setClickedOutside] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState('');
  const [formularyId, setFormularyId] = useState(userDetails&&userDetails.userDetails?.formularyId)
  useEffect(() => {
    if (userDetails&&userDetails.userDetails?.formularyId) {
      setFormularyId(userDetails.userDetails?.formularyId);
    }
  }, [dispatch,userDetails])
  //
  /* Function to toggle drop assessment*/
  const handleDropDown = (e) => {
    setDropDown(!viewDropDown)
    setClickedOutside(false)
  }

  /* Trigger Drop Down Items close */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  const handleClickOutside = (e) => {
    if (dropDownRef !== null && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
      setDropDown(false);
      setClickedOutside(true);
    }
  };
  const handleDropChange = (e, i, optionValues) => {
    if (optionValues) {
     
      dispatch(setDressingAppearance({}))
      dispatch(setDebridement({}))
      dispatch(setWoundCleanserGetData({}))
      dispatch(setSkinProtectantGetData({}))
      dispatch(setPrimaryDressingGetData({}))
      dispatch(setSecondaryDressingGetData({}))
      dispatch(setCompressionGetData({}))
      dispatch(setModalities({}))
      dispatch(setAdditionalCare({}))
      dispatch(setdressChangeFrequency({}))
      setInterventionId(optionValues.interventionId)
      setInterventionName(optionValues.interventionName)
     
      
    }
    setDropDown(false);
    setClickedOutside(true);
    setDefaultIndex(102);

  }

  const handleClickAddIntervention = (e) => {
    if (assessmentId) {
      // dispatch(addInterventionList({ patientId, assessmentId }));
      dispatch(setTreatmentListIntervention({}))
      dispatch(setDressingAppearance({}))
      dispatch(setDebridement({}))
      dispatch(setWoundCleanserGetData({}))
      dispatch(setSkinProtectantGetData({}))
      dispatch(setPrimaryDressingGetData({}))
      dispatch(setSecondaryDressingGetData({}))
      dispatch(setCompressionGetData({}))
      dispatch(setModalities({}))
      dispatch(setAdditionalCare({}))
      dispatch(setdressChangeFrequency({}))
      setInterventionId(0)
      setInterventionName('Intervention')
    }
  };

  useEffect(() => {
    if (interventionId !== 0) {
      dispatch(getTreatmentListByIntervention({ patientId, assessmentId, interventionId }));
    }

  }, [interventionId, dispatch])
  let isComplete = []
  let tabList = dynTab && Array.isArray(dynTab) && dynTab?.map((data) => {
    if (data && data.isCompleted && data.isCompleted === 1) {
      isComplete.push({ isCompleted: data.isCompleted })
    }
    const tabsMake = {
      'dressingappearance': {
        name: 'Dressing Appearance',
        content: <DressingAppearance interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },
      'debridement': {
        name: 'Debridement',
        content: <Debridement interventionId={interventionId}  assessmentDetails={assessmentDetails} formularyId={formularyId}/>,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },
      'woundcleanser': {
        name: 'Wound Cleanser',
        content: <WoundCleanser interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },
      'skinprotectant': {
        name: 'Skin Protectant',
        content: <SkinProtectant interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },
      'primarydressing': {
        name: 'Primary Dressing',
        content: <PrimaryDressing interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },
      'secondarydressing': {
        name: 'Secondary Dressing',
        content: <SecondaryDressing interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },
      'compression': {
        name: 'Compression',
        content: <Compression interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },
      'modalities': {
        name: 'Modalities',
        content: <Modalities interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },
      'additionalcare': {
        name: 'Additional Care',
        content: <AdditionalCare interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },
      'dressingchangefrequency': {
        name: 'Dressing Change Frequency',
        content: <DressingChangeFrequency interventionId={interventionId} assessmentDetails={assessmentDetails} formularyId={formularyId}/>,
        completed: (data.isCompleted) ? data.isCompleted : ''
      },

    }
    return tabsMake[data.key]
  })

  tabList = tabList && Object.values(tabList).filter(function (el) {
    return el != null;
  });

  useEffect(() => {
    if (listOptions && listOptions.length > 0 && Array.isArray(listOptions)) {
      setInterventionId(listOptions && listOptions.length > 0 && listOptions[0] ? listOptions[listOptions.length - 1]['interventionId'] : '');
      setInterventionName(listOptions && listOptions.length > 0 && listOptions[0] ? listOptions[listOptions.length - 1]['interventionName'] : '');
    }
  }, [dispatch, listOptions])
  let temptabList = [];
  if (interventionId === 0) {
    temptabList = [
      {
        name: 'Dressing Appearance',
        content: <DressingAppearance interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: ''
      },
      {
        name: 'Debridement',
        content: <Debridement interventionId={interventionId}  assessmentDetails={assessmentDetails} formularyId={formularyId} />,
        completed: ''
      },
      {
        name: 'Wound Cleanser',
        content: <WoundCleanser interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: ''
      },
      {
        name: 'Skin Protectant',
        content: <SkinProtectant interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: ''
      },
      {
        name: 'Primary Dressing',
        content: <PrimaryDressing interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: ''
      },
      {
        name: 'Secondary Dressing',
        content: <SecondaryDressing interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: ''
      },
      {
        name: 'Compression',
        content: <Compression interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: ''
      },
      {
        name: 'Modalities',
        content: <Modalities interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: ''
      },
      {
        name: 'Additional Care',
        content: <AdditionalCare interventionId={interventionId} formularyId={formularyId} assessmentDetails={assessmentDetails} />,
        completed: ''
      },
      {
        name: 'Dressing Change Frequency',
        content: <DressingChangeFrequency interventionId={interventionId} assessmentDetails={assessmentDetails}  formularyId={formularyId} />,
        completed: ''
      },

    ]
  }
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    if(interventionLoader){
      dispatch(setInterventionLoader(false))
      setTimeout(() => {
        setTinyDelay(true)
      }, 500)
    }
  }, [dispatch,interventionLoader])
  return (
 <>
     {tinyDelay?
    <div>
      <div className={`${styles.intervention_blk}`}>
        <div className="dropdown">
          <div className={styles.new_select_blk}>
            <input
              type={'hidden'}
              id={'interventionId'}
              defaultValue={interventionId}
            />
            {listOptions && listOptions?.length === 0 ? <div className={`${styles.select_heading} interventionName`}>{'Intervention'}</div> : <div className={`${styles.select_heading} interventionName`} onClick={handleDropDown} ref={dropDownRef}>{interventionName}</div>}
            {isComplete && isComplete[0] && isComplete[0].isCompleted === 1 && hideButtons ? <div className={styles.select_btn}><img src={editIcon} alt="btn" onClick={handleClickAddIntervention} /></div> : <div className={style.disabled_select_btn}><img src={editIcon} alt="btn" /></div>}

          </div>
          {viewDropDown &&
            <div className={`dropdown-menu show ${styles.intervene_dropdown}`} ref={dropDownRef}>
              {
                listOptions && listOptions?.length > 0 && listOptions?.map((optionValues, i) => {
                  return (
                    <>
                      <p className={`dropdown-item `} onClick={e => handleDropChange(e, i, optionValues)}>
                        <label>{optionValues.interventionName ? optionValues.interventionName : ''}</label>
                        <span>Last updated by: <strong>{optionValues.lastUpdatedBy ? optionValues.lastUpdatedBy : ''}</strong></span>
                        <span>Last updated on: {optionValues.lastUpdatedDate ? format(new Date(optionValues.lastUpdatedDate), 'MMM dd, yyyy') : ''}</span>
                      </p>
                    </>
                  )

                })
              }

            </div>
          }
        </div>
      </div>
      {(interventionId === 0) && (temptabList != '') ? <VerticalTab tabList={temptabList} defaultIndex={defaultIndex} dispatch={dispatch} InterventionTab={true} interventionId={interventionId} /> : <VerticalTab tabList={tabList} defaultIndex={defaultIndex} dispatch={dispatch} InterventionTab={true} interventionId={interventionId} />
      }
    </div>:
   <div className={styles.loader}>
    <img src={Loader} alt="loading" />
  </div>
}
  </>
    
  )
}
