import React from 'react';
import { UsageAnalyticsReport } from '../components/core/UsageAnalyticsReport'

export const UsageAnalytics = ({history}) => {
  return (
    <>
      <UsageAnalyticsReport history={history}/>
    </>
  )
}
