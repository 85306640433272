import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { form_shadow,btn_custom, btn_small } from "../styles/common.module.scss";
import { Banner } from "../components/secondary/Banner";
import { Logo } from "../components/secondary/Logo";
import { Info, Modal } from "../components/secondary";
import { useDispatch } from "react-redux";
import {
  logout,
  setVerificationLinkErr,
  setVerificationOtpCode,
  resendOtpCodeAsync,
  resendUserActivationAsync,
  setResendEmailMsg,
  resendOtpCodeAsyncForgetPass,
  resendForgetPassAsync
} from "../app/userSlice";
import { setAccessError, setError } from "../app/appSlice";

import {
  Button,
  Form,
  RadioButton,
  TextField,
  AccessErrorModal,
} from "../components/primary";
import successIcon from "../static/images/icons-check-success.png";
import iconDanger from "../static/images/icon-check-danger.png";
import { handleClickMove } from "../utils";

export function Status({ match: { params }, history }) {
  const dispatch = useDispatch();
  const verificationLinkErr = useSelector((state) => state.user.verificationLinkErr);
  const VerificationOtpCode = useSelector((state) => state.user.VerificationOtpCode);//added
  const resendEmailMsg = useSelector((state) => state.user.resendEmailMsg);
  const paramLocationData = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  
  useEffect(() => {
    dispatch(setError(""));
    dispatch(setAccessError(null));
    dispatch(logout());
    dispatch(setResendEmailMsg({}));
  }, [dispatch]);

  const modalClose = () => {
    setModalOpen(false);
    dispatch(setResendEmailMsg({}));
  };
  useEffect(() => {
    if (params && params.type !== "verificationlinkexpired") {
      dispatch(setVerificationLinkErr(''));
    }
  }, [dispatch, params]);

  /*useEffect(()=>{  //added
    if(params && params.type !== "codeexpired"){
      dispatch(setVerificationOtpCode(''));
    }
  }, [dispatch, params]);*/
  

  useEffect(() => {
    if (resendEmailMsg && resendEmailMsg?.status) {
      setModalOpen(true);
    }
  }, [resendEmailMsg]);


  const resendEmail = (email, token, history,page) => {
    dispatch(resendUserActivationAsync(email, token, history,page));
  };
  const resendEmailForget = (email, token, history,page) => {
    dispatch(resendForgetPassAsync(email, token, history,page));
  };
  const otpCodeEmail = (email, code, history,status_type) => {  //added
    if(status_type&&status_type==="forgotpasswordsuccess"){
      dispatch(resendOtpCodeAsyncForgetPass(email, code, history,status_type));
    }else{
      dispatch(resendOtpCodeAsync(email, code, history,status_type));
    }
  
  }
  
  const _info = {
    accountstatus: (
      <Info
        title={"Congratulations!"}
        desc={"Please check your email for activation link."}
        btn={{ to: "/login", value: "Continue to login" }}
        status_type={"accountstatus"}
        dynParam={paramLocationData?.state?.dynParam}
        history={history}
      />
    ),
    registrationsuccess: (
      <Info
        title={"Congratulations!"}
        desc={"Please check your email for activation link."}
        btn={{ to: "/login", value: "Submit" }}
        status_type={"registrationsuccess"}
        dynParam={paramLocationData?.state?.dynParam}
        history={history}
        resendEmail={resendEmail}
        otpCodeEmail={otpCodeEmail}
      />
    ),
    forgotpasswordsuccess: (
      <Info
        title={"Great!"}
        desc={"Please check your email to reset your password"}
        status_type={"forgotpasswordsuccess"}
        history={history}
        btn={{ to: "/login", value: "Next" }}
        dynParam={paramLocationData?.state?.dynParam}
        resendEmailForget={resendEmailForget}
        otpCodeEmail={otpCodeEmail}
      />
    ),
    resetpasswordsuccess: (
      <Info
        title={"Great!"}
        desc={"Your password has been changed"}
        btn={{ to: "/login", value: "Continue to login" }}
        status_type={"resetpasswordsuccess"}
        history={history}
      />
    )
    ,
    createpasswordsuccess: (
      <Info
        title={"Great!"}
        desc={"Your password has been created"}
        btn={{ to: "/login", value: "Continue to login" }}
        status_type={"createpasswordsuccess"}
        history={history}
      />
    ),
    activationsuccess: (
      <Info
        title={"Email verified!"}
        desc={"Your account has been activated"}
        btn={{ to: "/login", value: "Continue to login" }}
        status_type={"activationsuccess"}
        history={history}
      />
    ),
    /* When verification link expired */
    verificationlinkexpired: (<>
    {verificationLinkErr && <Info
        title={verificationLinkErr &&verificationLinkErr !=='Invalid Token.' && verificationLinkErr !=='Token expired, retry.' && verificationLinkErr!=='User activation request does not exists.'&& verificationLinkErr!=='Email sent, please check your inbox.'? `${verificationLinkErr}` : "The link has expired!"}
        desc={''}
       // desc={verificationLinkErr &&verificationLinkErr !=='Invalid Token.' && verificationLinkErr!=='User activation request does not exists.' && verificationLinkErr !=='Token expired, retry.' ? `${verificationLinkErr}` : ""}
        btn={
          verificationLinkErr &&verificationLinkErr !=='Invalid Token.'&& verificationLinkErr !=='Token expired, retry.' && verificationLinkErr!=='User activation request does not exists.'&& verificationLinkErr!=='Email sent, please check your inbox.'
            ? { to: "/login", value: "Continue to login" }
            : { to: "/userActivation", value: "Resend the link" }
        }
        warning={true}
        status_type={"verificationlinkexpired"}
        history={history}
        dynParam={paramLocationData?.state?.dynParam}
        resendEmail={resendEmail}
      />}
      </>
     
    ),
    // codeexpired: (
    //   <>
    //   {VerificationOtpCode && <Info 
    //         title={VerificationOtpCode && VerificationOtpCode !== 'Invalid code.' && VerificationOtpCode !== 'Code expired, retry' && VerificationOtpCode !== 'User activation request does not exisit' && VerificationOtpCode !== 'Email send, please check you inbox' ? `${VerificationOtpCode}` : "The code has expired!"} 
    //         desc={''}
    //         btn={
    //           VerificationOtpCode && VerificationOtpCode !=='Invalid code.'&& VerificationOtpCode !=='Code expired, retry.' && VerificationOtpCode!=='User activation request does not exists.'&& VerificationOtpCode !=='Email sent, please check your inbox.'
    //             ? { to: "/login", value: "Continue to login" }
    //             : { to: "/userActivation", value: "Resend the " }            
    //         }code
    //         warning={true}
    //         status_type={"codeexpired"}
    //         history={history}
    //         dynParam={paramLocationData?.state?.dynParam}
    //         otpCodeEmail={otpCodeEmail}
    //       />
    //     }
    //   </>
    // )
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row vh-100">
          <div className="col-sm-12 col-lg-6">
            <Logo />
            <div className="container pt-3">
              <div className="row">
                <div className={`col-12 col-sm-12 mx-auto pt-5 pb-5 ${form_shadow}`}>
                  {params && _info[params.type]}
                </div>
              </div>
            </div>
          </div>
          <Banner />
        </div>
      </div>
      {resendEmailMsg &&
        resendEmailMsg?.status &&
        (resendEmailMsg?.status === "error" ||
          resendEmailMsg?.status === "success") && (
          <Modal modalOpen={modalOpen} modalClose={modalClose}>
            <>
              <div class="text-center pr-5 pl-4 mt-4">
                <p>
                  <img
                    src={
                      resendEmailMsg?.status === "error"
                        ? iconDanger
                        : successIcon
                    }
                    alt={resendEmailMsg?.status === "error" ? "Error" : ""}
                  />
                </p>
                <p className="pb-3"> {resendEmailMsg?.message} </p>
              </div>
            </>

            <div className="col-sm-12">
              <div className="text-center pb-4">
                <Button
                  type={"reset"}
                  value={"Close"}
                  classes={{ className: `btn ${btn_custom} ${btn_small}` }}
                  onClick={modalClose}
                />
              </div>
            </div>
          </Modal>
        )}
    </>
  );
}
