import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button, Form, TextField } from "../../primary";
import styles from "./organization.module.scss";
import EditIconBlue from "../../../static/images/edit-icon-blue.png";
import closeIcon from "../../../static/images/Close_Normal.svg";
import {
  checkCustomerAsync,
  getFacilityByIdAsync,
  resetFacilitiesDataById,
  customerUpdateAsync,
  setEditState,
} from "../../../app/customerSlice";
import {
  CUSTOMER_UPDATE_SUCCESS,
  UPDATE_FAILURE,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from "../../../constants";
import { getFacilityLabel } from "../../../utils";
import { useForm } from "react-hook-form";
import { AccessErrorModal } from "../../primary";
import Loader from '../../../static/images/spinner.svg';
export function Organization({ customerData, dispatch, history, isOrgSave }) {
  const LINK_EXISTING_LBL = "Link";
  const UNLINK_LBL = "Unlink";
  const SEARCH_ERR_MSG = "Invalid customer number";
  const PRIMARY_REQ_MSG = "Please enter customer number";
  const { register, handleSubmit, watch, errors, reset } = useForm({
    mode: "onSubmit",
  });
  const [soldToNumber, setSoldToNumber] = useState(
    customerData?.customerNumber ? customerData?.customerNumber : ""
  );
  let [shipOptions, setShipOptions] = useState([]);
  let organization = customerData?.organization;

  const [formStep2, setStep2] = useState(false);
  const [contentStep3, setContentStep3] = useState(false);
  const [editable, setEditable] = useState(false);
  const accessError = useSelector((state) => state.app.accessError);
  /* Get All Facility or  data */
  const getFacilities = useSelector((state) => state.customer.facilitiesData);
  let organizationMake = { primaryGroupNumber: "", soldToNumbers: [] };
  let shipOptionsDyn = [];
  if (organization && organization.primaryGroupNumber) {
    getFacilities &&
      getFacilities.data &&
      organization &&
      getFacilities.data.facilities.map((value, index) => {
        if (
          organization &&
          organization.primaryGroupNumber == value.facilityNumber
        ) {
          organizationMake["primaryGroupNumber"] = getFacilityLabel(value);
          organizationMake["id"] = value.id;
        }
      });
  }

  organization &&
    organization.soldToNumbers &&
    organization.soldToNumbers.map((soldValue, soldIndex) => {
      getFacilities &&
        getFacilities.data &&
        organization &&
        getFacilities.data.facilities.map((value, index) => {
          if (soldValue.soldToNumber == value.facilityNumber) {
            organizationMake["soldToNumbers"].push({
              soldToNumber: getFacilityLabel(value),
              ref1: soldValue.soldToNumber,
              shipToNumbers: [],
              id: value.id,
            });
            soldValue.shipToNumbers &&
              Array.isArray(soldValue.shipToNumbers) &&
              soldValue.shipToNumbers.map((shipValue, shipIndex) => {
                if (organizationMake["soldToNumbers"][soldIndex] !== "") {
                  getFacilities.data &&
                    organization &&
                    getFacilities.data.facilities.map((value, index) => {
                      if (
                        value.soldToNumber !== "" &&
                        value.facilityNumber == shipValue
                      ) {
                        if (
                          organizationMake["soldToNumbers"][soldIndex] &&
                          organizationMake["soldToNumbers"][soldIndex]
                            .shipToNumbers
                        ) {
                          organizationMake["soldToNumbers"][
                            soldIndex
                          ].shipToNumbers.push({'shipTo':getFacilityLabel(value),'ids':value.id});
                        }

                        if (
                          customerData?.customerNumber ===
                          soldValue.soldToNumber
                        ) {
                          shipOptionsDyn.push({
                            key: value.facilityNumber,
                            value: getFacilityLabel(value),
                          });
                        }
                      }
                    });
                }
              });
          }
        });
    });
  const [primaryDisabled, setPrimaryDisabled] = useState(
    organization && organization?.primaryGroupNumber ? true : false
  );
  let [primaryGroupOnly, setPrimaryGroupOnly] = useState(
    organization && organization?.primaryGroupNumber
      ? organization.primaryGroupNumber
      : ""
  );
  const [primaryGroupErr, setPrimaryGroupErr] = useState(false);
  const [shipToErr, setShipToErr] = useState(false);
  const [shipTo, setShipTo] = useState("");
  organization = organizationMake;

  let [primaryGroupValue, setPrimaryGroupValue] = useState(
    organization && organization.primaryGroupNumber
      ? organization.primaryGroupNumber
      : ""
  );

  /* Function to toggle edit */
  const handleEdit = (organization) => {
    setShipOptions(shipOptionsDyn);
    resetState();
    setEditable(!editable);
    setContentStep3(!contentStep3);
    setStep2(!formStep2);
    setPrimaryGroupValue("");
    if (organization && organization.primaryGroupNumber) {
      setPrimaryGroupValue(organization.primaryGroupNumber);
      setPrimaryGroupOnly(organization.primaryGroupNumber.split("|")[0].trim());
    }
  };
  /*Reset state*/
  const resetState = () => {
    reset();
    dispatch(resetFacilitiesDataById());
    setPrimaryDisabled(
      organization && organization.primaryGroupNumber ? true : false
    );
    setShipToErr("");
    setPrimaryGroupErr("");
    setShipTo("");
  };

  const handlPrimary = () => {
    setPrimaryGroupErr(false);
    setPrimaryGroupErr(false);
    if (primaryDisabled !== false) {
      document.getElementById("primaryGroup").value = "";
      setPrimaryGroupOnly("");
      setPrimaryGroupValue("");
      setPrimaryDisabled(false);
    } else {
      //setPrimaryDisabled(true)
      if (document.getElementById("primaryGroup").value) {
        dispatch(
          getFacilityByIdAsync(
            { id: primaryGroupValue.trim() },
            primaryGroupValue.trim()
          )
        )
          .then((result) => {
            setTimeout(() => {
              if (result && result.status === "success") {
                const getFacilitiesDataByid = result;
                if (
                  !getFacilitiesDataByid.errorMessage &&
                  getFacilitiesDataByid &&
                  getFacilitiesDataByid.data !== null &&
                  getFacilitiesDataByid &&
                  getFacilitiesDataByid.status === "success"
                ) {
                  let value = getFacilitiesDataByid
                    ? getFacilitiesDataByid.data
                    : "";
                  if (value.accountGroup === "Z002") {
                    setPrimaryGroupValue(primaryGroupValue);//NOSONAR
                    primaryGroupValue = getFacilityLabel(value);
                    primaryGroupOnly = `${value.facilityNumber}`;
                    document.getElementById(
                      "primaryGroup"
                    ).value = primaryGroupValue;
                    setPrimaryGroupOnly(primaryGroupOnly);//NOSONAR
                    setPrimaryDisabled(true);
                  } else {
                    setPrimaryGroupErr(true);
                    setPrimaryDisabled(false);
                  }
                }
              } else if (result === "" || result === undefined) {
                setPrimaryGroupErr(true);
              }
            }, 500);
          })
          .catch((error) => { });
      }
    }

    // setPrimaryDisabled(!primaryDisabled)
  };

  const handlShip = (e) => {
    e.preventDefault();
    setShipToErr(false);
    if (shipTo !== "") {
      dispatch(resetFacilitiesDataById());
      dispatch(getFacilityByIdAsync({ id: shipTo.trim() }, shipTo.trim()))
        .then((result) => {
          setTimeout(() => {
            if (result && result.status === "success") {
              const getFacilitiesDataByid = result;
              if (
                !getFacilitiesDataByid.errorMessage &&
                getFacilitiesDataByid &&
                getFacilitiesDataByid.data !== null &&
                getFacilitiesDataByid &&
                getFacilitiesDataByid.status === "success"
              ) {
                let value = getFacilitiesDataByid
                  ? getFacilitiesDataByid.data
                  : "";
                if (value.accountGroup === "0002") {
                  let newShip = {
                    key: value.facilityNumber,
                    value: getFacilityLabel(value),
                  };
                  shipOptions.push(newShip);
                  setShipOptions(shipOptions);//NOSONAR
                  setShipTo("");
                  document.getElementById("shipTo").value = "";
                } else {
                  setShipToErr(true);
                }
              }
            } else if (result === "" || result === undefined) {
              setShipToErr(true);
            }
          }, 500);
        })
        .catch((error) => { });
    }
  };

  const getShip = (e) => {
    setShipTo(e.target.value);
  };
  const getPrimaryGroup = (e) => {
    primaryGroupValue = e.target.value;
    setPrimaryGroupValue(primaryGroupValue);//NOSONAR
    setPrimaryGroupErr(false);
  };

  //handlShipDelete
  const handlShipDelete = (e, val) => {
    let list = [...shipOptions];
    list.splice(val, 1);
    setShipOptions(list);
  };
  /*final form submit*/

  const saveStep2 = (data, e) => {
    e.preventDefault();
    let checkFaci = "";
    if (document.getElementById("primaryGroup").value) {
      checkFaci = document
        .getElementById("primaryGroup")
        .value.split("|")[0]
        .trim();
    }
    if (
      primaryGroupOnly &&
      document.getElementById("primaryGroup").value &&
      primaryGroupOnly === checkFaci
    ) {
      let shipToNumbers = [];
      if (shipOptions && shipOptions.length > 0) {//NOSONAR
        shipOptions.forEach(function (key, index) {//NOSONAR
          return shipToNumbers.push(key.key);//NOSONAR
        });//NOSONAR
        shipToNumbers.join(",");//NOSONAR
      }//NOSONAR
      const tempData = {
        organization: {
          primaryGroupNumber: primaryGroupOnly,
          soldToNumbers: [
            {
              soldToNumber: soldToNumber, //facility number
              shipToNumbers: shipToNumbers,
            },
          ],
        },
      };
      /* Merging default object with new data */
      const result = { ...customerData, ...tempData };
      dispatch(customerUpdateAsync(result));
      setContentStep3(!contentStep3);
      setStep2(!formStep2);
      setEditable(!editable);
      resetState();
    } else {
      setPrimaryGroupErr(true);
    }
  };

  const userDetails = useSelector((state) => state.user);
  const adminView = userDetails?.role;
  const [externalAdmin, setExternalAdmin] = useState(false);
  useEffect(() => {
    if (adminView === "ExternalAdministrator") {
      setExternalAdmin(true);
    }
  }, [adminView]);
  /* Edit success */
  const editState = useSelector((state) => state.customer.editState);

  useEffect(() => {
    if (editState === "success") {
      setEditable(!editable)
    }
    setTimeout(() => {
      dispatch(setEditState(false));
    }, 5000);
  }, [editState, dispatch]);
  useEffect(() => {
    dispatch(
      checkCustomerAsync({ customer: customerData?.customerNumber }, history)
    );
  }, [dispatch]);
  const [tinyDelay, setTinyDelay] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 3000)
  }, [])
  return (
    <div>
      <div className={`${styles.tab_content_top}`}>
        <h2 className={`${styles.tab_content_hdg}`}>
          {customerData?.customerName}
        </h2>
        {tinyDelay&&!editable &&
          !externalAdmin &&
          customerData &&
          customerData?.accountGroup === "0001" &&
          adminView !== "ExternalAdministrator" && (
            <div
              className={`float-sm-right ${styles.tab_edit}`}
              onClick={() => handleEdit(organization)}
            >
              <img src={EditIconBlue} alt="edit" />
              Edit
            </div>
          )}
      </div>
      {!tinyDelay ?<div className={`${styles.loader} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
      <div className={`col-sm-12 min-vh-100 ${styles.single_product_block}`}>
        <div className="row">
          {!contentStep3 && (
            <div className="col-sm-12">
              {/* Edit success */}
              {editState === SUCCESS_STATUS && (
                <div className={`alert alert-success ${styles.form_alert}`}>
                  {CUSTOMER_UPDATE_SUCCESS}
                </div>
              )}

              {/* Edit failure */}
              {editState === ERROR_STATUS && (
                <div className={`alert alert-danger ${styles.form_alert}`}>
                  {UPDATE_FAILURE}
                </div>
              )}
              {organization && (
                <>
                  <div className={`${styles.organization_top}`}>
                    <p className={`${styles.heading_bold}`}>Primary Group:</p>
                    <p className={`pl-4 ${styles.normal_text_primary}`}>
                    <a href={`/admin/customers/${organization?.id}`}> 
                      {(organization.primaryGroupNumber).split('|',1)}</a>
                       {'|' + (organization.primaryGroupNumber).split('|').slice(1,6).join('|')}
                    </p>
                  </div>

                  {organization.soldToNumbers &&
                    organization.soldToNumbers.map(
                      (key, index) =>
                        key.soldToNumber && (
                          <div key={index+'tes'}>
                            {key.soldToNumber && (
                              <div className={`${styles.organizaion_1}`}>
                                <p className={`pl-4 ${styles.heading_bold} `}>
                                  Sold To:
                                </p>
                                <p
                                  className={`pl-4 ml-1 ${styles.normal_text}`}
                                >
                                  <a href={`/admin/customers/${key.id}`}> 
                                  {(key.soldToNumber).split('|',1)} </a>
                                    { '|' + (key.soldToNumber).split('|').slice(1,6).join('|')}
                                </p>
                              </div>
                            )}
                            <div className={` ${styles.organizaion_2}`}>
                              {key.shipToNumbers &&
                                Array.isArray(key.shipToNumbers) &&
                                key.shipToNumbers.length > 0 && (
                                  <p
                                    className={`pl-5 ${styles.heading_bold}`}
                                  >
                                    Ship To:
                                  </p>
                                )}
                              {key.shipToNumbers &&
                                Array.isArray(key.shipToNumbers) &&
                                key.shipToNumbers.map((shipIndex) => (
                                  <p
                                    key={shipIndex.ids+'tes1'}
                                    className={`ml-6 ${styles.text_shipto}`}>
                                  
                                    {" "}
                                    <a href={`/admin/customers/${shipIndex.ids}`}> 
                                    {(shipIndex.shipTo).split('|',1)}</a>
                                     {'|' + (shipIndex.shipTo).split('|').slice(1,6).join('|')}
                                  </p>
                                ))}
                            </div>
                          </div>
                        )
                    )}
                </>
              )}
            </div>
          )}
          {/*Form 2*/}

          {formStep2 && (
            <div className={` ${styles.organization_content}`}>
              <Form formChanges={true} onSubmit={handleSubmit(saveStep2)} id="organizationForm">
                <div className={`p-0 ${styles.patient_content}`}>
                  <div className={`col-12  ${styles.col_spec}`}>
                    <input
                      type="hidden"
                      id="primaryGroupOnly"
                      name="primaryGroupOnly"
                      defaultValue={primaryGroupOnly}
                    />
                    <TextField
                      id={"primaryGroup"}
                      name={"primaryGroup"}
                      label={"Primary Group #:"}
                      classes={{
                        column: `col-8 ${styles.col_spec}`,
                        labelClass: `col-4 col-form-label ${styles.label_spec}`,
                        inputColumn: "col-8 p-0",
                        inputClassName: `${styles.basic_input}`,
                      }}
                      type="text"
                      onChange={getPrimaryGroup}
                      defaultValue={primaryGroupValue}
                      customError={
                        document.getElementById("primaryGroup") &&
                          document.getElementById("primaryGroup").value &&
                          primaryGroupErr
                          ? SEARCH_ERR_MSG
                          : ""
                      }
                      disabled={primaryDisabled ? true : false}
                      validator={register({
                        required: PRIMARY_REQ_MSG,
                      })}
                      errors={errors}
                      required={true}
                    />
                    <span></span>
                    <div className="ml-4 d-inline">
                      {primaryDisabled ? (
                        <button
                          type="button"
                          id="unlinkButton"
                          className={`btn btn-custom btn-small-cancel align-top ${styles.btn_small_cancel}`}
                          onClick={handlPrimary}
                        >
                          {UNLINK_LBL}
                        </button>
                      ) : (
                        <button
                          type="button"
                          id="unlinkButton"
                          className={`btn btn-custom btn-small-cancel align-top ${styles.btn_small_cancel}`}
                          onClick={handlPrimary}
                        >
                          {LINK_EXISTING_LBL}
                        </button>
                      )}
                    </div>
                  </div>

                  <div className={`col-12  ${styles.col_spec}`}>
                    <TextField
                      id={"shipTo"}
                      name={"shipTo"}
                      label={"Ship To #:"}
                      onChange={getShip}
                      classes={{
                        column: `col-8 ${styles.col_spec}`,
                        labelClass: `col-4 col-form-label ${styles.label_spec}`,
                        inputColumn: "col-8 p-0",
                        inputClassName: `${styles.basic_input}`,
                      }}
                      type="text"
                      defaultValue={!shipTo ? shipTo : ""}
                      customError={shipToErr ? SEARCH_ERR_MSG : ""}
                    />

                    <div className="ml-4 d-inline">
                      <button
                        type="button"
                        id="handlShip"
                        className={`btn btn-custom btn-small-cancel align-top ${styles.btn_small_cancel}`}
                        onClick={handlShip}
                      >
                        Link
                      </button>
                    </div>
                  </div>
                  {shipOptions.length > 0 && (
                    <div className="col-12 col_spec row">
                      <div className="col-8 col_spec">
                        <div
                          className={`col-8 p-0 col_spec offset-4 ${styles.org_drop}`}
                        >
                          <div className={`col-spec ${styles.org_close_box}`}>
                            {shipOptions.map(
                              (key, index) =>
                                key.value && (
                                  <p
                                    key={index+'tes2'}
                                    className={`dropdown-item  ${styles.org_items}`}
                                    title={key.value}
                                  >
                                    {key.value}
                                    <a className={` ${styles.close_icon}`}>
                                      <img
                                        className={`deleteOrg`}
                                        onClick={(e) =>
                                          handlShipDelete(e, index)
                                        }
                                        src={closeIcon}
                                        alt=""
                                      />
                                    </a>
                                  </p>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-sm-12">
                  <div className="text-center">
                    <Button
                      type={"submit"}
                      value={"Save"}
                      classes={{
                        className: `btn  mr-4 ${styles.btn_small} ${styles.btn_custom}`,
                      }}
                    />

                    <Button
                      type={"button"}
                      value={"Cancel"}
                      id={"cancel_org"}
                      classes={{
                        className: `cancel_org btn ${styles.btn_custom} ${styles.btn_small_cancel}  ${styles.ship_spec}`,
                      }}
                      onClick={() => handleEdit(organization)}
                    />
                  </div>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>}

      {accessError && <AccessErrorModal />}
    </div>
  );
}

Organization.propTypes = {
  dispatch: PropTypes.func,
};

Organization = connect()(Organization);
