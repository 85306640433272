import React from 'react'

export class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            hasError: false,
            error_message: '',
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        this.setState({
            error_message: error.toString()
        })
    }

    render() {
        if (this.state.hasError) {
            return (<div> 
                        <h2 style={{margin: '150px auto', textAlign: 'center', maxWidth: '500px', color: "#555"}}>Something went wrong!!!
                        <br />
                        <span style={{fontSize: '20px', fontWeight: 'normal'}}>Please contact the administrator.</span>
                        <br />{this.state.error_message}</h2>
                    </div>)
        }
        return this.props.children
    }
}

export default ErrorBoundary