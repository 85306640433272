export const onSubmitUserData = (data, onselectOrgz, typedCustomerNumber, dispatch, createUserAsync, setMsgModal, modalRefresh, reset, resMsgState, customerNumber, role) => {
    let key = [];
    onselectOrgz?.map((item) => { //NOSONAR
        let facility = item.key.split("|")[0].trim();
        key = [...key, facility];
    }) //NOSONAR
    console.log('key1',key)
    key=removeDuplicates(key)
    console.log('key2',key)
    const tempUserData = {
        customerNumber: role !== 'ExternalAdministrator' ? typedCustomerNumber : customerNumber,
        email: data.email,
        facilities: key,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        role: data.roleID,
        actionUrl: `${window.location.origin}/`,
    }
    dispatch(createUserAsync(tempUserData))
    if(resMsgState === "success") { 
        setMsgModal(true);
        reset();
    }       
}

//SSO Payload
export const onSubmitSsoData = (data, dispatch, createSsoAsync, setMsgModal, modalRefresh, reset, resMsgState, role) => {  
    const tempUserData = {
        ssodomainname: data.ssodomainname,
        tenant: data.tenant,
        department: data.department,
        title: data.title,
        primarygroup: data.primarygroup,
        facilities: data.facilities,
        role: data.role,
        assigntoallemails: data.assigntoallemails,
        assigntospecificemails: data.assigntospecificemails,
        excludetospecificemails: data.excludetospecificemails,
        isActiveStatus: data.isActiveStatus,
        isSSOEnable:data.isSSOEnable,
        isTokenizedFacility:data.isTokenizedFacility
    }
    dispatch(createSsoAsync(tempUserData))
    if(resMsgState === "success") { 
        setMsgModal(true);
        reset();
    }       
}

function removeDuplicates(arr) {
    var unique = [];
    arr.forEach(element => {
        if (!unique.includes(element)) {
            unique.push(element);
        }
    });
    return unique;
}