import React, { useState, useEffect } from 'react'
import { SsoBasicInfo } from '../components/core/Users';
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { withRouter, useParams } from 'react-router-dom'
import { DetailView } from '../components';
import { ListView } from '../components/secondary/ListView';
import { LIST_VIEW_LIMIT, SUCCESS_STATUS, SSO} from '../constants';
import {
    resetUsers,
    ssoDetailsAsync,
    ssoUpdateAsync,
    setDetailSsoViewData,
    deleteSsoListAsync,
    uploadFile,
    getUserRoleAsync,
    setUploadStatus,
    getSsoCallBackListAsync,
} from '../app/userSlice';
import { List, Search, Sorting, Pagination, Delete, SelectedRow} from '../utils';


export let Sso = ({ dispatch, history, location }) => {
    const accessError = useSelector(state => state?.app?.accessError)
    let userDetails = useSelector(state => state.user);
    let customerNumber = 0;
    if (userDetails) {
        customerNumber = userDetails.customerNumber
    }
    const [offSet, setOffSet] = useState(0)
    /* Get Sso table list details */
    let ssoList = useSelector(state => state?.user?.ssoUser);
    /* Get state with the Individual customer data */
    const userData = useSelector(state => state?.user?.detailSsoViewData)
    /* update user data */
    const updateSsoData = (data) => dispatch(ssoUpdateAsync(data))
    /* Tabs object */
    const tabList = [
        {
            name: 'Sso Info',
            content: <SsoBasicInfo userData={userData} facilityOptions={''} updateSsoData={updateSsoData} />
        }
    ];

    /* Check url for Sso id */
    const { ssoId } = useParams()
    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);

    const [searchKey, setSearchKey] = useState('')
    const [sortType, setSortType] = useState('')

    /* Creating Sso Details */
    const getUserList = () => {
        List(SSO, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getSsoCallBackListAsync);
    }

    /* when dispatch call */
    useEffect(() => {
        setSortType('')
        setSearchKey('')
        dispatch(resetUsers())
        getUserList();
    }, [dispatch]);

    const showFixedColumn = !!ssoList?.data && !!ssoList?.data?.ssoTenantMappingList?.length
    let columns = [
        {
            Header: 'Domain name',
            accessor: 'domainName',
            fixedColumn: showFixedColumn,
            // customWidth: "100px",
            fixedWidth: '250px'
        },
        {
            Header: 'Enable SSO',
            accessor: 'isSSOEnable',
            fixedColumn: showFixedColumn,
            // customWidth: "100px",
            fixedWidth: '120px'
        },
        {
            Header: 'Tokenized facility',
            accessor: 'isTokenizedFacility',
            fixedColumn: showFixedColumn,
            fixedWidth: '150px'
        },
        {
            Header: 'Tenant',
            accessor: 'tenant',
            // fixedColumn: showFixedColumn,
            // fixedWidth: '140px'
            restWidth: "140px"
        }, 
        {
            Header: 'Department',
            accessor: 'department',
            restWidth: "150px"
        }, 
        {
            Header: 'Title',
            accessor: 'title',
            restWidth: "150px"
        },        
        {
            Header: 'Primary group',
            accessor: 'primaryGroup',
            restWidth: "150px"
        },  
        {
            Header: 'Facility',
            accessor: 'facilities',
            restWidth: "230px"
        }, 
        {
            Header: 'All email addresses',
            accessor: 'assignToAllEmails',
            restWidth: "170px"
        },      
        {
            Header: 'Specific email addresses',
            accessor: 'assignToSpecificEmail',
            restWidth: "250px"
        },
        {
            Header: 'Role',
            accessor: 'role',
            restWidth: "170px"
        },
        {
            Header: 'Date created',
            accessor: 'dateCreated',
            restWidth: "220px"
        },
        {
            Header: 'Date updated',
            accessor: 'dateUpdated',
            restWidth: "220px"
        },
        {
            Header: 'Include/Exclude',
            accessor: 'excludeToSpecificEmail',
            restWidth: "130px"
        },
    ];

    

    /* If no Sso id in url, dispatch empty data */
    useEffect(() => {
        if (!ssoId) {
            dispatch(setDetailSsoViewData({}))
            dispatch(getUserRoleAsync());
        }
    }, [ssoId, dispatch])

    /* If Sso id exists display detail view */
    useEffect(() => {
        if (ssoId) {
            /* Fetch data using parameter from url */
            dispatch(ssoDetailsAsync(ssoId,history));
        }
    }, [ssoId, dispatch])

    /* Selected Sso by ID details */
    const [detailsRowData, setDetailsRowData] = useState([])
    const getSelectedRowData = (dataget) => {
        let data = {
            id: ""
        }
        data.id = dataget.ssoTenantMappingId
        SelectedRow(data, setDetailsRowData, 'sso', history, isAdminView);
    }    

    // search list
    const getSearchValue = (event) => {        
        if (event && event.keyCode === 13) {
            if (event && event.target.value !== '') {
                setOffSet(0)
                setActivePage(1)
                setSearchKey(event.target.value)
                Search(event, SSO, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getSsoCallBackListAsync,sortType, '', '') 
            } 
            else {
                setOffSet(0)
                setActivePage(1)
                getUserList();
                setSearchKey('')
                setSortType('')
            }
        }
    }

    // pagination
    const [activePage, setActivePage] = useState(1);
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)  
        Pagination(data, setActivePage, SSO, 'Get', LIST_VIEW_LIMIT, dispatch, getSsoCallBackListAsync, searchKey,'',sortType,'','')  
    }
     
    // sorting
    var sortingPayLoad = Sorting(SSO, 'Get', LIST_VIEW_LIMIT, offSet, getSsoCallBackListAsync, searchKey,sortType,setSortType, '', '')
  
    //delete
    const deleteList = (data) => {
        Delete(data, dispatch, deleteSsoListAsync)
    }

    //After Deleting sso by ID refresh the table
    const deleteStatus = useSelector(state => state?.app?.deleteStatus)

    useEffect(() => {
      if (deleteStatus) {
        if (deleteStatus.status === SUCCESS_STATUS) {
            getUserList();           
        }
      }
    }, [deleteStatus])


    const handleBack = () => {
    }
  
    return (
        <div>
            {!ssoId && <ListView
                columns={columns}
                data={ssoList?.data && ssoList?.data?.ssoTenantMappingList}
                metaData={ssoList?.data && ssoList?.data?.metadata}
                sorting={true}
                rowSelect={isAdminView}
                getSelectedRowData={getSelectedRowData}
                detailView={true}
                pageRangeDisplayed={5}
                searchPlaceholder={"Domain Name, Status, Tenant, Department, Title, Primary Group, Assign to all email addresses, assign to spectific email addresses"}
                getSearchValue={(e) => getSearchValue(e)}
                detailsRow={!ssoId}
                activePage={activePage}
                getActivePage={getActivePage}
                listSorting={sortingPayLoad}
                listType={'Sso'}
                subHeading={'SSO'}
                showFixedColumn={showFixedColumn}
                deleteListView={deleteList}
                searchKey={(searchKey) ? searchKey : ''}
                fileUplodad={uploadFile}
                setUploadStatus={setUploadStatus}
                getUserList={getUserList}
                offset={offSet}
                accessError={accessError}
                sortType={sortType?sortType:''}
            />}
            {ssoId && <DetailView
                detailsRowData={detailsRowData}
                title="Sso Management"
                tabList={tabList}
                defaultIndex={0}
                detailsRow={ssoId}
                handleBack={handleBack}
                subHeading={'SSO'}
                backLink='/admin/sso'
                tabRemove = {true}
            />}
        </div>
    )
}

Sso.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

Sso = connect()(withRouter(Sso));
