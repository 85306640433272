import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import {getWoundDashBoardAssessment, setWoundAssessmentData} from "../../../app/woundReportSlice";
import { DataTable, Pagination } from "../../secondary";
import { Header } from "../../secondary/Header";
import styles from "../QualityReport/overviewRowData.module.scss";
import { DASHBOARD_WOUNDASSESSMENT_OVERVIEW, LIST_VIEW_LIMIT } from '../../../constants';

import Spreadsheet from "../../../static/images/ExportSpredsheet.svg";
import { CSVLink } from "react-csv";
import Loader from '../../../static/images/spinner.svg';	
import { format } from "date-fns";

export const ActiveWoundsNotResolved = ({ history }) => {
  
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.user);
    const woundReportPayload = useSelector(state => state.woundreport.woundReportPayload);  

  //when onload payload passing to API
  useEffect(() => {
    const listPayLoad = {};
    listPayLoad["url"] =  `${DASHBOARD_WOUNDASSESSMENT_OVERVIEW}/activewoundsnotresolved`;
    listPayLoad["method"] = "Post";
    listPayLoad["payload"] = woundReportPayload;
    listPayLoad["queryParam"] = { offSet: 0, limit: LIST_VIEW_LIMIT };
    if (
        woundReportPayload && woundReportPayload.facility && woundReportPayload.facility.length > 0
    ) {
      dispatch(getWoundDashBoardAssessment(listPayLoad));
    }
  }, []);
    
    // For scrolling the page to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])   
  
  const [activePage, setActivePage] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [sortType, setSortType] = useState('')   

//Getting data from Clicked rows in Overview table through the API
const woundAssessmentData = useSelector((state) => state.woundreport.woundAssessmentData);

const [tinyDelay, setTinyDelay] = useState(false)
 useEffect(()=>{
  let getloadValue = woundAssessmentData?.woundAssessment !== undefined ? true : false
  if(getloadValue){
    setTimeout(()=>{
      setTinyDelay(true)
    }, 1000)    
  }    
 })
 
const columns = [

    {
        Header: ' Wound photo',
        accessor: 'woundImage',
        imageData: 'picture',
        restWidth: '120px',
        disableSortBy: true,

    },
    {
      Header: 'MRN #',
      accessor: 'mrn',
      restWidth: '100px',
  },
    {
        Header: 'Patient\'s name',
        accessor: 'patientName',
        restWidth: '150px',
    },
    {
        Header: 'History/Cause',
        accessor: 'historyCause',
        restWidth: '300px'

    },
    {
        Header: 'Classification',
        accessor: 'classification',
        restWidth: '200px'

    },
    {
        Header: 'Last assessed',
        accessor: 'lastAccessed',
        restWidth: '150px'

    },
    {
        Header: 'Admission date',
        accessor: 'admissionDate',
        restWidth: '250px'
    },
    {
        Header: 'Discharge date',
        accessor: 'dischargeDate',
        restWidth: '250px'
    },
    {
        Header: 'HPR Variable 1 - Worst Tissue Type',
        accessor: 'worstTissueType',
        restWidth: '265px'
    },
    {
        Header: 'HPR Variable 2 - Surface Area Value',
        accessor: 'surfaceAreaValue',
        restWidth: '265px'
    },
    {
        Header: 'HPR Variable 3 - Intact Value',
        accessor: 'intactValue',
        restWidth: '265px'
    },
];


const [adminAccess, setAdminAccess] = useState(false);
useEffect(() => {
    if (userDetails && userDetails.role == "ExternalStandardUser") {
        setAdminAccess(true);
    }
}, [userDetails]) 

  //redirect to patient-> assessments page 
  const getSelectedRowData = (data) => {
    if (data && !adminAccess) {
        history.push(`/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`);
    }
}
//   const getSelectedRowData = (data) => {
//     if (data) {
//       history.push(
//         `/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`
//       );
//     }
//   };

  const deleteDataList = () => {};

  const selectRowCount = () => {};  

  //sorting API call
  const handleSort = useCallback(
    (data) => {
      if (data && data.sortBy[0] && data.sortBy[0].id) {        
        const woundAssessmentPayload = { ...woundReportPayload, optionSelected: woundReportPayload?.optionSelected, woundProgress: woundReportPayload?.woundProgress }
        const sortingColumn = data.sortBy[0];
        const sortingType = sortingColumn
          ? sortingColumn.desc
            ? ":DESC"
            : ":ASC"
          : "";
        let sortParam = sortingColumn.id + sortingType;
        const listPayLoad = {};
        listPayLoad["url"] =  `${DASHBOARD_WOUNDASSESSMENT_OVERVIEW}/activewoundsnotresolved`;
        listPayLoad["method"] = "Post";
        listPayLoad["payload"] = woundAssessmentPayload;
        listPayLoad["queryParam"] = {
          offSet: isNaN(offSet)===true?0:offSet,
          limit: LIST_VIEW_LIMIT,
          sort: sortParam,
        };
        setSortType(sortParam)
        if (
          woundAssessmentPayload &&
          woundAssessmentPayload.facility &&
          woundAssessmentPayload.facility.length > 0
        ) {
          dispatch(getWoundDashBoardAssessment(listPayLoad));
        }
      }
    },
    [dispatch, woundReportPayload]
  );

  //active Page API Call
  const getActivePage = (data) => {
    setActivePage(data);
    const woundAssessmentPayload = { ...woundReportPayload, optionSelected: woundReportPayload?.optionSelected, woundProgress: woundReportPayload?.woundProgress }
    const listPayLoad = {};
    listPayLoad["url"] =  `${DASHBOARD_WOUNDASSESSMENT_OVERVIEW}/activewoundsnotresolved`;
    listPayLoad["method"] = "Post";
    listPayLoad["payload"] = woundAssessmentPayload;
    listPayLoad["queryParam"] = {
      limit: LIST_VIEW_LIMIT,
      offSet:
        data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT < LIST_VIEW_LIMIT
          ? 0
          : data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT,
          sort: (sortType)?sortType:''
    };
    setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
    if (
      woundAssessmentPayload &&
      woundAssessmentPayload.facility &&
      woundAssessmentPayload.facility.length > 0
    ) {
      dispatch(getWoundDashBoardAssessment(listPayLoad));
    }
  };
  
  const downloadDate = format(new Date(), "MM-dd-yyyy");
  
  const [csvHeader, setCsvHeader] = useState([
    { label: 'MRN #', key: 'mrn' },
    { label: 'Patient name', key: 'patientName' },
    { label: 'DOB', key: 'dob' },
    { label: 'History/Cause', key: 'historyCause' },
    { label: 'Classification', key: 'classification' },
    { label: 'Wound acquired', key: 'woundAcquired' },
    { label: 'Photo taken by', key: 'photoTakenBy' },
    { label: 'Photo taken on', key: 'photoTakenOn' },
    { label: 'Assessed by', key: 'lastAssessedby' },
    { label: 'Last assessed', key: 'lastAccessed'},
    { label: 'Signed', key: 'signed'},
    { label: 'Reviewed & locked', key: 'reviewedLocked'},
    { label: "HPR Variable 1 - Worst Tissue Type", key: "worstTissueType" },    
    { label: "HPR Variable 2 - Surface Area Value", key: "surfaceAreaValue" },
    { label: "HPR Variable 3 - Intact Value", key: "intactValue" }     
  ]);

  const woundReportOverviewDownloadCsvfile = useSelector((state) => state.woundreport.woundReportOverviewDownloadCsvfile);  

  return (
    <>
        <div className={`${styles.back_btn}`}>
            <Header 
                // heading="Admin Portal"
                // subHeading="Pending Assessments"  
                reportHeading={"Active wounds that are not resolved"} 
                backLink={'/woundReport'} 
                handleBack={() => history.goBack()} 
            />
        </div>
        {!tinyDelay ?    
          <div className={`${styles.loader} col-12`}>
            <img src={Loader} alt="loading" />
          </div>:
          <div className="col-12">
        {woundAssessmentData && Array.isArray(woundAssessmentData?.woundAssessment) && (
            <>
              <div className="col-sm-12 mb-2 justify-content-end d-flex">
                <span className={styles.down_spread}>
                  <img src={Spreadsheet} alt="" />
                  <label>
                    <CSVLink                      
                      headers={csvHeader}
                      data={
                        woundReportOverviewDownloadCsvfile && Array.isArray(woundReportOverviewDownloadCsvfile?.reports)
                          ? woundReportOverviewDownloadCsvfile?.reports
                          : []
                      }
                      filename={`NE1-Wounds-Report-Active-Wounds-${downloadDate}.csv`}
                      target="_blank"
                    >
                      Download
                    </CSVLink>
                  </label>
                </span>
              </div>
              <div className="col-12 px-0">
                <DataTable
                  columns={columns}
                  data={
                    woundAssessmentData && Array.isArray(woundAssessmentData?.woundAssessment)
                      ? woundAssessmentData?.woundAssessment
                      : []
                  }
                  sorting={true}
                  getSelectedRowData={getSelectedRowData}
                  setSelectedRows={selectRowCount}
                  onSort={handleSort}
                  detailView={true}
                  deleteDataList={deleteDataList}
                  showFixedColumn={false}
                  workListView={true}
                  reportView={true}
                  sortType={(sortType)?sortType:''}
                />
              </div>
            </>
          )}
        {Array.isArray(woundAssessmentData.woundAssessment) &&
          woundAssessmentData.woundAssessment.length > 1 && (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={
                woundAssessmentData?.metadata
                  ? woundAssessmentData?.metadata?.limit
                  : 50
              }
              totalItemsCount={
                woundAssessmentData?.metadata
                  ? woundAssessmentData?.metadata?.total
                  : 0
              }
              getActivePage={getActivePage}
              reportView={true}
            />
        )}
      </div>}
    </>
  );
}//NOSONAR


ActiveWoundsNotResolved.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
};