import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { addUpDateDeleteQuestionAsync, cloneQuestionAsync, getSupportTopicsAsync, setSucessStatus } from '../../../app/supportSlice';
import { CANCEL_BTN, CANCEL_ID, SAVE_BTN, SUBMIT_ID, UPDATE_FAILURE, UPDATE_SUCCESS } from '../../../constants';
import DeleteIcon from '../../../static/images/delete-icon.png';
import iconDanger from '../../../static/images/icon-check-danger.png';
import iconSuccess from '../../../static/images/icons-check-success.png';
import PlusIcon from '../../../static/images/plus-icon_white.svg';
import { Button, Form, SelectBox, TextArea, TextField } from '../../primary';
import { Modal } from '../../secondary';
import style from './contextualSupport.module.scss';
import Loader from '../../../static/images/spinner.svg';	
export function ContextualSupport({ history }) {
    const dispatch = useDispatch();
    const { supportDataId } = useParams();
    /* Use hook form */
    const { register, handleSubmit, errors, reset } = useForm({
        mode: 'onBlur'
    });
    
    const questionsData = useSelector(state => state.support.questions);
    let questionBuilder = "";
    if (questionsData && questionsData.questions && questionsData?.questions.length > 0) {
        const defaulteQuestion = [...questionsData?.questions].map((value) => {//NOSONAR
            const isDel = { ...value }//NOSONAR
            isDel.isDeleted = 0;
            return isDel;
        })//NOSONAR
        questionBuilder = questionsData.questions && defaulteQuestion;
    } else {
        questionBuilder = [{
            "id": 0,
            "question": "",
            "answer": "",
            "sequence": 1,
            "isDeleted": 0
        }]
    }
    useEffect(() => {
        dispatch(setSucessStatus({}))
        reset();
        setCustomQuestionBuilder(questionBuilder)
    }, [questionsData])

    const [customQuestionBuilder, setCustomQuestionBuilder] = useState(questionBuilder)

    const nextQuestion = () => {
        let nextQuestionData = [...customQuestionBuilder]
        const numberCount = [];

        Array.isArray(nextQuestionData)&&nextQuestionData.map((value) => {//NOSONAR
            return numberCount.push(value.id);
          });//NOSONAR

        const maxNumber = Math.max(...numberCount)
        nextQuestionData = [...nextQuestionData, {
            "id": 0,
            "question": "",
            "answer": "",
            "sequence": maxNumber + 1,
            "isDeleted": 0

        }]
        setCustomQuestionBuilder(nextQuestionData)
    }

    const getQuestions = (value, index) => {
        if(value !== ""){
            const setQuestionData = { ...customQuestionBuilder[index] }
            setQuestionData.question =value
            const customQuestionObj = [...customQuestionBuilder]
            customQuestionObj.splice(index, 1, setQuestionData)
            setCustomQuestionBuilder(customQuestionObj)
        }
        
    }
    const getAnswer = (value, index) => {
        if(value !== ""){
            const setAnswerData = { ...customQuestionBuilder[index] }
            setAnswerData.answer = value
            const customAnswerObj = [...customQuestionBuilder]
            customAnswerObj.splice(index, 1, setAnswerData)
            setCustomQuestionBuilder(customAnswerObj)
        }
        
    }

    const deleteQuestion = (index) => {
        const deleteQuestionData = [...customQuestionBuilder]
        const deletedObj = { ...deleteQuestionData[index] }
        deletedObj.isDeleted = 1;
        deleteQuestionData.splice(index, 1, deletedObj);
        setCustomQuestionBuilder(deleteQuestionData);

    }
    const onSubmit = () => {
        const payload = { "id": supportDataId }
        const result = { ...payload }
        const finalQuestionsBuild=[]
        if (customQuestionBuilder && customQuestionBuilder.length > 0) {
            customQuestionBuilder.forEach(function (key, index) {
                if((key.id!==0 && key.isDeleted===1)&&(key.question!=='') && (key.answer!=='')){
                     finalQuestionsBuild.push(key);
                }
                else if((key.id===0 && key.isDeleted===0)&&(key.question!=='') && (key.answer!=='')){
                    finalQuestionsBuild.push(key);
               }
               else if((key.id!==0 && key.isDeleted===0)&&(key.question!=='') && (key.answer!=='')){
                finalQuestionsBuild.push(key);
           }
              
               
          });
        
        }
        result.questions = finalQuestionsBuild
        dispatch(addUpDateDeleteQuestionAsync(result));
    }
    const handleCancelBtn = () => {
        history.push('/admin/support');
    }

    const appPageData = useSelector(state => state.support.applicationPageData);

    /* Clone modal */
    const [showModal, setShowModal] = useState(false);
    const [cloneModal, setCloneModal] = useState(false);
    const [successStatusModal, setSuccessStatusModal] = useState(false);
    const [errorStatusModal,setErrorStatusModal]=useState(false);
    
    const modalClose = () => {
        setShowModal(false);
        setShowConfirmationModal(false);
        reset();
        dispatch(setSucessStatus({}))
        setSuccessStatusModal(false)
        setErrorStatusModal(false);
    }

    const [pageData, setPageData] = useState();
    const [selectedApp, setSelectedApp] = useState();
    const [selectedPage, setSelectedPage] = useState();
    const [selectedTopic, setSelectedTopic] = useState();

    useEffect(() => {
        handleChangeApplication(appPageData[0]?.key);
    }, [appPageData])

    const handleChangeApplication = (app) => {
        setSelectedApp(app)
        {
            appPageData && appPageData.length > 0 && appPageData.map((page) => {
                if (page.key === app) {
                    setPageData(page.options);
                }

            });
        }

    }

    const handleChangePage = (page) => {
        setSelectedPage(page);
    }

    useEffect(() => {
        let payload = "";
        if (selectedApp && selectedPage && selectedApp!==undefined && selectedApp!==undefined ) {
            payload = {
                application: selectedApp,
                page: selectedPage,
            }
        } else {
            payload = {
                application: appPageData[0]?.key,
                page: appPageData[0]?.options[0]?.key,
            }
        }
        dispatch(getSupportTopicsAsync(payload));
        setCbState('')
    }, [appPageData[0]?.key,appPageData[0]?.options[0]?.key,selectedApp, selectedPage])

    const supportTopicData = useSelector(state => state.support.supportTopicData);
    const convertedSupportTopicData = supportTopicData && supportTopicData.length>1 && supportTopicData.map(data => (
        (supportDataId!==data.id) ? { key: data.id, value: data.topic }:null
        
        ));
    
    const [topicsState, setTopicsState] = useState(convertedSupportTopicData[0]?.key) 
    const modalOpen = () => {
        reset();
        setTopicsState(convertedSupportTopicData[0]?.key)
        setShowModal(true);
        setCloneModal(true);
    }
    useEffect(() => {
        setTopicsState(convertedSupportTopicData[0]?.key);
        
    },[convertedSupportTopicData] )   
    const handleChangeTopic = (topic) => {
        setSelectedTopic(topic);
    }
    const [clonePayloadData, setClonePayloadData] = useState({})
    const [cbState, setCbState] = useState();
    const doCloneSubmit = () => {
        setCbState('')
        let clonePayload = ""
        if(supportDataId!==convertedSupportTopicData[0]?.key){
        if (selectedTopic !== undefined) {
            clonePayload = {
                supportDataId: supportDataId,
                id: selectedTopic,
                replaceQuestions: 0,
                setCbState
            }
        } else {
            clonePayload = {
                supportDataId: supportDataId,
                id: convertedSupportTopicData[0]?.key,
                replaceQuestions: 0,
                setCbState
            }
        }
        }
        setClonePayloadData(clonePayload);
        cloneQuestions(clonePayload);
        reset();
    }


    const [confirmationModal, setConfirmationModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // clone question
    const cloneQuestions = (res) => {
        setShowModal(false);
        dispatch(cloneQuestionAsync(res));
        if (cbState!==null) {
            setConfirmationModal(true);
            setShowConfirmationModal(true);
        }
        if (cbState===null) {
            setConfirmationModal(false);
            setShowConfirmationModal(false);
            setCbState('')
        }
    }
    const overrideClone = () => {
        const payload = {
            replaceQuestions: 1
        }
        const result = { ...clonePayloadData, ...payload };
        cloneQuestions(result);
        setConfirmationModal(false);

    }
    let questionNumber = 0;
    const isDeletedCount = [];

    const updateStatus = useSelector(state => state.support.sucessStatus);
    useEffect(() => {
        if(updateStatus==="success" || updateStatus==="error"){
        if(updateStatus && updateStatus==="success"){
            setSuccessStatusModal(true)
            setTimeout(() => {
               dispatch(setSucessStatus({}))
               setSuccessStatusModal(false);
           }, 5000)
        }
        else if(updateStatus && updateStatus==="error"){
            setErrorStatusModal(true)
            setTimeout(() => {
               dispatch(setSucessStatus({}))
               setErrorStatusModal(false);
           }, 5000)
        }
    }
     }, [updateStatus])
     const [tinyDelay, setTinyDelay] = useState(false)

     useEffect(() => {
       setTimeout(() => {
         setTinyDelay(true)
       }, 2500)
     }, [])
    return (
        <>
           
            {questionsData && questionsData.questions && questionsData?.questions.length > 0 &&
                <div className="col-12 d-flex justify-content-end">
                    <div className="mr-2">
                        <Button
                            type={"button"}
                            value={"Clone"}
                            classes={{ className: `btn ${style.btn_custom} ${style.btn_small_cancel}` }}
                            id="clone"
                            onClick={() => modalOpen()}
                        />
                    </div>
                </div>
            }
            {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
            <div className={`col ${style.admin_bg_bottom}`}>
                <div className={`${style.tab_content}`} id="myTabContent">
                    <div className={`${style.tab_pane} fade show active`} id="contexualSupport" role="tabpanel" aria-labelledby="BasicInfo-tab">
                        <div className={`${style.tab_content_top}`}>
                            <h2 className={`${style.tab_content_hdg}`}>{questionsData?.topic}
                                <span className={`${style.tab_subtitle} mt-2`}>
                                    <span className="font-weight-bold">Page:</span> {questionsData?.page} 
                                    | <span class="font-weight-bold">Application:</span> {questionsData?.application}
                                </span>
                            </h2>
                        </div>

                        <div className={`col-md-12 ${style.single_product_block}`}>
                            <div className={`${style.moreinfo_content}`}>
                                <Form formChanges={true} className={`pl-2 pt-0 ${style.moreinfo_content}`} onSubmit={handleSubmit(onSubmit)} >

                                    {customQuestionBuilder.map((value, index) => {
                                        if (value?.isDeleted === undefined || value?.isDeleted === 0) {
                                            questionNumber = questionNumber + 1;
                                            isDeletedCount.push(value.id);
                                            return (
                                                <>

                                                    <TextField
                                                        id={`text${value.id}`}
                                                        name={`text${value.id}`}
                                                        label={`${questionNumber}.`}
                                                        classes={{
                                                            column: `col-10 col-form-label ${style.col_spec}`,
                                                            labelClass: `col-1 col-form-label ${style.label_spec}`,
                                                            inputColumn: 'col-11',
                                                            inputClassName: `${style.basic_input} getQues`
                                                        }}
                                                        type="text"
                                                        validator={register()}
                                                        placeholder={"Enter a question"}
                                                        errors={errors}
                                                        defaultValue={value?.question}
                                                        onChange={(e) => getQuestions(e.target.value, index)}
                                                    />
                                                    <div className={`col-1 col-form-label ${style.content_spec}`}>
                                                        {isDeletedCount.length > 1 && <span className={`deleteQues`} onClick={() => deleteQuestion(index)}><img src={DeleteIcon} alt="" /></span>}
                                                    </div>

                                                    <TextArea
                                                        id={`textArea${value.id}`}
                                                        name={`textArea${value.id}`}
                                                        classes={{
                                                            column: `col-10 col-form-label ${style.col_spec}`,
                                                            inputClassName: `${style.text_content} getAns`,
                                                            labelClassName: `col-1 col-form-label ${style.label_spec}`,
                                                            inputColumn: `offset-1 col-11`
                                                        }}
                                                        validator={register()}
                                                        placeholder={"Enter a response"}
                                                        errors={errors}
                                                        value={value?.answer}
                                                        onChange={(e) => getAnswer(e.target.value, index)}

                                                    />
                                                    <div className={`col-1 col-form-label ${style.content_spec}`}>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })
                                    }
                                    {/* Next button */}
                                    <div className={`col-12 col-form-label ${style.col_spec}`}>
                                        <label className={`col-1 col-form-label  ${style.label_spec}`}></label>
                                        <button type="button" className={`btn ${style.btn_add} w-auto`} 
                                        onClick={() => nextQuestion()}><img src={PlusIcon} alt="Plus icon" /> Next Question</button>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="text-center">
                                            {/* Save Button */}
                                            <Button
                                                classes={{ className: `btn ${style.btn_custom} ${style.btn_small}` }}
                                                type="submit"
                                                id={SUBMIT_ID}
                                            >
                                                {SAVE_BTN}
                                            </Button>
                                            {/* Cancel button  */}
                                            <Button
                                                classes={{ className: `btn ${style.btn_custom} ${style.btn_small_cancel}` }}
                                                id={CANCEL_ID}
                                                onClick={handleCancelBtn}
                                            >
                                                {CANCEL_BTN}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            }
            {cloneModal &&
                <Modal
                    modalOpen={showModal}
                    modalClose={modalClose}
                    heading={<div>
                        <div className={style.modal_heading}>{"Clone"}</div>
                    </div>}
                    modalSize={style.custom_modal_size}
                    reset={modalClose}
                >
                    <Form  onSubmit={handleSubmit(doCloneSubmit)}>
                        <>
                            <div className={`col-sm-12  ${style.single_product_block}`}>
                                <div className="row">
                                    <div className={`p-0 ${style.patient_content}`}>
                                        {/*Application Drop down */}
                                        <div className={`col-7 ${style.col_spec}`}>
                                            <label className={`col-5 col-form-label ${style.label_spec}`}>Application:</label>
                                            <div className={`col-6 p-0 ${style.select_wrapper}`}>
                                                <select className={`${style.basic_select} changeapp`} name="cause" ref={register} onChange={e => handleChangeApplication((e.currentTarget.value))}>
                                                    {appPageData && appPageData.length > 0 && appPageData.map((data, i) => {
                                                        return <option value={data.key} key={i}  >{data.value}</option>
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <SelectBox
                                            id={"page"}
                                            name={"page"}
                                            label={"Page:"}
                                            classes={{ column: `col-7 ${style.col_spec}`, inputClassName: style.basic_select, labelClassName: `col-5 col-form-label ${style.label_spec}`, inputColumn: `col-6 p-0 ${style.select_wrapper}` }}
                                            optionValue={pageData}
                                            defaultValue={pageData&& pageData[0]?.key}
                                            validator={register}
                                            onChange={e => handleChangePage(e.currentTarget.value)}
                                        />
                                        <SelectBox
                                            id={"topics"}
                                            name={"topics"}
                                            label={"Topics:"}
                                            classes={{ column: `col-7 ${style.col_spec}`, inputClassName: style.basic_select, labelClassName: `col-5 col-form-label ${style.label_spec}`, inputColumn: `col-6 p-0 ${style.select_wrapper} ${style.disabled}` }}
                                            optionValue={convertedSupportTopicData}
                                            defaultValue={topicsState}
                                            validator={register}
                                            onChange={e => handleChangeTopic(e.currentTarget.value)}
                                        />

                                    </div>
                                    <div className="col-sm-12">
                                        <div className="text-center pb-4">
                                              {/* Save Button */}
                                              <Button
                                                classes={{ className: `btn ${style.btn_custom} ${style.modal_btn_small} cloneQuestion` }}
                                                type="submit"
                                                id={SUBMIT_ID}
                                            >
                                                {SAVE_BTN}
                                            </Button>
                                            <Button
                                                type={"reset"}
                                                classes={{ className: `btn ${style.btn_custom} ${style.modal_btn_small_cancel}` }}
                                                id={CANCEL_ID}
                                                onClick={modalClose}
                                            >
                                                {CANCEL_BTN}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Form>
                </Modal>
            }

            {cbState&&cbState !== null && confirmationModal &&
                <Modal
                    modalOpen={showConfirmationModal}
                    modalClose={modalClose}
                    heading={<div>

                    </div>}
                    modalSize={style.custom_confirm_modal_size}
                >
                    <div className={`col-sm-12  ${style.single_product_block}`}>
                        <div className="row">
                            <div className={`${style.patient_content}`}>
                                <p className={`${style.clone_status}`}>{cbState}</p>
                            </div>
                            <div className="col-sm-12">
                                <div className="text-center pb-4">
                                   
                                    {/* Save Button */}
                                    <Button
                                        classes={{ className: `btn ${style.btn_custom} ${style.modal_btn_small} overrideClone` }}
                                        type="submit"
                                        id="yes"
                                        onClick={overrideClone}
                                    >
                                        Yes
                                    </Button>

                                    <Button
                                        type={"reset"}
                                        classes={{ className: `btn ${style.btn_custom} ${style.modal_btn_small_cancel}` }}
                                        id={CANCEL_ID}
                                        onClick={modalClose}
                                    >
                                        No
                                    </Button>

                                </div>

                            </div>
                        </div>

                    </div>
                </Modal>
            }

{updateStatus!=="" && successStatusModal && <Modal
                modalOpen={successStatusModal}
                modalClose={modalClose}
            >
                <div className="text-center p-4">
                    <div>
                        <img src={iconSuccess} alt="Icon" />
                    </div>
                    <div>{UPDATE_SUCCESS}</div>
                    <div className="mt-4">
                        <Button
                            classes={{ className: `btn ${style.btn_custom} ${style.modal_btn_small}` }}
                            type="button"
                            id="close"
                            onClick={modalClose}
                        >
                            Close
                        </Button>
                    </div>

                </div>
            </Modal>
            }
{updateStatus!=="" && errorStatusModal && <Modal
                modalOpen={errorStatusModal}
                modalClose={modalClose}
            >
                <div className="text-center p-4">
                    
                    <div>
                        <img src={iconDanger} alt="Icon" />
                    </div>
                    <div>{UPDATE_FAILURE}</div>
                    <div className="mt-4">
                        <Button
                            classes={{ className: `btn ${style.btn_custom} ${style.modal_btn_small}` }}
                            type="button"
                            id="close"
                            onClick={modalClose}
                        >
                            Close
                        </Button>
                    </div>    
                </div>
            </Modal>
            }
        
        </>
    )
}

ContextualSupport.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
};

ContextualSupport = connect()(withRouter(ContextualSupport));




