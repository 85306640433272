import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ActionModal } from '../../secondary'
import iconSuccess from '../../../static/images/icons-check-success.png'
import Loader from '../../../static/images/spinner.svg';
import iconDanger from '../../../static/images/icon-check-danger.png'
import warning from '../../../static/images/rbac_auth.svg';
import { setError, setLoading } from '../../../app/appSlice';
import { setUploadStatus } from '../../../app/customerSlice';
export function CsvImportLoader({ modalOpen, modalClose, cancelUpload, listType }) {

    /* Get loader state (upload prgress) */
    const loading = useSelector(state => state.app.loading)
    /* Status once uploading is done, weather success or failure */
    const uploadStatus = useSelector(state => {
        if (listType === 'Patients') {
            return state.patient.uploadStatus
        }
        else if (listType === 'Customers') {
            return state.customer.uploadStatus
        }

        else if (listType === 'Users') {
            return state.user.uploadStatus
        }
        else {
            return null;
        }
    })

    const [accessErrorClass, setAccessErrorClass] = useState(false)

    useEffect(() => {
        if(uploadStatus && uploadStatus?.accessStatus===405){
            setAccessErrorClass(true)
        }
    }, [uploadStatus])

    const dispatch = useDispatch();
     const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
//  dispatch(setUploadStatus(null))
    if(loading ){
      setTimeout(() => {
        setTinyDelay(false)
        if(uploadStatus?.status==='error'){
            setTinyDelay(false)
        }else{
            setTinyDelay(true)
        }
        dispatch(setLoading(false))
      }, 4000)
    }
  }, [dispatch,loading,uploadStatus])
 
//uploadStatus
    return (
        <Modal
            modalOpen={modalOpen}
            modalClose={modalClose}
            accessErrorClass={accessErrorClass}
        >
           {listType === 'Customers'||listType === 'Patients'||listType === 'Users'?<>
           {loading && <ActionModal
                    loaderImg={Loader}
                    heading="Uploading in progress.."
                    buttonText=""
                    buttonAction={cancelUpload}
                />
                }
            {uploadStatus && uploadStatus?.message && <ActionModal
                                    iconImg={iconDanger}
                                    heading={uploadStatus.message}
                                    buttonText="Close"
                                    buttonAction={modalClose}
                                />
                                }
          {tinyDelay&&!uploadStatus?.message &&<ActionModal
                    iconImg={iconSuccess}
                    heading="File Upload is Successful and is being processed"
                    buttonText="Close"
                    buttonAction={modalClose}
                />}
           
           </>
           
           :<div className="text-center p-4">
                {/* File is uploaing */}
                {loading && <ActionModal
                    loaderImg={Loader}
                    heading="Uploading in progress.."
                    buttonText=""
                    buttonAction={cancelUpload}
                />
                }
                {/* Upload success */}
                {uploadStatus && uploadStatus.failedRowCount === 0 && <ActionModal
                    iconImg={iconSuccess}
                    heading="Your file has been uploaded successfully."
                    buttonText="Close"
                    buttonAction={modalClose}
                />

                }
                {/* Upload failed, display message received from the API */}
                {uploadStatus && uploadStatus.failedRowCount > 0 && <ActionModal
                    iconImg={iconDanger}
                    heading={uploadStatus.failedRowCount + ' items failed to import'}
                    buttonText="Close"
                    buttonAction={modalClose}
                />
                }

                {uploadStatus && uploadStatus.accessStatus===405 && <ActionModal
                                    iconImg={warning}
                                    heading={uploadStatus.message}
                                    buttonText="Close"
                                    buttonAction={modalClose}
                                />
                                }
            </div>}
        </Modal>
    )
}
