import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// 'navFormBuilder', 'navInAppMessaging', 'navLayout', 'navManageUsers', 'navUsers', 'navMobolileAppMngmnt', navNE1WMS

const navRbac = {
    'MedlineAdministrator': ['navFormBuilder', 'navInAppMessaging', 'navMobolileAppMngmnt', 'navPlatformAnalytics', 'navUsageAnalytics', 'navSurveyResult', 'navContextualSupport', 'navProducts', 'navCustomers', 'navFormulary', 'navNE1WMS', 'navUsers'],

    'MedlineStandardUser': ['navPlatformAnalytics', 'navUsageAnalytics', 'navContextualSupport', 'navProducts', 'navCustomers', 'navFormulary', 'navNE1WMS', 'navCompanyProfile'],

    'MedlineSupport': ['navPlatformAnalytics', 'navUsageAnalytics', 'navProducts', 'navCustomers', 'navFormulary', 'navUsers', 'navNE1WMS', 'navContextualSupport'],

    'ExternalAdministrator': ['navUsageAnalytics', 'navUsers', 'navNE1WMS', 'navCompanyProfile'],

    'ExternalStandardUser': ['navFormBuilder', 'navInAppMessaging', 'navMobolileAppMngmnt', 'navUsageAnalytics', 'navSurveyResult', 'navContextualSupport', 'navProducts', 'navCustomers', 'navFormulary', 'navUsers', 'navNE1WMS', 'navCompanyProfile'],

    'ExternalNE1WoundExpert': [ 'navUsageAnalytics'],

    'ExternalNE1Review': ['navFormBuilder', 'navInAppMessaging', 'navMobolileAppMngmnt', 'navUsageAnalytics', 'navSurveyResult', 'navContextualSupport', 'navProducts', 'navCustomers', 'navFormulary', 'navUsers', 'navNE1WMS', 'navCompanyProfile'],

    'ExternalNE1Assesment': ['navFormBuilder', 'navInAppMessaging', 'navMobolileAppMngmnt', 'navUsageAnalytics', 'navSurveyResult', 'navContextualSupport', 'navProducts', 'navCustomers', 'navFormulary', 'navUsers', 'navNE1WMS', 'navCompanyProfile'],
}

export const navigationChecker = (name, userRole) => {

    if (navRbac[userRole]?.indexOf(name) !== -1) {
        return true;
    } else {
        return false;
    }
}

const customerRbac = {
    'MedlineAdministrator': [],
    'MedlineStandardUser': ['isListAll', 'isImport', 'isDelete', 'isAdd', 'isBasicInfoSave', 'isMoreInfoSave', 'isOrgSave', 'isFormularySave', 'isSettingsSave'],
    'MedlineSupport': ['isListAll', 'isImport', 'isDelete', 'isAdd', 'isBasicInfoSave', 'isMoreInfoSave', 'isOrgSave', 'isFormularySave', 'isSettingsSave'],
    'ExternalAdministrator': ['isListAll', 'isImport', 'isDelete', 'isAdd', 'isBasicInfoSave', 'isOrgSave', 'isFormularySave'],
    'ExternalStandardUser': [''],
    'ExternalNE1WoundExpert': [''],
    'ExternalNE1Review': [''],
    'ExternalNE1Assesment': [''],
}

export const customerRbacChecker = (name, userRole) => {
    if (customerRbac[userRole]?.indexOf(name) !== -1) {
        return true;
    } else {
        return false;
    }
}

const primaryNavRbac = {
    'MedlineAdministrator': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'MedlineStandardUser': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'MedlineSupport': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'ExternalAdministrator': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'ExternalStandardUser': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports'],

    'ExternalNE1WoundExpert': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'ExternalNE1Review': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports'],

    'ExternalNE1Assesment': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports']

}

export const primaryNavigationChecker = (name, userRole) => {

    if (primaryNavRbac[userRole]?.indexOf(name) !== -1) {
        return true;
    } else {
        return false;
    }
}


const worklistTabRbac = {
    'MedlineAdministrator': [true, true, true, true, true, true, true],

    'MedlineStandardUser': [true, true, true, true, true, true, true],

    'MedlineSupport': [true, true, true, true, true, true, true],

    'ExternalAdministrator': [true, true, true, true, true, true, true],

    'ExternalStandardUser': [true, true, true, false, false, false, true],

    'ExternalNE1WoundExpert': [true, true, true, true, true, true, true],

    'ExternalNE1Review': [true, true, true, true, true, false, true],

    'ExternalNE1Assesment': [true, true, true, true, true, false, true]
}

export const workListTabRbacChecker = (tabList, userRole) => {
    let result = []
    worklistTabRbac[userRole]?.map((data, i) => {//NOSONAR
        if (data) {
            result.push(tabList[i])
        }
    })//NOSONAR
    return result;
}

/*const measurementRbac = {
    'MedlineAdministrator': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'MedlineStandardUser': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'MedlineSupport': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'ExternalAdministrator': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'ExtertnalStandardUser': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports'],

    'ExternalNE1WoundExpert': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports', 'primaryNavAdmin'],

    'ExternalNE1Review': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports'],

    'ExternalNE1Assesment': ['primaryNavWorklist', 'primaryNavPatients', 'primaryNavWounds', 'primaryNavTreatmentOutcomes', 'primaryNavQualityAssurance', 'primaryNavAdhocReports']

}

export const measurementRbacChecker = (name, userRole) => {

    if (measurementRbac[userRole]?.indexOf(name) !== -1) {
        return true;
    } else {
        return false;
    }
}*/

const assessmentRbac = {
    'MedlineAdministrator': true,
    'MedlineStandardUser': true,
    'MedlineSupport': true,
    'ExternalAdministrator':true,
    'ExtertnalStandardUser': false,
    'ExternalNE1WoundExpert':true,
    'ExternalNE1Review':true,
    'ExternalNE1Assesment': true,
}

export const assessmentRbacChecker = (userRole,isOwnCustomer) => {
    if (assessmentRbac[userRole] && isOwnCustomer===1) {
        return true;
    } else {
        return false;
    }
}


const rbacMasterSecNav = {
    'MedlineAdministrator': ['navMaster'],

    'MedlineStandardUser': ['navMaster'],

    'MedlineSupport': ['navMaster'],

    'ExternalAdministrator': [],

    'ExternalStandardUser': [],

    'ExternalNE1WoundExpert': [],

    'ExternalNE1Review': [],

    'ExternalNE1Assesment': [],
}

export const rbacMasterSecNavChecker = (name, userRole) => {

    if (rbacMasterSecNav[userRole]?.indexOf(name) !== -1) {
        return true;
    } else {
        return false;
    }
}