import React, { useState, useEffect, useRef } from 'react'
import styles from './assign.module.scss'
import deleteIcon from '../../../static/images/Close_Normal.svg'
import { SelectBox, RadioButton } from '../../primary'
import { AddFamily } from './addFamily'
import { AddMaterial } from './addMaterial'
import { AddRuleText } from './addRuleText'
import topArrow from '../../../static/images/arrow_top_chevron_icon.svg'
import downArrow from '../../../static/images/arrow_down_chevron_icon.svg'
export const SingleSelect = ({ name, index, placeholder, defaultValue, count, pIndex, getOptionValue,
    getGotoValue, goto, gotoOption, deleteAnswerOption, optionType, getFamilyCode, productCategoryList, productFamily, productFilter, addRuleData, addRuleList, getRuleData, rule, formDataName, id, formBuilder,fetchIndex,productAttributeOrderChange,getMaterialCode,productMaterial,materialList}) => {
    const [modalShow, setmodalShow] = useState(false)
    const modalOpen = () => {
        setmodalShow(true);
    }
    const modalClose = () => {
        setmodalShow(false);
    }
    const deleteOption = (index, pIndex) => {
        deleteAnswerOption(index, pIndex)
    }
    const showRuleData = () => {
        addRuleData(pIndex, index)
    }
    const upArrowSequenceFetch = (index, pIndex,fetchIndex) => {
        productAttributeOrderChange('up',index, pIndex,fetchIndex);
    }
    const downArrowSequenceFetch = (index, pIndex,fetchIndex) => {
        productAttributeOrderChange('down',index, pIndex,fetchIndex);
    }
    return (
        <>
            <div className={`col-7 ${styles.col_spec} ${styles.col_spec_img} ${styles.form_data} fireEvent fetch`} key={index}>
                <div className="ml-3 mr-2 col-form-label d-flex align-items-center">
                    <span className={`${styles.pos_delete_form} delete`} onClick={() => deleteOption(index, pIndex)}>
                        <img src={deleteIcon} alt="" title={ count === 2 ? 'Default Options. You cannot delete the last two options': ''}/>
                    </span>
                        <>
                            <div className="ml-2 d-flex flex-column align-items-center justify-content-center">
                                {index !== 0 &&
                                    <span className={`${styles.pos_fetch_uparrow}`} onClick={() => upArrowSequenceFetch(index, pIndex, fetchIndex)}>
                                        <img src={topArrow} alt="Top arrow" title="Up" />
                                    </span>}
                                {
                                    count - 1 !== index &&
                                    <span className={`${styles.pos_fetch_downarrow} `} onClick={() => downArrowSequenceFetch(index, pIndex, fetchIndex)}>
                                        <img src={downArrow} alt="doen arrow" title="Down" />
                                    </span>
                                }
                            </div>
                        </>                  
                </div>
                <div className={`col-5 ${styles.form_custom_check} ${optionType === "productattribute" ? 'd-flex ml-1 '+(fetchIndex?.attributeValueKey===0||fetchIndex?.attributeValueKey=="0"?'':styles.product_attribute) : ''}`}>
                    <RadioButton
                        id={`option${index}`}
                        name={name}
                        classes={{
                            column: `${styles.custom_check_bg}`,
                            labelClass: `custom-control-label ${styles.custom_control_label} ${styles.custom_control_label_t0}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input} #`,
                            inputColumn: "custom-control custom-radio custom-control-inline mr-1"
                        }}
                        radioOption={['']}
                    />
                    <input
                        type="text"
                        className={`${styles.type_basic_input} ${styles.input_block} ${styles.hide_outline} singleSelectIOpt`}
                        placeholder={placeholder} id={`option${index}`}
                        name={name}
                        autoComplete="off"
                        onBlur={(e) => getOptionValue(e.target.value, index, pIndex)}
                        defaultValue={defaultValue}
                    />
                </div>
                {productFamily?.length > 0 && <div className={`${styles.form_text_blk}`} title={productFamily?.toString()}>{
                    productFamily?.toString()
                }</div>}
                {productMaterial?.length > 0 && <div className={`${styles.form_text_blk}`} title={productMaterial?.toString()}>{
                    productMaterial?.toString()
                }</div>}
                {optionType === "productfamily" && <><button type="button" className={`btn ${styles.btn_custom_rule} w-auto mt-2`} onClick={() => modalOpen()}>{productFamily?.length > 0 ? 'Edit Product Family Code' : 'Select Product Family Code'}</button>
                    {modalShow&&<AddFamily modalOpen={modalShow} modalClose={modalClose} getFamilyCode={getFamilyCode} index={index} productCategoryList={productCategoryList} pIndex={pIndex} productFamily={productFamily} defaultValue={productFilter} />}</>
                    }
                {optionType === "productmaterial" && <><button type="button" className={`btn ${styles.btn_custom_rule} w-auto mt-2`} onClick={() => modalOpen()}>{productMaterial?.length > 0 ? 'Edit Material' : 'Select Material'}</button>
                {modalShow&&<AddMaterial modalOpen={modalShow} modalClose={modalClose} getMaterialCode={getMaterialCode} index={index} materialList={materialList} pIndex={pIndex} productMaterial={productMaterial} defaultValue={productFilter} />}</>
                }
            </div>
            {goto === "" || goto === undefined || goto === null ? <div className={`col-3 ${styles.col_spec}`}></div> : ""}
            {goto !== "" && goto !== undefined && goto !== null && <SelectBox
                id={"Goto"}
                name={"Goto"}
                label={`Go to:`}
                classes={{
                    column: `col-3 ${styles.col_spec}`,
                    inputClassName: `${styles.type_basic_select} select_Goto`,
                    labelClassName: `col-4 col-form-label ${styles.type_label_spec}`,
                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`
                }}
                optionValue={gotoOption}
                defaultValue={goto}
                onChange={(e) => getGotoValue(e.target.value, pIndex, index)}
                customForm={true}
            />}
            {formDataName !== "NE1 WMS" && goto === "eof" && rule !== undefined && <div className="col-2 p-0">
                <button type="button" className={`btn ${styles.btn_custom_rule} w-auto`} onClick={showRuleData}>{rule === null || rule === undefined ? 'Add Rule':'Edit Rule'}</button>
            </div>}
        </>
    )
}
