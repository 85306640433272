import { LIST_VIEW_LIMIT } from "../constants"

import { getUniqueListBy } from "./commonHelper"

export const handleDropDownChangeMove = (e, field, setActivePage, setFirstTimeEdit, dispatch, setNewPayload, reset, getValues, newPayload) => {
    setActivePage(1)
    setFirstTimeEdit(false)
    // If I am reselecting 'division', reset all dropdown states and dropdown fields
    if (field === 'division'&&e.target.value=== '') {
        const payload = {
            division: '',
            productCategoryDesc: null,
            productCategoryCode: null,
            productFamilyDesc: null,
            productFamilyCode: null
        }
        dispatch(setNewPayload(payload))
        // Reseting dropdown fields 
        reset({ ...getValues(), productCategoryDesc: '', productCategoryCode: '', productFamilyDesc: '', productFamilyCode: '' })
    }
    if (field === 'division'&&e.target.value) {
        const payload = {
            division: e.target.value,
            productCategoryDesc: null,
            productCategoryCode: null,
            productFamilyDesc: null,
            productFamilyCode: null
        }
        dispatch(setNewPayload(payload))
        // Reseting dropdown fields 
        reset({ ...getValues(), productCategoryDesc: '', productCategoryCode: '', productFamilyDesc: '', productFamilyCode: '' })
    } else if (field === 'productCategoryDesc') {
        const payload = { ...newPayload }
        payload[field] = e.target.value === '' ? null : e.target.value;
        payload['productCategoryCode'] = payload['productFamilyDesc'] = payload['productFamilyCode'] = null;
        dispatch(setNewPayload(payload))
        // Reseting dropdown fields 
        reset({ ...getValues(), productCategoryCode: '', productFamilyDesc: '', productFamilyCode: '' })
    } else if (field === 'productCategoryCode') {
        const payload = { ...newPayload }
        payload[field] = e.target.value === '' ? null : e.target.value;
        payload['productFamilyDesc'] = payload['productFamilyCode'] = null;
        dispatch(setNewPayload(payload))
        // Reseting dropdown fields 
        reset({ ...getValues(), productFamilyDesc: '', productFamilyCode: '' })
    } else if (field === 'productFamilyDesc') {
        const payload = { ...newPayload }
        payload[field] = e.target.value === '' ? null : e.target.value;
        payload['productFamilyCode'] = null;
        dispatch(setNewPayload(payload))
        // Reseting dropdown fields 
        reset({ ...getValues(), productFamilyCode: '' })
    } else if (field === 'productFamilyCode') {
        const payload = { ...newPayload }
        payload[field] = e.target.value === '' ? null : e.target.value;
        dispatch(setNewPayload(payload))
    }
}

export const sortingItemsMove = (data, setActivePage, ref, asec, setApiMaterials, desc) => {
    setActivePage(1)
    const temp = [...ref.current]
    if (data?.desc === false) {
        temp.sort(asec)
        setApiMaterials([...temp])
    }
    if (data?.desc === true) {
        temp.sort(desc)
        setApiMaterials([...temp])
    }
    if (data === undefined) {
        temp.sort(asec)
        setApiMaterials([...temp])
    }
}

export const getActivePageMove = (data, apiMaterials, setDisplayItems, setActivePage) => {
    const offset = (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT
    const temp = []
    for (let i = offset; i < LIST_VIEW_LIMIT + offset; i++) {
        if (apiMaterials[i]) {
            temp.push(apiMaterials[i])
        } else {
            break
        }
    }
    setDisplayItems([...temp])
    setActivePage(data);
}

export const handleAddItemsMove = (setAddTracker, setAddedItems, selectedItems, addedItems, apiMaterials, setApiMaterials) => {
    setAddTracker(true)
    const uniqueList = getUniqueListBy([...selectedItems, ...addedItems], 'materialNumber')
    setAddedItems(uniqueList)
    //setAddedItems([...selectedItems, ...addedItems])
    // Removing added items from main list
    const items = apiMaterials.filter((i) => !selectedItems.includes(i))
    setApiMaterials(items)
}

export const handleRemoveItemsMove = (setAddTracker, setApiMaterials, rightSelectedItems, apiMaterials, addedItems, setAddedItems) => {
    setAddTracker(true)
    // Add those removed items to main list
    setApiMaterials([...rightSelectedItems, ...apiMaterials])
    // Remove those items from right table
    const items = addedItems.filter((i) => !rightSelectedItems.includes(i))
    const uniqueList = getUniqueListBy(items, 'materialNumber')
    setAddedItems(uniqueList)
   // setAddedItems(items)
}

export const checkAddedMove = (addedItems, dispatch, setError) => {
    const addedNumbers = addedItems && addedItems.length > 0 && addedItems.map((data) => data.materialNumber)
    if (addedNumbers === false) {
        dispatch(setError('Please add materials!'))
    }
}