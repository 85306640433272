import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import { Header } from '../../secondary/Header'
import { ViewFormulary } from './viewFormulary'
import styles from './viewSingleFormulary.module.scss'

export let ViewSingleFormulary = ({ dispatch, history }) => {


    // If there is a formulary id in url, set viewmode to true
    // const { formularyId } = useParams();

  //  const [formId, setFormId] = useState(formularyId)

    return (
        <div>
            {/* Heading */}
            <div className={`${styles.back_btn}`}>
                <Header heading="Admin Portal" subHeading="Formulary" formulary={true} backLink={'/admin/formulary/'} />
            </div>

            <ViewFormulary dispatch={dispatch} history={history} createView={true} />

        </div>
    )
}

ViewSingleFormulary = connect()(withRouter(ViewSingleFormulary));