import React from 'react';
import { daysAgo, getDays } from '../../../utils';
import { Button } from '../../primary/Button/button';
import style from './historicalView.module.scss';


export const AllOtherAnatomy = ({ handleCurrent, humanAnatomy, historyCause, setCurrentId, otherAnotomy }) => {


    const sameAsCurrentWound = (data) => {
        setCurrentId(data)
        handleCurrent();
    }

    return (
        <div className={`${style.tab_pane}`}>
            {
                otherAnotomy && otherAnotomy.length > 0 ? otherAnotomy.map((data, index) => {
                    return <div key={index} className={`${style.wound_left_block} ${style.wound_left_block_in}`}>
                        <figure className="figure align-top">
                            <img className={`figure-img img-fluid ${style.image_size}`} src={data.picture} />
                        </figure>
                        <div className={`${style.patient_details}`}>
                            <p><label>History/Cause:</label><span>{historyCause && historyCause.length > 0 && historyCause.map((item) => {
                                let value;
                                if (item?.options?.length > 0) {
                                    item.options.map((sub) => {
                                        if (sub.key === data?.cause) {
                                            value = sub.value
                                        }
                                    })
                                }
                                else {
                                    if (item.key === data?.cause) {
                                        value = item.value
                                    }
                                }
                                return value;
                            })}</span></p>
                            <p><label>Anatomy:</label>
                                <span>
                                    {humanAnatomy && humanAnatomy.length > 0 && humanAnatomy?.map((item) => {
                                        let value;
                                        if (item.key === data?.anatomy) {
                                            value = item.value;
                                        }
                                        return value;
                                    })}
                                </span>
                            </p>
                            <p><label>Identified By:</label><span>{data?.identifiedBy}</span></p>
                            <p><label>Last Assessed:</label><span>{data?.lastAssessedDate ? daysAgo(data.lastAssessedDate) : 'NA'}</span></p>
                            <p><label>Wound Acquired:</label><span>{data?.woundAcquired}</span></p>
                            <Button
                                type={"submit"}
                                value="Same as the Current Wound"
                                id={"submit"}
                                classes={{ className: `btn pr-3 pl-3 ${style.btn_custom} ${style.btn_small} w-auto` }}
                                onClick={() => sameAsCurrentWound(data?.id)}
                            />

                        </div>

                    </div>
                })

                    :

                    <p className={`${style.heading_wound}`}>No records available.</p>

            }

        </div>
    )
}
