import React, { useState, useEffect, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button, MultiSelect } from "../../primary";
import { CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from "../../../constants";
import styles from "./assign.module.scss";
import { Modal } from "../../secondary";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ProductInputs } from "./productInputs";
import ReactTags from "react-tag-autocomplete";
import { getUniqueListBy } from "../../../utils";

export let AddMaterial = ({
  modalOpen,
  modalClose,
  getMaterialCode,
  index,
  materialList,
  pIndex,
  defaultValue,
  productMaterial,
}) => {
  const { register, errors, reset } = useForm();
  const [materialCodeTag, setMaterialCodeTag] = useState([]);
  const [materialCodeSuggestions, setMaterialCodeSuggestions] = useState([]);
  const materialCodTagsRef = useRef();

  const onDelete = useCallback(
    (tagIndex) => {
      const finalTag = materialCodeTag.filter((_, i) => i !== tagIndex);
      setMaterialCodeTag(finalTag);
    },
    [materialCodeTag]
  );

  const onAddition = useCallback(
    (newTag) => {
      const ids = materialCodeTag.map(({ id }) => id);
      if (!ids.includes(newTag.id)) {
        const finalTag = [...materialCodeTag, newTag];
        setMaterialCodeTag(finalTag);
      }
    },
    [materialCodeTag]
  );

  useEffect(() => {
    const getAllMaterialCode = [];
    const collAllFam =
      Array.isArray(materialList?.data?.options) &&
      materialList?.data?.options.map((items, index) => {
            return getAllMaterialCode.push({
              id: items.exactMaterialNumber,
              name: items.exactMaterialNumber,
            });
      });
    setMaterialCodeSuggestions(getAllMaterialCode);

    if (productMaterial?.length > 0) {
      let materialCodeDef = [];
      productMaterial?.map((item) => {
        materialCodeDef.push({ id: item, name: item });
      });
      Array.isArray(materialCodeDef) &&
        materialCodeDef.length > 0 &&
        materialCodeDef.sort((a, b) => (a.value > b.value ? 1 : -1));
      setMaterialCodeTag(materialCodeDef);
    }
  }, [materialList, defaultValue, modalOpen]);

  const addMaterialCode = () => {
    const defaultMaterialCodeBuket = materialCodeTag.map((e) => {
      return e.id;
    });
    getMaterialCode(defaultMaterialCodeBuket, index, pIndex);
    modalClose();
  };

  const modalRefresh = () => {
    reset();
    modalClose();
  };

  const handleCancel = (e) => {
    reset();
    e.preventDefault();
    modalClose();
  };

  return (
    <Modal
      modalOpen={modalOpen}
      modalClose={modalRefresh}
      heading={
        productMaterial?.length > 0
          ? "Edit Material #"
          : "Select Material #"
      }
      className={"modal_box"}
      modalSize={styles.custom_modal_size_family}
      reset={reset}
    >
      <div className={`pl-2 ${styles.patient_content} addFamily`}>
        <div className={`col-12 ${styles.col_spec}`}>
          <label className={`col-3 col-form-label ${styles.label_spec}`}>
            Material #:{" "}
          </label>
          <div className="col-9 p-0">
            <ReactTags
              ref={materialCodTagsRef}
              tags={materialCodeTag}
              suggestions={materialCodeSuggestions}
              onDelete={onDelete}
              onAddition={onAddition}
              classNames={{ root: `${styles.root_tag}` }}
              placeholderText={"Select material"}
              allowBackspace={false}
              readOnly={false}
              maxSuggestionsLength={10}
              minQueryLength={1}
            />
          </div>
        </div>

        <div className="col-sm-12">
          <div className="text-center pb-4">
            <Button
              classes={{
                className: `btn ${styles.btn_custom} ${styles.btn_small}`,
              }}
              type="button"
              id={SUBMIT_ID}
              onClick={() => addMaterialCode()}
            >
              {SAVE_BTN}
            </Button>
            <Button
              classes={{
                className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} handleCancel`,
              }}
              id={CANCEL_ID}
              onClick={handleCancel}
            >
              {CANCEL_BTN}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
AddMaterial = connect()(withRouter(AddMaterial));
