import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router";
import {
  getDownloadQualityReportCSVDetails,
  getQualityAssuranceWoundAssessmentWorklist,
  setQualityReportCsv,
} from "../../../app/woundReportSlice";
import {
  DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT,
  LIST_VIEW_LIMIT,
} from "../../../constants";
import Spreadsheet from "../../../static/images/ExportSpredsheet.svg";
import { firstLetterCaps } from "../../../utils";
import { DataTable, Pagination } from "../../secondary";
import style from "./qualityReportData.module.scss";
// import ReactExport from 'react-export-excel'
// const ExcelFile=ReactExport.ExcelFile;
// const ExcelSheet=ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn=ReactExport.ExcelFile.ExcelColumn;

export let QualityReportData = ({
  dispatch,
  history,
  woundProgressValue,
  tempPayload,
  selectedDivOption,
}) => {//NOSONAR
  useEffect(() => {
    setSortType('')
    setOffSet(0);
    setActivePage(1);
    const woundAssessmentPayload = {
      ...tempPayload,
      section: selectedDivOption,
      subsection: woundProgressValue,
    };
    const listPayLoad = {};
    listPayLoad["url"] = DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT;
    listPayLoad["method"] = "Post";
    listPayLoad["payload"] = woundAssessmentPayload;
    listPayLoad["queryParam"] = { offSet: 0, limit: LIST_VIEW_LIMIT };
    if (
      woundAssessmentPayload &&
      woundAssessmentPayload.facility &&
      woundAssessmentPayload.facility.length > 0
    ) {
      dispatch(getQualityAssuranceWoundAssessmentWorklist(listPayLoad));
    }
  }, [tempPayload, selectedDivOption]);
  const [activePage, setActivePage] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [sortType, setSortType] = useState('')
  const woundAssessmentData = useSelector(
    (state) => state.woundreport.qualityAssuranceAssessmentData
  );
  const customerName = useSelector((state) => state.user.customerName);

  useEffect(() => {
    dispatch(setQualityReportCsv({}));
    setTimeout(() => {
      const total =
        woundAssessmentData.woundAssessment && woundAssessmentData.metadata
          ? woundAssessmentData.metadata.total
          : 50;
      const queryParam = `limit=` + total + `&offSet=0`;
      const userDetailPayload = {
        ...tempPayload,
        section: selectedDivOption,
        subsection: woundProgressValue,
      };
      if (
        userDetailPayload &&
        userDetailPayload.facility &&
        userDetailPayload.facility.length > 0 &&
        total !== 0
      ) {
        dispatch(
          getDownloadQualityReportCSVDetails(userDetailPayload, queryParam)
        );
      }
    }, 100);
  }, [woundAssessmentData, tempPayload, selectedDivOption, woundProgressValue]);

  const qualityReportCsv = useSelector(
    (state) => state.woundreport.qualityReportCsv
  );

  const csvLink = useRef();

  const getFileName = () => {
    // const csvDownloadDate = format(new Date(), "MM.dd.yyyy-hh.mm.aaa");
    // return `${customerName}.${selectedDivOption}.${firstLetterCaps(
    //   woundProgressValue
    // )}.Quality_Assurance.${csvDownloadDate}.csv`;
    const csvDownloadDate = format(new Date(), "MM-dd-yyyy")
    return `NE1-Quality-Assurance-Report-${csvDownloadDate}.csv`;
  };

  const columns = [
    {
      Header: " Wound photo",
      accessor: "woundImage",
      imageData: "picture",
      restWidth: "110px",
      disableSortBy: true,
    },
    {
      Header: "Wound number",
      accessor: "woundNumber",
      restWidth: "150px",
    },
    {
      Header: "Photo taken by",
      accessor: "photoTakenBy",
      restWidth: "180px",
    },
    {
      Header: "Photo taken on",
      accessor: "photoTakenOn",
      restWidth: "200px",
    },
    {
      Header: "MRN #",
      accessor: "mrn",
      restWidth: "100px",
    },
    {
      Header: "Patient\'s name",
      accessor: "patientName",
      restWidth: "180px",
    },
    {
      Header: "History/Cause",
      accessor: "historyCause",
      restWidth: "200px",
    },
    {
      Header: "Anatomy",
      accessor: "anatomy",
      restWidth: "200px",
    },
    {
      Header: "Last assessed",
      accessor: "lastAccessed",
      restWidth: "200px",
    },
    {
      Header: "Admission date",
      accessor: "admissionDate",
      restWidth: "200px",
    },
    {
      Header: "Signed by",
      accessor: "signedBy",
      restWidth: "200px",
    },
    {
      Header: "Reviewed by",
      accessor: "reviewedBy",
      restWidth: "200px",
    },
  ];

  //redirect
  const getSelectedRowData = (data) => {
    if (data) {
      history.push(
        `/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`
      );
    }
  };

  const deleteDataList = () => {};

  const selectRowCount = () => {};

  const handleSort = useCallback(
    (data) => {
      if (data && data.sortBy[0] && data.sortBy[0].id) {
        const woundAssessmentPayload = {
          ...tempPayload,
          section: selectedDivOption,
          subsection: woundProgressValue,
        };
        const sortingColumn = data.sortBy[0];
        const sortingType = sortingColumn
          ? sortingColumn.desc
            ? ":DESC"
            : ":ASC"
          : "";
        let sortParam = sortingColumn.id + sortingType;
        const listPayLoad = {};
        listPayLoad["url"] = DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT;
        listPayLoad["method"] = "Post";
        listPayLoad["payload"] = woundAssessmentPayload;
        listPayLoad["queryParam"] = {
          offSet: isNaN(offSet)===true?0:offSet,
          limit: LIST_VIEW_LIMIT,
          sort: sortParam,
        };
        setSortType(sortParam)
        if (
          woundAssessmentPayload &&
          woundAssessmentPayload.facility &&
          woundAssessmentPayload.facility.length > 0
        ) {
          dispatch(getQualityAssuranceWoundAssessmentWorklist(listPayLoad));
        }
      }
    },
    [dispatch, tempPayload, selectedDivOption]
  );
  const getActivePage = (data) => {
    setActivePage(data);
    const woundAssessmentPayload = {
      ...tempPayload,
      section: selectedDivOption,
      subsection: woundProgressValue,
    };
    const listPayLoad = {};
    listPayLoad["url"] = DASHBOAD_QUALITY_ASSURANCE_ASSESSMENT;
    listPayLoad["method"] = "Post";
    listPayLoad["payload"] = woundAssessmentPayload;
    listPayLoad["queryParam"] = {
      limit: LIST_VIEW_LIMIT,
      offSet:
        data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT < LIST_VIEW_LIMIT
          ? 0
          : data * LIST_VIEW_LIMIT - LIST_VIEW_LIMIT,
          sort: (sortType)?sortType:''
    };
    setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
    if (
      woundAssessmentPayload &&
      woundAssessmentPayload.facility &&
      woundAssessmentPayload.facility.length > 0
    ) {
      dispatch(getQualityAssuranceWoundAssessmentWorklist(listPayLoad));
    }
  };
  const fileHeaders = [
    { label: "Wound Number", key: "woundNumber" },
    { label: "MRN #", key: "mrn" },
    { label: "Patient\'s Name", key: "patientName" },
    { label: "Photo Taken By", key: "photoTakenBy" },
    { label: "Photo Taken On", key: "photoTakenOn" },
    { label: "History/Cause", key: "historyCause" },
    { label: "Anatomy", key: "anatomy" },
    { label: "Last Assessed", key: "lastAccessed" },
    { label: "Admission Date", key: "admissionDate" },
    { label: "Signed By", key: "signedBy" },
    { label: "Reviewed By", key: "reviewedBy" },
  ];

  return (
    <>
      {qualityReportCsv?.woundAssessment &&
        qualityReportCsv?.woundAssessment.length > 0 && (
          <>
            <div className="col-sm-12 mb-2 justify-content-end d-flex">
              <span className={style.down_spread}>
                <img src={Spreadsheet} alt="" />
                {/* <ExcelFile filename={getFileName()} element={<label className={style.excel}> Download as CSV</label>} >
                    <ExcelSheet data={qualityReportCsv?.woundAssessment} name={getFileName()}>
                        <ExcelColumn label="Wound Id" value="woundId" />
                        <ExcelColumn label="Patient Name" value="patientName" />
                        <ExcelColumn label="Photo Taken By" value="photoTakenBy" />
                        <ExcelColumn label="Photo Taken On" value="photoTakenOn" />
                        <ExcelColumn label="History/Cause" value="historyCause" />
                        <ExcelColumn label="Anatomy" value="anatomy" />
                        <ExcelColumn label="Last Assessed" value="lastAccessed" />
                        <ExcelColumn label="Admission Date & Time" value="admissionDate" />
                        <ExcelColumn label="Signed By" value="signedBy" />
                        <ExcelColumn label="Reviewed By" value="reviewedBy" />
                        
                    </ExcelSheet>
                </ExcelFile> */}

                <label>
                  <CSVLink
                    onClick={() =>
                      (csvLink.current.link.download = getFileName())
                    }
                    headers={fileHeaders}
                    data={qualityReportCsv?.woundAssessment}
                    fileName={getFileName}
                    target="_blank"
                    ref={csvLink}
                  >
                    Download as CSV
                  </CSVLink>
                </label>
              </span>
            </div>
          </>
        )}
      {woundAssessmentData && woundAssessmentData?.woundAssessment && (
        <div className="col-12">
          <DataTable
            columns={columns}
            data={woundAssessmentData && woundAssessmentData?.woundAssessment}
            sorting={true}
            getSelectedRowData={getSelectedRowData}
            setSelectedRows={selectRowCount}
            onSort={handleSort}
            detailView={true}
            deleteDataList={deleteDataList}
            showFixedColumn={false}
            workListView={true}
            reportView={true}
            sortType={(sortType)?sortType:''}
          />
        </div>
      )}

      {Array.isArray(woundAssessmentData.woundAssessment) &&
        woundAssessmentData.woundAssessment.length > 1 && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={
              woundAssessmentData?.metadata
                ? woundAssessmentData?.metadata?.limit
                : 50
            }
            totalItemsCount={
              woundAssessmentData?.metadata
                ? woundAssessmentData?.metadata?.total
                : 0
            }
            getActivePage={getActivePage}
            reportView={true}
          />
        )}
    </>
  );
}//NOSONAR
QualityReportData.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
};
QualityReportData = connect()(withRouter(QualityReportData));
