import React from 'react'
import {connect} from 'react-redux'
import {withRouter,useLocation} from 'react-router-dom'
import { ResetPasswordForm } from '../components'
import { resetPasswordAsync,updatesSSOuserPasswordAsync } from '../app/userSlice'
import { UrlParams } from '../utils/commonHelper'
import PropTypes from 'prop-types'

export let ResetPassword = ({dispatch, history}) => {
    const paramLocationData = useLocation();
  const dynParam=paramLocationData?.state?.dynParam
    const handleSubmit = data => {
        const params = new UrlParams(window.location.search);
        if(dynParam&&dynParam?.acct_status==='isPasswordConfigured'&&dynParam?.email){
            dispatch(updatesSSOuserPasswordAsync(dynParam?.email, data, history));
    
        }else{
            dispatch(resetPasswordAsync(params.get('username'), `${params.get('token')}`, data, history));
        }
  } 

    return <ResetPasswordForm onSubmit={handleSubmit} dynParam={dynParam}/>
}

ResetPassword.propTypes = {
    dispatch : PropTypes.func, 
    history : PropTypes.object
}

ResetPassword = connect()(withRouter(ResetPassword))