import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { Form, TextField, SelectBox, RadioButton, CheckBox, Button } from '../../primary'
import styles from './assign.module.scss'
import { EDIT, CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants'
import editIcon from '../../../static/images/edit-icon-blue.png'
import rightArrow from '../../../static/images/right_arrow.svg'
import topArrow from '../../../static/images/toparrow.svg'
import downArrow from '../../../static/images/downarrow.svg'
import deleteIcon from '../../../static/images/delete-icon.png'
import plusIcon from '../../../static/images/plus_icon_gray.svg'
import branch_icon from '../../../static/images/branch_icon.svg'
import plusIconWhite from '../../../static/images/plus-icon_white.svg'
import { SingleSelect } from './singleSelect'
import { MultiSelect } from './multiSelect'
import { TextBox } from './textBox';
import { setUpdateStatus, productCategoryListOptions,getMateriallist, updateFormBuilderMediaList} from '../../../app/customFormSlice';
import { AddOptionDropDown } from './addOptionDropDown';
import { ProductAttributeDivision } from './productAttributeDivision';
import { AddRule } from './addRule'
import { AddRuleText } from './addRuleText'
import { CloseWarning } from '../../secondary';
import { Confirmation } from '../../secondary/Confirmation/confirmation'
import warning from '../../../static/images/icon-check-danger.png';
import { checkCoreDescription } from '../../../utils'
import PlusIcon from '../../../static/images/plus-icon_white.svg';
import { MultiFileUploadFormBuilder } from './multiFileUploadFormBuilder'
import ImageIcon from '../../../static/images/products/image_icon.svg';
import CloseIcon from '../../../static/images/products/close_icon.svg';
import Loader from '../../../static/images/spinner.svg';
export const FormBuilder = ({ formData, upDateForm, handleCancel }) => {
   
    /* Use hook form */
    let dispatch = useDispatch();
    const { register, handleSubmit, control, errors, reset } = useForm({
        mode: 'all'
    });

    let formBuilder = "";
    if (formData?.formBuilderInfo && formData?.formBuilderInfo.length > 0) {
        formBuilder = [...formData.formBuilderInfo];
        formBuilder.sort((a, b) => {
            return a.order - b.order;
        })
    } else {
        formBuilder = [{
            "id": 0,
            "desc": "",
            "order": 1,
            "options": [],
            "optionType":'default',
            "media":{
                "images":[],
               "embedVideo":[]
           }
        }]
    }
    useEffect(() => {
        reset({});
        setTimeout(() => {
            dispatch(setUpdateStatus({}))
        }, 1000)
        setcustomFormBuilder(formBuilder)
    }, [formData])

    useEffect(() => {
        if (formData?.formBuilderInfo?.length > 0) {
            dispatch(productCategoryListOptions())
            dispatch(getMateriallist())
        }
    }, [formData])

    const productCategoryList = useSelector(state => state.forms.productCategoryListOptions)
    const materialList = useSelector(state => state.forms.materiallist)

    let upDateStatus = useSelector(state => state.forms.upDateStatus)

    let [customFormBuilder, setcustomFormBuilder] = useState(formBuilder)
    const [getRule, setRule] = useState(false)
    const [ruleList, setRuleList] = useState([])
    
    const addRuleButton = () => {
        handleAddMediaCancel();
        setRule(false)
        let result = { ...formData }
        result.formBuilderInfo = customFormBuilder
        upDateForm(result);
    }
    const [ruleEmpty, setRuleEmpty] = useState('');
    const handleDeleteImage = (imagesList,formId,questionId,questionIndex,indexImg) => {
        //indexImg
        const filtered = Array.isArray(imagesList)&&imagesList.filter((data, i) => i !== indexImg)
    let  list= {images:[]}
     if (filtered && filtered.length > 0) {
        list['images'] = [...filtered]
    }
    dispatch(updateFormBuilderMediaList({formId,questionId,list,questionIndex,customFormBuilder,setcustomFormBuilder}))
    handleAddMediaCancel();
        
    }
    const onSubmit = () => {
        handleAddMediaCancel();
        const ele = document.querySelector('.err-msg-product');
        let result = { ...formData }
        let optionData = false
        let saveRuleOption = false
        customFormBuilder?.map((item, index) => {//NOSONAR
            item?.options?.map((itemOpt) => {//NOSONAR
                if (itemOpt.value === "") {
                    optionData = true
                    setQuestionError({ index: index })
                    document?.querySelector(`.err_val${index}`)?.scrollIntoView({ behavior: "smooth" })
                }
                if(itemOpt.goto === 'eof' && itemOpt.rule === null && item.id !==0&&formData?.assignInfo?.feature != "NE1 WMS") {
                    setRuleEmpty('One / more End of form question rule not configured');
                    optionData = true
                    document?.querySelector(`.err_val${index}`)?.scrollIntoView({ behavior: "smooth" })
                }
                if(itemOpt.goto === 'eof' && itemOpt.rule === undefined) {
                    saveRuleOption = true 
                }
            })
            if (item?.desc === "") { //NOSONAR
                optionData = true
                setQuestionError({ index: index })
                document?.querySelector(`.err_val${index}`)?.scrollIntoView({ behavior: "smooth" })
                document?.querySelector(`.err_val${index} input[type='text']`)?.focus()
            } //NOSONAR
            if(item.goto === 'eof' && item.rule === null&&formData?.assignInfo?.feature != "NE1 WMS") {
                setRuleEmpty('One / more End of form question rule not configured');
                optionData = true
                document?.querySelector(`.err_val${index}`)?.scrollIntoView({ behavior: "smooth" })
            }
            if(item.goto === 'eof' && item.rule === undefined&&formData?.assignInfo?.feature != "NE1 WMS") {
                saveRuleOption = true 
            }
        })//NOSONAR
        if(ele?.offsetTop !== undefined) {
            optionData = true;
        }
        if(getRuleIndex.length > 0) {
            optionData = true;
        }
        if (!optionData) {
            setQuestionError({})
            if (saveRuleOption && formData?.assignInfo?.feature != "NE1 WMS") {
                setRule(true);
                setmodalShow(true);
                setUpdateStatus({})
            } else {
                setRuleEmpty('')
                result.formBuilderInfo = customFormBuilder
                upDateForm(result);
            }
        }
    }
    const dropRef = useRef();
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const handleClickOutside = (e) => {
        if (dropRef !== null && dropRef.current && !dropRef.current.contains(e.target)) {
            setBranch(false);
        }
    };

    const [branch, setBranch] = useState(false)
    const [addBranchIndex, setbranchIndex] = useState("")
    const addBranch = (index) => {
        handleAddMediaCancel();
        setBranch(true)
        setbranchIndex(index)
    }

    // select question type
    const selectAnswerType = (value, index) => {
        handleAddMediaCancel();
        let customFormObject = [...customFormBuilder]
        if (value !== "") {
            let selectTypeDat = { ...customFormObject[index] }
            selectTypeDat.type = value
            selectTypeDat.goto = ''
            if (value !== "text") {
                selectTypeDat.options = [{
                    "key": "1",
                    "value": "",
                    "order": 1,
                    "goto":""
                },
                {
                    "key": "2",
                    "value": "",
                    "order": 2,
                    "goto":""
                }]
            } else {
                selectTypeDat.options = []
            }
            let customFormObj = [...customFormObject]
            customFormObj.splice(index, 1, selectTypeDat)
            setcustomFormBuilder(customFormObj)
        }
    }

    // add branch
    const addBranchValue = (branchValue, pIndex) => {
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[pIndex] };
        if (branchValue !== "Add for options") {
            selectTypeDat.goto = 'next'
            delete selectTypeDat.rule
            let addGoTo = [...selectTypeDat.options].map((value, index) => {
                let gotoAdd = { ...selectTypeDat.options[index] }
                gotoAdd.goto = ''
                delete gotoAdd.rule
                return gotoAdd
            })
            selectTypeDat.options = addGoTo
        } else {
            delete selectTypeDat.rule
            selectTypeDat.goto = ''
            let addGoTo = [...selectTypeDat.options].map((value, index) => {
                let gotoAdd = { ...selectTypeDat.options[index] }
                gotoAdd.goto = 'next'
                delete gotoAdd.rule
                return gotoAdd
            })
            selectTypeDat.options = addGoTo
        }
        let customFormObj = [...customFormObject]
        customFormObj.splice(pIndex, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
        setBranch(false)
    }

    // add new answer based on type
    const addNewQuestionOption = (value, type, parentIndex) => {
        handleAddMediaCancel();
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = [...customFormObject[parentIndex].options]
       
        let addNewQuestionOptionVal = {
            "1": `Option${selectTypeDat.length + 1}`,
            "2": "Others",
            "3": "All of the above",
            "4": "None of the above"
        }
        let addNewQuestionOptionKey = {
            "1": `Option${selectTypeDat.length + 1}`,
            "2": "other",
            "3": "alloftheabove",
            "4": "noneoftheabove"
        }
        
        if(customFormObject[parentIndex]&&customFormObject[parentIndex]?.optionType&&customFormObject[parentIndex]?.optionType==='productattribute'){
            selectTypeDat = [...selectTypeDat, {
                "order": (selectTypeDat.length + 1) * 1,
                "key": `${value.key !== "1" ? addNewQuestionOptionKey[value.key] : selectTypeDat.length + 1}`,
                "value": (value.key !== "1") ? addNewQuestionOptionVal[value.key] : "",
                "goto": selectTypeDat[0]&&selectTypeDat[0].goto !== null && selectTypeDat[0].goto !== undefined && selectTypeDat[0].goto !== "" ? "next" : "",
                "attributeValueKey":0
            }]
        }else{
            selectTypeDat = [...selectTypeDat, {
                "order": (selectTypeDat.length + 1) * 1,
                "key": `${value.key !== "1" ? addNewQuestionOptionKey[value.key] : selectTypeDat.length + 1}`,
                "value": (value.key !== "1") ? addNewQuestionOptionVal[value.key] : "",
                "goto": selectTypeDat[0]&&selectTypeDat[0].goto !== null && selectTypeDat[0].goto !== undefined && selectTypeDat[0].goto !== "" ? "next" : "",
            }]
        }
        let addAnswer = { ...customFormObject[parentIndex] }
        addAnswer.options = selectTypeDat
        let other = addAnswer?.options?.find(o => o.value === 'Others');
        let alloftheabove = addAnswer?.options?.find(o => o.value === 'All of the above');
        let noneoftheabove = addAnswer?.options?.find(o => o.value === 'None of the above');
        addAnswer.addOption = [
            { key: "1", value: "Add answer", visible: 1 },
            { key: "2", value: "Add other", visible: other ? 0 : 1 },
            { key: "3", value: "Add all of the above", visible: alloftheabove ? 0 : 1 },
            { key: "4", value: "Add none of the above", visible: noneoftheabove ? 0 : 1 }
        ]
        let customFormObj = [...customFormObject]
        customFormObj.splice(parentIndex, 1, addAnswer)
        setcustomFormBuilder(customFormObj)
    }

    // deleteAnswerOption
    const deleteAnswerOption = (index, pIndex) => {
        handleAddMediaCancel();
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = [...customFormObject[pIndex].options]
        let optionLength = selectTypeDat.length
        let addOption = { ...customFormObject[pIndex] }
        optionLength > 2 && selectTypeDat.splice(index, 1)
        addOption.options = selectTypeDat
        let other = selectTypeDat?.find(o => o.value === 'Others');
        let alloftheabove = selectTypeDat?.find(o => o.value === 'All of the above');
        let noneoftheabove = selectTypeDat?.find(o => o.value === 'None of the above');
        addOption.addOption = [
            { key: "1", value: "Add answer", visible: 1 },
            { key: "2", value: "Add other", visible: other ? 0 : 1 },
            { key: "3", value: "Add all of the above", visible: alloftheabove ? 0 : 1 },
            { key: "4", value: "Add none of the above", visible: noneoftheabove ? 0 : 1 }
        ]
        let customFormObj = [...customFormObject]
        customFormObj.splice(pIndex, 1, addOption)
        setcustomFormBuilder(customFormObj)
        reset({});
    }

    // up arrow sequence
    const upArrowSequence = (index) => {
        handleAddMediaCancel();
        if (index !== 0) {
            reset({});
            let selectTypeDat = [...customFormBuilder]
            selectTypeDat.splice(index - 1, 0, selectTypeDat[index])
            selectTypeDat.splice(index + 1, 1)
            let orderChange = selectTypeDat.map((value, index) => {
                let order = { ...value }
                order.order = (index + 1) * 1
                return order;
            })
            setcustomFormBuilder(orderChange)
        }
    }

    // down arrow sequence
    const downArrowSequence = (index) => {
        handleAddMediaCancel();
        let selectTypeDat = [...customFormBuilder]
        if (selectTypeDat.length > 1 && selectTypeDat.length - 1 !== index) {
            reset({});
            selectTypeDat.splice(index, 0, selectTypeDat[index + 1])
            selectTypeDat.splice(index + 2, 1)
            let orderChange = selectTypeDat.map((value, index) => {
                let order = { ...value }
                order.order = (index + 1) * 1
                return order;
            })
            setcustomFormBuilder(orderChange)
        }
    }

    const getInputValue = (e, pIndex) => {
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[pIndex] }
        selectTypeDat.placeholder = e.target.value
        let customFormObj = [...customFormObject]
        customFormObj.splice(pIndex, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
    }

    const addNewQuestionOptionSelect = (value, type, parentIndex) => {
        addNewQuestionOption(value, type, parentIndex)
    }

    const getAttributeVal = (e, index, attribute) => {
        handleAddMediaCancel();
        e.stopPropagation();
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[index] }

        if (attribute === "productmaterial") {               
            if(document.getElementById('productfamily'+index+1)){
                document.getElementById('productfamily'+index+1).checked = false;
            }
            if(document.getElementById('productattribute'+index+1)){
                document.getElementById('productattribute'+index+1).checked = false;
            }
            if(document.getElementById('productfamily'+index+0)){
                document.getElementById('productfamily'+index+0).checked = false;
            }
            if(document.getElementById('productattribute'+index+0)){
                document.getElementById('productattribute'+index+0).checked = false;
            }
        }
        if (attribute === "productattribute") {               
            if(document.getElementById('productfamily'+index+1)){
                document.getElementById('productfamily'+index+1).checked = false;
            }
            if(document.getElementById('productmaterial'+index+1)){
                document.getElementById('productmaterial'+index+1).checked = false;
            }
            if(document.getElementById('productfamily'+index+0)){
                document.getElementById('productfamily'+index+0).checked = false;
            }
            if(document.getElementById('productmaterial'+index+0)){
                document.getElementById('productmaterial'+index+0).checked = false;
            }
        }
        if (attribute === "productfamily") {               
            if(document.getElementById('productattribute'+index+1)){
                document.getElementById('productattribute'+index+1).checked = false;
            }
            if(document.getElementById('productmaterial'+index+1)){
                document.getElementById('productmaterial'+index+1).checked = false;
            }
            if(document.getElementById('productattribute'+index+0)){
                document.getElementById('productattribute'+index+0).checked = false;
            }
            if(document.getElementById('productmaterial'+index+0)){
                document.getElementById('productmaterial'+index+0).checked = false;
            }
        }      
      
        if (e.target.value === "Yes") {
            selectTypeDat.optionType = attribute
            delete selectTypeDat.type;
            delete selectTypeDat.productAttributeFilter;
            
            if (attribute === "productfamily"||attribute === "productmaterial") {               
                selectTypeDat.options = [{
                    "key": "1",
                    "value": "",
                    "order": 1
                },
                {
                    "key": "2",
                    "value": "",
                    "order": 2
                }]
                selectTypeDat.type = "singleselect"
            }
            selectTypeDat.goto = ''
            if(attribute === "productmaterial"){
                dispatch(getMateriallist())
            }else{
                dispatch(productCategoryListOptions())
            }
            
        } else {
            selectTypeDat.optionType = null
            selectTypeDat.options = []
            delete selectTypeDat.type;
            selectTypeDat.goto = ''
            if(document.getElementById('questionType'+index)){
                document.getElementById('questionType'+index).selectedIndex = '';
            }
        }
        let customFormObj = [...customFormObject]
        customFormObj.splice(index, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
    }


    const fetchValue = (index, res, productAttr) => {
        handleAddMediaCancel();
        reset({});
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[index] }
        selectTypeDat.type = "singleselect"
        selectTypeDat.productAttributeFilter = productAttr
        selectTypeDat.options = res.data&&res.data.attributeValue?res.data.attributeValue:''
        let customFormObj = [...customFormObject]
        customFormObj.splice(index, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
    }
    const productAttributeOrderChange = (orderType,index, pIndex,fetchIndex) => {
        handleAddMediaCancel();
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[pIndex] }
        let selectTypeDatOptions=[...selectTypeDat.options]
        if (index !== 0&&orderType==='up') {
           reset({});
           selectTypeDatOptions.splice(index - 1, 0, selectTypeDatOptions[index])
          selectTypeDatOptions.splice(index + 1, 1)
            let orderChange = selectTypeDatOptions.map((value, index) => {
                let order = { ...value }
                order.order = (index + 1) * 1
                return order;
            })
            selectTypeDat.options = orderChange
            let customFormObj = [...customFormObject]
            customFormObj.splice(pIndex, 1, selectTypeDat)
            setcustomFormBuilder(customFormObj)
        }
        if (selectTypeDatOptions.length > 1 && selectTypeDatOptions.length - 1 !== index&&orderType==='down') {
            reset({});
            selectTypeDatOptions.splice(index, 0, selectTypeDatOptions[index+1])
            selectTypeDatOptions.splice(index + 2, 1)
             let orderChange = selectTypeDatOptions.map((value, index) => {
                 let order = { ...value }
                 order.order = (index + 1) * 1
                 return order;
             })
            selectTypeDat.options = orderChange
            let customFormObj = [...customFormObject]
            customFormObj.splice(pIndex, 1, selectTypeDat)
             setcustomFormBuilder(customFormObj)
         }     
    }
    const [modalShowRule, setmodalShowRule] = useState(false)
    const [getIndex, setIndex] = useState({})
    const [editRule, setEditRule] = useState()

    const addRuleData = (ruleValue, pIndex, index) => {
        handleAddMediaCancel();
        let customFormObject = [...customFormBuilder]
        const rule = customFormObject.slice(0, pIndex + 1)
        setRuleList(rule)
        setmodalShowRule(true)
        setIndex({ pIndex: pIndex, index: index })
        setEditRule(ruleValue)
        setRuleEmpty('')
        reset({})
    }
    //questions
    const questions = (qvalue, index) => {
        let pIndex = index;
        let defaultGotoOption = [{ value: "Next", key: "next" }, { value: "End of form", key: "eof" }];
        let dataOption = [];
        [...customFormBuilder].map((value, formIndex) => {//NOSONAR
            return dataOption = [...dataOption, { value: `${formIndex + 1} - ${value.desc}`, key: `${formIndex + 1}` }]//NOSONAR
        })//NOSONAR
        let gotoOption = [...defaultGotoOption, ...dataOption.splice(index + 2, dataOption.length)]
        let value = { ...qvalue }
        let other = value?.options?.find(o => o.value === 'Others');
        let alloftheabove = value?.options?.find(o => o.value === 'All of the above');
        let noneoftheabove = value?.options?.find(o => o.value === 'None of the above');
        value.addOption === undefined ? value.addOption = [
            { key: "1", value: "Add answer", visible: 1 },
            { key: "2", value: "Add other", visible: other ? 0 : 1 },
            { key: "3", value: "Add all of the above", visible: alloftheabove ? 0 : 1 },
            { key: "4", value: "Add none of the above", visible: noneoftheabove ? 0 : 1 }
        ] :
            value.addOption = value?.addOption//NOSONAR

        let branchOption = []
        if(value.type !=='singleselect') {
            branchOption = ["Add for question"]
        } else {
            branchOption = ["Add for question", "Add for options"]
        }
        return (
            <>
                <TextField
                    id={`text${value.id}`}
                    name={`text${value.id}`}
                    classes={{
                        column: `col-5 ${styles.col_spec} ${'err_val'+index}`,
                        labelClass: `col-1 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-11 p-0 outline`,
                        inputClassName: `${styles.basic_input} getQues ml-5`
                    }}
                    type="text"
                    validator={register()}
                    errors={errors}
                    placeholder={"Type here"}
                    defaultValue={value.desc}
                    onChange={(e) => getQutions(e.target.value, index)}
                    title={value.desc}
                />
               {value?.media&&value?.media?.images &&Array.isArray(value?.media?.images)&& value?.media?.images.length<5&&<Button
                        classes={{ className: `btn ${styles.btn_customs} ${styles.btn_smalls}` }}
                        type="button"
                        id={SUBMIT_ID}
                        onClick={(e)=>handleAddMedia(index)}
                    >
                        <img src={PlusIcon} alt="edit" />Add Media
                </Button>}
                {value?.media&&value?.media?.images &&Array.isArray(value?.media?.images)&& value?.media?.images.length>=5&&<Button
                        classes={{ className: `btn ${styles.btn_custom_disable} ${styles.btn_smalls}` }}
                        type="button"
                        id={SUBMIT_ID}
                    >
                        <img src={PlusIcon} alt="edit" />Add Media
                </Button>}
                
                {addMedia && addMedia?.index === index && <div className={`dropdown-menu show ${styles.media_align}`} aria-labelledby="dropdownMenu3" ref={dropRef}>
                    <MultiFileUploadFormBuilder
                    handleAddMediaCancel={handleAddMediaCancel}    
                    questionId={value?.id?value?.id:0}     
                    formId={formData?.id}  
                    mediaList={value?.media} 
                    questionIndex={index}  
                    customFormBuilder={customFormBuilder}  
                    setcustomFormBuilder={setcustomFormBuilder}        
                    />
                </div>}
                {value.optionType === null || value.optionType === undefined ||value.optionType === "default" ? <SelectBox
                    id={`questionType${index}`}
                    name={`questionType${index}`}
                    label={`Type:`}
                    classes={{
                        column: `col-5 ${styles.col_spec}`,
                        inputClassName: `${styles.type_basic_select} selectAnswer`,
                        labelClassName: `col-3 col-form-label ${styles.type_label_spec}`,
                        inputColumn: `col-9 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={[
                        { key: 'singleselect', value: 'Single Select' },
                        { key: 'multiselect', value: 'Multi Select' },
                        { key: 'text', value: 'Text Box' }
                    ]}
                    placeholder={"Select type"}
                    validator={register()}
                    errors={errors}
                    defaultValue={value.type}
                    onChange={(e) => selectAnswerType(e.target.value, index)}
                    customForm={true}
                /> : ''}
                <TextField
                    id={`coretext${index}`}
                    name={`coretext${index}`}
                    classes={{
                        column: `col-9 ${styles.col_spec} ml-5  mt-n3 mb-1`,
                        labelClass: `col-1 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 outline`,
                        inputClassName: `${styles.basic_input_core_des} ${value.coreDesc?styles.basic_input_core_foc:''} getQues`
                    }}
                    type="text"
                    validator={register(checkCoreDescription)}
                    errors={errors}
                    placeholder={"Description"}
                    defaultValue={value.coreDesc}
                    onChange={(e) => getQutionsCoreDesc(e.target.value, index)}
                />
                {showQuestionError&&showQuestionError?.index === index ? <div className="col-12 pb-4 ml-0 mt-0 mb-n4"><span className={`${styles.error_message} ${styles.pl_32}`}>{`Please enter question ${value.type !=="text" ? 'and options':''}`}</span></div> : false}
                {formData?.assignInfo?.feature != "NE1 WMS" && <>
                {(value.optionType === null || value.optionType === undefined||value.optionType === "default" || value.optionType==="productattribute" ||value.optionType==="productfamily"||value.optionType==="productmaterial")?<div className={`ml-5 col-6 ${styles.col_spec} mb-0`}>
                        <div className={`col-sm-12 p-0`}>
                            <label className={`col-5 col-form-label mr-0 ${styles.label_in_spec}`}>Product Attribute:</label>
                            <RadioButton
                                id={`productattribute${index}`}
                                name={`productattribute${index}`}
                                classes={{
                                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                    inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                    inputColumn: "custom-control custom-radio custom-control-inline"
                                }}
                                validator={register}
                                radioOption={['Yes', 'No']}
                                defaultValue={value.optionType === "productattribute" ? "Yes" : ""}
                                onChange={(e) => getAttributeVal(e, index, 'productattribute')}
                                onClick={(e) => getAttributeVal(e, index, 'productattribute')}
                            />
                        </div>
                    </div>:false}
                    {(value.optionType === null || value.optionType === undefined||value.optionType === "default" ||value.optionType==="productattribute" ||value.optionType==="productfamily"||value.optionType==="productmaterial")&&<div className={`ml-5 col-6 ${styles.col_spec} mb-0`}>
                        <div className={`col-sm-12 p-0`}>
                            <label className={`col-5 col-form-label mr-0 ${styles.label_in_spec}`}>Product Family:</label>
                            <RadioButton
                                id={`productfamily${index}`}
                                name={`productfamily${index}`}
                                classes={{
                                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                    inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                    inputColumn: "custom-control custom-radio custom-control-inline"
                                }}
                                validator={register}
                                radioOption={['Yes', 'No']}
                                defaultValue={value.optionType === "productfamily" ? "Yes" : ""}
                                onChange={(e) => getAttributeVal(e, index, 'productfamily')}
                                onClick={(e) => getAttributeVal(e, index, 'productfamily')}
                            />
                        </div>
                    </div> }
                     {(value.optionType === null || value.optionType === undefined||value.optionType === "default" ||value.optionType==="productattribute" ||value.optionType==="productfamily"||value.optionType==="productmaterial")&&<div className={`ml-5 col-6 ${styles.col_spec} mb-2`}>
                        <div className={`col-sm-12 p-0`}>
                            <label className={`col-5 col-form-label mr-0 ${styles.label_in_spec}`}>Product Material #:</label>
                            <RadioButton
                                id={`productmaterial${index}`}
                                name={`productmaterial${index}`}
                                classes={{
                                    labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                                    inputClassName: `custom-control-input ${styles.custom_control_input}`,
                                    inputColumn: "custom-control custom-radio custom-control-inline"
                                }}
                                validator={register}
                                radioOption={['Yes', 'No']}
                                defaultValue={value.optionType === "productmaterial" ? "Yes" : ""}
                                onChange={(e) => getAttributeVal(e, index, 'productmaterial')}
                                onClick={(e) => getAttributeVal(e, index, 'productmaterial')}
                            />
                        </div>
                    </div>}
                </>}
                {value.optionType === "productattribute" && <div className="col-sm-12">
                    <div className={`${styles.product_sub_block}`}>
                        <ProductAttributeDivision
                            fetchValue={(val, res, attr) => fetchValue(val, res, attr)}
                            index={index}
                            defaultValue={value?.productAttributeFilter}
                            productCategoryList={productCategoryList}
                        />
                    </div>
                </div>}
                {
                    value.type !== "text" ? value.options.map(
                        (optionValue, optionIndex) => questionAnswer(index, optionValue, optionIndex, value.type, value.options.length, gotoOption, value.addOption, value.optionType, optionValue.productFamily, optionValue.productFilter, optionValue.rule, value.id,optionValue.productMaterial)
                    ) : <TextBox
                        id="entertext"
                        index={index}
                        pIndex={index}
                        getGotoValue={getGotoValue}
                        goto={value.goto}
                        gotoOption={gotoOption}
                        getInputValue={getInputValue}
                        placeholder={value.placeholder}
                    />
                }
                {value.type !== "text" && value.goto !== '' && value.goto !== undefined && value.goto !== null &&
                    <>
                        <div className="col-7"></div>
                        <SelectBox
                            id={"Goto"}
                            name={"Goto"}
                            label={`Go to:`}
                            classes={{
                                column: `col-3 ${styles.col_spec}`,
                                inputClassName: `${styles.type_basic_select} select_Goto`,
                                labelClassName: `col-4 col-form-label ${styles.type_label_spec}`,
                                inputColumn: `col-12 p-0 ${styles.select_wrapper}`
                            }}

                            optionValue={gotoOption}
                            defaultValue={value.goto}
                            onChange={(e) => getGotoValue(e.target.value, index)}
                            customForm={true}
                        />
                    </>
                }
                {formData?.assignInfo?.feature != "NE1 WMS" && value?.goto === "eof" && value?.rule !== undefined && <div className="col-2 p-0">
                    <button type="button" className={`btn ${styles.btn_custom_rule} w-auto`} onClick={() => addRuleData(value.rule, index)}>{value.rule === null || value.rule === undefined ? 'Add Rule' : 'Edit Rule'}</button>
                </div>}
                {getRuleIndex.includes(value?.id) ? <div className="col-12 text-right"><span className={`${styles.error_message} ${styles.pr_90}`}>{`One or more questions have been deleted, please review the rule.`}</span></div> : ''}
                {value.type !== undefined && value.type !== "" && <div className={`col-6 ${styles.col_spec}`}>
                    <div className={"col-3 p-0 offset-1"}>
                        <button type="button" className={`btn ${styles.btn_custom_branch} addbranch ml-3`} onClick={() => addBranch(index)} ref={dropRef}>
                            Branching
                        </button>
                        {branch && pIndex === addBranchIndex && <div className={`dropdown-menu ${styles.dropdown_align_add} show`} ref={dropRef}>
                            {
                                branchOption.map(
                                    (value, index) => <p className={`${styles.dropdown_heading_add} addBranchValue`} key={index} onClick={() => addBranchValue(value, pIndex)} data-testid={`addBranchValue${index}`}>
                                        <span>{value}</span>
                                    </p>
                                )
                            }
                        </div>}
                    </div>
                    {value.type !== "text" && value.optionType === "productfamily" && <AddOptionDropDown
                        addNewQuestionOption={addNewQuestionOptionSelect}
                        type={value.type}
                        parentIndex={pIndex}
                        addOption={value.addOption}
                        clsName={value.type}
                    />}
                    {value.type !== "text" && value.optionType === "productattribute" && <div className={"col-4 p-0"}>
            <button type="button" className={`btn ml-5 w-auto ${styles.btn_custom_branch_answer}`} onClick={() => addNewQuestionOptionSelect({key: '1', value: 'Add answer', visible: 1},'singleselect',pIndex)}>
            Add Answer
            </button>
            
        </div>}
        {value.type !== "text" && value.optionType === "productmaterial" && <AddOptionDropDown
                        addNewQuestionOption={addNewQuestionOptionSelect}
                        type={value.type}
                        parentIndex={pIndex}
                        addOption={value.addOption}
                        clsName={value.type}
                    />}
                {((value.type !== "text" && value.optionType === null) || (value.type !== "text"&&value.optionType === "default"))&&<AddOptionDropDown
                                        addNewQuestionOption={addNewQuestionOptionSelect}
                                        type={value.type}
                                        parentIndex={pIndex}
                                        addOption={value.addOption}
                                        clsName={value.type}
                                    />}
                                
                </div>}
            </>
        )
    }

    const productFamilyCode = (code, index, pIndex, filter) => {
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[pIndex] }
        let opt = [...selectTypeDat.options];
        let optInd = { ...opt[index] }
        optInd.productFamily = code
        optInd.productFilter = filter
        opt.splice(index, 1, optInd)
        selectTypeDat.options = opt;
        let customFormObj = [...customFormObject]
        customFormObj.splice(pIndex, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
    }
    const productMaterialCode = (code, index, pIndex, filter) => {
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[pIndex] }
        let opt = [...selectTypeDat.options];
        let optInd = { ...opt[index] }
        optInd.productMaterial =code
        optInd.productFamily = ''
        optInd.productFilter = ''
        opt.splice(index, 1, optInd)
        selectTypeDat.options = opt;
        let customFormObj = [...customFormObject]
        customFormObj.splice(pIndex, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
    }

    const getRuleData = (rule) => {
        const pIndex = getIndex?.pIndex;
        const index = getIndex?.index;
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[pIndex] }
        if (index !== undefined) {
            let opt = [...selectTypeDat.options];
            let optInd = { ...opt[index] }
            optInd.rule = rule
            opt.splice(index, 1, optInd)
            selectTypeDat.options = opt;
            selectTypeDat.rule = null
        } else {
            selectTypeDat.rule = rule
            let removeRule = [...selectTypeDat.options].map((value, ind) => {
                let ruleOption = { ...selectTypeDat.options[ind] }
                ruleOption.rule = null
                return ruleOption
            })
            selectTypeDat.options = removeRule
        }
        let customFormObj = [...customFormBuilder]
        customFormObj.splice(pIndex, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
        if(getRuleIndex.includes(selectTypeDat?.id)) {
            let getRule = [...getRuleIndex]
            getRule.pop(selectTypeDat?.id)
            setGetRuleIndex(getRule)
        }
    }
    //answer
    const questionAnswer = (pIndex, value, index, type, length, gotoOption, addOption, optionType, productFamily, productFilter, rule, id,productMaterial) => {
        const selectType = {
            singleselect: <SingleSelect
                name={"option"}
                index={index}
                count={length}
                key={index}
                placeholder={`Option${index + 1}`}
                defaultValue={value.value}
                pIndex={pIndex}
                getOptionValue={getOptionValue}
                getGotoValue={getGotoValue}
                goto={value.goto}
                gotoOption={gotoOption}
                addOption={addOption}
                deleteAnswerOption={deleteAnswerOption}
                optionType={optionType}
                productCategoryList={productCategoryList}
                getFamilyCode={(code, index, pIndex, filter) => productFamilyCode(code, index, pIndex, filter)}
                getMaterialCode={(code, index, pIndex, filter) => productMaterialCode(code, index, pIndex, filter)}
                materialList={materialList}
                productMaterial={productMaterial}
                productFamily={productFamily}
                productFilter={productFilter}
                addRuleData={() => addRuleData(rule, pIndex, index)}
                addRuleList={ruleList}
                getRuleData={(rule) => getRuleData(rule)}
                rule={rule}
                formDataName={formData?.assignInfo?.feature}
                id={id}
                formBuilder={formBuilder}
                fetchIndex={value}
                productAttributeOrderChange={productAttributeOrderChange}
            />,
            multiselect: <MultiSelect
                id={`check${index}`}
                index={index}
                count={length}
                key={index}
                placeholder={`Option${index + 1}`}
                defaultValue={value.value}
                pIndex={pIndex}
                getOptionValue={getOptionValue}
                getGotoValue={getGotoValue}
                goto={value.goto}
                gotoOption={gotoOption}
                addOption={addOption}
                deleteAnswerOption={deleteAnswerOption}
            />
        }
        return selectType[type]
    }
    const [showQuestionError, setQuestionError] = useState()
    let nextQuestion = () => {
        handleAddMediaCancel();
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = [...customFormObject]
        selectTypeDat = [...selectTypeDat, {
            "id": 0,
            "desc": "",
            "coreDesc": "",
            "order": (selectTypeDat.length + 1) * 1,
            "options": [
            ],
            "optionType":null,
            "media":{
                "images":[],
               "embedVideo":[]
           }
        }]
        let optionData = false
        selectTypeDat[selectTypeDat.length - 2].options.map((item) => {
            if (item.value !== "") {
                optionData = true
            }
        })
        if(selectTypeDat[selectTypeDat.length - 2].type === 'text') {
            optionData = true
        }
        if (selectTypeDat[selectTypeDat?.length - 2]?.desc !== "" && optionData) {
            setcustomFormBuilder(selectTypeDat)
            setQuestionError({})
        } else {
            setQuestionError({ index: selectTypeDat.length - 2 })
        }
    }

    const handleCancelBtn = () => {
        setCloseWarning(true)
        window.scrollTo(0, 0)
    }

    const getOptionValue = (value, index, pIndex) => {
        handleAddMediaCancel();
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[pIndex] }
        let optionsVal = { ...selectTypeDat.options[index] }
        optionsVal.key = `${index + 1}`
        optionsVal.value = value !== "" ? value : ''

        let addOptionsValue = [...selectTypeDat.options]
        addOptionsValue.splice(index, 1, optionsVal)
        selectTypeDat.options = addOptionsValue
        let customFormObj = [...customFormObject]
        customFormObj.splice(pIndex, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
    }
    const getGotoValue = (value, pIndex, index = "") => {
        handleAddMediaCancel();
        let customFormObject = [...customFormBuilder]
        let selectTypeDat = { ...customFormObject[pIndex] }
        if (index === "") {
            selectTypeDat.goto = value
            delete selectTypeDat.rule;
        } else {
            selectTypeDat.goto = ''
            let optionsVal = { ...selectTypeDat.options[index] }
            optionsVal.goto = value !== "" ? value : ''
            delete optionsVal.rule;
            let addOptionsValue = [...selectTypeDat.options]
            addOptionsValue.splice(index, 1, optionsVal)
            selectTypeDat.options = addOptionsValue
        }
        let customFormObj = [...customFormObject]
        customFormObj.splice(pIndex, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
    }

    const getQutions = (value, index) => {
        let selectTypeDat = { ...customFormBuilder[index] }
        selectTypeDat.desc = (value !== "") ? value : ``
        let customFormObj = [...customFormBuilder]
        customFormObj.splice(index, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
    }

    const getQutionsCoreDesc = (value, index) => {
        let selectTypeDat = { ...customFormBuilder[index] }
        selectTypeDat.coreDesc = (value !== "") ? value : ``
        let customFormObj = [...customFormBuilder]
        customFormObj.splice(index, 1, selectTypeDat)
        setcustomFormBuilder(customFormObj)
    }

    const [deleteInd, setDeleteInd] = useState('');
    const [deleteIndRule, setDeleteIndRule] = useState(false);
    const deleteQuestion = (index,value) => {
        handleAddMediaCancel();
        setDeleteIndRule(false)
        setDeleteInd(index)
        setDeleteQuestionCon(true)
        if(value&&value.rule&&value.rule!==null){
            setDeleteIndRule(true)
        }
        if(value&&value.options){
            let chck_rule=0
            Array.isArray(value.options)&&value.options.map((valueOpt)=>{
                if(valueOpt&&valueOpt.rule&&valueOpt.rule!==null){
                    chck_rule=1
                }
            })
            if(chck_rule===1){
                setDeleteIndRule(true)
            }
        }
        if(customFormBuilder&&Array.isArray(customFormBuilder)&&customFormBuilder.length>0){
            let chck_rule_all=0
            customFormBuilder.map((all_value, inds)=>{
                Array.isArray(all_value.options)&&all_value.options.map((valueOpt)=>{
                    if(value.optionType==='productattribute'&&valueOpt&&valueOpt.rule){
                        Array.isArray(valueOpt?.rule?.attribute)&&valueOpt?.rule?.attribute.map((value_attr)=>{
                            if(value_attr===value.id){
                                chck_rule_all=1
                            }
                        })
                    }
                    if(value.optionType==='productfamily'&&valueOpt&&valueOpt.rule){
                        Array.isArray(valueOpt?.rule?.productQuestion)&&valueOpt?.rule?.productQuestion.map((value_family)=>{
                            if(value_family===value.id){
                                chck_rule_all=1
                            }
                        })
                    }
                })
            })
            if(chck_rule_all===1){
                setDeleteIndRule(true)
            }
        }
    }

    const [getRuleIndex, setGetRuleIndex] = useState([])
    const deleteList = () => {
        handleAddMediaCancel();
        setDeleteQuestionCon(false);
        let selectTypeDat = [...formBuilder]
        let quesId = [];
        Array.isArray(formBuilder)&&formBuilder.map((item, pIndex)=>{//NOSONAR
            const id = selectTypeDat[deleteInd]?.id;
            if(deleteInd < pIndex) {
                if(item?.rule?.attribute?.includes(id) || item?.rule?.productQuestion?.includes(id)) {
                    quesId = [...quesId, item.id]
                } 
                item&&Array.isArray(item.options)&&item?.options?.map((itemOption)=>{//NOSONAR
                    if(itemOption?.rule?.attribute?.includes(id) || itemOption?.rule?.productQuestion?.includes(id)) {
                        quesId = [...quesId, item.id]
                    }
                })//NOSONAR
            }
        })//NOSONAR
        setGetRuleIndex(quesId);
        let selectTypeData = [...customFormBuilder]
        selectTypeData.splice(deleteInd, 1)
        setcustomFormBuilder(selectTypeData);
        reset({})
        if(customFormBuilder.length - 1 === 0 && deleteInd == 0){
            let selectTypeDat = [...selectTypeData]
            selectTypeDat = [...selectTypeDat, {
                "id": 0,
                "desc": "",
                "coreDesc": "",
                "order": (selectTypeDat.length + 1) * 1,
                "options": [
                ],
                "optionType":null,
                "media":{
                    "images":[],
                   "embedVideo":[]
               }
            }]
            setcustomFormBuilder(selectTypeDat);
        }
    }

    const [modalShow, setmodalShow] = useState(false)

    const modalClose = () => {
        setmodalShow(false);
        setmodalShowRule(false)
        setDeleteQuestionCon(false)
    }

    let nextQuestionOption = customFormBuilder?.length - 1;
    let questionType = customFormBuilder[nextQuestionOption]?.type

    const [closeWarning, setCloseWarning] = useState(false)
    const handleYes = () => {
        setCloseWarning(false)
        handleCancel();
    }
    /* No fn */
    const handleNo = () => {
        handleAddMediaCancel();
        setCloseWarning(false)
    }
    const [addMedia, setAddMedia] = useState()
    const handleAddMedia = (index) => {
        setAddMedia({ index: index })
    }
    const handleAddMediaCancel = () => {
        setAddMedia({})
    }
    const [deleteQuestionCon, setDeleteQuestionCon] = useState(false)
    const [tinyDelay, setTinyDelay] = useState(false)

    useEffect(() => {
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    }, [])
    return (
        <>
            {closeWarning &&
                <div className="text-center">
                    <CloseWarning
                        message="All progress in this section will be lost. Are you sure want to cancel?"
                        yesFn={handleYes}
                        noFn={handleNo}
                    />
                </div>
            }
            {deleteQuestionCon &&
                <Confirmation 
                    onClose={() => modalClose()}
                    title={" "}
                >
                    <div className="text-center pr-5 pl-5">
                        <p>
                            <img src={warning} alt="warnning-icon" />
                        </p>
                        <p className="pb-3">Are you sure you’d like to delete Question.{deleteIndRule&&<>This question affect the rule</>}</p>
                        <div className="mb-2">
                            <Button
                            value="Yes"
                            classes={{ className: `btn mr-2 pb-2 pt-2 mr-3 ${styles.yes_btn}` }}
                            onClick={() => deleteList()}
                            />
                            <Button
                            id="closeId"
                            value="No"
                            classes={{ className: `btn ml-2 pb-2 pt-2 ${styles.no_btn}` }}
                            onClick={() => modalClose()}
                            />
                        </div>
                    </div>
                </Confirmation>
            }
            {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
                <>
                <div className={`${styles.tab_content_top}`}>
                    <h2 className={`${styles.tab_content_hdg}`}>
                        {formData && formData.name}
                    </h2>
                </div>
                <div className={`col-md-12 ${styles.single_product_block} formbuilder`}>
                    {(upDateStatus) && upDateStatus.status === "success" && <div className={`alert alert-success ${styles.form_alert} ${styles.sucessmsg}`}>Successfully updated</div>}
                    {(upDateStatus) && upDateStatus.status === "error" && <div className={`alert alert-danger ${styles.form_alert}`}>Form not update</div>}
                    {ruleEmpty !== "" && <div className={`alert alert-danger ${styles.form_alert} ${styles.sucessmsg}`}>{ruleEmpty}</div>}
                    <div className="row">
                        <div className={`p-0 ${styles.patient_content}`}>
                            <Form formChanges={true} className={`pl-2 pt-0 ${styles.patient_content} formSubmit`} onSubmit={handleSubmit(onSubmit)} >
                                {
                                    customFormBuilder && [...customFormBuilder].map(
                                        (value, index) =>
                                            <>
                                            <div className={`${styles.question_blk}`}>
                                                    <div className={`col-sm-12 ${styles.col_spec} ${styles.question_top}`}>
                                                    <p className={`col 1 ${styles.question_heading}`}>Question {index + 1}</p>
                                                        <p className={`col-10 w-100 ${styles.media_view}`}>
                                                            <ul className={`${styles.list_view} ml-5`}>
                                                            {/* <li>
                                                                <img src={ImageIcon} alt="image_icon"></img>
                                                                <a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Sample Sample Sample</a>
                                                                {<span  onClick={() =>handleDeleteImage(value?.media?.images,formData?.id,value?.id,index,0)}><img src={CloseIcon} alt="close" /></span>}
                                                            </li>
                                                            <li>
                                                                <img src={ImageIcon} alt="image_icon"></img>
                                                                <a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">Sample Sample Sample</a>
                                                                {<span  onClick={() =>handleDeleteImage(value?.media?.images,formData?.id,value?.id,index,1)}><img src={CloseIcon} alt="close" /></span>}
                                                            </li> */}
                                                                {
                                                                    value?.media&&value?.media?.images &&Array.isArray(value?.media?.images)&& value?.media?.images.map(
                                                                    (valueImg, indexImg) =>
                                                                    <>
                                                                    <li>
                                                                        <img src={ImageIcon} alt="image_icon"></img>
                                                                        <a href={valueImg?.image} target="_blank" rel="noopener noreferrer" title={valueImg?.fileName}>{valueImg?.fileName}</a>
                                                                        {<span  onClick={() =>handleDeleteImage(value?.media?.images,formData?.id,value?.id,index,indexImg)} title="Remove"><img src={CloseIcon} alt="close" /></span>}
                                                                        </li>
                                                                    </>   
                                                                    )
                                                                }
                                                            </ul>
                                                        </p>
                                                        <p className={`${styles.up_down_list}`}>
                                                            {index !== 0 && <span className={`${styles.sequence_arrow} uparrow`} onClick={() => upArrowSequence(index)}>
                                                                <img src={topArrow} alt="Top arrow" />
                                                            </span>}
                                                            {nextQuestionOption !== index && <span className={`${styles.sequence_arrow} downarrow`} onClick={() => downArrowSequence(index)}>
                                                                <img src={downArrow} alt="doen arrow" />
                                                            </span>}
                                                            <span
                                                                className={`${styles.sequence_arrow} deleteQues`}
                                                                onClick={() => deleteQuestion(index,value)}>
                                                                <img src={deleteIcon} alt="delete" />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className={`${styles.form_block1} mb-2`}>
                                                        {questions(value, index)}
                                                    </div>
                                                    {nextQuestionOption === index ?
                                                        questionType !== undefined && <div className={`${styles.question_add_new}`} title={'Add Next Question'}>
                                                            <button type="button" className={`btn btn-transparent next-ques`} onClick={() => nextQuestion()}>
                                                                <img src={plusIconWhite} alt="Plus icon" />
                                                            </button>
                                                        </div> : ""
                                                    }
                                                </div>
                                            </>
                                    )
                                }
                                {getRule && <AddRule modalOpen={modalShow} modalClose={modalClose} addRuleButton={addRuleButton} />}
                                {<AddRuleText modalOpen={modalShowRule} modalClose={modalClose} addRuleList={ruleList} getRuleData={getRuleData} defaultValue={editRule} />}
                                {questionType !== undefined && <div className="col-sm-12">
                                    <div className="text-center pb-4">
                                        <br />
                                        {/* Save Button */}
                                        <Button
                                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                                            type="submit"
                                            id={SUBMIT_ID}
                                        >
                                            {SAVE_BTN}
                                        </Button>
                                        {/* Cancel button  */}
                                        <Button
                                            classes={{ className: `btn mr-3 handlecancel ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                                            id={CANCEL_ID}
                                            type="button"
                                            value="Cancel"
                                            onClick={handleCancelBtn}
                                        >
                                            {CANCEL_BTN}
                                        </Button>
                                    </div>
                                </div>}
                            </Form>
                        </div>
                    </div>
                </div>
                </>
            }
        </>
    )
}
