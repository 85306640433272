import React, { useEffect, useState } from 'react';
import { Button, Form } from '../../primary';
import { TreatmentDropDownItem, CloseWarning } from '../../secondary';
import styles from './viewTreatment.module.scss'
import { EDIT, CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants'
import editIcon from '../../../static/images/edit-icon-blue.png';
import { useForm } from 'react-hook-form';
import { signedLockedAccess,getUniqueListBy } from '../../../utils';
import { useSelector } from 'react-redux'
import { assessmentRbacChecker } from '../../../utils/rbac';
export const ViewTreatMent = ({ editData, viewData, saveData, noDataMessage, viewScreen, dressing, assessmentDetails,setCloseWarningSec, dropValue, setGetValueDrop}) => {
  
  const [closeWarning, setCloseWarning] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm();
  const patientData = useSelector(state => state.patient.detailViewData)
  const userDetails = useSelector(state => state.user);
  const hideButtons = assessmentRbacChecker(userDetails?.role,patientData?.isOwnCustomer);
  const [editable, setEditable] = useState(false);
  const [getValue, setGetValue] = useState(Array.isArray(viewData)?viewData:[]);
  const [isPrimSec, setIsPrimSec] = useState(false);
  useEffect(() => {
    if (viewData?.length === 0) {
      let val = []
      editData && Array.isArray(editData) && editData.map((item, index) => {//NOSONAR
        let obj = {}
        obj['key'] = item.key
        val = [...val, obj]
        return
      })//NOSONAR
      setGetValue(val)
    }
  }, [editData])

  const handleEdit = () => {
    setEditable(true);
    setGetValue(viewData)
  }

  const handleCancel = () => {
    setEditable(false)
  }

  const doSubmit = () => {
    let sub=[]
    getValue && Array.isArray(getValue) && getValue.map((itemDef, j) => {//NOSONAR
      if(itemDef.key&&itemDef.key!==null&&itemDef.value&&itemDef.value!=null){
        sub.push(itemDef)//NOSONAR
        return
      }
     })//NOSONAR

     saveData(sub);
    setEditable(false);
  }

  const EditIcon = () => {
    return (
      !editable && signedLockedAccess(assessmentDetails?.assessmentStage) && hideButtons && <div className={`${styles.edit_icon}`}>
        <span className={`float-sm-right ${styles.tab_edit} handleEdit`} onClick={handleEdit} >
          <img src={editIcon} alt="edit" />{EDIT}
        </span>
      </div>
    )
  }

  const ViewForm = () => {
    return viewData && Array.isArray(viewData) && viewData.map((item, index) => {
      const getLabel = editData && Array.isArray(editData) && editData?.find((f) => f.key === item.key)
      const getOption = getLabel?.options?.find((f) => f.materialNumber === item.value)
      if (getLabel&&getLabel.name&&getOption?.productFamilyDesc) {
        return <div className={`col-11 ${styles.col_spec} d-flex mb-2`} key={index}>
          <label className={`col-5 p-0`}>{`${getLabel.name}:`}</label>
          <div className="col-6 p-0">
            <div className={`${styles.cat}`}>{getOption?.productFamilyDesc}</div>
            <div>{getOption?.materialDescription}</div>
            {dressing !== "secondary" && getOption?.isRecommended ? <div className={`${styles.recommended}`}>
              Recommended
            </div> : ""}
            <div className={`${styles.material_code}`}>{getOption?.materialCode}</div>
          </div>
        </div>
      }
    })
  }

  const getOptValue = (index,name, optionsVal, isRecommended) => {
    if (dressing === "primary"||dressing==="secondary") {
      setIsPrimSec(true)
    }
    if(setCloseWarningSec){
      setCloseWarningSec(false)
    }
    if (dressing === "secondary") {
      if (isRecommended === 0) {
        //setCloseWarning(true)
      }
    }/*else{*/
   /* let getOpt = { ...getValue[index]}
    getOpt['key'] = name
    getOpt['value'] = optionsVal
    getOpt['isRecommended'] = isRecommended
    const getVal = [...getValue]
    getVal.splice(index, 1, getOpt)
    setGetValue(getVal)*/
    /*}*/
  let getOpt ={}
  getOpt['key'] = name
  if(optionsVal){
    getOpt['value'] = optionsVal
  }else{
    getOpt['value'] = ''
  }
  if(isRecommended){
    getOpt['isRecommended'] = isRecommended
  }else{
    getOpt['isRecommended'] = 0
  }
  
  const getVal = [...getValue,...[getOpt]]
  let finalVal = [];
  getVal.forEach((val,i)=>{
      if(val.key===getOpt.key){
        finalVal.push({...getOpt})
      }else{
        finalVal.push({...val})
      }
  })
  const uniqueList = getUniqueListBy(finalVal, 'key')
  setGetValue(uniqueList)
  }

  const yesFun = () => {
    setCloseWarning(false)

  }

  const noFun = () => {
    setCloseWarning(false)
  }

  const EditForm = () => {
    return (
      <>{closeWarning && <CloseWarning
        message={`Are you sure you would like to proceed with your secondary dressing selection?`}
        yesFn={yesFun}
        noFn={noFun}
      />}
        {signedLockedAccess(assessmentDetails?.assessmentStage) ?
          <Form directChanges={isPrimSec} className="col-12 p-0" onSubmit={handleSubmit(doSubmit)}>
            {
              editData && Array.isArray(editData) && editData.length > 0 && editData.map((item, index) => {
                let defaulVal = ''
                item && Array.isArray(item.options) && item.options.map((chckOpt, k) => {//NOSONAR
                    getValue && Array.isArray(getValue) && getValue.map((itemDef, j) => {//NOSONAR
                      if(itemDef.value===chckOpt.materialNumber){
                        defaulVal=itemDef.value//NOSONAR
                        return
                      }
                     })//NOSONAR
                 })//NOSONAR
                return <TreatmentDropDownItem
                  index={index}
                  key={index}
                  id={item.key}
                  name={item.key}
                  label={`${item.name}`}
                  classes={{
                    column: `col-12 ${styles.col_spec} d-flex mb-2`,
                    inputClassName: styles.basic_select,
                    inputColumn: `col-6 p-0`,
                    labelClassName: `col-5 p-0`
                  }}
                  listOptions={item.options}
                 // defaultValue={getValue && getValue[index] && getValue[index].value ? getValue[index].value : ''}
                  defaultValue={(defaulVal)?defaulVal:''}
                  getData={getOptValue}
                  viewScreen={viewScreen}
                  dressing={dressing ? dressing : 'secondary'}
                  dropValue={dropValue}
                  setGetValueDrop={setGetValueDrop}
                />
              })
            }
            {
             hideButtons&& editData?.length !== 0 ? <div className="row">
                <div className="mt-4 pt-2 col-sm-12 p-0">
                  <div className="offset-5">
                    {/* Save Button */}
                    <Button
                      classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                      type="submit"
                      id={SUBMIT_ID}
                    >
                      {SAVE_BTN}
                    </Button>
                    {/* Cancel button  */}
                    <Button
                      classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} handlecancel` }}
                      id={CANCEL_ID}
                      onClick={handleCancel}
                      type = 'button'
                    >
                      {CANCEL_BTN}
                    </Button>
                  </div>
                </div>
              </div> : ''
            }

          </Form>
          :
          'This wound assessment is signed and locked, cannot be updated.'
        }
      </>
    )
  }

  return (
    <>
      <div className={`col-md-12 ${styles.treatment_container}`}>
        {viewData?.length > 0 ? <EditIcon /> : ""}
        {
          <div className={`p-0 ${styles.patient_content}`}>
            {viewData?.length > 0 ? (!editable) && ViewForm() : <EditForm />}
            {(editable) && <EditForm />}
          </div>
        }
        {editData?.length === 0 ? <div className={`${styles.cat}`}>{noDataMessage}</div> : ""}
      </div>
    </>
  )
}
