import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./info.module.scss";
import { custom_btn_primary } from "../../primary/Button/button.module.scss";
import verify_icon from '../../../static/images/email_verify_icon.png'
import email_acc_icon from '../../../static/images/email_acc_status.png'
import { resendUserActivationAsync,setErrorOtpMsg} from '../../../app/userSlice'; 
import PropTypes from 'prop-types'

export function Info({title, desc, btn = {}, warning, status_type, dynParam = {}, history, resendEmail, otpCodeEmail,resendEmailForget}) {  
  const errorOtpMsg = useSelector((state) => state.user.errorOtpMsg)//added
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(4).fill("")); 
  const [errorMsgs, setErrorMsgs] = useState(""); 

  useEffect(() => {
    dispatch(setErrorOtpMsg(""));
}, [dispatch]);

  useEffect(() => {
        setErrorMsgs(errorOtpMsg);   
    }, [errorOtpMsg]);

 
  const handleChange = (element, index) => {
        setErrorMsgs('');
        
        if (isNaN(element.value)) return false;        
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }        
    };

    const resendEmailChk = (e) => {
        e.preventDefault();
        let page=''
        if(status_type === "verificationlinkexpired"){
          page='expired';
        }
        if(status_type === "forgotpasswordsuccess"&&dynParam?.email){
          resendEmailForget(dynParam?.email,'',history,page)
        }
        else if(dynParam?.email){
            resendEmail(dynParam?.email,'',history,page)
        }else{
            history.push('/')
        }
      }
    
    const onSubmit = (e,status_type) => {
      console.log('status_type',status_type)
      e.preventDefault();
      let data=otp.join("")
      if(data){
       otpCodeEmail(dynParam?.email, data, history,status_type);
        setErrorMsgs('');           
      }
      else{
        setErrorMsgs('Please enter the code')              
      } 
    }
  return (

    
    <div className="col-12">
      <div className={`${styles.success_confirmation}`}>
        {(status_type && status_type === "registrationsuccess")||(status_type && status_type === "forgotpasswordsuccess") ? (
          <div className="text-center">
              <img className={`${styles.verify_img}`} src={verify_icon}/>
            <h4 className="mb-2 mt-4">{'Verify Email​'}</h4>
            <p className="mt-4 pb-2">{'Enter the 4 digit code, We sent you via email to​ '}<b>{dynParam?.email}</b></p>
            <p className="mt-2 py-0">{'If you haven’t received the verification email, please check your "Spam" or "Bulk Email" folder.​'}</p>
            
            {otp.map((data, index) => {
                  return (
                      <input
                          id={"Otp_field"}
                          className={`${styles.otp_field}`}
                          type="text"
                          name="otp"
                          maxLength="1"
                          key={index}
                          value={data}                                                    
                          onChange={e => handleChange(e.target, index)}
                          onFocus={e => e.target.select()}
                      />
                  );
              })}
            {errorMsgs ? (<div className="pt-2"><p style={{"color": "#ff0000"}}>{`${errorMsgs}`}</p></div>): null}
            <div className="mt-2 mb-1 pb-1">              
                <p className="mt-2 pt-2 pb-2">{`Didn't get the code? `}<a href="#javascript"  id={'resend_email_first'} onClick={(e) =>resendEmailChk(e)}className={`${styles.custom_btn_resend}`}>{'Resend code​'}</a></p>                
            </div>           
          </div>
        ) : status_type && status_type === "accountstatus" ? (
          <div className="text-center">
              <img src={email_acc_icon}/>
            <h4 className="mb-2 mt-2">{dynParam?.acct_status&&dynParam?.acct_status==='customerInActive'?'Medline Account #':'User Registration'}</h4>
            <p className="mt-4 pb-2"><b>{dynParam?.acct_status&&dynParam?.acct_status==='customerInActive'?'Status: Medline account # is pending approval':'Status: Your registration is pending approval'}</b></p>
            <p className="mt-2 pb-2">{'Your registration information is being reviewed for approval. Watch your email or login to the app for status updates.'}</p>
            <p className="mt-2 pb-2">{'​If you believe this is an error, please email us at NE1Support@medline.com'}</p>
           
          </div>
        ) : (
          <>
            <h2 className={warning && `${styles.warning}`}>{title}</h2>
            <p className="mb-4 pb-2">{desc}</p>
          </>
        )}
        <div className="pt-1">
          {btn["to"] && btn.value==='Resend the link' ? (
            <a href="#javascript"  id={'resend_email'}  onClick={(e) =>resendEmailChk(e)} className={`btn btn-block ${custom_btn_primary}`}>{'RE-SEND EMAIL​'}</a>
          ): ((status_type && status_type === "registrationsuccess")||(status_type && status_type === "forgotpasswordsuccess"))? (
            <a href="#javascript" id={'cont_login'} onClick={(e) =>onSubmit(e,status_type)} className={`btn btn-block ${custom_btn_primary}`}>{btn.value}</a>
          ): (
            <Link 
              to={btn.to}
              className={`btn btn-block ${custom_btn_primary}`} 
              >
              {btn.value}
          </Link>
          )}
        </div>
      </div>
    </div>
  );
}
