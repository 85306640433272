import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './usageAnalyticsReport.module.scss';
import Spreadsheet from '../../../static/images/ExportSpredsheet.svg';
import { subDays, format } from 'date-fns';
import { getAnalyticsDownloadDetails, getAnalyticsUserDetails,setIsUserDownloadReady } from '../../../app/usageAnalyticsReportSlice'
import { DataTable, Pagination } from '../../secondary';
import { CSVLink } from 'react-csv';
export const AnalyticsUserDetailTable = ({ tempPayload, selectedDivOption }) => {
  const dispatch = useDispatch();
  const csvLink = useRef();
  const userCsvLink = useRef();
  const customerName = useSelector(state => state.user.customerName);
  useEffect(() => {
    setOffSet(0)
    setActivePage(1)
    const userDetailPayload = { ...tempPayload, selectedOption: selectedDivOption }
        if ((userDetailPayload.fromDate) || (userDetailPayload.toDate) || (userDetailPayload?.facility)|| (Array.isArray(userDetailPayload?.customerType))) {
      //if((userDetailPayload.facility.length>0)|| (Array.isArray(userDetailPayload.customerType)&&userDetailPayload.customerType.length>0)){
      dispatch(getAnalyticsUserDetails(userDetailPayload))
      const downloadDetails = { ...tempPayload, selectedOption: selectedDivOption }
      dispatch(getAnalyticsDownloadDetails(downloadDetails))
      //}
      
    }
  }, [tempPayload, selectedDivOption])
  
  const [activePage, setActivePage] = useState(1);
  const [offSet, setOffSet] = useState(0)
  const [sortType, setSortType] = useState('')
  const userDetailsData = useSelector(state => state.usageAnalyticsReport.analyticsUserDetails);
  const allUserDetailsData = useSelector(state => state.usageAnalyticsReport.analyticsAllUserDetails);
  const downloadDetailsData = useSelector(state => state.usageAnalyticsReport.analyticsDownloaddetails);
  const isUserDownloadReady = useSelector(state => state.usageAnalyticsReport.isUserDownloadReady);
  const [downloadCheck, setDownloadCheck] = useState(false);
  const csvDownloadDate = format(new Date(), "MM.dd.yyyy-hh.mm.aaa");
  const clickAllUserSummary = () => {
    const total=(userDetailsData.userDetails && userDetailsData.metadata)? userDetailsData.metadata.total : 50
    const queryParam = `limit=` + total + `&offSet=0`;
    const userDetailPayload = { ...tempPayload, selectedOption: selectedDivOption }
    dispatch(getAnalyticsUserDetails(userDetailPayload, queryParam,"allDetails"))
    setDownloadCheck(true)
  }
  useEffect(() => {
    if (userCsvLink&&userCsvLink.current&&isUserDownloadReady&&isUserDownloadReady==='downloadAllUser'&&downloadCheck) {
     userCsvLink.current.link.click()
     dispatch(setIsUserDownloadReady(''))
     setDownloadCheck(false);
    }else{
      dispatch(setIsUserDownloadReady(''))
      setDownloadCheck(false);
    }
}, [isUserDownloadReady]);
  const getFileNameDetails = () => {
    return `${customerName}.${selectedDivOption}.${'Usage_Analytics_Details'}.${csvDownloadDate}.csv`;
  }
  let detailsFileHeaders = []
  if (selectedDivOption === "Product Selector") {
    detailsFileHeaders = [
      { label: 'Created by', key: 'createdBy' },
      { label: 'Email', key: 'emailId' },
      { label: 'Created on', key: 'createdDate' },
      { label: 'Product selector name', key: 'productSelectorName' },
      { label: 'Product selector values', key: 'productSelector' },
      { label: 'Viewed recommendation', key: 'viewedRecommendation' },
      { label: 'Saved recommendation', key: 'savedRecommendation' }
    ];
  }
   else if (selectedDivOption === "Wound Management") {
    detailsFileHeaders = [
      { label: 'Created by', key: 'createdBy' },
      { label: 'Email', key: 'emailId' },
      { label: 'Created on', key: 'createdDate' },
      { label: '# Of Non paid Wound assessments', key: 'nonPaidWoundRequest' },
      { label: '# Number of demo requested', key: 'demoRequest' }
    ];
  }
  else if (selectedDivOption === "Product Search") {
    detailsFileHeaders = [
      { label: 'Searched by', key: 'searchedBy' },
      { label: 'Email', key: 'emailId' },
      { label: 'Searched on', key: 'searchedDate' },
      { label: 'Search keyword', key: 'searchKeyword' },
      { label: 'Search result - Found/Not found', key: 'searchResult' }
    ];
  }
  else if (selectedDivOption === "OR Pads") {
    detailsFileHeaders = [
      { label: 'created by', key: 'createdBy' },
      { label: 'Email', key: 'emailId' },
      { label: 'Created on', key: 'createdDate' },
      { label: 'OR pads count', key: 'orPadCount' },
      { label: 'Stretcher pads count', key: 'stretcherPadCount' }
    ];
  }
  else if (selectedDivOption === "Protein needs calculator") {
    detailsFileHeaders = [
      { label: 'created by', key: 'createdBy' },
      { label: 'Email', key: 'emailId' },
      { label: 'Created on', key: 'createdDate' },
      { label: 'Count', key: 'count' }
    ];
  }
  else if (selectedDivOption === "NE1 WMS(Paid customers)") {
    detailsFileHeaders = [
      { label: 'created by', key: 'createdBy' },
      { label: 'Email', key: 'emailId' },
      { label: 'Created on', key: 'createdDate' },
      { label: 'Wound photo', key: 'image' },
      { label: 'Wound ID', key: 'woundId' },
      { label: 'Re-eval ID', key: 'reEvalId' },
      { label: 'Patient\'s name', key: 'patientName' }
    ];
  }
  else if (selectedDivOption === "NE1 WMS(Offline Patients)") {
    detailsFileHeaders = [
      { label: 'Added by', key: 'addedBy' },
      { label: 'Email', key: 'emailId' },
      { label: 'Date', key: 'addedDate' },
      { label: '# Of Patients added to offline', key: 'noOfOffline' },
    ];
  }
  else if (selectedDivOption === "Risk Assessment") {
    detailsFileHeaders = [
      { label: 'created by', key: 'createdBy' },
      { label: 'Email', key: 'emailId' },
      { label: 'Created on', key: 'createdDate' },
      { label: 'Count', key: 'count' }
    ];
  }
  

  const fileHeadersSummary = [
    { label: 'First name', key: 'firstName' },
    { label: 'Last name', key: 'lastName' },
    { label: 'Role', key: 'role' },
    { label: 'Usage', key: 'usage' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Last login', key: 'lastLogin' }
  ];
  const columns = [
    {
      Header: 'First name',
      accessor: 'firstName',
      restWidth: '200px'
    },
    {
      Header: 'Last name',
      accessor: 'lastName',
      restWidth: '150px'

    },
    {
      Header: 'Role',
      accessor: 'role',
      restWidth: '200px'
    },
    {
      Header: 'Usage',
      accessor: 'usage',
      restWidth: '100px'
    }, {
      Header: 'Email',
      accessor: 'email',
      restWidth: '200px'
    }, {
      Header: 'Phone',
      accessor: 'phone',
      restWidth: '200px'
    }, {
      Header: 'Last login',
      accessor: 'lastLogin',
      restWidth: '200px'
    }
  ];
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const selectRowCount = (length) => {
    setSelectedRowCount(length)
  }
  const handleSort = useCallback(data => {
    if (data && data.sortBy[0] && data.sortBy[0].id) {
      const sortingColumn = data.sortBy[0]
      const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
      let queryParam = `sort=` + sortingColumn.id + sortingType
      let userDetailsload = { ...tempPayload, selectedOption: selectedDivOption }
      if(userDetailsload&&userDetailsload.facility&&userDetailsload.facility.length>0){
      dispatch(getAnalyticsUserDetails(userDetailsload, queryParam))
      setSortType(sortingColumn.id + sortingType)
      }
    }

  }, [dispatch, tempPayload, selectedDivOption,offSet])
  const deleteDataList = (data) => {
  }
  const getActivePage = (data) => {
    setActivePage(data);
    const limit = 50;
    const offSets = (((data * limit) - limit) < limit) ? 0 : (data * limit) - limit;
    setOffSet(offSets)
    const queryParam = `limit=` + limit + `&offSet=` + offSets+`sort=`+(sortType)?sortType:'';
    const userDetailPayload = { ...tempPayload, selectedOption: selectedDivOption }
    if(userDetailPayload&&userDetailPayload.facility&&userDetailPayload.facility.length>0){
        dispatch(getAnalyticsUserDetails(userDetailPayload, queryParam))
    }
  }
  return (
    <>
      <div className={`${style.wound_dashboard} mt-0 `}>
        <div className={`${style.overview_top} justify-content-end`}>
          <div className={`${style.down_spread} mr-4`}  onClick={() =>clickAllUserSummary()}><img src={Spreadsheet} alt="" className="mr-1"  />
          <a className="">Download Summary</a>
          </div>
          <CSVLink
              headers={fileHeadersSummary}
              data={allUserDetailsData && Array.isArray(allUserDetailsData.userDetails) ? allUserDetailsData.userDetails : []}
              filename={`${customerName}.${selectedDivOption}.Usage_Analytics_Summary.${csvDownloadDate}.csv`}
              target="_blank"
              className="hidden"
              ref={userCsvLink}
            >
            </CSVLink>
          <div className={`pl-3`}>
            <div className={style.down_spread}><img src={Spreadsheet} alt="" className="mr-1" />
              <CSVLink
                onClick={() => csvLink.current.link.download = getFileNameDetails()}
                headers={detailsFileHeaders}
                data={downloadDetailsData && Array.isArray(downloadDetailsData) ? downloadDetailsData : []}
                fileName={getFileNameDetails}
                target="_blank"
                ref={csvLink}
              >
                Download Details
              </CSVLink>
            </div>
          </div>
        </div>
        {/* data table start */}
        <div className="col-12">
          <DataTable
            columns={columns}
            data={userDetailsData && Array.isArray(userDetailsData.userDetails) ? userDetailsData.userDetails : []}
            sorting={true}
            getSelectedRowData={''}
            setSelectedRows={selectRowCount}
            onSort={handleSort}
            detailView={false}
            fixedFullWidthSize={'0'}
            workListView={true}
            deleteDataList={deleteDataList}
            reportView={true}
          />
          {(Array.isArray(userDetailsData.userDetails)) && (userDetailsData.userDetails.length > 1) && <Pagination
            activePage={activePage}
            itemsCountPerPage={userDetailsData.userDetails && userDetailsData.metadata ? userDetailsData.metadata.limit : 50}
            totalItemsCount={userDetailsData.userDetails && userDetailsData.metadata ? userDetailsData.metadata.total : 0}
            getActivePage={getActivePage}
            reportView={true}
          />
          }
        </div>
        {/* data table end */}
      </div>
    </>
  )
}
