import React from "react";
import styles from './multiSelectBox.module.scss';
import PropTypes from 'prop-types';
import { Multiselect } from 'multiselect-react-dropdown';
import './multiSelect.css'

export function MultiSelect({
    classes, id = '',
    label, name = '',
    placeholder = '',
    optionValue,
    defaultValue,
    disabled = false,
    validator,
    required,
    errors,
    onSelect,
    onRemove,
    showCheckbox,
    displayValue,
    groupBy,
    customErrors,
    customErrorsMsg,
    labelSubText,
    errorBorder,
    closeOnSelect=false
}) {
    let selectedValue = [];
    if (defaultValue?.length > 0) {//NOSONAR
        optionValue && optionValue?.length > 0 && optionValue?.forEach((value) => {//NOSONAR
            defaultValue?.forEach((dValue) => {//NOSONAR
                if (value?.key) {//NOSONAR
                    if (value.cat) {
                        value?.key == dValue?.key && selectedValue?.push(value)//NOSONAR
                    } else {
                        value?.key == dValue && selectedValue?.push(value)//NOSONAR
                    }
                } else if (value.id) {
                    value?.id == dValue && selectedValue?.push(value)//NOSONAR
                }
            })//NOSONAR
        })//NOSONAR
    }//NOSONAR
    return (
        <div className={(classes) ? classes.column : ''}>
            {/* Label  */}
            {label && <label htmlFor={id} className={(classes) ? classes.labelClassName : ''}>{label} {required ? <span className={styles.astrick}>&#42;</span> : ''}{labelSubText && <div className={styles.bir_subline}>{labelSubText}</div>}</label>}

            {/* Inner div */}
            <div className={`${(classes) ? classes.inputColumn : ''} ${errorBorder ? styles.error_border_color : ''}`}>
                <Multiselect
                    options={optionValue}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue={displayValue}
                    showCheckbox={showCheckbox}
                    groupBy={groupBy}
                    id={id}
                    ref={validator}
                    name={name}
                    disable={disabled}
                    closeOnSelect={closeOnSelect}
                    placeholder={placeholder ? placeholder : 'Select'}
                    selectedValues={selectedValue.length > 0 ? selectedValue : []}
                />
                {customErrors && <span className={`${styles.error_message}`}>{customErrors ? customErrorsMsg : ''}</span>}
            </div>

            {errors && <span className={`col-6 ${(id&&id==='exudateType')?'offset-5':'offset-6'} p-1 ${styles.error_message}`}>{(errors[name]) ? errors[name].message : ''}</span>}
            {errorBorder && <span className={`col-6 offset-5 p-0 pt-1 ${styles.error_message}`}>Product Manager is required</span>}
        </div>
    );
}

MultiSelect.propTypes = {
    classes: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    optionValue: PropTypes.array,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    onChange: PropTypes.func,
    error_boder: PropTypes.bool
}
