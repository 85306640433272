import React, {useState } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoginForm } from '../components';
import PropTypes from 'prop-types';
import {
  loginAsync,
  loginEmailAsync,  
} from '../app/userSlice'; 
import { clearError } from "../app/appSlice";

export let Login = ({ dispatch, history }) => {
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleLogin = data => {
    dispatch(loginAsync(data, history));
    
  } 

  const handleLoginEmail =  data => { 
      dispatch(clearError())
      if(setIsEmailValid){
      // setIsEmailValid(true)
      }
      dispatch(loginEmailAsync(data, history,setIsEmailValid));    
  } 
  return <LoginForm doLogin={handleLogin} doLoginEmail={handleLoginEmail} isEmailValid={isEmailValid} setIsEmailValid={setIsEmailValid}  history={history} />;
};

Login.propTypes = {
  dispatch: PropTypes.func,
};
 
Login = connect()(withRouter(Login));
