import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  CANCEL_BTN,
  CANCEL_ID,
  EDIT,
  SAVE_BTN,
  SUBMIT_ID,
  USER_MANAGE_STATUS,
  USER_EMAIL_STATUS,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  SUCCESS_STATUS,
  ERROR_STATUS,
} from "../../../constants";
import {
  Button,
  Form,
  RadioButton,
  TextField,
  AccessErrorModal,
} from "../../primary";
import { Modal } from "../../secondary";
import styles from "./userSettings.module.scss";
import successIcon from "../../../static/images/icons-check-success.png";
import iconDanger from "../../../static/images/icon-check-danger.png";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../static/images/edit-icon-blue.png";
import {
  mobileAppInviteAsync,
  forgotPasswordInviteAsync,
  setEditState,
  setTemporaryPassword,
  temporaryPasswordAsync,
  userUpdateLockAsync,
  resendUserSettingsActivationAsync,
  userDetailsAsync
} from "../../../app/userSlice";
import { useParams } from "react-router";
import { getKeyByValue } from "../../../utils";
import { clearError } from "../../../app/appSlice";
import { Confirmation } from "../../secondary/Confirmation/confirmation";
import warning from "../../../static/images/icon-check-danger.png";
import Loader from '../../../static/images/spinner.svg';	
export function UserSettings({customerData, userData, updateUserData }) {
  const { userId } = useParams();
  /* Use hook form */
  const { register, handleSubmit } = useForm();

  /* State to check editable forms */
  const [editable, setEditable] = useState(false);
  const [disable, setDisable] = useState(false);
  /*user confirm pop*/
  const [confirmationModalUser, setConfirmationModalUser] = useState(false);
  const [userDefStatus, setUserDefStatus] = useState(
    userData?.status ? "Active" : "Inactive"
  );

  /*toggleState*/
  const [toggle, setToggle] = useState(userData?.emailVerifiedByAdmin ? 1 : 0 );
 

   /* Get state with the Individual customer data */
  const getConFirmUserAct = (value) => {
    if (value !== "") {
      setConfirmationModalUser(true);
      setUserDefStatus(value);
    }
  };

  //custYesConfirm
  const userYesConfirm = () => {
    //setmodalShow(false);
    // setUserDefStatus(userDefStatus);
    if (
      userDefStatus === "Inactive" &&
      document.getElementById("manageStatus" + 1)
    ) {
      document.getElementById("manageStatus" + 1).checked = true;
      document.getElementById("manageStatus" + 0).checked = false;
    } else {
      if (document.getElementById("manageStatus" + 0)) {
        document.getElementById("manageStatus" + 0).checked = true;
        document.getElementById("manageStatus" + 1).checked = false;
      }
    }
    setConfirmationModalUser(false);
  };
  const userConfirmClose = () => {
    if (
      userDefStatus === "Inactive" &&
      document.getElementById("manageStatus" + 0)
    ) {
      document.getElementById("manageStatus" + 0).checked = true;
      document.getElementById("manageStatus" + 1).checked = false;
    } else {
      if (document.getElementById("manageStatus" + 1)) {
        document.getElementById("manageStatus" + 1).checked = true;
        document.getElementById("manageStatus" + 0).checked = false;
      }
    }

    setConfirmationModalUser(false);
  };
  
  /* Function to toggle edit */
  const handleEdit = () => {
    setEditable(!editable);
    setDisable(!disable);
    setUserDefStatus(userData?.status ? "Active" : "Inactive");
  };

  /*ToggleChange */
  const toggleChange = () => {   
    if(toggle === 0){
      setToggle(1)
    } else{
      setToggle(0)
    } 
  }

  const dispatch = useDispatch();

  /* Loader */
  const loading = useSelector((state) => state.app.loading);

  /* Temp password */
  const tempPasswordData = useSelector((state) => state.user.temporaryPassword);

  const [userEmail, setUserEmail] = useState("");
  const [tempPassWord, setTempPassWord] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const errorMessage = useSelector((state) => state.app.errorMessage);

  useEffect(() => {
    if (tempPasswordData) {
      setTempPassWord(tempPasswordData);
    }
  }, [tempPasswordData]);

  {
    /* Close modal */
  }
  const modalClose = () => {
    setModalOpen(false);
    dispatch(clearError());
  };
  {
    /* open madal */
  }
  const openModal = (modalType) => {
    if (modalType === "reSendEmailModal") {
      if (userData.email !== "") {
        const payload = { id: userId, email: userData.email };
        setUserEmail(userData.email);
        dispatch(resendUserSettingsActivationAsync(payload));
        setModalType(modalType);
        setModalOpen(true);
      }
    }
    else if (modalType === "pwdResetModal") {
      if (userData.email !== "") {
        const payload = { id: userId, email: userData.email };
        setUserEmail(userData.email);
        dispatch(forgotPasswordInviteAsync(payload));
        setModalType(modalType);
        setModalOpen(true);
      }
    } else if (modalType === "tmpPwdModal") {
      if (userData.email !== "") {
        const payload = { id: userId, email: userData.email };
        setUserEmail(userData.email);
        setTempPassWord("");
        dispatch(setTemporaryPassword(""));
        dispatch(temporaryPasswordAsync(payload));
        setModalType(modalType);
        setModalOpen(true);
      }
    } else if (modalType === "appInviteModal") {
      if (userData.email !== "") {
        const payload = { id: userId, email: userData.email };
        setUserEmail(userData.email);
        dispatch(mobileAppInviteAsync(payload));
        setModalType(modalType);
        setModalOpen(true);
      }
    }
  };

  

  const doSubmit = (data) => {
    // data.preventDefault();
   let emailStatus = (document.getElementById("toggleSwitch") === null ? 1 : (document.getElementById("toggleSwitch").checked ? 1 : 0)); 
    handleEdit();  
    const tempUserData = {
      id: userId,
      status: getKeyByValue(USER_MANAGE_STATUS, data.manageStatus),//check user status
      emailVerifiedByAdmin : emailStatus      
    };
    /* Merging */
    let result = { ...userData, ...tempUserData };
    /* Dispatching */
    updateUserData(result);
  };

  /* Edit success */
  const editState = useSelector((state) => state.user.editState);
  useEffect(() => {
    setTimeout(() => {
      dispatch(setEditState(false));
    }, 5000);
  }, [editState, dispatch]);
  {
    /* Define multiple modal */
  }
  const [closeWarning, setCloseWarning] = useState(false);
  const yesFn = () => {
    const tempUserData = {
      id: userId,
    };
    /* Dispatching */
    dispatch(userUpdateLockAsync(tempUserData));
    setCloseWarning(false);
  };
  const noFn = () => {
    setCloseWarning(false);
  };

  const conFirm = () => {
    setCloseWarning(true);
  };

  const multipleModal = {
    reSendEmailModal: (
      <>
        {/* emailInvite request modal  */}
        {!loading && (
          <Modal
            modalOpen={modalOpen}
            modalClose={modalClose}
            heading={
              <div>
                <div className={styles.modal_heading}></div>
              </div>
            }
            modalSize={styles.req_email_custom_modal_size}
          >
            <div className={`col-sm-12  ${styles.single_product_block}`}>
              <div className="text-center pr-5 pl-5">
                {errorMessage ? (
                  <>
                    <p>
                      <img
                        src={errorMessage ? iconDanger : successIcon}
                        alt={errorMessage ? "Error" : ""}
                      />
                    </p>
                    <p className="pb-3"> {errorMessage} </p>
                  </>
                ) : (
                  <>
                    <p>
                      <img
                        src={errorMessage ? iconDanger : successIcon}
                        alt={errorMessage ? "Error" : "Success"}
                      />
                    </p>
                    <p className="pb-3">
                      {errorMessage
                        ? errorMessage
                        : "Email sent"}
                    </p>
                  </>
                )}
                <div className="col-sm-12">
                  <div className="text-center pb-4">
                    <Button
                      type={"reset"}
                      value={"Close"}
                      classes={{
                        className: `btn ${styles.btn_custom} ${styles.btn_small}`,
                      }}
                      onClick={modalClose}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    ),
    tmpPwdModal: (
      <>
        {/* Temporary Password modal  */}
        {!loading && (
          <Modal
            modalOpen={modalOpen}
            modalClose={modalClose}
            heading={
              <div>
                <div className={styles.modal_heading}>
                  {"Set Temporary Password"}
                </div>
              </div>
            }
            modalSize={styles.custom_modal_size}
          >
            {errorMessage ? (
              <>
                <div class="text-center pr-5 pl-4">
                  <p>
                    <img
                      src={errorMessage ? iconDanger : successIcon}
                      alt={errorMessage ? "Error" : ""}
                    />
                  </p>
                  <p className="pb-3"> {errorMessage} </p>
                </div>
              </>
            ) : (
              <div className={`col-sm-12  ${styles.single_product_block}`}>
                <div className="row">
                  <div className={`p-0 ${styles.patient_content}`}>
                    <TextField
                      classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: "col-5 p-0",
                        inputClassName: `${styles.basic_input} ${styles.disabled}`,
                      }}
                      label={"Registered Email:"}
                      type={"text"}
                      name={"email"}
                      id={"registered_email"}
                      defaultValue={userEmail}
                      disabled={true}
                    />
                    <TextField
                      classes={{
                        column: `col-10 ${styles.col_spec}`,
                        labelClass: `col-5 col-form-label ${styles.label_spec}`,
                        inputColumn: "col-5 p-0",
                        inputClassName: `${styles.basic_input} ${styles.disabled}`,
                      }}
                      label={"Temporary password:"}
                      type={"text"}
                      name={"temporarypwd"}
                      id={"temporary_pwd"}
                      defaultValue={tempPassWord}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="col-sm-12">
              <div className="text-center pb-4">
                <Button
                  type={"reset"}
                  value={"Close"}
                  classes={{
                    className: `btn ${styles.btn_custom} ${styles.btn_small}`,
                  }}
                  onClick={modalClose}
                />
              </div>
            </div>
          </Modal>
        )}
      </>
    ),
    pwdResetModal: (
      <>
        {/* Password Reset request modal  */}
        {!loading && (
          <Modal
            modalOpen={modalOpen}
            modalClose={modalClose}
            heading={
              <div>
                <div className={styles.modal_heading}></div>
              </div>
            }
            modalSize={styles.req_email_custom_modal_size}
          >
            <div className={`col-sm-12  ${styles.single_product_block}`}>
              <div className="text-center pr-5 pl-5">
                <p>
                  <img
                    src={errorMessage ? iconDanger : successIcon}
                    alt={errorMessage ? "Error" : "Success"}
                  />
                </p>
                <p className="pb-3">
                  {" "}
                  {errorMessage
                    ? errorMessage
                    : "Password for this user has been reset. User will get an email with the instructions to complete the process."}{" "}
                </p>

                <div className="col-sm-12">
                  <div className="text-center pb-4">
                    <Button
                      type={"reset"}
                      value={"Close"}
                      classes={{
                        className: `btn ${styles.btn_custom} ${styles.btn_small}`,
                      }}
                      onClick={modalClose}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    ),
    appInviteModal: (
      <>
        {/* emailInvite request modal  */}
        {!loading && (
          <Modal
            modalOpen={modalOpen}
            modalClose={modalClose}
            heading={
              <div>
                <div className={styles.modal_heading}></div>
              </div>
            }
            modalSize={styles.req_email_custom_modal_size}
          >
            <div className={`col-sm-12  ${styles.single_product_block}`}>
              <div className="text-center pr-5 pl-5">
                {errorMessage ? (
                  <>
                    <p>
                      <img
                        src={errorMessage ? iconDanger : successIcon}
                        alt={errorMessage ? "Error" : ""}
                      />
                    </p>
                    <p className="pb-3"> {errorMessage} </p>
                  </>
                ) : (
                  <>
                    <p>
                      <img
                        src={errorMessage ? iconDanger : successIcon}
                        alt={errorMessage ? "Error" : "Success"}
                      />
                    </p>
                    <p className="pb-3">
                      {errorMessage
                        ? errorMessage
                        : "Email has been sent to the user with instructions to download the app."}
                    </p>
                  </>
                )}
                <div className="col-sm-12">
                  <div className="text-center pb-4">
                    <Button
                      type={"reset"}
                      value={"Close"}
                      classes={{
                        className: `btn ${styles.btn_custom} ${styles.btn_small}`,
                      }}
                      onClick={modalClose}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    ),
  };
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
  return (
    <>
      <AccessErrorModal />
      {confirmationModalUser && (
        <Confirmation onClose={() => userConfirmClose()} title={" "}>
          <div className="text-center pr-5 pl-5">
            <p>
              <img src={warning} alt="warnning-icon" />
            </p>
            <p className="pb-3">
              Are you sure you'd would like to change the user status?
            </p>
            <div className="mb-2">
              <Button
                value="Yes"
                id={"yesId"}
                classes={{
                  className: `btn mr-2 pb-2 pt-2 mr-3 ${styles.yes_btn}`,
                }}
                onClick={() => userYesConfirm()}
              />
              <Button
                id={"closeId"}
                value="No"
                classes={{ className: `btn ml-2 pb-2 pt-2 ${styles.no_btn}` }}
                onClick={() => userConfirmClose()}
              />
            </div>
          </div>
        </Confirmation>
      )}
      {multipleModal[modalType]}
      <div className={`${styles.tab_content_top}`}>
        {closeWarning && (
          <>
            <div className={`${styles.warning} ml-3 mt-2`}>
              <h6>
                {"Are you sure you'd like to unlock the Account?"}
                <button onClick={yesFn}>Yes</button>
                <button onClick={noFn}>No</button>
              </h6>
            </div>
          </>
        )}
        <h2 className={`${styles.tab_content_hdg}`}>
          <div className={`row`}>
            <div className={`ml-3 ${styles.user_status}`}>
              {userData?.isCustomerActive === 0 ? (<>
                <span className={`${styles.label_spec}`}>{userData?.firstName + " " + userData?.lastName} </span> | {"customer"} <a href={`/admin/customers/${userData?.customerId}`} target="_blank">{userData?.customerNumber}</a> {"is"} {userData?.isCustomerActive === 0 && "inactive"} 
              </>):(
                <span className={`${styles.label_spec}`}>{userData?.firstName + " " + userData?.lastName}</span>
                
              )}
              
            </div>
            {userData?.isAccountLocked === 1 && (
              <>
                <div className={`ml-3 mt-n1`}>
                  <Button
                    classes={{
                      className: `btn ${styles.btn_custom} ${styles.btn_small}`,
                    }}
                    value="unLock"
                    id={"unLockBtn"}
                    data-testid={"unLockBtn"}
                    aria-label="unLockBtn"
                    onClick={
                      userData?.isAccountLocked === 1
                        ? () => conFirm()
                        : () => {}
                    }
                  >
                    Unlock Account
                  </Button>
                </div>
              </>
            )}
          </div>

          {tinyDelay&&!editable && userData?.isAccountLocked === 0 && (
            <span
              className={`float-sm-right ${styles.tab_edit}`}
              onClick={handleEdit}
            >
              <img src={editIcon} alt="edit" />
              {EDIT}
            </span>
          )}
          <span
            className={`float-sm-right ${styles.tab_edit} d-none`}
            onClick={handleEdit}
          >
            <img src={editIcon} alt="edit" />
            {EDIT}
          </span>
        </h2>
      </div>

      {!tinyDelay?<div className={`${styles.loader} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
        <div className={`row col-12`}>
        <div className={`col-md-8 ${styles.single_product_block}`}>
          <div className="row">
            {/* Edit success */}
            {editState === SUCCESS_STATUS && (
              <div className={`alert alert-success ${styles.form_alert}`}>
                {UPDATE_SUCCESS}
              </div>
            )}

            {/* Edit failure */}
            {editState === ERROR_STATUS && (
              <div className={`alert alert-danger ${styles.form_alert}`}>
                {UPDATE_FAILURE}
              </div>
            )}
            {/* Password Reset Email*/}
            <div className={`p-0 ${styles.patient_content}`}>

            {editable ? (
                <>

                  <div className={`col-12 mb-4 ${styles.col_spec}`}>
                    <label className={`col-6 col-form-label ${styles.label_spec}`}>
                      Activation Email:
                    </label>
                  { userData?.isEmailVerified===0 ? 
                    <>
                      <Button
                          classes={{
                            className: `btn ${styles.btn_custom} ${
                              styles.btn_small_cancel
                            } ${
                              userData?.isAccountLocked === 1
                                ? styles.disable_check
                                : ""
                            }`,
                          }}
                        
                          onClick={
                            userData?.isAccountLocked === 0
                              ? () => openModal("reSendEmailModal")
                              : () => {}
                          }
                        >                    
                           {"Resend Email"}
                          
                      </Button>

                          {/* Toggle switch */}
                      <div className="custom-control custom-switch ml-4 pt-0">
                        <div className={`${styles.toggle_shells}`}>
                          <div className={`${styles.toggle_button_status}`}>Verified</div> 
                          {/* <div className={`${styles.toggle_button_status}`}>{toggle ? "Verified" : "Not verified"}</div>                                               */}
                          <input 
                              type="checkbox"
                              name="toggle" 
                              className="custom-control-input"
                              id={"toggleSwitch"}
                              data-testid={"toggleSwitch"}
                              value={USER_EMAIL_STATUS[userData?.emailVerifiedByAdmin]}                                                    
                              onChange={toggleChange}
                          />
                          <label className={`custom-control-label ${styles.toggle_switch}`} for="toggleSwitch"></label> 
                        </div>
                      </div>
                    </>
                      :
                    <>
                      <div className={`col-3 pl-5 pt-2`}>                          
                           Verified
                      </div>
                    </>                  
                  }
                  
                  </div>

                  
                </>) : (
                  <>
                    <div className={`col-12 mb-4 ${styles.col_spec}`}>
                    <label className={`col-6 col-form-label ${styles.label_spec}`}>
                      Activation Email:
                    </label>
                  { userData?.isEmailVerified===0? <Button
                      classes={{
                        className: `btn ${styles.btn_custom} ${
                          styles.btn_small_cancel
                        } ${
                          userData?.isAccountLocked === 1
                            ? styles.disable_check
                            : ""
                        }`,
                      }}
                    
                      onClick={
                        userData?.isAccountLocked === 0
                          ? () => openModal("reSendEmailModal")
                          : () => {}
                      }
                    >
                      {"Resend Email"}
                    </Button>
                    :
                    <>
                      <div className={`col-3 pl-5 pt-2`}>                          
                           Verified
                      </div>
                    </>
                  
                  }
                  
                  </div>
                  </>
                )
            }
                

              <div className={`col-12 ${styles.col_spec}`}>
                <label className={`col-6 col-form-label ${styles.label_spec}`}>
                  Password Reset Email:
                </label>

                <Button
                  classes={{
                    className: `btn ${styles.btn_custom} ${
                      styles.btn_small_cancel
                    } ${
                      userData?.isAccountLocked === 1
                        ? styles.disable_check
                        : ""
                    }`,
                  }}
                  id={CANCEL_ID}
                  onClick={
                    userData?.isAccountLocked === 0
                      ? () => openModal("pwdResetModal")
                      : () => {}
                  }
                >
                  {"Send Email"}
                </Button>
              </div>

              {/* Set Temporary Password */}
              <div className={`col-12 ${styles.col_spec}`}>
                <label className={`col-6 col-form-label ${styles.label_spec}`}>
                  Set Temporary Password:
                </label>
                <Button
                  classes={{
                    className: `btn ${styles.btn_custom} ${
                      styles.btn_small_cancel
                    } ${
                      userData?.isAccountLocked === 1
                        ? styles.disable_check
                        : ""
                    }`,
                  }}
                  id={"setTemporaryPwd"}
                  onClick={
                    userData?.isAccountLocked === 0
                      ? () => openModal("tmpPwdModal")
                      : () => {}
                  }
                >
                  {"Create"}
                </Button>
              </div>

              {/* Invite To Download The App*/}
              <div className={`col-12 ${styles.col_spec}`}>
                <label className={`col-6 col-form-label ${styles.label_spec}`}>
                  Invite To Download The App:
                </label>
                <Button
                  classes={{
                    className: `btn ${styles.btn_custom} ${
                      styles.btn_small_cancel
                    } ${
                      userData?.isAccountLocked === 1
                        ? styles.disable_check
                        : ""
                    }`,
                  }}
                  id={"inviteApp"}
                  onClick={
                    userData?.isAccountLocked === 0
                      ? () => openModal("appInviteModal")
                      : () => {}
                  }
                >
                  {"Send Email"}
                </Button>
              </div>

              {/* Manage Status */}

              {editable ? (
                <>
                  <>
                    <Form
                      className={`${styles.patient_content}`}
                      onSubmit={handleSubmit(doSubmit)}
                    >

                      <div className={`col-12 ${styles.col_spec}`}>
                        <label
                          className={`col-6 col-form-label ${styles.label_spec}`}
                        >
                          Manage Status:
                        </label>
                        <RadioButton
                          id={"manageStatus"}
                          name={"manageStatus"}
                          classes={{
                            labelClass: `custom-control-label col-form-label ${styles.custom_control_label}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input}`,
                            inputColumn:
                              "custom-control custom-radio custom-control-inline",
                          }}
                          validator={register}
                          radioOption={["Active", "Inactive"]}
                          defaultValue={USER_MANAGE_STATUS[userData?.status]}
                          onChange={(e) => getConFirmUserAct(e.target.value)}
                        />
                      </div>

                      <div className="col-sm-12">
                        <div className="text-center pb-4 float-right">
                          <br />
                          {/* Save Button */}
                          <Button
                            classes={{
                              className: `btn ${styles.btn_custom} ${styles.btn_small}`,
                            }}
                            type="submit"
                            id={SUBMIT_ID}
                          >
                            {SAVE_BTN}
                          </Button>
                          {/* Cancel button  */}
                          <Button
                            classes={{
                              className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}`,
                            }}
                            id={CANCEL_ID}
                            onClick={handleEdit}
                          >
                            {CANCEL_BTN}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </>
                </>
              ) : (
                <div className={`col-12 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Manage Status:
                  </label>
                  <><div className={`col-3 pl-5 pt-1`}> {USER_MANAGE_STATUS[userData?.status]}</div></>
                 
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
}
