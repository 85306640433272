import {
    SUCCESS,
    FIRST_NAME_REQUIRED,
    LAST_NAME_REQUIRED,
    CUSTOMER_NUMBER_REQUIRED,
    EMAIL_REQUIRED,
    EMAIL_REGEX,
    EMAIL_INVALID,
    PASSWORD_REQUIRED,
    CONFIRM_PASSWORD_REQUIRED,
    PHONE_REQUIRED,
    VALID_PHONE,
    CHECK_TERMS,
    NO_FUTURE_DATE,
    DOB_REQUIRED,
    GENDER_REQUIRED,
    CUSTOMER_NAME_REQUIRED,
    STREET_ADDRESS_REQUIRED,
    REGION_REQUIRED,
    CITY_REQUIRED,
    POSTAL_NUMBER_REQUIRED,
    POSTAL_INVALID,
    ACC_GROUP_REQUIRED,
    DOMAIN_REQUIRED,
    DOMAIN_REGEX,
    DOMAIN_INVALID,
    ALPHA_NUMERIC,
    ALPHA_NUMERIC_CUSTOMFORM,
    ADMISSION_DATE_REQUIRED,
    ANNOUNCEMENT_NAME_REQUIRED,
    SALES_OFFICE_REQUIRED,
    ANNOUNCEMENT_DESCRIPTION_REQUIRED,
    ANNOUNCEMENT_DESCRIPTION_LIMIT,
    ANNOUNCEMENT_DESCRIPTION_LIMIT_REQUIRED,
    COMPANY_EMAIL_REQUIRED,
    SELECT_PASSWORD_REQUIRED,
    SEQUENCE_NUMBER,
    CONTENT_URL,
    SEQUENCE_VALID,
    SELECT_NEW_PASSWORD,
    ANNOUNCEMENT_NAME_LIMIT_REQUIRED,
    ANNOUNCEMENT_NAME_LIMIT,
    ENTER_NAME,
    VALID_URL,
    USER_PROFILE_PHDR_LIMIT,
    CARING_DESCRIPTION_LIMIT_REQUIRED,
    VALID_FIRST_NAME,
    VALID_LAST_NAME,
    MATERIAL_NUMBER_REQUIRED,
    MATERIAL_GROUP_CODE_REQUIRED,
    MATERIAL_GROUP_DESCRIPTION_REQUIRED,
    PRODUCT_MANAGER_REQUIRED,
    PRODUCT_FAMILY_FAMILY_DESCRIPTION_REQUIRED,
    PRODUCT_FAMILY_CODE_REQUIRED,
    PRODUCT_CATEGORY_DESCRIPTION_REQUIRED,
    PRODUCT_CATEGORY_CODE_REQUIRED,
    SURGICAL_DEBRIDEMENT_PERFORMED,
    DEBRIDEMENT_PERFORMED_BY,
    NON_EXCISIONAL_APPROACH,
    TYPE_OF_PROCEDURE,
    DEPTH_OF_DEBRIDEMENT,
    ERROR_DEBRIED_NECROTIC_SKIN,
    DRESSING_APPEARANCE_ERROR,
    GENERAL_ERROR_MARKETING_DESC,
    DIVISION_CODE_REQUIRED,
    MATERIAL_DESCRIPTION_REQUIRED,
    MATERIAL_DESCRIPTION_LIMIT,
    MATERIAL_DESCRIPTION_LIMIT_REQUIRED,
    SESSION_MONTHS_LIMIT,
    SESSION_WEEKS_LIMIT,
    SESSION_DAYS_LIMIT,
    SESSION_HOURS_LIMIT,
    SESSION_MINUTES_LIMIT,
    NEW_PASSWORD_REQUIRED,
    MRN_REQUIRED,
    VALID_MRN_NUMBER
} from "../constants";
/* Mandatory email */
export const checkEmail = {
    required: EMAIL_REQUIRED,
    pattern: {
        value: EMAIL_REGEX,
        message: EMAIL_INVALID
    }
}
/* Mandatory customer_moreInfo email */
export const checkEmailKFI = {
    // required: EMAIL_REQUIRED,
    pattern: {
        value: EMAIL_REGEX,
        message: EMAIL_INVALID
    }
}
/* Mandatory Comapany email */
export const checkCompanyEmail = {
    required: COMPANY_EMAIL_REQUIRED,
    pattern: {
        value: EMAIL_REGEX,
        message: EMAIL_INVALID
    }
}

/* Non mandatory email */
export const checkEmailAlt = {
    pattern: {
        value: EMAIL_REGEX,
        message: EMAIL_INVALID
    }
}

/* New Password validation */

export const checkNewPassword = {
    validate: {
        required: value => !!value || SELECT_PASSWORD_REQUIRED,
        passwordHint: value => {
            const props = { minLength: false, oneSpecialChar: false, oneNum: false, oneUCase: false };
            if (value.length >= 8) { props.minLength = true; }
            if (value.match(/[A-Z]/g)) { props.oneUCase = true; }
            if (value.match(/[!@#$%^&*()\-_=+{};:,<.>]/g)) { props.oneSpecialChar = true; }
            if (value.match(/[0-9]/g)) { props.oneNum = true; }

            if (props.minLength && props.oneNum && props.oneSpecialChar && props.oneUCase) {
                return SUCCESS;
            } else {
                return JSON.stringify(props);
            }
        }
    }
} 

/* Reset Password validation */

export const checkResetNewPassword = {
    validate: {
        required: value => !!value || NEW_PASSWORD_REQUIRED ,
        passwordHint: value => {
            const props = { minLength: false, oneSpecialChar: false, oneNum: false, oneUCase: false,atleastOne:false };
            if (value.length >= 8) { 
                props.minLength = true; 
            }
            if (value.match(/[A-Z]/g)) { 
                props.oneUCase = true; 
            }
            if (value.match(/[!@#$%^&*()\-_=+{};:,<.>]/g)) { 
                props.oneSpecialChar = true; 
            }
            if (value.match(/[0-9]/g)) { 
                props.oneNum = true; 
            }
            if (!props.minLength || !props.oneNum || !props.oneSpecialChar || !props.oneUCase) {
                props.atleastOne = true; 
                return false;
            }
            if (props.minLength && props.oneNum && props.oneSpecialChar && props.oneUCase) {
                props.atleastOne = false; 
                return SUCCESS;
            }
        }
    }
}
/* Confirm Password validation */

export const checkConfirmPassword = {
    required: CONFIRM_PASSWORD_REQUIRED
}

/* Password validation */

export const checkPassword = {
    required: PASSWORD_REQUIRED
}

/* Mrn Number */
export const checkMRNNumber = {
    required: MRN_REQUIRED,
    pattern: {
        // value: /^[1-9]\d*$/,
        // value: /^[0-9 \-]+$/,
        value: /^[a-zA-Z0-9-_ /\\/]+$/,
        message: VALID_MRN_NUMBER
    }
}

/* First name */

export const checkFirstName = {
    required: FIRST_NAME_REQUIRED,
    pattern: {
      //  value: /^[a-zA-Z]+$/,
       // value:/^([A-z])*[^\s]\1*$/,
        // value:/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        value:/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$|^[A-Za-z'-]+$/,
        message: VALID_FIRST_NAME
    }
}

/* Last name */
export const checkLastName = {
    required: LAST_NAME_REQUIRED,
    pattern: {
        //value: /^[a-zA-Z]+$/,
        value:/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$|^[A-Za-z'-]+$/,
        message: VALID_LAST_NAME
    }
}


export const checkFirstNamePatient = {
    required: "Enter Patient’s First Name",
    pattern: {
        value:/^([A-z])*[^\s]\1*$/,
        message: "Enter a valid Patient's first name"
    }
}


/* Last name */

export const checkLastNamePatient = {
    required: "Enter Patient’s Last Name",
    pattern: {
        value:/^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        message: "Enter a valid Patient’s Last Name"
    }
}

export const checkGenderPatient = {
    required: "Select Patient’s Gender"
}

/* Customer number */

export const checkCustomerNumber = {
    required: CUSTOMER_NUMBER_REQUIRED,
}

/* Primary group */

export const checkPrimaryGroup = {
    required: "Enter primary group number",
}


/* Facility */

export const checkFacilities = {
    required: "Please select facility",
}

/* Specifi Email */

export const checkSpecificEmail = {
    required: "Enter specific email addresses",
}

/* Email Domain */

export const checkDominaName = {
    required: "Enter domain name",
}

/* Type Include/Exclude */
export const checkIncExcType = {
    required: "Please select 'Include' or 'Exclude'",
}

/* Tenant ID */

export const checkTenantID = {
    required: "Enter tenant ID",
}

/* checkDepartment Name */
export const checkDepartmentName = {
    required: "Enter department name",
}

/* Title Name */
export const checkTitleName = {
    required: "Enter title name",
}

/* Include/Exclude */

export const checkAssignToAllEmails = {
    required: "Please select 'Yes' or 'No'",
}

export const checkTokenizedFacility = {
    required: "Please select 'Yes' or 'No'",
}


/* Gender number */

export const checkGender = {
    required: GENDER_REQUIRED,
}

/* Phone number */

export const checkPhoneNumber = {
    required: PHONE_REQUIRED,
    pattern: {
        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        message: VALID_PHONE
    }
}
export const checkPhoneNumberWithoutValidation = {
    pattern: {
        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        message: VALID_PHONE
    }
}



/* Terms and conditions (for registration page) */

export const checkTos = {
    required: CHECK_TERMS,
}

/* No future date validator */

export const checkNoFuturedate = {
    validate: {
        required: value => !!value || DOB_REQUIRED,
        futureDate: value => {
            const todaysDate = new Date().getTime()
            const selectedDate = new Date(value).getTime()
            if (selectedDate < todaysDate) {
                return true;
            } else {
                return NO_FUTURE_DATE;
            }
        }
    }
}


export const checkNoFuturedatePatient = {
    validate: {
        required: value => !!value || "Enter Patient’s Date of Birth",
        futureDate: value => {
            const todaysDate = new Date().getTime()
            const selectedDate = new Date(value).getTime()
            if (selectedDate < todaysDate) {
                return true;
            } else {
                return NO_FUTURE_DATE;
            }
        }
    }
}


/* Customer name */

export const checkCustomerName = {
    required: CUSTOMER_NAME_REQUIRED,
}


export const checkStreetName = {
    required: STREET_ADDRESS_REQUIRED,
}


export const checkRegion = {
    required: REGION_REQUIRED,
}

export const checkCityName = {
    required: CITY_REQUIRED,
}


/* Postal number */

export const checkPostalNumber = {//NOSONAR
    required: POSTAL_NUMBER_REQUIRED,
    pattern: {//NOSONAR
        //value: /^(?=.*[0-9])/,
        value: /(^(?=.*\d.*)[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\s\/?]{5,10}$)|(^[a-zA-Z]{5,10}$)|(^[A-Z]\d[A-Z][-]?\d[A-Z]\d$)|(^[A-Z]\d[A-Z][ ][-]?[ ]\d[A-Z]\d$)|(^\d{3}-\d{3}$)|(^\d{3}\s-\s\d{3}$)|(^[A-Z]\d[A-Z][-]?\d{3}$)|(^\d{3}?[ ]\d[A-Z]\d$)|(^[A-Z]\d[A-Z][][-]?\S\d{3}$)|(^\d{3}\S?[]\d[A-Z]\d$)/, //NOSONAR
        // value : /[A-Z]\d[A-Z][ ][-]?[ ]\d[A-Z]\d$/,
        // value: /(^\d{9}$)|(^(?=.*[0-9A-Z])$)|(^\d{4}-\d{4}$)/,
        message: POSTAL_INVALID
    }//NOSONAR
}//NOSONAR

/* US Postal number */

export const checkUSPostalNumber = {
    required: POSTAL_NUMBER_REQUIRED,
    pattern: {
        value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
        message: POSTAL_INVALID
    }
}
/* Check Apt suite other */

export const checkAplhaNumeric = {
    pattern: {
        value: ALPHA_NUMERIC,
        message: 'Only Letters & Numbers allowed!'
    }
}

export const checkAplhaNumericCustomForm = {
    pattern: {
        value: ALPHA_NUMERIC_CUSTOMFORM,
        message: 'Only Letters & Numbers allowed!'
    }
}

/* */

export const checkGroup = {
    required: ACC_GROUP_REQUIRED,
}

/* Mandatory domain */
export const checkDomain = {
    required: DOMAIN_REQUIRED,
    pattern: {
        value: DOMAIN_REGEX,
        message: DOMAIN_INVALID
    }
}


/* Admission Date */

export const checkAdmissionDate = {
    validate: {
        required: value => !!value || ADMISSION_DATE_REQUIRED
    }
}

/* Check name */

export const checkName = {
    required: ENTER_NAME,
}


export const checkAnnouncementName = {
    required: ANNOUNCEMENT_NAME_REQUIRED,
    maxLength: {
        value: ANNOUNCEMENT_NAME_LIMIT,
        message: ANNOUNCEMENT_NAME_LIMIT_REQUIRED
    }
}
export const checkSalesOffice = {
    required: SALES_OFFICE_REQUIRED,
}
export const checkAnnouncementDescription = {
    required: ANNOUNCEMENT_DESCRIPTION_REQUIRED,
    maxLength: {
        value: ANNOUNCEMENT_DESCRIPTION_LIMIT,
        message: ANNOUNCEMENT_DESCRIPTION_LIMIT_REQUIRED
    }
}

export const checkDescription={
    required : GENERAL_ERROR_MARKETING_DESC
}

/* Layout Sequence number */

export const sequenceNumber = {
    required: SEQUENCE_NUMBER,
    maxLength: {
        value: USER_PROFILE_PHDR_LIMIT,
        message: CARING_DESCRIPTION_LIMIT_REQUIRED
    },
    pattern: {
        // value: /^[1-9]+\d*$/,
        value: /^[1-9]\d*$/,
        message: SEQUENCE_VALID
    }
}



export const checkSessionMonths = {
    required: 'Pleae enter number',
    max: {
        value: SESSION_MONTHS_LIMIT,
        message: 'Max session timout is 12 months'
    },
    pattern: {
        value: /^[1-9]\d*$/,
        message: 'Pleae enter valid number'
    }
}

export const checkSessionWeeks = {
    required: 'Pleae enter number',
    max: {
        value: SESSION_WEEKS_LIMIT,
        message: 'Max session timout is 4 weeks'
    },
    pattern: {
        value: /^[1-9]\d*$/,
        message: 'Pleae enter valid number'
    }
}

export const checkSessionDays = {
    required: 'Pleae enter number',
    max: {
        value: SESSION_DAYS_LIMIT,
        message: 'Max session timout is 7 days'
    },
    pattern: {
        value: /^[1-9]\d*$/,
        message: 'Pleae enter valid number'
    }
}

export const checkSessionHours = {
    required: 'Pleae enter number',
    max: {
        value: SESSION_HOURS_LIMIT,
        message: 'Max session timout is 24 hours'
    },
    pattern: {
        value: /^[1-9]\d*$/,
        message: 'Pleae enter valid number'
    }
}

export const checkSessionMinutes = {
    required: 'Pleae enter number',
    max: {
        value: SESSION_MINUTES_LIMIT,
        message: 'Max session timout is 1440 minutes'
    },
    pattern: {
        value: /^[1-9]\d*$/,
        message:  'Pleae enter valid number'
    }
}

export const checkSessionTime = {
    required: 'Pleae enter number',
    pattern: {
        value: /^[1-9]\d*$/,
        message:  'Pleae enter valid number'
    }
}

export const checkCustomerGroup = {
    required: 'Please select a customer group ',
}

export const checkSessionType = {
    required: 'Select time type ',
}

export const checkPoaType = {
    required: 'Select time type ',
}
/* number */
export const measurementNumber = {
    pattern: {
        value: /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/,
        message: "Please enter valid measurement number"
    }
}

/* Layout Sequence number */

export const contentUrl = {
    required: CONTENT_URL,
    pattern: {
        // value:/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
        value: /^https?\:\/\/[^\/\s]+(\/.*)?$/,
        message: VALID_URL
    }
}

// user profile textarea validation ( max-chart - 250)
export const checkCaringTextarea = {
    required: ANNOUNCEMENT_DESCRIPTION_REQUIRED,
    maxLength: {
        value: USER_PROFILE_PHDR_LIMIT,
        message: CARING_DESCRIPTION_LIMIT_REQUIRED
    }
}
export const customerNumberValid = {
    required: "Customer number required"
}
export const OrganizationValid = {
    required: "Organization is required"
}
export const SequenceValid = {
    required: "Sequence is required"
}

export const commonHintCheck = (passwordCheck, setMinLength, setOneUCase, setOneSpecialChar, setOneNum ) => {
    passwordCheck?.length >= 8 ? setMinLength(true) : setMinLength(false);
    passwordCheck?.match(/[A-Z]/g) ? setOneUCase(true) : setOneUCase(false);
    passwordCheck?.match(/[!@#$%^&*()\-_=+{};:,<.>]/g) ? setOneSpecialChar(true) : setOneSpecialChar(false);
    passwordCheck?.match(/[0-9]/g) ? setOneNum(true) : setOneNum(false);
}

// Tissue type input box validator

export const tissueInput = (data) => {
    if(data.length === 0){
        return true
    }
    if(!data.match(/^(?!0+(?:\.0+)?$)\d?\d(?:\.\d\d?)?$/)){
        return false
    }
    else{
        return true
    }
}

// POA number validator

// export const poaValidator = (data) => {
//     if(data === ''){
//         return true
//     }
//     if(data.match(/^\d+$/)){
//         return true
//     }
//     return false
// }

export const poaRevalValidator = {
    required: "Pleae enter number",
    pattern: {
        value: /^[1-9]\d*$/,
        message: "Please enter valid number"
    }
}

export const checkMaterialNumber = {
    required: MATERIAL_NUMBER_REQUIRED,
}
export const checkMaterialDescription= {
    required: MATERIAL_DESCRIPTION_REQUIRED,
    maxLength: {
        value: MATERIAL_DESCRIPTION_LIMIT,
        message: MATERIAL_DESCRIPTION_LIMIT_REQUIRED
    }
}
export const checkCoreDescription= {
    maxLength: {
        value: MATERIAL_DESCRIPTION_LIMIT,
        message: MATERIAL_DESCRIPTION_LIMIT_REQUIRED
    }
}
export const checkMaterialGroupCode = {
    required: MATERIAL_GROUP_CODE_REQUIRED,
}
export const checkMaterialGroupDescription = {
    required: MATERIAL_GROUP_DESCRIPTION_REQUIRED,
}
export const checkProductCategoryCode = {
    required: PRODUCT_CATEGORY_CODE_REQUIRED,
}
export const checkProductCategoryDescription = {
    required: PRODUCT_CATEGORY_DESCRIPTION_REQUIRED,
}
export const checkProductFamilyCode = {
    required: PRODUCT_FAMILY_CODE_REQUIRED,
}
export const checkProductFamilyDescription = {
    required: PRODUCT_FAMILY_FAMILY_DESCRIPTION_REQUIRED,
}
export const checkProductManager = {
    required: PRODUCT_MANAGER_REQUIRED,
}

export const checkMaterialDivison={
    required: DIVISION_CODE_REQUIRED,
}

// Debridement 
export const surgicalDebridementPerformed = {
    required: SURGICAL_DEBRIDEMENT_PERFORMED
}
export const debridementPerformedBy = {
    required: DEBRIDEMENT_PERFORMED_BY
}
export const typeProcedure = {
    required: TYPE_OF_PROCEDURE
}
export const nonExcisionalApproach = {
    required: NON_EXCISIONAL_APPROACH
}
export const DepthofDebridement = {
    required: DEPTH_OF_DEBRIDEMENT
}//

export const debridedNecroticSkin = {
    required: ERROR_DEBRIED_NECROTIC_SKIN
}

export const dressingAppearance = {
    required: DRESSING_APPEARANCE_ERROR
}
export const numericValueOnly = {
    pattern: {
        value: /^[0-9]+$/,
        message: "Please enter valid number"
    }
}
export const numericValueOnlyBill = {
    required: 'Enter a bill number',
    pattern: {
        value: /^[0-9]+$/,
        message: "Please enter valid number"
    }
}
export const integerWithTwoDecimal = {
    required: "Enter an amount",
    pattern: {
        value: /^\d*\.?\d{0,2}$/,
        message: "Enter a correct amount"
    }
}
export const intergerQuantityValueOnly = {
    required: "Enter a quantity",
    pattern: {
        value: /^[0-9]+$/,
        message: "Enter correct quantity"
    }
}
/* check beds available */
export const checkBedsAvail = {
    // required: "Enter number of beds",
    pattern: {
        value: /^[1-9]/,
        message: "Enter valid number of beds"
    }
}

/* check Average Daily Census */
export const checkAvgDailyCensus = {
    // required: "Enter average daily census",
    pattern: {
        value: /^[1-9]/,
        message: "Enter valid average daily census"
    }
}

/* check patient population wound  */
export const checkPatientPopulation = {
    // required: "Enter patient population w/wounds(%)",
    pattern: {
        value: /^[1-9]/,
        message: "Enter valid patient population w/wounds(%)"
    }
}
