import { LIST_VIEW_LIMIT } from "../constants";
import { format } from 'date-fns';
const pdfDownloadDate = format(new Date(), "MM.dd.yyyy-hh.mm.aaa");


export const handleToDateMove = (data, setToDate, startOfYear, setError, clearErrors) => {
    setToDate(data)
    const fromDate = startOfYear(new Date());
    const toDate = data
    if (toDate < fromDate) {
        setError('datePicker2', {
            type: "manual",
            message: "To date should be greater than from date"
        })
    }
    if (toDate > fromDate) {
        clearErrors(['datePicker2'])
    }
} 

export const getActivePageMove = (data, setActivePage, setPlatformResults, sortType, format, fromDate, toDate, selectedBlock, dispatch, getPlatformResultsAlt) => {
    setActivePage(data);
    const paginationPayLoad = {};
    paginationPayLoad['url'] = '/reports/platformanalytics/customerdata'
    paginationPayLoad['method'] = 'POST'
    paginationPayLoad['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT }
    paginationPayLoad['setMethod'] = setPlatformResults
    paginationPayLoad['sortType'] = sortType
    paginationPayLoad['toBeSent'] = {
        fromDate: format(fromDate, "LL/dd/yyyy"),
        toDate: format(toDate, "LL/dd/yyyy"),
        customerType: selectedBlock
    }
    dispatch(getPlatformResultsAlt(paginationPayLoad));
}

export const handleClickMove = (html2canvas, jsPDF, canvases, header, customerName) => {
    html2canvas(document.getElementById('pdf'), { scale: 2 }).then(function (canvas) {

        var imgWidth = 210;
        var pageHeight = 294;

        var doc = new jsPDF('p', 'mm');

        let size = 0

        // Below is the moving the content to next page based on fitting
        canvases.forEach((data, i) => {
            if (size + data.height > pageHeight) {
                doc.addPage();
                doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
                size = header.height
            }
            if (size === 0) {
                doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
                size = header.height
            }
            doc.addImage(data.imgData, 'PNG', 0, size, imgWidth, data.height);
            size += data.height
        })

        doc.save(`${customerName}.Platform_Analytics.${pdfDownloadDate}.pdf`);
    });

}