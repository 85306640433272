import React from 'react'
import { RECORD_CREATED_HEAD, FACILITY_LOCATION_LBL } from '../../../constants'
import styles from './patientHeading.module.scss'
import PropTypes from 'prop-types'
import { dateForPatientCard } from '../../../utils'

export function PatientHeading({ heading }) {

  return (
    // Generic heading for all tabs 

    <div className={styles.tab_content_top}>
      <h2 className={styles.tab_content_hdg}>
        <span className={styles.tab_hdg}>
          {(heading && heading.firstName !== null) ? heading.firstName + ' ' + heading.lastName : ''}
        </span><span className={styles.tab_content_line}>
          {RECORD_CREATED_HEAD} : {heading?.createdDate && dateForPatientCard(heading?.createdDate)}
        </span>
        <span className={styles.tab_content_subline}>
          <strong>
            {FACILITY_LOCATION_LBL}
          </strong> {/* heading?.facilityNumber} | */}
          {heading?.facilityName}
        </span>
      </h2>
    </div>
  )
}

PatientHeading.propTypes = {
  heading: PropTypes.object
}

