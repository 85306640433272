import React from 'react'
import Slider from "react-slick";
import "./react-slick.min.css";
import "./react-slick-theme.css";
import { useSelector } from 'react-redux';

export function CharacteristicReferenceImages({ setReferenceImage, styles }) {


    // Get state
    const images = useSelector(state => state.patient.touchViewImages)

    // Settings for react slick
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
    };

    return (
        <div>
            {/* Back button */}
            <button
                className={styles.reference_images}
                onClick={() => setReferenceImage(false)}
            >
                Back
            </button>

            <br />

            {/* Carousel block */}
            <div className={styles.carousel_block}>

                {images && images.length > 0 && images.map((data) => {
                    return <>
                        {/* Heading */}
                        <h3>{data.title}</h3>
                        {/* Slider */}
                        <Slider {...settings}>
                            {data.referencePictures && data.referencePictures.length > 0 && data.referencePictures.map((image) => {
                                return <div>
                                    {/* Image */}
                                    <img src={image} alt="wound" />
                                </div>
                            })}
                        </Slider>
                        <br />
                    </>
                })}
            </div>


        </div>
    )
}
