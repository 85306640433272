import React from 'react';
import style from './historicalView.module.scss';
import { Button } from '../../primary/Button/button';
import { Tab as TabComponent } from '../../secondary';
import PropTypes from 'prop-types'
import { AllOtherAnatomy } from './allOtherAnatomy';
import { SameBodyAnatomy } from './sameBodyAnatomy';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { daysAgo } from '../../../utils';

export let HistoricalView = ({ setHistoryState, setNewWound, setEditable, setCurrentWound, setSave, setCurrentId }) => {


    const humanAnatomy = useSelector(state => state.patient.humanAnatomy);
    const historyCause = useSelector(state => state.patient.historyCause);
    // single assessment details.
    const detailAssessment = useSelector(state => state.patient.detailAssessment);
    // multiple assessment details. 
    const woundAssessments = useSelector(state => state.patient.woundAssessments);


    let sameAnotomy = [];
    let otherAnotomy = [];
    // compare between two data
    woundAssessments && woundAssessments?.assessments?.length > 0 &&Array.isArray(woundAssessments.assessments)&& woundAssessments?.assessments?.map((obj) => {//NOSONAR
        obj?.assessments?.filter((assessment) => {//NOSONAR
            if (detailAssessment.id !== assessment.id) {
                if (assessment.anatomy === detailAssessment.anatomy) {
                    sameAnotomy.push(assessment)
                } else {
                    otherAnotomy.push(assessment);
                }
            }
        })//NOSONAR
    })//NOSONAR


    /* Handle cancel */
    const handleCancel = () => {
        setHistoryState(false)
        //setEditable(true)
    }

    /* Handle new wound */
    const handleNew = () => {
        setHistoryState(false)
        setEditable(true)
        setNewWound(true)
        setSave(true)
    }

    /* Handle current wound */
    const handleCurrent = () => {
        setHistoryState(false)
        setEditable(true)
        setCurrentWound(true)
        setSave(true)
    }


    const tabList = [
        {
            name: 'Same Body Anatomy',
            content: <SameBodyAnatomy
                handleCurrent={handleCurrent}
                setCurrentId={setCurrentId}
                humanAnatomy={humanAnatomy}
                historyCause={historyCause}
                sameAnotomy={sameAnotomy}
            />,
            className: style.tab_none_history
        },
        {
            name: 'All Others',
            content: <AllOtherAnatomy
                handleCurrent={handleCurrent}
                setCurrentId={setCurrentId}
                humanAnatomy={humanAnatomy}
                historyCause={historyCause}
                otherAnotomy={otherAnotomy}
            />,
            className: style.tab_none_history
        }

    ];


    return (


        <div className={` ${style.tab_content}`}>
            <div className={`${style.tab_pane} ${style.active}`}>

                <div className={`col ${style.measurement_blk}`}>

                    <div className={`${style.wound_left_block}`}>

                        <figure className="figure align-top">
                            <figcaption className={`${style.figure_caption}`}>Current Wound</figcaption>
                            <img className={`figure-img img-fluid ${style.image_size}`} src={detailAssessment?.picture} />
                        </figure>
                        <div className={`${style.patient_details}`}>
                            <p><label>History/Cause:</label><span>{
                                historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                    let value;
                                    if (data?.options && data?.options?.length > 0) {
                                        data.options.map((sub) => {
                                            if (sub.key === detailAssessment?.historyCause?.cause) {
                                                value = sub.value
                                            }
                                        })
                                    }
                                    else {
                                        if (data.key === detailAssessment?.historyCause?.cause) {
                                            value = data.value
                                        }
                                    }
                                    return value;
                                })
                            }</span></p>
                            <p><label>Anatomy:</label>
                                <span>
                                    {humanAnatomy && humanAnatomy.length > 0 && humanAnatomy?.map((item) => {
                                        let value;
                                        if (item.key === detailAssessment?.anatomy) {
                                            value = item.value;
                                        }
                                        return value;
                                    })}
                                </span>
                            </p>
                            <p><label>Identified By:</label><span>{detailAssessment?.identifiedBy}</span></p>
                            <p>
                                <label>Last Assessed:</label>
                                <span>
                                    {detailAssessment?.lastAssessedDate ? daysAgo(detailAssessment.lastAssessedDate) : 'NA'}                                </span></p>
                            <p><label>Wound Acquired:</label><span>{detailAssessment?.woundAcquired}</span></p>
                            <Button
                                type={"submit"}
                                value="Document as New Wound"
                                id={"tes"}
                                classes={{ className: `btn ${style.btn_custom} ${style.btn_small} w-auto` }}
                                onClick={handleNew}
                            />

                        </div>

                    </div>

                    <br />

                    <div className={`${style.wound_right_block}`}>
                        <p className={`${style.heading_wound}`}>Past Wounds</p>
                        {
                            sameAnotomy.length > 0 || otherAnotomy.length > 0 ?
                                <>
                                    <TabComponent tabList={tabList} histCause={true}/>
                                </>
                                :
                                <p className={`${style.heading_wound}`}>Patient doesn't have any record of past wounds</p>
                        }


                    </div>
                    <div className="col-sm-12 mb-4 mt-4">
                        <div className="text-center">
                            <Button
                                type={"submit"}
                                value="Cancel"
                                id={"tes"}
                                classes={{ className: `btn ${style.btn_custom} ${style.btn_small_cancel}` }}
                                onClick={handleCancel}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>


    )
}
HistoricalView.propTypes = {
    dispatch: PropTypes.func,
}

HistoricalView = connect()(withRouter(HistoricalView));
