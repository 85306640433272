import React from 'react'
import { Button } from '../../primary'
import styles from './actionModal.module.scss'

export function ActionModal({ iconImg, loaderImg, heading, buttonText, buttonAction, buttonTextAlt, buttonActionAlt, buttonClass = "" }) {


    return (
        <div className="text-center">
            {/* Image */}
            {iconImg && <img src={iconImg} alt="icon" />}
            {/* Heading */}
            {heading && <h5>{heading}</h5>}
            {/* Loader */}
            {loaderImg && <img src={loaderImg} alt="loader" className={styles.loader_img} />}
            <div className="mt-2">
                {/* Button  alt */}
                
                {/* Button  */}
                {buttonText && <Button
                    classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small} alertMsgClose` }}
                    type="submit"
                    id={buttonText}
                    onClick={buttonAction}
                    data-testid={"alertMsg"}
                >
                    {buttonText}
                </Button>}
                {buttonTextAlt && <Button
                    classes={{ className: `btn ${styles.btn_custom} ${styles.btn_cancel}` }}
                    type="button"
                    id={buttonTextAlt}
                    onClick={buttonActionAlt}
                >
                    {buttonTextAlt}
                </Button>}
            </div>
        </div>
    )
}
