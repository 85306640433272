import React from 'react'
import styles from './characteristicCustomForm.module.scss'
import { TextField, MultiSelect } from '../../primary'

export function CharacteristicCustomFormMultiSelect({ data, index, optionData, getForm, register, errors, addItemMultiselect, showViewForm, optionLevelbranching, customFormBuilder, checkBranch }) {
    let viewForm = getForm?.formdata?.length > 0 && [...getForm.formdata].find((v) => v.id === data.id)
    let findOthers = viewForm?.options?.find((v) => v === "other")
    const onSelectOrg = (selectedList, selectedItem) => { addItemMultiselectVal(selectedList, data, index, selectedItem, data.id) }
    const removeItem = (selectedList, removedItem) => { removeItemMultiSelectVal(selectedList, data, index, removedItem, data.id) } 
    // multiselect select
    const addItemMultiselectVal = (selectedList, data, index, selectedItem, id) => {
        let selectList = []
        if (selectedItem.key !== "other" && selectedItem.key !== "noneoftheabove" && selectedItem.key !== "alloftheabove") {
            selectedList.map((val, index) => {
                if (val.key !== "other" && val.key !== "noneoftheabove" && val.key !== "alloftheabove") {
                    selectList = [...selectList, val.key]
                }
            })
        } else {
            selectList = [selectedItem.key]
        }
        let forms = customFormBuilder && [...customFormBuilder]
        let formobj = { ...forms[index] }
        let findGotoNum = formobj.options.find((o) => o.key === selectedItem.key)
        let branchNumber = checkBranch(findGotoNum, index)
        if (showViewForm?.formdata?.length > 0) {
            let viewData = { ...getForm?.formdata[index] }
            viewData.options = selectList
            let dataform = { ...getForm }
            let viewFrm = [...getForm.formdata]
            viewFrm.splice(index, 1, viewData)
            dataform.formdata = viewFrm
            addItemMultiselect(dataform)
        } else {
            let viewForm = getForm?.formdata?.length > 0 && [...getForm.formdata].find(v => v.id === id)
            let selectedIndex = getForm?.formdata?.length > 0 && [...getForm.formdata].findIndex(x => x.id === id);
            if (viewForm) {
                let viewData = { ...viewForm }
                viewData.options = selectList
                let dataform = { ...getForm }
                let viewFrm = [...getForm.formdata]
                viewFrm.splice(selectedIndex, 1, viewData)
                dataform.formdata = viewFrm
                addItemMultiselect(dataform)
            } else {
                let viewData = { ...getForm }
                viewData.formdata = [...viewData.formdata, { id: id, options: selectList, value: "" }]
                addItemMultiselect(viewData)
            }
        }
        branchNumber !== "" && optionLevelbranching(selectedItem.key, index, id, forms, branchNumber)
    }

    // multiselect remove
    const removeItemMultiSelectVal = (selectedList, data, index, removedItem, id) => {
        let selectList = []
        selectedList.map((val, index) => {
            selectList = [...selectList, val.key]
        })
        let lastIndex = selectedList[selectedList.length - 1]
        let forms = customFormBuilder && [...customFormBuilder]
        let formobj = { ...forms[index] }
        let branchNumber = "";
        if (lastIndex !== undefined) {
            let findGotoNum = formobj.options.find((o) => o.key === lastIndex.key)
            branchNumber = checkBranch(findGotoNum, index)
        } else {
            if (formobj.goto !== "" && formobj.goto !== null) {
                branchNumber = checkBranch(formobj, index)
            } else {
                if (formobj.options != null && formobj.options.length > 0) {
                    formobj.options.map((opt, optind) => {
                        if (branchNumber === "next" || branchNumber === "") {
                            branchNumber = checkBranch(opt, index)
                        }
                    });
                }
            }
        }
        if (showViewForm?.formdata?.length > 0) {
            let viewData = { ...getForm.formdata[index] }
            viewData.options = selectList
            let dataform = { ...getForm }
            let viewFrm = [...getForm.formdata]
            viewFrm.splice(index, 1, viewData)
            dataform.formdata = viewFrm
            addItemMultiselect(dataform)
        } else {
            let viewForm = getForm?.formdata?.length > 0 && [...getForm.formdata].find(v => v.id === id)
            let selectedIndex = getForm?.formdata?.length > 0 && [...getForm.formdata].findIndex(x => x.id === id);
            if (viewForm) {
                let viewData = { ...viewForm }
                viewData.options = selectList
                let dataform = { ...getForm }
                let viewFrm = [...getForm.formdata]
                viewFrm.splice(selectedIndex, 1, viewData)
                dataform.formdata = viewFrm
                addItemMultiselect(dataform)
            }
        }
        branchNumber !== "" && optionLevelbranching(removedItem.key, index, id, forms, branchNumber)
    }

    const getOtherValue = (e, index, id) => {
        if (showViewForm?.formdata?.length > 0) {
            let viewData = { ...getForm.formdata[index] }
            viewData.value = e.target.value
            let dataform = { ...getForm }
            let viewFrm = [...getForm.formdata]
            viewFrm.splice(index, 1, viewData)
            dataform.formdata = viewFrm
            addItemMultiselect(dataform)
        } else {
            let viewForm = getForm?.formdata?.length > 0 && [...getForm.formdata].find(v => v.id === id)
            let selectedIndex = getForm?.formdata?.length > 0 && [...getForm.formdata].findIndex(x => x.id === id);
            if (viewForm) {
                let viewData = { ...viewForm }
                viewData.value = e.target.value
                let dataform = { ...getForm }
                let viewFrm = [...getForm.formdata]
                viewFrm.splice(selectedIndex, 1, viewData)
                dataform.formdata = viewFrm
                addItemMultiselect(dataform)
            }
        }
    }

    return <div key={index}>
        <MultiSelect
            id={`${data.id}`}
            name={`${data.id}`}
            label={`${data.desc}`}
            classes={{
                column: `col-12 ${styles.col_spec}`,
                inputClassName: styles.basic_select,
                labelClassName: `col-6 col-form-label ${styles.label_spec}`,
                inputColumn: `col-5 p-0 ${styles.select_wrapper} multiselectorg`,
            }}
            optionValue={optionData}
            showCheckbox={true}
            displayValue="value"
            onSelect={onSelectOrg}
            onRemove={removeItem}
            defaultValue={viewForm?.options}
            labelSubText={'Select all that apply'}
        />
        {findOthers && <TextField
            id={`${data.id}`}
            name={`${data.id}`}
            classes={{
                column: `col-12 ${styles.col_spec}`,
                inputColumn: `offset-6 col-5 p-0 ${styles.display_flex}`,
                inputClassName: `${styles.basic_input} getOtherval`
            }}
            type="text"
            validator={register}
            errors={errors}
            defaultValue={viewForm.value}
            onBlur={(e) => getOtherValue(e, index, data.id)}
        />}
    </div>
}
