import React from 'react'
import { ProductImage } from '../../core/Products/productImage'
import { ProductVideo } from '../../core/Products/productVideo'
import { Tab } from '../../secondary'
import styles from './multiFileUpload.module.scss'

export function MultiFileUpload({ handleAddMediaCancel, productType, mediaList, getMedia, productId }) {

    const tabList = [
        {
            name: 'Images',
            content: <ProductImage
                handleAddMediaCancel={handleAddMediaCancel}
                productType={productType}
                mediaList={mediaList}
                getMedia={getMedia}
                productId={productId}
            />,
            className: styles.no_shadow
        },
        {
            name: 'Videos',
            content: <ProductVideo
                handleAddMediaCancel={handleAddMediaCancel}
                productType={productType}
                mediaList={mediaList}
                getMedia={getMedia}
                productId={productId}
            />,
            className: styles.no_shadow
        }
    ]

    return (
        <div>
            <div className={styles.media_hdg}>
                Upload Media
            </div>
            <div className={styles.media_btm}>
                <Tab tabList={tabList} />
            </div>

        </div>
    )
}
