import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkListRecordsCount, setLastVal } from "../../../app/patientSlice";
import { VerticalTabWorkList } from "../../secondary/VerticalTabWorkList";
import { OtherAssessmentsNONPOA } from "./otherAssessmentsNONPOA";
import { OtherAssessmentsPOA } from "./otherAssessmentsPOA";

export function OtherAssessments({facilityNumber, history }) {
  useEffect(() => {
    if(facilityNumber){
        dispatch(getWorkListRecordsCount(facilityNumber,'otherAssessmentsValues'));
    }
   }, [facilityNumber])
  const workListRecordsCount = useSelector(state => state.patient.workListRecordsCount);
  const poaCount=workListRecordsCount?.otherAssessmentsValues?.poaCount?`(${workListRecordsCount?.otherAssessmentsValues?.poaCount})`:''
  const nonpoaCount=workListRecordsCount?.otherAssessmentsValues?.nonpoaCount?`(${workListRecordsCount?.otherAssessmentsValues?.nonpoaCount})`:''
 
  /* Tab list */
  const tabList = [
    {
      name: `POA ${poaCount}`,
      content: <OtherAssessmentsPOA facilityNumber={facilityNumber}/>,
      bottomName: 'Pending Review'
    },
    {
      name: `Non-POA ${nonpoaCount}`,
      content: <OtherAssessmentsNONPOA facilityNumber={facilityNumber} />,
      bottomName: 'Pending Review'
    },
  ];
  const dispatch = useDispatch();
  const lastVal = useSelector(state => state.patient.lastVal)
  const [defaultVerTab, setDefaultVerTab] = useState(lastVal?.selectVerTab?lastVal?.selectVerTab:0)
  const onSelectVerTab = (e) => {
    setDefaultVerTab(e)
     const setLast={...lastVal,selectVerTab: e }
     dispatch(setLastVal(setLast))
  
    }
  return (
    <>
      <VerticalTabWorkList onSelectInt={onSelectVerTab} defaultIndex={defaultVerTab<=1?defaultVerTab:0} tabList={tabList} dispatch={dispatch} />
    </>
  )

}
