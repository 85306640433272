import React, { useState, useEffect, useRef } from 'react';
import { PatientHeading } from './patientHeading'
import { useForm } from 'react-hook-form'
import {
  PATIENT_STATUS_LBL,
  DISCHARGED_DATE_LBL,
  ADMITTED_DATE_LBL,
  RE_ADMITTED_DATE_LBL,
  PATIENT_STATUS_ID,
  UPDATE_FAILURE,
  UPDATE_SUCCESS,
  SUCCESS_STATUS,
  ERROR_STATUS
} from '../../../constants';
import { Button, Form, SelectBox, DateTimePicker, AccessErrorModal } from '../../primary';
import style from './status.module.scss';
import EditIconBlue from '../../../static/images/edit-icon-blue.png';
import "react-datepicker/dist/react-datepicker.css";
import { format, addDays, subDays } from 'date-fns'
import { Confirmation } from '../../secondary/Confirmation/confirmation'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { patientUpdateAsync, setEditState } from '../../../app/patientSlice';
import infoIcon from '../../../static/images/info.svg';
import { saveStatus, yesConfirmStatus, handleStatusChangeStatus, allNullCheck } from '../../../utils'
import Loader from '../../../static/images/spinner.svg';

export function Status({ patientData }) {
  /* Use hook form */

  const [offlineData, setOfflineData] = useState((patientData && patientData.offlineInfo) ? patientData.offlineInfo : '');
  let patientStatus = '';

  const { control, errors } = useForm({
    mode: 'all'
  });
  let statusDate = new Date();
  let statusTime = new Date();
  const [statusDateErr, setStatusDateErr] = useState(false);
 
  const [startDate, setStartDate] = useState(new Date());
  let [patientOptions, setPatientOptions] = useState([{ key: 'admitted', value: 'Admit' }, { key: 'readmitted', value: 'Re-Admit' }, { key: 'discharged', value: 'Discharge' }]);
  let patientStatusName = 'Admit';

  let [patientSatusData, setPatientSatusData] = useState('');
  useEffect(() => {
    patientStatus = (patientData.statusInfo && patientData.statusInfo.status !== '') ? patientData.statusInfo.status : '';
    if (patientStatus === '') {
      patientOptions = [{ key: 'admitted', value: 'Admit' }];
      patientStatusName = 'Admit';
    }

    patientOptions.map(function (proj) {//NOSONAR
      if (proj.key === patientStatus) {
        patientStatusName = proj.value;//NOSONAR
        return
      }
    });//NOSONAR

    statusDate = (patientStatus === 'discharged') ? patientData.statusInfo.dischargeDate : (patientData && patientData.statusInfo && patientData.statusInfo.admissionDate) ? patientData.statusInfo.admissionDate.replace('Z', '') : (patientData && patientData.careInfo && patientData.careInfo.admissionDate) ? patientData.careInfo.admissionDate.replace('Z', '') : new Date();
    setPatientSatusData({
      patientStatus: patientStatus,
      statusDate: statusDate ? format(new Date(statusDate), "MM/dd/yyy") : format(new Date(), "MM/dd/yyy"),
      statusTime: statusDate ? format(new Date(statusDate), "h:mm aa") : format(new Date(), "h:mm aa"),
      patientStatusName: (patientStatusName === '') ? 'Admit' : patientStatusName,
    })

    if (patientStatus === 'admitted' || patientStatus === 'readmitted') {
      setPatientOptions([{ key: 'discharged', value: 'Discharge' }]);

    } else if (patientStatus === 'discharged') {
      setPatientOptions([{ key: 'readmitted', value: 'Re-Admit' }]);
    }
    patientData = { ...patientData, ...offlineData }
  }, [patientData])
  /* State to check editable forms */
  const [editable, setEditable] = useState(false)
  const handleCancel = () => {
    setEditable(!editable)
  }
  /* Function to toggle edit */
  const handleEdit = () => {
    let label = '';
    if (patientSatusData.patientStatus === "admitted" || patientSatusData.patientStatus === "") {
      label = DISCHARGED_DATE_LBL;
    } else if (patientSatusData.patientStatus === "readmitted") {
      label = DISCHARGED_DATE_LBL;
    }
    else if (patientSatusData.patientStatus === "discharged") {
      label = RE_ADMITTED_DATE_LBL;
    }
    if (patientSatusData.patientStatus === "" || label === '') {
      label = ADMITTED_DATE_LBL;
    }
    setDyntLbl(label)
    setEditable(!editable)
    setStatusDateErr(false)
  }

  const handleStatusChange = (e) => {
    setStatusDateErr(false)
    handleStatusChangeStatus(e, ADMITTED_DATE_LBL, RE_ADMITTED_DATE_LBL, DISCHARGED_DATE_LBL, setDyntLbl)
  }

  /* Dispatch */
  const dispatch = useDispatch();
  /* Form submit */
  let save = (e) => {
    e.preventDefault();
    if(e.target.patientStatus && e.target.patientStatus.value && e.target.patientStatus.value !== null){
      setStatusDateErr(false)
      saveStatus(e, setConfirmationModal, patientData, dispatch, patientUpdateAsync, setEditable, editable);
    }else{
      setStatusDateErr(true)
    }
  }

  const [dyntLbl, setDyntLbl] = useState('');
  useEffect(() => {
    let label = '';
    if (patientSatusData.patientStatus === "admitted") {
      label = ADMITTED_DATE_LBL;
    } else if (patientSatusData.patientStatus === "readmitted") {
      label = RE_ADMITTED_DATE_LBL;
    }
    else if (patientSatusData.patientStatus === "discharged") {
      label = DISCHARGED_DATE_LBL;
    }
    setDyntLbl(label)
  }, [setDyntLbl])
  let statusRef = useRef();
  const modalClose = () => {
    setConfirmationModal(false);
  }
  const [confirmationModal, setConfirmationModal] = useState(false)

  const yesConfirm = (e) => {
    yesConfirmStatus(e, PATIENT_STATUS_ID, patientData, dispatch, patientUpdateAsync, setEditable, editable, setConfirmationModal);
  }

  const noConfirm = () => {
    setEditable(false)
    modalClose();
  }
  const [infoModal, setInfoModal] = useState(false)
  const closeInfoConfirm = () => {
    setInfoModal(false)
    modalClose();
  }
  const openInfoModal = () => {
    setInfoModal(true);
  }

  /* Edit success */
  const editState = useSelector(state => state.patient.editState)

  useEffect(() => {
    setTimeout(() => {
      dispatch(setEditState(false))
    }, 5000)
  }, [editState, dispatch])
  useEffect(() => {
    if (editState === "success") {
      setEditable(!editable)
      setConfirmationModal(false)
      setTimeout(() => {
        dispatch(setEditState(false))
      }, 5000)
    } else if (editState?.errorMessage) {
      setTimeout(() => {
        dispatch(setEditState(false))
      }, 5000)
     
    }
  }, [editState, dispatch])

  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
  
  return (
    <div>
      {/* Generic heading  */}
      {/* Edit button */}
      {tinyDelay&&!editable && allNullCheck(patientData?.firstName) && <div className={`float-sm-right ${style.tab_edit}`} onClick={handleEdit}><img src={EditIconBlue} alt="edit" />Edit</div>}
     
      {!tinyDelay ? <div className={`${style.loader} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
     <> 
     {/* <PatientHeading heading={patientData} /> */}
      {/* To display access error */}
      <AccessErrorModal />
      <div className={`col-sm-12  ${style.single_product_block}`}>
        <div className={`row ${style.patient_content}`}>
          {!editable ?
            <>
              {/* Edit success */}
              {editState === SUCCESS_STATUS && <div className={`alert alert-success ${style.form_alert}`}>{UPDATE_SUCCESS}</div>}

              {/* Edit failure */}
              {editState === ERROR_STATUS && <div className={`alert alert-danger ${style.form_alert}`}>{UPDATE_FAILURE}</div>}
              {/* Heading */}
              <div className={`col-sm-12 ${style.col_spec} pt-3`}>
                <p className={`col-12 ${style.subheading_2}`}>Status Information:</p>
              </div>
              <div className="row col-md-12">
                {(patientSatusData.patientStatus === "admitted" || !patientSatusData.patientStatus) &&
                  <>
                    <div className={`col-md-6 ${style.col_spec}`}>
                      <label className={`col-6 col-form-label ${style.label_spec}`}>{PATIENT_STATUS_LBL}</label>
                      <div className="col-6 p-0 ">
                        <span className={`col-form-label ${style.content_spec}`}>
                          {patientSatusData.patientStatusName}
                        </span>
                      </div>
                    </div>

                    <div className={`col-md-6 ${style.col_spec}`}>
                      <label className={`col-6 col-form-label ${style.label_spec}`}>{ADMITTED_DATE_LBL}</label>
                      <div className="col-6 p-0">
                        <span className={`col-form-label ${style.content_spec}`}>
                          {(patientSatusData.patientStatus !== "") ? patientSatusData.statusDate + ' ' + patientSatusData.statusTime : ''}
                        </span>
                      </div>
                    </div>
                  </>
                }
                {(patientSatusData.patientStatus === "readmitted") &&
                  <>
                    <div className={`col-md-6 ${style.col_spec}`}>
                      <label className={`col-6 col-form-label ${style.label_spec}`}>{PATIENT_STATUS_LBL}</label>
                      <div className="col-6 p-0">
                        <span className={`col-form-label ${style.content_spec}`}>
                          {patientSatusData.patientStatusName}
                        </span>
                      </div>
                    </div>

                    <div className={`col-md-6 ${style.col_spec}`}>
                      <label className={`col-6 col-form-label ${style.label_spec}`}>{RE_ADMITTED_DATE_LBL}</label>
                      <div className="col-6 p-0">
                        <span className={`col-form-label ${style.content_spec}`}>
                          {patientSatusData.statusDate + ' ' + patientSatusData.statusTime}
                        </span>
                      </div>
                    </div>
                  </>
                }
                {(patientSatusData.patientStatus === "discharged") &&
                  <>
                    <div className={`col-md-6 ${style.col_spec}`}>
                      <label className={`col-6 col-form-label ${style.label_spec}`}>{PATIENT_STATUS_LBL}</label>
                      <div className="col-6 p-0 ">
                        <span className={`col-form-label ${style.content_spec}`}>
                          {patientSatusData.patientStatusName}
                        </span>
                      </div>
                    </div>

                    <div className={`col-md-6 ${style.col_spec}`}>
                      <label className={`col-6 col-form-label ${style.label_spec}`}>{DISCHARGED_DATE_LBL}</label>
                      <div className="col-6 p-0">
                        <span className={`col-form-label ${style.content_spec}`}>
                          {patientSatusData.statusDate + ' ' + patientSatusData.statusTime}
                        </span>
                      </div>
                    </div>
                  </>
                }

              </div>
            </>
            :<>
            {editState?.errorMessage && <div className={`alert alert-danger ${style.form_alert}`}>{editState?.errorMessage}</div>}
            <Form formChanges={true} onSubmit={save} role="my-form">
              <div className={`p-0 ${style.patient_content} pt-3`}>
                <div className={`col-12  ${style.col_spec}`}>
                <SelectBox
                    classes={{
                      column: `col-8  ${style.col_spec}`,
                      labelClassName: `col-5  ${style.label_spec} ${style.custom_label_spec}`,
                      inputColumn: `col-6 p-0  ${style.select_wrapper}`,
                      inputClassName: `form-control  ${style.basic_select}`
                    }}
    
                    label={PATIENT_STATUS_LBL}
                    name={PATIENT_STATUS_ID}
                    id={PATIENT_STATUS_ID}
                    optionValue={patientOptions}
                    onChange={handleStatusChange}
                    placeholder="Select"
                    required={true}
                    customError={statusDateErr?"Select patient  status":""}
                  />
                </div>
                <div className={`col-12  ${style.col_spec}`}>
                  <DateTimePicker
                    id="statusDate"
                    name="statusDate"
                    label={dyntLbl}
                    timeName="statusTime"
                    timeID="statusTime"
                    control={control}
                    classes={{
                      date_picker: style.basic_input,
                      time_picker: `${style.basic_input}`,
                      dateColumn: `col-6 p-0`,
                      timeColumn: `col-2 p-0 pl-2`,
                      column: `col-8 ${style.col_spec}`,
                      labelClass: `col-5  ${style.label_spec}`
                    }}
                    defaultValue={new Date()}
                    defaultTime={new Date()}
                    displayIcon={true}
                    timeRequired={true}
                    showTimeInput={true}
                    dateRef={statusRef}
                    timeFormat="h:mm aa"
                    errors={errors}
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="text-center" >

                  {
                    offlineData && offlineData.offline === 1 ?
                      <>
                        <span className={`mr-2 ${style.info_icon} openInfo`} onClick={() => openInfoModal()}><img src={infoIcon} alt="info" /></span>
                        <Button
                          type={"button"}
                          value={"Save"}
                          classes={{ className: `btn mr-4   ${style.btn_custom_disable}` }}
                        />
                      </>
                      :
                      <Button
                        type={"submit"}
                        value={"Save"}
                        classes={{ className: `btn ${style.btn_small} ${style.btn_custom}` }}
                      />
                  }


                  <Button
                    type={"button"}
                    value={"Cancel"}
                    classes={{ className: `ml-3 btn ${style.btn_custom} ${style.btn_small_cancel}` }}
                    onClick={handleCancel}
                  />
                </div>
              </div>
            </Form>
            </>
          }
        </div>
      </div></>}
      {
        confirmationModal && <Confirmation
          onClose={() => modalClose()}
          title={" "}
        >
          <div className="text-center pr-5 pl-5">
            <p className="pb-3">This would discharge the Patient. Do you wish to Continue?</p>
            <div className="mb-2">
              <Button
                value="Yes"
                classes={{ className: `btn mr-3 pb-2 pt-2 ${style.yes_btn}` }}
                onClick={() => yesConfirm()}
              />
              <Button
                value="No"
                classes={{ className: `btn  ml-2 pb-2 pt-2 ${style.no_btn}` }}
                onClick={() => noConfirm()}
              />

            </div>
          </div>
        </Confirmation>
      }
      {
        infoModal && <Confirmation
          onClose={() => closeInfoConfirm()}
          title={" "}
          customSize={style.customSize}
        >
          <p className="pr-2 pl-2 pb-3">This patient is locked because another clinician
            <strong> {(offlineData && offlineData.addedBy) ? offlineData.addedBy : ''}</strong> added
            this patient to  their offline list. Please have clinican
            <strong> {(offlineData && offlineData.addedBy) ? offlineData.addedBy : ''}</strong> remove
            this patient from their offline list
          </p>
          <div className="text-center pr-5 pl-5">
            <div className="mb-2">

              <Button
                value="Close"
                classes={{ className: `btn mr-2 pb-2 pt-2 ${style.yes_btn} closeInfo` }}
                onClick={() => closeInfoConfirm()}
              />
            </div>
          </div>
        </Confirmation>
      }
    </div>
  )
}
Status.propTypes = {
  heading: PropTypes.object,
  patientData: PropTypes.object
}
