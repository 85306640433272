import React, { useState, useEffect } from 'react'
import { UserBasicInfo, UserSettings } from '../../../components/core/Users';
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { withRouter, useParams } from 'react-router-dom'
import { DetailView } from '../../../components';
import { CustListView } from '../../../components/secondary/ListView';
// import { CustListView } from '../../../components/secondary/ListView';
import format from 'date-fns/format';
import { LIST_VIEW_LIMIT, USER,CUSTOMERONE,SUCCESS_STATUS,CUSTOMER,CHECK_CUSTOMER_NUMBER } from '../../../constants';
import {
    getCallBackListAsync,
    resetUsers,
    userDetailsAsync,
    userUpdateAsync,
    setDetailViewData,
    getUserRoleAsync,
    getUsersCallBackListAsync
} from '../../../app/userSlice'
// import { getUsersCallBackListAsync } from '../../../app/customerSlice';
import { List, Search, Sorting, Pagination, Delete, SelectedRow } from '../../../utils';
import { ConsoleView } from 'react-device-detect';

import Loader from '../../../static/images/spinner.svg';
export let UsersList = ({ dispatch, history, location,customerData }) => {
    const accessError = useSelector(state => state.app.accessError)
    let userDetails = useSelector(state => state.user);
    let customerNumber = 0;
    if (userDetails) {
        customerNumber = userDetails.customerNumber
    }
    const [offSet, setOffSet] = useState(0)
    let userList = useSelector(state => state.user.users);
    /* Get state with the Individual customer data */
    const userData = useSelector(state => state.user.detailViewData)
    /* update user data */
    const updateUserData = (data) => dispatch(userUpdateAsync(data))
    /* Tabs object */
    const tabList = [
        {
            name: 'Basic Info',
            content: <UserBasicInfo userData={userData} facilityOptions={''} updateUserData={updateUserData} />
        }
        , {
            name: 'Settings',
            content: <UserSettings userData={userData} updateUserData={updateUserData} />
        }

    ];

    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);

    const [searchKey, setSearchKey] = useState('')
    const [sortType, setSortType] = useState('')
    /* Get User Details */
    const getUserList = () => {
        if(customerData?.id){
            List(`${CUSTOMERONE}/${customerData?.id}${USER}`, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getUsersCallBackListAsync);
        }
  }

    useEffect(() => {
        setSortType('')
        setSearchKey('')
        dispatch(resetUsers())
        getUserList();
    }, [dispatch]);

    const showFixedColumn = !!userList.data && !!userList.data?.users.length
    let columns = [
        {
            Header: 'Customer #',
            accessor: 'customerNumber',
            fixedColumn: showFixedColumn,
            // customWidth: "100px",
            fixedWidth: '200px'
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
            fixedColumn: showFixedColumn,
            customWidth: "170px"
            // fixedWidth: '230px'
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            fixedColumn: showFixedColumn,
            customWidth: "170px"
            // fixedWidth: '235px'
        },
        {
            Header: 'Email',
            accessor: 'email',
            restWidth: "170px"
        },
        {
            Header: 'Status',
            accessor: 'userStatus',
            restWidth: "120px"
        },
        {
            Header: 'Last Login',
            accessor: 'lastLogin',
            restWidth: "170px"
        },

        {
            Header: 'Phone Number',
            accessor: 'phone',
            restWidth: "170px"
        },
        {
            Header: 'Role',
            accessor: 'role',
            restWidth: "200px"
        }
    ];

    /* Check url for USER id */
    const { userId } = useParams()

   

    const [detailsRowData, setDetailsRowData] = useState([])
    const getSelectedRowData = (data) => {
        SelectedRow(data, setDetailsRowData, 'users', history,
        isAdminView
         );
    }
    const handleBack = () => {
    }
    // search list
    const getSearchValue = (event) => {   
        if(event.target.value==''){
            getUserList();
        }     
        if (event && event.keyCode === 13) {
            if (event && event.target.value !== '') {
                let custNum =  event.target.value;
                setOffSet(0)
                setActivePage(1)
                setSearchKey(event.target.value)
                if(customerData?.id){
                    Search(event, `${CUSTOMERONE}/${customerData.id}${USER}`, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getUsersCallBackListAsync,sortType) 
                }   
              } 
              else {
                setOffSet(0)
                setActivePage(1)
                getUserList();
                setSearchKey('')
                setSortType('')
                
            }
        }
    }

    // pagination
    const [activePage, setActivePage] = useState(1);
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        Pagination(data, setActivePage, USER, 'Get', LIST_VIEW_LIMIT, dispatch, getUsersCallBackListAsync, searchKey,'',sortType)
    }
    // sorting
    let sortingPayLoad = Sorting(`${CUSTOMERONE}/${customerData?.id}${USER}`, 'Get', LIST_VIEW_LIMIT, offSet, getUsersCallBackListAsync, searchKey,sortType,setSortType)
    const [tinyDelay, setTinyDelay] = useState(false)
    useEffect(() => {
      setTimeout(() => {
        setTinyDelay(true)
      }, 2500)
    }, [])
    
    return (<>
        {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
        <div>
            {!userId &&customerData?.id&& <CustListView
                columns={columns}
                data={userList.data && userList.data?.users}
                metaData={userList.data && userList.data?.metadata}
                sorting={true}
                getSelectedRowData={getSelectedRowData}
                detailView={true}
                searchPlaceholder={"First Name, Last Name, Email, Phone number, Role, Status"}
                getSearchValue={(e) => getSearchValue(e)}
                listSorting={sortingPayLoad}
                showFixedColumn={showFixedColumn}
                searchKey={(searchKey) ? searchKey : ''}
                getUserList={getUserList}
                customerNumberId = {customerData?.id}
                accessError={accessError}
                sortType={sortType?sortType:''}
            />}
            {userId && <DetailView
                detailsRowData={detailsRowData}
                tabList={tabList}
                defaultIndex={0}
                detailsRow={userId}
                handleBack={handleBack}
                backLink='/admin/users'
            />}
        </div>}
        </>
    )
}

UsersList.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

UsersList = connect()(withRouter(UsersList));
