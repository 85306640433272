import React, { useEffect, useState } from 'react'
import { AccessErrorModal, Button } from '../../primary'
import { linkAssessmentUpdateAsync, setHistoryCauseFormData, woundAssessmentUpdateAsync, patientAssessmentAsyncOverride, characteristicsImagesAsync} from '../../../app/patientSlice'
import editIcon from '../../../static/images/edit-icon.png'
import { HistoricalView } from './historicalView'
import { CharacteristicHistoryEdit } from './characteristicHistoryEdit'
import { CloseWarning } from '../../secondary'
import { useForm } from 'react-hook-form'
import { useSelector, shallowEqual } from 'react-redux'
import { allNullCheck, signedLockedAccess } from '../../../utils'
import Loader from '../../../static/images/spinner.svg';

export function CharacteristicHistory({ index, location, patientId, updateDone, assessmentId, dispatch, selectedIndex, styles, assessmentDetails,
    getCharDetails, hideIt, overrideToggle }) {
    const charStatus = useSelector(state => state.patient.charStatus)

    /* State for start button */
    const [fresh, setFresh] = useState(true)
    // Edit state
    const [editable, setEditable] = useState(false)
    // Handle Save (state for moving from historical view to history cause page)
    const [save, setSave] = useState(false)
    /* State for catgory */
    const [category, setCategory] = useState()
    /* Selected cause */
    const [selectedCause, setSelectedCause] = useState()
    /* Warning state for closing modal */
    const [closeWarning, setCloseWarning] = useState(false)
    /* State to display hide history view */
    const [historyState, setHistoryState] = useState(false)
    /* State to handle current or new wound info from history view */
    const [newWound, setNewWound] = useState(false)
    const [currentWound, setCurrentWound] = useState(false)
    /* Current wound id (we get this id when user clicks 'same as current wound') */
    const [currentId, setCurrentId] = useState(null)
    /*Error check HistCause*/
    const [errChkHistCause, setErrChkHistCause] = useState(false)
    // Getting data 
    const historyCauseFormData = useSelector(state => state.patient.historyCauseFormData, shallowEqual);
    const historyCause = useSelector(state => state.patient.historyCause);
    const piHistory = useSelector(state => state.patient.piHistory);
    const woundAge = useSelector(state => state.patient.woundAge);
    const photoType = useSelector(state => state.patient.photoType);
    const documentProvidedBy = useSelector(state => state.patient.documentProvidedBy);

    const { register, errors, handleSubmit, watch } = useForm({
        defaultValues: historyCauseFormData
    })

    useEffect(() => {
        dispatch(setHistoryCauseFormData({ cause: '' }))
    }, [location])

    /* Warning functions Yes and no */
    const handleYes = () => {
        setHistoryState(false)
        setEditable(false)
        setCloseWarning(false)
        //setEditable(false)
        setSave(false)
        dispatch(setHistoryCauseFormData({ cause: '' }))
    }
    /* No fn */
    const handleNo = () => {
        setCloseWarning(false)
    }

    // On click start 
    const handleStart = () => {
         setHistoryState(true)
        setEditable(false)
        //setEditable(true)
    }

    // Next screen historical view 
    const handleNext = () => {
        setHistoryState(true)
        setEditable(false)
    }

    // We should not show 'historical view' when user revisits the history/cause.
    // So I am checking wheather newWound has value or not. If there is value, then I am assuming 
    // user is editing 2nd time. If newWound don't have value, it is first time.

    useEffect(() => {
        if(!newWound && !currentWound){
            if (assessmentDetails?.historyCause?.newWound) {
                setSave(true)
            }else{
                setSave(false)
            }
        }
    }, [assessmentDetails, editable])


    //  call the API for Update the data
    useEffect(()=>{
        let payload = { patientId, assessmentId, type: 'tissuetypes'} 
        dispatch(patientAssessmentAsyncOverride(payload))  
    },[dispatch])

    const handleSave = (data, reset) => {
       
        if((category)&&(selectedCause)){
            setErrChkHistCause(false)
        let documentProvidedByPost= data?.documentProvidedBy ? data?.documentProvidedBy : assessmentDetails?.historyCause?.documentProvidedBy?assessmentDetails?.historyCause?.documentProvidedBy.replace(/\s/g, '').toLowerCase():assessmentDetails?.historyCause?.documentProvidedBy;
        if(data?.documentProvidedBy){
            if(data?.documentProvidedBy&&data?.documentProvidedBy.replace(/\s/g, '').toLowerCase()==='other'&&data?.documentProvidedByOther){
                documentProvidedByPost=data?.documentProvidedByOther
            }
        }
        if(data?.documentProvidedBy===''){
            documentProvidedByPost=''
        }        
        function navigate() {
           
            setSave(false)
            updateDone(index)
            if(Array.isArray(charStatus)&&charStatus[index]===1){
                setEditable(false)
            }else{
                setSelectedCause('')
                setCategory('')
                selectedIndex(index + 1)
            }
        }
        //update history cause options details updateHistoryCauseDetailsOption
        if(data?.otherNamecause){
            let chckExitOtheropt=false
            historyCause && historyCause.length > 0 && historyCause.map((histDataOpt) => {
              if(histDataOpt.key==='other'){
                     return chckExitOtheropt =Array.isArray(histDataOpt.options)&&histDataOpt.options.some((val) => val.key.toLowerCase() === data?.otherNamecause.toLowerCase())
              }
            })
            if(!chckExitOtheropt){
                const payloadOptUpdate = {
                    ne1ID:patientId,
                    assessmentId:assessmentId,
                    historyCauseType: "other",
                    historyCauseTypeValue: data?.otherNamecause,
                    KeyUpdated: null 
                    }
            }
        }
     
     //str.replace(/\s/g, '').toLowerCase()
     
     
        // Temp assessment with submitted data
        const tempAssessment = {
            historyCause: {
                cause: selectedCause ? selectedCause : assessmentDetails?.historyCause?.cause,
                causeCategory: category ? category : assessmentDetails?.historyCause?.causeCategory,
                woundAge: data?.woundAge ? data?.woundAge.replace(/\s/g, '').toLowerCase() : null,
                conditionPOA: data?.conditionPOA ? data?.conditionPOA === 'Yes' ? 1 : 0 : assessmentDetails?.historyCause?.conditionPOA,
                photoType: data?.photoType ? data?.photoType.replace(/\s/g, '').toLowerCase() : assessmentDetails?.historyCause?.photoType?assessmentDetails?.historyCause?.photoType.replace(/\s/g, '').toLowerCase():assessmentDetails?.historyCause?.photoType,
                documentProvidedBy: documentProvidedByPost?documentProvidedByPost.replace(/\s/g, '').toLowerCase() : null,
                piHistory: pi ? data?.piHistory ? data?.piHistory.replace(/\s/g, '').toLowerCase() : null : null,
                newWound: save ? 1 : assessmentDetails?.historyCause?.newWound
            }
        }
        // Merging our temp assessment with assessment details and dispatching
        const assessment = { ...assessmentDetails, ...tempAssessment }
        const payload = {
            patientId,
            assessmentId,
            assessment,
            navigate,
            getCharDetails,
            reset,
            overrideenable : overrideToggle ? true : false
        }
        // if users clicks 'same as current wound' the below linking API call will get triggerd 
        if (currentWound) {
            const linkPayload = {
                patientId,
                assessmentId,
                currentId
            }
            /* Linking assessment when user clicks 'same as current wound' */
            dispatch(linkAssessmentUpdateAsync(linkPayload))
        }
        // Saving history cause data 
        dispatch(woundAssessmentUpdateAsync(payload))
        }else{
            setErrChkHistCause(true)
        }

    }

    /* Pressure Injury PI */
    const [pi, setPi] = useState()
    /* POA or Non POA */
    const [poa, setPoa] = useState(false)

    /* POA for form handling */
    const [formPoa, setFormPoa] = useState(false)

    /* PDocumentation provided by: other */
    const [otherDocType, setOtherDocType] = useState(false)
    const [otherDocTypeVal, setOtherDocTypeVal] = useState('')
    const [viewDocType, setViewDocType] = useState('')
    const [viewDocTypeSelect, setViewDocTypeSelect] = useState('')

    useEffect(() => {
        let docView=documentProvidedBy && documentProvidedBy.length > 0 && documentProvidedBy.map((data) => {
            if (assessmentDetails?.historyCause?.documentProvidedBy&&assessmentDetails?.historyCause?.documentProvidedBy.replace(/\s/g, '').toLowerCase() === data.key) {
                return data.value
            }
        })
        docView = docView && Object.values(docView).filter(function (el) {
            return el != null;
          });
        if(docView){
            let docViews=docView;
            setViewDocType(docViews)
            setViewDocTypeSelect(Array.isArray(docViews)&&docViews.length>0?docViews[0].replace(/\s/g, '').toLowerCase():'')
        }
        if(docView.length===0){
            setViewDocType(`Other - ${assessmentDetails?.historyCause?.documentProvidedBy}`)
            if(assessmentDetails?.historyCause?.documentProvidedBy)
            setViewDocTypeSelect('other')
        }
        const chkDfalDoc=Array.isArray(documentProvidedBy)&&documentProvidedBy.some((val) => ((assessmentDetails?.historyCause?.documentProvidedBy)&&(val.key === assessmentDetails?.historyCause?.documentProvidedBy.replace(/\s/g, '').toLowerCase())))
        if (assessmentDetails?.historyCause?.documentProvidedBy&&assessmentDetails?.historyCause?.documentProvidedBy.replace(/\s/g, '').toLowerCase() === 'other' || !chkDfalDoc) {
            if(assessmentDetails?.historyCause&&assessmentDetails?.historyCause?.documentProvidedBy){
                setOtherDocType(true)
                setOtherDocTypeVal(assessmentDetails?.historyCause?.documentProvidedBy)
            }
        } 
        // Set fresh form 
        if (assessmentDetails?.historyCause?.newWound) {
            setFresh(false)
        } else {
            setFresh(true)
        }
        // Setting PI
        if (assessmentDetails?.historyCause?.causeCategory === 'pi') {
            setPi(true)
        } else {
            setPi(false)
        }
        // Setting POA
        if (assessmentDetails?.woundAcquired === 'POA') {
            setPoa(true)
            setFormPoa(false)
        } else {
            setPoa(false)
            setFormPoa(true)
        }
        if (assessmentDetails?.historyCause?.conditionPOA === 1 && assessmentDetails?.woundAcquired === 'POA') {
            setPoa(false)
        }
    }, [assessmentDetails])

    return (
        <div className={`${styles.mesurement_blk} pt-1 pb-0`}>

            <AccessErrorModal />

            {assessmentDetails === null ? 
            
            <div className={`${styles.main_loader}`}>
                <img src={Loader} alt="loader" />
            </div>
            
            :

            <>
                {/* New  */}

                {fresh && !editable && !historyState && <div>
                    {/* If there aren't any data saved already, show below with start button */}
                    {/* Display history cause  */}
                    <p>
                        <label>History/Cause:</label>
                        <span>
                            {assessmentDetails?.historyCause?.causeCategory!=='other'&&historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                let value;
                                if (data?.options?.length > 0) {
                                    data.options.map((sub) => {
                                        if (sub.key=== assessmentDetails?.historyCause?.cause.toLowerCase()) {
                                            value = `${data.value}-${sub.value}`
                                            value=`${assessmentDetails?.historyCause?.causeCategory==='unabletodetermine'?data.value:`${data.value}-${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (data.key === assessmentDetails?.historyCause?.cause.toLowerCase()) {
                                        value = data.value
                                    }
                                }
                                return value;
                            })}
                            
                            {assessmentDetails?.historyCause?.causeCategory==='other'&&historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                let value;
                                if (data?.options?.length > 0 ) {
                                    data?.options?.map((sub) => {
                                        if (sub.key === assessmentDetails?.historyCause?.cause) {
                                            value=`${assessmentDetails?.historyCause?.causeCategory==='unabletodetermine'?data.value:`${data.value}-${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (data.key === assessmentDetails?.historyCause?.cause) {
                                        value = data.value
                                    }
                                }
                                return value;
                            })}
                            
                           {assessmentDetails?.historyCause?.causeCategory==='others'&&`${'Other'}-${assessmentDetails?.historyCause?.cause}`}
                        </span>
                    </p>
                    {/* Button to start  */}
                    {hideIt && <p>
                        <Button
                            type="button"
                            value="Start"
                            classes={{ className: `btn btn-small ${styles.btn_small} ${styles.btn_custom}` }}
                            id="startHistroy"
                            onClick={() => handleStart()}
                        />
                    </p>}
                </div>}

                {/* Already saved */}


                {!editable ?

                    // Readable field
                    // 'fresh' - if the data is already saved, we just need to display. 
                    !fresh && !historyState && <div>
                        {/* Edit button  */}
                        {signedLockedAccess(assessmentDetails?.assessmentStage) && hideIt && <div className={`float-sm-right ${styles.tab_edit}`} onClick={() => setEditable(true)}>
                            <img src={editIcon} alt="edit" />Edit
                        </div>}

                        <div>
                            {/* History cause */}
                            <p><label>History/Cause:</label><span>{assessmentDetails?.historyCause?.causeCategory!=='other'&&historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                let value;
                                if (data?.options?.length > 0) {
                                    data.options.map((sub) => {
                                        if (sub.key === assessmentDetails?.historyCause?.cause.toLowerCase()) {
                                            value=`${assessmentDetails?.historyCause?.causeCategory==='unabletodetermine'?data.value:`${data.value}-${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (data.key === assessmentDetails?.historyCause?.cause.toLowerCase()) {
                                        value = data.value
                                    }
                                }
                                return value;
                            })}
                                
                                
                            {assessmentDetails?.historyCause?.causeCategory==='other'&&historyCause && historyCause.length > 0 && historyCause.map((data) => {
                                let value;
                                if (data?.options?.length > 0 ) {
                                    data?.options?.map((sub) => {
                                        if (sub.key === assessmentDetails?.historyCause?.cause) {
                                            value=`${assessmentDetails?.historyCause?.causeCategory==='unabletodetermine'?data.value:`${data.value}-${sub.value}`}`
                                        }
                                    })
                                }
                                else {
                                    if (data.key === assessmentDetails?.historyCause?.cause) {
                                        value = data.value
                                    }
                                }
                                return value;
                            })}
                            
                             {assessmentDetails?.historyCause?.causeCategory==='others'&&`${'Other'}-${assessmentDetails?.historyCause?.cause}`}
                            </span></p>

                            {/* Wound age */}
                            {allNullCheck(assessmentDetails?.historyCause?.woundAge) ?
                                <p><label>Wound Age:</label><span>
                                    {woundAge && woundAge.length > 0 && woundAge.map((data) => {
                                        if (assessmentDetails?.historyCause?.woundAge === data.key) {
                                            return data.value
                                        }
                                    })}
                                </span></p>
                                : ''}


                            {/* If non-POA */}
                            {!poa && <>
                                {/* Condition POA */}
                                <p><label> Condition present on admission?</label><span>{assessmentDetails?.historyCause?.conditionPOA === 1 ? 'Yes' : 'No'}</span></p>


                                {assessmentDetails?.historyCause?.photoType && <>
                                    {/* Photo Type */}
                                    <p><label>Photo Type:</label><span>
                                        {photoType && photoType.length > 0 && photoType.map((data) => {
                                            if (assessmentDetails?.historyCause?.photoType === data.key) {
                                                return data.value
                                            }
                                        })}
                                    </span></p></>}

                                {assessmentDetails?.historyCause?.documentProvidedBy && <>
                                    {/* Dcoument */}
                                    <p><label>Documentation type:</label><span>
                                        {
                                            viewDocType
                                            }
                                    </span></p></>}

                            </>}

                            {/* If PI is true  */}
                            {pi && <div>
                                {/* Does the patient has PI? */}
                                {/* <p><label>Does the Patient has the history of Pressure Injury/Ulcer for the same wound?:</label><span>Yes</span></p> */}
                                {/* PI history */}
                                {allNullCheck(assessmentDetails?.historyCause?.piHistory) ? <p><label>Does the patient has a past history of Pressure Injury/Ulcer for the same wound?</label><span>
                                    {piHistory && piHistory.length > 0 && piHistory.map((data) => {
                                        if (assessmentDetails?.historyCause?.piHistory === data.key) {
                                            return data.value
                                        }
                                    })}
                                </span></p>
                                    :
                                    ""}
                            </div>}

                        </div>
                    </div>

                    :

                    <>

                        {/* On close warning */}
                        {closeWarning && <CloseWarning
                            message="All progress in this section will be lost. Are you sure want to cancel?"
                            yesFn={handleYes}
                            noFn={handleNo}
                        />
                        }

                        {/* Edit history/cause component  */}
                        <CharacteristicHistoryEdit
                            poa={poa}
                            pi={pi}
                            setPi={setPi}
                            styles={styles}
                            save={save}
                            piHistory={piHistory}
                            setCategory={setCategory}
                            category={category}
                            setSelectedCause={setSelectedCause}
                            selectedCause={selectedCause}
                            historyCause={historyCause}
                            errChkHistCause={errChkHistCause}
                            woundAge={woundAge}
                            photoType={photoType}
                            documentProvidedBy={documentProvidedBy}
                            handleNext={handleNext}
                            handleSave={handleSave}
                            setCloseWarning={setCloseWarning}
                            formPoa={formPoa}
                            //handleFormPoa={handleFormPoa}
                            setFormPoa={setFormPoa}
                            assessmentDetails={assessmentDetails}
                            dispatch={dispatch}
                            register={register}
                            errors={errors}
                            handleSubmit={handleSubmit}
                            watch={watch}
                            historyCauseFormData={historyCauseFormData}
                            hideIt={hideIt}
                            setOtherDocType={setOtherDocType}
                            otherDocType={otherDocType}
                            otherDocTypeVal={otherDocTypeVal}
                            viewDocTypeSelect={viewDocTypeSelect}
                            setViewDocTypeSelect={setViewDocTypeSelect}
                        />

                    </>

                }

                {/* Local historical view componet */}
                {historyState&&hideIt  && <HistoricalView
                    modalOpen={true}
                    setHistoryState={setHistoryState}
                    setSave={setSave}
                    setNewWound={setNewWound}
                    setCurrentWound={setCurrentWound}
                    setEditable={setEditable}
                    setCurrentId={setCurrentId}
                />}
            </>}

        </div>
    )
}
