import React, { useEffect } from "react";
import cancel from "./../../../static/icons/cancel.svg";
import warning from "../../../static/images/rbac_auth.svg";
import {
  main_content,
  opps_txt,
  opps_msg,
  opps_btn,
} from "./unAuthorizedPage.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setAccessError } from "../../../app/appSlice";
import { Header } from '../../secondary/Header';
export const UnAuthorizedPage = () => {
  const dispatch = useDispatch();
  const accessError = useSelector((state) => state.app.accessError);
  const defaultPageClick = () => {
    localStorage.removeItem('badReqMsg')
    dispatch(setAccessError(null))
    window.location='/'
  }
  useEffect(() => {
      dispatch(setAccessError(null))
  }, []);
  return (
    <>
      <Header/>
      <div className={main_content}>
        <img src={warning} alt="Warning" />
        <div className={`${opps_txt}`}>Oops…</div>
        <div className={`${opps_msg}`}>{(localStorage.getItem('badReqMsg'))?localStorage.getItem('badReqMsg'):'You are not authorized!'}</div>
        <button  data-testid="close" onClick={defaultPageClick} className={`${opps_btn} defaultPageClick`}>
                  Home
                </button>
      </div>
    </>
  );
};
