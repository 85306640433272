import React,{useState, useEffect} from 'react';
import style from './assessmentNotes.module.scss';
import { Button, Form, TextArea } from '../../primary';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { CANCEL_BTN, SAVE_BTN } from '../../../constants';
import { fullMonthFormatDate, signedLockedAccess } from '../../../utils';
import {allowEnterData} from '../../../utils/patientsHelper';
import Loader from '../../../static/images/spinner.svg';
export const AssessmentNotes = ({ notes, postNotes, detailAssessment, hideIt }) => {
  const { patientId } = useParams();
  const { assessmentId } = useParams();
  const { register, handleSubmit, reset } = useForm();
  const clearField = () => {
    reset();
  }
  const doSubmit = (data) => {
    if (data?.notes.trim() !== '') {
      const payload = {
        "assessmentId": assessmentId,
        "patientId": patientId,
        "notes": data.notes.trim()
      }
      postNotes(payload);
    }
    reset();
  }
  const allowEnter = (e) => {
    allowEnterData(e)
    // if (e.key === 'Enter') {
    //   e.stopPropagation();
    // }
  }


  // <-----ZoomIn/Out OPtion----->
const [initalState, setInitalState] = useState(0);
const [zoomClick, setZoomClick] = useState(false);
    
    const zoomInAction = (direction) => {
        if(initalState <= 8){
            setZoomClick(true)
            setInitalState(initalState + 1) 
        }
        else{
            setZoomClick(true)
            setInitalState(9)
        }

        // const delta = 10 * direction;
        // const element = document.getElementById('patient_wound');
        // const positionInfo = element.getBoundingClientRect();
        // element.style.width = positionInfo.width+delta+'px';
        // element.style.height = positionInfo.height+(delta-1)+'px';
    }


    const zoomOutAction = (direction) => {
        if(initalState >= 1 && initalState <= 9) {
            setZoomClick(true)
            setInitalState(initalState - 1)
        }
        
        if(initalState === 0){
            setZoomClick(true)
            setInitalState(0)
        } 
        if (initalState < 0){
            setZoomClick(true)
            setInitalState(0)
        }     
    }    

    const reSizeAction = () => {
        setZoomClick(true)
        setInitalState(0) 
    }

    useEffect(()=>{
        if(initalState === 0){
            setZoomClick(true)
            setInitalState(0)
        } 
    },[initalState])
// <-----ZoomIn/Out OPtion----->

const [tinyDelay, setTinyDelay] = useState(false)

useEffect(() => {
  setTimeout(() => {
    setTinyDelay(true)
  }, 2500)
}, [])
  
  return <div>
{!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
        <>
    <div className="row d-flex d-flex row no-gutters">

      <div className={`col-4`} style={{overflow : 'hidden'}}>
        <div  className={`${style.patient_wound}`}>
          {/* <img src={detailAssessment?.measurement?.assessedPicture || detailAssessment?.picture} alt="annotate image" className={style.image_siz} /> */}
             <img 
              src={detailAssessment?.measurement?.assessedPicture || detailAssessment?.picture} 
              alt="annotate image" 
              className={style.image_siz}
              id="patient_wound" 
              style={Object.assign({},
                  zoomClick === true && {transform : `scale(1.${initalState}, 1.${initalState})`})}
            />
        </div>
        {detailAssessment?.isWoundWithoutPhoto === 0  &&
          <div className={`${style.iconListEdit} d-flex align-items-center justify-content-around`}>
                <div className='pl-4'>
                    <i class={`${style.iconHover} fa fa-search-plus`} style={{color : '#fff', fontSize:'18px'}} onClick={zoomInAction} aria-hidden="true"></i>
                </div>
                <div className=''>
                    <i class={`${style.iconHover} fa fa-search-minus`} style={{color : '#fff', fontSize:'18px'}} onClick={zoomOutAction} aria-hidden="true"></i>
                </div>
                <div className='pr-5'>
                    <i class={`${style.iconHover} fa fa-arrows-alt`} style={{transform :`rotate(45deg) scale(1.1)`,color : '#fff', fontSize:'18px'}} onClick={reSizeAction} aria-hidden="true"></i>
                </div>
          </div>
          }
      </div>

      <div className={`col w-100`}>
        <div className={`${style.notes_conatiner} w-100`}>
          {hideIt && signedLockedAccess(detailAssessment?.assessmentStage)? <div>
            <div className="pt-2 pb-2"><span className={style.notes_title}>Notes:</span> <span className={style.notes_sub_title}>(Optional)</span></div>
            <Form  onSubmit={handleSubmit(doSubmit)}>
              <div>
                <TextArea
                  classes={{ inputClassName: `${style.textarea_box} w-100 mb-2` }}
                  rows={3}
                  id={"notesField"}
                  name={"notes"}
                  placeholder={'Add notes here'}
                  validator={register}
                  onKeyPress={(e) => allowEnter(e)}
                />
              </div>
              <div className="d-flex justify-content-end w-100">
                <Button
                  type={"submit"}
                  value={SAVE_BTN}
                  id={"updateNotes"}
                  classes={{ className: `btn p-1 ${style.btn_primary}` }}
                />
                <Button
                  type={"button"}
                  value={'Clear'}
                  id={"updateCancel"}
                  classes={{ className: `btn p-1 ${style.btn_scondary}` }}
                  onClick={() => clearField()}
                />
              </div>
            </Form>
          </div>:'This wound assessment is signed and locked, cannot be updated.'}
          {notes&&Array.isArray(notes)&&notes?.length !== 0 && <div className={style.notes_container}>
            {notes&&Array.isArray(notes) && notes.map((data, index) => {
              return <div className={style.entered_notes} key={index}>
                  <div className={style.post_details}>
                    <span className={style.creater_name}>{data.createdBy}</span> | <span>{fullMonthFormatDate(data.createdDate)}</span> | <span>{data.createdByRole}</span>
                  </div>
                  <div className={style.notes_text}>{data?.notes === '' ? 'Unknown' : data?.notes?.split('\n').map(str => <div>{str}</div>)}</div>
                </div> 
            })}
          </div>}
        </div>
      </div>

    </div></>}
  </div>
}
