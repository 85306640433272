import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import logo from '../../../static/images/skin-health-logo.png';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Loader from '../../../static/images/spinner.svg';
import { Button } from '../../primary';
import { Header } from '../../secondary/Header';
import styles from "./platformAnalytics.module.scss";
import { useLocation } from "react-router-dom";
import { allNullCheck } from '../../../utils';
import { handleClickMove } from '../../../utils/platformHelper';

export function PlatformAnalyticsPdf({ props, history }) {

    const location = useLocation();

    const platformOverview = location.state;

    // For scrolling the page to top
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Getting loader 
    const loading = useSelector(state => state.app.loading);
    const customerName = useSelector(state => state.user.customerName);

    // States
    const [canvases, setCanvases] = useState([])
    const [header, setHeader] = useState({})
    const [started, setStarted] = useState(false) // To avoid multiple html2canvas promises
    const [pdfGeneration, setPdfGeneration] = useState(false) // State for end of html2canvas process
    const [message, setMessage] = useState(false)


    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                setMessage(true)
            }, 10000)
        }
    }, [loading])

    // Getting header, finding the size, converting the code and pushing it into a state
    useEffect(() => {
        async function run() {
            const header = document.getElementById('header')
            const imageWidth = 210;
            await html2canvas(header).then(function (canvas) {
                setHeader({ height: canvas.height * imageWidth / canvas.width, imgData: canvas.toDataURL('image/png') })
            })
        }
        // We are starting above canvasing process after loading the html file with a delay
        if (!loading) {
            if (!started) {
                setTimeout(() => {
                    run();
                }, 5000)
                setStarted(true)
            }
            else {
                return
            }
        }
    }, [loading])


    // Getting all the elements with the tag name 'article'. Capturing screenshot, finding height and converting it into base code.
    // Pushing all those into an array of object
    useEffect(() => {
        async function run() {
            const allDivs = document.getElementsByTagName('article')
            const divsArray = Array.from(allDivs)
            const can = []
            const imageWidth = 210;
            // Creating screenshot for each section
            for (let i = 0; i < divsArray.length; i++) {
                await html2canvas(divsArray[i], { allowTaint: true, useCORS: true }).then(function (canvas) {
                    can.push({ id: i, height: canvas.height * imageWidth / canvas.width, imgData: canvas.toDataURL('image/png') })
                    setCanvases([...can])
                }).finally(() => {
                    if (i === divsArray.length - 1) {
                        setPdfGeneration(true)
                    }
                })
            }
        }
        // We are starting above canvasing process after loading the html file with a delay
        if (!loading) {
            if (!started) {
                setTimeout(() => {
                    run();
                }, 5000)
                setStarted(true)
            }
            else {
                return
            }
        }
    }, [loading])


    


    // On click, generating pdf by putting all the above images into a document. We are using jsPDF to generate pdf.
    // If we can't fit image into same page, we are moving the image into next page. It is done automatically.
    const handleClick = () => {
        handleClickMove(html2canvas, jsPDF, canvases, header, customerName)
    }


    return (
        <>
            <div className={`${styles.back_btn}`}>
                <Header backLink={'patients'} handleBack={() => history.goBack()} />
            </div>

            {!loading && <div className={styles.info_message}>
                {!pdfGeneration && <>
                    {!message && <h3>We are generating PDF. Please wait.</h3>}
                    {message && <h3>We are almost done. Few more seconds.</h3>}
                </>}
                {pdfGeneration && <>
                    {/* <h3>Your PDF is ready.</h3> */}
                    <Button
                        classes={{ className: `btn btn-small ${styles.btn_small} ${styles.btn_custom}` }}
                        type="submit"
                        id={"next"}
                        data-testid={"next"}
                        onClick={handleClick}
                    >
                        Download PDF
                    </Button>
                </>}
            </div>}

            {loading && <div className={styles.loader_fix}>
                <h3>We are gathering data. Please wait.</h3>
                <img src={Loader} alt="loading" />
            </div>}

            {!loading && <div id="pdf" className={styles.content}>

                <header id="header" className={`min ${styles.header}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-8">
                                <h1>Platform Analytics</h1>
                            </div>
                            <div className="col-4 text-right">
                                <img src={logo} alt="test" />
                            </div>
                        </div>
                    </div>
                </header>
                
                <article>
                    <div className={styles.title_top}>
                        <p className={styles.bi_title}>Support Info</p>
                    </div>

                    <div className={styles.platform_content}>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Clinical Support
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.supportInfo?.clinicalSupportInfo?.clinicalSupport?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.supportInfo?.clinicalSupportInfo?.clinicalSupport?.percent) ? platformOverview?.supportInfo?.clinicalSupportInfo?.clinicalSupport?.percent + '%' : 'Unknown'}
                                </div>
                            </div>
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    # of Tickets
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.supportInfo?.clinicalSupportInfo?.numberOfTickets?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.supportInfo?.clinicalSupportInfo?.numberOfTickets?.percent) ? platformOverview?.supportInfo?.clinicalSupportInfo?.numberOfTickets?.percent + '%' : 'Unknown'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Application Support
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.supportInfo?.applicationSupportInfo?.applicationSupport?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.supportInfo?.applicationSupportInfo?.applicationSupport?.percent) ? platformOverview?.supportInfo?.applicationSupportInfo?.applicationSupport?.percent + '%' : 'Unknown'}
                                </div>
                            </div>
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    # of Tickets
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.supportInfo?.applicationSupportInfo?.numberOfTickets?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.supportInfo?.applicationSupportInfo?.numberOfTickets?.percent) ? platformOverview?.supportInfo?.applicationSupportInfo?.numberOfTickets?.percent + '%' : 'Unknown'}
                                </div>
                            </div>

                        </div>
                    </div>
                </article>

                <article>
                    <div className={styles.title_top}>
                        <p className={styles.bi_title}>NE1 WMS (Paid customers)</p>
                    </div>

                    <div className={styles.platform_content}>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Acute
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.ne1wmsPaidCustomer?.acute?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.ne1wmsPaidCustomer?.acute?.percent) ? platformOverview?.ne1wmsPaidCustomer?.acute?.percent + '%' : 'Unknown'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Post Acute
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.ne1wmsPaidCustomer?.postAcute?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.ne1wmsPaidCustomer?.postAcute?.percent) ? platformOverview?.ne1wmsPaidCustomer?.postAcute?.percent + '%' : 'Unknown'}
                                </div>
                            </div>

                        </div>
                    </div>
                </article>

                <article>
                    <div className={styles.title_top}>
                        <p className={styles.bi_title}>Product Master</p>
                    </div>

                    <div className={styles.platform_content}>
                        <div className="col-12">
                            <h3 className={styles.pa_subtitle}>Advanced Skin Care</h3>
                        </div>
                        <div className="col-5">

                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Active
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.advancedSkinCare?.active?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.advancedSkinCare?.active?.percent) ? platformOverview?.productMaster?.advancedSkinCare?.active?.percent + '%': 'Unknown'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Inactive
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.advancedSkinCare?.inActive?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.advancedSkinCare?.inActive?.percent) ? platformOverview?.productMaster?.advancedSkinCare?.inActive?.percent + '%': 'Unknown'}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={`${styles.platform_content} pt-2`}>
                        <div className="col-12">
                            <h3 className={styles.pa_subtitle}>Advanced Wound Care</h3>
                        </div>
                        <div className="col-5">

                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Active
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.advancedWoundCare?.active?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.advancedWoundCare?.active?.percent) ? platformOverview?.productMaster?.advancedWoundCare?.active?.percent + '%': 'Unknown'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Inactive
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.advancedWoundCare?.inActive?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.advancedWoundCare?.inActive?.percent) ? platformOverview?.productMaster?.advancedWoundCare?.inActive?.percent + '%': 'Unknown'}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`${styles.platform_content} pt-2`}>
                        <div className="col-12">
                            <h3 className={styles.pa_subtitle}>Nutrition and Pharmaceuticals</h3>
                        </div>
                        <div className="col-5">

                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Active
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.nutritionandPharmaceuticals?.active?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.nutritionandPharmaceuticals?.active?.percent) ? platformOverview?.productMaster?.nutritionandPharmaceuticals?.active?.percent + '%': 'Unknown'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Inactive
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.nutritionandPharmaceuticals?.inActive?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.nutritionandPharmaceuticals?.inActive?.percent) ? platformOverview?.productMaster?.nutritionandPharmaceuticals?.inActive?.percent + '%': 'Unknown'}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={`${styles.platform_content} pt-2`}>
                        <div className="col-12">
                            <h3 className={styles.pa_subtitle}>Repositioning and Offloading</h3>
                        </div>
                        <div className="col-5">

                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Active
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.repositioningandOffloading?.active?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.repositioningandOffloading?.active?.percent) ? platformOverview?.productMaster?.repositioningandOffloading?.active?.percent + '%': 'Unknown'}
                                </div>
                            </div>

                        </div>
                        <div className="col-5">
                            <div className={styles.platform_support}>
                                <div className={styles.platform_support_left}>
                                    Inactive
                                </div>
                                <div className={styles.platform_support_right}>
                                    {platformOverview?.productMaster?.repositioningandOffloading?.inActive?.count}
                                </div>
                                <div className={styles.platform_support_right}>
                                    {allNullCheck(platformOverview?.productMaster?.repositioningandOffloading?.inActive?.percent) ? platformOverview?.productMaster?.repositioningandOffloading?.inActive?.percent + '%': 'Unknown'}
                                </div>
                            </div>

                        </div>
                    </div>
                </article>

                <article>
                    <div className={styles.overview_blk}>
                        <div className={styles.pa_score}>
                            <div className={`${styles.pa_percentage_pdf}`}>
                                <label className={styles.dash_top}>Acute Customers</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.acuteCustomers?.count}</span>
                                    <span>{allNullCheck(platformOverview?.acuteCustomers?.percent) ? platformOverview?.acuteCustomers?.percent + '%': '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage_pdf}`}>
                                <label className={styles.dash_top}>Post Acute Customers</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.postAcuteCustomers?.count}</span>
                                    <span>{allNullCheck(platformOverview?.postAcuteCustomers?.percent) ? platformOverview?.postAcuteCustomers?.percent + '%': '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage_pdf}`}>
                                <label className={styles.dash_top}>Most Active Customers</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.mostActiveCustomers?.count}</span>
                                    <span>{allNullCheck(platformOverview?.mostActiveCustomers?.percent) ? platformOverview?.mostActiveCustomers?.percent + '%': '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage_pdf}`}>
                                <label className={styles.dash_top}>NE1 WMS Paid Customers</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.ne1wmsPaidCustomers?.count}</span>
                                    <span>{allNullCheck(platformOverview?.ne1wmsPaidCustomers?.percent) ? platformOverview?.ne1wmsPaidCustomers?.percent + '%': '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage_pdf}`}>
                                <label className={styles.dash_top}>Customers with Formulary</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.customersWithFormulary?.count}</span>
                                    <span>{allNullCheck(platformOverview?.customersWithFormulary?.percent) ? platformOverview?.customersWithFormulary?.percent + '%': '-'}</span>
                                </p>
                            </div>
                            <div className={`${styles.pa_percentage_pdf}`}>
                                <label className={styles.dash_top}>Customers without Formulary</label>
                                <p className={styles.dash_btm}>
                                    <span>{platformOverview?.customersWithoutFormulary?.count}</span>
                                    <span>{allNullCheck(platformOverview?.customersWithoutFormulary?.percent) ? platformOverview?.customersWithoutFormulary?.percent + '%': '-'}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </article>
            


            </div>}
        </>
    )
}

PlatformAnalyticsPdf = connect()(withRouter(PlatformAnalyticsPdf));