import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getWorkListRecordsCount, setLastVal } from '../../../app/patientSlice';
import { VerticalTabWorkList } from '../../secondary/VerticalTabWorkList';
import { NewWoundsNONPOA } from './newWoundsNONPOA';
import { NewWoundsPOA } from './newWoundsPOA';
import style from './workListCommon.module.scss';

export function NewWounds({facilityNumber, history}) {
  useEffect(() => {
    if(facilityNumber){
        dispatch(getWorkListRecordsCount(facilityNumber,'newWoundsValues'));
    }
   }, [facilityNumber])
  const workListRecordsCount = useSelector(state => state.patient.workListRecordsCount);
  const poaCount=workListRecordsCount?.newWoundsValues?.poaCount?`(${workListRecordsCount?.newWoundsValues?.poaCount})`:''
  const nonpoaCount=workListRecordsCount?.newWoundsValues?.nonpoaCount?`(${workListRecordsCount?.newWoundsValues?.nonpoaCount})`:''
 
  /* Tab list */
  const tabList = [
    {
      name: `POA ${poaCount}`,
      content: <NewWoundsPOA facilityNumber={facilityNumber} />,
      bottomName: 'Pending Assessment',
      bgcolor: style.new_wound_bgcolor
    },
    {
      name: `Non-POA ${nonpoaCount}`,
      content: <NewWoundsNONPOA facilityNumber={facilityNumber}/>,
      bottomName: 'Pending Assessment',
      bgcolor: style.new_wound_bgcolor
    },
  ];
  const dispatch = useDispatch();
  const lastVal = useSelector(state => state.patient.lastVal)
  const [defaultVerTab, setDefaultVerTab] = useState(lastVal?.selectVerTab?lastVal?.selectVerTab:0)
  const onSelectVerTab = (e) => {
    setDefaultVerTab(e)
     const setLast={...lastVal,selectVerTab: e }
     dispatch(setLastVal(setLast))
  
    }
  return (
    <>
      <VerticalTabWorkList  onSelectInt={onSelectVerTab} defaultIndex={defaultVerTab<=1?defaultVerTab:0}  tabList={tabList} dispatch={dispatch} />
    </>
  )
}
