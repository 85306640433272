import { createSlice } from '@reduxjs/toolkit';
import {
  COMPETITORS_PRODUCTS,
  DELETE_PRODUCTS,
  ERROR_STATUS,
  LIST_VIEW_LIMIT,
  MARKETTING_PRODUCTS,
  OPTION_TYPES,
  PRODUCT,
  SPECIFICATION_PRODUCTS,
  SUCCESS_STATUS,
  UPDATE_MATERIAL,
  UPDATE_PRODUCTS_FAMILY,
  BASE_URL
} from '../constants';
import { Service } from '../service';
import { constructEndpoint } from '../utils/commonHelper';
import { deleteAsync, setError, setLoading,setAccessError } from './appSlice';
const { request } = Service;

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    detailViewData: {}, // individual Product details 
    products: {},
    resMsgState: '',
    editState: false,
    basicViewDetailData:{},
    specification:{},
    mediaList: {},
    documentList: {},
    materialAttribute:{},
    competitors:{},
    markettingData:{},
    productEditStatus:{},
    productManager:{},
    materialStatus:{},
    materialGroupCode:{},
    productCategoryCode:{},
    productDivision:{},
    familyCode:{},
    manufacturer:{},
  },
  reducers: {
    /* Data for detailed PRODUCT view */
    setDetailViewData: (state, action) => {
      state.detailViewData = action.payload
    },
    /* Get Products Details*/
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    resetProducts: (state, action) => {
      state.products = {}
    },
    setEditState: (state, action) => {
      state.editState = action.payload;
    },
    setBasicViewDetailData: (state, action) => {
      state.basicViewDetailData = action.payload;
    },
    setResMsgState: (state, action) => {
      state.resMsgState = action.payload;
    },
    setSpecification: (state, action) => {
      state.specification = action.payload;
    },
    setMediaList: (state, action) => {
      state.mediaList = action.payload
    },
    setDocumentList: (state, action) => {
      state.documentList = action.payload
    },
    setMaterialAttribute: (state, action) => {
      state.materialAttribute = action.payload;
    },
    setCompetitors: (state, action) => {
      state.competitors = action.payload;
    },
    setMarkettingData: (state, action) => {
      state.markettingData = action.payload;
    },
    setProductEditStatus: (state, action) => {
      state.productEditStatus = action.payload;
    },
    setProductManager: (state, action) => {
      state.productManager = action.payload;
    },
    setMaterialStatus: (state, action) => {
      state.materialStatus = action.payload;
    },
    setMaterialGroupCode: (state, action) => {
      state.materialGroupCode = action.payload;
    },
    setProductCategoryCode: (state, action) => {
      state.productCategoryCode = action.payload;
    },
    setProductDivision: (state, action) => {
      state.productDivision = action.payload;
    },
    setFamilyCode: (state, action) => {
      state.familyCode = action.payload;
    },
    setManufacturer: (state, action) => {
      state.manufacturer = action.payload;
    },
   
  }
});

export const {
  setDetailViewData,
  setProducts,
  resetProducts,
  setEditState,
  setBasicViewDetailData,
  setResMsgState,
  setSpecification,
  setMediaList,
  setDocumentList,
  setMaterialAttribute,
  setCompetitors,
  setMarkettingData,
  setProductEditStatus,
  setProductManager,
  setMaterialStatus,
  setMaterialGroupCode,
  setProductCategoryCode,
  setProductDivision,
  setFamilyCode,
  setManufacturer
} = productSlice.actions;



// getProductsDetails
export const getCallBackListAsync = (payload, history) => async dispatch => {
  const url = payload.url;
  const queryParam = payload.queryParam && payload?.queryParam
  const method = payload.method
  try {
    dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
      method: method
    });
   if (data && data.status === SUCCESS_STATUS) {
      dispatch(setProducts(data))
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405 || obj?.response?.status === 404 ){
      dispatch(setProducts({"data":{"products":[],"metadata":{"total":0,"limit":50,"offset":0,"prev":"","next":""}}}))
      dispatch(setAccessError(null))
      
      window.location='/unAuthorized';
    }
   else{
      dispatch(setProducts(obj))
      dispatch(setError(obj.errorMessage))
    }
    //dispatch(setProducts(data))
  }
  finally {
   
    dispatch(setLoading(false));
  }
}

// delete PRODUCT list
export const deleteProductListAsync = (payload) => async dispatch => {
  try {

    dispatch(setLoading(true));
    payload.map((value) => {
      dispatch(deleteAsync(DELETE_PRODUCTS, value.id))
    })
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Product details  
export const productDetailsAsync = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${PRODUCT}/${payload}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setDetailViewData(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
    if(obj?.response?.status === 405 || obj?.response?.status === 404){
      window.location='/unAuthorized'
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Product details  update
export const productUpdateAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${PRODUCT}/${payload.id}`, {
      method: 'PUT',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(productDetailsAsync(payload.id))
      dispatch(setEditState(data.status))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setError(data.message))
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setEditState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Product details basic info(material,productfamily)
export const productDetailsBasicAsync = (payload,history) => async dispatch => {
  dispatch(setBasicViewDetailData({}))
  try {
    dispatch(setLoading(true));
    const data = await request(`/products/${payload.productType}/${payload.id}/basicinfo`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setBasicViewDetailData(data.data))
      //dispatch(setEditState(data.status))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setError(data.message))
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    dispatch(setEditState('error'))
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    if(obj?.response?.status === 405 || obj?.response?.status === 404){
      history.push('/unAuthorized')
      //dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setEditState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Create Material 
export const createMaterialAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${PRODUCT}/material`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setEditState(data.status))
      dispatch(setResMsgState(data.status))
      const listPayLoad = {};
      listPayLoad['url'] = `${PRODUCT}/material`
      listPayLoad['method'] = 'Get'
      listPayLoad['queryParam'] = { offSet: 0, limit: LIST_VIEW_LIMIT }
      listPayLoad['setMethod'] = setProducts
      dispatch(getCallBackListAsync(listPayLoad))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setError(data.message))
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setEditState('error'))
      dispatch(setResMsgState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Create product family 
export const createProductFamilyAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${PRODUCT}/productfamily`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      //dispatch(productDetailsAsync())
      dispatch(setEditState(data.status))
      dispatch(setResMsgState(data.status))
      const listPayLoad = {};
      listPayLoad['url'] = `${PRODUCT}/productfamily`
      listPayLoad['method'] = 'Get'
      listPayLoad['queryParam'] = { offSet: 0, limit: LIST_VIEW_LIMIT }
      listPayLoad['setMethod'] = setProducts
      dispatch(getCallBackListAsync(listPayLoad))
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setError(data.message))
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setEditState('error'))
      dispatch(setResMsgState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Update product family 
export const updateProductFamilyAsync = (payload) => async dispatch => {
  const tempPayload ={
    productDivisionCodeId:payload.productDivisionCodeId,
    productFamilyCode:payload.productFamilyCode,
    productFamilyDesc:payload.productFamilyDesc,
    productCategoryCodeId:payload.productCategoryCodeId,
    productManager:payload.productManager,
    
  }
  const refreshPayload={
    productType:payload.productType,
    id:payload.productId
  }
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(UPDATE_PRODUCTS_FAMILY,payload.productId), {
      method: 'PATCH',
      body: JSON.stringify(tempPayload)
    });
    dispatch(setLoading(true));
    if (data && data.status === SUCCESS_STATUS) {
      await dispatch(productDetailsBasicAsync(refreshPayload)) // call-back detail
      dispatch(setLoading(false));
      dispatch(setProductEditStatus(data.status));
      
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setProductEditStatus('error'));
    }
    
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setProductEditStatus('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Update material 
export const updateMaterialAsync = (payload) => async dispatch => {
  const tempPayload ={
      productDivisionCodeId:payload.productDivisionCodeId,
      materialNumber:payload.materialNumber,
      materialDescription:payload.materialDescription,
      materialGroupCodeId:payload.materialGroupCodeId,
      productCategoryCodeId:payload.productCategoryCodeId,
      productFamilyCodeId:payload.productFamilyCodeId,
      manufacturerNameId:payload.manufacturerNameId,
      salesUOMCodeId:payload.salesUOMCodeId,
      salesUOMNumeratorQuantity:payload.salesUOMNumeratorQuantity,
      gtinNumber:payload.gtinNumber,
      upcCode:payload.upcCode,
      hcpcScode:payload.hcpcScode,
      materialStatusCodeId:payload.materialStatusCodeId
    
  }
  const refreshPayload={
    productType:payload.productType,
    id:payload.productId
  }
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(UPDATE_MATERIAL,payload.productId), {
      method: 'PATCH',
      body: JSON.stringify(tempPayload)
    });
    dispatch(setLoading(true));
    if (data && data.status === SUCCESS_STATUS) {
      await dispatch(productDetailsBasicAsync(refreshPayload)) // call-back detail
      dispatch(setLoading(false));
      dispatch(setProductEditStatus(data.status));
      
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setProductEditStatus('error'));
    }
    
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setProductEditStatus('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Get products  SPECIFICATION
export const getProductSpecificationAsync = (productId) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(SPECIFICATION_PRODUCTS, productId), {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setSpecification(data.data.specification))
    }
  }
  catch (error) {
    // Sales Office list option get call
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setSpecification([]))
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

//  PATCH products  SPECIFICATION
export const updateProductSpecificationAsync = (payload, productId) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(SPECIFICATION_PRODUCTS,productId), {
      method: 'PATCH',
      body: JSON.stringify(payload)
    });
    dispatch(setLoading(true));
    if (data && data.status === SUCCESS_STATUS) {
     await dispatch(getProductSpecificationAsync(productId)) // call-back detail
     dispatch(setProductEditStatus(data.status));
     dispatch(setLoading(false));
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setProductEditStatus('error'));
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setProductEditStatus('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const updateProductMarketing = (payload, productId) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(MARKETTING_PRODUCTS,productId), {
      method: 'PATCH',
      body: JSON.stringify(payload)
    });
    dispatch(setLoading(true));
    if (data && data.status === SUCCESS_STATUS) {
     await dispatch(getProductMarkettingAsync(productId)) // call-back detail
     dispatch(setProductEditStatus(data.status));
     dispatch(setLoading(false));
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setProductEditStatus('error'));
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setProductEditStatus('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

    
// Get products  Competitor 
export const getProductCompetitorAsync = (productId) => async dispatch => { 
  try {
    dispatch(setLoading(true));
     const data = await request(constructEndpoint(COMPETITORS_PRODUCTS, productId), {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setCompetitors(data.data.competitors))
    }
  }
  catch (error) {
    // Sales Office list option get call
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setCompetitors([]))
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setError(obj.errorMessage))
    }
    
  }
  finally {
    dispatch(setLoading(false));
  }
}

//  PATCH products  Competitor 
export const updateProductCompetitorAsync = (payload, productId) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(COMPETITORS_PRODUCTS,productId), {
      method: 'PATCH',
      body: JSON.stringify(payload)
    });
    dispatch(setLoading(true));
    if (data && data.status === SUCCESS_STATUS) {
      await dispatch(getProductCompetitorAsync(productId)) // call-back detail
      dispatch(setLoading(false));
      dispatch(setProductEditStatus(data.status));
      
    }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setProductEditStatus('error'));
    }
    
  }
  catch (error) {
    const obj = { ...error }   
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setError(obj.errorMessage))
      dispatch(setProductEditStatus('error'));
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}
/* Setting options types */

export const productOptionTypeAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${OPTION_TYPES}/${payload}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      if (payload === 'materialattribute') {
        dispatch(setMaterialAttribute(data.data.options))
      }
      else if(payload==='productmanager'){
        dispatch(setProductManager(data.data.options))
      }
      else if(payload==='materialstatus'){
        dispatch(setMaterialStatus(data.data.options))
      }
      else if(payload==='productdivision'){
        dispatch(setProductDivision(data.data.options))
      }
      else if(payload==='manufacturer'){
        dispatch(setManufacturer(data.data.options))
      }
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const productCategoryCodeAndMaterialGroupCodeOption = (payload) => async dispatch => {
  try {
if(payload.id&&payload.code){
    dispatch(setLoading(true));
    const data = await request(`${OPTION_TYPES}/division/${payload.id}/${payload.code}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      if (payload.code === 'materialGroupCode') {
        dispatch(setMaterialGroupCode(data.data.options))
      }
      else if(payload.code==='productCategoryCode'){
        dispatch(setProductCategoryCode(data.data.options))
      }
     
    }
  }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setError(obj.errorMessage))
    }
   
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const familyCodeOption = (payload) => async dispatch => {
  try {
    if(payload.id&&payload.productCategoryCode){
    dispatch(setLoading(true));
    const data = await request(`${OPTION_TYPES}/division/${payload.id}/ProductCategory/${payload.productCategoryCode}/FamilyCode`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
        dispatch(setFamilyCode(data.data))
    }
  }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

// Get products  marketing 
export const getProductMarkettingAsync = (productId) => async dispatch => {
  try {
    dispatch(setLoading(true));
      const data = await request(constructEndpoint(MARKETTING_PRODUCTS, productId), {
      method: 'GET',
    });
    if (data.status === SUCCESS_STATUS) {
      dispatch(setMarkettingData(data.data))
    }
  }
  catch (error) {
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
    //dispatch(setMarkettingData(data.data.marketting))
  }
  finally {
    dispatch(setLoading(false));
  }
}

//  PATCH products  marketing 
export const updateProductMarkettingAsync = (payload, productId) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(MARKETTING_PRODUCTS,productId), {
      method: 'PATCH',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      await dispatch(getProductMarkettingAsync(productId)) // call-back detail
      dispatch(setProductEditStatus(data.status));
      dispatch(setLoading(false));
     }
     if (data && data.status === ERROR_STATUS) {
       dispatch(setProductEditStatus('error'));
     }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setError(obj.errorMessage))
      dispatch(setProductEditStatus('error'));
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

/************* */
// ASHOK SECTION
/************* */
// Product media list
export const getMediaList = (productId, productType) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/products/${productType}/${productId}/media`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setMediaList(data.data)) // call-back detail
    }
  }
  catch (error) { 
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Update media
export const updateMediaList = ({productId, productType, list, getMedia, setUploadSuccess, setServerError}) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/products/${productType}/${productId}/media`, {
      method: 'PATCH',
      body: JSON.stringify(list)
    });
    if (data && data.status === SUCCESS_STATUS) {
      if(setUploadSuccess){
        setUploadSuccess(true)
        setTimeout(() => {
          setUploadSuccess(false)
        }, 5000)
      }
      if(getMedia){
        getMedia();
      }
    }
  }
  catch (error) {  
    const obj = { ...error }
    if(setServerError){
      setServerError(obj.errorMessage)
      setTimeout(() => {
        setServerError(null)
      }, 5000)
    }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Product document
export const getDocumentList = (productId, productType) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/products/${productType}/${productId}/document`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setDocumentList(data.data)) // call-back detail
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
// Update documents
export const updateDocumentList = ({productId, productType, list, getDocs, setUploadSuccess, setServerError}) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`/products/${productType}/${productId}/document`, {
      method: 'PATCH',
      body: JSON.stringify(list)
    });
    if (data && data.status === SUCCESS_STATUS) {
      if(setUploadSuccess){
        setUploadSuccess(true)
        setTimeout(() => {
          setUploadSuccess(false)
        }, 5000)
      }
      if(getDocs){
        getDocs();
      }
    }
  }
  catch (error) {  
    const obj = { ...error }
    if(setServerError){
      setServerError(obj.errorMessage)
      setTimeout(() => {
        setServerError(null)
      }, 5000)
    }
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
//Create Product Manager
export const createProductManagerAsync = (payload) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${PRODUCT}/productmanager`, {
      method: 'POST',
      body: JSON.stringify(payload)
    }
    )
     if (data && data.status === SUCCESS_STATUS) {
      dispatch(productOptionTypeAsync('productmanager')) 
     }
    if (data && data.status === ERROR_STATUS) {
      dispatch(setError(data.message))
      dispatch(setEditState('error'))
    }
  }
  catch (error) {
    const obj = { ...error }
    if(obj?.response?.status === 405){
      console.log("ERROR 405 STATUS");
      dispatch(setAccessError(obj.errorMessage))
    }else{
      dispatch(setEditState('error'))
      dispatch(setResMsgState('error'))
      dispatch(setError(obj.errorMessage))
    }
  }
  finally {
    dispatch(setLoading(false));
  }
}

export default productSlice.reducer;
