import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UpdatePasswordForm } from '../components/core/UpdatePasswordForm/updatePasswordForm';

export let UpdatePassword = () => {
    return <UpdatePasswordForm />
}

UpdatePassword = connect()(withRouter(UpdatePassword));
