import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector, connect } from "react-redux";
import {
  createFormularyAsync,
  formularyOptionsAsync,
  setNewPayload,
  updateFormularyAsync,
} from "../../../app/formularySlice";
import {
  CANCEL_BTN,
  CANCEL_ID,
  LIST_VIEW_LIMIT,
  SAVE_BTN,
  SUBMIT_ID,
  FORMULARY_IMPORT_METHOD
} from "../../../constants";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  SelectBox,
  TextField,
  MultiSelect,
  RadioButton,
} from "../../primary";
import { Header } from "../../secondary/Header";
import styles from "./createFormulary.module.scss";
import { ListView } from "../../secondary";
import NewFormulary from "./newFormulary";
import Loader from "../../../static/images/spinner.svg";
import { setError, setLoading } from "../../../app/appSlice";
import { AccessErrorModal } from "../../primary";
import {
  asec,
  dateOfBirthDate,
  desc,
  onlyFirstName,
  getUniqueListBy,
} from "../../../utils";
import {
  getMateriallist,
  importProductToFormulary,
} from "../../../app/customFormSlice";
import ImportIcon from "../../../static/images/import-icon.png";
import {
  checkAddedMove,
  getActivePageMove,
  handleAddItemsMove,
  handleDropDownChangeMove,
  handleRemoveItemsMove,
  sortingItemsMove,
} from "../../../utils/formularyHelper";
import { Modal } from "../../secondary";
import * as XLSX from "xlsx";
import DocumentIcon from "../../../static/images/products/document_icon.svg";
import CloseIcon from "../../../static/images/products/close_icon.svg";
export default function CreateFormulary({
  handleCancel,
  duplicate,
  editMode,
  dispatch,
  materials,
  history,
  setEditMode,
  newPayload,
  setCreateFormulary,
  formularyId,
  formularyData,
  setDuplicate
}) {
  const {
    register,
    errors,
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm();
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
    setError: setErrorExcel,
    clearErrors: clearErrorsExcel,
  } = useForm({ mode: "onBlur" });

  const [addedItems, setAddedItems] = useState([]);
  const [addedMeta, setAddedMeta] = useState({});
  const [formularyName, setFormularyName] = useState("Formulary Name");
  const [productCategoryDescOptions, setProductCategoryDescOptions] = useState(
    []
  );
  const [productCategoryCodeOptions, setProductCategoryCode] = useState([]);
  const [productFamilyDescOptions, setProductFamilyDesc] = useState([]);
  const [productFamilyCodeOptions, setProductFamilyCode] = useState([]);
  const [apiMaterials, setApiMaterials] = useState([...materials?.materials]);//NOSONAR
  const [apiMeta, setApiMeta] = useState({ ...materials?.metadata });//NOSONAR
  const [noPage, setNoPage] = useState(false);
  const [sortType, setSortType] = useState(null);
  const [displayItems, setDisplayItems] = useState([...apiMaterials]);
  const [addTracker, setAddTracker] = useState(false);

  const divisionOptions = useSelector((state) => state.formulary.division);
  const [seletedMaterial, setSeletedMaterial] = useState([]);
  const materialList = useSelector((state) => state.forms?.materiallist);
  const [matCodeMulOpt, setMatCodeMulOpt] = useState([]);
  const [modalShowImport, setmodalShowImport] = useState(false);

  useEffect(() => {
    dispatch(setError(""));
    dispatch(getMateriallist());
  }, []);

  const ApiError = useSelector((state) => state.app.errorMessage);

  const userDetails = useSelector((state) => state.user);;// Added for External Formulary

  useEffect(() => {
    if (ApiError !== "") {
      setTimeout(() => {
        dispatch(setError(""));
      }, 5000);
    }
  }, [ApiError]);

  const handleBack = () => {
    setCreateFormulary(false);
  };

  const handleNameChange = (data) => {
    setFormularyName(data.target.value);
  };

  useEffect(() => {
    if (editMode) {
      setTimeout(() => {
        const payload = {
          division: formularyData?.division ? formularyData?.division : "",
          productCategoryDesc: formularyData?.productCategoryDesc
            ? formularyData?.productCategoryDesc
            : null,
          productCategoryCode: formularyData?.productCategoryCode
            ? formularyData?.productCategoryCode
            : null,
          productFamilyDesc: formularyData?.productFamilyDesc
            ? formularyData?.productFamilyDesc
            : null,
          productFamilyCode: formularyData?.productFamilyCode
            ? formularyData?.productFamilyCode
            : null,
        };
        if (!duplicate) {
          setFormularyName(formularyData.formularyName);
        }
        const queryParam = { limit: 80000 };
        if (formularyData?.division) {
          dispatch(setNewPayload(payload));
          dispatch(formularyOptionsAsync(payload, queryParam));
        }
      }, [500]);
    }
  }, [editMode, formularyData]);

  useEffect(() => {
    if (editMode) {
      setAddedItems(formularyData?.materials);
      setAddedMeta(formularyData?.metadata);
    }
  }, [editMode, formularyData]);

  const [firstTimeEdit, setFirstTimeEdit] = useState(true);
  const handleDropDownChange = (e, field) => {
    handleDropDownChangeMove(
      e,
      field,
      setActivePage,
      setFirstTimeEdit,
      dispatch,
      setNewPayload,
      reset,
      getValues,
      newPayload
    );
  };

  useEffect(() => {
    const queryParam = { limit: 80000 };
    dispatch(formularyOptionsAsync(newPayload, queryParam));
  }, [newPayload]);

  useEffect(() => {
    if (materials) {
      setApiMaterials(materials?.materials);
      setApiMeta(materials?.metadata);
      setProductCategoryDescOptions(materials?.productCategoryDesc);
      setProductCategoryCode(materials?.productCategoryCode);
      setProductFamilyDesc(materials?.productFamilyDesc);
      setProductFamilyCode(materials?.productFamilyCode);
    }
  }, [materials]);

  useEffect(() => {
    if (editMode && firstTimeEdit) {
      setValue("productCategoryDesc", formularyData?.productCategoryDesc);
      setValue("productCategoryCode", formularyData?.productCategoryCode);
      setValue("productFamilyDesc", formularyData?.productFamilyDesc);
      setValue("productFamilyCode", formularyData?.productFamilyCode);
    }
  }, [
    productCategoryDescOptions,
    productCategoryCodeOptions,
    productFamilyDescOptions,
    productFamilyCodeOptions,
  ]);

  const columns = [
    {
      Header: <span className={`${styles.thead}`}>Materials</span>,
      accessor: "title",
      fixedColumn: false,
    },
  ];

  const ref = useRef(apiMaterials);
  useEffect(() => {
    ref.current = [...apiMaterials];
  }, [apiMaterials]);

  const sortingItems = (data) => {
    sortingItemsMove(data, setActivePage, ref, asec, setApiMaterials, desc);
  };

  const [sortingPayload, setSortingPayload] = useState({
    queryParam: { limit: LIST_VIEW_LIMIT, offSet: 0 },
    asyncMethod: sortingItems,
  });

  const [activePage, setActivePage] = useState(1);
  const getActivePage = (data) => {
    getActivePageMove(data, apiMaterials, setDisplayItems, setActivePage);
  };

  useEffect(() => {
    if (apiMaterials && apiMaterials.length < 1) {
      setNoPage(true);
    }
    if (apiMaterials && apiMaterials.length >= 1) {
      setNoPage(false);
    }
  }, [apiMaterials]);

  useEffect(() => {
    if (addedItems && addedItems.length > 0) {
      const materialNumber = [];
      materials.materials &&
        materials.materials.length > 0 &&
        materials.materials.forEach((item) => {
          materialNumber.push(item.materialNumber);
        });
      const addedMaterialNumber = [];
      addedItems.forEach((item) => {
        addedMaterialNumber.push(item.materialNumber);
      });
      const items = [];
      materialNumber.map((data, i) => {//NOSONAR
        if (!addedMaterialNumber.includes(data)) {
           return items.push(materials.materials[i]); //NOSONAR
        }
      }); //NOSONAR
      setApiMaterials([...items]);
    }
  }, [materials]);

  useEffect(() => {
    if (apiMaterials && apiMaterials.length > 0) {
      const temp = [];
      for (let i = 0; i < LIST_VIEW_LIMIT; i++) {
        if (apiMaterials[i]) {
          temp.push(apiMaterials[i]);
        } else {
          break;
        }
      }
      setDisplayItems([...temp]);
      const tempMeta = { ...apiMeta };
      tempMeta["total"] = apiMaterials.length;
      tempMeta["limit"] = LIST_VIEW_LIMIT;
      setApiMeta({ ...tempMeta });
    } else if (apiMaterials && apiMaterials.length === 0) {
      setDisplayItems([]);
    }
  }, [apiMaterials]);

  let selectedItems = [];
  const getSelectedItems = (data) => {
    selectedItems = [...data];
  };

  const handleAddItems = () => {
    setSeletedMaterial([]);
    handleAddItemsMove(
      setAddTracker,
      setAddedItems,
      selectedItems,
      addedItems,
      apiMaterials,
      setApiMaterials
    );
  };

  let rightSelectedItems = [];
  const getRightSelectedItems = (data) => {
    rightSelectedItems = [...data];
  };
  const changeMaterial = (item) => {
    let tempSeletedMaterial = seletedMaterial;
    if (
      tempSeletedMaterial.some(
        (material) => material.materialNumber === item.materialNumber
      )
    ) {
      tempSeletedMaterial = tempSeletedMaterial.filter(
        (material) => material.materialNumber !== item.materialNumber
      );
    } else {
      tempSeletedMaterial.push(item);
    }

    //getRightSelectedItems(tempSeletedMaterial)
    setSeletedMaterial(tempSeletedMaterial);
    rightSelectedItems = [...tempSeletedMaterial];
  };
  const handleRemoveItems = () => {
    handleRemoveItemsMove(
      setAddTracker,
      setApiMaterials,
      rightSelectedItems,
      apiMaterials,
      addedItems,
      setAddedItems
    );

    setSeletedMaterial([]);
  };

  const [tinyDelay, setTinyDelay] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true);
    }, 2500);
  }, []);

  const checkAdded = () => {
    checkAddedMove(addedItems, dispatch, setError);
  };
  const [defaultName, setDefaultName] = useState(null);
  const doSubmit = (data) => {
    const addedNumbers =
      addedItems &&
      addedItems.length > 0 &&
      addedItems.map((data) => data.materialNumber);
    if (addedNumbers == false) {
      dispatch(setError("Please add materials!"));
      return;
    }
    if (!editMode && !duplicate) {
      const payload = {
        formularyName: data?.formularyName ? data?.formularyName : defaultName,
        ...newPayload,
        materials: addedNumbers,
        division: data.division,
      };
      dispatch(createFormularyAsync(payload, history, setCreateFormulary));
    }
    if (editMode && !duplicate) {
      const payload = {
        id: formularyId,
        formularyName: data?.formularyName ? data?.formularyName : defaultName,
        ...newPayload,
        materials: addedNumbers,
        division: data.division,
      };
      /* if (formularyData.id == payload.id
        && formularyData.division === payload.division
        && formularyData.formularyName === payload.formularyName
        && formularyData.productCategoryCode === payload.productCategoryCode
        && formularyData.productCategoryDesc === payload.productCategoryDesc
        && formularyData.productFamilyCode === payload.productFamilyCode
        && formularyData.productFamilyDesc === payload.productFamilyDesc
        && addTracker === false) {
        // dispatch(setError('No updates made. Please update before saving.'))
        dispatch(updateFormularyAsync(payload, history))
        return;
      }*/
      dispatch(updateFormularyAsync(payload, history, setEditMode));
    }
    if (duplicate) {
      const payload = {
        formularyName: data?.formularyName ? data?.formularyName : defaultName,
        ...newPayload,
        materials: addedNumbers,
        setEditMode: setEditMode,
        duplicate: duplicate,
        setDuplicate: setDuplicate,
      };
      //defaultName
      dispatch(createFormularyAsync(payload, history));
    }
  };
  useEffect(() => {
    setDefaultName(
      duplicate ? null : editMode ? formularyData?.formularyName : null
    );
  }, [formularyData]);

  const matCodeMulOptRef = useRef();
  useEffect(() => {
    //addedItems
    const getAllMaterialCode = [];
    const collAllFam =
      Array.isArray(materialList?.data?.options) &&
      materialList?.data?.options.map((items, index) => {
        return getAllMaterialCode.push({
          key: items.exactMaterialNumber,
          value: items.exactMaterialNumber,
          valueCode: items.exactMaterialNumber,
          matList: items,
        });
      });
    setMatCodeMulOpt(getAllMaterialCode);
  }, [materialList]);

  const selectMatCode = (list, chkItems) => {
    const finalAddedItems = [...addedItems, chkItems.matList];
    const uniqueList = getUniqueListBy(
      finalAddedItems,
      "materialNumber"
    );
    setAddedItems(uniqueList);
    //setAddedItems(finalAddedItems);
    const finalMatRemove = matCodeMulOpt.filter((e) =>
      list.some((val) => val.key !== e.key)
    );
    setTimeout(() => {
      matCodeMulOptRef.current.state.selectedValues = [];
      matCodeMulOptRef.current.resetSelectedValues();
    }, 2);
    setMatCodeMulOpt(finalMatRemove);
  };

  /*import actions*/
  const [matColOptions, setMatColOptions] = useState([]);
  const [isMatColOptions, setIsMatColOptions] = useState(false);
  const [isImportMethod, setIsImportMethod] = useState(false);
  const [matFileData, setMatFileData] = useState([]);
  const [isMatValidCol, setIsMatValidCol] = useState(false);
  const [importedMaterials, setImportedMaterials] = useState({});
  const modalOpenImport = () => {
    setmodalShowImport(true);
    clearMatExcel();
  };
  const modalCloseImport = () => {
    setmodalShowImport(false);
    clearMatExcel();
  };

  //handleMatExcel
  const handleMatExcel = (e) => {
    const file = e.target?.files[0];
    const fileName = file.name.split(".");
    if (
      fileName[fileName.length - 1] === "csv" ||
      fileName[fileName.length - 1] === "xlsx" ||
      fileName[fileName.length - 1] === "xls"
    ) {
      if (parseInt(file.size / 1024) > 51200) {
        setErrorExcel("importFileMat", {
          type: "manual",
          message: "Please select a file less than 50mb",
        });
      } else {
        clearErrorsExcel("importFileMat");
        readExcel(file);
      }
    } else {
      setErrorExcel("importFileMat", {
        type: "manual",
        message: "Please select only excel file",
      });
    }
  };
  //handleMatCol
  const handleMatCol = (e) => {
    setMatFileData({ ...matFileData, finalExcelMat: [] });
    let file = document?.getElementById("importFileMat")?.files[0];
    let getCol = e?.target?.selectedIndex - 1;
    let getMatkey = e?.target?.value;
    let inValidMat = [];
    let validMat = [];
    Array.isArray(matFileData?.firstFiveRow) &&
      matFileData?.firstFiveRow?.map((item, index) => {
        //NOSONAR
        if (item[getMatkey]) {
          let chckMatVal = item[getMatkey];
          if (!isNaN(chckMatVal.toString().charAt(0))) {
            //If is a first number
            inValidMat = [...inValidMat, item[getMatkey]];
          } else if (!chckMatVal.match(/^[0-9A-Z]+$/)) {
            //If is a alphanumeric Capital only & number only
            inValidMat = [...inValidMat, item[getMatkey]];
          } else if (chckMatVal.indexOf(" ") >= 0) {
            //If is a WhiteSpace
            inValidMat = [...inValidMat, item[getMatkey]];
          } else if (chckMatVal.length < 4) {
            //If is a length min 4
            inValidMat = [...inValidMat, item[getMatkey]];
          } else if (chckMatVal.length > 13) {
            //If is a length max 13
            inValidMat = [...inValidMat, item[getMatkey]];
          } else {
            validMat = [...validMat, item[getMatkey]];
          }
        }
      }); //NOSONAR
    if (
      inValidMat.length === matFileData?.firstFiveRow.length ||
      inValidMat.length > 3
    ) {
      setErrorExcel("selMat", {
        type: "manual",
        message: "Please select the correct material # column",
      });
      setIsMatValidCol(true);
    } else if (validMat.length > 2) {
      clearErrorsExcel("selMat");
      readExcelCol(file, getCol);
      setIsMatValidCol(false);
    } else {
      setErrorExcel("selMat", {
        type: "manual",
        message: "Please select the correct material # column",
      });
      setIsMatValidCol(true);
    }
  };
  const readExcel = async (file) => {
    dispatch(setLoading(true));

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const bufferArray = e?.target.result;
      const wb = XLSX.read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let getRowRange = XLSX.utils.decode_range(ws["!ref"]);
      getRowRange.s.r = 0;
      getRowRange.e.r = 5;
      const newRowRange = XLSX.utils.encode_range(getRowRange);
      let data = XLSX.utils.sheet_to_json(ws, { range: newRowRange });
      const fileName = file?.name?.split(".")[0];
      let headingCol = Object.keys(data[0]);
      headingCol = headingCol.filter(item => !!item.trim());
      headingCol = headingCol.map((item) => ({ key: item, value: item }));
      setMatColOptions(headingCol);
      setIsMatColOptions(true);
      if (addedItems && Array.isArray(addedItems) && addedItems.length > 0) {
        setIsImportMethod(true);
      } else {
        setIsImportMethod(false);
      }
      setMatFileData({ fileName: fileName, firstFiveRow: data });
      dispatch(setLoading(false));
    };
  };
  const readExcelCol = async (file, getCol) => {
    dispatch(setLoading(true));
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const bufferArray = e?.target.result;
      const wb = XLSX.read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let getColRange = XLSX.utils.decode_range(ws["!ref"]);
      getColRange.s.c = getCol;
      getColRange.e.c = getCol;
      const newColRange = XLSX.utils.encode_range(getColRange);
      const colData = XLSX.utils.sheet_to_json(ws, { range: newColRange });
      setMatFileData({ ...matFileData, finalExcelMat: colData });
      dispatch(setLoading(false));
    };
  };
  const doSubmitImport = (data) => {
    if (isMatValidCol) {
      setErrorExcel("selMat", {
        type: "manual",
        message: "Please select the correct material # column",
      });
    } else if (
      Array.isArray(matFileData?.finalExcelMat) &&
      matFileData?.finalExcelMat.length > 0
    ) {
      clearErrorsExcel("selMat");
      let finalPayloadMat = [data?.selMat];
      Array.isArray(matFileData?.finalExcelMat) &&
        matFileData?.finalExcelMat?.map((item, index) => {
          //NOSONAR
          if (item[data?.selMat]) {
            finalPayloadMat = [...finalPayloadMat, item[data?.selMat]];
          }
        }); //NOSONAR
      let payloadImport = {
        formularyId: formularyId ? formularyId : 0,
        importMethod: data?.importMatMethod && data?.importMatMethod===FORMULARY_IMPORT_METHOD[0]? "Append" :data?.importMatMethod? "Replace":'',
        materials: finalPayloadMat,
      };
      dispatch(importProductToFormulary(payloadImport, setImportedMaterials));
    }
  };

  const removeMatExcel = (e) => {
    clearMatExcel();
  };
  const clearMatExcel = () => {
    reset2();
    setMatFileData([]);
    setMatColOptions([]);
    setIsMatColOptions(false);
    setIsImportMethod(false);
    setIsMatValidCol(false);
    setImportedMaterials({});
  };
  useEffect(() => {
    if (
      importedMaterials?.data &&
      Array.isArray(importedMaterials?.data?.materials)
    ) {
      const uniqueList = getUniqueListBy(
        importedMaterials?.data?.materials,
        "materialNumber"
      );
      setAddedItems(uniqueList);
     /* if (addedItems.length > 0) {
        const uniqueList = getUniqueListBy(
          [...addedItems, importedMaterials?.data?.materials],
          "materialNumber"
        );
        setAddedItems(uniqueList);
      } */
    }
  }, [importedMaterials]);
  return (
    <div>
      <AccessErrorModal />
      {modalShowImport && (
        <Modal
          modalOpen={modalShowImport}
          modalClose={modalCloseImport}
          heading={
            importedMaterials?.status
              ? "File Import"
              : "Import formulary"
          }
          className={"modal_box"}
          modalSize={`${importedMaterials?.status === "success" && importedMaterials?.data?.failedRecords.length===0?styles.custom_modal_suc:styles.custom_modal_size}`}
        >
          <div className={`pl-2`}>
            {importedMaterials?.status &&
            importedMaterials?.status === "success" ? (
              <>
                <p className="ml-3  mb-4">
                  {importedMaterials?.data &&
                    Array.isArray(importedMaterials?.data?.failedRecords) &&            
                    importedMaterials?.data?.failedRecords.map((item, key) => {
                      let failmat = item;
                      if(key ===0){
                        failmat='Some materials failed to import:'+ item;
                      }
                     else {
                        failmat = "," + item;
                      }
                      return failmat;
                    })
                    
                    }
                </p>
                {importedMaterials?.data &&
                Array.isArray(importedMaterials?.data?.failedRecords) &&
                importedMaterials?.data?.failedRecords.length >0 ? (
                  <>
                   <p className="ml-3">
                     Note: Admin has been notified, no further action is needed!
                  </p>
                  <p className="ml-3 mt-n2">
                     Reminder: Please scroll down and click the 'Save' button on the Formulary page. 
                  </p>
                  </>
                 
                ) : (
                 
                  <>
                   <p className={`${styles.confirm_suc} text-center mt-2`}>
                         <h2>Success!</h2>
                  </p>
                  <p className="text-center  mb-2">
                     Reminder: Please scroll down and click the 'Save' button on the Formulary page. 
                  </p>
                  </>
                )}
              </>
            ) : importedMaterials?.status &&
              importedMaterials?.status === "error" ? (
              <>
               <p className="ml-3">
                <span>{importedMaterials?.message}</span>  
                </p>
              </>
            ) : (
              <>
                <Form onSubmit={handleSubmit2(doSubmitImport)}>
                  <div className={`d-flex`}>
                    <div className={`row col-12 ml-n1 p-3`}>
                      <div className={`col-4`}>
                        <label
                          className={`col-form-label ${styles.label_spec}`}
                        >
                          Select File:
                        </label>
                      </div>
                      <div className={`col-6`}>
                        {matFileData && matFileData?.fileName ? (
                          <div className={`${styles.documentView} ml-n3`}>
                            <img src={DocumentIcon} alt="image_icon" />
                            <a className="blue" rel="noopener noreferrer">
                              {matFileData?.fileName}
                            </a>
                            <span>
                              <img
                                src={CloseIcon}
                                alt="close"
                                onClick={(e) => removeMatExcel(e)}
                              />
                            </span>
                          </div>
                        ) : (
                          <label
                            htmlFor={"importFileMat"}
                            className={`${styles.file_upload} ml-n3`}
                          >
                            Browse
                          </label>
                        )}

                        {errors2 &&
                          errors2["importFileMat"] &&
                          errors2["importFileMat"].message && (
                            <>
                              <span className={`${styles.error_message} ml-n3`}>
                                {errors2["importFileMat"].message}
                              </span>
                            </>
                          )}
                        <input
                          className={"d-none"}
                          type="file"
                          id={"importFileMat"}
                          name="importFileMat"
                          accept=".xls,.xlsx"
                          onChange={(e) => handleMatExcel(e)}
                          ref={register2({
                            required: "Please select a file",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {isMatColOptions && (
                    <div className={`d-flex`}>
                      <div className={`col-12`}>
                        <SelectBox
                          id={"selMat"}
                          name={"selMat"}
                          label={"Material # column:"}
                          classes={{
                            column: `row ${styles.col_spec} ml-n1`,
                            inputClassName: `${styles.input_field}`,
                            labelClassName: `col-4 col-form-label mt-0 ${styles.label_cutom}`,
                            inputColumn: `col-8 p-0 `,
                          }}
                          placeholder="Select"
                          optionValue={matColOptions}
                          validator={register2({
                            required: "Please select material # column",
                          })}
                          errors={errors2}
                          onChange={(e) => handleMatCol(e)}
                          //required={true}
                          //defaultValue={layoutRowData && layoutRowData.salesOffice}
                        />
                      </div>
                    </div>
                  )}

                  {isImportMethod && (
                    <div className={`d-flex`}>
                      <div className={`row col-12 ml-n1 p-3`}>
                        <div className={`col-4`}>
                          <label
                            className={`col-form-label ${styles.label_spec}`}
                          >
                            Import Method:
                          </label>
                        </div>
                        <RadioButton
                          id={"importMatMethod"}
                          name={"importMatMethod"}
                          classes={{
                            column: `col-8 p-0 mt-n3 ml-n1`,
                            labelClass: `custom-control-label  ${styles.custom_control_label}`,
                            inputClassName: `custom-control-input ${styles.custom_control_input}`,
                            inputColumn: ` custom-control custom-radio custom-control-inline ${styles.custom_control_inline}`
                          }}
                          validator={register2({
                            required: "Please select a import method",
                          })}
                          //radioOption={["Replace", "Append"]}
                          radioOption={FORMULARY_IMPORT_METHOD}
                          errors={errors2}
                          // defaultValue={customerData && WOUND_EDGE_AUTO_TRACING_STATUS[customerData?.settings?.woundAssessmentScore]}
                        />
                      </div>
                    </div>
                  )}
                  <div className="text-center pb-4">
                    <br />
                    <Button
                      classes={{
                        className: `btn ${styles.btn_custom} ${styles.btn_small}  ml-2`,
                      }}
                      type="submit"
                    >
                      {"Upload"}
                    </Button>
                    <Button
                      classes={{
                        className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small_cancel}`,
                      }}
                      type="button"
                      onClick={() => modalCloseImport()}
                    >
                      {CANCEL_BTN}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </div>
        </Modal>
      )}
      {!editMode && (
        <div className={`${styles.back_btn}`}>
          <Header
            heading="Admin Portal"
            subHeading="Formulary"
            formulary={true}
            createFormulary={true}
            handleBack={handleBack}
            backLink={true}
            setCreateFormulary={setCreateFormulary}
          />
        </div>
      )}
      {editMode ? (
        duplicate ? (
          ""
        ) : (
          <h3 className={styles.admin_meta}>
            <span>
              <b>Created Date:</b>{" "}
              {formularyData?.createdDate &&
                dateOfBirthDate(formularyData?.createdDate)}
            </span>
            <span>
              <b>Created By:</b> {onlyFirstName(formularyData?.createdBy)}
            </span>
            {formularyData?.updatedDate && (
              <span>
                <b>Last Edited:</b>{" "}
                {dateOfBirthDate(formularyData?.updatedDate)}
              </span>
            )}
            {formularyData?.updatedBy && (
              <span>
                <b>Last Edited By:</b> {onlyFirstName(formularyData?.updatedBy)}
              </span>
            )}
          </h3>
        )
      ) : (
        ""
      )}
      {editMode ? (
        duplicate ? (
          ""
        ) : (
          <h4 className={styles.id_block}>
            ID: <strong>{formularyId ? formularyId : ""}</strong>
          </h4>
        )
      ) : (
        ""
      )}
      <div className={styles.content}>
        {ApiError &&
          ApiError.length > 0 &&
          (ApiError.includes("formulary name") ||
            ApiError.includes("add materials") ||
            ApiError.includes("updates made")) && (
            <div
              className={`alert alert-danger p-2 pl-3 pr-3 mb-3 d-block ${styles.form_error_message}`}
            >
              {ApiError}
            </div>
          )}
        <div className={styles.fields}>
          <Form formChanges={true} onSubmit={handleSubmit(doSubmit)}>
            <div className="row">
              <div className="col-4">
                <TextField
                  classes={{
                    column: `form-group col-12 p-0 ${styles.field_max}`,
                    labelClass: `font-weight-bold ${styles.label_spec}`,
                    inputClassName: "form-control mb-1",
                    inputColumn: "col-12 p-0",
                  }}
                  label={"Formulary Name:"}
                  type={"text"}
                  name={"formularyName"}
                  id={"formularyName"}
                  placeholder={"Formulary Name"}
                  validator={register({
                    required: "Please enter formulary name",
                  })}
                  errors={errors}
                  onChange={handleNameChange}
                  defaultValue={defaultName}
                  required={true}                  
                  disabled={
                    (userDetails?.role === "ExternalAdministrator") || ( defaultName &&
                    (formularyData?.defaultType === "acute" ||
                      formularyData?.defaultType === "postacute"||
                      formularyData?.defaultType === "alternatesite"||
                      formularyData?.defaultType === "international"||
                      formularyData?.defaultType === "miscellaneous"||
                      formularyData?.isDisabled === 1))
                      ? true
                      : false
                  }
                />
              </div>
              <div className="col-4">
                <SelectBox
                  id={"division"}
                  name={"division"}
                  label={"Division:"}
                  classes={{
                    column: `col-12 p-0  ${styles.field_max}`,
                    inputClassName: styles.basic_select,
                    labelClassName: `font-weight-bold ${styles.formulary_label} ${styles.label_spec}`,
                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`,
                  }}
                  optionValue={divisionOptions}
                  onChange={(e) => handleDropDownChange(e, "division")}
                  placeholder={"Select"}
                  //validator={register({ required: 'Please Select Division' })}
                  validator={register()}
                  errors={errors}
                  defaultValue={editMode ? formularyData?.division : ""}
                  // required={true}
                />
              </div>
              <div className="col-4">
                <SelectBox
                  id={"productCategoryDesc"}
                  name={"productCategoryDesc"}
                  label={"Product Category Description:"}
                  classes={{
                    column: `col-12 p-0  ${styles.field_max}`,
                    inputClassName: styles.basic_select,
                    labelClassName: `font-weight-bold ${styles.formulary_label}`,
                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`,
                  }}
                  optionValue={productCategoryDescOptions}
                  onChange={(e) =>
                    handleDropDownChange(e, "productCategoryDesc")
                  }
                  placeholder={"Select"}
                  validator={register}
                />
              </div>
              <div className="col-12 p-0">
                <div className="pt-2"></div>
              </div>
              <div className="col-4">
                <SelectBox
                  id={"productCategoryCode"}
                  name={"productCategoryCode"}
                  label={"Product Category Code:"}
                  classes={{
                    column: `col-12 p-0  ${styles.field_max}`,
                    inputClassName: styles.basic_select,
                    labelClassName: `font-weight-bold ${styles.formulary_label}`,
                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`,
                  }}
                  optionValue={productCategoryCodeOptions}
                  onChange={(e) =>
                    handleDropDownChange(e, "productCategoryCode")
                  }
                  placeholder={"Select"}
                  validator={register}
                />
              </div>
              <div className="col-4">
                <SelectBox
                  id={"productFamilyDesc"}
                  name={"productFamilyDesc"}
                  label={"Product Family Description:"}
                  classes={{
                    column: `col-12 p-0  ${styles.field_max}`,
                    inputClassName: styles.basic_select,
                    labelClassName: `font-weight-bold ${styles.formulary_label}`,
                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`,
                  }}
                  optionValue={productFamilyDescOptions}
                  onChange={(e) => handleDropDownChange(e, "productFamilyDesc")}
                  placeholder={"Select"}
                  validator={register}
                />
              </div>
              <div className="col-4">
                <SelectBox
                  id={"productFamilyCode"}
                  name={"productFamilyCode"}
                  label={"Product Family Code:"}
                  classes={{
                    column: `col-12 p-0  ${styles.field_max}`,
                    inputClassName: styles.basic_select,
                    labelClassName: `font-weight-bold ${styles.formulary_label}`,
                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`,
                  }}
                  optionValue={productFamilyCodeOptions}
                  onChange={(e) => handleDropDownChange(e, "productFamilyCode")}
                  placeholder={"Select"}
                  validator={register}
                />
              </div>
            </div>

            {tinyDelay ? (
              <>
                <div className={styles.blocks}>
                  <div className={styles.left_item}>
                    <div className={`font-weight-bold ${styles.proCatPos}`}
                    >
                      Product Catalog
                    </div>

                    <ListView
                      columns={columns}
                      data={displayItems}
                      metaData={apiMeta}
                      sorting={true}
                      rowSelect={true}
                      // getSelectedRowData={getSelectedRowData}
                      getSelectedItems={getSelectedItems}
                      detailView={false}
                      pageRangeDisplayed={5}
                      // searchPlaceholder={"Formulary ID, Formulary Name, Customer #, Customer Name"}
                      // getSearchValue={(e) => getSearchValue(e)}
                      // searchKey={(searchKey)?searchKey:''}
                      activePage={activePage}
                      getActivePage={getActivePage}
                      listSorting={sortingPayload}
                      listType={"Formulary"}
                      subHeading={"Formulary"}
                      showFixedColumn={false}
                      formularyView={true}
                      formularyLeft={true}
                      rightFormulary={true}
                      noPage={noPage}
                      apiMaterials={apiMaterials}
                    />

                  </div>
                  <div className={styles.add_block}>
                    <button
                      type="button"
                      className={`btn ${styles.btn_items}`}
                      id="additem"
                      onClick={handleAddItems}
                    >{`>>`}</button>
                    {addedItems && addedItems.length > 0 && (
                      <button
                        type="button"
                        className={`btn ${styles.btn_items}`}
                        id="removeitem"
                        onClick={handleRemoveItems}
                      >{`<<`}</button>
                    )}
                  </div>
                  <div className={`${styles.left_item} mt-1`}>
                    <div
                      className={`font-weight-bold ${styles.my_form} mb-n1 `}
                    >
                      <div className="row">
                        <div className={`col-3 mb-3 font-weight-bold ${styles.procatPosMyForm}`}> My Formulary</div>
                        <div className="col-2 mt-n2">
                          <button
                            className={`${styles.import_block} ml-1`}
                            onClick={() => modalOpenImport()}
                          >
                            {/* Import button */}
                            <img src={ImportIcon} alt="Import" />
                            <label htmlFor="csv">Import</label>
                          </button>
                        </div>

                        <MultiSelect
                          id={"matCodeMul"}
                          name={"matCodeMul"}
                          label={``}
                          classes={{
                            column: `col-7 ${styles.fieldSize}`,
                            inputClassName: styles.basic_select,
                            inputColumn: `col-11 ml-4 p-0 ${styles.select_SearchWrapper} ${styles.searchPositon}`,
                          }}
                          placeholder={"Enter Material #"}
                          optionValue={matCodeMulOpt}
                          displayValue="valueCode"
                          showCheckbox={true}
                          onSelect={(list, chkItems) =>
                            selectMatCode(list, chkItems)
                          }
                          defaultValue={[]}
                          validator={(e) => {
                            matCodeMulOptRef.current = e;
                          }}
                          closeOnSelect={false}
                        />
                      </div>
                    </div>

                    <NewFormulary
                      addedItems={addedItems}
                      dispatch={dispatch}
                      editMode={editMode}
                      formularyId={formularyId}
                      getRightSelectedItems={getRightSelectedItems}
                      formularyName={formularyName}
                      addedMeta={addedMeta}
                      setSeletedMaterial={setSeletedMaterial}
                      seletedMaterial={seletedMaterial}
                      changeMaterial={changeMaterial}
                    />
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="text-center pb-4">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Button
                    classes={{
                      className: `btn ${styles.btn_custom} ${styles.btn_small}`,
                    }}
                    type="submit"
                    id={SUBMIT_ID}
                    onClick={checkAdded}
                  >
                    {SAVE_BTN}
                  </Button>
                  <Button
                    classes={{
                      className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small_cancel}`,
                    }}
                    id={CANCEL_ID}
                    onClick={handleCancel}
                  >
                    {CANCEL_BTN}
                  </Button>
                </div>
              </>
            ) : (
              <div className={styles.loader}>
                <img src={Loader} alt="loading" />
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    materials: state.formulary.materials,
    addedItemsApi: state.formulary.addedItems,
    newPayload: state.formulary.newPayload,
  };
}

CreateFormulary = connect(mapStateToProps)(withRouter(CreateFormulary));
