import { createSlice } from '@reduxjs/toolkit';
import { OPTION_TYPES, SUCCESS_STATUS, SUPPORT_LIST } from '../constants';
import { Service } from '../service';
import { constructEndpoint } from '../utils/commonHelper';
import { setError, setLoading } from './appSlice';
const { request } = Service;

export const supportSlice = createSlice({
    name: 'support',
    initialState: {
      supportListData:{},
      questions:{},
      applicationPageData:{},
      supportTopicData: [],
      sucessStatus:{}
    },
    reducers: {
      setSupportListData:(state,action)=>{
        state.supportListData=action.payload
      },
      
      setQuestionsData:(state,action)=>{
        state.questions=action.payload
      },
      setApplicationPageData:(state,action)=>{
        state.applicationPageData=action.payload
      },
      setSupportTopicData:(state,action)=>{
        state.supportTopicData=action.payload
      },
      setSucessStatus: (state, action) => {
        state.sucessStatus = action.payload;
      }
    }
})
export const {
  setSupportListData,
  setQuestionsData,
  setApplicationPageData,
  setSupportTopicData,
  setSucessStatus
} = supportSlice.actions;

// Get support list data.
export const getSupportListDataAsync = (payload, history) => async dispatch => {
    const url = payload.url;
    const queryParam = payload.queryParam && payload?.queryParam
    const method = payload.method
    try {
      dispatch(setLoading(true));
      const data = await request(constructEndpoint(url, queryParam), {
        method: method
      });
      
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setSupportListData(data.data))
      }
    }
    catch (error) {
      const obj = { ...error }
      dispatch(setError(obj.errorMessage))
      if(obj?.response?.status === 405 || obj?.response?.status === 404 ){
        window.location='/unAuthorized';
      }
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

 // get question number from particular contexual support
export const getSupportQuestionsAsync = (payload, history) => async dispatch => {
  dispatch(setQuestionsData({}))
    try {
      dispatch(setLoading(true));
      const data = await request(`${SUPPORT_LIST}/${payload}`, {
        method: 'GET',
      });
      if (data && data.status === SUCCESS_STATUS) {
        dispatch(setQuestionsData(data.data))
      }
    }
    catch (error) {
       /* If there are error, dispatch setError action */
       const obj = { ...error }
      dispatch(setError(obj.errorMessage))
      dispatch(setQuestionsData(obj))
      if(obj?.response?.status === 405 || obj?.response?.status === 404 ){
        history.push('/unAuthorized')
      }
    } 
    finally {
      dispatch(setLoading(false));
    }
  }

    // Add/Update/Delete support questions
    export const addUpDateDeleteQuestionAsync = (payload, history) => async dispatch => {
      try {
        dispatch(setLoading(true));
        const data = await request(`${SUPPORT_LIST}/${payload.id}/${"questions"}`, {
          method: 'POST',
          body: JSON.stringify(payload)
        });
        if (data && data.status === SUCCESS_STATUS) {
          dispatch(setSucessStatus(data.status))
          dispatch(getSupportQuestionsAsync(payload.id))
        }
        if (data && data.status==='error') { 
          dispatch(setSucessStatus('error'))
        }
      }
      catch (error) {
        dispatch(setSucessStatus('error'))
        const obj = { ...error }
        /* If there are error, dispatch setError action */
        dispatch(setError(obj.errorMessage))
      } 
      finally {
        dispatch(setLoading(false));
      }
    }

    export const optionTypeAsync = (payload) => async dispatch => {
      try {
        dispatch(setLoading(true));
        const data = await request(`${OPTION_TYPES}/${payload}`, {
          method: 'GET',
        });
        if (data && data.status === SUCCESS_STATUS) { 
          if (payload === 'applicationpage') {
            dispatch(setApplicationPageData(data.data.options))
          }
        }
      }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    }
    finally {
      dispatch(setLoading(false));
    }
  }

    export const getSupportTopicsAsync = (payload) => async dispatch => {
      try {
        dispatch(setLoading(true));
        const data = await request('/supporttopics?page='+payload.page+"&application="+payload.application, {
          method: 'GET',
        });
        if (data && data.status === SUCCESS_STATUS) { 
            dispatch(setSupportTopicData(data.data))
        }
      }
    catch (error) {
      const obj = { ...error }
      /* If there are error, dispatch setError action */
      dispatch(setError(obj.errorMessage))
    }
    finally {
      dispatch(setLoading(false));
    }
  }
  
  export const cloneQuestionAsync = (payload) => async dispatch => {
    const modifiedPayload ={
      id:payload.id,
      replaceQuestions:payload.replaceQuestions
    }
    try {
      dispatch(setLoading(true));
        const data = await request(`${SUPPORT_LIST}/${payload.supportDataId}/${"clone"}`, {
          method: 'POST',
          body: JSON.stringify(modifiedPayload)
        });
      if (data && data.status===SUCCESS_STATUS) { 
        if (data && data.status!=='error') { 
          dispatch(setSucessStatus(data.status))
        }
          payload.setCbState(data.message);
          dispatch(getSupportQuestionsAsync(payload.supportDataId))
        }
        if (data && data.status==='error') { 
          payload.setCbState(data.message);
          dispatch(getSupportQuestionsAsync(payload.supportDataId))
        }
    }
  catch (error) {
    dispatch(setSucessStatus('error'))
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

  export default supportSlice.reducer;
