import React from "react";
import PropTypes from 'prop-types';
import style from '../TextField/textField.module.scss';
export function TextArea({ classes, id='', label, name='', placeholder='', disabled=false, rows, cols, validator, errors, value,onChange,required,charLimit=false, onKeyPress }) {
    const errorMessage = errors && errors[name] && errors[name].message;
    
    return (
        <div className={(classes) ? classes.column : ''}>
            {/* Label  */}
            {label && <label htmlFor={id} className={(classes) ? classes.labelClassName : ''}>{label}{required ? <span className={style.astrick}> &#42;</span> :''}</label>}
            {/* Inner div */}
            <div className={(classes) ? classes.inputColumn : ''}>
                {/* Input */}
                <textarea
                    name={name} 
                    id={id} 
                    className={`${(classes) ? classes.inputClassName : ''} ${errorMessage ? `${style.error_border}` : ''}`}
                    placeholder={placeholder}
                    disabled={disabled}
                    ref={validator}
                    autoComplete="off"
                    rows={rows}
                    cols={cols}
                    defaultValue={value}
                    onChange={onChange}
                    maxLength={charLimit?charLimit:''}
                    onKeyPress={onKeyPress}
                />  
                 {/* Char Count  */}
                {
                    charLimit && <span className={`${style.char_count}`}>
                        <font className={`${style.char_count_font}`}>
                            <font className={`${style.char_count_font}`}>
                                {value != 0 ? value.length : 0}/{charLimit}
                            </font>
                        </font>
                    </span>
                }
                {/* Error */}
             {errorMessage && <span className={style.error_message}>{errorMessage}</span>}          
            </div>
        </div>
    );
}

TextArea.propTypes = {
    id:PropTypes.string,
    classes:PropTypes.object,
    label:PropTypes.string,
    name:PropTypes.string,
    placeholder:PropTypes.string,
    disabled:PropTypes.bool,
    validator:PropTypes.func,
    errors:PropTypes.object,
    value: PropTypes.string,
}
