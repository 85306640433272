import React from 'react'
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import Loader from '../../../static/images/spinner.svg';
import { Button, Form } from '../../primary';
import './anatomyEdit.css'

export default function AnatomyEdit({ handleSelect, loading, cancel, styles, setCloseWarning, bodyPart, Viewer }) {
    return (

        <div>
            <div className="row">
                <div id="front" className={`col-6 col_spec text-center ${styles.svg_outer}`}>

                    {/* Left and Right */}
                    <div className={styles.left_right}>
                        <span>R</span>
                        <span>L</span>
                    </div>

                    {/* Below component is used to implement SVG zoom */}
                    <UncontrolledReactSVGPanZoom
                        ref={Viewer}
                        width={205} height={477} // Set width and height
                        background="#ffffff" // Background color
                        miniatureProps={{ // Small box at bottom left
                            position: 'none'
                        }
                        }
                        detectAutoPan={false} // Prevent auto panning
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="181"
                            height="477"
                            viewBox="0 0 181 477"
                            id="svg_out"
                        >
                            <g
                                id="Page-1"
                                fill="none"
                                fillRule="evenodd"
                                stroke="none"
                                strokeWidth="1"
                            >
                                <g
                                    transform="translate(-80 -21)"
                                >
                                    <g id="Group" transform="translate(80.35 21.03)">
                                        <path
                                            stroke="#7A94BB"
                                            strokeLinecap="round"
                                            d="M136.15 452.5L146.15 452.47"
                                        ></path>
                                        <path
                                            id="Foot_Left_Plantar"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M128.65 444.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 
                                            01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                            <title>Foot Left, Plantar</title>
                                        </path>
                                        <path
                                            id="Toes_Right"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M47.8899994,459.681671 C51.9117133,462.062322 55.427025,463.805919 58.992981,465.124834 C62.7699452,466.521794 66.4562406,467.342236 70.5372009,467.848226 L68.6235115,472.895748 C67.078889,476.159299 63.8585838,477.113517 61.5417703,476.33346 C59.997228,475.813422 59.3224031,474.796177 59.5172956,473.281725 C58.8769974,474.79789 58.2859589,475.456459 57.7455135,475.71369 L57.3800345,475.865789 C57.1812376,475.945276 57.0673429,475.973342 56.7271891,475.983159 L56.4247882,475.987618 L55.7317944,475.988277 C54.7271422,475.988277 53.8447995,475.187033 53.0847663,473.584545 C50.5098728,474.292171 49.2961288,472.316781 49.2208579,471.263972 C48.4785157,471.232156 47.9777548,471.153705 47.7185753,471.02862 C46.8984357,470.631659 46.3025407,469.202286 46.6459644,467.848226 C46.130648,468.016702 45.6727497,468.009903 45.2722697,467.827828 C43.8106609,467.164135 44.0474446,465.561662 44.8400016,463.981032 L44.8722257,463.920908 L44.9656944,463.766787 L45.1746109,463.454367 L45.4598417,463.049962 L47.8899994,459.681671 Z"
                                        >
                                            <title>Toes Right</title>
                                        </path>
                                        <path
                                            id="Foot_Right_Dorsal"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M60.225 429.333c3.181.813 6.17 1.375 8.968 1.684 2.508.276 5.031.369 7.57.277a166.375 166.375 0 00-.02 8.015l-.009.083c.237 
                                            1.854.284 3.782.24 5.293l-.097 1.64-.01.114c-.1.96-.57 1.704-1.164 2.427l-.306.36-.501.569c-.764.865-1.554 
                                            1.818-1.979 3.171-.275.877-.42 2.127-.517 3.528l-.074 1.232-.044.832-.07 1.172-.075 
                                            1.078-.054.668-.056.626-.088.863-.063.523-.065.483-.102.646-.071.38c-.036.18-.073.344-.112.492l-.077.277c-.053.17-.107.314-.163.43l-.264.665a49.621 
                                            49.621 0 01-11.894-2.782c-3.316-1.226-6.865-2.95-10.645-5.171 
                                            1.264-1.762 2.698-3.821 4.033-5.942l.405-.65.397-.653.387-.652.564-.974.54-.961.346-.63.494-.921.465-.888.43-.845.52-1.047.451-.94.376-.813.23-.516.23-.546.082-.208.219-.586.193-.538.168-.494.211-.662.166-.58.089-.345.072-.316.06-.29.046-.268.036-.248.038-.343.025-.316.228-3.908.144-2.035.097-1.46z"
                                        >
                                            <title>Foot Right, Dorsal</title>
                                        </path>
                                        <path
                                            id="Leg_Lower_Right_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M58.402 330.012c4.24 1.176 8.883 2.218 13.93 3.126 5.869 1.055 11.323 1.757 16.365 2.106l-.39 4.363-.613 6.55-.703 
                                            7.27-.634 5.54-.897 7.461-.381 3.036-.353 2.7-.266 1.943-.185 1.284-.255 1.69-.437 2.797-.593 3.659-.786 
                                            4.705-1.024 5.96-1.034 5.891c-.44 2.309-.825 4.586-1.168 6.907l-.25 1.75c-.274 1.981-.52 4.007-.751 6.12l-.225 
                                            2.146-.192 1.965-.186 2.027-.092 1.039a152.37 152.37 0 00-.411 6.239 48.58 48.58 0 01-7.349-.251c-2.817-.311-5.856-.896-9.118-1.755l.104-2.07c.063-1.366.115-2.706.156-4.02l.053-1.952c.17-7.408-.031-13.956-.606-19.644l-.281-2.663-.223-2.163-.31-3.14-.448-4.832-.32-3.669-.349-4.228-.382-5.02-.282-4.208-.065-1.268-.157-2.356-.095-1.53-.128-2.233-.11-2.16-.065-1.399-.056-1.366-.048-1.334-.04-1.3-.046-1.89-.021-1.22-.013-1.186-.006-1.154.007-1.67.014-1.071.022-1.04.03-1.006.037-.974.046-.941.053-.908.06-.876.07-.843.037-.41c.141-1.495.378-3.199.71-5.112l.212-1.173.234-1.223.204-1.016z"
                                        >
                                            <title>Leg Lower Right, Anterior</title>
                                        </path>
                                        <path
                                            id="Knee_Right_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M61.004 297.879c4.825 1.25 10.188 2.3 16.091 3.15 6.507.938 12.496 1.464 17.97 1.577l-.133.72-.267 1.407c-.09.462-.18.916-.271 
                                            1.363l-.275 1.32-.278 1.276c-.093.419-.186.83-.28 1.233l-.285 1.189c-.81 3.307-1.66 6.088-2.551 8.344-.822 
                                            4.007-1.409 8.205-1.76 12.595l-.015.201c-4.94-.34-10.303-1.029-16.086-2.069-5.028-.904-9.638-1.942-13.83-3.11l.328-1.434.173-.736.363-1.51.487-1.96.523-2.038.675-2.552.48-1.765c.164-.596.332-1.202.503-1.815l-1.562-15.386z"
                                        >
                                            <title>Knee Right, Anterior</title></path>
                                        <path
                                            id="Leg_Upper_Right_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M57.335 236.855c15.38 8.094 30.188 12.399 43.406 12.878l-.133 3.266h-.077l-1.727 21.433-.209 2.071-.212 2.029-.215 1.987-.219 
                                            1.945-.222 1.903-.225 1.862-.228 1.82-.231 1.777-.235 1.736-.238 1.694-.24 1.652-.245 1.61-.248 1.57-.25 1.526c-5.466-.089-11.489-.606-18.064-1.554-6.245-.899-11.86-2.023-16.843-3.37l-2.903-28.636-.033-.21c-.836-9.851-.85-19.112-.438-28.38l.029-.61z"
                                        >
                                            <title>Leg Upper Right, Anterior</title>
                                        </path>
                                        <path
                                            id="Leg_Upper_Left_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M101.741 249.766l.3.001c14.61.144 29.54-4.435 44.778-13.706l.03.768.066 1.91.082 2.792.02.872.033 1.127.044 1.811.036 1.77.028 1.73.021 1.686.02 2.449.004 1.578-.003 
                                            2.363-.02 2.756-.043 3.081-.048 2.376-.093 3.424-.113 3.28-.2 5.153-.016.795a38.077 38.077 0 01-.7 7.131c-.528 5.732-.942 
                                            11.373-1.08 17.054-3.97.593-8.24 1.042-12.809 1.346-6.607.439-12.587.492-17.938.16a87.28 87.28 0 00-.63-2.109l-2.535-7.627-.585-1.782-.845-2.623-.543-1.714-.527-1.686-.51-1.66-.492-1.631-.709-2.396-.562-1.95-.536-1.907-.61-2.231-.384-1.454-.37-1.426-.351-1.399-.17-.689-.327-1.357-.311-1.33-.294-1.303-.41-1.903-.253-1.234-.242-1.248-.034-.335-.161-2.27-.213-3.395.205-5.048z"
                                        >
                                            <title>Leg Upper Left, Anterior</title>
                                        </path>
                                        <path
                                            id="Knee_Left_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M144.84 305.007c-.026 3.81.085 7.65.378 11.559.38 5.038.93 9.978 1.527 14.88l.264 2.122c-4.56.762-9.536 1.322-14.931 1.681-4.694.312-9.072.43-13.133.352l-.01-.157-.033-.345-.045-.387-.058-.43-.112-.722-.14-.817-.17-.912-.13-.66-.218-1.07-.336-1.572-.286-1.29-.272-1.158c-.534-2.337-.965-4.72-.923-7.198l.018-.573c.19-4.061-.36-7.96-1.28-11.786 5.226.276 11.002.203 17.327-.218 4.461-.296 8.649-.73 12.563-1.3z"
                                        >
                                            <title>Knee Left, Anterior</title>
                                        </path>
                                        <path
                                            id="Leg_Lower_Left_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M147.38 336.547l.796 6.347c1.105 9.016 2.045 18.065 2.045 27.53 0 3.84-.229 7.834-.527 11.912l-.269 3.514-.407 5.178-.689 14.779c.002 4.086.227 8.128.825 12.06a48.726 48.726 0 01-7.312 1.983c-2.952.558-6.233.895-9.842 1.01.644-10.23-.912-22.434-4.062-32.336-.88-2.769-2.074-5.5-3.343-8.223l-2.756-5.858c-1.768-3.845-3.348-7.721-4.08-11.715-.76-4.153-.487-8.319-.007-12.487l.313-2.728.217-1.996.187-1.829.121-1.262.135-1.538.082-1.044.045-.643.038-.603c4.149.081 8.611-.038 13.387-.356 5.442-.361 10.476-.926 15.104-1.695z"
                                        >
                                            <title>Leg Lower Left, Anterior</title>
                                        </path>
                                        <path
                                            id="Toes_Left"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M175.894424,453.714394 L179.420505,458.099736 C180.706525,460.089474 180.60407,462.012122 176.997104,464.284665 C173.995761,466.176449 167.651793,466.644575 163.961008,466.231522 C161.422612,465.950208 158.182729,463.700452 156.429878,462.304134 C160.503296,461.207367 165.172835,459.557474 168.341832,458.099736 C171.222748,456.774514 173.270672,455.563785 175.894424,453.714394 Z"
                                        >
                                            <title>Toes Left</title>
                                        </path>
                                        <path
                                            id="Groin_Left_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M141.211242,211.26741 C138.304989,215.899649 136.894933,220.064061 136.994633,223.767016 C137.110466,228.069176 139.414166,231.602626 143.852847,234.34044 L144.393375,234.020357 C132.58412,241.069821 121.023014,245.190109 109.704116,246.396185 L117.718857,220.136499 C125.290432,218.500744 132.944179,215.637865 140.678492,211.551789 L141.211242,211.26741 Z"
                                        >
                                            <title>Groin Left, Anterior</title>
                                        </path>
                                        <path
                                            id="Path"
                                            fill="#96A6BF"
                                            d="M145.221894,209.016256 L146.236257,224.574724 L146.366508,226.70799 L146.484924,228.80574 L146.591951,230.867338 L146.675063,232.623826 L144.986582,233.764223 L144.916116,233.704256 L144.826528,233.758847 C140.36201,231.148287 138.104083,227.818629 137.994271,223.740102 C137.889665,219.854932 139.581009,215.349392 143.086986,210.23226 L145.221894,209.016256 Z"
                                        ></path>
                                        <path
                                            id="Perineum_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M90.745 220.758c3.85.637 7.615.973 11.295 1.01 4.09.04 8.206-.29 12.346-.99l-7.899 25.883a65.304 65.304 0 01-7.842-.016l-7.9-25.887z"
                                        >
                                            <title>Perineum Anterior</title>
                                        </path>
                                        <path
                                            id="Groin_Right_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M63.2058915,211.773606 L63.8633658,212.09216 C72.0330177,215.892069 79.8847958,218.574726 87.4183332,220.137195 L95.4246921,246.371537 C84.4893846,245.191587 72.7511388,241.394548 60.2121735,234.968869 C64.4098663,232.417043 66.763432,228.965755 67.2351283,224.639317 C67.6875433,220.489729 66.3372036,216.198743 63.2058915,211.773606 Z"
                                        >
                                            <title>Groin Right, Anterior</title>
                                        </path>
                                        <path
                                            id="Path"
                                            fill="#96A6BF"
                                            d="M60.0967095,210.273335 L61.2406918,210.825335 L61.3751024,210.99405 C64.9911505,215.588078 66.6158436,219.974395 66.2760944,224.161958 L66.2410192,224.530933 C65.7855947,228.708124 63.4412063,231.997512 59.1639222,234.426205 L57.5056918,233.547772 L57.5194683,233.289966 C57.6592033,230.549392 57.8343088,227.804238 58.033748,225.038874 L58.2480193,222.174305 L58.360869,220.735447 L60.0967095,210.273335 Z"
                                        ></path>
                                        <path
                                            id="Abdomen_Right"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M65.381 140.45c9.6 3.44 19.393 5.434 29.373 5.984l-.188 71.84c-10.574-1.269-21.893-4.966-33.955-11.102L62.795 194l1.557-9.842L69.016 164l-.031-.895-.037-.74-.049-.764-.059-.787-.07-.81-.081-.832-.093-.855-.103-.878-.114-.9-.126-.924-.136-.947-.147-.969-.158-.992-.17-1.015-.18-1.038-.19-1.06-.308-1.634-.333-1.685-.235-1.152-.246-1.174-.39-1.805-.379-1.693z"
                                        >
                                            <title>Abdomen: Right</title>
                                        </path>
                                        <path
                                            id="Abdomen_Center"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M110.755 146.128l-.19 72.17a66.083 66.083 0 01-15 .088l.188-71.902c2.917.133 5.85.142 8.799.028 1.699-.065 3.362-.16 4.99-.285l1.213-.099z"
                                        >
                                            <title>Abdomen: Center</title>
                                        </path>
                                        <path
                                            id="Abdomen_Left"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M141.118 137.873l-3.82 24.909c-.046.396-.08.802-.103 1.216l1.422 10.003c1.601 5.13 2.882 9.997 3.844 14.602l.23 1.126.152.842.17 1.045.186 1.25.159 1.133.11.345.2.655c.094.316.178.615.252.898l.133.543c.14.607.226 1.123.258 1.547l.009-.076.008-.02.014.014.018.068.028.175.092.858.218 2.622.289 4.036c-11.369 6.875-22.507 11.04-33.42 12.508l.188-72.134c11.744-1.116 21.533-3.832 29.363-8.165z"
                                        >
                                            <title>Abdomen: Left</title>
                                        </path>
                                        <path
                                            id="Neck_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M88.822 49.908c5.223 3.695 9.644 5.991 13.3 6.881 1.14.278 2.556.47 4.219.588l.46.03.88.046.455.019.94.027.978.016 1.017.005a131.819 131.819 0 005.93-.159l-.134.177-.361.5-.395.581-.34.523-.36.576-.383.627-.615 1.039.244 1.48.066.441c.301 2.222.151 4.686-.458 7.395l-.128.545-.116.45c-.633 2.363-1.602 4.302-2.902 5.836l-.264.301-.11.117h-8.532l-.206-.244c-2.68-3.206-5.302-7.543-7.855-13.009-2.33-4.987-4.095-9.871-5.297-14.652l-.033-.136z"
                                        >
                                            <title>Neck Anterior</title>
                                        </path>
                                        <path
                                            id="Shoulder_Left_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M117.754 63.371l.443.214.486.242.442.227.396.211.352.197c.11.063.211.124.306.182l32.067 17.436c2.4 2.108 4.428 4.443 6.083 7.004-1.905 6.35-5.681 10.752-11.398 13.291l-.402.174-.364.15-.062-.312c-1.15-5.428-3.794-10.186-7.914-14.243-3.695-3.64-9.965-6.508-18.828-8.666l-.622-.15-1.215-.278c-.41-.092-.825-.183-1.245-.271l-.634-.133-1.388-.277c1.258-1.796 2.188-3.937 2.794-6.41.755-3.089.993-5.952.703-8.588z"
                                        >
                                            <title>Shoulder Left, Anterior</title>
                                        </path>
                                        <path
                                            id="Shoulder_Right_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M87.682 56.661a90.342 90.342 0 003.752 9.305c2.262 4.842 4.595 8.864 7.008 12.069-13.257.407-23.318 3.023-30.18 7.906l-.379.273-.4.3c-6.349 4.821-10.019 11.21-10.98 19.084l-.068.615-.571-.555-.562-.535-.558-.52-.736-.673a66.716 66.716 0 00-1.628-1.422l-.534-.445-.706-.572-.523-.412-.52-.398-.686-.509c-5.033-3.66-9.431-5.242-13.2-4.656l-.029.26c1.05-10.093 5.848-15.813 11.274-19.214l40.226-19.9z"
                                        >
                                            <title>Shoulder Right, Anterior</title>
                                        </path>
                                        <path
                                            id="Cubital_Fossa_Right_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M33.252 142.92c2.772.144 6.139.82 10.085 2.04 4.517 1.396 8.292 3.04 11.323 4.922l-.328 1.059-.107.394-2.479 13.803c-4.127-.495-7.636-1.19-10.523-2.082a49.643 49.643 0 01-3.88-1.397l-.605-.248-.996-.422-1.03-.452-.526-.238-.535-.246-1.095-.514-.56-.269-.674-.328c.587-1.89 1.23-3.83 1.93-5.82V142.92z"
                                        >
                                            <title>Cubital Fossa Right, Anterior</title>
                                        </path>
                                        <path
                                            id="Arm_Lower_Right_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M30.45 161.853l.851.411c3.444 1.64 6.454 2.86 9.036 3.658 3.03.937 6.655 1.66 10.877 2.173l-2.187 12.188-8.022 24.412c-2.09-1.153-4.53-2.158-7.318-3.02-2.376-.734-4.669-1.26-6.877-1.578l.06-1.634.038-1.328.031-1.296.025-1.263.026-1.834.02-2.333.02-6.696.017-1.788.023-1.257.023-.799.028-.767.036-.734.044-.704.053-.671.062-.64c.034-.312.071-.612.113-.9.61-4.182 1.617-8.715 3.021-13.6z"
                                        >
                                            <title>Arm Lower Right, Anterior</title>
                                        </path>
                                        <path
                                            id="Wrist_Right_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M26.468 203.08c2.022.294 4.133.781 6.333 1.461 2.838.878 5.254 1.9 7.249 3.06l-1.827 5.554.088.01-.623 2.846c-1.779-.871-3.78-1.65-6-2.336-2.534-.783-4.973-1.33-7.315-1.638l.327-1.456.394-1.681.25-1.003.256-.974.202-.804.184-.764.165-.723.147-.681.17-.872z"
                                        >
                                            <title>Wrist Right, Anterior</title>
                                        </path>
                                        <path
                                            id="Hand_Right_Palmer"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M23.573 214.96c2.291.274 4.7.8 7.228 1.581 2.368.732 4.442 1.565 6.223 2.495l-1.653 7.538-.762 5.15-.424 2.752-.356 2.207-.147.853c-3.347-.902-6.47-2.128-9.375-3.677-3.992-2.13-8.222-5.58-12.677-10.352l.335-.279.959-.783.611-.493.877-.695.828-.642.524-.4.502-.374.48-.352.46-.327.646-.448.404-.27.382-.245.36-.221.339-.199.316-.174.295-.151c.047-.024.093-.046.139-.067l.261-.116a5.07 5.07 0 01.123-.049c1.44-.357 2.456-1.075 3.048-2.154l.054-.108z"
                                        >
                                            <title>Hand Right, Palmer</title>
                                        </path>
                                        <path
                                            id="Fingers_Right"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M9.33 225.44c4.707 5.06 9.224 8.75 13.565 11.066 3.154 1.683 6.543 3.005 10.165 3.969l-1.972 7.558-.889 3.327-.56 2.024-.24.823-.11.354-.08.232a.648.648 0 01-.037.08c-.744 1.288-1.507 1.51-2.377 1.402-1.045-.13-1.358-1.346-1.214-3.2l.043-.456.028-.237 1.803-8.803.185-.969.025-.166c.013-.332-.061-.339-.172-.157l-.064.117-1.431 4.89-1.018 3.407L23.97 254l-.403 1.284-.464 1.448-.42 1.273-.304.893-.276.78-.248.67-.219.556-.191.445-.125.26-.109.198-.064.097-.057.068a.181.181 0 01-.071.05c-1.474.441-2.571-.512-2.574-1.922l.004-.075.032-.243.088-.477.208-.992.31-1.37.922-3.876 1.436-5.83.226-.909 1.066-5.42-5.277 19.282-.129.433c-.89 2.144-2.492 2.527-3.833 1.4-1.035-.871-1.144-1.504-.842-3.036l.138-.647 4.54-17.068.482-2.229-5.693 17.42c-1.12 2.117-2.347 2.831-3.3 1.546-1.244-1.682-.838-4.562-.291-6.483l.128-.425 4.613-14.772.133-.639.12-.595.105-.55.09-.507.076-.463.062-.419.048-.374.033-.33.018-.287a3.221 3.221 0 00-.006-.442c-.1-1.014-.858-1.253-1.156-1.296l-.084-.008-.035.003-1.893 1.99-.308.282-.598.536-.29.253-.565.48c-1.933 1.614-3.444 2.545-4.533 2.792-4.778 1.087-5.462-2.626-3.37-4.088l1.858-1.67 1.77-1.574 1.684-1.481 1.205-1.05 1.156-.995.537-.458z"
                                        >
                                            <title>Fingers Right</title>
                                        </path>
                                        <path
                                            id="Cubital_Fossa_Left_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M166.554 146.842l.144.61.316 1.276.344 1.344.373 1.41.4 1.479.43 1.546.459 1.613.487 1.681.693 2.347.366 1.218.147.477c-2.865 1.663-6.317 3.067-10.357 4.206-4.802 1.353-8.75 1.857-11.827 1.533l-.35-.04-.052.39-.021-.786-.021-1.265a94.867 94.867 0 00-1.224-14.21c3.183.077 6.949-.506 11.309-1.735 3.104-.874 5.899-1.905 8.384-3.094z"
                                        >
                                            <title>Cubital Fossa Left, Anterior</title>
                                        </path>
                                        <path
                                            id="Arm_Lower_Left_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M171.61 164.784l.392 1.327.275.948.26.91.242.873.334 1.236.298 1.15.262 1.064.154.66.202.92.114.566.098.526.083.489.033.22.062.465.056.497.049.529.043.56.037.592.03.624.024.656.017.687.012.72.004.75-.001.784-.008.814-.023 1.282-.038 1.353-.053 1.424-.066 1.496-.053 1.038-.09 1.615-.105 1.687-.118 1.758-.134 1.83-.2 2.55-.225 2.679-.06.69-.076 1.54c-2.686.11-5.523.584-8.511 1.422a44.344 44.344 0 00-7.24 2.717l-.382-.996-1.81-4.614c-.3-.768-.598-1.537-.889-2.307-3.564-9.421-5.766-19.687-6.364-29.946 3.502.362 7.804-.19 12.925-1.633 3.991-1.125 7.472-2.507 10.44-4.152z"
                                        >
                                            <title>Arm Lower Left, Anterior</title>
                                        </path>
                                        <path
                                            id="Wrist_Left_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M173.292 207.304l-.482 9.753.012.284.017.304a41.01 41.01 0 00-4.91 1.07 44.554 44.554 0 00-6.486 2.364 36.12 36.12 0 00-.118-.654c-.595-3.13-1.522-6.168-2.588-9.165a41.106 41.106 0 017.002-2.656c2.67-.749 5.187-1.181 7.553-1.3z"
                                        >
                                            <title>Wrist Left, Anterior</title>
                                        </path>
                                        <path
                                            id="Hand_Left_Palmer"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M173.127 220.636l.115.876.12.83.134.871.149.91.163.947.177.987.191 1.026.314 1.612 2.559 11.208c.822 4.108 1.046 7.517.624 11.27-1.304-3.55-3.325-6.34-6.076-8.345-3.974-2.897-8.463-3.416-13.317-1.61l-.113-1.655-.06-.82c.262-2.06.669-3.842 1.22-5.348l1.837-4.982c.407-1.325.636-2.587.607-4.17a41.15 41.15 0 016.968-2.64 38.214 38.214 0 014.388-.967z"
                                        >
                                            <title>Hand Left, Palmer</title>
                                        </path>
                                        <path
                                            id="Fingers_Left"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M169.83 245.252c3.37 2.457 5.47 6.442 6.264 12.037l-.137.182-.28.35-.477.564-.353.401-.378.418-.608.652-.429.45-.672.69-.464.466-.713.705-1.04 1.005-.978.922-.721.665-.701.635-.672.596-.634.55-.397.335-.55.454-.334.265-.304.233-.27.198-.236.16-.198.121c-.059.034-.111.06-.157.08l-.932.39-.296.117-.26.094c-.482.165-.76.184-.975.029l-.053-.042c-.713-.536-.533-1.474.117-2.682l4.438-6.454c1.263-1.912 2.358-3.928 2.699-5.864.82-4.662-.635-7.53-5.54-7.857-.286.001-.514.502-.717 1.302l-1.694 8.828c-.442 1.419-1.017 2.342-1.83 2.144-.984.28-1.518-.536-1.763-1.818l-.06-.36-.028-.766-.028-1.051-.01-.636-.002-.584.006-.533.013-.48.02-.428c.009-.134.018-.26.029-.377l.018-.182.053-.404.034-.223.084-.485.104-.54.125-.595.07-.317.156-.676.273-1.115.01-.074.018-.18.012-.224.007-.266v-.478l-.01-.575-.022-.645c4.3-1.875 8.031-1.562 11.373.873z"
                                        >
                                            <title>Fingers Left</title>
                                        </path>
                                        <path
                                            id="Arm_Upper_Right_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M47.53 102.507l.374.273.618.465.313.242.314.247.798.646.811.683.66.574.67.598.338.308.34.314.688.647.697.67.352.345.711.708.36.363.724.744 2.751 2.878 2.77 13.82-3.08 9.78-.364.869.08.033-1.975 6.276-.908 2.937c-3.14-1.856-6.924-3.465-11.349-4.833-4.226-1.306-7.879-2.036-10.972-2.177v-1.31l2.688-39.95c3.017-.798 6.884.45 11.592 3.85z"
                                        >
                                            <title>Arm Upper Right, Anterior</title>
                                        </path>
                                        <path
                                            id="Chest_Right"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M94.923 81.205l-.162 62.225c-10.267-.582-20.33-2.74-30.191-6.475l-.617-2.544-.514-2.062-.538-2.113-.373-1.437-.383-1.46-.015-.032-2.901-14.476.02-3.142.007-.489c.178-8.769 3.63-15.58 10.406-20.57 5.709-4.204 14.129-6.694 25.261-7.425z"
                                        >
                                            <title>Chest Right</title>
                                        </path>
                                        <path
                                            id="Chest_Left"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M111.924 80.986l.56.101 1.286.244 1.255.25 1.224.26.6.132 1.175.27c8.7 2.056 14.727 4.756 18.06 8.038 4.056 3.994 6.494 8.69 7.342 14.133l.074.513.243 1.782.011-.005-2.011 27.083-.066.43-.05-.089-1.301.748-.513.29c-7.401 4.13-16.75 6.754-28.05 7.857l.161-62.037z"
                                        >
                                            <title>Chest Left</title>
                                        </path>
                                        <path
                                            id="Chest_Center"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M110.924 80.948l-.161 62.168c-2.046.182-4.155.315-6.327.399-2.908.112-5.8.1-8.675-.033l.162-62.338a113.06 113.06 0 016.117-.167l.752.001-.001-.03h8.133z"
                                        >
                                            <title>Chest Center</title>
                                        </path>
                                        <path
                                            id="Arm_Upper_Left_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M160.281 92.563c1.915 3.967 3.06 8.402 3.437 13.307l1.398 32.737.024.407.036.438.063.589.083.635.08.543.094.572.106.603.118.633.132.662.03.142c-2.47 1.239-5.312 2.312-8.526 3.218-4.398 1.24-8.081 1.766-11.033 1.597a105.723 105.723 0 00-4.278-15.536l1.966-26.49 1.442-.47.443-.149c6.894-2.374 11.711-6.882 14.385-13.438z"
                                        >
                                            <title>Arm Upper Left, Anterior</title>
                                        </path>
                                        <path
                                            id="Head_Posterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M88.9105807,3.47140142 C90.0360491,8.79874352 90.5196724,15.2801676 90.3590023,22.913378 C86.6156149,27.3720439 84.0109908,33.2414724 82.5511389,40.51776 C75.726973,29.7147335 74.6174138,11.7153646 88.4683268,3.7217848 L88.9105807,3.47140142 Z"
                                        >
                                            <title>Head Posterior </title>
                                        </path>
                                        <path
                                            id="Forehead_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M104.493391,0.00706051017 C107.826914,4.30273042 109.986175,8.51666229 110.976905,12.648275 C102.746109,13.5655523 96.208793,16.6057087 91.3778722,21.7625414 C91.4708414,14.4357338 90.9556987,8.18040441 89.8309281,2.99352155 L90.65,2.62464235 C91.3412516,2.31529918 92.0843064,2.0714734 92.8300225,1.78995839 L93.3890408,1.5701428 C95.5888704,0.662949182 98.9519619,0.148872799 103.478315,0.0279136498 L104.493391,0.00706051017 Z"
                                        >
                                            <title>Forehead Anterior</title>
                                        </path>
                                        <path
                                            id="ForeHead_Right"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M105.755879,0.012554902 L105.65,1.33226763e-14 C114.367382,0.963919577 121.569987,6.03137543 125.527661,12.9866334 C120.604766,12.3281924 116.088437,12.1824011 111.980212,12.5485113 C111.016763,8.42767289 108.939957,4.24890141 105.755879,0.012554902 Z"
                                        >
                                            <title>ForeHead Right</title>
                                        </path>
                                        <path
                                            fill="#D8D8D8"
                                            d="M117.067 32.409c4.249 0 4.7-.111 6.103-1.762 1.403-1.651 2.386-2.374 2.386-3.893 0-3.74-3.22-5.38-7.47-5.38s-8.713 1.64-8.713 5.38 3.444 5.655 7.694 5.655z"
                                        ></path>
                                        <path
                                            id="Chin_Anterior"
                                            onClick={handleSelect}
                                            fill="#7A94BB"
                                            d="M124.95 54.426c3.038 0 3.442.192 4.445-.662.686-.584.778-1.477.778-2.262 0-1.934-1.654-3.78-4.691-3.78-3.038 0-6.032 1.846-6.032 3.78 0 1.934 2.462 2.924 5.5 2.924z"
                                        >
                                            <title>Chin Anterior</title>
                                        </path>
                                        <path
                                            id="Face_Anterior"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M126.726 54.483l-.936.059-.886.046-.7.048-.484.045a14.87 14.87 0 00-.8.108l-1.234.107-1.022.079-1.077.076c-2.38.161-4.742.274-6.98.319l-.95.015-.997.006-.96-.004-.922-.014-.447-.011-.863-.03-.822-.042-.78-.053c-1.39-.106-2.558-.269-3.47-.49-2.836-.691-6.337-2.417-10.48-5.18l-.966-.657a82.734 82.734 0 01-.492-.342l-.498-.35-.948-.684c-.564-.412-1.139-.84-1.723-1.286a3.389 3.389 0 00-.828-1.138 20.663 20.663 0 01-1.811-1.795c.498-17.442 9.381-27.376 26.75-29.931 5.913-.87 11.058-.262 15.437 1.833.213.507.41 1.021.59 1.542l.777 2.246.215.655.12.391.102.36.044.17.074.322c.12.57.145 1.04.077 1.527-.038.273-.147.5-.28.722l-.26.401c-.268.414-.532.889-.532 1.663 0 .9.747 2.19 1.713 3.569l.08.11c-2.38.797-4.342 3.013-4.342 5.313 0 2.626 2.106 4.083 4.843 4.315-.118.125-.25.271-.395.44-.7-.435-1.556-.691-2.56-.691-3.116 0-6.188 2.471-6.188 5.06 0 2.519 2.391 3.842 5.391 3.912l.57.004 1.743.023h.329a8.03 8.03 0 00.377-.012l.04-.048c.002-.003.004-.003.003 0l-.053.143-.049.143c-.075.245-.146.62-.037 1.011l.066.217.087.243.313.797c-.693-1.172-2.127-2.043-4.213-2.043-3.038 0-6.032 1.846-6.032 3.78 0 1.934 2.462 2.924 5.5 2.924l.621.003 1.438.016.167-.001-.45.04z"
                                        >
                                            <title>Face Anterior</title>
                                        </path>
                                        <path
                                            id="Lips_Anterior"
                                            onClick={handleSelect}
                                            fill="#7A94BB"
                                            d="M127.098 38.29c1.004 0 1.86.256 2.56.69l-.07.116c-.551 1.044.041 2.73 1.237 3.949-.102 1.144-.58 1.44-1.22 1.65l-.231.072c.855.227 1.11 1.083 1.015 1.889-.545.163-.88.373-1.069.621a8.03 8.03 0 01-.377.012h-.329l-1.743-.023h-.319c-3.116 0-5.642-1.327-5.642-3.916s3.072-5.06 6.188-5.06z"
                                        >
                                            <title>Lips Anterior</title>
                                        </path>
                                        <path
                                            id="Nose_Anterior"
                                            onClick={handleSelect}
                                            fill="#7A94BB"
                                            d="M129.64 29.031l.396.556.413.56 1.459 1.936.39.528.186.257.349.499.252.38c.52.805.845 1.48.8 1.922-.044.442-.453.826-1.002 1.21l-.313.208-.164.106-.804.504a8.793 8.793 0 00-1.137.834c-2.987-.084-5.365-1.554-5.365-4.345 0-2.323 2.003-4.56 4.414-5.336l.126.181z"
                                        >
                                            <title>Nose Anterior</title>
                                        </path>
                                        <path
                                            id="Ear_Right"
                                            onClick={handleSelect}
                                            fill="#7A94BB"
                                            d="M98.015 42.403c3.116 0 3.531.429 4.56-1.476.704-1.303-1.5-3.296-1.5-5.049 0-4.314.602-8.433-2.514-8.433-3.117 0-6.188 4.119-6.188 8.433 0 4.315 2.526 6.525 5.642 6.525z"
                                        >
                                            <title>Ear Right</title>
                                        </path>
                                        <path
                                            id="Eye_Right"
                                            onClick={handleSelect}
                                            fill="#7A94BB"
                                            d="M119.58 29.42c4.532 0 5.136.257 6.632-.886 1.024-.782 1.16-1.977 1.16-3.03 0-2.588-2.466-5.06-6.999-5.06-4.532 0-9 2.472-9 5.06 0 2.59 3.674 3.916 8.207 3.916z"
                                            transform="matrix(-1 0 0 1 238.745 0)"
                                        >
                                            <title>Eye Right </title>
                                        </path>
                                        <path
                                            id="Foot_Left_Dorsal"
                                            onClick={handleSelect}
                                            fill="#96A6BF"
                                            d="M150 420.97c1.173 5.686 3.236 11.1 6.668 16.035 1.73 2.486 4.402 4.482 7.153 6.379l2.576 1.76c1.698 1.17 3.32 2.35 4.657 3.631l4.287 4.246a49.036 49.036 0 01-7.359 4.194c-3.451 1.587-7.499 2.96-12.142 4.113l-.343-.39-1.166-1.537-.823-1.068-.78-.997-.497-.625-.934-1.153-.65-.782-.409-.48-.39-.45-.548-.613-.342-.37-.322-.337-.447-.446-.274-.258-.254-.226a6.878 6.878 0 00-.346-.278l-2.906-2.17-2-1.483-2.048-1.51-2.098-1.54-3.239-2.364-3.35-2.428c-1.645-1.599-2.595-3.575-1.649-6.54l.152-.457c.892-2.575 1.51-5.578 1.874-8.849 3.898-.107 7.45-.464 10.659-1.07a51.884 51.884 0 007.29-1.937z"
                                        >
                                            <title>Foot Left, Dorsal</title>
                                        </path>
                                        <path
                                        id="Knee_Right_Lateral"
                                        onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M57.65 305.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                              <title>Knee Right, Lateral</title>
                                        </path>
                                        <path
                                            id="Ankle_Right_Medial"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M82.65 430.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                            <title>Ankle Right, Medial</title>
                                        </path>
                                        <path
                                            id="Foot_Right_Plantar"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M92.65 447.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                            <title>Foot Right, Plantar</title>
                                        </path>
                                        <path
                                         id="Ankle_Left_Lateral"
                                         onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M157.65 418.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                             <title>Ankle Left, Lateral</title>
                                        </path>
                                        <path
                                        id="Ankle_Right_Lateral"
                                        onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M54.65 429.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                             <title>Ankle Right, Lateral</title>
                                        </path>
                                        <path
                                            id="Ankle_Left_Medial"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M125.65 421.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                            <title>Ankle Left, Medial</title>
                                        </path>
                                        <path
                                         id="Knee_Left_Lateral"
                                         onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M150.65 310.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                             <title>Knee Left, Lateral</title>
                                        </path>
                                        <path
                                            id="Knee_Left_Medial"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M114.65 314.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                            <title>Knee Left, Medial</title>
                                        </path>
                                        <path
                                            id="Knee_Right_Medial"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2"
                                            d="M94.65 312.97c1.933 0 3.683.784 4.95 2.05a6.978 6.978 0 012.05 4.95 6.978 6.978 0 01-2.05 4.95 6.978 6.978 0 01-4.95 2.05 6.978 6.978 0 01-4.95-2.05 6.978 6.978 0 01-2.05-4.95c0-1.933.784-3.683 2.05-4.95a6.978 6.978 0 014.95-2.05z"
                                        >
                                            <title>Knee Right, Medial</title>
                                        </path>
                                        <path
                                            stroke="#7A94BB"
                                            strokeLinecap="round"
                                            d="M74.15 455.47L84.15 455.47"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </UncontrolledReactSVGPanZoom>
                </div>
                <div id="back" className={`col-6 col_spec text-center ${styles.svg_outer}`}>

                    {/* Left and Right */}
                    <div className={styles.left_right}>
                        <span>L</span>
                        <span>R</span>
                    </div>

                    {/* Below component is used to implement SVG zoom */}
                    <UncontrolledReactSVGPanZoom
                        ref={Viewer}
                        width={205} height={500} // Set width and height
                        background="#ffffff" // Background color
                        miniatureProps={{ // Small box at bottom left
                            position: 'none'
                        }
                        }
                        detectAutoPan={false} // Prevent auto panning
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="204"
                            height="491"
                            viewBox="0 0 204 491"
                        >
                            <g
                                fill="none"
                                fillRule="evenodd"
                                stroke="none"
                                strokeWidth="1"
                            >
                                <g
                                    transform="translate(-808 -316)"
                                >
                                    <g id="Group-4" transform="translate(808 316)">
                                        <g id="Group" transform="translate(21.59)">
                                            <path
                                                id="Face_Anterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M54.992 20.167c8.727 2.206 12.293 6.761 10.886 13.775l-.07.33.033.008a32.979 32.979 0 00-.964 3.812c-.963 5.074-.532 9.785.969 12.255l.114.179.086.531a43.3 43.3 0 01.495 6.512c0 1.282-.058 2.46-.172 3.536-2.672-.18-5.156-.91-6.497-2.936-1.346-2.033-.76-3.53-1.579-6.454-.454-1.623-3.05-8.548-3.301-15.698-.1-2.816 1.167-6.767 1.026-9.388-.134-2.514-.477-4.668-1.026-6.462z"
                                            >
                                                <title>Face Anterior</title>
                                            </path>
                                            <path
                                                id="Shape"
                                                fill="#96A6BF"
                                                d="M101.023 10.446c1.21 1.783 2.215 3.844 2.94 6.215 1.843 6.03 1.102 14.28-2.224 24.747l-.403 1.241-.21.629-.44 1.272a36.65 36.65 0 00-.474 1.466l-.247.83-.085.295-1.517.161-1.769.174-.872.082-1.72.15-1.688.136-.832.062-.823.057-1.623.104-.8.046-.79.043-1.558.072-1.525.058c-.671.021-1.332.039-1.983.051l-.97.016-1.428.01c-4.41.008-8.344-.212-11.8-.66l-.685-.093-1.67-.234c-.549-2.388-.567-5.644.087-9.093 1.142-6.016 4.113-12.007 8.879-17.063 3.756-3.986 9.374-7.394 15.072-9.192 2.993-.944 6.713-1.473 11.158-1.582z"
                                            ></path>
                                            <path
                                                id="Shoulder_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M100.33 67.037l22.297 11.997 12.275 4.793c3.769 2.365 7.252 5.786 9.522 10.873-1.23 2.427-4.322 4.838-9.364 7.158l-.478.217-.845.37-.434.185-.895.368-.46.184-.946.365-.98.363c-4.044 1.472-8.284-.098-12.671-4.772l-.48-.523a37.896 37.896 0 01-1.686-2.022l-.485-.634-.242-.326-.486-.67c-.163-.228-.325-.46-.488-.695l-.488-.72a62.349 62.349 0 01-.49-.745l-.49-.77-.49-.795-.247-.406-.493-.832-.494-.857-.619-1.106-.62-1.145-.497-.944-.498-.969-.5-.994-.5-1.019-.5-1.044-.646-1.379a153.4 153.4 0 01-.647-1.42l-.324-.726-.65-1.483c-.52-1.203-1.04-2.446-1.563-3.728l-.392-.97-.654-1.65-.328-.84-.656-1.711-.367-.978z"
                                            >
                                                <title>Shoulder Right, Posterior</title>
                                            </path>
                                            <path
                                                id="Shoulder_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M65.526 66.994l-.357 1.628-.45 1.977-.444 1.911-.587 2.444-.291 1.178-.433 1.71-.287 1.104-.284 1.074-.283 1.044-.281 1.015-.28.984-.414 1.42-.274.91-.273.88-.404 1.264-.268.805-.265.775-.263.745-.261.715-.26.685-.256.654-.255.624-.252.595-.25.563-.125.271-.247.519-.123.247-.244.473a19.14 19.14 0 01-.401.721l-.199.329-.235.367c-.118.175-.234.34-.35.492-8.83 11.674-21.579 13.039-38.762 3.905 2.699-10.057 7.944-16.945 15.734-20.666l28.575-11.32.325-.194.372-.209.421-.225.469-.241.516-.257.565-.273.613-.29.768-.353z"
                                            >
                                                <title>Shoulder Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Arm_Upper_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M15.7 104.405l19.602 5.598.069 2.1.018.503.083 1.977c.03.65.06 1.29.094 1.92l.051.94.111 1.837c.078 1.206.163 2.374.256 3.506l.128 1.473.122 1.303.137 1.355.15 1.406.163 1.457.177 1.509.191 1.56.205 1.611.356 2.69-.241.672-1.677 4.57-.607 1.686c-1.096 3.088-2.076 6.148-2.643 9.083-3.034.174-6.82-.369-11.343-1.645-3.05-.86-5.773-1.868-8.172-3.02l-.593-.292.03-.146.136-.682.122-.651.109-.62.096-.59.083-.558.085-.655.065-.606.037-.45.024-.42 2.576-38.421z"
                                            >
                                                <title>Arm Upper Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Arm_Upper_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M145.356 99.153l.105.024 2.763 41.124v1.348c-3.18.146-6.936.896-11.28 2.24-4.282 1.326-7.98 2.871-11.09 4.641l-.578.335-.933-3.024-2.031-6.46.083-.034-.793-.198.404-4.008.5-4.74.413-3.69.334-2.825.318-2.511.202-1.5.241-1.68.23-1.461.135-.794c.647-3.664 1.45-6.456 2.407-8.374.313.005.626-.004.94-.026l2.202-.741 1.576-.542 2.055-.725 1.43-.524.734-.28.834-.333.518-.22.424-.192c2.53-1.204 5.15-2.814 7.857-4.83z"
                                            >
                                                <title>Arm Upper Right, Posterior</title>
                                            </path>
                                            <path
                                                id="Arm_Lower_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M27.3 169.772l.404 1.365.283.977.266.937.25.898.343 1.273.307 1.183.269 1.095.159.68.207.947.117.582.101.542.085.503.034.227.064.479.057.51.05.545.045.577.038.61.03.642.025.675.018.707.012.74.005.774-.002.806-.008.838-.024 1.32-.039 1.392-.054 1.466-.068 1.54-.054 1.068-.093 1.663-.107 1.736-.123 1.81-.137 1.883-.205 2.626-.232 2.757c-.02.235-.04.472-.062.71l-.077 1.586c-2.762.112-5.68.601-8.751 1.463-2.6.73-5.081 1.662-7.443 2.797l-.393-1.026-1.861-4.749c-.309-.79-.615-1.582-.914-2.374-3.664-9.698-5.928-20.265-6.542-30.825 3.6.373 8.022-.195 13.287-1.68 4.104-1.159 7.682-2.582 10.733-4.275z"
                                                transform="matrix(-1 0 0 1 33.749 0)"
                                            >
                                                <title>Arm Lower Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Elbow_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M27.615 151.304l.149.628.324 1.313.354 1.383.384 1.452.412 1.522.442 1.59.471 1.662.5 1.73.713 2.416.376 1.254.152.49c-2.946 1.713-6.495 3.158-10.648 4.33-4.937 1.393-8.997 1.911-12.16 1.578l-.36-.042-.053.402-.022-.81-.021-1.3a97.767 97.767 0 00-1.259-14.628c3.273.08 7.144-.52 11.627-1.785 3.191-.9 6.065-1.962 8.62-3.185z"
                                                transform="matrix(-1 0 0 1 39.262 0)"
                                            >
                                                <title>Elbow  Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Wrist_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M19.683 213.54l-.496 10.039.012.293.018.312a42.118 42.118 0 00-5.047 1.102A45.77 45.77 0 007.5 227.72a37.225 37.225 0 00-.122-.674c-.612-3.221-1.564-6.35-2.66-9.434a42.23 42.23 0 017.199-2.734c2.745-.77 5.333-1.216 7.765-1.339z"
                                                transform="matrix(-1 0 0 1 24.401 0)"
                                            >
                                                <title>Wrist Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Hand_Left_Dorsal"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M15.442 227.263l.119.901.123.856.138.896.153.936.167.976.183 1.016.196 1.056.323 1.659 2.63 11.537c.845 4.228 1.076 7.737.642 11.6-1.34-3.654-3.419-6.525-6.247-8.59-4.086-2.982-8.7-3.516-13.69-1.657l-.117-1.704-.062-.844c.27-2.12.688-3.955 1.256-5.505l1.887-5.127c.418-1.364.654-2.664.624-4.293a42.275 42.275 0 017.164-2.717 39.247 39.247 0 014.511-.996z"
                                                transform="matrix(-1 0 0 1 20.33 0)"
                                            >
                                                <title>Hand Left, Dorsal</title>
                                            </path>
                                            <path
                                                id="Fingers_Left"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M14.557 252.602c3.465 2.528 5.624 6.63 6.441 12.39l-.141.188-.288.359-.49.581-.363.413-.389.43-.625.672-.44.462-.692.71-.477.48-.733.727-1.069 1.034-1.005.949-.742.685-.72.653-.691.613-.652.566-.408.346-.567.467-.343.273-.312.24-.278.203-.242.165-.203.125a1.607 1.607 0 01-.162.081l-.958.402-.305.12-.266.098c-.496.169-.783.189-1.004.03l-.054-.045c-.733-.551-.548-1.516.12-2.76l4.563-6.643c1.298-1.969 2.425-4.043 2.775-6.036.843-4.799-.653-7.751-5.696-8.088-.294.001-.528.517-.737 1.34l-1.742 9.087c-.454 1.46-1.046 2.411-1.88 2.207-1.013.288-1.561-.552-1.814-1.87l-.061-.372-.03-.788-.028-1.082-.01-.655-.002-.601.006-.548.013-.494.022-.441c.008-.138.018-.267.029-.388l.019-.187.054-.416.035-.23.086-.5.107-.555.128-.612.073-.327.16-.695.28-1.148.011-.076.018-.186.013-.23.007-.274v-.492l-.01-.592-.023-.664c4.42-1.93 8.257-1.608 11.692.899z"
                                                transform="matrix(-1 0 0 1 22.835 0)"
                                            >
                                                <title>Fingers Left</title>
                                            </path>
                                            <path
                                                id="Elbow_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M128.198 144.74c2.85.148 6.311.844 10.369 2.1 4.644 1.438 8.524 3.13 11.641 5.067l-.338 1.09-.11.405-2.548 14.208c-4.244-.509-7.851-1.225-10.819-2.143a50.998 50.998 0 01-3.988-1.438l-.623-.255-1.024-.434-1.058-.466-.542-.245-.55-.253-1.126-.53-.575-.276-.694-.338a170.945 170.945 0 011.985-5.99v-10.501z"
                                                transform="matrix(-1 0 0 1 276.421 0)"
                                            >
                                                <title>Elbow Right, Posterior</title>
                                            </path>
                                            <path
                                                id="Arm_Lower_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M133.499 164.23l.874.422c3.541 1.689 6.636 2.944 9.29 3.765 3.116.964 6.842 1.709 11.183 2.237l-2.248 12.546-8.248 25.128c-2.15-1.187-4.658-2.221-7.524-3.108-2.443-.756-4.8-1.298-7.07-1.624l.061-1.683.04-1.367.032-1.333.025-1.3.028-1.889.02-2.401.02-6.892.018-1.841.024-1.294.023-.822.03-.79.036-.756.045-.724.054-.69.064-.66c.035-.32.073-.63.117-.926.626-4.304 1.662-8.97 3.106-13.999z"
                                                transform="matrix(-1 0 0 1 284.602 0)"
                                            >
                                                <title>Arm Lower Right, Posterior</title>
                                            </path>
                                            <g
                                                id="Group-3"
                                                fill="#96A6BF"
                                                transform="matrix(-1 0 0 1 170.453 218.895)"
                                            >
                                                <path
                                                    id="Hand_Right_Dorsal"
                                                    onClick={handleSelect}
                                                    d="M12.278 0c2.356.281 4.833.823 7.432 1.627 2.435.754 4.567 1.611 6.398 2.569l-1.7 7.758-.783 5.301-.436 2.833-.367 2.272-.15.878c-3.441-.928-6.653-2.19-9.64-3.785C8.929 17.261 4.58 13.71 0 8.798l.344-.287.986-.807.629-.507.901-.716.85-.66.54-.411.516-.386.494-.362.472-.337.665-.461.415-.277.393-.253.37-.228.348-.204.325-.18.303-.155.143-.07.269-.118c.043-.018.085-.035.126-.05 1.48-.368 2.525-1.108 3.134-2.219l.055-.11z"
                                                >
                                                    <title>Hand Right, Dorsal</title>
                                                </path>
                                            </g>
                                            <path
                                                id="Wrist_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M143.388 206.665c2.079.304 4.249.805 6.511 1.505 2.918.903 5.402 1.956 7.453 3.15l-1.878 5.717.09.01-.64 2.93c-1.83-.898-3.886-1.698-6.17-2.405-2.605-.806-5.111-1.369-7.52-1.686l.337-1.5.404-1.73.259-1.032.262-1.002.208-.828.189-.786.17-.744.15-.702.175-.897z"
                                                transform="matrix(-1 0 0 1 298.586 0)"
                                            >
                                                <title>Wrist Right, Posterior</title>
                                            </path>
                                            <path
                                                id="Fingers_Right"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M158.013 229.681c4.84 5.21 9.483 9.008 13.946 11.392 3.243 1.732 6.727 3.094 10.45 4.085l-2.026 7.78-.914 3.424-.576 2.085-.246.846-.114.365-.083.239a.667.667 0 01-.037.083c-.765 1.325-1.55 1.553-2.444 1.442-1.074-.133-1.396-1.385-1.248-3.293l.044-.47.028-.244 1.855-9.061.19-.997.026-.171c.013-.342-.064-.35-.177-.162l-.066.12-1.471 5.033-1.047 3.508-1.038 3.395-.414 1.322-.478 1.491-.431 1.31-.313.92-.284.803-.254.688-.226.574-.196.458-.129.268-.112.203-.065.1-.059.07a.186.186 0 01-.074.052c-1.515.453-2.643-.528-2.646-1.98l.004-.077.034-.25.09-.491.214-1.021.318-1.41.948-3.99 1.476-6.001.233-.935 1.096-5.58-5.425 19.848-.133.446c-.914 2.207-2.562 2.6-3.94 1.44-1.065-.896-1.176-1.548-.866-3.124l.141-.666 4.668-17.57.496-2.293-5.853 17.93c-1.153 2.18-2.413 2.915-3.392 1.592-1.28-1.73-.862-4.696-.3-6.673l.131-.438 4.743-15.205.137-.658.123-.612.108-.567.093-.522.078-.476.064-.43.048-.387.034-.34.02-.295a3.32 3.32 0 00-.007-.454c-.102-1.044-.881-1.29-1.188-1.335l-.087-.008-.036.003-1.946 2.05-.317.29-.615.55-.298.262-.58.493c-1.987 1.662-3.54 2.62-4.66 2.875-4.913 1.118-5.616-2.704-3.466-4.209l1.91-1.718 1.821-1.621 1.73-1.525 1.24-1.08 1.188-1.024.552-.472z"
                                                transform="matrix(-1 0 0 1 330.83 0)"
                                            >
                                                <title>Fingers Right</title>
                                            </path>
                                            <path
                                                id="Leg_Upper_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M82.424 263.545c2.795 6.363 8.066 10.54 15.69 12.462 2.319.585 5.03 1.005 8.138 1.261l.672.053 1.085.072.554.032.562.028 1.145.048 1.176.035 1.207.023 1.236.01 1.267-.002 1.296-.015.66-.012 1.683-.043 1.73-.063.705-.031 1.433-.07 1.464-.084.743-.046 1.51-.101 1.539-.114 1.57-.126 1.488-.13c-.032 9.424-.617 18.169-1.756 26.234l-.26 2.212-.19 1.463-.198 1.452-.317 2.158-.22 1.422-.345 2.106-.236 1.384-.362 2.043-.49 2.658-.737 3.826-1.265 6.414-.404 2.11-.276 1.496-.178 1.018-26.664 1.245-.131-.752-1.768-9.982-13.454-60.247c.248-.469.48-.951.698-1.447z"
                                            >
                                                <title>Leg Upper Right, Posterior</title>
                                            </path>
                                            <path
                                                id="Knee_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M123.258 337.842l-.035.264-.09.761a30.8 30.8 0 00-.125 1.396l-.031.632c-.01.305-.013.593-.006.864l.044 1.597.078 2.42.092 2.426.104 2.403.117 2.355.127 2.28.184 2.878.098 1.35.102 1.283.063.729-24.342 1.136.051-.42.09-.842c.151-1.558.222-3.202.181-4.989a44.097 44.097 0 00-.248-3.495l-.132-1.178-.075-.617-.17-1.29-.093-.672-.202-1.397-.224-1.465-.371-2.321-.27-1.626-.434-2.552-.126-.713 25.643-1.197z"
                                            >
                                                <title>Knee Right, Posterior</title>
                                            </path>
                                            <path
                                                id="Leg_Upper_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M80.09 267.607l.1 2.469-.226 3.608-.17 2.412-.037.356-.258 1.326-.268 1.312-.435 2.022-.312 1.385-.33 1.413-.347 1.443-.18.732-.374 1.487-.392 1.515-.409 1.545-.646 2.372-.57 2.026-.596 2.072-.752 2.547-.523 1.734-.541 1.763-.559 1.792-.576 1.822-.898 2.787-.62 1.894-5.302 26.839-31.254.001c.073-.857.143-1.717.207-2.579.312-4.153.43-8.234.402-12.284l-.049-3.23c-.148-6.038-.586-12.033-1.148-18.125a40.246 40.246 0 01-.729-6.715l-.013-.863-.018-.845-.212-5.476-.12-3.486-.098-3.64-.051-2.524-.029-1.98.372.038 1.63.15 1.6.14 1.57.126 1.54.114 1.51.101 2.206.13 1.433.07.706.03 1.73.064 1.682.043.66.012 1.297.015 1.266.002 1.237-.01 1.206-.023 1.176-.035 1.146-.048.561-.028.554-.032 1.086-.072c3.39-.25 6.325-.687 8.81-1.314 5.86-1.477 10.331-4.288 13.355-8.4z"
                                            >
                                                <title>Leg Upper Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Knee_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M64.26 341.368l-.082.416-1.928 11.987-.245 8.703.094.51H31.034l.214-2.16.236-2.205.08-.653.045-.307.058-.367.053-.413c.017-.145.033-.299.048-.46l.043-.506.038-.554.033-.6.04-.988.28-2.255c.409-3.36.797-6.737 1.13-10.147l30.928-.001z"
                                            >
                                                <title>Knee Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Heel_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#7A94BB"
                                                d="M108.058 483.15c1.131 0 3.04.011 4.896-.294 1.45-.238 2.922-.728 3.604-1.187 1.559-1.049 1.985-2.952 1.687-4.796-.2-1.244-.808-2.46-1.011-3.748-.843-5.337-3.781-8.767-9.176-8.767-5.394 0-9.767 4.378-9.767 9.778 0 5.401 4.373 9.013 9.767 9.013z"
                                            >
                                                <title>Heel Right, Posterior</title>
                                            </path>
                                            <path
                                                id="Leg_Lower_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M124.285 364.558c.087.8.179 1.6.273 2.401l.62 5.18c.662 5.614 1.22 11.24.96 16.895-.24 5.245-1.635 10.302-3.26 15.3l-.494 1.498-.582 1.731c-.681 2.018-1.368 4.031-1.998 6.048-1.916 6.132-3.17 12.478-4.169 18.82l-.416 9.938c.12.982.21 1.964.28 2.946H101.74l-.014-.363-.028-.52-.048-.7-.06-.71-.072-.722-.082-.734-.32-9.493-.066-.877-.069-.738-.04-.384-.091-.798-.108-.843-.125-.894-.142-.95-.16-1.009-.274-1.637-.318-1.8-1.084-5.926c-1.069-5.877-2.073-11.768-2.625-17.719-.402-4.336-.501-8.044-.4-11.298l.047-1.2c.047-.982.112-1.922.193-2.825l.103-1.067.117-1.033.13-1.002c.044-.33.091-.654.14-.973l.15-.946.241-1.372.17-.887.175-.867.272-1.267.65-2.84.455-1.966.346-1.56.163-.781.156-.785.017-.098 25.146-1.173z"
                                            >
                                                <title>Leg Lower Right, Posterior</title>
                                            </path>
                                            <path
                                                id="Ankle_Right_Lateral"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M115.652 448.403c.043 1.333.058 2.665.062 3.997l.002 1.114-.004 3.594.007 1.62c.035 4.08.212 8.143 1.003 12.17-1.25-4.045-4.063-6.54-8.664-6.54-5.394 0-9.767 4.378-9.767 9.778 0 5.36 4.307 8.958 9.644 9.012h-.94l-.826-.016-.86-.02-1.827-.065-1.464-.067-2.082-.113-1.66-.101-2.349-.156-2.302-.165-.463-.043-.484-.054-.497-.07-.443-.078c-1.702-.33-3.408-1.035-3.913-2.702-.522-1.718.385-3.725 1.473-5.475l.15-.237.332-.506.347-.508.812-1.15 1.14-1.643.935-1.38.707-1.067.671-1.034.941-1.494.297-.483.568-.945.536-.919c.324-.566.63-1.116.917-1.654l.282-.533.427-.835.397-.814.368-.795.341-.777.16-.382.3-.752c.504-1.304.888-2.54 1.171-3.743l.115-.514.136-.679.06-.338.054-.336.095-.67c.061-.479.108-.955.14-1.432h13.955z"
                                            >
                                                <title>Ankle Right, Lateral</title>
                                            </path>
                                            <path
                                                id="Leg_Lower_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M62.687 366.072l.344 1.739.584 2.86c.893 4.368 1.568 8.753 1.147 13.22-.38 4.043-1.565 8.046-2.965 12.033l-.265.748-2.373 6.456c-1.09 3.002-2.098 6.003-2.774 9.015l-.122.566-.124.62-.128.673-.195 1.101-.2 1.205-.204 1.3-.277 1.866-.281 2-.285 2.112-.357 2.77-.426 3.458-.557 4.73-.72 6.452-.375 3.512c-2.91.479-5.88.719-8.913.719a55.09 55.09 0 01-7.531-.51c.147-3.927.115-8.142-.096-12.644a207.944 207.944 0 00-1.267-15.325l-.91-5.445-.61-3.695c-.692-4.29-1.303-8.497-1.658-12.563-.779-8.907-.741-17.535-.489-26.118l.09-2.855h31.937z"
                                            >
                                                <title>Leg Lower Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Ankle_Left_Lateral"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M36.663 480.546l-3.52-.476-1.895-.243-1.315-.156c-2.006-.229-4.16-.43-6.48-.556l-.352-.018-.744-.026-.798-.016-.852-.006-.905.006-.959.016-1.013.026-1.066.038-1.12.048-1.174.058-.774-.064.142-.179-.27-.217c-5.247-4.271-5.702-8.303-1.494-12.874l.246-.263c.12-.01.252-.025.392-.044l.449-.066.508-.089.548-.107 11.966-3.409.71-.245 1.004-.36.624-.232.58-.225.535-.215.483-.204.201-.089.367-.17.164-.08.29-.153c.217-.121.376-.23.468-.32 1.693-1.683 2.935-5.279 3.725-10.789l.14-1.043c2.541.34 5.123.51 7.747.51 2.911 0 5.771-.21 8.58-.629l-.497 4.866-.51 5.345-.01.13c-.007.08-.011.17-.013.27l.002.327.015.382.027.431.062.734.082.828.175 1.554.519 4.323-.438-.096c-5.64-1.21-9.578-.65-11.816 1.675-2.237 2.326-2.641 6.282-1.213 11.87l.113.433-1.636-.211z"
                                            >
                                                <title>Ankle Left, Lateral</title>
                                            </path>
                                            <path
                                                id="Heel_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#7A94BB"
                                                d="M51.653 466.875l.178 1.579a93.65 93.65 0 01.406 4.579l.036.688.024.661.007.32.003.611c-.007 1.288-.117 2.315-.384 2.91-1.008 2.252-2.757 2.842-5.459 2.955l-.535.016-.559.006-.583-.002-1.568-.028c-1.516-.034-3.146-.194-4.92-.413-1.561-5.817-1.194-9.918 1.1-12.303 2.295-2.385 6.38-2.912 12.254-1.579z"
                                            >
                                                <title>Heel Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Toes_Left"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M7.272 465.658c.075.014.159.027.251.038l.303.03.355.021.068.002c-2.94 4.246-2.781 8.513.43 12.513a10.853 10.853 0 01-1.629-.74l-.506-.284-.497-.287-.484-.293c-2.146-1.33-3.825-2.855-3.825-5.168 0-1.886 1.845-3.83 5.534-5.832z"
                                            >
                                                 <title>Toes Left</title>
                                            </path>
                                            <ellipse
                                                id="Perianal_Posterior"
                                                onClick={handleSelect}
                                                cx="81.328"
                                                cy="252.826"
                                                fill="#7A94BB"
                                                rx="8.739"
                                                ry="8.749"
                                            >
                                                <title>Perianal Posterior</title>
                                            </ellipse>
                                            <path
                                                id="Sacral_Coccyx"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M83.59 213.428c3.602 0 7.182.095 10.742.285l-8.918 31.377a8.692 8.692 0 00-4.086-1.013 8.695 8.695 0 00-3.81.872l-10.56-30.874a219.092 219.092 0 0116.633-.647z"
                                            >
                                                <title>Sacral/Coccyx</title>
                                            </path>
                                            <path
                                                id="Back_Upper_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M68.505 67.822l.88.042c1.63.071 3.216.116 4.76.134l.498 80.72c-11.911.371-23.443 1.492-34.593 3.361l-1.636-10.693-.042.116-.148-.977-.27-1.83.128-.017-.12-.876-.232-1.713-.218-1.661-.205-1.61-.28-2.32-.17-1.481-.157-1.43-.143-1.379-.13-1.327-.115-1.276c-.116-1.334-.222-2.721-.316-4.162l-.068-1.09-.106-1.864-.048-.952-.088-1.947-.077-2.003-.05-1.56.27.017c8.937.428 16.394-3.12 22.267-10.68l.293-.382.131-.177c.12-.166.24-.34.362-.524l.181-.282.273-.45c.122-.207.244-.423.366-.648l.184-.345.277-.544.138-.283.279-.59.28-.623.28-.653.141-.338.283-.7.284-.733.286-.763.286-.795.289-.826.29-.858.29-.89.293-.922.294-.953.295-.984.297-1.017.298-1.049.3-1.08.302-1.112.455-1.728.306-1.192.307-1.224.31-1.256.31-1.288.312-1.32.314-1.352.475-2.09.348-1.57z"
                                            >
                                                <title>Back Upper Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Back_Upper_Posterior_Center"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M89.552 67.105v81.539l-.55-.013-1.772-.03-.898-.01-.906-.007-1.835-.005c-2.658 0-5.298.036-7.92.108l-.498-80.681c2.533.01 4.948-.056 7.246-.195l.914-.06 1.214-.094a95.093 95.093 0 005.005-.552z"
                                            >
                                                <title>Back Upper Posterior, Center</title>
                                            </path>
                                            <path
                                                id="Back_Upper_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M96.61 65.871l.573 1.558.34.91.68 1.79c.226.59.453 1.172.679 1.748l.34.859.679 1.686.301.736.604 1.448.452 1.065.68 1.562.288.651.577 1.28.288.63.577 1.235.577 1.205.577 1.175.288.577.577 1.13.289.553.577 1.085.289.531.577 1.04.53.93c.178.305.355.606.532.903l.531.878.266.43.531.84c.177.275.354.547.532.814l.531.789c.178.258.355.513.532.763l.532.737a42.502 42.502 0 003.198 3.885l.533.557c3.587 3.657 7.193 5.568 10.837 5.708-.945 1.935-1.736 4.707-2.375 8.318l-.094.548-.181 1.119-.237 1.594-.3 2.2-.318 2.513-.335 2.827-.293 2.594-.367 3.4-.384 3.715-.27 2.699-.041-.01-2.07 12.322-.122.57c-3.829-.98-8.215-1.746-13.161-2.3l-.934-.101-1.462-.147c-.657-.062-1.323-.121-1.998-.176l-1.02-.081-1.555-.111-.79-.051-.796-.049-1.618-.087-.82-.04-1.664-.07c-.56-.021-1.125-.04-1.695-.058l-1.024-.03V66.96a98.076 98.076 0 003.7-.62l1.22-.237 1.11-.23z"
                                            >
                                                <title>Back Upper Right, Posterior</title>
                                            </path>
                                            <path
                                                id="Back_Lower_Left_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M74.644 151.807l.002 58.72c-12.37.516-25.327 2.086-38.873 4.71l.18-1.513.487-3.953.385-2.923.216-1.545.198-1.328.18-1.111.162-.894.243-1.197c.943-4.518 2.174-9.273 3.695-14.265l.386-1.253 1.51-10.63a21.488 21.488 0 00-.065-.87l-.046-.424-2.786-18.2c10.995-1.845 22.37-2.953 34.126-3.324z"
                                            >
                                                <title>Back Lower Left, Posterior</title>
                                            </path>
                                            <path
                                                id="Back_Lower_Posterior_Center"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M83.59 151.667l1.805.005 1.772.017 1.742.029 1.157.026v58.697c-2.15-.068-4.31-.101-6.475-.101-2.612 0-5.25.049-7.917.147l-.002-58.71c2.054-.058 4.12-.093 6.196-.105l1.723-.005z"
                                            >
                                                <title>Back Lower Posterior Center</title>
                                            </path>
                                            <path
                                                id="Back_Lower_Right_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M91.094 151.772l.369.01 1.663.057 2.036.087 1.986.105 1.554.097 1.523.108 1.492.12 1.46.13 1.43.142.703.075 1.382.16 1.351.17c3.874.51 7.353 1.164 10.436 1.96l-.164.796-.353 1.79-.326 1.737-.203 1.127-.191 1.103-.18 1.078-.168 1.055-.156 1.03-.145 1.006-.133.981-.08.639-.337 3.07-.072.835-.063.836-.052.812-.04.787-.032.95 4.95 21.423 3.427 18.435a196.646 196.646 0 00-33.066-4.007v-58.704z"
                                            >
                                                <title>Back Lower Right, Posterior</title>
                                            </path>
                                            <g id="Group" transform="translate(-21.59, 1.5)">
                                                <path
                                                    id="Hip_Left_Lateral"
                                                    onClick={handleSelect}
                                                    fill="#96A6BF"
                                                    d="M55.9793314,224.405518 C61.8151915,233.574674 64.6079626,241.366274 64.3741316,247.766042 C64.1245921,254.595741 60.4316088,261.288201 53.2623449,267.84529 L53.264986,266.999984 L53.285286,264.408024 L53.2945081,263.725172 L53.3061723,263.716819 L53.259581,263.705426 L53.3375954,260.793835 L53.3756328,258.920436 L53.4222227,257.003733 L53.4573004,255.811098 L53.4785977,254.888272 L53.565397,251.93349 L53.6353118,249.911466 L53.6668974,249.09868 L53.8852708,246.439085 L53.8998813,246.166248 C54.0480765,243.265898 54.2337835,240.3607 54.4452976,237.434114 L54.6725417,234.40254 L54.7922237,232.879796 L55.9793314,224.405518 Z"
                                                >
                                                    <title>Hip Left, Lateral</title>
                                                </path>
                                                <path
                                                    id="Buttock_Left_Posterior"
                                                    onClick={handleSelect}
                                                    fill="#96A6BF"
                                                    d="M85.3042503,213.440445 L96.4248968,245.845408 C94.9805659,247.399823 94.0975826,249.480988 94.0975826,251.767949 C94.0975826,256.11092 97.2818451,259.71174 101.447703,260.372401 L101.426417,260.428525 L101.147368,260.429597 L101.204328,260.971754 C98.8278579,266.552685 94.3015489,270.16043 87.4960479,271.870226 C86.2185764,272.191174 84.804077,272.459467 83.2529024,272.674941 L82.5797465,272.764052 L81.6561825,272.872802 C81.3433725,272.907134 81.0256081,272.939547 80.7028917,272.970042 L80.2151033,273.014343 L79.2172496,273.094305 C78.8796825,273.119039 78.537167,273.141851 78.1897056,273.162741 L77.6648044,273.192634 L76.5927524,273.243766 L75.4910467,273.283355 L74.3597019,273.311392 L73.1987326,273.327873 L72.0081534,273.332822 L71.4017647,273.330909 L70.1667981,273.318466 L69.5382239,273.307902 L68.2589028,273.278086 L66.9500304,273.236678 L65.6116212,273.183671 L64.2436898,273.11906 L62.846251,273.042838 L61.4193192,272.954997 L59.9629092,272.855531 L58.4770355,272.744432 L56.1930126,272.55596 L54.6335437,272.415753 L53.2705614,272.284939 L53.2628141,271.171323 L53.2616998,269.226409 C61.0735787,262.300429 65.1319191,255.158739 65.4006645,247.80339 C65.6518566,240.928463 62.5779222,232.615731 56.1972358,222.849287 L56.9385963,217.542144 C66.7085182,215.623514 76.1637505,214.256404 85.3042503,213.440445 Z"
                                                >
                                                    <title>Buttock Left, Posterior</title>
                                                </path>
                                                <path
                                                    id="Hip_Right_Lateral"
                                                    onClick={handleSelect}
                                                    fill="#96A6BF"
                                                    d="M147.093287,221.537887 L150.311293,240.162148 L150.681169,243.505688 L150.858413,245.251187 C150.887625,245.548651 150.916653,245.84906 150.94547,246.151926 L151.115608,247.994674 C151.143466,248.305404 151.171056,248.61761 151.198349,248.930801 L151.389348,251.196037 L151.567755,253.454679 L151.731223,255.666071 L151.877406,257.789553 L152.000079,259.720472 C152.126518,261.970303 152.225402,264.18643 152.296858,266.368999 C144.732969,260.513419 140.549723,254.229651 139.713489,247.518788 C138.883972,240.861832 141.229401,232.394656 146.770087,222.131958 L147.093287,221.537887 Z"
                                                >
                                                    <title>Hip Right, Lateral</title>
                                                </path>
                                                <path
                                                    id="Buttock_Right_Posterior"
                                                    onClick={handleSelect}
                                                    fill="#96A6BF"
                                                    d="M118.969836,213.011528 C128.199132,213.65099 137.288035,214.933079 146.237096,216.857756 L146.809088,219.9356 C140.500455,231.138889 137.787521,240.369697 138.694124,247.645273 C139.592545,254.855194 144.151345,261.536564 152.335801,267.690434 C152.381903,269.30835 152.411668,270.906994 152.42625,272.487057 L151.655884,272.55596 L149.747931,272.71484 L147.885987,272.855531 L146.429577,272.954997 L145.002645,273.042838 L143.605206,273.11906 L142.237275,273.183671 L140.898866,273.236678 L139.589993,273.278086 L138.310672,273.307902 L137.682098,273.318466 L136.447132,273.330909 L135.840743,273.332822 L135.241753,273.331777 L134.650164,273.327873 L133.489194,273.311392 L132.357849,273.283355 L131.256144,273.243766 L130.184092,273.192634 L129.659191,273.162741 L129.141708,273.129964 L128.631647,273.094305 L127.633793,273.014343 L127.146005,272.970042 L126.665644,272.922861 L126.192714,272.872802 L125.26915,272.764052 L124.595994,272.674941 C123.044819,272.459467 121.63032,272.191174 120.352848,271.870226 C113.142471,270.05871 108.490542,266.1167 106.243221,259.954715 L106.192078,259.810979 C109.344742,258.496746 111.560238,255.390528 111.560238,251.767949 C111.560238,249.634341 110.791701,247.679854 109.515804,246.165112 L118.969836,213.011528 Z"
                                                >
                                                    <title>Buttock Right, Posterior</title>
                                                </path>
                                            </g>
                                            <path
                                                id="Neck_Posterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M98.984 50.343l-.125.449-.336 1.195-.345 1.195c-.583 1.98-1.203 3.889-1.862 5.372l.127.703.128.663.128.626.127.587.19.813.124.496.038.145-1.1.242a93.97 93.97 0 01-11.787 1.751l-1.174.09c-4.3.303-9.053.33-14.26.078l.022-.09c.465-1.843.71-4.01.742-6.507l.004-.582-.008-.871a46.255 46.255 0 00-.29-4.36l-.108-.871-.076-.54.866.09c3.547.348 7.511.486 11.894.417l.946-.018 1.604-.044.816-.028.824-.033 1.676-.08.851-.046 1.73-.106c2.745-.18 5.623-.426 8.634-.736z"
                                            >
                                                <title>Neck Posterior</title>
                                            </path>
                                            <path
                                                id="Head_Posterior_Head_Anterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M103.962 16.66c1.844 6.031 1.103 14.28-2.223 24.748l-.403 1.241-.21.629-.44 1.272a36.65 36.65 0 00-.474 1.466l-.247.83-.085.295-1.517.161-1.769.174-.872.082-1.72.15-1.688.136-.832.062-.823.057-1.623.104-.8.046-.79.043-1.558.072-1.525.058c-.671.021-1.332.039-1.983.051l-.97.016-1.428.01c-4.41.008-8.344-.212-11.8-.66l-.685-.093-1.67-.234c-.549-2.388-.567-5.644.087-9.093 1.142-6.016 4.113-12.007 8.879-17.063 3.756-3.986 9.374-7.394 15.072-9.192 2.993-.944 6.713-1.473 11.158-1.582 1.21 1.783 2.215 3.844 2.94 6.215z"
                                            >
                                                <title>Head Posterior Head Anterior</title>
                                            </path>
                                            <path
                                                id="Ear_Left"
                                                onClick={handleSelect}
                                                fill="#7A94BB"
                                                d="M69.01 43.802c3.099 0 3.511.412 4.534-1.418.7-1.252-1.49-3.166-1.49-4.85 0-4.146.597-8.103-2.5-8.103-3.099 0-6.153 3.957-6.153 8.103 0 4.145 2.512 6.268 5.61 6.268z"
                                                transform="matrix(-1 0 0 1 137.084 0)"
                                            >
                                                <title>Ear Left</title>
                                            </path>
                                            <path
                                                id="ForeHead_Left"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M64.595 2.858l.158.739.104.511.207 1.068.103.557.102.572.202 1.188c.067.407.133.823.198 1.25.351 2.287.666 4.77.935 7.448l.1 1.014.112 1.257.05.614.087 1.202c.027.395.05.783.072 1.166l.03.57.047 1.111c.078 2.212.056 4.201-.065 5.968-.84-4.858-4.848-8.194-11.937-9.96l.084-.738.07-.561.078-.584.087-.59.079-.464c1.244-6.261 4.276-10.707 9.097-13.338z"
                                            >
                                                <title>ForeHead Left</title>
                                            </path>
                                            <path
                                                id="Forehead_Anterior"
                                                onClick={handleSelect}
                                                fill="#96A6BF"
                                                d="M78.886.01l.48.013a32.21 32.21 0 011.548.059l.635.045.32.028.809.084.818.105.33.049.662.108.333.06.668.13.714.157c5.125 1.185 10.393 3.774 14.085 8.58-4.23.148-7.811.684-10.745 1.61-5.865 1.85-11.64 5.353-15.523 9.474a36.818 36.818 0 00-5.939 8.356c.134-2.146.126-4.595-.022-7.345l-.04-.694-.08-1.204-.047-.616-.106-1.258-.06-.643a151.81 151.81 0 00-.91-7.648l-.23-1.512-.1-.623-.102-.609-.206-1.17-.104-.563-.21-1.08c-.07-.35-.14-.691-.21-1.021l-.112-.506C69.102.686 73.55-.103 78.886.01z"
                                            >
                                                <title>Forehead Anterior</title>
                                            </path>
                                            <path
                                                id="Eye_Left"
                                                onClick={handleSelect}
                                                fill="#7A94BB"
                                                d="M58.441 33.25c1.314 0 3.354.282 3.827-.59a49.879 49.879 0 00-.374-2.536c-.264-1.478-.266-3.25-.258-3.665.013-.606.216-1.31 0-1.634-.363-.543-6.25 3.54-6.25 4.892 0 1.373.659 2.464 3.055 3.533z"
                                                transform="matrix(-1 0 0 1 117.655 0)"
                                            >
                                                <title>Eye Left</title>
                                            </path>
                                        </g>
                                        <path
                                            id="Knee_Left_Lateral"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M48.32 344.832c1.988 0 3.786.807 5.088 2.11a7.188 7.188 0 012.108 5.096c0 1.99-.805 3.791-2.108 5.095a7.168 7.168 0 01-5.087 2.11 7.168 7.168 0 01-5.088-2.11 7.188 7.188 0 01-2.108-5.095c0-1.99.806-3.792 2.108-5.096a7.168 7.168 0 015.088-2.11z"
                                        >
                                            <title>Knee Left, Lateral</title>
                                        </path>
                                        <path
                                            id="Knee_Right_Lateral"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M149.075 341.744c1.987 0 3.786.807 5.088 2.11a7.188 7.188 0 012.108 5.096c0 1.99-.806 3.791-2.108 5.095a7.168 7.168 0 01-5.088 2.11 7.168 7.168 0 01-5.087-2.11 7.188 7.188 0 01-2.108-5.095c0-1.99.805-3.792 2.108-5.096a7.168 7.168 0 015.087-2.11z"
                                        >
                                            <title>Knee Right, Lateral</title>
                                        </path>
                                        <path
                                        id="Knee_Right_Medial"
                                        onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M117.204 344.832c1.987 0 3.785.807 5.087 2.11a7.188 7.188 0 012.108 5.096c0 1.99-.805 3.791-2.108 5.095a7.168 7.168 0 01-5.087 2.11 7.168 7.168 0 01-5.088-2.11 7.188 7.188 0 01-2.108-5.095c0-1.99.806-3.792 2.108-5.096a7.168 7.168 0 015.088-2.11z"
                                        >
                                             <title>Knee Right, Medial</title>
                                        </path>
                                        <path
                                            id="Knee_Left_Medial"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M88.417 344.832c1.987 0 3.785.807 5.087 2.11a7.188 7.188 0 012.108 5.096c0 1.99-.805 3.791-2.108 5.095a7.168 7.168 0 01-5.087 2.11 7.168 7.168 0 01-5.088-2.11 7.188 7.188 0 01-2.108-5.095c0-1.99.806-3.792 2.108-5.096a7.168 7.168 0 015.088-2.11z"
                                        >
                                            <title>Knee Left, Medial</title>
                                        </path>
                                        <path
                                            id="Foot_Right_Plantar"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M158.328 475.56c1.987 0 3.785.806 5.088 2.11a7.188 7.188 0 012.107 5.095c0 1.99-.805 3.792-2.107 5.096a7.168 7.168 0 01-5.088 2.11 7.168 7.168 0 01-5.088-2.11 7.188 7.188 0 01-2.108-5.096c0-1.99.806-3.791 2.108-5.095a7.168 7.168 0 015.088-2.11z"
                                        >
                                            <title>Foot Right, Plantar</title>
                                        </path>
                                        <path
                                         id="Foot_Left_Plantar"
                                         onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M8.225 473.501c1.987 0 3.785.806 5.087 2.11a7.188 7.188 0 012.108 5.095c0 1.99-.805 3.792-2.108 5.096a7.168 7.168 0 01-5.087 2.11 7.168 7.168 0 01-5.088-2.11 7.188 7.188 0 01-2.108-5.096c0-1.99.806-3.79 2.108-5.095a7.168 7.168 0 015.088-2.11z"
                                        >
                                             <title>Foot Left, Plantar</title>
                                        </path>
                                        <path
                                        id="Ankle_Left_Medial"
                                        onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M77.108 447.767c1.987 0 3.785.807 5.087 2.11a7.188 7.188 0 012.108 5.096c0 1.99-.805 3.791-2.108 5.095a7.168 7.168 0 01-5.087 2.11 7.168 7.168 0 01-5.088-2.11 7.188 7.188 0 01-2.108-5.095c0-1.99.806-3.792 2.108-5.096a7.168 7.168 0 015.088-2.11z"
                                        >
                                              <title>Ankle Left, Medial</title>
                                        </path>
                                        <path
                                            id="Ankle_Left_Lateral"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M51.405 447.767c1.987 0 3.786.807 5.088 2.11a7.188 7.188 0 012.108 5.096c0 1.99-.806 3.791-2.108 5.095a7.168 7.168 0 01-5.088 2.11 7.168 7.168 0 01-5.087-2.11 7.188 7.188 0 01-2.108-5.095c0-1.99.805-3.792 2.108-5.096a7.168 7.168 0 015.087-2.11z"
                                        >
                                            <title>Ankle Left, Lateral</title>
                                        </path>
                                        <path
                                            id="Ankle_Right_Medial"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M117.204 447.767c1.987 0 3.785.807 5.087 2.11a7.188 7.188 0 012.108 5.096c0 1.99-.805 3.791-2.108 5.095a7.168 7.168 0 01-5.087 2.11 7.168 7.168 0 01-5.088-2.11 7.188 7.188 0 01-2.108-5.095c0-1.99.806-3.792 2.108-5.096a7.168 7.168 0 015.088-2.11z"
                                        >
                                             <title>Ankle Right, Medial</title>
                                        </path>
                                        <path
                                            id="Ankle_Right_Lateral"
                                            onClick={handleSelect}
                                            fill="#FBFDFF"
                                            stroke="#7A94BB"
                                            strokeWidth="2.059"
                                            d="M142.906 447.767c1.987 0 3.786.807 5.088 2.11a7.188 7.188 0 012.108 5.096c0 1.99-.806 3.791-2.108 5.095a7.168 7.168 0 01-5.088 2.11 7.168 7.168 0 01-5.087-2.11 7.188 7.188 0 01-2.108-5.095c0-1.99.805-3.792 2.108-5.096a7.168 7.168 0 015.087-2.11z"
                                        >
                                            <title>Ankle Right, Lateral</title>
                                        </path>
                                        <path
                                            id="Path"
                                            fill="#7A94BB"
                                            fillRule="nonzero"
                                            d="M45.722 480.243a.514.514 0 01.108 1.02l-.092.009-29.28.443a.514.514 0 01-.108-1.02l.092-.01 29.28-.442z"
                                        ></path>
                                        <path
                                            fill="#7A94BB"
                                            fillRule="nonzero"
                                            d="M151.66 483.33c.26-.004.475.223.479.508.003.252-.16.465-.38.512l-.084.01-26.817.443c-.26.004-.474-.223-.478-.507-.004-.253.16-.466.38-.513l.084-.01 26.817-.442z"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </UncontrolledReactSVGPanZoom>
                </div>

                {/* Display selected part */}

                <div className="col-12">
                    <h5 className="text-center"><strong>{bodyPart}</strong></h5>
                </div>

                {/* Buttons */}
                <div className="col-sm-12">
                    <div className="text-center">

                        {/* Loader */}
                        {
                            loading && <div className={styles.loader_position}>
                                <img src={Loader} alt="loading" className={styles.loader_img} />
                            </div>
                        }

                        <Button
                            classes={{ className: `btn btn-small ${styles.btn_small} ${styles.btn_custom} mr-4` }}
                            type="submit"
                            id="next"
                        >
                            Save
                        </Button>
                        {cancel && <Button
                            type="button"
                            value="Cancel"
                            classes={{ className: `btn btn-small ${styles.btn_small_cancel} ${styles.btn_custom} ml-2` }}
                            id="cancel"
                            onClick={() => {
                                window.scrollTo(0, 0)
                                setCloseWarning(true)
                            }}
                        />}
                    </div>
                </div>
            </div>
        </div>
       
    )
}
