import React, { useEffect, useState, useRef, KeyboardEventHandler } from 'react';
import { Tab as TabComponent } from '../../secondary';
import { Measurement } from './measurement';
import style from './assessmentDetail.module.scss';
import { Button } from '../../primary/Button/button';
import { Characteristic } from './characteristic';
import { Treatment } from './treatment';
import { AssessmentNotes } from './assessmentNotes';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { setLoading } from '../../../app/appSlice';
import PropTypes from 'prop-types';
import {
  detailAssessmentAsync,
  setDetailAssessment,
  setWoundMeasurementData,
  setHealingTrajectoryData,
  checkAutoMeasurementStatusAsync,
  setAutoMeasurementStatus,
  patientDetailsAsync,
  optionTypeAsync,
  updatepatientassessmentcustomlabels,
  setPdfData
} from '../../../app/patientSlice';
import { Header } from '../../secondary/Header';
import { SignAndLock } from './signAndLock';
import { fullMonthFormatDate, getLabel, getSubLabel, allNullCheck, dateOfBirthDate, dateForPatientCard} from '../../../utils';
import { AssessmentHistory } from '../../core/Patients/assessmentHistory';
import { HealingTrajectory } from '../../core/Patients/healingTrajectory';
import { withRouter } from 'react-router-dom';
import { assessmentRbacChecker } from '../../../utils/rbac';
import { TextBox } from '../FormBuilder/textBox';
import { Modal } from '../../secondary/Modal/modal';
import { PdfDownload } from '../../../components/core/Patients/pdfDownload';
import pictureIcon from '../../../static/images/picture-icon-default.png'
import { FACILITY_HEAD, PATIENT_GENDER } from '../../../constants'
import { MobileAppRegisterForm } from '../../core/MobileAppRegisterForm/mobileAppRegisterForm';


export let AssessmentDetail = ({ assessment, dispatch, postNotes, history }) => {

  window.onload = function() {
    var inputFocus = document.getElementById("labelID")
    inputFocus?.blur();
  }

  const path = window.location.href;
  const origin = window.location.origin;
  const isAdminView = path.includes(`${origin}/admin`);

  const { patientId } = useParams();
  const { assessmentId } = useParams();

  const patientData = useSelector(state => state.patient.detailViewData)

  useEffect(() => {
    dispatch(optionTypeAsync('woundprogress'))
    dispatch(setHealingTrajectoryData([]))
    dispatch(setAutoMeasurementStatus(''));
    dispatch(setWoundMeasurementData(null));
    dispatch(setDetailAssessment(null));
    dispatch(checkAutoMeasurementStatusAsync(patientId, assessmentId))
    dispatch(detailAssessmentAsync({ patientId, assessmentId }))
    dispatch(patientDetailsAsync(patientId,history))
  }, [dispatch, patientId, assessmentId])

  const nameImageNavigation = (data) => {    
    dispatch(setLoading(true))
    history.push({
      pathname: '/patients/' + data ,
      state: true
  });
  }

  const humanAnatomy = useSelector(state => state.patient.humanAnatomy);
  const historyCause = useSelector(state => state.patient.historyCause);
  const detailAssessment = useSelector(state => state.patient.detailAssessment);
  const notes = useSelector(state => state.patient.assessmentNotes);
  
  const userDetails = useSelector(state => state.user);
  const hideIt = assessmentRbacChecker(userDetails?.role, patientData?.isOwnCustomer)
  const woundClassificationOptions = useSelector(state => state.patient.woundClassificationOptions) // Get wound classification select box options
  

  // API Referesh for All Assessment button shows while clicking back button 
  const [refersh, setRefersh] = useState(detailAssessment)
  useEffect(()=>{     
      setRefersh(detailAssessment?.reEvalSequence);     
  },[refersh]) 

/* Register mobile app modal */
const [showModal, setShowModal] = useState(false)
const [checkTempPass, setCheckTempPass] = useState(false)
const modalOpenMobile = () => {
    setShowModal(true);
    setCheckTempPass(true)
}
const modalCloseMobile = () => {
    setShowModal(false);
    setCheckTempPass(false)
}
  

// added for Label  
  // const [tags, setTags] = useState([detailAssessment?.injuryType !== "null" && detailAssessment?.injuryType !== null ? detailAssessment?.injuryType : "", detailAssessment?.bodyType !== "null" && detailAssessment?.bodyType !== null ? detailAssessment?.bodyType : ""])
  const [tags, setTags] = useState([])
  const [error, setError] = useState("");
  const [hover, setHover] = useState('notdisplayed')   
  const inputRef = useRef(null);
  

  useEffect(() => {
    setTags([])
    if (detailAssessment) {
      setTags([detailAssessment?.injuryType !== "null" && detailAssessment?.injuryType !== null && detailAssessment?.injuryType !== "None" ? detailAssessment?.injuryType : "", detailAssessment?.bodyType !== "null" && detailAssessment?.bodyType !== null && detailAssessment?.bodyType !== "None" ? detailAssessment?.bodyType : ""])
    }
  }, [detailAssessment]);

  useEffect(() => {
    if(hover === "displayed") {     
        inputRef.current.focus();
      }
  }, [tags]);
 


    // Add or remove tags by using the key
    const handleTags = (event) => {  // press enter key
      let maxTagCount = 2
      if((event.key === "Enter" && event.target.value !== "" && tags[0] === ""&&tags.length===1)||(event.key === "Enter" && event.target.value !== "" &&tags.length===0)){
        setTags([event.target.value, ""]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": event.target.value !== undefined && event.target.value !== "" ? event.target.value : "null",
          "bodyType": tags[1] !== undefined && tags[1] !== "" ? tags[1] : "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload))
      }
      else if(event.key === "Enter" && tags.length===2 && tags[0] === ""&& tags[1] === ""){
        setTags([event.target.value, tags[1]]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": event.target.value !== undefined && event.target.value !== "" ? event.target.value : "null",
          "bodyType": tags[1] !== undefined && tags[1] !== "" ? tags[1] : "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload)) 
      }
      else if(event.key === "Enter" && event.target.value !== "" && tags[1] === ""&&tags.length===2){
        setTags([tags[0], event.target.value]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": tags[0] !== undefined && tags[0] !== "" ? tags[0] : "null",
          "bodyType": event.target.value !== undefined && event.target.value !== "" ? event.target.value : "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload))
      }         
      else if (event.key === "Enter" && event.target.value !== "" && tags.length < maxTagCount) {
        setTags([...tags, event.target.value]);
        event.target.value = "";
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": tags[0] !== undefined && tags[0] !== "" ? tags[0] : "null",
          "bodyType": event.target.value !== undefined && event.target.value !== "" ? event.target.value : "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload))
      } 
      else if (event.key === "Backspace" && event.target.value === "" && tags.length === 2 && tags[0] === "" && tags[1] === "") {  
        setTags([]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType":  "null",
          "bodyType": "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload)) 
      }
      else if (event.key === "Backspace" && event.target.value === "" && tags.length === 2 && tags[0] !== "" && tags[1] === "") {  
        setTags([]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType":  "null",
          "bodyType": "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload))
      }     
      else if (event.key === "Backspace" && tags.length && event.target.value == 0) {        
        const tagsCopy = [...tags];
        tagsCopy.pop();
        event.preventDefault();
        setTags(tagsCopy);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": tagsCopy[0] !== undefined && tagsCopy[0] !== "" ? tagsCopy[0] : "null",
          "bodyType": tagsCopy[1] !== undefined && tagsCopy[1] !== "" ? tagsCopy[1] : "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload))
      } 
    };

    const onTakeValue = (event) => {  // without press just onblur
        let maxTagCount = 2
      if((event.target.value !== "" && tags[0] === ""&&tags.length===1)||(event.target.value !== "" &&tags.length===0)){        
        setTags([event.target.value, ""]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": event.target.value !== undefined && event.target.value !== "" ? event.target.value : "null",
          "bodyType": tags[1] !== undefined && tags[1] !== "" ? tags[1] : "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload)) 
      }
      else if(tags.length===2 && tags[0] === ""&& tags[1] === ""){//NOSONAR
        setTags([event.target.value, tags[1]]);//NOSONAR
        const payload = {//NOSONAR
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": event.target.value !== undefined && event.target.value !== "" ? event.target.value : "null",
          "bodyType": tags[1] !== undefined && tags[1] !== "" ? tags[1] : "null",
        }//NOSONAR
        dispatch(updatepatientassessmentcustomlabels(payload)) //NOSONAR
      }//NOSONAR
      else if( event.target.value !== "" && tags[1] === ""&&tags.length===2){
        setTags([tags[0], event.target.value]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": tags[0] !== undefined && tags[0] !== "" ? tags[0] : "null",
          "bodyType": event.target.value !== undefined && event.target.value !== "" ? event.target.value : "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload)) 
      }         
      else if (event.target.value !== "" && tags.length < maxTagCount) {
        setTags([...tags, event.target.value]);
        event.target.value = "";
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": tags[0] !== undefined && tags[0] !== "" ? tags[0] : "null",
          "bodyType": event.target.value !== undefined && event.target.value !== "" ? event.target.value : "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload)) 
      } 
      else if (event.key === "Backspace" && event.target.value === "" && tags.length === 2 && tags[0] === "" && tags[1] === "") {  //NOSONAR
        setTags([]);//NOSONAR
        const payload = {//NOSONAR
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType":  "null",
          "bodyType": "null",
        }//NOSONAR
        dispatch(updatepatientassessmentcustomlabels(payload)) //NOSONAR
      }   //NOSONAR
      else if (event.key === "Backspace" && event.target.value === "" && tags.length === 2 && tags[0] !== "" && tags[1] === "") {  
        setTags([]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType":  "null",
          "bodyType": "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload)) 
      }   
      else if (event.key === "Backspace" && tags.length && event.target.value == 0) {        
        const tagsCopy = [...tags];
        tagsCopy.pop();
        event.preventDefault();
        setTags(tagsCopy);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": tagsCopy[0] !== undefined && tagsCopy[0] !== "" ? tagsCopy[0] : "null",
          "bodyType": tagsCopy[1] !== undefined && tagsCopy[1] !== "" ? tagsCopy[1] : "null",
        }
        dispatch(updatepatientassessmentcustomlabels(payload))
      } 
      
    }
  
    //Remove tags by clicking the cross sign
    const removeTags = (index) => {  
      const updateState = [...tags.filter((tag) => tags.indexOf(tag) !== index)]    
      if(updateState.length === 1) {
        setTags([updateState[0],""]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": updateState[0] !== undefined && updateState[0] !== "" ? updateState[0] : "null",
          "bodyType": "null",
        }
      dispatch(updatepatientassessmentcustomlabels(payload))
      }            
      else{
        setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
        const payload = {
          "patientId": patientId,
          "assessmentId": assessmentId,
          "injuryType": tags[0] !== undefined && tags[0] !== "" ? tags[0] : "null",
          "bodyType": tags[1] !== undefined && tags[1] !== "" ? tags[1] : "null",
        }
      dispatch(updatepatientassessmentcustomlabels(payload))
      }
    };
  
    //To update the error after user changes something
    const handleError = () => {
      setError("");
    };
  
    const removeTagsTotal = () => {
      setTags([]);
      const payload = {
        "patientId": patientId,
        "assessmentId": assessmentId,
        "injuryType":  "null",
        "bodyType": "null",
      }
      dispatch(updatepatientassessmentcustomlabels(payload))
    };

    const showXicon = () => {
      setHover("displayed");
    }
    const hideXicon = () => {
        setHover("notdisplayed");
    }

//passing payload for labels alone
    // const handleBlur = () => {
    //   const payload = {
    //         "patientId": patientId,
    //         "assessmentId": assessmentId,
    //         "injuryType": tags[0] !== undefined && tags[0] !== "" ? tags[0] : "null",
    //         "bodyType": tags[1] !== undefined && tags[1] !== "" ? tags[1] : "null",
    //       }
    //   dispatch(updatepatientassessmentcustomlabels(payload)) 
    // }
//-------------added for Label


  const tabList = [
    {
      name: 'Characteristics',
      content: <Characteristic assessment={assessment} hideIt={hideIt} />,
    },
    {
      name: 'Measurements',
      content: <Measurement assessment={assessment} detailAssessment={detailAssessment} hideIt={hideIt}/>
    },
    {
      name: 'Treatment',
      content: <Treatment assessment={assessment} />
    },
    {
      name: 'Notes',
      content: <AssessmentNotes notes={notes} postNotes={postNotes} detailAssessment={detailAssessment} hideIt={hideIt}/>
    },
    {
      name: 'Healing Trajectory',
      content: <HealingTrajectory detailAssessment={detailAssessment} patientId={patientId} assessmentId={assessmentId} dispatch={dispatch} />
    },
  ];

  
  // Assessment without photo
  const pdfPage = (data) => {    
    dispatch(setLoading(true))
    // history.push('/patients/' + patientId + '/assessment/' + data + '/pdfdownload');
    history.push({
      pathname: '/patients/' + patientId + '/assessment/' + data + '/pdfdownload',
      state: true
  });
  }

  //Assessment without photo after Popup added for Pdf with/without Image
  const pdfPageTrue = (data) => {  
    dispatch(setLoading(true))
    history.push({
      pathname: '/patients/' + patientId + '/assessment/' + data + '/pdfdownload',
      state: true
    });
  }
  const pdfPageFalse = (data) => {  
    dispatch(setLoading(true))
    history.push({
      pathname: '/patients/' + patientId + '/assessment/' + data + '/pdfdownload',
      state: false
    });
  }
  //added for Pdf with/without Image

  const [modalShow, setmodalShow] = useState(false);
  const [pdfModal, setPdfModal] = useState(false) //added for Pdf with/without Image
  

  const modalOpen = () => {
    setmodalShow(true);    
  }

  const modalClose = () => {
    setmodalShow(false);
  }

  const pdfmodalopen = () => { //added for Pdf with/without Image
    setPdfModal(true);
  }
  const pdfmodalclose = () => {
    setPdfModal(false)
  }


  const moreInfoNavigation = (data) => {    
    dispatch(setLoading(true))
        history.push({
        pathname: '/patients/' + data + '/moreInfo',
        state: true
    });
  }
 

  return <div>
    {/* <div className="d-flex justify-content-between w-auto col-lg-11 p-0">
      <div className={`w-auto ${style.back_btn}`}>
        <Link to={
          {
            pathname: ('/patients/' + patientId),
            state: assessmentData,
          }
        }>
          &#60; Back
        </Link>
      </div>
      <div>
        <div className="d-flex align-items-center">
          <div>
            <img src={Bell} alt="bell-icon" className={style.bell_icon} />
          </div>
          <div>
            <img src={Logo} alt="logo" />
          </div>
        </div>
      </div>
    </div> */}
    
    {/* Loaded for until window loadding */}
    
    {/* {patientData?.firstName === undefined && 
      <div className={`${style.spinner}`} id="spinner">
        <div className={`${style.loading}`}></div>
      </div>
      } */}
    {/* Loaded for until window loadding */}

    {showModal&&<MobileAppRegisterForm modalOpen={showModal} modalClose={modalCloseMobile} checkTempPass={checkTempPass} setCheckTempPass={setCheckTempPass} assessmentId={assessmentId} />}
    
    <div className={`${style.back_btn}`}>
      <Header backLink={'/patients/' + patientId} />
    </div>
    <div className={`${style.tab_container}`}>
      <>
        <div className={`col-12`}>
          <div className={`row`}>

              <div className={`col-12 ${style.patient_details_blk}`}>
                  <div className={`${style.patient_image} mx-3`}>
                      <img src={patientData?.moreInfo?.profilePicture || pictureIcon} alt={`${patientData?.firstName} ${patientData?.lastName}`} onClick={() => nameImageNavigation(patientData?.ne1Id)}/>                    
                      {/* <div className={'text-center'}>
                          <Button
                              type={"button"}
                              value={"More info >"}
                              classes={{ className: `btn ${style.btn_custom_with_moreInfo} mt-2` }}
                              id="moreInfo"
                              onClick={() => moreInfoNavigation(patientData?.ne1Id)}
                          />
                      </div> */}
                  </div>
                  <div className={style.patient_details}>
                    <div className='d-flex align-items-center'>
                        <div className={'d-flex align-items-center'} onClick={() => nameImageNavigation(patientData?.ne1Id)}>
                            <h4 className="pr-1 mb-0">{`${patientData?.firstName} ${patientData?.lastName}`}</h4>
                            {/* <h4 className="mb-0">{`${patientData?.lastName}`}</h4> */}
                        </div>
                        <div className={`verticalCode  ${style.vertical_Code}`}>
                        </div>    
                        <p className={`${style.labelSize} pt-1`}><span>{patientData?.age}</span></p>
                        <div className={`verticalCode  ${style.vertical_Code}`}>
                        </div>
                        <p className={`${style.labelSize} pt-1`}><span>{PATIENT_GENDER[patientData.gender ? patientData.gender.toLowerCase() : '']}</span></p>                        
                        <div className={'mx-4 text-center'}>
                            <Button
                                type={"button"}
                                value={"Diabetic"}
                                classes={{ className: (patientData?.moreInfo?.diabetic === "1" || patientData?.moreInfo?.diabetic === 1) ? `btn ${style.btn_custom_with_diabetic}` : 'd-none' }}
                                id="diabetic"
                            />
                        </div> 
                        <div className={`${style.patient_mobile_card} ml-auto`} onClick={() => modalOpenMobile()}>
                            <svg xmlns="
                                http://www.w3.org/2000/svg"
                                width="12" height="24" viewBox="0 0 11.393 18.228">
                                <path id="Subtraction_4" data-name="Subtraction 4" d="M-4999.886-342.772h-6.835a2.279,2.279,0,0,1-2.279-2.278v-13.671a2.279,2.279,0,0,1,2.279-2.278h6.835a2.279,2.279,0,0,1,2.279,2.278v13.671A2.279,2.279,0,0,1-4999.886-342.772Zm-3.417-2.848a1.139,1.139,0,0,0-1.139,1.139,1.139,1.139,0,0,0,1.139,1.139,1.139,1.139,0,0,0,1.139-1.139A1.139,1.139,0,0,0-5003.3-345.62Zm-2.734-13.027a1.709,1.709,0,0,0-1.823,1.556v9.342a1.709,1.709,0,0,0,1.823,1.556h5.468a1.708,1.708,0,0,0,1.823-1.556v-9.342a1.708,1.708,0,0,0-1.823-1.556Z" transform="translate(5009 361)" fill="#fff"/>
                            </svg>
                            <p>Register patient for mobile</p>
                        </div>                       
                    </div>

                    <div className='d-flex align-items-center'>
                        <p className={`${style.labelSize}`}><label>MRN #:</label><span>{patientData?.mrn !== null ? patientData?.mrn : "-"}</span></p>
                        <div className={`verticalCode  ${style.vertical_Code_one}`}>
                        </div>
                        <p className={`${style.labelSize}`}><label>DOB:</label><span>{dateOfBirthDate(patientData?.dob)}</span></p>
                    </div>

                    <div className='d-flex align-items-center pt-3'>
                        <p className={`${style.labelSize_one}`}><label>{FACILITY_HEAD}:</label><span>{patientData?.facilityName}</span></p>                        
                    </div>
                    
                    <div className='d-flex align-items-center'>
                        <p className={`${style.labelSize_one}`}><label>Unit:</label><span>{patientData?.careInfo?.unit !== null ? patientData?.careInfo?.unit : "-"}</span></p>
                        <div className={`verticalCode  ${style.vertical_Code_one}`}>
                        </div>
                        <p className={`${style.labelSize_one}`}><label>Room:</label><span>{patientData?.careInfo?.roomNumber !== null ? patientData?.careInfo?.roomNumber : "-"}</span></p>
                        <div className={`verticalCode  ${style.vertical_Code_one}`}>
                        </div>
                        <p className={`${style.labelSize_one}`}><label>Bed:</label><span>{patientData?.careInfo?.bed !== null ? patientData?.careInfo?.bed : "-"}</span></p>
                    </div>  
                </div>
              </div>

          <div className={`{col-6}`}>
            <div className={`${style.patient_heading_details} pb-3`}>
            {/* {patientData && patientData?.firstName !== undefined && <>                
              <div className={'d-flex align-items-center'}>                
                <h4 className="d-inline mr-2">{`${patientData?.firstName} ${patientData?.lastName}`}</h4>
                <div className={`${style.straight_line}`}>{`|`}</div>
                <p className='ml-2'><label>Date of Birth:</label><span>{dateOfBirthDate(patientData?.dob)}</span></p>
              </div>
              </>} */}   
            </div>

            <div className={`${style.body_heading}`}>
              {/* <span>{detailAssessment?.title} <SignAndLock /></span> */}
              <span>
                {
                  <>
                    {detailAssessment?.title ? detailAssessment?.title : null}
                    &nbsp;
                    <SignAndLock hideIt={hideIt} userDetails={userDetails} patientData={patientData} history={history}/>
                  </>
                }
              </span>
            </div>
            <div className={`mb-3`}>
              <div className={`${style.assess_content} mt-4`}>
                <span className={`${style.assess_label} pt-4`}>History/Cause: &#173;</span>
                {detailAssessment?.historyCause?.causeCategory !== "unknown"  ? (allNullCheck(detailAssessment?.historyCause?.causeCategory) && allNullCheck(detailAssessment?.historyCause?.cause) ?
                   (detailAssessment?.historyCause?.causeCategory !== "others" || detailAssessment?.historyCause?.causeCategory.toLowerCase() !== "others") ?
                  <span className=''>                                               
                    {historyCause && historyCause.length > 0 && historyCause?.map((data)=>{
                      if(data.key===detailAssessment?.historyCause?.causeCategory.toLowerCase()){
                        return data?.options?.map((item)=>{
                          if(item.key!=='unabletodetermine'){
                            if(item.key===detailAssessment?.historyCause?.cause || item.value===detailAssessment?.historyCause?.cause){
                              return data.value + '-' + item.value
                            }
                          }
                          else{
                            return data.value
                          }
                        })
                      }                      
                    })}
                  </span> : <span className=''>{"Other" + `-` + detailAssessment?.historyCause?.cause}</span>
                  : <span className=''>{`-`}</span>)
                  : <span className=''>{`Unknown`}</span>
                }
              </div>
              <div className={`${style.assess_content} d-flex`}>
                <span className={`${style.assess_label} pt-4`}>Labels: &#173;</span> 
                <span className='ml-2 align-self-center'> 
                  <div className={(tags.length !== 0 && (tags[0] !== "" && tags[1] !== "" )) || (tags.length !== 0 && (tags[0] !== "" || tags[1] !== "" )) ? `${style.tags}`: `${style.tagsCompleted}`} onMouseOver={showXicon} onMouseOut={hideXicon} ref={inputRef}>
                    {/* {Array.isArray(tags) && tags.map((tag, index) => (                      
                      tag[index] && */}
                      {tags.map((tag, index) => ( 
                           tag !== "" &&               
                      <>
                        <div className={`${style.single_tag}`} ref={inputRef}>
                          <span type="text" className={`{style.tagsValue}`}>
                            {tag}
                          </span>
                          {/* <input type="text" id={'tagsValue'} className={`{style.tagsValue}`} value={tag}/> */}
                          <span className={`${style.x_icon}`} >  
                            <i className={hover === 'displayed' && tags.length !== 0 && `fa fa-times`} onClick={() => removeTags(index)} data-testid="singleIcon" style={{color : "#000"}} aria-hidden="true"></i>  
                          </span>
                        </div>
                      </>
                    ))}                                     
                      
                      {tags.length <= 2 && tags[0] === "" || tags[1] === "" && <>                          
                          <input
                              className={`${style.labelInput} onblurKeyword`}
                              id="labelID"
                              type="text"
                              onKeyDown={event => handleTags(event)}
                              onBlur={event => onTakeValue(event)}
                              placeholder={tags.length < 1 && "+ Add custom"}
                              ref={inputRef}
                              maxLength="25"
                          />
                      </>
                      }
                      
                      {tags.length === 0 && <>                          
                          <input
                              className={`${style.labelInput} onblurKeyword`}
                              id="labelID"
                              type="text"
                              onKeyDown={event => handleTags(event)}
                              onBlur={event => onTakeValue(event)}
                              placeholder={tags.length < 1 && "+ Add custom"}
                              ref={inputRef}
                              maxLength="25"
                          />
                      </>
                      }

                      {tags.length === 1 && <>                          
                          <input
                              className={`${style.labelInput} onblurKeyword`}
                              id="labelID"
                              type="text"
                              onKeyDown={event => handleTags(event)}
                              onBlur={event => onTakeValue(event)}
                              placeholder={tags.length < 1 && "+ Add custom"}
                              ref={inputRef}
                              maxLength="25"
                          />
                      </>
                      }
                      
                      {tags[0] === "" && tags[1] === "" && <>                          
                          <input
                              className={`${style.labelInput} onblurKeyword`}
                              id="labelID"
                              type="text"
                              onKeyDown={event => handleTags(event)}
                              onBlur={event => onTakeValue(event)}
                              placeholder={"+ Add custom"}
                              ref={inputRef}
                              maxLength="25"
                          />
                      </>
                      }
                    {/* tags[0] !== "" && tags[1] !== "" && tags.length !== 0 && tags.length !== 1 */}
                    { tags[0] !=="" && (tags[1] !==""|| tags[1] ==="") && tags.length !== 0 && <div className={`${style.delteWhole} onmouseoverKeyword`} onMouseOver={showXicon} onMouseOut={hideXicon}>
                      <i className={hover === 'displayed' && `fa fa-times-circle`} data-testid={'iconshow'} onClick={removeTagsTotal} style={{color : "#000",fontSize:"18px"}} aria-hidden="true"></i>
                    </div>
                    }              
                  </div>                                  
                  {/* <div className={`${style.errorLabel}`}>{error}</div> */}
                </span> 

              </div>




              {/* <form>
                <div class="css-164r41r">
                  <div class="css-1v0cd8t">
                    <button aria-label="Edit Labels" type="button" class="css-1kh4s4h"></button>
                    <div data-read-view-fit-container-width="true" class="css-1dtwgr">
                      <div data-component-selector="jira-issue-view-select-inline-edit-read-view-container" class="_1e0c1txw _16jlkb7n _1o9zkb7n _i0dl1wug _1nmz1hna _kqswh2mm _vwz48sxn">
                        <div class="_19pkmyb0 _2hwxmyb0 _otyrmyb0 _18u0myb0 _1bsb1osq">
                          <div class="css-1pc3ops">
                            <div role="presentation">
                              <div role="presentation">
                                <span class="css-clkc0" style="--ds-ct:var(--ds-text, #253858); --ds-cth:var(--ds-link, #0065FF); --ds-ctp:var(--ds-link-pressed, #0065FF); --ds-ctl:var(--ds-link, #0052CC); --ds-cb:var(--ds-background-neutral, #F4F5F7); --ds-cbh:var(--ds-background-neutral-hovered, #EBECF0); --ds-cba:var(--ds-background-neutral-pressed, #EBECF0); --ds-cfr:var(--ds-border-focused, #4C9AFF); --ds-rb:var(--ds-text, #42526E); --ds-rbh:var(--ds-text-danger, #253858); --ds-br:3px;">
                                  <a href="/issues/?jql=labels%20%3D%20%22NE1%22" data-color="standard" class="css-11sg6bi">NE1</a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form> */}




              <div className={`${style.assess_content} mb-4 WoundAcquired`}>
                <span className={style.assess_label}>Wound Acquired: &#173;</span>                
                {detailAssessment?.woundAcquired ? ((detailAssessment?.woundAcquired ==="POA") ? <span className={`${style.POAColor}`}>{detailAssessment?.woundAcquired}</span> : <span className={`${style.NONPOAColor}`}>{detailAssessment?.woundAcquired}</span>) : 'NA'}
              </div>

              {/* <div className={`${style.assess_content} mb-4 pb-3`}>
                <span className={style.assess_label}>Classification: &#173;</span>                
                {
                    getSubLabel(detailAssessment?.historyCause?.cause, historyCause)
                  }
              </div> */}

                <div className={`${style.assess_content} pb-3`}>
                    <span className={`${style.assess_label}`}>Classification: </span>
                    {detailAssessment?.classification?.woundClassification !== "unknown" ? (allNullCheck(detailAssessment?.classification?.woundClassification) ?                                  
                    <span className={`${style.classifi_content}`}>
                        {woundClassificationOptions && woundClassificationOptions?.result?.length > 0 && woundClassificationOptions?.result?.map((data) => {
                            if (data.key === detailAssessment?.classification?.woundClassification) {
                                return data.value
                            }
                        })
                        }
                    </span> : <span className={`${style.classifi_content}`}>{`Unknown`}</span>) :
                    <span className={`${style.classifi_content}`}>{`Unknown`}</span>}
                </div>              

              </div>
            </div>

            <div className={`{col-6}`}></div>

            </div>
            
            <div className={`row`}>
            <div className={`col-12 pl-2`}>
              <div className={`row align-items-center ${style.assess_content_subDetails}`}>

                <div className={`col-9`}>
                  <div className={`${style.assess_content} mb-2 pt-1`}>
                    <span className={style.assess_label_subDetail_label}>Photo taken by:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.photoTakenBy ? detailAssessment?.photoTakenBy : 'NA'}</span>
                    {` | `}
                    <span className={style.assess_label_subDetail_label}>Photo taken on:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.identifiedDate ? fullMonthFormatDate(detailAssessment?.identifiedDate) : 'NA'}</span>
                  </div>

                  <div className={`${style.assess_content} mb-2`}>
                    <span className={style.assess_label_subDetail_label}>Assessed by:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.assessedBy ? detailAssessment?.assessedBy : 'NA'}</span> 
                    {` | `}
                    <span className={style.assess_label_subDetail_label}>Last assessed:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.lastAssessedDate ? fullMonthFormatDate(detailAssessment?.lastAssessedDate) : 'NA'}</span>
                  </div>

                  <div className={`${style.assess_content} mb-1`}>
                    <span className={style.assess_label_subDetail_label}>Signed:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.signedBy ? detailAssessment?.signedBy : "NA"}</span>                                     
                      {` | `}
                    <span className={style.assess_label_subDetail_label}>Reviewed & Locked:</span> <span className={style.assess_label_subDetail_labelout}>{detailAssessment?.lockedBy ? detailAssessment?.lockedBy : "NA"}</span> 
                  </div>

                </div>
                <div className={`col-3`}>                
                    {
                      detailAssessment && detailAssessment?.reEvalSequence > 0 && userDetails?.role !== 'ExternalStandardUser' ? 
                        <div className={`row align-items-center`}>
                          <div className={`${style.assement_all_link}`}>
                            {
                              detailAssessment && !isAdminView && 
                              <a
                                className={style.assess_link}
                                onClick={() => modalOpen()}
                              >
                                All assessments                                              
                                <span 
                                    className={`${style.right_Arrow}`}
                                    onClick={() => modalOpen()}                         
                                    >                                                    
                                    <i class="fa-solid fa-circle-right" aria-hidden="true"></i>
                                </span> 
                              </a>
                            }
                          </div>                                          
                      </div>

                      : ''
                    }                  
                </div>                            
              </div>
          </div> 
          <div className='col-3'></div>

          </div>
        </div>
                      
      </>  
          
    </div>
    
    {detailAssessment?.isWoundWithoutPhoto === 0 ?
     <div className={`${style.PDFButton}`}>
      {(detailAssessment?.assessmentStage === 'pendingreview' || detailAssessment?.assessmentStage === 'signedlocked') && <Button
        type={"submit"}
        value="Download PDF"
        id={"tes"}
        // onClick={() => pdfPage(detailAssessment.id)}
        onClick={() => pdfmodalopen()} 
        classes={{ className: `btn pr-3 pl-3 ${style.btn_custom}` }}
      />}    
    </div> : 
    <div className={`${style.PDFButton}`}>
      {(detailAssessment?.assessmentStage === 'pendingreview' || detailAssessment?.assessmentStage === 'signedlocked') && <Button
        type={"submit"}
        value="Download PDF"
        id={"tes"}
        onClick={() => pdfPage(detailAssessment.id)}
        // onClick={() => pdfmodalopen()} 
        classes={{ className: `btn pr-3 pl-3 ${style.btn_custom}` }}
      />}    
    </div>
    }

     {/* added for Pdf with/without Image */}
    {pdfModal && <Modal
        modalOpen={pdfmodalopen}
        modalClose={pdfmodalclose}
        modalSize={style.custom_modal_size_woundImage}
      >
        {
          <div className={style.modalparent}>
            <div className={`${style.modal_heading} pb-2`}>
              <div className={`${style.modal_header} pl-3 pt-3`}>
                <h3>PDF Download</h3>
              </div>
              <button type="button" className={`close ${style.close_icon} pr-3`} onClick={() => pdfmodalclose()}>&times;</button>
            </div>
          
            <div className={`${style.modal_footer}`}>
              <div className={'px-4 mt-5'}>
                <h2> Do you want to include <br /> wound images? </h2>
              </div>
              <div className={'d-flex justify-content-center px-3 pt-5 pb-2'}>
              <Button
                    type="submit"
                    value="Yes"
                    classes={{ className: `btn btn-small ml-0 mr-5 ${style.btn_small} ${style.btn_custom}` }}
                    id="save"
                    onClick={()=>pdfPageTrue(detailAssessment.id)}
                />
                
                <Button
                    type="button"
                    value="No"
                    classes={{ className: `btn btn-small ${style.btn_small_cancel} ${style.btn_custom} ml-4` }}
                    id="cancel"
                    onClick={()=>pdfPageFalse(detailAssessment.id)}                    
                />
              </div>
            </div>
          
          <br />
          

        </div>
        }
      
      </Modal>
    }
    {/* added for Pdf with/without Image */}

    {
      modalShow && <AssessmentHistory
        modalOpen={modalShow}
        modalClose={'modalClose'}
        setmodalShow={setmodalShow}
        modalTitlteId={assessmentId}
        assessmentTitle={detailAssessment.title}
      />
    }
    <TabComponent tabList={tabList} />    
  </div>
}

AssessmentDetail.propTypes = {
  dispatch: PropTypes.func,
  aboutProps: PropTypes.object, 
  handleCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};
AssessmentDetail = connect()(withRouter(AssessmentDetail));
