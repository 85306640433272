import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Form, Button, SelectBox, MultiSelect } from '../../primary';
import { CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants';
import styles from './assign.module.scss';
import { Modal } from '../../secondary';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
export let AddRule = ({ modalOpen, modalClose, addRuleButton }) => {

    const modalRefresh = () => {
        modalClose();
    }

    const addRuleData = () => {
        addRuleButton()
    }

    return (
        <Modal
            modalOpen={modalOpen}
            modalClose={modalRefresh}
            heading={" "}
            className={"modal_box"}
            modalSize={styles.custom_modal_size}
        >
            <div className="col-sm-12 single-product-block pt-5 pb-5">
                <div className="row">
                    <div className="col-12">
                        <p className={`${styles.form_modal_text}`}>
                            You have completed Step 1 of the
                            Product Selector form builder.
                        </p>
                        <p className={`${styles.form_modal_text}`}>
                            For Step 2, click each of the
                            ‘Add Rule' buttons to define
                            additional logic.</p>
                    </div>
                    <div className="col-sm-12 mt-5">
                        <div className="text-center">
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                            type="button"
                            id={SUBMIT_ID}
                            onClick={()=>addRuleData()}
                        >
                            OK
                        </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
AddRule = connect()(withRouter(AddRule));
