import React, { useEffect } from 'react';
import { ViewTreatMent } from '../../secondary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDressingAsync, getlistOptionsAsync, saveDressingAsync } from '../../../app/patientSlice';

export const SkinProtectant = ({ interventionId, formularyId, assessmentDetails }) => {
  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const skinProtectantGetData = useSelector(state => state.patient.skinProtectantGetData);
  const skinProtectantListOptions = useSelector(state => state.patient.skinProtectantListOptions);

  let flag = false;
  const doSubmit = (data) => {
    const payloadData = {
      "productCategory": data,
      'patientId': patientId,
      'assessmentId': assessmentId,
      'interventionId': interventionId,
      'payType': 'skinprotectant'
    }

    let skinProData = [];
    const skinProtectantData = Array.isArray(data) && data?.map((item, index) => {//NOSONAR
      skinProData.push(item.value)//NOSONAR
      return
    })//NOSONAR

    for (let i = 0; i < skinProData.length; i++) {
      if (skinProData[i] === undefined) {
        flag = false
      }
      if (skinProData[i] !== undefined) {
        flag = true;
        break;
      }
    }
   
      dispatch(saveDressingAsync(payloadData))
    
  }

  useEffect(() => {
    if (formularyId !== null) {
      const payload = {
        'formularyid': formularyId,
        'option': 'skinprotectant',
        payType: 'skinprotectant'
      }
      dispatch(getlistOptionsAsync(payload));
      dispatch(getDressingAsync({ patientId, assessmentId, interventionId, payType: 'skinprotectant' }));
    }
  }, [interventionId])


  return (
    <ViewTreatMent
      editData={skinProtectantListOptions === null ? [] : skinProtectantListOptions}
      viewData={skinProtectantGetData === null ? [] : skinProtectantGetData}
      saveData={doSubmit}
      noDataMessage={"Your formulary doesn't have any Skin Protectant, please contact your Medline Sales Rep"}
      assessmentDetails={assessmentDetails}
    />
  )
}
