import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ForgotPasswordForm } from '../components';
import { forgotPasswordAsync } from '../app/userSlice'
import PropTypes from 'prop-types'
import { browserName, osName  } from 'react-device-detect';
// import {} from ''

export let ForgotPassword = ({ dispatch, history }) => {
    

    const handleSubmit = data => {
        const operatingSystem = osName;
        const browser = browserName
        
        const payload = {
            "email" : data.email,
            "browser": browser,
            "oS" : operatingSystem    
          }

         dispatch(forgotPasswordAsync(payload, history))
    }

    return <ForgotPasswordForm doSubmit={handleSubmit} />;
}

ForgotPassword.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
};

ForgotPassword = connect()(withRouter(ForgotPassword))