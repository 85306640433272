import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { withRouter, useParams } from 'react-router-dom';
import { setWoundMeasurementData, notesAssessmentAsync, notesPostAsync, setDetailAssessment, detailAssessmentAsync, setAssessmentNotes, setAutoMeasurementStatus } from '../app/patientSlice';
import { AssessmentDetail } from '../components/core/Patients/assessmentDetail';

export let Assessment = ({ dispatch, history, location }) => {
  const { patientId } = useParams();
  const { assessmentId } = useParams();

  useEffect(() => {
      const payload = {patientId, assessmentId}
      dispatch(setAssessmentNotes(null));
      dispatch(setAutoMeasurementStatus(''));
      dispatch(setWoundMeasurementData(null));
      dispatch(setDetailAssessment(null));
      dispatch(notesAssessmentAsync(payload))
      dispatch(detailAssessmentAsync(payload))
    }, [dispatch, patientId, assessmentId])


  const postNotes = (data) => dispatch(notesPostAsync(data))

  return <AssessmentDetail postNotes={postNotes}/>;
};

Assessment.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
}

Assessment = connect()(withRouter(Assessment));
