import React from 'react'
import styles from './characteristicCustomForm.module.scss'
import { TextField, MultiSelect} from '../../primary'

export function CharacteristicCustomFormText({ data, index, getForm, register, errors, getTextBoxVal, showViewForm}) {
    const getTextBoxValue = (e, index, id) => {
        if (showViewForm?.formdata?.length > 0) {
            let viewData = { ...getForm.formdata[index] }
            viewData.value = e.target.value
            let dataform = { ...getForm }
            let viewFrm = [...getForm.formdata]
            viewFrm.splice(index, 1, viewData)
            dataform.formdata = viewFrm
            getTextBoxVal(dataform)
        } else {
            let viewForm = getForm?.formdata?.length > 0 && [...getForm.formdata].find(v => v.id === id)
            let selectedIndex = getForm?.formdata?.length > 0 && [...getForm.formdata].findIndex(x => x.id === id);
            if (viewForm) {
                let viewData = { ...viewForm }
                viewData.value = e.target.value
                let dataform = { ...getForm }
                let viewFrm = [...getForm.formdata]
                viewFrm.splice(selectedIndex, 1, viewData)
                dataform.formdata = viewFrm
                getTextBoxVal(dataform)
            } else {
                let viewData = { ...getForm }
                viewData.formdata = [...viewData.formdata, { id: id, options: null, value: e.target.value }]
                getTextBoxVal(viewData)
            }
        }
    }
    
    let viewForm = getForm?.formdata?.length > 0 && [...getForm.formdata].find(v => v.id === data.id)
    return <TextField
        id={`${data.id}`}
        name={`${data.id}`}
        label={`${data.desc}`}
        classes={{
            column: `col-12 ${styles.col_spec}`,
            labelClass: `col-6 col-form-label ${styles.label_spec}`,
            inputColumn: `col-5 p-0 ${styles.display_flex}`,
            inputClassName: `${styles.basic_input} getTextBox`
        }}
        type="text"
        validator={register()}
        errors={errors}
        defaultValue={viewForm?.value}
        key={index}
        onBlur={(e) => getTextBoxValue(e, index, data.id)}
        placeholder={data.placeholder}
    />
}
