import React from 'react';
import BannerImage from '../../../static/images/banner-image.png';
export function Banner() {
    return (
        <div className="col-sm-12 col-lg-6 d-none d-lg-block">
            <div className="row h-100 ">
                <div className="col-sm-12 align-self-start text-right pr-0">
                    <img src={BannerImage} alt="Medline Logo " className="img-fluid " />
                </div>
            </div>
        </div>
    )
}