import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './assign.module.scss'
import { ProductInputs } from './productInputs'
import { productAttribute, setProductAttributes } from '../../../app/customFormSlice';
import { MultiSelect } from '../../primary';
export const ProductAttributeDivision = ({fetchValue, index, defaultValue, productCategoryList}) => {
    let prodCat = useRef();
    let prodFam = useRef();
    const dispatch = useDispatch();
    
    useEffect(()=> {
        dispatch(setProductAttributes({}))
        dispatch(productAttribute(defaultValue)).then((res)=>{
            setAttributeList(res)
        })
    },[defaultValue])

    useEffect(()=>{
        const categoryDesc = productCategoryList?.data?.productCategoryDesc?.find((x)=>x?.key === defaultValue?.division)
        if(categoryDesc?.value?.length > 0) { setProductCat(categoryDesc?.value) }
    },[defaultValue])

    useEffect(()=>{
        let familyCode = [];
        defaultValue?.productCategoryDesc?.map((item)=>{//NOSONAR
            const productFamilCode = productCategoryList?.data?.productFamilyDesc?.find((x) => x?.key === item)
            if(productFamilCode?.value?.length > 0) {familyCode = [...familyCode, ...productFamilCode?.value]}//NOSONAR
        })//NOSONAR
        setProductFamilyCode(familyCode)
    },[productCategoryList, defaultValue])

    useEffect(()=>{
        setGetOptions(defaultValue)
    }, [defaultValue])

    const [getOptions, setGetOptions] = useState({})
    const [productCat, setProductCat] = useState([])
    const [productFamilyCode, setProductFamilyCode] = useState([])
    const [attributeList, setAttributeList] = useState([])
    const getAttributes = (data) => {
        prodCat.current.resetSelectedValues([]);
        prodFam.current.resetSelectedValues([]);
        const categoryDesc = productCategoryList?.data?.productCategoryDesc?.find((x)=>x?.key === data)
        if(categoryDesc?.value?.length > 0) { setProductCat(categoryDesc?.value) }
        dispatch(setProductAttributes({}))
        dispatch(productAttribute({division:data})).then((res)=>{
            setAttributeList(res)
        })
        setGetOptions({division:data})
        setDivision(false)
        setDefaultData(false);
    }
    
    const getAttributeValue = (value) => {
        if(value.productAttribute !=="") {
            setAttribute(false)
        }
        let data = {...getOptions, ...value}
        setGetOptions(data)
    }

    const [division, setDivision] = useState(false)

    const [attribute, setAttribute] = useState(false)

    const [defaultData, setDefaultData] = useState(true)

    const fetchOption = () => {
        let productAttr = {...getOptions}
        productAttr['fetchResult'] = 1
        const division = productAttr['division'];
        const categoryDesc = productAttr['productCategoryDesc'];
        const familyDesc = productAttr['productFamilyDesc']
        const productAtt = productAttr['productAttribute'];
        if(division === 0 || division === undefined || division ==='') {
            setDivision(true)
        } else {
            setDivision(false)
        }
        if(categoryDesc === 0 || categoryDesc === undefined) {
            productAttr['productCategoryDesc'] = []
        }
        if(familyDesc === 0 || familyDesc === undefined) {
            productAttr['productFamilyDesc'] = []
        }
        if(productAtt === 0 || productAtt === undefined || productAtt==='') {
            setAttribute(true)
        } else {
            setAttribute(false)
        }
        if(division && productAtt) {
            dispatch(setProductAttributes({}))
            dispatch(productAttribute(productAttr)).then((res)=>{
                fetchValue(index, res, productAttr)
                setAttributeList(res)
            })
        } 
    }

    const selectCategoryDesc = (list) => {
        prodFam.current.resetSelectedValues();
        let prodcutCat = [];      
        let productFamilyDes = [];
        list.map((item)=>{
            prodcutCat = [...prodcutCat, item.key]
            const categoryDesc = productCategoryList?.data?.productFamilyDesc?.find((x)=>x?.key === item.key)
            productFamilyDes = [...productFamilyDes, ...categoryDesc?.value]//NOSONAR
        })
        const cat = { productCategoryDesc: prodcutCat }
        const filter = { ...getOptions, ...cat }
        setGetOptions(filter);
        setProductFamilyCode(productFamilyDes);
        dispatch(setProductAttributes({}))
        dispatch(productAttribute(filter)).then((res)=>{
            setAttributeList(res)
        })  
    }

    const selectProductFamilyDesc = (list) => {
        let productFamily = [];
        list.map((item)=>{ 
            productFamily = [...productFamily, item.key]
        })
        const family = { productFamilyDesc: productFamily }
        const filter = { ...getOptions, ...family }
        setGetOptions(filter)
        dispatch(setProductAttributes({}))
        dispatch(productAttribute(filter)).then((res)=>{
            setAttributeList(res)
        })
    }
    return (
        <>
        <ProductInputs 
            label="Product Division"
            id={`productDivision${index}`}
            classes={{
                column: `col-6 ${styles.col_spec}`,
                inputClassName: `${styles.prod_select_search}`,
                labelClassName: `col-5  col-form-label ${styles.label_spec}`,
                inputColumn: `col-7 p-0 ${styles.select_wrapper} ${styles.select_wrapper_pro}`
            }}
            required={true}
            listData={productCategoryList?.data?.division}
            getValue={(val)=>getAttributes(val)}
            error={division ? 'Please Select Product Division' :''}
            defaultValue={defaultValue?.division}
        />

        <MultiSelect
            id={"categoryDesc"}
            name={"categoryDesc"}
            label={`Category Description`}
            classes={{
                column: `col-6 ${styles.col_spec}`,
                inputClassName: `${styles.prod_select_search}`,
                labelClassName: `col-5  col-form-label ${styles.label_spec}`,
                inputColumn: `col-7 p-0 ${styles.select_wrapper}`
            }}
            optionValue={productCat}
            displayValue="value"
            showCheckbox={true}
            onSelect={(list) => selectCategoryDesc(list)}
            onRemove={(list) => selectCategoryDesc(list)}
            validator = {(e)=>{
                prodCat.current = e
            }}
            defaultValue={defaultData ? defaultValue?.productCategoryDesc :''}
        />

        <MultiSelect
            id={"productFamilyDesc"}
            name={"productFamilyDesc"}
            label={`Product Family Description`}
            classes={{
                column: `col-6 ${styles.col_spec} pt-2`,
                inputClassName: `${styles.prod_select_search}`,
                labelClassName: `col-5  col-form-label ${styles.label_spec}`,
                inputColumn: `col-7 p-0 ${styles.select_wrapper}`
            }}
            optionValue={productFamilyCode}
            displayValue="value"
            showCheckbox={true}
            onSelect={(list) => selectProductFamilyDesc(list)}
            onRemove={(list) => selectProductFamilyDesc(list)}
            validator = {(e)=>{
                prodFam.current = e
            }}
            defaultValue={defaultData ? defaultValue?.productFamilyDesc : ''}
        />
        
        <ProductInputs 
            label="Product Attribute"
            id={`productAttribute${index}`}
            classes={{
                column: `col-6 ${styles.col_spec} pt-2`,
                inputClassName: `${styles.prod_select_search}`,
                labelClassName: `col-5  col-form-label ${styles.label_spec}`,
                inputColumn: `col-7 p-0 ${styles.select_wrapper} ${styles.select_wrapper_pro}`
            }}
            required={true}
            listData={attributeList?.data?.productAttribute}
            getValue={(key, value)=>getAttributeValue({productAttribute:key, productAttributeLabel:value})}
            error={attribute ? 'Please Select Product Attribute' :''}
            defaultValue={getOptions?.productAttribute}
        />
        <div className={`col-12 ${styles.col_spec} align-items-end justify-content-end`}>
            <div className={`btn ${styles.btn_fetch}`} onClick={()=>fetchOption()}>Fetch</div>
        </div>
        </>
    )
}
