import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../primary';
import { FACILITY_HEAD, PATIENT_GENDER, PATIENT } from '../../../constants'
import styles from './patientCard.module.scss'
import pictureIcon from '../../../static/images/picture-icon-default.png'
import { dateForPatientCard, dateOfBirthDate } from '../../../utils'
import {
    patientMoreInfo,
    createAssessMentWithoutPhoto,
    patientDetailsAsync
  } from "../../../app/patientSlice";
  import { setLoading } from '../../../app/appSlice';

export default function PatientCard({modalOpen, divHeight, history, dispatch}) {

    const patientData = useSelector(state => state.patient.detailViewData)

    // MoreInfo Navigation Page
    const moreInfoNavigation = (data) => {    
        dispatch(setLoading(true))
            history.push({
            pathname: '/patients/' + data + '/moreInfo',
            state: true
        });   
    }
    
    return (
        <>
        <div className={`col-12`}>
          <div className={`row`}>
            {patientData && patientData.firstName && 
            <div className={`col-12 ${!divHeight ? styles.patient_details_blk : styles.patient_details_blk_scroll}`}>
                <div className={`${styles.patient_image} mx-3`}>
                    <a href={`/patients/${patientData?.ne1Id}`} target="_self">
                        <img src={patientData?.moreInfo?.profilePicture || pictureIcon} alt={`${patientData?.firstName} ${patientData?.lastName}`}/>                    
                    </a>
                    {/* {!divHeight && <div className={'text-center'}>
                        <Button
                            type={"button"}
                            value={"More info >"}
                            classes={{ className: `btn ${styles.btn_custom_with_moreInfo} mt-2` }}
                            id="moreInfo"
                            onClick={() => moreInfoNavigation(patientData?.ne1Id)}
                        />
                    </div>} */}
                </div>
                <div className={`${styles.patient_details}`}>
                    <div className='d-flex align-items-center'>
                        <div className={'d-flex align-items-center'} >
                            <a href={`/patients/${patientData?.ne1Id}`} target="_self" className={'d-flex align-items-center'}>
                            <h4 className="pr-1 mb-0">{`${patientData?.firstName} ${patientData?.lastName}`}</h4>
                            {/* <h4 className="mb-0">{`${patientData?.lastName}`}</h4> */}
                            </a>
                        </div>
                        <div className={`verticalCode  ${styles.vertical_Code}`}>
                        </div>    
                        <p className={`${styles.labelSize} pt-1`}><span>{patientData?.age}</span></p>
                        <div className={`verticalCode  ${styles.vertical_Code}`}>
                        </div>
                        <p className={`${styles.labelSize} pt-1`}><span>{PATIENT_GENDER[patientData.gender ? patientData.gender.toLowerCase() : '']}</span></p>                        
                        <div className={'mx-4 text-center'}>
                            <Button
                                type={"button"}
                                value={"Diabetic"}
                                classes={{ className: (patientData?.moreInfo?.diabetic === "1" || patientData?.moreInfo?.diabetic === 1) ?  `btn ${styles.btn_custom_with_diabetic}` : 'd-none' }}
                                id="diabetic"
                            />
                        </div> 
                        <div className={`${styles.patient_mobile_card} ml-auto`} onClick={() => modalOpen()}>
                            <svg xmlns="
                                http://www.w3.org/2000/svg"
                                width="12" height="24" viewBox="0 0 11.393 18.228">
                                <path id="Subtraction_4" data-name="Subtraction 4" d="M-4999.886-342.772h-6.835a2.279,2.279,0,0,1-2.279-2.278v-13.671a2.279,2.279,0,0,1,2.279-2.278h6.835a2.279,2.279,0,0,1,2.279,2.278v13.671A2.279,2.279,0,0,1-4999.886-342.772Zm-3.417-2.848a1.139,1.139,0,0,0-1.139,1.139,1.139,1.139,0,0,0,1.139,1.139,1.139,1.139,0,0,0,1.139-1.139A1.139,1.139,0,0,0-5003.3-345.62Zm-2.734-13.027a1.709,1.709,0,0,0-1.823,1.556v9.342a1.709,1.709,0,0,0,1.823,1.556h5.468a1.708,1.708,0,0,0,1.823-1.556v-9.342a1.708,1.708,0,0,0-1.823-1.556Z" transform="translate(5009 361)" fill="#fff"/>
                            </svg>
                            <p>Register patient for mobile</p>
                        </div>                       
                    </div>

                    <div className='d-flex align-items-center'>
                        <p className={`${styles.labelSize}`}><label>MRN #:</label><span>{patientData?.mrn !== null ? patientData?.mrn : "-"}</span></p>
                        <div className={`verticalCode  ${styles.vertical_Code_one}`}>
                        </div>
                        <p className={`${styles.labelSize}`}><label>DOB:</label><span>{dateOfBirthDate(patientData?.dob)}</span></p>
                    </div>

                    {!divHeight && <div className='d-flex align-items-center pt-3'>
                        <p className={`${styles.labelSize_one}`}><label>{FACILITY_HEAD}:</label><span>{patientData?.facilityName}</span></p>                        
                    </div>}
                    
                    {!divHeight && <div className='d-flex align-items-center'>
                        <p className={`${styles.labelSize_one}`}><label>Unit:</label><span>{patientData?.careInfo?.unit !== null ? patientData?.careInfo?.unit : "-"}</span></p>
                        <div className={`verticalCode  ${styles.vertical_Code_one}`}>
                        </div>
                        <p className={`${styles.labelSize_one}`}><label>Room:</label><span>{patientData?.careInfo?.roomNumber !== null ? patientData?.careInfo?.roomNumber : "-"}</span></p>
                        <div className={`verticalCode  ${styles.vertical_Code_one}`}>
                        </div>
                        <p className={`${styles.labelSize_one}`}><label>Bed:</label><span>{patientData?.careInfo?.bed !== null ? patientData?.careInfo?.bed : "-"}</span></p>
                    </div>}  
                </div>
            </div>
            }
            </div>
        </div>
        </>
    )
}
