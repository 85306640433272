import { format } from 'date-fns';
const pdfDownloadDate = format(new Date(), "MM.dd.yyyy-hh.mm.aaa");

export const handleClickGenerateWoundReportPDF = (html2canvas, jsPDF, canvases, header, customerName) => {
    html2canvas(document.getElementById('pdf'), { scale: 2 }).then(function (canvas) {

        var imgWidth = 210;
        var pageHeight = 294;

        var doc = new jsPDF('p', 'mm');

        let size = 0

        // Below is the moving the content to next page based on fitting
        canvases.forEach((data, i) => {
            if (size + data.height > pageHeight) {
                doc.addPage();
                doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
                size = header.height
            }
            if (size === 0) {
                doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
                size = header.height
            }
            doc.addImage(data.imgData, 'PNG', 0, size, imgWidth, data.height);
            size += data.height
        })

        doc.save(`${customerName}.Wounds_Dashboard.${pdfDownloadDate}.pdf`);
    });

}
export const handleClickGenerateTreatmentPDF = (html2canvas, jsPDF, canvases, header, customerName) => {
    html2canvas(document.getElementById('pdf'), { scale: 2 }).then(function (canvas) {

        var imgWidth = 210;
        var pageHeight = 294;

        var doc = new jsPDF('p', 'mm');

        let size = 0

        // Below is the moving the content to next page based on fitting
        canvases.forEach((data, i) => {
            if (size + data.height > pageHeight) {
                doc.addPage();
                doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
                size = header.height
            }
            if (size === 0) {
                doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
                size = header.height
            }
            doc.addImage(data.imgData, 'PNG', 0, size, imgWidth, data.height);
            size += data.height
        })

        doc.save(`${customerName}.Treatment_Outcomes.${pdfDownloadDate}.pdf`);
    });

}
export const handleClickGenerateQualityReportPDF = (html2canvas, jsPDF, canvases, header, customerName) => {
    html2canvas(document.getElementById('pdf'), { scale: 2 }).then(function (canvas) {

        var imgWidth = 210;
        var pageHeight = 294;

        var doc = new jsPDF('p', 'mm');

        let size = 0

        // Below is the moving the content to next page based on fitting
        canvases.forEach((data, i) => {
            if (size + data.height > pageHeight) {
                doc.addPage();
                doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
                size = header.height
            }
            if (size === 0) {
                doc.addImage(header.imgData, 'PNG', 0, 0, imgWidth, header.height)
                size = header.height
            }
            doc.addImage(data.imgData, 'PNG', 0, size, imgWidth, data.height);
            size += data.height
        })

        doc.save(`${customerName}.Quality_Assurance.${pdfDownloadDate}.pdf`);
    });

}