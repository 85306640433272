import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  CONTENTURL, EXTRENAL_URL, GENERAL_ERROR, SALES_OFFICE_OPTIONS, SAVE_BTN, SEQUENCE, SUBMIT_ID, WEB_VIEW_URL, SEQUENCE_NUMBER,
  UPDATE_SUCCESS, UPDATE_FAILURE, SUCCESS_STATUS, ERROR_STATUS
} from '../../../constants';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { checkName, checkSalesOffice, contentUrl } from '../../../utils';
import { Button, Form, RadioButton, SelectBox, TextField } from '../../primary';
import { PictureIconMultiple } from '../../secondary';
import style from './editMarketingBannerView.module.scss';
import { updateLayoutAsync, getMarketingBanners, deleteMarketingBannerAsync, createMarketingBannertAsync, updateMarketingBannerAsync, setUpdateBannerStatus } from '../../../app/layoutsSlice';
import { useSelector, useDispatch } from 'react-redux';
import deleteIcon from '../../../static/images/delete-icon.png';
import { deepCopyArray, imageSizeFromFile } from '../../../utils/commonHelper';
import PlusIconBanner from '../../../static/images/banner_plus.svg';
import Loader from '../../../static/images/spinner.svg';	
export let EditMarketingBannerView = ({ layoutRowData, layoutId }) => {
  const imagePlusIcon = <img src={PlusIconBanner} className={`${style.plusimg}`} alt="Click to add" />
  const { register, handleSubmit, watch, errors, reset } = useForm({ mode: 'all' });
  let bannerData = useSelector(state => state.layouts.marketingBanner);
  const updateStatus = useSelector(state => state.layouts.updateBannerStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    if (layoutId) {
      dispatch(getMarketingBanners(layoutId))
    }
  }, [layoutId, dispatch]);
  const [editable, setEditable] = useState(false);
  const editMode = () => {
    setEditable(true);
  }
  const doBannerEdit = (data) => {
    data && data['banners'] && data['banners'].length > 0 && data['banners'].map((value, key) => {
      let urlTypeChk = ((value.urlType && value?.urlType === EXTRENAL_URL) ? 'external' : 'internal')
      let finalMpbilePic;
      if (value.mobile) {
        finalMpbilePic = value.mobile.replace('data:image/png;base64,', '')
        finalMpbilePic = finalMpbilePic.replace('data:image/jpeg;base64,', '')
        finalMpbilePic = finalMpbilePic.replace('data:image/jpg;base64,', '')
        finalMpbilePic = finalMpbilePic.replace('data:image/svg+xml;base64,', '')
      }
      let finalTabletPic;
      if (value.tablet) {
        finalTabletPic = value.tablet.replace('data:image/png;base64,', '')
        finalTabletPic = finalTabletPic.replace('data:image/jpeg;base64,', '')
        finalTabletPic = finalTabletPic.replace('data:image/jpg;base64,', '')
        finalTabletPic = finalTabletPic.replace('data:image/svg+xml;base64,', '')
      }
      let banner_payload = {
        "mobile": finalMpbilePic,
        "tablet": finalTabletPic,
        "contentURL": value.Url,
        "contentURLType": urlTypeChk,
        "sequence": value.filterSequence,
      }
      if (value.bannerId) {
        banner_payload['id'] = value.bannerId;
        dispatch(updateMarketingBannerAsync(banner_payload, layoutId, value.bannerId))
      } else {
        dispatch(createMarketingBannertAsync(banner_payload, layoutId))
      }
    })
    const layout_payload = {
      "id": layoutId,
      "name": data.name,
      "type": layoutRowData.type,
      "presetLocation": layoutRowData.presetLocation,
      "salesOffice": data.filterSalesOffice,
    }
    dispatch(updateLayoutAsync(layout_payload))
    setEditable(false);
    reset();
  }
  let clone_json = { "id": "", "mobile": "", "tablet": "", "contentURL": "", "contentURLType": "external", "sequence": "" }
  let [inputList, setInputList] = useState();
  const handleAddBannerClick = () => {
    if (inputList && Array.isArray(inputList)) {
      setInputList([...inputList, clone_json]);
    }
  };
  const handleRemoveBannerClick = (index, bannerValues) => {
    if (bannerValues.id && bannerValues.id !== '' && bannerValues.id !== null) {
      dispatch(deleteMarketingBannerAsync(layoutId, bannerValues.id))
      reset();
      let tempInputList = deepCopyArray(bannerData);
      setInputList(tempInputList);
    } else {
      let list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    }
  };
  useEffect(() => {
    setInputList(bannerData);
  }, [bannerData, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setUpdateBannerStatus(false))
    }, 5000)
  }, [updateStatus, dispatch])
  const preSetLocationArr = [{ value: 'Skin Health Homepage', key: 'skinhealthhomepage' }, { value: 'Education', key: 'education' }, { value: 'Wound Assessment', key: 'woundassessment' }]
  const viewMode = () => {
    reset();
    setInputList(bannerData);
    setEditable(false);
  }
  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])
  return (<div>
    <div className={style.tab_pane}>
      <div className={`d-flex justify-content-between ${style.container_wrapper}`}>
        <div className={style.heading}>Marketing Banner</div>
        {tinyDelay&&!editable && <div className={style.edit_icon} onClick={() => editMode()}><img src={editIcon} alt="icon" />&nbsp;Edit</div>
        }
      </div>
      {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
        <>
      {
        !editable ? <div className="col-12 p-4">
          {updateStatus === SUCCESS_STATUS && <div className={`alert alert-success ${style.form_alert}`}>{UPDATE_SUCCESS}</div>}
          {updateStatus === ERROR_STATUS && <div className={`alert alert-danger ${style.form_alert}`}>{UPDATE_FAILURE}</div>}
          <div className={`row`}>
            <div className={`col-6`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Name: </label>
                <div className={`col-6 ${style.data_color}`}>
                  {layoutRowData && layoutRowData.name}
                </div>
              </div>
            </div>
            <div className={`col-6`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Sales Office: </label>
                <div className={`col-6 ${style.data_color}`}>
                  {layoutRowData && SALES_OFFICE_OPTIONS.map(e => e.key == layoutRowData.salesOffice && e.value)}
                </div>
              </div>
            </div>
            <div className={`col-6 mt-3`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Type:</label>
                <div className={`col-6 ${style.data_color}`}>
                  {layoutRowData && layoutRowData.type && <span>Marketing Banner</span>}
                </div>
              </div>
            </div>
            <div className={`col-6 mt-3`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Preset Location:</label>
                <div className={`col-6 ${style.data_color}`}>
                  {layoutRowData && layoutRowData.presetLocation && preSetLocationArr.filter((f) => f.key === layoutRowData.presetLocation)[0]?.value}
                </div>
              </div>
            </div>
          </div>
          {bannerData && bannerData.length > 0 && Array.isArray(bannerData) && bannerData.map((bannerValues, i) => {
            return (
              <>
                <div className={`row mt-3`}>
                  <label className={`col-3 col-form-label ${style.label_spec}`}>Banner Image {i + 1}: </label>
                  <div className={`col-4 col-form-label ${style.content_spec}`}>
                    Mobile <span className={` ${style.banner_size}`}>(375x187px)</span>
                    <a href="#/"><img src={bannerValues.mobile} alt="" className={` ${style.img_fluid}`} /></a>
                  </div>
                  <div className={`col-4 col-form-label ${style.content_spec}`}>
                    Tablet <span className={` ${style.banner_size}`}>(834 x 437px)</span>
                    <a href="#/"><img src={bannerValues.tablet} alt="" className={` ${style.img_fluid}`} /></a>
                  </div>
                </div>
                <div className={`row mt-3`}>
                  <label className={`col-3 pt-2 ${style.label_cutom}`} >Content URL: </label>
                  <div className={`col-5 pt-1`}>
                    {bannerValues && bannerValues.contentURL}
                  </div>
                </div>
                <div className={`row mt-3`}>
                  <div className={`col-6 mt-3`}>
                    <div className={`row`}>
                      <label className={`col-6 ${style.label_cutom}`} >Sequence: </label>
                      <div className={`col-6 ${style.data_color}`}>
                        {bannerValues && bannerValues.sequence}
                      </div>
                    </div>
                  </div>
                </div>
                {Array.isArray(bannerData) && bannerData.length !== i + 1 && <div className="dropdown-divider"></div>}
              </>
            )
          })
          }
        </div> :
          <div className="col-12 p-4">
            <Form formChanges={true} onSubmit={handleSubmit(doBannerEdit)}>
              {Object.keys(errors).length !== 0 && <div className="col-12 pl-0"><span className={style.general_error}>{GENERAL_ERROR}</span></div>}
              <div className={`row`}>
                <div className={`col-6`}>
                  <TextField
                    classes={{
                      column: `row mb-4`,
                      labelClass: `col-6 ${style.label_cutom}`,
                      inputColumn: 'col-6',
                      inputClassName: `${style.input_field}`
                    }}
                    label={'Name:'}
                    type={"text"}
                    name={'name'}
                    id={'bannerNameId'}
                    defaultValue={layoutRowData && layoutRowData.name}
                    validator={register(checkName)}
                    required={true}
                    errors={errors}
                  />
                </div>
                <div className={`col-6`}>
                  <SelectBox
                    id={'salesOffice'}
                    name={'filterSalesOffice'}
                    label={'Sales Office:'}
                    classes={{
                      column: `row ${style.col_spec}`,
                      inputClassName: `${style.basic_select} ${style.first_child}`,
                      labelClassName: `col-5 pl-0 col-form-label mt-0 ${style.label_cutom}`,
                      inputColumn: `col-6 p-0  ${style.select_wrapper}`
                    }}
                    placeholder="Select"
                    optionValue={SALES_OFFICE_OPTIONS}
                    validator={register(checkSalesOffice)}
                    errors={errors}
                    required={true}
                    defaultValue={layoutRowData && layoutRowData.salesOffice}
                  />
                </div>
                <div className={`col-6 mt-3`}>
                  <div className={`row`}>
                    <label className={`col-6 ${style.label_cutom}`} >Type:</label>
                    <div className={`col-6 ${style.data_color}`}>
                      {
                        'Marketing Banner'
                      }
                    </div>
                  </div>
                </div>
                <div className={`col-6 mt-3`}>
                  <div className={`row`}>
                    <label className={`col-5 ${style.label_cutom}`} >Preset Location:</label>
                    <div className={`col-6 p-0 ${style.data_color}`}>
                      {
                        layoutRowData && layoutRowData.presetLocation && preSetLocationArr.filter((f) => f.key === layoutRowData.presetLocation)[0]?.value
                      }
                    </div>
                  </div>
                </div>
              </div>
              {
                inputList && inputList.length > 0 && inputList.map((bannerValues, i) => {
                  return (
                    <>
                      <div className={`row mt-3`}>
                        <div className={`col-12`}>
                          <div className={`row mt-3`}>
                            <label className={`col-3 col-form-label ${style.label_spec}`}>Banner Image {inputList&&inputList.length === 1 ? '' : i + 1}: <span>*</span></label>
                            <div className={`col-4 col-form-label ${style.content_spec}`}>
                              Mobile <span className={` ${style.banner_size}`}>(375x187px)</span>
                              <input type="hidden" name={`banners[${i}][bannerId]`} id={`banners[${i}][bannerId]`} ref={register} style={{ visibility: "hidden" }} defaultValue={(bannerValues && bannerValues.id) ? bannerValues.id : ''} />
                              <PictureIconMultiple
                                classes={{ pic_wrapper: `${style.img_fluid}`, contr_wrapper: `mt-0 `, change_label: ` ${style.banner_link}` }}
                                previewId={`banners[${i}][mobileFile]`}
                                id={`banners[${i}][mobileFile]`}
                                name={`banners[${i}][mobileFile]`}
                                imageURL={`banners[${i}][mobile]`}
                                imagevalidator={register}
                                browseLbl={'Upload Banner '}
                                marketingBanner={true}
                                subName={`mobile`}
                                validateName={`mobileFile`}
                                refKeys={`${i}`}
                                inputList={inputList}
                                setInputList={setInputList}
                                validator={register({
                                  validate: (value) => {
                                    if (value && value[0]) {
                                      const imgResolutionCheck = async () => {
                                        const imageDimensions = await imageSizeFromFile(value);
                                        if (imageDimensions && imageDimensions.width && imageDimensions.height) {
                                          if (imageDimensions.width < 375 || imageDimensions.width > 833 || imageDimensions.height < 187 || imageDimensions.height > 436) {
                                            return 'Please upload required Size and Pixel';
                                          }
                                        }
                                      }
                                      let FileSize = value[0].size / 1024 / 1024; // in MiB
                                      if (FileSize > 2) {
                                        return 'File size exceeds 2 MB';
                                      } else {
                                        return imgResolutionCheck();
                                      }
                                    } else if (!value[0] && bannerValues.mobile === '' || bannerValues.mobile === null) {
                                      return 'Please upload banner';
                                    }
                                  }
                                })}
                                errors={errors}
                                required={true}
                              />
                            </div>
                            <div className={`col-4 col-form-label ${style.content_spec}`}>
                              Tablet <span className={` ${style.banner_size}`}>(834 x 437px)</span>
                              <PictureIconMultiple
                                classes={{ pic_wrapper: `${style.img_fluid}`, contr_wrapper: `mt-0 `, change_label: ` ${style.banner_link}` }}
                                previewId={`banners[${i}][tabletFile]`}
                                id={`banners[${i}][tabletFile]`}
                                name={`banners[${i}][tabletFile]`}
                                imageURL={`banners[${i}][tablet]`}
                                imagevalidator={register}
                                browseLbl={'Upload Banner '}
                                marketingBanner={true}
                                subName={`tablet`}
                                validateName={`tabletFile`}
                                refKeys={`${i}`}
                                inputList={inputList}
                                setInputList={setInputList}
                                // onChange={e =>handleImageChange(e,i,`tablet`)}
                                validator={register({
                                  validate: (value) => {

                                    if (value && value[0]) {
                                      const imgResolutionCheck = async () => {
                                        const imageDimensions = await imageSizeFromFile(value);
                                        if (imageDimensions && imageDimensions.width && imageDimensions.height) {
                                          if (imageDimensions.width < 834 || imageDimensions.width > 1023 || imageDimensions.height < 290 || imageDimensions.height > 570) {
                                            return 'Please upload required Size and Pixel';
                                          }
                                        }
                                      }
                                      let FileSize = value[0].size / 1024 / 1024; // in MiB
                                      if (FileSize > 2) {
                                        return 'File size exceeds 2 MB';
                                      } else {
                                        return imgResolutionCheck();
                                      }
                                    }
                                    else if (!value[0] && bannerValues.tablet === '' || bannerValues.tablet === null) {
                                      return 'Please upload banner';
                                    }
                                  }
                                })}
                                errors={errors}
                              />
                            </div>
                          </div>
                          <div className={`row mt-3`}>
                            <div className="col-3 mb-4 ">
                              <label className={style.label_cutom}>Content URL:</label>
                            </div>
                            <div className="col-9">
                              <div className="row">
                                <div className="col-12">
                                  <RadioButton
                                    id={CONTENTURL + '_' + i}
                                    name={`banners[${i}][urlType]`}
                                    classes={{
                                      labelClass: `custom-control-label mb-2 ${style.custom_control_label}`,
                                      inputClassName: `custom-control-input ${style.custom_control_input}`,
                                      inputColumn: "custom-control custom-radio custom-control-inline"
                                    }}
                                    radioOption={[EXTRENAL_URL, WEB_VIEW_URL]}
                                    defaultValue={(bannerValues.contentURLType && bannerValues.contentURLType === 'external') ? EXTRENAL_URL
                                      : (bannerValues.contentURLType && bannerValues.contentURLType === 'internal') ? WEB_VIEW_URL
                                        : ""}
                                    //defaultValue={(bannerValues.contentURLType && bannerValues.contentURLType==='external')?EXTRENAL_URL:WEB_VIEW_URL}
                                    validator={register}
                                  />
                                </div>
                                <div className="col-12 mb-3">
                                  <TextField
                                    classes={{
                                      column: `row mb-4`,
                                      labelClass: `col-4 ${style.label_spec}`,
                                      inputColumn: 'col-12',
                                      inputClassName: `w-100 ${style.input_field}`
                                    }}
                                    id={'banners_Url_' + i}
                                    type={"text"}
                                    name={`banners[${i}][Url]`}
                                    subName={`Url`}
                                    validator={register(contentUrl)}
                                    required={true}
                                    errors={errors}
                                    defaultValue={bannerValues && bannerValues.contentURL}
                                    refKeys={`${i}`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`col-12 mt-3`}>
                            <TextField
                              classes={{
                                column: `row`,
                                labelClass: `col-3 p-0 ${style.label_cutom}`,
                                inputColumn: 'col-6 pl-2',
                                inputClassName: `${style.small_input} ${style.input_field}`
                              }}

                              id={`filterSequence_${i}`}
                              label={SEQUENCE}
                              type={"number"}
                              name={`banners[${i}][filterSequence]`}
                              subName={`filterSequence`}
                              //  validator={register(sequenceNumber)}
                              validator={register({
                                validate: (value) => {
                                  if (!value) {
                                    return SEQUENCE_NUMBER;
                                  } else if (value < 1) {
                                    return 'Please enter valid Sequence Number'
                                  }
                                  else if (inputList && inputList.length > 0) {
                                    let chckSeq = 0;
                                    inputList.forEach((item, keys) => {
                                      if (i !== keys && watch(`banners[${keys}][filterSequence]`) === value) {
                                        chckSeq = 1;
                                      }
                                    })
                                    if (chckSeq === 1) {
                                      return 'This Sequence has been taken already';
                                    }
                                  }
                                }
                              })}
                              required={true}
                              errors={errors}
                              defaultValue={bannerValues && bannerValues.sequence}
                              refKeys={`${i}`}
                            />
                          </div>
                        </div>
                        <div className={`col-auto col-form-label pr-4 ${style.content_spec} ${style.pos_delete}`}>
                          <a href="#/" onClick={() => handleRemoveBannerClick(i, bannerValues)} className="delBanner"><img src={deleteIcon} alt="" /></a>
                        </div>
                      </div>
                    </>
                  )
                })
              }
              {/* old design button
              {inputList && inputList.length !== 0 && <div className="row col-sm-12 mt-3">
                <a href="#/" onClick={() => handleAddBannerClick()} id="addBanner">
                  <img src={addBannerIcon} alt="" />
                </a>
              </div>} */}
              <div className="row">
                <div className="col-3 offset-3">
                  <Button
                    id={'addBanner'}
                    type={'button'}
                    classes={{ className: `mt-2 ${style.add_banner_txt}` }}
                    onClick={() => handleAddBannerClick()}
                    title={'Add Banner'}
                  >
                    {imagePlusIcon}
                    {'Add Banner'}
                  </Button>
                </div>
              </div>
              <div className={style.btn_container}>
                <Button
                  id={"creat_list_view_menu"}
                  type={SUBMIT_ID}
                  value={SAVE_BTN}
                  classes={{ className: `btn mb-1 ${style.btn_save}` }}
                />
                <Button
                  id={'cancel'}
                  type={"button"}
                  value={'Cancel'}
                  classes={{ className: `btn mb-1 ${style.btn_cancel}` }}
                  onClick={() => viewMode()}
                />
              </div>
            </Form>
          </div>
      }</>}
    </div>
  </div>)
}

EditMarketingBannerView.propTypes = {
  layoutRowData: PropTypes.any,
}
