import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef,useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  ERROR_STATUS,
  LAYOUT_CREATED_MSG,
  CANCEL_BTN,
  CONTENTURL,
  CREATE_FILTER,
  CREATE_LIST_VIEW_MENU,
  EXTRENAL_URL,
  GENERAL_ERROR,
  LIST_VIEW_ICON,
  LIST_VIEW_MENU_SEQUENCE_ID,
  SALES_OFFICE_OPTIONS,
  SAVE_BTN,
  SEQUENCE,
  SUBMIT_ID,
  UPLOAD_ICON,
  WEB_VIEW_URL,
  SUCCESS_STATUS,
} from '../../../constants';
import { checkName, checkSalesOffice, contentUrl, sequenceNumber, getFacilityLabel,ServiceError,useDebounce } from '../../../utils';
import { Button, Form, RadioButton, SelectBox, TextField,Organizational } from '../../primary';
import { MultiSelect } from '../../primary/MultiSelect';
import { Modal, PictureIcon, ActionModal } from '../../secondary';
import style from './createLayouts.module.scss';
import { createLayoutAsync, setNewLayoutStatus, setUpdateLayout, getLayoutAsync, getDocumentList, updateDocumentList,resetLayoutDetailsData,setDocumentList,reSetFacilitiesData,getCustomerNumberSuggestionsLayout,setExcludeNumberSearch,getAutoSeqNumber,setAutoSeqNumber} from '../../../app/layoutsSlice';
import{getCustomerNumberSuggestions, setCustomerNumberSearch } from '../../../app/userSlice'
import { useDispatch, useSelector } from 'react-redux';
import iconSuccess from '../../../static/images/icons-check-success.png';
import iconDanger from '../../../static/images/icon-check-danger.png'
import rightArrow from '../../../static/images/right_arrow.svg';
import PlusIcon from '../../../static/images/plus-icon_white.svg';
import  {LayoutDocument}  from '../Layouts/layoutDocument';
import { useParams } from 'react-router-dom';
import DocumentIcon from '../../../static/images/products/document_icon.svg';
import { handleDeleteMove } from '../../../utils/productsHelper';
import CloseIcon from '../../../static/images/products/close_icon.svg';
import { clearError } from "../../../app/appSlice";
import ReactTags from "react-tag-autocomplete";
export let CreateListViewMenu = ({ menuList, dropRef,productType }) => {
  let listViewMenus = useSelector(state => state.layouts.listViewMenus);
  let layoutData = useSelector(state => state.layouts.orgsData);
  const documentListItems = useSelector(state => state.layouts.documentList);
  // let facilitiesData = useSelector(state => state.forms.orgData);
  const { register, handleSubmit, errors, reset,setError, setValue } = useForm({ mode: 'onSubmit' });
  const dispatch = useDispatch();
  const newLayout = useSelector(state => state.layouts.newLayoutStatus);
  const [msgModal, setMsgModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [previewImg, setPreviewImg] = useState(null);
  const [buttonState, setButtonState] = useState(true);
  const [isValidSaleCust, setIsValidSaleCust] = useState(false);
  const [isValidPdf,setIsValidPdf] = useState(false);
  const [addedList,setAddedList] = useState([]);

  const [layoutType, setLayoutType] = useState('');

  const autoSeqNumber = useSelector(state => state.layouts.autoSeqNumber);
  
  useEffect(() => {
      if(autoSeqNumber?.data&&document.getElementById('sequence')){
        document.getElementById('sequence').value=autoSeqNumber?.data
      }
  }, [autoSeqNumber])

  let optionData = [];
    const soldToFacilities = layoutData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
    soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
        const shipToFacilities = layoutData.data.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
        if (shipToFacilities.length === 0) {
            optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(soldToFacility),
                facilityNumber: soldToFacility.facilityNumber,
                soldToFacility: 'soldToFacility'
            })
        }
        shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
            optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(shipToFacility),
                facilityNumber: shipToFacility.facilityNumber,
                shipToFacilities: 'shipToFacilities'
            })
        })
    })
    const [editable, setEditable] = useState(false)
    const { productId } = useParams()

  const modalClose = () => {
    dispatch(setNewLayoutStatus(false))
    setMsgModal(false);
    setIsValidSaleCust(false);
    setIsValidPdf(false);
    setCustomerNumber("")
    setUrlText('');
  }
  const msgModalOpen = () => {
    setMsgModal(true);
  }
const validateSalesCust = () =>{
  let docLength = documentListItems?.documents && documentListItems?.documents?.length;
  if((customerNumber == '' && customerGroup=='') && (textUrl == '' && docLength==undefined)) {
    setIsValidSaleCust(true);
    setIsValidPdf(true);    
  }
  else  if(customerNumber == '' && customerGroup==''){
    setIsValidSaleCust(true);
  }
  else if(textUrl == '' && docLength==undefined){
    setIsValidPdf(true);   
  }
  else  {
    setIsValidSaleCust(false);
    setIsValidPdf(false);
}
}
  const [errorAssign, setErrorAssign] = useState(false)
  const listViewMenuSubmit = (data) => {
    setUpdateLayout({})
    let soldToNumbers = [];
    org?.forEach((soldTo) => {
            const facilityNumber = soldTo?.cat?.split('|')[0]?.trim();
            if (!soldToNumbers.find(st => st.soldToNumber === facilityNumber)) {
                soldToNumbers.push({
                    "soldToNumber": facilityNumber,
                    "shipToNumbers": org?.filter(k => k.cat === soldTo.cat).map(shipTo => shipTo?.key?.split('|')[0]?.trim())
                });
            }
        })
        let docLength = documentListItems?.documents && documentListItems?.documents?.length;
        const urlTypeChk = (data.listUrlType == WEB_VIEW_URL  ? 'internal' : 'external')
        let finalPic;
    if (previewImg) {
      finalPic = previewImg.replace('data:image/png;base64,', '')
      finalPic = finalPic.replace('data:image/jpeg;base64,', '')
      finalPic = finalPic.replace('data:image/jpg;base64,', '')
      finalPic = finalPic.replace('data:image/svg+xml;base64,', '')
    }
       /* const tempUserData = {
           customerGroup: customerGroup,
            organization:  {
              primaryGroupNumber: layoutData?.data?.facilities.find((f) => f.accountGroup === 'Z002').facilityNumber,
              soldToNumbers,
          } 
        }*/
        soldToNumbers=Array.isArray(soldToNumbers)&&soldToNumbers.length===0?null:soldToNumbers;
        const tempUserData = {
          customerNumber: customerNumber&&soldToNumbers? customerNumber : null,
          organization: customerNumber&&soldToNumbers? {
              primaryGroupNumber: layoutData?.data?.facilities.find((f) => f.accountGroup === 'Z002').facilityNumber,
              soldToNumbers
          } : null,

      }


    let finalCustomerCodeTag = [];
    finalCustomerCodeTag=Array.isArray(customerCodeTag)&&customerCodeTag?.map(
      (e) => {
        return e.id;
      }
    );
const payload = 
{
  "name": data.name,
  "type": "listviewmenu",
  "presetLocation": "skinhealthhomepage",
  "salesOffice":customerGroup ?customerGroup : '',
  "excludedCustomers":Array.isArray(finalCustomerCodeTag)&&finalCustomerCodeTag.length>0?finalCustomerCodeTag:null,
  "menuIcon":finalPic ? finalPic : data.picture || data.picture == "" ? "" : finalPic,
  //"contentURL": data.url,
  "contentURL":textUrl,
  "contentURLType": textUrl ? urlTypeChk : '' ,
  "sequence": parseInt(data.listSeque),
  "listViewMenus": [
    10
  ],
  "customerInfo": tempUserData,
  documents: 
  documentListItems.documents ? documentListItems.documents : ''
}


if((customerNumber == '' && customerGroup=='') && (textUrl == '' && docLength==undefined)) {
  setIsValidSaleCust(true);
  setIsValidPdf(true);    
}
else  if(customerNumber == '' && customerGroup==''){
  setIsValidSaleCust(true);
}
else if(textUrl == '' && (docLength==undefined || docLength==0)){
  setIsValidPdf(true);   
}
else if(customerNumber !== ''&&tempUserData?.customerNumber==null){
  setIsValidSaleCust(true);
}
else  {
  setIsValidSaleCust(false);
  setIsValidPdf(false);
  dispatch(createLayoutAsync(payload))
}
 
} 
const onChangeOrg = (selectedList=[{}], selectedItem) => {
  setErrorAssign(false);
  setOrg([...selectedList]);
}
const getOrganization = () => {
  const customerNum = document.getElementById("customerNumber").value;
      if(customerNum){
        setOrg([])
        setRemoveDefaultOrg(false)
        dispatch(reSetFacilitiesData({}))
        setCustomerNumber(customerNum)
        dispatch(getLayoutAsync(customerNum))
        setCreateGroup(false);
        setCustomerNumberInp(true);
        setIsValidSaleCust(false);
        setShowExclude(false)
      } 
}
let [customerNumber, setCustomerNumber] = useState("");
let [removeDefaultOrg, setRemoveDefaultOrg] = useState(true)
  
const enterCustomerNumber = (customerNumber) => {
  const dynCustomerNumber=customerNumber
  if(document.getElementById('sequence')){
    document.getElementById('sequence').value = ""
  }
  dispatch(setAutoSeqNumber({}))
  if (dynCustomerNumber) {
      const payload = {sequenceNumber: "",layoutType: layoutType,customerNumber:dynCustomerNumber}
      dispatch(getAutoSeqNumber({payload}))	
      setOrg([])
      setRemoveDefaultOrg(false)
      dispatch(reSetFacilitiesData({}))
      setCustomerNumber(dynCustomerNumber)
      dispatch(getLayoutAsync(dynCustomerNumber))
      setCreateGroup(false);
      setCustomerNumberInp(true)
      setIsValidSaleCust(false);
  }
  if (dynCustomerNumber === "") {
      setOrg(null)
      setRemoveDefaultOrg(false)
      setCustomerNumberInp(false)
      setIsValidSaleCust(true);
      setCreateGroup(false);
      setCustomerNumber("")
      dispatch(reSetFacilitiesData({}))
  }
}
const enterTextUrl = (e) =>{
 setUrlText(e.target.value);
 setIsValidPdf(false);

}
const defaultFacilities = () => {
  const facilities = [];
  layoutData?.organizationDetailInfo?.soldToNumbers?.forEach((soldTo) => { //NOSONAR
      const soldToFacility = layoutData?.data?.facilities?.find((f) => f.facilityNumber === soldTo.soldToNumber) //NOSONAR
      let soldToFacilityLabel, shipToFacilityLabel;
      if (soldToFacility) {
          soldToFacilityLabel = getFacilityLabel(soldToFacility);
      }
      const shipToFacilities = layoutData?.data?.facilities?.filter((f) => soldTo.shipToNumbers.includes(f.facilityNumber)) //NOSONAR
      if (shipToFacilities && shipToFacilities.length > 0) {
          shipToFacilities.forEach((shipToFacility) => {
              shipToFacilityLabel = getFacilityLabel(shipToFacility);
              facilities.push({ cat: soldToFacilityLabel, key: shipToFacilityLabel });
          });
      }
  }) //NOSONAR
  return facilities;
}

const [org, setOrg] = useState(defaultFacilities());
 /* CustomerNumber search Suggestions  */   
 const [custNumInput, setCustNumInput] = useState("");
 const [isSelect, setIsSelect] = useState(false);
 const searchTerm = useDebounce(custNumInput, 500);
const [errorMsg, setErrorMsg] = useState("");
const customerNumberSearchDatas = useSelector(
  (state) => state.user.customerNumberSearch
);
useEffect(() => {
  if (!isSelect && searchTerm && searchTerm.length >= 1) {
    dispatch(getCustomerNumberSuggestions(searchTerm));
  } else {
    dispatch(setCustomerNumberSearch({}));
  }
}, [searchTerm]);

const getCustomerNumberSearch = (event) => {  
  if(event === ''){
      setErrorMsg("please enter any customer number")
      setCustNumInput("")
  }else {    
     // setIsValidSaleCust(false)       
      setCustNumInput(event)
      setIsSelect(false);
      setErrorMsg('')                        
  }  
};

const ClearData = (value) => { 
  document.getElementById('customerNumber').value = null ;        
  setCustNumInput("");
  dispatch(reSetFacilitiesData({}))
}

const customerNumberSelect = (e, items) => {
  e.stopPropagation();       
  setIsSelect(true);
  dispatch(setCustomerNumberSearch({}));                 
  if (items) {
    setErrorMsg('')          
    const customerNumber = items;
    setCustNumInput(customerNumber);
    document.getElementById('customerNumber').value = customerNumber;
    document.getElementById('customerNumber').value = items; 
    enterCustomerNumber(customerNumber)    
  }      
}

const [createGroup, setCreateGroup] = useState(false);
const [customerNumberInp, setCustomerNumberInp] = useState(false);
const [textUrl,setUrlText] = useState('');
const [customerGroup, setCustomerGroup] = useState("");
const getCustomerGroup = (e) => {
  if(document.getElementById('sequence')){
    document.getElementById('sequence').value = ""
  }
  dispatch(setExcludeNumberSearch({}))
  dispatch(setAutoSeqNumber({}))
  if (e.target.value !== "") {
    if(layoutType){
      const payload = {sequenceNumber: "",layoutType: layoutType,groupType:e.target.value}
      dispatch(getAutoSeqNumber({payload}))	
      } 
      setCustomerGroup(e.target.value)
      setCreateGroup(true);
      //assignForm.current.resetSelectedValues();
      document.getElementById('customerNumber').value = ""
      setErrorAssign(false)
      setRemoveDefaultOrg(false)
      setIsValidSaleCust(false);
      setShowExclude(true)
       setCustomerCodeSuggestions([])
    setCustomerCodeTag([])
  } else {
      setCustomerGroup("");
      setCreateGroup(false);
      setShowExclude(false)
      // setIsValidSaleCust(true);
  }
}
//end

  useEffect(() => {
    if (newLayout === "success") {
      reset();
      dispatch(setDocumentList({}))
      dispatch(setExcludeNumberSearch({}))
      setCustomerGroup('')
      dispatch(setExcludeNumberSearch({}))
      setCustomerCodeSuggestions([])
      setCustomerCodeTag([])
      setShowExclude(false)
      setModalOpen(false);
      setFilterModal(false);
     
    }
  }, [newLayout])

  const typeOfDeviceRef = useRef();
  const [typeOfDeviceData, setTypeOfDeviceData] = useState([]);
  const handleTypeOfDeviceSelect = () => {
    const selectedItems = typeOfDeviceRef.current.getSelectedItems()
    const tempData = selectedItems.map((data) => {
      return data.id
    })
    setTypeOfDeviceData(tempData)
  }

  const createFilterSubmit = (data) => {
    setUpdateLayout({})
    let soldToNumbers = [];
    org?.forEach((soldTo) => {
            const facilityNumber = soldTo?.cat?.split('|')[0]?.trim();
            if (!soldToNumbers.find(st => st.soldToNumber === facilityNumber)) {
                soldToNumbers.push({
                    "soldToNumber": facilityNumber,
                    "shipToNumbers": org?.filter(k => k.cat === soldTo.cat).map(shipTo => shipTo?.key?.split('|')[0]?.trim())
                });
            }
        })
        soldToNumbers=Array.isArray(soldToNumbers)&&soldToNumbers.length===0?null:soldToNumbers;
        const tempUserData = {
          customerNumber: customerNumber && soldToNumbers? customerNumber : null,
          organization: customerNumber&&soldToNumbers? {
              primaryGroupNumber: layoutData?.data?.facilities.find((f) => f.accountGroup === 'Z002').facilityNumber,
              soldToNumbers
          } : null,

      }      
    let finalCustomerCodeTag = [];
    finalCustomerCodeTag=Array.isArray(customerCodeTag)&&customerCodeTag?.map(
      (e) => {
        return e.id;
      }
    );
    const payload = {
      "name": data.filterName,
      "type": "filter",
      "salesOffice":customerGroup ?customerGroup : '',
      "excludedCustomers":Array.isArray(finalCustomerCodeTag)&&finalCustomerCodeTag.length>0?finalCustomerCodeTag:null,
      "sequence": data.filterSequence,      
      "presetLocation": "skinhealthhomepage",
      "listViewMenus": typeOfDeviceData,
      "customerInfo": tempUserData,
      "contentURL":"",
      "contentURLType": "" ,
    }

    if(customerNumber === '' && customerGroup==='') {
      setIsValidSaleCust(true);
      setIsValidPdf(true);    
    }    
   
    else if(customerNumber !== ''&& tempUserData?.customerNumber===null){
      setIsValidSaleCust(true);
    }
    else  {
      setIsValidSaleCust(false);
      setIsValidPdf(false);
      dispatch(createLayoutAsync(payload))
    }
    // if (newLayout === !SUCCESS_STATUS) {
    //   setNewLayoutStatus(false)
    // }
  }



  
  useEffect(() => {
   // setLayoutType('')
    setCustomerGroup('')
    dispatch(setNewLayoutStatus({}))
    setIsValidSaleCust(false);
    setIsValidPdf(false);
    setCustomerNumberInp(false)
    setCustomerNumber("")
    setShowExclude(false)
  }, [dispatch])
  const listViewMenuModal = () => {
    dispatch(setAutoSeqNumber({}))
    /*const payload = {sequenceNumber: "",layoutType: "listviewmenu"}
    dispatch(getAutoSeqNumber({payload}))*/
    dispatch(setNewLayoutStatus({}))
    modalBoxClose();
    setModalOpen(true);
    setLayoutType('listviewmenu')
  }
  const filterModalOpen = () => {
    dispatch(setAutoSeqNumber({}))
   /*const payload = {sequenceNumber: "",layoutType: "filter"}
    dispatch(getAutoSeqNumber({payload}))*/
    dispatch(setNewLayoutStatus({}))
    modalBoxClose();
    setFilterModal(true);
    setLayoutType('filter')
  }
  const getSeqNum = (e,layoutType) => {
    if(e?.target?.value){
    const payload = {sequenceNumber: e?.target?.value,layoutType: layoutType}
        dispatch(getAutoSeqNumber({payload}))
    }   
  }
  const modalBoxClose = (e) => {
    setLayoutType('')
    setShowExclude(false)
    setOrg([])
    setRemoveDefaultOrg(false)
    dispatch(reSetFacilitiesData({}))
    dispatch(clearError());
    setButtonState(true)
    setPreviewImg(null);
    setFilterModal(false);
    setAddFile(false);
    reset();
    dispatch(setDocumentList({}))
    setIsValidSaleCust(false);
    setIsValidPdf(false);
    setCustomerNumberInp(false)
    setCustomerNumber("")
    setUrlText('');
    setCreateGroup(false);
    setModalOpen(false);
    dispatch(setExcludeNumberSearch({}))
    setCustomerCodeSuggestions([])
    setCustomerCodeTag([])
  }
  function getDocs() {
   // dispatch(getDocumentList());
  }
  
  // Initial GET call to get existing media
  useEffect(() => {
    getDocs();
    dispatch(setDocumentList({}))
    setButtonState(true)
    setPreviewImg(null);
    setFilterModal(false);
    setAddFile(false);   
    dispatch(setDocumentList({}))
    setIsValidSaleCust(false);
    setIsValidPdf(false);
    setCustomerNumberInp(false)
    setCustomerNumber("")
    setUrlText('');
    setCreateGroup(false);
    reset();
    dispatch(setExcludeNumberSearch({}))
    setCustomerCodeSuggestions([])
    setCustomerCodeTag([])
    setShowExclude(false)
  }, [dispatch])
  
  
  const [addFile, setAddFile] = useState(false)
  
  const handleAddFile = () => {
  setAddFile(true)
  setIsValidPdf(false);
  }
  
  const handleAddFileCancel = () => {
    setAddFile(false)
  }
  /* Trigger ad doc overlay close*/
  const dropOverlay = useRef();
  
  // Click event listener and calling handleClickOutside function if we click outside
  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  const handleClickOutside = (e) => {
      if (dropOverlay !== null && dropOverlay.current && !dropOverlay.current.contains(e.target)) {
          setAddFile(false);
      }
  };
  const handleDelete = (id) => {
    const filtered = documentListItems['documents'].filter((data, i) => i !== id)
    let list;
    if (filtered && filtered.length === 0) {
        list = {
            documents: []
        }
    } else if (filtered && filtered.length > 0) {
        list = {
            documents: [...filtered]
        }
    }
   dispatch(setDocumentList(list))
   
}
// const buttonHide = (e) =>{
//   let hideButton = document.getElementsByName(SUBMIT_ID);
//   hideButton.style.display = 'none';
// }

const errorMessage = ServiceError();

useEffect(() => {

  if (errorMessage==="This sequence number is assigned to another banner. Please use a different sequence number") {
    if(filterModal){
      setError("filterSequence", {
        type: "manual",
        message: errorMessage
      });
    }
    dispatch(clearError());
  }
  if (errorMessage==="Layouts name already exist") {
  if(filterModal){
      setError("filterName", {
        type: "manual",
        message: errorMessage
      });
  }else{
    setError("name", {
      type: "manual",
      message: errorMessage
    });
  }
    dispatch(clearError());
  } 
}, [errorMessage,dispatch]);
useEffect(() => {
  if(documentListItems?.documents && documentListItems?.documents?.length > 0){
  setUrlText('')
  setValue("url",'');
  if(document?.getElementById('url')){
    document.getElementById('url').value=''
  }
  if(document?.querySelector('input[name="listUrlType"]')){
    document.querySelector('input[name="listUrlType"]').checked=false
  }
  setAddFile(false);  
  }
  if(documentListItems?.documents && documentListItems?.documents?.length === 0){
    if(document?.getElementById('contentUrl0')){
      document.getElementById('contentUrl0').checked=true
    }
  }
  }, [documentListItems])

  /* exclude options */
const excludeNumberSearch = useSelector(state => state.layouts.excludeNumberSearch);

const customerCodeTagsRef = useRef();   
const [customerCodeTag, setCustomerCodeTag] = useState([]);

const [customerCodeSuggestions, setCustomerCodeSuggestions] = useState([]);

const [suggestionsBusy, setSuggestionsBusy] = useState(false);

const [showExclude, setShowExclude] = useState(false);

const onDelete = useCallback(
(tagIndex) => {
 
const finalTag=customerCodeTag.filter((_, i) => i !== tagIndex);
setCustomerCodeTag(finalTag); 

},
[customerCodeTag]
);

const onAddition = useCallback(
(newTag) => {
 const ids = customerCodeTag.map(({ id }) => id);
 if (!ids.includes(newTag.id)) {
   const finalTag=[...customerCodeTag, newTag];
   setCustomerCodeTag(finalTag);
   }
},
[customerCodeTag]
);

//onInput
const onInputExclude  = useCallback(
  (query) => {
    if(!suggestionsBusy&&query&&customerGroup){
      setSuggestionsBusy(true)
      dispatch(getCustomerNumberSuggestionsLayout(query,setSuggestionsBusy,customerGroup))
    }
  },
  [customerCodeTag]
  );

  useEffect(() => {
    if(Array.isArray(excludeNumberSearch)){
      const getExcludeNumber=[]
      excludeNumberSearch.map((excludeNumber,ind) =>{//NOSONAR
        return getExcludeNumber.push({ id: excludeNumber, name: excludeNumber })
       })//NOSONAR
       setCustomerCodeSuggestions(getExcludeNumber)
    }else{
      setCustomerCodeSuggestions([])
    }

    
    }, [excludeNumberSearch])

  return (
    <>
    {
      menuList == true ? <div className={style.list_menu_postion} ref={dropRef}>
        <div onClick={() => filterModalOpen()}>{CREATE_FILTER}</div>
        <div onClick={() => listViewMenuModal()}>{CREATE_LIST_VIEW_MENU}</div>
      </div> : null
    }
    {
      filterModal && <Modal
        modalOpen={'modalOpen'}
        modalClose={modalBoxClose}
       // modalSize={style.modalSize}
        heading={<div className={style.modal_heading}>{"Create Filter"}</div>}
        modalSize={style.custom_modal_size}
        // noScroll={true}
      >
        <div className="pl-4 pr-4">
          <Form onSubmit={handleSubmit(createFilterSubmit)} >
            {/* {Object.keys(errors).length !== 0 && <div className="col-12 pl-0"><span className={style.general_error}>{GENERAL_ERROR}</span></div>} */}
            <div className="row mt-3">
              <div className="col-6">
                <TextField
                  classes={{
                    column: `row mb-4`,
                    labelClass: `col-4 ${style.label_spec}`,
                    inputColumn: 'col-7',
                    inputClassName: `${style.input_field}`
                  }}
                  label={'Name:'}
                  type={"text"}
                  name={'filterName'}
                  id={'filterNameId'}
                  defaultValue={''}
                  validator={register(checkName)}
                  required={true}
                  errors={errors}
                />
              </div>
              <div className={`col-6 p-0`}>
              <>
              <div className={`col-12 py-2`}>
                <SelectBox
                  id={'salesOffice'}
                  name={'filterSalesOffice'}
                  label={'Sales Office:'} //creter Filter ModelOpen
                  classes={{
                    column: `row ${style.col_spec}`,
                    inputClassName: `${style.basic_select} ${style.first_child}`,
                    labelClassName: `col-5 col-form-label mt-0 ${style.label_spec}`,
                    inputColumn: `col-6 p-0 ${style.select_wrapper}`
                  }}
                  placeholder="Select"
                  optionValue={SALES_OFFICE_OPTIONS}
                  errors={errors}
                  onChange={(e) => getCustomerGroup(e)}
                  disabled={customerNumberInp ? true : false}
                  required={true}
                />
                </div>
                {showExclude&&
                <div className={` col-12 py-2`}>
                <div className={`row`}>
                <div className="col-5">
                  <label className={`mb-0 ${style.label_cutom}`} >Exclude :</label>
                </div>
                <div className={`col-6 p-0`}>
                    <ReactTags
                      ref={customerCodeTagsRef}
                      tags={customerCodeTag}
                      suggestions={customerCodeSuggestions}
                      onDelete={onDelete}
                      onAddition={onAddition}
                      onInput={onInputExclude}
                      classNames={{ root: `${style.root_tag}` }}
                      placeholderText={"Customer Number"}
                      allowBackspace={false}
                      readOnly={false}
                      maxSuggestionsLength ={10}
                      minQueryLength ={1}
                      />
                      {excludeNumberSearch===null&&Array.isArray(customerCodeSuggestions)&&<>No data found</>}
                  </div>
                  </div>
                </div>
               }
                {/* customer Number search */}
                <div className={`${style.form_OR} pt-3`} ><strong>OR</strong></div>
                <div className={`col-12 pt-1 pb-3 ml-n2 ${style.input_icons}`}>    
                    <TextField
                            id={"customerNumber"}
                            name={"customerNumber"}
                            label={"Customer Number:"}
                            classes={{
                              column: `d-flex ${style.col_spec}`,
                              inputClassName: `${style.input_field_cust_num} ${style.first_child} ${createGroup ? style.disable_field : ''}`,
                              labelClass: `col-5 col-form-label mt-0 ${style.label_cutom} p-2`,
                              inputColumn: `col-7 p-0`
                              
                            }}
                            type="text"
                            onChange={(e)=>getCustomerNumberSearch(e.target.value)}                        
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                  //getCustomerNumber(e.target.value);
                                }
                            }}
                           // onKeyUp={(e) => enterCustomerNumber(e)}
                            customError={createGroup ? '' : errorMsg}
                            disabled={createGroup ? true : false}
                            required={true}
                        />
                         {customerNumberSearchDatas &&
                        Array.isArray(customerNumberSearchDatas) &&
                        customerNumberSearchDatas.length > 0 && (
                            <div className="col-12 col-spec row">
                             <div className={`col_spec mr-auto ${style.search_drop}`}>
                                <div className={`col-spec ${style.search_close_box}`}>
                                {customerNumberSearchDatas.map(
                                    (items, index) =>
                                    items && (
                                        <p
                                        className={`dropdown-item`}
                                        title={items.value}
                                        onClick={(e) => customerNumberSelect(e, items)}
                                        >
                                        {items}
                                        </p>
                                    )
                                )}
                                </div>
                            </div>
                            </div>
                        )}                  
                    <span 
                        id="clear_icon"
                        className={`${style.x_icon}`}
                        onClick={(e)=>ClearData(
                            document.getElementById('customerNumber').value
                          )}                          
                        >
                        <i className="fa fa-times" style={{color : "#000"}} aria-hidden="true"></i>
                    </span>  
                    </div>
                    <div className={`col-12 py-4`}>
                      {/* Organizational dropdown     */}
                      { <Organizational 
                                      id={"organization"}
                                      name={"organization"}
                                      label={`Organization:`}
                                      classes={{
                                        column: `row ${style.col_spec} `,
                                        inputClassName: `  ${style.input_field}  ${style.first_child} `,
                                        labelClassName: `col-5 col-form-label mt-0 ${style.label_cutom}`,
                                        inputColumn: `col-6 p-0 ${createGroup ? style.disabled : ''}`
                                      }}
                                      optionValue={optionData}
                                      groupBy="cat"
                                      displayValue="key"
                                      onSelect={onChangeOrg}
                                      onRemove={onChangeOrg}
                                      // disabled={false}
                                      disabled={createGroup ? true : false}
                                      assignPage={true}
                                      required={true}
                                     defaultValue={Array.isArray(org)?org:[]}
                                     selectAll={true}  
                                  />}
                                  { isValidSaleCust&&<span className={`${style.error_message} text-center`}>{
              'Please select customer group selection or customer number and organization' }
                </span> }
              </div>
              </>

              </div>
              
              <div className={`col-8`}>
                <div className="row mb-4">
                  <div className="col-3  pr-0 ">
                    <label className={`${style.label_spec} pt-0 mb-0`}>List View Menu:</label>
                    <div className={style.bir_subline}>Select all that apply</div>
                  </div>
                  <div className="col-9">
                    <MultiSelect
                      id={"listViewMenu"}
                      name={"listViewMenu"}
                      classes={{
                        column: `col-12 p-0`,
                        inputClassName: `${style.basic_select}`
                      }}
                      optionValue={listViewMenus}
                      showCheckbox={true}
                      displayValue="value"
                      required={true}
                      validator={typeOfDeviceRef}
                      onSelect={handleTypeOfDeviceSelect}
                      labelSubText={'Select all that apply'}
                    />
                  </div>
                </div>
              </div>
              <div className={`col-6 ${style.small_space_one}`}>
                <TextField
                  classes={{
                    column: `row`,
                    labelClass: `col-4 ${style.label_spec}`,
                    inputColumn: 'col-7',
                    inputClassName: `${style.small_input} ${style.input_field}`
                  }}
                  id={LIST_VIEW_MENU_SEQUENCE_ID}
                  label={SEQUENCE}
                  type={"number"}
                  name={'filterSequence'}
                  validator={register(sequenceNumber)}
                  required={true}
                  errors={errors}
                  defaultValue={autoSeqNumber?.data?autoSeqNumber?.data:''}
                />
              </div>
            </div>
            <div className="text-center mb-5" >
              <Button
                id={"creat_list_view_menu"}
                type={SUBMIT_ID}
                value={SAVE_BTN}
                classes={{ className: `btn mb-1 ${style.btn_save}` }}
                onClick={() => validateSalesCust()} //added new
              />
              <Button
                id={'cancel-list-view'}
                type={"button"}
                value={CANCEL_BTN}
                classes={{ className: `btn mb-1 ${style.btn_cancel}` }}
                onClick={() => modalBoxClose()}
              />
            </div>
          </Form>
        </div>
      </Modal>
    }
    
    {
      newLayout === SUCCESS_STATUS && <Modal
        modalOpen={msgModalOpen}
        modalClose={modalClose}
      >
        <div className="text-center p-4">
          {newLayout === SUCCESS_STATUS && <ActionModal
            iconImg={iconSuccess}
            heading={LAYOUT_CREATED_MSG}
            buttonText="Close"
            buttonAction={() => modalClose()}
          />
          }
          {newLayout === ERROR_STATUS && <ActionModal
            iconImg={iconDanger}
            heading="Layout not yet created!"
            buttonText="Close"
            buttonAction={modalClose}
          />
          }
        </div>
      </Modal>
    }
    {modalOpen && <Modal
      modalOpen={'modalOpen'}
      modalClose={modalBoxClose}
      // modalSize={style.modalSize}
      heading={<div className={style.modal_heading}>{"Create List View Menu"}</div>}
      modalSize={style.custom_modal_size}
    >
      <div className="pl-4 pr-4">
        <Form onSubmit={handleSubmit(listViewMenuSubmit)}>
          {Object.keys(errors).length !== 0 && <div className="col-12 pl-0"><span className={style.general_error}>{GENERAL_ERROR}</span></div>}
          <div className="row mt-3">
            <div className="col-6">
              <TextField
                classes={{
                  column: `row mb-4`,
                  labelClass: `col-4 ${style.label_spec}`,
                  inputColumn: 'col-7',
                  inputClassName: `${style.input_field}`
                }}
                label={'Name:'}
                type={"text"}
                name={'name'}
                id={'listNameId'}
                defaultValue={''}
                validator={register(checkName)}
                required={true}
                errors={errors}
                // customError={newLayout?.errorMessage === "Layouts name already exist" ? newLayout?.errorMessage : ""}
              />
            </div>
            <div className="col-6">
            {/* code added */}
           <SelectBox
                id={'salesOffice'}
                name={'salesOffice'}
                label={'Sales Office:'} //Create List View Menu ModelOpen
                classes={{
                  column: `row ${style.col_spec}`,
                  inputClassName: `${style.basic_select} ${style.first_child}`,
                  labelClassName: `col-5 col-form-label mt-0 ${style.label_spec}`,
                  inputColumn: `col-6 p-0 ${style.select_wrapper}`
                }}
                // defaultValue={layoutData.salesOffice && layoutData.salesOffice}
                placeholder="Select"
                optionValue={SALES_OFFICE_OPTIONS}
                errors={errors}
                onChange={(e) => getCustomerGroup(e)}
                disabled={customerNumberInp ? true : false}
                required={true}
                
              />
            </div>
            {showExclude&&
                <> <div className={` col-6 py-2`}></div>
                <div className={` col-6 py-2`}>
                <div className={`row`}>
                <div className="col-5">
                  <label className={`mb-0 ${style.label_cutom}`} >Exclude :</label>
                </div>
                <div className={`col-6 p-0`}>
                    <ReactTags
                      ref={customerCodeTagsRef}
                      tags={customerCodeTag}
                      suggestions={customerCodeSuggestions}
                      onDelete={onDelete}
                      onAddition={onAddition}
                      onInput={onInputExclude}
                      classNames={{ root: `${style.root_tag}` }}
                      placeholderText={"Customer Number"}
                      allowBackspace={false}
                      readOnly={false}
                      maxSuggestionsLength ={10}
                      minQueryLength ={1}
                      />
                      {excludeNumberSearch===null&&Array.isArray(customerCodeSuggestions)&&<><p>No data found</p></>}
                  </div>
                  </div>
                </div></>
               }
            <div className="col-6 mt-0">
              <div className="row">
                <div className="col-4 mb-4 mt-4 ">
                  <label className={style.label_spec}>Icon:</label>
                </div>
                <div className="col-7 mb-4 pb-2  mt-4">
                  <PictureIcon
                    classes={{ pic_wrapper: '', contr_wrapper: `mt-0 ${style.icon_align}` }}
                    previewImg={previewImg}
                    setPreviewImg={setPreviewImg}
                    buttonState={buttonState}
                    setButtonState={setButtonState}
                    id={LIST_VIEW_ICON}
                    name={"picture"}
                    validator={register}
                    browseLbl={UPLOAD_ICON}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
            {/* {`${style.form_OR}`} offset-4 mt-2*/} 
            <div className={`${style.form_OR}`} ><strong>OR</strong></div>
            <div className={`row col-12 pt-1 ml-n4 ${style.input_icons}`}>    
                            <TextField
                                    id={"customerNumber"}
                                    name={"customerNumber"}
                                    label={"Customer Number:"}
                                    classes={{
                                      column: `d-flex ${style.col_spec}`,
                                      inputClassName: `${style.input_field_cust_num} ${style.first_child} ${createGroup ? style.disable_field : ''}`,
                                      labelClass: `col-6 col-form-label mt-0 ${style.label_cutom} p-2`,
                                      inputColumn: `col-6 p-0`
                                      
                                    }}
                                    type="text"
                                    onChange={(e)=>getCustomerNumberSearch(e.target.value)}                        
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                          //getCustomerNumber(e.target.value);
                                        }
                                    }}
                                   // onKeyUp={(e) => enterCustomerNumber(e)}
                                    customError={createGroup ? '' : errorMsg}
                                    disabled={createGroup ? true : false}
                                    required={true}
                                />
                                 {customerNumberSearchDatas &&
                        Array.isArray(customerNumberSearchDatas) &&
                        customerNumberSearchDatas.length > 0 && (
                            <div className="col-12 col-spec row">
                             <div className={`col_spec mr-auto ${style.search_drop}`}>
                                <div className={`col-spec ${style.search_close_box}`}>
                                {customerNumberSearchDatas.map(
                                    (items, index) =>
                                    items && (
                                        <p
                                        className={`dropdown-item`}
                                        title={items.value}
                                        onClick={(e) => customerNumberSelect(e, items)}
                                        >
                                        {items}
                                        </p>
                                    )
                                )}
                                </div>
                            </div>
                            </div>
                        )}                  
                    <span 
                        id="clear_icon"
                        className={`${style.x_icon}`}
                        onClick={(e)=>ClearData(
                            document.getElementById('customerNumber').value
                          )}                          
                        >
                        <i className="fa fa-times" style={{color : "#000"}} aria-hidden="true"></i>
                    </span>  
              </div>
                                 
            </div> 
            
            <div className={`col-6 `}>
              <div className="row">
              
                <div className="col-6 mt-5 ">
                
                  <label className={style.label_spec}>PDF File:<span className={style.errStar}>&#42;</span> </label>
                </div>
                <div className="col-6 mt-5">
                  
                    
                 { documentListItems?.documents?.length > 0 ? '' :<Button
                            classes={{ className: `btn ${style.btn_custom} ${style.btn_small} ${style.btn_position} ${textUrl ? style.disabled : ''}` }}
                            type="button"
                            id={SUBMIT_ID}
                            onClick={handleAddFile}
                            name="submitButton"
                        >
                            <img src={PlusIcon} alt="edit" /> Add File
                        </Button> }
                        {addFile && 
                        <div className={`dropdown-menu show ${style.media_align}`} aria-labelledby="dropdownMenu3">
                        <LayoutDocument
                            handleAddFileCancel={handleAddFileCancel}
                            productType={productType}
                            documentListItems={documentListItems}
                            getDocs={getDocs}
                            productId={productId}
                         />
                   </div> 
                    } 
                    <div className={style.media_view}>
                    
                <ul>
                    {/* {documentListItems?.documents && documentListItems?.documents?.length === 0 && <h6 className="text-left">There are no documents added to this list</h6>} */}
                    {documentListItems?.documents && documentListItems?.documents?.length > 0 && documentListItems.documents.map((data, i) => {
                        return <li key={i} className={`${style.list_custom}`} >
                            <img src={DocumentIcon} alt="image_icon"></img>
                            <a href={data.documentUrl} target="_blank" rel="noopener noreferrer" title={`${data?.fileName?data.fileName:''}`}>{data.fileName}</a>
                            <span className={`${style.close_icon}`} onClick={() => handleDelete(i)}><img src={CloseIcon} alt="close" /></span>
                        </li>
                    }) }
                </ul>
            </div>
            <br />
    <div className={`${style.Or_Align}`} ><strong>OR</strong></div>
    </div>
    </div>
               
    
            </div>
            <div className="col-6 mt-0">
                     { <Organizational 
                                    id={"organization"}
                                    name={"organization"}
                                    label={`Organization:`}
                                    classes={{
                                      column: `row ${style.col_spec} `,
                                      inputClassName: `  ${style.input_field}  ${style.first_child} `,
                                      labelClassName: `col-5 col-form-label mt-0 ${style.label_cutom}`,
                                      inputColumn: `col-6 p-0 ${createGroup ? style.disabled : ''}`
                                     }}
                                    optionValue={optionData}
                                    groupBy="cat"
                                    displayValue="key"
                                    // defaultValue={removeDefaultOrg ? defaultFacilities() : ''}
                                    onSelect={onChangeOrg}
                                    onRemove={onChangeOrg}
                                    // disabled={false}
                                    disabled={createGroup ? true : false}
                                    assignPage={true}
                                    required={true}
                                    defaultValue={Array.isArray(org)?org:[]}
                                    selectAll={true}  
                                />}
                                { isValidSaleCust&&<span className={`${style.error_message} text-center`}>{
             'Please select customer group selection or customer number and organization' }
               </span> }
            </div>
            <div className="col-8">
            
              <div className="row">

                <div className="col-3 mb-4 ">
                  <label className={style.label_spec}>Content URL: <span className={style.errStar}>&#42;</span></label>
                </div>
                <div className="col-9 pr-0">
                  <div className="row">
                    <div className="col-12">
                      <RadioButton
                        id={CONTENTURL}
                        name={"listUrlType"}
                        classes={{
                          labelClass: `custom-control-label mb-2 ${style.custom_control_label}`,
                          inputClassName: `custom-control-input ${style.custom_control_input}`,
                          inputColumn: "custom-control custom-radio custom-control-inline"
                        }}
                        radioOption={[EXTRENAL_URL, WEB_VIEW_URL]}
                         defaultValue={EXTRENAL_URL}
                         disabled={documentListItems?.documents?.length > 0?true:false}
                        validator={register}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <TextField
                        classes={{
                          column: `row mb-4`,
                          labelClass: `col-4 ${style.label_spec}`,
                          inputColumn: 'col-12',
                          inputClassName: `w-100 ${style.input_field}  ${documentListItems?.documents?.length > 0 ? style.disable_field : ''}`
                        }}
                        id={'url'}
                        type={"text"}
                        name={'url'}
                        validator={register}
                        errors={errors}
                        onKeyUp={(e) => enterTextUrl(e)}
                        disabled={documentListItems?.documents?.length > 0 ?true: false}
                       
                      />
                    </div>
                    { isValidPdf && <span className={`${style.error_message} ${style.error_PDF} text-right`}>{
             'Please select PDF file or content url' }
               </span> }
                  </div>  
                </div>
              </div>
              
            </div>
            <div className={`col-6 ${style.small_space}`}>
              <TextField
                classes={{
                  column: `row`,
                  labelClass: `col-4 ${style.label_spec}`,
                  inputColumn: 'col-7',
                  inputClassName: `${style.small_input} ${style.input_field}`
                }}
                id={LIST_VIEW_MENU_SEQUENCE_ID}
                label={SEQUENCE}
                type={"number"}
                name={"listSeque"}
                validator={register(sequenceNumber)}
                required={true}
                errors={errors}
                customError={newLayout && newLayout?.errorMessage !== "Layouts name already exist" && newLayout.errorMessage ? newLayout.errorMessage : ""}
                defaultValue={autoSeqNumber?.data?autoSeqNumber?.data:''}
                //onChange={(e) => getSeqNum(e,'listviewmenu')}
              />
            </div>
          </div>
          <div className="text-center mb-5 pb-5" >
            <Button
              id={"creat_list_view_menu"}
              type={SUBMIT_ID}
              value={SAVE_BTN}
              classes={{ className: `btn mb-1 ${style.btn_save}` }}
              onClick={() => validateSalesCust()}
            />
            <Button
              id={'cancel-list-view'}
              type={"button"}
              value={CANCEL_BTN}
              classes={{ className: `btn mb-1 ${style.btn_cancel}` }}
              onClick={() => modalBoxClose()}
            />
          </div>
        </Form>
      </div>
    </Modal>}
    </>
    )
    }
    CreateListViewMenu.propTypes = {
    menuList: PropTypes.func
    }
    