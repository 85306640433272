import { format, formatDistanceToNowStrict, subDays, differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { clearError } from '../app/appSlice'
import { PHONE_MASK_REGEX } from '../constants'

export class UrlParams {
  constructor(search) {
    this.qs = (search || window.location.search).substr(1);
    this.params = {};
    this.parseQueryString();
  }
  parseQueryString() {
    this.qs.split('&').reduce((a, b) => {
      const [key, val] = b.split('=');
      a[key] = val;
      return a;
    }, this.params);
  }
  get(key) {
    return this.params[key];
  }
}


/* Display backend error message */

export const ServiceError = () => {
  const dispatch = useDispatch();

  /* Before setting error clear errors if there is any */
  useEffect(() => {
      dispatch(clearError())
  }, [dispatch])

  /* Get error state */
  const errorMessage = useSelector(state => state.app.errorMessage)

  /* If there is an error, hide it after some seconds */
  /*if (errorMessage) {
    setTimeout(() => {
      dispatch(clearError())
    }, 6000)
  }*/

  /* Return the error message */
   return errorMessage;
  
}

/* Phone number masking */
/* Take normal phone number and return formated */
export const phoneMasker = (phone) => {
  /* Adding brackets using regex */
  phone = phone.replace(/[\D]+/g, ''); //Remove alphabetic characters
  const maskedPhone = phone.replace(PHONE_MASK_REGEX, '($1) $2-$3')
  return maskedPhone;
}

/* Array into name value pair */
/**
 * Arg - array - Eg: ['a','b',c]
 * Return - array of objects = Eg: [{name : a, value : a}, {name: b, value : b}] 
 * */
export const selectBoxConverter = (data) => {
  const result = data.map((item) => ({ key: item, value: item }));
  return result;
}

/* Get Time format 00:00 AM */
export const getTimeFormat = (date) => {
  if (date) {
    return new Date(date.toLocaleString()).toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" });
  } else {
    return new Date().toLocaleTimeString([], { hour: '2-digit', minute: "2-digit" });
  }
}

/* Get key by value */

export function getKeyByValue(object, value) {
  const val = Object.keys(object).find(key => object[key] === value);

  return isNaN(parseInt(val)) ? val : parseInt(val)
}

export function constructEndpoint(uri, ...params) {
  let endpoint = uri;
  if (params && params.length > 0) {
    const routeParams = params.filter((a) => a && typeof a !== 'object');
    const queryParams = params.find((a) => a && typeof a === 'object');
    if (routeParams && routeParams.length > 0) {
      endpoint = getPlaceholders(endpoint, routeParams);
    }
    if (queryParams) {
      endpoint = `${endpoint}?${constructQueryParams(queryParams)}`;
    }
  }
  return endpoint;
}

export function getPlaceholders(key, obj) {
  //TODO: write logic to replace placeholders with values
  obj.forEach((o, i) => (key = key.replace(`{${i}}`, obj[i] || '')));
  return key;
}

export function constructQueryParams(queryParams) {
  return Object.entries(queryParams)
    .filter((obj) => obj[1] !== undefined)
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&');
}

// calculate days
export function getDays(date) {
  const oneDay = 24 * 60 * 60 * 1000;
  const currentDate = new Date();
  const parsedDate = new Date(date.toLocaleString());
  return Math.round(Math.abs((currentDate - parsedDate) / oneDay))
}



//Default value for radio button
export const radioDefault = (data) => {
  if (data === 1) {
    return 'Yes'
  }
  if (data === 0) {
    return 'No'
  }
}

export function getLocalDate(date) {
  return (new Date(date + " UTC-00:02"));
}

export function getNotificationDate(date) {
  /*let conveChkUTC=new Date(date);
  conveChkUTC=conveChkUTC.toUTCString('UTC-00:00');
  let checkDate=new Date(conveChkUTC).getTime();*/
  const checkDays = date ? (formatDistanceToNowStrict(subDays(new Date(date.toLocaleString()), 0), new Date(), { addSuffix: true }).replace(/[0-9]/g, '')).trim() : new Date();
  if (checkDays === 'second' || checkDays === 'seconds' || checkDays === 'minute' || checkDays === 'minutes' || checkDays === 'hour' || checkDays === 'hours') {
    return formatDistanceToNowStrict(subDays(new Date(date.toLocaleString()), 0), new Date(), { addSuffix: true }) + ' ago'
  }
  else {
    return format(new Date(date.toLocaleString()), 'MM/dd/yyyy | h:mm aa');
  }

}

export function getLabel(key, options) {
  return options && options.length > 0 && options.map((item) => {
    let value;
    if (item['key'] === key) {
      value = item['value'];
    }
    return value;
  })
}

export function getSubLabel(key, options) {
  return options && options.length > 0 && options.map((data) => {
    let value;
    if (data?.options?.length > 0) {
      data.options.map((sub) => {
        if (sub.key === key) {
          value = sub.value
        }
      })
    }
    else {
      if (data.key === key) {
        value = data.value
      }
    }
    return value;
  })
}

export function getAssessmentTitle(id, causeCategory, cause, anatomy, causeList, anatomyList) {
  return <>
    #{id}: &#173;
    {getLabel(causeCategory, causeList)}
    &nbsp;-&nbsp;
    {getSubLabel(cause, causeList)}
    &nbsp;-&nbsp;
    {getLabel(anatomy, anatomyList)}
  </>
}

export const deepCopyArray = (arr) => {
  const copy = [];
  arr.forEach(elem => {
    if (Array.isArray(elem)) {
      copy.push(deepCopyArray(elem))
    } else {
      if (typeof elem === 'object') {
        copy.push(deepCopyObject(elem))
      } else {
        copy.push(elem)
      }
    }
  })
  return copy;
}

// Helper function to deal with Objects
export const deepCopyObject = (obj) => {
  const tempObj = {};
  for (let [key, value] of Object.entries(obj)) {
    value = (value === null) ? '' : value;

    if (Array.isArray(value)) {
      tempObj[key] = deepCopyArray(value);
    } else {
      if (typeof value === 'object') {
        tempObj[key] = deepCopyObject(value);
      } else {
        tempObj[key] = value
      }
    }

  }
  return tempObj;
}

export function fullMonthFormatDate(date) {
  let finalDate = date;
  try {
    finalDate = date ? format(new Date(date.toLocaleString()), 'MMMM dd, yyyy h:mm aa') : ''
  } catch { }
  return finalDate;
}

export function dateForPatientCard(date) {
  let finalDate = date;
  try {
    finalDate = date ? format(new Date(date.toLocaleString()), 'MM/dd/yyyy | h:mm aa') : ''
  } catch { }
  return finalDate;
}

export function dateOfBirthDate(date) {
  let finalDate = date;
  try {
    finalDate = date ? format(new Date(date.toLocaleString()), 'MMMM dd, yyyy') : ''
  } catch { }
  return finalDate;
}

export function dateBasic(date) {
  let finalDate = date;
  try {
    finalDate = date ? format(new Date(date.toLocaleString()), 'MM/dd/yyyy') : ''
  } catch { }
  return finalDate;
}

export function daysAgo(date) {
  let finalDate = date;
  try {
    finalDate = date ? differenceInDays(new Date(), new Date(date.toLocaleString())) : ''
  } catch { }
  if (finalDate === 0) {
    return 'Today'
  } else if (finalDate === 1) {
    return finalDate + ' day ago'
  } else {
    return finalDate + ' days ago'
  }
}

/*To calculate the hours on behalf of API date and time and local date & time */
export function hrsAgo(date) {
  let finalHrs = date;
  try {
    finalHrs = date ? differenceInHours(new Date(), new Date(date.toLocaleString())) : ''
  } catch { }
  if (finalHrs < 1) {
    return differenceInMinutes(new Date(), new Date(date.toLocaleString())) + ' min ago';
  }
  else if (finalHrs <= 24) {
    return finalHrs + ' hrs ago'
  } else if (finalHrs > 24) {
    return fullMonthFormatDate(date);
  }
}

export function getFacilityLabel(facility) {
  return `${facility.facilityNumber ? facility.facilityNumber : ''}${facility.facilityName ? ` | ${facility.facilityName}` : ''}${facility.address.addressLine1 ? ` | ${facility.address.addressLine1}` : ''}${facility.address.addressLine2 ? ` | ${facility.address.addressLine2}` : ''}${facility.address.city ? ` | ${facility.address.city}` : ''}${facility.address.state ? ` | ${facility.address.state}` : ''}${facility.address.zipCode ? ` | ${facility.address.zipCode}` : ''}`;
}

/*getFacilityLabel with URL*/
export function getFacilityLabelUrl(facility) {
  return (
    <div className={`col-12 px-0`}>
      {facility?.facilityNumber ? <a href={`/admin/customers/${facility?.id}`} target="_blank">{facility?.facilityNumber}</a> : ''} {facility?.facilityName ? ` | ${facility?.facilityName}` : ''} {facility?.address?.addressLine1 ? ` | ${facility?.address?.addressLine1}` : ''} {facility?.address?.addressLine2 ? ` | ${facility?.address?.addressLine2}` : ''} {facility?.address?.city ? ` | ${facility?.address?.city}` : ''} {facility?.address?.state ? ` | ${facility?.address?.state}` : ''} {facility?.address?.zipCode ? ` | ${facility?.address?.zipCode}` : ''}
    </div>
  )  
}

export function getRegFacilityLabel(facility) {
  return `${facility?.facilityName ? ` ${facility.facilityName}` : ''} ${facility.address?.city ? ` | ${facility.address?.city}` : ''}${facility.address?.state ? ` , ${facility.address?.state}` : ''}`;
}

export function allNullCheck(data) {
  if (data === null || data === '' || data === undefined || data === 'Unknown') {
    return false
  } else {
    return true
  }
}

/*Get Image Resolution Byvignesh*/
export function imageSizeFromURL(url) {
  const img = document.createElement("img");
  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      // Resolve promise with the width and height
      resolve({ width, height });
    };
    // Reject promise on error
    img.onerror = reject;
  });
  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url;
  return promise;
}

export function imageSizeFromFile(fileValue) {
  const reader = new FileReader();
  reader.readAsDataURL(fileValue[0]);
  const promise = new Promise((resolve, reject) => {
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();
      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;
      image.onload = function () {
        const height = this.height;
        const width = this.width;
        resolve({ width, height });
      }
      image.onerror = reject;
    };

  });
  return promise;
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
}


export function prefixO(val) {
  return isNaN(parseFloat(val)) ? val : parseFloat(val);
}

// Get file name from URL
export function getFileName(path) {
  path = path?.substring(path?.lastIndexOf("/") + 1);
  return (path?.match(/[^.]+(\.[^?#]+)?/) || [])[0];
}

export function toggleInfo(id) {
  var x = document.getElementById(id);
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

export const onlyFirstName = (data) => {
  if (data !== undefined) {
    const myArr = data?.split(" ");
    return myArr[0]
  }
  return;
}



// Functions which sort the data (asec and desc) function
export function asec(a, b) {
  if (a.materialName < b.materialName) {
    return -1;
  }
  if (a.materialName > b.materialName) {
    return 1;
  }
  return 0;
}

export function desc(a, b) {
  if (a.materialName < b.materialName) {
    return 1;
  }
  if (a.materialName > b.materialName) {
    return -1;
  }
  return 0;
}

export const checkNumbers = {
  required: "Pleae enter valid number",
  pattern: {
    value: /^[0-9]\d+$/,
    message: "Please enter a valid number"
  }

}

export const checkNumbericValue = {
  // required: "Pleae enter valid number",
  // pattern: {
  //     value: /^[0-9]\d+$/,
  //     message: "Please enter a valid number"
  // }
  pattern: {
    value: /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/,
    message: "Please enter a valid number"
  }
}
export const checkPercentageNumbericValue = {
  pattern: {
   // value: /^100(\.(0){0,2})?$|^([1-9]?[0-9])(\.(\d{0,2}))?\%$/,
   value:/(^$)|(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)\.(\.[0-9]{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/,
    message: "Please enter a number between 0 and 100"
  }
}
// Thu Jun 09 2011 00:00:00 GMT+0530 (India Standard Time)"))
//-> "2011/06/08"
export const convertnewDateToDate = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("/");
}

export const signedLockedAccess = (data) => {
  if (data === 'signedlocked') {
    return false
  } else {
    return true
  }
}

//first letter capital
export const firstLetterCaps = str => str && str[0].toUpperCase() + str.slice(1)

export function occurrences(string, subString, allowOverlapping) {

  string += "";
  subString += "";
  if (subString.length <= 0) return (string.length + 1);

  var n = 0,
      pos = 0,
      step = allowOverlapping ? 1 : subString.length;

  while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
          ++n;
          pos += step;
      } else break;
  }
  return n;
}

export const getCustomerName = (data) => {
  
}

//Remove Duplicate ArrayList
export const getUniqueListBy=(arr, key)=>{
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

//group by list

export const groupByList=(arr, key)=>{
 const MakeKey= arr.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
  const Object_keysCat=Object.keys(MakeKey);
  return Object_keysCat && Array.isArray(Object_keysCat) && Object_keysCat?.map((key) => {
    return {key:key,value:MakeKey[key]}
  })
}