import { createSlice } from '@reduxjs/toolkit';
import { Service } from '../service';
import { constructEndpoint } from '../utils/commonHelper';
import { deleteAsync, setError, setLoading } from './appSlice';
const { request } = Service;

export const dressingChangeFrequencySlice = createSlice({
  name: 'dressing',
  initialState: {
    dressChangefrequency: {}
  },
  reducers: {
    setdressChangeFrequency: (state, action) => {
      state.dressChangefrequency = action.payload;
    }
  }
})

export const {
  setdressChangeFrequency
} = dressingChangeFrequencySlice.actions;


export const getDressingChangeFrequency = (payload, history) => async dispatch => {
  const url = `/patients/${payload.patientId}/assessments/${payload.assessmentId}/intervention/${payload.intervationId}/dressingchangefrequency`;
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url), {
      method: "GET"
    });
    if (data && data.status === 'success') {
      dispatch(setdressChangeFrequency(data))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setdressChangeFrequency(obj))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const updateDressingChangeFrequency = (payload, history) => async dispatch => {
  const url = `/patients/${payload.patientId}/assessments/${payload.assessmentId}/intervention/${payload.intervationId}/dressingchangefrequency`;
  try {
    dispatch(setLoading(true));
    const data = await request(constructEndpoint(url), {
      method: "PATCH",
      body: JSON.stringify(payload.updateData.data)
    });
    if (data && data.status === 'success') {
      dispatch(getDressingChangeFrequency(payload))
    }
  }
  catch (error) {
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setdressChangeFrequency(obj))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export default dressingChangeFrequencySlice.reducer;
