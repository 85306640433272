import { combineReducers } from 'redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import appReducer from './appSlice';
import userReducer from './userSlice';
import patientReducer from './patientSlice';
import customerReducer from './customerSlice';
import announcementReducer from './announcementSlice';
import layoutsSlice from './layoutsSlice';
import customFormReducer from './customFormSlice';
import workListReducer from './workListSlice';
import supportReducer from './supportSlice';
import formularyReducer from './formularySlice';
import productReducer from './productSlice';
import dressingchangefrequency from './dressingChangeFrequencySlice';
import woundReportReducer from './woundReportSlice';
import usageAnalyticsReportReducer from './usageAnalyticsReportSlice';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'DSHP_ROOT',
  version: 1,
  storage
}

// import storageSession from 'redux-persist/lib/storage/session'

// const persistConfig = {
//   key: 'DSHP_ROOT',
//   version: 1,
//   storage: storageSession
// }

const persistedReducer = persistReducer(persistConfig, combineReducers({
  app: appReducer,
  user: userReducer,
  patient: patientReducer,
  customer:customerReducer,
  announcement:announcementReducer,
  layouts:layoutsSlice,
  forms:customFormReducer,
  worklists:workListReducer,
  support:supportReducer,
  formulary: formularyReducer,
  product: productReducer,
  dressing:dressingchangefrequency,
  woundreport:woundReportReducer,
  usageAnalyticsReport:usageAnalyticsReportReducer,
}))

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      warnAfter: 150
    }
  })
})

export const persistor = persistStore(store)
