import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { LIST_VIEW_LIMIT, NEW_ADMISSION_POA } from "../../../constants";
import { getBiReportsListAsync } from "../../../app/patientSlice";
import { DataTable, Pagination } from "../../secondary";
import { Sorting, Pagination as assignedPagination } from "../../../utils";
import { Header } from "../../secondary/Header";
import style from "./biReportsList.module.scss";
import Spreadsheet from "../../../static/images/ExportSpredsheet.svg";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import Loader from '../../../static/images/spinner.svg';	

export let BiReportsList = ({ dispatch, history, setDownloadDataValue }) => { //NOSONAR
  const biReportsListData = useSelector((state) => state.patient.biReportsList);
  const biReportSummaryCsvData = useSelector(
    (state) => state.patient.biReportSummaryCsv
  );
  const biReportDetailCsvData = useSelector(
    (state) => state.patient.biReportDetailCsv
  );
  const customerName = useSelector((state) => state.user.customerName);

  const [tinyDelay, setTinyDelay] = useState(false)

useEffect(()=>{
  let getloadValue = biReportsListData?.reports !== undefined ? true : false
  if(getloadValue){
    setTimeout(()=>{
      setTinyDelay(true)
    }, 1000)    
  }    
 })

  const columns = [
    {
      Header: "Wound photo",
      accessor: "woundImage",
      imageData: "picture",
      disableSortBy: true,
    },
    {
      Header: "Wound progress​",
      accessor: "woundProgress",
      restWidth: "150px",
    },
    {
      Header: "Wound number",
      accessor: "woundNumber",
      restWidth: "150px",
    },
    {
      Header: "Wound acquired​",
      accessor: "woundAcquired",
      restWidth: "200px",
    },
    {
      Header: "Re-eval ID",
      accessor: "revalId",
      restWidth: "120px",
    },
    {
      Header: "MRN #",
      accessor: "mrn",
      restWidth: "100px",
    },
    {
      Header: "Patient\'s name",
      accessor: "patientName",
      restWidth: "200px",
    },
    {
      Header: "DOB",
      accessor: "dob",
      restWidth: "150px",
    },
    {
      Header: "Facility/Location",
      accessor: "facilityLocation",
      restWidth: "150px",
    },
    {
      Header: "Admission date",
      accessor: "admissionDate",
      restWidth: "150px",
    },

    {
      Header: "Discharge date",
      accessor: "dischargeDate",
      restWidth: "150px",
    },
    {
      Header: "Wound photo taken by",
      accessor: "woundPhotoTakenBy",
      restWidth: "180px",
    },
    {
      Header: "Wound photo taken on ​",
      accessor: "woundPhotoTakenOn",
      restWidth: "190px",
    },
    {
      Header: "Last assessed​ ",
      accessor: "lastAssessed",
      restWidth: "170px",
    },
    {
      Header: "Surface area​",
      accessor: "surfaceArea",
      restWidth: "150px",
    },
    {
      Header: "Length​",
      accessor: "length",
      restWidth: "150px",
    },

    {
      Header: "Width​",
      accessor: "width",
      restWidth: "150px",
    },

    {
      Header: "Depth",
      accessor: "depth",
      restWidth: "150px",
    },
    {
      Header: "Tuneling",
      accessor: "tuneling",
      restWidth: "150px",
    },
    {
      Header: "Undermining",
      accessor: "undermining",
      restWidth: "150px",
    },
    {
      Header: "HPR 1​",
      accessor: "hpr1",
      restWidth: "150px",
    },
    {
      Header: "HPR 2​",
      accessor: "hpr2",
      restWidth: "150px",
    },
    {
      Header: "HPR 3​",
      accessor: "hpr3",
      restWidth: "150px",
    },
    {
      Header: "History/Cause​​",
      accessor: "historyCause",
      restWidth: "150px",
    },
    {
      Header: "Anatomy",
      accessor: "anatomy",
      restWidth: "150px",
    },
    {
      Header: "Condition POA ​​",
      accessor: "conditionPOA",
      restWidth: "150px",
    },
    {
      Header: "Photo type ​​​",
      accessor: "photoType",
      restWidth: "150px",
    },
    {
      Header: "Documentation type​​",
      accessor: "documentationType",
      restWidth: "170px",
    },
    {
      Header: "Wound age​​​",
      accessor: "woundAge",
      restWidth: "150px",
    },
    {
      Header: "Tissue type",
      accessor: "tissueType",
      restWidth: "150px",
    },
    {
      Header: "Are there any open areas ​​",
      accessor: "areThereAnyOpenAreas",
      restWidth: "200px",
    },
    {
      Header: "Evidence of infection?",
      accessor: "woundAtRiskOfInfection",
      restWidth: "200px",
    },
    {
      Header: "Exudate type​​",
      accessor: "exudateType",
      restWidth: "150px",
    },
    {
      Header: "Exudate amount​​​",
      accessor: "exudateAmount",
      restWidth: "150px",
    },
    {
      Header: "Wound bed identified ​​​",
      accessor: "woundbedIdentified",
      restWidth: "180px",
    },
    {
      Header: "Wound classification ​​​",
      accessor: "woundClassification",
      restWidth: "180px",
    },    
    {
      Header: "Intervention name​​",
      accessor: "interventionName",
      restWidth: "170px",
    },
    {
      Header: "Last updated by​​",
      accessor: "lastUpdatedBy",
      restWidth: "150px",
    },
    {
      Header: "Last updated on​​​",
      accessor: "lastUpdatedOn",
      restWidth: "150px",
    },
    {
      Header: "Dressing appearance​​",
      accessor: "dressingAppearance",
      restWidth: "180px",
    },
    {
      Header: "Debridement​​",
      accessor: "debridement",
      restWidth: "150px",
    },
    {
      Header: "Wound cleanser​​",
      accessor: "woundCleanser",
      restWidth: "150px",
    },
    {
      Header: "Skin protectant​​",
      accessor: "skinProtectant",
      restWidth: "150px",
    },
    {
      Header: "Primary dressing​​",
      accessor: "primaryDressing",
      restWidth: "150px",
    },
    {
      Header: "Secondary dressing​​",
      accessor: "secondaryDressing",
      restWidth: "170px",
    },
    {
      Header: "Compression​​",
      accessor: "compression",
      restWidth: "150px",
    },
    {
      Header: "Modalities​​",
      accessor: "modalities",
      restWidth: "150px",
    },
    {
      Header: "Additional care​​",
      accessor: "additionalCare",
      restWidth: "160px",
    },
    {
      Header: "Dressing change frequency",
      accessor: "dressingChangeFrequency",
      restWidth: "210px",
    },
  ];

  // const downloadDate = format(new Date(), "MM.dd.yyyy-hh.mm.aaa");
  const downloadDate = format(new Date(), "MM-dd-yyyy");
  // const dateObj = new Date();
  // const monthGet   = dateObj.getUTCMonth() + 1; // months from 1-12
  // const dayGet     = dateObj.getUTCDate();
  // const yearGet    = dateObj.getUTCFullYear();  
  // const downloadDate = monthGet + "-" + dayGet + "-" + yearGet;
  

  //redirect
  const getSelectedRowData = (data) => {
    if (data) {
      history.push(
        `/${"patients"}/${data.ne1Id}/${"assessment"}/${data.woundId}`
      );
    }
  };

  const deleteDataList = () => {};

  const selectRowCount = () => {};



  const fileHeaders = [    
    { label: "Ne1 Id", key: "ne1Id" },
    { label: "Wound acquired", key: "woundAcquired" },
    { label: "Re-eval Id", key: "revalId" },
    { label: "Wound number", key: "woundNumber" },
    { label: "MRN #", key: "mrn" },
    { label: "Patient\'s name", key: "patientName" },
    { label: "DOB", key: "dob" },
    { label: "Facility/Location", key: "facilityLocation" },
    { label: "Admission date", key: "admissionDate" },
    { label: "Discharge date", key: "dischargeDate" },
    { label: "Wound photo taken by", key: "woundPhotoTakenBy" },
    { label: "Wound photo taken on", key: "woundPhotoTakenOn" },
    { label: "Last assessed", key: "lastAssessed" },
    { label: "Surface area​", key: "surfaceArea" },
    { label: "Length​", key: "length" },
    { label: "Width", key: "width" },
    { label: "Depth", key: "depth" },
    { label: "Tuneling", key: "tuneling" },
    { label: "Undermining", key: "undermining" },
    { label: "HPR 1", key: "hpr1" },
    { label: "HPR 2​", key: "hpr2" },
    { label: "HPR 3", key: "hpr3" },
    { label: "History/Cause", key: "historyCause" },
    { label: "Anatomy", key: "anatomy" },
    { label: "Condition POA", key: "conditionPOA" },
    { label: "Photo type", key: "photoType​" },
    { label: "Documentation type​​", key: "documentationType" },
    { label: "Wound age", key: "woundAge" },
    { label: "Tissue type", key: "tissueType" },
    { label: "Are there any open areas", key: "areThereAnyOpenAreas" },
    { label: "Evidence of infection?", key: "woundAtRiskOfInfection" },
    { label: "Exudate type​​", key: "exudateType" },
    { label: "Exudate amount​​​", key: "exudateAmount" },
    { label: "Wound bed identified", key: "woundbedIdentified" },
    { label: "Wound classification", key: "woundClassification​​" },
    { label: "Wound progress", key: "woundProgress" },
    { label: "Intervention name​​", key: "interventionName" },
    { label: "Last updated by", key: "lastUpdatedBy​" },
    { label: "Last updated on​​​", key: "lastUpdatedOn" },
    { label: "Dressing appearance", key: "dressingAppearance​" },
    { label: "Debridement​​", key: "debridement​" },
    { label: "Wound cleanser", key: "woundCleanser" },
    { label: "Skin protectant", key: "skinProtectant" },
    { label: "Primary dressing", key: "primaryDressing​" },
    { label: "Secondary dressing", key: "secondaryDressing​​" },
    { label: "Compression", key: "compression​​​" },
    { label: "Modalities​​", key: "modalities​​" },
    { label: "Additional care", key: "additionalCare​" },
    { label: "Dressing change frequency", key: "dressingChangeFrequency​" },
  ];

  const [csvHeader, setCsvHeader] = useState([
    { label: "Ne1 Id", key: "ne1Id" },
    { label: "Wound acquired", key: "woundAcquired" },
    { label: "Re-eval Id", key: "revalId" },
    { label: "Wound number", key: "woundNumber" },
    { label: "MRN #", key: "mrn" },
    { label: "Patient\'s name", key: "patientName" },
    { label: "DOB", key: "dob" },
    { label: "Facility/Location", key: "facilityLocation" },
    { label: "Admission date", key: "admissionDate" },
    { label: "Discharge date", key: "dischargeDate" },
    { label: "Wound photo taken by", key: "woundPhotoTakenBy" },
    { label: "Wound photo taken on", key: "woundPhotoTakenOn" },
    { label: "Last assessed", key: "lastAssessed" },
    { label: "Last updated by", key: "lastUpdatedBy" },
    { label: "Last updated on", key: "lastUpdatedOn" },
    { label: "Surface area​", key: "surfaceArea" },
    { label: "Length​", key: "length" },
    { label: "Width", key: "width" },
    { label: "Depth", key: "depth" },
    { label: "Tuneling", key: "tuneling" },
    { label: "Undermining", key: "undermining" },
    { label: "HPR 1", key: "hpr1" },
    { label: "HPR 2​", key: "hpr2" },
    { label: "HPR 3", key: "hpr3" },
    { label: "History/Cause", key: "historyCause" },
    { label: "Anatomy", key: "anatomy" },
    { label: "Condition POA", key: "conditionPOA" },
    { label: "Photo type", key: "photoType​" },
    { label: "Documentation type​​", key: "documentationType" },
    { label: "Wound age", key: "woundAge" },
    { label: "Tissue type", key: "tissueType" },
    { label: "Are there any open areas", key: "areThereAnyOpenAreas" },
    { label: "Evidence of infection?", key: "woundAtRiskOfInfection" },
    { label: "Exudate type​​", key: "exudateType" },
    { label: "Exudate amount​​​", key: "exudateAmount" },
    { label: "Wound bed identified", key: "woundbedIdentified​" },
    { label: "Wound classification", key: "woundClassification" },
    { label: "Wound progress", key: "woundProgress" },
  ]);

  // pagination
  const [activePage, setActivePage] = useState(1);
  const [csvData, setCsvData] = useState(null);
  const [offSet, setOffSet] = useState(0)
  const [sortType, setSortType] = useState('')
  const [numberOfintervention, setNumberOfintervention] = useState(0); // State for mainaining number of intervention each row has

  const getActivePage = (data) => {
    setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
    assignedPagination(
      data,
      setActivePage,
      "/reports/dashboard",
      "POST",
      LIST_VIEW_LIMIT,
      dispatch,
      getBiReportsListAsync,
      "",
      biReportsListData?.Postdata,
      sortType
    );
  };
  const handleSort = (data) => {
    let sortingColumn = data.sortBy[0]
    let sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
    setSortType((sortingColumn) && sortingColumn.id + sortingType)
    let sortingList = {};
    sortingList['url'] = "/reports/dashboard"
    sortingList['method'] =  "POST"
    sortingList['queryParam'] = { limit: LIST_VIEW_LIMIT, offSet: isNaN(offSet)===true?0:offSet,sort: (sortingColumn) && sortingColumn.id + sortingType }
    sortingList['reportData'] =  biReportsListData?.Postdata
    dispatch(getBiReportsListAsync(sortingList))
  };
 
  useEffect(() => {
    if (
      biReportDetailCsvData &&
      biReportDetailCsvData?.reports &&
      biReportDetailCsvData?.reports?.length > 0
    ) {
      const temp = [...biReportDetailCsvData.reports];
      const res = [];
      let max = 0;
      biReportDetailCsvData?.reports?.forEach((data, i) => { //NOSONAR
        const test = { ...temp[i] };
        // We need to pass header dynamically
        if (data?.interventions?.length > max) {
          max = data.interventions.length;
        }
        data.interventions &&
          data.interventions.length > 0 &&
          data.interventions.forEach((data, qi) => { //NOSONAR
            test["additionalCare " + (qi + 1)] = data.additionalCare;
            test["compression" + (qi + 1)] = data.compression;
            test["debridement" + (qi + 1)] = data.debridement;
            test["dressingAppearance" + (qi + 1)] = data.dressingAppearance;
            test["dressingChangeFrequency" + (qi + 1)] =
              data.dressingChangeFrequency;
            test["interventionName" + (qi + 1)] = data.interventionName;
            test["modalities" + (qi + 1)] = data.modalities;
            test["primaryDressing" + (qi + 1)] = data.secondaryDressing;
            test["secondaryDressing" + (qi + 1)] = data.compression;
            test["skinProtectant" + (qi + 1)] = data.skinProtectant;
            test["woundCleanser" + (qi + 1)] = data.woundCleanser;
          })//NOSONAR
        res.push(test);
      })//NOSONAR
      if (max >= 6) {
        setNumberOfintervention(6);
      } else {
        setNumberOfintervention(max);
      }
      setCsvData([...res]);
    }
  }, [biReportDetailCsvData]);

  useEffect(() => {
    if (numberOfintervention > 0) {
      const temp = [];
      for (let i = 1; i <= numberOfintervention; i++) {
        temp.push({ label: "Additional Care" + i, key: "additionalCare" + i });
        temp.push({ label: "Compression" + i, key: "compression" + i });
        temp.push({ label: "Debridement" + i, key: "debridement" + i });
        temp.push({
          label: "Dressing Appearance" + i,
          key: "dressingAppearance" + i,
        });
        temp.push({
          label: "Dressing Change Frequency" + i,
          key: "dressingChangeFrequency" + i,
        });
        temp.push({
          label: "Intervention Name" + i,
          key: "interventionName" + i,
        });
        temp.push({ label: "Modalities" + i, key: "modalities" + i });
        temp.push({
          label: "Primary Dressing" + i,
          key: "primaryDressing" + i,
        });
        temp.push({
          label: "Secondary Dressing" + i,
          key: "secondaryDressing" + i,
        });
        temp.push({ label: "Skin Protectant" + i, key: "skinProtectant" + i });
        temp.push({ label: "Wound Cleanser" + i, key: "woundCleanser" + i });
      }
      setCsvHeader([...csvHeader, ...temp]);
    }
  }, [numberOfintervention]); 

  return (
    <>
      <div className={`${style.back_btn}`}>
        <Header backLink={"/biReports"} handleBack={() => history.goBack()} />
      </div>
      {!tinyDelay ?    
        <div className={`${style.loader} col-12`}>
        <img src={Loader} alt="loading" />
      </div>:
      <div className="col-12">
        {biReportsListData && Array.isArray(biReportsListData?.reports) && (
          <>
            <div className="col-sm-12 mb-2 justify-content-end d-flex ">
              <span className={`mr-1 ${style.down_spread}`}>
                <img src={Spreadsheet} alt="download_icon" className="mr-2" />
                <CSVLink
                  headers={fileHeaders}
                  data={
                    biReportSummaryCsvData &&
                    Array.isArray(biReportSummaryCsvData.reports)
                      ? biReportSummaryCsvData?.reports
                      : []
                  }
                  filename={`NE1-Ad-Hoc-Report-${downloadDate}.csv`}
                  target="_blank"
                >
                  Download
                </CSVLink>
              </span>
              {/* <span className={style.down_spread}>
                <a href="#">
                  <img src={Spreadsheet} alt="download_icon" className="mr-2" />
                  <CSVLink
                    headers={csvHeader}
                    data={csvData && Array.isArray(csvData) ? csvData : []}
                    filename={`${customerName}.Ad-hoc_Reports_Details.${downloadDate}.csv`}
                    target="_blank"
                  >
                    Download Details
                  </CSVLink>
                </a>
              </span> */}
            </div>
            <DataTable
              columns={columns}
              data={              
                biReportsListData && Array.isArray(biReportsListData?.reports)
                  ? biReportsListData?.reports
                  : []
              }
              sorting={true}
              getSelectedRowData={getSelectedRowData}
              setSelectedRows={selectRowCount}
              onSort={handleSort}
              detailView={true}
              deleteDataList={deleteDataList}
              showFixedColumn={false}
              workListView={true}
              fixedFullWidthSize={"0"}
              sortType={(sortType)?sortType:''}
            />
            <Pagination
              activePage={activePage}
              itemsCountPerPage={
                biReportsListData?.metadata
                  ? biReportsListData?.metadata?.limit
                  : 50
              }
              totalItemsCount={
                biReportsListData?.metadata
                  ? biReportsListData?.metadata?.total
                  : 0
              }
              getActivePage={getActivePage}
            />
          </>
        ) 
        // : (
        //   <div className={style.no_matching_result}>
        //     There are no records matching your report criteria....
        //   </div>
        // )
        }
      </div>      
      }
    </>
  );
}//NOSONAR
BiReportsList.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
};
BiReportsList = connect()(withRouter(BiReportsList));
