import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import { VerticalTab } from '../../secondary'
import { CharacteristicCustomForm } from './characteristicCustomForm';
import { CharacteristicProgress } from './characteristicProgress';
import { CharacteristicHistory } from './characteristicHistory';
import { CharacteristicAnatomy } from './characteristicAnatomy';
import { CharacteristicTissue } from './characteristicTissue';
import { CharacteristicTouch } from './characteristicTouch';
import { CharacteristicClassification } from './characteristicClassification';
import { optionTypeAsync, setCharStatus, detailAssessmentAsync, setTissueTypesData, patientAssessmentAsyncOverride } from '../../../app/patientSlice';
import { getCustomFormAsync } from '../../../app/customFormSlice';
import styles from './characteristic.module.scss'
import { useParams, withRouter } from 'react-router-dom'

export let Characteristic = ({ location, hideIt }) => {

  /* Dispatch */
  const dispatch = useDispatch()

  // Getting details about wound assessment
  // Get values from url
  const { patientId, assessmentId } = useParams();
  // Assign it to payload
  const payload = { patientId, assessmentId }

  // This is for re-rendering
  const getCharDetails = () => { 
    // Dispatching get assessment details call
    dispatch(detailAssessmentAsync(payload))
  }

  useEffect(() => {
    dispatch(detailAssessmentAsync(payload))
  }, [location])

  useEffect(() => {
    getCharDetails();
    dispatch(setTissueTypesData({}))
  }, [])

  useEffect(() => {
    if (assessmentId) {
      // Dispatching list options
      dispatch(optionTypeAsync('humananatomy'));
      dispatch(optionTypeAsync('historycause'));
      dispatch(optionTypeAsync('pihistory'));
      dispatch(optionTypeAsync('woundage'));
      dispatch(optionTypeAsync('phototype'));
      dispatch(optionTypeAsync('documentationtype'));
      dispatch(optionTypeAsync('tissuetemperature'));
      dispatch(optionTypeAsync('tissuefirmness'));
      dispatch(optionTypeAsync('painlevel'));
      dispatch(optionTypeAsync('blanchtest'));
      dispatch(optionTypeAsync('exudatetype'));
      dispatch(optionTypeAsync('exudateamount'));
      dispatch(optionTypeAsync('woundedges'));
      dispatch(optionTypeAsync('skincolorarounwound'));
      dispatch(optionTypeAsync('induration'));
      dispatch(optionTypeAsync('edema'));
      dispatch(optionTypeAsync('granulationtissue'));
      dispatch(optionTypeAsync('epithelialization'));
      dispatch(optionTypeAsync('woundprogress'));
      dispatch(optionTypeAsync('periwoundcondition'));
    }
  }, [assessmentId, dispatch])


  // Get values of assessment from state 
  const assessmentDetails = useSelector(state => state.patient.detailAssessment)


  //added wound photo for T&v and Tissue Type
  const [showwound, setShowWound] = useState(false) 

  // get Overrideinfo status from TT
  const tissueOverrideStatus = useSelector(state => state.patient.tissueOverrideStatus)
  useEffect(()=>{
      let payload = { patientId, assessmentId, type: 'tissuetypes', assessmentDetails, getCharDetails} 
      dispatch(patientAssessmentAsyncOverride(payload))
  },[dispatch])

  const[overrideToggle, setOverrideToggle] = useState(false)
  useEffect(()=>{
    if(tissueOverrideStatus===1){
      setOverrideToggle(true)
    }
    else{
      setOverrideToggle(false)
    }
  },[tissueOverrideStatus])
// get Overrideinfo status from TT
  
  /* Tab list */
  const tabList = [
    {
      name: 'History/Cause',
      content: <CharacteristicHistory
        styles={styles}
        patientId={patientId}
        assessmentId={assessmentId}
        assessmentDetails={assessmentDetails}
        getCharDetails={getCharDetails}
        location={location}
        hideIt={hideIt}
        setShowWound={false}
        overrideToggle={overrideToggle}
      />
    },
    {
      name: 'Anatomy',
      content: <CharacteristicAnatomy
        styles={styles}
        patientId={patientId}
        assessmentId={assessmentId}
        assessmentDetails={assessmentDetails}
        getCharDetails={getCharDetails}
        hideIt={hideIt}
        setShowWound={setShowWound}
        overrideToggle={overrideToggle}
      />
    },
    {
      name: 'Tissue Types',
      content: <CharacteristicTissue
        styles={styles}
        assessmentDetails={assessmentDetails}
        patientId={patientId}
        assessmentId={assessmentId}
        getCharDetails={getCharDetails}
        hideIt={hideIt}
        setShowWound={false}
        overrideToggle={overrideToggle}
      />
    },
    {
      name: 'Touch & View Details',
      content: <CharacteristicTouch
        styles={styles}
        patientId={patientId}
        assessmentId={assessmentId}
        assessmentDetails={assessmentDetails}
        getCharDetails={getCharDetails}
        updateCharStatus={updateCharStatus}
        hideIt={hideIt}
        setShowWound={setShowWound}
        overrideToggle={overrideToggle}
      />
    },
    {
      name: 'Classification',
      content: <CharacteristicClassification
        styles={styles}
        patientId={patientId}
        assessmentId={assessmentId}
        assessmentDetails={assessmentDetails}
        getCharDetails={getCharDetails}
        hideIt={hideIt}
        setShowWound={setShowWound}
        overrideToggle={overrideToggle}
      />
    },
    {
      name: 'Set Progress',
      content: <CharacteristicProgress
        styles={styles}
        patientId={patientId}
        assessmentId={assessmentId}
        assessmentDetails={assessmentDetails}
        getCharDetails={getCharDetails}
        hideIt={hideIt}
        setShowWound={setShowWound}
        overrideToggle={overrideToggle}
      />
    },
  ];

  // Get Customform
  let formsName = useSelector(state => state.forms.setForm)
  useEffect(() => {
    const queryParam = {
      feature: "NE1 WMS", subFeature: "Characteristics", ne1Id: patientId, assessmentId: assessmentId
    }
    dispatch(getCustomFormAsync(queryParam))
  }, [dispatch])
  formsName = formsName && formsName.data && [...formsName.data].sort((a, b) => {
    return a.sequence - b.sequence;
  });

  // Map through the above customForm array and add it to tablist array for Tabs */
  const customFormList = formsName && formsName.map((data, i) => {
    return {
      /* Name is "Custom Form x" (x denotes number) */
      name: data.value,
      /* Container component for custom form */
      content: <CharacteristicCustomForm name={data.value} formId={data.id} queryParam={{ ne1Id: patientId, assessmentId: assessmentId }} hideIt={hideIt} assessmentDetails={assessmentDetails}/>,
      completed: data.completed
    }
  })

  // Adding new tabs to the tabList using Array splice 
  formsName && tabList.splice(4, 0, ...customFormList)

  const [tabLength, setTabLength] = useState()

  /* Calculating the tabList array length */
  useEffect(() => {
    setTabLength(tabList.length);
  })

  /* If the user hasn't updated anything, 
     create new array of the length of tabList and filling it with '0' */
  useEffect(() => {
    if (JSON.stringify(charStatus) === JSON.stringify([])) {
      dispatch(setCharStatus(Array(tabLength).fill(0)))
    }
  }, [dispatch, tabLength])

  const charStatus = useSelector(state => state.patient.charStatus)

  var updateCharStatus = () => {
    const temp = [...charStatus]
      if (assessmentDetails) {
        if (assessmentDetails?.historyCause?.newWound === 1) {
          temp[0] = 1
        } else {
          temp[0] = 0
        }

        if (assessmentDetails?.anatomy !== null) {
          temp[1] = 1
        } else {
          temp[1] = 0
        }

        if (assessmentDetails?.tissueTypes !== null) {
          temp[2] = 1
        } else {
          temp[2] = 0
        }

        if (assessmentDetails?.touchView !== null) {
          temp[3] = 1
        } else {
          temp[3] = 0
        }

        // if (assessmentDetails?.classification !== null) {
        //   temp[tabLength - 2] = 1
        // } else {
        //   temp[tabLength - 2] = 0
        // }

        // Two condition for classification 
       if (assessmentDetails?.classification && assessmentDetails?.classification?.woundBedIdentifiable !== null) {
          temp[tabLength - 2] = 1
        } 
        else if ((assessmentDetails?.classification && assessmentDetails?.classification?.woundClassification !== 'unknown')&&(assessmentDetails?.classification && assessmentDetails?.classification?.woundClassification !== 'Unknown')) {
          temp[tabLength - 2] = 1
        }
         else {
          temp[tabLength - 2] = 0
        }
        // Two condition for classification 

        if (assessmentDetails?.woundStage !== null) {
          temp[tabLength - 1] = 1
        } else {
          temp[tabLength - 1] = 0
        }
      }
      dispatch(setCharStatus(temp))
  }

  useEffect(() => {
    updateCharStatus();
  }, [dispatch, tabLength, assessmentDetails])


//   useEffect(() => {
//     if(showwound === true){
//       setShowWound(true)
//     }else{
//       setShowWound(false)
//     }
        
// }, [tabList]) 

  return (
    <div>
      <span className="d-none">{assessmentDetails?.anatomy}</span>
        <VerticalTab tabList={tabList} dispatch={dispatch} showwound={showwound} setShowWound={setShowWound}/>            
    </div>
  )
}

Characteristic = connect()(withRouter(Characteristic));
