import React, { useState, useEffect } from 'react';
import style from './dressingAppearance.module.scss';
import { Button, Form, SelectBox } from '../../primary';
import { CANCEL_BTN, SAVE_BTN } from '../../../constants';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { useForm } from 'react-hook-form';
import { dressingAppearance, signedLockedAccess } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { assessmentRbacChecker } from '../../../utils/rbac';
import { getDressingAppearanceAsync, optionTypeAsync, patchDressingAppearanceAsync } from '../../../app/patientSlice';

export const DressingAppearance = ({ interventionId, formularyid, assessmentDetails }) => {

  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const resp = useSelector(state => state.patient.dressingAppearance);
  const listResp = useSelector(state => state.patient.dressingappearanceListoptions);
  const patientData = useSelector(state => state.patient.detailViewData)
  const userDetails = useSelector(state => state.user);
  const hideButtons = assessmentRbacChecker(userDetails?.role, patientData?.isOwnCustomer);
  const { patientId, assessmentId } = useParams();

  const [editable, setEditable] = useState(false);

  const editMode = () => {
    setEditable(true);
  }

  const viewMode = () => {
    if (resp?.dressingAppearance === null) {
      return false
    } else {
      setEditable(false);
      reset();
    }
  }

  const doSubmit = (data) => {
    const payloadData = {
      "dressingAppearance": data.dressinghStatus,
      'patientId': patientId,
      'assessmentId': assessmentId,
      'interventionId': interventionId
    }
    dispatch(patchDressingAppearanceAsync(payloadData))
    setEditable(false);
  }

  useEffect(() => {
    if ((interventionId === 0) && (resp?.dressingAppearance === null)) {
      setEditable(true)
    } else if ((interventionId !== 0) && (resp?.dressingAppearance === null)) {
      setEditable(true)
    } else {
      setEditable(false)
    }
  }, [resp, interventionId])

  useEffect(() => {
    viewMode()
    dispatch(optionTypeAsync('dressingappearance'));
    dispatch(getDressingAppearanceAsync({ patientId, assessmentId, interventionId }));
 
  }, [interventionId, formularyid])

  return <div>
    {
      !editable ? <div>
        <div className={`row ${style.col_spec}`}>
          <label className="col-5">Dressing Appearance: </label> <span className={`col-6 p-0 ${style.first_letter_capital}`}>{resp?.dressingAppearance}</span>
          {
            !editable && signedLockedAccess(assessmentDetails?.assessmentStage) && hideButtons && <div className={` ${style.tab_edit}`} onClick={() => editMode()}><img src={editIcon} alt="icon" className={style.e_icon_space} />Edit</div>
          }
        </div>
      </div> :
        <div>
          {signedLockedAccess(assessmentDetails?.assessmentStage) ?
            <Form  onSubmit={handleSubmit(doSubmit)}>
              <SelectBox
                id={"dressinghStatus"}
                name={"dressinghStatus"}
                label={"Dressing Appearance:"}
                classes={{
                  column: `row ${style.col_spec}`,
                  inputClassName: style.basic_select,
                  labelClassName: `col-5`,
                  inputColumn: `col-6 p-0 ${style.select_wrapper}`
                }}
                optionValue={listResp}
                defaultValue={resp?.dressingAppearance ? resp.dressingAppearance : ''}
                validator={register(dressingAppearance)}
                placeholder="Select"
              />
              {
                hideButtons && <div className="row">
                  <div className="mt-4 pt-2 col-sm-12 p-0">
                    <div className="offset-5 p-0">
                      <Button
                        type={"submit"}
                        classes={{ className: `btn ${style.btn_save}` }}
                        id={'updateBtn'}
                        value={SAVE_BTN}
                      />
                      <Button
                        classes={{ className: `btn ${style.btn_cancel}` }}
                        type="button"
                        id="cancel"
                        value={CANCEL_BTN}
                        onClick={() => viewMode()}
                      />
                    </div>
                  </div>
                </div>
              }
            </Form>
            :
            'This wound assessment is signed and locked, cannot be updated.'
          }
        </div>
    }
  </div>
}
