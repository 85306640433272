import React, { useEffect, useRef, useState } from "react";
import { Form } from "../../primary";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  createAssessmentAsync,
  createAssessMentWithoutPhoto,
} from "../../../app/patientSlice";
import { useParams } from "react-router-dom";
import AnatomyEdit from "./anatomyEdit";
import { ServiceError } from "../../../utils";
import { clearError } from "../../../app/appSlice";
export function CreateAssessmentAnatomy({
  styles,
  dispatch,
  setCreateAssessmentData,
  history,
  currentWoundPhoto,
  modalClose,
  isWoundWithoutPhoto,
}) {
  /***************** */
  /* Zoom in and out */
  /***************** */

  // For zooming
  const Viewer = useRef(null);

  /* Fit it inside viewer */
  useEffect(() => {
    dispatch(clearError())
    Viewer.current.fitToViewer();
  }, []);

  /***************** */
  /* Zoom in and out */
  /***************** */

  /* Hook form */
  const { register, handleSubmit } = useForm();

  /* Get patient id from url */

  const { patientId } = useParams();

  /* State for body part */
  const [bodyPart, setBodyPart] = useState("");
  /* Selected ID */
  const [id, setId] = useState("");
  const [errChk, setErrChk] = useState(false);
  const [view, setView] = useState(null);
  // Handle body part select
  const handleSelect = (e) => {
    setErrChk(false);
    /* If ID is already there, clear it first */
    if (id) {
      handleClear(id);
    }
    /* Set it */
    setId(e.target.id);
    /* Set body part using textContent */
    setBodyPart(e?.target?.textContent);
    /* Add active class */
    e?.target?.classList?.add("active"); //NOSONAR

    const front =
      document.getElementById("front") &&
      Array.from(
        document.getElementById("front")?.getElementsByClassName("active")
      ).length;
    const back =
      document.getElementById("back") &&
      Array.from(
        document.getElementById("back")?.getElementsByClassName("active")
      ).length;

    if (front === 0) {
      setView(0);
    }
    if (back === 0) {
      setView(1);
    }
  };

  /* Handling clear */
  const handleClear = (id) => {
    /* If ID exists clear it */
    if (id) {
      const part = document.getElementById(id);
      /* remove active class */
      part?.classList?.remove("active"); //NOSONAR
    }
  };

  /* Handle submit */

  const onSubmit = (data) => {
    dispatch(clearError())
    /* If data exists */
    if (data && data.anatomy) {
      setErrChk(false);
      /* Creating temp object to setting isSubmitted true */
      const temp = {
        /* Conver the title into lower case and remove sapces*/
        anatomy: data.anatomy.toLowerCase().replace(/\W+/g, ""),
        isSubmitted: true,
        isFrontView: view,
      };
      /* Dispatch it */
      dispatch(setCreateAssessmentData(temp));
      /* If the form is submitted, dispatch API call */
      if (isWoundWithoutPhoto && isWoundWithoutPhoto === 1) {
        dispatch(createAssessMentWithoutPhoto(patientId, history));
      } else {
        dispatch(createAssessmentAsync(patientId, history, currentWoundPhoto));
        setTimeout(() => {
          if (!loading) {
            modalClose();
          }
        }, 2000);
      }

   
    } else {
      setErrChk(true);
    }
  };

  // Get API fetch loading
  const loading = useSelector((state) => state.app.loading);
  const errorMessage = ServiceError();
  return (
    <div>
      <Form formChanges={true} onSubmit={handleSubmit(onSubmit)}>
        {errorMessage&&!errChk&&(
          <span className={`mb-n2 text-center ${styles.hist_cause_err}`}>
            {errorMessage}
          </span>
        )}
        {errChk && (
          <span className={`mb-n2 text-center ${styles.hist_cause_err}`}>
            Please select anatomy
          </span>
        )}
        <input
          type="text"
          className="d-none"
          name="anatomy"
          defaultValue={bodyPart}
          ref={register}
        />

        <AnatomyEdit
          handleSelect={handleSelect}
          loading={loading}
          cancel={false}
          styles={styles}
          bodyPart={bodyPart}
          Viewer={Viewer}
        />
      </Form>
    </div>
  );
}
