import React, { useEffect, useState } from 'react';
import { ViewTreatMent } from '../../secondary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDressingAsync, getlistOptionsAsync, saveDressingAsync,setIsMessageShowForSecondayDressing } from '../../../app/patientSlice';
import styles from './secondaryDressing.module.scss'
export const SecondaryDressing = ({ interventionId, formularyId, assessmentDetails }) => {
  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const resp = useSelector(state => state.patient.secondaryDressingGetData);
  const secondaryDressingGetData = useSelector(state => state.patient.secondaryDressingGetData);
  const secondaryDressingListOptions = useSelector(state => state.patient.secondaryDressingListOptions);
  const primaryDressingGetData = useSelector(state => state.patient.primaryDressingGetData); // added for hiding popup when primary dressing not selected
  const interventionListOptions = useSelector(state => state.patient.interventionListOptions)
  const isMessageShowForSecondayDressing = useSelector(state => state.patient.isMessageShowForSecondayDressing)
  const [closeWarning, setCloseWarning] = useState(false)
  const [saveDataConfirm, setSaveDataConfirm] = useState('')  
  const [getOption, setGetOption] = useState(secondaryDressingListOptions);
  const [getintervention, setGetintervention] = useState(interventionListOptions)
  const [dropValue, setDropValue] = useState(false)
  const [secSelectData, setSecSelect] = useState([])
  useEffect(() => {
    setSaveDataConfirm('')
   let finalSecData=[]
    if (Array.isArray(secondaryDressingGetData)&&secondaryDressingGetData.length>0) {
      //secondaryDressingListOptions
     secondaryDressingListOptions && Array.isArray(secondaryDressingListOptions) && secondaryDressingListOptions.map((item, index) => {
     item && Array.isArray(item.options) && item.options.map((chckOpt, k) => {//NOSONAR
      secondaryDressingGetData && Array.isArray(secondaryDressingGetData) && secondaryDressingGetData.map((itemDef, j) => {//NOSONAR
        if(itemDef.value===chckOpt.materialNumber){
          finalSecData.push({key: itemDef?.key, value: itemDef?.value, isRecommended: chckOpt?.isRecommended})//NOSONAR
          return
        }
       })//NOSONAR
   })//NOSONAR
  })
    }
    setSecSelect(finalSecData)
  }, [secondaryDressingGetData,secondaryDressingListOptions])
  let flags = false  // added for hiding popup when primary dressing not selected
  const saveData = (data) => {    
    const payloadData = {
      "productCategory": data,
      'patientId': patientId,
      'assessmentId': assessmentId,
      'interventionId': interventionId,
      'payType': 'secondarydressing',
      'formularyId': formularyId,
      
    }
    setSaveDataConfirm(payloadData)
    let countIsRec=[]
    const countIsPrimRec=Array.isArray(primaryDressingGetData)&&primaryDressingGetData.filter(function(itm){
      return (itm.isRecommended===1);
    });

    data && Array.isArray(data) && data.map((chckOpt, k) => {//NOSONAR
      countIsPrimRec && Array.isArray(countIsPrimRec) && countIsPrimRec.map((itemDef, j) => {//NOSONAR
        // if(itemDef?.value===chckOpt?.value && itemDef?.isRecommended===chckOpt?.isRecommended){ //
        if(itemDef?.value===chckOpt?.value){//NOSONAR
          countIsRec.push({key: itemDef?.key, value: itemDef?.value, isRecommended: chckOpt?.isRecommended})//NOSONAR
          return
        }
       })//NOSONAR
   })//NOSONAR
   setCloseWarning(false)
      if(isMessageShowForSecondayDressing&&isMessageShowForSecondayDressing===1){
        setCloseWarning(true)
      }else{
        dispatch(saveDressingAsync(payloadData))
    }

    /*if(Array.isArray(data)&&data.length===0 || Array.isArray(primaryDressingGetData)&&primaryDressingGetData.length===0){
        dispatch(saveDressingAsync(payloadData))
    }else if(Array.isArray(countIsPrimRec)&&countIsPrimRec.length>0){
      setCloseWarning(true)
    }
    else{
        dispatch(saveDressingAsync(payloadData))
    }*/
 
  }

  useEffect(() => {
    setSaveDataConfirm('')
   //setIsMessageShowForSecondayDressing
   dispatch(setIsMessageShowForSecondayDressing({}));
    if (formularyId !== null) {
      //dispatch(getDressingAsync({ patientId, assessmentId, interventionId, payType: 'primarydressing' }));
      const payload = {
        'formularyid': formularyId,
        'option': 'secondarydressing?ne1Id=' + patientId + '&assessmentId=' + assessmentId+ '&interventionid=' + interventionId,
        payType: 'secondarydressing'
      }
      dispatch(getlistOptionsAsync(payload));
      dispatch(getDressingAsync({ patientId, assessmentId, interventionId, payType: 'secondarydressing' }));

    }
  }, [interventionId])


  let flag = false
  const yesFn = () => {
    let secondaryData = [];
      for (let i = 0; i < secondaryData.length; i++) {
        if (secondaryData[i] === undefined) {
          flag = false
        }
        if (secondaryData[i] !== undefined) {
          flag = true;
          break;
        }
      }
      const secData = Array.isArray(saveDataConfirm?.productCategory) && saveDataConfirm?.productCategory?.map((item, index) => {
        secondaryData.push(item.value)
      })
   
    dispatch(saveDressingAsync(saveDataConfirm))
    dispatch(setIsMessageShowForSecondayDressing({}));
    setCloseWarning(false)
    setDropValue(false)
  }
  
  const noFn = () => {
    setGetOption([])
    setGetintervention([])
   // setDropValue(true)
    setSaveDataConfirm('')
    setCloseWarning(false)   
    setGetOption(secondaryDressingListOptions)
    setGetintervention(interventionListOptions)
    const payload = {
      'formularyid': formularyId,
      'option': 'secondarydressing?ne1Id=' + patientId + '&assessmentId=' + assessmentId+ '&interventionid=' + interventionId,
      payType: 'secondarydressing'
    }
    dispatch(getlistOptionsAsync(payload));
    dispatch(getDressingAsync({ patientId, assessmentId, interventionId, payType: 'secondarydressing' }));
  }
  useEffect(() => {   
    if (secondaryDressingListOptions) {
      setGetOption(secondaryDressingListOptions)
    }
  }, [secondaryDressingListOptions])
  return (
    <>
      {closeWarning  && 
        <>
          <div className={`${styles.warning}`}>
            <h6>
            {'Based on your Primary dressing selection, Secondary dressing is not recommended.'} <br/>
            {'Are you sure you would like to proceed with your Secondary dressing selection?'} <br/>
            <button onClick={yesFn}>Yes</button>
            <button onClick={noFn}>No</button>
            </h6>
           
          </div>
        </>

      }
      <ViewTreatMent
        editData={getOption}
        viewData={secSelectData}
        noDataMessage={"Your formulary doesnt have any secondary dressings, please contact your Medline Sales Rep"}
        saveData={saveData}
        dressing="secondary"
        assessmentDetails={assessmentDetails}
        setCloseWarningSec={setCloseWarning}
        dropValue={dropValue}
        setGetValueDrop = {setDropValue}
      />
    </>
  )
}
