import React, { useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  GENERAL_ERROR,
  LIST_VIEW_MENU_SEQUENCE_ID,
  SAVE_BTN,
  SUBMIT_ID,
  SALES_OFFICE_OPTIONS,
  SEQUENCE_LBL,
  SUCCESS_STATUS,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  ERROR_STATUS,
  VALID_EXISTING_SEQUENCE_NUM,
  SEQUENCE
} from '../../../constants';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { sequenceNumber,checkName, ServiceError, getFacilityLabel } from '../../../utils';
import { Button, Form, TextField, SelectBox, Organizational, MultiSelect } from '../../primary';
import style from './editFilter.module.scss';
import { updateLayoutAsync, setLayoutEditStatus, getCustomerNumberSuggestionsLayout, setFacilitiesData, getLayoutAsync, setExcludeNumberSearch, setAutoSeqNumber, getAutoSeqNumber, reSetFacilitiesData} from '../../../app/layoutsSlice';
import { useDispatch, useSelector } from 'react-redux';
import ReactTags from "react-tag-autocomplete";
import { clearError } from "../../../app/appSlice";
import rightArrow from '../../../static/images/right_arrow.svg';
import Loader from '../../../static/images/spinner.svg';	

export const EditCrad = ({ layoutRowData, layoutId }) => {

  const { register, handleSubmit, errors, reset, setValue, setError } = useForm({ mode: 'all' });
  const listViewRef = useRef();
  const [listViewMenuData, setListViewMenuDatta] = useState([]);
  const listViewMenusApi = useSelector(state => state.layouts.listViewMenus);
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [errorAssign, setErrorAssign] = useState(false); //added
  const [customerGroup, setCustomerGroup] = useState('');
  const [isValidSaleCust, setIsValidSaleCust] = useState(false);
  const [isValidPdf,setIsValidPdf] = useState(false);
  let [removeDefaultOrg, setRemoveDefaultOrg] = useState(true)
  let [customerNumber, setCustomerNumber] = useState("");
  const [customerNumberInp, setCustomerNumberInp] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);
  const updateStatus = useSelector(state => state.layouts.layoutEditStatus);
  let facilitiesDatas = useSelector(state => state.layouts.orgsData); 
  
  const [updateSeq, setUpdateSeq] = useState('');
  const autoSeqNumber = useSelector(state => state.layouts.autoSeqNumber);
  useEffect(() => {
    setUpdateSeq(autoSeqNumber?.data?autoSeqNumber?.data:'')
    if(autoSeqNumber?.data&&document.getElementById('sequence')){
      document.getElementById('sequence').value=autoSeqNumber?.data
    }
}, [autoSeqNumber])
  
const editMode = () => {
    //listSeque
    setUpdateSeq(layoutRowData && layoutRowData.sequence)
    setIsValidSaleCust(false);  
    setListViewMenuDatta(layoutRowData?.listViewMenus)
    setCustomerGroup(layoutRowData && layoutRowData.salesOffice)
    setEditable(true);
    reset();
    dispatch(setLayoutEditStatus({}))
    setIsValidPdf(false);   
    if(layoutRowData && layoutRowData.salesOffice){
      dispatch(setFacilitiesData({}))
      setCreateGroup(true);
      setShowExclude(true)
      setCustomerCodeSuggestions([])
      const getExcludeNumber=Array.isArray(layoutRowData.excludedCustomers)&&layoutRowData.excludedCustomers.length>0 ? layoutRowData.excludedCustomers:[];
      let editExcludeNumber=[]
      getExcludeNumber.map((excludeNumber,ind) =>{
        return editExcludeNumber.push({ id: excludeNumber, name: excludeNumber })
       })
      setCustomerCodeTag(editExcludeNumber)
    }
    if(customerNumber&&layoutRowData && layoutRowData.customerInfo!==customerNumber){     
      setValue("filterSalesOffice", '');
      setRemoveDefaultOrg(true)
      dispatch(getLayoutAsync(layoutRowData && layoutRowData.customerInfo))     
    } 
    else if(customerNumber===''&&layoutRowData && layoutRowData.customerInfo){
      setValue("filterSalesOffice", '');
      setRemoveDefaultOrg(true)
      dispatch(getLayoutAsync(layoutRowData && layoutRowData.customerInfo))
    } 
  }


  

  useEffect(() => {
       if(layoutRowData && layoutRowData.customerInfo){
      setCustomerNumber(layoutRowData && layoutRowData.customerInfo)
    }
  }, [layoutRowData])
  useEffect(() => {
    if (updateStatus === "success") {
      setEditable(false);
      reset();      
      setTimeout(() => {
        dispatch(setLayoutEditStatus({}))
      }, 5000)
    } else if (updateStatus?.errorMessage === VALID_EXISTING_SEQUENCE_NUM) {
      setEditable(true);
    }
  }, [updateStatus]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(setLayoutEditStatus({}))
    }, 5000)
    dispatch(setExcludeNumberSearch({}))
    setCustomerCodeSuggestions([])
    setCustomerCodeTag([])
    setShowExclude(false)
  }, [dispatch])



  const viewMode = () => {
    setEditable(false);
    reset();
    setRemoveDefaultOrg(true)
    setOrg(defaultFacilities)
    if(customerNumber&&layoutRowData && layoutRowData.customerInfo!==customerNumber){
      dispatch(getLayoutAsync(layoutRowData && layoutRowData.customerInfo))  
    } 
    else if(customerNumber===''&&layoutRowData && layoutRowData.customerInfo){
      dispatch(getLayoutAsync(layoutRowData && layoutRowData.customerInfo))
    }
  }


  const handleListViewMenuChange = () => {
    const selectedItems = listViewRef.current.getSelectedItems()
    const tempData = selectedItems && Array.isArray(selectedItems) && selectedItems.map((data) => {
      return data.id
    })
    setListViewMenuDatta(tempData)
  }
  

  const doCardEdit = (data) => {
    let soldToNumbers = [];
    org?.forEach((soldTo) => {
            const facilityNumber = soldTo?.cat?.split('|')[0]?.trim();
            if (!soldToNumbers.find(st => st.soldToNumber === facilityNumber)) {
                soldToNumbers.push({
                    "soldToNumber": facilityNumber,
                    "shipToNumbers": org?.filter(k => k.cat === soldTo.cat).map(shipTo => shipTo?.key?.split('|')[0]?.trim())
                });
            }
        })   
    const orgNumber= document.getElementById("customerNumber")?.value;
    soldToNumbers=Array.isArray(soldToNumbers)&&soldToNumbers.length===0?null:soldToNumbers;
    const tempUserData = {
      customerNumber: orgNumber&&soldToNumbers? orgNumber : null,
      organization: orgNumber&&soldToNumbers? {
          primaryGroupNumber: facilitiesDatas?.data?.facilities.find((f) => f.accountGroup === 'Z002').facilityNumber,
          soldToNumbers
      } : null, 
  }
  let finalCustomerCodeTag = [];
  finalCustomerCodeTag=Array.isArray(customerCodeTag)&&customerCodeTag?.map(
    (e) => {
      return e.id;
    }
  );
    const payload = {
      "id": layoutId,
      "name": data.filterName,
      "type": layoutRowData.type,
      "presetLocation": layoutRowData.presetLocation,
      "salesOffice": customerGroup?customerGroup : "",
      "excludedCustomers":Array.isArray(finalCustomerCodeTag)&&finalCustomerCodeTag.length>0?finalCustomerCodeTag:null,
      "sequence": data.filterSequence,
      "listViewMenus": listViewMenuData,
      "menuIcon":"",
      "contentURL": "",
      "contentURLType": "" ,
      "customerInfo": tempUserData,
    }

    if(customerNumber == '' && customerGroup=='') {
      setIsValidSaleCust(true);
      setIsValidPdf(true);    
    }    
    else if(customerNumber !== ''&&tempUserData?.customerNumber==null){
      setIsValidSaleCust(true);
    }
    else  {
      setIsValidSaleCust(false);
      setIsValidPdf(false);
      dispatch(updateLayoutAsync(payload))    
    }   
  }

  let updateMenus = [];
  useEffect(() => {
    if (updateStatus === "success") {
      setEditable(false);
      reset();
      setTimeout(() => {
        dispatch(setLayoutEditStatus({}))
      }, 5000)
    } else if (updateStatus?.errorMessage === VALID_EXISTING_SEQUENCE_NUM) {
      setEditable(true);
    }
  }, [updateStatus]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(setLayoutEditStatus({}))
    }, 5000)
  }, [dispatch])

  
  const errorMessage = ServiceError();
  useEffect(() => {
    if (errorMessage==="This sequence number is assigned to another banner. Please use a different sequence number") {
      setError("filterSequence", {
        type: "manual",
        message: errorMessage
      });
      dispatch(clearError());
    }
    if (errorMessage==="Layouts name already exist") { 
      setError("filterName", {
        type: "manual",
        message: errorMessage
      });
    
      dispatch(clearError());
    } 
  }, [errorMessage,dispatch]);


const onChangeOrg = (selectedList=[{}], selectedItem) => {
  setErrorAssign(false);
  setOrg([...selectedList]);
  setRemoveDefaultOrg(false)
}

const enterCustomerNumber = (e) => {
  dispatch(setAutoSeqNumber({}))
  if (e.target.value) {
      const payload = {sequenceNumber: "",layoutType:'filter',customerNumber:e.target.value}
      dispatch(getAutoSeqNumber({payload}))	
      setValue("filterSalesOffice", '');
      setOrg([])
      setRemoveDefaultOrg(false)
      dispatch(reSetFacilitiesData({})) 
      setCustomerNumber(e.target.value)
      setCustomerGroup('')
      //dispatch(getLayoutAsync(e.target.value))
      setCreateGroup(false);
      setCustomerNumberInp(true)
    
      setIsValidSaleCust(false); 
      
  }
  if (e.target.value === "") {
      setOrg(null)
      setRemoveDefaultOrg(false)
      setCustomerNumberInp(false)
      setIsValidSaleCust(true);
      setCreateGroup(false);
      setCustomerNumber("")
      dispatch(reSetFacilitiesData({}))
      dispatch(setFacilitiesData({}))
      setCustomerGroup('')
  }
}

const validateSalesCust = () =>{
    if(customerNumber == '' && customerGroup=='') {
      setIsValidSaleCust(true);
      setIsValidPdf(true);    
    }   
    else  {
      setIsValidSaleCust(false);
      setIsValidPdf(false);
  }
}


const getOrganization = () => {
  const customerNum = document.getElementById("customerNumber").value;
  setOrg([])
  setRemoveDefaultOrg(false)
  dispatch(setFacilitiesData({}))
  dispatch(reSetFacilitiesData({}))
  setCustomerNumber(customerNum)
  if(customerNum){
    dispatch(getLayoutAsync(customerNum))
  } 
 
  setCreateGroup(false);
  setCustomerNumberInp(true)
  //setCustomerGroup(false) 
  setShowExclude(false)
}


const getCustomerGroup = (e) => {
  dispatch(setAutoSeqNumber({}))
  if (e.target.value !== "") {
    const payload = {sequenceNumber: "",layoutType:'filter',groupType:e.target.value}
      dispatch(getAutoSeqNumber({payload}))	
      setCustomerGroup(e.target.value)
      setCreateGroup(true);
      //assignForm.current.resetSelectedValues();
      document.getElementById('customerNumber').value = ""
      setErrorAssign(false)
      setRemoveDefaultOrg(false)
      setIsValidSaleCust(false); 
      setShowExclude(true)
      setCustomerCodeSuggestions([])
      setCustomerCodeTag([])
  } else {
      setCustomerGroup("")
      setCreateGroup(false);
      // setIsValidSaleCust(true); 
      setShowExclude(false)
  }
}

let optionData = [];
    const soldToFacilities = facilitiesDatas?.data?.facilities?.filter((f) => f.accountGroup === '0001')
    soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
        const shipToFacilities = facilitiesDatas?.data?.facilities.filter((f) => f.accountGroup === '0002' && f.soldToNumber === soldToFacility.facilityNumber);
        if (shipToFacilities.length === 0) {
            optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(soldToFacility),
                facilityNumber: soldToFacility.facilityNumber,
                soldToFacility: 'soldToFacility'
            })
        }
        shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
            optionData.push({
                cat: getFacilityLabel(soldToFacility),
                key: getFacilityLabel(shipToFacility),
                facilityNumber: shipToFacility.facilityNumber,
                shipToFacilities: 'shipToFacilities'
            })
        })
    })


const assignedFacilities = () => {
  const facilities = [];
  layoutRowData?.organizationDetailInfo?.soldToNumbers?.forEach((soldTo) => { //NOSONAR
      const shipToFacilities = facilitiesDatas?.data?.facilities?.filter((f) => soldTo.shipToNumbers?.includes(f.facilityNumber)) //NOSONAR
      shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
          facilities.push(<div className={`col-form-label ${style.content_spec}`}>
              {getFacilityLabel(shipToFacility)}
          </div>);
      });
  }) //NOSONAR
  return facilities;
}
const defaultFacilities = () => {
const facilities = [];
layoutRowData?.organizationDetailInfo?.soldToNumbers?.forEach((soldTo) => { //NOSONAR
    const soldToFacility = facilitiesDatas?.data?.facilities?.find((f) => f.facilityNumber === soldTo.soldToNumber) //NOSONAR
    let soldToFacilityLabel, shipToFacilityLabel;
    if (soldToFacility) {
        soldToFacilityLabel = getFacilityLabel(soldToFacility);
    }
    const shipToFacilities = facilitiesDatas?.data?.facilities?.filter((f) => soldTo.shipToNumbers.includes(f.facilityNumber)) //NOSONAR
    if (shipToFacilities && shipToFacilities.length > 0) {
        shipToFacilities.forEach((shipToFacility) => {
            shipToFacilityLabel = getFacilityLabel(shipToFacility);
            facilities.push({ cat: soldToFacilityLabel, key: shipToFacilityLabel });
        });
    }
}) //NOSONAR
return facilities;
}

const [org, setOrg] = useState(defaultFacilities);

 /* exclude options */
 const excludeNumberSearch = useSelector(state => state.layouts.excludeNumberSearch);

 const customerCodeTagsRef = useRef();   
 const [customerCodeTag, setCustomerCodeTag] = useState([]);
 
 const [customerCodeSuggestions, setCustomerCodeSuggestions] = useState([]);
 
 const [suggestionsBusy, setSuggestionsBusy] = useState(false);
 
 const [showExclude, setShowExclude] = useState(false);
 
 const onDelete = useCallback(
 (tagIndex) => {
  
 const finalTag=customerCodeTag.filter((_, i) => i !== tagIndex);
 setCustomerCodeTag(finalTag); 
 
 },
 [customerCodeTag]
 );
 
 const onAddition = useCallback(
 (newTag) => {
  const ids = customerCodeTag.map(({ id }) => id);
  if (!ids.includes(newTag.id)) {
    const finalTag=[...customerCodeTag, newTag];
    setCustomerCodeTag(finalTag);
    }
 },
 [customerCodeTag]
 );
 
 //onInput
 const onInputExclude  = useCallback(
   (query) => {
    if(!suggestionsBusy&&query&&customerGroup){
      setSuggestionsBusy(true)
      dispatch(getCustomerNumberSuggestionsLayout(query,setSuggestionsBusy,customerGroup))
    }
   },
   [customerCodeTag]
   );
 
   useEffect(() => {
     if(Array.isArray(excludeNumberSearch)){
       const getExcludeNumber=[]
       excludeNumberSearch.map((excludeNumber,ind) =>{//NOSONAR
         return getExcludeNumber.push({ id: excludeNumber, name: excludeNumber })
        })//NOSONAR
        setCustomerCodeSuggestions(getExcludeNumber)
     }else{
       setCustomerCodeSuggestions([])
     }
 
     
     }, [excludeNumberSearch])


     const [tinyDelay, setTinyDelay] = useState(false)

     useEffect(() => {
       setTimeout(() => {
         setTinyDelay(true)
       }, 2500)
     }, [])
  return <div>
    <div className={style.tab_pane}>
      <div className={`d-flex justify-content-between ${style.container_wrapper}`}>
        <div className={style.heading}>{layoutRowData?.name}</div>
        {tinyDelay&&!editable && <div className={style.edit_icon} onClick={() => editMode()}><img src={editIcon} alt="icon" /> Edit</div>
        }
      </div>
      {!tinyDelay ?<div className={`${'loader'} col-12`}>
				<img src={Loader} alt="loading" />
			  </div>:
<>
      {
        !editable ? 
          <div className="col-12 p-4">
            {updateStatus === SUCCESS_STATUS && <div className={`alert alert-success d-inline ${style.form_alert}`}>{UPDATE_SUCCESS}</div>}
            {updateStatus === ERROR_STATUS && <div className={`alert alert-danger ${style.form_alert}`}>{UPDATE_FAILURE}</div>}
            <div className={`row`}>

              <div className={`col-6`}>
                <div className={`col-12`}>
                <div className={`row`}>
                  <label className={`col-6 ${style.label_cutom}`} >Name:</label>
                  <div className={`col-6 ${style.data_color}`}>
                    {
                      layoutRowData && layoutRowData.name
                    }
                  </div>
                </div>
              </div>

              <div className={`col-12 mt-5 mb-3`}>                
                  <div className={`row`}>
                    <label className={`col-6 ${style.label_cutom}`} >Type:</label>
                    <div className={`col-6 ${style.data_color}`}>
                      {
                        layoutRowData && layoutRowData.type && 'Card'
                      }
                    </div>
                  </div>                
              </div>
              <div className={`col-12 mt-5 mb-3`}>                
                  <div className={`row`}>
                    <label className={`col-6 ${style.label_cutom}`} >Preset Location:</label>
                    <div className={`col-6 ${style.data_color}`}>
                      {layoutRowData && layoutRowData.presetLocation}
                    </div>
                  </div>                
              </div>

              <div className={`col-12 mt-5 mb-3`}>                
                <div className={`row`}>
                  <label className={`col-6 ${style.label_cutom}`} >Sequence:</label>
                  <div className={`col-6 ${style.data_color}`}>
                    {layoutRowData && layoutRowData.sequence}
                  </div>
                </div>                
              </div>


            </div>

            <div className={`col-6`}>
              <div className={`col-12`}>
              <div className={`row`}>
                <label className={`col-6 ${style.label_cutom}`} >Sales Office:</label>
                <div className={`col-6 ${style.data_color}`}>
                  {
                    layoutRowData?.salesOffice ? SALES_OFFICE_OPTIONS.map(e => e.key == layoutRowData.salesOffice && e.value)  : '-'
                  }
                </div>
              </div>
              </div>

              <div className={`col-12 mt-5`}>
                <div className={`row`}>
                  <label className={`col-6 ${style.label_cutom}`} >Exclude:</label>
                  <div className={`col-6 ${style.data_color}`}>
                    {
                      layoutRowData && Array.isArray(layoutRowData.excludedCustomers)&&layoutRowData.excludedCustomers.length>0 ? layoutRowData.excludedCustomers.join(', '): '-'
                    }

                  </div>
                </div>
              </div>

              <div className={`col-12 mt-5`}>
                <div className={`row`}>
                  <label className={`col-6 ${style.label_cutom}`} >Customer Number:</label>
                  <div className={`col-6 ${style.data_color}`}>
                    {
                      layoutRowData && layoutRowData.customerInfo ? layoutRowData.customerInfo : '-'
                    }
                  </div>
                </div>
              </div>
              
              <div className={`col-12 mt-5`}>                     
                <div className={`row`}>
                  <label className={`col-6 ${style.label_cutom}`} >Organization:</label>
                    <div className={`${style.org_view}`}>
                      {assignedFacilities().length > 0 ? assignedFacilities() : '-'}
                    </div>
                </div>         
              </div>

            </div>
          </div> 
        </div>
        :
        <div className="col-12 p-4">
          <Form formChanges={true} onSubmit={handleSubmit(doCardEdit)}>
            {Object.keys(errors).length !== 0 && <div className="col-12 pl-0"><span className={style.general_error}>{GENERAL_ERROR}</span></div>}
            <div className={`row`}>
              <div className={`col-6`}>
                <div className={`col-12 px-0`}>
                  <TextField
                    id={'filterName'}
                    classes={{
                      column: `row mb-5`,
                      labelClass: `col-6 ${style.label_cutom}`,
                      inputColumn: 'col-6',
                      inputClassName: `${style.input_field}`
                    }}
                    label={'Name:'}
                    type={"text"}
                    name={'filterName'}
                    defaultValue={layoutRowData && layoutRowData.name}
                    validator={register(checkName)}
                    required={true}
                    errors={errors}
                  />
                </div>
                
                <div className={`col-12 pb-4 pt-3 px-0`}>
                  <div className={`row`}>
                    <label className={`col-6 ${style.label_cutom}`} >Type:</label>
                    <div className={`col-6 ${style.data_color}`}>
                      {layoutRowData && layoutRowData.type && 'Card'}
                    </div>
                  </div>
                </div>
                <div className={`col-12 my-4 px-0`}>
                  <div className={`row`}>
                    <label className={`col-6 ${style.label_cutom}`} >Preset Location:</label>
                    <div className={`col-6 ${style.data_color}`}>
                      {layoutRowData && layoutRowData.presetLocation}
                    </div>
                  </div>
                </div>
                <div className={`col-12 mt-5`}>
                  <TextField
                    classes={{
                      column: `row`,
                      labelClass: `col-6 p-0 ${style.label_cutom}`,
                      inputColumn: 'col-6 pl-3 pr-0',
                      inputClassName: `${style.small_input} ${style.input_field}`
                    }}
                    id={LIST_VIEW_MENU_SEQUENCE_ID}
                    label={SEQUENCE}
                    type={"number"}
                    name={'filterSequence'}
                    validator={register(sequenceNumber)}
                    required={true}
                    errors={errors}
                    defaultValue={updateSeq}
                    // customError={updateStatus && updateStatus.errorMessage ? updateStatus.errorMessage : ""}
                  />
                </div>
              </div>

              <div className={`col-6`}>
                <div className={`col-12 p-0`}>                    
                  <SelectBox
                    id={'salesOffice'}
                    name={'filterSalesOffice'}
                    label={'Sales Office:'}
                    classes={{
                      column: `row ${style.col_spec}`,
                      inputClassName: `${style.input_field} ${style.first_child}`,
                      labelClassName: `col-5 col-form-label mt-0 ${style.label_cutom}`,
                      inputColumn: `col-7 p-0 `
                    }}
                    placeholder="Select"
                    optionValue={SALES_OFFICE_OPTIONS}
                    // validator={register(checkSalesOffice)}                  
                    errors={errors}
                    onChange={(e) => getCustomerGroup(e)}
                    defaultValue={layoutRowData && layoutRowData.salesOffice}
                    disabled={document.getElementById("customerNumber")?.value ? true : false}
                    required={true}
                  />                    
                </div>
            {showExclude&&
              <div className={`row mt-3`}>
                <div className="col-5">
                  <label className={`mb-0 ${style.label_cutom}`} >Exclude :</label>
                </div>
              <div className={`col-5 p-0`}>
              <ReactTags
                    ref={customerCodeTagsRef}
                    tags={customerCodeTag}
                    suggestions={customerCodeSuggestions}
                    onDelete={onDelete}
                    onAddition={onAddition}
                    onInput={onInputExclude}
                    classNames={{ root: `${style.root_tag}` }}
                    placeholderText={"Customer Number"}
                    allowBackspace={false}
                    readOnly={false}
                    maxSuggestionsLength ={10}
                    minQueryLength ={1}
                    />
                    {excludeNumberSearch===null&&Array.isArray(customerCodeSuggestions)&&<><p>No data found</p></>}
                </div>
              </div>}
              
              <div className={`col-6 col-form-label py-1 mt-4 ${style.label_cutom} ${style.label_OR}`}>
                <div className={'row'}>
                  <strong>OR</strong>
                </div>
              </div> 

              <div className={`col-12 mt-0 p-0`}>                              
                <TextField
                  id={"customerNumber"}
                  name={"customerNumber"}
                  label={"Customer Number:"}
                  classes={{
                    column: `row ${style.col_spec}`,
                      inputClassName: `${style.input_field} ${style.first_child} ${createGroup ? style.disable_field : ''}`,
                      labelClass: `col-5 col-form-label mt-0 ${style.label_cutom}`,
                      inputColumn: `col-6 p-0 `
                  }}
                  type="text"
                  // validator={register()}
                  errors={errors}
                  onKeyUp={(e) => enterCustomerNumber(e)}
                  disabled={createGroup ? true : false}
                  defaultValue={layoutRowData && layoutRowData.customerInfo}
                  required={true}
                />
                
                  <span className={`col-3 col-form-label ${style.form_span} ml-3`} onClick={() => getOrganization()}>
                                      Search <img src={rightArrow} alt="" className={`${style.img_left}`}  />
                  </span>                  
              </div>
              {/* <br />  */}

                <div className={`col-12 py-4 px-0`}>
                                      
                    {<Organizational 
                        id={"organization"}
                        name={"organization"}
                        label={`Organization:`}
                        classes={{
                            column: `row ${style.col_spec}`,
                            inputClassName: `${style.input_field} ${style.first_child}`,
                            labelClassName: `col-5 col-form-label ${style.label_cutom} onselect`,
                            inputColumn: `col-7 p-0  ${style.org_input} `
                        }}
                        optionValue={optionData}
                        groupBy="cat"
                        displayValue="key"
                        //defaultValue={removeDefaultOrg ? defaultFacilities() : ''}
                        defaultValue={removeDefaultOrg ? defaultFacilities() :Array.isArray(org) ? org : []}
                        onSelect={onChangeOrg}
                        onRemove={onChangeOrg}
                        assignPage={true}
                        disabled={createGroup ? true : false}
                        required={true}
                        selectAll={true}  
                    />}

                  { isValidSaleCust&&<span className={`${style.error_message} ${style.error_CustNum} text-center`}>{
                  'Please select customer group selection or customer number and organization' }
                  </span> }
                
                </div>
              </div>
            </div>           

            

            <div className={style.btn_container}>
              <Button
                id={"creat_list_view_menu"}
                type={SUBMIT_ID}
                value={SAVE_BTN}
                classes={{ className: `btn mb-1 ${style.btn_save}` }}
                onClick={() => validateSalesCust()}
              />
              <Button
                id={'cancel'}
                type={"button"}
                value={'Cancel'}
                classes={{ className: `btn mb-1 ${style.btn_cancel}` }}
                onClick={() => viewMode()}
              />
            </div>
          </Form>
        </div>
      }
      </>}
    </div>
  </div>
}
EditCrad.propTypes = {
  layoutRowData: PropTypes.any,
  layoutId: PropTypes.any
}
