export const onSubmitCreateProductData = (data,productManagerVal,dispatch,createProductFamilyAsync,setProductModalOpen,setMsgModal,resMsgState) => {
  const payload ={
        productDivisionCodeId:(data.division==="Advanced Skin Care (80)")?80:(data.division==="Advanced Wound Care (71)")?71 : (data.division==="Nutrition and Pharmaceuticals (14)")?14 :(data.division==="Repositioning and Offloading (75)")?75:'',
        productFamilyCode:data.productFamilyCode,
        productFamilyDesc:data.productFamilyDescription,
        productCategoryCodeId:data.productCategoryCode,
        productManager:productManagerVal
  }
  dispatch(createProductFamilyAsync(payload)).then(
    () => {
      setProductModalOpen(false);
      setMsgModal(true);
    }
  )
   
}
export const onSubmitCreateMaterialData = (data,dispatch,createMaterialAsync,setMaterialModalOpen,setCreateMsgModal) => {
  const payload ={
    productDivisionCodeId:(data.division==="Advanced Skin Care (80)")?80:(data.division==="Advanced Wound Care (71)")?71 : (data.division==="Nutrition and Pharmaceuticals (14)")?14 :(data.division==="Repositioning and Offloading (75)")?75:'',
    materialNumber:data.materialNumber,
    materialDescription:data.materialDescription,
    materialGroupCodeId:data.materialGroupCode,
    productCategoryCodeId:data.productCategoryCode,
    productFamilyCodeId:data.productFamilyCode,
    manufacturerNameId:data.manufacturer,
    salesUOMCodeId:data.unit,
    salesUOMNumeratorQuantity:data.quantity === "" || data.quantity ===null ? 0 : data.quantity,
    gTINNumber:data.gtin,
    uPCCode:data.upc,
    hCPCScode:data.hcpsc,
    materialStatusCodeId:data.materialStatus
  }

  dispatch(createMaterialAsync(payload)).then(
    () => {
        setMaterialModalOpen(false);
        setCreateMsgModal(true);
    }
)
   
}

export const onSubmitProductData = (data, productData, productManagerVal,productType, productId, dispatch, updateProductFamilyAsync) => {
  let payload ={}
  if(productData.isEditable===0){
   payload ={
       // productDivisionCodeId:productData.productDivisionCodeId,
        productDivisionCodeId:(data.division==="Advanced Skin Care (80)")?80:(data.division==="Advanced Wound Care (71)")?71 : (data.division==="Nutrition and Pharmaceuticals (14)")?14 :(data.division==="Repositioning and Offloading (75)")?75:'',
        productFamilyCode:productData.productFamilyCode,
        productFamilyDesc:productData.productFamilyDesc,
       // productCategoryCodeId:productData.productCategoryCodeId,
       productCategoryCodeId:data.productCategoryCode,
        productManager:productManagerVal,
        productType:productType,
        productId:productId
      }
  }else{
    payload ={
      productDivisionCodeId:(data.division==="Advanced Skin Care (80)")?80:(data.division==="Advanced Wound Care (71)")?71 : (data.division==="Nutrition and Pharmaceuticals (14)")?14 :(data.division==="Repositioning and Offloading (75)")?75:'',
      productFamilyCode:data.productFamilyCode,
      productFamilyDesc:data.productFamilyDescription,
      productCategoryCodeId:data.productCategoryCode,
      productManager:productManagerVal,
      productType:productType,
      productId:productId
    }
  }
      dispatch(updateProductFamilyAsync(payload));
}

export const onSubmitMaterialData = (data, productData, productType, productId, dispatch, updateMaterialAsync) => {
  let payload={};
  if(productData.isEditable===0){  
   payload ={
        productDivisionCodeId:productData.productDivisionCodeId,
        materialNumber:productData.materialNumber,
        materialDescription:productData.materialDescription,
        materialGroupCodeId:productData.materialGroupCodeId,
        productCategoryCodeId:productData.productCategoryCodeId,
        productFamilyCodeId:productData.productFamilyCodeId,
        manufacturerNameId:productData.manufacturerNameId,
        salesUOMCodeId:productData.salesUOMCodeId,
        salesUOMNumeratorQuantity:productData.salesUOMNumeratorQuantity,
        gtinNumber:productData.gtinNumber,
        upcCode:productData.upcCode,
        hcpcScode:productData.hcpcScode,
        
        materialStatusCodeId:data.materialStatus,
        productType:productType,
        productId:productId
      }
  }else{
    payload ={
      productDivisionCodeId:(data.division==="Advanced Skin Care (80)")?80:(data.division==="Advanced Wound Care (71)")?71 : (data.division==="Nutrition and Pharmaceuticals (14)")?14 :(data.division==="Repositioning and Offloading (75)")?75:'',
      materialNumber:data.materialNumber,
      materialDescription:data.materialDescription,
      materialGroupCodeId:data.materialGroupCode,
      productCategoryCodeId:data.productCategoryCode,
      productFamilyCodeId:data.productFamilyCode,
      manufacturerNameId:data.manufacturer,
      salesUOMCodeId:data.unit,
      salesUOMNumeratorQuantity:data.quantity,
      gtinNumber:data.gtin,
      upcCode:data.upc,
      hcpcScode:data.hcpsc,
      materialStatusCodeId:data.materialStatus,
      productType:productType,
      productId:productId
    }
  }
      dispatch(updateMaterialAsync(payload));
}

export function handleFirstMove(eventVal, reset, getValues, dispatcher){  
  reset({ ...getValues(), woundClassification: '' })
  if (eventVal === 'No') {
      dispatcher(0, 0)
  }
  if (eventVal === 'Yes') {
      dispatcher(1, 0)
  } 
}

export function enableCheckerMove(woundClassificationOptions, setEnabled){
  let temp = false;
  woundClassificationOptions && woundClassificationOptions.length > 0 && woundClassificationOptions.forEach((data) => {
      if (data.enable === 1) {
          setEnabled(true)
          temp = true;
      }
  })
  // If there is not even signle enabled fields, then set unableClassify as 'false'
  if (!temp) {
      setEnabled(false)
  }
}

export const clearFileInputMove = (radioOption, setDisplayName) => {     
  if (radioOption === 'Browse') {
      if(document.getElementById('pdf')){                
          document.getElementById('pdf').value = ''
          setDisplayName(null)
      }
  }
}

export function getBase64Move(file) {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });
}

export function pdfValidatorMove(file) {
  // Logic to check image size
  if (parseInt(file.size / 1024) > 20480) {
      return false
  }
  else {
      return true
  }
}

export const fixBaseCodeMove = (data) => {
  let fixed;
  if (data.search('data:application/pdf;base64') !== -1) {
      fixed = data.replace('data:application/pdf;base64,', '')
  }
  return fixed;
}

export const fileTypeCheckerMove = (e) => {
  if(e.type&&e.type === 'application/pdf'){
      return true
  }else{
      return false
  }
}   

export const handleDocChangeMove = (e, fileTypeChecker, setServerError, clearFileInput, addedList, setAddedList, pdfValidator, setDisplayName, getBase64, fixBaseCode, setDocumentError) => {
  if(!fileTypeChecker(e.target.files[0])){
      setServerError("Please upload only PDF file")
      setTimeout(() => {
          setServerError(null)
      }, [5000])
      clearFileInput()
  }
  // Clear if anyother document is already added
  if (addedList && addedList?.length > 0) {
      setAddedList([])
  }
  e.preventDefault();
  const files = e?.target?.files
  let datas = []
  // Going trough each file and calling above getBase64 function.
  // Return promise will be handled here and stored in a variable, 
  // then that variable will be set in state
  // files.forEach((file, i) => {
  //     if (pdfValidator(file)) {    // If the document passes required condition, store the doc else throw error    
  //         setDisplayName(file.name)
  //         getBase64(file).then((data) => {
  //             const temp = [...datas]
  //             temp[i] = {
  //                 document: fixBaseCode(data),
  //                 fileName: file.name
  //             }
  //             datas = [...temp]
  //             setAddedList([...datas])
  //         });
  //     } else {
  //         e.target.value = '';
  //         setDocumentError(true)
  //         setTimeout(() => {
  //             setDocumentError(false)
  //         }, 5000)
  //     }
  // })
  if(files && files[0]){
    if (pdfValidator(files[0])) {    // If the document passes required condition, store the doc else throw error    
        setDisplayName(files[0]?.name)
        getBase64(files[0]).then((data) => {
            const temp = [...datas]
            temp[0] = {
                document: fixBaseCode(data),
                fileName: files[0]?.name
            }
            datas = [...temp]
            setAddedList([...datas])
        });
    } else {
        e.target.value = '';
        setDocumentError(true)
        setTimeout(() => {
            setDocumentError(false)
        }, 5000)
    }
  }
};

export const getBase64FromUrlMove = async (url, setUrlLoading, setServerError) => {
  setUrlLoading(true)
  setServerError(null)
  try {
      const data = await fetch(url);
  } catch (e) {
      setServerError("Content in this link doesn't have access to download")
      setUrlLoading(false)
      // setTimeout(() => {
      //     setServerError(null)
      // }, [7000])
  }
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
      }
  });
}

export const handleUrlMove = (e, addedList, setAddedList, getBase64FromUrl, setUrlLoading, fixBaseCode, getFileName) => {
  // Clear if anyother document is already added
  if (addedList && addedList?.length > 0) {
      setAddedList([])
  }
  setAddedList([{ document: e.target.value, fileName: null }])
  //setAddedList([{ document: e.target.value, fileName: getFileName(e.target.value) }])
  // getBase64FromUrl(e.target.value).then((res) => {
  //     setUrlLoading(false)
  //     setAddedList([{ document: fixBaseCode(res), fileName: getFileName(e.target.value) }])
  // })
}

export const handleVideoMove = (e, setVideoFetch, getFileName, setEmbedVideo, setServerError) => {
  if(e.target.value !== ''){            
      setVideoFetch(true)
      // Getting title from embed code. 
      // We are creating a div, and putting the value from textarea inside the div
      // Then using getElementsByTagName we are getting the title
      var div = document.createElement('div');
      div.innerHTML = e.target.value;
      const videoUrl = div?.getElementsByTagName("a")[0]?.getAttribute('href')
      const fileName = div?.getElementsByTagName("a")[0]?.textContent
      const fetchUrl = "https://vimeo.com/api/v2/video/" + getFileName(videoUrl) + ".json"
      fetch(fetchUrl)
          .then(response => response.json())
          .then(response => {
              setVideoFetch(false)
              setEmbedVideo([{ videoUrl: videoUrl, fileName: fileName, thumbnail: response[0].thumbnail_large }])
          })
          .catch(err => {
              setVideoFetch(false)
              setServerError("Please enter valid video embed code")
              setTimeout(() => {
                  setServerError(null)
              }, [5000])
          })
  }
  else{
      return
  }
}

export const handleDeleteMove = (id, documentList, dispatch, updateDocumentList, productId, productType, getDocs) => {
  const filtered = documentList['documents'].filter((data, i) => i !== id)
  let list;
  if (filtered && filtered.length === 0) {
      list = {
          documents: []
      }
  } else if (filtered && filtered.length > 0) {
      list = {
          documents: [...filtered]
      }
  }
  dispatch(updateDocumentList({ productId, productType, list, getDocs }))
}