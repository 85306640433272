import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField, Form } from '../../primary';
import { Link, withRouter } from 'react-router-dom';
import { checkResetNewPassword, ServiceError, commonHintCheck } from '../../../utils';
import { Banner } from '../../secondary/Banner';
import { Modal } from '../../secondary/Modal';
import { Logo } from '../../secondary/Logo';
import { link_style, subheading, form_shadow, title, medline_block, form_group, service_error,close_btn } from '../../../styles/common.module.scss';
import { PASSWORD_PHDR, CONFIRM_PASSWORD_REQUIRED } from '../../../constants';
import style from './updatePasswordForm.module.scss';
import { useDispatch, useSelector, connect } from 'react-redux';
import { updatePasswordAsync, logout } from '../../../app/userSlice';

import PropTypes from 'prop-types';
import { setAccessError, setError, clearError } from '../../../app/appSlice';
import closeErrorIcon from "./../../../static/images/Close_Normal.svg";

export function UpdatePasswordForm({ history }) {

  const { register, handleSubmit, watch, errors, reset } = useForm({ mode: "all" });
  const errorMessage = ServiceError();
  const passwordCheck = watch('updatePassword');
  const [minLength, setMinLength] = useState(false);
  const [oneSpecialChar, setOneSpecialChar] = useState(false);
  const [oneNum, setOneNum] = useState(false);
  const [oneUCase, setOneUCase] = useState(false);
  const [atleastOne, setAtleastOne] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(false);

  const passwordCondition = () => {
    commonHintCheck(passwordCheck, setMinLength, setOneUCase, setOneSpecialChar, setOneNum)
    if (checkUpdate) {
      if (!minLength || !oneSpecialChar || !oneNum || !oneUCase) {
        setAtleastOne(true)
      } else {
        setAtleastOne(false)
      }
    }
  }
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.user.loginUserId);
  const [updateStatus, setUpdateStatus] = useState(false);
  const updateSubmit = (data) => {
    const payload = {
      "id": userDetails.data && userDetails.data.userId ? userDetails.data.userId : '',
      "newpassword": data.updatePassword
    }
    dispatch(updatePasswordAsync(payload))
    if (userDetails.status === 'success') {
      reset();
      setUpdateStatus(true);
    }
  }
  const doLogout = () => {    
    dispatch(setError(''))
    dispatch(setAccessError(null))    
    dispatch(logout())
    history.push('/login');
  }

  const upDateButton = () => {
    if (!minLength || !oneSpecialChar || !oneNum || !oneUCase) {
      setAtleastOne(true)
    } else {
      setAtleastOne(false)
    }
    setCheckUpdate(true);
  }

  return <div className={`container-fluid ${medline_block}`}>
    <div className="row vh-100">
      <div className="col-sm-12 col-lg-6">
        <Logo />
        <div className="container pt-3">
          <div className="row">
            <div className={` col-12 col-sm-12  mx-auto pt-5 pb-5 ${form_shadow}`}>
              <div className="col">
                <p>
                  <span className={subheading}>Return to&#160;
                    <Link to="/login" className={link_style}>Login</Link>
                  </span>
                </p>
              </div>
              <div className="col-12">
                <h1 className={title}>Update Password</h1>
                <p className={subheading}>Enter a new password</p>
              </div>
              {/* {errorMessage && <div className={service_error}>{errorMessage}</div>} */}
              {errorMessage && (
                    <div className="row">
                    <div
                     
                      className={service_error}
                      dangerouslySetInnerHTML={{
                        __html:
                          errorMessage
                      }}
                    ></div>
                    <>
                      {
                         <button className={`${close_btn}`} onClick={()=>{dispatch(clearError())}}>
                          <img src={closeErrorIcon} alt="Close" />
                        </button>
                      }
                    </>
                  </div>
                  )}
              <Form className={"col-12 mx-auto"} onSubmit={handleSubmit(updateSubmit)}>
                <TextField
                  classes={{ column: 'form-group', inputClassName: `form-control mb-1`, inputColumn: 'col-12 p-0' }}
                  label={"New Password"}
                  type={"password"}
                  name={"updatePassword"}
                  id={"updatePassword"}
                  placeholder={PASSWORD_PHDR}
                  validator={register(checkResetNewPassword)}
                  onKeyUp={() => passwordCondition()}
                  errors={errors}
                  hints={true}
                  minLength={minLength}
                  oneSpecialChar={oneSpecialChar}
                  oneNum={oneNum}
                  oneUCase={oneUCase}
                  atleastOne={atleastOne}
                  required={true}
                />
                <TextField
                  classes={{ column: `form-group ${form_group}`, inputClassName: `form-control mb-1`, inputColumn: 'col-12 p-0' }}
                  label={"Re-type new password"}
                  type={"password"}
                  name={"confirmPassword"}
                  id={"confirmPassword"}
                  placeholder={PASSWORD_PHDR}
                  validator={register({
                    required: CONFIRM_PASSWORD_REQUIRED,
                    validate: (value) => value === watch('updatePassword') || "Password does not match"
                  })}
                  errors={errors}
                  required={true}
                />
                <div className="form-group pt-3">
                  <Button
                    id={"updateBtn"}
                    type={"submit"}
                    value={"Update Password"}
                    classes={{ className: `btn btn-block` }}
                    onClick={upDateButton}
                  />
                </div>
              </Form>
              {
                updateStatus && <Modal
                  modalOpen={'modalOpen'}
                  modalClose={'viewMode'}
                  modalSize={style.success_modal}
                >
                  <div className={`${style.parent} ${style.modal_contr}`}>
                    <div className={style.status_heading}>Success!</div>
                    <div className={style.msg_content}>Your password has been changed</div>
                    <div className={`text-center mt-2`}>
                      <Button
                        id={"logoutBtn"}
                        type={"button"}
                        value={"Continue to Login"}
                        classes={{ className: `btn mt-4 pl-4 pr-4 ${style.continue_btn}` }}
                        onClick={() => doLogout()}
                      />
                    </div>
                  </div>
                </Modal>
              }
            </div>
          </div>
        </div>
      </div>
      <Banner />
    </div>
  </div>
}
UpdatePasswordForm.propTypes = {
  history: PropTypes.object
}

UpdatePasswordForm = connect()(withRouter(UpdatePasswordForm));
