import React, { useState, useEffect } from 'react';
import { getDressingChangeFrequency, updateDressingChangeFrequency } from '../../../app/patientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './dressingChangeFrequency.module.scss';
import { EDIT, CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants';
import { Button, Form } from '../../primary';
import editIcon from '../../../static/images/edit-icon-blue.png';
import { useForm } from 'react-hook-form';
import { signedLockedAccess } from '../../../utils';
import { assessmentRbacChecker } from '../../../utils/rbac';

export const DressingChangeFrequency = ({ interventionId, assessmentDetails }) => {

  const { handleSubmit } = useForm();
  const dressChange = useSelector(state => state.patient.dressChangefrequency);
  const [newfrequence, setNewFrequence] = useState(dressChange);
  const [editable, setEditable] = useState(false);
  const [numValidator, setNumValidator] = useState(null);
  const patientData = useSelector(state => state.patient.detailViewData)
  const userDetails = useSelector(state => state.user);
  const hideButtons = assessmentRbacChecker(userDetails?.role, patientData?.isOwnCustomer);
  const handleEdit = () => {
    setEditable(true);
  }
  const handleCancel = () => {
    setEditable(false)
  }
  const dispatch = useDispatch();
  const { patientId, assessmentId } = useParams();
  const payload = {
    patientId: patientId,
    assessmentId: assessmentId,
    interventionId: interventionId
  }
  useEffect(() => {
    dispatch(getDressingChangeFrequency(payload))
  }, [dispatch])
  useEffect(() => {
    setNewFrequence(dressChange);
  }, [dressChange])
  const doSubmit = () => {
    setEditable(false);
    const payloadData = {
      patientId: patientId,
      assessmentId: assessmentId,
      interventionId: interventionId,
      updateData: newfrequence
    }
    dispatch(updateDressingChangeFrequency(payloadData));
  }
  const EditIcon = () => {
    return (
      !editable && signedLockedAccess(assessmentDetails?.assessmentStage) && <div className={`${styles.edit_icon}`}>
        <span className={`float-sm-right ${styles.tab_edit} handleEdit`} onClick={handleEdit} >
          <img src={editIcon} alt="edit" />{EDIT}
        </span>
      </div>
    )
  }
  const getFrequency = (e, ind, type) => {
    if (isNaN(e.target.value)) {
      setNumValidator(ind)
      e.target.value = null
      setTimeout(() => {
        setNumValidator(null)
      }, 2000)
    }
    else {
      setNumValidator(null)
    }
    let data = { ...newfrequence.data[type] }
    let catType = [...data.productCategory]
    let cat = { ...catType[ind] }
    cat.frequence = e.target.value;
    if( cat&&cat.frequenceType===null){
      cat.frequenceType='Days'
    }
    catType.splice(ind, 1, cat)
    data.productCategory = catType
    let update = { ...newfrequence.data }
    update[type] = data;
    setNewFrequence({ data: update });
  }
  const getFrequencyType = (e, ind, type) => {
    let data = { ...newfrequence.data[type] }
    let catType = [...data.productCategory]
    let cat = { ...catType[ind] }
    cat.frequenceType = e.target.value;
    catType.splice(ind, 1, cat)
    data.productCategory = catType
    let update = { ...newfrequence.data }
    update[type] = data;
    setNewFrequence({ data: update });
  }

  return (
    <div className={`col-md-12 ${styles.treatment_container}`}>
      {dressChange?.data?.primary?.productCategory?.length === 0 && dressChange?.data?.secondary?.productCategory?.length === 0 ? <div className={styles.nodata_container}>There are no Primary / Secondary Dressing selected for this assessment</div> :
        <Form formChanges={true} className="col-12 p-0" onSubmit={handleSubmit(doSubmit)}>
          <div className={`col-12 ${styles.col_spec} d-flex mb-2`} >
            {dressChange?.data?.primary?.productCategory?.length > 0 ? !editable && hideButtons ? <EditIcon /> : "" : ""}
            <label className={`col-4 p-0`}>Primary Dressing:</label>
            <div className="col-8 mb-4 pb-1 p-0">
              {
                dressChange?.data?.primary?.productCategory && dressChange?.data?.primary?.productCategory.length > 0 && dressChange?.data?.primary?.productCategory?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className={styles.edit_wrapper}>
                        <div className={`${styles.cat}`}>{item.productFamilyDesc}</div>
                        <div>{`${item.materialDescription} ${!editable ? item.frequence != null && item.frequence != undefined && item.frequence !== '' ? ` - ${item.frequence}` : '' : ''} 
                        ${!editable ?
                            item?.frequence !== null && item.frequence !== undefined && item.frequence !== '' ?
                              item?.frequenceType === null ? 'Hrs' : item?.frequenceType : '' : ''
                          }`}</div>
                      </div>
                      {editable ?
                        <>
                          <div className={`${styles.recom_text}`}>Recommended : {item.recommended}</div>
                          <div className={`${styles.treatment_text}`}>Dressing Change Frequency</div>
                          <div className={`d-flex`}>
                            <div className={`${styles.treatment_input}`}>
                              <input type="text" className="getFrequency" onBlur={(e) => getFrequency(e, index, 'primary')} defaultValue={item.frequence ? item.frequence : ''} />
                            </div>
                            <div className={`${styles.treatment_select}`}>
                              <select className="getFrequencyType" onChange={(e) => getFrequencyType(e, index, 'primary')} defaultValue={item.frequenceType ? item.frequenceType : ''}>
                                <option>Days</option>
                                <option>Hrs</option>
                              </select>
                            </div>
                          </div>
                          <div className={`d-flex ${styles.error_message}`}>
                            {numValidator === index ? "please Select a number" : null}
                          </div>
                        </> : ''
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className={`col-12 ${styles.col_spec} d-flex mb-2`} >
            {dressChange?.data?.secondary?.productCategory?.length > 0 ? !editable && hideButtons ? <EditIcon /> : "" : ""} 
            <label className={`col-4 p-0`}>Secondary Dressing:</label>
            <div className="col-8 p-0">
              {dressChange?.data?.secondary?.productCategory && dressChange?.data?.secondary?.productCategory.length > 0 && dressChange?.data?.secondary?.productCategory?.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={styles.edit_wrapper}>
                      <div className={`${styles.cat}`} key={index}>{item.productFamilyDesc} </div>
                      <div>{`${item.materialDescription} ${!editable ? item.frequence != null && item.frequence != undefined && item.frequence !== '' ? ` - ${item.frequence}` : '' : ''} 
                        ${!editable ?
                          item?.frequence !== null && item.frequence != undefined && item.frequence !== '' ?
                            item?.frequenceType === null ? 'Hrs' : item?.frequenceType : '' : ''
                        }`}</div>
                    </div>
                    {editable ? <>
                      <div className={`${styles.recom_text}`}>Recommended : {item.recommended}</div>
                      <div className={`${styles.treatment_text}`}>Dressing Change Frequency</div>
                      <div className={`d-flex`}>
                        <div className={`${styles.treatment_input}`}>
                          <input type="text" className="getFrequencySec" onBlur={(e) => getFrequency(e, index, 'secondary')} defaultValue={item.frequence ? item.frequence : ''} />
                        </div>
                        <div className={`${styles.treatment_select}`}>
                          <select className="getFrequencyTypeSec" onChange={(e) => getFrequencyType(e, index, 'secondary')} defaultValue={item.frequenceType ? item.frequenceType : ''}>
                            <option>Days</option>
                            <option>Hrs</option>
                          </select>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.error_message}`}>
                        {numValidator === index ? "please Select a number" : null}
                      </div>
                    </> : ''
                    }
                  </div>
                )
              })}
            </div>
          </div>
          {editable && hideButtons ?
            <div className="col-12 pt-2 mt-5 pl-1">
              <div className={`offset-4`}>
                <Button
                  classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                  type="submit"
                  id={SUBMIT_ID}
                >
                  {SAVE_BTN}
                </Button>
                <Button
                  classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} handlecancel` }}
                  id={CANCEL_ID}
                  onClick={handleCancel}
                >
                  {CANCEL_BTN}
                </Button>
              </div>
            </div> : ''
          }
        </Form>}
    </div>
  )
}
