import { createSlice } from '@reduxjs/toolkit';
import { DASHBOARD_ANALYTICS, ERROR_STATUS, SUCCESS_STATUS } from '../constants';
import { Service } from '../service';
import { constructEndpoint, fullMonthFormatDate,convertnewDateToDate } from '../utils/commonHelper';
import { setError, setLoading } from './appSlice';
import { subDays } from 'date-fns';
const { request } = Service;

export const usageAnalyticsReportSlice = createSlice({
  name: 'usageAnalyticsReport',
  initialState: {
    analyticsOverViewData: {},
    analyticsUserDetails: {},
    analyticsDownloaddetails: {},
    customerFacilities: {},
    analyticsAllUserDetails:{},
    isUserDownloadReady:'',
    treatmentCustomerFacilities:{}
  },
  reducers: {
    setCustomerFacilities: (state, action) => {
      state.customerFacilities = action.payload
    },
    setAnalyticsOverViewData: (state, action) => {
      state.analyticsOverViewData = action.payload
    },
    setAnalyticsUserDetails: (state, action) => {
      state.analyticsUserDetails = action.payload
    },
    setAnalyticsAllUserDetails: (state, action) => {
      state.analyticsAllUserDetails = action.payload
    },
    setAnalyticsDownloaddetails: (state, action) => {
      state.analyticsDownloaddetails = action.payload
    },
    setIsUserDownloadReady: (state, action) => {
      state.isUserDownloadReady = action.payload
    },
    setTreatmentCustomerFacilities: (state, action) => {
      state.treatmentCustomerFacilities = action.payload
    },
  }
})

export const {
  setAnalyticsOverViewData,
  setAnalyticsUserDetails,
  setAnalyticsAllUserDetails,
  setAnalyticsDownloaddetails,
  setCustomerFacilities,
  setIsUserDownloadReady,
  setTreatmentCustomerFacilities
} = usageAnalyticsReportSlice.actions;

export const getFaciltiestCustomerAsyn = (payload, firstLoad,setTempPayload) => async dispatch => {
  dispatch(setCustomerFacilities([]))
  try {
    dispatch(setLoading(true));
    let queryStr = '/listoptions/facility'
    if (payload) {
      queryStr = '/facilities?customerNumber=' + payload
    }
    const data = await request(`${queryStr}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      await dispatch(setCustomerFacilities(data))
      if(firstLoad==='firstLoadUsageAnalytics'){
         defaultUsageAnalysis(data,dispatch,setTempPayload)
         dispatch(setLoading(true));
      }   
       
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setCustomerFacilities(obj))
  }
  finally {
    dispatch(setLoading(false));
  }
}
export const defaultUsageAnalysis=(facilitiesData,dispatch,setTempPayload )=>{
  if (facilitiesData !== '') {
    let optionData = [];
    const soldToFacilities = facilitiesData?.data?.facilities?.filter((f) => f.accountGroup === '0001')
    soldToFacilities && soldToFacilities.forEach((soldToFacility) => {
      const shipToFacilities = facilitiesData.data.facilities.filter((f) => (f.accountGroup === '0002') && (f.soldToNumber === soldToFacility.facilityNumber));
      if(shipToFacilities.length===0){
        optionData.push(soldToFacility.facilityNumber)
      }
      shipToFacilities && shipToFacilities.forEach((shipToFacility) => {
        optionData.push(shipToFacility.facilityNumber)
      })
    })
    const loadData = {
      fromDate: convertnewDateToDate(subDays(new Date(), 30)),
      toDate: convertnewDateToDate(new Date()),
      facility: optionData,
    }
    //Default load data
   // if(optionData.length>0){
      dispatch(getAnalyticsOverview(loadData))
    //}
  
    setTempPayload(loadData)
    dispatch(setLoading(true));
  }
}
export const getAnalyticsOverview = (payload, queryParam, history) => async dispatch => {
  let queryStr = ''
 
  if (queryParam) {
    queryStr = '?' + queryParam
  }else{
    dispatch(setAnalyticsOverViewData({}));
    dispatch(setAnalyticsUserDetails({}));
    dispatch(setAnalyticsAllUserDetails({}));
    dispatch(setAnalyticsDownloaddetails({}));
  }
  try {
    dispatch(setLoading(true));
    const data = await request(`${DASHBOARD_ANALYTICS}/overview` + queryStr, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setAnalyticsOverViewData(data.data.overview));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  }
  catch (error) {
    dispatch(setAnalyticsOverViewData({}));
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const getAnalyticsUserDetails = (payload, queryParam, allDetails) => async dispatch => {
  let queryStr = ''
  if (queryParam) {
    queryStr = '?' + queryParam
  }
  try {
    dispatch(setLoading(true));
    const data = await request(`${DASHBOARD_ANALYTICS}/userdetails` + queryStr, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      data.data.userDetails.map((value) => {
        value.lastLogin = (value.lastLogin) ? fullMonthFormatDate(value.lastLogin) : '';
      })
      if(allDetails==="allDetails"&&queryParam){
        dispatch(setAnalyticsAllUserDetails(data.data));
        dispatch(setIsUserDownloadReady('downloadAllUser'));
        //setIsUserDownloadReady
      }else{
        dispatch(setAnalyticsUserDetails(data.data));
        dispatch(setIsUserDownloadReady(''));
      }
      
    }
    if (data && data.status === ERROR_STATUS) {
    }
  }
  catch (error) {
    if(allDetails==="allDetails"&&queryParam){
      dispatch(setAnalyticsAllUserDetails({}));
      dispatch(setIsUserDownloadReady(''));
    }else{
      dispatch(setAnalyticsUserDetails({}));
    }
   
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}

export const getAnalyticsDownloadDetails = (payload, history) => async dispatch => {
  try {
    dispatch(setLoading(true));
    const data = await request(`${DASHBOARD_ANALYTICS}/downloaddetails`, {
      method: 'POST',
      body: JSON.stringify(payload)
    });
    if (data && data.status === SUCCESS_STATUS) {
      dispatch(setAnalyticsDownloaddetails(data.data.downloadDetails));
    }
    if (data && data.status === ERROR_STATUS) {
    }
  }
  catch (error) {
    dispatch(setAnalyticsDownloaddetails({}));
    const obj = { ...error }
    /* If there are error, dispatch setError action */
    dispatch(setError(obj.errorMessage))
  }
  finally {
    dispatch(setLoading(false));
  }
}
//Treatment Report
export const getTretmentFaciltiestCustomerAsyn = (payload, firstLoad,setTempPayload) => async dispatch => {
  dispatch(setTreatmentCustomerFacilities([]))
  try {
    dispatch(setLoading(true));
    let queryStr = '/listoptions/facility'
    if (payload) {
      queryStr = '/facilities?customerNumber=' + payload
    }
    const data = await request(`${queryStr}`, {
      method: 'GET',
    });
    if (data && data.status === SUCCESS_STATUS) {
      await dispatch(setTreatmentCustomerFacilities(data))
      if(firstLoad==='firstLoadUsageAnalytics'){
         defaultUsageAnalysis(data,dispatch,setTempPayload)
         dispatch(setLoading(true));
      }   
       
    }
  }
  catch (error) {
    /* If there are error, dispatch setError action */
    const obj = { ...error }
    dispatch(setError(obj.errorMessage))
    dispatch(setTreatmentCustomerFacilities(obj))
  }
  finally {
    dispatch(setLoading(false));
  }
}
export default usageAnalyticsReportSlice.reducer;
