export default class ErrorResponse extends Error {
    constructor({ method, resourceUrl, response, bodyText }, ...args) {
        let body = ``, msg,finalErrorRes;
        try {
            const { message, trace, ...rest } = JSON.parse(bodyText);
            if (message) {
                body += `Message:\n\n  ${message}\n`;
                msg = message;
            } else {
                body += 'Message:\n\n  Unknown error occurred\n';
                msg = 'Unknown error occurred';
            }
            const addl = Object.entries(rest);
            if (addl.length > 0) {
                body += `\nAdditional info:\n\n${JSON.stringify(
                    rest,
                    null,
                    4
                )}\n\n`;
                if(rest){
                    finalErrorRes=`${JSON.stringify(rest)}`
                }
            }
            if (trace) {
                body += `Error stack trace: \n\n${trace}`;
            }
            body += '\n';
        } catch (e) {
            body = bodyText;
            msg = 'Unknown error occurred';
        }
        super(
            `${method} ${resourceUrl} responded ${response.status} ${
                response.statusText
            }: \n\n${body}`,
            ...args
        );
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ErrorResponse);
        }
        this.errorMessage = msg;
        this.response = response;
        this.method = method;
        this.resourceUrl = resourceUrl;
        this.finalErrorRes=finalErrorRes;
    }
}
