import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { getPIAssessmentsPOAAsync } from '../../../app/workListSlice';
import { LIST_VIEW_LIMIT, PI_ASSESSMENT_POA } from '../../../constants';
import { DataTable, Pagination } from '../../secondary';
export function PIAssessmentsPOA({ dispatch, history, facilityNumber }) {
    const [activePage, setActivePage] = useState(1);
    const [offSet, setOffSet] = useState(0)
    const [sortType, setSortType] = useState('')
    const userDetails = useSelector(state => state.user);
    const getWorkListPIAssessmentPOA = () => {
        const listPayLoad = {};
        listPayLoad['url'] = PI_ASSESSMENT_POA
        listPayLoad['method'] = 'Get'
        listPayLoad['queryParam'] = { offSet: 0, limit: LIST_VIEW_LIMIT, facilityNumber:facilityNumber }
        dispatch(getPIAssessmentsPOAAsync(listPayLoad))
    }
    useEffect(() => {
        getWorkListPIAssessmentPOA();
    }, [dispatch, facilityNumber]);
    const piAssessmentPOAData = useSelector(state => state.worklists.piAssessmentPOAData)

    const columns = [
        // {
        //     Header: 'Patient Profile Photo',
        //     accessor: 'profilePicture',
        //     imageData: 'picture',
        //     restWidth: '165px',
        //     disableSortBy: true,
        // },
        {
            Header: 'MRN #',
            accessor: 'mrn',
        },
        {
            Header: 'First name',
            accessor: 'firstName',
        },

        {
            Header: 'Last name',
            accessor: 'lastName',
        },
        {
            Header: 'Age',
            accessor: 'age',
            restWidth: '150px'
        },
        {
            Header: 'DOB',
            accessor: 'dob',
            restWidth: '180px'
        },
        {
            Header: 'Admission date',
            accessor: 'admissionDate',
            restWidth: '250px'
        },
        {
            Header: 'Discharge date',
            accessor: 'dischargeDate',
            restWidth: '250px'
        },
        {
            Header: 'Wound photo',
            accessor: 'woundPicture',
            imageData: 'picture',
            disableSortBy: true,
        },
        {
            Header: 'Wound name',
            accessor: 'woundName',
            restWidth: '450px'
        },

        {
            Header: 'Re-Eval counter label',
            accessor: 'reEvalCount',
            restWidth: '200px'
        },
        {
            Header: 'Workflow status',
            accessor: 'assessmentStage',
            restWidth: '200px'
        },
        {
            Header: 'History/Cause',
            accessor: 'cause',
            restWidth: '250px'
        },
        {
            Header: 'Anatomy',
            accessor: 'anatomy',
            restWidth: '200px'
        },
        {
            Header: 'Photo taken by',
            accessor: 'identifiedBy',
            restWidth: '200px'
        },
        {
            Header: 'Last assessed',
            accessor: 'lastAssessedDate',
            restWidth: '250px'
        },
        {
            Header: 'Wound acquired',
            accessor: 'woundAcquired',
            restWidth: '150px'
        }
    ];

    //redirect
    const getSelectedRowData = (data) => {
        if (data && userDetails.role !== "ExternalStandardUser") {
            history.push(`/${"patients"}/${data.ne1Id}/${"assessment"}/${data.assessmentId}`);
        }
    }
    const deleteDataList = () => {
    }
    const selectRowCount = () => {
    }
    const handleSort = useCallback(data => {
        if (data && data.sortBy[0] && data.sortBy[0].id) {
            const sortingColumn = data.sortBy[0]
            const sortingType = sortingColumn ? (sortingColumn.desc) ? ':DESC' : ':ASC' : ''
            let sortParam = sortingColumn.id + sortingType
            setSortType(sortParam)
            const listPayLoad = {};
            listPayLoad['url'] = PI_ASSESSMENT_POA
            listPayLoad['method'] = 'Get'
            listPayLoad['queryParam'] = { offSet: isNaN(offSet)===true?0:offSet, limit: LIST_VIEW_LIMIT, facilityNumber: facilityNumber, sort: sortParam }
            dispatch(getPIAssessmentsPOAAsync(listPayLoad))
        }
    
    }, [dispatch, facilityNumber,offSet])

    const getActivePage = (data) => {
        setActivePage(data);
        const listPayLoad = {};
        listPayLoad['url'] = PI_ASSESSMENT_POA
        listPayLoad['method'] = 'Get'
        listPayLoad['queryParam'] = { offSet: (((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT, limit: LIST_VIEW_LIMIT, facilityNumber:facilityNumber,sort: (sortType)?sortType:''  }
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
       
        dispatch(getPIAssessmentsPOAAsync(listPayLoad))
    }

    return (
        <>
            {piAssessmentPOAData.piAssessments &&
                <DataTable
                    columns={columns}
                    data={piAssessmentPOAData?.piAssessments}
                    sorting={true}
                    getSelectedRowData={getSelectedRowData}
                    setSelectedRows={selectRowCount}
                    onSort={handleSort}
                    detailView={true}
                    deleteDataList={deleteDataList}
                    workListView={true}
                    sortType={(sortType)?sortType:''}
                />
            }
            {(Array.isArray(piAssessmentPOAData?.piAssessments)) && (piAssessmentPOAData?.piAssessments.length > 1) &&<Pagination
                activePage={activePage}
                itemsCountPerPage={piAssessmentPOAData?.metadata ? piAssessmentPOAData?.metadata?.limit : 50}
                totalItemsCount={piAssessmentPOAData?.metadata ? piAssessmentPOAData?.metadata?.total : 0}
                getActivePage={getActivePage}
                reportView={true}
            />
            }
        </>
    )
}
PIAssessmentsPOA.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}
PIAssessmentsPOA = connect()(withRouter(PIAssessmentsPOA));
