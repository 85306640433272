import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ActionModal } from '../../secondary'
import styles from './createAnnouncement.module.scss'
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CreateEditAnnouncement } from './createEditAnnouncement'
import { createAnnouncementAsync } from '../../../app/announcementSlice';
import { SUCCESS_STATUS, ERROR_STATUS, ANNOUNCEMENT_CREATED_MSG } from '../../../constants';
import iconSuccess from '../../../static/images/icons-check-success.png'
import iconDanger from '../../../static/images/icon-check-danger.png'

export let CreateAnnouncement = ({ dispatch, history, modalOpen, modalClose }) => {
    /* Use hook form */
    const { reset, register, handleSubmit, control, errors } = useForm({
        mode: 'all'
    });
    const [msgModal, setMsgModal] = useState(false);
    const resMsgState = useSelector(state => state.announcement.resMsgState);

    const onSubmitAnnouncement = (data) => {

        const postData = {
            "title": data.announcementID,
            "salesOffice": data.salesOfficeID,
            "description": data.announcementDescriptionID,
        }
        dispatch(createAnnouncementAsync(postData, history)).then(
            () => {
                setMsgModal(true)
                reset();
            }
        )
    }
    const resetForm = (e) => {
        e.preventDefault();
        reset();
        setMsgModal(false)
        modalClose();
    }
    const modalCheckClose = () => {
        setMsgModal(false)
        reset();
        modalClose();
    }
    return (
        <>
            {!msgModal && <Modal
                modalOpen={modalOpen}
                modalClose={modalClose}
                heading={"Create Item"}
                className={"modal_box"}
                modalSize={styles.custom_modal_size}
                reset={reset}
            >
                <CreateEditAnnouncement
                    register={register}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmitAnnouncement}
                    control={control}
                    errors={errors}
                    handleCancel={resetForm}
                    isModal="true"
                />
            </Modal>
            }
            {msgModal && <Modal
                modalOpen={modalOpen}
                modalClose={modalClose}
            >
                <div className="text-center p-4">

                    {resMsgState === SUCCESS_STATUS && <ActionModal
                        iconImg={iconSuccess}
                        heading={ANNOUNCEMENT_CREATED_MSG}
                        buttonText="Close"
                        buttonAction={()=>modalCheckClose()}
                    />
                    }

                    {resMsgState === ERROR_STATUS && <ActionModal
                        iconImg={iconDanger}
                        heading="Announcement not yet created!"
                        buttonText="Close"
                        buttonAction={()=>modalCheckClose()}
                    />
                    }

                </div>
            </Modal>
            }
        </>
    )
}
CreateAnnouncement.propTypes = {
    dispatch: PropTypes.func
};
CreateAnnouncement = connect()(withRouter(CreateAnnouncement));
