import React, { useState, useEffect } from 'react'
import { CustomerBasicInfo, CustomerMoreInfo, Organization, FormularyAssign, Ne1, Integration, Settings, Billing } from '../components/core/Customers';
import PropTypes from 'prop-types'
import { UsersList } from '../components/core/Customers/usersList';
import { connect, useSelector } from 'react-redux'
import { withRouter, useParams } from 'react-router'
import { DetailView } from '../components';
import { ListView } from '../components/secondary/ListView';
import { customerRbacChecker } from '../utils/rbac'
import {
    getCallBackListAsync,
    resetCustomers,
    setUploadStatus,
    uploadingCheckAsync,
    uploadFile,
    customerDetailsAsync,
    customerUpdateAsync,
    setDetailViewData,
    deleteCustomerListAsync,
    listOptionSalesCustomer
} from '../app/customerSlice'
import { LIST_VIEW_LIMIT, CUSTOMER, SUCCESS_STATUS } from '../constants';
import { optionTypeAsync } from '../app/patientSlice';
import { Delete, FacilityData, List, Pagination, Search, SelectedRow, Sorting } from '../utils';
import style from "./bIReports.module.scss";

export let Customers = ({ dispatch, history, location }) => {

    let userDetails = useSelector(state => state.user);
    let customerNumber = 0;
    if (userDetails) {
        customerNumber = userDetails.customerNumber
    }
    const [offSet, setOffSet] = useState(0)
    let customersList = useSelector(state => state.customer.customers);
    const customerData = useSelector(state => state.customer.detailViewData)
    const updateCustomerData = (data) => dispatch(customerUpdateAsync(data, history))

    const listOptionSales = useSelector(state=> state.customer.listOption) //dropdown value getting from API

    let tabList = [];
    (userDetails?.role !== 'ExternalAdministrator') ? tabList = [
        {
            name: 'Basic Info',
            content: <CustomerBasicInfo customerData={customerData} updateCustomerData={updateCustomerData} listOptionSales={listOptionSales}/>
        },
        {
            name: 'More Info',
            content: <CustomerMoreInfo customerData={customerData} updateCustomerData={updateCustomerData} />
        }
        , {
            name: 'Organization',
            content: <Organization customerData={customerData} updateCustomerData={updateCustomerData} />
        }
        , {
            name: 'Formulary',
            content: <FormularyAssign customerData={customerData} updateCustomerData={updateCustomerData} />
        }
        , {
            name: 'NE1',
            content: <Ne1 customerData={customerData} updateCustomerData={updateCustomerData} />
        },
        , {
            name: 'Billing',
            content: <Billing customerData={customerData} updateCustomerData={updateCustomerData} />
        }        
        , {
            name: 'Integration',
            content: <Integration customerData={customerData} updateCustomerData={updateCustomerData} />
        }
        ,{
            name: 'Users',
            content: <UsersList customerData={customerData} updateCustomerData={updateCustomerData} />
        },
        // ,{
        //     name: 'Settings',
        //     content: <Settings customerData={customerData} updateCustomerData={updateCustomerData} />
        // }
    ] :
        tabList = [
            {
                name: 'Basic Info',
                content: <CustomerBasicInfo customerData={customerData} updateCustomerData={updateCustomerData} />
            },
            {
                name: 'More Info',
                content: <CustomerMoreInfo customerData={customerData} updateCustomerData={updateCustomerData} />
            }
            , {
                name: 'Organization',
                content: <Organization customerData={customerData} updateCustomerData={updateCustomerData} />
            }
            , {
                name: 'Formulary',
                content: <FormularyAssign customerData={customerData} updateCustomerData={updateCustomerData} />
            }
            ,{
                name: 'Users',
                content: <UsersList customerData={customerData} updateCustomerData={updateCustomerData} />
            }
        ];
     
    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);
    const [searchKey, setSearchKey] = useState('')
    const [sortType, setSortType] = useState('')
    const getCustomerList = () => {
        List(CUSTOMER, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync);
    }


    useEffect(() => {
        setSortType('')
        setSearchKey('')
        dispatch(resetCustomers())
        getCustomerList();

    }, [dispatch]);

    const showFixedColumn = !!customersList.data && !!customersList.data.customers.length
    
    
    let columns = [
        {
            Header: 'Customer # ',
            accessor: 'customerNumber',
            disableSortBy: true,
            fixedColumn: showFixedColumn,
            fixedWidth: '110px',           
        },
        {
            Header: 'Customer name',
            accessor: 'customerName',
            fixedColumn: showFixedColumn,
            fixedWidth: '300px'
        },
        {
            Header: 'Account group',
            accessor: 'accountGroup',
            fixedColumn: showFixedColumn,
            fixedWidth: '170px'
        },
        {
            Header: 'Status',
            accessor: 'customerStatus',
            restWidth: "100px"
        },
        {
            Header: 'Street',
            accessor: 'streetName'
        },
        {
            Header: 'City',
            accessor: 'city',
        },
        {
            Header: 'District',
            accessor: 'district'
        },
        {
            Header: 'State',
            accessor: 'state',
        },
        {
            Header: 'Country',
            accessor: 'country'
        },
        {
            Header: 'Zip',
            accessor: 'zipCode'
        },
        {
            Header: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'Customer group',
            accessor: 'customerGroup'
        },
        {
            Header: 'Domain',
            accessor: 'domain'
        }
    ];



    let columnsExter = [
        {
            Header: 'Customer # ',
            accessor: 'customerNumber',
            fixedColumn: showFixedColumn,
            disableSortBy: false,       
        },
        {
            Header: 'Customer name',
            accessor: 'customerName',
            fixedColumn: showFixedColumn,
            fixedWidth: '300px',
            disableSortBy: false,
        },
        {
            Header: 'Account group',
            accessor: 'accountGroup',
            fixedColumn: showFixedColumn,
            fixedWidth: '170px'
        },
        {
            Header: 'Status',
            accessor: 'customerStatus',
            restWidth: "100px"
        },
        {
            Header: 'Street',
            accessor: 'streetName'
        },
        {
            Header: 'City',
            accessor: 'city',
        },
        {
            Header: 'District',
            accessor: 'district'
        },
        {
            Header: 'Region',
            accessor: 'region',
        },
        {
            Header: 'Country',
            accessor: 'country'
        },
        {
            Header: 'Zip',
            accessor: 'zipCode'
        },
        {
            Header: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'Customer group',
            accessor: 'customerGroup'
        },
        {
            Header: 'Domain',
            accessor: 'domain'
        }
    ];


    /* Check url for customer id */
    const { customerId } = useParams()

    /* If no customer id in url, dispatch empty data */
    useEffect(() => {
        if (!customerId) {
            dispatch(setDetailViewData({}))
        }
    }, [customerId, dispatch])

    /* If patient id exists display detail view */
    useEffect(() => {
        if (customerId) {
            dispatch(optionTypeAsync('emr'));
            dispatch(optionTypeAsync('devicetype'));
            dispatch(optionTypeAsync('woundevaluationfrequency'));
            /* Fetch data using parameter from url */
            dispatch(customerDetailsAsync(customerId, history))
            dispatch(listOptionSalesCustomer('salesoffice')) //listoption for saleoffice
        }
    }, [customerId, dispatch])

    useEffect(() => {
        dispatch(listOptionSalesCustomer('salesoffice')) //listoption for saleoffice
    }, [])


    const [detailsRow, setDetailsRow] = useState(false)
    const [detailsRowData, setDetailsRowData] = useState([])
    const userRole = useSelector(state => state.user);

    const getSelectedRowData = (data) => {          
        SelectedRow(data, setDetailsRowData, 'customers', history, isAdminView, userDetails);
        }
        

    const handleBack = () => {
    }
    // search list
    const getSearchValue = (event) => {        
        if (event && event.keyCode === 13) {
            if (event && event.target.value !== '') {
                setOffSet(0)
                setActivePage(1)
                setSearchKey(event.target.value)
                Search(event, CUSTOMER, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType)
            } else {
                setOffSet(0)
                setActivePage(1)
                getCustomerList();
                setSearchKey('')
                setSortType('')
            }
        }
    }
    // pagination
    const [activePage, setActivePage] = useState(1);
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
        Pagination(data, setActivePage, CUSTOMER, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackListAsync, searchKey,'',sortType);
    }
    // sorting
    const sortingPayLoad = Sorting(CUSTOMER, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackListAsync, searchKey,sortType,setSortType)
    /* Get Patients Details Facility Number*/
    const facilityListView = (value) => {
        FacilityData(value, CUSTOMER, 'Get', LIST_VIEW_LIMIT, dispatch, resetCustomers, getCallBackListAsync)

    }
    //delete
    const deleteList = (data) => {
        Delete(data, dispatch, deleteCustomerListAsync)
    }
    const subHeadings = ((userDetails?.role) && (userDetails?.role === 'MedlineAdministrator') ? 'Customers' : (userDetails?.role === 'ExternalAdministrator') ? 'Company Profile' : '');
    const subHeading = ((userDetails?.role) && (userDetails?.role === 'MedlineAdministrator') ? 
    <span>Customers
    <pre className={style.customer_details}><p>{customerData?.customerNumber} | {customerData?.customerName}</p> </pre>
    {/* <pre className={`mt-0 ${style.customer_details}`}><p>{(customerData?.address?.addressLine1 !== null && customerData?.address?.addressLine1 !== "") && `${customerData?.address?.addressLine1}, `}{(customerData?.address?.city !== null && customerData?.address?.city !== "") && `${customerData?.address?.city}, `} {(customerData?.address?.state !==null && customerData?.address?.state !== "") && `${customerData?.address?.state}, `} {(customerData?.address?.country !== null && customerData?.address?.country !== "") && `${customerData?.address?.country}`} {(customerData?.address?.zipCode !== null && customerData?.address?.zipCode !== "") && `, ${customerData?.address?.zipCode}`}</p></pre> */}
    <pre className={`mt-0 ${style.customer_details}`}><p>{customerData?.address?.addressLine1 ? customerData?.address?.addressLine1 + "," : ""} {customerData?.address?.city ? customerData?.address?.city + ", " : ""} {customerData?.address?.state ? customerData?.address?.state + ", " : ""} {customerData?.address?.country ? customerData?.address?.country : ""}{customerData?.address?.zipCode ? ", "+ customerData?.address?.zipCode : ""}</p></pre>
    </span>
     : (userDetails?.role === 'ExternalAdministrator') ? 'Company Profile' : '');
    const isListAll = customerRbacChecker('isListAll', 'MedlineStandardUser');
    const accessError = useSelector(state => state.app.accessError)
    const deleteStatus = useSelector(state => state.app.deleteStatus)

    useEffect(() => {
      if (deleteStatus) {
        if (deleteStatus.status === SUCCESS_STATUS) {
            getCustomerList();
        }
      }
    }, [deleteStatus])
    

    // if((detailsRowData?.accountGroup==="0002"||detailsRowData?.accountGroup==="0001")||(customerData?.accountGroup==="0002"||customerData?.accountGroup==="0001")){
    //     tabList.splice(5, 0, {  name: 'Billing',
    //         content: <Billing customerData={customerData} updateCustomerData={updateCustomerData} /> 
    //     })

    // }

    return (
        <div>
            {!customerId && <ListView
                columns={userRole?.role !== 'ExternalAdministrator' ? columns : columnsExter}
                data={customersList.data && customersList.data.customers}
                metaData={customersList.data && customersList.data.metadata}
                sorting={true}
                rowSelect={userRole?.role !== 'ExternalAdministrator' ? isAdminView : !isAdminView}
                getSelectedRowData = {getSelectedRowData}
                // getSelectedRowData={(userDetails.role === 'ExternalAdministrator') && !isAdminView ?  getSelectedRowDataExternal : getSelectedRowData}
                detailView={true}
                pageRangeDisplayed={5}
                searchPlaceholder={"Customer #,  Customer Name, Account Group Code, ..."}
                getSearchValue={(e) => getSearchValue(e)}
                detailsRow={!customerId}
                activePage={activePage}
                getActivePage={getActivePage}
                listSorting={sortingPayLoad}
                listType={"Customers"}
                subHeading={subHeadings}
                facilityListView={facilityListView}
                fileUplodad={uploadFile}
                showFixedColumn={showFixedColumn}
                setUploadStatus={setUploadStatus}
                uploadingCheckAsync={uploadingCheckAsync}
                deleteListView={deleteList}
                getCustomerList={getCustomerList}
                searchKey={(searchKey) ? searchKey : ''}
                offset={offSet}
                accessError={accessError}
                sortType={sortType?sortType:''}
            />}
            {
                customerId && <DetailView
                    detailsRowData={detailsRowData}
                    title="Customer Management"
                    tabList={tabList}
                    defaultIndex={0}
                    detailsRow={customerId}
                    handleBack={handleBack}
                    subHeading={subHeading}
                    backLink='/admin/customers'
                />
            }
        </div>
    )
}

Customers.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

Customers = connect()(withRouter(Customers));
