import React, { useState, useEffect } from 'react'
import { UserBasicInfo, UserSettings } from '../components/core/Users';
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { withRouter, useParams } from 'react-router-dom'
import { DetailView } from '../components';
import { ListView } from '../components/secondary/ListView';
import { LIST_VIEW_LIMIT, USER, SUCCESS_STATUS, 
    USERPENDLOCK /* Pending & Locked Button */
 } from '../constants';
import {
    getCallBackListAsync,
    resetUsers,
    userDetailsAsync,
    userUpdateAsync,
    setDetailViewData,
    deleteUserListAsync,
    uploadFile,
    getUserRoleAsync,
    setUploadStatus,
    uploadingCheckAsync
} from '../app/userSlice'
import { List, Search, Sorting, Pagination, Delete, SelectedRow,
    ListPendLock /* Pending & Locked Button */
 } from '../utils';


export let Users = ({ dispatch, history, location }) => {
    const accessError = useSelector(state => state.app.accessError)
    let userDetails = useSelector(state => state.user);
    let customerNumber = 0;
    if (userDetails) {
        customerNumber = userDetails.customerNumber
    }
    const [offSet, setOffSet] = useState(0)
    let userList = useSelector(state => state.user.users);

    /* Get state with the Individual customer data */
    const userData = useSelector(state => state.user.detailViewData)
    /* update user data */
    const updateUserData = (data) => dispatch(userUpdateAsync(data))
    /* Tabs object */
    const tabList = [
        {
            name: 'Basic Info',
            content: <UserBasicInfo userData={userData} facilityOptions={''} updateUserData={updateUserData} />
        }
        , {
            name: 'Settings',
            content: <UserSettings userData={userData} updateUserData={updateUserData} />
        }

    ];

    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);

    const [searchKey, setSearchKey] = useState('')
    const [sortType, setSortType] = useState('')
    /* Get User Details */

    const getUserList = () => {
        List(USER, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync);
    }

    /* Pending & Locked Button */

    const [openPend, setOpenPend] = useState(false)
    const [openLock, setOpenLock] = useState(false)

    const pendingList = () => {
        setOpenPend((prevState) => !prevState)        
    }
    const lockedList = () => {
        setOpenLock((prevState) => !prevState)
    }

    useEffect(()=>{
        if(openPend === false && openLock === false){
            const type="Noooo"
            List(USER, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync);
        }
        if(openPend === true && openLock=== false){
            const type ="Pending"
            ListPendLock(USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync, type, openPend);
        }
        if(openPend === false && openLock === true){
            const type ="Locked"
            ListPendLock(USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync, type, openLock);
        }
        if(openPend === true && openLock === true ){
            const type="Both"
            ListPendLock(USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync, type, openLock);        
        }    
    },[openLock, openPend])

    /* Pending & Locked Button */

    useEffect(() => {
        setSortType('')
        setSearchKey('')
        dispatch(resetUsers())
        getUserList();
    }, [dispatch]);

    const showFixedColumn = !!userList.data && !!userList.data?.users.length
    let columns = [
        {
            Header: 'Customer #',
            accessor: 'customerNumber',
            fixedColumn: showFixedColumn,
            // customWidth: "100px",
            fixedWidth: '120px'
        },
        {
            Header: 'First name',
            accessor: 'firstName',
            fixedColumn: showFixedColumn,
            // customWidth: "170px"
            fixedWidth: '230px'
        },
        {
            Header: 'Last name',
            accessor: 'lastName',
            fixedColumn: showFixedColumn,
            // customWidth: "170px"
            fixedWidth: '235px'
        },
        {
            Header: 'Email',
            accessor: 'email',
            restWidth: "250px"
        },        
        {
            Header: 'Status',
            accessor: 'userStatus',
            restWidth: "120px"
        },
        {
            Header: 'Facility',
            accessor: 'facility',
            restWidth: "200px"
        },
        {
            Header: 'Last login',
            accessor: 'lastLogin',
            restWidth: "200px"
        },

        {
            Header: 'Phone',
            accessor: 'phone',
            restWidth: "170px"
        },
        {
            Header: 'Role',
            accessor: 'role',
            restWidth: "200px"
        }
    ];

    /* Check url for USER id */
    const { userId } = useParams()

    /* If no USER id in url, dispatch empty data */
    useEffect(() => {
        if (!userId) {
            dispatch(setDetailViewData({}))
            dispatch(getUserRoleAsync());
        }
    }, [userId, dispatch])

    /* If patient id exists display detail view */
    useEffect(() => {
        if (userId) {
            /* Fetch data using parameter from url */
            dispatch(userDetailsAsync(userId,history));
        }
    }, [userId, dispatch])

    const [detailsRowData, setDetailsRowData] = useState([])
    const getSelectedRowData = (data) => {
        SelectedRow(data, setDetailsRowData, 'users', history, isAdminView);
    }
    const handleBack = () => {
    }
    // search list
    const getSearchValue = (event) => {        
        if (event && event.keyCode === 13) {
            if (event && event.target.value !== '') {
                setOffSet(0)
                setActivePage(1)
                setSearchKey(event.target.value)

                if(openPend === false && openLock === false){
                    Search(event, USER, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType, '', '')
                }
                if(openPend === true && openLock === true){
                    let BtnType = "Both"
                    let both = true
                    Search(event, USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType,BtnType,both)
                }
                if(openPend === true && openLock === false){
                    let BtnType = "Pending"
                    Search(event, USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType,BtnType,openPend)
                }
                if(openPend === false && openLock === true){
                    let BtnType = "Locked"
                    Search(event, USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType,BtnType,openLock)
                }
            
            } else {
                setOffSet(0)
                setActivePage(1)
                // getUserList();
                setSearchKey('')
                setSortType('')

                if(openPend === false && openLock === false){
                    getUserList();
                }
                if(openPend === true && openLock === true){
                    let BtnType = "Both"
                    let both = true
                    Search(event, USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType,BtnType,both)
                }
                if(openPend === true && openLock === false){
                    let BtnType = "Pending"
                    Search(event, USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType,BtnType,openPend)
                }
                if(openPend === false && openLock === true){
                    let BtnType = "Locked"
                    Search(event, USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync,sortType,BtnType,openLock)
                }
            }
        }
    }

    // pagination
    const [activePage, setActivePage] = useState(1);
    // const [BtnPage,setBtnPage] = useState(false);
    const getActivePage = (data) => {
        setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)  
        if(openPend === false && openLock === false){  
            Pagination(data, setActivePage, USER, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackListAsync, searchKey,'',sortType,'','')
        } 
        if(openPend === true && openLock === true){
            let BtnType = "Both"
            let both = true
            // setBtnPage(true)
            Pagination(data, setActivePage, USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackListAsync, searchKey,'',sortType, BtnType, both)
        }       
        if(openPend === true && openLock === false){
            let BtnType = "Pending"
            // setBtnPage(true)
            Pagination(data, setActivePage, USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackListAsync, searchKey,'',sortType, BtnType, openPend)
        }
        if(openPend === false && openLock === true){
            let BtnType = "Locked"
            // setBtnPage(true)
            Pagination(data, setActivePage, USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackListAsync, searchKey,'',sortType, BtnType, openLock)
        }   
    }

    // useEffect(()=>{
    //     if(openPend === true || openLock === true){
    //         setOffSet(0)
    //         // setBtnPage(true)
    //     }
    // },[openPend, openLock])
  
    // sorting
    if(openPend === true && openLock === false){
        let BtnType = "Pending"
        var sortingPayLoad = Sorting(USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackListAsync, searchKey,sortType,setSortType, BtnType, openPend)
    }
    if(openPend === false && openLock === true){
        let BtnType = "Locked"
        var sortingPayLoad = Sorting(USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackListAsync, searchKey,sortType,setSortType, BtnType, openLock)
    }
    if(openPend === true && openLock === true){
        let BtnType = "Both"
        let both = true
        var sortingPayLoad = Sorting(USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackListAsync, searchKey,sortType,setSortType, BtnType, both)
    } 
     
    if(openPend === false && openLock === false){
        var sortingPayLoad = Sorting(USER, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackListAsync, searchKey,sortType,setSortType, '', '')
    }
    
    
    //delete
    const deleteList = (data) => {
        Delete(data, dispatch, deleteUserListAsync)
    }
    const deleteStatus = useSelector(state => state.app.deleteStatus)

    useEffect(() => {
      if (deleteStatus) {
        if (deleteStatus.status === SUCCESS_STATUS) {
            if(openPend === false && openLock === false){
                getUserList();
            }
            if(openPend === true && openLock=== false){
                const type ="Pending"
                ListPendLock(USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync, type, openPend);
            }
            if(openPend === false && openLock === true){
                const type ="Locked"
                ListPendLock(USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync, type, openLock);
            }
            if(openPend === true && openLock === true ){
                const type="Both"
                ListPendLock(USERPENDLOCK, 'Get', LIST_VIEW_LIMIT, offSet, dispatch, getCallBackListAsync, type, openLock);        
            }            
        }
      }
    }, [deleteStatus])
  
    return (
        <div>
            {!userId && <ListView
                columns={columns}
                data={userList.data && userList.data?.users}
                metaData={userList.data && userList.data?.metadata}
                sorting={true}
                rowSelect={isAdminView}
                getSelectedRowData={getSelectedRowData}
                detailView={true}
                pageRangeDisplayed={5}
                searchPlaceholder={"First Name, Last Name, Email, Phone number, Customer #, Role, Status"}
                getSearchValue={(e) => getSearchValue(e)}
                detailsRow={!userId}
                activePage={activePage}
                getActivePage={getActivePage}
                listSorting={sortingPayLoad}
                listType={"Users"}
                subHeading={'User Profile'}
                showFixedColumn={showFixedColumn}
                deleteListView={deleteList}
                searchKey={(searchKey) ? searchKey : ''}
                fileUplodad={uploadFile}
                setUploadStatus={setUploadStatus}
                getUserList={getUserList}
                offset={offSet}
                accessError={accessError}
                sortType={sortType?sortType:''}
                pendingList={pendingList} /* Pending & Locked Button */
                lockedList={lockedList} /* Pending & Locked Button */
                openPend={openPend} /* Pending & Locked Button */
                openLock={openLock} /* Pending & Locked Button */
                // getList={getList}
                uploadingCheckAsync={uploadingCheckAsync}
            />}
            {userId && <DetailView
                detailsRowData={detailsRowData}
                title="User Management"
                tabList={tabList}
                defaultIndex={0}
                detailsRow={userId}
                handleBack={handleBack}
                subHeading={'User Profile'}
                backLink='/admin/users'
            />}
        </div>
    )
}

Users.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

Users = connect()(withRouter(Users));
