import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import {
  ABOUT_CUSTOMER_HEAD,
  ACCOUNT_GROUP_CODE_LBL,
  CITY_LBL, COUNTRY_CODE_LBL,
  CUSTOMER_GROUP_NAME_LBL,
  CUSTOMER_NAME_LBL,
  CUSTOMER_NUMBER_LBL,
  CUSTOMER_PHONE_LBL,
  CUSTOMER_POSTAL_LBL,
  CUSTOMER_STREET_LBL,
  DISTRICT_NAME_LBL,
  DIVISION_MANAGER_LBL,
  EDIT,
  REGION_CODE_LBL,
  SALES_OFFICE_LBL,
  WOUND_CARE_SALES_REP_LBL,
  //UPDATE_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
  UPDATE_FAILURE,
  SUCCESS_STATUS,
  ERROR_STATUS,
  CUSTOMER_GROUP_NAME,
  CONTACT_INFORMATION_HEAD,
  SALES_OFFICE_OPTIONS_CUST
} from '../../../constants'
import editIcon from '../../../static/images/edit-icon-blue.png'
import styles from './basicInfo.module.scss'
import { CreateEditCustomer } from './createEditCustomer'
import { setEditState, listOptionSalesCustomer, listOptionCustomerGroup } from '../../../app/customerSlice';
import { AccessErrorModal } from '../../primary';
import Loader from '../../../static/images/spinner.svg';	
import DefaultImg from "../../../static/images/picture-icon-default.png";


export function BasicInfo({ customerData, updateCustomerData }) {  
  
  useEffect(() => {
    dispatch(listOptionSalesCustomer('salesoffice')) //listoption for saleoffice
    // dispatch(listOptionCustomerGroup(customerData?.salesOfficeDescription?.toLowerCase())) // sending to API for getting Customer Group Dropdown based on Sale office showing Value 
    dispatch(listOptionCustomerGroup('customergroup')) 
  }, [])

  const accessError = useSelector(state => state.app.accessError)
  const listOptionSales = useSelector(state=> state.customer.listOption) //sale office dropdown value getting from API
  const customerGroupType = useSelector(state=>state.customer.customerGroupType)//customer group dropdown value getting from API  
   
  /* State to check editable forms */
  const [editable, setEditable] = useState(false)

   /* DefaultTimeout Seesion */
   const [defaultTimeOut, setDefaultTimeOut] = useState("");
   const [defaultTimeOutType, setDefaultTimeOutType] = useState("");
  /* Function to toggle edit */
  const handleEdit = () => {
    setEditable(!editable)
    setDefaultTimeOut(
      customerData && customerData?.settings?.sessionTimeout
        ? customerData?.settings?.sessionTimeout
        : 24
    );
    setDefaultTimeOutType(
      customerData && customerData?.settings?.sessionTimeoutType
        ? customerData?.settings?.sessionTimeoutType
        : "hours"
    );
    setShowStatus(customerData?.settings?.customerStatus === 1 ? 1 : 0)
  }

  const userDetails = useSelector(state => state.user);
  const adminView = userDetails?.role
  const [externalAdmin, setExternalAdmin] = useState(false);

  useEffect(() => {
    if (adminView === "ExternalAdministrator") {
      setExternalAdmin(true)
    }
  }, [adminView])

  /* Preview image state */
  const [previewImg, setPreviewImg] = useState(null);
  /* Disable button state */
  const [buttonState, setButtonState] = useState(true);
  const [showStatus, setShowStatus] = useState(0)
    
    useEffect(()=>{
        if(customerData?.settings?.customerStatus === 1){
            setShowStatus(1)
        }
        else{
        setShowStatus(0)
        }
    },[customerData])   

  /* On Submit */
  const onSubmit = (data) => {
    let finalPic = previewImg ? previewImg : customerData.companyLogo;
    if (finalPic) {
      finalPic = finalPic.replace("data:image/png;base64,", "");
      finalPic = finalPic.replace("data:image/jpeg;base64,", "");
      finalPic = finalPic.replace("data:image/jpg;base64,", "");
      finalPic = finalPic.replace("data:image/svg+xml;base64,", "");
    }
    const tempData = {
      customerName: data.customerName,
      phone: data.phone,
      customerGroup: data.customerGroup,
      salesOfficeDescription: data.salesOfficeDescription,
      divisionManagerName: data.divisionManagerName,
      woundCareSalesRepName: data.woundCareSalesRepName,
      statusInfo: customerData?.statusInfo,
      address: {
        // addressLine1: "55",
        addressLine1: data.streetAddress,
        region: data.Region,
        city: data.city,
        district: data.districtName,
        state: data.state !== 'Select' ? data.state : null,
        country: data.countryCode,
        zipCode: data.postalCode
      },
      settings: {
        companyLogo: finalPic,
        customerStatus: showStatus, //check customer status
        domain:  data.emailDomains,
        sessionTimeout: data.sessionTimeOut,
        sessionTimeoutType: data.sessionTimeoutType,
        ne1Wms: customerData?.settings?.ne1Wms, //check customer status
        pdfAutoGen : customerData?.settings?.pdfAutoGen, // config pdf auto generation
        poaWithinHrs: customerData?.settings?.poaWithinHrs,
        poaType: customerData?.settings?.poaType,
        reEvalWithinHrs: customerData?.settings?.reEvalWithinHrs,
        aiTissueType: customerData?.settings?.aiTissueType, // aiTissueType
        woundEdgeAutoTracing: customerData?.settings?.woundEdgeAutoTracing,
        measureType: customerData?.settings?.measureType,
        riskAssessmentScale: customerData?.settings?.riskAssessmentScale,
        woundAssessmentScore: customerData?.settings?.woundAssessmentScore,
        historyCause: customerData?.settings?.historyCause, //from here new changes
        soldToShipToForZ002: customerData?.settings?.soldToShipToForZ002,
        copyDataFromPreviousAssessment: customerData?.settings?.copyDataFromPreviousAssessment,
        signWithOutMinimumDataset: customerData?.settings?.signWithOutMinimumDataset,
        skipPhoto: customerData?.settings?.skipPhoto       
      },
    }
    /* Merging */
    const result = { ...customerData, ...tempData }

    /* Dispatching */
    updateCustomerData(result)
  }

  /* BELOW IS ONLY TEMPORARY */
  /* Mock data (Actual data to be fetched from API) */

  /* Edit success */
  const editState = useSelector(state => state.customer.editState)

  const dispatch = useDispatch()

  useEffect(() => {
    if (editState === "success") {
      setEditable(!editable)
    }
    setTimeout(() => {
      dispatch(setEditState(false))
    }, 5000)
  }, [editState, dispatch])

  const [tinyDelay, setTinyDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTinyDelay(true)
    }, 2500)
  }, [])

  return (
    <>
    {tinyDelay&&!editable && 
      <div className={`${styles.tab_content_top} col-12 py-2`}>
        <h2 className={`${styles.tab_content_hdg} pl-0`}>
          <div className={`col-10 d-flex align-items-center justify-content-between`}>
            <div className={`${styles.tab_content_ne1Status}`}>
            {`Status: `}<span className="" style={{fontSize:'19x', color:'#737373'}}>{customerData?.settings?.customerStatus === 1 ? "Active" : "Inactive"}</span>
            </div>
            <div className={`${styles.tab_content_ne1Status}`}>
              {`Session Timeout: `}
              <span className="" style={{fontSize:'18px', color:'#737373'}}>{customerData?.settings?.sessionTimeout}{" "}
              {customerData &&
              customerData?.settings?.sessionTimeoutType
                ? customerData?.settings?.sessionTimeoutType[0]?.toUpperCase() +
                  customerData?.settings?.sessionTimeoutType?.slice(1)
                : ""}
              </span>
            </div>
            <div className={`${styles.tab_content_ne1Status}`}>
              { `Logo: `}
              <span className="" style={{fontSize:'22px', color:'#737373'}}>
                {!customerData?.settings?.companyLogo ? (
                  <img
                    src={DefaultImg}
                    alt="No image"
                    className={styles.patient_img}
                  />
                ) : (
                  <img
                    src={customerData?.settings?.companyLogo}
                    alt="CompanyLogo"
                    className={styles.patient_img}
                  />
                )}  
              </span>
            </div>
          </div>
          
          <span
            className={`float-sm-right ${styles.tab_edit} pt-2`}
            onClick={handleEdit}
          >
            <img src={editIcon} alt="edit" />
            {EDIT}
          </span>
        
        </h2>
      </div> }
      
    {!tinyDelay ?
    
        <div className={`${styles.loader} col-12`}>
        <img src={Loader} alt="loading" />
      </div>:

      <div className={`col-md-12 ${styles.single_product_block}`}>
        <div className="row">
          <div className={`p-0 ${styles.patient_content} pb-2`}>

            {!editable ?

              <>
                {/* Edit success */}
                {editState === SUCCESS_STATUS && <div className={`alert alert-success ${styles.form_alert}`}>{CUSTOMER_UPDATE_SUCCESS}</div>}

                {/* Edit failure */}
                {editState === ERROR_STATUS && <div className={`alert alert-danger ${styles.form_alert}`}>{UPDATE_FAILURE}</div>}

                {/* Heading */}
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>{"General Data"}</p>
                </div>

                {/* Customer Number */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{CUSTOMER_NUMBER_LBL}: </label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.customerNumber}</span></div>
                </div>

                {/* Customer Name */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{CUSTOMER_NAME_LBL}: </label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.customerName}</span></div>
                </div>

                {/* Street Address */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{"Address"}: </label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.address?.addressLine1}</span></div>
                </div>

                {/* City */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{CITY_LBL}: </label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.address?.city}</span></div>
                </div>

                  {/* Region Code */}
                  <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{"State"}:</label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.address?.state ? customerData?.address?.state : "-"}</span></div>
                </div>

                {/* Postal code */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{CUSTOMER_POSTAL_LBL}: </label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.address?.zipCode}</span></div>
                </div>

               {/* Phone */}
               <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{"Phone"}: </label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.phone ? customerData?.phone : "-" }</span></div>
                </div>

                {/* District Name */}
                {/* <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{DISTRICT_NAME_LBL}:</label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.address?.district}</span></div>
                </div> */}

                {/* Country Name */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{"Country"}:</label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.address?.country}</span></div>
                </div>

                {/* Customer email domain */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label
                    className={`col-6 col-form-label ${styles.label_spec}`}
                  >
                    Domain:{" "}
                  </label>
                  <div className="col-6 p-0">
                    {/* { <span className={`col-form-label ${styles.content_spec}`}>
                      {customerData?.settings?.domain}
                    </span> } */}

                {customerData?.settings?.domain &&customerData?.settings?.domain?.length > 0 ?
                              customerData?.settings?.domain?.map(
                                (email, i) => {
                                  return (
                                    <> 
                                    <p className={`col-form-label ${styles.content_spec}`}>
                    
                      {email}
                    </p><br/>
                                    </>
									)
									}
									):<><span className={`col-form-label ${styles.content_spec}`}>-</span></>
									}
                  </div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>


                {/****************
                                * About Customer section
                                **************** */}

                {/* Heading */}
                <div className={`col-sm-12 ${styles.col_spec}`}>
                  <p className={`col-12 ${styles.subheading_2}`}>{"Sales Area"}</p>
                </div>

                {/* Account Group Code */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{ACCOUNT_GROUP_CODE_LBL}: </label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.accountGroup}{` - `}{customerData?.accountGroup==="Z002" ? 'Primary Group' : customerData?.accountGroup==="0001" ? 'Sold_To' : 'Ship_To'}</span></div>
                </div>
                <div className={`col-6 ${styles.col_spec}`}></div>

                {/* Sale office description */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{"Sales Office"}:</label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>
                    {
                      listOptionSales && listOptionSales.length > 0 && listOptionSales?.map(e => e.key == customerData?.salesOfficeDescription && e.value)//NOSONAR
                    }
                  </span></div>
                </div>

                {/* Customer group name */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{"Customer Group"}:</label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{
                      customerGroupType && customerGroupType.length > 0 && customerGroupType?.map(e => e.key == customerData?.customerGroup && e.value)//NOSONAR
                    }</span></div>
                </div> 

                {/* Rep Name */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{"Wound Care Sales Rep"}:</label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.woundCareSalesRepName ? customerData?.woundCareSalesRepName : "-"}</span></div>
                </div>

                {/* Division manager name */}
                <div className={`col-6 ${styles.col_spec}`}>
                  <label className={`col-6 col-form-label ${styles.label_spec}`}>{"Division Manager"}:</label>
                  <div className="col-6 p-0"><span className={`col-form-label ${styles.content_spec}`}>{customerData?.divisionManagerName ? customerData?.divisionManagerName : "-"}</span></div>
                </div>
                

              </>
              :
              <CreateEditCustomer
                onSubmit={onSubmit}
                customerData={customerData}
                handleCancel={handleEdit}
                externalAdmin={externalAdmin}
                previewImg = {previewImg}
                setPreviewImg = {setPreviewImg}
                buttonState={buttonState}
                setButtonState = {setButtonState}
                defaultTimeOut={defaultTimeOut}
                defaultTimeOutType={defaultTimeOutType}
                setDefaultTimeOut={setDefaultTimeOut}
                setDefaultTimeOutType={setDefaultTimeOutType}
                showStatus={showStatus}
                setShowStatus={setShowStatus}
              />
            }

          </div>
        </div>
      </div>
      }

      {
        accessError &&
        <AccessErrorModal />
      }
    </>
  )

}
