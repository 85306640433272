import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Form, TextField, Button, SelectBox } from '../../primary';
import { createFormAsync, setCreateFormStatus } from '../../../app/customFormSlice';
import { CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants';
import styles from './assign.module.scss';
import { Modal, ActionModal } from '../../secondary';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import iconSuccess from '../../../static/images/icons-check-success.png';
import iconDanger from '../../../static/images/icon-check-danger.png';

export let CreateForm = ({ dispatch, history, modalOpen, modalClose }) => {

    const path = window.location.href;
    const origin = window.location.origin;
    const isAdminView = path.includes(`${origin}/admin`);
    const [feature, setFeature] = useState('')
    useEffect(() => {
        dispatch(setCreateFormStatus({}))
    }, [dispatch])

    const { register, handleSubmit, control, errors, reset, getValues } = useForm();
    let upDateStatus = useSelector(state => state.forms.createFormStatus)
    const onSubmit = (data) => {
        dispatch(setCreateFormStatus({}))
        let formData = {}
        formData['data'] = data;
        dispatch(createFormAsync(formData))
    }

    const modalRefresh = () => {
        reset();
        setFeature('')
        reset({...getValues(), feature: ''})
        modalClose();
        dispatch(setCreateFormStatus({}))
    }
    const handleCancel = (e) => {
        e.preventDefault();
        reset();
        setFeature('');
        reset({...getValues(), feature: ''})
        modalClose();
        dispatch(setCreateFormStatus({}))
    }
    const alertMsgClose = () => {
        modalClose();
        setFeature('')
        reset({...getValues(), feature: ''})
        dispatch(setCreateFormStatus({}))
    }
    const getFeature = (e) => {
        setFeature(e.target.value)
        reset({...getValues(), subFeature: e.target.value === 'NE1 WMS' ? 'Characteristics':''})
    }
    const hideErrormsg = (e) => {
        dispatch(setCreateFormStatus({}))
    }
    const subFeature = {"NE1 WMS":false,"Product Selector":true, "Survey":true, '':true}
    return (
        <Modal
            modalOpen={modalOpen}
            modalClose={modalRefresh}
            heading={(upDateStatus.status === undefined) && "Create Form"}
            className={"modal_box"}
            modalSize={styles.custom_modal_size}
            reset={reset}
        >
            {upDateStatus.status === undefined && <Form className={`pl-2 ${styles.patient_content} createForm`} onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    classes={{ 
                        column: `col ${styles.col_spec}`, 
                        labelClass: `col-3 col-form-label ${styles.label_spec}`, 
                        inputColumn: 'col-4 p-0', 
                        inputClassName: `${styles.basic_input} form-control` 
                    }}
                    label={"Form Name:"}
                    type={"text"}
                    name={"name"}
                    id={"name"}
                    placeholder={"Enter form name"}
                    validator={register({required:'Enter form name'})}
                    errors={errors}
                    customError={upDateStatus?.errorMessage}
                    required={true}
                    onKeyUp={hideErrormsg}
                />
                <SelectBox
                    id={"feature"}
                    name={"feature"}
                    label={`Feature:`}
                    classes={{
                        column: `col-12 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-3 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-4 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={[
                        { key: 'NE1 WMS', value: 'NE1 WMS' },
                        { key: 'Product Selector', value: 'Product Selector' },
                        { key: 'Survey', value: 'Survey' },
                    ]}
                    placeholder={"Select"}
                    defaultValue={''}
                    validator={register({required:'Select Feature'})}
                    errors={errors}
                    onChange={(e)=>getFeature(e)}
                    required={true}
                />
                <SelectBox
                    id={"subFeature"}
                    name={"subFeature"}
                    label={`Sub-Feature:`}
                    classes={{
                        column: `col-12 ${styles.col_spec}`,
                        inputClassName: `${styles.basic_select}`,
                        labelClassName: `col-3 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-4 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={feature === 'NE1 WMS' ? [
                        { key: 'Characteristics', value: 'Characteristics' }
                    ] :''}
                    placeholder={""}
                    defaultValue={""}
                    validator={register()}
                    errors={errors}
                    disabled={subFeature[feature]}
                />
                <div className="col-sm-12">
                    <div className="text-center pb-4">
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                            type="submit"
                            id={SUBMIT_ID}
                        >
                            {SAVE_BTN}
                        </Button>
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel} handleCancel` }}
                            id={CANCEL_ID}
                            onClick={handleCancel}
                        >
                            {CANCEL_BTN}
                        </Button>
                    </div>
                </div>
            </Form>
            }
            {upDateStatus?.status === "success" && <div className="text-center p-4">
                <ActionModal
                    iconImg={iconSuccess}
                    heading={upDateStatus?.message}
                    buttonText="Close"
                    buttonAction={alertMsgClose}
                />
            </div>}
        </Modal>
    )
}
CreateForm = connect()(withRouter(CreateForm));
