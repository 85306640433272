import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { Form, Button, SelectBox, MultiSelect, TextArea } from '../../primary';
import { CANCEL_ID, CANCEL_BTN, SUBMIT_ID, SAVE_BTN } from '../../../constants';
import styles from './assign.module.scss';
import { Modal } from '../../secondary';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { productAttribute } from '../../../app/customFormSlice';
import { occurrences } from '../../../utils';
export let AddRuleText = ({ modalOpen, modalClose, addRuleList, getRuleData, defaultValue }) => {
    const { register, errors, reset } = useForm();
    const [ruleType, setRuleType] = useState('')
    const [attributes, setAttributes] = useState([])
    const [questions, setQuestions] = useState([])
    const [showlistAttr, setShowListAttr] = useState([])
    const [showlistFamily, setShowListFamily] = useState([])
    const [showlistMaterial, setShowListMaterial] = useState([])
    const [showlist, setShowList] = useState([])
    const [textArea, setTextArea] = useState('')
    const [expression, setExpression] = useState([])
    const [defaultData, setDefaultData] = useState(true)
    const [materials, setMaterials] = useState([])
    const [customMsgErr, setCustomMsgErr] = useState('')
    
    useEffect(() => {
        document.getElementById('endofformrule').value = defaultValue?.ruleOption
        setCustomMsgErr('')
    }, [defaultValue, modalOpen])
   
    useEffect(() => {
        if (defaultValue?.expression !== null) {
            setExpression(defaultValue?.expression)
        }
    }, [defaultValue, modalOpen])

    useEffect(() => {
        if (defaultValue !== null) {
            setRuleType(defaultValue?.ruleOption)
            setTextArea(defaultValue?.textArea)
            changeEndofRule(defaultValue?.ruleOption, 1)

            let arrAttr = []
            let arrFam = []
            let arrMat=[]
            defaultValue?.attribute?.length > 0 && Array.isArray(defaultValue.attribute)&& defaultValue?.attribute?.forEach((val) => {//NOSONAR
               Array.isArray(addRuleList)&&addRuleList?.map(//NOSONAR
                    (item) => {//NOSONAR
                        if (item.optionType === "productattribute") {
                            if (val === item.id) {
                                let obj = { key: item?.id, value: item?.productAttributeFilter?.productAttributeLabel }
                                arrAttr = [...arrAttr, obj]
                                return setShowListAttr(arrAttr)//NOSONAR
                            }
                        }
                    }//NOSONAR
                )//NOSONAR
            })//NOSONAR

            defaultValue?.productQuestion?.length > 0 && Array.isArray(defaultValue.productQuestion)&& defaultValue?.productQuestion?.forEach((val) => {//NOSONAR
                Array.isArray(addRuleList)&&addRuleList?.map(//NOSONAR
                    (item, index) => {//NOSONAR
                        if (item.optionType === "productfamily") {
                            if (val === item.id) {
                                let obj = { key: item?.id, value: `${index + 1}.${item?.desc}` }
                                arrFam = [...arrFam, obj]
                                return setShowListFamily(arrFam)//NOSONAR
                            }
                        }
                    }//NOSONAR
                )//NOSONAR
            })//NOSONAR

            defaultValue?.productMaterial?.length > 0 && Array.isArray(defaultValue.productMaterial)&& defaultValue?.productMaterial?.forEach((val) => {//NOSONAR
                Array.isArray(addRuleList)&&addRuleList?.map(//NOSONAR
                    (item, index) => {//NOSONAR
                        if (item.optionType === "productmaterial") {
                            if (val === item.id) {
                                let obj = { key: item?.id, value: `${index + 1}.${item?.desc}` }
                                arrMat = [...arrMat, obj]
                                return setShowListMaterial(arrMat)//NOSONAR
                            }
                        }
                    }//NOSONAR
                )//NOSONAR
            })//NOSONAR
        } else {
            setRuleType('Select');
        }
    }, [addRuleList, defaultValue, modalOpen])
    const modalRefresh = () => {
        reset();
        setRuleType('Select')
        setShowListAttr([])
        setShowListFamily([])
        setShowListMaterial([])
        setShowList([])
        modalClose();
    }
    const changeEndofRule = (value, def = 0) => {
        if (value !== "custommessage") {
            let arrAttr = []
            let arrFam = []
            let arrMat = []
            Array.isArray(addRuleList)&&addRuleList?.map(
                (item, index) => {//NOSONAR
                    if (item.optionType === "productattribute") {
                        let obj = { key: item?.id, value: `${index + 1}.${item?.productAttributeFilter?.productAttributeLabel}` }
                        arrAttr = [...arrAttr, obj]
                       return setAttributes(arrAttr)//NOSONAR
                    }
                    if (item.optionType === "productfamily") {
                        let obj = { key: item?.id, value: `${index + 1}.${item?.desc}` }
                        arrFam = [...arrFam, obj]
                        return setQuestions(arrFam)//NOSONAR
                    }
                    if (item.optionType === "productmaterial") {
                        let obj = { key: item?.id, value: `${index + 1}.${item?.desc}` }
                        arrMat = [...arrMat, obj]
                        return setMaterials(arrMat)//NOSONAR
                    }
                })//NOSONAR
            let optAttr = addRuleList?.find(o => o.optionType === 'productattribute')
            let optQues = addRuleList?.find(o => o.optionType === 'productfamily')
            let optMat = addRuleList?.find(o => o.optionType === 'productmaterial')
            if(optAttr === undefined){setAttributes([])}
            if(optQues === undefined){setQuestions([])}
            if(optMat === undefined){setMaterials([])}
        }
        setDefaultData(true)
        setRuleType(value)
        if (def === 0) {
            setDefaultData(false)
            setTextArea('')
            setExpression([])
            setShowListAttr([])
            setShowListFamily([])
            setShowListMaterial([])
        }
    }
    const handleCancel = (e) => {
        setRuleType('Select')
        setShowListAttr([])
        setShowListFamily([])
        setShowListMaterial([])
        setShowList([])
        setExpression([])
        setErrorMsg(false)
        reset();
        e.preventDefault();
        modalClose();
    }
    const getRuleValue = (selectedList, selectedItem, type) => {
        if (expression?.length > 0) {
            const showListData = [...expression, " AND ", "", selectedItem.key, ""]
            setExpression(showListData)
        } else {
            const showListData = ["", selectedItem.key, ""]
            setExpression(showListData)
        }
        if (type === "attributes") {
            setShowListAttr(selectedList)
        } else if(type==="family") {
            setShowListFamily(selectedList)
        }else{
            setShowListMaterial(selectedList)
        }
    }
    const removeRuleValue = (removeList, removeItem, type) => {
        const getInd = expression?.findIndex((x) => x == removeItem.key)
        const showListData = [...expression]
        showListData.splice(getInd, 3)
        showListData.splice(getInd - 1, 1)
        const len = showListData?.length - 1;
        if(showListData[len] == ' AND ' || showListData[len] == ' OR ') {
            showListData.splice(len, len)
        }
        setExpression(showListData)
        if (type === "attributes") {
            setShowListAttr(removeList)
        } else if(type==="family") {
            setShowListFamily(removeList)
        }else{
            setShowListMaterial(removeList)
        }
    }
    const getAnswer = (val) => {
        setTextArea(val)
    }
    // State to display error message
    const [errorMsg, setErrorMsg] = useState(false)

    useEffect(() => {
        if (errorMsg !== false) {
            setTimeout(() => {
                setErrorMsg(false)
            }, 5000)
        }
    }, [errorMsg])
    const saveRule = () => {
        let container = document.getElementById(`ruleData`);

        // Payload text and expression data
        let payloadElements = document.querySelectorAll(`.editTable`);
        let payloadExp = [];
        let payloadTxt = '';

        payloadElements.forEach((element) => {
            payloadTxt += element.textContent
            payloadExp = [...payloadExp, element.textContent]
        });

        if (container) {
            let elements = container.querySelectorAll('span:not(.ruleHide)')
            let exp = [];
            let txt = '';
            let codes = [];
            let operators = [];
            elements.forEach((element) => {
                // Removing parantheses if there is any 
                let stripedText = element?.textContent?.replace(/[{()}]/g, '');
                //console.log('stripedText', stripedText)
                txt += element.textContent
                exp = [...exp, stripedText]
                // Adding only attributes and family, not the AND and OR
                if (stripedText !== '' && stripedText !== String.fromCharCode(160) && stripedText.trim() !== 'AND' && stripedText.trim() !== 'OR') {
                    codes = [...codes, stripedText]
                }
                if(stripedText.trim() === 'AND' || stripedText.trim() === 'OR'){
                    operators = [...operators, stripedText.trim()]
                }
            });

            //console.log(operators, codes); 
            // This is for coming out of save action
            let breaker = false;

            // Extra ANDs and ORs

            if(operators.length !== codes.length - 1){
                //console.log('operator');
                setErrorMsg('Oops invalid expression , the allowed expression or only AND, OR , “(“ and “)” please remove other expression and also validate if all the open braces are closed.')
                breaker = true;
            }

            if(breaker !== false){
                return;
            }

            let lCount = occurrences(txt, '(', true)
            let rCount = occurrences(txt, ')', true)
            if (lCount !== rCount) {
                //console.log('(((');
                setErrorMsg('Oops invalid expression , the allowed expression or only AND, OR , “(“ and “)” please remove other expression and also validate if all the open braces are closed.')
                breaker = true;
            }

            if (breaker !== false) {
                return;
            }

            // Checking wheather the entered data is right or wrong
            codes?.forEach((item) => {//NOSONAR
                const findQuestion = questions?.find(x => x.value === item);
                const findAttribute = attributes?.find(x => x.value === item);
                const findMaterials = materials?.find(x => x.value === item);
                if (findAttribute === undefined && findQuestion === undefined&&findMaterials===undefined) {
                    //console.log('attr');
                    setErrorMsg('Oops invalid expression , the allowed expression or only AND, OR , “(“ and “)” please remove other expression and also validate if all the open braces are closed.')
                    breaker = true;
                    return;
                }
            })//NOSONAR

            if (breaker !== false) {
                return;
            }
        }
        if(ruleType === "custommessage"&&textArea==''){
            setCustomMsgErr({textArea:{message:'Please enter custom message'}})
            return;
        }else{
            setCustomMsgErr('');
        }

        let attr = []
        Array.isArray(showlistAttr)&&showlistAttr?.map((item, index) => {//NOSONAR
            attr = [...attr, item.key]
        })//NOSONAR
        
        let family = []
        Array.isArray(showlistFamily)&&showlistFamily?.map((item, index) => {//NOSONAR
            family = [...family, item.key]
        })//NOSONAR

        let material = []
        Array.isArray(showlistMaterial)&&showlistMaterial?.map((item, index) => {//NOSONAR
            material = [...material, item.key]
        })//NOSONAR

        const rule = {
            ruleOption: ruleType,
            attribute: attr,
            productQuestion: family,
            productMaterial: material,
            textArea: ruleType === "custommessage" ? textArea : payloadTxt,
            expression: payloadExp
        }
        getRuleData(rule)
        modalClose();
        /*let elements = document.querySelectorAll(`.editTable`);
        let exp = [];
        let txt = '';
        elements.forEach((element) => {
            txt += element.textContent
            exp = [...exp, element.textContent]
        });

        let attr = []
        showlistAttr.map((item, index) => {
            attr = [...attr, item.key]
        })

        let family = []
        showlistFamily.map((item, index) => {
            family = [...family, item.key]
        })

        const rule = {
            ruleOption: ruleType,
            attribute: attr,
            productQuestion: family,
            textArea: ruleType === "custommessage" ? textArea : txt,
            expression: exp
        }
        getRuleData(rule)
        modalClose();*/
    }
    return (
        <Modal
            modalOpen={modalOpen}
            modalClose={modalRefresh}
            heading={"Add Rule"}
            className={"modal_box"}
            modalSize={styles.custom_modal_size}
        >
            <div className={`pl-2 ${styles.patient_content}`}>
                <SelectBox
                    id={`endofformrule`}
                    name={`endofformrule`}
                    label={`End of Form Rule:`}
                    classes={{
                        column: `col-12 ${styles.col_spec}`,
                        inputClassName: `${styles.type_rule_select}`,
                        labelClassName: `col-4 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={[
                        { key: 'custommessage', value: 'Display Custom Message' },
                        //{ key: 'Display Products (product attributes)', value: 'Display Products (product attributes)' },
                        //{ key: 'Display Products (product family)', value: 'Display Products (product family)' },
                       // { key: 'Display Products (product attribute, product family)', value: 'Display Products (product attribute, product family)' }
                       { key: 'Display Products (product attribute, product family, material #)', value: 'Display Products (product attribute, product family,material #)' }
                    ]}
                    placeholder={"Select"}
                    onChange={(e) => changeEndofRule(e.target.value)}
                />
                {attributes&&Array.isArray(attributes)&&attributes.length>0&&ruleType === "Display Products (product attribute, product family, material #)" ? <MultiSelect
                    id={"selectAttribute"}
                    name={"selectAttribute"}
                    label={"Select Attribute(s):"}
                    classes={{
                        column: `col-12 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-4 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={attributes}
                    showCheckbox={true}
                    displayValue="value"
                    onSelect={(selectedList, selectedItem) => getRuleValue(selectedList, selectedItem, 'attributes')}
                    onRemove={(removeList, removeItem) => removeRuleValue(removeList, removeItem, 'attributes')}
                    defaultValue={defaultData ? defaultValue?.attribute : ''}
                /> : ''}
                {questions&&Array.isArray(questions)&&questions.length>0&&ruleType === "Display Products (product attribute, product family, material #)"  ? <MultiSelect
                    id={"selectQuestion"}
                    name={"selectQuestion"}
                    label={"Select Product Family(s):"}
                    classes={{
                        column: `col-12 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-4 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={questions}
                    showCheckbox={true}
                    displayValue="value"
                    onSelect={(selectedList, selectedItem) => getRuleValue(selectedList, selectedItem, 'family')}
                    onRemove={(removeList, removeItem) => removeRuleValue(removeList, removeItem, 'family')}
                    defaultValue={defaultData ? defaultValue?.productQuestion : ''}
                /> : ''}

{materials&&Array.isArray(materials)&&materials.length>0&&ruleType === "Display Products (product attribute, product family, material #)"  ? <MultiSelect
                    id={"selectMaterial"}
                    name={"selectMaterial"}
                    label={"Select Material #(s):"}
                    classes={{
                        column: `col-12 ${styles.col_spec}`,
                        inputClassName: styles.basic_select,
                        labelClassName: `col-4 col-form-label ${styles.label_spec}`,
                        inputColumn: `col-6 p-0 ${styles.select_wrapper}`
                    }}
                    optionValue={materials}
                    showCheckbox={true}
                    displayValue="value"
                    onSelect={(selectedList, selectedItem) => getRuleValue(selectedList, selectedItem, 'productMaterial')}
                    onRemove={(removeList, removeItem) => removeRuleValue(removeList, removeItem, 'productMaterial')}
                    defaultValue={defaultData ? defaultValue?.productMaterial : ''}
                /> : ''}
                <div className={`col-12 ${styles.col_spec}`}>
                    <label className={`col-4 col-form-label ${styles.label_spec}`} ></label>
                    {ruleType == "custommessage" ?
                        <TextArea
                            id={`textArea`}
                            name={`textArea`}
                            classes={{
                                column: `col-7 col-form-label ${styles.col_spec}`,
                                inputClassName: `${styles.text_content} getAns`,
                                labelClassName: `col-1 col-form-label ${styles.label_spec}`,
                                inputColumn: `col-12 p-0`
                            }}
                            placeholder={"Enter a response"}
                            onChange={(e) => getAnswer(e.target.value)}
                            value={textArea}
                            errors={customMsgErr}
                        />
                        :
                        <div className={`col-6 p-0 ${styles.edit_textarea}`} id="ruleData">
                            {

                                expression?.map((item, ind) => {
                                    if (isNaN(item) || item === "") {
                                        return <span contenteditable="true" className="editTable">{item}</span>
                                    } else {
                                        const findQuestion = questions?.find(x => x.key == item);
                                        const findAttribute = attributes?.find(x => x.key == item);
                                        const findMaterials = materials?.find(x => x.key == item);
                                        return <>
                                            <span className={`editTable ${styles.display_none} ruleHide`}>{item}</span>
                                            {findAttribute !== undefined ? <span>{findAttribute?.value}</span> : ''}
                                            {findQuestion !== undefined ? <span>{findQuestion?.value}</span> : ''}
                                            {findMaterials !== undefined ? <span>{findMaterials?.value}</span> : ''}
                                        </>
                                    }
                                })
                            }
                        </div>
                    }
                </div>
                {errorMsg && <div className="col-sm-6 text-danger offset-4 p-0 pb-3">
                    {errorMsg}
                </div>}
                <div className="col-sm-12">
                    <div className="text-center pb-4">
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small}` }}
                            type="button"
                            id={SUBMIT_ID}
                            onClick={() => saveRule()}
                        >
                            {SAVE_BTN}
                        </Button>
                        <Button
                            classes={{ className: `btn ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={handleCancel}
                        >
                            {CANCEL_BTN}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
AddRuleText = connect()(withRouter(AddRuleText));
