import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import styles from './dropDownItems.module.scss';
export const DropDownItems = ({id = '',name = '',validator, listOptions,errors }) => {
  let errorMessage = errors && errors[name] && errors[name].message;
  // Password mask 
  const [itemValue, setitemValue] = useState('');
  const [itemValueText, setitemValueText] = useState('Select');
  const [viewDropDown, setDropDown] = useState(false)

  /* Function to toggle drop assessment*/
  const handleDropDown = (e) => {

    setDropDown(!viewDropDown)
    setClickedOutside(false)
  }

  /* Trigger Drop Down Items close */
  const dropDownRef = useRef();
  const [clickedOutside, setClickedOutside] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  const handleClickOutside = (e) => {
    if (dropDownRef !== null && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
      setDropDown(false);
      setClickedOutside(true);
    }
  };

  const handleDropChange = (e, i, optionValues, id) => {
    document.getElementById(id).value = optionValues.key;
    document.getElementById(id + 'dyn').innerHTML = optionValues.value;
    setitemValue(optionValues.key)
    setitemValueText(optionValues.value)
    setDropDown(false);
    setClickedOutside(true);

  }


 
  return (
    <>
    <div className={`dropdown ${styles.risk_select_box} ${errorMessage &&itemValue==='' ? `${styles.error_border}` : ''}`}>
      <input
        type={'hidden'}
        name={name}
        id={id}
        ref={validator}
        value={itemValue}
      />
      <p className={`btn btn-secondary dropdown-toggle ${styles.drop_arrow} ${styles.dropdown_toggle} `} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={handleDropDown} ref={dropDownRef} id={id + 'dyn'}>
          {itemValueText}
      </p>
      {viewDropDown &&
        <div className={`dropdown-menu show ${styles.risk_drop_down}`} aria-labelledby={id} ref={dropDownRef}>
          {
            listOptions &&Array.isArray(listOptions)&&listOptions.length > 0 && listOptions.map((optionValues, i) => {
              return (
                <>
                  <p className={`dropdown-item  ${styles.risk_item_wrap}`}  onClick={e => handleDropChange(e, i, optionValues, id)}><strong>{optionValues.value ? optionValues.value : ''}</strong>
                    {optionValues.description ? ' - ' + optionValues.description : ''}

                  </p>
                </>
              )

            })
          }


        </div>
      }

    </div>
    {
          errorMessage && itemValue==='' && <span className={styles.error_message}>
            {
             errorMessage
            }
          </span>
        }
      </>
  );
}

DropDownItems.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.any,
  type: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  validator: PropTypes.func,
  errors: PropTypes.object,
  onChange: PropTypes.func
}