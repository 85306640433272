import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import {
  deleteProductListAsync, getCallBackListAsync, productDetailsBasicAsync, productOptionTypeAsync, productUpdateAsync, resetProducts, setDetailViewData, setDocumentList, setFamilyCode,
  setMaterialGroupCode, setMediaList, setProductCategoryCode
} from '../app/productSlice';
import { DetailView } from '../components';
import { BasicInfoView, CompetitorsView, DocumentsView, MarketingView, MediaView, SpecificationView } from '../components/core/Products';
import { ListView } from '../components/secondary/ListView';
import { LIST_VIEW_LIMIT, PRODUCT, SUCCESS_STATUS } from '../constants';
import { Delete, List, Pagination, Search, SelectedProductRow, Sorting } from '../utils';
export let Products = ({ dispatch, history }) => {
  

  /* Check url for PRODUCT id, productTypeUrl */
  const { productURLType,productId } = useParams()

  const [offSet, setOffSet] = useState(0)
  const [productType, setProductType] = useState('productfamily');
  let productsList = useSelector(state => state.product.products);
  const accessError = useSelector(state => state.app.accessError);
  // Clearing state 

  useEffect(() => {
    dispatch(setDocumentList({}))
    dispatch(setMediaList({}))
    dispatch(setFamilyCode({}))
    dispatch(setMaterialGroupCode({}))
    dispatch(setProductCategoryCode({}))
    
  }, [])

  const productData = useSelector(state => state.product.basicViewDetailData);

  const updateproductData = (data) => dispatch(productUpdateAsync(data))
  /* Tabs object */
  const materialTabList = [
    {
      name: 'Basic Info',
      content: <BasicInfoView productData={productData} updateproductData={updateproductData} productType={productURLType} />
    },
    {
      name: 'Specifications',
      content: <SpecificationView productData={productData} updateproductData={updateproductData} />
    }
    , {
      name: 'Media',
      content: <MediaView productData={productData} updateproductData={updateproductData} productType={productURLType}/>
    }

  ];

  const productTabList = [
    {
      name: 'Basic Info',
      content: <BasicInfoView productData={productData} updateproductData={updateproductData} productType={productURLType} />
    },
    {
      name: 'Marketing',
      content: <MarketingView productData={productData} updateproductData={updateproductData} />
    },

    {
      name: 'Media',
      content: <MediaView productData={productData} updateproductData={updateproductData} productType={productURLType}/>
    },
    {
      name: 'Documents',
      content: <DocumentsView productData={productData} updateproductData={updateproductData} productType={productURLType}/>
    },
    {
      name: 'Competitors',
      content: <CompetitorsView productData={productData} updateproductData={updateproductData} />
    }

  ];


  const path = window.location.href;
  const origin = window.location.origin;
  const isAdminView = path.includes(`${origin}/admin`);
  const [searchKey, setSearchKey] = useState('')
  const [sortType, setSortType] = useState('')
  /* Get PRODUCT Details */

  const getProductList = () => {
    List(PRODUCT + '/' + productType, 'Get', LIST_VIEW_LIMIT, 0, dispatch, getCallBackListAsync);
  }

  useEffect(() => {
    setSortType('')
    setSearchKey('')
    dispatch(resetProducts())
    getProductList();

  }, [dispatch]);

  const showFixedColumn = !!productsList.data && !!productsList.data.products.length
  let columns = [];
  (productType === 'material') ?
    columns = [
      {
        Header: 'Material #',
        accessor: 'materialNumber',
        disableSortBy: true,
        fixedColumn: showFixedColumn,
        fixedWidth: '17%'
      },
      {
        Header: 'Description',
        accessor: 'description',
        fixedColumn: showFixedColumn,
        fixedWidth: '300px'
      },
      {
        Header: 'Division',
        accessor: 'divisionCode',
        fixedColumn: showFixedColumn,
        fixedWidth: '120px'
      },
      {
        Header: 'Material group',
        accessor: 'materialGroupCode',
        fixedColumn: showFixedColumn,
        fixedWidth: '167px'
      },
      {
        Header: 'Material group desc',
        accessor: 'materialGroupDesc',
      },
      {
        Header: 'Product family',
        accessor: 'productFamilyCode'
      },
      {
        Header: 'Product family desc',
        accessor: 'productFamilyDesc',
      },
      {
        Header: 'Product category',
        accessor: 'productCategoryDesc'
      },
      {
        Header: 'Product manager',
        accessor: 'productManager'
      },
      {
        Header: 'Keywords',
        accessor: 'keyWord'
      }
    ] : columns = [

      {
        Header: 'Product family',
        accessor: 'productFamilyCode',
        fixedColumn: showFixedColumn,
        fixedWidth: '92%'
        
      },
      {
        Header: 'Division',
        accessor: 'divisionCode',
        restWidth: '150px'
      },
      {
        Header: 'Material group',
        accessor: 'materialGroupCode',
        restWidth: '180px'
      },
      {
        Header: 'Product family desc',
        accessor: 'productFamilyDesc',
        restWidth: '300px'
      },
      {
        Header: 'Product category',
        accessor: 'productCategoryDesc',
        restWidth: '300px'
      },
      {
        Header: 'Keywords',
        accessor: 'keyWord',
        restWidth: '300px'
      }

    ]

  /* If no PRODUCT id in url, dispatch empty data */
  useEffect(() => {
    if (!productId) {
      dispatch(setDetailViewData({}))
    }
  }, [productId, dispatch])

  /* If patient id exists display detail view */
  useEffect(() => {
    dispatch(productOptionTypeAsync('productmanager'));
    dispatch(productOptionTypeAsync('materialstatus'));
    dispatch(productOptionTypeAsync('productdivision'));
    dispatch(productOptionTypeAsync('manufacturer'));
    if (productId) {
      const payload={id:productId,productType:productURLType}
      dispatch(productDetailsBasicAsync(payload,history));
      
    }
  }, [productId, dispatch])

  const [detailsRowData, setDetailsRowData] = useState([])
  const getSelectedRowData = (data) => {
    SelectedProductRow(data, setDetailsRowData,'products',productType, history, isAdminView);
  }
  const handleBack = () => {
  }
  // search list
  const getSearchValue = (event) => {    
    if (event && event.keyCode === 13) {
      if (event && event.target.value !== '') {
        setOffSet(0)
        setActivePage(1)
        setSearchKey(event.target.value)
        Search(event, PRODUCT + '/' + productType, 'Get', LIST_VIEW_LIMIT, 0, dispatch, getCallBackListAsync,sortType)
      } else {
        setOffSet(0)
        setActivePage(1)
        getProductList();
        setSearchKey('')
        setSortType('')
      }
    }
  }
  // pagination
  const [activePage, setActivePage] = useState(1);
  const getActivePage = (data) => {
    setOffSet((((data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT) < LIST_VIEW_LIMIT) ? 0 : (data * LIST_VIEW_LIMIT) - LIST_VIEW_LIMIT)
    Pagination(data, setActivePage, PRODUCT + '/' + productType, 'Get', LIST_VIEW_LIMIT, dispatch, getCallBackListAsync,searchKey,'',sortType);
  }
  // sorting
  const sortingPayLoad = Sorting(PRODUCT + '/' + productType, 'Get', LIST_VIEW_LIMIT, offSet, getCallBackListAsync,searchKey,sortType,setSortType)


  //delete
  const deleteList = (data) => {
    Delete(data, dispatch, deleteProductListAsync)
  }
  const deleteStatus = useSelector(state => state.app.deleteStatus)

  useEffect(() => {
    if (deleteStatus) {
      if (deleteStatus.status === SUCCESS_STATUS) {
        getProductList();
      }
    }
  }, [deleteStatus])
  const handleProductChange = (data) => {
   // dispatch(resetProducts())
    if (data.target && data.target.value) {
      setOffSet(0)
      setActivePage(1)
      setSearchKey('')
      setSortType('')
      let changeType = data.target.value
      if (changeType === 'Product Family') {
        changeType = 'productfamily';
      } else {
        changeType = 'material';
      }
      setProductType(changeType)
      if (document.getElementById('search') && document.getElementById('search').value) {
        document.getElementById('search').value = '';
      }
      List(PRODUCT + '/' + changeType, 'Get', LIST_VIEW_LIMIT, 0, dispatch, getCallBackListAsync);
    }
  }

  const [tinyDelay, setTinyDelay] = useState(false);
  setTimeout(() => {setTinyDelay(true)}, 2000)
  return (
    <div>
      {!productId && tinyDelay && <ListView
        columns={columns}
        data={productsList.data && productsList.data.products}
        metaData={productsList.data && productsList.data.metadata}
        sorting={true}
        rowSelect={(productType == "material") ? true : false}
        getSelectedRowData={getSelectedRowData}
        detailView={true}
        pageRangeDisplayed={5}
        searchPlaceholder={"Search Skin Health Products"}
        getSearchValue={(e) => getSearchValue(e)}
        detailsRow={!productId}
        activePage={activePage}
        getActivePage={getActivePage}
        listSorting={sortingPayLoad}
        listType={"Products"}
        // subHeading={(productType === "material") ? 'Material' : (productType === "productfamily") ? 'Product Family' :''}
        subHeading = {'Products'}
        showFixedColumn={showFixedColumn}
        deleteListView={deleteList}
        searchKey={(searchKey) ? searchKey : ''}
        handleProductChange={handleProductChange}
        productType={productType}
        setProductType={setProductType}
        fixedFullWidthSize={(productType == "material") ? '62' : '17'}
        offset={offSet}
        accessError={accessError}
        sortType={sortType?sortType:''}
      /> }
      {
        productId && <DetailView
          detailsRowData={detailsRowData}
          title={(productURLType === "material") ? 'Material' : (productURLType === "productfamily") ? 'Product Family' :''}
          tabList={(productURLType ==="material") ? materialTabList : productTabList}
          defaultIndex={0}
          detailsRow={productId}
          handleBack={handleBack} 
          subHeading={(productURLType === "material") ? 'Material' : (productURLType === "productfamily") ? 'Product Family' :''}
          backLink='/admin/products'
        />
      }   
    </div>
  )
}

Products.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object
}

Products = connect()(withRouter(Products));