import React from 'react';
import styles from './assign.module.scss';
import { SelectBox } from '../../primary';
export const TextBox = ({ id, gotoText, index, getGotoValue, pIndex, goto, getInputValue, gotoOption, placeholder }) => {
    return (
        <>
            <div className={`col-7  ${styles.col_spec}`}>
                <div className={`col-8  offset-1 p-0`}>
                    <input
                        type="text"
                        name={id} id={id}
                        className={`${styles.basic_input} testIdCls`}
                        placeholder={`${placeholder || "Enter text"}`}
                        onBlur={(e) => getInputValue(e, pIndex)}
                        autoComplete="off"
                        data-testid={"textBox"}
                    />
                </div>
            </div>
            {goto === "" || goto === undefined || goto === null ? <div className={`col-3 ${styles.col_spec}`}></div> : ""}
            {goto !== "" && goto !== undefined && goto !== null && <SelectBox
                id={"Goto"}
                name={"Goto"}
                label={`Go to:`}
                classes={{
                    column: `col-3 ${styles.col_spec}`,
                    inputClassName: `${styles.type_basic_select} select_Goto`,
                    labelClassName: `col-4 col-form-label ${styles.type_label_spec}`,
                    inputColumn: `col-12 p-0 ${styles.select_wrapper}`
                }}
                optionValue={gotoOption}
                defaultValue={goto}
                onChange={(e) => getGotoValue(e.target.value, pIndex)}
                customForm={true}
            />}
        </>
    )
}
