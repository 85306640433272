import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMediaList } from '../../../app/productSlice'
import { CANCEL_BTN, CANCEL_ID, SUBMIT_ID } from '../../../constants'
import { Button, TextArea } from '../../primary'
import Loader from '../../../static/images/spinner.svg';
import styles from './productVideo.module.scss'
import { getFileName } from '../../../utils'
import { handleVideoMove } from '../../../utils/productsHelper'

export function ProductVideo({ productType, mediaList, handleAddMediaCancel, getMedia, productId }) {


    const [embedVideo, setEmbedVideo] = useState([]) // Image url
    const [inputList, setInputList] = useState([]) // List we get from API
    const [serverError, setServerError] = useState(null) // Private image error
    const [uploadSuccess, setUploadSuccess] = useState(false) // on Success
    const [videoFetch, setVideoFetch] = useState(false)

    // Loading media list from API to a state
    useEffect(() => {
        if (mediaList?.embedVideo && mediaList?.embedVideo?.length > 0) {
            setInputList([...mediaList?.embedVideo]) //NOSONAR
        }
    }, [mediaList])


    const dispatch = useDispatch()

    // Handle video 
    const handleVideo = (e) => {
        return handleVideoMove(e, setVideoFetch, getFileName, setEmbedVideo, setServerError)
    }

    // const embedCodeValidator = () => {
    //     if(embedVideo && embedVideo.length > 0){    
    //         let code = embedVideo[0]        
    //         // if(code.includes('iframe')){
    //         //     return false
    //         // }
    //         // else{
    //         //     return true
    //         // }
    //     }
    // }

    // On submit 

    const onSubmit = () => {
        if(embedVideo && embedVideo.length === 0){
            setServerError("Please enter video embed code")
            setTimeout(() => {
                setServerError(null)
            }, [5000])
            return;
        }
        const list = { ...mediaList }
        if (list?.embedVideo === null || list?.embedVideo?.length === 0) {
            list['embedVideo'] = [...embedVideo]
        }
        else if (list?.embedVideo && list?.embedVideo?.length > 0) {
            list['embedVideo'] = [...list['embedVideo'], ...embedVideo]
        }
        dispatch(updateMediaList({ productId, productType, list, getMedia, setServerError, setUploadSuccess })).then(() => {
            setEmbedVideo([]);
        }
        ) // Dispatch goes here
    }

    const loading = useSelector(state => state.app.loading)

    return (
        <div className={styles.product_video}>
            {inputList && inputList?.length >= 10 ?

                <>
                    <h3>Maximum media size reached</h3>

                    <div className="text-center pb-4">
                        <Button
                            classes={{ className: `btn mr-3 ${styles.btn_custom} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={handleAddMediaCancel}
                        >
                            {CANCEL_BTN}
                        </Button>
                    </div>
                </>

                :

                <>
                    <h4>Embed Video</h4>
                    {serverError && <div className={`alert alert-danger ${styles.alert_fix}`}>{serverError}</div>}
                    {uploadSuccess && <div className={`alert alert-success ${styles.alert_fix}`}>Video added successfully.</div>}

                    {videoFetch && <div className={styles.loader}>
                        <img src={Loader} alt="loading" />
                    </div>}

                    <div className={styles.upload_block}>
                        {loading ? <div className={styles.loader}>
                            <img src={Loader} alt="loading" />
                        </div>
                            :
                            <>
                                <TextArea
                                    id={"videoField"}
                                    name={"videoField"}
                                    classes={{ inputClassName: styles.text_area }}
                                    onChange={handleVideo}
                                    placeholder={'<iframe src="https://player.vimeo.com/video/341335823" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>'}
                                />
                            </>}
                    </div>

                    <div className="text-center pt-3">
                        {/* Save Button */}
                        <Button
                            classes={{ className: `btn ${styles.btn_custom}  ${styles.nothing} ${styles.btn_small} ${loading && styles.uploading_btn}` }}
                            type="submit"
                            id={SUBMIT_ID}
                            onClick={onSubmit}
                        >
                            {loading ? 'Uploading...' : 'Upload'}
                        </Button>
                        {/* Cancel button  */}
                        <Button
                            classes={{ className: `btn mr-3 ${styles.btn_custom}  ${styles.nothing} ${styles.btn_small_cancel}` }}
                            id={CANCEL_ID}
                            onClick={handleAddMediaCancel}
                        >
                            {CANCEL_BTN}
                        </Button>
                    </div>

                </>}
        </div>
    )
}
