import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { userActivationAsync, resendUserActivationAsync } from '../app/userSlice'
import PropTypes from 'prop-types'
import { UrlParams } from '../utils/commonHelper'

export let UserActivation = ({ dispatch, history, location }) => {

    const activationDetails = useSelector(state => state.user.activationDetails);

    useEffect(() => {
        const params = new UrlParams(location.search);
        if (params.get('username')) {
            /* If email and token exists in URL params, use that */
            dispatch(userActivationAsync(params.get('username'), `${params.get('token')}=`, history));
        } else if (activationDetails['email']) {
            /* If email and token exists in redux state, use that */
            dispatch(resendUserActivationAsync(activationDetails.email, activationDetails.token, history))
        } 
        else {
           // history.push('/status/activationsuccess');
            history.push('/');
        }

    }, [dispatch, history, location, activationDetails]);

    return null
}

UserActivation.propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object
}

UserActivation = connect()(withRouter(UserActivation))