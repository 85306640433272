import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from '../../../static/images/skin-health-logo.svg';
import {
    logo_style
} from '../../../styles/common.module.scss';

export function Logo() {
    return <div className="container pt-3 pb-3">
        <div className="row">
            <div className={`col-12 col-sm-12 mx-auto text-center text-lg-left ${logo_style} `}>
            <Link to="/"><img src={LogoImage} alt="Medline Logo " className="img-fluid" /></Link>
            </div>
        </div>
    </div>
}
